import { widgetSelectors } from '~/selectors'
import { perspectiveTypes } from '../types/perspective.types'
import { widgetCreators } from './widget.creators'

const toggleAutoRefresh = () => {
    return {
        type: perspectiveTypes.TOGGLE_AUTO_REFRESH,
    }
}

const refreshPerspective = () => (dispatch, getState) => {
    dispatch(widgetCreators.clearShouldRefresh())
    setTimeout(() => {
        const state = getState()
        const perspectiveId = widgetSelectors.getCurrentPerspectiveId(state)
        const widgetIds = widgetSelectors.getWidgetsForPerspective(state, perspectiveId)
        return dispatch(widgetCreators.setShouldRefresh(widgetIds))
    }, 500)
}

const togglePerspectiveSummary = (value: boolean) => {
    return {
        type: perspectiveTypes.TOGGLE_PERSPECTIVE_SUMMARY,
        payload: { value },
    }
}

export const perspectiveCreators = {
    toggleAutoRefresh,
    refreshPerspective,
    togglePerspectiveSummary,
}
