import { useState } from 'react'
import { Autocomplete } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Box, TextField, Drawer, IconButton, Typography, Button } from '@mui/material'

import { Table, FormWidget, Settings } from 'genesis-suite/icons'
import { FormConfig, } from 'genesis-suite/types/visualTypes'
import { SourceOption } from './EditFormTypes'
import LabeledToggleButton from '../LabeledToggleButton'

const useStyles = makeStyles(({ palette, spacing }) => ({
    settingsBtn: {
        zIndex: 10,
        marginLeft: -20,
        borderLeft: 'none',
        position: 'absolute',
        marginTop: spacing(4),
        alignSelf: 'flex-start',
        borderTopRightRadius: spacing(2),
        background: palette.secondary.main,
        borderBottomRightRadius: spacing(2),
        border: `1px solid ${palette.grayscale.light}`,
        transition: 'margin-left .5s',
        '&:hover': {
            marginLeft: 0,
        },
        '& .MuiIconButton-root': {
            color: palette.getContrastText(palette.secondary.main),
        },
    },
    top: { top: 50, '&>div': { top: 50 } },
    drawerPaper: { display: 'flex', flexFlow: 'column', padding: spacing(1), '&>*': { marginBottom: spacing(1) } },
    submitBtn: { alignSelf: 'flex-end' },
}))

interface Props {
    isNew: boolean
    loading: boolean
    network: SourceOption[]
    handleTypeChange: () => void
    config: FormConfig
    onSourceChange: (source: SourceOption) => void
}

export default function FormConfigDrawer({ isNew, loading, network, handleTypeChange, config, onSourceChange }: Props) {
    const classes = useStyles({})
    const { source, formType } = config
    const [open, setOpen] = useState(isNew)
    const sourceVal = network.find(o => o.id === source?.id) || null

    const options = [
        { value: 'table', tip: 'Switch to Table Form', label: <Table /> },
        { value: 'input', tip: 'Switch to Input Form', label: <FormWidget /> },
    ]

    return (
        <>
            {!open && (
                <div className={classes.settingsBtn}>
                    <IconButton onClick={() => setOpen(true)} size="large">
                        <Settings />
                    </IconButton>
                </div>
            )}
            <Drawer className={classes.top} classes={{ paper: classes.drawerPaper }} open={open}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h6">Form Configuration</Typography>
                    <Settings />
                </Box>
                <LabeledToggleButton
                    disabled={loading}
                    options={options}
                    exclusive
                    value={formType}
                    onChange={handleTypeChange}
                />
                <Autocomplete
                    fullWidth
                    value={sourceVal}
                    options={network}
                    disableClearable={true}
                    groupBy={opt => opt.groupBy}
                    getOptionLabel={opt => opt.name}
                    isOptionEqualToValue={opt => opt.id === sourceVal.id}
                    onChange={(e, source: SourceOption, reason) => {
                        if (reason !== 'blur') onSourceChange(source)
                    }}
                    renderInput={params => <TextField {...params} label="Source Node/Link" variant="outlined" />}
                />
                <Button
                    color="primary"
                    variant="contained"
                    className={classes.submitBtn}
                    onClick={() => setOpen(false)}
                >
                    Confirm
                </Button>
            </Drawer>
        </>
    )
}
