import PublishIcon from '@mui/icons-material/RocketLaunch'
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Skeleton,
    Typography
} from '@mui/material'
import { isEmpty } from 'lodash'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { Collapsable } from 'genesis-suite/components'
import { letMeMap } from 'genesis-suite/types/utilTypes'
import {
    DraftAction,
    DraftCountByAction,
    DraftCountByStatus,
    DraftCountByType,
    DraftStatus,
    PublishError,
    VisualType,
    VisualTypeAndConfig
} from 'genesis-suite/types/visualTypes'
import { visualService } from '../../lib/services'
import { applicationSelectors, deploymentSelectors } from '../../selectors'
import { ApprovalsContext } from '../contexts/ApprovalsContext'

export default function PendingDraftsList() {
    const viewFlag = useSelector(deploymentSelectors.getDeploymentViewFlag)
    const { counts, setCounts } = useContext(ApprovalsContext)
    const [openPublish, setOpenPublish] = useState(false)

    if (!viewFlag) return <Typography sx={{ p: 1 }}>Select a different view</Typography>
    if (counts == null) return null

    function handlePublishDone(published = false) {
        if (published) setCounts(s => ({ ...s, [DraftStatus.APPROVED]: emptyCountsByType }))
        setOpenPublish(false)
    }

    return (
        <>
            <Button
                disabled={!counts[DraftStatus.APPROVED].all.total}
                fullWidth
                onClick={() => setOpenPublish(true)}
                startIcon={<PublishIcon />}
                variant="outlined"
            >
                Publish
            </Button>
            <PublishDialog counts={counts} open={openPublish} onDone={handlePublishDone} />

            <Box py={1}>
                <DraftsByStatus status={DraftStatus.EDITING} />
                <DraftsByStatus status={DraftStatus.PENDING} />
                <DraftsByStatus status={DraftStatus.APPROVED} />
                <DraftsByStatus status={DraftStatus.REJECTED} />
            </Box>
        </>
    )
}

function DraftsByStatus({ status }: { status: DraftStatus }) {
    const { counts, selectedVisual } = useContext(ApprovalsContext)
    const [open, setOpen] = useState(false)

    const countByType = counts[status]
    const totalCount = countByType.all.total

    useEffect(() => {
        if (!open && selectedVisual?.status === status) setOpen(true)
    }, [selectedVisual])

    useEffect(() => {
        if (open && !totalCount) setOpen(false)
    }, [totalCount])

    return (
        <Collapsable
            buttonProps={{ disabled: !totalCount }}
            open={open}
            header={
                <Typography color="inherit" sx={{ fontWeight: 'bold', pl: 1 }}>
                    {status} ({totalCount})
                </Typography>
            }
            onToggle={() => setOpen(s => !s)}
        >
            <Box py={1}>
                {Boolean(countByType.widget.total) && <AsyncDraftsByType status={status} type={VisualType.WIDGET} />}
                {Boolean(countByType.dashboard.total) && (
                    <AsyncDraftsByType status={status} type={VisualType.DASHBOARD} />
                )}
                {Boolean(countByType.module.total) && <AsyncDraftsByType status={status} type={VisualType.MODULE} />}
            </Box>
        </Collapsable>
    )
}

interface AsyncDraftsByTypeProps {
    status: DraftStatus
    type: VisualType
}

function AsyncDraftsByType({ status, type }: AsyncDraftsByTypeProps) {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const viewFlag = useSelector(deploymentSelectors.getDeploymentViewFlag)
    const { selectVisual, updateVisuals, counts, selectedVisual, visuals } = useContext(ApprovalsContext)

    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    const countByType = counts[status]
    const countByAction = countByType[type]
    const totalCount = countByAction.total
    const filteredVisuals = visuals.filter(v => v.config.draft?.status === status && v.type === type).map(v => v.config)
    const title = type === VisualType.DASHBOARD ? 'Collection' : type === VisualType.MODULE ? 'App' : 'Widget'

    const hasFetched = useRef(false)
    useEffect(() => {
        if (!open || hasFetched.current || !viewFlag) return

        hasFetched.current = true

        setLoading(true)
        visualService
            .getDraftsByType(appName, type, { type: viewFlag, status })
            .then(drafts => {
                const visuals = letMeMap(drafts).map(config => ({ type, config } as VisualTypeAndConfig))
                updateVisuals(visuals, true)
            })
            .finally(() => setLoading(false))
    }, [open, appName, type, viewFlag])

    useEffect(() => {
        if (open || !selectedVisual) return
        if (selectedVisual.status === status && selectedVisual.type === type) setOpen(true)
    }, [selectedVisual])

    useEffect(() => {
        if (open && !totalCount) setOpen(false)
    }, [totalCount])

    return (
        <Collapsable
            open={open}
            onToggle={() => setOpen(s => !s)}
            header={
                <Typography sx={{ fontWeight: 'bold', pl: 2 }}>
                    {title} ({totalCount})
                </Typography>
            }
        >
            <List component="div" disablePadding dense>
                {loading
                    ? Array(totalCount).fill(0).map((_, index) => (
                          <ListItem key={index} sx={{ pl: 4 }}>
                              <Skeleton height="22px" width="100%" />
                          </ListItem>
                      ))
                    : filteredVisuals.map(i => (
                          <ListItemButton
                              key={i.id}
                              selected={i.id === selectedVisual?.id}
                              sx={{ pl: 4 }}
                              onClick={() => selectVisual(i.id)}
                          >
                              <ListItemText primary={i.title} />
                          </ListItemButton>
                      ))}
            </List>
        </Collapsable>
    )
}

interface PublishDialogProps {
    counts: DraftCountByStatus
    open: boolean
    onDone: (published?: boolean) => void
}

function PublishDialog({ counts, open, onDone }: PublishDialogProps) {
    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const viewFlag = useSelector(deploymentSelectors.getDeploymentViewFlag)

    const [errors, setErrors] = useState<PublishError[]>(null)

    useEffect(() => {
        if (!open) return setErrors([])

        visualService.publishErrorCheck(appName, viewFlag).then(setErrors)
    }, [open])

    const { all, [DraftStatus.APPROVED]: approvedCountByType, ...otherCountsByType } = counts

    async function handlePublish() {
        try {
            await visualService.publish(appName, viewFlag)
            onDone(true)
        } catch (err) {
            console.error(err)
            showSnackbar('Failed to publish changes', { variant: 'error' })
        }
    }

    return (
        <Dialog open={open} onClose={() => onDone()}>
            <DialogTitle>Publish</DialogTitle>
            <DialogContent>
                {errors ? (
                    errors.length ? (
                        errors.map((error, index) => <ErrorMessage key={index} {...error} />)
                    ) : (
                        <Box>
                            <CountMessage {...approvedCountByType.all} title="total approved" />
                            <CountMessage {...approvedCountByType.module} title="application" />
                            <CountMessage {...approvedCountByType.dashboard} title="collection" />
                            <CountMessage {...approvedCountByType.widget} title="widget" />

                            {!isEmpty(otherCountsByType) && (
                                <Typography mt={2}>
                                    {all.all.total - approvedCountByType.all.total} unapproved changes will not be
                                    published -{' '}
                                    {Object.entries(otherCountsByType)
                                        .filter(([type, countByAction]) => countByAction.all.total)
                                        .map(([type, countByAction]) => `${countByAction.all.total} ${type}`)
                                        .join()}
                                </Typography>
                            )}
                        </Box>
                    )
                ) : (
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <CircularProgress />
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onDone()} color="error">
                    Cancel
                </Button>
                <Button onClick={handlePublish} variant="contained">
                    Publish
                </Button>
            </DialogActions>
        </Dialog>
    )
}

function CountMessage({ Added, Updated, Removed, total, title }: DraftCountByAction & { title: string }) {
    let detail = ''
    if (Added) detail = detail ? `${detail}, ${Added} added` : `${Added} added`
    if (Updated) detail = detail ? `${detail}, ${Updated} updated` : `${Updated} updated`
    if (Removed) detail = detail ? `${detail}, ${Removed} removed` : `${Removed} removed`

    return (
        <Typography>
            {total} {title} change{total === 1 ? '' : 's'} - {detail}
        </Typography>
    )
}

function ErrorMessage({ dependant, visual }: PublishError) {
    return (
        <Typography>
            {visual.type} {visual.title} depends on {dependant.type} {dependant.title} ({dependant.status}){' '}
        </Typography>
    )
}

const emptyCountsByType: DraftCountByType = {
    all: { total: 0, [DraftAction.ADDED]: 0, [DraftAction.REMOVED]: 0, [DraftAction.UPDATED]: 0 },
    dashboard: { total: 0, [DraftAction.ADDED]: 0, [DraftAction.REMOVED]: 0, [DraftAction.UPDATED]: 0 },
    module: { total: 0, [DraftAction.ADDED]: 0, [DraftAction.REMOVED]: 0, [DraftAction.UPDATED]: 0 },
    widget: { total: 0, [DraftAction.ADDED]: 0, [DraftAction.REMOVED]: 0, [DraftAction.UPDATED]: 0 },
}
