import React, { useState } from 'react'
import { TwitterPicker } from 'react-color'
import makeStyles from '@mui/styles/makeStyles'
import { DragIndicatorRounded } from '@mui/icons-material'
import {
    Select,
    Popper,
    MenuItem,
    FormGroup,
    TextField,
    InputLabel,
    IconButton,
    FormControl,
    Autocomplete,
    createFilterOptions,
} from '@mui/material';

import { Close } from 'genesis-suite/icons'
import { Property } from 'genesis-suite/types/networkTypes'
import { ColorFormat, ComparisonOperator, FormConditionalFormat } from 'genesis-suite/types/visualTypes'

const useStyles = makeStyles(({ spacing }) => ({
    fieldsContainer: {
        width: '100%',
        alignItems: 'flex-end',
        marginBottom: spacing(1),
        justifyContent: 'space-evenly',
    },
    propertySelector: { width: 175 },
    optionBox: {
        overflowX: 'hidden',
        '& li': {
            display: 'block',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
    },
}))

interface Props {
    properties: Property[]
    condFormat: FormConditionalFormat
    onChange: (change: FormConditionalFormat) => void
    onDelete: () => void
}

interface UserDefined {
    name: string
    value: string
    userDefined: boolean
}

type TargetOption = UserDefined | Property

const ruleOptions: { label: string; value: ComparisonOperator }[] = [
    { label: '=', value: ComparisonOperator.EQUAL },
    { label: '>', value: ComparisonOperator.GREATER_THAN },
    { label: '>=', value: ComparisonOperator.GREATER_THAN_OR_EQUAL },
    { label: '<', value: ComparisonOperator.LESS_THAN },
    { label: '<=', value: ComparisonOperator.LESS_THAN_OR_EQUAL },
]

export function CondFormat({ condFormat, properties, onChange, onDelete }: Props) {
    const classes = useStyles({})
    const { property, rule, target, format } = condFormat

    const handleChange = (change: Partial<FormConditionalFormat>) => onChange({ ...condFormat, ...change })

    const handleTarget = (target: TargetOption) => {
        if (target) {
            if ('userDefined' in target) {
                handleChange({ target: target.value })
            } else handleChange({ target })
        }
    }

    const getTargetLabel = (option: string | number | Property | UserDefined): string => {
        if (typeof option === 'object') {
            return option.name
        } else {
            return option.toString()
        }
    }

    const handleColor = (colorFormat: ColorFormat) => {
        handleChange({ format: { ...format, ...colorFormat } })
    }

    return (
        <FormGroup row className={classes.fieldsContainer}>
            <DragIndicatorRounded fontSize="small" />
            <Autocomplete
                disableClearable
                value={property}
                options={properties}
                getOptionLabel={opt => opt.name}
                className={classes.propertySelector}
                classes={{ listbox: classes.optionBox }}
                onChange={(e, property: Property) => handleChange({ property })}
                renderInput={params => <TextField label="Property" {...params} />}
            />
            <FormControl>
                <InputLabel id="rule">Operator</InputLabel>
                <Select
                    value={rule}
                    labelId="rule"
                    onChange={e => handleChange({ rule: e.target.value as ComparisonOperator })}
                >
                    {ruleOptions.map(o => (
                        <MenuItem key={o.label} value={o.value}>
                            {o.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Autocomplete
                value={target}
                disableClearable
                options={properties}
                getOptionLabel={getTargetLabel}
                className={classes.propertySelector}
                classes={{ listbox: classes.optionBox }}
                onChange={(e, value: TargetOption) => handleTarget(value)}
                filterOptions={(options, params) => {
                    const filtered = createFilterOptions()(options, params) as TargetOption[]
                    const { inputValue } = params
                    if (inputValue !== '')
                        filtered.push({ name: `Use "${inputValue}"`, value: inputValue, userDefined: true })
                    return filtered
                }}
                renderInput={params => <TextField label="Target" placeholder="Enter or select value" {...params} />}
            />
            <ColorPicker
                color={format.backgroundColor}
                onChange={backgroundColor => handleColor({ backgroundColor })}
            />
            <IconButton onClick={onDelete} size="large">
                <Close fontSize="small" />
            </IconButton>
        </FormGroup>
    )
}

const usePickerStyles = makeStyles(({ spacing, palette }) => ({
    colorPickerRoot: {
        height: 48,
        width: 60,
        justifyContent: 'flex-end',
    },
    colorPickerWrapper: {
        flex: 1,
        borderRadius: 4,
        padding: spacing(0.5),
        marginTop: spacing(2),
        border: '1px solid black',
    },
    colorPicker: {
        width: '100%',
        height: '100%',
        borderRadius: 4,
        backgroundColor: ({ color }: any) => (color ? color : '#FFFFFF'),
    },
}))

interface ColorPickerProps {
    color: string
    onChange: (color: string) => void
}

function ColorPicker({ color, onChange }: ColorPickerProps) {
    const [anchorEl, setAnchorEl] = useState(null)
    const classes = usePickerStyles({ color })

    const toggleAnchor = (e: React.MouseEvent<HTMLElement>) =>
        anchorEl ? setAnchorEl(null) : setAnchorEl(e.currentTarget)

    const colorOpen = Boolean(anchorEl)

    return (
        <FormControl className={classes.colorPickerRoot}>
            <InputLabel focused shrink>
                Background
            </InputLabel>
            <div className={classes.colorPickerWrapper} onClick={toggleAnchor}>
                <div className={classes.colorPicker} />
            </div>
            <Popper open={colorOpen} anchorEl={anchorEl}>
                <TwitterPicker
                    color={color}
                    width="175px"
                    triangle="hide"
                    onChange={({ hex }) => onChange(hex)}
                    colors={['#D0021B', '#F5A623', '#F8E71C', '#7ED321']}
                />
            </Popper>
        </FormControl>
    )
}
