import { Box } from '@mui/material'
import { useContext, useState } from 'react'
import GridLayout from 'react-grid-layout'
import Measure from 'react-measure'

import { DragItem, DragList } from 'genesis-suite/components'
import { DashboardDevice, Widget } from 'genesis-suite/types/visualTypes'
import { DashboardContext } from './DashboardContext'
import { grabHandleStyles, gridGap, makeFlexLayout, mobileScale, WidgetWithPosition } from './dashboardUtils'
import DashboardWidget from './DashboardWidget'
import { appearanceConstants } from '../../../constants'

export default function DashboardTopWidgets() {
    const { layout } = useContext(DashboardContext)
    const { topRowCount } = layout

    return <Box sx={{ display: 'flex', flex: 1 }}>{topRowCount === 1 ? <SingleRowWidgets /> : <GridWidgets />}</Box>
}

function SingleRowWidgets() {
    const { config, editing, updateConfig, widgets } = useContext(DashboardContext)

    function handleUpdate(updatedTopWidgets: WidgetWithPosition[]) {
        const mainWidgets = widgets.filter(w => !w.position?.top)
        const orderedIds = [...updatedTopWidgets, ...mainWidgets].map(w => w.id)
        const updated = orderedIds.map(id => config?.widgets.find(w => w.id === id))
        updateConfig({ widgets: updated })
    }

    const topWidgets = widgets.filter(w => w.position?.top)
    const editable = editing && widgets.length > 1

    return (
        <DragList
            droppableContainerProps={{
                sx: { flex: 1, overflow: 'scroll auto', paddingX: `${appearanceConstants.ScrollBarWidth}px` },
            }}
            droppableProps={{ direction: 'horizontal' }}
            onDragEnd={handleUpdate}
            items={topWidgets}
        >
            <Box sx={{ display: 'flex', height: '100%', gap: '8px' }}>
                {topWidgets.map((l, index) => {
                    const widget = widgets.find(w => (w as Widget).draft?.visualId === l.id || w.id === l.id)
                    return (
                        <DragItem isDragDisabled={!editable} key={widget.id} itemId={widget.id} index={index}>
                            <DashboardWidget key={widget.id} config={widget} />
                        </DragItem>
                    )
                })}
            </Box>
        </DragList>
    )
}

function GridWidgets() {
    const { devicePreview, layout, positionWidgets, widgets, editing } = useContext(DashboardContext)
    const [dimensions, setDimensions] = useState<{ height: number; width: number }>({ width: 0, height: 0 })

    const topWidgets = widgets.filter(w => w.position?.top)
    const { topColumnCount, topRowCount } = layout
    const gridLayout = makeFlexLayout(topWidgets, { row: topRowCount, column: topColumnCount })
    const rowHeight =
        (dimensions.height - appearanceConstants.ScrollBarWidth) / topRowCount -
        (gridGap * (topRowCount - 1)) / topRowCount

    return (
        <Measure client onResize={({ client }) => setDimensions(client)}>
            {({ measureRef }) => (
                <Box
                    ref={measureRef}
                    sx={{
                        flex: 1,
                        paddingBottom: `${appearanceConstants.ScrollBarWidth}px`,
                        marginLeft: `${appearanceConstants.ScrollBarWidth}px`,
                        overflow: 'hidden scroll',
                        ...grabHandleStyles,
                    }}
                >
                    <GridLayout
                        autoSize={false}
                        cols={topColumnCount}
                        containerPadding={[0, 0]}
                        isDraggable={editing}
                        isResizable={editing}
                        layout={gridLayout}
                        margin={[gridGap, gridGap]}
                        onLayoutChange={positionWidgets}
                        preventCollision={false}
                        rowHeight={rowHeight}
                        transformScale={devicePreview === DashboardDevice.SMALL ? mobileScale : 1}
                        verticalCompact={true}
                        width={dimensions.width - 1}
                    >
                        {gridLayout?.map(l => {
                            const widget = widgets.find(w => (w as Widget).draft?.visualId === l.i || w.id === l.i)
                            return <div key={l.i}>{widget && <DashboardWidget config={widget} />}</div>
                        })}
                    </GridLayout>
                </Box>
            )}
        </Measure>
    )
}
