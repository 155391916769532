import { ComparisonOperator, DataType } from 'genesis-suite/types/visualTypes'

/**
 * @param {Object} formatConfig - the formatting configuration set in the visual editor
 * @param {Object} data - object representing the field/value pairs for the data point to be format checked
 * @param {Object} columnConfig - configuration for the column or data series
 */
export const conditionalFormattingCheck = (formatConfig, data = {}, columnConfig) => {
    const { HeaderName } = columnConfig
    const { FormatRule, FormatValue, TableField, TargetProperty } = formatConfig

    const parsedData = { ...data }
    Object.keys(parsedData).forEach(key => {
        const split = key.split('-')
        if (split.length > 1) parsedData[split[0]] = parsedData[key]
    })

    const field = TableField || HeaderName
    const value = parsedData[field] ?? parsedData[HeaderName]
    const target = TargetProperty ? parsedData[TargetProperty] : FormatValue

    return shouldApplyFormat(value, target, FormatRule)
}

/**
 * Converts "=", "<", etc to "EqualTo", "LessThan", etc.
 *
 * @param {ComparisonOperator} rule rule value to be converted
 * @returns the string that represents the operator
 */
export const convertRuleType = (rule: ComparisonOperator) => {
    const rulesConvertion = {
        [ComparisonOperator.EQUAL]: 'EqualTo',
        [ComparisonOperator.GREATER_THAN]: 'GreaterThan',
        [ComparisonOperator.LESS_THAN]: 'LessThan',
        [ComparisonOperator.NOT_EQUAL]: 'NotEqualTo',
        [ComparisonOperator.LESS_THAN_OR_EQUAL]: 'LessThanorEqualTo',
        [ComparisonOperator.GREATER_THAN_OR_EQUAL]: 'GreaterThanorEqualTo',
        [ComparisonOperator.CONTAINS]: 'Contains',
        [ComparisonOperator.STARTS_WITH]: 'StartsWith',
        [ComparisonOperator.ENDS_WITH]: 'EndsWith',
    }
    return rulesConvertion[rule]
}

/**
 * return variables converted based on datatype provided
 *
 * @param {string} value field value
 * @param {string} target target value that will be compared
 * @param {string} dataType type of the field value
 * @returns value and target variables with the proper type to be compared
 */
const compareTypes = (value: string, target: string, dataType: DataType) => {
    switch (dataType) {
        case DataType.TEXT:
            return [value, target]
        case DataType.DATE:
            const dateValue = new Date(value)
            const dateTarget = new Date(target)
            return [dateValue, dateTarget]
        default:
            const numberValue = Number(value)
            const numberTarget = Number(target)
            return [numberValue, numberTarget]
    }
}

/**
 * Converts "undefined" and "null" String values to null.
 * Otherwise, the original target value will be returned.
 */
const convertTargetType = (targetStr: string): string | null => {
    if (targetStr === 'undefined' || targetStr === 'null') return null
    else return targetStr
}

export const shouldApplyFormat = (value, target, rule, dataType: DataType = DataType.NUMBER) => {
    const convertedTarget = typeof target === 'string' ? convertTargetType(target.toLowerCase()) : target

    /** Using "==" because null == undefined */
    if (value == null) {
        switch (rule) {
            case 'EqualTo':
                return value == convertedTarget
            default:
                return false
        }
    }

    const [typedValue, typedTarget] = compareTypes(value, target, dataType)

    switch (rule) {
        case 'GreaterThan':
            return typedValue > typedTarget
        case 'LessThan':
            return typedValue < typedTarget
        case 'EqualTo':
            return value.toString().toLowerCase() === target.toString().toLowerCase()
        case 'NotEqualTo':
            return value.toString().toLowerCase() !== target.toString().toLowerCase()
        case 'LessThanorEqualTo':
            return typedValue <= typedTarget
        case 'GreaterThanorEqualTo':
            return typedValue >= typedTarget
        case 'Contains':
            return value.toString().toLowerCase().includes(target.toString().toLowerCase())
        case 'StartsWith':
            return value.toString().toLowerCase().startsWith(target.toString().toLowerCase())
        case 'EndsWith':
            return value.toString().toLowerCase().endsWith(target.toString().toLowerCase())
        default:
            console.error('Unknown conditional formatting rule')
            return false
    }
}
