import { CloseRounded } from '@mui/icons-material'
import { Dialog, DialogContent, DialogTitle, IconButton, Tooltip, styled } from '@mui/material'
import { useHotKey } from 'genesis-suite/hooks'
import { Scenario } from 'genesis-suite/icons'
import { useState } from 'react'
import { HotKeys, createTip } from '../../lib/hotkeys'
import ScenarioController from './ScenarioController'

const StyledDialog = styled(Dialog)(() => ({
    '& .MuiDialog-paper': {
        width: 700,
        height: 500,
    },
}))

export default function ScenarioButton({ iconStyle, buttonStyle }) {
    const [open, setOpen] = useState(false)

    const toggle = () => setOpen(!open)
    useHotKey(HotKeys.Scenarios, toggle)
    const keyTip = createTip(HotKeys.Scenarios)

    return (
        <>
            <Tooltip title={`Scenarios ${keyTip}`}>
                <IconButton sx={buttonStyle} onClick={toggle}>
                    <Scenario sx={iconStyle} />
                </IconButton>
            </Tooltip>
            <StyledDialog open={open} onClose={toggle}>
                <DialogTitle>
                    Manage Scenarios
                    <IconButton
                        aria-label="close"
                        onClick={toggle}
                        sx={{
                            color: 'inherit',
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseRounded />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <ScenarioController onRequestClose={toggle} />
                </DialogContent>
            </StyledDialog>
        </>
    )
}
