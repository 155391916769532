import { createSelector } from 'reselect'

import { applicationSelectors } from '.'
import { moduleSelectors } from './module.selectors'
import { isEmpty } from 'lodash'

const getUser = state => {
    return state.authentication.user
}

const getSessionExpired = state => {
    return state.session.expired
}

const getAccessKey = state => {
    return state.authentication.user && state.authentication.user.accessKey
}

const getUserId = state => {
    // return 'another person'
    return state.authentication.user && state.authentication.user.userId
}

const getUserAlias = state => {
    return state.authentication.user && state.authentication.user.alias
}

const getIsCoreSuperUser = state => state.authentication.user?.isCoreSuperUser ?? false

const getUserFirstName = state => state.authentication?.user?.firstName ?? ''

const getUserEmail = state => state.authentication.user?.email

const getContextDefaultNetworkFilters = state => state.authentication.user?.ContextDefaultNetworkFilters

const getUserPreferences = state => {
    return (
        state.authentication.user &&
        state.authentication.user.preferences &&
        state.authentication.user.preferences.appPrefs
    )
}

const getDefaultModule = createSelector([getUserPreferences], preferences => {
    if (preferences) {
        const defaultModule = preferences.find(pref => pref.Key === 'defaultModule')
        return defaultModule && defaultModule.Value
    }
})

const getAutoLaunchPerspective = createSelector(
    [getUserPreferences, moduleSelectors.getModuleId],
    (preferences, moduleId) => {
        if (preferences) {
            const autoLaunchPerspective = preferences.find(
                pref => pref.Key === 'AutoShowPerspective' && pref.VisorId === moduleId
            )
            return autoLaunchPerspective?.Value && JSON.parse(autoLaunchPerspective.Value).Id
        }
    }
)

const getShortcuts = createSelector([getUserPreferences], preferences => {
    if (!preferences) return []

    const shortcuts = preferences.find(pref => pref.Key === 'Shortcuts')
    return shortcuts ? (shortcuts.Value ? JSON.parse(shortcuts.Value) : []) : []
})

const getGlobalPreferenceByKey = (state, key) => {
    const preferences = getUserPreferences(state)
    if (!preferences) return [null, null]

    const cloudNames = Object.keys(preferences)

    return cloudNames.reduce(
        (acc, cloud) => {
            const preference = Array.isArray(preferences[cloud]) && preferences[cloud].find(p => p.Key === key)
            return preference ? [cloud, preference] : acc
        },
        [cloudNames[0], null]
    )
}

const getIsFavorite = (state, key, value) => {
    const preferences = getUserPreferences(state)
    if (!preferences) return false
    const favorites = preferences.find(pref => pref.Key === key)
    const parsed = favorites ? JSON.parse(favorites.Value) : []
    return parsed.includes(value)
}

const getPreference = (state, key) => {
    const preferences = getUserPreferences(state)
    if (!preferences) return null
    const pref = preferences.find(pref => pref.Key === key)
    return pref && JSON.parse(pref.Value)
}

const getAppPermission = createSelector([getUser, applicationSelectors.getCurrentAppName], (user, currentApp) => {
    if (!user?.permissions) return
    return userPermissionLevels[user.permissions[currentApp]]
})

const getIsPowerUser = createSelector(
    [getIsCoreSuperUser, getAppPermission],
    (isCoreSuperUser, permission) => isCoreSuperUser || permission !== 'User'
)

const getIsAdmin = createSelector(
    [getIsCoreSuperUser, getAppPermission],
    (isCoreSuperUser, permission) => isCoreSuperUser || permission === 'Admin' || permission === 'Owner'
)

const getAppDefaultNetworkFilters = createSelector(
    [getContextDefaultNetworkFilters, applicationSelectors.getCurrentAppName],
    (defaultNetworkFilters, currentApp) => {
        if (isEmpty(defaultNetworkFilters)) return
        return defaultNetworkFilters[currentApp]
    }
)

const getIsFormMultiEditEnabled = createSelector([getUserPreferences], preferences => {
    if (preferences) {
        const multiEditPref = preferences.find(pref => pref.Key === 'formsMultiEditEnabled')
        return multiEditPref && JSON.parse(multiEditPref.Value)
    }
})

const getIsYAxisRangeSelectionEnabled = createSelector([getUserPreferences], preferences => {
    if (preferences) {
        const yAxisPref = preferences.find(pref => pref.Key === 'dynamicYAxisRangeEnabled')
        return yAxisPref && JSON.parse(yAxisPref.Value)
    }
})

const getCanPublishScenario = createSelector([getUser, applicationSelectors.getCurrentAppName], (user, appName) =>
    user && appName ? user?.scenarioPublishPermissions[appName] : false
)

const getCanApproveScenario = createSelector([getUser, applicationSelectors.getCurrentAppName], (user, appName) =>
    user && appName ? user?.scenarioApprovalPermissions[appName] : false
)

const userPermissionLevels = {
    1: 'User',
    4: 'Super User',
    5: 'Admin',
    6: 'Owner',
}

const getIsPublicLogin = createSelector(getUserEmail, email => !email)

const getDomainId = state => state.authentication.user?.domainId

export const authSelectors = {
    getUser,
    getAppPermission,
    getSessionExpired,
    getAccessKey,
    getUserId,
    getUserAlias,
    getUserFirstName,
    getUserEmail,
    getUserPreferences,
    getDefaultModule,
    getShortcuts,
    getGlobalPreferenceByKey,
    getAutoLaunchPerspective,
    getIsFavorite,
    getPreference,
    getIsPowerUser,
    getIsAdmin,
    getIsPublicLogin,
    getContextDefaultNetworkFilters,
    getAppDefaultNetworkFilters,
    getIsFormMultiEditEnabled,
    getIsYAxisRangeSelectionEnabled,
    getCanPublishScenario,
    getCanApproveScenario,
    getIsCoreSuperUser,
    getDomainId,
}
