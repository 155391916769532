import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ReactNode } from 'react'

import { PropertyType } from 'genesis-suite/types/architectureTypes'
import { Aggregation } from 'genesis-suite/types/visualTypes'
import { DisplayProperty, DragField } from '../builderTypes'
import PropertyChip from '../PropertyChip'
import PropertyController from './PropertyController'

interface Props {
    properties: DisplayProperty[]
    accept: PropertyType[]
    limit?: number
    label?: string
    children?: ReactNode | ReactNode[]
    onFieldSelect?: (index: number) => void
    onAggregationChange?: (index: number, value: Aggregation) => void
    /** add item. if index is provided, insert item at that location */
    onAdd: (field: DragField, index?: number) => void
    onRemove: (index: number) => void
}

const useStyles = makeStyles(({ spacing, palette }) => ({
    borderedSection: {
        minHeight: 48,
        maxHeight: 200,
        display: 'flex',
        padding: spacing(1),
        marginBottom: spacing(1),
        flexFlow: 'column nowrap',
        border: `2px solid ${palette.grayscale.light}`,
    },
}))

export default function DnDPropertySelector({
    properties,
    accept,
    limit,
    label,
    children = null,
    onFieldSelect,
    onAggregationChange,
    onAdd,
    onRemove,
}: Props) {
    const classes = useStyles({})

    const showPlaceholder = Boolean(properties.length) && (!limit || properties.length < limit)
    const hideSelect = properties.length >= limit

    return (
        <div>
            <Typography gutterBottom>
                <strong>{label}</strong>
            </Typography>

            <PropertyController
                accept={accept}
                hideSelect={hideSelect}
                showPlaceholder={showPlaceholder}
                onDrop={onAdd}
                className={classes.borderedSection}
            >
                {properties.map((p, i) => (
                    <PropertyChip
                        key={i}
                        property={p}
                        accept={accept}
                        showPlaceholder={showPlaceholder}
                        onAdd={field => onAdd(field, i)}
                        onClick={onFieldSelect ? () => onFieldSelect(i) : undefined}
                        onAggregationChange={onAggregationChange ? v => onAggregationChange(i, v) : undefined}
                        onRemove={() => onRemove(i)}
                    />
                ))}
                {children}
            </PropertyController>
        </div>
    )
}
