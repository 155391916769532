import { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Typography } from '@mui/material'

import { VisualObject } from 'genesis-suite/types/visualTypes'
import { getVisualById } from '../../lib/manageUtils'
import { applicationSelectors } from '../../selectors'
import { PublishContext } from '../contexts/PublishContext'
import VisualHistory from '../widgets2/VisualHistory'

export default function ManagePublish() {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const { publish, selectedChange } = useContext(PublishContext)
    const [visual, setVisual] = useState<VisualObject>(null)

    useEffect(() => {
        if (!selectedChange?.id) return

        const { type, id } = selectedChange
        getVisualById(appName, type, id).then(setVisual)
    }, [selectedChange])

    if (!publish || !selectedChange || !visual)
        return (
            <Box flex={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Typography gutterBottom variant="h4">
                    Publish history
                </Typography>
                <Typography variant="h6">
                    {publish !== null
                        ? 'Choose a publish or select a change to review'
                        : 'This cloud has no publish history. Publish drafts from the Approvals tab to see them here.'}
                </Typography>
            </Box>
        )

    return <VisualHistory config={visual} mode="compare-previous" publishId={publish.id} type={selectedChange.type} />
}
