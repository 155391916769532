import React from 'react'
import { connect } from 'react-redux'
import { ContextPanelIcon } from 'genesis-suite/icons'
import { MenuIcon } from 'genesis-suite/components'
import withStyles from '@mui/styles/withStyles'
import { filterSelectors } from '../selectors'
import { filterCreators } from '../actions/creators'
import { ListItemText, Typography, Button } from '@mui/material';
import ContextPanelItem from './ContextPanelItem'
import classNames from 'classnames'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import { makeTemporalFilterDisplayText, parseFilter } from '../lib/utils'

const styles = ({ palette, spacing }) => ({
    filtersHeader: { fontSize: '16px', fontWeight: 'bold' },
    filtersHeight: { maxHeight: '250px', overflowY: 'scroll' },
    filtersPosition: { paddingTop: '18px' },
    noInformationText: { padding: spacing(1), margin: 0 },
    applyButton: {
        float: 'right',
        backgroundColor: palette.secondary.main,
        color: palette.getContrastText(palette.secondary.main),
    },
})

const ContextPanel = ({
    appliedContextFilters,
    appliedInlineFilters,
    appliedPerspectiveFilters,
    disabled,
    iconProps,
    buttonStyle,
    setDeletedFilters,
    addContextFilter,
    deleteContextFilter,
    setInlineFilters,
    historyFilters,
    applyPerspectiveFilter,
    deletedFilters,
    classes,
}) => {
    const [openPopover, setOpenPopover] = React.useState(false)
    // Array will hold all the individual filters that are shown in the panel
    let filterList = []

    // Array will hold all the perspective filters that have to be shown in the panel
    let perspectiveFilterList = []

    let clonedPerspectiveFilters = appliedPerspectiveFilters && JSON.parse(JSON.stringify(appliedPerspectiveFilters))
    clonedPerspectiveFilters &&
        clonedPerspectiveFilters.forEach(perspectiveFilter => {
            let Values = []
            perspectiveFilter.DisplayValues.forEach(value => {
                let newValue = {
                    value: value,
                    isApplied: true,
                }
                Values.push(newValue)
            })
            perspectiveFilter.Values = Values
            perspectiveFilter.isTemporalApplied = true
            perspectiveFilterList.push(perspectiveFilter)
        })
    // Array will hold all the inline filters that have to be shown in the panel
    let inlineFilterList = []

    if (appliedInlineFilters !== null) {
        let clonedInlineFilters = cloneDeep(appliedInlineFilters.filters)

        clonedInlineFilters.forEach(inlineFilter => {
            inlineFilter.Values = inlineFilter.Values.map(value => ({ value, isApplied: true }))
            inlineFilterList.push(inlineFilter)
        })
    }
    // clones deleted filter and add them to their respective filter list
    let clonedDeletedFilters = deletedFilters && JSON.parse(JSON.stringify(deletedFilters))
    clonedDeletedFilters &&
        clonedDeletedFilters.perspectiveFilters &&
        clonedDeletedFilters.perspectiveFilters.length > 0 &&
        clonedDeletedFilters.perspectiveFilters.forEach(perspectiveFilter => {
            if (perspectiveFilter.IsTemporal) {
                perspectiveFilterList.push({ ...perspectiveFilter, isTemporalApplied: false })
            } else {
                let index = perspectiveFilterList.findIndex(
                    appliedFilter => appliedFilter.FilterName === perspectiveFilter.FilterName
                )
                let newValue = {
                    value: perspectiveFilter.Values,
                    isApplied: false,
                }
                if (index < 0) {
                    let displayValue = perspectiveFilter.DisplayValues
                    perspectiveFilter.Values = []
                    perspectiveFilter.Values.push(newValue)
                    perspectiveFilter.DisplayValues = []
                    perspectiveFilter.DisplayValues.push(displayValue)
                    perspectiveFilterList.push(perspectiveFilter)
                } else {
                    perspectiveFilterList[index].Values.push(newValue)
                    perspectiveFilterList[index].DisplayValues.push(perspectiveFilter.DisplayValues)
                }
            }
        })

    clonedDeletedFilters &&
        clonedDeletedFilters.inlineFilters &&
        clonedDeletedFilters.inlineFilters.length > 0 &&
        clonedDeletedFilters.inlineFilters.forEach(inlineFilter => {
            let index = inlineFilterList.findIndex(
                appliedFilter => appliedFilter.FilterName === inlineFilter.FilterName
            )

            let newValue = {
                value: inlineFilter.Values,
                isApplied: false,
            }

            if (index < 0) {
                inlineFilter.Values = []
                inlineFilter.Values.push(newValue)
                inlineFilterList.push(inlineFilter)
            } else inlineFilterList[index].Values.push(newValue)
        })

    // Array will hold applied context filters excluding the focal point
    const contextFilterList =
        appliedContextFilters.Filters?.filter(f => f.ResourceName !== appliedContextFilters.Name) ?? []

    const getFocalPoint = () => {
        const { DisplayFieldName, DisplayValue, FieldName, Value } = appliedContextFilters
        const name = DisplayFieldName || FieldName
        const value = DisplayValue || Value
        if (!name || !value) return null
        return (
            <div>
                <br />
                <Typography className={classes.filtersHeader}> Focal Point</Typography>
                <ListItemText>{`${name}: ${value}`}</ListItemText>
            </div>
        )
    }

    const getPerspectiveFilters = () => {
        return (
            <div className={classes.filtersPosition}>
                <div>
                    <Typography className={classes.filtersHeader}>Perspective Filters</Typography>
                </div>
                <div className={classes.filtersHeight}>
                    {perspectiveFilterList.map((perspectiveFilter, index) => (
                        <ListItemText key={index}>
                            {perspectiveFilter.PropertyName + ':'}
                            <ContextPanelItems filter={perspectiveFilter} />
                        </ListItemText>
                    ))}
                </div>
            </div>
        )
    }
    const getInlineFilters = () => {
        // will bring filters with same property name under the same heading
        const organizedInlineFilters = {}

        inlineFilterList.forEach(inlineFilter => {
            if (!(inlineFilter.PropertyName in organizedInlineFilters)) {
                organizedInlineFilters[inlineFilter.PropertyName] = []
            }

            organizedInlineFilters[inlineFilter.PropertyName].push(inlineFilter)
        })

        return (
            <div className={classes.filtersPosition}>
                <div>
                    <Typography className={classes.filtersHeader}>Inline Filters</Typography>
                </div>
                <div className={classes.filtersHeight}>
                    {Object.keys(organizedInlineFilters).map((propertyName, index) => (
                        <ListItemText key={index}>
                            {propertyName + ':'}
                            {organizedInlineFilters[propertyName].map((inlineFilter, inlineFilterIndex) => (
                                <ContextPanelItems key={inlineFilterIndex} filter={inlineFilter} isInline />
                            ))}
                        </ListItemText>
                    ))}
                </div>
            </div>
        )
    }

    const getContextFilters = () => {
        return (
            <div className={classes.filtersPosition}>
                <Typography className={classes.filtersHeader}>Context Filters</Typography>
                <div className={classes.filtersHeight}>
                    {contextFilterList &&
                        contextFilterList.map((contextFilter, index) => (
                            <ListItemText key={index}>
                                {contextFilter.PropertyName + ':'}
                                <ContextPanelItems filter={contextFilter} isContext isAppliedOverride />
                            </ListItemText>
                        ))}
                    {deletedFilters.contextFilters &&
                        deletedFilters.contextFilters.map((contextFilter, index) => (
                            <ListItemText key={index}>
                                {contextFilter.PropertyName + ':'}
                                <ContextPanelItems filter={contextFilter} isContext />
                            </ListItemText>
                        ))}
                </div>
            </div>
        )
    }

    const toggleFilterDelete = filterEntry => {
        filterEntry.shouldDelete = !filterEntry.shouldDelete
    }

    // Store each individual filter in filterList
    const ContextPanelItems = ({ filter, isContext = false, isAppliedOverride = false, isInline = false }) => {
        const { Values, isTemporalApplied, IsTemporal } = filter

        const getValues = () => {
            if (!Values) return null

            return Values.map(filterValue => {
                if (!filterValue) return null
                const _filter = JSON.parse(JSON.stringify(filter))

                const isAppliedFilter = isContext ? isAppliedOverride : filterValue.isApplied

                let value = filterValue
                if (!isContext) {
                    value = filterValue.value
                    let index = _filter.Values.findIndex(v => v.value === value)
                    _filter.Values = _filter.Values[index].value

                    if (_filter.DisplayValues) _filter.DisplayValues = _filter.DisplayValues[index]
                }

                let filterEntry = {
                    filter: _filter,
                    isContext,
                    shouldDelete: !isAppliedFilter,
                    isAppliedFilter,
                    isInline,
                }

                filterList.push(filterEntry)

                return (
                    <ContextPanelItem
                        key={value}
                        label={value}
                        isDeletedFilter={!isAppliedFilter}
                        toggleFilterDelete={toggleFilterDelete.bind(this, filterList[filterList.length - 1])}
                    />
                )
            })
        }

        const getTemporalFilter = () => {
            const isAppliedFilter = isContext ? isAppliedOverride : isTemporalApplied

            let filterEntry = {
                filter,
                isContext,
                shouldDelete: !isAppliedFilter,
                isAppliedFilter,
                isInline,
            }

            filterList.push(filterEntry)

            return (
                <ContextPanelItem
                    label={makeTemporalFilterDisplayText(parseFilter(filter))}
                    isDeletedFilter={!isAppliedFilter}
                    toggleFilterDelete={toggleFilterDelete.bind(this, filterList[filterList.length - 1])}
                />
            )
        }

        return <ListItemText>{IsTemporal ? getTemporalFilter() : getValues()}</ListItemText>
    }

    const hasFocalPoint = Boolean(appliedContextFilters)
    const hasPerspectiveFilters = perspectiveFilterList.length > 0
    const hasInlineFilters =
        inlineFilterList.length > 0 || (deletedFilters.inlineFilters && deletedFilters.inlineFilters.length > 0)
    const hasContextFilters =
        contextFilterList.length > 0 || (deletedFilters.contextFilters && deletedFilters.contextFilters.length > 0)

    const getFiltersContent = () => {
        return (
            <div>
                {!(hasFocalPoint || hasContextFilters || hasPerspectiveFilters || hasInlineFilters) && (
                    <h2 className={classes.noInformationText}>No information to display</h2>
                )}
                {hasFocalPoint && getFocalPoint()}
                {hasContextFilters && getContextFilters()}
                {hasPerspectiveFilters && getPerspectiveFilters()}
                {hasInlineFilters && getInlineFilters()}
            </div>
        )
    }

    const handleApply = () => {
        // Close the popover on click of apply button
        setOpenPopover(false)

        // Stores the filters that have not been applied on the perspective
        let updatedDeletedFilters = {
            contextFilters: [],
            perspectiveFilters: [],
            inlineFilters: [],
        }
        // Stores context filters that have to be added and deleted
        let contextFilters = {
            add: [],
            delete: [],
        }
        // Stores perspective filters that have to be added and deleted
        let perspectiveFilters = {
            add: [],
            delete: [],
        }
        // Stores inline filters that have to be added or deleted
        let inlineFilters = {
            add: [],
            delete: [],
        }

        filterList.forEach(filterItem => {
            // Filters that have been applied and have to be deleted
            if (filterItem.isAppliedFilter && filterItem.shouldDelete) {
                if (filterItem.isContext) contextFilters.delete.push(filterItem.filter)
                else if (filterItem.isInline) inlineFilters.delete.push(filterItem.filter)
                else perspectiveFilters.delete.push(filterItem.filter)
            }

            // Filters that have been deleted but have to be reapplied
            if (!filterItem.isAppliedFilter && !filterItem.shouldDelete) {
                if (filterItem.isContext) contextFilters.add.push(filterItem.filter)
                else if (filterItem.isInline) inlineFilters.add.push(filterItem.filter)
                else perspectiveFilters.add.push(filterItem.filter)
            }

            // Stores deleted filters
            if (filterItem.shouldDelete) {
                if (filterItem.isContext) updatedDeletedFilters.contextFilters.push(filterItem.filter)
                else if (filterItem.isInline) updatedDeletedFilters.inlineFilters.push(filterItem.filter)
                else updatedDeletedFilters.perspectiveFilters.push(filterItem.filter)
            }
        })

        // Dispatch the action to add the deleted filters to redux store
        setDeletedFilters(updatedDeletedFilters)

        let clonedHistoryFilters = JSON.parse(JSON.stringify(historyFilters))

        perspectiveFilters.add.forEach(perspectiveFilter => {
            const { FilterName, IsTemporal, Values, DisplayValues, Range, RangeOffset, UseLastRefreshDate } =
                perspectiveFilter

            if (IsTemporal) {
                if (!isEmpty(Range)) {
                    const { MinValue, MaxValue } = Range
                    clonedHistoryFilters[FilterName].range = { min: MinValue, max: MaxValue }
                } else if (RangeOffset) {
                    clonedHistoryFilters[FilterName] = {
                        rangeOffset: { min: RangeOffset.Min, max: RangeOffset.Max },
                        useLastRefreshDate: UseLastRefreshDate,
                    }
                }
            } else {
                if (clonedHistoryFilters[FilterName].values == null) clonedHistoryFilters[FilterName].values = []
                let newFilter = {
                    label: DisplayValues,
                    value: Values,
                }
                clonedHistoryFilters[FilterName].values.push(newFilter)
            }
        })

        perspectiveFilters.delete.forEach(perspectiveFilter => {
            if (perspectiveFilter.IsTemporal) {
                clonedHistoryFilters[perspectiveFilter.FilterName] = {}
            } else {
                let index = clonedHistoryFilters[perspectiveFilter.FilterName].values.findIndex(
                    value => value.value === perspectiveFilter.Values
                )
                clonedHistoryFilters[perspectiveFilter.FilterName].values.splice(index, 1)
                if (clonedHistoryFilters[perspectiveFilter.FilterName].values.length === 0)
                    clonedHistoryFilters[perspectiveFilter.FilterName] = {}
            }
        })
        // Dispatch action to apply updated perspective filters
        applyPerspectiveFilter(clonedHistoryFilters)

        // Dispatch actions to add or delete inline filters
        let backupAppliedInlineFilters = {}

        if (appliedInlineFilters !== null) {
            backupAppliedInlineFilters = cloneDeep(appliedInlineFilters)
            //  non deleted inline filters that are not present in backupAppliedInlineFilters
            let nonDeletedInlineFilters = backupAppliedInlineFilters.filters.filter(
                filter =>
                    inlineFilters.delete.findIndex(
                        deletedInlineFilter =>
                            deletedInlineFilter.FilterName === filter.FilterName &&
                            deletedInlineFilter.WidgetId === filter.WidgetId
                    ) < 0
            )
            //  adding inline filters that are not present in backupAppliedInlineFilters
            let addedInlineFilters = inlineFilters.add
                .map(filter => {
                    if (Array.isArray(filter.Values)) return filter

                    return { ...filter, Values: [filter.Values] }
                })
                .filter(
                    filter =>
                        backupAppliedInlineFilters.filters.findIndex(
                            backupFilter =>
                                backupFilter.FilterName === filter.FilterName &&
                                backupFilter.WidgetId === filter.WidgetId
                        ) < 0
                )

            backupAppliedInlineFilters.filters = [...addedInlineFilters, ...nonDeletedInlineFilters]

            if (perspectiveFilters.add.length > 0 || perspectiveFilters.delete.length > 0)
                backupAppliedInlineFilters.filters = []
            // Dispatch actions to add or delete inline filters
            setInlineFilters(backupAppliedInlineFilters)
        }

        // Dispatch actions to add or delete context filters
        if (contextFilters.delete.length > 0) deleteContextFilter(contextFilters.delete)
        if (contextFilters.add.length > 0) addContextFilter(contextFilters.add)
    }

    return (
        <MenuIcon
            icon={<ContextPanelIcon {...iconProps} />}
            disabled={disabled}
            buttonProps={{ sx: buttonStyle }}
            open={openPopover}
            onClick={() => setOpenPopover(true)}
            onClose={() => setOpenPopover(false)}
            title="Context"
            tooltip="Context"
            popoverProps={{ PaperProps: { sx: { minWidth: '295px', maxWidth: '320px', maxHeight: '750px' } } }}
        >
            {getFiltersContent()}

            {(hasContextFilters || hasPerspectiveFilters || hasInlineFilters) && (
                <Button
                    className={classNames(classes.buttons, classes.applyButton)}
                    onClick={handleApply}
                    variant="contained"
                >
                    Apply
                </Button>
            )}
        </MenuIcon>
    )
}

const mapStateToProps = state => ({
    appliedContextFilters: filterSelectors.getCoord(state),
    appliedInlineFilters: filterSelectors.getInlineFilters(state),
    appliedPerspectiveFilters: filterSelectors.currentPerspectiveFiltersConfiguration(state),
    historyFilters: filterSelectors.getAppliedPerspectiveFilters(state),
    deletedFilters: filterSelectors.getDeletedFilters(state),
})

const mapDispatchToProps = dispatch => ({
    addContextFilter: addedContextFilters => {
        dispatch(filterCreators.addContextFilter(addedContextFilters))
    },
    deleteContextFilter: deletedContextFilters => {
        dispatch(filterCreators.deleteContextFilter(deletedContextFilters))
    },
    setInlineFilters: inlineFilters => {
        dispatch(filterCreators.setInlineFilters(inlineFilters))
    },
    setDeletedFilters: deletedFilters => {
        dispatch(filterCreators.setDeletedFilters(deletedFilters))
    },
    applyPerspectiveFilter: perspectiveFilters => {
        dispatch(filterCreators.applyPerspectiveFilters(perspectiveFilters, false))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(ContextPanel))
