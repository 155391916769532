import {
    BaseDataRequest,
    ChartType,
    DataResponse,
    NodeFilterWithValue,
    Pagination,
    Widget,
} from 'genesis-suite/types/visualTypes'
import { isEqual } from 'lodash'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { stagingWidgetService, widgetService } from '../../../lib/services'
import { deploymentSelectors } from '../../../selectors'
import { DeploymentView } from '../../../types/DeploymentTypes'
import { ParsedResponse, ParsedResponseWithConfig, WidgetRenderProps, WidgetStatus } from '../../../types/WidgetTypes'
import checkShouldPaginate from './checkShouldPaginate'
import { tablePageSize } from './configDefaults'
import makeBaseDataRequest from './makeBaseDataRequest'
import makeDataRequest from './makeDataRequest'
import makeTransformedData from './makeTransformedData'
import parseResponse from './parseResponse'
import skipData from './skipData'

export interface Props {
    /** Don't use this! Only Widget2 should use this if it's controlled */
    controlled?: WidgetRenderProps
    config: Widget
    deploymentView?: DeploymentView
    nodeFilters?: Array<NodeFilterWithValue>
    onData?: (response: ParsedResponse) => void
    onStatus?: (status: WidgetStatus) => void
}

interface Inputs {
    baseDataRequest: BaseDataRequest
    nodeFilters: NodeFilterWithValue[]
    page: Pagination
}

export function useWidgetData(props: Props): WidgetRenderProps {
    const { controlled, config, nodeFilters, deploymentView, onData, onStatus } = props
    const appName = useSelector(deploymentSelectors.getDeploymentAppName)

    const [status, setStatus] = useState<WidgetStatus>('idle')
    const [page, setPage] = usePagination(config)
    const [responseWithConfig, setResponseWithConfig] = useState<ParsedResponseWithConfig>({ config, data: undefined })
    const [refreshToggle, setRefreshToggle] = useState(false)

    const lastRefreshToggle = useRef(false)
    const lastInputs = useRef<Inputs>(null)
    const lastResponse = useRef<ParsedResponseWithConfig>(null)
    const lastDeploymentView = useRef<DeploymentView>(deploymentView)
    const cancelCall = useRef(null)
    const isEmpty = useRef(true)

    const isControlled = controlled !== undefined
    const currentConfig = responseWithConfig?.config
    const currentData = responseWithConfig?.data

    function refresh() {
        setRefreshToggle(s => !s)
    }

    function updatePage(update: Partial<Pagination>) {
        setPage(s => ({ ...s, update }))
    }

    function updateStatus(status: WidgetStatus) {
        setStatus(status)
        onStatus?.(status)
    }

    const filterOptions = (inputs: Inputs) => {
        if (!inputs) return

        const { baseDataRequest, ...rest } = inputs
        return { widgetFilters: baseDataRequest?.filter, ...rest }
    }
    
    useEffect(() => {
        if (isControlled) return

        if (skipData(config)) {
            handleConfigAndData(config, [])
            lastInputs.current = null
            return
        }

        const stagingAppName = appName
        const requestConfig = {
            ...config,
            ...(deploymentView === DeploymentView.STAGING && { appName: stagingAppName }),
        }
        const baseDataRequest = makeBaseDataRequest(requestConfig)
        const inputs = { baseDataRequest, nodeFilters, page }

        if (
            status === 'idle' &&
            refreshToggle === lastRefreshToggle.current &&
            lastDeploymentView.current === deploymentView
        ) {
            if (isEqual(inputs, lastInputs.current)) {
                if (baseDataRequest && currentData) handleConfigAndData(config, currentData)
                return
            }

            const onlyConfigChanged = isEqual(filterOptions(inputs), filterOptions(lastInputs.current))
            if (onlyConfigChanged) {
                const transformedData = makeTransformedData(currentConfig, currentData, config)
                if (transformedData) {
                    lastInputs.current = inputs
                    handleConfigAndData(config, transformedData)
                    return
                }
            }
        }

        const pagination = checkShouldPaginate(config) ? page : undefined
        const dataRequest = makeDataRequest(baseDataRequest, config.id, nodeFilters, pagination)

        if (!dataRequest) {
            lastInputs.current = inputs
            if (config || currentData) handleConfigAndData(undefined, undefined)
            isEmpty.current = true
            if (status === 'loading') cancelCall.current?.()
            if (status !== 'idle') updateStatus('idle')
            return
        }

        isEmpty.current = false
        lastRefreshToggle.current = refreshToggle
        lastDeploymentView.current = deploymentView

        if (status === 'loading') cancelCall.current?.()
        else updateStatus('loading')

        const service = deploymentView === DeploymentView.STAGING ? stagingWidgetService : widgetService

        service
            .getData2(dataRequest, cancel => (cancelCall.current = cancel))
            .then(res => {
                lastInputs.current = inputs
                handleConfigAndData(config, res)
                updateStatus('idle')
            })
            .catch(error => {
                if (error.__CANCEL__) return updateStatus(isEmpty.current ? 'idle' : 'loading')

                console.error(error)
                lastInputs.current = inputs
                updateStatus(error.status === 403 ? 'unauthorized' : 'error')
            })
    }, [config, nodeFilters, page, refreshToggle, deploymentView, appName])

    function handleConfigAndData(config: Widget, response: DataResponse) {
        const parsed = parseResponse(response, config)
        const withConfig = { ...parsed, config }
        setResponseWithConfig(withConfig)

        if (onData && parsed && parsed.data?.length > 0 && !isEqual(withConfig, lastResponse.current)) onData(parsed)
        lastResponse.current = withConfig
    }

    if (isControlled) return controlled

    return { ...responseWithConfig, refresh, status, page, updatePage }
}

export function usePagination(config: Widget): [Pagination, Dispatch<SetStateAction<Pagination>>] {
    const [page, setPage] = useState<Pagination>(() => initPagination(config))

    const lastSize = useRef(null)
    useEffect(() => {
        if (config.type !== ChartType.TABLE) return

        const size = config.series[0]?.pageSize ?? tablePageSize
        if (lastSize.current === size) return

        lastSize.current = size
        if (page.size !== size) setPage(s => ({ ...s, size }))
    }, [config])

    return [page, setPage]
}

function initPagination(config: Widget) {
    if (config.type !== ChartType.TABLE) return { number: 1, size: tablePageSize }
    return { number: 1, size: config.series[0]?.pageSize ?? tablePageSize }
}
