import { Dialog, Paper, Slide } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { FieldVisibility, FormConfig, FormData, FormPrompt, InputFormConfig } from 'genesis-suite/types/visualTypes'
import { useIsMobile } from '../../../hooks/useIsMobile'
import { getIndexProperties, getallPrompts } from '../../../lib/formUtils'
import { toInput } from './formConverter'
import InputForm, { InputFormProps } from './InputForm'
import { useContext, useMemo } from 'react'
import { InteractionContext } from '~/components/widgets/InteractionContext'

const useStyles = makeStyles(({ spacing }) => ({
    formEditRoot: {
        right: 0,
        display: 'flex',
        overflow: 'auto',
        padding: spacing(0),
        position: 'absolute',
        width: 'max(30%, 325px)',
        flexFlow: 'column nowrap',
        height: 'calc(100% - 40px)',
        zIndex: 3,
    },
    mobileInputDialog: { padding: spacing() },
}))

const getInputConfig = (config: FormConfig): InputFormConfig => {
    if ('customInputForm' in config && config.customInputForm) return config.customInputForm
    else return toInput(config)
}

const isFormEditable = (prompt: FormPrompt) =>
    (prompt.visibility === FieldVisibility.FORM || prompt.visibility === FieldVisibility.SHOW) && prompt.editable

interface SlideoutFormProps {
    data: FormData[]
    config: FormConfig
    onClose: () => void
    defaults: FormData
    onSubmit: (data: FormData[]) => Promise<any>
}

export default function SlideoutForm({ data, config, onSubmit, onClose, defaults }: SlideoutFormProps) {
    const classes = useStyles()
    const isMobile = useIsMobile()
    const { appMode } = useContext(InteractionContext)
    
    const { inputConfig, multiEditPropertyNames, primaryPropertyNames } = useMemo(() => {
        const inputConfig = getInputConfig(config)
        const multiEditPropertyNames = getallPrompts(inputConfig)
            .filter(prompt => isFormEditable(prompt) && !prompt.property?.isPrimary)
            .map(prompt => prompt.property.name)
        const primaryPropertyNames = getIndexProperties(config).map(p => p.name)
        return { inputConfig, multiEditPropertyNames, primaryPropertyNames }
    }, [config])

    if (!data || !data.length) return null
    const { isNew } = data[0]
    const multiEdit = !isNew && data.length > 1
    const title = isNew ? 'Add Row' : multiEdit ? 'Edit Selected Rows' : 'Edit Row'

    const submitMulti = (rows: FormData[]) => {
        const nonPrimaryData = Object.entries(rows[0]).reduce((acc, [k, v]) => {
            if (multiEditPropertyNames.includes(k)) acc[k] = v
            return acc
        }, {})
        const formatted = data.reduce((acc, item) => {
            acc.push({ ...item, ...nonPrimaryData })
            return acc
        }, [])
        return onSubmit(formatted)
    }

    const createRecord = () => {
        const keys = Object.keys(data[0])
        return keys.reduce((acc, key) => {
            const uniqueVals = [...new Set(data.map(item => item[key]))].filter(v => v === 0 || v)
            if (primaryPropertyNames.includes(key)) {
                acc[key] = uniqueVals
            } else {
                acc[key] = uniqueVals.length === 1 ? uniqueVals[0] : null
            }
            return acc
        }, {})
    }
    // massage data into single record
    const record = multiEdit ? createRecord() : data[0]

    const formProps: InputFormProps = {
        title,
        onClose,
        defaults,
        multiEdit,
        data: record,
        config: inputConfig,
        onSave: multiEdit ? submitMulti : onSubmit,
    }
    return (
        <>
            {isMobile ? (
                <Dialog
                    fullScreen
                    open={Boolean(record)}
                    TransitionComponent={Slide}
                    classes={{ paper: classes.mobileInputDialog }}
                >
                    <InputForm {...formProps} />
                </Dialog>
            ) : (
                <Slide direction="left" in={Boolean(record)} mountOnEnter unmountOnExit>
                    <Paper
                        elevation={4}
                        className={classes.formEditRoot}
                        style={appMode === 'presentation' ? { bottom: 0 } : { top: 0 }}
                    >
                        <InputForm {...formProps} />
                    </Paper>
                </Slide>
            )}
        </>
    )
}
