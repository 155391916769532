import React from 'react'
import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Spinner from 'genesis-suite/components/Spinner'

const useStyles = makeStyles({
    loaderContainer: { height: '100px', position: 'relative' },
})

export default function LoadingDialog({ text }) {
    return (
        <div>
            <Typography variant="h6">{text}</Typography>

            <div className={useStyles().loaderContainer}>
                <Spinner />
            </div>
        </div>
    )
}
