import { backupTypes } from '../types'

const updateSelectedSchedules = payload => dispatch => {
    dispatch({ type: backupTypes.SELECTED_BACKUP, payload: payload })
}

const removeSelectedSchedules = () => dispatch => {
    dispatch({ type: backupTypes.SELECTED_BACKUP, payload: [] })
}

export const backupCreators = {
    updateSelectedSchedules,
    removeSelectedSchedules,
}
