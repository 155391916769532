import NewIcon from '@mui/icons-material/AddRounded'
import EditIcon from '@mui/icons-material/EditOutlined'
import {
    Box,
    Button,
    Checkbox,
    Collapse,
    FormControlLabel,
    IconButton,
    ListItem,
    MenuItem,
    Slider,
    TextField,
    Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import produce from 'immer'
import { round } from 'lodash'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import {
    Collapsable,
    DebouncedTextField, DragItem, DragList, DropZone, EditableLabel, FILE_TYPES
} from 'genesis-suite/components'
import { Menu, Trash } from 'genesis-suite/icons'
import { ResourceType } from 'genesis-suite/types/networkTypes'
import { MapConfig, MapImageOverlay, MapSeries, MapSurface } from 'genesis-suite/types/visualTypes'
import { sleep } from 'genesis-suite/utils'
import { ConfigContext } from '..'
import { moduleService, uploadService } from '../../../lib/services'
import { applicationSelectors } from '../../../selectors'
import BorderedSection from '../../BorderedSection'
import { initializePosition, useGeoData } from '../../widgets2/map/mapUtils'
import EditorWrapper from '../config_fields/EditorWrapper'

const useStyles = makeStyles(({ spacing, palette, border }) => ({
    listItem: { padding: spacing(0.5, 1), gridGap: spacing(), backgroundColor: '#fff' },
    buttonSelected: {
        backgroundColor: `${palette.secondary.light} !important`,
        color: palette.primary.contrastText,
    },
    overlays: { border: '2px transparent', borderRadius: border.radius.round, padding: spacing(1, 0) },
    overlay: { background: '#fff', borderRadius: border.radius.round, border: border.default, padding: spacing(0.5) },
    overlayCollapseHeader: { padding: 0 },
    overlayImageContainer: { display: 'flex', cursor: 'pointer' },
    overlayImage: { height: '100%' },
    overlayImageEditIcon: { position: 'absolute', right: '-10px', top: '-10px' },
    dragIcon: { fontSize: '18px', color: 'inherit', cursor: 'grab' },
    deleteIcon: { fontSize: '18px', color: 'inherit' },
}))

export default function MapSelector() {
    const { dispatch, resources, selectedField, ...rest } = useContext(ConfigContext)
    const config = rest.config as MapConfig
    const { surface, zoom } = config
    const classes = useStyles()

    const [draftZoom, setDraftZoom] = useState(0)
    useEffect(() => {
        if (zoom && zoom !== draftZoom) setDraftZoom(zoom)
    }, [zoom])

    function handleZoomChange(zoom: number) {
        dispatch({ type: 'UPDATE_CONFIG', payload: { zoom } })
        if (zoom == null) setDraftZoom(0)
    }

    return (
        <>
            <ManageSeriesType classes={classes} type="marker" title="Markers" />
            {/* <ManageSeriesType type="connection" title="Connections" /> */}
            <ManageSeriesType classes={classes} type="heat" title="Regions" />

            <Box m={1} />

            <TextField
                select
                margin="dense"
                variant="outlined"
                label="Surface"
                value={surface ?? MapSurface.ROADMAP}
                onChange={e => dispatch({ type: 'UPDATE_CONFIG', payload: { surface: e.target.value as MapSurface } })}
            >
                {surfaceOptions.map(o => (
                    <MenuItem key={o.value} value={o.value}>
                        {o.label}
                    </MenuItem>
                ))}
            </TextField>

            <FormControlLabel
                label="Zoom override"
                control={
                    <Checkbox
                        checked={zoom != null}
                        onChange={e => handleZoomChange(e.target.checked ? 3 : undefined)}
                    />
                }
            />

            <Collapse in={zoom != null}>
                <Box mx={2}>
                    <Slider
                        step={1}
                        min={0}
                        max={20}
                        value={draftZoom}
                        onChange={(e, val) => setDraftZoom(val as number)}
                        onChangeCommitted={(e, val) => handleZoomChange(val as number)}
                    />
                </Box>
            </Collapse>

            <FormControlLabel
                control={
                    <Checkbox
                        checked={config.legend?.enabled ?? true}
                        onChange={e =>
                            dispatch({ type: 'UPDATE_LEGEND', payload: { enabled: e.target.checked as boolean } })
                        }
                    />
                }
                label="Legend"
            />

            <ManageOverlays classes={classes} />
        </>
    )
}

type SeriesType = 'marker' | 'heat' | 'connection'

function ManageSeriesType({ classes, type, title }: { classes: any; type: SeriesType; title: string }) {
    const { dispatch, resources, selectedField, ...rest } = useContext(ConfigContext)
    const config = rest.config as MapConfig
    const allSeries = config.series
    const focusSeries = allSeries.filter(s => s.type === type)
    const indexes = getIndexesForType(allSeries, type)
    const selectedType = selectedField && selectedField.index >= indexes.first && selectedField.index < indexes.next

    function handleDragEnd(seriesWithId) {
        const series = produce(allSeries, draft => {
            draft.splice(indexes.first, seriesWithId.length, ...seriesWithId.map(({ id, ...s }) => s))
        })
        dispatch({ type: 'UPDATE_CONFIG', payload: { series } })
    }

    async function handleAdd() {
        const resource = resources?.byId[resources.selectedId]
        const service = { type: ResourceType.INSIGHT, id: resource.id, name: resource.name }
        const targetIndex = indexes.next
        const series = produce(allSeries, draft => {
            if (targetIndex > draft.length) draft.push({ type, service, values: [] })
            else draft.splice(targetIndex, 0, { type, service, values: [] })
        })
        dispatch({ type: 'UPDATE_CONFIG', payload: { series } })
        await sleep()
        dispatch({ type: 'SET_SELECTED_FIELD', payload: { type: 'series', index: targetIndex } })
    }

    function handleSelect(index) {
        dispatch({
            type: 'SET_SELECTED_FIELD',
            payload: { type: 'series', index: indexes.first + index },
        })
    }

    function makeItemContent(index, title, draggable) {
        const seriesIndex = indexes.first + index
        return (
            <ItemContent
                draggable={draggable}
                classes={classes}
                title={title || `Series ${seriesIndex + 1}`}
                selected={selectedType && selectedField.index - indexes.first === index}
                onTitleChange={title => dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: { title } })}
                onSelect={() => handleSelect(index)}
                onRemove={() => dispatch({ type: 'REMOVE_SERIES', payload: seriesIndex })}
            />
        )
    }

    return (
        <BorderedSection header={title}>
            {focusSeries?.length > 0 && (
                <>
                    {focusSeries?.length > 1 ? (
                        <DragList onDragEnd={handleDragEnd} items={focusSeries.map((s, i) => ({ ...s, id: i }))}>
                            {focusSeries.map((series, index) => (
                                <DragItem key={index} itemId={index} index={index}>
                                    {makeItemContent(index, series.title, true)}
                                </DragItem>
                            ))}
                        </DragList>
                    ) : (
                        makeItemContent(0, focusSeries[0].title, false)
                    )}

                    <Box mt={1} />
                </>
            )}

            <NewButton onClick={handleAdd} />
        </BorderedSection>
    )
}

/** Return the first index where the series type starts and the next index where to add a new series of type. Order of series are marker, then heat, then connection */
function getIndexesForType(allSeries: MapSeries[], type: SeriesType) {
    const typeArray = allSeries.map(s => s.type)
    let first = typeArray.indexOf(type)
    let next = typeArray.lastIndexOf(type) + 1

    if (first === -1) {
        const lastMarkerIndex = typeArray.lastIndexOf('marker')
        const lastHeatIndex = typeArray.lastIndexOf('heat')

        switch (type) {
            case 'marker':
                first = 0
                break
            case 'heat':
                first = lastMarkerIndex > -1 ? lastMarkerIndex + 1 : 0
                break
            case 'connection':
                first = lastHeatIndex ? lastHeatIndex + 1 : lastMarkerIndex ? lastMarkerIndex + 1 : 0
                break
        }

        next = first
    }

    return { first, next }
}

function ItemContent({ draggable = false, classes, title, selected, onSelect, onTitleChange, onRemove }) {
    return (
        <ListItem
            button
            classes={{ root: classes.listItem, selected: classes.buttonSelected }}
            selected={selected}
            onClick={onSelect}
        >
            <Box flex="1" display="flex" alignItems="center" gap="8px">
                {draggable && <Menu className={classes.dragIcon} />}
                <EditableLabel
                    hideFinishButtons
                    editMethod="click-text"
                    disableEdit={!selected}
                    value={title}
                    onChange={onTitleChange}
                />
            </Box>

            <IconButton
                color="inherit"
                size="small"
                onClick={e => {
                    e.stopPropagation()
                    onRemove(e)
                }}
            >
                <Trash className={classes.deleteIcon} />
            </IconButton>
        </ListItem>
    )
}

const surfaceOptions: Array<{ value: MapSurface; label: string }> = [
    { value: MapSurface.ROADMAP, label: 'Road' },
    { value: MapSurface.SATELLITE, label: 'Satellite' },
    { value: MapSurface.HYBRID, label: 'Hybrid' },
    { value: MapSurface.TERRAIN, label: 'Terrain' },
]

function NewButton(props) {
    return (
        <Button
            size="small"
            variant="outlined"
            startIcon={<NewIcon fontSize="small" style={{ color: 'inherit' }} />}
            {...props}
        >
            New
        </Button>
    )
}

function ManageOverlays({ classes }) {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const { dispatch, dataResponse, ...rest } = useContext(ConfigContext)
    const { overlays = [], series } = rest.config as MapConfig

    const geoData = useGeoData(series, dataResponse?.data)
    const dataPosition = useMemo(() => initializePosition(geoData), [geoData])

    const [openOverlayIndex, setOpenOverlayIndex] = useState(null)
    const [newFile, setNewFile] = useState<{ preview: string; file: File; overlay: MapImageOverlay }>(null)

    function handleNewImage(accepted: File[]) {
        const file = accepted[0]
        const preview = URL.createObjectURL(file)

        let placement = { top: 0, bottom: 0, left: 0, right: 0 }
        if (dataPosition) {
            const { bounds, center } = dataPosition
            placement = {
                top: round(center.lat + (bounds.north - center.lat) / 4, 3),
                bottom: round(center.lat + (bounds.south - center.lat) / 4, 3),
                left: round(center.lng + (bounds.west - center.lng) / 4, 3),
                right: round(center.lng + (bounds.east - center.lng) / 4, 3),
            }
        }

        const overlay = { urlString: '', placement, ...newFile?.overlay }
        setNewFile({ file, preview, overlay })
        setOpenOverlayIndex(null)
    }

    async function handleSaveNewFile() {
        const { file, overlay } = newFile
        const urlString = await uploadFile(file)
        updateOverlays([...overlays, { ...overlay, urlString }])
        setNewFile(null)
    }

    async function handleUpdateFile(index: number, file: File) {
        const urlString = await uploadFile(file)
        updateOverlays(overlays.map((o, i) => (i === index ? { ...o, urlString } : o)))
    }

    async function uploadFile(file: File): Promise<string> {
        const fileData = new FormData()
        fileData.append('file', file)
        const fileName = file.name.substring(0, file.name.lastIndexOf('.'))
        try {
            return await uploadService.uploadFile(fileData, fileName, appName, false)
        } catch (err) {
            console.error(err)
        }
    }

    function handleUpdate(index: number, update: Partial<MapImageOverlay>) {
        updateOverlays(overlays.map((o, i) => (i === index ? { ...o, ...update } : o)))
    }
    function handleRemove(index: number) {
        updateOverlays(overlays.filter((_, i) => i !== index))
        setOpenOverlayIndex(null)
    }
    function handleDragEnd(overlayWithId) {
        updateOverlays(overlayWithId.map(({ id, ...o }) => o))
    }
    function updateOverlays(overlays: MapConfig['overlays']) {
        dispatch({ type: 'UPDATE_CONFIG', payload: { overlays } })
    }

    return (
        <EditorWrapper header="Image overlays">
            {overlays.length > 1 ? (
                <DragList onDragEnd={handleDragEnd} items={overlays.map((s, i) => ({ ...s, id: i }))}>
                    {overlays.map((overlay, index) => (
                        <DragItem key={index} itemId={index} index={index}>
                            <OverlayItem
                                classes={classes}
                                draggable
                                overlay={{ ...overlay, title: overlay.title || `Overlay ${index + 1}` }}
                                open={openOverlayIndex === index}
                                onUpdate={v => handleUpdate(index, v)}
                                onUpdateFile={file => handleUpdateFile(index, file)}
                                onToggle={() => setOpenOverlayIndex(s => (s != null && s === index ? null : index))}
                                onRemove={() => handleRemove(index)}
                            />
                        </DragItem>
                    ))}
                </DragList>
            ) : overlays.length ? (
                <OverlayItem
                    classes={classes}
                    overlay={{ ...overlays[0], title: overlays[0].title || 'Overlay 1' }}
                    open={openOverlayIndex === 0}
                    onUpdate={v => handleUpdate(0, v)}
                    onUpdateFile={file => handleUpdateFile(0, file)}
                    onToggle={() => setOpenOverlayIndex(s => (s == null ? 0 : null))}
                    onRemove={() => handleRemove(0)}
                />
            ) : null}

            <DropZone
                noClick
                className={classes.overlays}
                onDrop={handleNewImage}
                accept={[FILE_TYPES.JPEG, FILE_TYPES.JPG, FILE_TYPES.PNG]}
            >
                {open =>
                    newFile ? (
                        <div>
                            <Typography variant="caption">New overlay:</Typography>
                            <OverlayImage classes={classes} src={newFile.preview} onClick={open} />

                            <Box mt={1} display="flex" justifyContent="flex-end" gap="8px">
                                <Button onClick={() => setNewFile(null)}>Cancel</Button>
                                <Button variant="contained" color="primary" onClick={handleSaveNewFile}>
                                    Save
                                </Button>
                            </Box>
                        </div>
                    ) : (
                        <Box display="flex" alignItems="center" gap="8px">
                            <NewButton onClick={open} />
                            <Typography variant="body2">Drag image here</Typography>
                        </Box>
                    )
                }
            </DropZone>
        </EditorWrapper>
    )
}

interface OverlayItemProps {
    classes: any
    draggable?: boolean
    overlay: MapImageOverlay
    onUpdate: (overlay: Partial<MapImageOverlay>) => void
    onUpdateFile: (file: File) => void
    open: boolean
    onToggle?: () => void
    onRemove?: () => void
}

function OverlayItem({
    classes,
    draggable,
    overlay,
    onUpdate,
    onUpdateFile,
    open,
    onToggle,
    onRemove,
}: OverlayItemProps) {
    const { title, urlString, placement } = overlay
    const [imgSrc, setImgSrc] = useState('')

    useEffect(() => {
        if (!urlString) return

        moduleService.getFiles(urlString).then(URL.createObjectURL).then(setImgSrc)
    }, [urlString])

    function handleUpdatePlacement(key: keyof Placement, value: string) {
        onUpdate({ placement: { ...placement, [key]: Number(value) } })
    }

    function handleUpdateImage(accepted: File[]) {
        onUpdateFile(accepted[0])
    }

    const header = (
        <>
            <Box flex="1" display="flex" alignItems="center" gap="8px">
                {draggable && <Menu className={classes.dragIcon} />}
                <EditableLabel
                    textProps={{ variant: 'caption' }}
                    hideFinishButtons
                    editMethod="click-text"
                    value={title}
                    onChange={title => onUpdate({ title })}
                />
            </Box>

            {onRemove && (
                <IconButton color="inherit" size="small" onClick={onRemove}>
                    <Trash className={classes.deleteIcon} />
                </IconButton>
            )}
        </>
    )

    return (
        <Collapsable
            className={classes.overlay}
            header={header}
            headerClass={classes.overlayCollapseHeader}
            open={open}
            onToggle={onToggle}
        >
            <DropZone
                className={classes.overlays}
                onDrop={handleUpdateImage}
                accept={[FILE_TYPES.JPEG, FILE_TYPES.JPG, FILE_TYPES.PNG]}
            >
                {() => <OverlayImage classes={classes} src={imgSrc} />}
            </DropZone>

            <Box display="grid" gridTemplateColumns="1fr 1fr" gap="8px">
                {makeImagePlacementOptions(placement).map(o => (
                    <DebouncedTextField
                        key={o.key}
                        margin="dense"
                        type="number"
                        inputProps={{ step: '0.001' }}
                        label={o.label}
                        value={o.value}
                        onChange={v => handleUpdatePlacement(o.key, v)}
                    />
                ))}
            </Box>
        </Collapsable>
    )
}

interface OverlayImageProps {
    classes: any
    src: string
    onClick?: () => void
}

function OverlayImage({ classes, src, onClick }: OverlayImageProps) {
    return (
        <div className={classes.overlayImageContainer}>
            <Box height="80px" maxWidth="90%" margin="auto" position="relative">
                <EditIcon className={classes.overlayImageEditIcon} />
                <img className={classes.overlayImage} src={src} onClick={onClick} alt="map overlay" />
            </Box>
        </div>
    )
}

type Placement = MapImageOverlay['placement']

function makeImagePlacementOptions(
    placement: Placement
): Array<{ key: keyof Placement; label: string; value: number }> {
    return [
        { key: 'top', label: 'Top', value: placement.top },
        { key: 'bottom', label: 'Bottom', value: placement.bottom },
        { key: 'left', label: 'Left', value: placement.left },
        { key: 'right', label: 'Right', value: placement.right },
    ]
}
