import React from 'react'
import { IconButton, Tooltip } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

const BackButton = ({ onClick }) => (
    <IconButton onClick={onClick} sx={{ padding: '6px', mr: 1 }}>
        <Tooltip title="Back">
            <ArrowBackIcon sx={{ fontSize: 28 }} />
        </Tooltip>
    </IconButton>
)

export default BackButton
