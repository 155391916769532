import { useContext } from 'react'
import { Box, ButtonBase, Paper, Typography } from '@mui/material'

import { DashboardContext } from './widgets2/dashboard/DashboardContext'
import useNewWidgetList from './widgets2/utils/useNewWidgetList'

export default function EmptyPerspective() {
    const actions = useNewWidgetList()
    const { editable } = useContext(DashboardContext)

    if (!editable)
        return (
            <Box height="100%" display="flex" alignItems="center" justifyContent="center">
                <Typography variant="h5">No widgets</Typography>
            </Box>
        )

    return (
        <Box height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={4}>
            <Typography variant="h5">Add your first widget!</Typography>

            <Box width="600px" display="grid" gap={2} gridTemplateColumns="1fr 1fr">
                {actions.map(({ hide, ...a }) => !hide && <Tile key={a.name} {...a} />)}
            </Box>
        </Box>
    )
}

function Tile({ Icon, name, description, onClick }) {
    return (
        <ButtonBase onClick={onClick}>
            <Paper
                sx={{
                    p: 2,
                    textAlign: 'left',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 0.5,
                }}
            >
                <Icon fontSize="large" />
                <Typography variant="h6">{name}</Typography>
                <Typography>{description}</Typography>
            </Paper>
        </ButtonBase>
    )
}
