import { memo, useCallback, useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, InputAdornment, IconButton } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'

import { Module } from 'genesis-suite/types/visualTypes'
import { SortBy } from 'genesis-suite/types/utilTypes'
import { DebouncedTextField, SwalContext } from 'genesis-suite/components'
import { Search } from 'genesis-suite/icons'
import { buildRoute } from 'genesis-suite/utils'
import { moduleCreators, navigationCreators } from '../../actions/creators'
import { applicationSelectors, moduleSelectors } from '../../selectors'
import ModulesTable from './ModulesTable'
import ModuleSettings from './ModuleSettings'
import NewModuleButton from './NewModuleButton'
import { createModuleCopyTitle } from './utils'
import { ModuleMetaFilter } from '../../types/ManageAppTypes'
import { createSlug } from '../../lib/utils'
import { useSnackbar } from 'notistack'
import { routePaths } from '../../lib/routes'
import { useFeature } from '../../lib/featureFlags'

interface SelectedModule {
    showSettings: boolean
    data: Module
}

const initialFilters: ModuleMetaFilter['filter'] = {
    textSearch: undefined,
    onlyUser: false,
}

function Render() {
    const { confirm } = useContext(SwalContext)
    const dispatch = useDispatch()

    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const modules = useSelector(moduleSelectors.getAllModules)?.sort((a, b) => a.title?.localeCompare(b.title))
    const [enableBuilder] = useFeature('builder')
    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState<ModuleMetaFilter['filter']>(initialFilters)
    const [sortBy, setSortBy] = useState<SortBy<Module>>({})
    const [selectedModule, setSelectedModule] = useState<SelectedModule>({ showSettings: false, data: null })

    const { enqueueSnackbar: showSnackbar } = useSnackbar()

    const handleOpen = useCallback(
        (id: string) => {
            const moduleName = modules.find(m => m.id === id).name
            dispatch(navigationCreators.goToPath(buildRoute(routePaths.MANAGE, appName, moduleName)))
        },
        [appName, modules]
    )

    async function handleNew(title) {
        try {
            const moduleNames = modules.map(m => m.name)
            const name = createSlug(title, moduleNames)
            dispatch(moduleCreators.createModule({ title, name }))
        } catch (error) {
            showSnackbar('An error occurred while creating your App. Please try again.', { variant: 'error' })
            console.error(error)
        }
    }

    const handleCopy = useCallback(
        async origModule => {
            const { id, _id, v, __v, createdAt, createdBy, updatedAt, author, ...origModuleBody } = origModule

            const moduleCopyTitle = createModuleCopyTitle(origModule.title, modules)

            const moduleCopyName = createSlug(
                moduleCopyTitle,
                modules.map(m => m.name)
            )
            const moduleCopy = { ...origModuleBody, name: moduleCopyName, title: moduleCopyTitle }

            try {
                setLoading(true)
                await dispatch(moduleCreators.createModule(moduleCopy))
            } catch (error) {
                showSnackbar(`An error occurred while copying your App. Please try again.`, {
                    variant: 'error',
                })
                console.error(error)
            } finally {
                setLoading(false)
            }
        },
        [modules]
    )

    const handleDelete = useCallback(async (moduleId: string) => {
        const response = await confirm(
            'Are you sure you want to delete this application and all connected perspectives and widgets?',
            { type: 'warning' }
        )

        if (response.dismiss) return

        try {
            setLoading(true)
            await dispatch(moduleCreators.deleteModule(moduleId))
        } catch (error) {
            showSnackbar(`An error occurred while deleting your App. Please try again.`, {
                variant: 'error',
            })
            console.error(error)
        } finally {
            setLoading(false)
        }
    }, [])

    function handleSearchInput(val?: string) {
        if (val === filter.textSearch) return
        setFilter(s => ({ ...s, textSearch: val }))
    }

    function updateFilter(updated: Partial<ModuleMetaFilter['filter']>) {
        setFilter(s => ({ ...s, ...updated }))
    }

    const handleSettings = useCallback((data?: Module) => {
        setSelectedModule(s => ({ ...s, showSettings: true, data }))
    }, [])

    return (
        <Box flex="1" overflow="hidden" display="flex" flexDirection="column" position="relative">
            <Box m={1} display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
                {enableBuilder && <NewModuleButton onNew={handleNew} />}
                <DebouncedTextField
                    debounceTime={500}
                    sx={theme => ({
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: theme.palette.text.primary,
                        },
                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: theme.palette.text.primary,
                        },
                        '.MuiSvgIcon-root ': {
                            fill: theme.palette.text.primary,
                        },
                        width: '300px',
                    })}
                    variant="outlined"
                    autoFocus
                    size="small"
                    margin="dense"
                    placeholder="Title or Author"
                    onFocus={e => e.target.select()}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search sx={{ color: 'text.primary' }} fontSize="small" />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    size="small"
                                    disabled={!filter.textSearch}
                                    onClick={() => handleSearchInput()}
                                    sx={{ color: 'text.primary' }}
                                >
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    value={filter.textSearch || ''}
                    onChange={handleSearchInput}
                />
            </Box>
            <ModulesTable
                filter={filter}
                loading={loading}
                modules={modules}
                onCopy={handleCopy}
                onDelete={handleDelete}
                onOpen={handleOpen}
                onSort={setSortBy}
                onSettings={handleSettings}
                onUpdateFilter={updateFilter}
                sortBy={sortBy}
            />
            <ModuleSettings selectedModule={selectedModule} setSelectedModule={setSelectedModule} />
        </Box>
    )
}

const MemoizedRender = memo(Render)

export default function ManageModules() {
    return <MemoizedRender />
}
