import { useInterval } from 'genesis-suite/hooks'
import { useCallback, useMemo, useState, useEffect } from 'react'
import Markdown from '../../components/Markdown'

const SpeechMimic = ({
    text,
    onChange,
    onTypingComplete,
    onLoad,
    avoidTyping,
}: {
    text: string
    onChange: () => void
    onTypingComplete?: () => void
    onLoad?: (props: any) => void
    avoidTyping?: boolean
}) => {
    const [wordIndex, setWordIndex] = useState(0)
    const [stopTyping, setStopTyping] = useState(false)

    const words = useMemo(() => text.split(' '), [text])

    useEffect(() => {
        if (onTypingComplete && wordIndex >= words.length) onTypingComplete()
    }, [wordIndex])

    const processLines = useCallback(() => {
        if (avoidTyping) setWordIndex(words?.length)
        else setWordIndex(prev => prev + 1)
        onChange()
    }, [onChange])

    // Random delays cuz why not, make it look like it's thinking sometimes
    const delay = !stopTyping && wordIndex < words.length ? Math.random() * 300 : null
    useInterval(() => {
        processLines()
    }, delay)

    useEffect(() => {
        onLoad?.({ stopTyping: () => setStopTyping(true) })
    }, [])

    return <Markdown>{words.slice(0, wordIndex).join(' ')}</Markdown>
}

export default SpeechMimic
