import { useDispatch } from 'react-redux'

import { NodeFilterWithValue } from 'genesis-suite/types/visualTypes'
import { filterCreators } from '../actions/creators'
import { logEvent } from '../lib/amplitudeClient'
import { FilterApplyMethod } from '../types/FilterTypes'
import ContextFilterSelector from './widgets2/ContextFilterSelector'
import { filterHasValue, useGlobalFilters } from './widgets2/utils/contextFilterUtils'

interface Props {
    onDone?: () => void
}

export default function GlobalFilters2({ onDone }: Props) {
    const { filters, source } = useGlobalFilters() || {}

    const dispatch = useDispatch()

    async function updateFilters(filters: NodeFilterWithValue[], method: FilterApplyMethod) {
        const withValues = filters.filter(filterHasValue)
        switch (method) {
            case 'apply-only':
                dispatch(filterCreators.applyGlobalFilters(withValues))
                break
            case 'save':
                logEvent('GLOBAL_FILTERS_SAVE_AS_DEFAULT')
                dispatch(filterCreators.saveDefaultGlobalFilters(withValues))
                break
        }
        onDone?.()
    }

    return (
        <ContextFilterSelector
            filters={filters}
            omitMethods={['save-for-all']}
            onApply={updateFilters}
            onDone={() => onDone?.()}
            source={source}
        />
    )
}
