import ClearIcon from '@mui/icons-material/Clear'
import { Box, Button, IconButton, Skeleton, Slider, SliderProps, Typography } from '@mui/material'
import produce from 'immer'
import { isEmpty, isEqual } from 'lodash'
import { CSSProperties, useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import useSWR from 'swr'

import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { ChopText, DebouncedTextField, MenuIcon } from 'genesis-suite/components'
import FilterIcon from 'genesis-suite/icons/Filter'
import { BareResource, Property, ResourceType } from 'genesis-suite/types/networkTypes'
import { isNumeric, stringToFilters, isDatePicker } from 'genesis-suite/utils'
import { useSemanticTypeById } from '~/hooks/useSemanticTypes'
import { FilterOperator, WidgetFilter, WidgetFilterValue } from '~/types/FilterTypes'
import useResourceMeta, { fetchResource } from '../../hooks/useResourceMeta'
import { insightService } from '../../lib/services'
import { applicationSelectors, moduleSelectors } from '../../selectors'
import AsyncResourceValueSelect from '../AsyncResourceValueSelect'
import FilterOperatorButton, { isSelect } from '../FilterOperatorButton'
import { PerspectiveContext } from '../contexts/PerspectiveContext'
import WidgetSearchFilterMenu from './WidgetSearchFilterMenu'
import DateFilter from '../DateFilter'
import { isWidgetDateRangeFilterEmpty } from '~/lib/utils'
import { getDateLabel } from '../FilterInput'
interface Props {
    buttonStyle?: CSSProperties
    filterConfigs: DynamicFilter[]
    iconProps?: any
    widgetId: string
}

interface DynamicFilter {
    Id: string
    IsRangeFilter: boolean
    Property: string
    PropertyId: string
    StepSize: number
    Title: string
    IsCompound: boolean
    IsTemporal: boolean
    resource: any
    Source: { ElementName: string; ElementType: ResourceType; Filters: any; NetworkFilters: any }
}

export default function WidgetFilters({ filterConfigs, iconProps = {}, buttonStyle = {}, widgetId }: Props) {
    const enableSearch = useSelector(moduleSelectors.getEnableSearch)
    const { filterByWidgetId, setFilterByWidgetId } = useContext(PerspectiveContext)
    const correctedConfigs = filterConfigs?.filter(c => c.Property) ?? []
    const [compoundFilterConfigs, setCompoundFiltersConfig] = useState(
        correctedConfigs?.filter(conf => conf.IsCompound)
    )
    const [open, setOpen] = useState(false)
    const [keepMounted, setKeepMounted] = useState(false)
    const [draft, setDraft] = useState<WidgetFilter>(emptyFilter)

    const currentValue = useMemo(() => {
        if (filterByWidgetId?.[widgetId] && filterByWidgetId[widgetId].dynamic && filterByWidgetId[widgetId].search) {
            return filterByWidgetId[widgetId]
        }
        return emptyFilter
    }, [filterByWidgetId, widgetId])
    const isDirty = !isEqual(currentValue, draft)
    const filterCount = useMemo(() => {
        let first = 0
        if (currentValue?.dynamic) {
            first = Object.values(currentValue.dynamic).reduce((acc, cur) => {
                if (cur?.values) {
                    acc += cur.values.length
                }
                if (cur?.datePickerFilter && !isWidgetDateRangeFilterEmpty(cur?.datePickerFilter)) {
                    acc += 1
                }
                return acc
            }, 0)
        }
        const second = currentValue?.search ? currentValue.search.length : 0
        return first + second
    }, [currentValue])

    useEffect(() => {
        if (open && !keepMounted) setKeepMounted(true)
    }, [open])

    useEffect(() => {
        setDraft(currentValue)
    }, [currentValue])

    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const semanticTypeById = useSemanticTypeById()

    const prepareCompoundFiltersConfig = async () => {
        const updatedCompoundFiltersConfig = []
        if (!isEmpty(compoundFilterConfigs)) {
            for (const conf of compoundFilterConfigs) {
                const resource = await fetchResource(
                    appName,
                    conf.Source.ElementType,
                    conf.Source.ElementName,
                    semanticTypeById
                )
                updatedCompoundFiltersConfig.push({ ...conf, resource: resource })
            }

            setCompoundFiltersConfig(updatedCompoundFiltersConfig)
        }
    }

    useEffect(() => {
        if (!isEmpty(correctedConfigs)) {
            ;(async () => {
                await prepareCompoundFiltersConfig()
            })()
        }
        return () => {
            if (typeof setFilterByWidgetId === 'function') {
                setFilterByWidgetId(s => ({
                    ...s,
                    [widgetId]: { dynamic: {}, search: [], temporal: filterByWidgetId?.[widgetId]?.temporal },
                }))
            }
        }
    }, [])

    if (!correctedConfigs.length && !enableSearch) return

    function handleFilterChange(propertyName, value) {
        setDraft(s =>
            produce(s, draft => {
                if (value) draft.dynamic[propertyName] = value
                else delete draft.dynamic[propertyName]
            })
        )
    }

    function handleSearchFilterChange(updated) {
        setDraft(s => ({ ...s, search: updated }))
    }

    function handleUpdate() {
        setFilterByWidgetId(s => ({ ...s, [widgetId]: draft }))
        setOpen(false)
    }

    function handleClear() {
        setFilterByWidgetId(s => ({
            ...s,
            [widgetId]: { dynamic: {}, search: [], temporal: filterByWidgetId?.[widgetId]?.temporal },
        }))
        setOpen(false)
    }

    function handleCancel() {
        setDraft(currentValue)
        setOpen(false)
    }

    const icon = correctedConfigs.length ? <FilterIcon {...iconProps} /> : <SearchRoundedIcon {...iconProps} />

    return (
        <MenuIcon
            badge={filterCount}
            buttonProps={{ sx: buttonStyle }}
            icon={icon}
            onClick={() => setOpen(true)}
            onClose={handleCancel}
            open={open}
            popoverProps={{
                anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                keepMounted,
                transformOrigin: { vertical: 'top', horizontal: 'right' },
            }}
            tooltip={correctedConfigs.length ? 'Filters' : 'Find'}
        >
            <Box width="300px" maxHeight="500px" display="flex" flexDirection="column" gap={1} overflow="hidden">
                <Box overflow="auto">
                    {correctedConfigs.map(f => {
                        const compoundFilters = []
                        if (f.IsCompound && !isEmpty(compoundFilterConfigs)) {
                            compoundFilterConfigs.forEach(comFilConf => {
                                const { Property } = comFilConf
                                if (Property !== f.Property && !isEmpty(draft?.dynamic?.[Property]?.values)) {
                                    const property = comFilConf.resource?.properties?.find(p => p.name === Property)
                                    if (!isEmpty(property?.container)) {
                                        const { name, type } = property.container
                                        const { values, operator } = draft.dynamic[Property]
                                        const filterObj = {
                                            FilterName: Property,
                                            ResourceName: name,
                                            ResourceType: type,
                                            PropertyName: Property,
                                            Range: {},
                                            DateRangeOffset: 0,
                                            Values: values.map(val => val.value),
                                            ClickRangeName: null,
                                            IsTemporal: f.IsTemporal,
                                            UseLastRefreshDate: false,
                                            PostCalculation: false,
                                            Operator: operator,
                                        }
                                        compoundFilters.push(filterObj)
                                    }
                                }
                            })
                        }
                        return (
                            <Filter
                                key={f.Id}
                                config={f}
                                filter={draft.dynamic[f.Property]}
                                contextFilters={compoundFilters}
                                onChange={value => handleFilterChange(f.Property, value)}
                            />
                        )
                    })}
                </Box>

                {enableSearch && open && (
                    <WidgetSearchFilterMenu id={widgetId} filters={draft.search} onUpdate={handleSearchFilterChange} />
                )}

                <Box display="flex" justifyContent="flex-end" gap={1} pb={0.5}>
                    {filterCount ? (
                        <Button color="error" onClick={handleClear} sx={{ borderRadius: '20px' }}>
                            Clear all
                        </Button>
                    ) : null}
                    <Button color="secondary" variant="contained" onClick={handleUpdate} sx={{ borderRadius: '20px' }}>
                        {isDirty ? 'Apply' : 'Done'}
                    </Button>
                </Box>
            </Box>
        </MenuIcon>
    )
}

interface FilterProps {
    config: DynamicFilter
    filter: WidgetFilterValue
    onChange: (filter: WidgetFilterValue) => void
    contextFilters?: any
}

function Filter({ config, filter, onChange, contextFilters }: FilterProps) {
    const { Id, IsRangeFilter, Property, Source, StepSize, Title } = config

    const [optionCount, setOptionCount] = useState(null)
    const [totalOptionCount, setTotalOptionCount] = useState(null)

    const [resource] = useResourceMeta(Source.ElementType, Source.ElementName)

    const property = resource?.properties?.find(p => p.name === Property)
    const numeric = isNumeric(property)

    const [operator, setOperator] = useState<FilterOperator>(
        filter?.operator ?? (IsRangeFilter && numeric) ? 'GreaterThanorEqualTo' : 'EqualTo'
    )

    if (!resource) return <Skeleton height={38} />

    const { values = [] } = filter || {}

    function handleChangeValues(values) {
        if (!values?.length) onChange(null)
        else handleChange({ values })
    }

    function handleChangeOperator(operator) {
        setOperator(operator)
        if (filter) handleChange({ operator })
    }

    function handleChange(update: Partial<WidgetFilterValue>) {
        const container: BareResource = { type: resource.type, id: resource.id, name: resource.name }
        onChange({ ...filter, id: Id, resource: container, operator, ...update })
    }
    function handleDatePickerChange(datePickerFilter) {
        handleChange({ datePickerFilter: datePickerFilter })
    }

    let input
    let selectedLabel = ''
    const isDateRangeFilter = isDatePicker(property) && IsRangeFilter
    if (isDateRangeFilter) {
        if (filter?.datePickerFilter) {
            const { range, rangeOffset, startDate, endDate }: any = filter.datePickerFilter
            if (range && (range.min || range.max)) selectedLabel = getDateLabel(range.min, range.max)
            else if (rangeOffset?.min && rangeOffset?.max) {
                selectedLabel = getDateLabel(startDate, endDate)
            }
        }

        input = <DateRangeFilter onChange={handleDatePickerChange} property={property} widgetFilter={filter} />
    } else if (IsRangeFilter && numeric) {
        input = (
            <PropertySlider
                insightName={resource.name}
                operator={operator}
                property={property}
                stepSize={StepSize}
                value={values?.[0]?.value as number}
                onChange={value => handleChangeValues(value != null ? [{ value }] : null)}
            />
        )
    } else if (isSelect(operator)) {
        selectedLabel =
            optionCount != null
                ? `(${values.length.toLocaleString()}/${optionCount}${totalOptionCount > optionCount ? '+' : ''})`
                : '(...)'

        input = (
            <AsyncResourceValueSelect
                cacheFirstPage
                keyPropertyName={property.name}
                filters={stringToFilters(resource.properties, Source.Filters)}
                networkFilters={Source.NetworkFilters?.map(f => f.split('.')[1])}
                onChange={(e, values) => handleChangeValues(values)}
                multiple
                onOptions={(options, totalOptionCount) => {
                    setOptionCount(options.length)
                    setTotalOptionCount(totalOptionCount)
                }}
                resourceName={property.container.name}
                resourceType={property.container.type}
                value={values as any}
                contextFilters={contextFilters}
            />
        )
    } else {
        input = (
            <DebouncedTextField
                fullWidth
                size="small"
                margin="none"
                variant="outlined"
                type={numeric ? 'number' : 'text'}
                onFocus={e => e.target.select()}
                value={values?.[0]?.value ?? ''}
                onChange={value => handleChangeValues(value ? [{ value }] : null)}
            />
        )
    }

    return (
        <Box
            sx={{
                mb: 1,
                padding: '0 10px',
                '& .MuiInput-underline::before': {
                    borderColor: 'text.primary',
                },
            }}
        >
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <ChopText
                    showEllipsis
                    tooltipProps={{ placement: 'top' }}
                    sx={{ fontWeight: 'bold', display: 'inline', mr: 0.5 }}
                >
                    {Title}
                </ChopText>
                <Typography sx={{ fontWeight: 'bold', display: 'inline', mr: 0.5 }}>{selectedLabel}</Typography>
                <Box flex={1} />
                {!isDateRangeFilter && (
                    <FilterOperatorButton numeric={numeric} value={operator} onChange={handleChangeOperator} />
                )}
            </Box>

            {input}
        </Box>
    )
}

interface PropertySliderProps {
    insightName: string
    operator: FilterOperator
    property: Property
    /** (optional) If provided, add step ticks */
    stepSize?: number
    value: number
    onChange: (value: number) => void
}

function PropertySlider({ insightName, operator, property, stepSize, value, onChange }: PropertySliderProps) {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const { data, error } = useSWR(
        ['insightService.getPropertyBoundaries', insightName, property.name],
        ([_, insightName, propertyName]) => insightService.getPropertyBoundaries(appName, insightName, [propertyName]),
        { revalidateOnFocus: false, errorRetryCount: 2, dedupingInterval: 60000 }
    )

    const [draft, setDraft] = useState(0)
    useEffect(() => {
        setDraft(value ?? 0)
    }, [value])

    if (error)
        return (
            <Typography color="error" sx={{ mb: 3 }}>
                Failed to load slider values
            </Typography>
        )
    if (!data) return <Skeleton height={50} />

    const { min, max } = data[property.name]
    const minStepSize = (max - min) / 20 // 20 steps max
    const step = stepSize ? Math.min(Math.max(stepSize, minStepSize), max) : undefined
    const isPercent = property.semanticType?.baseDataType === 'Percentage'

    return (
        <Box ml={2}>
            <Box display="flex" alignItems="center" gap={1}>
                <Slider
                    marks={Boolean(step)}
                    max={max}
                    min={min}
                    onChange={(e, v) => setDraft(v as number)}
                    onChangeCommitted={(e, v) => onChange(v as number)}
                    size="small"
                    step={isPercent && (!step || step > 0.5) ? 0.1 : step}
                    track={getSliderTrack(operator)}
                    value={draft}
                    valueLabelDisplay="auto"
                    valueLabelFormat={isPercent ? v => `${v * 100}%` : undefined}
                />
                <IconButton
                    sx={{ color: 'text.primary' }}
                    size="small"
                    disabled={value == null}
                    onClick={() => onChange(null)}
                >
                    <ClearIcon fontSize="small" />
                </IconButton>
            </Box>

            <Box display="flex" alignItems="center" gap={1}>
                <Typography variant="caption">Value:</Typography>
                <DebouncedTextField
                    variant="standard"
                    size="small"
                    type="number"
                    sx={{ width: '100px' }}
                    value={value?.toString() ?? ''}
                    onChange={val => onChange(val ? Number(val) : null)}
                />
            </Box>
        </Box>
    )
}
interface DateRangeFilterProps {
    onChange: (datePickerFilter: any) => void
    property: any
    widgetFilter: WidgetFilterValue
}

const DateRangeFilter = ({ onChange, property, widgetFilter }: DateRangeFilterProps) => {
    const filters = { ClickRangeNames: null }
    const emptyFilter = {
        values: [],
        range: {},
        rangeOffset: {},
        useLastRefreshDate: false,
        clickRangeName: null,
        operator: 'EqualTo',
        FilterName: property?.name + property.id,
    }
    let selectedFilter = emptyFilter

    const handleFilterChange = updatedFields => {
        selectedFilter = { ...selectedFilter, ...updatedFields }
        onChange(selectedFilter)
    }
    return (
        <DateFilter
            filter={filters}
            rawValues={[]}
            selectedValues={widgetFilter?.datePickerFilter ?? emptyFilter}
            onRangeChange={(filter, range) => handleFilterChange({ range })}
            onDateOffsetChange={(filter, rangeOffset, useLastRefreshDate, startDate, endDate) =>
                handleFilterChange({ rangeOffset, useLastRefreshDate, startDate, endDate })
            }
            onClickRangeChange={(filter, clickRangeName) => {
                handleFilterChange({ clickRangeName })
            }}
            isWidget={true}
        />
    )
}

const emptyFilter: WidgetFilter = { dynamic: {}, search: [], temporal: [] }

function getSliderTrack(operator: FilterOperator): SliderProps['track'] {
    switch (operator) {
        case 'GreaterThan':
        case 'GreaterThanorEqualTo':
            return 'inverted'
        case 'LessThan':
        case 'LessThanorEqualTo':
            return 'normal'
        default:
            return false
    }
}
