import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, IconButton, Typography, Tooltip, Button, FormControlLabel, Checkbox } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import CopyIcon from '@mui/icons-material/FileCopyOutlined'
import OpenIcon from '@mui/icons-material/Launch'

import { VisualType, Widget } from 'genesis-suite/types/visualTypes'
import { EditableLabel, SwalContext } from 'genesis-suite/components'
import DeleteIcon from 'genesis-suite/icons/Trash'
import HelpIcon from 'genesis-suite/icons/Help'
import { sleep } from 'genesis-suite/utils'
import { routePaths } from '../../lib/routes'
import { moduleCreators, navigationCreators } from '../../actions/creators'
import { applicationSelectors, deploymentSelectors, moduleSelectors } from '../../selectors'
import { visualService } from '../../lib/services'
import { getInfoPanelDateProps } from '../../lib/manageUtils'
import VisualVersionWithHistoryButton from '../widgets2/VisualVersionWithHistoryButton'
import VisualReferences, { Status, WidgetReferences } from '../widgets2/VisualReferences'

interface Props {
    id: string
    onCopy: (id: string) => void
    onDelete: (id: string) => void
    onDone: () => void
    onOpen: (liveId: string) => void
    onUpdate: (id: string, body: Partial<Widget>) => Promise<void>
}

export default function WidgetDetails({ id, onCopy, onDelete, onDone, onOpen, onUpdate }: Props) {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const viewFlag = useSelector(deploymentSelectors.getDeploymentViewFlag)
    const currentModuleId = useSelector(moduleSelectors.getModuleId)
    const dispatch = useDispatch()

    const { confirm } = useContext(SwalContext)

    const [widget, setWidget] = useState<Widget>(null)
    const [status, setStatus] = useState<Status>('loading')
    const [references, setReferences] = useState<WidgetReferences>(null)

    const { created, updated } = getInfoPanelDateProps(widget) || {}
    const { active, draft, title, type, updatedBy } = widget || {}
    const liveId = draft?.visualId ?? id

    useEffect(() => {
        getWidget(id)
    }, [id])

    function getWidget(id?: string) {
        if (id) {
            visualService.getWidgetById(appName, id, viewFlag).then(setWidget)
        } else {
            setReferences(null)
        }
    }

    async function handleUpdate(body: Partial<Widget>) {
        //@ts-ignore
        setWidget(s => ({ ...s, ...body }))
        await onUpdate(id, body)
        await sleep(100)
        getWidget(id)
    }

    async function handleActiveToggle() {
        if (!active) return handleUpdate({ active: true })

        const activeModules = references?.modules?.filter(m => m.active)
        const activeDashboards = references?.dashboards?.filter(d => d.active)
        const hasActiveRefs = activeModules?.length || activeDashboards?.length

        if (hasActiveRefs) {
            const response = await confirm(
                'Making the widget private will remove all references within collections and applications throughout this cloud',
                { title: 'Remove references?', type: 'warning' }
            )
            if (response.dismiss) return
        }

        if (widget.revision) {
            const response = await confirm('Making the widget private will delete the change history', {
                title: 'Delete change history?',
                type: 'warning',
            })
            if (response.dismiss) return
        }

        await handleUpdate({ active: false })

        if (hasActiveRefs) setReferences(null)

        if (activeModules?.some(m => m.id === currentModuleId)) {
            const moduleName = activeModules.find(m => m.id === currentModuleId).name
            dispatch(moduleCreators.changeModule(moduleName))
        }
    }

    function handleEdit() {
        dispatch(navigationCreators.goTo(routePaths.EDIT, liveId, { type }))
    }

    async function handleDelete() {
        if (references) {
            const response = await confirm(
                'Deleting dashboard will remove all references within widgets and applications throughout this cloud',
                { title: 'Remove references?', type: 'warning' }
            )
            if (response.dismiss) return
        }

        onDelete(id)
    }

    return (
        <Box height="100%" display="flex" flexDirection="column" overflow="hidden">
            <Box m={1}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="h5">Details</Typography>
                    <IconButton onClick={onDone}>
                        <ClearIcon />
                    </IconButton>
                </Box>

                <EditableLabel value={title} onChange={title => handleUpdate({ title })} />

                <Box display="flex" gap={2}>
                    <Button
                        size="small"
                        startIcon={<OpenIcon color="primary" fontSize="small" />}
                        onClick={() => onOpen(liveId)}
                    >
                        Open
                    </Button>
                    <Button
                        size="small"
                        startIcon={<CopyIcon color="secondary" fontSize="small" />}
                        onClick={() => onCopy(id)}
                    >
                        Copy
                    </Button>
                    <Button
                        size="small"
                        startIcon={<CopyIcon color="secondary" fontSize="small" />}
                        onClick={handleEdit}
                    >
                        Edit
                    </Button>
                    <Button
                        color="error"
                        disabled={status !== 'idle'}
                        size="small"
                        startIcon={<DeleteIcon color="error" fontSize="small" />}
                        onClick={handleDelete}
                    >
                        Delete
                    </Button>
                </Box>

                <InfoItem label="Created" value={created} />
                {updatedBy && <InfoItem label="Updated" value={updated} />}

                <Box display="flex" alignItems="center">
                    <FormControlLabel
                        disabled={!widget || status !== 'idle' || Boolean(draft)}
                        control={<Checkbox checked={active ?? false} onChange={handleActiveToggle} />}
                        label="Public"
                    />
                    <Tooltip
                        title="Allows other admins to edit the widget. Also, enable widget to be referenced by active applications and collections."
                        placement="right"
                    >
                        <HelpIcon fontSize="small" />
                    </Tooltip>
                </Box>

                <VisualVersionWithHistoryButton type={VisualType.WIDGET} config={widget} />
            </Box>

            <Typography variant="h6" sx={{ m: 1 }}>
                References
            </Typography>
            <Box overflow="auto">
                <VisualReferences
                    type={VisualType.WIDGET}
                    id={liveId}
                    onReferences={(status, refs) => {
                        setStatus(status)
                        setReferences(refs)
                    }}
                />
            </Box>
        </Box>
    )
}

function InfoItem({ label, value }) {
    return (
        <Box display="flex" alignItems="center" gap="8px">
            <Typography variant="caption">{label}:</Typography>
            <Typography>{value}</Typography>
        </Box>
    )
}
