import equal from 'fast-deep-equal'
import produce from 'immer'
import { useEffect, useState } from 'react'

import themeTile_BeyondData from '../assets/svg/themeTile_BeyondData.svg'
import themeTile_DustInTheWind from '../assets/svg/themeTile_DustInTheWind.svg'
import themeTile_GoodOldDaze from '../assets/svg/themeTile_GoodOldDaze.svg'
import themeTile_JobSite from '../assets/svg/themeTile_JobSite.svg'
import { definitionService } from './services'

/** image tiles for the themes. prop is the server theme id and value is the image src */
const themeImages = {
    2: themeTile_BeyondData,
    3: themeTile_JobSite,
    4: themeTile_DustInTheWind,
    5: themeTile_GoodOldDaze,
}

let cachedThemes = []

export const useThemes = () => {
    const [themes, setThemes] = useState(cachedThemes)
    const [status, setStatus] = useState(cachedThemes.length === 0 ? 'loading' : 'done')

    useEffect(() => {
        if (status !== 'loading') return

        definitionService
            .getThemes()
            .then(themes => {
                // change secondary color (cause it doesn't make sense) and add image tile
                const modifiedThemes = themes.map(theme => {
                    theme.base.fonts.secondary = '#FFF'
                    theme.imageSrc = themeImages[theme.id]
                    return theme
                })

                cachedThemes = modifiedThemes
                setThemes(cachedThemes)
                setStatus('done')
            })
            .catch(error => {
                console.error(error)
                setStatus('error')
            })
    })

    return [themes, status]
}

/**
 * Compare the current theme object with tadaThemes and return true if custom
 * @param {object} currentTheme current theme object
 */
export function isCustomized(currentTheme) {
    if (!cachedThemes.length) return true

    const draftTheme = produce(currentTheme, draft => {
        delete draft?.imageSrc
        delete draft?.base?.id
        delete draft?.custom?.id
    })

    const matchedTadaTheme = produce(
        cachedThemes.find(theme => theme.id === draftTheme.id),
        draft => {
            delete draft.imageSrc
        }
    )

    return !equal(draftTheme, matchedTadaTheme)
}

export const getTadaThemeById = id => cachedThemes.find(theme => theme.id === id)

export const defaultTheme = {
    id: 1,
    name: 'Default',
    base: {
        backgrounds: { main: '#FFF', sideNav: '#DFDFDF', widget: '#FFF' },
        fonts: { primary: '#4E4E4E', secondary: '#FFF', stroke: '#B6B6B6' },
    },
    custom: {
        accent1: { main: '#672771' },
        accent2: { main: '#2DB0AA' },
        topBar: { main: '#828282' },
        widget: ['#672771', '#2CB0AA', '#C01F41', '#4E4E4E', '#FF9900', '#60B66E', '#065D7B', '#E7650E'],
    },
}

export const lightTheme = {
    backgrounds: { ...defaultTheme.base.backgrounds, default: '#FFF', secondary: '#DFDFDF' },
    fonts: { ...defaultTheme.base.fonts, disabled: 'rgba(0, 0, 0, 0.38)' },
    borders: { main: '#BDBDBD', shadow: 'rgba(0,0,0,0.2)' },
    actions: { disabled: 'rgba(0, 0, 0, 0.26)', hover: ' rgba(0, 0, 0, 0.04)' },
    highcharts: {
        chart: '#FFF',
        dataLabels: '#333',
        legend: '#333',
        title: '#333',
        axis: {
            gridLineColor: '#E6E6E6',
            labels: '#666',
            lineColor: '#CCD6EB',
            title: '#666',
        },
    },
    components: {},
}

const darkBackground = '#24292E'

export const darkTheme = {
    backgrounds: {
        default: darkBackground,
        main: darkBackground,
        paper: darkBackground,
        secondary: '#2F363D',
        sideNav: '#2F363D',
        widget: darkBackground,
    },
    fonts: { primary: '#FFF', disabled: 'rgba(255, 255, 255, 0.5)' },
    borders: { main: '#444D56', shadow: 'rgba(130,130,130,0.7)' },
    actions: { disabled: 'rgba(255, 255, 255, 0.26)', hover: 'rgba(255, 255, 255, 0.04)' },
    highcharts: {
        chart: darkBackground,
        dataLabels: '#FFF',
        legend: '#FFF',
        title: '#FFF',
        axis: {
            gridLineColor: '#444D56',
            labels: '#FFF',
            lineColor: '#CCD6EB',
            title: '#FFF',
        },
    },
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    boxShadow: '0px 4px 10px 1px rgba(130,130,130,0.7)',
                },
            },
        },
        MuiPopover: {
            styleOverrides: {
                paper: {
                    boxShadow: '0px 4px 10px 1px rgba(130,130,130,0.7)',
                },
            },
        },
    },
}
