import isEmpty from 'lodash/isEmpty'

import { themeTypes } from '../types'
import { defaultTheme } from '../../lib/tadaThemes'
import { setPreference } from '../../lib/browserPreferences'
import { logEvent } from '../../lib/amplitudeClient'

const updateBase = ({ id, name, base }) => ({
    type: themeTypes.UPDATE_BASE,
    payload: { id, name, base },
})

const updateCustom = custom => ({
    type: themeTypes.UPDATE_CUSTOM,
    payload: { custom },
})

const updateAll = theme => dispatch => {
    if (isEmpty(theme) || isEmpty(theme.base) || isEmpty(theme.custom)) {
        dispatch(updateBase(defaultTheme))
        dispatch(updateCustom(defaultTheme.custom))
    } else {
        dispatch(updateBase(theme))
        dispatch(updateCustom(theme.custom))
    }
}

const setThemeMode = themeMode => dispatch => {
    setPreference('themeMode', themeMode)
    dispatch({ type: themeTypes.SET_THEME_MODE, payload: themeMode })
    logEvent('SET_THEME_MODE', { themeMode })
}

export const themeCreators = { updateAll, setThemeMode }
