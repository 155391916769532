import { ResourceType } from 'genesis-suite/types/networkTypes'
import {
    BulletChartConfig,
    BulletSeries,
    Category,
    ChartType,
    ColorGradient,
    DataSource,
    Service,
} from 'genesis-suite/types/visualTypes'
import { makeField, stringToFilters } from 'genesis-suite/utils'
import { WidgetConverterProps } from '../migrationTypes'
import { convertAggregation, getPropertyFromResource, makeAggregation, makeNavigation } from '../widgetConverterUtils'

export default async function bulletConverter({
    config,
    dashboardIdByOldId,
    resourceManager,
}: WidgetConverterProps): Promise<BulletChartConfig> {
    const { Title, BulletConfig, NoDataMessage } = config
    const { Source, Series, CrumbMetaName, DefaultPerspective } = BulletConfig
    const baseInsight = await resourceManager.getInsight(Source.ElementName)

    const category: Category = {
        field: makeField(baseInsight.properties, Series[0].CategoryAxis),
        navigation: makeNavigation(CrumbMetaName, DefaultPerspective, dashboardIdByOldId),
    }

    const series = await (async () => {
        return Promise.all<BulletSeries>(
            Series.map(async bulletSeries => {
                const {
                    Source: seriesSource,
                    SeriesName,
                    ActualValue: actualValueName,
                    TargetValue: targetValueName,
                    AggregationTypeForActual,
                    AggregationTypeForTarget,
                    ThresholdValue1,
                    ThresholdValue2,
                    Color,
                    FirstColor,
                    SecondColor,
                    ThirdColor,
                } = bulletSeries

                const insight = await resourceManager.getInsight(seriesSource.ElementName)

                const actualValue: DataSource = {
                    field: makeField(insight.properties, actualValueName),
                    aggregation: makeAggregation(
                        getPropertyFromResource(insight, actualValueName),
                        convertAggregation(AggregationTypeForActual)
                    ),
                }

                const targetValue: DataSource = {
                    field: makeField(insight.properties, targetValueName),
                    aggregation: makeAggregation(
                        getPropertyFromResource(insight, targetValueName),
                        convertAggregation(AggregationTypeForTarget)
                    ),
                }

                const service: Service = {
                    type: ResourceType.INSIGHT,
                    name: insight.name,
                    id: insight.id,
                }

                const bands: ColorGradient = [
                    {
                        position: ThresholdValue1 / 100,
                        color: FirstColor,
                    },
                    {
                        position: ThresholdValue2 / 100,
                        color: SecondColor,
                    },
                    {
                        position: 1,
                        color: ThirdColor,
                    },
                ]

                return {
                    title: SeriesName,
                    service,
                    filters: stringToFilters(insight.properties, seriesSource.Filters),
                    values: [actualValue, targetValue],
                    bands,
                    colors: { [actualValueName]: { series: Color } },
                }
            })
        )
    })()

    return {
        version: '2',
        type: ChartType.BULLET,
        title: Title,
        categories: [category],
        series,
        filters: stringToFilters(baseInsight.properties, Source.Filters),
        noDataMessage: NoDataMessage,
    }
}
