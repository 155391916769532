import { useState, useRef, useEffect } from 'react'
import { CustomPicker, ChromePicker } from 'react-color'
import { Input, Popover, Typography } from '@mui/material'
import withStyles from '@mui/styles/withStyles'

const styles = ({ palette }) => ({
    hexInput: {
        border: `1px solid ${palette.border?.main}`,
        borderRadius: '2px',
        display: 'flex',
        alignItems: 'center',
        width: '80%',
        margin: '6px 0',
    },
    hexInputSwatch: {
        width: '18px',
        height: '18px',
        border: `1px solid ${palette.border?.main}`,
        boxSizing: 'content-box',
        margin: '6px',
        flexShrink: 0,
    },
})

const ColorPicker = ({ classes, theme, onChange, onChangeComplete, color, ...props }) => {
    const [open, setOpen] = useState(false)
    const [selectedColor, setSelectedColor] = useState('')
    const anchorEl = useRef(null)

    const handleKeyPress = ({ key }) => {
        if (key === 'Enter') setOpen(false)
    }

    const handleColorChange = ({ hex }) => {
        setSelectedColor(hex)
    }

    useEffect(() => {
        if (color) {
            setSelectedColor(color)
        }
    }, [color])

    return (
        <div>
            <div ref={anchorEl}>
                <HexInput classes={classes} value={props.hex} onClick={() => setOpen(true)} onChange={onChange} />
            </div>
            <Popover
                open={open}
                anchorEl={anchorEl.current}
                onClose={() => setOpen(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                onKeyPress={handleKeyPress}
            >
                <ChromePicker
                    color={selectedColor}
                    onChange={handleColorChange}
                    onChangeComplete={onChangeComplete}
                    disableAlpha
                />
            </Popover>
        </div>
    )
}

const HexInput = ({ classes, value, onClick, onChange }) => {
    const [inputValue, setInputValue] = useState('')
    const [errorText, setErrorText] = useState('')

    useEffect(() => {
        setInputValue(value.split('#')[1].toUpperCase())
        setErrorText('')
    }, [value])

    const doneEditing = value => {
        const isValid = /(^[0-9A-F]{6}$)|(^[0-9A-F]{3}$)/i.test(value)

        if (isValid) {
            onChange('#' + value)
            setErrorText('')
        } else {
            setErrorText('Invalid hex code')
        }
    }

    const handleChange = ({ target: { value } }) => {
        setInputValue(value)
    }

    const handleKeyPress = ({ target, key }) => {
        if (key === 'Enter') doneEditing(target.value)
    }

    return (
        <>
            <div className={classes.hexInput}>
                <div className={classes.hexInputSwatch} onClick={onClick} style={{ backgroundColor: value }} />
                <Typography display="inline">#</Typography>
                <Input
                    spellCheck="false"
                    disableUnderline
                    value={inputValue}
                    onChange={handleChange}
                    onFocus={({ target }) => target.select()}
                    onBlur={({ target }) => doneEditing(target.value)}
                    onKeyPress={handleKeyPress}
                />
            </div>
            <Typography color="error">{errorText}</Typography>
        </>
    )
}

export default CustomPicker(withStyles(styles, { withTheme: true })(ColorPicker))
