import React, { useState } from 'react'
import { UndoIcon } from 'genesis-suite/icons'
import withStyles from '@mui/styles/withStyles';

import { Chip, Tooltip } from '@mui/material';

const styles = theme => ({
    deletedchip: {
        borderColor: theme.palette.status.error,
        backgroundColor: theme.palette.grayscale.lighter,
        margin: '0 5px 5px 0',
    },
    normalChip: {
        margin: '0 5px 5px 0',
    },
    chipLabel: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: 'block',
        maxWidth: '240px',
    },
})

const ContextPanelItem = props => {
    const { label, isDeletedFilter, toggleFilterDelete, classes } = props
    const [isDeleted, toggleDelete] = useState(isDeletedFilter)

    const handleDelete = () => {
        toggleDelete(!isDeleted)
        toggleFilterDelete()
    }

    return (
        <Tooltip title={label || ''} enterDelay={300}>
            <Chip
                label={label}
                onDelete={handleDelete}
                deleteIcon={isDeleted ? <UndoIcon /> : null}
                className={isDeleted ? classes.deletedchip : classes.normalChip}
                classes={{ label: classes.chipLabel }}
                variant={isDeleted ? 'outlined' : 'default'}
            />
        </Tooltip>
    )
}

export default withStyles(styles)(ContextPanelItem)
