import {
    Box,
    FormControlLabel,
    IconButton,
    MenuItem,
    Popover,
    Select,
    Switch,
    Tooltip,
    Typography,
} from '@mui/material'
import { useState } from 'react'

import makeStyles from '@mui/styles/makeStyles'
import { Settings as SettingsIcon } from 'genesis-suite/icons'

import { useDispatch, useSelector } from 'react-redux'
import { chatCreators } from '~/actions/creators'
import { chatSelectors } from '~/selectors'

const options = [
    { value: 'V1', label: 'V1', tooltip: 'Version 1' },
    { value: 'V2', label: 'V2', tooltip: 'Version 2' },
    { value: 'V3', label: 'V3', tooltip: 'Version 3' },
]

const useStyles = makeStyles(({ palette }) => ({
    aiVersionToggle: { position: 'relative', alignSelf: 'center' },
}))

const usePopoverStyles = makeStyles(({ palette }) => ({
    typeSelect: {
        minWidth: '100px',
        borderWidth: 1,
        borderRadius: 1,
        backgroundColor: palette.background.main,
        '& .MuiSelect-select': { padding: '7px 30px 7px 14px !important' },
        '& .MuiSelect-icon': { fill: palette.text.primary },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.text.primary,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.text.primary,
        },
    },
}))

/**
 * AI Settings
 * @param {*=} props
 * @returns
 */
export default function AISettings({ disabled, iconProps }) {
    const [anchorEl, setAnchorEl] = useState(null)
    const settings = useSelector(chatSelectors.getChatSettings)
    const dispatch = useDispatch()
    const classes = useStyles()
    const [draft, setDraft] = useState(settings)

    const onSettingsChange = (key, value) => {
        setDraft({ ...draft, [key]: value })
    }

    const onPopoverClose = () => {
        dispatch(chatCreators.updateChatSettings(draft))
        setAnchorEl(null)
    }

    return (
        <>
            <div className={classes.aiVersionToggle}>
                <Tooltip title="Chat Settings">
                    <IconButton color="inherit" onClick={e => setAnchorEl(e.target)}>
                        <SettingsIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </div>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={onPopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <SettingsComponent settings={draft} onSettingsChange={onSettingsChange}></SettingsComponent>
            </Popover>
        </>
    )
}

const SettingsComponent = ({ settings, onSettingsChange }) => {
    const classes = usePopoverStyles()
    const chartOptions = [
        { label: 'Low', value: 0 },
        { label: 'Medium', value: 1 },
        { label: 'High', value: 2 },
    ]

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', m: 2 }}>
            <FormControlLabel
                control={
                    <Switch
                        color="primary"
                        checked={settings.format}
                        onChange={() => {
                            onSettingsChange('format', !settings.format)
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                }
                sx={{
                    margin: 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    '& .MuiFormControlLabel-label': {
                        flexGrow: 1,
                        textAlign: 'left',
                    },
                    '& .MuiFormControlLabel-control': {
                        marginLeft: 'auto',
                    },
                }}
                label={
                    <Typography display="inline" variant="subtitle1" fontWeight={'bold'}>
                        Format
                    </Typography>
                }
                labelPlacement="start"
            />

            <FormControlLabel
                control={
                    <Select
                        variant="outlined"
                        value={settings.genIndex}
                        className={classes.typeSelect}
                        onChange={e => onSettingsChange('genIndex', e.target.value)}
                    >
                        {chartOptions.map(option => (
                            <MenuItem value={option.value} key={option.label}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                }
                label={
                    <Tooltip title="Generative">
                        <Typography display="inline" variant="subtitle1" fontWeight={'bold'}>
                            Generative
                        </Typography>
                    </Tooltip>
                }
                labelPlacement="start"
                sx={{
                    mr: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    marginLeft: '0px',
                    '& .MuiFormControlLabel-label': {
                        flexGrow: 1,
                        textAlign: 'left',
                    },
                    '& .MuiInputBase-root': { marginBottom: '8px' },
                    '& .MuiTypography-root': {
                        maxWidth: '100px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    },
                }}
            />
            <FormControlLabel
                control={
                    <Select
                        variant="outlined"
                        value={settings.responseIndex}
                        className={classes.typeSelect}
                        onChange={e => onSettingsChange('responseIndex', e.target.value)}
                    >
                        {chartOptions.map(option => (
                            <MenuItem value={option.value} key={option.label}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                }
                label={
                    <Tooltip title="Response Size">
                        <Typography display="inline" variant="subtitle1" fontWeight={'bold'}>
                            Response Size
                        </Typography>
                    </Tooltip>
                }
                labelPlacement="start"
                sx={{
                    mr: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    marginLeft: '0px',
                    '& .MuiFormControlLabel-label': {
                        flexGrow: 1,
                        textAlign: 'left',
                    },
                    '& .MuiInputBase-root': { marginBottom: '8px' },
                    '& .MuiTypography-root': {
                        maxWidth: '100px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    },
                }}
            />
        </Box>
    )
}
