import React from 'react'
import withStyles from '@mui/styles/withStyles';
import { Typography } from '@mui/material'
import RemoveButton from './RemoveButton'

const styles = theme => ({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        width: '100%',
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: theme.spacing(1),
    },
    boldText: {
        fontWeight: 'bold',
    },
    removed: {
        fontStyle: 'italic',
        opacity: '.45',
    },
})

const addComma = (length, index) => (length > 1 && index < length - 1 ? ', ' : '')

/**
 * Displays and handles removal of Global Filters
 * @param {Object} preference - The Object representing the Global Filter preference
 * @param {Object[]} stagedRemoves - Object keyed by preference Key and FilterName, represents what has been staged for removal
 * @param {function} onChange - Callback function when RemoveButton is clicked
 */
const GlobalFilterPrefs = ({ classes, className, preferences = [], stagedRemoves, onChange }) => {
    const formatPrefs = preference =>
        preference.Value.map(filter => {
            const isStaged = stagedRemoves[preference.Key][filter.FilterName]
            return (
                <div key={filter.FilterName} className={classes.row}>
                    <div className={isStaged ? classes.removed : ''}>
                        <Typography display="inline" key={filter.FilterName} className={classes.boldText}>
                            {`${filter.ResourceName}: `}
                        </Typography>
                        {filter.DisplayValues &&
                            filter.DisplayValues.map((val, i) => (
                                <Typography display="inline" key={val}>{`${val}${addComma(
                                    filter.DisplayValues.length,
                                    i
                                )}`}</Typography>
                            ))}
                    </div>
                    <RemoveButton removed={isStaged} onClick={() => onChange(preference.Key, filter.FilterName)} />
                </div>
            )
        })
    return (
        <div className={className}>
            <div className={classes.container}>
                <Typography variant="subtitle1" className={classes.boldText}>
                    Global Filter Defaults
                </Typography>
                {preferences.length > 0 ? (
                    <>{preferences.map(pref => formatPrefs(pref))}</>
                ) : (
                    <Typography>No Global Filters configured...</Typography>
                )}
            </div>
        </div>
    )
}

export default withStyles(styles)(GlobalFilterPrefs)
