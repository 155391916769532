import { FC, ReactNode, SyntheticEvent } from 'react'
import clsx from 'classnames'
import { makeStyles } from '@mui/styles'
import { Accordion, AccordionDetails, AccordionProps, AccordionSummary } from '@mui/material'
import { ExpandMoreRounded } from '@mui/icons-material'

const useStyles = makeStyles(({ custom, palette, spacing }) => ({
    expanded: {
        margin: `${spacing(1, 0)} !important`,
        border: `2px solid ${palette.secondary.main} !important`,
    },
    accordion: {
        borderRadius: 4,
        boxShadow: 'none',
        border: `1px solid ${palette.grayscale.light}`,
    },
}))

interface ExpansionPanelProps extends Omit<AccordionProps, 'title'> {
    title: ReactNode | string
    expanded: boolean
    onChange: (e: SyntheticEvent<Element, Event>, expanded: boolean) => void
    className?: string
    contained?: boolean
}

const ExpansionPanel: FC<ExpansionPanelProps> = ({
    children,
    className = '',
    title,
    expanded,
    onChange,
    contained,
    ...rest
}) => {
    const classes = useStyles()
    const accordionProps = {
        expanded,
        onChange,
        classes: { root: clsx(classes.accordion, className), expanded: classes.expanded },
        ...rest,
    }

    if (contained) {
        return (
            <Accordion {...accordionProps}>
                <AccordionSummary
                    expandIcon={<ExpandMoreRounded />}
                    sx={{ '&.Mui-expanded': { bgcolor: 'grayscale.lightest' }, '& .MuiAccordionSummary-content.Mui-expanded': { my: 1 } }}
                >
                    {title}
                </AccordionSummary>
                <AccordionDetails>{children}</AccordionDetails>
            </Accordion>
        )
    } else {
        return (
            <Accordion {...accordionProps}>
                {expanded ? (
                    <AccordionDetails>{children}</AccordionDetails>
                ) : (
                    <AccordionSummary sx={{ '& .MuiAccordionSummary-content.Mui-expanded': { my: 1 } }}>
                        {title}
                    </AccordionSummary>
                )}
            </Accordion>
        )
    }
}

export default ExpansionPanel
