import React, { useState, useEffect } from 'react'
import { useLocation, useMatch } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
    BottomNavigation,
    BottomNavigationAction,
    Backdrop,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import withTheme from '@mui/styles/withTheme'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { BusinessElement, _360, Link, House } from 'genesis-suite/icons'

import { useIsMobile } from '../hooks/useIsMobile'
import { routePaths } from '../lib/routes'
import { authSelectors, menuSelectors, moduleSelectors } from '../selectors'
import { openInNewTab } from '../lib/utils'
import { navigationCreators } from '../actions/creators'

const HOME = 'HOME'
const navHeight = '62px'
const drawerHeight = '65vh'

const useStyles = makeStyles(({ custom, palette, spacing, shadows }) => ({
    navContainer: {
        height: navHeight,
        boxShadow: ({ open }) => !open && shadows[10],
        zIndex: 1101,
        width: '100%',
    },
    buttonLabel: { whiteSpace: 'nowrap', color: palette.text.primary },
    button: { paddingLeft: 0, paddingRight: 0, opacity: '76%' },
    buttonSelected: { opacity: 1 },
    iconWrapper: { height: '35px', display: 'flex', flexDirection: 'column', justifyContent: 'center' },
    backdrop: { zIndex: 1100 },
    drawer: {
        width: '100%',
        height: drawerHeight,
        marginBottom: navHeight,
        padding: spacing(2),
        backgroundColor: palette.grayscale.lightest,
        position: 'absolute',
        bottom: ({ open }) => (open ? 0 : `-${drawerHeight}`),
        transition: 'all 500ms',
        overflow: 'auto',
    },
    hidden: { display: 'none' },
}))

export default function MobileNavigation() {
    const modulePending = useSelector(moduleSelectors.getModulesPending)
    const _360views = useSelector(menuSelectors.getViews)
    const bizElements = useSelector(menuSelectors.getTopNav)
    const shortcuts = useSelector(authSelectors.getShortcuts)
    const dispatch = useDispatch()

    const [selection, setSelection] = useState('')
    const [open, setOpen] = useState(false)

    const location = useLocation()
    const isMobile = useIsMobile()
    const classes = useStyles({ open })
    const atHome = Boolean(useMatch(routePaths.HOME))

    useEffect(() => {
        setOpen(false)
        setSelection(atHome ? HOME : '')
    }, [atHome, location])

    if (!isMobile || modulePending) return null

    const handleClick = value => {
        if (value === HOME) {
            dispatch(navigationCreators.goTo(routePaths.HOME))
            setOpen(false)
            setSelection(HOME)
        } else {
            setOpen(value !== selection)
            setSelection(value !== selection ? value : atHome ? HOME : '')
        }
    }

    const handleClose = () => {
        setOpen(false)
        setSelection(atHome ? HOME : '')
    }

    const navOptions = [{ value: HOME, label: 'Home', icon: <House /> }]
    bizElements &&
        bizElements.length &&
        navOptions.push({
            value: 'bizElements',
            label: 'Biz Elements',
            title: 'Business Elements',
            items: bizElements,
            icon: <BusinessElement />,
        })
    _360views &&
        _360views.length &&
        navOptions.push({
            value: '360views',
            label: '360 Views',
            title: '360 Views',
            subMenuItems: _360views,
            icon: <_360 fontSize="large" />,
        })
    shortcuts &&
        shortcuts.length &&
        navOptions.push({
            value: 'shortcuts',
            label: 'Shortcuts',
            title: 'Shortcuts',
            items: shortcuts,
            icon: <Link />,
        })

    return (
        <>
            <BottomNavigation value={selection} className={classes.navContainer} showLabels>
                {navOptions.map(({ value, label, icon }) => (
                    <BottomNavigationAction
                        key={value}
                        value={value}
                        showLabel={value === selection}
                        onClick={() => handleClick(value)}
                        label={label}
                        classes={{ root: classes.button, selected: classes.buttonSelected, label: classes.buttonLabel }}
                        icon={<div className={classes.iconWrapper}>{icon}</div>}
                    />
                ))}
            </BottomNavigation>

            <Backdrop open={open} onClick={handleClose} className={classes.backdrop}>
                <div className={classes.drawer} onClick={e => e.stopPropagation()}>
                    {navOptions.map(({ value, title, items, subMenuItems }) => {
                        if ((!items || !items.length) && (!subMenuItems || !subMenuItems.length)) return null

                        return (
                            <div key={title} className={value === selection ? '' : classes.hidden}>
                                {subMenuItems ? (
                                    subMenuItems.map(({ text, to, isExternal, subMenuConfig, type }) => (
                                        <Panel
                                            key={text}
                                            title={text}
                                            to={to}
                                            isExternal={isExternal}
                                            items={subMenuConfig}
                                            type={type}
                                        />
                                    ))
                                ) : (
                                    <Panel expanded title={title} items={items} />
                                )}
                            </div>
                        )
                    })}
                </div>
            </Backdrop>
        </>
    )
}

const usePanelStyles = makeStyles(({ palette, spacing }) => ({
    summary: { minHeight: '0 !important', margin: `${spacing(0, 0)} !important` },
    summaryContent: { margin: `${spacing(1, 0)} !important`, overflow: 'hidden' },
    summaryTitle: { whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' },
    details: { padding: 0 },
    list: { width: '100%', padding: 0 },
    listItem: { borderTop: `1px solid ${palette.grayscale.lighter}`, paddingLeft: spacing(4), fontWeight: 'bold' },
}))

function Panel({ expanded, title, to, isExternal, items, type }) {
    const classes = usePanelStyles()
    const dispatch = useDispatch()

    const handleNavigation = (to, isExternal, type) => {
        if (isExternal && to) openInNewTab(to)
        else if (type === 'viewId') dispatch(navigationCreators.goToPerspective(to))
        else if (type === 'widgetId') dispatch(navigationCreators.goTo(routePaths.WIDGET, to))
        else if (type === 'elementName') dispatch(navigationCreators.goTo(routePaths.ELEMENT, to))
    }

    if (to) {
        return (
            <Accordion>
                <AccordionSummary
                    classes={{ root: classes.summary, content: classes.summaryContent }}
                    onClick={e => {
                        e.stopPropagation()
                        handleNavigation(to, isExternal, type)
                    }}
                >
                    <Typography variant="h6" className={classes.summaryTitle}>
                        {title}
                    </Typography>
                </AccordionSummary>
            </Accordion>
        )
    }

    if (!items) return null

    return (
        <Accordion expanded={expanded} onChange={e => e.stopPropagation()}>
            <AccordionSummary
                classes={{ root: classes.summary, content: classes.summaryContent }}
                expandIcon={!expanded && <ThemedExpandIcon />}
            >
                <Typography variant="h6" className={classes.summaryTitle}>
                    {title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
                <List className={classes.list}>
                    {items.map(({ text, type, to, isExternal }, index) => (
                        <ListItem
                            key={index}
                            button
                            className={classes.listItem}
                            onClick={e => {
                                e.stopPropagation()
                                handleNavigation(to, isExternal, type)
                            }}
                        >
                            {text}
                        </ListItem>
                    ))}
                </List>
            </AccordionDetails>
        </Accordion>
    )
}

const ThemedExpandIcon = withTheme(({ theme }) => <ExpandMoreIcon htmlColor={theme.palette.text.primary} />)
