import { Box } from '@mui/material'
import { useContext } from 'react'

import { DashboardDevice } from 'genesis-suite/types/visualTypes'
import { DashboardContext } from './DashboardContext'
import DashboardMainWidgets from './DashboardMainWidgets'
import DashboardTopWidgets from './DashboardTopWidgets'
import { mobileScale } from './dashboardUtils'

export default function DashboardContent() {
    const { devicePreview, layout, widgets } = useContext(DashboardContext)
    const hasTopWidgets = Boolean(widgets?.filter(w => w.position?.top).length)
    const { topHeight, topHeightUnits } = layout

    return (
        <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }} style={addPreviewDeviceStyle(devicePreview)}>
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'border.main',
                    display: 'flex',
                    height: hasTopWidgets ? `${topHeight}${topHeightUnits}` : undefined,
                }}
            >
                {hasTopWidgets && <DashboardTopWidgets />}
            </Box>
            <DashboardMainWidgets />
        </Box>
    )
}

const phoneWidth = 400

function addPreviewDeviceStyle(device: DashboardDevice) {
    if (device === DashboardDevice.LARGE) return

    return {
        margin: '16px auto 0',
        width: phoneWidth,
        height: `calc(${100 / mobileScale}% - ${16 / mobileScale}px)`,
        transformOrigin: 'top',
        transform: `scale(${mobileScale})`,
        border: '5px solid black',
        borderRadius: '15px',
    }
}
