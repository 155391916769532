import CircleIcon from '@mui/icons-material/Circle'
import { SvgIconProps } from '@mui/material'

import { DraftKey } from 'genesis-suite/types/visualTypes'
import { DeploymentViewColor } from '../types/DeploymentTypes'

interface Props extends SvgIconProps {
    type?: DraftKey
}

export default function ViewStatusIndicator({ type, sx, ...rest }: Props) {
    return <CircleIcon sx={{ fontSize: '1rem', color: getDeploymentViewColor(type), ...sx }} {...rest} />
}

const getDeploymentViewColor = (viewFlag: DraftKey) => {
    switch (viewFlag) {
        case 'prod':
            return DeploymentViewColor.DRAFT
        case 'staging':
            return DeploymentViewColor.STAGING
        default:
            return DeploymentViewColor.LIVE
    }
}
