import { architectureService } from '../../lib/services'
import { linksTypes } from '../types'

const get = appName => async dispatch => {
    try {
        dispatch({ type: linksTypes.LINKS_REQUEST })
        const [nodes, data] = await Promise.all([
            architectureService.getNodes(appName),
            architectureService.getLinks(appName, '', true),
        ])
        const links =
            data?.map(l => ({
                id: l.ResourceId,
                name: l.ResourceName,
                nodes: l.ParentServices?.reduce((acc, s) => {
                    const node = nodes?.find(n => n.ServiceName.toLowerCase() === s.toLowerCase())
                    if (node) acc.push({ id: node.ResourceId, name: node.ResourceName })

                    return acc
                }, []),
            })) ?? []
        dispatch({ type: linksTypes.LINKS_SUCCESS, payload: { appName, links } })
    } catch (err) {
        console.error(err)
        dispatch({ type: linksTypes.LINKS_FAILURE })
    }
}

export const linksCreators = {
    get,
}
