import { DraftKey } from 'genesis-suite/types/visualTypes'
import { useSelector } from 'react-redux'
import useSWR from 'swr'

import { visualService } from '../../../lib/services'
import { applicationSelectors, deploymentSelectors } from '../../../selectors'

export default function useDashboard(id: string, view?: DraftKey) {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const currentView = useSelector(deploymentSelectors.getDeploymentViewFlag)

    return useSWR(['visualService.getDashboardById', appName, id, view ?? currentView], ([_, appName, id, view]) =>
        visualService.getDashboardById(appName, id, true, view)
    )
}
