import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import { useEffect, useRef, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useUpdateEffect } from 'genesis-suite/hooks'
import { toCamel } from 'genesis-suite/utils'
import { debugCreators, widgetCreators } from '../../../actions/creators'
import { PerspectiveContext } from '~/components/contexts/PerspectiveContext'
import { widgetConstants } from '../../../constants'
import { parseWidgetData } from '../../../lib/utils'
import { networkFilterSelectors } from '../../../redux/networkFilterSlice'
import { debugSelectors } from '../../../selectors'
import useIsMobileControlSelect from '../visuals/TableWidget/helpers/useIsMobileControlSelect'
import useFetchWidgetData from './useFetchWidgetData'
import useWidgetStatus from './useWidgetStatus'
import { checkIsTranspose } from '../../../lib/formTableUtils'

/**
 * Fetch data from the visor endpoint provided a full widget config including any desired filtering (context, inline filters and widget filters), sorting and pagination
 * @param {*} config widget config
 * @param {*=} activeScenarios Optional. Apply scenarios
 * @returns
 */
export const useWidgetData = (_config, activeScenarios) => {
    const isCaching = useSelector(debugSelectors.getIsCaching)
    const cache = useSelector(debugSelectors.getWidgetCacheById(_config?.Id))
    const showDiagnostics = useSelector(debugSelectors.getWidgetDiagnostics)
    const networkFilters = useSelector(
        _config?.isCompare ? networkFilterSelectors.getAppliedCompareIds : networkFilterSelectors.getAppliedIds
    )
    const skipData = useSkipData(_config)
    const dispatch = useDispatch()
    const { fetchData, cancelCall } = useFetchWidgetData(_config)

    const [data, setData] = useState(null)
    const [informationConfig, setInformationConfig] = useState(null)
    const [forceRefresh, setForceRefresh] = useState(false)
    const { topLabelData, setTopLabelData, updateWidgetDataById } = useContext(PerspectiveContext)

    const formConfig = informationConfig?.FormConfig?.DataForm
        ? toCamel(JSON.parse(informationConfig?.FormConfig?.DataForm))
        : null
    const [status, setStatus, reset] = useWidgetStatus(
        _config?.Id,
        _config?.Type?.toUpperCase() === 'FORM' && !formConfig?.allowFormOnly && formConfig?.defaultMode !== 6
    )

    // refresh data on input changes
    const nextInputs = { _config, activeScenarios, networkFilters }
    const inputs = useRef()
    if (!isEqual(nextInputs, inputs.current)) inputs.current = nextInputs
    useUpdateEffect(() => {
        refresh()
    }, [inputs.current])

    function refresh() {
        if (status === 'fetching') setForceRefresh(s => !s)
        else reset()
    }

    const handleData = (data, informationConfig, statusOverride) => {
        setData(data)
        const infoContext = informationConfig.Context
        /**
         * SortOrders returned as part of response of fetchData is not same as the sortOrders sent in payload
         * Hence adding a temporary hackyway to override the sortOrders with sortOrders in widget config.
         */
        if (_config.Type === 'Table') {
            const sortOrders = _config.TableConfig?.SortOrders
            if (sortOrders) informationConfig.TableConfig.SortOrders = sortOrders
        }

        if (_config.Type == 'Form' && informationConfig?.FormConfig && informationConfig?.FormConfig?.DataForm) {
            const formConfig = JSON.parse(informationConfig.FormConfig.DataForm)
            const isFormTable = !formConfig.AllowFormOnly && formConfig.DefaultMode !== 6
            const isTranspose = isFormTable && checkIsTranspose(formConfig?.TransposeSettings)
            if (isTranspose) {
                informationConfig.FormConfig.PageSize = data?.data?.length ?? 200
            }
        }

        setInformationConfig({
            ...informationConfig,
            Context: infoContext ? infoContext : _config.Context,
        })
        if (isCaching && !cache) dispatch(debugCreators.cacheWidget(_config.Id, { data, informationConfig }))

        if (statusOverride) {
            setStatus(statusOverride)
        } else if (isEmpty(informationConfig.Type.toLowerCase() === 'statustracker' ? data.data : data)) {
            setStatus('empty')
        } else {
            setStatus('data')
        }
    }

    const handleError = (error, config, startTime) => {
        updateDiagnostics(startTime)
        if (error.__CANCEL__) return

        console.error(error)
        if (config.Type.toLowerCase() === 'label') {
            updateTopLabelData({ config: config, status: 'error' })
        }
        handleData(null, config, error.status === 403 ? 'unauthorized' : 'error')
    }

    const updateDiagnostics = (startTime, trace) => {
        if (!showDiagnostics) return

        const endTime = new Date().getTime()
        const time = (endTime - startTime) / 1000
        dispatch(widgetCreators.setDiagnostics(_config.Id, Math.floor(time), trace))
    }

    function updateTopLabelData(payload) {
        if (payload?.Config?.LabelConfig?.AlignTop) {
            setTopLabelData(item => ({
                ...item,
                [payload.Config.Id]: payload,
            }))
        }
    }

    useEffect(() => {
        if (!_config) return
        if (skipData) return handleData([null], _config)
        if (status !== 'fetching') return
        if (cache) return handleData(cache.data, cache.informationConfig)
        else
            (async () => {
                const startTime = new Date().getTime()
                try {
                    const result = await fetchData()
                    const { config, data: content, ...rest } = result
                    switch (_config.Type) {
                        case 'ReportWidget': {
                            if (!result) return handleData(null, _config)
                            handleData(result, { ..._config, reportWidgetConfig: { ...result } })
                            return
                        }
                        case 'Form': {
                            updateDiagnostics(startTime)
                            const parsedConfig = JSON.parse(config)
                            if (!validFormConfig(parsedConfig)) return setStatus('error')
                            handleData({ data: JSON.parse(content), ...rest }, parsedConfig)
                            return
                        }
                        default: {
                            const diagnosticsHeader = rest.headers['x-tadametrics']
                            const trace = diagnosticsHeader ? JSON.parse(atob(diagnosticsHeader)) : null
                            updateDiagnostics(startTime, trace)

                            if (!content) return handleData(null, _config)

                            if (_config.HistogramConfig) {
                                const parsedData = content ? JSON.parse(content.data) : null
                                const success = Boolean(parsedData)
                                if (success) {
                                    handleData(parseWidgetData(content, false), JSON.parse(content.Config))
                                } else {
                                    throw new Error(parsedData)
                                }
                            } else if (_config.Type.toLowerCase() === 'webview') {
                                handleData({ Config: _config }, _config)
                            } else if (_config.Type.toLowerCase() === 'label') {
                                handleData(parseWidgetData(content, true), _config)
                                let data = parseWidgetData(content, true)
                                updateTopLabelData({ ...data, status: 'data' })
                                const { Name, Title, Description } = _config
                                updateWidgetDataById?.(Name, { Name, Description, Title, data: data.seriesData })
                            } else if (_config.Type.toLowerCase() === 'iris') {
                                const responseConfig = JSON.parse(content.Config)
                                handleData({ Config: responseConfig }, responseConfig)
                            } else if (_config.Type.toLowerCase() === 'richtext') {
                                const responseConfig = JSON.parse(content.Config)
                                handleData({ Config: responseConfig, data: content.data }, responseConfig)
                            } else {
                                handleData(
                                    parseWidgetData(
                                        content,
                                        !!(
                                            _config.TableConfig ||
                                            _config.PnLConfig ||
                                            _config.LabelConfig ||
                                            _config.WebViewConfig ||
                                            _config.TwoByTwoConfig ||
                                            (_config.MapConfig &&
                                                _config.MapConfig.MapViewType.toLowerCase() === 'standard')
                                        )
                                    ),
                                    content ? JSON.parse(content.Config) : null
                                )
                            }
                        }
                    }
                } catch (error) {
                    console.error(error)
                    updateDiagnostics(startTime)
                    handleError(error, _config, startTime)
                }
            })()
        return () => cancelCall.current && cancelCall.current()
    }, [status, forceRefresh])

    return { informationConfig, data, status, refresh, fetchData }
}

const validFormConfig = config => {
    if (!config) return true
    if (config.FormConfig) {
        const {
            FormConfig: { DataForm },
        } = config
        if (!DataForm) return false
    }
    return true
}

function useSkipData(config) {
    const isMobileControlSelect = useIsMobileControlSelect(config)
    if (isMobileControlSelect) return true
    if (config.Type === widgetConstants.Type.BUSINESS_ELEMENT) return true
    if (['TREEGRAPH', 'SUNBURST'].includes(config.Type.toUpperCase())) return true

    return false
}
