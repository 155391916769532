import React, { useState } from 'react'
import { Collapsable } from 'genesis-suite/components'
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ExpandMore, ExpandLess } from '@mui/icons-material'

const useStyles = makeStyles(({ spacing }) => ({
    root: { marginBottom: spacing() },
    headerWrapper: { padding: 0 },
    header: { fontWeight: 'bold', fontSize: '1rem' },
    indent: { margin: spacing(0, 0, 2, 1) },
}))

export default ({ children, header, startOpen = false }) => {
    const classes = useStyles({})
    const [open, setOpen] = useState(startOpen)

    return (
        <Collapsable
            open={open}
            direction="down"
            ExpandIcon={ExpandMore}
            className={classes.root}
            CollapseIcon={ExpandLess}
            onToggle={() => setOpen(!open)}
            headerClass={classes.headerWrapper}
            header={<Typography className={classes.header}>{header}</Typography>}
        >
            <div className={classes.indent}>{children}</div>
        </Collapsable>
    )
}
