import {
    Box,
    Checkbox,
    FormControlLabel,
    TextField,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
    InputAdornment,
    IconButton,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import HelpIcon from '@mui/icons-material/HelpOutline'
import CloseIcon from '@mui/icons-material/Close'

import { CellIconFormat } from 'genesis-suite/types/visualTypes'
import { statusIconOptions } from 'status-icon'

const useStyles = makeStyles(theme => ({
    fieldWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        '&>div': { width: '45%' },
    },
    selectAdornment: {
        marginRight: theme.spacing(3),
    },
}))

interface Props {
    icon: CellIconFormat
    disabled?: boolean
    onChange: (format: CellIconFormat) => void
}

export default function IconFormatFields({ icon = {}, disabled, onChange }: Props) {
    const classes = useStyles()
    const handleIconChange = change => onChange({ ...icon, ...change })

    const { lowLevel, showBar, hideValue, warnLevel, minValue, maxValue, iconType } = icon

    return (
        <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row" justifyContent="end">
                <TablesIconHelp />
            </Box>
            <FormControl>
                <InputLabel>Icon</InputLabel>
                <Select
                    value={iconType || ''}
                    onChange={e => handleIconChange({ iconType: e.target.value })}
                    endAdornment={
                        <InputAdornment className={classes.selectAdornment} position="end">
                            <IconButton
                                aria-label="reset value"
                                onClick={() => handleIconChange({ iconType: null })}
                                edge="end"
                            >
                                <CloseIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                >
                    {statusIconOptions.map(option => (
                        <MenuItem value={option.value} key={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div className={classes.fieldWrapper}>
                <TextField
                    fullWidth
                    type="number"
                    label="Low level"
                    disabled={disabled || false}
                    value={lowLevel || lowLevel === 0 ? lowLevel.toString() : ''}
                    onChange={e => handleIconChange({ lowLevel: parseInt(e.target.value) })}
                />
                <TextField
                    fullWidth
                    type="number"
                    label="Warn level"
                    disabled={disabled || false}
                    value={warnLevel ? warnLevel.toString() : ''}
                    onChange={e => handleIconChange({ warnLevel: parseInt(e.target.value) })}
                />
            </div>
            <div className={classes.fieldWrapper}>
                <TextField
                    fullWidth
                    type="number"
                    label="Min value"
                    disabled={disabled || false}
                    value={minValue || minValue === 0 ? minValue.toString() : ''}
                    onChange={e => handleIconChange({ minValue: parseInt(e.target.value) })}
                />
                <TextField
                    fullWidth
                    type="number"
                    label="Max value"
                    disabled={disabled || false}
                    value={maxValue ? maxValue.toString() : ''}
                    onChange={e => handleIconChange({ maxValue: parseInt(e.target.value) })}
                />
            </div>
            <FormControlLabel
                disabled={disabled || false}
                control={
                    <Checkbox
                        color="primary"
                        checked={showBar || false}
                        onChange={() => handleIconChange({ showBar: !showBar })}
                    />
                }
                label="Show bar"
            />
            <FormControlLabel
                disabled={disabled || false}
                control={
                    <Checkbox
                        color="primary"
                        checked={hideValue || false}
                        onChange={() => handleIconChange({ hideValue: !hideValue })}
                    />
                }
                label="Hide value"
            />
        </Box>
    )
}

const useTablesIconHelpStyles = makeStyles(() => ({
    iconsHelpContainer: { minWidth: '470px' },
    cell: { padding: 0, borderBottom: 'none', fontSize: '0.7rem', color: 'inherit' },
}))

function TablesIconHelp() {
    const classes = useTablesIconHelpStyles()

    return (
        <Tooltip
            classes={{ tooltip: classes.iconsHelpContainer }}
            title={
                <>
                    <Typography variant="h6" color="inherit">
                        Properties Icon's formatter
                    </Typography>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell className={classes.cell}>Icon</TableCell>
                                <TableCell className={classes.cell}>The icon to be displayed</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>Show bar</TableCell>
                                <TableCell className={classes.cell}>If checked, show bar behind value</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>Hide value</TableCell>
                                <TableCell className={classes.cell}>
                                    If checked, hide the numeric value and only render icon and/or bar
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>Min value</TableCell>
                                <TableCell className={classes.cell}>Number defining empty status</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>Max value</TableCell>
                                <TableCell className={classes.cell}>Number defining full status</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>Low level</TableCell>
                                <TableCell className={classes.cell}>
                                    Number when smaller than it change color to warm (yellow)
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>Warn level</TableCell>
                                <TableCell className={classes.cell}>
                                    Number when smaller than it change color to low (red)
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </>
            }
        >
            <HelpIcon fontSize="small" />
        </Tooltip>
    )
}
