import { Box, Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { SplitButton } from 'genesis-suite/components'
import { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { useIsMobile } from '~/hooks/useIsMobile'
import { authSelectors } from '~/selectors'
import { NetFiltersContext, NetworkFiltersApplyButton } from './NetworkFiltersContext'

interface NetworkFiltersActionsProps {
    onCancelClick: any
    onApply: any
    isCompare: boolean
    saveFilters: any
    saveFiltersForAll: any
    onSave: any
    onSaveForAll: any
}

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
    buttons: {
        [breakpoints.down('sm')]: {
            width: '200px',
        },
    },
    splitButtons: {
        margin: spacing(1),
    },
    primarySplitButton: {
        [breakpoints.down('sm')]: {
            width: '200px',
        },
    },
    clearAllButton: {
        backgroundColor: '#f13f6b',
        color: '#000000',
    },
    applyButton: { backgroundColor: palette.secondary.main, color: palette.getContrastText(palette.secondary.main) },
}))

const NetworkFiltersActions = ({
    onCancelClick,
    onApply,
    isCompare = false,
    saveFilters,
    saveFiltersForAll,
    onSave,
    onSaveForAll,
}: NetworkFiltersActionsProps) => {
    const classes = useStyles()

    const isMobile = useIsMobile()
    const isPowerUser = useSelector(authSelectors.getIsPowerUser)
    const { selected, clearAll, makeDirty, dirty } = useContext(NetFiltersContext)

    const handleSave = (saveFunc, saveCallback) => {
        saveFunc(selected)
        saveCallback()
    }

    const saveOptions = [{ onClick: () => handleSave(saveFilters, onSave), label: 'Save as Default' }]

    if (saveFiltersForAll && isPowerUser && !isMobile)
        saveOptions.push({ onClick: () => handleSave(saveFiltersForAll, onSaveForAll), label: 'Save for All' })
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Box sx={{ width: '50%' }}>
                <Button
                    className={classNames(classes.buttons, classes.clearAllButton)}
                    onClick={makeDirty(clearAll)}
                    variant="contained"
                >
                    Clear All
                </Button>
                {!isCompare && (
                    <SplitButton
                        buttonGroupProps={{ className: classes.splitButtons }}
                        buttonProps={{ className: classes.primarySplitButton }}
                        options={saveOptions}
                    />
                )}
            </Box>
            <Box sx={{ width: '50%', display: 'flex', justifyContent: 'space-between', flex: 0, alignSelf: 'center' }}>
                <Button variant="outlined" onClick={onCancelClick} sx={{ mr: 1 }} disabled={!dirty}>
                    Cancel
                </Button>
                <NetworkFiltersApplyButton onApply={onApply} isCompare={isCompare} disabled={!dirty} />
            </Box>
        </Box>
    )
}

export default NetworkFiltersActions
