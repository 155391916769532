import { Box } from '@mui/material'

import { appearanceConstants } from '../constants'
import ModelLastUpdated from './ModelLastUpdated'

export default function PerspectiveInfoBar() {
    return (
        <Box
            sx={{
                alignItems: 'center',
                boxShadow: '0 -6px 5px -5px #888',
                display: 'flex',
                height: appearanceConstants.SideNav.FOOTER_HEIGHT,
                padding: 0.5,
                backgroundColor: 'background.sideNav',
                zIndex: 3,
            }}
        >
            <ModelLastUpdated />
        </Box>
    )
}
