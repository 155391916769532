import { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useSelector } from 'react-redux'

import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
} from '@mui/material'
import { CloseRounded } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { LoadingButton } from '@mui/lab'

import { Scenario } from 'genesis-suite/types/networkTypes'
import { scenarioService, userService } from '../../lib/services'
import { applicationSelectors, moduleSelectors } from '../../selectors'

const useStyles = makeStyles(({ palette }) => ({
    icon: { color: palette.text.primary },
    textFieldStyle: {
        '& .MuiAutocomplete-endAdornment svg': {
            color: palette.text.primary,
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.text.primary,
        },
        '&:hover .MuiOutlinedInput-notchedOutline,': {
            borderColor: palette.text.primary,
        },
        '& .MuiSvgIcon-root': {
            color: palette.text.primary,
        },
    },
}))

interface Props {
    scenario: Scenario
    onClose: () => void
    onDone: () => void
}

export function PublishScenario({ scenario, onClose, onDone }: Props) {
    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const [userOptions, setUserOptions] = useState([])

    const [reqValue, setReqValue] = useState({
        name: scenario?.name,
        description: '',
        users: [],
    })

    const initialError = {
        name: false,
        description: false,
    }

    const [formError, setFormError] = useState(initialError)

    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const moduleId = useSelector(moduleSelectors.getModuleId)

    const getUserOptions = async () => {
        setLoading(true)
        try {
            const res = await userService.getUsersV2({
                ModelName: appName,
                PageSize: 100,
                PageNumber: 1,
                loaddetails: true,
                superUsersOnly: false,
            })

            const option = res.Users.filter(user => user?.UserInfo?.ScenarioApprovalPermissions?.[appName]).map(
                user => {
                    return {
                        info: user?.UserInfo,
                        label: user?.UserInfo?.ScreenAlias,
                        value: user?.UserInfo?.UserId,
                        id: user?.UserInfo?.UserId,
                    }
                }
            )
            setUserOptions(option)
        } catch (error) {
            console.error(error)
            showSnackbar('Failed to load User(s) list', { variant: 'error', preventDuplicate: true })
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (appName) getUserOptions()
    }, [appName])

    const handleValueChange = change => {
        setReqValue(prev => ({ ...prev, ...change }))
    }

    const handleSubmit = async event => {
        event.preventDefault()
        setFormError(initialError)

        if (reqValue?.description.length === 0) {
            setFormError(prev => ({ ...prev, description: true }))
            return
        }

        try {
            setLoading(true)

            const reqPayload = {
                scenarioId: scenario?.id,
                notes: reqValue?.description,
                modelname: appName,
                visorId: moduleId,
                requiredApprovers: reqValue.users.map(val => val.id),
            }
            await scenarioService.requestToPublishScenario(reqPayload)
            showSnackbar('Request has been submitted successfully.', { variant: 'success', preventDuplicate: true })
            onDone()
        } catch (err) {
            console.error(err)
            showSnackbar('Failed to submit publish request. Try again later.', {
                variant: 'error',
                preventDuplicate: true,
            })
        } finally {
            setLoading(false)
        }
    }

    if (!scenario) return null
    return (
        <Dialog
            PaperProps={{
                sx: {
                    width: 400,
                    overflow: 'hidden',
                },
            }}
            open={true}
            onClose={onClose}
        >
            <DialogTitle>
                Publish Scenario
                <IconButton
                    className={classes.icon}
                    aria-label="close"
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                    onClick={onClose}
                >
                    <CloseRounded />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        gap: 1.5,
                    }}
                >
                    <TextField
                        required
                        InputProps={{ readOnly: true }}
                        fullWidth
                        name="name"
                        value={reqValue?.name}
                        label="Scenario Name"
                        className={classes.textFieldStyle}
                        InputLabelProps={{ sx: { color: 'text.primary' } }}
                    />
                    <TextField
                        required
                        fullWidth
                        multiline
                        rows={5}
                        error={formError?.description}
                        name="description"
                        value={reqValue?.description}
                        label="Scenario Description"
                        className={classes.textFieldStyle}
                        helperText={!!formError?.description ? 'Scenario Description is Required' : null}
                        onChange={e => handleValueChange({ description: e.target.value })}
                        InputLabelProps={{ sx: { color: 'text.primary' } }}
                    />
                    <Autocomplete
                        fullWidth
                        multiple
                        loading={loading}
                        disableClearable
                        options={userOptions}
                        renderOption={(props, option, { selected }) => {
                            return (
                                <li {...props}>
                                    <Checkbox style={{ marginRight: 8 }} checked={selected} />
                                    {option.label}
                                </li>
                            )
                        }}
                        getOptionLabel={option => option.label}
                        value={reqValue?.users}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label="Users"
                                className={classes.textFieldStyle}
                                InputLabelProps={{ sx: { color: 'text.primary' } }}
                            />
                        )}
                        onChange={(_, value) => handleValueChange({ users: value })}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" variant="contained" onClick={onClose}>
                    Cancel
                </Button>
                <LoadingButton color="primary" variant="contained" loading={loading} onClick={handleSubmit}>
                    Submit
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
