import { useRef, useState, Dispatch, SetStateAction, forwardRef, useEffect, useCallback } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import {
    Avatar,
    Box,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Switch,
    OutlinedInput,
    Tooltip,
    Typography,
} from '@mui/material'
import { Person, SendRounded, RepeatRounded } from '@mui/icons-material'

import { ShortTadaLogoIcon } from 'genesis-suite/icons'
import { TadaGPTEditor } from 'genesis-suite/components'
import { TGPTResponseType } from 'genesis-suite/types/visualTypes'

import { getCaretCoordinates, getLastWordOfString, stripHtmlDOM } from '~/lib/utils'

import ChatAutoComplete from './ChatAutoComplete'
import Markdown from '~/components/Markdown'
import useSearch from '~/hooks/useSearch'

interface ChatComponentProps {
    Header: any
    messages: any[]
    loading: boolean
    sendQuestion: (value?: string) => void
    inputValue: string
    setInputValue: (value: string) => void
    responseType: TGPTResponseType
    setResponseType: Dispatch<SetStateAction<TGPTResponseType>>
}

const ChatComponent = forwardRef(
    (
        {
            Header,
            messages,
            loading,
            sendQuestion,
            inputValue,
            setInputValue,
            responseType,
            setResponseType,
        }: ChatComponentProps,
        ref
    ) => {
        const editorRef = useRef(null)
        const [openAutoComplete, setOpenAutoComplete] = useState(false)
        const [searchQuery, setSearchQuery] = useState('')
        const [suggestions, fetching] = useSearch(searchQuery)
        const [anchorPos, setAnchorPos] = useState(null)
        const [showSuggestions, setShowSuggestions] = useState(false)
        const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1)

        const handleChangeDebounce = useDebouncedCallback(handlePerformSearch, 900)

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            setInputValue(e.target.value)
        }

        const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') sendQuestion()
        }

        useEffect(() => {
            if (suggestions?.length) {
                setOpenAutoComplete(true)
            }
        }, [suggestions])

        const onArrowDown = useCallback(() => {
            if (suggestions?.length) {
                setSelectedSuggestionIndex(prevIndex => (prevIndex + 1) % suggestions.length)
            }
        }, [suggestions])

        const onArrowUp = useCallback(() => {
            if (suggestions?.length) {
                setSelectedSuggestionIndex(prevIndex => (prevIndex - 1 + suggestions.length) % suggestions.length)
            }
        }, [suggestions])

        const onEnter = useCallback(() => {
            if (
                openAutoComplete &&
                suggestions?.length &&
                selectedSuggestionIndex > -1 &&
                suggestions[selectedSuggestionIndex]
            ) {
                onClickMenuItem(suggestions[selectedSuggestionIndex])
            }
        }, [suggestions, selectedSuggestionIndex, openAutoComplete])

        const onEscape = useCallback(() => handleClose(), [])

        function handlePerformSearch(query) {
            if (!query) return
            setAnchorPos(getCaretCoordinates())
            const striped = stripHtmlDOM(query)
            const lastValue = getLastWordOfString(striped)
            setSearchQuery(lastValue)
        }

        const onClickMenuItem = suggestion => {
            insertHtmlAtCaret(suggestion)
            updateEditorValue()
            setOpenAutoComplete(false)
        }

        const handleClose = () => {
            setSelectedSuggestionIndex(-1)
            setOpenAutoComplete(false)
        }

        const handleGPTChange = (value: string) => {
            setInputValue(value)
            if (showSuggestions && value) {
                handleChangeDebounce(value)
            }
        }

        const handleRepeat = (text: string) => {
            setInputValue(text)
            sendQuestion(text)
        }

        const onHandleSubmit = () => {
            if (!openAutoComplete) {
                sendQuestion()
            }
        }

        const updateEditorValue = () => {
            const editorInstance = editorRef.current
            if (editorInstance) {
                const val = editorInstance.getHtml()
                setInputValue(val)
            }
        }

        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                {Header && (
                    <Header>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 1, maxHeight: '35px' }}>
                            <Tooltip title="Get Response as data">
                                <Switch
                                    sx={{ display: 'none' }}
                                    color="primary"
                                    checked={responseType === TGPTResponseType.Data}
                                    onChange={() => {
                                        setResponseType(
                                            responseType === TGPTResponseType.Data
                                                ? TGPTResponseType.Auto
                                                : TGPTResponseType.Data
                                        )
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </Tooltip>
                            <Tooltip title="Show suggestions">
                                <Switch
                                    color="primary"
                                    checked={showSuggestions}
                                    onChange={() => setShowSuggestions(s => !s)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </Tooltip>
                        </Box>
                    </Header>
                )}
                <Box ref={ref} sx={{ flexGrow: 1, overflow: 'auto', p: 1 }}>
                    <List sx={{ p: 0 }}>
                        {messages.map((message, index) => (
                            <ListItem
                                key={index}
                                sx={{
                                    justifyContent: message.type === 'user' ? 'flex-end' : 'flex-start',
                                    '& .fade-in': {
                                        opacity: 0,
                                        transition: 'opacity ease .2s',
                                    },
                                    '&:hover .fade-in': { opacity: 1 },
                                }}
                            >
                                <ListItemAvatar>
                                    <Avatar
                                        sx={{
                                            bgcolor: message.type === 'user' ? 'tada.teal' : 'tada.purple',
                                            '& *':
                                                message.type === 'user'
                                                    ? { color: '#000' }
                                                    : { fill: '#fff !important' },
                                        }}
                                    >
                                        {message.type === 'user' ? <Person /> : <ShortTadaLogoIcon />}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        message.type === 'user' ? <Markdown>{message.text}</Markdown> : message.text
                                    }
                                    sx={{
                                        bgcolor: message.type === 'user' ? 'tada.teal' : 'tada.purple',
                                        color: message.type === 'user' ? '#000' : '#fff',
                                        borderRadius: 1,
                                        p: 1,
                                        minWidth: 100,
                                        maxWidth: '80%',

                                        '& .gptTag': {
                                            fontWeight: 'bold',
                                            textDecoration: 'underline',
                                        },
                                    }}
                                />
                                {message.type === 'user' && (
                                    <Tooltip title="Repeat question" className="fade-in">
                                        <IconButton
                                            sx={{ color: 'text.disabled' }}
                                            component="label"
                                            onClick={() => handleRepeat(message.text)}
                                        >
                                            <RepeatRounded />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </ListItem>
                        ))}
                        {loading && (
                            <ListItem sx={{ justifyContent: 'center' }}>
                                <FallingDots />
                            </ListItem>
                        )}
                    </List>
                </Box>
                <Box
                    sx={{
                        p: 2,
                        pb: 0.25,
                        flex: 0,
                        mt: 'auto',
                        minWidth: 300,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {showSuggestions ? (
                        <TadaGPTEditor
                            editorRef={editorRef}
                            value={inputValue}
                            placeholder="How can I help you?"
                            onChange={handleGPTChange}
                            onHandleSubmit={onHandleSubmit}
                            onArrowDown={onArrowDown}
                            onArrowUp={onArrowUp}
                            onEnter={onEnter}
                            onEscape={onEscape}
                            suggestions={suggestions}
                            selectedSuggestionIndex={selectedSuggestionIndex}
                        />
                    ) : (
                        <OutlinedInput
                            autoFocus
                            fullWidth
                            value={inputValue}
                            onChange={handleChange}
                            onKeyPress={handleKeyDown}
                            placeholder="How can I help you?"
                        />
                    )}
                    <Tooltip title="Send">
                        <IconButton
                            color="inherit"
                            sx={{ ml: 1 }}
                            onClick={showSuggestions ? onHandleSubmit : () => sendQuestion()}
                        >
                            <SendRounded />
                        </IconButton>
                    </Tooltip>
                </Box>
                <ChatAutoComplete
                    anchorPos={anchorPos}
                    openAutoComplete={openAutoComplete}
                    handleClose={handleClose}
                    onClickMenuItem={onClickMenuItem}
                    suggestions={suggestions}
                    selectedSuggestionIndex={selectedSuggestionIndex}
                />
                <Typography sx={{ px: 2 }} variant="caption">
                    Response generated by TADA explainable artificial intelligence may contain errors or inaccurate
                    information for your questions and should not be relied upon as a substitute for professional
                    advice. Validate your response accordingly.
                </Typography>
            </Box>
        )
    }
)

const FallingDots = () => (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', width: 50 }}>
        <Box
            sx={{
                position: 'relative',
                left: '-9999px',
                width: '10px',
                height: '10px',
                borderRadius: '5px',
                backgroundColor: '#9880ff',
                color: '#9880ff',
                boxShadow: '9999px 0 0 0 #9880ff',
                animation: 'dot-falling 1s infinite linear',
                animationDelay: '0.1s',
                '&::before, &::after': {
                    content: '""',
                    display: 'inline-block',
                    position: 'absolute',
                    top: '0',
                },
                '&::before': {
                    width: '10px',
                    height: '10px',
                    borderRadius: '5px',
                    backgroundColor: '#9880ff',
                    color: '#9880ff',
                    animation: 'dot-falling-before 1s infinite linear',
                    animationDelay: '0s',
                },
                '&::after': {
                    width: '10px',
                    height: '10px',
                    borderRadius: '5px',
                    backgroundColor: '#9880ff',
                    color: '#9880ff',
                    animation: 'dot-falling-after 1s infinite linear',
                    animationDelay: '0.2s',
                },
                '@keyframes dot-falling': {
                    '0%': {
                        boxShadow: '9999px -15px 0 0 rgba(152, 128, 255, 0)',
                    },
                    '25%, 50%, 75%': {
                        boxShadow: '9999px 0 0 0 #9880ff',
                    },
                    '100%': {
                        boxShadow: '9999px 15px 0 0 rgba(152, 128, 255, 0)',
                    },
                },
                '@keyframes dot-falling-before': {
                    '0%': {
                        boxShadow: '9984px -15px 0 0 rgba(152, 128, 255, 0)',
                    },
                    '25%, 50%, 75%': {
                        boxShadow: '9984px 0 0 0 #9880ff',
                    },
                    '100%': {
                        boxShadow: '9984px 15px 0 0 rgba(152, 128, 255, 0)',
                    },
                },
                '@keyframes dot-falling-after': {
                    '0%': {
                        boxShadow: '10014px -15px 0 0 rgba(152, 128, 255, 0)',
                    },
                    '25%, 50%, 75%': {
                        boxShadow: '10014px 0 0 0 #9880ff',
                    },
                    '100%': {
                        boxShadow: '10014px 15px 0 0 rgba(152, 128, 255, 0)',
                    },
                },
            }}
        />
    </Box>
)

const insertHtmlAtCaret = suggestion => {
    const displayProperty = suggestion?.displayProperty
    if (!displayProperty) return

    const selection = window.getSelection()
    if (selection.rangeCount === 0) return

    const range = selection.getRangeAt(0)
    const textNode = range.startContainer

    const textContent = textNode.textContent
    const lastSpaceIndex = textContent.lastIndexOf(' ', range.startOffset - 1)
    const lastWordStart = lastSpaceIndex === -1 ? 0 : lastSpaceIndex + 1

    const newRange = document.createRange()
    newRange.setStart(textNode, lastWordStart)
    newRange.setEnd(range.endContainer, range.endOffset)
    newRange.deleteContents()

    const span = document.createElement('span')
    span.className = 'gptTag'
    span.textContent = displayProperty.value

    span.dataset.tag = JSON.stringify({
        type: 'tag',
        resourceType: displayProperty.container?.type,
        value: displayProperty.value,
        name: displayProperty.container?.name,
    })
    span.setAttribute('contenteditable', 'false')

    newRange.insertNode(span)

    const newSelectionRange = document.createRange()
    newSelectionRange.setStartAfter(span)
    selection.removeAllRanges()
    selection.addRange(newSelectionRange)
}

export default ChatComponent
