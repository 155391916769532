import CloseIcon from '@mui/icons-material/Close'
import { Box, DialogActions, IconButton } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useDispatch } from 'react-redux'
import { logEvent } from '../../lib/amplitudeClient.js'
import { networkFilterActions } from '../../redux/networkFilterSlice'
import NetworkFiltersActions from './NetworkFiltersActions'
import { NetworkFiltersList, NetworkFiltersProvider } from './NetworkFiltersContext'

interface NetworkFilterTabParams {
    perspectiveId: string
    isCompare: boolean
    onClick: any
    onApply: any
    onSave?: any
    onSaveForAll?: any
}

export default function NetworkFilterTabContent({
    perspectiveId,
    isCompare = false,
    onClick,
    onApply,
    onSave,
    onSaveForAll,
}: NetworkFilterTabParams) {
    const { enqueueSnackbar: showSnackbar, closeSnackbar } = useSnackbar()
    const dispatch = useDispatch()

    const defaultSnackbarConfig = {
        autoHideDuration: 5000,
        action: (
            <IconButton
                onClick={() => {
                    closeSnackbar()
                }}
                size="large"
            >
                <CloseIcon fontSize="small" htmlColor="#ffffff" />
            </IconButton>
        ),
    }

    const saveNetworkFilters = async (ids, saveForAll) => {
        logEvent(saveForAll ? 'NETWORK_FILTERS_SAVE_AS_DEFAULT_ALL' : 'NETWORK_FILTERS_SAVE_AS_DEFAULT')
        return dispatch(networkFilterActions.saveDefaultNetworkFilters(ids, perspectiveId, saveForAll))
    }
    const saveFilters = (ids, saveForAll) =>
        saveNetworkFilters(ids, saveForAll)
            .then(() => {
                showSnackbar('New defaults saved successfully', { ...defaultSnackbarConfig, variant: 'success' })
            })
            .catch(() => {
                showSnackbar('An error occured while saving default filters', {
                    ...defaultSnackbarConfig,
                    variant: 'error',
                })
            })

    return (
        <NetworkFiltersProvider perspectiveId={perspectiveId} isCompare={isCompare}>
            <Box sx={{ maxHeight: 500, display: 'flex', flexFlow: 'column' }}>
                <NetworkFiltersList />
                <DialogActions>
                    <NetworkFiltersActions
                        onApply={onApply}
                        onCancelClick={onClick}
                        onSave={onSave}
                        onSaveForAll={onSaveForAll}
                        isCompare={isCompare}
                        saveFilters={ids => saveFilters(ids, false)}
                        saveFiltersForAll={ids => saveFilters(ids, true)}
                    />
                </DialogActions>
            </Box>
        </NetworkFiltersProvider>
    )
}
