import React from 'react'
import { Box, Button, Tooltip } from '@mui/material'
import WidgetColorPicker from '../WidgetColorPicker'

interface Props {
    /** hex color */
    value: string
    onChange: (value: string) => void
    resetButtonProps: { disabled?: boolean; onClick: (e: any) => void }
}

export default function FormatEditorColorSelector({ value, onChange, resetButtonProps }: Props) {
    return (
        <Box display="grid" gridTemplateColumns="40px auto" gap="16px" my={1}>
            <WidgetColorPicker value={value} onChange={({ hex }) => onChange(hex)} />
            <Tooltip title="Reset back to default theme color">
                <span>
                    <Button size="small" {...resetButtonProps}>
                        Reset
                    </Button>
                </span>
            </Tooltip>
        </Box>
    );
}
