export const defaultLGLayout = config => {
    return config?.map((item, index) => {
        let obj = {
            i: item.config_id + '_' + item.SeriesName.replaceAll(' ', ''),
            x: index,
            y: 0,
            w: 1,
            h: 1,
            minH: 1,
            maxH: 1,
            minW: 1,
        }
        return obj
    })
}

export const defaultWidgetLGLayout = config => {
    return config?.LabelSeries?.map((item, index) => {
        let obj = { i: item.SeriesName.replaceAll(' ', ''), x: index + 1, y: 0, w: 3, h: 2, minH: 2, minW: 2 }
        return obj
    })
}

export const defaultSMLayout = lg => {
    return lg?.map((item, index) => {
        let obj = { i: item.i, x: 0, y: index + 1, w: 2, h: 2, minH: 2, minW: 2, maxW: 2 }
        return obj
    })
}

const buildLayoutObj = (item, x, y, h, w, isTopLabel) => {
    let obj = {
        i: isTopLabel
            ? item.config_id + '_' + item.SeriesName.replaceAll(' ', '')
            : item.SeriesName.replaceAll(' ', ''),
        x: x,
        y: y,
        w: w,
        h: h,
        minH: 2,
        minW: 2,
    }
    if (isTopLabel) {
        obj['maxH'] = 1
        obj['minH'] = 1
        obj['minW'] = 1
    }

    return obj
}

export const getLayOutsForLabels = (items, isTopLabel) => {
    if (!items?.length) return

    let layoutsArr = []
    const labelsWithoutLayout = []
    items.forEach(item => {
        if (item.Layout) {
            const { x, y, w, h } = item.Layout

            layoutsArr.push(buildLayoutObj(item, x, y, h, w, isTopLabel))
        } else {
            labelsWithoutLayout.push(item)
        }
    })

    if (labelsWithoutLayout.length) {
        labelsWithoutLayout.forEach(item => {
            let x = 0
            let y = 0
            const xSum = [...new Set(layoutsArr.map(o => o.x))].reduce((sum, x) => sum + x, 0)
            if (xSum > 0) x = xSum + layoutsArr[layoutsArr.length - 1].w
            const ySum = [...new Set(layoutsArr.map(o => o.y))].reduce((sum, y) => sum + y, 0)
            if (ySum > 0) y = ySum + layoutsArr[layoutsArr.length - 1].h

            layoutsArr.push(buildLayoutObj(item, x, y, 2, 2, isTopLabel))
        })
    }

    return layoutsArr
}

export const getMappedSeries = topLabelWidgets => {
    return topLabelWidgets.flatMap(item => {
        const mappedSeries = item.LabelConfig.LabelSeries.map(each => {
            let obj = { ...each, config_id: item.Id }
            return obj
        })
        return mappedSeries
    })
}
