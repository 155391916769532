import { DataGridPro } from '@mui/x-data-grid-pro'
import { Box } from '@mui/system'
import MakeTreeTableRowsAndColumns from './MakeTreeTableRowsAndColumns'

export default function TreeTable({ meta, data, onNav }) {
    const { rows, columns } = MakeTreeTableRowsAndColumns(meta, data.Data)
    const getTreeDataPath = row => row.Path

    return (
        <Box
            sx={{
                height: '100%',
                width: '100%',
            }}
        >
            <DataGridPro
                treeData
                disableColumnSelector
                rows={rows}
                columns={columns}
                getTreeDataPath={getTreeDataPath}
                onCellClick={e => onNav(e)}
            />
        </Box>
    )
}
