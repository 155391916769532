import { useEffect, useRef, useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import { Box, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useUpdateEffect } from 'genesis-suite/hooks'
import isEqual from 'lodash/isEqual'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import { appearanceConstants } from '~/constants'
import ShowDetailsContainer from '~/containers/ShowDetailsContainer'
import { pageview } from '~/lib/googleAnalyticsClient'
import { scenarioSelectors } from '~/selectors'
import { PerspectiveProvider } from '~/components/contexts/PerspectiveContext'
import { LayoutPerspectiveContext } from '~/components/contexts/LayoutPerspectiveContext'
import { makeLayouts, PerspectiveContent, topLabelFilter } from '~/components/widgets/Perspective'
import TopLabels from '~/components/widgets/visuals/LabelWidget/TopLabels'
import IndividualPerspectiveToolbar from './IndividualPerspectiveToolbar'

const { ScrollBarWidth } = appearanceConstants

const rows = 18 // default row count per perspective clientHeight
const minHeight = 2 // minimum number of rows for widget
const gridGap = 8
const smBreakpoint = 800

const useStyles = makeStyles(({ spacing, palette }) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    layout: {
        flex: 1,
        backgroundColor: palette.background.main,
        // paddingLeft: ScrollBarWidth,
    },
    widgetContainer: { '&:hover': { zIndex: p => (p.isEditing ? '' : 2) } }, // allow tooltip to cover other widgets
}))

/**
 * Renders a perspective
 * @param {function} props.changeLayouts callback to update the draft layout in redux
 * @param {string=} props.containerWidth width of the perspective container. 100% by default. used to simulate different device
 * @param {array} props.configs array of widgetConfig objects
 * @param {string} props.id id of the perspective being rendered
 * @param {boolean=} props.isEditing optional. if true perspective is being edited
 * @param {*} props.layouts device layouts object from perspective config or redux draft state
 * @param {number=} props.currentLayout if provided, override layouts with grid columns based on value provided
 * @param {function} props.onChange callback for when the perspective being rendered changes to a new collection of widgets
 * @param {boolean} props.hasFetched if false, v2 module requiring perspective fetching
 */
export default function TabPerspectiveView({
    changeLayouts,
    containerWidth = '100%',
    configs,
    id,
    isEditing = false,
    layouts,
    currentLayout,
    onChange = () => {},
    hasFetched,
    onEditCompare,
    isCompare = false,
    ...rest
}) {
    const widgetContainer = useRef()
    const [width, setWidth] = useState(1000)
    const [height, setHeight] = useState(0)

    const topConfigs = configs.filter(topLabelFilter)
    const [widgetConfigs, setWidgetConfigs] = useState(configs.filter(c => !topLabelFilter(c)))
    const hasFormWidget = configs.some(c => c.Type?.toUpperCase() === 'FORM')
    const isEditingPerspective = isEditing && widgetConfigs.length > 1
    const breakpoint = width < smBreakpoint ? 'sm' : 'lg'
    const [_layouts, isOneRow] = makeLayouts(widgetConfigs, currentLayout, breakpoint, layouts, isEditingPerspective)
    const rowPxHeight = isOneRow && !isEditingPerspective ? 200 : Math.max(Math.floor(height / rows - gridGap), 36)

    const classes = useStyles({ containerWidth, isEditing })
    const { tabRows, setTabRows } = useContext(LayoutPerspectiveContext)

    useEffect(() => {
        onChange(id)
        pageview(window.location.pathname + window.location.search)

        if (widgetContainer.current) widgetContainer.current.scrollTo(0, 0)
    }, [id])

    // HACK - needed to prevent sm layout from sticking when transitioning to lg (in ReactGridLayout component state)?
    const [isTransitioning, setIsTransitioning] = useState(false)
    useUpdateEffect(() => {
        setIsTransitioning(true)
        setTimeout(() => setIsTransitioning(false), 0)
    }, [breakpoint])

    function handleLayoutChange(_, u) {
        if (!isEditingPerspective) return
        const updated = {
            sm: u.sm.map(({ i, x, y, w, h }) => ({ i, x, y, w, h })),
            lg: u.lg.map(({ i, x, y, w, h }) => ({ i, x, y, w, h })),
        }
        if (isEqual(_layouts, updated)) return
        let data = [...tabRows]
        let updated_data = data.map(item => {
            item?.perspective.map(p => {
                if (p.Id === id) {
                    p.Position = updated
                }
            })
            return item
        })
        setTabRows(updated_data)
        // changeLayouts(updated)
    }
    const handleResetWidgets = () => {
        setWidgetConfigs(configs.filter(c => !topLabelFilter(c)))
    }

    const perspectiveLayoutProps = {
        containerWidth,
        handleLayoutChange,
        id,
        isEditing,
        isEditingPerspective,
        isTransitioning,
        _layouts,
        rowPxHeight,
        setHeight,
        setWidth,
        topConfigs,
        widgetConfigs,
        width,
        isCompare,
        ...rest,
    }

    return (
        <PerspectiveProvider id={id} configs={configs}>
            <Box id={'perspective-container'} className={classes.root}>
                {/* {!isEditing && <IndividualPerspectiveToolbar Id={id} />} */}
                <TopLabels configs={topConfigs} isEdit={isEditing} />
                <div className={classes.layout}>
                    <PerspectiveLayout {...perspectiveLayoutProps} />
                    <ShowDetailsContainer />
                </div>
            </Box>
        </PerspectiveProvider>
    )
}

function PerspectiveLayout({ topConfigs, isCompare, ...props }) {
    const activeScenarios = useSelector(scenarioSelectors.getActiveScenarioIds)
    const hasActiveScenarios = activeScenarios.length > 0
    const baseStyles = { display: 'grid', height: '100%', overflowX: 'hidden', overflowY: 'scroll' }

    return isCompare ? (
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <PerspectiveContent contentTitle={'Original'} {...props} />
            </Grid>
            <Grid item xs={6}>
                <PerspectiveContent
                    contentTitle={'Compare'}
                    isCompare={isCompare}
                    activeScenarios={activeScenarios}
                    {...props}
                />
            </Grid>
        </Grid>
    ) : hasActiveScenarios ? (
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <PerspectiveContent contentTitle={'Original'} {...props} />
            </Grid>
            <Grid item xs={6}>
                <PerspectiveContent contentTitle={'Scenario'} activeScenarios={activeScenarios} {...props} />
            </Grid>
        </Grid>
    ) : (
        <Grid container>
            <PerspectiveContent {...props} />
        </Grid>
    )
}
