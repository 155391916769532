import { DraftAction } from 'genesis-suite/types/visualTypes'
import { createSelector } from 'reselect'
import { deploymentSelectors } from './deployment.selectors'

import { userNavSelectors } from './userNav.selectors'

const getModuleStore = state => state.modules

const getCurrentModule = createSelector(getModuleStore, m => m.current || {})

const getFilters = createSelector(getCurrentModule, m => m.filters ?? [])
const getModuleId = createSelector(getCurrentModule, m => m.id)
const getModuleTitle = createSelector(getCurrentModule, m => m.title || m.name)
const getModuleName = createSelector(getCurrentModule, m => m.name)
const getModuleCloud = createSelector(getCurrentModule, m => m.cloud)
const shouldShowNetwork = createSelector(getCurrentModule, m => !(m?.hideNetworkDiagram ?? true))
const showBusinessExplorer = createSelector(getCurrentModule, m => !(m?.hideBusinessExplorer ?? true))
const getThemeColors = createSelector(getCurrentModule, m => m.colors)
const getModuleTheme = createSelector(getCurrentModule, m => m.theme)
const getEnablePowerNav = createSelector(getCurrentModule, m => m.enablePowerNav)
const getEnableSearch = createSelector(getCurrentModule, m => m.enableSearch)
const getEnableAIChat = createSelector(getCurrentModule, m => m.enableAIChat)
const isStale = createSelector(getCurrentModule, m => m.isStale)
const isUpdateDismissed = createSelector(getCurrentModule, m => m.updateDismissed)
const getViews = createSelector(getCurrentModule, m => m.views ?? [])

const getViewFlag = createSelector(getModuleStore, s => s.viewFlag)
const getAllModules = createSelector(getModuleStore, s => s.all)
const getModulesPending = createSelector(getModuleStore, s => s.isPending)
const getModuleError = createSelector(getModuleStore, s => s.error)
const getIsMigrating = createSelector(getModuleStore, s => s.isMigrating)

const getModuleImageFileToken = createSelector(
    [getModuleId, getAllModules],
    (id, modules) => modules?.find(m => m.id === id)?.BackgroundFileToken
)

const getIsV2 = createSelector([getCurrentModule], appModule => appModule?.version === '2')
const isPowerNavEnabled = createSelector(
    [getEnablePowerNav, getIsV2],
    (powerNavEnabled, isV2) => powerNavEnabled || isV2
)

const getLiveId = createSelector([getCurrentModule, getIsV2], (appModule, isV2) => {
    if (!appModule) return ''
    if (!isV2) return appModule.Id
    return appModule.draft?.visualId ?? appModule.id
})

const replaceWithDrafts = views => {
    return views.reduce((acc, view) => {
        if (!view.draft) {
            const draftView = views.find(v => v.draft?.visualId === view.id)
            acc.push(draftView ? { ...draftView, id: draftView.draft.visualId } : view)
        } else if (view.draft.action !== DraftAction.UPDATED) acc.push(view)
        return acc
    }, [])
}

const getViewMenu = createSelector(
    [getViews, userNavSelectors.get, deploymentSelectors.getDeploymentViewFlag],
    (moduleViews, userViews, viewFlag) => {
        let filtered = userViews
        if (viewFlag) {
            filtered = replaceWithDrafts(userViews)
        }
        const views = filtered.map(({ id, title }) => ({ type: 'dashboard', id, title }))
        const v2Views = views.length ? [...moduleViews, { title: 'My views', views }] : moduleViews

        return v2Views.map(convertToOldMenu)
    }
)

const convertToOldMenu = view => ({
    text: view.title,
    type: view.type === 'widget' ? 'widgetId' : 'viewId',
    to: view.id,
    subMenuConfig: view.views?.length ? view.views.map(convertToOldMenu) : undefined,
    icon: view.icon,
})

const getElements = createSelector(getCurrentModule, appModule => appModule?.elements)

const getBusinessElements = createSelector(
    getElements,
    elements =>
        elements?.map(e => ({
            text: e.title,
            type: 'elementName',
            icon: e.icon,
            to: e.nodeName,
        })) ?? []
)

/** Create flat list of nested module views */
const getModuleViewIds = createSelector([getViews], views => {
    if (!views) return []

    let viewIds = []
    function appendViewId({ id, views }) {
        if (id) return viewIds.push(id)
        if (views)
            views.forEach(v => {
                appendViewId(v)
            })
    }
    views.forEach(v => {
        appendViewId(v)
    })
    return viewIds
})

const getActiveModules = createSelector(getAllModules, modules => modules?.filter(m => m.version !== '2' || m.active))

const getAppOptions = createSelector(getCurrentModule, m => m.appOptions)

export const moduleSelectors = {
    getEnableAIChat,
    getCurrentModule,
    getFilters,
    getLiveId,
    getModuleId,
    getModuleTitle,
    getModuleName,
    getModuleImageFileToken,
    getModuleCloud,
    shouldShowNetwork,
    showBusinessExplorer,
    getThemeColors,
    getModuleTheme,
    isPowerNavEnabled,
    isStale,
    isUpdateDismissed,
    getAllModules,
    getActiveModules,
    getModulesPending,
    getModuleError,
    getIsV2,
    getViews,
    getViewMenu,
    getElements,
    getIsMigrating,
    getBusinessElements,
    getModuleViewIds,
    getViewFlag,
    getEnableSearch,
    getAppOptions,
}
