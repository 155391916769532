import { useRef } from 'react'
import { IHeaderParams } from '@ag-grid-community/core'
import { Tooltip } from '@mui/material'
import { EditRounded } from '@mui/icons-material'

export interface ICustomHeaderParams extends IHeaderParams {
    canEdit: any
    headerJustification: any
}

export default (props: ICustomHeaderParams) => {
    const refButton = useRef(null)
    const onMenuClicked = () => {
        props.showColumnMenu(refButton.current!)
    }
    let sort = null
    if (props.enableSorting) {
        sort = (
            <>
                {props.column.isSortAscending() && <>🡳</>}
                {props.column.isSortDescending() && <>🡱</>}
            </>
        )
    }

    return (
        <>
            <div
                onClick={event => {
                    // Prevent the event from bubbling up to the parent elements
                    event.stopPropagation()
                    if (props.column.isSortAscending()) {
                        props.setSort('desc', false)
                    } else if (props.column.isSortDescending()) {
                        props.setSort(null, false)
                    } else {
                        props.setSort('asc', false)
                    }
                }}
                style={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    alignItems: 'center',
                }}
                onMouseEnter={() => {
                    if (refButton.current) {
                        refButton.current.style.opacity = '1'
                    }
                }}
                onMouseLeave={() => {
                    if (refButton.current) {
                        refButton.current.style.opacity = '0'
                    }
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        whiteSpace: 'nowrap',
                        flex: '1',
                        overflow: 'hidden',
                        alignItems: 'center',
                        minWidth: '0',
                        justifyContent:
                            props.headerJustification?.toLocaleLowerCase() === 'center'
                                ? 'center'
                                : props.headerJustification?.toLocaleLowerCase() === 'right'
                                ? 'flex-start'
                                : props.headerJustification?.toLocaleLowerCase() === 'left'
                                ? 'flex-end'
                                : 'flex-start',
                    }}
                >
                    <div style={{ display: 'flex', overflow: 'hidden', alignItems: 'center' }}>
                        <Tooltip title={props.displayName}>
                            <div
                                style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {props.displayName}
                            </div>
                        </Tooltip>
                        {props.canEdit && (
                            <Tooltip title="Column is editable">
                                <EditRounded sx={{ ml: 0.7, fontSize: 10 }} />
                            </Tooltip>
                        )}
                    </div>
                    <div style={{ display: 'flex' }}>{sort}</div>
                </div>

                {props.enableMenu && (
                    <div
                        style={{ display: 'flex', alignItems: 'center', opacity: '0', width: 'auto' }}
                        ref={refButton}
                        onClick={event => {
                            event.stopPropagation()
                            onMenuClicked()
                        }}
                    >
                        <i className="ag-icon ag-icon-menu"></i>
                    </div>
                )}
            </div>
        </>
    )
}
