import { useContext } from 'react'
import produce from 'immer'
import { OptionsObject, useSnackbar } from 'notistack'
import { makeStyles } from '@mui/styles'
import { Box, Paper, Typography } from '@mui/material'

import { PropertyType } from 'genesis-suite/types/architectureTypes'
import {
    Aggregation,
    BulletChartConfig,
    BulletSeries,
    ComparisonOperator,
    DataField,
} from 'genesis-suite/types/visualTypes'
import SortEditor from '../SortEditor'
import ValueField from '../../ValueField'
import EditorWrapper from '../EditorWrapper'
import PropertyChip from '../../PropertyChip'
import { ConfigContext } from '../../ConfigContext'
import SeriesTooltipEditor from './SeriesTooltipEditor'
import BulletFormatEditor from '../FormatEditor/BulletFormatEditor'
import { getDataFieldProperty, getDefaultAggregation, getDisplayProperty, getSeriesColor } from '../../utils'
import useWidgetColors from '../../../../hooks/useWidgetColors'
import AggregationSelect from '../../AggregationSelect'

const useStyles = makeStyles(({ spacing, palette }) => ({
    group: { marginBottom: spacing() },
    stackingInput: { width: '85px' },
    targetValueBorderedSection: {
        minHeight: 48,
        maxHeight: 200,
        padding: spacing(1),
        border: `2px solid ${palette.grayscale.light}`,
    },
    targetValueLabel: {
        fontWeight: 'bold',
        marginBottom: spacing(0.5),
    },
}))

export default function SeriesEditorBullet() {
    const { selectedField, resources, dispatch, ...rest } = useContext(ConfigContext)
    const config = rest.config as BulletChartConfig
    const series = config.series
    const classes = useStyles()
    const activeSeries: BulletSeries = config.series[selectedField.index]
    const { properties } = resources.byId[activeSeries?.service.id] ?? {}
    const targetDataSource = activeSeries?.values[1]
    const colors = useWidgetColors()
    const { enqueueSnackbar: showSnackbar, closeSnackbar } = useSnackbar()

    const snackbarOptions: OptionsObject = { variant: 'error', autoHideDuration: 8000 }

    const targetDisplayProperty = getDisplayProperty(
        targetDataSource,
        properties?.find(property => property.id === targetDataSource?.field.id)
    )

    function updateActiveSeries(payload: Partial<BulletSeries>) {
        dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload })
    }

    function handleAdd(field: DataField) {
        const { semanticType, hasAggregates } = getDataFieldProperty(field, properties)
        const aggregation = getDefaultAggregation(semanticType, hasAggregates)
        const source = { field, aggregation }
        const actualValueInsightName = activeSeries.service.name
        if (!properties.find(property => property.id === source?.field.id)) {
            closeSnackbar()
            showSnackbar(
                `Property "${field.name}" is not found in "${actualValueInsightName}" Insight. Please select another property.`,
                snackbarOptions
            )
            return
        }
        updateActiveSeries({ ...activeSeries, target: undefined, values: [...activeSeries.values, source] })
    }

    function handleAggregationChange(aggregation: Aggregation) {
        const values = produce(activeSeries.values, draft => {
            draft[1].aggregation = aggregation
        })
        updateActiveSeries({ values })
    }

    function handleTargetPropertyRemove() {
        updateActiveSeries({ values: [activeSeries.values[0]] })
    }

    return (
        <>
            <AggregationSelect />
            {selectedField.type !== 'category' && (
                <div className={classes.group}>
                    <Typography variant="caption">Sorting</Typography>
                    <SortEditor pointer={selectedField} />
                </div>
            )}
            <Box mb={1}>
                <Typography className={classes.targetValueLabel} variant="body1">
                    Target Value
                </Typography>
                {targetDisplayProperty ? (
                    <Paper elevation={0} className={classes.targetValueBorderedSection}>
                        <PropertyChip
                            property={{
                                ...targetDisplayProperty,
                                service: { ...activeSeries.service },
                                color: getSeriesColor(series, selectedField.index, colors),
                            }}
                            accept={propertyTypes}
                            onAdd={({ type, pointer, ...field }) => handleAdd(field)}
                            onAggregationChange={handleAggregationChange}
                            onRemove={handleTargetPropertyRemove}
                        />
                    </Paper>
                ) : (
                    <ValueField
                        comparison={ComparisonOperator.EQUAL}
                        field={activeSeries?.values?.[1]?.field}
                        values={activeSeries?.target}
                        onChange={target => updateActiveSeries({ target })}
                        onAdd={handleAdd}
                        properties={properties}
                    />
                )}
            </Box>
            <BulletFormatEditor />
            <EditorWrapper header="Tooltip">
                <SeriesTooltipEditor />
            </EditorWrapper>
        </>
    )
}

const propertyTypes = [PropertyType.DEFINING, PropertyType.QUALITATIVE, PropertyType.QUANTITATIVE]
