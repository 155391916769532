import { useState } from 'react'
import { useSnackbar } from 'notistack'
import makeStyles from '@mui/styles/makeStyles'
import { FormSection } from 'genesis-suite/types/visualTypes'
import { Typography, Step, StepLabel, StepContent, Stepper, Button, Box } from '@mui/material'

import Section, { SectionProps } from './InputFormSection'
import { getFormBackground } from '../../../lib/formUtils'

const useStyles = makeStyles(({ spacing, palette }) => ({
    stepperRoot: { padding: spacing(1), overflow: 'auto' },
    stepLabel: {
        //@ts-ignore
        color: ({ background }) =>
            typeof background === 'string'
                ? `${palette.getContrastText(getFormBackground(background))} !important`
                : 'inherit',
    },
    activeLabel: { '& > p': { fontWeight: 'bold' } },
    submitBtn: { marginTop: spacing(1), marginLeft: spacing(1), alignSelf: 'flex-end' },
}))

interface WizardProps extends Omit<SectionProps, 'FormActions'> {
    sections: FormSection[]
}

export default function WizardForm({ sections, palette, isEditingLayout, ...sectionProps }: WizardProps) {
    const classes = useStyles({ ...palette })
    const [step, setStep] = useState(0)
    const [taken, setTaken] = useState([])

    const { enqueueSnackbar } = useSnackbar()
    const errSnack = (message: string) => enqueueSnackbar(message, { variant: 'error' })

    const curSection = sections[step]

    const handleBack = () => {
        const index = [...taken].pop()
        setTaken(taken.filter((i) => i !== index))
        setStep(index || 0)
    }
    const handleNext = (sectionId: string, fromIndex: number) => {
        const toIndex = sections.findIndex(s => s.id === sectionId)
        if (toIndex === -1) {
            errSnack('An error occurred presenting the form. Please contact support')
            console.error(
                'Section with section Id not found in config! Please check the configuration',
                `Requested Id: ${sectionId}`
            )
            return
        }
        setTaken([...taken, fromIndex])
        setStep(toIndex)
    }
    const handleStep = (index: number) => setStep(index)

    const completed = !sectionProps?.values?.isNew

    return (
        <Stepper
            className={classes.stepperRoot}
            style={{ background: 'transparent' }}
            activeStep={step}
            orientation="vertical"
            nonLinear={completed}
        >
            {sections.map(({ description, title, goTo }, index) => {
                return (
                    <Step key={title} completed={completed}>
                        <StepLabel
                            sx={completed && { '&:hover': { cursor: 'pointer' } }}
                            onClick={completed ? () => handleStep(index) : null}
                            classes={{ label: classes.stepLabel, active: classes.activeLabel }}
                        >
                            <Typography>{title}</Typography>
                            <Typography variant="caption">{description}</Typography>
                        </StepLabel>
                        <StepContent>
                            <Section
                                {...sectionProps}
                                palette={palette}
                                section={curSection}
                                FormActions={({ onSubmit, submitDisabled }) => (
                                    <Box display="flex">
                                        <Button
                                            variant="outlined"
                                            onClick={handleBack}
                                            disabled={step === 0}
                                            className={classes.submitBtn}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            disabled={submitDisabled}
                                            className={classes.submitBtn}
                                            onClick={goTo ? () => handleNext(goTo, index) : onSubmit}
                                        >
                                            {goTo ? 'Next' : 'Submit'}
                                        </Button>
                                    </Box>
                                )}
                            />
                        </StepContent>
                    </Step>
                )
            })}
        </Stepper>
    )
}
