import { useEffect, useState } from 'react'
import { Box, ListItem, Typography, List, ListItemButton, Tooltip } from '@mui/material'
import moment from 'moment'

import { Spinner, Collapsable, ChopText } from 'genesis-suite/components'
import { BaseVisual, Dashboard, Module, VisualType, Widget } from 'genesis-suite/types/visualTypes'
import { buildRoute } from 'genesis-suite/utils'
import { navigationCreators } from '../../actions/creators'
import { filterVisualObjectsByViewMode, userDisplayName } from '../../lib/manageUtils'
import { routePaths } from '../../lib/routes'
import { visualService } from '../../lib/services'
import { useDispatch, useSelector } from 'react-redux'
import { applicationSelectors, deploymentSelectors, moduleSelectors } from '../../selectors'
import ViewStatusIndicator from '../ViewStatusIndicator'
import { letMeMap } from 'genesis-suite/types/utilTypes'

type Type = VisualType.DASHBOARD | VisualType.WIDGET
export type Status = 'idle' | 'loading' | 'failed'

interface Props<T extends Type> {
    id: string
    type: T
    onReferences?: (status: Status, references?: References<T>) => void
}

type References<T extends Type> = T extends VisualType.DASHBOARD ? DashboardReferences : WidgetReferences

export interface DashboardReferences {
    modules: Module[]
    widgets: Widget[]
}

export interface WidgetReferences {
    modules: Module[]
    dashboards: Dashboard[]
}

/** List of references that visual is in */
export default function VisualReferences<T extends Type>({ id, type, onReferences }: Props<T>) {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const viewFlag = useSelector(deploymentSelectors.getDeploymentViewFlag)
    const currentModuleId = useSelector(moduleSelectors.getModuleId)
    const dispatch = useDispatch()

    const [status, setStatus] = useState<Status>('idle')
    const [dashboards, setDashboards] = useState<Dashboard[]>(null)
    const [modules, setModules] = useState<Module[]>(null)
    const [widgets, setWidgets] = useState<Widget[]>(null)
    const [expandModules, setExpandModules] = useState(false)
    const [expandDashboards, setExpandDashboards] = useState(false)
    const [expandWidgets, setExpandWidgets] = useState(false)

    useEffect(() => {
        if (!id) return
        handleGetReferences(id)
    }, [id])

    async function handleGetReferences(id: string) {
        setStatus('loading')
        try {
            const otherFetcher =
                type === VisualType.DASHBOARD
                    ? visualService.getWidgetsWithNavigationPerspectiveId
                    : visualService.getDashboardsWithWidgetId
            const [modules, others] = await Promise.all([
                visualService.getModulesWithWidgetId(appName, id, undefined, viewFlag),
                otherFetcher(appName, id, undefined, viewFlag),
            ])

            setStatus('idle')
            const filteredModules = filterVisualObjectsByViewMode(modules)
            const filteredOthers = filterVisualObjectsByViewMode(letMeMap(others))
            if (!filteredModules?.length && !filteredOthers?.length) return onReferences?.('idle')

            setModules(filteredModules)
            setExpandModules(modules.length < 5)
            if (type === VisualType.DASHBOARD) {
                const widgets = filteredOthers as Widget[]
                setWidgets(widgets)
                setExpandWidgets(widgets.length < 5)
                //@ts-ignore
                onReferences?.('idle', { modules: filteredModules, widgets })
            } else {
                const dashboards = filteredOthers as Dashboard[]
                setDashboards(dashboards)
                setExpandDashboards(dashboards.length < 5)
                //@ts-ignore
                onReferences?.('idle', { modules: filteredModules, dashboards })
            }
        } catch (err) {
            console.error(err)
            setStatus('failed')
        }
    }

    function handleOpenModule(id) {
        if (id === currentModuleId) return

        const moduleName = modules.find(m => m.id === id || m.draft?.visualId === id).name
        const path = `${buildRoute(routePaths.MANAGE, appName, moduleName)}?tab=collections`
        dispatch(navigationCreators.goToPath(path))
    }

    function handleOpenDashboard(id) {
        dispatch(navigationCreators.goToPerspective(id))
    }

    function handleOpenWidget(id) {
        dispatch(navigationCreators.goTo(routePaths.WIDGET, id))
    }

    if (status === 'loading') return <Spinner />

    if (status === 'failed')
        return (
            <Box display="flex" flexDirection="column" gap={1} alignItems="center" justifyContent="center">
                <Typography color="error" variant="h6">
                    Error
                </Typography>
                <Typography color="error">There was an error getting widget references</Typography>
            </Box>
        )

    if (!dashboards && !modules && !widgets)
        return (
            <Box flex={1} display="flex" flexDirection="column" gap={1} alignItems="center" justifyContent="center">
                <Typography variant="h6">No references</Typography>
                <Typography>No references found for this visual</Typography>
            </Box>
        )

    return (
        <Box display="flex" flexDirection="column" gap={1} overflow="auto">
            <List dense disablePadding>
                <ListItem sx={{ display: 'grid', gridTemplateColumns }}>
                    <Typography variant="caption">Title</Typography>
                    <Typography variant="caption">|</Typography>
                    <Typography variant="caption">Author</Typography>
                    <Typography variant="caption">|</Typography>
                    <Typography variant="caption">Updated</Typography>
                    <Typography variant="caption">|</Typography>
                    <Typography variant="caption">Public</Typography>
                </ListItem>
            </List>

            {Boolean(modules?.length) && (
                <Collapsable
                    header={`Applications (${modules.length})`}
                    open={expandModules}
                    onToggle={() => setExpandModules(s => !s)}
                >
                    <ReferenceItems items={modules} selectedId={currentModuleId} onClick={handleOpenModule} />
                </Collapsable>
            )}

            {Boolean(dashboards?.length) && (
                <Collapsable
                    header={`Dashboards (${dashboards.length})`}
                    open={expandDashboards}
                    onToggle={() => setExpandDashboards(s => !s)}
                >
                    <ReferenceItems items={dashboards} onClick={handleOpenDashboard} />
                </Collapsable>
            )}

            {Boolean(widgets?.length) && (
                <Collapsable
                    header={`Widgets (${widgets.length})`}
                    open={expandWidgets}
                    onToggle={() => setExpandWidgets(s => !s)}
                >
                    <ReferenceItems items={widgets} onClick={handleOpenWidget} />
                </Collapsable>
            )}
        </Box>
    )
}

interface ReferenceItemsProps {
    items: BaseVisual[]
    selectedId?: string
    onClick: (id: string) => void
}

const gridTemplateColumns = '200px 10px 150px 10px 70px 10px auto'

function ReferenceItems({ items, selectedId, onClick }: ReferenceItemsProps) {
    return (
        <List dense disablePadding>
            {items.map((item, index) => {
                const momentDate = moment(item.updatedAt)
                return (
                    <ListItemButton
                        key={index}
                        selected={selectedId && (item.id === selectedId || item.draft?.visualId === selectedId)}
                        sx={{ display: 'grid', gridTemplateColumns, alignItems: 'center' }}
                        onClick={() => onClick(item.draft?.visualId ?? item.id)}
                    >
                        <ChopText showEllipsis sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <ViewStatusIndicator type={item.draft?.type} />
                            {item.title || 'Untitled'}
                        </ChopText>
                        <Typography>&nbsp;</Typography>
                        <ChopText showEllipsis>{userDisplayName(item.createdBy)}</ChopText>
                        <Typography>&nbsp;</Typography>
                        <Tooltip title={momentDate.format('MM/DD/YY h:mm a')}>
                            <Typography>{momentDate.format('MM/DD/YY')}</Typography>
                        </Tooltip>
                        <Typography>&nbsp;</Typography>
                        <Typography>{item.active ? 'Yes' : 'No'}</Typography>
                    </ListItemButton>
                )
            })}
        </List>
    )
}
