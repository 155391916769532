import { DataGroup, DataResponse, TableConfig, ValueTableColumn } from 'genesis-suite/types/visualTypes'

export default function flattenData(
    seriesResponse: DataResponse[0]['data'],
    config: TableConfig,
    pivotColumnHeaders: string[]
) {
    const tableSeries = config.series[0]
    const { subSeries: pivot, values } = tableSeries || {}
    const subSeries = pivot ? pivotColumnHeaders : []
    const categoryCount = config.categories?.length

    let headers = config.categories?.map(({ title }) => title)
    if (pivot) {
        if (values?.length > 1) {
            headers = [...headers, 'pivot value', ...subSeries]
        } else {
            headers = [...headers, ...subSeries]
        }
    } else if (tableSeries) {
        headers = [...headers, ...tableSeries.values.map(({ title }) => title)]
    }

    const table: (string | number)[][] = []
    let rowIndex = 0

    const buildRows = (data: DataGroup[], depth: number, rowTemplate: (string | number)[]): (string | number)[][] => {
        data.forEach(({ group, children, aggregatedData }) => {
            if (!table[rowIndex]) table[rowIndex] = [...rowTemplate]
            table[rowIndex][depth] = group

            if (depth < categoryCount - 1) buildRows(children, depth + 1, table[rowIndex])
            else {
                if (pivot) {
                    children.forEach(({ group, aggregatedData }) => {
                        const index = subSeries.indexOf(group)
                        const row = table[rowIndex]

                        values?.forEach((c, i) => {
                            if (!table[rowIndex + i]) table[rowIndex + i] = [...row]
                            if (values.length > 1) table[rowIndex + i][categoryCount] = makePivotColumRowTitle(c)
                            const offset = values.length > 1 ? index + 1 : index

                            table[rowIndex + i][offset + categoryCount] = aggregatedData?.[i]
                        })
                    })
                    rowIndex += children[0].aggregatedData?.length ?? 1
                } else {
                    aggregatedData?.forEach((d, i) => {
                        table[rowIndex][categoryCount + i] = d
                    })
                    rowIndex++
                }
            }
        })
        return table
    }

    buildRows(seriesResponse, 0, new Array(headers.length).fill(null))
    return table
}

function makePivotColumRowTitle({ title, aggregation }: ValueTableColumn): string {
    if (!title) return ''

    return `${aggregation.charAt(0).toUpperCase() + aggregation.slice(1)} of ${title}`
}
