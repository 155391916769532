import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { FavoritesWidgetConfig } from 'genesis-suite/types/visualTypes'
import { forwardRef } from 'react'
import Favorites from '../../views/welcome/Favorites'

const useLabelStyles = makeStyles(({ palette, spacing }) => ({
    widget: {
        height: '100%',
        flex: 'none',
        borderRadius: '8px',
        display: 'flex',
        gridGap: spacing(),
        alignItems: 'flex-end',
    },
    favorites: {
        backgroundColor: palette.background.main,
        border: `1px solid ${palette.border?.main}`,
        borderRadius: '15px',
        marginBottom: spacing(1),
        padding: spacing(1),
        flex: 2,
        height: '400px',
        overflow: 'auto',
    },
}))

export default forwardRef<HTMLDivElement, { config: FavoritesWidgetConfig }>((_, ref) => {
    const classes = useLabelStyles()

    return (
        <div ref={ref} className={classes.widget}>
            <Box
                flex={1}
                height="100%"
                overflow="hidden"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
            >
                <Favorites className={classes.favorites} />
            </Box>
        </div>
    )
})
