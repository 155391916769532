import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { go } from 'redux-first-history'
import { MenuItem, Typography, Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { History } from '@mui/icons-material'

import { ChopText, MenuIcon } from 'genesis-suite/components'
import { logEvent } from '../lib/amplitudeClient'
import { widgetSelectors } from '../selectors'
import { navigationCreators } from '../actions/creators/navigation.creators'

export default function SessionHistory({ buttonProps }) {
    const { all, back, forward } = useSelector((s: any) => s.history)
    const router = useSelector((s: any) => s.router)
    const editing = Boolean(useSelector(widgetSelectors.getEditingPerspective))

    const dispatch = useDispatch()

    const [openHistory, setOpenHistory] = useState(false)

    const handleCustom = key => {
        setOpenHistory(false)
        if (key === router.location.key) return

        logEvent('PERSPECTIVE_GO_HISTORY_SELECT')

        const backIndex = back.findIndex(i => i.location.key === key)
        if (backIndex !== -1) return dispatch(go(backIndex - back.length + 1))

        const forwardIndex = forward.findIndex(i => i.location.key === key)
        if (forwardIndex !== -1) return dispatch(go(forwardIndex + 1))

        const { location } = all.find(i => i.location.key === key)
        dispatch(navigationCreators.goToPath(location.pathname, { oldRouteKey: key }))
    }

    const disableAll = editing || all.length === 1

    return (
        <div>
            <MenuIcon
                icon={<History />}
                tooltip="Session history"
                buttonProps={buttonProps}
                noPadding
                closeButton
                title="History"
                popoverProps={{
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                    transformOrigin: { vertical: 'top', horizontal: 'left' },
                }}
                disabled={disableAll}
                open={openHistory}
                onClick={() => setOpenHistory(true)}
                onClose={() => setOpenHistory(false)}
            >
                <Box maxHeight="240px" maxWidth="450px" overflow="auto">
                    {all.map(
                        ({ perspectiveId, location, time, ...rest }) =>
                            perspectiveId && (
                                <PerspectiveItem
                                    key={location.key || 'first'}
                                    id={perspectiveId}
                                    selected={location.key === router.location.key}
                                    onClick={() => handleCustom(location.key)}
                                    context={location.state?.context}
                                    time={time}
                                    {...rest}
                                />
                            )
                    )}
                </Box>
            </MenuIcon>
        </div>
    )
}

const usePerspectiveItemStyles = makeStyles(({ palette, spacing }) => ({
    menuItem: { display: 'grid', gridTemplateColumns: '70px auto', padding: spacing(0.5, 1) },
    timeText: { color: palette.grayscale.light },
}))

function PerspectiveItem({ id, time, context, selected, onClick }) {
    const baseTitle = useSelector(s => widgetSelectors.getWidgetTitle(s, id))
    const value = context?.DisplayValue || context?.Value
    const title = value ? `${baseTitle} - ${value}` : baseTitle
    const classes = usePerspectiveItemStyles()

    return (
        <MenuItem onClick={onClick} className={classes.menuItem}>
            <Typography className={classes.timeText}>{time}</Typography>
            <Box display="flex" alignItems="center" overflow="hidden">
                <ChopText showEllipsis>{title}</ChopText>
                {selected && <Typography color="primary">&nbsp;&#9679;</Typography>}
            </Box>
        </MenuItem>
    )
}
