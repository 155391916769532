import { DeploymentMode, DeploymentModeSecure } from '../../types/DeploymentTypes'
import { deploymentTypes } from '../types'

const setDeploymentMode = (mode: DeploymentMode | DeploymentModeSecure) => {
    return { type: deploymentTypes.SET_DEPLOYMENT_MODE, payload: mode }
}

export const setDeploymentAppName = (appName: string) => {
    return { type: deploymentTypes.SET_DEPLOYMENT_APP_NAME, payload: appName }
}

export const deploymentCreators = {
    setDeploymentMode,
    setDeploymentAppName,
}
