import React from 'react'
import makeStyles from '@mui/styles/makeStyles'

import { FormColumn, FormSection } from 'genesis-suite/types/visualTypes'
import ConfigHeader from './ConfigHeader'
import { BuilderProps } from './EditFormTypes'
import ColumnTabs from './table_form/ColumnTabs'
import InputFormBuilder from './input_form/InputFormBuilder'

const useStyles = makeStyles(({ spacing }) => ({
    formFieldsRoot: {
        width: 750,
        display: 'flex',
        flexFlow: 'column',
        margin: spacing(1),
        marginLeft: spacing(3),
    },
}))

export default function FormFields(props: BuilderProps) {
    const classes = useStyles()
    const { config, onFieldSelect, editKey, onChange, properties } = props

    const handleColumns = (columns: FormColumn[]) => onChange({ columns })

    const handleSections = (sections: FormSection[]) => onChange({ sections })

    return (
        <div className={classes.formFieldsRoot}>
            <ConfigHeader title={config.formType === 'table' ? 'Column Configuration' : 'Prompt Configuration'} />
            {config.formType === 'table' ? (
                <ColumnTabs
                    columnId={editKey}
                    properties={properties}
                    columns={config.columns}
                    onChange={handleColumns}
                    onFieldSelect={onFieldSelect}
                />
            ) : (
                <InputFormBuilder properties={properties} onChange={handleSections} sections={config.sections} />
            )}
        </div>
    )
}
