import copperwireLogo from '../assets/logos/copperwire/logo'
import tadanowLogo from '../assets/logos/tadanow/logo'

export function getAppLogo() {
    const environment = window.TADA_APIS.ENVIRONMENT

    switch (environment) {
        case 'copperwire': {
            return copperwireLogo
        }
        default: {
            return tadanowLogo
        }
    }
}
