import { ArrowBackRounded as ArrowLeft, ArrowForwardRounded as ArrowRight } from '@mui/icons-material'
import { Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { goBack, goForward } from 'redux-first-history'

import { MenuIcon } from 'genesis-suite/components'
import { logEvent } from '../lib/amplitudeClient'
import { TourTag } from '../lib/tourSteps'
import { widgetSelectors } from '../selectors'
import SessionHistory from './SessionHistory'

export default function HistoryController() {
    const { back, forward } = useSelector(s => s.history)
    const router = useSelector(s => s.router)
    const editing = Boolean(useSelector(widgetSelectors.getEditingPerspective))
    const dispatch = useDispatch()

    const handleBack = () => {
        dispatch(goBack())
        logEvent('PERSPECTIVE_GO_BACK')
    }

    const handleForward = () => {
        dispatch(goForward())
        logEvent('PERSPECTIVE_GO_FORWARD')
    }

    const disableBack = editing || !back.length || back[0].location.key === router.location.key
    const disableForward = editing || !forward.length
    return (
        <Box data-tour={TourTag.HistoryController} sx={{ display: 'flex', alignItems: 'center' }}>
            <MenuIcon
                icon={<ArrowLeft />}
                tooltip="Go back"
                buttonProps={buttonProps}
                onClick={handleBack}
                disabled={disableBack}
            />
            <SessionHistory buttonProps={buttonProps} />
            <MenuIcon
                icon={<ArrowRight />}
                tooltip="Go forward"
                buttonProps={buttonProps}
                onClick={handleForward}
                disabled={disableForward}
            />
        </Box>
    )
}

const buttonProps = { sx: { color: 'text.primary', padding: '1px' } }
