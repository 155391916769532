import { useContext } from 'react'
import { Box } from '@mui/material'

import { MenuIcon } from 'genesis-suite/components'
import { Filter as FilterIcon } from 'genesis-suite/icons'
import EditFilters from '../../../EditFilters'
import { DashboardContext } from '../DashboardContext'

export default function EditFiltersAction() {
    const { config, updateConfig } = useContext(DashboardContext)

    return (
        <MenuIcon title="Edit filters" icon={<FilterIcon />} buttonProps={{ sx: { color: 'inherit' } }}>
            <Box width="500px" height="280px">
                <EditFilters filters={config?.filters} onChange={filters => updateConfig({ filters })} />
            </Box>
        </MenuIcon>
    )
}
