import { notificationCreators, moduleCreators } from '../actions/creators'

export const subscribeToNotifications = (model, cloud, moduleId, moduleName) => {
    const { $ } = window
    const { connection } = $

    const subscriber = {
        id: connection.hub.id,
        MessageFilters: {
            VisorId: moduleId,
            VisorName: moduleName,
            ModelName: model,
            CloudName: cloud,
        },
    }

    connection.TADANotificationsHub.server.subscribe(null, subscriber)
}

export const registerNotificationClientFunctions = dispatch => {
    const { $ } = window
    const notificationHub = $.connection.TADANotificationsHub

    if (notificationHub) {
        notificationHub.client.notify = message => {
            const { MessageType } = message
            switch (MessageType) {
                case 'VisorChanged':
                    dispatch(moduleCreators.updateDetected(message))
                    break
                case 'TadaNotification':
                    const { messageContents } = message
                    if (!messageContents) return
                    const action = messageContents.Action

                    if (!action || action.length < 1) {
                        const notification = messageContents.Notification
                        dispatch(notificationCreators.processNotification(notification))
                    } else {
                        switch (action.toLocaleLowerCase()) {
                            case 'ruledeleted':
                                const { BusinessRuleId } = messageContents
                                dispatch(notificationCreators.notificationRuleDeleted(BusinessRuleId))
                                break
                        }
                    }
            }
        }
    }
}
