import { useState } from 'react'
import { Box, List, ListItem, ListItemIcon, ListItemText, Chip } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-pro'
import MoreIcon from '@mui/icons-material/MoreVert'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import CopyIcon from '@mui/icons-material/FileCopyOutlined'
import SettingsIcon from '@mui/icons-material/SettingsOutlined'

import { ChopText, DateOrTime, MenuIcon } from 'genesis-suite/components'
import TableHeaderCell from '../TableHeaderCell'
import ViewStatusIndicator from '../ViewStatusIndicator'
import { Module } from 'genesis-suite/types/visualTypes'
import { ModuleMetaFilter } from '../../types/ManageAppTypes'
import { filterVisualObjectsByViewMode, userDisplayName } from '../../lib/manageUtils'

export const authorKey = 'author'
export const createdBySortKey = 'createdBy.lastName'

const typeHelpText =
    'Method used to create Apps, Widgets and Perspectives. Visor provides a legacy experience, while Builder is a modular approach.'

const activeHelpText =
    'Visuals (Apps, Collections, and Widgets) that can be seen and edited by other Super Users and Admins. Apps built with Builder will use the review process.'

export interface RowData extends Module {
    author: string
    updatedByString: string
}

export const makeRows = (modules: Module[], filter: ModuleMetaFilter['filter']): RowData[] => {
    const filteredModules = filterVisualObjectsByViewMode(modules)
    if (!filteredModules) return []

    let moduleRows: RowData[] = filteredModules.map(m => {
        const createdBy = m.version === '2' && m.createdBy ? userDisplayName(m.createdBy) : `${m.createdBy ?? ''}`

        const updatedByString = m.version === '2' && m.updatedBy ? userDisplayName(m.updatedBy) : `${m.updatedBy ?? ''}`

        return {
            ...m,
            author: createdBy,
            updatedByString,
        }
    })

    if (filter.textSearch) {
        const searchRegex = new RegExp(filter.textSearch, 'i')
        moduleRows = moduleRows.filter(module => {
            return Object.keys(module).some(key => {
                return searchRegex.test(module[key]?.toString())
            })
        })
    }
    return moduleRows
}

export const createModuleCopyTitle = (origTitle: string, allModules: Module[]) => {
    let duplicateTitleFound = false
    let copyNum = 0
    let moduleCopyTitle = ''

    do {
        copyNum++
        moduleCopyTitle = `${origTitle} (${copyNum})`
        duplicateTitleFound = allModules.some(module => module.title?.toLowerCase() === moduleCopyTitle.toLowerCase())
    } while (duplicateTitleFound)

    return moduleCopyTitle
}

function ActionsCell({ row, onCopy, onDelete, onSettings }) {
    const [open, setOpen] = useState(false)
    const iconStyles = { color: 'text.primary' }

    function handleSettings() {
        const { author, updatedByString, ...data } = row
        onSettings(data)
        setOpen(false)
    }

    function handleDelete() {
        onDelete(row.id)
        setOpen(false)
    }

    function handleCopy() {
        onCopy(row)
        setOpen(false)
    }

    return (
        <Box>
            <MenuIcon
                icon={<MoreIcon fontSize="small" />}
                buttonProps={{ size: 'small' }}
                open={open}
                onClick={() => setOpen(true)}
                onClose={() => setOpen(false)}
            >
                <List dense disablePadding>
                    <ListItem button onClick={handleSettings}>
                        <ListItemIcon>
                            <SettingsIcon sx={iconStyles} />
                        </ListItemIcon>
                        <ListItemText primary="Settings" />
                    </ListItem>
                    {row?.version === '2' && (
                        <>
                            <ListItem button onClick={handleCopy}>
                                <ListItemIcon>
                                    <CopyIcon sx={iconStyles} />
                                </ListItemIcon>
                                <ListItemText primary="Copy" />
                            </ListItem>
                            <ListItem button onClick={handleDelete}>
                                <ListItemIcon>
                                    <DeleteIcon sx={iconStyles} />
                                </ListItemIcon>
                                <ListItemText primary="Delete" />
                            </ListItem>
                        </>
                    )}
                </List>
            </MenuIcon>
        </Box>
    )
}

export function makeColumns(
    onCopy: (module: Module) => void,
    onDelete: (moduleId: string) => void,
    onOpen: (moduleId: string) => void,
    onSettings: () => void
): GridColDef[] {
    return [
        {
            disableColumnMenu: true,
            field: 'title',
            pinnable: false,
            renderHeader: () => <TableHeaderCell title="Title" />,
            renderCell: ({ value, row }) => {
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <ViewStatusIndicator type={row?.draft?.type} sx={{ mr: 1 }} />
                        <ChopText
                            showEllipsis
                            sx={{
                                cursor: 'pointer',
                                '&:hover': { textDecoration: 'underline' },
                            }}
                            onClick={() => onOpen(row.id)}
                        >
                            {value}
                        </ChopText>
                    </Box>
                )
            },
            sortable: true,
            sortingOrder: ['asc', 'desc', null],
            flex: 2,
        },
        {
            disableColumnMenu: true,
            field: 'modules',
            pinnable: false,
            renderHeader: () => <TableHeaderCell title="Module" />,
            renderCell: ({ value }) =>
                value?.map((v, i) => (
                    <ListItem key={v.Name} sx={{ paddingLeft: '2px', paddingRight: '2px', width: 'auto' }}>
                        <ChopText showEllipsis>{value.length - 1 === i ? v.Name : v.Name + ' |'}</ChopText>
                    </ListItem>
                )),
            sortable: true,
            sortingOrder: ['asc', 'desc', null],
            flex: 2,
        },
        {
            disableColumnMenu: true,
            field: authorKey,
            pinnable: false,
            renderHeader: () => <TableHeaderCell title="Author" />,
            renderCell: ({ value }) => <ChopText showEllipsis>{value}</ChopText>,
            sortable: true,
            sortingOrder: ['asc', 'desc', null],
            flex: 1,
        },
        {
            headerAlign: 'center',
            align: 'center',
            disableColumnMenu: true,
            field: 'createdAt',
            pinnable: false,
            renderHeader: () => <TableHeaderCell title="Created" />,
            renderCell: ({ value }) => <DateOrTime>{value}</DateOrTime>,
            resizable: false,
            sortable: true,
            sortingOrder: ['desc', 'asc', null],
            width: 100,
        },
        {
            disableColumnMenu: true,
            field: 'updatedByString',
            pinnable: false,
            renderHeader: () => <TableHeaderCell title="Modified By" />,
            renderCell: ({ value }) => <ChopText showEllipsis>{value}</ChopText>,
            sortable: true,
            sortingOrder: ['asc', 'desc', null],
            width: 200,
        },
        {
            headerAlign: 'center',
            align: 'center',
            disableColumnMenu: true,
            field: 'updatedAt',
            pinnable: false,
            renderHeader: () => <TableHeaderCell title="Modified" />,
            renderCell: ({ value }) => <DateOrTime>{value}</DateOrTime>,
            resizable: false,
            sortable: true,
            sortingOrder: ['desc', 'asc', null],
            width: 100,
        },
        {
            headerAlign: 'center',
            align: 'center',
            disableColumnMenu: true,
            field: 'version',
            pinnable: false,
            resizable: false,
            renderHeader: () => <TableHeaderCell title="Type" helpText={typeHelpText} />,
            renderCell: ({ value }) => (
                <Chip
                    sx={{ width: 56 }}
                    label={value === '2' ? 'Builder' : 'Visor'}
                    color="primary"
                    variant="outlined"
                    size="small"
                />
            ),
            sortable: false,
            sortingOrder: ['asc', 'desc', null],
            width: 100,
        },
        {
            headerAlign: 'center',
            align: 'center',
            disableColumnMenu: true,
            field: 'active',
            pinnable: false,
            resizable: false,
            renderHeader: () => <TableHeaderCell title="Public" helpText={activeHelpText} />,
            renderCell: ({ row }) => (row.version === '1' ? 'Yes' : row.active ? 'Yes' : 'No'),
            sortable: false,
            sortingOrder: ['asc', 'desc', null],
            width: 100,
        },
        {
            disableColumnMenu: true,
            field: 'actions',
            pinnable: false,
            renderHeader: () => <TableHeaderCell title="" />,
            renderCell: ({ row }) => (
                <ActionsCell row={row} onCopy={onCopy} onDelete={onDelete} onSettings={onSettings} />
            ),
            resizable: false,
            sortable: false,
            width: 20,
        },
    ]
}
