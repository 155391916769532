import { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import MobileNavigation from '../components/MobileNavigation'
import SuspenseLoader from '../components/SuspenseLoader'
import TopNav from '../components/TopNav'
import { subPaths } from '../lib/routes'
import MessageBar from '~/components/Messenger/MessageBar'

const EditWidgetView = lazy(() => import('./EditWidgetView'))
const TVView = lazy(() => import('./TVView'))
const AppView = lazy(() => import('./AppView'))

const AppLayout = () => {
    const { PERSPECTIVE } = subPaths
    const routes = [
        {
            path: 'edit/:widgetID',
            element: (
                <SuspenseLoader>
                    <EditWidgetView />
                </SuspenseLoader>
            ),
        },
        {
            path: `${PERSPECTIVE}/tv`,
            element: (
                <SuspenseLoader>
                    <TVView />
                </SuspenseLoader>
            ),
        },
        {
            path: '*',
            element: (
                <SuspenseLoader>
                    <AppView />
                </SuspenseLoader>
            ),
        },
    ]
    return (
        <>
            <TopNav />
            <Routes>
                {routes.map(props => (
                    <Route key={props.path} {...props} />
                ))}
            </Routes>
            {/* <MobileNavigation /> */}

            <MessageBar />
        </>
    )
}

export default AppLayout
