import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import makeStyles from '@mui/styles/makeStyles'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    ListItemButton,
    MenuList,
    Typography,
    Box,
    List,
    IconButton,
    Collapse,
    ListSubheader,
    Button,
    TextField,
    Badge,
    Tab,
} from '@mui/material'
import { ExpandLessRounded, ExpandMoreRounded, ExpandMore } from '@mui/icons-material'
import { ChopText } from 'genesis-suite/components'
import { PublishScenarioStatus, Scenario } from 'genesis-suite/types/networkTypes'

import { applicationSelectors, authSelectors, scenarioSelectors } from '~/selectors'
import CriteriaDisplay from '~/components/scenarios/CriteriaDisplay'
import { scenarioCreators } from '~/actions/creators'
import { scenarioService } from '~/lib/services'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { formatDateTime } from '~/lib/utils'
import useScenarios from '~/components/scenarios/useScenarios'

const useStyles = makeStyles(({ palette, spacing }) => ({
    popoverTitle: {
        padding: spacing(1),
        position: 'sticky',
        top: 0,
        fontWeight: 'bold',
        height: 37,
    },
    moduleList: {
        flex: 1,
        overflowY: 'auto',
        minWidth: 300,
        maxWidth: '100%',
        paddingTop: 0,
    },
    container: {
        margin: spacing(0, 1),
        display: 'flex',
        color: 'white',
    },
    accordionSummary: {
        maxHeight: 35,
        minHeight: '35px !important',
        justifyContent: 'space-between',
        padding: spacing(0.5),
        paddingRight: spacing(2),
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content .Mui-expanded': {
            fontWeight: 'bold',
        },
        '& .MuiAccordionSummary-content': {
            maxWidth: '80%',
            marginLeft: spacing(1),
        },
        '& .MuiAccordionSummary-expandIconWrapper': {
            '& .MuiSvgIcon-root': {
                color: palette.text.primary,
            },
        },
    },
    accordionDetails: {
        padding: spacing(0, 2),
    },
    accordion: {
        '&.MuiAccordion-root:before': {
            display: 'none',
        },
    },
    listContainer: {
        marginTop: spacing(1),
        border: '1px solid',
        borderColor: '#BDBDBD',
        borderRadius: spacing(0.5),
    },
    listIcon: {
        display: 'flex',
        marginLeft: 'auto',
        alignItems: 'center',
        '& .MuiSvgIcon-root': {
            color: palette.text.primary,
        },
    },
    collapseList: {
        margin: 0,
        paddingTop: 0,
        padding: spacing(1),
        border: '1px solid',
        borderColor: '#BDBDBD',
        borderRadius: spacing(0.5),
        borderTop: 0,
    },
    subHeader: {
        color: palette.text.primary,
        lineHeight: '28px',
        fontWeight: 600,
        padding: spacing(0, 1),
    },
    actionDisc: {
        color: palette.text.primary,
        padding: spacing(0, 1),
    },
    subHeaderDetails: { padding: spacing(0, 2) },
    buttonsSection: {
        display: 'flex',
        gap: spacing(1),
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: spacing(2),
    },
}))

export function ScenarioComponent({ Header }) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const [scenarios] = useScenarios()

    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const isPublish = useSelector(authSelectors.getCanPublishScenario)
    const isApprove = useSelector(authSelectors.getCanApproveScenario)

    const isRequestedByUser = useSelector(scenarioSelectors.getIsRequestedByCurrentUser)
    const toApproveRequests = useSelector(scenarioSelectors.getToApproveByCurrentUser)

    const [isBtnLoading, setIsBtnLoading] = useState(false)
    const [tabPanel, setTabPanel] = useState('1')

    const userRequests = useMemo(() => {
        const finalObj = {
            activeRequests: [],
            approvedRequests: [],
            canceledRequests: [],
        }
        isRequestedByUser.map(scenario => {
            if (scenario?.status === PublishScenarioStatus.OPEN) {
                finalObj.activeRequests.push(scenario)
            }
            if (scenario?.status === PublishScenarioStatus.APPROVE) {
                finalObj.approvedRequests.push(scenario)
            }
            if (
                scenario?.status === PublishScenarioStatus.ABANDON ||
                scenario?.status === PublishScenarioStatus.REJECT
            ) {
                finalObj.canceledRequests.push(scenario)
            }
        })

        return finalObj
    }, [isRequestedByUser])

    const onReject = async (selectedSenario, remark) => {
        setIsBtnLoading(true)
        try {
            await scenarioService.updatePublishScenario({
                requestId: selectedSenario?.requestId,
                scenarioId: selectedSenario?.scenarioId,
                modelname: appName,
                status: PublishScenarioStatus.REJECT,
                remark: remark ?? null,
            })
            dispatch(scenarioCreators.getUserScenario())
        } catch (error) {
            console.error(error)
            showSnackbar('Request Error', { variant: 'error', preventDuplicate: true })
        } finally {
            setIsBtnLoading(false)
        }
    }

    const onAbandon = async selectedSenario => {
        setIsBtnLoading(true)
        try {
            await scenarioService.updatePublishScenario({
                requestId: selectedSenario?.requestId,
                scenarioId: selectedSenario?.scenarioId,
                modelname: appName,
                status: PublishScenarioStatus.ABANDON,
            })
            dispatch(scenarioCreators.getUserScenario())
        } catch (error) {
            console.error(error)
            showSnackbar('Request Error', { variant: 'error', preventDuplicate: true })
        } finally {
            setIsBtnLoading(false)
        }
    }

    const onApprove = async (selectedSenario, remark) => {
        setIsBtnLoading(true)
        try {
            await scenarioService.updatePublishScenario({
                requestId: selectedSenario?.requestId,
                scenarioId: selectedSenario?.scenarioId,
                modelname: appName,
                status: PublishScenarioStatus.APPROVE,
                remark: remark ?? null,
            })
            dispatch(scenarioCreators.getUserScenario())
        } catch (error) {
            console.error(error)
            showSnackbar('Request Error', { variant: 'error', preventDuplicate: true })
        } finally {
            setIsBtnLoading(false)
        }
    }

    return (
        <Box sx={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
            {Header && (
                <Header>
                    <Typography variant="h6">Scenario Publish Requests</Typography>
                </Header>
            )}
            <MenuList className={classes.moduleList} sx={{ flex: 1, overflow: 'auto' }}>
                <Box sx={theme => ({ padding: theme.spacing(1) })}>
                    {isPublish && (
                        <Accordion className={classes.accordion}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="my_requests"
                                id="my_requests"
                                className={classes.accordionSummary}
                            >
                                Created By Me
                            </AccordionSummary>
                            <AccordionDetails className={classes.accordionDetails}>
                                <TabContext value={tabPanel}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList
                                            sx={{ '& .MuiTab-root': { color: 'text.primary' } }}
                                            centered
                                            onChange={(_, newVal) => setTabPanel(newVal)}
                                        >
                                            <Tab label="Active" value="1" />
                                            <Tab label="Approved" value="2" />
                                            <Tab label="Canceled" value="3" />
                                        </TabList>
                                    </Box>
                                    <ScenarioTabPanel
                                        value="1"
                                        requests={userRequests?.activeRequests}
                                        status="active"
                                        onAbandon={onAbandon}
                                        scenarios={scenarios}
                                    />
                                    <ScenarioTabPanel
                                        value="2"
                                        requests={userRequests?.approvedRequests}
                                        status="accepted"
                                        scenarios={scenarios}
                                    />
                                    <ScenarioTabPanel
                                        value="3"
                                        requests={userRequests?.canceledRequests}
                                        status="canceled"
                                        scenarios={scenarios}
                                    />
                                </TabContext>
                            </AccordionDetails>
                        </Accordion>
                    )}
                    {isApprove && (
                        <Accordion className={classes.accordion}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="assigned_to_me"
                                id="assigned_to_me"
                                className={classes.accordionSummary}
                            >
                                <Badge badgeContent={toApproveRequests.length} color="primary">
                                    Assigned to me
                                </Badge>
                            </AccordionSummary>
                            <AccordionDetails className={classes.accordionDetails}>
                                {!toApproveRequests ? null : toApproveRequests.length > 0 ? (
                                    <List sx={{ height: '100%', width: '100%', overflow: 'auto' }}>
                                        {toApproveRequests.map(scenario => {
                                            return (
                                                <ScenarioDisplay
                                                    key={scenario.id}
                                                    scenario={scenario}
                                                    isAssign={true}
                                                    isLoading={isBtnLoading}
                                                    onReject={onReject}
                                                    onApprove={onApprove}
                                                    scenarios={scenarios}
                                                />
                                            )
                                        })}
                                    </List>
                                ) : (
                                    <Box className={classes.loader}>
                                        <Typography variant="body1">No Scenarios Assigned</Typography>
                                    </Box>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    )}
                </Box>
            </MenuList>
        </Box>
    )
}

interface ScenarioTabPanelProps {
    value: string
    requests: Array<Scenario>
    scenarios: Array<Scenario>
    status: 'active' | 'accepted' | 'canceled'
    onAbandon?: (scenario: any) => void
}

const ScenarioTabPanel = ({ value, requests, status, onAbandon, scenarios }: ScenarioTabPanelProps) => {
    const classes = useStyles()
    return (
        <TabPanel sx={{ padding: 0 }} value={value}>
            {!requests ? null : requests.length > 0 ? (
                <List sx={{ height: '100%', width: '100%', overflow: 'auto' }}>
                    {requests.map(scenario => (
                        <ScenarioDisplay
                            key={scenario.id}
                            scenario={scenario}
                            onAbandon={onAbandon}
                            isPublish={status}
                            scenarios={scenarios}
                        />
                    ))}
                </List>
            ) : (
                <Box className={classes.loader}>
                    <Typography variant="body1">No Scenarios Requested</Typography>
                </Box>
            )}
        </TabPanel>
    )
}
interface ScenarioDisplayProps {
    scenario: Scenario
    scenarios: Array<Scenario>
    isAssign?: boolean
    isPublish?: 'active' | 'accepted' | 'canceled'
    isLoading?: boolean
    onReject?: (scenario: any, remark: string) => void
    onApprove?: (scenario: any, remark: string) => void
    onAbandon?: (scenario: any) => void
}

function ScenarioDisplay({
    scenario,
    scenarios = [],
    isAssign = false,
    isPublish,
    isLoading = false,
    onReject,
    onApprove,
    onAbandon,
}: ScenarioDisplayProps) {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [remark, setRemark] = useState('')

    const prevent = (e: React.MouseEvent) => {
        e.stopPropagation()
        e.preventDefault()
    }

    const toggle = e => {
        prevent(e)
        setOpen(!open)
    }

    const scenerioDetail = scenarios?.find(obj => obj.id === scenario.scenarioId)

    return (
        <>
            <ListItemButton className={classes.listContainer} onClick={toggle}>
                <ChopText tooltipProps={{ placement: 'top' }} showEllipsis variant="subtitle1">
                    {scenario?.scenarioName}
                </ChopText>
                <div className={classes.listIcon}>
                    <IconButton>{open ? <ExpandLessRounded /> : <ExpandMoreRounded />}</IconButton>
                </div>
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List className={classes.collapseList}>
                    <ListSubheader className={classes.subHeader}>Note:</ListSubheader>
                    <Box className={classes.subHeaderDetails}>{scenario?.notes}</Box>
                    {scenerioDetail && (
                        <>
                            <ListSubheader className={classes.subHeader}>Scenario Conditions:</ListSubheader>
                            {scenerioDetail?.criterias?.map((c, i) => (
                                <Box className={classes.subHeaderDetails}>
                                    <CriteriaDisplay key={c.id} criteria={c} index={i} />
                                </Box>
                            ))}
                        </>
                    )}
                    {scenario?.remarks && (isPublish === 'accepted' || isPublish === 'canceled') && (
                        <>
                            <ListSubheader className={classes.subHeader}>Remark:</ListSubheader>
                            <Box className={classes.subHeaderDetails}>{scenario?.remarks}</Box>
                        </>
                    )}
                    {isPublish === 'accepted' && (
                        <Box className={classes.actionDisc}>
                            Scenario Publish Approved by <strong>{scenario?.approvedBy}</strong> on{' '}
                            <strong>{formatDateTime(scenario?.approvedOn)} CST</strong>
                        </Box>
                    )}
                    {isPublish === 'canceled' && (
                        <Box className={classes.actionDisc}>
                            Scenario Request Canceled by <strong>{scenario?.updatedBy}</strong> on{' '}
                            <strong>{formatDateTime(scenario?.updatedOn)} CST</strong>
                        </Box>
                    )}
                    {isPublish === 'active' && (
                        <Box className={classes.buttonsSection}>
                            <Button
                                color="error"
                                variant="contained"
                                disabled={isLoading}
                                onClick={() => onAbandon(scenario)}
                            >
                                Abandon
                            </Button>
                        </Box>
                    )}
                    {isAssign && (
                        <>
                            <TextField
                                label="Remark (optional)"
                                multiline={true}
                                minRows={2}
                                fullWidth
                                sx={{
                                    marginY: '8px',
                                    '& .MuiInputLabel-root': {
                                        color: 'text.primary',
                                    },
                                    '& .MuiInputBase-root:before': {
                                        borderBottomColor: 'text.primary',
                                    },
                                }}
                                value={remark}
                                color="primary"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setRemark(event.target.value)
                                }}
                            />
                            <Box className={classes.buttonsSection}>
                                <Button
                                    color="error"
                                    variant="contained"
                                    disabled={isLoading}
                                    onClick={() => onReject(scenario, remark)}
                                >
                                    Reject
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    disabled={isLoading}
                                    onClick={() => onApprove(scenario, remark)}
                                >
                                    Approve
                                </Button>
                            </Box>
                        </>
                    )}
                </List>
            </Collapse>
        </>
    )
}
