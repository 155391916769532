import {
    ChartType, CustomTooltip, DataGroup, DataIndexes, DataRequest, DataResponse, SeriesConfig, TooltipType
} from 'genesis-suite/types/visualTypes'
import { replaceMustacheValues } from '../../../lib/utils'
import { dataSourceIsEqual } from '../../edit_widget/utils'
import { aggregateFunction, arithmeticFunction } from './aggregateFunctions'
import { makeDefaultTooltip } from './configDefaults'
import formatNumber from './formatNumber'
import getDataGroup from './getDataGroup'
import isRawSeries from './isRawSeries'
import makeBaseDataRequest from './makeBaseDataRequest'

export default function makeTooltipMarkdown(config: SeriesConfig, data: DataResponse, indexes: DataIndexes) {
    if (!data || !indexes) return

    const _indexes = { ...indexes, series: indexes.series ?? 0 }
    const isRaw = isRawSeries(config.series[indexes.series])
    return isRaw ? makeRaw(config, data, _indexes) : makeAggregated(config, data, _indexes)
}

function makeRaw(config: SeriesConfig, data: DataResponse, indexes: DataIndexes) {
    if (!indexes.raw) return

    const { markdown, configs } = makeTooltip(config, indexes) ?? {}
    if (!markdown) return

    const { rawData, count } = data[indexes.series]?.data[0] ?? {}
    const row = rawData[indexes.raw.row]
    if (!row) return

    const seriesConfig = config.series[indexes.series]

    const values = configs.map(({ source, format }) => {
        const index = seriesConfig.values.findIndex(r => dataSourceIsEqual(r, source))
        const v = row[index]
        return typeof v === 'string' ? v : formatNumber(v ?? 1234, format)
    })

    let displayMarkdown = replaceMustacheValues(markdown, values)
    displayMarkdown = displayMarkdown.replace(new RegExp(aggregateFunction.totalRecords.regexp, 'g'), count.toString())
    return displayMarkdown
}

function makeAggregated(config: SeriesConfig, data: DataResponse, indexes: DataIndexes) {
    const dataGroup = getDataGroup(data, indexes)
    if (!dataGroup) return

    const { markdown, configs } = makeTooltip(config, indexes) ?? {}
    if (!markdown) return

    const categoryDataGroup = indexes.subSeries
        ? getDataGroup(data, { ...indexes, groupNames: indexes.groupNames.slice(0, -1) })
        : dataGroup

    const { group, aggregatedData, count } = dataGroup

    const dataRequest = makeBaseDataRequest(config)
    if (!dataRequest) return

    const requestSeries = dataRequest.series[indexes.series]
    //Adding unformatted values to each config object
    const configsWithValues = configs.map(c => {
        const index = requestSeries.values.findIndex(r => dataSourceIsEqual(r, c.source))
        return { ...c, value: aggregatedData?.[index] ?? 1234 }
    })

    let displayMarkdown = markdown

    displayMarkdown = replaceValueTotal(displayMarkdown, configs, requestSeries, data, indexes)
    displayMarkdown = replaceCategoryValueTotal(displayMarkdown, configs, requestSeries, data, indexes)
    displayMarkdown = replacePercentOfTotal(displayMarkdown, configs, requestSeries, data, indexes)
    displayMarkdown = replacePercentOfCategory(displayMarkdown, configs, requestSeries, data, indexes)
    displayMarkdown = replaceArithmeticOperations(displayMarkdown, configsWithValues)
    //Formatting all values to replace mustache
    const formattedValues = configsWithValues.map(c => formatNumber(c.value, c.format))
    displayMarkdown = replaceMustacheValues(displayMarkdown, formattedValues)
    displayMarkdown = displayMarkdown.replace(/\[category\]/g, categoryDataGroup.group)

    displayMarkdown = displayMarkdown.replace(
        new RegExp(aggregateFunction.totalRecords.regexp, 'g'),
        data[indexes.series].data
            .reduce((acc, cur) => {
                if (cur.children) acc += cur.children.reduce((acc, cur) => acc + cur.count, 0)
                else acc += cur.count
                return acc
            }, 0)
            .toString()
    )
    displayMarkdown = displayMarkdown.replace(
        new RegExp(aggregateFunction.totalCategoryRecords.regexp, 'g'),
        categoryDataGroup.count?.toString() ?? ''
    )

    if (indexes.groupNames) {
        displayMarkdown = displayMarkdown.replace(/\[sub-series\]/g, group)

        displayMarkdown = displayMarkdown.replace(
            new RegExp(aggregateFunction.totalSubSeriesRecords.regexp, 'g'),
            count?.toString() ?? ''
        )
    }

    return displayMarkdown
}

function makeTooltip(config: SeriesConfig, indexes: DataIndexes) {
    if (config.type === ChartType.TABLE) return

    const configSeries = config.series[indexes.series]
    const tooltipConfig = configSeries?.tooltip
    if (tooltipConfig?.disabled) return

    return tooltipConfig?.type === TooltipType.CUSTOM ? tooltipConfig : makeDefaultTooltip(config.type, configSeries)
}

function replaceArithmeticOperations(markdown: string, configs) {
    //Getting an array of unformatted values for arithmetic operations
    const values = configs.map(c => c.value.toString())
    let regExp = new RegExp(arithmeticFunction.addition.regexp)
    let matchedFunction
    while ((matchedFunction = regExp.exec(markdown)) !== null) {
        matchedFunction[0] = replaceMustacheValues(matchedFunction[0], values)
        //Getting format of the first property
        let format
        const matchedConfigIndex = matchedFunction[1].match(/{{([0-9]+)}}/)?.[1]
        if (!matchedConfigIndex) format = null
        else {
            const config = configs[parseInt(matchedConfigIndex)]
            format = config?.format
        }
        const total = add(matchedFunction[0])
        markdown = markdown.replace(regExp, formatNumber(total, format))
    }
    regExp = new RegExp(arithmeticFunction.subtraction.regexp)
    matchedFunction = null
    while ((matchedFunction = regExp.exec(markdown)) !== null) {
        matchedFunction[0] = replaceMustacheValues(matchedFunction[0], values)
        //Getting format of the first property
        let format
        const matchedConfigIndex = matchedFunction[1].match(/{{([0-9]+)}}/)?.[1]
        if (!matchedConfigIndex) format = null
        else {
            const config = configs[parseInt(matchedConfigIndex)]
            format = config?.format
        }
        const total = subtract(matchedFunction[0])
        markdown = markdown.replace(regExp, formatNumber(total, format))
    }
    regExp = new RegExp(arithmeticFunction.multiplication.regexp)
    matchedFunction = null
    while ((matchedFunction = regExp.exec(markdown)) !== null) {
        matchedFunction[0] = replaceMustacheValues(matchedFunction[0], values)
        //Getting format of the first property
        let format
        const matchedConfigIndex = matchedFunction[1].match(/{{([0-9]+)}}/)?.[1]
        if (!matchedConfigIndex) format = null
        else {
            const config = configs[parseInt(matchedConfigIndex)]
            format = config?.format
        }
        const total = multiply(matchedFunction[0])
        markdown = markdown.replace(regExp, formatNumber(total, format))
    }
    regExp = new RegExp(arithmeticFunction.division.regexp)
    matchedFunction = null
    while ((matchedFunction = regExp.exec(markdown)) !== null) {
        matchedFunction[0] = replaceMustacheValues(matchedFunction[0], values)
        //Getting format of the first property
        let format
        const matchedConfigIndex = matchedFunction[1].match(/{{([0-9]+)}}/)?.[1]
        if (!matchedConfigIndex) format = null
        else {
            const config = configs[parseInt(matchedConfigIndex)]
            format = config?.format
        }
        const total = divide(matchedFunction[0])
        markdown = markdown.replace(regExp, formatNumber(total, format))
    }
    return markdown
}

function replaceValueTotal(
    markdown: string,
    configs: CustomTooltip['configs'],
    requestSeries: DataRequest['series'][0],
    data: DataResponse,
    dataIndexes: DataIndexes
) {
    const regExp = new RegExp(aggregateFunction.valueTotal.regexp)
    let matchedFunction
    while ((matchedFunction = regExp.exec(markdown)) !== null) {
        const matchedProperty = matchedFunction[1]
        const { index, format } = getValue(configs, requestSeries, matchedProperty)
        if (index == null) return markdown

        const total = getDataGroupTotal(data[dataIndexes.series].data, index)
        markdown = markdown.replace(regExp, formatNumber(total, format))
    }
    return markdown
}

function replaceCategoryValueTotal(
    markdown: string,
    configs: CustomTooltip['configs'],
    requestSeries: DataRequest['series'][0],
    data: DataResponse,
    dataIndexes: DataIndexes
) {
    const regExp = new RegExp(aggregateFunction.categoryValueTotal.regexp)
    let matchedFunction
    while ((matchedFunction = regExp.exec(markdown)) !== null) {
        const matchedProperty = matchedFunction[1]
        const { index, format } = getValue(configs, requestSeries, matchedProperty)
        if (index == null) return markdown

        const total = getDataGroupTotal(
            data[dataIndexes.series].data.find(d => d.group === dataIndexes.groupNames[0]).children,
            index
        )
        markdown = markdown.replace(regExp, formatNumber(total, format))
    }
    return markdown
}

function replacePercentOfTotal(
    markdown: string,
    configs: CustomTooltip['configs'],
    requestSeries: DataRequest['series'][0],
    data: DataResponse,
    dataIndexes: DataIndexes
) {
    const regExp = new RegExp(aggregateFunction.percentOfTotal.regexp)
    let matchedFunction
    while ((matchedFunction = regExp.exec(markdown)) !== null) {
        const matchedProperty = matchedFunction[1]
        const { index } = getValue(configs, requestSeries, matchedProperty)
        if (index == null) return markdown

        const value = getDataGroup(data, dataIndexes).aggregatedData?.[index]
        const total = getDataGroupTotal(data[dataIndexes.series].data, index)
        const calc = formatNumber(value / total, { decimalPlaces: 1, isPercentage: true })
        markdown = markdown.replace(regExp, calc)
    }
    return markdown
}

function replacePercentOfCategory(
    markdown: string,
    configs: CustomTooltip['configs'],
    requestSeries: DataRequest['series'][0],
    data: DataResponse,
    dataIndexes: DataIndexes
) {
    const regExp = new RegExp(aggregateFunction.percentOfCategory.regexp)
    let matchedFunction
    while ((matchedFunction = regExp.exec(markdown)) !== null) {
        const matchedProperty = matchedFunction[1]
        const { index } = getValue(configs, requestSeries, matchedProperty)
        if (index == null) return markdown

        const value = getDataGroup(data, dataIndexes).aggregatedData?.[index]
        const total = getDataGroupTotal(
            data[dataIndexes.series].data.find(d => d.group === dataIndexes.groupNames[0]).children,
            index
        )
        const calc = formatNumber(value / total, { decimalPlaces: 1, isPercentage: true })
        markdown = markdown.replace(regExp, calc)
    }
    return markdown
}

function getValue(configs: CustomTooltip['configs'], requestSeries: DataRequest['series'][0], matchedProperty: string) {
    const matchedConfigIndex = matchedProperty.match(/{{([0-9]+)}}/)?.[1]
    if (!matchedConfigIndex) return {}

    const config = configs[parseInt(matchedConfigIndex)]
    if (!config) return {}

    return { index: requestSeries.values.findIndex(r => dataSourceIsEqual(r, config.source)), format: config.format }
}

function getDataGroupTotal(dataGroups: DataGroup[], valueIndex: number) {
    return dataGroups?.reduce((acc, cur) => {
        const amount = cur.children
            ? getDataGroupTotal(cur.children, valueIndex)
            : cur.aggregatedData?.[valueIndex] ?? 0
        return acc + amount
    }, 0)
}

function add(matchedProperty: string) {
    const startIndex = matchedProperty.indexOf('(')
    const endIndex = matchedProperty.indexOf(')')
    const matchedNumbers = matchedProperty.substring(startIndex + 1, endIndex).split(',')
    let total = 0
    for (const num of matchedNumbers) {
        total += Number(num.trim())
    }
    return total
}

function subtract(matchedProperty: string) {
    const startIndex = matchedProperty.indexOf('(')
    const endIndex = matchedProperty.indexOf(')')
    const matchedNumbers = matchedProperty.substring(startIndex + 1, endIndex).split(',')
    let total = Number(matchedNumbers?.[0].trim())
    for (let i = 1; i < matchedNumbers.length; i++) {
        total -= Number(matchedNumbers[i].trim())
    }
    return total
}

function multiply(matchedProperty: string) {
    const startIndex = matchedProperty.indexOf('(')
    const endIndex = matchedProperty.indexOf(')')
    const matchedNumbers = matchedProperty.substring(startIndex + 1, endIndex).split(',')
    let total = 1
    for (const num of matchedNumbers) {
        total *= Number(num.trim())
    }
    return total
}

function divide(matchedProperty: string) {
    const startIndex = matchedProperty.indexOf('(')
    const endIndex = matchedProperty.indexOf(')')
    const matchedNumbers = matchedProperty.substring(startIndex + 1, endIndex).split(',')
    let total = Number(matchedNumbers?.[0].trim())
    for (let i = 1; i < matchedNumbers.length; i++) {
        total /= Number(matchedNumbers[i].trim())
    }
    return total
}
