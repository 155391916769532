import { createContext } from 'react'
import { useBrowserPreferences } from '../../lib/browserPreferences'

export interface ColumnOptions {
    colId: string
    sort?: 'asc' | 'desc'
    width?: number
    hide?: boolean
    pinned?: 'left' | 'right' | boolean
}

type ColumnOptionsKey = keyof ColumnOptions
type ColOptProps = {
    options: ColumnOptions[]
    setOptions: (options: ColumnOptions[]) => void
    /**
     * Resets the column options to the default values (or just the provided key)
     */
    resetOptions: (keys?: ColumnOptionsKey[]) => void
}

const ColOptionsContext = createContext<ColOptProps>(null)

function ColOptionsProvider({ children, id }) {
    const [value = [], setOptions, clearBrowserPrefs] = useBrowserPreferences(`colOpts_${id}`)
    const options = migrateOptions(value, setOptions)

    const columnOptionsProviderValue: ColOptProps = {
        options,
        setOptions: newOptions => {
            setOptions(newOptions)
        },
        resetOptions: keys => {
            if (keys) {
                const newOptions = options.forEach(option => {
                    keys.forEach(key => {
                        delete option[key]
                    })
                })
                setOptions(newOptions)
            } else {
                clearBrowserPrefs()
            }
        },
    }

    return <ColOptionsContext.Provider value={columnOptionsProviderValue}>{children}</ColOptionsContext.Provider>
}

export { ColOptionsContext, ColOptionsProvider }

type ColumnOptionsV1 = {
    colName: string
    hidden: boolean
    index: number
    key: string
    width?: number
}

/** Migrate old column options */
function migrateOptions(options, updateOptions): ColumnOptions[] {
    if (!options) {
        return []
    } else if (!Array.isArray(options)) {
        const updated = []
        updateOptions(updated)
        return updated
    } else if (options[0]?.colName) {
        const updated = (options as ColumnOptionsV1[]).reduce((acc, { colName, width, hidden }) => {
            acc.push({
                colId: colName,
                width: width ? { [colName]: width } : undefined,
                hide: { [colName]: hidden },
                pinned: false,
                sort: undefined,
            })
            return acc
        }, [])
        updateOptions(updated)
        return updated
    } else {
        return options
    }
}
