import { CSSProperties, forwardRef } from 'react'
import { Box, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Skeleton } from '@mui/material'
import tinyColor from 'tinycolor2'

import { CognitiveIcon } from 'genesis-suite/components'
import ChopText, { Props as ChopTextProps } from 'genesis-suite/components/ChopText'
import { LabelConfig } from 'genesis-suite/types/visualTypes'
import formatNumber from './utils/formatNumber'
import { getConditionalFormat } from './utils/getConditionalFormats'
import makeBaseDataRequest from './utils/makeBaseDataRequest'
import makeConditionalRules from './utils/makeConditionalRules'
import { WidgetProps } from '../../types/WidgetTypes'

const useLabelStyles = makeStyles(({ spacing }) => ({
    label: {
        height: '100%',
        flex: 'none',
        padding: spacing(),
        borderRadius: '8px',
        display: 'flex',
        gridGap: spacing(),
        alignItems: 'flex-end',
    },
    icon: { width: '40px' },
}))

export default forwardRef<HTMLDivElement, WidgetProps<LabelConfig>>(({ config, data, trellis }, ref) => {
    const theme = useTheme()
    const { title, series } = config
    const { icon, numberFormat } = series?.[0] || {}
    const classes = useLabelStyles()

    const values = data?.[0].data[0].aggregatedData
    const value = values?.[0]
    const displayValue = formatNumber(value, numberFormat)
    const style = createStyle(config, values, theme.palette.grayscale.light)
    const darkBackground = isDarkBackground(style?.backgroundColor)
    const textProps: ChopTextProps = {
        showEllipsis: true,
        color: !style.color && darkBackground ? '#fff' : 'inherit',
        tooltipProps: { placement: 'bottom' },
    }

    return (
        <div ref={ref} className={classes.label} style={style}>
            <Box
                flex={1}
                height="100%"
                overflow="hidden"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
            >
                {value == null ? (
                    <Skeleton variant="rectangular" width={80} height={32} />
                ) : (
                    <ChopText {...textProps} variant="h5">
                        {displayValue}
                    </ChopText>
                )}

                <ChopText {...textProps} variant="body1">
                    {trellis?.name || title || ' '}
                </ChopText>
            </Box>

            <CognitiveIcon className={classes.icon} white={darkBackground} icon={icon} />
        </div>
    )
})

function createStyle(config: LabelConfig, data: Array<number>, defaultBackground: string) {
    const style: CSSProperties = {}
    const seriesConfig = config?.series[0]
    const { format, conditionalFormats } = seriesConfig

    const dataRequest = makeBaseDataRequest(config)
    const seriesRequest = dataRequest.series[0]
    const rules = makeConditionalRules(conditionalFormats, seriesRequest)
    const c = getConditionalFormat(data, rules)
    if (c) {
        if (c.backgroundColor) style.backgroundColor = c.backgroundColor
        if (c.fontColor) style.color = c.fontColor
        if (c.borderColor) style.border = `2px solid ${c.borderColor}`
    }

    if (format) {
        if (!c?.backgroundColor && format.backgroundColor) style.backgroundColor = format.backgroundColor
        if (!c?.fontColor && format.fontColor) style.color = format.fontColor
        if (!c?.borderColor && format.borderColor) style.border = `2px solid ${format.borderColor}`
    }

    if (!style.backgroundColor) style.backgroundColor = defaultBackground
    if (!style.border) style.border = `1px solid ${tinyColor(style.backgroundColor).darken(20)}`

    return style
}

function isDarkBackground(backgroundColor) {
    if (!backgroundColor) return false

    const color = tinyColor(backgroundColor)
    return color.isDark()
}
