import { useSelector } from 'react-redux'
import { Chip } from '@mui/material'

import { Widget } from 'genesis-suite/types/visualTypes'
import WidgetInfoEmptyText from './WidgetInfoEmptyText'
import { insightSelectors } from '../../selectors'

export default function WidgetInfoSources({ config }) {
    const isV2 = config?.version === '2'

    return isV2 ? <WidgetInfoSourcesV2 config={config} /> : <WidgetInfoSourcesV1 config={config} />
}

function WidgetInfoSourcesV1({ config }) {
    const findDistinct = (value, index, self) => {
        return self.indexOf(value) === index
    }

    const isSingleSource = widgetType => {
        const singleSourceWidgetList = ['table', 'pnl', 'bubblechart']
        if (singleSourceWidgetList.includes(widgetType.toLowerCase())) return true
        return false
    }

    const isMultiSource = widgetType => {
        const multiSourceWidgetList = ['map', 'label', 'chart']
        if (multiSourceWidgetList.includes(widgetType.toLowerCase())) return true
        return false
    }

    let insights = []
    if (isSingleSource(config.Type)) insights.push(config[`${config.Type}Config`].Source.ElementName)
    else if (isMultiSource(config.Type)) insights = config.Sources

    if (insights != null && insights.length > 0) {
        insights = insights.filter(findDistinct)
    }

    if (!insights.length) return <WidgetInfoEmptyText>No source insights available</WidgetInfoEmptyText>

    return (
        <>
            {insights.map((item, index) => {
                return <Chip key={item} label={item}></Chip>
            })}
        </>
    )
}

function WidgetInfoSourcesV2({ config }: { config: Widget }) {
    const insights = useSelector(insightSelectors.getList)
    const insightId = 'series' in config ? config.series?.[0]?.service.id : undefined
    const insight = insightId ? insights.find(i => i.id === insightId) : undefined

    if (!insight) return <WidgetInfoEmptyText>No source insights available</WidgetInfoEmptyText>

    return <Chip label={insight.name} />
}
