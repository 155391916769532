import { alpha, useTheme } from '@mui/material'
import { CognitiveIcon } from 'genesis-suite/components'
import { FormWidget, Perspective as PerspectiveIcon, Widget as WidgetIcon } from 'genesis-suite/icons'
import { useCallback, useContext, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import tinyColor from 'tinycolor2'
import { navigationCreators } from '../../actions/creators'
import { useIsMobile } from '../../hooks/useIsMobile'
import { filterSelectors, widgetSelectors } from '../../selectors'
import { PopoutContext } from '../contexts/PopoutWidgetContext'
import { useSnackbar } from 'notistack'
import { widgetConstants } from '../../constants'

const { DRILLDOWN } = widgetConstants.Interactions

export default function useWidgetAction(action): [() => void, JSX.Element] {
    const { IsPopUp, PerspectiveId, ActionName, ShortName, WidgetId, WidgetType } = action
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const { popoutWidget } = useContext(PopoutContext)
    const context = useSelector(filterSelectors.getCoord)
    const config = useSelector(state => widgetSelectors.getWidgetConfig(state, WidgetId || PerspectiveId))
    const inlineFilters = useSelector(filterSelectors.getInlineFilters)
    const theme = useTheme()
    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const interactionType = useSelector(widgetSelectors.getInteractionType)

    const handleClick = useCallback(
        (actionFilter = null) => {
            const isValidActionFilter =
                actionFilter && typeof actionFilter === 'object' && !('nativeEvent' in actionFilter)
            const processedActionFilter = isValidActionFilter ? { ...actionFilter } : null

            if (IsPopUp && !isMobile) {
                if (!config) {
                    showSnackbar('Action Widget config not found.', { variant: 'error' })
                    return
                }
                popoutWidget({ ...config, PerspectiveId: PerspectiveId, actionFilters: processedActionFilter })
            } else {
                const carryOverFilters = interactionType === DRILLDOWN

                dispatch(
                    navigationCreators.goToPerspective(WidgetId || PerspectiveId, {
                        context,
                        carryOverFilters,
                        inlineFilters: {
                            contexts: {
                                ...inlineFilters?.contexts,
                                ...processedActionFilter?.contexts,
                            },
                            filters: [...(inlineFilters?.filters || []), ...(processedActionFilter?.filters || [])],
                            widgetId: inlineFilters?.widgetId || processedActionFilter?.widgetId,
                            scrollTop: inlineFilters?.scrollTop,
                        },
                    })
                )
            }
        },
        [action, inlineFilters]
    )

    const icon = useMemo(() => {
        if (action.Icon)
            return (
                <CognitiveIcon
                    white={tinyColor(alpha(theme.palette.secondary.light, 0.7)).isDark()}
                    fontSize="small"
                    icon={{ type: 'tada', file: action.Icon }}
                />
            )
        if (!WidgetId) return <PerspectiveIcon color="secondary" fontSize="small" />
        switch (WidgetType) {
            case 25:
                return <FormWidget color="secondary" fontSize="small" />
            default:
                return <WidgetIcon color="secondary" fontSize="small" />
        }
    }, [action])

    return [handleClick, icon]
}
