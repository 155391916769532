import { IServerSideGetRowsRequest, RowNode, SortModelItem } from '@ag-grid-community/core'
import { PropertyMetaWithSemantic } from 'genesis-suite/types/networkTypes'
import {
    Aggregation,
    CategoryTableColumn,
    ComparisonOperator,
    DataRequest,
    FilterGroup,
    RawDataField,
    SeriesRequest,
    SortableDataSource,
    TableConfig,
    TableSeries,
} from 'genesis-suite/types/visualTypes'
import { isEmpty } from 'lodash'
import { agGridConstants } from '../../constants/aggrid.constants'
import { getPropertyMeta } from '../../hooks/useProperties'
import { createRequestField, createRequestSource, makeServiceRequest } from '../widgets2/utils/makeBaseDataRequest'
import makeValidFilters from '../widgets2/utils/makeValidFilters'

export const createBaseDataRequest = (
    requestParams: IServerSideGetRowsRequest,
    config: TableConfig,
    mappedPivotsColumns?: { [key: string]: string },
    properties?: PropertyMetaWithSemantic[],
    groupKeysStartingIndex = 0,
    filters: FilterGroup = null,
    parentNode?: RowNode<any>,
    cacheBlockSize?: number,
    networkFilters?: Array<string>
) => {
    const groupKeys = requestParams?.groupKeys.slice(groupKeysStartingIndex, requestParams?.groupKeys.length)
    const getDataFromNode = (parentNode: RowNode<any>, fieldName: string) => {
        if (!fieldName || !parentNode) return

        const parentNodeData = parentNode?.data
        if (parentNodeData?.[fieldName]) return parentNodeData[fieldName]

        if (parentNode.parent?.data) return getDataFromNode(parentNode.parent, fieldName)
    }

    const baseDataRequest: DataRequest = {
        application: config.appName,
        categories: getCategories(config.categories, groupKeys, requestParams ? config.collapsable : false),
        series: [
            {
                ...getSeries(config.series),
                page: requestParams && {
                    size:
                        (requestParams.endRow - requestParams.startRow) *
                        (config.series[0].subSeries ? cacheBlockSize || agGridConstants.CACHE_BLOCK_SIZE : 1),
                    number: requestParams.endRow / (cacheBlockSize || agGridConstants.CACHE_BLOCK_SIZE),
                },
            },
        ],
        //we only use the filters to get the data on the table first load (groupKeys.length === 0)
        //otherwise we use the groupKeys to make the filters
        filter: getRequestFilters(filters, groupKeys, config, parentNode, getDataFromNode),
        sort: (() => {
            //default sort created in the builder, when there is no header click
            if (requestParams?.sortModel.length === 0)
                return getDefaultSort(config.categories, config.series, groupKeys, config.collapsable)

            return getSortModel(
                requestParams?.sortModel,
                groupKeys,
                config.categories,
                config.series,
                mappedPivotsColumns,
                config.collapsable,
                properties
            )
        })(),
    }

    if (!isEmpty(networkFilters)) {
        baseDataRequest['NetworkFilters'] = networkFilters
    }
    return baseDataRequest
}

const getRequestFilters = (filters, groupKeys, config, parentNode, getDataFromNode) => {
    if (groupKeys.length === 0) {
        if (filters) return filters

        return makeValidFilters({
            type: 'group',
            group: 'AND',
            items: makeFilterItems(groupKeys, config, parentNode, getDataFromNode),
        })
    }

    if (!filters)
        return makeValidFilters({
            type: 'group',
            group: 'AND',
            items: makeFilterItems(groupKeys, config, parentNode, getDataFromNode),
        })

    if (groupKeys.length <= 1) {
        filters.items = [...filters.items, ...makeFilterItems(groupKeys, config, parentNode, getDataFromNode)]
    } else {
        filters.items.push(
            makeValidFilters({
                type: 'group',
                group: 'AND',
                items: makeFilterItems(groupKeys, config, parentNode, getDataFromNode),
            })
        )
    }

    return filters
}

const makeFilterItems = (groupKeys, config, parentNode, getDataFromNode) => {
    if (groupKeys.length === 0) return []
    const filters = []
    groupKeys.forEach((groupKey, index) => {
        filters.push({
            type: 'filter',
            field: config.categories[index].field,
            comparison: ComparisonOperator.EQUAL,
            values: [getDataFromNode(parentNode, config.categories[index].field?.name) || groupKey],
        })
    })
    return filters
}

export const createPivotSubSeriesRequest = (config: TableConfig) => {
    const baseDataRequest: DataRequest = {
        application: config.appName,
        series: [
            {
                ...getSeries(config.series),
            },
        ],
    }
    return baseDataRequest
}

const getCategories = (
    categories: CategoryTableColumn[],
    groupKeys: string[],
    collapsable: boolean
): RawDataField[] => {
    //visual feedback for the user when it's a collapsable table with one category, will show the same values as the grouped row
    if (categories.length === 1) return [createRequestField(categories[0].field, false)]

    if (collapsable) return [createRequestField(categories[groupKeys.length].field, false)]
    const requestCategories: Array<RawDataField> = []
    categories.forEach(category => {
        requestCategories.push(createRequestField(category.field, false))
    })
    return requestCategories
}

const getSeries = (series: TableSeries[] | []): SeriesRequest => {
    return {
        service: makeServiceRequest(series[0].service),
        values: series[0].values.filter(v => v.field).map(d => createRequestSource(d, false)),
        ...(series[0].subSeries && { subSeriesField: series[0].subSeries.field }),
    }
}

//get sort from the builder
const getDefaultSort = (
    categories: CategoryTableColumn[],
    series: TableSeries[],
    groupKeys: string[],
    collapsable: boolean
): Array<SortableDataSource> => {
    let sortArray: Array<SortableDataSource & { sortIndex?: number }> = []

    //if there is a groupKey we need to create one sort entry for all categories, only for the most inner group
    if (collapsable && categories[groupKeys.length].sort) {
        sortArray.push({
            field: categories[groupKeys.length].field,
            aggregation: Aggregation.NONE,
            sort: categories[groupKeys.length].sort,
            sortIndex: categories[groupKeys.length].sortIndex,
        })
    }

    if (groupKeys.length === 0 && !collapsable) {
        categories.forEach(category => {
            if (category.sort) {
                sortArray.push({
                    field: category.field,
                    aggregation: Aggregation.NONE,
                    sort: category.sort,
                    sortIndex: category.sortIndex,
                })
            }
        })
    }

    series[0].values.forEach(value => {
        if (value.sort) {
            sortArray.push({
                field: value.field,
                aggregation: value.aggregation,
                sort: value.sort,
                sortIndex: value.sortIndex,
            })
        }
    })

    //sort the array by the sortIndex
    sortArray = sortArray
        .sort((a, b) => {
            return a.sortIndex - b.sortIndex
        })
        .map(s => {
            delete s.sortIndex
            return s
        })

    return sortArray
}

//this is the sort part of the data request, for server side sorting.
//sortModel and groupKeys come from the grid
const getSortModel = (
    sortModel: SortModelItem[],
    groupKeys: string[],
    categories: CategoryTableColumn[],
    series: TableSeries[],
    mappedPivotsColumns: { [key: string]: string },
    collapsable: boolean,
    properties: PropertyMetaWithSemantic[]
): Array<SortableDataSource> => {
    const filters: SortableDataSource[] = []
    let outerGroupSorted = false

    //if there is a sortModel, it means that the user clicked on a header
    sortModel.reduce((acc, model) => {
        const sort = model.sort === 'asc' ? 'ascending' : 'descending'

        const category = categories.find(category => {
            const { displayName } = getPropertyMeta(properties, category.field) || {}
            return category.title === model.colId || displayName === model.colId || category.field.name === model.colId
        })
        if (category) {
            //if it's a collapsable table, it means that the user clicked on a header of a group, so we need to create one sort entry for all categories
            if (collapsable && !outerGroupSorted) {
                outerGroupSorted = true
                acc.push({
                    field: categories[groupKeys.length].field,
                    aggregation: Aggregation.NONE,
                    sort,
                })
                return acc
            }

            //skips all the other categories if it's a collapsable table with the group sorted
            if (collapsable) return acc

            acc.push({
                field: category.field,
                aggregation: Aggregation.NONE,
                sort,
            })
            return acc
        }

        //if it's a pivoted table, the colId is the title of the value, so we need to find the field name
        const sortModelValue = series[0].subSeries ? mappedPivotsColumns[model.colId] : model.colId

        const sortValue = series[0].values.find(value => {
            const { displayName } = getPropertyMeta(properties, value.field) || {}
            return (
                value.title === sortModelValue || displayName === sortModelValue || value.field.name === sortModelValue
            )
        })
        if (sortValue) {
            acc.push({
                field: sortValue.field,
                aggregation: sortValue.aggregation,
                sort,
            })
        }

        return acc
    }, filters)

    return filters
}
