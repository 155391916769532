import { createContext, Dispatch, SetStateAction, useState } from 'react'
import { useSelector } from 'react-redux'
import { authSelectors } from '~/selectors'

type ContextProps = {
    config: any
    loading: boolean
    isCopying: boolean
    isEdit: EditProps
    setLoading: Dispatch<SetStateAction<boolean>>
    setIsCopying: Dispatch<SetStateAction<boolean>>
    setIsEdit: Dispatch<SetStateAction<EditProps>>
    kanbanColumns: any
    setKanbanColumns: Dispatch<SetStateAction<any>>
    yAxisRangeSelectionEnabled: boolean
    showSave: boolean
    enableSave: boolean
    setEnableSave: Dispatch<SetStateAction<boolean>>
    handleSave: any
    setHandleSave: Dispatch<SetStateAction<any>>
    showSettings: boolean
    setShowSettings: Dispatch<SetStateAction<boolean>>
    settingsComponent: any
    setSettingsComponent: Dispatch<SetStateAction<any>>
    onSettingsApply: any
    setOnSettingsApply: Dispatch<SetStateAction<any>>
    widgetDataById: any
    updateWidgetDataById: (name: string, data: any) => void
    updateConfigAndRefresh?: (config: any) => void
}

interface EditProps {
    [key: string]: string
}

const chartWidgetTypes = [
    'CHART',
    'BUBBLECHART',
    'HISTOGRAM',
    'GAUGE',
    'FLOW',
    'BULLET',
    'HEATMAP',
    'TREEMAP',
    'XRANGE',
]

const widgetTypesToEnableSave = ['GANTT']

const displaySaveButton = (type, yAxisRangeSelectionEnabled) => {
    if (yAxisRangeSelectionEnabled && chartWidgetTypes.includes(type)) return true

    if (widgetTypesToEnableSave.includes(type)) return true

    return false
}

const PerspectiveWidgetContext = createContext<Partial<ContextProps>>({})

function PerspectiveWidgetProvider({ children, config, updateConfigAndRefresh }) {
    const [isCopying, setIsCopying] = useState(false)
    const [loading, setLoading] = useState(false)
    const [kanbanColumns, setKanbanColumns] = useState({ cols: [], selectedColumn: '' })
    const yAxisRangeSelectionEnabled = useSelector(authSelectors.getIsYAxisRangeSelectionEnabled)
    const showSave = displaySaveButton(config.Type.toUpperCase(), yAxisRangeSelectionEnabled)
    const [enableSave, setEnableSave] = useState(false)
    const [handleSave, setHandleSave] = useState(null)
    const [isEdit, setIsEdit] = useState<EditProps>({})
    const [showSettings, setShowSettings] = useState(false)
    const [settingsComponent, setSettingsComponent] = useState<any>(null)
    const [onSettingsApply, setOnSettingsApply] = useState<any>(null)
    const [widgetDataById, setWidgetDataById] = useState<any>({})

    return (
        <PerspectiveWidgetContext.Provider
            value={{
                config,
                isCopying,
                loading,
                isEdit,
                setIsCopying,
                setLoading,
                setIsEdit,
                kanbanColumns,
                setKanbanColumns,
                yAxisRangeSelectionEnabled,
                showSave,
                enableSave,
                setEnableSave,
                handleSave,
                setHandleSave,
                showSettings,
                setShowSettings,
                settingsComponent,
                setSettingsComponent,
                onSettingsApply,
                setOnSettingsApply,
                widgetDataById,
                updateWidgetDataById: (name, data) => setWidgetDataById({ [name]: data }),
                updateConfigAndRefresh,
            }}
        >
            {children}
        </PerspectiveWidgetContext.Provider>
    )
}

export { PerspectiveWidgetContext, PerspectiveWidgetProvider }
