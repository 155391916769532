import { VisualTypeAndConfig } from 'genesis-suite/types/visualTypes'
import DisplayDiff from './DisplayDiff'
import useLiveWithDraft from './utils/useLiveWithDraft'

export default function DisplayDraftDiff({ config, type }: VisualTypeAndConfig) {
    const { isNew, liveConfig } = useLiveWithDraft(type, config)
    if (!isNew && !liveConfig) return null

    return <DisplayDiff before={liveConfig} after={config} />
}
