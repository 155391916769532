import { memo, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, InputAdornment, IconButton, Paper } from '@mui/material'
import produce from 'immer'
import ClearIcon from '@mui/icons-material/Clear'

import { View, Widget, WidgetRequestFilter } from 'genesis-suite/types/visualTypes'
import { SortBy } from 'genesis-suite/types/utilTypes'
import { DebouncedTextField, SwalContext } from 'genesis-suite/components'
import { sleep } from 'genesis-suite/utils'
import { Search } from 'genesis-suite/icons'
import { moduleCreators, navigationCreators } from '../../actions/creators'
import { routePaths } from '../../lib/routes'
import { visualService } from '../../lib/services'
import { applicationSelectors, authSelectors, deploymentSelectors, moduleSelectors } from '../../selectors'
import { ModuleViewsContext } from '../manage_module_views/ModuleViewsContext'
import WidgetsTable from './WidgetsTable'
import { makeFilterOptions } from './utils'
import NewWidgetButton from '../NewWidgetButton'
import WidgetDetails from './WidgetDetails'
import { updateWidgetOrCreateDraft } from '../../lib/manageUtils'

interface Props {
    resourceNames?: WidgetRequestFilter['resourceNames']
    /** (optional) if provided, only allow select operations (no editing, deleting operations) */
    selectOnly?: boolean
    /** (optional) if provided, override what happens when widget is clicked */
    onSelect?: (id: string) => void
}

export default function ManageWidgets(props: Props) {
    const { newView, clearNewView, onCopyToUsersViews } = useContext(ModuleViewsContext)
    return (
        <MemoizedRender
            newView={newView}
            clearNewView={clearNewView}
            onCopyToUsersViews={onCopyToUsersViews}
            {...props}
        />
    )
}

const MemoizedRender = memo(Render)

interface Data {
    results: Widget[]
    totalRecords: number
    nextPage: number
}

interface RenderProps extends Props {
    newView: any
    clearNewView: () => void
    onCopyToUsersViews: (id: string, forceCopy?: boolean) => void
}

function Render({
    resourceNames,
    newView,
    clearNewView,
    selectOnly = false,
    onCopyToUsersViews,
    onSelect,
}: RenderProps) {
    const { confirm } = useContext(SwalContext)

    const liveModuleId = useSelector(moduleSelectors.getLiveId)
    const moduleViews = useSelector(moduleSelectors.getViews)
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const viewFlag = useSelector(deploymentSelectors.getDeploymentViewFlag)
    const userId = useSelector(authSelectors.getUserId)
    const isPowerUser = useSelector(authSelectors.getIsPowerUser)

    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState<Data>(initData)
    const [filter, setFilter] = useState<WidgetRequestFilter>({ textSearch: undefined, resourceNames })
    const [sortBy, setSortBy] = useState<SortBy<Widget>>({ updatedAt: 'desc' })
    const [highlightId, setHighlightId] = useState(null)
    const [showNew, setShowNew] = useState(false)
    const [detailsId, setDetailsId] = useState('')

    const { results, nextPage, totalRecords } = data
    const haveAllRecords = totalRecords && results.length >= totalRecords

    const getData = useRef<() => Data>()
    getData.current = () => data

    useEffect(() => {
        getWidgets()
        return () => cancelCall.current?.()
    }, [filter, sortBy])

    useEffect(() => {
        if (!newView) return

        handleAppendWidget(newView)
        clearNewView()
    }, [newView])

    /** If getting records, function to cancel the request */
    const cancelCall = useRef(null)

    const paginateRecords = useCallback(() => {
        if (haveAllRecords || cancelCall.current || nextPage === 1) return
        getWidgets(nextPage)
    }, [haveAllRecords, cancelCall.current, nextPage, appName, viewFlag, filter, sortBy])

    /** Fetch paginated dashboards. If page == 1, replace data results, else append to end */
    function getWidgets(page = 1) {
        setLoading(true)

        const options = makeFilterOptions(filter, sortBy, page)
        visualService
            .getWidgets(appName, options, viewFlag, cancel => (cancelCall.current = cancel))
            .then(({ data, totalRecords }) => {
                setData(s => ({
                    results: page === 1 ? data : [...s.results, ...data],
                    nextPage: page === 1 ? 2 : s.nextPage + 1,
                    totalRecords,
                }))

                cancelCall.current = null
            })
            .finally(() => setLoading(false))
    }

    const handleOpen = useCallback((id: string) => {
        if (onSelect) return onSelect(id)

        dispatch(navigationCreators.goTo(routePaths.WIDGET, id))
    }, [])

    function handleSelect(id) {
        const widget = results.find(d => d.id === id)
        if (!isPowerUser && widget.createdBy.id !== userId) return

        setDetailsId(detailsId === id ? '' : id)
    }

    async function handleAppendWidget(dashboard: Widget) {
        setSortBy({ updatedAt: 'desc' })
        setData(d =>
            produce(d, draft => {
                draft.results.push(dashboard)
            })
        )
        setHighlightId(dashboard.id)
        await sleep(1000)
        setHighlightId(null)
    }

    const handleUpdate = useCallback(
        async (id: string, body) => {
            try {
                await updateWidgetOrCreateDraft(appName, id, body, viewFlag)
                const results = getData.current().results
                const index = results.findIndex(d => d.id === id)
                const old = results[index]
                const newWidget = { ...old, ...body }

                updateRow(index, newWidget)
            } catch (err) {
                console.error(err)
            }
        },
        [appName, viewFlag]
    )

    /** Refresh a row by id */
    const handleRefresh = useCallback(
        async (id: string) => {
            const widget = await visualService.getWidgetById(appName, id)
            const index = data.results.findIndex(r => r.id === id)
            if (index === -1) return

            updateRow(index, widget)
        },
        [appName, data]
    )

    function updateRow(index: number, widget: Widget) {
        setData(s =>
            produce(s, draft => {
                draft.results[index] = widget
            })
        )
    }

    const handleNewButtonClick = useCallback(() => setShowNew(true), [])

    const handleDelete = useCallback(
        async (id: string) => {
            try {
                await visualService.deleteWidget(appName, id, viewFlag)

                const updatedViews = removeWidgetFromModuleViews(id, moduleViews)
                if (updatedViews) dispatch(moduleCreators.updateCurrentModule({ views: updatedViews }))

                handleRemove(id)
                setDetailsId('')
            } catch (err) {
                console.error(err)
            }
        },
        [appName, moduleViews, viewFlag]
    )

    function handleRemove(id: string) {
        setData(d => ({
            ...d,
            results: produce(d.results, draft => {
                const index = draft.findIndex(d => d.id === id)
                draft.splice(index, 1)
            }),
        }))
    }

    function handleSearchInput(val?: string) {
        if (val === filter.textSearch) return
        setFilter(s => ({ ...s, textSearch: val }))
    }

    const updateFilter = useCallback((updated: Partial<WidgetRequestFilter>) => {
        setFilter(s => ({ ...s, ...updated }))
    }, [])

    return (
        <Box flex="1" overflow="hidden" display="flex" flexDirection="column" position="relative">
            <Box m={1} display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
                {!selectOnly && (
                    <NewWidgetButton
                        variant="contained"
                        onlyNew
                        open={showNew}
                        onClick={() => setShowNew(true)}
                        onClose={() => setShowNew(false)}
                    >
                        Add
                    </NewWidgetButton>
                )}

                <DebouncedTextField
                    debounceTime={500}
                    sx={theme => ({
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: theme.palette.text.primary,
                        },
                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: theme.palette.text.primary,
                        },
                        '.MuiSvgIcon-root ': {
                            fill: theme.palette.text.primary,
                        },
                        width: '300px',
                    })}
                    variant="outlined"
                    autoFocus
                    size="small"
                    margin="dense"
                    placeholder="Title or Author"
                    onFocus={e => e.target.select()}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search fontSize="small" />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    size="small"
                                    disabled={!filter.textSearch}
                                    onClick={() => handleSearchInput()}
                                >
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    value={filter.textSearch || ''}
                    onChange={handleSearchInput}
                />
            </Box>

            <Box flex={1} display="flex" overflow="hidden">
                <WidgetsTable
                    draggable={!selectOnly}
                    filter={filter}
                    highlightId={detailsId || highlightId}
                    loading={loading}
                    onNewButton={selectOnly ? undefined : handleNewButtonClick}
                    onOpen={selectOnly ? undefined : handleOpen}
                    onRefresh={selectOnly ? undefined : handleRefresh}
                    onScrollEnd={paginateRecords}
                    onSelect={selectOnly ? handleOpen : handleSelect}
                    onSort={setSortBy}
                    onUpdateFilter={updateFilter}
                    views={results}
                    sortBy={sortBy}
                />

                <Paper elevation={4} sx={{ transition: 'width 150ms', width: detailsId ? '510px' : 0 }}>
                    <WidgetDetails
                        id={detailsId}
                        onCopy={onCopyToUsersViews}
                        onDelete={handleDelete}
                        onDone={() => setDetailsId('')}
                        onOpen={handleOpen}
                        onUpdate={handleUpdate}
                    />
                </Paper>
            </Box>
        </Box>
    )
}

/** Return new views w/o id if id is found in moduleViews (else undefined) */
function removeWidgetFromModuleViews(id: string, moduleViews: View[]) {
    if (!moduleViews?.length) return

    let madeChange = false
    const filterFunc = (v: View) => {
        if (v.type !== 'dashboard' || v.id !== id) return true
        madeChange = true
        return false
    }

    const views = moduleViews
        .map(g => ({ ...g, ...(g.type === 'group' && { views: g.views?.filter(filterFunc) }) }))
        .filter(filterFunc)
    if (!madeChange) return

    return views
}

const initData = {
    results: [],
    totalRecords: null,
    nextPage: 1,
}
