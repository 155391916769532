import { useState, useRef, forwardRef, useLayoutEffect, memo } from 'react'
import { useDispatch } from 'react-redux'
import classnames from 'classnames'
import {
    List,
    ListItem,
    ListItemText,
    Collapse,
    IconButton,
    Tooltip,
    Popover,
    Typography,
    useTheme,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { CognitiveIcon } from 'genesis-suite/components'
import { Donut } from 'genesis-suite/icons'
import CollapsableTwist from './CollapsableTwist'
import { openInNewTab } from '../lib/utils'
import { useHover } from 'genesis-suite/hooks'
import { navigationCreators } from '../actions/creators'
import { routePaths } from '../lib/routes'
import useIsItemAtRoute from '../hooks/useIsItemAtRoute'

const useStyles = makeStyles(({ palette, spacing }) => ({
    listItem: {
        padding: spacing(0, 1),
        whiteSpace: 'nowrap',
        '&:focus': { backgroundColor: 'initial' },
    },
    collapsedListItem: { '&:hover': { backgroundColor: 'initial' } },
    header: { display: 'flex', alignItems: 'center', overflow: 'hidden' },
    inlineIcon: { marginRight: spacing() },
    text: { margin: `2px 0` },
    donutButton: {
        color: palette.text.primary,
        padding: p => (p.icon && p.collapsed ? spacing(0.5) : 0),
        backgroundColor: p =>
            p.collapsed && p.atRoute
                ? p.icon
                    ? palette.primary.light
                    : p.submenuOpen
                    ? 'rgba(0, 0, 0, 0.08)'
                    : ''
                : '',
        margin: p => (p.collapsed ? 'auto' : ''),
        display: p => (!p.collapsed && p.submenuOpen && p.atRoute ? 'none' : ''),
        '&:hover': { backgroundColor: p => (p.collapsed ? 'rgba(0, 0, 0, 0.08)' : 'transparent') },
    },
    submenuPopoverContainer: { transform: 'translate(6px, -7px)' },
    submenuPopoverHeader: {
        fontWeight: 700,
        borderBottom: `2px solid ${palette.grayscale.lighter}`,
        padding: spacing(),
    },
    submenuOpen: { padding: '0 0 0 30px' },
    activeTooltip: { marginTop: '-3px' },
    inactiveTooltip: {
        backgroundColor: palette.background.sideNav,
        color: palette.text.primary,
        margin: spacing(0, 3),
        padding: '1px 8px',
    },
}))

/**
 * Item to be displayed in CollapsableList
 * @param {object} props react props
 * @param {{type: string, to: string, text: string, subMenuConfig: any}[]=} props.items array of items that will recursively call same component
 * @param {string} props.text item display text
 * @param {"viewId" | "elementName"} props.type optional type of navigation
 * @param {string=} props.to navigation location based on type
 * @param {string=} props.icon cognitive icon
 * @param {boolean=} props.isExternal optional. if should open "to" link externally
 * @param {boolean} props.collapsed if item should collapse or not
 * @param {boolean} props.showTooltips show tooltip text to right of item
 * @param {(event) => void=} props.onClick if provided, callback after item being clicked
 */
function CollapsableListItem({ collapsed, showTooltips, onClick, id, ...item }) {
    const { type, to, isExternal, text, items, icon } = item

    const atRoute = useIsItemAtRoute(item)
    const dispatch = useDispatch()

    const [submenuOpen, setSubmenuOpen] = useState(false)
    const donutRef = useRef(null)

    const classes = useStyles({ collapsed, atRoute, icon, submenuOpen })
    const theme = useTheme()

    const toggleSubmenu = () => {
        if (items) setSubmenuOpen(!submenuOpen)
    }

    const listClick = e => {
        if (onClick) onClick(e)
        toggleSubmenu()
        if (!to || atRoute) return

        const query = item?.treeId ? { treeId: item?.treeId } : null

        if (isExternal) openInNewTab(to)
        else if (type === 'viewId') dispatch(navigationCreators.goToPerspective(to))
        else if (type === 'widgetId') dispatch(navigationCreators.goTo(routePaths.WIDGET, to))
        else if (type === 'elementName') dispatch(navigationCreators.goTo(routePaths.ELEMENT, to, null, query))
        else if (type === 'treeName') dispatch(navigationCreators.goTo(routePaths.TREE, to))
    }

    return (
        <>
            <ListItem
                id={id}
                className={classnames(classes.listItem, { [classes.collapsedListItem]: collapsed })}
                disableGutters
                disableRipple
                button
                onClick={listClick}
            >
                {!collapsed && <Header classes={classes} open={submenuOpen} {...item} />}
                {(collapsed || atRoute) && (
                    <DonutButton
                        classes={classes}
                        theme={theme}
                        submenuOpen={submenuOpen}
                        active={atRoute}
                        collapsed={collapsed}
                        tooltipText={showTooltips ? text : ''}
                        icon={icon}
                        ref={donutRef}
                    />
                )}
            </ListItem>

            <Submenu
                classes={classes}
                collapsed={collapsed}
                headerText={text}
                items={items}
                open={submenuOpen}
                anchorEl={donutRef.current}
                onClose={toggleSubmenu}
            />
        </>
    )
}

export default memo(CollapsableListItem)

const Header = ({ classes, text, items, open, icon }) => {
    const [enableTooltip, setEnableTooltip] = useState(false)
    const textRef = useRef(null)

    useLayoutEffect(() => {
        if (!textRef.current) return
        const element = textRef.current

        if (element.scrollWidth > element.clientWidth) setEnableTooltip(true)
        else setEnableTooltip(false)
    }, [textRef.current])

    return (
        <Tooltip title={enableTooltip ? text : ''} placement="right" arrow>
            <div className={classes.header}>
                {items && <CollapsableTwist open={open} />}
                {icon && <CognitiveIcon fontSize="small" className={classes.inlineIcon} icon={icon} />}
                <ListItemText ref={textRef} className={classes.text}>
                    {text}
                </ListItemText>
            </div>
        </Tooltip>
    )
}

const DonutButton = forwardRef(({ classes, theme, tooltipText, active, collapsed, icon }, ref) => {
    const isHovering = useHover(ref)

    return (
        <Tooltip
            title={collapsed ? tooltipText : ''}
            open={!!tooltipText}
            placement="right"
            classes={{ tooltip: isHovering ? classes.activeTooltip : classes.inactiveTooltip }}
        >
            <IconButton ref={ref} className={classes.donutButton} size="large">
                {icon?.file && collapsed ? (
                    <CognitiveIcon fontSize="small" icon={icon} />
                ) : (
                    <Donut active={active ? theme.palette.text.primary : null} />
                )}
            </IconButton>
        </Tooltip>
    )
})

const Submenu = ({ classes, collapsed, headerText, items, open, anchorEl, onClose }) => {
    if (!items) return null

    const handleItemClick = () => {
        if (collapsed) onClose()
    }

    const Items = ({ submenuClass }) => (
        <List component="div" className={submenuClass}>
            {items.map((item, index) => (
                <CollapsableListItem key={index} {...item} onClick={handleItemClick} />
            ))}
        </List>
    )

    if (collapsed) {
        return (
            <Popover
                open={open}
                onClose={onClose}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                className={classes.submenuPopoverContainer}
            >
                <Typography variant="body1" className={classes.submenuPopoverHeader}>
                    {headerText}
                </Typography>
                <Items />
            </Popover>
        )
    }

    return (
        <Collapse in={open} timeout="auto" unmountOnExit>
            <Items submenuClass={classes.submenuOpen} />
        </Collapse>
    )
}
