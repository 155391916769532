import cloneDeep from 'lodash/cloneDeep'
import { createTransform, persistReducer } from 'redux-persist'
import sessionStorage from 'redux-persist/lib/storage/session'

import rootreducer from './root.reducer'

const setPending = createTransform(
    inboundState => {
        const clone = cloneDeep(inboundState)
        checkKeys(clone)
        return clone
    },
    outboundState => outboundState,
    { blacklist: [] }
)

const checkKeys = obj => {
    if (typeof obj !== 'object') return

    for (const key in obj) {
        if (key.toLowerCase() === 'ispending') obj[key] = false
        else checkKeys(obj[key])
    }
}

const persistConfig = {
    key: 'root',
    transforms: [setPending],
    storage: sessionStorage,
    ...(process.env.NODE_ENV === 'development'
        ? { blacklist: ['history', 'lockout', 'router', 'theme'] }
        : { whitelist: ['applications', 'authentication'] }),
}

export default persistReducer(persistConfig, rootreducer)
