import React, { useEffect } from 'react'
import useSWR from 'swr'
import isEmpty from 'lodash/isEmpty'
import { useSnackbar } from 'notistack'
import makeStyles from '@mui/styles/makeStyles'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, Typography } from '@mui/material'
import { Profile as ProfileIcon } from 'genesis-suite/icons'
import { ResourceType } from 'genesis-suite/types/networkTypes'

import EditProfile from './EditProfile'
import ProfileHistory from './ProfileHistory'
import ProfileRootNode from './ProfileRootNode'
import ProfileLinkedNodes from './ProfileLinkedNodes'
import { architectureService } from '../../lib/services'
import useResourceMeta from '../../hooks/useResourceMeta'
import { interactionCreators } from '../../actions/creators'
import { interactionSelectors, applicationSelectors, authSelectors } from '../../selectors'
import { RightNavComponentProps } from '../../views/rightnav/RightNav'

const useStyles = makeStyles(({ palette, spacing }) => ({
    profiles: { height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden' },
    label: { color: palette.grey['700'] },
    profileIcon: { marginRight: spacing(0.5), verticalAlign: 'middle' },
}))

export default function Profile({ Header }: RightNavComponentProps) {
    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const isPowerUser = useSelector(authSelectors.getIsPowerUser)
    const profiles = useSelector(interactionSelectors.getProfiles)
    const index = useSelector(interactionSelectors.getProfileIndex)
    const editing = useSelector(interactionSelectors.getIsEditingProfile)
    const dispatch = useDispatch()

    const classes = useStyles()

    const currentProfile = profiles?.[index]
    const nodeName = currentProfile?.nodeName
    const [node] = useResourceMeta(ResourceType.NODE, nodeName)
    const [data, error, updateData] = useProfileData(currentProfile)
    const rootData = node && data ? data[node.name][0] : null

    useEffect(() => {
        return () => {
            dispatch(interactionCreators.toggleProfileEdit(false))
        }
    }, [])

    useEffect(() => {
        if (!error) return
        showSnackbar('An error occurred loading profile.', { variant: 'error' })
    }, [error])

    function handleDoneEditing() {
        dispatch(interactionCreators.toggleProfileEdit(false))
        updateData()
    }

    if (!profiles.length)
        return (
            <>
                {Header && (
                    <Header>
                        <ProfileHistory />
                    </Header>
                )}
                <Typography variant="h6">No Profile selected</Typography>
                <Typography>
                    Right-click on a widget and select the{' '}
                    <ProfileIcon fontSize="small" className={classes.profileIcon} />
                    Profile option
                </Typography>
            </>
        )

    return (
        <div className={classes.profiles}>
            {Header && (
                <Header>
                    {editing ? <Typography variant="caption">Edit profile</Typography> : <ProfileHistory />}
                </Header>
            )}
            {editing ? (
                <EditProfile initialNodeName={nodeName} onDone={handleDoneEditing} />
            ) : (
                <>
                    <ProfileRootNode
                        node={node}
                        data={rootData}
                        hasLinked={!isEmpty(data)}
                        loading={(!data || !node) && !error}
                    />
                    <ProfileLinkedNodes linkedNodeConfig={node?.profile?.linkedNodes} data={data} />
                    {isPowerUser && (
                        <Box display="flex" justifyContent="flex-end" m={1}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => dispatch(interactionCreators.toggleProfileEdit(true))}
                            >
                                Edit
                            </Button>
                        </Box>
                    )}
                </>
            )}
        </div>
    )
}

function isValid(profile) {
    if (!profile) return false

    const { nodeName, field, value } = profile
    return Boolean(nodeName && field && value != null)
}

function useProfileData(profile) {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const { nodeName, field, value } = profile || {}

    const { data, error, mutate } = useSWR(
        isValid(profile) ? ['profile', appName, nodeName, field, value] : null,
        ([_, appName, nodeName, field, value]) => {
            const reqBody = {
                nodeName,
                filter: { type: 'filter', field: { name: field }, comparison: '=', values: [value] },
            }
            return architectureService.getProfiles(appName, reqBody)
        },
        { shouldRetryOnError: false }
    )

    return [data, error, mutate]
}
