import makeStyles from '@mui/styles/makeStyles'
import produce from 'immer'
import isEqual from 'lodash/isEqual'
import { createElement, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'

import { Box, Button, List, ListItem, ListItemIcon, ListItemText, Popover, Typography } from '@mui/material'
import { DrillDown, InfiniteNav, InlineFilter, Profile as ProfileIcon, ShowDetails } from 'genesis-suite/icons'
import { ResourceType } from 'genesis-suite/types/networkTypes'
import { letMeMap } from 'genesis-suite/types/utilTypes'
import {
    ChartType, Dashboard, DataField, DataIndexes,
    DataResponse, InlineNodeFilter, Navigation, NodeFilterWithValue, RawDataField, SeriesConfig, TableConfig, Widget
} from 'genesis-suite/types/visualTypes'
import { useSnackbar } from 'notistack'
import useNavigableFocalPoints from '../../hooks/useFocalPoints'
import { useIsMobile } from '../../hooks/useIsMobile'
import { visualService, widgetService } from '../../lib/services'
import { applicationSelectors, deploymentSelectors, moduleSelectors, widgetSelectors } from '../../selectors'
import { CanClick, SelectedPoint, SetSelectedPoint, WidgetInteractions } from '../../types/WidgetTypes'
import { createBaseDataRequest } from '../DynamicTable/DataRequest'
import Markdown from '../Markdown'
import getDataGroup from './utils/getDataGroup'
import isSeriesWidget from './utils/isSeriesWidget'
import makeTooltipMarkdown from './utils/makeTooltipMarkdown'

const useStyles = makeStyles({
    root: {
        minHeight: 0, //keep this here so that tables stay in their container
        flexGrow: 3,
        flexShrink: 3,
        height: '100%',
        overflow: 'auto',
    },
    tooltipWrapper: { '& p': { margin: 0 } },
})

interface Props {
    config: Widget
    data: DataResponse
    children: (canClick: CanClick, setSelectedPoint: SetSelectedPoint) => any
    inlineFilters?: Array<InlineNodeFilter>
    interactions?: WidgetInteractions
}

/** Adds interactions to child (Widget2) including tooltip */
export default function WidgetInteraction({ config, data, children, inlineFilters, interactions }: Props) {
    const isMobile = useIsMobile()
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const moduleId = useSelector(moduleSelectors.getModuleId)
    const isV2 = useSelector(moduleSelectors.getIsV2)
    const v1Perspectives = useSelector(widgetSelectors.getV1Perspectives).filter(p => p.showInExplorer)
    const navigableFocalPoints = useNavigableFocalPoints()
    const viewFlag = useSelector(deploymentSelectors.getDeploymentViewFlag)

    const [selectedPoint, setSelectedPoint] = useState<SelectedPoint>(null)
    // data cached in order to maintain selectedPoint when the interaction popover is open
    const [cached, setCached] = useState<SelectedPoint>(null)
    const [rightClickPosition, setRightClickPosition] = useState(null)
    const [loadingFocalPointPerspectives, setLoadingFocalPointPerspectives] = useState(false)
    const [perspectivesByFocalPoint, setPerspectivesByFocalPoint] = useState<{ [focalPoint: string]: Dashboard[] }>({})
    const [showFocalPointPerspectives, setShowFocalPointPerspectives] = useState(false)
    const [widgetData, setWidgetData] = useState<DataResponse>(data)
    const { enqueueSnackbar } = useSnackbar()
    const errorSnackbar = message => enqueueSnackbar(message, { variant: 'error' })

    const navigationDetails = getNavigationDetails(config, cached?.dataIndexes)
    const focalPoint = navigationDetails.field?.resourceName
    const focalPointPerspectives = perspectivesByFocalPoint[focalPoint]
    const v1FocalPointPerspectives = v1Perspectives.filter(p => p.focalPoint === focalPoint)

    const { onInlineFilter, onNavigate, onOpenProfile, onShowDetails, onPointHover, onPointSelect } = interactions || {}

    useEffect(() => {
        if (selectedPoint) setCached(selectedPoint)
        if (
            (!widgetData || widgetData.length === 0) &&
            ((selectedPoint?.clicked && onPointSelect) || onPointHover) &&
            config.type === ChartType.TABLE &&
            ((config as TableConfig).dynamic || (config as TableConfig).collapsable)
        ) {
            getWidgetData()
        }
    }, [selectedPoint])

    useEffect(() => {
        setWidgetData(data)
    }, [data])

    useEffect(() => {
        if (!widgetData || widgetData.length === 0) return
        if (selectedPoint?.clicked) {
            if (onNavigate) handleNavigation()
            if (onPointSelect) onPointSelect(makeCallbackProps(selectedPoint))
        } else {
            if (onPointHover)
                onPointHover(makeCallbackProps(selectedPoint || cached), selectedPoint ? 'enter' : 'leave')
        }
    }, [selectedPoint, widgetData, cached?.dataIndexes])

    useEffect(() => {
        if (selectedPoint) setCached(selectedPoint)
    }, [selectedPoint])

    function makeCallbackProps(selectedPoint: SelectedPoint) {
        if (!selectedPoint || !('series' in config)) return
        const { event, dataIndexes } = selectedPoint
        const dataGroup = getDataGroup(widgetData, dataIndexes)
        const rowData = dataGroup.aggregatedData ?? (dataIndexes.raw ? dataGroup.rawData[dataIndexes.raw.row] : [])
        const row = letMeMap(config.series[dataIndexes.series].values).reduce((acc, cur, index) => {
            return { ...acc, [cur.field.name]: rowData[index] }
        }, {} as { [field: string]: any })
        const value = rowData.length
            ? dataIndexes.raw?.column
                ? rowData[dataIndexes.raw.column]
                : rowData[0]
            : undefined
        return { indexes: dataIndexes, event, row, value }
    }

    const getWidgetData = useCallback(() => {
        const baseDataRequest = createBaseDataRequest(null, config as TableConfig)
        widgetService
            .getData2(baseDataRequest)
            .then(result => {
                if (!result || result.length === 0) {
                    errorSnackbar('Navigation error: No data found')
                    return
                }
                setWidgetData(result)
            })
            .catch(() => {
                errorSnackbar('Navigation error: No data found')
            })
    }, [])

    const canClick = useCallback(
        (focalPoint: string) => {
            if (onPointSelect) return true
            return canNavigate(focalPoint)
        },
        [navigationDetails.enabled, navigableFocalPoints.data, Boolean(onPointSelect)]
    )

    function canNavigate(focalPoint: string) {
        if (!navigationDetails.enabled) return false
        if (!focalPoint) return false
        if (isV2) return navigableFocalPoints.data?.includes(focalPoint)
        return v1Perspectives.filter(p => p.focalPoint === focalPoint).length > 0
    }

    const { dataIndexes, event } = cached || {}
    const clickPosition = event?.pageX ? { left: event.pageX, top: event.pageY - 20 } : null
    const widgetFiltersIndex = inlineFilters?.findIndex(f => f.widgetId === config.id) ?? -1
    const pointIsFiltered =
        widgetFiltersIndex > -1
            ? inlineFilters[widgetFiltersIndex].dataIndexes.some(d => isEqual(d, dataIndexes))
            : false
    const navigatable = canNavigate(focalPoint)
    const classes = useStyles()

    function handleRightClick(e) {
        if (!selectedPoint) return

        e.preventDefault()
        e.stopPropagation()
        if (!rightClickPosition) return setRightClickPosition({ left: e.pageX, top: e.pageY })

        setSelectedPoint(null)
        setRightClickPosition(null)
    }

    function handleClose() {
        setCached(null)
        setSelectedPoint(null)
        setRightClickPosition(null)
        setShowFocalPointPerspectives(false)
    }

    function handleOpenProfile() {
        handleClose()
    }

    function handleInlineFilters() {
        onInlineFilter(
            produce(inlineFilters || [], draft => {
                if (pointIsFiltered) {
                    draft[widgetFiltersIndex].dataIndexes.filter(d => !isEqual(d, dataIndexes))
                    if (!draft[widgetFiltersIndex].dataIndexes.length) draft.splice(widgetFiltersIndex, 1)
                } else {
                    const keyPropertyName = navigationDetails.field.name
                    const nodeName = navigationDetails.field.resourceName
                    const { series, groupNames } = dataIndexes
                    const value = getDataGroup(widgetData, { series, groupNames: [groupNames[0]] }).group

                    if (widgetFiltersIndex === -1) {
                        draft.push({
                            widgetId: config.id,
                            dataIndexes: [dataIndexes],
                            source: 'inline',
                            keyPropertyName,
                            nodeName,
                            values: [{ value }],
                        })
                    } else {
                        draft[widgetFiltersIndex].dataIndexes.push(dataIndexes)
                        draft[widgetFiltersIndex].values.push({ value })
                    }
                }
            })
        )
        handleClose()
    }

    function handleShowDetails() {
        handleClose()
    }

    async function handleNavigation(mode?: 'infinite' | 'drilldown') {
        if (!navigatable) return

        const perspectiveId = navigationDetails.perspectiveId
        if (perspectiveId) return finishNavigation(perspectiveId, selectedPoint.dataIndexes, mode)

        if (focalPointPerspectives?.length) return setShowFocalPointPerspectives(true)

        try {
            let dashboards
            if (isV2) {
                setLoadingFocalPointPerspectives(true)
                const body = await visualService.getMetaDashboards(
                    appName,
                    { moduleId, focalPoint, active: true },
                    viewFlag
                )
                dashboards = body.data
                setLoadingFocalPointPerspectives(false)
            } else {
                dashboards = v1FocalPointPerspectives
            }

            setPerspectivesByFocalPoint(s => ({
                ...s,
                [focalPoint]: dashboards.sort((a, b) => a.title.localeCompare(b.title)),
            }))

            if (dashboards.length === 0) return
            else if (dashboards.length === 1) finishNavigation(dashboards[0].id, selectedPoint.dataIndexes)
            else setShowFocalPointPerspectives(true)
        } catch (err) {
            console.error(err)
        }
    }

    function finishNavigation(perspectiveId: string, dataIndexes: DataIndexes, mode?: 'infinite' | 'drilldown') {
        if (!('series' in config)) return

        const { raw, groupNames, series = 0, subSeries } = dataIndexes
        const field = navigationDetails.field

        const categoryValues = subSeries ? groupNames.slice(0, groupNames.length - 1) : [...(groupNames ?? [])]

        const filters: Array<NodeFilterWithValue> = []

        if (raw) {
            const value = widgetData[series].data[0].rawData[raw.row][raw.column].toString()
            filters.push({
                keyPropertyName: field.name,
                nodeName: field.resourceName,
                source: 'navigation',
                values: [{ label: value, value }],
            })
        } else {
            categoryValues?.forEach((value, index) => {
                const { field } = config.categories[index]
                filters.push({
                    keyPropertyName: field.name,
                    nodeName: field.resourceName,
                    source: 'navigation',
                    values: [{ label: value, value }],
                })
            })

            if (subSeries) {
                const subSeriesField = config.series[series].subSeries?.field
                const { resourceName, name } = subSeriesField
                const value = groupNames[groupNames.length - 1]

                filters.push({
                    keyPropertyName: name,
                    nodeName: resourceName,
                    source: 'navigation',
                    values: [{ label: value, value }],
                })
            }
        }

        onNavigate(perspectiveId, filters, mode)
        handleClose()
    }

    const infiniteInteraction = {
        show: Boolean(navigatable && onNavigate),
        onClick: () => handleNavigation('infinite'),
        title: 'Infinite Navigation',
        Icon: InfiniteNav,
    }

    const drilldownInteraction = {
        show: Boolean(navigatable && onNavigate),
        onClick: () => handleNavigation('drilldown'),
        title: 'Drill Down',
        Icon: DrillDown,
    }

    const inlineInteraction = {
        show: Boolean(onInlineFilter),
        onClick: handleInlineFilters,
        title: pointIsFiltered ? 'Reset Filter' : 'Inline Filter',
        Icon: InlineFilter,
    }

    const detailsInteraction = {
        show: Boolean(onShowDetails),
        onClick: handleShowDetails,
        title: 'Show Details',
        Icon: ShowDetails,
    }

    const profileInteraction = {
        show: Boolean(onOpenProfile),
        onClick: handleOpenProfile,
        title: 'Profile',
        Icon: ProfileIcon,
    }

    const mobileInteractions = [infiniteInteraction, drilldownInteraction, inlineInteraction, detailsInteraction]
    const desktopInteractions = [profileInteraction, inlineInteraction, detailsInteraction]
    const desktopHasInteraction = desktopInteractions.some(i => i.show)
    const markdown = 'series' in config ? makeTooltipMarkdown(config, widgetData, dataIndexes) : undefined

    const interactionMenu = isMobile ? (
        <MobileMenu markdown={markdown} profileInteraction={profileInteraction} interactions={mobileInteractions} />
    ) : (
        <DesktopMenu interactions={desktopInteractions} />
    )

    return (
        <>
            <div
                className={classes.root}
                style={{
                    cursor: loadingFocalPointPerspectives
                        ? 'wait'
                        : selectedPoint && canClick(focalPoint)
                        ? 'pointer'
                        : 'inherit',
                }}
                data-for={config.id}
                data-tip
                onContextMenu={handleRightClick}
            >
                {children(canClick, setSelectedPoint)}
            </div>

            <Popover
                open={Boolean(
                    isMobile
                        ? clickPosition
                        : showFocalPointPerspectives ||
                              (rightClickPosition && desktopHasInteraction && !loadingFocalPointPerspectives)
                )}
                anchorReference="anchorPosition"
                anchorPosition={clickPosition || rightClickPosition || { top: 0, left: 0 }}
                transformOrigin={{
                    vertical: isMobile ? 'bottom' : 'top',
                    horizontal: isMobile ? 'center' : 'left',
                }}
                onClose={handleClose}
            >
                {showFocalPointPerspectives ? (
                    <List>
                        {focalPointPerspectives.map(({ id, title }) => (
                            <ListItem key={id} button onClick={() => finishNavigation(id, dataIndexes)}>
                                <ListItemText>{title}</ListItemText>
                            </ListItem>
                        ))}
                    </List>
                ) : (
                    interactionMenu
                )}
            </Popover>

            {!isMobile && (
                <ReactTooltip id={config.id} place="bottom" getContent={() => null}>
                    {selectedPoint && markdown ? (
                        <div className={classes.tooltipWrapper}>
                            <Markdown>{markdown}</Markdown>
                        </div>
                    ) : null}
                </ReactTooltip>
            )}
        </>
    )
}

interface InteractionItem {
    show: boolean
    onClick: () => void
    title: string
    Icon: any
}

function DesktopMenu({ interactions }: { interactions: InteractionItem[] }) {
    return (
        <List>
            {interactions.map(
                ({ show, onClick, title, Icon }) =>
                    show && (
                        <ListItem key={title} button onClick={onClick}>
                            <ListItemIcon>
                                <Icon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>{title}</ListItemText>
                        </ListItem>
                    )
            )}
        </List>
    )
}

const useMobileMenuStyles = makeStyles(({ palette, spacing }) => ({
    root: { border: `1px solid ${palette.primary.main}`, borderRadius: '4px' },
    header: {
        background: palette.primary.main,
        color: palette.primary.contrastText,
        padding: spacing(),
        display: 'flex',
        justifyContent: 'space-between',
    },
    profileIcon: { color: 'inherit' },
    button: { justifyContent: 'flex-start' },
    interactionText: { paddingLeft: spacing(), textAlign: 'left' },
    footer: { background: palette.primary.main, height: spacing() },
}))

interface MobileMenuProps {
    markdown: string
    interactions: InteractionItem[]
    profileInteraction: InteractionItem
}

function MobileMenu({ markdown, interactions, profileInteraction }: MobileMenuProps) {
    const classes = useMobileMenuStyles()

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <Markdown>{markdown}</Markdown>
                {createElement(profileInteraction.Icon, {
                    onClick: profileInteraction.onClick,
                    className: classes.profileIcon,
                })}
            </div>

            <Box display="grid" gridTemplateColumns="120px 94px" gridTemplateRows="50px 50px" gridAutoFlow="column">
                {interactions.map(
                    ({ show, onClick, title, Icon }) =>
                        show && (
                            <Button key={title} className={classes.button} onClick={onClick}>
                                <Icon fontSize="small" />
                                <Typography className={classes.interactionText}>{title}</Typography>
                            </Button>
                        )
                )}
            </Box>

            <div className={classes.footer} />
        </div>
    )
}

interface NavigationDetails {
    enabled: boolean
    field?: DataField
    perspectiveId?: string
}

function getNavigationDetails(config: Widget, dataIndexes: DataIndexes): NavigationDetails {
    if (!isSeriesWidget(config.type)) return { enabled: false }

    const { categories, series } = config as SeriesConfig
    let source: { field: DataField; navigation?: Navigation }

    if (!dataIndexes) return { enabled: false }

    const { raw, groupNames, series: seriesIndex, subSeries } = dataIndexes
    const service = series[seriesIndex ?? 0]?.service

    if (raw) {
        if (raw.column == null) return { enabled: false }
        source = series[seriesIndex].values[raw.column]
    } else if (categories?.length) {
        source = categories?.[groupNames?.length - 1 - (subSeries ? 1 : 0)]
    } else {
        source = series[seriesIndex]?.subSeries
    }

    if (!source) return { enabled: false }

    const { enabled = true, perspectiveId } = source.navigation || {}
    const field: RawDataField = {
        resourceType: service?.type,
        resourceId: service?.id,
        resourceName: service?.name,
        ...source.field,
    }

    if (field?.resourceType !== ResourceType.NODE) return { enabled: false }

    return { field, enabled, perspectiveId }
}
