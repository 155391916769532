import { useState } from 'react'
import { List, ListItem, ListItemText, Collapse } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import classNames from 'classnames'
import Bowser from 'bowser'
import { ChevronRight } from 'genesis-suite/icons'
import Spinner from 'genesis-suite/components/Spinner'

const browserName = Bowser.getParser(window.navigator.userAgent).getBrowserName()
const isEdge = browserName === 'Microsoft Edge'

const styles = ({ spacing, typography, palette }) => ({
    container: { height: '100%', overflow: 'auto', padding: 0 },
    sticky: { position: 'sticky', top: 0, zIndex: 1, backgroundColor: palette.background.default },
    gutters: { paddingLeft: '10px' },
    groupButton: {
        '&:hover': { backgroundColor: palette.background.default, cursor: 'pointer' },
        '&:focus': { backgroundColor: palette.background.default },
    },
    groupText: { ...typography.body1, fontWeight: 700 },
    divider: { borderBottom: '2.4px solid rgba(0, 0, 0, 0.1)' },
    expandIcon: { transition: '250ms', color: palette.text.primary },
    expandIconOpen: { transform: 'rotate(90deg)' },
    itemText: typography.body1,
    secondaryText: { color: palette.text.disabled },
    item: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: spacing(1),
        '&>a': {
            flex: 1,
        },
    },
})

/**
 *
 * @param {object} props React props
 * @param {object} props.pending Show spinner if loading
 * @param {object} props.data Data in the form of:
 *                      [
 *                          {
 *                              primary!: 'Primary text',
 *                              secondary?: 'Secondary text',
 *                              list?:   [
 *                                          {
 *                                              primary!: 'Primary text',
 *                                              secondary?: 'Secondary text'
 *                                              onClick?: callback on item click
 *                                              action?: react component to place on the right of item
 *                                          }, ...
 *                              ]
 *                          }, ...
 *                      ]
 */
const GroupedList = ({ pending, data, classes }) => {
    if (pending) return <Spinner />

    if (!data) return null

    return (
        <List className={classes.container}>
            {data.map(group => (
                <Grouping key={group.primary} group={group} groupCount={data.length} classes={classes} />
            ))}
        </List>
    )
}

const Grouping = ({ group, groupCount, classes }) => {
    const { primary, secondary, list } = group
    const [isOpen, setIsOpen] = useState(list && list.length < 5 && groupCount < 5)

    let groupPrimaryText = primary
    const groupSecondaryText = secondary
    let CollapseIcon = null
    let GroupList = null
    let Divider = null

    if (list && list.length > 0) {
        groupPrimaryText += ` (${list.length})`

        CollapseIcon = <ChevronRight className={classNames(classes.expandIcon, { [classes.expandIconOpen]: isOpen })} />

        GroupList = (
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {list
                        .sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate))
                        .map((item, index) => {
                            const { primary, secondary, onClick, action: ActionComponent, disabled } = item

                            return (
                                <div key={index} className={classes.item}>
                                    <ListItem
                                        dense
                                        button
                                        disabled={disabled}
                                        classes={{ gutters: classes.gutters }}
                                        onClick={onClick}
                                    >
                                        <ListItemText
                                            primary={primary}
                                            secondary={secondary}
                                            classes={{ primary: classes.itemText, secondary: classes.secondaryText }}
                                        />
                                    </ListItem>
                                    {ActionComponent && <ActionComponent />}
                                </div>
                            )
                        })}
                </List>
            </Collapse>
        )

        Divider = <div className={classes.divider} />
    }

    return (
        <>
            <ListItem
                button={list && list.length > 0}
                onClick={() => setIsOpen(!isOpen)}
                classes={{ button: classes.groupButton, gutters: classes.gutters }}
                className={isEdge ? '' : classes.sticky}
            >
                <ListItemText
                    primary={groupPrimaryText}
                    secondary={groupSecondaryText}
                    classes={{ primary: classes.groupText }}
                />
                {CollapseIcon}
            </ListItem>

            {GroupList}

            {Divider}
        </>
    )
}

export default withStyles(styles)(GroupedList)
