import { InsightResource, ResourceType } from 'genesis-suite/types/networkTypes'
import {
    Aggregation,
    CategoryTableColumn,
    ChartType,
    TableConfig,
    TableSeries,
    ValueTableColumn,
} from 'genesis-suite/types/visualTypes'
import { makeField, stringToFilters } from 'genesis-suite/utils'
import { WidgetConverterProps } from '../migrationTypes'
import {
    getPropertyFromResource,
    makeAggregation,
    makeConditionalFormats,
    makeNavigation,
} from '../widgetConverterUtils'

export default async function tableConverter({
    config,
    dashboardIdByOldId,
    resourceManager,
}: WidgetConverterProps): Promise<TableConfig> {
    const { Title, TableConfig: tableConfig, NoDataMessage } = config
    const { Source, Fields, PageSize, PivotFieldConfig } = tableConfig
    if (!Fields) throw new Error(`Table ${Title} requires at least one table field`)

    const insightName = Source.ElementName

    const insight = await resourceManager.getInsight(insightName)

    const pivotFieldName = PivotFieldConfig?.CategoryField
    const isAggregate = Fields.some(f => isAggregateField(insight, f)) || Boolean(pivotFieldName)
    const categoryFields = isAggregate ? Fields.filter(f => !isAggregateField(insight, f)) : []
    const valueFields = isAggregate ? Fields.filter(f => isAggregateField(insight, f)) : Fields

    const categories = categoryFields.map(f => {
        const category: CategoryTableColumn = {
            field: makeField(insight.properties, f.FieldName),
            navigation: makeNavigation(f.CrumbMetaName, f.DefaultPerspective, dashboardIdByOldId),
            title: f.HeaderName,
            ...(f.Justification !== 'None' && { align: f.Justification.toLowerCase() }),
            ...(f.IsHyperlink && { isHyperlink: f.IsHyperlink }),
            ...(f.HyperlinkUrl && { hyperlinkUrl: f.HyperlinkUrl }),
            ...(f.ConditionalFormatting && {
                conditionalFormats: makeConditionalFormats(insight, f.ConditionalFormatting),
            }),
        }
        return category
    })

    let values = valueFields.map(f => {
        let aggregation = Aggregation.NONE
        if (isAggregate) {
            aggregation = makeAggregation(getPropertyFromResource(insight, f.FieldName), f.AggregationType)
            if (aggregation === Aggregation.NONE) aggregation = Aggregation.UNKNOWN
        }

        const column: ValueTableColumn = {
            field: makeField(insight.properties, f.FieldName),
            aggregation,
            title: f.HeaderName,
            ...(f.Justification !== 'None' && { align: f.Justification.toLowerCase() }),
            ...(f.IsHyperlink && { isHyperlink: f.IsHyperlink }),
            ...(f.HyperlinkUrl && { hyperlinkUrl: f.HyperlinkUrl }),
            showTotal: f.ShowTotal,
            ...(f.ConditionalFormatting && {
                conditionalFormats: makeConditionalFormats(insight, f.ConditionalFormatting),
            }),
        }
        return column
    })

    if (PivotFieldConfig) {
        const { ValueFields } = PivotFieldConfig

        const pivotValues = ValueFields.map(f => {
            const column: ValueTableColumn = {
                field: makeField(insight.properties, f.FieldName),
                aggregation: makeAggregation(getPropertyFromResource(insight, f.FieldName), f.AggregationType),
                title: f.HeaderName,
                ...(f.Justification !== 'None' && { align: f.Justification.toLowerCase() }),
                ...(f.IsHyperlink && { isHyperlink: f.IsHyperlink }),
                ...(f.HyperlinkUrl && { hyperlinkUrl: f.HyperlinkUrl }),
                showTotal: f.ShowTotal,
                ...(f.ConditionalFormatting && {
                    conditionalFormats: makeConditionalFormats(insight, f.ConditionalFormatting),
                }),
            }
            return column
        })

        values = values.concat(pivotValues)
    }

    const series: TableSeries = {
        service: { type: ResourceType.INSIGHT, name: insightName, id: insight.id },
        pageSize: PageSize,
        values,
        ...(pivotFieldName && { subSeries: { field: makeField(insight.properties, pivotFieldName) } }),
    }

    const builderConfig: TableConfig = {
        version: '2',
        type: ChartType.TABLE,
        title: Title,
        noDataMessage: NoDataMessage,
        ...(categories.length && { categories }),
        series: [series],
        filters: stringToFilters(insight.properties, Source?.Filters),
    }

    return builderConfig
}

const isAggregateField = (insight: InsightResource, f) => {
    if (f.AggregationType.toLowerCase() !== 'unknown') return true

    const property = insight.properties.find(p => p.name === f.FieldName)
    if (!property) return false

    return Boolean(property.hasAggregates)
}
