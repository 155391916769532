import { FilterList } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogTitle, IconButton, Tooltip, styled } from '@mui/material'
import { useState } from 'react'
import { NetworkFiltersApplyButton, NetworkFiltersList, NetworkFiltersProvider } from './NetworkFiltersContext'

const StyledDialog = styled(Dialog)(() => ({
    '& .MuiDialog-paper': {
        width: 500,
        height: 500,
    },
}))

export default function NetworkFiltersController({ iconStyle, buttonStyle }) {
    const [open, setOpen] = useState(false)
    const toggleDialog = () => setOpen(!open)

    return (
        <>
            <Tooltip title="Network Filters">
                <IconButton sx={buttonStyle} aria-label="Open network filters" onClick={toggleDialog}>
                    <FilterList sx={iconStyle} />
                </IconButton>
            </Tooltip>
            <StyledDialog open={open} onClose={toggleDialog}>
                <NetworkFiltersProvider>
                    <DialogTitle>Network Filters</DialogTitle>
                    <NetworkFiltersList />
                    <DialogActions>
                        <Button onClick={toggleDialog}>Cancel</Button>
                        <NetworkFiltersApplyButton onApply={toggleDialog} />
                    </DialogActions>
                </NetworkFiltersProvider>
            </StyledDialog>
        </>
    )
}
