import DashboardDisplay from '~/components/widgets2/dashboard/DashboardDisplay'
import defaultHomePage from '~/lib/defaultHomePage'
import { HomeDashboardProvider, useHomeDashboardContext } from '~/components/widgets2/dashboard/HomeDashboardContext'

function HomePageManageViewContent() {
    const { dashboard, appOptionsDashboard } = useHomeDashboardContext()
    return <DashboardDisplay config={dashboard || appOptionsDashboard || defaultHomePage} widgets={[]} />
}

const HomePageManageView = () => {
    return (
        <HomeDashboardProvider>
            <HomePageManageViewContent />
        </HomeDashboardProvider>
    )
}

export default HomePageManageView
