import React from 'react'
import { Box, Link, Typography } from '@mui/material'

export default function RefreshError() {
    const refresh = () => window.location.reload()

    return (
        <Box height="100%" display="flex" alignItems="center" justifyContent="center">
            <Typography variant="h5">
                An error has occurred, please{' '}
                <Link href="#" onClick={refresh}>
                    refresh
                </Link>{' '}
                to try again.
            </Typography>
        </Box>
    )
}
