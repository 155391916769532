import { useSelector } from 'react-redux'

import Select, { Props as SelectProps } from 'genesis-suite/components/AsyncResourceValueSelect'
import { modelService } from '../lib/services'
import { applicationSelectors, getSelectedBackup } from '../selectors'

type Props<
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined
> = Omit<SelectProps<Multiple, DisableClearable, FreeSolo>, 'appName' | 'service'>

export default function AsyncResourceValueSelect<
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined
>(props: Props<Multiple, DisableClearable, FreeSolo>) {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const selectedBackup = useSelector(getSelectedBackup)

    return (
        <Select
            sx={{
                '& .MuiAutocomplete-endAdornment svg': {
                    color: 'text.primary',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'text.primary',
                    opacity: 0.6,
                },
                '&:hover .MuiOutlinedInput-notchedOutline,': {
                    borderColor: 'text.primary',
                    opacity: 1,
                },
                ...props?.sx,
            }}
            appName={appName}
            backupId={selectedBackup?.[0]?.Id}
            service={modelService.getResourceValues}
            {...props}
        />
    )
}
