import { useState } from 'react'
import {
    Badge,
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Fab,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material'
import TabUnselectedIcon from '@mui/icons-material/TabUnselected'
import makeStyles from '@mui/styles/makeStyles'
import { Search, Refresh, Close } from 'genesis-suite/icons'
import { ChopText } from 'genesis-suite/components'

import { useIsMobile } from '~/hooks/useIsMobile'
import { widgetConstants } from '~/constants'

const useStyles = makeStyles(({ spacing, palette }) => ({
    infoContainer: { minWidth: '300px', maxHeight: '560px', maxWidth: '560px', padding: spacing(0, 1) },
    toolbar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    refresh: {
        fontSize: '14px',
    },
    refreshButton: {
        backgroundColor: palette.grayscale.lighter,
        height: '25px',
        width: '25px',
        borderRadius: '15px',
        minWidth: 0,
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    titleWrapper: { display: 'flex', alignItems: 'center' },
    titleText: { margin: '0 0 0 10px' },
    titleRoot: { padding: spacing(1) },
    backdrop: { cursor: 'not-allowed' },
    container: { cursor: 'default' },
    closeIcon: { padding: spacing(1), marginLeft: 'auto', color: palette.text.primary },
}))

const WidgetMinimised = ({ minimizedWidgets = [], triggerRefresh }) => {
    const isMobile = useIsMobile()
    const { palette } = useTheme()

    const [isModelOpen, setIsModelOpen] = useState(false)

    const handleModelClose = () => {
        setIsModelOpen(false)
    }

    const handleOpenModel = () => {
        if (minimizedWidgets.length > 0) {
            setIsModelOpen(true)
        }
    }
    return (
        <>
            <div style={{ height: 0 }}>
                <Tooltip title="Minimised Widgets" placement="top">
                    <Fab
                        color="primary"
                        size="medium"
                        aria-label="Minimised Widgets"
                        sx={{
                            position: 'relative',
                            zIndex: 50,
                            bottom: isMobile ? 110 : 60,
                            left: 'calc(95% - 24px)',
                            color: palette.text.primary,
                            backgroundColor: palette.background.sideNav,
                            '&:hover': {
                                backgroundColor: palette.background.sideNav,
                            },
                        }}
                        onClick={handleOpenModel}
                    >
                        <Badge badgeContent={minimizedWidgets.length} color="primary">
                            <TabUnselectedIcon />
                        </Badge>
                    </Fab>
                </Tooltip>
            </div>
            <MinimizedContainer
                open={isModelOpen}
                onClose={handleModelClose}
                minimizedWidgets={minimizedWidgets}
                triggerRefresh={triggerRefresh}
            />
        </>
    )
}

const MinimizedContainer = ({ minimizedWidgets, triggerRefresh, open, onClose }) => {
    const classes = useStyles()
    const { ERROR, EMPTY } = widgetConstants.DataStatus

    const getReason = (reason, widget) => {
        switch (reason) {
            case EMPTY:
                return (
                    <Tooltip
                        title={
                            <Box display="flex" alignItems="center" justifyContent="center">
                                <Box mx={1}>
                                    <Search scale={0.6} />
                                </Box>
                                <Typography variant="body1">{widget.NoDataMessage}</Typography>
                            </Box>
                        }
                        placement="top"
                        arrow
                    >
                        <Typography variant="body1" gutterBottom>
                            No Data
                        </Typography>
                    </Tooltip>
                )
            case ERROR:
                return (
                    <Tooltip
                        title={
                            <Typography>
                                <b> Something went wrong. </b> <br />
                                Please refresh the page and try again. If the error persists you can contact us
                            </Typography>
                        }
                        placement="top"
                        arrow
                    >
                        <Typography variant="body1" gutterBottom>
                            Error
                        </Typography>
                    </Tooltip>
                )
            default:
                return (
                    <Typography variant="body1" gutterBottom>
                        No Data
                    </Typography>
                )
        }
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            hideBackdrop={true}
            classes={{ container: classes.backdrop, paper: classes.container }}
        >
            <DialogTitle classes={{ root: classes.titleRoot }}>
                <div className={classes.titleWrapper}>
                    <Typography variant="h6" className={classes.titleText}>
                        Minimized Widget
                    </Typography>
                    <IconButton classes={{ root: classes.closeIcon }} onClick={onClose} size="large">
                        <Close />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className={classes.infoContainer}>
                    <TableContainer component={Paper}>
                        <Table size="medium">
                            <TableBody>
                                {minimizedWidgets.map(widget => (
                                    <TableRow key={widget.Id}>
                                        <TableCell sx={{ width: '350px' }}>
                                            <ChopText variant="body1" showEllipsis tooltipProps={{ placement: 'top' }}>
                                                {widget.Title}
                                            </ChopText>
                                        </TableCell>
                                        <TableCell>{getReason(widget.minimizeReason, widget)}</TableCell>
                                        <TableCell>
                                            {widget.minimizeReason === ERROR ? (
                                                <Button
                                                    className={classes.refreshButton}
                                                    onClick={() => triggerRefresh(widget)}
                                                >
                                                    <Refresh classes={{ root: classes.refresh }} />
                                                </Button>
                                            ) : null}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default WidgetMinimised
