import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'

import { authCreators } from '../actions/creators'
import { useIsMobile } from '../hooks/useIsMobile'
import useSessionStatus, { showContent } from '../hooks/useSessionStatus'
import { requiresAuthentication, requiresMobile, routePaths } from '../lib/routes'
import { login } from '../lib/userManager'

export default function AuthorizationGateway({ children }) {
    const isMobile = useIsMobile()
    const location = useLocation()
    const dispatch = useDispatch()

    const requireAuth = requiresAuthentication(location.pathname)
    const requireMobile = requiresMobile(location.pathname)
    const [sessionStatus, extendSession] = useSessionStatus(requireAuth, true)

    const logout = () => dispatch(authCreators.logout())
    const dialog = getDialog(sessionStatus, extendSession, logout)

    useEffect(() => {
        if (sessionStatus === 'failed') login()
        if (sessionStatus === 'locked') logout()
    }, [sessionStatus])

    if (requireMobile && !isMobile) return <Navigate to={routePaths.HOME} />

    return (
        <>
            {Boolean(dialog) && (
                <Dialog open>
                    <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                        <Typography>{dialog.message}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" {...dialog.yesButtonProps} />
                        {dialog.noButtonProps && <Button variant="contained" {...dialog.noButtonProps} />}
                    </DialogActions>
                </Dialog>
            )}

            {showContent(sessionStatus) && children}
        </>
    )
}

function getDialog(sessionStatus, extendSession, logout) {
    switch (sessionStatus) {
        case 'expired':
            return {
                message: 'Your session has expired. You will be logged out.',
                yesButtonProps: { children: 'Ok', onClick: logout },
            }

        case 'timed-out':
            return {
                message: 'Are you still here? Would you like to extend your session?',
                yesButtonProps: { children: 'Yes, extend', onClick: extendSession },
                noButtonProps: { children: 'No, logout', onClick: logout },
            }
    }
}
