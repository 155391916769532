import { ChangeEvent, Dispatch, MutableRefObject, SetStateAction, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    TextField,
    Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import CircleIcon from '@mui/icons-material/Circle'
import axios from 'axios'
import { uppercaseFirstLetter } from 'genesis-suite/utils/stringUtils'
import { useInterval } from 'genesis-suite/hooks'
import { deploymentCreators } from '../actions/creators'
import { deploymentSelectors } from '../selectors'
import { stagingAuthService } from '../lib/services'
import { DeploymentView, DeploymentViewColor, DeploymentViewTooltip } from '../types/DeploymentTypes'
import DeploymentViewSelector, { DeploymentViewSelectorOption } from './DeploymentViewSelector'

export default function DeploymentViewController({ iconProps, isDisabled }) {
    const [open, setOpen] = useState(false)
    const dispatch = useDispatch()
    const currentDeploymentMode = useSelector(deploymentSelectors.getDeploymentMode)
    const stagingViewVisited = useRef(false)

    const options: Array<DeploymentViewSelectorOption> = [
        {
            value: DeploymentView.LIVE,
            Icon: CircleIcon,
            color: DeploymentViewColor.LIVE,
            label: uppercaseFirstLetter(DeploymentView.LIVE),
            tooltip: DeploymentViewTooltip.LIVE,
        },
        {
            value: DeploymentView.DRAFT,
            Icon: CircleIcon,
            color: DeploymentViewColor.DRAFT,
            label: uppercaseFirstLetter(DeploymentView.DRAFT),
            tooltip: DeploymentViewTooltip.DRAFT,
        },
        {
            value: DeploymentView.STAGING,
            Icon: CircleIcon,
            color: DeploymentViewColor.STAGING,
            label: uppercaseFirstLetter(DeploymentView.STAGING),
            tooltip: DeploymentViewTooltip.STAGING,
        },
    ]

    const handleChange = (option: DeploymentViewSelectorOption) => {
        const selectedDeploymentView: DeploymentView = option.value

        if (currentDeploymentMode.view === selectedDeploymentView) return

        switch (selectedDeploymentView) {
            case DeploymentView.DRAFT:
            case DeploymentView.LIVE:
                dispatch(deploymentCreators.setDeploymentMode({ view: selectedDeploymentView }))
                return

            case DeploymentView.STAGING:
                if (!currentDeploymentMode.accessKey) setOpen(true)
                else
                    dispatch(
                        deploymentCreators.setDeploymentMode({
                            view: selectedDeploymentView,
                            accessKey: currentDeploymentMode.accessKey,
                        })
                    )
        }
    }

    useInterval(() => {
        if (stagingViewVisited.current) stagingAuthService.ping()
    }, 5 * 60 * 1000)

    return (
        <>
            <DeploymentViewSelector
                value={options.find(option => option.value === currentDeploymentMode.view)}
                tooltip="Change deployment view"
                items={options}
                iconProps={iconProps}
                disabled={isDisabled}
                onChange={handleChange}
            />
            <StagingViewAuthentication open={open} setOpen={setOpen} stagingViewVisited={stagingViewVisited} />
        </>
    )
}

interface StagingViewAuthenticationProps {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    stagingViewVisited: MutableRefObject<boolean>
}

function StagingViewAuthentication({ open, setOpen, stagingViewVisited }: StagingViewAuthenticationProps) {
    const dispatch = useDispatch()
    const [formFields, setFormFields] = useState({ UserId: '', password: '' })
    const [formError, setFormError] = useState('')
    const [authenticating, setAuthenticating] = useState(false)

    const clearFormError = () => {
        setFormError('')
    }

    const handleFormFields = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
        clearFormError()
        setFormFields({ ...formFields, [name]: value })
    }

    const handleClose = () => {
        clearFormError()
        setOpen(false)
        setFormFields({ UserId: '', password: '' })
    }

    const handleAuth = async (event: React.SyntheticEvent) => {
        event.preventDefault()
        try {
            setAuthenticating(true)
            const authResponse = await auth(formFields.UserId, formFields.password)
            const accessKey = authResponse.data
            dispatch(deploymentCreators.setDeploymentMode({ view: DeploymentView.STAGING, accessKey }))
            if (!stagingViewVisited.current) stagingViewVisited.current = true
            handleClose()
        } catch (error) {
            const defaultErrorMessage = 'Unable to Sign In'
            setFormError(error?.response?.data?.Message ?? defaultErrorMessage)
        } finally {
            setAuthenticating(false)
        }
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
                Staging View
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'text.primary',
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <form onSubmit={handleAuth}>
                <DialogContent sx={{ pt: 0 }}>
                    <DialogContentText sx={{ pb: 1, color: 'text.primary' }}>
                        Please enter your credentials to access this view mode.
                    </DialogContentText>
                    <Box
                        sx={{
                            '& .MuiInputLabel-root': {
                                color: 'text.primary',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'border.main',
                            },
                        }}
                        display="flex"
                        flexDirection="column"
                    >
                        <TextField
                            value={formFields.UserId}
                            onChange={handleFormFields}
                            name="UserId"
                            label="Username"
                            variant="outlined"
                            margin="normal"
                        />
                        <TextField
                            value={formFields.password}
                            onChange={handleFormFields}
                            type="password"
                            name="password"
                            label="Password"
                            variant="outlined"
                            margin="normal"
                        />
                    </Box>
                    {formError && <Typography sx={{ mt: 1, color: 'error.main' }}>{formError}</Typography>}
                </DialogContent>
                <DialogActions sx={{ pr: 3 }}>
                    <Button
                        sx={{ width: 105 }}
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={
                            !Boolean(formFields.UserId.trim()) || !Boolean(formFields.password.trim()) || authenticating
                        }
                    >
                        {authenticating ? <CircularProgress size={24} /> : 'Authenticate'}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

function auth(UserId: string, password: string) {
    return axios.post(`${window.TADA_APIS.STAGING_INTEGRATION}/security/check`, {
        UserId,
        password,
    })
}
