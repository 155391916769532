import { useContext } from 'react'
import produce from 'immer'

import { PropertyType } from 'genesis-suite/types/architectureTypes'
import { Basket, Aggregation, SeriesConfig } from 'genesis-suite/types/visualTypes'
import { letMeMap } from 'genesis-suite/types/utilTypes'
import { ConfigContext } from '../ConfigContext'
import DnDPropertySelector from './DnDPropertySelector'
import { getDataFieldProperty, getDefaultAggregation } from '../utils'
import { DisplayProperty, DragField, FieldPointer } from '../builderTypes'
import useWidgetColors from '../../../hooks/useWidgetColors'
import { useSemanticTypeById } from '../../../hooks/useSemanticTypes'
import isRawSeries from '../../widgets2/utils/isRawSeries'

interface Props {
    basket?: Basket
    label?: string
    limit?: number
}

/** For charts that only have one series and multiple values (most charts) */
export default function ValuesSelector({ basket, label, limit }: Props) {
    const semanticTypeById = useSemanticTypeById()
    const { dispatch, selectedField, resources, ...rest } = useContext(ConfigContext)
    const config = rest.config as SeriesConfig
    const { series } = config
    const colors = useWidgetColors()
    const firstSeries = series?.[0]
    const isAggregate = !isRawSeries(firstSeries)

    const seriesValues =
        letMeMap(firstSeries?.values)?.map(value => ({
            ...value,
            service: { ...series[0].service, color: colors[0] },
        })) ?? []

    const handleAdd = (dragField: DragField, index?: number) => {
        const pointerValueIndex = index !== undefined ? index : seriesValues.length
        const { pointer, type, ...field } = dragField
        const to: FieldPointer = { type: 'series', index: 0, valueIndex: pointerValueIndex }
        const { properties } = resources?.byId[resources.selectedId] ?? {}
        const { semanticType, hasAggregates } = getDataFieldProperty(field, properties)

        const aggregation = isAggregate ? getDefaultAggregation(semanticType, hasAggregates) : Aggregation.NONE
        const source = { field, aggregation, basket }

        const options = pointer ? { operation: 'move' as const, from: pointer } : { operation: 'new' as const, source }

        dispatch({ type: 'UPDATE_SELECTED_PROPERTY', payload: { to, limit, ...options, semanticTypeById } })
    }

    const handleRemove = index => {
        dispatch({ type: 'SET_SELECTED_FIELD', payload: null })

        const newValues = produce(seriesValues, draft => {
            draft.splice(index, 1)
        })
        if (newValues.length) dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: { values: newValues } })
        else dispatch({ type: 'UPDATE_CONFIG', payload: { series: [] } })
    }

    const handleAggregationChange = (index: number, value: Aggregation) => {
        const newValues = produce(firstSeries?.values, draft => {
            draft[index].aggregation = value
        })
        dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: { values: newValues } })
    }

    const displayValues: DisplayProperty[] = seriesValues.map((value, i) => {
        const { properties } = resources.byId[value.service.id] || {}
        const { displayName, semanticType } = getDataFieldProperty(value.field, properties)
        return {
            ...value,
            type: semanticType?.type,
            title: displayName,
            pointer: { type: 'series', index: 0, valueIndex: i },
            selected: selectedField?.type === 'series' && selectedField?.valueIndex === i,
            color: value.service.color,
        }
    })

    return (
        <DnDPropertySelector
            label={label}
            accept={[PropertyType.DEFINING, PropertyType.QUALITATIVE, PropertyType.QUANTITATIVE]}
            limit={limit}
            properties={displayValues}
            onFieldSelect={i => dispatch({ type: 'SET_SELECTED_FIELD', payload: displayValues[i].pointer })}
            onAggregationChange={isAggregate ? handleAggregationChange : undefined}
            onAdd={handleAdd}
            onRemove={handleRemove}
        />
    )
}
