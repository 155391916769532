import { useState } from 'react'
import { useSnackbar } from 'notistack'
import { MenuIcon } from 'genesis-suite/components'
import { useDispatch, useSelector } from 'react-redux'
import { IconButton, Tooltip, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Notification, Settings, Archive } from 'genesis-suite/icons'

import GroupedList from '../GroupedList'
import NotificationSettings from './NotificationSettings'
import getDefaultPerspective from '../../lib/getDefaultPerspective'
import { dialogCreators, notificationCreators, navigationCreators } from '../../actions/creators'
import { logEvent } from '../../lib/amplitudeClient'
import { moduleSelectors } from '../../selectors'
import moment from 'moment'

const useStyles = makeStyles(({ spacing }) => ({
    contentsContainer: { width: '450px', maxWidth: '100%', height: '342px' },
    header: {
        display: 'flex',
        alignItems: 'center',
        padding: spacing(1),
        justifyContent: 'space-between',
    },
    notifications: { position: 'relative', height: 'calc(100% - 56px)' },
    emptyState: {
        top: '50%',
        left: '50%',
        margin: 'auto',
        fontWeight: 700,
        textAlign: 'center',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
    },
}))

export default ({ iconStyle, buttonStyle }) => {
    const classes = useStyles({})
    const moduleId = useSelector(moduleSelectors.getCurrentModule)?.id
    const notifications = useSelector(state => state.notifications.all || [])
    const requestPending = useSelector(state => state.notifications.isPending)

    const [open, setOpen] = useState(false)

    const { enqueueSnackbar: showSnackbar } = useSnackbar()

    const dispatch = useDispatch()
    const showSettings = () =>
        dispatch(
            dialogCreators.showDialog('noti-settings', NotificationSettings, {
                title: 'Notification settings',
                titleIcon: Settings,
                maxWidth: 'sm',
                fullWidth: true,
            })
        )
    const archiveNotification = id =>
        dispatch(notificationCreators.archive(id))
            .then(() => showSnackbar('Notification archived', { variant: 'success' }))
            .catch(() => showSnackbar('An error occurred archiving notification', { variant: 'error' }))
    const openPerspective = (id, state) => dispatch(navigationCreators.goToPerspective(id, state))

    const handleClose = () => setOpen(false)

    const filtered = notifications.filter(n => !n.VisorId || n.VisorId === moduleId)
    const groupedResults = createGroupedResults(openPerspective, filtered, archiveNotification, handleClose)

    return (
        <MenuIcon
            badge={filtered.length}
            icon={<Notification sx={iconStyle} />}
            buttonProps={{ sx: buttonStyle }}
            tooltip="Notifications"
            noPadding
            open={open}
            onClose={handleClose}
            onClick={() => setOpen(true)}
        >
            <div className={classes.contentsContainer}>
                <div className={classes.header}>
                    <Typography variant="h6">Notifications</Typography>
                    <div className={classes.headerActions}>
                        <Tooltip title="Modify Notification Settings">
                            <IconButton onClick={showSettings} size="large" sx={{ color: 'text.primary' }}>
                                <Settings />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                <div className={classes.notifications}>
                    {!requestPending && filtered.length === 0 ? (
                        <Typography variant="subtitle1" classes={{ root: classes.emptyState }}>
                            No New Notifications
                        </Typography>
                    ) : (
                        <GroupedList data={groupedResults} pending={requestPending} />
                    )}
                </div>
            </div>
        </MenuIcon>
    )
}

/**
 * Take notifications and create grouping for GroupedList
 * @param {*} notifications Notifications data array
 */
function createGroupedResults(openPerspective, notifications, onArchive, onClose) {
    if (notifications.length === 0) return [{ primary: 'No Results' }]

    const groupedResults = []
    notifications.forEach(notification => {
        const {
            Id,
            GroupName,
            CrumbMetaName,
            CrumbName,
            CrumbValue,
            DefaultPerspective,
            Title,
            Description,
            CreatedOn: creationDate,
        } = notification

        if (groupedResults.length === 0 || groupedResults.findIndex(r => r.primary === GroupName) === -1) {
            groupedResults.push({ primary: GroupName, list: [] })
        }

        const ArchiveButton = () => (
            <Tooltip title="Archive">
                <IconButton
                    sx={{ color: 'text.primary' }}
                    onClick={e => {
                        e.stopPropagation()
                        onArchive(Id)
                    }}
                    size="large"
                >
                    <Archive fontSize="small" />
                </IconButton>
            </Tooltip>
        )

        const crumb = {
            Name: CrumbMetaName,
            FieldName: CrumbName,
            Value: CrumbValue,
            DisplayValue: CrumbValue,
            DisplayValueFieldName: CrumbName,
            DefaultPerspective: DefaultPerspective,
        }

        const perspectiveId = getDefaultPerspective(crumb)
        const handleClick = perspectiveId
            ? () => {
                  openPerspective(perspectiveId, { context: crumb })
                  logEvent('NOTIFICATION_CLICK', { title: Title })
                  onClose()
              }
            : null

        const formattedDate = moment(creationDate).format('MMM D, YYYY h:mm A')
        const secondary = Description ? `${formattedDate} - ${Description.replace(/\r?\n/g, '<br />')}` : formattedDate
        groupedResults.forEach(function (r, i) {
            if (r.primary === GroupName)
                this[i].list.push({
                    id: Id,
                    primary: Title,
                    secondary: <div dangerouslySetInnerHTML={{ __html: secondary }} />,
                    onClick: handleClick,
                    creationDate,
                    action: ArchiveButton,
                })
        }, groupedResults)
    })

    groupedResults.sort(({ primary: aName }, { primary: bName }) => (aName < bName ? -1 : aName > bName ? 1 : 0))
    return groupedResults
}
