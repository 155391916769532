import { useRef } from 'react'
import { Marker, MarkerClusterer } from '@react-google-maps/api'
import tinyColor from 'tinycolor2'

import { Basket, MapConfig, MapMarkerSeries } from 'genesis-suite/types/visualTypes'
import { letMeMap } from 'genesis-suite/types/utilTypes'
import { generateIconPath, Limits, MarkerGeoData } from './mapUtils'
import { roundNumber } from '../../../lib/utils'
import { WidgetProps } from '../../../types/WidgetTypes'

interface Props extends Omit<WidgetProps<MapConfig>, 'data'>, MarkerGeoData {
    seriesIndex: number
}

export default function MapMarkers({
    canClick,
    color,
    config,
    data,
    selectedPoints,
    seriesIndex,
    setSelectedPoint,
}: Props) {
    const hasWarned = useRef(false)
    if (!data) return null

    const seriesConfig = config.series[seriesIndex] as MapMarkerSeries
    const dataPoints = letMeMap(data.data)
    const markerIndex = seriesConfig.values.findIndex(v => v.basket === Basket.ID)
    const markerSource = seriesConfig.values.find(v => v.basket === Basket.ID)
    const markerSvgPath = generateIconPath(seriesConfig.markerType)

    const sizeLimits = dataPoints.reduce((acc, cur) => {
        const size = cur.size
        if (!size) return acc
        return { min: acc.min ? Math.min(acc.min, size) : size, max: acc.max ? Math.max(acc.max, size) : size }
    }, {} as Limits)

    const markers = dataPoints
        .map(({ latitude, longitude, size, marker }, rowIndex) => {
            if (!latitude || !longitude) return

            const isSelected = selectedPoints.some(p => p.series === seriesIndex && p.raw.row === rowIndex)

            return {
                marker,
                position: { lat: latitude, lng: longitude },
                icon: {
                    scale: makeScale(size),
                    path: markerSvgPath,
                    fillColor: color,
                    fillOpacity: 0.8,
                    strokeWeight: 0,
                    ...(isSelected && { strokeWeight: 2, strokeColor: 'red' }),
                },
                rowIndex,
            }
        })
        .filter(m => Boolean(m))

    if (!hasWarned.current && markers.length !== dataPoints.length) {
        hasWarned.current = true
        console.warn(
            `Series ${seriesConfig.title} has ${dataPoints.length - markers.length} points with missing lat/lng data`
        )
    }

    function makeScale(size) {
        let minScale = 0.5
        let maxScale = 8
        let value = (seriesConfig.size ?? 15) / 100

        if (size) {
            const sliderOffset = 3
            minScale += value * sliderOffset
            maxScale += (value - 1) * sliderOffset
            value = (size - sizeLimits.min) / (sizeLimits.max - sizeLimits.min)
        }

        const offset = minScale
        const slope = maxScale - offset
        return roundNumber(slope * value + offset, 2)
    }

    const clusterStyles = [
        {
            width: 40,
            height: 40,
            url: generateClusterSVG(color),
            textColor: tinyColor(color).isDark() ? '#fff' : '#000',
            textSize: 12,
            color,
        },
    ]

    function handleMouseOver(event: PointerEvent, index: number) {
        setSelectedPoint({
            dataIndexes: { series: seriesIndex, raw: { row: index, ...(markerIndex > -1 && { column: markerIndex }) } },
            event,
        })
    }

    function handleClick(event, index: number) {
        setSelectedPoint({
            clicked: true,
            dataIndexes: { series: seriesIndex, raw: { row: index, ...(markerIndex > -1 && { column: markerIndex }) } },
            event,
        })
    }

    return (
        <MarkerClusterer
            gridSize={seriesConfig.clusterOptions?.gridSize ?? 10}
            minimumClusterSize={seriesConfig.clusterOptions?.minimumClusterSize ?? 100}
            styles={clusterStyles}
        >
            {clusterer => (
                <>
                    {markers.map(({ position, icon, rowIndex }) => (
                        <Marker
                            key={rowIndex}
                            clusterer={clusterer}
                            cursor={canClick(markerSource?.field.resourceName) ? 'pointer' : 'default'}
                            position={position}
                            icon={{
                                ...icon,
                            }}
                            onClick={e => handleClick(e.domEvent, rowIndex)}
                            onMouseOver={e => handleMouseOver(e.domEvent as any, rowIndex)}
                            onMouseOut={() => setSelectedPoint(null)}
                            title="marker"
                        />
                    ))}
                </>
            )}
        </MarkerClusterer>
    )
}

const generateClusterSVG = color => {
    const encoded = window.btoa(
        `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-100 -100 200 200"><g fill="${color}" stroke="black" stroke-width="5"><circle r="82"/><use xlink:href="#a"/><g transform="rotate(120)"><use xlink:href="#a"/></g><g transform="rotate(240)"><use xlink:href="#a"/></g></g></svg>`
    )

    return `data:image/svg+xml;base64,${encoded}`
}
