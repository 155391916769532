import { Checkbox, CheckboxProps, Radio, RadioProps } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ palette }) => ({
    boolean: { '& svg': { color: (p: any) => (p.color ? palette[p.color].main : palette.text.primary) } },
}))

type Props<Type = BooleanType> = Type extends BooleanType.radio ? RadioWithType : CheckboxWithType

enum BooleanType {
    check = 'check',
    radio = 'radio',
}

interface BaseProps {
    type?: BooleanType
}

interface RadioWithType extends BaseProps, RadioProps { }
interface CheckboxWithType extends BaseProps, CheckboxProps { }

/** Render a mui Checkbox (default) or Radio. Handles properly coloring the element */
export default function BooleanSelect<Type = BooleanType.check>({ size, ...props }: Props<Type>) {
    const classes = useStyles({ color: props.color })
    const radioSize: 'medium' | 'small' = (size === 'large') ? 'medium' : size; // Convert 'large' to 'medium'

    return (props.type === BooleanType.radio)
        ? <Radio className={classes.boolean} size={radioSize} {...props} />
        : <Checkbox className={classes.boolean} size={size} {...props} />
}
