import { useSelector } from 'react-redux'
import { List, ListItemText, Divider, ListItemButton } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-pro'
import useSWR from 'swr'

import { DateOrTime } from 'genesis-suite/components'
import { SortBy } from 'genesis-suite/types/utilTypes'
import { DraftCountByType, User } from 'genesis-suite/types/visualTypes'
import { applicationSelectors, authSelectors, deploymentSelectors } from '../../selectors'
import TableHeaderCell from '../TableHeaderCell'
import { visualService } from '../../lib/services'

export function makeColumns(
    filter: { createdBy: string },
    onUpdateFilter: (updated: Partial<{ createdBy: string }>) => void
): GridColDef[] {
    return [
        {
            disableColumnMenu: true,
            flex: 1,
            field: 'createdAt',
            pinnable: false,
            resizable: false,
            renderHeader: () => <TableHeaderCell title="Created" />,
            renderCell: ({ value }) => <DateOrTime>{value}</DateOrTime>,
            sortable: true,
            sortingOrder: ['desc', 'asc', null],
        },
        {
            disableColumnMenu: true,
            flex: 4,
            field: 'createdBy',
            pinnable: false,
            renderHeader: () => <AuthorHeader filter={filter} onUpdateFilter={onUpdateFilter} />,
            renderCell: ({ value }: { value?: User }) => `${value.firstName} ${value.lastName}`,
            sortable: true,
            sortingOrder: ['asc', 'desc', null],
        },
        {
            align: 'right',
            headerAlign: 'right',
            disableColumnMenu: true,
            flex: 2,
            field: 'counts',
            pinnable: false,
            resizable: false,
            renderHeader: () => <TableHeaderCell title="Total Changes" />,
            renderCell: ({ value }: { value?: DraftCountByType }) => value.all.total,
            sortable: false,
            sortingOrder: ['desc', 'asc', null],
        },
    ]
}

interface FilterableHeaderProps {
    filter: { createdBy: string }
    onUpdateFilter: (updated: Partial<{ createdBy: string }>) => void
}

function AuthorHeader({ filter, onUpdateFilter }: FilterableHeaderProps) {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const userId = useSelector(authSelectors.getUserId)
    const view = useSelector(deploymentSelectors.getDeploymentViewFlag)
    const { data } = useSWR(['publish createdBy list', appName], ([_, appName]) =>
        visualService.getPublishDistinctList(appName, 'createdBy', view)
    )

    return (
        <TableHeaderCell
            title="Author"
            filterApplied={Boolean(filter.createdBy)}
            filter={
                <List dense disablePadding>
                    <ListItemButton
                        selected={filter.createdBy === userId}
                        onClick={e => onUpdateFilter({ createdBy: userId })}
                    >
                        <ListItemText primary="Yours" />
                    </ListItemButton>
                    <Divider />
                    {data
                        ?.filter(user => user.id !== userId)
                        .map(user => (
                            <ListItemButton
                                key={user.id}
                                selected={user.id === filter.createdBy}
                                onClick={e => onUpdateFilter({ createdBy: user.id })}
                            >
                                <ListItemText primary={`${user.firstName} ${user.lastName}`} />
                            </ListItemButton>
                        ))}
                </List>
            }
            onClearFilter={() => onUpdateFilter({ createdBy: undefined })}
        />
    )
}

export const createdBySortKey = 'createdBy.lastName'

export function makeFilterOptions(filter: { createdBy: string }, sortBy?: SortBy, page?: number) {
    return {
        ...filter,
        sortBy,
        ...(page && { pageSize: 30, page }),
    }
}
