import { useState } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import SsidChartRoundedIcon from '@mui/icons-material/SsidChartRounded'
import SyncChartModel from '../SyncChartModel'

const SyncChartButton = ({ config, iconStyle, buttonStyle }) => {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <>
            {config?.ChartConfig?.Series?.length > 1 && (
                <Tooltip title="Synchronized Chart">
                    <IconButton sx={buttonStyle} onClick={() => setIsOpen(true)}>
                        <SsidChartRoundedIcon sx={iconStyle} />
                    </IconButton>
                </Tooltip>
            )}

            <SyncChartModel config={config} open={isOpen} onClose={() => setIsOpen(false)} />
        </>
    )
}

export default SyncChartButton
