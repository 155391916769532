import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import TabPerspectiveView from './TabPerpectiveView'
import { widgetSelectors, lockoutSelectors } from '~/selectors'
import { networkCreators, widgetCreators } from '~/actions/creators'

const TabPerspective = ({ id, perspectiveLayout, isEdit, isCompare = false }) => {
    const editingPerspective = useSelector(widgetSelectors.getEditingPerspective)
    const draftLayouts = useSelector(widgetSelectors.getDraftPerspectiveLayouts)
    const currentLayouts = useSelector(widgetSelectors.getPerspectiveLayoutsByPerspectiveId(id))
    const dispatch = useDispatch()

    const props = {
        id,
        editingPerspective,
        layouts:
            perspectiveLayout?.lg?.length > 0 && perspectiveLayout?.sm?.length > 0 ? perspectiveLayout : currentLayouts,
        containerWidth: useSelector(widgetSelectors.getPerspectiveWidth),
        isEditing: isEdit,
        currentLayout: useSelector(widgetSelectors.getCurrentLayout),
        configs: useSelector(s => widgetSelectors.getWidgetConfigsForPerspective(s, id)),
        hasFetched: useSelector(widgetSelectors.getHasFetchedPerspectiveById(id)),
        lockedWidgets: useSelector(lockoutSelectors.getItems),
        onChange: id => dispatch(networkCreators.perspectiveChange(id)),
        changeLayouts: layouts => dispatch(widgetCreators.changePerspectiveLayouts(layouts)),
        onEditPerspective: () => dispatch(widgetCreators.startEditingPerspective()),
        isCompare: isCompare,
    }

    return (
        <>
            <TabPerspectiveView key={id} {...props} />
        </>
    )
}

export const MemoizedTabPerspective = React.memo(TabPerspective)
