import { uniqBy } from 'lodash'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import useSWR, { SWRConfiguration } from 'swr'

import { BareResource, PropertyMetaWithSemantic } from 'genesis-suite/types/networkTypes'
import { RawDataField } from 'genesis-suite/types/visualTypes'
import { architectureService } from '../lib/services'
import { applicationSelectors } from '../selectors'
import { useSemanticTypeById } from './useSemanticTypes'

const swrOptions: SWRConfiguration = {
    revalidateOnFocus: false,
    errorRetryCount: 2,
    dedupingInterval: 3600000,
}

export default function useProperties(): PropertyMetaWithSemantic[] {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const semanticTypeById = useSemanticTypeById()

    const { data } = useSWR(
        ['architectureService.getProperties', appName],
        ([_, appName]) => architectureService.getProperties(appName),
        swrOptions
    )

    return useMemo(() => {
        if (!semanticTypeById || !data) return
        return data.map(({ semanticId, ...p }) => ({ ...p, semanticType: semanticTypeById?.[semanticId] }))
    }, [data, semanticTypeById])
}

export function useProperty(resourceName: string, name: string) {
    const properties = useProperties()
    return getPropertyMeta(properties, { resourceName, name })
}

export function getPropertyMeta(properties: PropertyMetaWithSemantic[], field: RawDataField) {
    return properties?.find(p => p.container.name === field.resourceName && p.name === field.name)
}

export function useResourcesWithLocationData(): BareResource[] {
    const properties = useProperties()

    const locationProperties = properties?.filter(p => {
        switch (p.semanticType?.name) {
            case 'LocationId':
            case 'Latitude':
            case 'Longitude':
                return true
            default:
                return false
        }
    })

    const resources = uniqBy(
        locationProperties?.map(p => ({ ...p.container, displayName: p.displayName })),
        'id'
    )

    return resources.filter(r => {
        const properties = locationProperties.filter(p => p.container.name === r.name)
        if (!properties.find(p => p.semanticType.name === 'Latitude')) return false
        if (!properties.find(p => p.semanticType.name === 'Longitude')) return false
        return true
    })
}
