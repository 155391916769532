import store from '../store/store'

export default coord => {
    if (!coord) return
    let perspectiveId

    const CONTAINER_TYPE_ID = 13
    const widgets = store.getState().widgets.configs

    const filtered = Object.values(widgets).filter(w => w.Type == CONTAINER_TYPE_ID || w.Type.toString().toLowerCase().trim() === 'container')
    for (let i = 0; i < filtered.length; i++) {
        const widget = filtered[i]

        if (coord.DefaultPerspective) {
            if (widget.Id === coord.DefaultPerspective) {
                perspectiveId = widget.Id
                break
            }
        } else {
            if (!widget.ContainerConfig.IsDefault) continue

            if (!coord.Name || widget.Element.trim().toLowerCase() !== coord.Name.toLowerCase().trim()) continue

            perspectiveId = widget.Id
            if (widget.IsGlobal) continue
            break
        }
    }
    return perspectiveId
}
