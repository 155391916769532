import { useContext } from 'react'
import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { SeriesConfig } from 'genesis-suite/types/visualTypes'
import { DataFields, CalculatedPropertyEditor, EditWidget, ConfigContext } from './edit_widget'
import BasicFields from './edit_widget/config_fields/BasicFields'
import FieldEditor from './edit_widget/config_fields/FieldEditor'
import EditWidgetHeader from './EditWidgetHeader'
import validateConfig from './widgets2/utils/validateConfig'
import AdvancedFilterEditorPanel from './edit_widget/AdvancedFilterEditorPanel'
import { TableStyleChangesProvider } from './widgets2/contexts/TableStyleChangesContext'

const columnWidth = 236

const useStyles = makeStyles(({ palette, spacing }) => {
    const baseColumn = {
        overflow: 'hidden',
        display: 'flex',
        flexFlow: 'column nowrap',
        paddingTop: spacing(),
    }

    return {
        controller: {
            position: 'relative',
            display: 'grid',
            gridTemplateColumns: `${columnWidth}px ${columnWidth}px ${columnWidth}px`,
        },
        dataColumn: { ...baseColumn, backgroundColor: palette.background.sideNav, zIndex: 2 },
        column: { ...baseColumn, paddingLeft: spacing(2) },
        widget: {
            width: '100%',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '8px',
            padding: spacing(),
        },
    }
})

interface Props {
    isNew: boolean
    onDone: (config?: SeriesConfig) => Promise<void>
}

export default function EditSeriesWidget({ isNew, onDone }: Props) {
    const classes = useStyles()
    const { config, isDirty, onReset } = useContext(ConfigContext)

    const isValid = validateConfig(config)

    return (
        <Box flex={1} display="flex" flexDirection="column" overflow="hidden">
            <EditWidgetHeader
                title="Edit Data Widget"
                isNew={isNew}
                isDirty={isDirty}
                isValid={isValid}
                onDone={() => onDone(isDirty ? config : undefined)}
                onReset={onReset}
            />

            <TableStyleChangesProvider>
                <Box flex={1} display="flex" overflow="hidden">
                    <div className={classes.controller}>
                        <DataFields className={classes.dataColumn} />
                        <BasicFields className={classes.column} />
                        <FieldEditor className={classes.column} />
                        <AdvancedFilterEditorPanel />
                        <CalculatedPropertyEditor />
                    </div>

                    <EditWidget className={classes.widget} />
                </Box>
            </TableStyleChangesProvider>
        </Box>
    )
}
