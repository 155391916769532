import { Criteria } from 'genesis-suite/types/networkTypes'
import { makeFilterReadable } from 'genesis-suite/utils'

export const getCriteriaSentence = (criteria: Criteria, index?: number) => {
    const { criteriaFilter, scenarioPropertyName, scenarioValue } = criteria
    const filterString = makeFilterReadable(criteriaFilter)

    return (
        <>
            {index != undefined ? `${index + 1}.` : ''}
            {index > 0 ? 'when ' : 'When '}{filterString}{', '}
            <b>
                <i>{scenarioPropertyName}</i>
            </b>{' '}
            will be{' '}
            <b>
                <i>{scenarioValue}</i>
            </b>
            .
        </>
    )
}
