import React from 'react'
import { connect } from 'react-redux'
import withStyles from '@mui/styles/withStyles';
import RemoveButton from './RemoveButton'
import { widgetSelectors } from '../../../selectors'
import { Typography } from '@mui/material';

const styles = theme => ({
    boldText: {
        fontWeight: 'bold',
    },
    removed: {
        fontStyle: 'italic',
        opacity: '.45',
    },
})

/**
 * Displays and handles removal of default appModule
 * @param {Object} preference - Object containing the ID of the perspective
 * @param {function} getTitle - Retrieves the title of the perspective based on the Id
 * @param {boolean} removed - Boolean representing if this item was removed
 * @param {function} onChange - Callback function when RemoveButton is clicked
 */
const AutoLaunchPref = ({ classes, className, getTitle, preference, removed, onChange }) => (
    <div className={className}>
        <div>
            <Typography display="inline" variant="subtitle1" className={classes.boldText}>
                {`Auto Launch: `}{' '}
            </Typography>
            {preference && preference.Value ? (
                <Typography display="inline" className={removed ? classes.removed : ''}>
                    {' '}
                    {getTitle(preference.Value.Id)}
                </Typography>
            ) : (
                <Typography display="inline">N/A</Typography>
            )}
        </div>
        {preference && <RemoveButton removed={removed} onClick={onChange} />}
    </div>
)

const mapStateToProps = state => ({
    getTitle: id => widgetSelectors.getWidgetTitle(state, id),
})

export default connect(mapStateToProps)(withStyles(styles)(AutoLaunchPref))
