import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { NodeLocationsMapWidget } from 'genesis-suite/types/visualTypes'
import { forwardRef } from 'react'
import NodeLocationsMap from '../widgets2/nodeLocations/NodeLocationsMap'

const useLabelStyles = makeStyles(({ palette, spacing }) => ({
    widget: {
        height: '100%',
        flex: 'none',
        borderRadius: '8px',
        display: 'flex',
        gridGap: spacing(),
        alignItems: 'flex-end',
    },
    nodeLocationsMapWidget: {
        backgroundColor: palette.background.main,
        border: `1px solid ${palette.border?.main}`,
        borderRadius: '15px',
        overflow: 'hidden',
        padding: 0,
        MinHeight: '500px',
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
    },
}))

export default forwardRef<HTMLDivElement, { config: NodeLocationsMapWidget }>(({ config }, ref) => {
    const classes = useLabelStyles()

    return (
        <div ref={ref} className={classes.widget}>
            <Box
                flex={1}
                height="100%"
                overflow="hidden"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
            >
                <NodeLocationsMap className={classes.nodeLocationsMapWidget} />
            </Box>
        </div>
    )
})
