import { ComparisonOperatorAgGrid } from './types'

export const makeOperator = (o: string): string => {
    switch (o) {
        case ComparisonOperatorAgGrid.CONTAINS:
            return 'Contains'
        case ComparisonOperatorAgGrid.ENDS_WITH:
            return 'EndsWith'
        case ComparisonOperatorAgGrid.EQUAL:
            return 'EqualTo'
        case ComparisonOperatorAgGrid.GREATER_THAN:
            return 'GreaterThan'
        case ComparisonOperatorAgGrid.GREATER_THAN_OR_EQUAL:
            return 'GreaterThanorEqualTo'
        case ComparisonOperatorAgGrid.LESS_THAN:
            return 'LessThan'
        case ComparisonOperatorAgGrid.LESS_THAN_OR_EQUAL:
            return 'LessThanorEqualTo'
        case ComparisonOperatorAgGrid.NOT_CONTAINS:
            return 'NotContains'
        case ComparisonOperatorAgGrid.NOT_EQUAL:
            return 'NotEqualTo'
        case ComparisonOperatorAgGrid.STARTS_WITH:
            return 'StartsWith'
        case ComparisonOperatorAgGrid.IN_RANGE:
            return 'EqualTo'
    }
}
