import { uniq } from 'lodash'
import moment from 'moment'

import { letMeMap } from 'genesis-suite/types/utilTypes'
import {
    ChartType,
    DataGroup,
    DataResponse,
    OneSeries,
    SeriesConfig,
    SeriesResponse,
    SortProps,
    TableConfig,
    Widget,
} from 'genesis-suite/types/visualTypes'
import { ParsedResponse, YAxisMinMax } from '../../../types/WidgetTypes'

export default function parseResponse(data: DataResponse, config: Widget): ParsedResponse {
    if (!data || !config || !('series' in config)) return
    if (noParse(config)) return { data }

    const { categories = [], series, isSorted } = config

    const groupNamesByCategory = letMeMap(categories).map((_, index) => getCategoryGroupNames(data, index))
    const seriesSortIndex = getSeriesSortIndex(config.series)

    const sortedGroupNamesByCategory =
        isSorted ?? false
            ? groupNamesByCategory
            : groupNamesByCategory.map((names, index) => {
                  if (index === categories.length - 1 && seriesSortIndex > -1) {
                      return sortGroupNamesBySeries(names, data[seriesSortIndex], series[seriesSortIndex], index)
                  } else {
                      return sortGroupNames(names, categories[index])
                  }
              })

    const groupNamesBySeries = letMeMap(series).map((s, i) => {
        if (!s.subSeries) return null
        return getCategoryGroupNames([data[i]], categories.length)
    })

    const groupNames = {
        byCategory: sortedGroupNamesByCategory,
        bySeries: groupNamesBySeries,
    }

    const yAxisMinMax = getYAxisMinMax(data, config)

    return { data, groupNames, yAxisMinMax }
}

function noParse(config: Widget) {
    if (config.type === ChartType.FORM) return true
    if (config.type === ChartType.MAP) return true
    if (config.type === ChartType.TABLE) {
        const tableConfig = config as TableConfig
        return tableConfig.dynamic || tableConfig.collapsable
    }
    return false
}

function getCategoryGroupNames(data: DataResponse, depth: number) {
    let groupNames: string[] = []

    function appendGroupNames(groups: DataGroup[], depth: number, currentDepth = 0) {
        if (currentDepth === depth) groupNames = [...groupNames, ...groups.map(g => g.group)]
        else
            groups.forEach(g => {
                appendGroupNames(g.children, depth, currentDepth + 1)
            })
    }

    data.forEach(series => {
        appendGroupNames(series.data, depth)
    })

    return uniq(groupNames)
}

/** Return series and value index and the sort direction if the last category should be sorted by series values */
function getSeriesSortIndex(series: OneSeries[]): number {
    return series.findIndex(s => {
        return s.values.some(v => v.sort)
    })
}

/** Sort group names based on criteria */
function sortGroupNames(names: string[], sortProps: SortProps) {
    const { sortType, sort = 'ascending', dateFormat } = sortProps

    return names
        .map((n, i) => ({ value: sortType === 'date' ? moment(n, dateFormat).format('YYYYMMDD') : n, origVal: n, index: i }))
        .sort((a, b) => {
            if (sortType === 'number') {
                const aNumber = Number(a.value)
                const bNumber = Number(b.value)
                return sort === 'ascending' ? aNumber - bNumber : bNumber - aNumber
            } else {
                return sort === 'ascending' ? a.value.localeCompare(b.value) : b.value.localeCompare(a.value)
            }
        })
        .map(s => s.origVal)
}

/** Sort group names based on series values */
function sortGroupNamesBySeries(names: string[], series: SeriesResponse, seriesConfig: OneSeries, depth: number) {
    const { subSeries, values } = seriesConfig
    const valueIndex = values.findIndex(v => v.sort)
    const sort = seriesConfig.values[valueIndex].sort

    const valueByName = names.reduce((acc, name) => ({ ...acc, [name]: 0 }), {} as { [name: string]: number })

    function tabulate(groups: DataGroup[], currentDepth = 0) {
        groups.forEach(group => {
            if (currentDepth === depth) {
                valueByName[group.group] += totalGroupAggregatedData(group, valueIndex, subSeries ? 1 : 0)
            } else {
                tabulate(group.children, currentDepth + 1)
            }
        })
    }

    tabulate(series.data)

    return Object.entries(valueByName)
        .sort((a, b) => (sort === 'ascending' ? a[1] - b[1] : b[1] - a[1]))
        .map(entry => entry[0])
}

/** Recursively get the total aggregated value of a data group */
function totalGroupAggregatedData(group: DataGroup, valueIndex: number, totalDepth: number, currentDepth = 0) {
    if (currentDepth === totalDepth) return group.aggregatedData?.[valueIndex] ?? 0
    return group.children.reduce(
        (acc, cur) => acc + totalGroupAggregatedData(cur, valueIndex, totalDepth, currentDepth + 1),
        0
    )
}

function getYAxisMinMax(data: DataResponse, config: SeriesConfig): YAxisMinMax {
    const { categories, series } = config
    if (!categories?.length) return

    const depth = categories.length - 1

    function tabulate(groups: DataGroup[], series: OneSeries, currentDepth = 0): YAxisMinMax {
        if (!series) return

        const { subSeries } = series

        return groups.reduce(
            (acc, group) => {
                if (currentDepth === depth) {
                    const value = totalGroupAggregatedData(group, 0, subSeries ? 1 : 0)
                    return {
                        max: acc.max != null ? Math.max(acc.max, value) : value,
                        min: acc.min != null ? Math.min(acc.min, value) : value,
                    }
                } else {
                    if (!series) return acc

                    const { min, max } = tabulate(group.children, series, currentDepth + 1)
                    return {
                        max: acc.max != null ? Math.max(acc.max, max) : max,
                        min: acc.min != null ? Math.min(acc.min, min) : min,
                    }
                }
            },
            { min: null, max: null } as YAxisMinMax
        )
    }

    const { min, max } = data.reduce(
        (acc, cur, index) => {
            if (!series[index]) return acc

            const { min, max } = tabulate(cur.data, series[index])
            return {
                max: acc.max != null ? Math.max(acc.max, max) : max,
                min: acc.min != null ? Math.min(acc.min, min) : min,
            }
        },
        { min: null, max: null } as YAxisMinMax
    )

    return Number.isNaN(min) || Number.isNaN(max) ? undefined : { min, max }
}
