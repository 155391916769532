import { LaunchRounded } from '@mui/icons-material'
import { Button, IconButton, Tooltip, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classnames from 'classnames'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { MapPlaceholder, TablePlaceholder } from 'genesis-suite/icons'
import { authCreators } from '../../actions/creators'
import FavoriteController from '../../components/FavoriteController.jsx'
import Widget from '../../components/widgets/Widget.jsx'
import { logEvent } from '../../lib/amplitudeClient.js'
import { ColOptionsProvider } from '../../components/contexts/ColumnOptionsContext'

const initDelay = 5
const useStyles = makeStyles(({ palette, spacing }) => ({
    item: {
        opacity: 1,
        height: 200,
        display: 'flex',
        borderRadius: 8,
        flexFlow: 'column',
        overflowY: 'hidden',
        border: `2px solid ${palette.grayscale.lighter}`,
    },
    undo: {
        opacity: 0,
        alignItems: 'center',
        justifyContent: 'center',
        transition: `opacity ${initDelay}s linear`
    },
    itemTitle: {
        display: 'flex',
        alignItems: 'center',
        padding: spacing(0, 1),
        margin: spacing(0.25, 0),
        justifyContent: 'space-between',
    },
    titleText: {
        flex: 1,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    smallBtn: {
        padding: spacing(0.5),
    },
    placeholder: {
        height: '100%',
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'center',
        background: `linear-gradient(#FFF, ${palette.grayscale.lightest} 90%)`,
        '& > svg': { fontSize: '10rem' },
    },
}))

const favoriteConfig = {
    legend: {
        enabled: false,
    },
    xAxis: {
        title: {
            text: null,
            enabled: false,
        },
    },
    yAxis: {
        title: {
            text: null,
            enabled: false,
        },
        labels: {
            enabled: false,
        },
    },
    series: {
        dataLabels: {
            enabled: false,
        },
    },
}

export default function FavoriteWidget({ config, onLaunch }) {
    const classes = useStyles()
    const [timeoutID, setTimeoutID] = useState(null)
    const { enqueueSnackbar: showSnackbar } = useSnackbar()

    const dispatch = useDispatch()
    const unfavorite = () => {
        logEvent('UNFAVORITE_WIDGET')
        dispatch(authCreators.saveFavorite('widgetFavorites', config.Id)).catch(() =>
            showSnackbar('An error occurred saving your favorites.', { variant: 'error' })
        )
    }


    const TableWidgetPlaceholder = () => (
        <div className={classes.placeholder} title="Launch Slideshow" onClick={onLaunch}>
            <TablePlaceholder />
        </div>
    )
    const MapWidgetPlaceholder = () => (
        <div className={classes.placeholder} title="Launch Slideshow" onClick={onLaunch}>
            <MapPlaceholder />
        </div>
    )

    const getFavoriteContent = config => {
        switch (config.Type.toUpperCase()) {
            case 'TABLE':
                return <TableWidgetPlaceholder />
            case 'MAP':
                return <MapWidgetPlaceholder />
            default:
                return <Widget interactions={false} config={config} customConfig={favoriteConfig} />
        }
    }

    const handleUnfavorite = () => setTimeoutID(setTimeout(unfavorite, initDelay * 1000))
    const handleUndo = () => {
        logEvent('UNDO_UNFAVORITE_WIDGET')
        clearTimeout(timeoutID)
        setTimeoutID(null)
    }

    if (timeoutID)
        return (
            <div className={classnames(classes.item, classes.undo)}>
                <Typography display="inline">Favorite removed. </Typography>
                <Button onClick={handleUndo}>Undo?</Button>
            </div>
        )

    return <>
        <ColOptionsProvider id={config.Id}>
            <div className={classes.item}>{getFavoriteContent(config)}</div>
            <div className={classes.itemTitle}>
                <Typography variant="subtitle1" display="inline" title={config.Title} className={classes.titleText}>
                    {config.Title}
                </Typography>
                <FavoriteController prefKey="widgetFavorites" value={config.Id} onClick={handleUnfavorite} />
                <Tooltip title="Launch Slideshow">
                    <IconButton className={classes.smallBtn} onClick={onLaunch} size="large">
                        <LaunchRounded fontSize="small" />
                    </IconButton>
                </Tooltip>
            </div>
        </ColOptionsProvider>
    </>;
}
