import { useContext, useState } from 'react'
import { Collapse, Box, Button, IconButton, ListItem, Typography } from '@mui/material'
import EmptyIcon from '@mui/icons-material/ImageOutlined'
import makeStyles from '@mui/styles/makeStyles'
import NewIcon from '@mui/icons-material/AddRounded'

import { MenuIcon, CognitiveIcon, CognitiveIconPicker } from 'genesis-suite/components'
import { DebouncedTextField } from 'genesis-suite/components'
import { DragList, DragItem } from 'genesis-suite/components'
import { Trash, Menu } from 'genesis-suite/icons'
import { ChartType } from 'genesis-suite/types/visualTypes'
import { Stage } from 'genesis-suite/types/visualTypes/widgetTypes/SeriesType'
import { ConfigContext } from '../ConfigContext'
import EditorWrapper from './EditorWrapper'
import AsyncResourceValueSelect from '../../AsyncResourceValueSelect'
import BorderedSection from '../../BorderedSection'

const useStyles = makeStyles(({ palette, spacing }) => ({
    listItem: { padding: spacing(0.5, 1), gridGap: spacing(), backgroundColor: '#fff' },
    buttonSelected: {
        backgroundColor: `${palette.secondary.light} !important`,
        color: palette.primary.contrastText,
    },
    dragIcon: { fontSize: '18px', color: 'inherit', cursor: 'grab' },
    deleteIcon: { fontSize: '18px', color: 'inherit' },
    borderedSection: {
        maxHeight: 200,
        display: 'flex',
        padding: spacing(0.5),
        flexFlow: 'column nowrap',
        border: `2px solid ${palette.grayscale.light}`,
    },
}))

export default function StatusTrackerStageEditor() {
    const { config, dispatch } = useContext(ConfigContext)
    const [openIconPicker, setOpenIconPicker] = useState(false)
    const [selectedStageIndex, setSelectedStageIndex] = useState<number>(null)
    const classes = useStyles()

    if (config.type !== ChartType.STATUS_TRACKER) return null
    const series = config.series[0]
    const stages = series.customStages ? [...series.customStages] : null
    const selectedStage = selectedStageIndex != null && stages?.[selectedStageIndex]
    const icon = selectedStage?.icon

    return (
        <EditorWrapper header="Custom Stages">
            <Collapse in={true}>
                <ListEditor
                    classes={classes}
                    dispatch={dispatch}
                    stages={stages}
                    setSelectedStageIndex={setSelectedStageIndex}
                    selectedStageIndex={selectedStageIndex}
                />
                <Box mt={1} />
                <Collapse in={Boolean(selectedStage)}>
                    <SelectedEditor
                        dispatch={dispatch}
                        selectedStageIndex={selectedStageIndex}
                        stages={stages}
                        selectedStage={selectedStage}
                        series={series}
                        openIconPicker={openIconPicker}
                        setOpenIconPicker={setOpenIconPicker}
                        icon={icon}
                        classes={classes}
                    />
                </Collapse>
            </Collapse>
        </EditorWrapper>
    )
}

function ListEditor({ classes, dispatch, stages, setSelectedStageIndex, selectedStageIndex }) {
    const handleDrag = v => handleStageDrag(v.map(({ id, ...c }) => c))

    if (!stages?.length) return <NewButton onClick={handleAdd} />

    function handleStageDrag(newStages) {
        newStages.map((n, i) => (n.order = i + 1))
        dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: { customStages: newStages } })
    }

    function handleAdd() {
        const newRule: Stage = {
            title: null,
            name: null,
            order: stages ? stages.length + 1 : 1,
            icon: null,
        }

        dispatch({
            type: 'UPDATE_ACTIVE_SERIES',
            payload: { customStages: [...(stages ?? []), newRule] },
        })

        setSelectedStageIndex(stages?.length ?? 0)
    }

    function handleDelete(index) {
        dispatch({
            type: 'UPDATE_ACTIVE_SERIES',
            payload: {
                customStages: stages.filter((c, i) => i !== index).map((s, p) => ({ ...s, order: p + 1 })),
            },
        })
        setSelectedStageIndex(null)
    }

    return (
        <BorderedSection header="Stages">
            <ListItem
                button
                classes={{ root: classes.listItem, selected: classes.buttonSelected }}
                selected={selectedStageIndex == null}
                onClick={() => setSelectedStageIndex(null)}
            ></ListItem>

            <DragList onDragEnd={handleDrag} items={stages.map((c, i) => ({ ...c, id: i }))}>
                {stages.map((stage, index) => (
                    <DragItem key={index} itemId={index} index={index}>
                        <ListItem
                            button
                            classes={{ root: classes.listItem, selected: classes.buttonSelected }}
                            selected={selectedStageIndex === index}
                            onClick={() => setSelectedStageIndex(index)}
                        >
                            <Box flex="1" display="flex" alignItems="center" gap="8px">
                                <Menu className={classes.dragIcon} />
                                <Typography color="inherit">{stage?.title ?? 'new stage'}</Typography>
                            </Box>
                            <IconButton color="inherit" size="small" onClick={() => handleDelete(index)}>
                                <Trash className={classes.deleteIcon} />
                            </IconButton>
                        </ListItem>
                    </DragItem>
                ))}
            </DragList>

            <Box mt={1} />

            <NewButton onClick={handleAdd} />
        </BorderedSection>
    )
}

function SelectedEditor({
    dispatch,
    stages,
    selectedStageIndex,
    selectedStage,
    series,
    openIconPicker,
    setOpenIconPicker,
    icon,
    classes,
}) {
    function handleUpdate(update: Partial<Stage>) {
        dispatch({
            type: 'UPDATE_ACTIVE_SERIES',
            payload: {
                customStages: stages.map((c, i) => (i === selectedStageIndex ? { ...c, ...update } : c)),
            },
        })
    }

    return (
        <BorderedSection header="">
            <DebouncedTextField
                fullWidth
                value={selectedStage?.title ?? ''}
                label="Title"
                placeholder="Enter stage title"
                onChange={title => handleUpdate({ title })}
            />
            <AsyncResourceValueSelect
                label="Stage"
                sx={{ mt: 1 }}
                textFieldProps={{ autoFocus: true, variant: 'standard' }}
                requestStart="focus"
                resourceName={series.values[0].field.resourceName}
                keyPropertyName={series.values[0].field.name}
                onChange={(e, values) => handleUpdate({ name: typeof values === 'string' ? values : values?.value })}
                multiple={false}
                disableCloseOnSelect={false}
                inputValue={(selectedStage?.name as any) ?? ''}
            />
            <Box display="flex" alignItems="center" gap={1} mb={1}>
                <Typography variant="h6">Icon:</Typography>
                <MenuIcon
                    open={openIconPicker}
                    buttonProps={{ size: 'small' }}
                    popoverProps={{
                        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                        transformOrigin: { vertical: 'top', horizontal: 'left' },
                    }}
                    icon={icon ? <CognitiveIcon fontSize="large" icon={icon} /> : <EmptyIcon fontSize="large" />}
                    onClick={() => setOpenIconPicker(true)}
                    onClose={() => setOpenIconPicker(false)}
                >
                    <CognitiveIconPicker
                        onSelect={icon => {
                            setOpenIconPicker(false)
                            handleUpdate({ icon })
                        }}
                    />
                </MenuIcon>
            </Box>
        </BorderedSection>
    )
}

function NewButton(props) {
    return (
        <Button
            size="small"
            variant="outlined"
            startIcon={<NewIcon fontSize="small" style={{ color: 'inherit' }} />}
            {...props}
        >
            Stage
        </Button>
    )
}
