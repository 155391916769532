import { useContext } from 'react'
import { PublishRounded } from '@mui/icons-material'

import { MenuIcon } from 'genesis-suite/components'
import { DraftStatus } from 'genesis-suite/types/visualTypes'
import { DashboardContext } from '../DashboardContext'

export default function ApprovalSubmitButton() {
    const { config, updateConfig } = useContext(DashboardContext)

    if (!config?.draft) return null

    return (
        <MenuIcon
            buttonProps={{ sx: { color: 'inherit' } }}
            title="Submit for approval"
            icon={<PublishRounded />}
            onClick={() => updateConfig({ draft: { ...config.draft, status: DraftStatus.PENDING } })}
        />
    )
}
