import { ResourceType } from 'genesis-suite/types/networkTypes'
import {
    Axis, Category, ChartType, ColumnSeries, ComboConfig, ComboSeries, DataSource, LineSeries
} from 'genesis-suite/types/visualTypes'
import { makeField, stringToFilters } from 'genesis-suite/utils'
import { WidgetConverterProps } from '../migrationTypes'
import {
    convertBaseFormat, convertLegend, convertTooltip, getPropertyFromResource, makeAggregation, makeNavigation
} from '../widgetConverterUtils'

export default async function comboConverter({
    config,
    dashboardIdByOldId,
    resourceManager,
}: WidgetConverterProps): Promise<ComboConfig> {
    const { Title, ChartConfig, NoDataMessage } = config
    const {
        Source,
        Series,
        XAxisField,
        LabelField,
        CrumbMetaName,
        DefaultPerspective,
        SecondaryAxisLabel,
        GridLines,
        HideXAxis,
        HideYAxis,
        SortAxis,
        InverseSort,
        DisplayCount,
        LabelName,
        ValueName,
        YAxisNames,
        IsTrellis,
        TrellisField,
        GridColumns,
        GridRows,
    } = ChartConfig

    const invertAxis = ChartConfig.ChartType === 'Bar'
    const baseInsight = await resourceManager.getInsight(Source.ElementName)
    const categories: Category[] = []

    if (IsTrellis) {
        const trellisCategory: Category = {
            field: makeField(baseInsight.properties, TrellisField),
            navigation: {
                enabled: false,
            },
        }
        categories.push(trellisCategory)
    }

    const category: Category = {
        field: makeField(baseInsight.properties, invertAxis ? LabelField : XAxisField),
        navigation: makeNavigation(CrumbMetaName, DefaultPerspective, dashboardIdByOldId),
        ...(SortAxis && { sort: InverseSort ? 'descending' : 'ascending' }),
    }

    categories.push(category)

    let hasSecondaryAxis = false
    let primaryTickAmount: number
    let secondaryTickAmount: number

    const stackByName: { [name: string]: number } = {}
    function getStack(name) {
        if (!name) return

        if (!Object.prototype.hasOwnProperty.call(stackByName, name)) stackByName[name] = Object.keys(stackByName).length + 1
        return stackByName[name]
    }

    const series = await (async () => {
        return Promise.all<ColumnSeries | LineSeries>(
            Series.filter(s => !s.IsTrendLine).map(async s => {
                const {
                    Source,
                    SeriesType,
                    AggregationType,
                    FieldName,
                    SeriesName,
                    PlotSecondaryAxis,
                    StackOnField,
                    GroupOnField,
                    TickCount,
                    IgnoreBlanks,
                    SecondaryAxisTickCount,
                    ShowLabels,
                    SmoothCurves,
                    BaseFormat,
                    Color,
                } = s

                const insight = await resourceManager.getInsight(Source.ElementName)

                if (PlotSecondaryAxis) hasSecondaryAxis = true
                if (TickCount) primaryTickAmount = TickCount
                if (SecondaryAxisTickCount) secondaryTickAmount = SecondaryAxisTickCount

                const value: DataSource = {
                    field: makeField(insight.properties, FieldName),
                    aggregation: makeAggregation(getPropertyFromResource(insight, FieldName), AggregationType),
                }

                const hasTrendLine = Series.some(s => s.IsTrendLine && s.FieldName === FieldName)
                const subSeriesField = StackOnField || GroupOnField
                const subSeries = subSeriesField ? { field: makeField(insight.properties, subSeriesField) } : undefined

                const baseSeries: ComboSeries = {
                    title: SeriesName,
                    service: { type: ResourceType.INSIGHT, name: insight.name, id: insight.id },
                    values: [value],
                    stack: getStack(StackOnField),
                    yAxis: PlotSecondaryAxis ? 1 : 0,
                    ...(DisplayCount && { limit: DisplayCount }),
                    tooltip: convertTooltip(insight, s),
                    ...(ShowLabels && { dataLabels: { value: true, format: { base: convertBaseFormat(BaseFormat) } } }),
                    ...(hasTrendLine && { trendLines: [{ show: true }] }),
                    filters: stringToFilters(insight.properties, Source.Filters),
                    ignoreBlanks: IgnoreBlanks,
                    subSeries,
                    colors: { FieldName: Color },
                }

                const singleSeries: ColumnSeries | LineSeries =
                    SeriesType === 'Column' || SeriesType === 'Bar'
                        ? { ...baseSeries, type: 'column' }
                        : {
                              ...baseSeries,
                              type: 'line',
                              shape: SeriesType === 'Area' ? 'area' : SmoothCurves ? 'spline' : 'line',
                          }

                return singleSeries
            })
        )
    })()

    const parsedGridColumns = Number.parseInt(GridColumns, 10)
    const parsedGridRows = Number.parseInt(GridRows, 10)
    const gridColumns = !parsedGridColumns ? undefined : parsedGridColumns
    const gridRows = !parsedGridRows ? undefined : parsedGridRows

    const trellis: ComboConfig['trellis'] = {
        enabled: Boolean(IsTrellis),
        layout: { type: 'grid', gridColumns, gridRows },
    }

    const xAxis: Axis = {
        title: { text: invertAxis ? YAxisNames[0] : LabelName },
        label: { enabled: !HideXAxis },
        gridLines: GridLines === 'Both' || GridLines === 'XAxis',
    }

    const yAxis: Axis[] = [
        {
            title: { text: invertAxis ? LabelName : ValueName },
            label: { enabled: !HideYAxis },
            tickAmount: primaryTickAmount,
            gridLines: GridLines === 'Both' || GridLines === 'YAxis',
        },
    ]

    if (hasSecondaryAxis)
        yAxis.push({ opposite: true, title: { text: SecondaryAxisLabel }, tickAmount: secondaryTickAmount })

    const builderConfig: ComboConfig = {
        version: '2',
        type: ChartType.COMBO,
        title: Title,
        noDataMessage: NoDataMessage,
        categories,
        series,
        xAxis,
        yAxis,
        invertAxis,
        filters: stringToFilters(baseInsight.properties, Source.Filters),
        legend: convertLegend(ChartConfig),
        trellis,
    }

    return builderConfig
}
