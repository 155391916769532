import { createSelector } from 'reselect'
import Store from '~/store/storeTypes'

const getActiveScenarios = (store: Store) => store.scenarios.scenario || []

const getActiveScenarioIds = createSelector([getActiveScenarios], scenarios => {
    return scenarios?.map(scenario => scenario.id) || []
})

const getToApproveByCurrentUser = (store: Store) => store.scenarios.assignedScenarios || []

const getIsRequestedByCurrentUser = (store: Store) => store.scenarios.createdScenarios || []

export const scenarioSelectors = {
    getActiveScenarios,
    getActiveScenarioIds,
    getToApproveByCurrentUser,
    getIsRequestedByCurrentUser,
}
