import React from 'react'
import makeStyles from '@mui/styles/makeStyles'

interface ProgressBarProps {
    minValue?: number
    maxValue?: number
    value?: number
    sx?: any
    config?: any
}

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        zIndex: 1,
        padding: '0px',
    },
    bar: {
        height: '100%',
        backgroundColor: '#eeeeee',
        transition: 'width 0.5s ease-in-out',
    },
}))

const ProgressBar: React.FC<ProgressBarProps> = ({ minValue = 0, maxValue = 100, value = 0, sx }) => {
    const classes = useStyles()
    const percentValue = calculatePercentage(minValue, maxValue, value)
    let barStyle: React.CSSProperties = {
        width: `${percentValue}%`,
    }

    if (sx !== null) {
        barStyle = {
            ...barStyle,
            ...sx,
        }
    }

    return (
        <div className={classes.root}>
            <div className={classes.bar} style={barStyle}></div>
        </div>
    )
}

export function calculatePercentage(minValue: number, maxValue: number, value: number): number {
    if (value <= minValue) {
        return 0
    }
    if (value >= maxValue) {
        return 100
    }
    const percentage = ((value - minValue) / (maxValue - minValue)) * 100
    return percentage
}

export default ProgressBar
