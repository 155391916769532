export const defaultLayout = perspectives => {
    return perspectives?.map((item, index) => {
        let obj = {
            i: item.Id,
            x: 0,
            y: index,
            w: 1,
            h: 1,
        }
        return obj
    })
}

export const customLayout = perspectives => {
    return perspectives?.map((item, index) => {
        let obj = {
            i: item.Id,
            x: 0,
            y: item.Order,
            w: 1,
            h: 1,
        }
        return obj
    })
}
