import React from 'react'
import { Typography } from '@mui/material'
import { Lock } from 'genesis-suite/icons'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
    container: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'none',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        position: 'relative'
    },
    lockIcon: {
        fontSize: 50
    }
})

const WidgetUnauthorized = () => {
    const classes = useStyles()

    return (
        <div className={classes.container}>
            <Lock className={classes.lockIcon} />
            <Typography >You do not have permission to view this data.</Typography>
        </div>
    )
}

export default WidgetUnauthorized