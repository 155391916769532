import { ReactElement } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Box, Dialog, DialogTitle, Typography, DialogContent, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { getFileType } from './utils'
import zIndex from '@mui/material/styles/zIndex'

export interface IFrameViewerProps {
    isMobile: boolean
    openDialog: boolean
    onClickDialog: () => void
    data: any
}

const useStyles = makeStyles(theme => ({
    image: {
        height: '100%',
        width: '100%',
    },
    pdf: {
        width: '100%',
        height: '100%',
    },
}))

export const IFrameViewer = ({ data, isMobile, openDialog, onClickDialog }: IFrameViewerProps): ReactElement => {
    const classes = useStyles()
    const { file, docs } = data

    const TabContent = () => {
        const fileType = getFileType(file?.MimeType)
        switch (fileType) {
            case 'image':
                return imageTab()
            case 'pdf':
                return pdfTab()
            default:
                return (
                    <>
                        <Typography variant="h6">{file?.MimeType} not supported</Typography>
                    </>
                )
        }
    }

    const imageTab = () => {
        return <img src={docs[0].uri} className={classes.image} />
    }
    const pdfTab = () => {
        return (
            <>
                <Box height="400px">
                    <iframe src={docs[0].uri} className={classes.pdf} />
                </Box>
            </>
        )
    }

    return (
        <>
            <Dialog
                open={openDialog}
                onClose={() => onClickDialog()}
                fullScreen={isMobile}
                sx={{ zIndex: '3000' }}
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogTitle>
                    <Typography variant="h5">{docs?.[0].fileName}</Typography>
                    <Box
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 10,
                        }}
                    >
                        <IconButton
                            onClick={() => onClickDialog()}
                            sx={{
                                color: theme => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <TabContent />
                </DialogContent>
            </Dialog>
        </>
    )
}
