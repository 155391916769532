export const filterTypes = {
    PERSPECTIVE_FILTER_CHANGE: 'PERSPECTIVE_FILTER_CHANGE',
    COMPARE_PERSPECTIVE_FILTER_CHANGE: 'COMPARE_PERSPECTIVE_FILTER_CHANGE',
    SEARCH_FILTER_CHANGE: 'SEARCH_FILTER_CHANGE',
    COMPARE_SEARCH_FILTER_CHANGE: 'COMPARE_SEARCH_FILTER_CHANGE',
    PERSPECTIVE_DEFAULT_FILTER_CHANGE: 'PERSPECTIVE_DEFAULT_FILTER_CHANGE',
    GLOBAL_FILTER_CHANGE: 'GLOBAL_FILTER_CHANGE',
    SET_INLINE_FILTERS: 'SET_INLINE_FILTERS',
    SET_BUILDER_FILTERS: 'SET_BUILDER_FILTERS',
    SET_DELETED_FILTERS: 'SET_DELETED_FILTERS',
    SET_CONTEXT: 'SET_CONTEXT',
    SET_COMPARE_CONTEXT: 'SET_COMPARE_CONTEXT',
    ADD_CONTEXT_FILTER: 'ADD_CONTEXT_FILTER',
    DELETE_CONTEXT_FILTER: 'DELETE_CONTEXT_FILTER',
    RESET_DELETED_PERSPECTIVE_FILTERS: 'RESET_DELETED_PERSPECTIVE_FILTERS',
    RESET_DELETED_COMPARE_PERSPECTIVE_FILTERS: 'RESET_DELETED_COMPARE_PERSPECTIVE_FILTERS',
    RESET_INLINE_FILTERS: 'RESET_INLINE_FILTERS',
    RESET_INLINE_COMPARE_FILTERS: 'RESET_INLINE_COMPARE_FILTERS',
    SET_CURRENT_WIDGET_ID: 'SET_CURRENT_WIDGET_ID',
}
