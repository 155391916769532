import { Base, NumberFormat } from 'genesis-suite/types/visualTypes'

export default function formatNumber(value: number, format: NumberFormat): string {
    if (value == null || Number.isNaN(value)) return null
    if (!format) return value.toString()

    const { decimalPlaces, isPercentage, removeCommas, base, prefix, suffix } = format

    let v = Number(value)
    if (Number.isNaN(v)) return value.toString()
    let suf: string = suffix || ''

    switch (base) {
        case Base.K:
            v /= 1000
            suf = suf ? `K ${suf}` : 'K'
            break
        case Base.M:
            v /= 1000000
            suf = suf ? `M ${suf}` : 'M'
            break
        case Base.B:
            v /= 1000000000
            suf = suf ? `B ${suf}` : 'B'
            break
        case Base.DYNAMIC:
            if ((v >= 1000 && v < 1000000) || (v > -1000000 && v <= -1000)) {
                v /= 1000
                suf = suf ? `K ${suf}` : 'K'
            } else if ((v >= 1000000 && v < 1000000000) || (v > -1000000000 && v <= -1000000)) {
                v /= 1000000
                suf = suf ? `M ${suf}` : 'M'
            } else if (v >= 1000000000 || v <= -1000000000) {
                v /= 1000000000
                suf = suf ? `M ${suf}` : 'B'
            }
            break
    }

    if (isPercentage) {
        v *= 100
        suf = suf ? `% ${suf}` : '%'
    }

    const decimals = decimalPlaces >= 0 ? decimalPlaces : 2
    const valueString = toFixedString(v, decimals, removeCommas)
    return `${prefix || ''}${valueString}${suf}`
}

function toFixedString(value: number, decimals: number, removeCommas?: boolean) {
    let numberStr = parseFloat(value.toFixed(decimals)).toLocaleString()
    const parts = numberStr.split('.')

    if (parts.length === 1 && decimals > 0) {
        numberStr = numberStr + '.' + '0'.repeat(decimals)
    } else if (parts[1]?.length < decimals) {
        const numZeros = decimals - parts[1].length
        numberStr = parts[0] + '.' + parts[1] + '0'.repeat(numZeros)
    }

    return removeCommas ? numberStr.replaceAll(',', '') : numberStr
}

export function roundValue(value) {
    return Math.round(value * 100) / 100
}

export function formatAndReturnRawValue(value: number, format?: NumberFormat) {
    if (value == null || Number.isNaN(value)) return null
    if (!format) return value.toString()
    const { decimalPlaces, isPercentage, removeCommas } = format;

    let formattedValue = Number(value);
    if (Number.isNaN(formattedValue)) return value.toString(); 

    if (isPercentage) {
        formattedValue *= 100;
    }

    let result = formattedValue.toFixed(decimalPlaces >= 0 ? decimalPlaces : 2);
    result = parseFloat(result).toString(); // This removes trailing zeros.
   
    if (removeCommas) {
        result = result.replace(/,/g, '');
    }
    return result;
}
