import { AddRounded, FilterAltRounded } from '@mui/icons-material'
import { Box, IconButton, MenuItem, Popover, Skeleton, TextField, Tooltip, Typography } from '@mui/material'
import { AdvancedFilterEditor } from 'genesis-suite/components'
import { Trash } from 'genesis-suite/icons'
import { Criteria, CriteriaOptionType, Property, Scenario } from 'genesis-suite/types/networkTypes'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { v4 as uuid } from 'uuid'
import useResourceMeta from '../../hooks/useResourceMeta'
import { modelService } from '../../lib/services'
import { applicationSelectors } from '../../selectors'
import { makeStyles } from '@mui/styles'

const _emptyCriteria: Omit<Criteria, 'id'> = {
    optionType: CriteriaOptionType.Equals,
    scenarioValue: '',
    criteriaFilter: null,
    scenarioProperty: '',
    scenarioPropertyName: '',
}

interface CriteriaEditorProps {
    scenario: Scenario
    onEdit: (criterias: Criteria[]) => void
    onAdd: (criteria: Criteria) => void
    onDelete: (criteria: Criteria) => void
}

const useStyles = makeStyles(theme => ({
    iconStyle: {
        color: theme.palette.text.primary,
    },
    labelStyle: {
        '& label': {
            color: theme.palette.text.primary,
        },
        '& label.Mui-focused': {
            color: theme.palette.text.primary,
        },
    },
}))

export default function CriteriaEditor({ scenario, onEdit, onAdd, onDelete }: CriteriaEditorProps) {
    const { criterias, resourceName, resourceType } = scenario
    const [resourceMeta] = useResourceMeta(resourceType, resourceName)
    const classes = useStyles()
    const properties = useMemo(() => {
        if (!resourceMeta) return []
        else return resourceMeta.properties.filter(p => p.container.type === resourceType || p.isPrimary)
    }, [resourceMeta])

    const handleAddCriteria = () => {
        onAdd({ ..._emptyCriteria, id: uuid() })
    }

    const handleEdit = (criteria: Criteria) => {
        onEdit(criterias.map(c => (c.id === criteria.id ? criteria : c)))
    }

    return (
        <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', borderBottom: 2, borderColor: 'border.main', my: 2 }}>
                <Typography variant="h6" display="inline" fontWeight={700}>
                    Criterias
                </Typography>
                <Tooltip title="Add New Condition">
                    <IconButton
                        sx={{ mx: 1 }}
                        disabled={!resourceMeta}
                        className={classes.iconStyle}
                        onClick={handleAddCriteria}
                    >
                        <AddRounded />
                    </IconButton>
                </Tooltip>
            </Box>
            <Box sx={{ overflow: 'auto' }}>
                {!resourceName ? (
                    <Typography
                        sx={{
                            color: 'text.primary',
                        }}
                        variant="body2"
                        color="text.secondary"
                    >
                        Select a resource to add conditions
                    </Typography>
                ) : !resourceMeta ? (
                    <Skeleton variant="rounded" height={200} />
                ) : (
                    <>
                        {criterias.map(criteria => (
                            <CriteriaRow
                                key={criteria.id}
                                criteria={criteria}
                                onEdit={handleEdit}
                                onDelete={onDelete}
                                resourceName={resourceName}
                                properties={properties}
                            />
                        ))}
                    </>
                )}
            </Box>
        </Box>
    )
}

interface CriteriaRowProps {
    criteria: Criteria
    onEdit: (criteria: Criteria) => void
    onDelete: (criteria: Criteria) => void
    properties: Property[]
    resourceName: string
}

function CriteriaRow({ criteria, onEdit, onDelete, properties, resourceName }: CriteriaRowProps) {
    const { scenarioPropertyName, optionType, scenarioValue } = criteria
    const [anchorEl, setAnchorEl] = useState(null)
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const classes = useStyles()

    const handleCriteriaChange = e => {
        const key = e.target.name
        const value = e.target.value
        onEdit({
            ...criteria,
            [key]: value,
        })
    }

    const handlePropertyChange = e => {
        const scenarioPropertyName = e.target.value
        const scenarioProperty = properties.find(p => p.name === scenarioPropertyName).id
        onEdit({
            ...criteria,
            scenarioPropertyName,
            scenarioProperty,
        })
    }

    const handleFilterEdit = filter => {
        onEdit({ ...criteria, criteriaFilter: filter })
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <Box sx={{ p: 1, display: 'flex', gap: 1, alignItems: 'center', border: 1, borderColor: 'border.main' }}>
                <Popover anchorEl={anchorEl} open={Boolean(anchorEl)}>
                    <AdvancedFilterEditor
                        appName={appName}
                        value={criteria.criteriaFilter}
                        getResourceValues={modelService.getResourceValues}
                        onCancel={() => setAnchorEl(null)}
                        onChange={handleFilterEdit}
                        properties={properties}
                        resourceName={resourceName}
                    />
                </Popover>
                <TextField
                    sx={theme => ({
                        flex: 1,
                        '& .MuiInput-underline::before': {
                            borderBottomColor: theme.palette.text.primary,
                        },
                    })}
                    select
                    label="Property"
                    value={scenarioPropertyName ?? ''}
                    onChange={handlePropertyChange}
                    SelectProps={{ classes: { icon: classes.iconStyle } }}
                    className={classes.labelStyle}
                >
                    {properties.map(p => (
                        <MenuItem key={p.id} value={p.name}>
                            {p.displayName}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    sx={theme => ({
                        flex: 1,
                        '& .MuiInput-underline::before': {
                            borderBottomColor: theme.palette.text.primary,
                        },
                    })}
                    select
                    label="Operation"
                    name="optionType"
                    value={optionType ?? ''}
                    onChange={handleCriteriaChange}
                    SelectProps={{ classes: { icon: classes.iconStyle } }}
                    className={classes.labelStyle}
                >
                    {Object.entries(CriteriaOptionType).map(([key, value]) => (
                        <MenuItem key={key} value={value}>
                            {key}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    sx={theme => ({
                        flex: 1,
                        '& .MuiInput-underline::before': {
                            borderBottomColor: theme.palette.text.primary,
                        },
                    })}
                    label="Value"
                    name="scenarioValue"
                    value={scenarioValue ?? ''}
                    onChange={handleCriteriaChange}
                    SelectProps={{ classes: { icon: classes.iconStyle } }}
                    className={classes.labelStyle}
                />
                <IconButton onClick={() => onDelete(criteria)} className={classes.iconStyle}>
                    <Trash />
                </IconButton>
            </Box>
            <Tooltip title="Filters">
                <IconButton sx={{ p: 0 }} onClick={e => setAnchorEl(e.currentTarget)} className={classes.iconStyle}>
                    <FilterAltRounded />
                </IconButton>
            </Tooltip>
        </Box>
    )
}
