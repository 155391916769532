import { useContext } from 'react'
import { Typography } from '@mui/material'

import { SeriesConfig, SortedSeries } from 'genesis-suite/types/visualTypes'
import LabeledToggleButton from '../../LabeledToggleButton'
import { ConfigContext } from '../../edit_widget'
import EditorWrapper from '../../edit_widget/config_fields/EditorWrapper'
import SeriesDataLabelsEditor from '../../edit_widget/config_fields/SeriesEditor/SeriesDataLabelsEditor'
import { typeOptions } from '../../edit_widget/config_fields/SeriesEditor/SeriesEditorFunnel'
import LegendEditor from '../../edit_widget/config_fields/LegendEditor'

export default function SortedFormatter() {
    const { dispatch, ...rest } = useContext(ConfigContext)
    const config = rest.config as SeriesConfig
    const seriesIndex = 0
    const activeSeries = config.series[seriesIndex] as SortedSeries
    const { type } = activeSeries || {}

    return (
        <div>
            <EditorWrapper header="Basic" startOpen>
                <Typography variant="caption">Type</Typography>
                <LabeledToggleButton
                    value={type || 'pyramid'}
                    label=""
                    onChange={(e, type) => dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: { type } })}
                    options={typeOptions}
                />
            </EditorWrapper>

            <EditorWrapper header="Data labels">
                <SeriesDataLabelsEditor />
            </EditorWrapper>

            <LegendEditor />
        </div>
    )
}
