import React, { useState } from 'react'
import {
    Modal,
    Box,
    Typography,
    CircularProgress,
    Alert,
    List,
    IconButton,
    Button,
    Tooltip,
    Tabs,
    Tab,
} from '@mui/material'
import CampaignIcon from '@mui/icons-material/Campaign'
import AnnouncementRender from 'genesis-suite/components/AnnouncementRender'
import AnnouncementTile from 'genesis-suite/components/AnnouncementTile'
import BackButton from './BackButton'
import { useSelector } from 'react-redux'
import { authSelectors, moduleSelectors } from '~/selectors'
import { Close, Refresh } from 'genesis-suite/icons'
import { SolutionAnnouncement, PlatformAnnouncement } from 'genesis-suite/types/announcementTypes'
import { useParams } from 'react-router-dom'
import { FileHandlerConfig } from 'genesis-suite/components/text-editor-v2/fileHandler'

interface AnnouncementsModalProps {
    open: boolean
    onClose: () => void
    activeAnnouncement: SolutionAnnouncement | PlatformAnnouncement | null
    onRefresh: () => void
    solutionAnnouncements: SolutionAnnouncement[]
    platformAnnouncements: PlatformAnnouncement[]
    isSolutionLoading: boolean
    isPlatformLoading: boolean
    isSolutionError: boolean
    isPlatformError: boolean
    onDetailsClick: (id: string) => void
    onPlatformDetailsClick: (id: string) => void
    handleBackClick: () => void
    getSrcUrlFromAttachmentToken: FileHandlerConfig['getSrcUrlFromToken']
    getAttachment: (fileToken: string) => Promise<any>
}

const AnnouncementsModal: React.FC<AnnouncementsModalProps> = ({
    open,
    onClose,
    activeAnnouncement,
    onRefresh,
    solutionAnnouncements,
    platformAnnouncements,
    isSolutionLoading,
    isPlatformLoading,
    isSolutionError,
    isPlatformError,
    onDetailsClick,
    onPlatformDetailsClick,
    handleBackClick,
    getSrcUrlFromAttachmentToken,
    getAttachment,
}) => {
    const accessKey = useSelector(authSelectors.getAccessKey) as string
    const [activeTab, setActiveTab] = useState<number>(0)

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue)
    }

    const moduleName = useSelector(moduleSelectors.getModuleTitle)
    const cloudName = useSelector(moduleSelectors.getModuleCloud)
    const { appName } = useParams()
    const moduleTitle = moduleName || cloudName || appName

    // Determine loading and error states based on active tab
    const isLoading = activeTab === 0 ? isSolutionLoading : isPlatformLoading
    const isError = activeTab === 0 ? isSolutionError : isPlatformError
    const noAnnouncements = activeTab === 0 ? solutionAnnouncements.length === 0 : platformAnnouncements.length === 0

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="announcements-modal-title"
            aria-describedby="announcements-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    maxWidth: 1400,
                    bgcolor: 'background.paper',
                    borderRadius: 2,
                    boxShadow: 24,
                    p: 4,
                    height: '90vh',
                    overflowY: 'auto',
                }}
            >
                {activeAnnouncement ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <BackButton onClick={handleBackClick} />
                            <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', ml: 1 }}>
                                Announcement Details
                            </Typography>
                        </Box>
                        <Tooltip title="Close">
                            <IconButton onClick={onClose} aria-label="Close">
                                <Close />
                            </IconButton>
                        </Tooltip>
                    </Box>
                ) : (
                    // Render the heading otherwise
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <CampaignIcon sx={{ fontSize: 28, mr: 1 }} />
                            <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold' }}>
                                Announcements
                            </Typography>
                        </Box>
                        <Box>
                            <Tooltip title="Refresh">
                                <IconButton onClick={onRefresh} aria-label="Refresh Announcements">
                                    <Refresh />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Close">
                                <IconButton onClick={onClose} aria-label="Close">
                                    <Close />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                )}

                {/* Tabs for Solution and Platform Announcements */}
                {!activeAnnouncement && (
                    <Tabs value={activeTab} onChange={handleTabChange} sx={{ mb: 2 }}>
                        <Tab label={moduleTitle} />
                        <Tab label="Platform" />
                    </Tabs>
                )}

                {/* Loading Indicator */}
                {isLoading && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
                        <CircularProgress />
                        <Typography variant="body2" sx={{ mt: 1 }}>
                            Loading {activeTab === 0 ? moduleTitle : 'Platform'} announcements...
                        </Typography>
                    </Box>
                )}

                {/* Error Message */}
                {isError && !isLoading && (
                    <Alert severity="error" sx={{ mt: 2 }}>
                        Failed to load {activeTab === 0 ? moduleTitle : 'Platform'} announcements. Please check your
                        internet connection or try again later.
                    </Alert>
                )}

                {/* Announcement Details */}
                {activeAnnouncement && (
                    <AnnouncementRender
                        announcement={activeAnnouncement}
                        getSrcUrlFromAttachmentToken={getSrcUrlFromAttachmentToken}
                        getAttachment={getAttachment}
                        userAccessKey={accessKey || null}
                    />
                )}

                {/* Announcements List */}
                {!isLoading && !isError && !activeAnnouncement && (
                    <>
                        {activeTab === 0 && solutionAnnouncements.length > 0 && (
                            <List sx={{ mt: 2 }}>
                                {solutionAnnouncements.map(announcement => (
                                    <AnnouncementTile
                                        key={announcement.id}
                                        announcement={announcement}
                                        onActionClick={() => onDetailsClick(announcement.id)}
                                        sx={{ mb: 2 }}
                                    />
                                ))}
                            </List>
                        )}
                        {activeTab === 1 && platformAnnouncements.length > 0 && (
                            <List sx={{ mt: 2 }}>
                                {platformAnnouncements.map(announcement => (
                                    <AnnouncementTile
                                        key={announcement.id}
                                        announcement={announcement}
                                        onActionClick={() => onPlatformDetailsClick(announcement.id)}
                                        sx={{ mb: 2 }}
                                    />
                                ))}
                            </List>
                        )}
                        {/* No Announcements Fallback */}
                        {noAnnouncements && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '60vh',
                                }}
                            >
                                <Typography variant="h6" sx={{ mt: 2 }}>
                                    No {activeTab === 0 ? moduleTitle : 'Platform'} announcements available.
                                </Typography>
                                <Button variant="contained" sx={{ mt: 2 }} onClick={onClose}>
                                    Close
                                </Button>
                            </Box>
                        )}
                    </>
                )}
            </Box>
        </Modal>
    )
}

export default AnnouncementsModal
