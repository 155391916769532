import produce from 'immer'
import { applicationTypes, businessExplorerTypes } from '../actions/types'
import { BusinessExplorerStore } from '../store/storeTypes'

const initialState: BusinessExplorerStore = {
    anchorId: null,
    configDictionary: {},
    selectedConfigId: null,
}

export default (state = initialState, action): BusinessExplorerStore => {
    const { type, payload } = action

    switch (type) {
        case businessExplorerTypes.CREATE_CONFIG: {
            const index = Object.keys(state.configDictionary).length
                ? Math.max(...Object.keys(state.configDictionary).map(i => parseInt(i))) + 1
                : 0
            return { ...state, configDictionary: { ...state.configDictionary, [index]: payload } }
        }
        case businessExplorerTypes.DELETE_CONFIG: {
            return {
                ...state,
                configDictionary: produce(state.configDictionary, draft => {
                    delete draft[payload]
                }),
                ...(state.selectedConfigId === payload && { selectedConfigId: null }),
            }
        }
        case businessExplorerTypes.DELETE_CONFIGS: {
            return {
                ...state,
                configDictionary: produce(state.configDictionary, draft => {
                    payload.forEach(id => {
                        delete draft[id]
                    })
                }),
                ...(payload.includes(state.selectedConfigId) && { selectedConfigId: null }),
            }
        }
        case businessExplorerTypes.RESET: {
            return { ...initialState, service: state.service }
        }
        case businessExplorerTypes.SET_ANCHOR_ID: {
            return { ...state, anchorId: payload }
        }
        case businessExplorerTypes.SET_SERVICE: {
            return { ...initialState, service: payload }
        }
        case businessExplorerTypes.SET_SELECTED_CONFIG: {
            return { ...state, selectedConfigId: payload }
        }
        case businessExplorerTypes.UPDATE_CONFIG: {
            return {
                ...state,
                configDictionary: produce(state.configDictionary, draft => {
                    draft[state.selectedConfigId] = { ...draft[state.selectedConfigId], ...payload }
                }),
            }
        }
        case applicationTypes.APPLICATION_SELECT:
            return initialState

        default:
            return state
    }
}
