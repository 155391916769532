import { ChartType } from 'genesis-suite/types/visualTypes'

export default function isSeriesWidget(type: ChartType) {
    switch (type) {
        case ChartType.FORM:
        case ChartType.TEXT:
        case ChartType.TREE:
        case ChartType.WEBVIEW:
        case ChartType.CALCULATOR:
        case ChartType.ACTIVITY_FEED:
        case ChartType.SPACIAL_MAP:
        case ChartType.MODULE_SELECTOR:
        case ChartType.SEARCH:
        case ChartType.FAVORITES:
            return false
        default:
            return true
    }
}
