import { Box, Typography } from '@mui/material'
import { memo, useContext } from 'react'

import { DefaultErrorBoundary } from 'genesis-suite/components'
import { Axis, ChartType } from 'genesis-suite/types/visualTypes'
import isSeriesWidget from '../../widgets2/utils/isSeriesWidget'
import { FieldPointer } from '../builderTypes'
import { ConfigContext } from '../ConfigContext'
import AxisEditor from './AxisEditor'
import CategoryEditor from './CategoryEditor'
import SeriesEditor from './SeriesEditor'

export default function FieldEditor({ className }) {
    const { selectedField, config } = useContext(ConfigContext)
    if (!isSeriesWidget(config.type)) return null

    return (
        <DefaultErrorBoundary>
            <FieldEditorRender className={className} selectedField={selectedField} />
        </DefaultErrorBoundary>
    )
}
interface RenderProps {
    className: string
    selectedField: FieldPointer
}

const FieldEditorRender = memo<RenderProps>(({ className, selectedField }) => {
    const { config, dispatch } = useContext(ConfigContext)

    let editor = selectedField?.type === 'category' ? <CategoryEditor /> : <SeriesEditor />
    let axisEditor = null

    switch (config.type) {
        case ChartType.BULLET: {
            const value = config.series[selectedField?.index]?.axis
            const handleChange = (update: Partial<Axis>) => {
                dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: { axis: { ...value, ...update } } })
            }
            editor = (
                <>
                    <CategoryEditor />
                    <SeriesEditor />
                </>
            )
            axisEditor = <AxisEditor value={value} title="Axis" hideTicks onChange={handleChange} />
            break
        }
        case ChartType.COMBO:
        case ChartType.BOX_PLOT:
        case ChartType.SCATTER: {
            if (selectedField?.type === 'category') {
                const handleChange = (update: Partial<Axis>) => {
                    dispatch({ type: 'UPDATE_CONFIG', payload: { xAxis: { ...config.xAxis, ...update } } })
                }
                axisEditor = <AxisEditor value={config.xAxis} title="xAxis" hideTicks onChange={handleChange} />
            } else {
                const yAxisIndex = config.series[selectedField?.index]?.yAxis ?? 0
                const handleChange = (update: Partial<Axis>) => {
                    if (update.opposite != null) {
                        const updatedAxisIndex = update.opposite ? 1 : 0
                        if (updatedAxisIndex !== yAxisIndex) {
                            dispatch({ type: 'FLIP_Y_AXIS', payload: update.opposite ? 1 : 0 })
                            return
                        }
                    }
                    const yAxis = config.yAxis.map((axis, index) =>
                        index === yAxisIndex ? { ...axis, ...update } : axis
                    )
                    dispatch({ type: 'UPDATE_CONFIG', payload: { yAxis } })
                }
                axisEditor = <AxisEditor value={config.yAxis[yAxisIndex]} title="yAxis" onChange={handleChange} />
            }
            break
        }
        case ChartType.TABLE: {
            editor = <SeriesEditor />
            break
        }
    }

    return (
        <div className={className}>
            <Typography gutterBottom variant="h6">
                Property config
            </Typography>
            <Box overflow="auto" flex="1">
                {selectedField ? (
                    <>
                        {editor}
                        {axisEditor}
                    </>
                ) : (
                    <Typography>Select property to edit</Typography>
                )}
            </Box>
        </div>
    )
})
