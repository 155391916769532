import { Box, Portal } from '@mui/material'
import { Widget } from 'genesis-suite/types/visualTypes'
import { sleep } from 'genesis-suite/utils'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { widgetCreators } from '../../actions/creators'
import { uploadService, visualService } from '../../lib/services'
import { applicationSelectors, widgetSelectors } from '../../selectors'
import { getExportType, getImageBlob } from '../widgets/lib/widgetExporting'
import Widget2 from './Widget2'
import { WidgetStatus } from '../../types/WidgetTypes'

export default function WidgetThumbnailMaker() {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const requests = useSelector(widgetSelectors.getThumbnailRequests)
    const dispatch = useDispatch()

    const currentId = requests[0]
    const [config, setConfig] = useState<Widget>(null)
    const widgetRef = useRef(null)

    useEffect(() => {
        if (!currentId) {
            if (config) setConfig(null)
            return
        }

        visualService.getWidgetById(appName, currentId).then(setConfig)
    }, [currentId])

    async function handleStatus(status: WidgetStatus) {
        if (status !== 'idle') return

        await sleep(1000) // wait for widget to fully render
        await updateWidgetThumbnail()
    }

    async function updateWidgetThumbnail() {
        try {
            const exportType = getExportType(config)
            const imageBlob = await getImageBlob(exportType, widgetRef)
            const thumbnail = await upload(imageBlob, `widgetThumb|${currentId}`, 'png')
            if (!thumbnail) return

            await visualService.updateWidget(appName, currentId, { thumbnail })
        } catch (err) {
            console.error(err)
        }

        dispatch(widgetCreators.removeThumbnailRequest(currentId))
    }

    async function upload(blob: Blob, name: string, fileExtension: string): Promise<string> {
        const file = new File([blob], `${name}.${fileExtension}`, { type: blob.type })
        const fileData = new FormData()
        fileData.append('file', file)
        return uploadService.uploadFile(fileData, name, appName, false)
    }

    if (!config) return null

    return (
        <Portal>
            <Box height="400px" width="400px">
                <Widget2 config={config} ref={widgetRef} onStatus={handleStatus} />
            </Box>
        </Portal>
    )
}
