import { useDispatch, useSelector } from 'react-redux'
import {
    Typography,
    Box,
    IconButton,
    List,
    ListItemButton,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { authSelectors } from '../selectors'
import { dialogCreators } from '../actions/creators'
import EditShortcuts, { editShortcutsDialogKey } from './EditShortcuts'
import { openInNewTab } from '../lib/utils'

export default function RightNavShortcuts({ Header }) {
    const shortcuts = useSelector(authSelectors.getShortcuts)
    const dispatch = useDispatch()
    const openEditShortcuts = () =>
        dispatch(dialogCreators.showDialog(editShortcutsDialogKey, EditShortcuts, { hideTitle: true }))

    return (
        <Box>
            <Header>
                <Typography variant="h6">My Shortcuts</Typography>
                <IconButton onClick={openEditShortcuts}>
                    <EditIcon fontSize="small" sx={{ color: 'text.primary' }} />
                </IconButton>
            </Header>

            {!shortcuts.length ? (
                <Typography sx={{ m: 1 }}>No shortcuts</Typography>
            ) : (
                <List>
                    {shortcuts.map(s => (
                        <ListItemButton key={s.id} onClick={() => openInNewTab(s.to)}>
                            {s.text}
                        </ListItemButton>
                    ))}
                </List>
            )}
        </Box>
    )
}
