import { FONT_SIZE, FontSize } from '~/constants'
import { logEvent } from '../../lib/amplitudeClient'
import { setPreference } from '../../lib/browserPreferences'
import { appearanceSelectors } from '../../selectors/appearance.selectors'
import { appearanceTypes } from '../types'

const openRightNav = tabValue => dispatch => {
    dispatch({ type: appearanceTypes.OPEN_RIGHT_NAV, payload: { tab: tabValue } })
}

const closeRightNav = () => (dispatch, getState) => {
    const layout = appearanceSelectors.getApplicationLayout(getState())
    dispatch({ type: appearanceTypes.CLOSE_RIGHT_NAV, payload: { layout } })
}

const setLayout = layout => dispatch => {
    setPreference('layout', layout)
    dispatch({ type: appearanceTypes.SET_LAYOUT, payload: { layout } })
}

const setFontSize = (fontSizeKey: keyof FontSize) => dispatch => {
    setPreference('fontSize', fontSizeKey)
    dispatch({ type: appearanceTypes.SET_FONT_SIZE, payload: { fontSize: FONT_SIZE[fontSizeKey] } })
    logEvent('SET_FONT_SIZE', { fontSizeKey })
}

const setTopNav = topNav => dispatch => {
    setPreference('topNav', topNav)
    dispatch({ type: appearanceTypes.SET_TOP_NAV, payload: { topNav } })
}

const toggleLoader = () => ({ type: appearanceTypes.TOGGLE_LOADER })

const toggleSettings = () => ({ type: appearanceTypes.TOGGLE_SETTINGS })

const toggleCookieConsent = () => ({ type: appearanceTypes.TOOGLE_COOKIE_CONSENT })

const toggleSideNav = () => ({ type: appearanceTypes.TOGGLE_SIDE_NAV })

const closeSideNav = () => ({ type: appearanceTypes.CLOSE_SIDE_NAV })

const toggleComparePerspectiveFilterPanel = () => ({ type: appearanceTypes.TOGGLE_COMPARE_PERSPECTIVE_FILTER_PANEL })

const togglePerspectiveFilterPopover = () => ({ type: appearanceTypes.TOGGLE_PERSPECTIVE_FILTER_POPOVER })

const togglePerspectiveCompareFilterPopover = () => ({
    type: appearanceTypes.TOGGLE_PERSPECTIVE_COMPARE_FILTER_POPOVER,
})

const toggleGlobalFilters = () => ({ type: appearanceTypes.TOGGLE_GLOBAL_FILTERS })

const toggleHelp = () => ({ type: appearanceTypes.TOGGLE_HELP })

const toggleAppContent = (hide: boolean) => ({ type: appearanceTypes.TOGGLE_APP_CONTENT, payload: { hide } })

const setShowNeoClassicLayout = showNeoClassicLayout => dispatch => {
    setPreference('showNeoClassicLayout', showNeoClassicLayout)
    dispatch({ type: appearanceTypes.SET_SHOW_NEO_CLASSIC_LAYOUT, payload: { showNeoClassicLayout } })
}

const toggleNeoClassicGlobalFilters = () => ({ type: appearanceTypes.TOGGLE_NEO_CLASSIC_GLOBAL_FILTERS })

const setShowApplicationSelector = showApplicationController => ({
    type: appearanceTypes.SET_SHOW_APPLICATION_CONTROLLER,
    payload: { showApplicationController },
})

const toggleBeta = betaTurnedOn => ({
    type: appearanceTypes.TOGGLE_BETA,
    payload: { betaTurnedOn },
})

export const appearanceCreators = {
    openRightNav,
    closeRightNav,
    setLayout,
    setFontSize,
    setTopNav,
    toggleLoader,
    toggleSettings,
    toggleSideNav,
    toggleComparePerspectiveFilterPanel,
    togglePerspectiveFilterPopover,
    togglePerspectiveCompareFilterPopover,
    toggleGlobalFilters,
    toggleHelp,
    closeSideNav,
    toggleCookieConsent,
    toggleAppContent,
    setShowNeoClassicLayout,
    toggleNeoClassicGlobalFilters,
    setShowApplicationSelector,
    toggleBeta,
}
