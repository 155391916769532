import moment from 'moment'
import { Tooltip, Typography, useTheme } from '@mui/material'
import { ComparisonOperator, NumberFormat, FormConditionalFormat, FormColumn } from 'genesis-suite/types/visualTypes'
import { getType } from '~/lib/formUtils'
import { ICellEditorParams, ICellRendererParams, RowNode } from '@ag-grid-community/core'
import AttachmentButton from '~/components/widgets2/form/AttachmentButton'
import formatNumber from '~/components/widgets2/utils/formatNumber'
import ReactLoading from 'react-loading'
import { getTransposeColumn } from '~/lib/formTableUtils'
import FormTableTextEditorCell from './FormTableTextEditorCell'

interface FormTableCellProps extends ICellRendererParams {
    conditionalFormatting: FormConditionalFormat[]
    colDef: any
    numberFormat: NumberFormat
    isEditing?: boolean
    isLoading?: boolean
    rowJustification?: any
    isTranspose?: boolean
    columns?: FormColumn[]
    params?: ICellEditorParams
}

export default function FormTableCell({
    data: row,
    value,
    conditionalFormatting,
    colDef,
    isEditing,
    isLoading,
    numberFormat,
    rowJustification,
    isTranspose,
    columns = [],
    params,
}: FormTableCellProps) {
    const {
        palette: { primary, getContrastText },
    } = useTheme()
    const _column = isTranspose ? getTransposeColumn(columns, row?.TransposeRow) : colDef
    const { dateFormat, displayProperty, property, editable, autogenerate, autogeneratePrefix, lastUpdateField } =
        _column
    const val = displayProperty ? row[displayProperty.name] : value
    const type = getType(property, dateFormat)
    const formattedVal = processValue(val, type, dateFormat, numberFormat)
    const { semanticType } = property
    const showTextEditor = type === 'string' && semanticType?.supportsMarkup

    const isAutoGenerated = autogenerate || autogeneratePrefix
    const isMultiPrimary = property?.isPrimary 
    const disabled = Boolean(!editable || isAutoGenerated || lastUpdateField) || isMultiPrimary

    return (
        <Tooltip title={formattedVal && type !== 'attachment' && !showTextEditor ? formattedVal : ''}>
            <Typography
                sx={{
                    p: '0 10px',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent:
                        rowJustification === 'center'
                            ? 'center'
                            : rowJustification === 'right'
                            ? 'flex-end'
                            : rowJustification === 'left'
                            ? 'flex-start'
                            : 'inherit',
                    '& .loading': { position: 'absolute' },
                    ...processFormatting(conditionalFormatting, row, getContrastText),
                    ...(isEditing && { opacity: 0.75, backgroundColor: '#b2ff59' }),
                    ...(isLoading && { opacity: 0.75, backgroundColor: 'grayscale.light' }),
                }}
            >
                {isLoading && <ReactLoading color={primary.main} type="bubbles" className="loading" />}
                {showTextEditor ? (
                    <FormTableTextEditorCell
                        formattedVal={formattedVal}
                        params={params}
                        displayProperty={displayProperty}
                        isEditing={isEditing}
                        disabled={disabled}
                    />
                ) : type === 'attachment' ? (
                    <AttachmentButton {...{ attachments: formattedVal }} />
                ) : (
                    formattedVal
                )}
            </Typography>
        </Tooltip>
    )
}

const processValue = (val, type, dateFormat, numberFormat) => {
    if (val && (type === 'date' || type === 'dateTime')) {
        const date = moment(val).format(dateFormat || undefined)
        if (date === 'Invalid date') return val
        else return date
    } else if (type === 'attachment') {
        return val?.split(',') ?? []
    } else if (type === 'number') return formatNumber(val, numberFormat)
    else if (type === 'string') return formatPercentageString(val, numberFormat)
    else return val
}

const processFormatting = (formats: FormConditionalFormat[], data: RowNode, getContrast) => {
    if (!formats?.length) return {}
    for (let i = 0; i < formats.length; i++) {
        const { property, format, rule, target } = formats[i]
        if (!property || !rule || !target) return {}
        const { backgroundColor, borderColor } = format

        const value = lowerIfString(data[property.name])
        const targetVal =
            typeof target === 'string' || typeof target === 'number'
                ? lowerIfString(target)
                : lowerIfString(data[target.name])

        if (evaluate(value, targetVal, rule)) {
            return { backgroundColor, color: getContrast(backgroundColor), borderColor }
        }
    }
}

const lowerIfString = value => {
    if (typeof value === 'string') return value.toLowerCase()
    else return value
}

const formatPercentageString = (val, numberFormat) => {
    if (!numberFormat) return val

    return (val = numberFormat?.isPercentage ? val + '%' : val)
}

const evaluate = (x, y, op: ComparisonOperator) => {
    switch (op) {
        case '=':
            return x == y
        case '<':
            return x < y
        case '>':
            return x > y
        case '<=':
            return x <= y
        case '>=':
            return x >= y
        default:
            return false
    }
}
