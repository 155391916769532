import { useState } from 'react'
import { Container, Box, Typography } from '@mui/material'
import { isEqual } from 'lodash'

import { DebouncedTextField } from 'genesis-suite/components'
import { ChartType, WebviewConfig } from 'genesis-suite/types/visualTypes'
import EditWidgetHeader from './EditWidgetHeader'
import WebviewWidget from './widgets2/WebviewWidget'

interface Props {
    /** (optional) provided if updating existing config */
    config?: WebviewConfig
    isNew: boolean
    onDone: (config?: WebviewConfig) => Promise<void>
}

export default function EditWebviewWidget({ config: initialConfig = emptyConfig, isNew, onDone }: Props) {
    const [config, setConfig] = useState(initialConfig)
    const isDirty = !isEqual(config, initialConfig)

    return (
        <Box flex={1} display="flex" flexDirection="column" overflow="hidden">
            <EditWidgetHeader
                title="Edit Webview Widget"
                isNew={isNew}
                isDirty={isDirty}
                onDone={() => onDone(isDirty ? config : undefined)}
                onReset={() => setConfig(initialConfig)}
            />

            <Container maxWidth="lg" sx={{ flex: 1 }}>
                <Box height="100%" py={2} display="flex" flexDirection="column" gap="8px">
                    <DebouncedTextField
                        sx={{ width: '400px' }}
                        InputProps={{ sx: { typography: 'h6' } }}
                        value={config?.title ?? ''}
                        onChange={title => setConfig(c => ({ ...c, title }))}
                        placeholder="Title"
                        autoFocus={!config?.title}
                    />

                    <Box display="flex" alignItems="center" gap="8px" my={2}>
                        <Typography variant="subtitle1">URL:</Typography>
                        <DebouncedTextField
                            fullWidth
                            debounceTime={500}
                            onFocus={e => e.target.select()}
                            value={config.url}
                            onChange={url => setConfig(c => ({ ...c, url }))}
                        />
                    </Box>

                    <Box flex={1} overflow="hidden">
                        <WebviewWidget config={config} />
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

const emptyConfig: WebviewConfig = { version: '2', title: '', type: ChartType.WEBVIEW, url: '' }
