import { useDispatch } from 'react-redux'

import TvIcon from 'genesis-suite/icons/TV'
import { MenuIcon } from 'genesis-suite/components'
import { widgetCreators } from '../../../../actions/creators'

export default function EditFiltersAction() {
    const dispatch = useDispatch()

    return (
        <MenuIcon
            title="TV mode"
            icon={<TvIcon />}
            buttonProps={{ sx: { color: 'inherit' } }}
            onClick={() => dispatch(widgetCreators.toggleTVMode())}
        />
    )
}
