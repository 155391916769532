import React, { HTMLAttributes } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Typography, Tooltip, Theme } from '@mui/material'

import { uppercaseFirstLetter } from 'genesis-suite/utils/stringUtils'

const useStyles = makeStyles<Theme, { status: Status; indicatorButtonColor: string }>(({ spacing, palette }) => ({
    tooltipText: { color: '#FFF' },
    previewContainer: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        border: `1px solid ${palette.grayscale.lighter}`,
        cursor: 'pointer',
        background: p =>
            p.status === 'active'
                ? palette.secondary.main
                : p.status === 'disabled'
                ? palette.grayscale.light
                : 'inherit',
        '&:hover': {
            backgroundColor: p => (p.status === 'active' ? palette.secondary.main : palette.grayscale.light),
        },
    },
    previewWrapper: {
        flex: '0 0 33.33%',
        padding: spacing(0.25),
    },
    indicator: {
        top: '4px',
        background: p => p.indicatorButtonColor,
        right: '4px',
        width: '8px',
        height: '8px',
        position: 'absolute',
        borderRadius: '4px',
    },
    previewIcon: {
        fontSize: 48,
        color: p => (p.status === 'active' ? '#fff' : 'inherit'),
    },
}))

type Status = 'default' | 'active' | 'disabled'

interface Props extends HTMLAttributes<HTMLDivElement> {
    /** (default 'default') determines the background color */
    status?: Status
    tipText: string
    Icon: (props: any) => JSX.Element
    indicatorButtonColor?: string
}

export default function BorderedIconButton({ status, tipText, Icon, indicatorButtonColor, ...props }: Props) {
    const classes = useStyles({ status, indicatorButtonColor })

    return (
        <Tooltip
            title={
                <Typography className={classes.tooltipText} variant="subtitle1">
                    {uppercaseFirstLetter(tipText)}
                </Typography>
            }
        >
            <div className={classes.previewWrapper}>
                <div className={classes.previewContainer} {...props}>
                    {indicatorButtonColor && <div className={classes.indicator} />}
                    <Icon className={classes.previewIcon} />
                </div>
            </div>
        </Tooltip>
    )
}
