import React, { useContext } from 'react'
import { Box, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import LineIcon from '@mui/icons-material/ShowChart'
import BarIcon from '@mui/icons-material/BarChartRounded'
import AreaIcon from '@mui/icons-material/NetworkCell'

import { DebouncedTextField } from 'genesis-suite/components'
import { ColumnSeries, ComboConfig, LineSeries } from 'genesis-suite/types/visualTypes'
import LabeledToggleButton from '../../../LabeledToggleButton'
import EditorWrapper from '../EditorWrapper'
import { ConfigContext } from '../../ConfigContext'
import ChartFormatEditor from '../FormatEditor/ChartFormatEditor'
import SeriesTooltipEditor from './SeriesTooltipEditor'
import SeriesDataLabelsEditor from './SeriesDataLabelsEditor'
import SortEditor from '../SortEditor'
import { getBoxPlotAggregations } from '../../utils'

const useStyles = makeStyles(({ spacing }) => ({
    group: { marginBottom: spacing() },
    stackingInput: { width: '85px' },
}))

type Shape = LineSeries['shape'] | ColumnSeries['type']

const comboOptions: Array<{ label: any; value: Shape; tip: string }> = [
    { label: <BarIcon />, value: 'column', tip: 'Bar' },
    { label: <LineIcon />, value: 'spline', tip: 'Smooth' },
    { label: <LineIcon />, value: 'line', tip: 'Straight' },
    { label: <AreaIcon />, value: 'area', tip: 'Area' },
]

export default function SeriesEditorBoxPlot() {
    const { selectedField, dispatch, ...rest } = useContext(ConfigContext)
    const config = rest.config as ComboConfig
    const classes = useStyles()

    const activeSeries = config.series[selectedField.index]
    const isBoxPlotSeries = activeSeries?.values.length === getBoxPlotAggregations().length

    let type: Shape = 'column'
    if (activeSeries?.type === 'line') type = activeSeries.shape || 'spline'

    function handleTypeChange(e, val: Shape) {
        const type = val === 'column' ? 'column' : 'line'
        updateActiveSeries({ type, ...(val !== 'column' && { shape: val }) })
    }

    function updateActiveSeries(payload: Partial<LineSeries | ColumnSeries>) {
        dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload })
    }

    return (
        <>
            {!isBoxPlotSeries && (
                <div className={classes.group}>
                    <Typography variant="caption">Type</Typography>
                    <LabeledToggleButton value={type} label="" onChange={handleTypeChange} options={comboOptions} />
                </div>
            )}

            <div className={classes.group}>
                <Typography variant="caption">Sorting</Typography>
                <SortEditor pointer={selectedField} />
            </div>

            {!activeSeries?.subSeries && (
                <Box py={1}>
                    <DebouncedTextField
                        variant="outlined"
                        size="small"
                        label="Legend title"
                        fullWidth
                        value={activeSeries?.title ?? activeSeries?.values?.[0].field.name ?? ''}
                        onChange={title => updateActiveSeries({ title })}
                    />
                </Box>
            )}

            {!isBoxPlotSeries && (
                <EditorWrapper header="Data labels">
                    <SeriesDataLabelsEditor />
                </EditorWrapper>
            )}

            <ChartFormatEditor />

            <EditorWrapper header="Tooltip">
                <SeriesTooltipEditor />
            </EditorWrapper>
        </>
    )
}
