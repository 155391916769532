import { useState } from 'react'
import { Box, Dialog, IconButton, MenuItem, TextField, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { VisualType } from 'genesis-suite/types/visualTypes'
import VisualHistory, { changesColumnWidth, HistoryCompareMode, Props as VisualHistoryProps } from './VisualHistory'

interface Props<T extends VisualType> extends VisualHistoryProps<T> {
    onDone: () => void
    open: boolean
}

export default function VisualHistoryDialog<T extends VisualType>({
    mode: parentMode = 'selected',
    onDone,
    open,
    ...rest
}: Props<T>) {
    const [mode, setMode] = useState<HistoryCompareMode>(parentMode)

    return (
        <Dialog maxWidth={false} open={open} onClose={onDone}>
            <Box sx={{ width: '90vw', height: '90vh', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
                <Box display="flex" alignItems="center" justifyContent="space-between" my={1}>
                    <Box width={changesColumnWidth} display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h5" sx={{ ml: 1 }}>
                            Version history
                        </Typography>
                        <TextField
                            onChange={e => setMode(e.target.value as HistoryCompareMode)}
                            select
                            size="small"
                            value={mode}
                        >
                            {modeOptions.map(o => (
                                <MenuItem dense key={o.value} value={o.value}>
                                    {o.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>

                    <IconButton onClick={onDone} sx={{ mr: 1 }}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                {rest.config && <VisualHistory mode={mode} {...rest} />}
            </Box>
        </Dialog>
    )
}

const modeOptions: Array<{ label: string; value: HistoryCompareMode }> = [
    { label: 'Selected only', value: 'selected' },
    { label: 'Compare with live', value: 'compare-live' },
    { label: 'Compare with previous', value: 'compare-previous' },
]
