import EditIcon from '@mui/icons-material/Edit'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { TextEditor } from 'genesis-suite/components'
import { useContext, useState } from 'react'
import { ConfigContext } from '../ConfigContext'

const useStyles = makeStyles({
    descriptionText: { fontWeight: 'bold' },
})

export default function DescriptionEditor() {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const { config, dispatch } = useContext(ConfigContext)

    function handleUpdate(description) {
        dispatch({ type: 'UPDATE_CONFIG', payload: { description } })
        setOpen(false)
    }

    return (
        <>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography className={classes.descriptionText}>Widget description</Typography>
                <IconButton onClick={() => setOpen(true)} size="large">
                    <EditIcon fontSize="small" />
                </IconButton>
            </Box>

            <DescriptionDialog open={open} value={config.description} onDone={handleUpdate} />
        </>
    )
}

function DescriptionDialog({ open, value: initValue, onDone }) {
    const [value, setValue] = useState(initValue)
    const handleDone = () => {
        onDone(value)
    }

    return (
        <Dialog open={open} onClose={handleDone}>
            <DialogTitle>Description Editor</DialogTitle>
            <DialogContent>
                <TextEditor value={value} onChange={setValue} minHeight={75} />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={handleDone}>
                    Done
                </Button>
            </DialogActions>
        </Dialog>
    )
}
