import MoreIcon from '@mui/icons-material/ArrowDropDownRounded'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandIcon from '@mui/icons-material/ExpandMore'
import { Skeleton } from '@mui/lab'
import { Box, Button, Divider, Popover, Tooltip, Typography } from '@mui/material'
import { isEqual, random } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import Measure from 'react-measure'
import { useDispatch, useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import CloudQueueRoundedIcon from '@mui/icons-material/CloudQueueRounded'
import { makeStyles, useTheme } from '@mui/styles'
import { ChopText, MenuIcon } from 'genesis-suite/components'
import ActiveIcon from 'genesis-suite/icons/Donut'
import { sleep } from 'genesis-suite/utils'
import useApplications from '~/hooks/useApplications'
import { appearanceCreators } from '../actions/creators'
import { moduleSelectors } from '../selectors'
import ModuleController from './ModuleController'

const useStyles = makeStyles(({ palette, spacing }) => ({
    expandMoreIcon: {
        position: 'absolute',
        right: '-10px',
    },
    letterBox: {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 30,
        height: 30,
        borderRadius: 5,
        backgroundColor: palette.tada.purple,
        color: 'white',
    },
}))

export default function NeoApplicationController() {
    const modulePending = useSelector(moduleSelectors.getModulesPending)
    const [applications, currAppName] = useApplications()
    const dispatch = useDispatch()

    const parentRef = useRef<HTMLDivElement>(null)
    const [hiddenCount, setHiddenCount] = useState(0)
    const [layingOut, setLayingOut] = useState(true)
    const [recompute, setRecompute] = useState(false)
    const [openHidden, setOpenHidden] = useState(false)

    const visibleItems = layingOut ? applications : applications.slice(0, applications.length - hiddenCount)
    const hiddenItems = applications.slice(-hiddenCount)
    const viewItemWidth = getViewItemWidth(visibleItems, 0.75)
    const theme = useTheme()

    const lastViews = useRef(null)
    useEffect(() => {
        if (isEqual(applications, lastViews.current)) return

        lastViews.current = applications
        resetLayout()
    }, [applications])

    const handleResize = useDebouncedCallback(resetLayout, 500)

    async function resetLayout() {
        console.log('calling resetLayout')
        setLayingOut(true)
        setOpenHidden(false)
        await sleep()
        setRecompute(s => !s)
    }

    useEffect(() => {
        setHiddenCount(getHiddenCount())
        setLayingOut(false)
    }, [recompute])

    const getHiddenCount = () => {
        const { clientWidth } = parentRef.current
        const totalWidth = clientWidth - 50
        return applications.length - Math.floor(totalWidth / (viewItemWidth + 70))
    }

    if (modulePending)
        return (
            <Box sx={{ display: 'flex', gap: 1, ml: 1 }}>
                {new Array(4).fill(0).map((_, i) => (
                    <Skeleton key={i} width={random(75, 150)} height="100%" />
                ))}
            </Box>
        )

    if (!applications.length)
        return <Typography sx={{ ml: 1, fontSize: '1rem', fontWeight: 700 }}>No Applications</Typography>

    return (
        <Measure client innerRef={parentRef} onResize={handleResize}>
            {({ measureRef }) => (
                <Box
                    ref={measureRef}
                    sx={{ display: 'flex', flex: 1, overflow: 'hidden', alignItems: 'center', height: '54px' }}
                >
                    {visibleItems.map((v, i) => (
                        <ViewItem
                            key={i}
                            index={i}
                            item={v.AppName}
                            hidden={layingOut}
                            top
                            textWidth={viewItemWidth}
                            currAppName={currAppName}
                        />
                    ))}

                    <Box sx={{ width: '48px' }}>
                        <MenuIcon
                            buttonProps={{
                                sx: { visibility: hiddenCount ? 'visible' : 'hidden', color: 'text.primary' },
                            }}
                            icon={
                                <MoreIcon
                                    fontSize="large"
                                    sx={{
                                        cursor: 'pointer',
                                        color: theme.palette.primary.contrastText,
                                        backgroundColor: theme.palette.primary.main,
                                        borderRadius: 35 / 2,
                                    }}
                                />
                            }
                            onClick={() => setOpenHidden(true)}
                            onClose={() => setOpenHidden(false)}
                            open={openHidden}
                            tooltip="More"
                            popoverProps={{
                                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                                transformOrigin: { vertical: 'top', horizontal: 'left' },
                                PaperProps: {
                                    sx: { maxHeight: '500px', overflowY: 'scroll', backgroundColor: '#777' },
                                },
                            }}
                        >
                            {hiddenItems.map((v, i) => (
                                <ViewItem
                                    key={i}
                                    index={i}
                                    item={v.AppName}
                                    overflow
                                    onDone={() => setOpenHidden(false)}
                                    currAppName={currAppName}
                                />
                            ))}
                        </MenuIcon>
                    </Box>
                    <Tooltip title="Close Application Selector">
                        <CancelOutlinedIcon
                            fontSize="small"
                            sx={{
                                cursor: 'pointer',
                                color: '#FFF',
                            }}
                            onClick={() => dispatch(appearanceCreators.setShowApplicationSelector(false))}
                        />
                    </Tooltip>
                </Box>
            )}
        </Measure>
    )
}

interface ViewItemProps {
    item: any
    index: number
    onDone?: (e?: any) => void
    hidden?: boolean
    overflow?: boolean
    top?: boolean
    textWidth?: number
    currAppName?: string
}

function ViewItem({
    item,
    index,
    onDone = undefined,
    hidden = false,
    overflow = false,
    top = false,
    textWidth,
    currAppName,
}: ViewItemProps) {
    const [first, ...second] = top ? item.split(' ') : [item]
    const secondText = second ? second.join(' ') : null
    const atRoute = item === currAppName
    const ref = useRef()
    const dispatch = useDispatch()
    const theme = useTheme()
    const classes = useStyles()
    const [expanded, setExpanded] = useState(0)

    const [openMenu, setOpenMenu] = useState(false)
    const isParent = true

    function handleClick(e) {
        if (isParent) {
            return setOpenMenu(true)
        }
    }

    function onClose() {
        setOpenMenu(false)
        dispatch(appearanceCreators.setShowApplicationSelector(false))
    }

    return (
        <>
            <Box
                sx={{
                    alignItems: top ? 'center' : 'stretch',
                    display: 'flex',
                    flexDirection: 'column',
                    visibility: hidden ? 'hidden' : 'visible',
                    color: '#FFF',
                    backgroundColor: '#777',
                    height: '100%',
                }}
            >
                <Button
                    color="inherit"
                    onClick={handleClick}
                    ref={ref}
                    sx={{
                        paddingLeft: top ? '0px' : '8px',
                        justifyContent: 'flex-start',
                        ':hover': { borderBottom: '3px solid gold' },
                        borderRadius: 0,
                        borderBottom: `3px solid #777`,
                        height: '100%',
                    }}
                >
                    {top && index > 0 && (
                        <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                            sx={{
                                borderColor: '#bfbfbf',
                                mt: 0,
                                mb: 0,
                                mr: '4px',
                                borderWidth: '1px',
                            }}
                        />
                    )}

                    {/* <span className={`${classes.letterBox}`}>{first[0]}</span> */}
                    <CloudQueueRoundedIcon
                        fontSize="medium"
                        sx={{
                            cursor: 'pointer',
                            color: '#FFF',
                            ml: 0.5,
                        }}
                    />
                    <ChopText
                        showEllipsis
                        lineCount={1}
                        sx={{
                            maxWidth: top ? textWidth + 'px' : '150px',
                            fontSize: top ? '0.75rem' : '0.7rem',
                            wordWrap: top ? 'break-word' : 'none',
                            textAlign: 'left',
                            fontFamily: 'segoe UI',
                            marginLeft: '4px',
                            width: top ? textWidth + 'px' : 'auto',
                        }}
                        tooltipProps={{ placement: 'bottom' }}
                    >
                        {first}
                        {secondText && <br />}
                        {secondText}
                    </ChopText>
                    {atRoute && !top && <ActiveIcon color="primary" />}
                    <ExpandMoreIcon
                        iconProps={{
                            sx: { visibility: isParent ? 'visible' : 'hidden' },
                            className: overflow ? classes.expandMoreIcon : '',
                        }}
                        overflow={overflow}
                        isParent={isParent}
                        openMenu={openMenu}
                    />
                </Button>

                {top && (
                    <Box
                        sx={{
                            backgroundColor: atRoute ? 'primary.main' : undefined,
                            borderRadius: '2px',
                            height: '3px',
                            width: '30px',
                        }}
                    />
                )}
            </Box>

            {isParent && (
                <Popover
                    anchorEl={ref.current}
                    anchorOrigin={{ vertical: overflow ? 'center' : 'bottom', horizontal: overflow ? 'left' : 'left' }}
                    onClose={() => setOpenMenu(false)}
                    open={openMenu}
                    transformOrigin={{ vertical: 'top', horizontal: overflow ? 'right' : 'left' }}
                >
                    <Box sx={{ maxHeight: '350px', maxWidth: '300px' }}>
                        <ModuleController
                            appName={item}
                            onClose={onClose}
                            currentApplication={currAppName}
                            onExpand={() => setExpanded(0)}
                            onModules={data => {
                                return null
                            }}
                        />
                    </Box>
                </Popover>
            )}
        </>
    )
}

function ExpandMoreIcon({ overflow, openMenu, isParent, iconProps }) {
    return (
        <>
            {openMenu ? (
                overflow ? (
                    <ChevronLeftIcon fontSize="small" {...iconProps} />
                ) : (
                    <ExpandLessIcon fontSize="small" {...iconProps} />
                )
            ) : overflow ? (
                <ChevronRightIcon fontSize="small" {...iconProps} />
            ) : (
                <ExpandIcon fontSize="small" {...iconProps} />
            )}
        </>
    )
}

function getViewItemWidth(items, fontSize) {
    let maxCharLength = 0
    items.forEach(item => {
        if (item.includes(' ')) {
            const [first, ...rest] = item.split(' ')
            if (first?.length > maxCharLength) maxCharLength = first.length
            if (rest.join(' ')?.length > maxCharLength) maxCharLength = rest.join(' ').length
        } else {
            maxCharLength = item.length > maxCharLength ? item.length : maxCharLength
        }
    })

    maxCharLength = maxCharLength > 14 ? 14 : maxCharLength
    return fontSize * ((maxCharLength + 0.5) / 2) * 16
}
