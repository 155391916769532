import { connect } from 'react-redux'
import { MenuIcon } from 'genesis-suite/components'
import { Network as NetworkIcon } from 'genesis-suite/icons'
import { routePaths } from '../lib/routes'
import { moduleSelectors } from '../selectors'
import { navigationCreators } from '../actions/creators'
import { useHotKey } from 'genesis-suite/hooks'
import { HotKeys, createTip } from '../lib/hotkeys'

const NetworkController = ({ show, goToNetwork, iconStyle, buttonStyle }) => {
    useHotKey(HotKeys.Network, goToNetwork)
    if (!show) return null
    return (
        <MenuIcon
            icon={<NetworkIcon sx={iconStyle} />}
            buttonProps={{ sx: buttonStyle }}
            onClick={goToNetwork}
            tooltip={`Business architecture ${createTip(HotKeys.Network)}`}
        />
    )
}

const mapStateToProps = state => ({
    show: moduleSelectors.shouldShowNetwork(state),
})

const mapDispatchToProps = dispatch => ({
    goToNetwork: () => dispatch(navigationCreators.goTo(routePaths.NETWORK)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NetworkController)
