import { Box, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useMemo, useState } from 'react'
import { Responsive as GridLayout } from 'react-grid-layout'
import Measure from 'react-measure'
import { useSelector } from 'react-redux'
import { authSelectors, perspectiveSelectors } from '~/selectors'
import ApplicationLogo from '../ApplicationLogo'
import { PerspectiveProvider } from '../contexts/PerspectiveContext'
import { addFixedPropsToLayouts, makeLayouts, topLabelFilter } from './Perspective'
import PerspectiveWidget from './PerspectiveWidget'
import TopLabels from './visuals/LabelWidget/TopLabels'

const smBreakpoint = 800
const minHeight = 2
const gridGap = 10
const rows = 18
const smCols = 4
const lgCols = 18
const mobileScale = 0.8

const useStyles = makeStyles(({ spacing, palette }) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    layout: {
        flex: 1,
        backgroundColor: palette.background.main,
    },
    wrapper: {
        margin: (p: any) => (p.containerWidth === '100%' ? undefined : `auto`),
        width: (p: any) => p.containerWidth,
        height: (p: any) =>
            p.containerWidth === '100%'
                ? '100%'
                : `calc(${100 / mobileScale}% - ${parseFloat(spacing(2)) / mobileScale}px)`,
        transformOrigin: 'top',
        transform: (p: any) => (p.containerWidth === '100%' ? 'inherit' : `scale(${mobileScale})`),
        border: (p: any) => (p.containerWidth === '100%' ? '' : '5px solid black'),
        borderRadius: (p: any) => (p.containerWidth === '100%' ? '' : '15px'),
        '& .react-grid-placeholder': { background: palette.primary.main },
        '& .react-resizable-handle.react-resizable-handle-se': {
            borderBottom: `4px solid ${palette.primary.main}`,
            borderRight: `4px solid ${palette.primary.main}`,
            width: '15px',
            height: '15px',
            backgroundImage: 'none',
            '&::after': { display: 'none' },
        },
    },
    widgetContainer: { '&:hover': { zIndex: (p: any) => (p.isEditing ? '' : 2) } }, // allow tooltip to cover other widgets
    forceScroll: { height: '200%' },
    // gridLayout: {
    //     '@media print': {
    //         reactGridItem: {
    //             position: 'unset !important',
    //             transform: 'unset !important',
    //             margin: '10px',
    //             display: 'inline-block',
    //             '& > div': {
    //                 pageBreakInside: 'avoid',
    //                 // breakInside: 'avoid',
    //             },
    //         },
    //     },
    // },
    topBar: {
        backgroundColor: palette.background.topBar,
        display: 'flex',
        alignItems: 'center',
        height: 70,
    },
    logo: { paddingLeft: spacing(1) },
    title: {
        flex: 1,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: 'white',
        textAlign: 'center',
    },
}))

export default function PerspectivePrintView({ configs, id, currentLayout, layouts, isEditing = false, ...rest }) {
    const isPublic = useSelector(authSelectors.getIsPublicLogin)
    const containerWidth = '100%'

    const classes = useStyles({ containerWidth, isEditing })

    const [width, setWidth] = useState(1000)
    const [height, setHeight] = useState(0)
    const widgetConfigs = configs.filter(c => !topLabelFilter(c))
    const topConfigs = configs.filter(topLabelFilter)
    const breakpoint = width < smBreakpoint ? 'sm' : 'lg'

    const isEditingPerspective = isEditing && widgetConfigs.length > 1

    const [_layouts, isOneRow] = makeLayouts(widgetConfigs, currentLayout, breakpoint, layouts, isEditingPerspective)

    const rowPxHeight =
        isOneRow && !isEditingPerspective
            ? height / minHeight - 1.5 * gridGap
            : Math.max(Math.floor(height / rows - gridGap), 36)

    const perspectiveLayoutProps = {
        containerWidth,
        id,
        // isEditing,
        // isEditingPerspective,
        // isTransitioning,
        _layouts,
        rowPxHeight,
        setHeight,
        setWidth,
        widgetConfigs,
        width,
        breakpoint,
        topConfigs,
        // handleLayoutChange,
        // minimizeWidgetCB,
        ...rest,
    }

    return (
        <PerspectiveProvider id={id} configs={configs}>
            <Box id="perspective-clone-container" className={classes.root}>
                <div className={classes.layout}>
                    <PerspectiveLayout {...perspectiveLayoutProps} />
                </div>
            </Box>
        </PerspectiveProvider>
    )
}

function PerspectiveLayout(props) {
    const baseStyles = { display: 'grid', height: '100%' }

    return (
        <Box id="perspective-clone-layout" sx={{ ...baseStyles, gridTemplateColumns: '1fr' }}>
            <PerspectiveContent {...props} />
        </Box>
    )
}

function PerspectiveContent({
    containerWidth,
    contentTitle,
    handleLayoutChange,
    id,
    _layouts,
    rowPxHeight,
    setHeight,
    setWidth,
    widgetConfigs,
    width,
    breakpoint,
    topConfigs,
    // handleLayoutChange,
    ...rest
}) {
    const classes = useStyles({ containerWidth })
    const title = useSelector(perspectiveSelectors.getTitle)

    /**
    const setComponentRef = useCallback((el, index) => {
        console.log('rkp el => ', el)
        if (el) {
            componentRefs.current[index] = el
        }
    }, [])
    console.log('rkp rowHeight => ', rowPxHeight)

    useEffect(() => {
        console.log('rkp inside useEffect => ', componentRefs.current)

        componentRefs.current.forEach((componentRef, index) => {
            if (componentRef) {
                console.log('rkp inside if')
                const componentHeight = componentRef.clientHeight
                const viewportHeight = window.innerHeight

                if (componentHeight > viewportHeight) {
                    // Update the layout for the specific component
                    const newLayout = _layouts.map((item, i) => {
                        if (i === index) {
                            return {
                                ...item,
                                h: Math.floor(viewportHeight / item.h), // Adjust the height based on the viewport
                            }
                        }
                        return item
                    })

                    handleLayoutChange(newLayout)
                }
            }
        })
    }, [_layouts, handleLayoutChange])

    console.log('rkp _layouts => ', _layouts)

    const updatedLayout = layout => {
        console.log('rkp prev layout => ', layout)
        // console.log('rkp current => ', current)
        const a4Height = 1403
        let diff = 0
        let gap = 0
        // const lg = layout

        // if (layout.length < 0) return layout
        const sortedLg = layout.sort((a, b) => a.y - b.y).filter(lg => lg.x === 0)

        let cummulativeLgH = 0
        let targetIndexLg = -1

        for (let i = 0; i < sortedLg.length; i++) {
            cummulativeLgH += sortedLg[i].h * rowPxHeight + gridGap * (i - 1)

            if (cummulativeLgH > a4Height) {
                targetIndexLg = i
                break
            }
        }

        if (targetIndexLg !== -1) {
            gap = targetIndexLg * gridGap
            console.log('rkp gap => ', gap)

            const breakingWidgetHeightInPx = sortedLg[targetIndexLg].h * rowPxHeight + gap

            const cummulativeLgBeforeTarget = cummulativeLgH - breakingWidgetHeightInPx

            console.log('rkp cummulativeLgBeforeTargetInPx => ', cummulativeLgBeforeTarget)

            diff = a4Height - cummulativeLgBeforeTarget

            const diffInRGL = Math.ceil((diff + gridGap) / (rowPxHeight + gridGap))

            console.log('rkp diff => ', diff)
            const prev = [...layout].filter(l => l.y === sortedLg[targetIndexLg].y - sortedLg[targetIndexLg - 1].h)

            prev?.map(l => (l.h += diffInRGL))

            console.log('rkp prevLayout after updated => ', prev)

            return layout
            // return updatedLayout(restArr)
        }
        return layout
    }

    const layouts = useMemo(() => {
        const sm = [..._layouts.sm]
        const lg = [..._layouts.lg]
        let smLayout
        let lgLayout

        const testArr = []

        console.log('rkp currentLayout => ', breakpoint)

        if (breakpoint === 'sm') {
            smLayout = updatedLayout(_layouts.sm)
        } else {
            lgLayout = updatedLayout(_layouts.lg)

            _layouts.lg.map(item1 => {
                lgLayout.map(item2 => {
                    if (item1.i === item2.i) {
                        const obj = { i: item1.i, x: item1.x, y: item1.y, h: item2.h, w: item1.w }

                        testArr.push(obj)
                    }
                })
            })

            console.log('rkp testArr => ', testArr)
        }

        const newLayout = breakpoint === 'sm' ? { sm: smLayout, lg: _layouts.lg } : { sm: _layouts.sm, lg: lgLayout }

        console.log('rkp newLayout => ', newLayout)

        return newLayout
    }, [])

    console.log('rkp _layouts => ', _layouts)
     */

    return (
        <Measure
            client
            onResize={({ client }) => {
                setWidth(client.width - 1) // prevent overlap w/ scrollbar
                setHeight(client.height)
            }}
        >
            {({ measureRef }) => (
                <div ref={measureRef} className={`widget-parent-wrapper-clone`}>
                    <div className={classes.topBar}>
                        <ApplicationLogo classes={{ image: classes.logo }} />
                        <Typography variant="h5" className={classes.title}>
                            {title}
                        </Typography>
                    </div>

                    <TopLabels configs={topConfigs} isCompare={false} isEdit={false} />
                    <GridLayout
                        key={'export'}
                        draggableCancel={'.noDrag'}
                        isDraggable={false}
                        isResizable={false}
                        width={width}
                        rowHeight={rowPxHeight}
                        margin={[gridGap, gridGap]}
                        autoSize={false}
                        verticalCompact={true}
                        preventCollision={false}
                        transformScale={containerWidth === '100%' ? 1 : mobileScale}
                        cols={{ sm: smCols, lg: lgCols }}
                        containerPadding={[0, 0]}
                        breakpoints={{ sm: 0, lg: smBreakpoint }}
                        layouts={addFixedPropsToLayouts(_layouts)}
                    >
                        {widgetConfigs.map((widgetConfig, index) => {
                            widgetConfig = { ...widgetConfig }
                            return (
                                <div key={widgetConfig.Id}>
                                    <PerspectiveWidget
                                        key={widgetConfig.Id}
                                        config={widgetConfig}
                                        perspectiveId={id}
                                        {...rest}
                                    />
                                </div>
                            )
                        })}
                    </GridLayout>
                </div>
            )}
        </Measure>
    )
}
