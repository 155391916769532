import { Box, InputBase, Typography } from '@mui/material'
import { useContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { CognitiveIcon } from 'genesis-suite/components'
import { NodeFilterWithValue, VisualType } from 'genesis-suite/types/visualTypes'
import { filterSelectors, menuSelectors } from '../../../selectors'
import DraftIndicator from '../../DraftIndicator'
import { DashboardContext } from './DashboardContext'

export default function DashboardTitle() {
    const { config, editable, editing, updateConfig } = useContext(DashboardContext)
    const { title, focalPoint } = config || {}

    const icon = useSelector(menuSelectors.getIcon)
    const appliedFilters = useSelector(filterSelectors.getBuilderFilters) as NodeFilterWithValue[]

    const [draft, setDraft] = useState('')

    const navigationFilters = appliedFilters.filter(f => f.source === 'navigation')
    const focalPointFilters = navigationFilters?.filter(f => f.source === 'navigation' && f.nodeName === focalPoint)
    const focalPointFilter = focalPointFilters?.[0]

    useEffect(() => {
        if (editing && title) setDraft(title)
    }, [editing, title])

    async function updateTitle(title) {
        updateConfig({ title })
    }

    const updateTitleTimeout = useRef<any>()
    function handleTitleChange(e) {
        const { value } = e.target
        setDraft(value)
        clearTimeout(updateTitleTimeout.current)
        updateTitleTimeout.current = setTimeout(() => updateTitle(value), 1000)
    }

    function handleTitleBlur() {
        if (draft !== title) updateTitle(draft)
    }

    return (
        <Box flex={1} display="flex" alignItems="center" gap={1}>
            <CognitiveIcon icon={icon} />
            {editing ? (
                <InputBase
                    value={draft}
                    autoFocus={!title}
                    onBlur={handleTitleBlur}
                    onChange={handleTitleChange}
                    placeholder="Perspective title"
                    fullWidth
                    sx={{
                        padding: 0,
                        height: 29, // match the height of title
                        fontSize: 'h5.fontSize',
                        letterSpacing: 'normal',
                        borderBottom: '1px solid',
                        borderColor: 'grayscale.light',
                    }}
                />
            ) : (
                <Typography variant="h5">
                    {title}
                    {focalPointFilter ? ` - ${focalPointFilter.values?.[0].label}` : ''}
                </Typography>
            )}
            {editable && !editing && <DraftIndicator type={VisualType.DASHBOARD} config={config} />}
        </Box>
    )
}
