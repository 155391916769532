import { useContext, useState } from 'react'
import { Box, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import NewGroupIcon from '@mui/icons-material/CreateNewFolderOutlined'
import { useDrop } from 'react-dnd'

import { MenuIcon } from 'genesis-suite/components'
import ViewGroup from './ViewGroup'
import ViewItem from './ViewItem'
import NewItemField from './NewItemField'
import { ModuleViewsContext } from './ModuleViewsContext'
import { DragItem } from './ViewTypes'
import { useSelector } from 'react-redux'
import { moduleSelectors } from '../../selectors'

const useStyles = makeStyles({
    emptyItems: { flex: 1 },
})

export default function ManageModuleViews() {
    const isV2 = useSelector(moduleSelectors.getIsV2)
    const { draftViews, onNewGroup, onMoveItem, isPowerUser } = useContext(ModuleViewsContext)
    const [showNewGroup, setShowNewGroup] = useState(false)

    const classes = useStyles()

    const [, drop] = useDrop({
        accept: ['dashboard', 'group', 'widget'],
        hover: (item: DragItem) => {
            if (item.status === 'new') {
                onMoveItem(item, [draftViews.length])
            } else {
                onMoveItem(item, [draftViews.length - (item.originalIndexes.length === 1 ? 1 : 0)])
            }
        },
    })

    async function handleNewGroup(title: string) {
        onNewGroup(title)
        setShowNewGroup(false)
    }

    return (
        <Box height="100%" display="flex" flexDirection="column" overflow="hidden">
            <Box display="flex" alignItems="center" justifyContent="space-between" m={1} mr={0}>
                <Typography variant="subtitle2">Application Views</Typography>

                {isPowerUser && isV2 && (
                    <MenuIcon
                        buttonProps={{ size: 'small', color: 'primary' }}
                        icon={<NewGroupIcon fontSize="small" />}
                        open={showNewGroup}
                        onClick={() => setShowNewGroup(true)}
                        onClose={() => setShowNewGroup(false)}
                    >
                        <NewItemField
                            textFieldProps={{ placeholder: 'Group name' }}
                            onDone={handleNewGroup}
                            onCancel={() => setShowNewGroup(false)}
                        />
                    </MenuIcon>
                )}
            </Box>

            {isV2 ? (
                <Box flex="1" overflow="auto" display="flex" flexDirection="column" ml={0.5}>
                    {draftViews.map((item, itemIndex) =>
                        item.views ? (
                            <ViewGroup key={item.dragId} index={itemIndex} />
                        ) : (
                            <ViewItem key={item.dragId} view={item} />
                        )
                    )}

                    {isPowerUser ? (
                        <div ref={drop} className={classes.emptyItems}>
                            {!draftViews.length && (
                                <Typography sx={{ ml: 1 }}>No views. Drag view from right.</Typography>
                            )}
                        </div>
                    ) : (
                        !draftViews.length && <Typography sx={{ ml: 1 }}>No views. Contact admin to modify.</Typography>
                    )}
                </Box>
            ) : (
                <Box ml={1}>
                    <Typography>Select a builder application to edit views</Typography>
                </Box>
            )}
        </Box>
    )
}
