import { Chip, ClickAwayListener, Paper, Popper, Typography } from '@mui/material'
import { isEqual } from 'lodash'
import { useContext, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { DebouncedTextField } from 'genesis-suite/components'
import { filterCreators } from '~/actions/creators'
import GroupedList from '~/components/GroupedList'
import { PerspectiveContext } from '~/components/contexts/PerspectiveContext'
import useSearch from '~/hooks/useSearch'
import { filterSelectors } from '~/selectors'

interface Props {
    isCompare?: boolean
}

export default function CompareSearchFilter({ isCompare }: Props) {
    const { id: perspectiveId } = useContext(PerspectiveContext)
    const regularFilter = useSelector(filterSelectors.getAppliedSearchFilters)
    const compareFilters = useSelector(filterSelectors.getAppliedCompareSearchFilters)
    const dispatch = useDispatch()

    const [text, setText] = useState('')
    const inputRef = useRef()

    const filters = useMemo(() => {
        return isCompare ? compareFilters : regularFilter
    }, [isCompare, regularFilter, compareFilters])

    const [searchResults] = useSearch(text, perspectiveId)
    const pending = !searchResults
    const noResults = !pending && !searchResults.length
    const actionCreator = isCompare ? filterCreators.applyCompareSearchFilters : filterCreators.applySearchFilters

    function removeFilter(index) {
        const updated = filters.filter((_, i) => i !== index)
        dispatch(actionCreator(updated))
    }

    function handleSelect(context) {
        dispatch(actionCreator([...filters, makeSearchFilter(context)]))
        setText('')
    }

    const listItems = useMemo(() => {
        if (!searchResults) return null

        return searchResults.reduce((acc, cur) => {
            const { nodeName, displayProperty, context } = cur
            if (filters.some(f => isEqual(f, context))) return acc

            let nodeListIndex = acc.findIndex(l => l.primary === nodeName)
            if (nodeListIndex === -1) {
                acc.push({ primary: nodeName, list: [] })
                nodeListIndex = acc.length - 1
            }

            acc[nodeListIndex].list.push({
                primary: displayProperty.value,
                onClick: () => handleSelect(context),
            })
            return acc
        }, [])
    }, [filters, searchResults])

    return (
        <>
            <DebouncedTextField
                debounceTime={500}
                inputRef={inputRef}
                onChange={setText}
                placeholder="Search"
                value={text}
                sx={theme => ({
                    width: '100px',
                    '& .MuiInputBase-root:before': {
                        borderBottomColor: theme.palette.text.primary,
                    },
                })}
            />
            <ClickAwayListener onClickAway={() => setText('')}>
                <Popper anchorEl={inputRef.current} open={Boolean(text)} placement="bottom-start" sx={{ zIndex: 2 }}>
                    <Paper
                        style={
                            pending || !listItems.length
                                ? { display: 'flex', minWidth: '100px', minHeight: '60px' }
                                : undefined
                        }
                        sx={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            maxHeight: '400px',
                            maxWidth: '600px',
                            overflow: 'auto',
                        }}
                    >
                        <GroupedList data={listItems} pending={pending} />
                        {noResults && <Typography>No results</Typography>}
                    </Paper>
                </Popper>
            </ClickAwayListener>

            {filters.map((f, i) => (
                <Chip
                    key={`${f.FieldName}${f.Values[0]}`}
                    label={f.DisplayValues[0]}
                    onDelete={() => removeFilter(i)}
                />
            ))}
        </>
    )
}

function makeSearchFilter(context) {
    return {
        DisplayValues: [context.DisplayValue],
        Operator: 'EqualTo',
        PostCalculation: false,
        PropertyName: context.FieldName,
        ResourceName: context.Name,
        ResourceType: 'Concept',
        Values: [context.Value],
    }
}
