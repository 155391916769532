import { isEmpty } from 'lodash'

export default function hasData(config: any, data: any) {
    switch (config?.Type?.toUpperCase()) {
        case 'STATUSTRACKER':
        case 'FORM':
            return !isEmpty(data?.data)
        default:
            return !isEmpty(data)
    }
}
