import { Box, CircularProgress, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { getAppLogo } from '~/lib/appLogoUtils'

const useStyles = makeStyles(({ spacing, palette, custom }) => ({
    topBar: {
        width: '100%',
        height: custom.topNav.height,
        background: palette.tada.purple,
        padding: spacing(0, 1),
        display: 'flex',
        alignItems: 'center',
        flexShrink: 0,
        justifyContent: 'space-between',
    },
    loadingContainer: {
        margin: 'auto',
        maxWidth: '400px',
        textAlign: 'center',
    },
    logo: { height: custom.topNav.height - 30, maxWidth: 230 },
}))

const LoadingView = ({ message = '', hideTopBar = false }) => {
    const classes = useStyles()
    const appLogo = getAppLogo()

    return (
        <Box
            sx={{
                height: '100%',
                overflow: 'auto',
                backgroundColor: 'background.primary',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            {!hideTopBar && (
                <Box className={classes.topBar}>
                    <img className={classes.logo} alt="App Logo" src={appLogo.light} draggable="false" />
                </Box>
            )}

            <Box
                sx={{
                    width: '100%',
                    maxWidth: 'lg',
                    flexGrow: 1,
                    padding: { xs: 1, sm: 2, md: 3 },
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div className={classes.loadingContainer}>
                    <CircularProgress color="primary" />
                    <Typography variant="h6" align="center" sx={{ marginTop: 2 }}>
                        {message ? message : 'Getting things ready for you...'}
                    </Typography>
                </div>
            </Box>
        </Box>
    )
}

export default LoadingView
