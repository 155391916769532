import PopoutIcon from '@mui/icons-material/LaunchRounded'
import { IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useContext } from 'react'
import { RightNavComponentProps } from '../../views/rightnav/RightNav'
import { PopoutContext } from '../contexts/PopoutWidgetContext'
import CalculatorHistory from './CalculatorHistory'

const useStyles = makeStyles(({ palette }) => ({
    Popout: { color: palette.text.primary, marginLeft: 'auto' },
    WrapHeaderButton: { width: '100%', display: 'flex', justifyContent: 'right' },
}))

export default function CalculatorController({ Header, onClose }: RightNavComponentProps) {
    const styles = useStyles()
    const { popoutWidget } = useContext(PopoutContext)

    function openPopout() {
        const popOutProps = {
            Id: 'CALCULATOR-POPOUT',
            isComponent: true,
            width: 300,
            height: 500,
            Title: 'Calculator',
        }
        popoutWidget({
            ...popOutProps,
            component: (
                <>
                    <CalculatorHistory />
                </>
            ),
        })
        onClose()
    }

    return (
        <>
            <Header>
                <div className={styles.WrapHeaderButton}>
                    <IconButton className={styles.Popout} onClick={() => openPopout()} size="large">
                        <PopoutIcon fontSize="small" />
                    </IconButton>
                </div>
            </Header>
            <CalculatorHistory />
        </>
    )
}
