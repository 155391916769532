import { Person, SendRounded } from '@mui/icons-material'
import {
    Avatar,
    Box,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    OutlinedInput,
    Tooltip,
    Typography,
} from '@mui/material'
import { ShortTadaLogoIcon } from 'genesis-suite/icons'
import { forwardRef, useRef } from 'react'
import { useSelector } from 'react-redux'
import { ColOptionsProvider } from '../../components/contexts/ColumnOptionsContext'
import Widget from '../../components/widgets/Widget'
import { chatSelectors, filterSelectors } from '../../selectors'
import SpeechMimic from './SpeechMimic'
interface ChatComponentProps {
    Header: any
    messages: any[]
    loading: boolean
    sendQuestion: () => void
    inputValue: string
    setInputValue: (value: string) => void
    hideInput?: boolean
    scrollIntoView: () => void
}

const AIRightChatComponent = forwardRef(
    (
        {
            Header,
            messages,
            loading,
            sendQuestion,
            inputValue,
            setInputValue,
            hideInput,
            scrollIntoView,
        }: ChatComponentProps,
        ref
    ) => {
        const d3WidgetBlowsUpOtherwiseRef = useRef(null)
        const normalFilters = useSelector(state => filterSelectors.currentFiltersConfiguration(state))
        const chatDensity = useSelector(chatSelectors.getChatDensity)
        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            setInputValue(e.target.value)
        }

        const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') sendQuestion()
        }

        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                {Header && <Header />}
                <Box ref={ref} sx={{ flexGrow: 1, overflow: 'auto', p: 1 }}>
                    <List sx={{ p: 0 }}>
                        {messages.map((message, index) => (
                            <ListItem
                                key={index}
                                sx={{
                                    justifyContent:
                                        message.type === 'user' && chatDensity !== 'compact'
                                            ? 'flex-end'
                                            : 'flex-start',
                                }}
                            >
                                <ListItemAvatar>
                                    <Avatar
                                        sx={{
                                            bgcolor: message.type === 'user' ? 'tada.teal' : 'tada.purple',
                                            '& *':
                                                message.type === 'user'
                                                    ? { color: '#000' }
                                                    : { fill: '#fff !important' },
                                        }}
                                    >
                                        {message.type === 'user' ? <Person /> : <ShortTadaLogoIcon />}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        message.messageType === 'string' ? (
                                            <SpeechMimic
                                                text={message.text}
                                                onChange={scrollIntoView}
                                                avoidTyping={message.avoidTyping}
                                            />
                                        ) : message.messageType === 'widget' ? (
                                            <ColOptionsProvider id={message.text.Id}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexFlow: 'column',
                                                        bgcolor: '#fff',
                                                        color: '#000',
                                                        height: 450,
                                                    }}
                                                >
                                                    {/* @ts-ignore */}
                                                    <Widget
                                                        //@ts-ignore
                                                        config={message.text}
                                                        networkContext={message.text.Context}
                                                        ref={d3WidgetBlowsUpOtherwiseRef}
                                                        filters={normalFilters}
                                                    />
                                                </Box>
                                            </ColOptionsProvider>
                                        ) : (
                                            message.text
                                        )
                                    }
                                    sx={{
                                        bgcolor: message.type === 'user' ? 'tada.teal' : 'tada.purple',
                                        color: message.type === 'user' ? '#000' : '#fff',
                                        borderRadius: 1,
                                        p: 1,
                                        minWidth: 100,
                                        maxWidth: '80%',
                                    }}
                                />
                            </ListItem>
                        ))}
                        {loading && (
                            <ListItem sx={{ justifyContent: 'center' }}>
                                <FallingDots />
                            </ListItem>
                        )}
                    </List>
                </Box>
                {!hideInput && (
                    <>
                        <Box
                            sx={{
                                p: 2,
                                pb: 0.25,
                                flex: 0,
                                mt: 'auto',
                                minWidth: 300,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <OutlinedInput
                                autoFocus
                                fullWidth
                                value={inputValue}
                                onChange={handleChange}
                                onKeyPress={handleKeyDown}
                                placeholder="How can I help you?"
                            />
                            <Tooltip title="Send">
                                <IconButton color="inherit" sx={{ ml: 1 }} onClick={sendQuestion}>
                                    <SendRounded />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Typography sx={{ px: 2 }} variant="caption">
                            Response generated by TADA explainable artificial intelligence may contain errors or
                            inaccurate information for your questions and should not be relied upon as a substitute for
                            professional advice. Validate your response accordingly.
                        </Typography>
                    </>
                )}
            </Box>
        )
    }
)

const FallingDots = () => (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', width: 50 }}>
        <Box
            sx={{
                position: 'relative',
                left: '-9999px',
                width: '10px',
                height: '10px',
                borderRadius: '5px',
                backgroundColor: '#9880ff',
                color: '#9880ff',
                boxShadow: '9999px 0 0 0 #9880ff',
                animation: 'dot-falling 1s infinite linear',
                animationDelay: '0.1s',
                '&::before, &::after': {
                    content: '""',
                    display: 'inline-block',
                    position: 'absolute',
                    top: '0',
                },
                '&::before': {
                    width: '10px',
                    height: '10px',
                    borderRadius: '5px',
                    backgroundColor: '#9880ff',
                    color: '#9880ff',
                    animation: 'dot-falling-before 1s infinite linear',
                    animationDelay: '0s',
                },
                '&::after': {
                    width: '10px',
                    height: '10px',
                    borderRadius: '5px',
                    backgroundColor: '#9880ff',
                    color: '#9880ff',
                    animation: 'dot-falling-after 1s infinite linear',
                    animationDelay: '0.2s',
                },
                '@keyframes dot-falling': {
                    '0%': {
                        boxShadow: '9999px -15px 0 0 rgba(152, 128, 255, 0)',
                    },
                    '25%, 50%, 75%': {
                        boxShadow: '9999px 0 0 0 #9880ff',
                    },
                    '100%': {
                        boxShadow: '9999px 15px 0 0 rgba(152, 128, 255, 0)',
                    },
                },
                '@keyframes dot-falling-before': {
                    '0%': {
                        boxShadow: '9984px -15px 0 0 rgba(152, 128, 255, 0)',
                    },
                    '25%, 50%, 75%': {
                        boxShadow: '9984px 0 0 0 #9880ff',
                    },
                    '100%': {
                        boxShadow: '9984px 15px 0 0 rgba(152, 128, 255, 0)',
                    },
                },
                '@keyframes dot-falling-after': {
                    '0%': {
                        boxShadow: '10014px -15px 0 0 rgba(152, 128, 255, 0)',
                    },
                    '25%, 50%, 75%': {
                        boxShadow: '10014px 0 0 0 #9880ff',
                    },
                    '100%': {
                        boxShadow: '10014px 15px 0 0 rgba(152, 128, 255, 0)',
                    },
                },
            }}
        />
    </Box>
)

export default AIRightChatComponent
