import { useSelector, useDispatch } from 'react-redux'
import { IconButton, Tooltip } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import DesktopIcon from '@mui/icons-material/DesktopWindowsOutlined'
import DoneIcon from '@mui/icons-material/Done'
import PhoneIcon from '@mui/icons-material/PhoneIphone'
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined'
import { widgetTypes } from '~/actions/types'

import { widgetSelectors } from '../selectors'
import { widgetCreators } from '../actions/creators'

const phoneWidth = '400px'

export default function EditPerspectiveTools({ buttonStyle }) {
    const width = useSelector(widgetSelectors.getPerspectiveWidth)
    const dispatch = useDispatch()

    function handlePhoneToggle() {
        dispatch(widgetCreators.changePerspectiveWidth(width === '100%' ? phoneWidth : '100%'))
    }

    async function handleDone() {
        dispatch(widgetCreators.stopEditingPerspective())
    }

    return (
        <>
            <ToolButton
                title="Add as Perspective Layout"
                Icon={DashboardCustomizeOutlinedIcon}
                onClick={() => {
                    dispatch({ type: widgetTypes.STOP_PERSPECTIVE_EDITING })
                    dispatch(widgetCreators.startChangingToPerspectiveLayout(null))
                }}
                sx={buttonStyle}
                data-cy="add-perspect-layout-btn"
            />

            <ToolButton
                title="Change display"
                Icon={width === '100%' ? DesktopIcon : PhoneIcon}
                onClick={handlePhoneToggle}
                sx={buttonStyle}
                data-cy="change-display-btn"
            />

            <ToolButton
                title="Done"
                Icon={DoneIcon}
                onClick={handleDone}
                sx={buttonStyle}
                data-cy="perspect-edit-submit-btn"
            />
        </>
    )
}

const ToolButton = withStyles(({ palette }) => ({ icon: { color: palette.primary.contrastText } }))(
    ({ classes, Icon, title, ...rest }) => (
        <Tooltip title={title}>
            <span>
                <IconButton variant="contained" {...rest}>
                    <Icon fontSize="small" className={classes.icon} />
                </IconButton>
            </span>
        </Tooltip>
    )
)
