import { useState, useContext, useLayoutEffect } from 'react'
import { isEqual } from 'lodash'
import { Accordion, AccordionSummary, Typography, Tooltip } from '@mui/material'
import Measure from 'react-measure'
import { LayoutPerspectiveContext } from '~/components/contexts/LayoutPerspectiveContext'
import { Responsive as GridLayout } from 'react-grid-layout'
import { defaultLayout, customLayout } from './utils'

export default function ReOrderAccordionPerspective({ layout, setLayout, perspectives }) {
    const [width, setWidth] = useState(100)
    const { tabRows, setTabRows, tabValue } = useContext(LayoutPerspectiveContext)

    useLayoutEffect(() => {
        let data = []
        const index = tabRows[tabValue]?.perspective?.length > 0 ? tabRows[tabValue]?.perspective?.length - 1 : 0
        if (tabRows[tabValue]?.perspective?.[index].Order) {
            data = customLayout(tabRows[tabValue]?.perspective)
        } else {
            data = defaultLayout(tabRows[tabValue]?.perspective)
        }
        if (isEqual(layout, data)) return
        setLayout(data)
    }, [])

    const perspectiveName = id => {
        return perspectives.find(item => item.Id == id).Title
    }

    const onChangeLayout = (_layout, layouts) => {
        const updated = layouts?.lg?.map(({ i, x, y, w, h }) => ({ i, x, y, w, h }))
        if (isEqual(layouts.lg, updated)) return
        setLayout(updated)
    }

    return (
        <Measure
            client
            onResize={({ client }) => {
                setWidth(client.width - 1)
            }}
        >
            {({ measureRef }) => (
                <div ref={measureRef}>
                    <GridLayout
                        layouts={{ lg: layout }}
                        compactType={'vertical'}
                        breakpoints={{ lg: 1200 }}
                        cols={{ lg: 1 }}
                        rowHeight={50}
                        isDraggable={true}
                        isResizable={false}
                        width={width}
                        onLayoutChange={onChangeLayout}
                    >
                        {tabRows[tabValue]?.perspective.map((item, key) => (
                            <div key={item.Id}>
                                <Accordion expanded={false}>
                                    <Tooltip title="Drag and Drop to Change the Order">
                                        <AccordionSummary id="panel2-header">
                                            <Typography variant="h6" style={{ fontWeight: '600' }}>
                                                {perspectiveName(item.Id)}
                                            </Typography>
                                        </AccordionSummary>
                                    </Tooltip>
                                </Accordion>
                            </div>
                        ))}
                    </GridLayout>
                </div>
            )}
        </Measure>
    )
}
