import makeStyles from '@mui/styles/makeStyles'
import Cookie from 'js-cookie'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CookieIcon from '@mui/icons-material/Cookie'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from '@mui/material'

import { appearanceCreators } from '../../actions/creators'
import { appearanceSelectors, applicationSelectors } from '../../selectors'

const useStyles = makeStyles(({ palette, spacing }) => ({
    root: {
        position: 'absolute',
        bottom: 0,
        maxHeight: '90vh',
        width: '500px',
        maxWidth: '90vw',
        cursor: 'default',
    },
    backdrop: { cursor: 'not-allowed' },
    cover: { display: 'flex', flexDirection: 'column' },
    titleContainer: { padding: spacing(1, 1, 0) },
    titleText: { marginLeft: spacing() },
    iconStyle: { color: palette.text.primary },
}))

const ManageCookies = () => {
    const disable = window.TADA_APIS.DISABLE_COOKIES
    const open = useSelector(appearanceSelectors.getShowCookieConsent)
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const dispatch = useDispatch()
    const classes = useStyles()

    useEffect(() => {
        if (disable) return
        if (Cookie.get('cookieConsent') === undefined && !open && appName) {
            dispatch(appearanceCreators.toggleCookieConsent())
        }
    }, [appName])

    const handleClick = (setAll = true) => {
        if (setAll) {
            Cookie.set('cookieConsent', JSON.stringify({ required: true, analytes: true }), { expires: 30 })
        } else {
            Cookie.set('cookieConsent', JSON.stringify({ required: true, analytes: false }), { expires: 30 })
        }
        dispatch(appearanceCreators.toggleCookieConsent())
    }

    if (disable) return null

    return (
        <Dialog
            // hideBackdrop
            open={open}
            classes={{ container: classes.backdrop, paper: classes.root }}
        >
            <DialogTitle className={classes.titleContainer}>
                <Box display="flex" alignItems="center">
                    <CookieIcon />
                    <Typography className={classes.titleText} variant="h6">
                        Cookie consent
                    </Typography>
                </Box>
            </DialogTitle>

            <DialogContent sx={{ pb: 0 }}>
                <DialogContentText sx={{ pb: 1, color: 'text.primary' }}>
                    We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze
                    our traffic. By clicking "Enhance Your Experience", you consent to our use of cookies.
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon className={classes.iconStyle} />}
                            aria-controls="Accept-All-Content"
                            id="accept_all_header"
                        >
                            Enhance Your Experience (Accept All Cookies)
                        </AccordionSummary>
                        <AccordionDetails>
                            <span>By clicking "Enhance Your Experience", you agree to the following:</span>
                            <ul>
                                <li>
                                    <strong>Storing all cookies:</strong>
                                    This includes:
                                    <ul>
                                        <li>
                                            <strong>Essential cookies:</strong>Strictly necessary for core functionality
                                            and security.
                                        </li>
                                        <li>
                                            <strong>Performance cookies:</strong>Analyze website usage to improve
                                            navigation and performance.
                                        </li>
                                        <li>
                                            <strong>Functional cookies:</strong>Remember preferences and settings for a
                                            personalized experience.
                                        </li>
                                        <li>
                                            <strong>Analytical cookies:</strong>Gather insights into user behavior to
                                            optimize features and services.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Data sharing with trusted partners:</strong>We do not share any data with
                                    partners.
                                </li>
                                <li>
                                    <strong>Full application functionality:</strong>Enjoy all features and capabilities,
                                    including personalized dashboards, recommendations, and efficient workflows.
                                </li>
                            </ul>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon className={classes.iconStyle} />}
                            aria-controls="Accept-Necessary-Content"
                            id="accept_necessary_header"
                        >
                            Maintain Essential Functionality (Accept Necessary Cookies only)
                        </AccordionSummary>
                        <AccordionDetails>
                            <span>By clicking "Maintain Essential Functionality", you agree to the following:</span>
                            <ul>
                                <li>
                                    <strong>Storing essential cookies only:</strong>Ensure basic application
                                    functionality and security.
                                </li>
                                <li>
                                    <strong>Limited functionality:</strong> Some features like personalized content,
                                    recommendations, and efficient workflow suggestions may be unavailable.
                                </li>
                                <li>
                                    <strong>No data sharing with third parties:</strong>Your data will not be shared
                                    with any third-party companies for analytics or advertising purposes.
                                </li>
                            </ul>
                        </AccordionDetails>
                    </Accordion>
                    <Box sx={{ pt: 1 }}>
                        <strong>Additional Information:</strong>
                        <ul>
                            <li>
                                You can manage your cookie preferences at any time by clicking on the "Cookie Settings"
                                link within the application settings menu.
                            </li>
                            <li>
                                For detailed information about the cookies we use and their purposes, please visit our
                                Privacy Policy. Privacy policy statement can be defined based on customer preferences.
                            </li>
                        </ul>
                    </Box>
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button variant="outlined" color={'secondary'} onClick={() => handleClick(false)}>
                    Enhance Your Experience
                </Button>
                <Button variant="contained" color={'primary'} onClick={() => handleClick()}>
                    Maintain Essential Functionality
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ManageCookies
