import { Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Slideshow from '~/components/Slideshow'
import { authSelectors, widgetSelectors } from '../../selectors'
import FavoriteWidget from './FavoriteWidget'

const useStyles = makeStyles(({ spacing }) => ({
    emptyState: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexFlow: 'column nowrap',
        '& p': {
            maxWidth: 500,
            '& svg': {
                verticalAlign: 'text-bottom',
            },
        },
    },
    svg: {
        height: '125px',
    },
    comingSoonText: {
        margin: spacing(2, 0),
        fontWeight: 750,
    },
    grid: {
        maxHeight: '100%',
        overflow: 'scroll',
        padding: spacing(0, 1),
    },
}))

export default function Favorites({ className }) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const configs = useSelector(widgetSelectors.getWidgetConfigs)
    const favorites = useSelector(state => authSelectors.getPreference(state, 'widgetFavorites'))

    const [slideShowIndex, setSlideshowIndex] = useState<number>(null)

    const widgets = favorites
        ? favorites.reduce((acc, id) => {
              if (configs[id]) acc.push(configs[id])
              return acc
          }, [])
        : []

    if (!widgets.length) return null

    return (
        <div className={className}>
            <Typography variant="h6" gutterBottom>
                Favorites
            </Typography>

            <Grid className={classes.grid} spacing={2} container>
                {widgets.map((config, index) => (
                    <Grid item key={config.Id} md={3} sm={12} xs={12}>
                        <FavoriteWidget config={config} onLaunch={() => setSlideshowIndex(index)} />
                    </Grid>
                ))}
            </Grid>

            <Slideshow
                configs={widgets}
                open={slideShowIndex != null}
                onClose={() => setSlideshowIndex(null)}
                startIndex={slideShowIndex}
            />
        </div>
    )
}
