import { Box, CircularProgress } from '@mui/material'

import { Dashboard, Widget } from 'genesis-suite/types/visualTypes'
import DashboardContent from './DashboardContent'
import { DashboardProvider } from './DashboardContext'
import DashboardHeader from './DashboardHeader'
import ExistingWidgetsDialog from './ExistingWidgetsDialog'
import HomePageHeader from './HomePageHeader'
import { DashboardAction } from './types'

interface Props {
    /** (optional) overwrite default actions */
    actions?: DashboardAction[]
    config: Dashboard
    /** (default true) */
    editable?: boolean
    /** Request new widgets (add/remove widget) */
    getWidgets?: () => void
    hideHeader?: boolean
    widgets: Widget[]
}

export default function DashboardDisplay(props: Props) {
    return (
        <DashboardProvider {...props}>
            {props.config ? (
                <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', overflow: 'hidden' }}>
                    {props.hideHeader ? null : props.config.homePage ? <HomePageHeader /> : <DashboardHeader />}
                    <DashboardContent />
                </Box>
            ) : (
                <Box flex={1} display="flex" alignItems="center" justifyContent="center">
                    <CircularProgress />
                </Box>
            )}
            <ExistingWidgetsDialog />
        </DashboardProvider>
    )
}
