import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { applicationSelectors, getSelectedBackup } from '../selectors'
import { modelService } from '../lib/services'

export const useNodeData = (name, search) => {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const [response, setResponse] = useState()
    const [loading, setLoading] = useState(false)
    const selectedBackup = useSelector(getSelectedBackup)
    const backUpId = selectedBackup?.[0]?.Id ?? null

    useEffect(() => {
        if (!name) return

        let cancelCall
        setLoading(true)
        modelService
            .getResourceValues(appName, name, search, backUpId, cancel => {
                cancelCall = cancel
            })
            .then(content => {
                setLoading(false)
                setResponse(content)
            })
            .catch(error => {
                if (error.__CANCEL__) return

                setLoading(false)
                console.error(error)
            })

        return () => cancelCall()
    }, [name, search])

    return { loading, ...response }
}
