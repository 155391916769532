import React from 'react'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(({ palette, spacing, custom }) => ({
    label: {
        fontWeight: 'bold',
        fontSize: '1rem',
        width: 'fit-content',
        marginLeft: spacing(1),
        padding: spacing(1, 2),
        borderTopLeftRadius: spacing(1),
        borderTopRightRadius: spacing(1),
        background: palette.primary.main,
        color: palette.getContrastText(palette.primary.main),
    },
}))

export default function ConfigHeader({ title }) {
    const classes = useStyles()
    return <div className={classes.label}>{title}</div>
}
