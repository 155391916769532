import { isEmpty, uniq, uniqBy } from 'lodash'
import hash from 'object-hash'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AsyncResourceFiltersCache } from 'genesis-suite/components'
import { ResourceType } from 'genesis-suite/types/networkTypes'
import { cleanFilters, filtersToString, getFilterFields, stringToFilters } from 'genesis-suite/utils'
import { filterCreators } from '../../../actions/creators'
import { fetchResource } from '../../../hooks/useResourceMeta'
import { useSemanticTypeById } from '../../../hooks/useSemanticTypes'
import { modelService } from '../../../lib/services'
import { applicationSelectors, filterSelectors } from '../../../selectors'

export default function useFetchPerspectiveDefaultFilters(id: string) {
    const perspectiveFiltersConfig = useSelector(filterSelectors.getPerspectiveFiltersConfig)
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const semanticTypeById = useSemanticTypeById()
    const userFilters =
        useSelector(state => filterSelectors.getFilterPreference(state, 'PerspectiveFilters-' + id)) || {}
    const dispatch = useDispatch()
    const [readyToRender, setReadyToRender] = useState<boolean>(false)

    useEffect(() => {
        const filtersWithDefaultFirstValue = perspectiveFiltersConfig.filter(filter => filter.DefaultFirstValue)

        if (filtersWithDefaultFirstValue?.length === 0) {
            setReadyToRender(true)
            return
        }

        ;(async () => {
            await prepareDefaultFilters(filtersWithDefaultFirstValue)
        })()
    }, [])

    const prepareDefaultFilters = async (filtersConfig: any) => {
        const defaultFilters = {}
        for (const filterConfig of filtersConfig) {
            const {
                Name,
                Source,
                DisplayPropertyName,
                KeyPropertyName,
                DefaultSort,
                IsTemporal,
                UseLastRefreshDate,
                PostCalculation,
            } = filterConfig
            const resource = await fetchResource(appName, ResourceType.NODE, Source.ElementName, semanticTypeById)
            const filters = stringToFilters(resource.properties, Source.Filters)
            const contextFilters = undefined
            const networkFilters = Source.NetworkFilters?.map(f => f.split('.')[1])
            const controlField = DisplayPropertyName ?? KeyPropertyName

            const cleanedFilters = cleanFilters(filters)
            const filterFields = getFilterFields(cleanedFilters).map(f => f.name)
            const fieldNames = uniq([controlField, KeyPropertyName, ...filterFields])
                .map(n => (n.indexOf(' ') >= 0 ? `[${n}]` : n))
                .join(',')
            const resourceName = Source.ElementName
            const resourceType = Source.ElementType

            const keyHash = hash({ filters, networkFilters, controlField, contextFilters })

            const search = {
                controlField,
                sort: DefaultSort === 2 ? ('desc' as 'd') : ('asc' as 'a'),
                filters: filtersToString(cleanedFilters),
                fieldNames,
                networkFilters: networkFilters?.join(', '),
                contextFilters,
                pageSize: 250,
                pageIndex: 0,
                resourceType,
            }

            const options = await fetchFilterData(resourceName, search, controlField, KeyPropertyName, keyHash)

            if (!isEmpty(options)) {
                const filterObj = {
                    FilterName: Name,
                    ResourceName: resourceName,
                    ResourceType: resourceType,
                    PropertyName: KeyPropertyName,
                    IsTemporal,
                    values: [options[0]],
                    DisplayValues: [options[0]?.label],
                    rangeOffset: {},
                    useLastRefreshDate: UseLastRefreshDate,
                    operator: 'EqualTo',
                    PostCalculation,
                }

                defaultFilters[Name] = filterObj
            }
        }

        const filters = { ...userFilters, ...defaultFilters }

        dispatch(filterCreators.applyPerspectiveFilters(filters))

        setReadyToRender(true)
    }

    const fetchFilterData = async (
        resourceName: string,
        search: any,
        controlField: string,
        KeyPropertyName: string,
        keyHash: any
    ) => {
        if (AsyncResourceFiltersCache[resourceName] && keyHash in AsyncResourceFiltersCache[resourceName])
            return AsyncResourceFiltersCache[resourceName][keyHash]?.options

        try {
            let handleCancel: () => void
            const res = await modelService.getResourceValues(appName, resourceName, search, '', c => {
                handleCancel = c
            })

            const options = uniqBy(res.values, controlField).map(v => ({
                value: String(v[KeyPropertyName]),
                label: String(v[controlField]),
            }))

            const result = { options, totalRecords: res.totalRecords }
            AsyncResourceFiltersCache[resourceName] = AsyncResourceFiltersCache[resourceName] || {}
            AsyncResourceFiltersCache[resourceName][keyHash] = result

            return options
        } catch (err) {
            if (err.__CANCEL__) return

            console.error(err)
        }

        return []
    }

    return readyToRender
}
