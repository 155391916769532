import { ColumnVO, IServerSideGetRowsRequest, RowNode } from '@ag-grid-community/core'
import { PropertyMetaWithSemantic } from 'genesis-suite/types/networkTypes'
import {
    CategoryTableColumn,
    DataGroup,
    DataResponse,
    FilterGroup,
    ReportValueField,
    TableConfig,
    TableSeries,
    ValueTableColumn,
    Widget,
} from 'genesis-suite/types/visualTypes'
import { isNil } from 'lodash'
import tadacharts from 'tadacharts/es/widgetFunctions'
import { v4 as uuid } from 'uuid'
import { getPropertyMeta } from '../../hooks/useProperties'
import { widgetService } from '../../lib/services'
import { IBaseRowData } from '../widgets2/agGridTable/BaseAgGridTable'
import { getPercentageFieldValue } from '../widgets2/reporting/ReportTable/DataRequest'
import makeTableRowsAndColumns from '../widgets2/table/makeTableRowsAndColumns'
import parseResponse from '../widgets2/utils/parseResponse'
import { createBaseDataRequest } from './DataRequest'

export interface CreateCollapsableRowsParams {
    data: DataGroup[]
    nGroupKeys: number
    columns: ColumnVO[]
    categories: CategoryTableColumn[]
    seriesValues: ValueTableColumn[]
    hasPivot: boolean
    properties: PropertyMetaWithSemantic[]
    parentNode?: RowNode<any>
    firstGroupData?: any
    useFieldName?: boolean
}

//format the rows based on the groupKeys (rows that are expanded) of the grid
export const createCollapsableRows = (params: CreateCollapsableRowsParams) => {
    const {
        data,
        nGroupKeys,
        columns,
        categories,
        seriesValues,
        hasPivot,
        properties,
        parentNode,
        firstGroupData,
        useFieldName,
    } = params
    return data.map((d: DataGroup, index) => {
        //first we create the row object, which will be filled with the values of the columns
        const row: IBaseRowData = {
            key: `${index}${d.group}`,
        }

        //nGroupKeys gives the number of groups that are expanded, so we know rowGroupCols[nGroupKeys] is the last expanded group
        //if the number of expanded groups is greater than the number of columns, then we know it's the last of the categories, the one used for the autoGroupColumn
        if (nGroupKeys < columns.length) {
            const category = categories?.find(cat => cat.field?.displayName === columns[nGroupKeys]?.field)
            if (category?.field) row[category.field.name] = d.group
            row[columns[nGroupKeys].field] = d.displayGroup || d.group
        } else {
            const category = categories.slice(-1)[0]
            row[category.field.name] = d.group
            if (category.field.displayName) row[category.field.displayName] = d.displayGroup
        }

        let hasValue = false
        const propertyFields = seriesValues
            .filter(v => v.propertyType == 'Property')
            .map(v => {
                return { id: v.id, name: v.field.name }
            })

        const propertySeriesValues = seriesValues.filter(v => v.propertyType == 'Property')
        const widgetFunctionFields = seriesValues.filter(v => v.propertyType == 'WidgetFunction') ?? []

        //if there are no pivot columns, then we can just add the values to the row
        hasPivot
            ? createPivotRows(d, row, seriesValues)
            : propertySeriesValues.forEach((value, index) => {
                  if (value.propertyType == 'WidgetFunction') {
                      widgetFunctionFields.push(value)
                      return
                  }

                  const rowPropertyName = () => {
                      if (useFieldName) return value.field?.name || value.title
                      return (
                          (properties &&
                              properties.length > 0 &&
                              getPropertyMeta(properties, value.field)?.displayName) ||
                          value.title ||
                          value.field?.name
                      )
                  }

                  let fieldValue: string | number = d.aggregatedData[index]
                  row[`${rowPropertyName()}_raw`] = fieldValue
                  if (isNil(fieldValue)) {
                      fieldValue = '--'
                  } else hasValue = true

                  row[rowPropertyName()] = fieldValue.toString()

                  if (value.percentageFieldTitle) {
                      row[value.percentageFieldTitle] = getPercentageFieldValue(
                          value as ReportValueField,
                          parentNode,
                          fieldValue,
                          firstGroupData
                      )
                  }
              })
        if (widgetFunctionFields.length > 0) {
            widgetFunctionFields.forEach(widgetFunctionField => {
                let functionValue = tadacharts.calculateWidgetFunction(
                    row,
                    widgetFunctionField.widgetFunction,
                    propertyFields
                )
                if (!isFinite(functionValue)) functionValue = 0
                row[widgetFunctionField.title] = functionValue ?? '--'
                row[`${widgetFunctionField.title}_raw`] = functionValue
            })
        }
        row.show = hasValue
        return row
    })
}

const createPivotRows = (groupData: DataGroup, row, seriesValues: ValueTableColumn[]) => {
    if (!groupData) return

    seriesValues.forEach((value, index) => {
        groupData.children.forEach((childGroupData: DataGroup) => {
            //pivot row names have to be in the format of "[pivot_group_value]_[series_value_name]"
            row[`${childGroupData.group}_${value.title || value.field.name}`] = childGroupData.aggregatedData[index]
        })
    })
}

//create rows without grouping
export const createRows = (response: DataResponse, config: Widget, properties: PropertyMetaWithSemantic[]) => {
    if (response[0].data.length === 0) return []
    const parsed = parseResponse(response, config)
    const withConfig = { ...parsed, config }
    const { rows, columns } = makeTableRowsAndColumns(
        config as TableConfig,
        withConfig.data,
        withConfig.groupNames,
        properties
    )

    const formattedRows = []
    rows.forEach((row, rowIndex) => {
        formattedRows.push({})
        columns.forEach((column, columnIndex) => {
            const displayName =
                properties && properties.length > 0 && getPropertyMeta(properties, column.field)?.displayName

            formattedRows[rowIndex] = {
                ...formattedRows[rowIndex],
                [displayName || column.title || column.field.name]: row[columnIndex],
            }
        })
    })

    return formattedRows
}

export const getRowCount = (results: DataGroup[], request: IServerSideGetRowsRequest, cacheBlockSize: number) => {
    if (!results || results.length === 0) return request.startRow
    if (request.endRow === undefined || request.startRow === undefined) return results.length
    return results.length < cacheBlockSize ? request.startRow + results.length : -1
}

export interface GetRowDataParams {
    request: IServerSideGetRowsRequest
    config: TableConfig
    categories: CategoryTableColumn[]
    tableSeries: TableSeries
    groupKeysStartingIndex?: number
    filters?: FilterGroup
    networkFilters?: Array<string>
    groupKeysLength?: number
    context?: string
    parentNode?: RowNode<any>
    mappedPivotsColumns?: {
        [key: string]: string
    }
    properties?: PropertyMetaWithSemantic[]
    isCollapsable?: boolean
    firstGroupData?: any
    useFieldName?: boolean
    cacheBlockSize?: number
}

export const getRowData = async (
    params: GetRowDataParams
): Promise<{ data: any; rows: any[]; totalRowCount: number }> => {
    const {
        request,
        config,
        categories,
        tableSeries,
        groupKeysStartingIndex = params.groupKeysStartingIndex || 0,
        filters,
        networkFilters,
        groupKeysLength,
        context,
        parentNode,
        mappedPivotsColumns = params.mappedPivotsColumns || {},
        properties = params.properties || [],
        isCollapsable = params.isCollapsable || true,
        firstGroupData = params.firstGroupData || null,
        useFieldName = params.useFieldName || false,
        cacheBlockSize,
    } = params

    const baseDataRequest = createBaseDataRequest(
        request,
        config,
        mappedPivotsColumns,
        properties,
        groupKeysStartingIndex,
        filters,
        parentNode,
        cacheBlockSize,
        networkFilters
    )

    if (context) baseDataRequest.context = context

    const result = await widgetService.getData2(baseDataRequest)

    if (result.length === 0) {
        return { data: null, rows: [], totalRowCount: 0 }
    }

    const rows = isCollapsable
        ? createCollapsableRows({
              data: result[0].data,
              nGroupKeys: groupKeysLength || request.groupKeys.length,
              columns: request.rowGroupCols,
              categories: categories,
              seriesValues: tableSeries?.values,
              hasPivot: request.pivotCols?.length > 0,
              properties,
              parentNode,
              firstGroupData,
              useFieldName,
          } as CreateCollapsableRowsParams)
        : createRows(result, config, properties)

    const idRows = rows.map(r => ({ ...r, id: uuid() }))
    return { data: result[0].data, rows: idRows, totalRowCount: result[0].meta?.totalRowCount }
}
