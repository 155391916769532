import React, { useEffect, useState } from 'react'

import { CloseRounded } from '@mui/icons-material'
import { useDebouncedCallback } from 'use-debounce'
import { FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material'

interface DtFormatProps {
    dateFormat: string
    onChange: (format: { dateFormat: string }) => void
    className?: string
}

export function DateFormatBuilder({ className, dateFormat, onChange }: DtFormatProps) {
    const [custom, setCustom] = useState(false)

    useEffect(() => {
        if (dateFormat) {
            const values = formatOptions.map(o => o.value)
            if (!values.includes(dateFormat)) setCustom(true)
        }
    }, [dateFormat])

    const formatOptions = [
        { label: 'Date', value: 'MM/DD/YYYY' },
        { label: 'Date & Time', value: 'MM/DD/YYYY h:mm a' },
        { label: 'Custom', value: 'custom' },
    ]

    const handleFormatChange = option => {
        if (option === 'custom') {
            setCustom(true)
        } else {
            onChange({ dateFormat: option })
        }
    }

    const handleClear = () => {
        setCustom(false)
        onChange({ dateFormat: formatOptions[0].value })
    }

    const handleTextChange = useDebouncedCallback((dateFormat: string) => {
        onChange({ dateFormat })
    }, 300)

    if (custom)
        return (
            <TextField
                className={className}
                label="Date Format"
                defaultValue={dateFormat}
                placeholder="(ex: YYYY/DD/MM)"
                onChange={e => handleTextChange(e.target.value)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={handleClear} size="large">
                                <CloseRounded fontSize="small" />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        )

    return (
        <FormControl className={className}>
            <InputLabel id="dtFormat">Date Format</InputLabel>
            <Select
                labelId="dtFormat"
                value={dateFormat || 'MM/DD/YYYY'}
                onChange={e => handleFormatChange(e.target.value)}
            >
                {formatOptions.map(o => (
                    <MenuItem key={o.label} value={o.value}>
                        {o.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
