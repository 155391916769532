import { Box } from '@mui/material'
import { useSelector } from 'react-redux'

import { useIsMobile } from '../hooks/useIsMobile'
import { appearanceSelectors } from '../selectors'
import AppMenu from './AppMenu'

export default function ThirdTopBar() {
    const isMobile = useIsMobile()
    const layout = useSelector(appearanceSelectors.getApplicationLayout)
    const showNeoClassic = useSelector(appearanceSelectors.getShowNeoClassicLayOut)

    if (layout === 'modern' || isMobile) return null

    if (showNeoClassic) return null

    return (
        <Box sx={{ borderBottom: 1, borderColor: 'border.main' }}>
            <AppMenu />
        </Box>
    )
}
