import { TextConfig, ChartType } from 'genesis-suite/types/visualTypes'

export default async function textConverter(config): Promise<TextConfig> {
    const { Title, RichTextConfig } = config

    return {
        version: '2',
        type: ChartType.TEXT,
        title: Title,
        text: RichTextConfig.Content,
    }
}
