import { IconButton, Tooltip } from '@mui/material'
import { Settings as SettingsIcon } from 'genesis-suite/icons'
import { useDispatch } from 'react-redux'

import { appearanceCreators } from '../actions/creators'
import { logEvent } from '../lib/amplitudeClient'

export default function SettingsButton({ iconStyle, buttonStyle }) {
    const dispatch = useDispatch()

    function openSettings() {
        dispatch(appearanceCreators.toggleSettings())
        logEvent('OPEN_SETTINGS')
    }

    return (
        <Tooltip title="Settings">
            <IconButton sx={buttonStyle} onClick={openSettings}>
                <SettingsIcon sx={iconStyle} />
            </IconButton>
        </Tooltip>
    )
}
