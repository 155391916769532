import makeStyles from '@mui/styles/makeStyles'
import { useDispatch } from 'react-redux'
import { ArrowBack, Done, ClearOutlined } from '@mui/icons-material'
import { Box, TextField, Tooltip, IconButton, Button, useTheme } from '@mui/material'
import { appearanceConstants } from '~/constants'
import { widgetCreators } from '~/actions/creators'
import { useIsMobile } from '~/hooks/useIsMobile'
const { ScrollBarWidth } = appearanceConstants

const useStyles = makeStyles(({ spacing, palette, border }) => ({
    rightTools: {
        alignSelf: 'center',
        backgroundColor: palette.primary.main,
        borderRadius: border.radius.round,
        display: 'flex',
        justifyContent: 'flex-end',
        padding: spacing(0.25),
    },
}))

export default function LayoutPerspectiveToolbar({
    lpName,
    setLPName,
    onClickDone,
    onClickCancel,
    isPerspectiveHasLayout,
}) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const { palette, custom } = useTheme()
    const defaultIconProps = {
        style: { fontSize: custom.perspectiveToolbar?.fontSize, color: palette.primary.contrastText },
    }
    const buttonStyle = {
        padding: custom.perspectiveToolbar?.padding,
        '&.Mui-disabled': {
            cursor: 'default',
            pointerEvents: 'none',
            opacity: 0.5,
        },
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <Box sx={{ flex: 1, overflow: 'hidden', display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box sx={{ width: isMobile ? 200 : 300, height: 45, pt: 1 }}>
                    <TextField
                        fullWidth
                        sx={{
                            '.MuiInputBase-root:before': {
                                borderBottomColor: 'text.primary',
                            },
                            '& .MuiFormLabel-root': {
                                color: 'text.primary',
                            },
                        }}
                        placeholder="Enter Perspective Layout Name"
                        value={lpName}
                        onChange={e => setLPName(e.target.value)}
                    />
                </Box>
                <Button
                    variant="outlined"
                    onClick={() => dispatch(widgetCreators.stopChangingToPerspectiveLayout())}
                    style={{ maxHeight: '30px' }}
                    size="small"
                    startIcon={<ArrowBack />}
                >
                    Go back to perspective
                </Button>
            </Box>
            <div className={classes.rightTools} data-html2canvas-ignore>
                {isPerspectiveHasLayout && (
                    <Tooltip title="Cancel">
                        <span>
                            <IconButton sx={buttonStyle} onClick={onClickCancel}>
                                <ClearOutlined {...defaultIconProps} />
                            </IconButton>
                        </span>
                    </Tooltip>
                )}
                <Tooltip title="Done">
                    <span>
                        <IconButton sx={buttonStyle} onClick={onClickDone}>
                            <Done {...defaultIconProps} />
                        </IconButton>
                    </span>
                </Tooltip>
            </div>
        </Box>
    )
}
