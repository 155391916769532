import { useSelector, useDispatch } from 'react-redux'
import { Box } from '@mui/material'
import { lockoutSelectors, widgetSelectors } from '../selectors'
import ComparePerspective from '../components/widgets/ComparePerspective'
import PerspectiveInfoBar from '../components/PerspectiveInfoBar'
import { networkCreators, widgetCreators } from '../actions/creators'
import Dashboard from '../components/widgets2/dashboard/Dashboard'
import useDashboardId from '../hooks/useDashboardId'
import ComparePerspectiveLayout from './perspectivelayout/ComparePerspectiveLayout'

export default function ComparePerspectiveView() {
    const { id, isV2 } = useDashboardId()
    const editingPerspective = useSelector(widgetSelectors.getEditingPerspective)
    const draftLayouts = useSelector(widgetSelectors.getDraftPerspectiveLayouts)
    const currentLayouts = useSelector(widgetSelectors.getCurrentPerspectiveLayouts)
    const dispatch = useDispatch()

    const perspectiveLayoutConfig = useSelector(widgetSelectors.getPerspectiveLayoutConfig(id))
    const perspectiveLayoutView = perspectiveLayoutConfig != undefined ? true : false

    const props = {
        id,
        editingPerspective,
        layouts: draftLayouts || currentLayouts,
        containerWidth: useSelector(widgetSelectors.getPerspectiveWidth),
        isEditing: editingPerspective === id,
        currentLayout: useSelector(widgetSelectors.getCurrentLayout),
        configs: useSelector(s => widgetSelectors.getWidgetConfigsForPerspective(s, id)),
        hasFetched: useSelector(widgetSelectors.getHasFetchedPerspective),
        lockedWidgets: useSelector(lockoutSelectors.getItems),
        onChange: id => dispatch(networkCreators.perspectiveChange(id)),
        changeLayouts: layouts => dispatch(widgetCreators.changePerspectiveLayouts(layouts)),
        onEditPerspective: () => dispatch(widgetCreators.startEditingPerspective()),
    }

    return (
        <>
            {isV2 ? (
                <Box sx={{ display: 'flex', flex: 1 }}>
                    <Dashboard id={id} />
                </Box>
            ) : perspectiveLayoutView ? (
                <ComparePerspectiveLayout config={perspectiveLayoutConfig} id={id} />
            ) : (
                <ComparePerspective key={id} {...props} />
            )}
            <PerspectiveInfoBar />
        </>
    )
}
