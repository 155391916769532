import { Widget } from 'genesis-suite/types/visualTypes'
import { visualService } from '../../lib/services'
import { Converted, PreMigration } from './migrationTypes'
import {
    boxPlotConverter,
    bulletConverter,
    comboConverter,
    visorFormConverter,
    heatmapConverter,
    mapConverter,
    packedBubbleConverter,
    pieConverter,
    sortedConverter,
    tableConverter,
    textConverter,
    webviewConverter,
    labelConverter,
} from './widgetConverters'

export default async function convertWidget(
    widget,
    appName,
    builderModuleId,
    resourceManager,
    formConfigs,
    dashboardIdByOldId
): Promise<Converted[]> {
    const visorId = widget.Id
    const base: Converted = { type: widget.Type, visorId, title: widget.Title, status: 'success' }
    let config: Widget

    const standardConvertProps = { config: widget, dashboardIdByOldId, resourceManager }

    try {
        switch (widget.Type) {
            case 'Chart':
                switch (widget.ChartConfig.ChartType) {
                    case 'Area':
                    case 'Bar':
                    case 'Column':
                    case 'Line':
                        config = await comboConverter(standardConvertProps)
                        break
                    case 'Funnel':
                    case 'Pyramid':
                        config = await sortedConverter(standardConvertProps)
                        break
                    case 'Pie':
                    case 'Doughnut':
                        config = await pieConverter(standardConvertProps)
                        break
                    case 'BoxPlot':
                        config = await boxPlotConverter(standardConvertProps)
                        break
                    default:
                        return [{ ...base, status: 'not-supported' }]
                }
                break

            case 'BubbleChart':
                config = await packedBubbleConverter(standardConvertProps)
                break
            case 'Bullet':
                config = await bulletConverter(standardConvertProps)
                break
            case 'Form':
                config = await visorFormConverter(widget, resourceManager, formConfigs)
                break
            case 'Heatmap':
                config = await heatmapConverter(standardConvertProps)
                break
            case 'Label':
                const { AlignTop, LabelSeries } = widget.LabelConfig
                const configs = await Promise.all<Widget[]>(
                    LabelSeries.map(async series => {
                        const config = await labelConverter(widget, series, resourceManager)
                        const body: Widget = { ...config, moduleId: builderModuleId, migratedVisorId: visorId }
                        return visualService.createWidget(appName, body)
                    })
                )

                return configs.map(w => ({ ...base, builderId: w.id, top: AlignTop }))

            case 'Map':
                config = await mapConverter(standardConvertProps)
                break
            case 'RichText':
                config = await textConverter(widget)
                break
            //add a new table converter
            case 'Table':
                config = await tableConverter(standardConvertProps)
                break
            case 'WebView':
                config = await webviewConverter(widget)
                break
            default:
                return [{ ...base, status: 'not-supported' }]
        }

        const body: Widget = { ...config, moduleId: builderModuleId, migratedVisorId: visorId }
        const builderWidget = await visualService.createWidget(appName, body)

        return [{ ...base, builderId: builderWidget.id }]
    } catch (err) {
        console.error(err)
        return [{ ...base, status: 'fail', failMessage: err.message }]
    }
}

/** Create list of support and unsupported widgets prior to running migration */
export function createPreMigration(visorConfigs) {
    const preMigration: PreMigration = { supported: [], unsupported: [] }

    function appendSupported(type) {
        const index = preMigration.supported.findIndex(s => s.type === type)
        if (index === -1) preMigration.supported.push({ type, count: 1 })
        else preMigration.supported[index].count += 1
    }

    function appendUnsupported(type) {
        const index = preMigration.unsupported.findIndex(s => s.type === type)
        if (index === -1) preMigration.unsupported.push({ type, count: 1 })
        else preMigration.unsupported[index].count += 1
    }

    visorConfigs.forEach(widget => {
        const { Type, ChartConfig } = widget
        switch (Type) {
            case 'Chart':
                switch (ChartConfig.ChartType) {
                    case 'Area':
                    case 'Bar':
                    case 'BoxPlot':
                    case 'Column':
                    case 'Doughnut':
                    case 'Funnel':
                    case 'Label':
                    case 'Line':
                    case 'Pie':
                    case 'Pyramid':
                        appendSupported(ChartConfig.ChartType)
                        break

                    default:
                        appendUnsupported(ChartConfig.ChartType)
                }
                break

            case 'BubbleChart':
            case 'Bullet':
            case 'Form':
            case 'Heatmap':
            case 'Label':
            case 'Map':
            case 'RichText':
            case 'Table':
            case 'WebView':
                appendSupported(Type)
                break

            default:
                appendUnsupported(Type)
        }
    })

    return preMigration
}
