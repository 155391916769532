import { useMemo } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { MemoizedTabPerspective } from './TabPerspective'

export default function TabAccordionPerspective({ id, perspectiveLayout, isEdit, perspectives, isCompare = false }) {
    const perspectiveName = useMemo(() => {
        return perspectives.find(item => item.Id == id).Title
    }, [id])
    return (
        <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMore />} id="panel2-header">
                <Typography variant="h6" style={{ fontWeight: '600' }}>
                    {perspectiveName}
                </Typography>
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    height: '500px',
                    overflowY: 'scroll',
                }}
                id={'PID1'}
            >
                <MemoizedTabPerspective
                    id={id}
                    perspectiveLayout={perspectiveLayout}
                    isEdit={isEdit}
                    isCompare={isCompare}
                />
            </AccordionDetails>
        </Accordion>
    )
}
