import { matchPath } from 'react-router-dom'
import { collaborationTypes } from '../types'
import { collaborationService } from '../../api'
import { routePaths } from '../../lib/routes'
import { widgetSelectors, filterSelectors, moduleSelectors, applicationSelectors } from '../../selectors'

const collaborationCollaborators = clients => {
    return {
        type: collaborationTypes.COLLABORATION_COLLABORATORS,
        payload: {
            clients,
        },
    }
}

const collaborationSendPerspective = toID => {
    return (dispatch, getState) => {
        const match = matchPath(routePaths.PERSPECTIVE, getState().router.location.pathname)

        if (!(match && match.params && match.params.perspectiveID)) return

        const { perspectiveID } = match.params
        const state = getState()
        const config = widgetSelectors.getWidgetConfig(state, perspectiveID)
        const context = filterSelectors.getCoord(state)
        const moduleCloud = moduleSelectors.getModuleCloud(state)
        const appName = applicationSelectors.getCurrentAppName(state)

        config.Context = context ? JSON.stringify(context) : null

        let message = {
            Props: {
                Config: encodeURIComponent(JSON.stringify(config)),
                Cloud: moduleCloud,
                Model: appName,
            },
            Type: 'Dynamic',
        }

        message = encodeURIComponent(JSON.stringify(message))

        collaborationService.sendMessage(toID, message)
    }
}

const collaborationRegistered = sessionName => {
    return {
        type: collaborationTypes.COLLABORATION_REGISTERED,
        payload: {
            sessionName,
        },
    }
}

const collaborationUnregistered = () => {
    return {
        type: collaborationTypes.COLLABORATION_UNREGISTERED,
    }
}

export const collaborationCreators = {
    collaborationCollaborators,
    collaborationSendPerspective,
    collaborationRegistered,
    collaborationUnregistered,
}
