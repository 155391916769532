import { Dispatch, SetStateAction, useState } from 'react'
import { ThemeMode } from '~/types/ThemeTypes'
import { BrowserPreferenceKey, BrowserPreferences } from '../types/BrowserPreferenceTypes'
import { logEvent } from './amplitudeClient'

const preferences = 'preferences'
const defaultPreferences: BrowserPreferences = {
    fontSize: 'small',
    expandFilterString: false,
    hideNodeMapGridLines: true,
    interactionMode: 'drilldown',
    layout: 'modern',
    networkPos: null,
    rightNav: { collapse: false },
    themeMode: ThemeMode.DARK,
    showNetworkFilterRules: true,
    mapTypePreferences: { type: 'Orthographic', rotation: [95, -35] },
}

function getPreferences(): BrowserPreferences {
    const raw = localStorage.getItem(preferences) || JSON.stringify(defaultPreferences)
    const prefs = JSON.parse(raw)
    return { ...defaultPreferences, ...prefs }
}

export function getPreference<K extends BrowserPreferenceKey>(key: K) {
    const sizeInBytes = encodeURI(JSON.stringify({ ...localStorage })).split(/%..|./).length - 1
    const kb = sizeInBytes / 1024
    const mb = kb / 1024
    const sizeStr = `${kb.toFixed(2)}kb (${mb.toFixed(2)}mb)`
    logEvent('getPreferences', { key, size: sizeStr })
    const preferences = getPreferences()
    return preferences[key]
}

export function setPreference<K extends BrowserPreferenceKey>(key: K, value: BrowserPreferences[K]) {
    const prefs = getPreferences()
    localStorage.setItem(preferences, JSON.stringify({ ...prefs, [key]: value }))
}

export function removePreference(key: BrowserPreferenceKey) {
    const prefs = getPreferences()
    const { [key]: value, ...rest } = prefs
    localStorage.setItem(preferences, JSON.stringify(rest))
}

export function useBrowserPreferences<K extends BrowserPreferenceKey>(key: K) {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(() => {
        try {
            return getPreference(key)
        } catch (error) {
            console.error(error)
            return undefined
        }
    })

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value: SetStateAction<BrowserPreferences[K]>) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore = value instanceof Function ? value(storedValue) : value
            // Save to local storage
            setPreference(key, valueToStore)
            // Save state
            setStoredValue(valueToStore)
        } catch (error) {
            console.error(error)
        }
    }

    const removeValue = () => {
        // Remove from local storage
        removePreference(key)
        // Remove from state
        setStoredValue(undefined)
    }

    return [storedValue, setValue, removeValue] as [
        BrowserPreferences[K],
        Dispatch<SetStateAction<BrowserPreferences[K]>>,
        () => void
    ]
}
