import { Basket, ChartType, LocationType, SeriesConfig, Widget } from 'genesis-suite/types/visualTypes'
import { letMeMap } from 'genesis-suite/types/utilTypes'

export default function validateConfig(config: Widget) {
    if (!config) return false

    switch (config.type) {
        case ChartType.FORM:
        case ChartType.TEXT:
        case ChartType.WEBVIEW:
        case ChartType.TREE:
        case ChartType.FLOWCHART:
            return true

        case ChartType.MAP: {
            if (!config.series?.length) return false

            for (const series of config.series) {
                switch (series.type) {
                    case 'marker':
                        const isGeo = !series.locationType || series.locationType === LocationType.GEO
                        if (isGeo) {
                            const hasLatitude = series.values.some(v => v.basket === Basket.LATITUDE)
                            const hasLongitude = series.values.some(v => v.basket === Basket.LONGITUDE)
                            if (!hasLatitude || !hasLongitude) return false
                        } else {
                            const hasLocation = series.values.some(v => v.basket === Basket.LOCATION)
                            if (!hasLocation) return false
                        }
                        break
                    case 'heat': {
                        const hasLocation = Boolean(series.subSeries)
                        if (!hasLocation) return false

                        break
                    }
                    case 'connection': {
                        const hasFromId = series.values.some(v => v.basket === Basket.ID)
                        const hasToId = series.values.some(v => v.basket === Basket.ID2)
                        if (!hasFromId || !hasToId) return false
                        break
                    }
                }
            }
            break
        }

        case ChartType.HEATMAP: {
            if (!hasCategories(config)) return false
            if (!allSeriesHaveValues(config.series)) return false
            if (!config.series[0].subSeries) return false
            break
        }

        // Require a category
        case ChartType.BUBBLE:
        case ChartType.COMBO:
        case ChartType.SORTED:
        case ChartType.PACKED_BUBBLE:
        case ChartType.PIE:
        case ChartType.BOX_PLOT: {
            if (!hasCategories(config)) return false
            if (!allSeriesHaveValues(config.series)) return false
            break
        }

        case ChartType.TABLE: {
            const categoriesValid = hasCategories(config)
            const seriesValid = allSeriesHaveValues(config.series)
            if (config.dynamic) return Boolean(seriesValid)
            else return Boolean(categoriesValid || seriesValid)
        }

        default: {
            if (!allSeriesHaveValues(config.series)) return false
        }
    }

    return true
}

const hasCategories = (config: SeriesConfig) => Boolean(config?.categories?.length)

const allSeriesHaveValues = (series: SeriesConfig['series']) =>
    series?.length && letMeMap(series).every(seriesHasValues)

const seriesHasValues = (aSeries: SeriesConfig['series'][0]) => aSeries?.values?.length > 0
