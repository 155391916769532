import { matchPath } from 'react-router-dom'
import { createSelector } from 'reselect'
import { routePaths } from '../lib/routes'

const getRouter = state => state.router

const getPendingRoute = state => state.navigation.pendingRoute
const getPendingRoutePath = state => state.navigation.pendingRoute.path
const getPendingRouteContext = state => state.navigation.pendingRoute.context

const getRoutePath = createSelector(getRouter, router => router?.location?.pathname ?? '')

const getCurrentBusinessElement = createSelector(getRouter, router => {
    const path = matchPath(routePaths.ELEMENT, router.location.pathname) || {}
    return path?.params?.elementName
})

const getCurrentWidgetId = createSelector(getRouter, router => {
    const path = matchPath(routePaths.WIDGET, router.location.pathname) || {}
    return path?.params?.widgetID
})

const getCurrentTreeName = createSelector(getRouter, router => {
    const path = matchPath(routePaths.TREE, router.location.pathname) || {}
    return path?.params?.treeName
})

export const navigationSelectors = {
    getRouter,
    getRoutePath,
    getPendingRoute,
    getPendingRoutePath,
    getPendingRouteContext,
    getCurrentBusinessElement,
    getCurrentWidgetId,
    getCurrentTreeName,
}
