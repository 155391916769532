import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Collapse,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Popover,
    TextField,
    Tooltip,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import HelpIcon from '@mui/icons-material/HelpOutline'

import { insightSelectors } from '../../selectors'
import { DashboardFilter, DashboardType } from './utils'

interface Props {
    filter: DashboardFilter
    open: boolean
    onCancel: () => void
    onNew: (dashboard: { title: string; focalPoint?: string }) => void
    onOpen: () => void
}

export default function NewDashboardButton({ filter, open, onCancel, onNew, onOpen }: Props) {
    const focalPoints = useSelector(insightSelectors.getFocalPoints)

    const buttonRef = useRef()

    const [focalPoint, setFocalPoint] = useState(null)
    const [title, setTitle] = useState('')
    const [isPerspective, setIsPerspective] = useState(false)

    const valid = title && (isPerspective ? Boolean(focalPoint) : true)

    useEffect(() => {
        if (!open) return

        const { type, focalPoint } = filter || {}
        setTitle('')
        setIsPerspective(Boolean(focalPoint) || type === DashboardType.ONLY_PERSPECTIVES)
        setFocalPoint(focalPoint ?? null)
    }, [open])

    function handleKeyDown(e) {
        if (e.key !== 'Enter') return

        e.preventDefault()
        handleNew()
    }

    function handleNew() {
        if (!valid) return

        onNew({ title, focalPoint: focalPoint || undefined })
    }

    return (
        <>
            <Button
                ref={buttonRef}
                variant="contained"
                startIcon={<AddIcon fontSize="small" />}
                color="secondary"
                onClick={onOpen}
            >
                Add
            </Button>

            <Popover
                anchorEl={buttonRef.current}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={open}
                onClose={onCancel}
            >
                <DialogTitle>New Collection</DialogTitle>

                <DialogContent onKeyDown={handleKeyDown}>
                    <TextField
                        placeholder="Name"
                        fullWidth
                        autoFocus
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                        sx={{
                            '& .MuiInput-root': {
                                '&:before': {
                                    borderColor: 'text.primary',
                                },
                            },
                        }}
                    />

                    <Box display="flex" alignItems="center" columnGap="8px">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isPerspective}
                                    sx={theme => ({ color: theme.palette.text.primary })}
                                    onChange={e => setIsPerspective(e.target.checked)}
                                />
                            }
                            label="Perspective"
                        />
                        <Tooltip
                            title={
                                <div>
                                    <p>
                                        Saving by default will create a collection. Collections contain widgets that
                                        include any business element and metric from the entire network
                                    </p>
                                    <p>
                                        Perspectives are a type of collection that centers around a specify business
                                        element (called the focal point) of the network. Widget navigation requires a
                                        perspective.
                                    </p>
                                </div>
                            }
                        >
                            <HelpIcon fontSize="small" />
                        </Tooltip>
                    </Box>
                    <Collapse mountOnEnter in={isPerspective}>
                        <Autocomplete
                            disableClearable
                            options={focalPoints}
                            value={focalPoint}
                            onChange={(e, newValue) => setFocalPoint(newValue)}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    size="small"
                                    margin="dense"
                                    variant="outlined"
                                    label="Focal point"
                                    sx={{
                                        '& .MuiFormLabel-root, .MuiAutocomplete-endAdornment svg': {
                                            color: 'text.primary',
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'text.primary',
                                        },
                                    }}
                                />
                            )}
                        />
                    </Collapse>

                    <Box mt={1} display="flex" justifyContent="flex-end"></Box>
                </DialogContent>

                <DialogActions>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button variant="contained" color="primary" disabled={!valid} onClick={handleNew}>
                        Create
                    </Button>
                </DialogActions>
            </Popover>
        </>
    )
}
