import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import CircleIcon from '@mui/icons-material/Circle'
import HelpIcon from '@mui/icons-material/HelpOutlineOutlined'
import { ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Tooltip, Typography, useTheme } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import classnames from 'classnames'
import { ComponentType, ReactNode, useState } from 'react'
import tinyColor from 'tinycolor2'

import { MenuIcon } from 'genesis-suite/components'
import { DeploymentView, DeploymentViewTooltip } from '../types/DeploymentTypes'

const styles = ({ spacing }) => ({
    deploymentViewSelectorBtnContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: spacing(1),
        width: 95,
        borderRadius: '60px',
    },
    deploymentViewSelectorDisabled: {
        opacity: 0.6,
    },
    deploymentViewSelectorBtn: {
        '& .MuiBadge-root': { alignSelf: 'stretch' },
        padding: 0,
        alignSelf: 'stretch',
    },
    deploymentViewSelectorBtnIcon: {
        fontSize: '0.8rem',
        marginRight: spacing(1),
    },
    deploymentViewList: {
        width: 210,
    },
    deploymentViewOptionIcon: { minWidth: '18px !important' },
})

export interface DeploymentViewSelectorOption {
    /**color used for Icon and highlighting during active state   */
    color: string
    /** disable option */
    disabled?: boolean
    /**icon to be displayed that represents the option; used by default to display the active option */
    Icon?: ComponentType<any>
    /*label for the option; used to represent the active option if Icon is not specified*/
    label?: string | ReactNode
    /**tooltip to be displayed when hovering over help icon  */
    tooltip?: DeploymentViewTooltip
    /**value for the option */
    value: DeploymentView
}

interface DeploymentViewSelectorProps extends WithStyles<typeof styles> {
    /**disables the popover */
    disabled: boolean
    /**props to be passed to the MenuIcon component */
    iconProps: unknown
    /**array of items for the dropdown */
    items: DeploymentViewSelectorOption[]
    /**callback when the active option changes */
    onChange: (value: DeploymentViewSelectorOption) => void
    /**tooltip value for the popover button */
    tooltip?: string
    /**option to set */
    value: DeploymentViewSelectorOption
}

const DeploymentViewSelector = ({
    items,
    value: activeOption,
    onChange,
    disabled,
    tooltip,
    iconProps,
    classes,
}: DeploymentViewSelectorProps) => {
    const [open, setOpen] = useState(false)
    const { palette } = useTheme()

    const activeIndex = items.findIndex(item => item.value === activeOption.value)

    const activeOptionColor =
        activeIndex > -1 ? tinyColor(items[activeIndex].color).lighten(35).toString() : palette.action.hover

    const activeOptionStyle = {
        backgroundColor: activeOptionColor,
        color: palette.getContrastText(activeOptionColor),
    }

    const activeIcon = (
        <Paper
            className={classnames({
                [classes.deploymentViewSelectorBtnContainer]: true,
                [classes.deploymentViewSelectorDisabled]: disabled,
            })}
            elevation={0}
            sx={iconProps}
        >
            <CircleIcon className={classes.deploymentViewSelectorBtnIcon} sx={{ color: activeOption.color }} />
            <Typography sx={{ fontWeight: 'bold', color: 'text.primary' }} variant="subtitle2">
                {activeOption.label}
            </Typography>
            <ArrowDropDown sx={{ color: 'text.primary' }} />
        </Paper>
    )

    const handleSelect = (value: DeploymentViewSelectorOption) => {
        setOpen(false)
        onChange(value)
    }

    const renderIcon = ({
        Icon,
        label,
        color,
    }: {
        Icon: ComponentType<any>
        label?: string | ReactNode
        color: string
    }) => {
        const DisplayIcon = <Icon sx={{ color, fontSize: 12 }} />
        return label ? (
            <ListItemIcon className={classes.deploymentViewOptionIcon}>{DisplayIcon}</ListItemIcon>
        ) : (
            DisplayIcon
        )
    }

    const renderItemTooltip = (title: string) => (
        <Tooltip placement="right-start" title={title}>
            <HelpIcon sx={{ fontSize: '1rem', ml: 1, color: 'text.primary' }} />
        </Tooltip>
    )

    return (
        <MenuIcon
            icon={activeIcon}
            open={open}
            disabled={disabled}
            tooltip={tooltip}
            onClick={() => setOpen(true)}
            onClose={() => setOpen(false)}
            noPadding
            buttonProps={{
                className: classnames({
                    [classes.deploymentViewSelectorBtn]: true,
                    [classes.deploymentViewSelectorDisabled]: disabled,
                }),
                ...(disabled && { pointerEvents: 'auto', cursor: 'not-allowed' }),
            }}
        >
            <MenuList className={classes.deploymentViewList}>
                {items.map((item, i) => (
                    <MenuItem
                        key={`${item.label}-${item.value}`}
                        onClick={() => {
                            handleSelect(item)
                        }}
                        disabled={item.disabled}
                    >
                        {item.Icon && renderIcon({ Icon: item.Icon, label: item.label, color: item.color })}
                        {item.label && (
                            <ListItemText
                                sx={{
                                    borderRadius: '8px',
                                    py: 0.3,
                                    px: 1.05,
                                    ...(i === activeIndex ? activeOptionStyle : {}),
                                }}
                            >
                                {item.label}
                            </ListItemText>
                        )}
                        {item.tooltip && renderItemTooltip(item.tooltip)}
                    </MenuItem>
                ))}
            </MenuList>
        </MenuIcon>
    )
}

export default withStyles(styles)(DeploymentViewSelector)
