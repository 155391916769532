import React from 'react'
import withStyles from '@mui/styles/withStyles';
import RemoveButton from './RemoveButton'
import { Typography } from '@mui/material';

const styles = theme => ({
    boldText: {
        fontWeight: 'bold',
    },
    removed: {
        fontStyle: 'italic',
        opacity: '.45',
    },
})

/**
 * Displays and handles removal of default appModule
 * @param {string} appModule - Name of the default appModule
 * @param {boolean} removed - Boolean representing if this item was removed
 * @param {function} onChange - Callback function when RemoveButton is clicked
 */
const DefaultModulePref = ({ classes, className, appModule, removed, onChange }) => (
    <div className={className}>
        <div>
            <Typography
                display="inline"
                variant="subtitle1"
                className={classes.boldText}
            >{`Default Module: `}</Typography>
            <Typography display="inline" className={removed ? classes.removed : ''}>
                {appModule || `N/A`}
            </Typography>
        </div>
        {appModule && <RemoveButton removed={removed} onClick={onChange} />}
    </div>
)

export default withStyles(styles)(DefaultModulePref)
