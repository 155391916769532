import { Lockout, User } from 'genesis-suite/types/visualTypes'

import { applicationSelectors, authSelectors, moduleSelectors } from '../../selectors'
import { lockoutTypes } from '../types'

let socket: WebSocket

type LockoutMessage = AddLockout | RemoveLockout | SetLockouts | SetOptions
interface AddLockout {
    type: 'add-lockout'
    data: Lockout
}
interface RemoveLockout {
    type: 'remove-lockout'
    /** Widget id */
    data: string
}
interface SetLockouts {
    type: 'set-lockouts'
    data: Lockout[]
}
interface SetOptions {
    type: 'set-options'
    data: ClientOptions
}

interface ClientOptions {
    userId: string
    /** current app the client is in */
    appName: string
    /** (optional) current module id the client is in */
    moduleId?: string
}

const isDev = process.env.NODE_ENV === 'development'
const wsUrl = window.TADA_APIS.VISUALS?.replace(/^https?:\/\//i, `ws${isDev ? '' : 's'}://`)

const connect = () => dispatch => {
    if (!isClosed()) return console.error('Lockout websocket is already opened')

    socket = new WebSocket(wsUrl)

    socket.onopen = event => {
        dispatch({ type: lockoutTypes.CONNECT })
    }

    socket.onmessage = event => {
        const { type, data } = JSON.parse(event.data) as LockoutMessage
        switch (type) {
            case 'add-lockout':
                dispatch({ type: lockoutTypes.ADD, payload: data })
                break
            case 'remove-lockout':
                dispatch({ type: lockoutTypes.REMOVE, payload: data })
                break
            case 'set-lockouts':
                dispatch({ type: lockoutTypes.SET, payload: data })
                break
            default:
                console.error('Unknown lockout message type', type)
        }
    }

    socket.onclose = event => {
        dispatch({ type: lockoutTypes.STOP })
    }
}

const lock = (widgetId: string) => (dispatch, getState) => {
    if (!isOpen()) return console.error('Lockout websocket is not open')

    const state = getState()
    const appName = applicationSelectors.getCurrentAppName(state)
    const isV2 = moduleSelectors.getIsV2(state)
    const moduleId = isV2 ? moduleSelectors.getModuleId(state) : undefined
    const { firstName, lastName, userId } = authSelectors.getUser(state)
    const user: User = { id: userId, firstName, lastName }

    sendMessage({ type: 'add-lockout', data: { appName, moduleId, user, widgetId } })
}

const setOptions = (data: ClientOptions) => () => {
    if (!isOpen()) return console.error('Lockout websocket is not open')

    sendMessage({ type: 'set-options', data })
}

const unlock = (widgetId: string) => () => {
    sendMessage({ type: 'remove-lockout', data: widgetId })
}

function sendMessage(message: LockoutMessage) {
    socket.send(JSON.stringify(message))
}

const isOpen = () => socket?.readyState === WebSocket.OPEN
const isClosed = () => !socket || socket.readyState === WebSocket.CLOSED
const disconnect = () => socket?.close()

export const lockoutCreators = {
    connect,
    disconnect,
    lock,
    setOptions,
    unlock,
}
