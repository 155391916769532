import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from '@mui/material';

const error = {
    variant: 'error',
    autoHideDuration: 5000,
    action: <IconButton size="large"><CloseIcon fontSize="small" htmlColor="#fff" /></IconButton>
}

const success = {
    variant: 'success',
    autoHideDuration: 5000,
    action: <IconButton size="large"><CloseIcon fontSize="small" htmlColor="#fff" /></IconButton>
}

export const snackbarConfigs = {
    error,
    success
}