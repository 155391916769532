import Store from '../store/storeTypes'
import { createSelector } from 'reselect'

import { DraftKey } from 'genesis-suite/types/visualTypes'
import { DeploymentView } from '../types/DeploymentTypes'

const getDeploymentView = (store: Store) => store.deployment.mode.view

const getDeploymentAccessKey = (store: Store) => store.deployment.mode.accessKey

const getDeploymentAppName = (store: Store) => store.deployment.appName

const getDeploymentMode = createSelector([getDeploymentView, getDeploymentAccessKey], (view, accessKey) => {
    return { view, accessKey }
})

const getDeploymentViewFlag = createSelector([getDeploymentView], (deploymentView: DeploymentView): DraftKey => {
    let viewFlag
    switch (deploymentView) {
        case DeploymentView.DRAFT:
            viewFlag = 'prod'
            break
        case DeploymentView.STAGING:
            viewFlag = 'staging'
            break
    }
    return viewFlag
})

export const deploymentSelectors = {
    getDeploymentAccessKey,
    getDeploymentAppName,
    getDeploymentMode,
    getDeploymentView,
    getDeploymentViewFlag,
}
