import WidgetIcon from '@mui/icons-material/BarChart'
import CloseIcon from '@mui/icons-material/Close'
import CopyIcon from '@mui/icons-material/FileCopyOutlined'
import OpenIcon from '@mui/icons-material/Launch'
import MoreIcon from '@mui/icons-material/MoreVert'
import DashboardIcon from '@mui/icons-material/StopOutlined'
import { Box, Button, IconButton, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { capitalize } from 'lodash'
import { useContext, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { useDispatch, useSelector } from 'react-redux'
import useSWR from 'swr'

import { EditableLabel, MenuIcon } from 'genesis-suite/components'
import { navigationCreators } from '../../actions/creators'
import { getInfoPanelDateProps } from '../../lib/manageUtils'
import { routePaths } from '../../lib/routes'
import { visualService } from '../../lib/services'
import { applicationSelectors, deploymentSelectors } from '../../selectors'
import { ModuleViewsContext } from './ModuleViewsContext'
import { DraftView, DragItem, MoveDragItem } from './ViewTypes'

const useStyles = makeStyles(({ spacing }) => ({
    viewItem: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '2px',
        paddingRight: (p: any) => (p.isInGroup ? '' : '2px'),
        height: '28px', // match ViewGroup header height
        cursor: (p: any) => (p.isPowerUser ? 'grab' : ''),
        opacity: (p: any) => (p.isDragging ? 0 : 1),
    },
}))

export interface Props {
    view: DraftView
    isInGroup?: boolean
    /** if view is inside group, number of views down before moving group */
    groupDownSteps?: number
}

export default function ViewItem({ view, isInGroup, groupDownSteps }: Props) {
    const dispatch = useDispatch()

    const { isPowerUser, onMoveItem, onFindIndexes, onCopyToUsersViews, onUpdateView, onRemoveView } =
        useContext(ModuleViewsContext)

    const originalIndexes = onFindIndexes(view.dragId)
    const [{ isDragging }, drag] = useDrag<MoveDragItem, MoveDragItem, { isDragging: boolean }>({
        type: view.type,
        item: { type: view.type, id: view.id, status: 'existing', dragId: view.dragId, originalIndexes },
        isDragging: monitor => view.dragId === monitor.getItem().dragId,
        collect: monitor => ({ isDragging: monitor.isDragging() }),
        end: (dropResult, monitor) => {
            if (!dropResult) return
            const { originalIndexes } = dropResult
            const didDrop = monitor.didDrop()

            // move item back if off target
            if (!didDrop) onMoveItem(dropResult, originalIndexes)
        },
    })

    const [_, drop] = useDrop({
        accept: ['dashboard', 'group', 'widget'],
        hover: (item: DragItem) => {
            const indexes = onFindIndexes(view.dragId)
            const itemIndexes = onFindIndexes(item.dragId)

            // move view or group over root view
            if (item.type !== 'group' || indexes.length === 1) onMoveItem(item, indexes)
            // move group down
            else if (indexes[0] === itemIndexes[0] && groupDownSteps && indexes[1] + 1 >= groupDownSteps)
                onMoveItem(item, [indexes[0] + 1])
        },
    })

    const [openMenu, setOpenMenu] = useState(false)
    const DragIcon = view.type === 'dashboard' ? DashboardIcon : WidgetIcon
    const classes = useStyles({ isPowerUser, isDragging, isInGroup })

    function handleOpen() {
        dispatch(navigationCreators.goTo(routePaths[view.type === 'dashboard' ? 'PERSPECTIVE' : 'WIDGET'], view.id))
    }

    function handleCopy() {
        onCopyToUsersViews(view.id, true)
        setOpenMenu(false)
    }

    function handleRemove() {
        onRemoveView(view.dragId)
        setOpenMenu(false)
    }

    return (
        <div ref={node => (isPowerUser ? drag(drop(node)) : undefined)} className={classes.viewItem}>
            {isPowerUser && <DragIcon sx={{ mr: 0.5 }} />}

            <EditableLabel
                editMethod="click-text"
                hideFinishButtons
                value={view.title}
                onChange={title => onUpdateView(view.dragId, { title })}
            />
            <Box flex={1} />

            <MenuIcon
                icon={<MoreIcon fontSize="small" />}
                buttonProps={{ size: 'small' }}
                popoverProps={{
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    transformOrigin: { vertical: 'top', horizontal: 'left' },
                }}
                open={openMenu}
                onClick={() => setOpenMenu(true)}
                onClose={() => setOpenMenu(false)}
            >
                {view.type === 'dashboard' ? (
                    <DashboardInfoPanel id={view.id} onOpen={handleOpen} onCopy={handleCopy} />
                ) : (
                    <WidgetInfoPanel id={view.id} onOpen={handleOpen} onCopy={handleCopy} />
                )}
            </MenuIcon>
            <IconButton size="small" onClick={handleRemove}>
                <CloseIcon fontSize="small" />
            </IconButton>
        </div>
    )
}

interface InfoPanelProps {
    id: string
    onOpen: () => void
    onCopy: () => void
}

function DashboardInfoPanel({ id, onOpen, onCopy }: InfoPanelProps) {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const viewFlag = useSelector(deploymentSelectors.getDeploymentViewFlag)
    const { data } = useSWR(['visualService.getDashboardById', appName, id], ([_, appName, id]) =>
        visualService.getDashboardById(appName, id, false, viewFlag)
    )

    if (!data) return null

    const { created, updated } = getInfoPanelDateProps(data)
    const { moduleId, updatedBy, title, focalPoint } = data

    return (
        <div>
            <Typography variant="h6">Details</Typography>

            <Box display="flex" gap="16px">
                <Button size="small" startIcon={<OpenIcon color="primary" fontSize="small" />} onClick={onOpen}>
                    Open
                </Button>
                <Button size="small" startIcon={<CopyIcon color="primary" fontSize="small" />} onClick={onCopy}>
                    Copy
                </Button>
            </Box>

            <InfoItem label="Title" value={title} />
            <InfoItem label="Created" value={created} />
            {updatedBy && <InfoItem label="Updated" value={updated} />}
            <InfoItem label="Scope" value={moduleId ? 'Module' : 'Global'} />
            <InfoItem label="Type" value={focalPoint ? `Perspective (focal point: "${focalPoint}")` : 'Collection'} />
        </div>
    )
}

function WidgetInfoPanel({ id, onOpen, onCopy }: InfoPanelProps) {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const viewFlag = useSelector(deploymentSelectors.getDeploymentViewFlag)
    const { data } = useSWR(['visualService.getWidgetById', appName, id], ([_, appName, id]) =>
        visualService.getWidgetById(appName, id, viewFlag)
    )

    if (!data) return null

    const { created, updated } = getInfoPanelDateProps(data)
    const { moduleId, updatedBy, title, type } = data

    return (
        <div>
            <Typography variant="h6">Details</Typography>

            <Box display="flex" gap="16px">
                <Button size="small" startIcon={<OpenIcon color="primary" fontSize="small" />} onClick={onOpen}>
                    Open
                </Button>
                <Button size="small" startIcon={<CopyIcon color="primary" fontSize="small" />} onClick={onCopy}>
                    Copy
                </Button>
            </Box>

            <InfoItem label="Title" value={title} />
            <InfoItem label="Created" value={created} />
            {updatedBy && <InfoItem label="Updated" value={updated} />}
            <InfoItem label="Scope" value={moduleId ? 'Module' : 'Global'} />
            <InfoItem label="Type" value={capitalize(type)} />
        </div>
    )
}

function InfoItem({ label, value }) {
    return (
        <Box display="flex" alignItems="center" gap="8px">
            <Typography variant="caption">{label}:</Typography>
            <Typography>{value}</Typography>
        </Box>
    )
}
