import React, { FC, ReactNode, useState } from 'react'
import classnames from 'classnames'
import withStyles, { WithStyles } from '@mui/styles/withStyles'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { Typography, Tooltip, IconButton, Collapse, SvgIconProps } from '@mui/material'

const styles = ({ palette, spacing, custom }: any) => ({
    container: { width: '100%', padding: spacing(0.5, 1) },
    header: {
        height: '35px',
        whiteSpace: 'nowrap',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: spacing(0.25, 0),
    },
    headerUnderline: {
        borderBottom: `1px solid ${custom.stroke || palette.grayscale.light}`,
    },
    textWrapper: { width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' },
    text: { fontWeight: 700, paddingLeft: '5px' },
    clickable: {
        '&:hover': { cursor: 'pointer', backgroundColor: palette.action.hover },
    },
    title: { fontSize: '0.9rem' },
})

/**
 * Main docking items in collapsible containers like SideNav. Adjust how items are displayed based on if container is open or closed
 * @param {object} props React props
 * @param {string=} props.className optional. class name for the container
 * @param {boolean} props.collapsed menu item should collapse
 * @param {title=} props.title optional. title
 * @param {react component} props.Icon Icon next to the title
 * @param {react components=} props.children optional. Reaction children components
 * @param {function} props.onClick optional. If you want to add a click callback to the container
 * @param {boolean} props.noUnderline optional. Remove underline under header
 * @param {component=} props.RightIcon optional. icon to right of text
 * @param {(event => void)=} props.onRightIconClick optional. callback on clicking right icon
 */

interface DockingMenuItemProps extends WithStyles<typeof styles> {
    className?: string
    collapsed: boolean
    title?: string
    Icon?: any
    children?: ReactNode
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    noUnderline?: boolean
    RightIcon?: FC<SvgIconProps>
    onRightIconClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    collapsable?: boolean
    startCollapsed?: boolean
    dataTour?: string
    expandIcon?: ReactNode
    collapseIcon?: ReactNode
}

const DockingMenuItem: FC<DockingMenuItemProps> = ({
    classes,
    className,
    collapsed,
    title,
    Icon,
    children,
    onClick,
    noUnderline,
    RightIcon,
    onRightIconClick,
    collapsable,
    startCollapsed,
    dataTour,
    expandIcon = <ArrowDropDown fontSize="small" />,
    collapseIcon = <ArrowDropUp fontSize="small" />,
}) => {
    const [open, setOpen] = useState(!startCollapsed)
    const containerClass = classnames(classes.container, {
        [classes.headerUnderline]: !noUnderline,
        [classes.clickable]: !!onClick,
        [className]: !!className,
    })

    const handleHeaderClick = e => {
        if (onClick) onClick(e)
    }

    const toggleOpen = () => setOpen(!open)

    return (
        <div data-tour={dataTour} className={containerClass} onClick={handleHeaderClick}>
            <div className={classes.header}>
                {Icon && (
                    <Tooltip disableHoverListener={!collapsed} title={title || ''} placement="right">
                        <Icon />
                    </Tooltip>
                )}

                {!collapsed && (
                    <div className={classes.textWrapper}>
                        <Typography classes={{ root: classes.text, body1: classes.title }} variant="body1">
                            {title}
                        </Typography>
                        <div>
                            {RightIcon && (
                                <IconButton size="small" onClick={onRightIconClick}>
                                    <RightIcon />
                                </IconButton>
                            )}
                            {collapsable && (
                                <IconButton sx={{ color: 'text.primary' }} size="small" onClick={toggleOpen}>
                                    {open ? (
                                        <Tooltip enterDelay={300} title="Collapse">
                                            {(collapseIcon && React.isValidElement(collapseIcon)) ? collapseIcon : <React.Fragment>{collapseIcon}</React.Fragment>}
                                        </Tooltip>
                                    ) : (
                                        <Tooltip enterDelay={300} title="Expand">
                                            {(expandIcon && React.isValidElement(expandIcon)) ? expandIcon : <React.Fragment>{expandIcon}</React.Fragment>}
                                        </Tooltip>
                                    )}
                                </IconButton>
                            )}
                        </div>
                    </div>
                )}
            </div>
            {collapsable && !collapsed ? <Collapse in={open}>{children}</Collapse> : children}
        </div>
    )
}

export default withStyles(styles)(DockingMenuItem)
