import React from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { Property } from 'genesis-suite/types/networkTypes'
import { Checkbox, FormControlLabel, TextField } from '@mui/material'

import { canUseAutogenPrefix } from '../../edit_widget/utils'

type autogenValue = {
    autogenerate?: boolean
    autogeneratePrefix?: string
}

interface AutogenProps {
    property: Property
    autogenerate: boolean
    autogeneratePrefix: string
    onChange: (value: autogenValue) => void
    className?: string
}

export function AutogenerateBuilder({ className, property, autogenerate, autogeneratePrefix, onChange }: AutogenProps) {
    const handleTextChange = useDebouncedCallback((autogeneratePrefix: string) => {
        onChange({ autogeneratePrefix })
    }, 200)

    if (canUseAutogenPrefix(property))
        return (
            <TextField
                className={className}
                label="Auto Generate Prefix"
                defaultValue={autogeneratePrefix}
                onChange={e => handleTextChange(e.target.value)}
            />
        )
    else
        return (
            <FormControlLabel
                className={className}
                control={
                    <Checkbox
                        name="autogenerate"
                        checked={autogenerate}
                        onChange={(e, checked) => onChange({ autogenerate: checked })}
                    />
                }
                label="Auto Generate"
            />
        )
}
