import makeStyles from '@mui/styles/makeStyles'

import WidgetInfoEmptyText from './WidgetInfoEmptyText'

const useStyles = makeStyles(({ palette }) => ({
    widget: { fontWeight: 400, color: 'grey' },
}))

export default function WidgetInfoWidgetFilters({ config, charLimit }) {
    const isV2 = config?.version === '2'
    const classes = useStyles()

    if (isV2) return null

    const widgetFilters = config.WidgetInfo.Filters.replace(/(<([^>]+)>)/gi, '')

    return (
        <div>
            {widgetFilters.length > 0 ? (
                !charLimit || widgetFilters.length <= charLimit ? (
                    <span className={classes.widget}>{widgetFilters}</span>
                ) : (
                    <span className={classes.widget}>
                        {widgetFilters.substring(0, charLimit) + ' ... '}
                        <b>and more</b>
                    </span>
                )
            ) : (
                <WidgetInfoEmptyText>No widget filter(s) applied</WidgetInfoEmptyText>
            )}
        </div>
    )
}
