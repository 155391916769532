import React from 'react'
import { Box, List, ListItem, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch } from 'react-redux'

import { ResourceType } from 'genesis-suite/types/networkTypes'
import { ChopText } from 'genesis-suite/components'
import { businessExplorerCreators } from '../../actions/creators'

const useStyles = makeStyles(({ spacing }) => ({
    title: { padding: spacing(1, 1, 0) },
    item: { padding: spacing(0.5, 0, 0.5, 1) },
}))

interface Props {
    insights: any[]
    selectedInsightId: string
}

export default function BusinessExplorerInsights({ insights, selectedInsightId }: Props) {
    const dispatch = useDispatch()

    const classes = useStyles()

    return (
        <Box display="flex" flexDirection="column" overflow="hidden" borderRight="1px solid #bdbdbd">
            <Typography className={classes.title} variant="h6">
                Insights
            </Typography>

            <Box overflow="auto">
                <List>
                    {insights.map(({ id, name }) => (
                        <ListItem
                            key={name}
                            button
                            className={classes.item}
                            selected={id === selectedInsightId}
                            onClick={() =>
                                dispatch(businessExplorerCreators.setService({ type: ResourceType.INSIGHT, id, name }))
                            }
                        >
                            <ChopText showEllipsis>{name}</ChopText>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Box>
    )
}
