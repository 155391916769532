import { Box, MenuItem, TextField, Typography } from '@mui/material'
import { forwardRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ChopText } from 'genesis-suite/components'
import { ModuleSelectorWidgetConfig } from 'genesis-suite/types/visualTypes'
import { navigationCreators } from '~/actions/creators'
import { logEvent } from '~/lib/amplitudeClient'
import { moduleSelectors } from '~/selectors'
import ModuleSelector from './ModuleSelector'

const moduleGroupSelColor = 'text.primary'
const moduleGroupSelStyles = {
    '& .MuiInputLabel-root': { color: moduleGroupSelColor },
    '& .MuiSvgIcon-root': { color: moduleGroupSelColor },
    '& .MuiInput-root:before': { borderColor: moduleGroupSelColor },
}

export default forwardRef<HTMLDivElement, { config: ModuleSelectorWidgetConfig }>(({ config }, ref) => {
    const modules = useSelector(moduleSelectors.getActiveModules)
    const dispatch = useDispatch()
    const [module, setModule] = useState('AllModules')
    const moduleGroups = modules?.find(m => m.modules?.length > 0)

    if (!modules || modules.length < 2) return null

    return (
        <Box
            ref={ref}
            sx={{
                backgroundColor: 'background.main',
                border: 1,
                borderColor: 'border.main',
                borderRadius: '15px',
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                height: '100%',
                p: 1,
            }}
        >
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <Typography variant="h5" fontWeight="bold">
                    Applications
                </Typography>
                {moduleGroups && (
                    <TextField
                        sx={moduleGroupSelStyles}
                        select
                        margin="none"
                        name="modules"
                        value={module ?? ''}
                        onChange={e => {
                            setModule(e.target.value)
                            logEvent('MODULE_SELECTED', { origin: 'Welcome View' })
                        }}
                    >
                        <MenuItem key="AllModules" value="AllModules">
                            All Modules
                        </MenuItem>
                        {Object.entries(
                            Object.fromEntries(
                                modules
                                    .filter(m => m.version === '1')
                                    .map(module => module.modules)
                                    .flat()
                                    .map(n => [n.Id, n.Name])
                            )
                        )
                            .map(([Id, Name]) => ({ Id, Name }))
                            .sort((a, b) => a.Name.localeCompare(b.Name))
                            .map(m => (
                                <MenuItem key={m.Id} value={m.Id}>
                                    <ChopText showEllipsis>{m.Name}</ChopText>
                                </MenuItem>
                            ))}
                    </TextField>
                )}
            </Box>
            <Box overflow="auto">
                <ModuleSelector
                    brickHeight={65}
                    onSelect={name => dispatch(navigationCreators.goToModule(name))}
                    module={module}
                />
            </Box>
        </Box>
    )
})
