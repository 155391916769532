import { BaseFormConfig, ChartType, FormType, InputFormConfig, KanbanFormConfig, TableFormConfig } from "genesis-suite/types/visualTypes";

const base: BaseFormConfig = {
    version: '2',
    formType: FormType.TABLE,
    type: ChartType.FORM,
    title: 'Untitled',
    palette: {
        background: '#FFFFFF',
        fontColor: '#000000',
    },
    loadInitialData: true,
    source: null,
    insight: null
}

const table: TableFormConfig = {
    ...base,
    formType: FormType.TABLE,
    columns: [],
    exportFileName: '',
    canAddNew: true,
    canDelete: true,
    canCellEdit: true,
    canInputEdit: true,
    canUploadExcel: true,
}

const input: InputFormConfig = {
    ...base,
    formType: FormType.INPUT,
    sectionOrientation: 'vertical',
    sections: [],
}

const kanban: KanbanFormConfig = {
    ...base,
    formType: FormType.KANBAN,
    canAddNew: false,
    canDelete: false,
    cardContent: '',
    sections: [],
}

export const defaultConfigs = {
    table,
    input,
    kanban
}