import { useDispatch } from 'react-redux'
import { IconButton, Tooltip } from '@mui/material'
import { FactCheckRounded } from '@mui/icons-material'

import { routePaths } from '../lib/routes'
import { navigationCreators } from '../actions/creators'

export default function ManageButton({ iconStyle, buttonStyle }) {
    const dispatch = useDispatch()

    return (
        <IconButton
            sx={buttonStyle}
            aria-label="manage"
            onClick={() => dispatch(navigationCreators.goTo(routePaths.MANAGE))}
        >
            <Tooltip title="Manage">
                <FactCheckRounded sx={iconStyle} />
            </Tooltip>
        </IconButton>
    )
}
