import { Box, Typography } from '@mui/material'

import { DraftAction, VisualType, Widget } from 'genesis-suite/types/visualTypes'
import WidgetCompare from '../ManageWidgets/WidgetCompare'
import DisplayDiff from '../widgets2/DisplayDiff'
import useLiveWithDraft from '../widgets2/utils/useLiveWithDraft'

interface Props {
    config: Widget
}

export default function ApprovalWidget({ config }: Props) {
    const { isNew, liveConfig } = useLiveWithDraft(VisualType.WIDGET, config)

    if (isNew)
        return (
            <Box flex={1} display="flex" p={1}>
                <WidgetCompare after={{ config, label: 'New draft' }} />
            </Box>
        )

    return (
        <Box flex={1} pb={1} pr={1} display="grid" gridTemplateColumns="350px auto" gap={1} overflow="hidden">
            <Box display="flex" flexDirection="column" overflow="hidden" borderRight={1} borderColor="divider">
                <Typography variant="h5" gutterBottom sx={{ ml: 1 }}>
                    {config.draft.action === DraftAction.ADDED ? 'New Widget' : 'Updated Widget'}
                </Typography>
                <DisplayDiff before={liveConfig} after={config} />
            </Box>

            <WidgetCompare after={{ config, label: 'Draft' }} before={{ config: liveConfig, label: 'Live' }} />
        </Box>
    )
}
