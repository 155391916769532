import { useContext, useEffect, useState } from 'react'
import LoadingIcon from '@mui/icons-material/Autorenew'
import DoneIcon from '@mui/icons-material/Done'

import { PerspectiveWidgetContext } from '../contexts/PerspectiveWidgetContext'

type LoadingState = 'idle' | 'loading' | 'done'

export default function WidgetLoadingIcon() {
    const { loading } = useContext(PerspectiveWidgetContext)
    const [state, setState] = useState<LoadingState>('idle')

    useEffect(() => {
        if (loading) setState('loading')
        else if (state !== 'idle') {
            setState('done')
            setTimeout(() => setState('idle'), 1000)
        }
    }, [loading])

    if (state === 'idle') return null

    return state === 'loading' ? (
        <LoadingIcon fontSize="small" color="warning" />
    ) : (
        <DoneIcon fontSize="small" color="success" />
    )
}
