import React, { useState } from 'react'
import { Tab, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { TabContext, TabList, TabPanel } from '@mui/lab'

import { Property } from 'genesis-suite/types/networkTypes'
import { FormColumn } from 'genesis-suite/types/visualTypes'
import ColumnBuilder from '../builders/ColumnBuilder'
import CustomRenderBuilder from '../builders/CustomRenderBuilder'
import CondFormatBuilder from '../builders/cond_format/CondFormatBuilder'

const useStyles = makeStyles(({ palette, spacing }) => ({
    contentTab: {
        flex: '1 1 auto',
        minWidth: 'unset',
        fontSize: '.75rem',
        borderBottom: `1px solid ${palette.grayscale.light}`,
    },
    innerPanel: { height: 'calc(100% - 48px)', position: 'relative' },
    fieldsContainer: {
        width: '100%',
        display: 'flex',
        flexFlow: 'column',
        padding: spacing(1),
        '&>*': { margin: spacing(0.5) },
    },
}))

interface ColumnEditorTabsProps {
    column: FormColumn
    properties: Property[]
    onChange: (column: FormColumn) => void
}

export default function ColumnEditorTabs({ column, onChange, ...rest }: ColumnEditorTabsProps) {
    const classes = useStyles({})
    const [value, setValue] = useState('general')

    if (!column) return null
    const { conditionalFormatting: cfArray, customEditor, useTadaValues } = column

    const handleChange = (change: Partial<FormColumn>) => onChange({ ...column, ...change })

    const handleCFAdd = (emptyObj: any) => {
        handleChange({ conditionalFormatting: [...cfArray, emptyObj] })
    }

    const handleCFDelete = (key: string) => {
        handleChange({ conditionalFormatting: cfArray.filter(cf => cf.key !== key) })
    }

    const tabs = [
        {
            label: 'General',
            value: 'general',
            render: <ColumnBuilder {...rest} className={classes.fieldsContainer} column={column} onChange={onChange} />,
        },
        {
            label: 'Conditional Formatting',
            value: 'condFormat',
            render: (
                <CondFormatBuilder
                    {...rest}
                    onAdd={handleCFAdd}
                    onDelete={handleCFDelete}
                    conditionalFormats={cfArray}
                    onChange={cf => handleChange({ conditionalFormatting: cf })}
                />
            ),
        },
        {
            label: 'Custom Editor',
            value: 'custom',
            render: (
                <CustomRenderBuilder
                    config={customEditor}
                    onChange={customEditor => handleChange({ customEditor })}
                    disableMessage={useTadaValues ? 'Turn off "Use Tada Values" to add custom editor' : undefined}
                />
            ),
        },
    ]

    return (
        <TabContext value={value}>
            <TabList onChange={(e, value) => setValue(value)}>
                {tabs.map(({ label, value }) => (
                    <Tab
                        key={value}
                        value={value}
                        className={classes.contentTab}
                        label={
                            <Tooltip title={label}>
                                <span>{label}</span>
                            </Tooltip>
                        }
                    />
                ))}
            </TabList>
            {tabs.map(({ value, render }) => (
                <TabPanel key={value} className={classes.innerPanel} value={value}>
                    {render}
                </TabPanel>
            ))}
        </TabContext>
    )
}
