import { Box, Button, IconButton, Paper, Tooltip, useTheme } from '@mui/material'
import { ResourceType } from 'genesis-suite/types/networkTypes'
import useResourceMeta from '~/hooks/useResourceMeta'
import AsyncResourceValueSelect from './AsyncResourceValueSelect'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { widgetSelectors } from '~/selectors'
import ProfileButton from './profile/ProfileButton'
import { logEvent } from '~/lib/amplitudeClient'
import ForwardRoundedIcon from '@mui/icons-material/ForwardRounded'
import { navigationCreators } from '~/actions/creators'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import DoneRoundedIcon from '@mui/icons-material/DoneRounded'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(({ palette, breakpoints }) => ({
    buttons: {
        height: 24,
        width: 24,
        margin: '2px',
        [breakpoints.down('sm')]: {
            width: '200px',
        },
    },
    buttonsContainer: {
        display: 'flex',
        gap: 2,
        justifyContent: 'center',
        position: 'fixed',
        bottom: 0,
        paddingBottom: 1,
        width: '100%',
        backgroundColor: palette.background.default,
        zIndex: 1,
    },
}))

export default function BusinessElementDropDown({ elementName, selected, onApply, onCancel }) {
    const [node] = useResourceMeta(ResourceType.NODE, elementName)
    const { values = [] } = selected
    const dispatch = useDispatch()
    const [selectedValues, setSelectedValues] = useState(values)
    const onValuesChange = values => {
        setSelectedValues(values)
    }
    const classes = useStyles()

    if (!node) return null
    const { name, type } = node
    const displayProperty = node?.properties?.find(p => p.name === `${name}Name`)
    const primaryProperty = node?.properties?.find(p => p.isPrimary)
    const keyPropertyName = primaryProperty.name

    const filterConfig = {
        Name: name,
        Source: { ElementName: name },
        Title: name,
        KeyPropertyName: keyPropertyName,
        IsTemporal: false,
        PostCalculation: false,
    }

    const OptionActions = ({ value }) => {
        //@ts-ignore
        const defaultPerspective = useSelector(s => widgetSelectors.getDefaultPerspectiveForNode(s, node?.name))

        const openPerspective = value => {
            const crumb = {
                DefaultPerspective: defaultPerspective,
                FieldName: keyPropertyName,
                Name: node.name,
                Value: value,
            }
            dispatch(navigationCreators.goToPerspective(defaultPerspective, { context: crumb }))
        }

        return (
            <Box display="flex" alignItems="center" position="absolute" right="0">
                {defaultPerspective && (
                    <Tooltip title="Go to perspective">
                        <IconButton
                            size="small"
                            onClick={e => {
                                e.stopPropagation()
                                openPerspective(value)
                            }}
                        >
                            <ForwardRoundedIcon fontSize="small" sx={{ color: 'text.primary' }} />
                        </IconButton>
                    </Tooltip>
                )}
                <ProfileButton
                    nodeName={node.name}
                    fieldName={primaryProperty.name}
                    value={value}
                    onClick={e => {
                        e.stopPropagation()
                        logEvent('OPEN_PROFILE', { method: 'search' })
                    }}
                />
            </Box>
        )
    }

    const Actions = () => {
        return (
            <Box className={classes.buttonsContainer}>
                <Button
                    key="apply-button"
                    variant="contained"
                    sx={{
                        backgroundColor: '#FDBD01',
                        color: '#4E4E4E',
                        '&:hover': { backgroundColor: '#24292E', color: '#FDBD01 !important' },
                    }}
                    onClick={() => onApply(name, selectedValues, filterConfig)}
                >
                    Apply
                </Button>
                <Button
                    key="cancel-button"
                    variant="outlined"
                    sx={{
                        color: '#FDBD01',
                        borderColor: '#FDBD01',
                        '&:hover': { borderColor: '#FFF', color: '#FFF !important' },
                    }}
                    onClick={() => onCancel()}
                >
                    Cancel
                </Button>
                {/* <Tooltip title="Apply">
                    <IconButton
                        className={classes.buttons}
                        onClick={() => onApply(name, selectedValues, filterConfig)}
                        sx={{ color: 'green' }}
                    >
                        <DoneRoundedIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Cancel">
                    <IconButton
                        className={classes.buttons}
                        sx={{
                            color: 'text.primary',
                        }}
                        onClick={() => onCancel()}
                    >
                        <ClearRoundedIcon fontSize="small" />
                    </IconButton>
                </Tooltip> */}
            </Box>
        )
    }

    return (
        <Paper sx={{ width: '300px' }}>
            <AsyncResourceValueSelect
                cacheFirstPage
                resourceName={name}
                resourceType={type}
                keyPropertyName={keyPropertyName}
                displayPropertyName={keyPropertyName}
                multiple={true}
                openOnFocus
                textFieldProps={{
                    autoFocus: true,
                    placeholder: 'Search',
                }}
                forcePopupIcon={false}
                onChange={(e, values) => onValuesChange(values)}
                showCheckBoxes={true}
                value={selectedValues ?? []}
                filterSelectedOptions={false}
                delayOpenBy={200}
                onApply={() => onApply(name, selectedValues, filterConfig)}
                onCancel={onCancel}
                showActions={true}
                optionStyle={{ paddingTop: 0, paddingBottom: 0, paddingRight: 0 }}
                OptionActions={({ value }) => <OptionActions value={value} />}
                labelStyles={{ fontSize: '0.9rem', maxWidth: '60%' }}
                DropDownActions={Actions}
            />
        </Paper>
    )
}
