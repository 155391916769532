import React, { useState, useEffect, useRef, useContext } from 'react'
import { TextField, Typography, Button, Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
    AddRounded,
    FormatAlignLeftRounded,
    FormatAlignCenterRounded,
    FormatAlignRightRounded,
} from '@mui/icons-material'
import produce from 'immer'

import { DataGroup, PlotLine } from 'genesis-suite/types/visualTypes'
import BorderedSection from '../../BorderedSection'
import ColorPicker from '../../ColorPicker'
import LabeledToggleButton from '../../LabeledToggleButton'
import { ConfigContext } from '../ConfigContext'

interface Props {
    plotLines: PlotLine[]
    onChange: (change: PlotLine[]) => void
}

export default ({ plotLines = [], onChange }: Props) => {
    const { selectedField, dataResponse } = useContext(ConfigContext)

    const [drafts, setDrafts] = useState(plotLines)

    const timeout = useRef(null)
    useEffect(() => {
        clearTimeout(timeout.current)
        if (drafts === plotLines) return

        timeout.current = setTimeout(() => onChange(drafts), 200)
    }, [drafts])

    const handleChange = (plotLine, i) => {
        const newLines = Array.from(drafts)
        newLines.splice(i, 1, plotLine)
        setDrafts(newLines)
    }

    const handleRemove = index =>
        setDrafts(s =>
            produce(s, draft => {
                draft.splice(index, 1)
            })
        )

    const addNewLine = () => {
        let value = 100
        if (dataResponse) {
            if (selectedField.type === 'category') value = Math.floor(dataResponse?.data[0].data.length / 2)
            else value = Math.floor(getHighestValue(dataResponse?.data[selectedField.index].data))
        }
        setDrafts([...drafts, { value, width: 2 }])
    }

    return (
        <BorderedSection header="Plot lines" collapsable startCollapsed>
            {drafts.map((pl, i) => (
                <PlotLineForm
                    key={i}
                    id={i}
                    plotLine={pl}
                    onRemove={() => handleRemove(i)}
                    onChange={plotLine => handleChange(plotLine, i)}
                />
            ))}

            <Box mt={1} />

            <Button onClick={addNewLine} startIcon={<AddRounded fontSize="small" />} size="small" variant="outlined">
                Add plot line
            </Button>
        </BorderedSection>
    )
}

const useFormStyles = makeStyles(({ spacing }) => ({
    fieldWrapper: {
        display: 'grid',
        gridTemplateColumns: 'auto 45px',
        gridGap: spacing(),
    },
}))

const PlotLineForm = ({ id, plotLine, onChange, onRemove }) => {
    const classes = useFormStyles({})
    const mergeChange = change => onChange({ ...plotLine, ...change })

    const { value, color, dashStyle, width, label } = plotLine

    const dashOptions = [
        { label: 'Solid', value: 'Solid' },
        { label: 'Dot', value: 'Dot' },
        { label: 'Dash', value: 'Dash' },
    ]

    const onLabelChange = change => mergeChange({ label: { ...label, ...change } })

    return (
        <BorderedSection header={`Line ${id + 1}`} collapsable onRemove={onRemove}>
            <div className={classes.fieldWrapper}>
                <TextField
                    fullWidth
                    required
                    type="number"
                    value={value}
                    label="Value"
                    onChange={e => mergeChange({ value: parseInt(e.target.value, 10) })}
                />
                <TextField
                    fullWidth
                    type="number"
                    value={width}
                    label="Width"
                    inputProps={{ min: '1' }}
                    onChange={e => mergeChange({ width: parseInt(e.target.value, 10) })}
                />
            </div>
            <div>
                <Typography display="inline">Color: </Typography>
                <ColorPicker onChange={obj => mergeChange({ color: obj.hex })} color={color} />
            </div>
            <LabeledToggleButton
                size="small"
                value={dashStyle || 'Solid'}
                label="Dash style:"
                options={dashOptions}
                onChange={(e, dashStyle) => mergeChange({ dashStyle })}
            />
            <PlotLineLabelEditor label={label || {}} onChange={onLabelChange} />
        </BorderedSection>
    )
}

const PlotLineLabelEditor = ({ label, onChange }) => {
    const classes = useFormStyles({})
    const { text, align, rotation } = label
    const alignmentOptions = [
        { label: <FormatAlignLeftRounded />, value: 'left' },
        { label: <FormatAlignCenterRounded />, value: 'center' },
        { label: <FormatAlignRightRounded />, value: 'right' },
    ]
    return (
        <BorderedSection header="Label">
            <div className={classes.fieldWrapper}>
                <TextField
                    fullWidth
                    value={text || ''}
                    label="Text"
                    onChange={e => onChange({ text: e.target.value })}
                />
                <TextField
                    fullWidth
                    type="number"
                    value={rotation || 0}
                    label="Rotation"
                    inputProps={{ min: '0', max: '270' }}
                    onChange={e => onChange({ rotation: parseInt(e.target.value, 10) })}
                />
            </div>
            <LabeledToggleButton
                size="small"
                value={align}
                label="Alignment:"
                options={alignmentOptions}
                onChange={(e, align) => onChange({ align })}
            />
        </BorderedSection>
    )
}

function getHighestValue(dataGroup: DataGroup[]) {
    return dataGroup.reduce((acc, { children, aggregatedData }) => {
        return Math.max(acc, children ? getHighestValue(children) : aggregatedData?.[0] ?? 0)
    }, 0)
}
