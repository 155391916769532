import { Widget } from 'genesis-suite/types/visualTypes'
import WidgetInfoEmptyText from './WidgetInfoEmptyText'
import Markdown from '../Markdown'

export default function WidgetInfoDescription({ config }) {
    const isV2 = config?.version === '2'

    return isV2 ? <WidgetInfoDescriptionV2 config={config} /> : <WidgetInfoDescriptionV1 config={config} />
}

function WidgetInfoDescriptionV1({ config }) {
    const description = config.WidgetInfo.Description

    return (
        <div>
            {description !== 'No Description Available.' ? (
                <div dangerouslySetInnerHTML={{ __html: description }} />
            ) : (
                <WidgetInfoEmptyText>No widget description available</WidgetInfoEmptyText>
            )}
        </div>
    )
}

function WidgetInfoDescriptionV2({ config }: { config: Widget }) {
    const description = config.description?.replace(/\n/g, '  \n')

    return description ? (
        <Markdown>{description}</Markdown>
    ) : (
        <WidgetInfoEmptyText>No widget description available</WidgetInfoEmptyText>
    )
}
