import React, { useState, Fragment } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import withStyles from '@mui/styles/withStyles';
import { Typography, Collapse, ButtonBase } from '@mui/material'
import { ExpandMore, ExpandLess } from '@mui/icons-material'
import { widgetSelectors } from '../../../selectors'
import RemoveButton from './RemoveButton'
import { makeTemporalFilterDisplayText, parseFilter } from '../../../lib/utils'

const styles = theme => ({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        width: '100%',
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        padding: 0,
    },
    perspectiveFilter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    boldText: {
        fontWeight: 'bold',
    },
    removed: {
        fontStyle: 'italic',
        opacity: '.45',
    },
    persNum: {
        fontStyle: 'italic',
        margin: theme.spacing(0, 1),
    },
    showIcon: {
        margin: theme.spacing(0, 1),
        cursor: 'pointer',
    },
})

const addComma = (length, index) => (length > 1 && index < length - 1 ? ', ' : '')

/**
 * Displays and handles removal of Perspective Filters
 * @param {function} getTitle - Retrieves the title of the perspective based on the Id
 * @param {Object[]} preferences - An array of Objects representing the Perspective Filter Preferences
 * @param {Object[]} stagedRemoves - Object keyed by preference Key and FilterName, represents what has been staged for removal
 * @param {function} onChange - Callback function when RemoveButton is clicked
 */
const PerspectiveFilterPrefs = ({ classes, className, getTitle, preferences, stagedRemoves, onChange }) => {
    const [isOpen, setIsOpen] = useState({})
    return (
        <div className={className}>
            <div className={classes.container}>
                <Typography variant="subtitle1" className={classnames(classes.boldText, classes.row)}>
                    Perspective Filter Defaults
                </Typography>
                {preferences && preferences.length > 0 ? (
                    preferences.map(pref => {
                        const filterOpen = isOpen[pref.Key]
                        return (
                            <Fragment key={pref.Key}>
                                <div className={classes.row}>
                                    <ButtonBase
                                        className={classes.perspectiveFilter}
                                        onClick={() => setIsOpen({ ...isOpen, [pref.Key]: !filterOpen })}
                                    >
                                        <span className={classes.boldText}>{getTitle(pref.perspectiveId)}</span>
                                        <span className={classes.persNum}>{` ${pref.Value?.length} filter(s) `}</span>
                                        {filterOpen ? (
                                            <ExpandLess className={classes.showIcon} />
                                        ) : (
                                            <ExpandMore className={classes.showIcon} />
                                        )}
                                    </ButtonBase>
                                </div>
                                <Collapse in={filterOpen} unmountOnExit>
                                    {pref && pref.Value ? (
                                        pref.Value.map(filter => {
                                            const { FilterName, ResourceName, DisplayValues, IsTemporal } = filter
                                            const isStaged = stagedRemoves[pref.Key][FilterName]
                                            return (
                                                <div key={FilterName} className={classes.row}>
                                                    <div className={isStaged ? classes.removed : ''}>
                                                        <Typography
                                                            display="inline"
                                                            key={FilterName}
                                                            className={classes.boldText}
                                                        >
                                                            {`${ResourceName}: `}
                                                        </Typography>
                                                        {DisplayValues &&
                                                            DisplayValues.map((val, i) => (
                                                                <Typography display="inline" key={val}>
                                                                    {`${val}${addComma(DisplayValues.length, i)}`}
                                                                </Typography>
                                                            ))}
                                                        {IsTemporal && (
                                                            <Typography display="inline">
                                                                {makeTemporalFilterDisplayText(parseFilter(filter))}
                                                            </Typography>
                                                        )}
                                                    </div>
                                                    <RemoveButton
                                                        removed={isStaged}
                                                        onClick={() => onChange(pref.Key, FilterName)}
                                                    />
                                                </div>
                                            )
                                        })
                                    ) : (
                                        <Typography>No Perspective Filters configured...</Typography>
                                    )}
                                </Collapse>
                            </Fragment>
                        )
                    })
                ) : (
                    <Typography>No Perspective Filters configured...</Typography>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    getTitle: id => widgetSelectors.getWidgetTitle(state, id),
})

export default connect(mapStateToProps)(withStyles(styles)(PerspectiveFilterPrefs))
