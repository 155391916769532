import HideIcon from '@mui/icons-material/VisibilityOff'
import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import { useContext, useRef, useState } from 'react'

import widgetDictionary from '../utils/widgetDictionary'
import { DashboardContext } from './DashboardContext'

export default function RestoreHiddenWidgets() {
    const { config, devicePreview, hiddenWidgets, updateConfig } = useContext(DashboardContext)
    const [open, setOpen] = useState(false)
    const ref = useRef(null)

    if (!hiddenWidgets?.length) return null

    function restore(id: string) {
        const widgets = config.widgets.map((w, i) => {
            if (w.id) {
                if (w.id !== id) return w
            } else if (String(i) !== id) {
                return w
            }

            const { hide, ...newPosition } = w.positions[devicePreview]
            return { ...w, positions: { ...w.positions, [devicePreview]: newPosition } }
        })

        updateConfig({ widgets })
    }

    return (
        <>
            <Button
                ref={ref}
                variant="outlined"
                startIcon={<HideIcon fontSize="small" />}
                onClick={() => setOpen(true)}
                children="Restore"
            />

            <Menu
                anchorEl={ref.current}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={open}
                onClose={() => setOpen(false)}
            >
                {hiddenWidgets.map(({ id, title, type }) => {
                    const Icon = widgetDictionary[type].Icon
                    return (
                        <MenuItem key={id} onClick={() => restore(id)}>
                            <ListItemIcon>
                                <Icon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>{title}</ListItemText>
                        </MenuItem>
                    )
                })}
            </Menu>
        </>
    )
}
