import { CellClassParams, ColDef, ICellRendererParams } from '@ag-grid-community/core'
import { Box } from '@mui/material'
import { PropertyMetaWithSemantic } from 'genesis-suite/types/networkTypes'
import {
    CategoryTableColumn,
    ConditionalFormat,
    NumberFormat,
    TableSeries,
    ValueTableColumn,
} from 'genesis-suite/types/visualTypes'
import { ReactNode, useCallback, useEffect, useState } from 'react'
import { getPropertyMeta } from '../../hooks/useProperties'
import { CanClick, SetSelectedPoint } from '../../types/WidgetTypes'
import { canNavigateColumn } from '../widgets2/table/TableWidget2'
import { createHyperlink, createStyle, handleClickColumn } from '../widgets2/table/TableWidgetCell2'
import formatNumber, { roundValue } from '../widgets2/utils/formatNumber'
import { conditionalFormatsDynamicTable } from '../widgets2/utils/getConditionalFormats'
import isRawSeries from '../widgets2/utils/isRawSeries'

interface CategoryColumnProps {
    columnIndex: number
    params: ICellRendererParams
    series: TableSeries
    categories?: CategoryTableColumn[]
    canClickTable: CanClick
    setSelectedPoint: SetSelectedPoint
    themeMode: any
}

export const CategoryColumn = ({
    params,
    columnIndex,
    canClickTable,
    series,
    categories,
    setSelectedPoint,
    themeMode,
}: CategoryColumnProps) => {
    const rawSeries = isRawSeries(series)
    const canClickColumn = Boolean(
        canNavigateColumn(columnIndex, series, categories, canClickTable, rawSeries) && params.value
    )
    //this style is used to make the cell clickable
    const style = createStyle(canClickColumn, null, null, null, themeMode)
    return (
        <div
            style={style}
            onClick={e =>
                canClickColumn
                    ? handleClickColumn(
                          e,
                          series,
                          categories,
                          setSelectedPoint,
                          columnIndex,
                          params.rowIndex,
                          rawSeries,
                          params.data,
                          true
                      )
                    : undefined
            }
        >
            {params.value}
        </div>
    )
}

interface SeriesColumnProps {
    params: ICellRendererParams
    isHyperlink?: boolean
    hyperlinkUrl?: string
    numberFormat?: NumberFormat
    roundNormal?: boolean
}

export const SeriesColumn = ({ params, isHyperlink, hyperlinkUrl, numberFormat, roundNormal }: SeriesColumnProps) => {
    function verifyNormal(value) {
        if (roundNormal) {
            return roundValue(value)
        }
        return value
    }
    return (
        <div>
            {params.value && isHyperlink ? (
                <a href={createHyperlink(params.value, hyperlinkUrl)} target="_blank" rel="noreferrer noopener">
                    {params.value + ' '}
                    <i className="fa fa-external-link"></i>
                </a>
            ) : numberFormat ? (
                formatNumber(params.value, numberFormat)
            ) : (
                verifyNormal(params.value)
            )}
        </div>
    )
}

export const createColumnStyle = (
    params: CellClassParams,
    themeMode: any,
    mappedTitles: Partial<conditionalFormatsDynamicTable>,
    data: any,
    conditionalFormats?: ConditionalFormat[],
    align?: 'center' | 'left' | 'right'
) => {
    //aggregated values are not clickable
    const style = createStyle(
        false,
        null,
        conditionalFormats,
        { data: data, titles: mappedTitles },
        themeMode,
        true,
        params.value
    )
    return { textAlign: align || null, ...style }
}

export const GroupCellRenderer = (
    params: ICellRendererParams,
    categories: CategoryTableColumn[],
    isPivotActive: boolean,
    children: ReactNode
) => {
    const align = categories[params.node.level].align || 'left'
    const { node } = params
    const [expanded, setExpanded] = useState(node.expanded)

    useEffect(() => {
        const expandListener = event => setExpanded(event.node.expanded)
        node.addEventListener('expandedChanged', expandListener)
        return () => {
            node.removeEventListener('expandedChanged', expandListener)
        }
    }, [])

    const onClick = useCallback(() => node.setExpanded(!node.expanded), [node])
    return (
        <Box
            style={{
                paddingLeft: `${node.level * 15}px`,
                display: 'flex',
                alignItems: 'center',
            }}
        >
            {node.group && (!isPivotActive || !node.leafGroup) && (
                <Box
                    style={{
                        cursor: 'pointer',
                        display: 'flex',
                    }}
                    onClick={onClick}
                >
                    <span className={expanded ? 'ag-icon ag-icon-tree-open' : 'ag-icon ag-icon-tree-closed'} />
                </Box>
            )}
            <Box marginLeft={1} flexGrow={1} sx={{ textAlign: align }}>
                {children}
            </Box>
        </Box>
    )
}

export const createColumns = (
    categories: CategoryTableColumn[],
    properties: PropertyMetaWithSemantic[],
    themeMode: any,
    categoryColumnRenderer: (params: ICellRendererParams, index: number) => ReactNode,
    seriesColumnRenderer: (params: ICellRendererParams, value: ValueTableColumn) => ReactNode,
    isPivot = false,
    isCollapsable = true,
    mappedCategoriesTitles: Partial<conditionalFormatsDynamicTable> = {},
    series: TableSeries[] | [] = [],
    mappedSeriesTitles: Partial<conditionalFormatsDynamicTable> = {}
): ColDef<any>[] => {
    let columnDefs: ColDef[] = []

    //if collapsable, we remove the last category from the list of categories, it will be the autoGroupColumn
    const groupCategories = isCollapsable && categories?.length > 1 && !isPivot ? categories.slice(0, -1) : categories
    if (!groupCategories) return []

    groupCategories.forEach((category, index) => {
        const { displayName } = getPropertyMeta(properties, category.field) || {}
        columnDefs.push({
            colId: category.columnOrder?.toString() || null,
            field: displayName || category.title || category.field.displayName || category.field.name,
            rowGroup: isCollapsable,
            hide: isCollapsable,
            unSortIcon: true,
            suppressSizeToFit: false,
            headerTooltip: displayName || category.title || category.field.displayName || category.field.name,
            //we use cellStyle instead of cellRenderer for better style performance and control, only for non collapsable tables
            //collapsible tables uses cellStyle and cellRenderer from the autoGroupColumnDef
            cellStyle: isCollapsable
                ? null
                : (params: CellClassParams) =>
                      createColumnStyle(
                          params,
                          themeMode,
                          mappedCategoriesTitles,
                          params.data,
                          category.conditionalFormats,
                          category.align
                      ),
            cellRenderer: isCollapsable ? null : (params: ICellRendererParams) => categoryColumnRenderer(params, index),
            cellClass: 'roundedValue',
        })
    })

    series.forEach((series: TableSeries) => {
        //pivot column
        series.subSeries &&
            columnDefs.push({
                field: series.subSeries.field.name,
                pivot: true,
            })

        //columns
        series.values.forEach(value => {
            const { displayName } = getPropertyMeta(properties, value.field) || {}
            columnDefs.push({
                colId: value.columnOrder?.toString() || null,
                field: displayName || value.title || value.field.name,
                aggFunc: value.aggregation,
                unSortIcon: true,
                suppressSizeToFit: false,
                //we use cellStyle instead of cellRenderer for better style performance and control
                cellStyle: (params: CellClassParams) =>
                    createColumnStyle(
                        params,
                        themeMode,
                        mappedSeriesTitles,
                        params.data,
                        value.conditionalFormats,
                        value.align
                    ),
                cellRenderer: (params: ICellRendererParams) => seriesColumnRenderer(params, value),
                cellClass: 'roundedValue',
            })
        })
    })

    //sort the columns by columnOrder
    let lastColumn = categories?.length + series?.length + 1

    columnDefs = columnDefs.sort((a, b) => {
        const returnPosition = (parseInt(a.colId) ?? lastColumn) - (parseInt(b.colId) ?? lastColumn)
        if (returnPosition === lastColumn) return lastColumn++
        return returnPosition
    })

    return columnDefs
}

export const createAutoGroupColumn = (
    isCollapsable: boolean,
    categories: CategoryTableColumn[],
    themeMode: any,
    mappedCategoriesTitles: Partial<conditionalFormatsDynamicTable>,
    canClick: CanClick,
    tableSeries: TableSeries,
    setSelectedPoint: SetSelectedPoint
) => {
    if (categories?.length === 0 || !isCollapsable) return null
    const category = categories.slice(-1)[0]
    return {
        flex: 1,
        minWidth: 280,
        field: category.field.name,
        unSortIcon: true,
        cellStyle: (params: CellClassParams) =>
            createColumnStyle(
                params,
                themeMode,
                mappedCategoriesTitles,
                params.data,
                category.conditionalFormats,
                category.align
            ),
        cellRenderer: (params: ICellRendererParams) =>
            GroupCellRenderer(
                params,
                categories,
                !!tableSeries?.subSeries,
                <CategoryColumn
                    params={params}
                    canClickTable={canClick}
                    categories={categories}
                    columnIndex={params.node.level}
                    series={tableSeries}
                    setSelectedPoint={setSelectedPoint}
                    themeMode={themeMode}
                />
            ),
    }
}
