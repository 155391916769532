import RefreshIcon from '@mui/icons-material/Refresh'
import { MenuIcon } from 'genesis-suite/components'

export default function PerspectiveRefresh({ disabled, iconProps, buttonStyle, handleRefresh }) {
    return (
        <MenuIcon
            buttonProps={{ sx: buttonStyle }}
            closeButton
            icon={<RefreshIcon {...iconProps} />}
            disabled={disabled}
            onClick={() => handleRefresh()}
            title="Refresh Perspective"
            tooltip="Refresh Perspective"
        ></MenuIcon>
    )
}
