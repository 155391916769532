import { useContext } from 'react'
import { Checkbox, FormControlLabel, Tooltip } from '@mui/material'

import { TableConfig } from 'genesis-suite/types/visualTypes'
import { ConfigContext } from '../ConfigContext'

export const DynamicTableEditor = () => {
    const { config, dispatch } = useContext(ConfigContext)
    const { dynamic = false, collapsable = false } = (config as TableConfig) || {}

    function handleUpdate(target: string, partialConfig: Partial<TableConfig>) {
        //if there is not a pivot value and collapsable is unchecked, dynamic should be unchecked
        if (target === 'collapsable' && !partialConfig.collapsable && config.series[0].subSeries)
            return dispatch({ type: 'UPDATE_CONFIG', payload: { ...partialConfig, dynamic: false } })

        //if there is a pivot value and dynamic is checked, collapsable should be checked
        if (target === 'dynamic' && partialConfig.dynamic && config.series[0]?.subSeries)
            return dispatch({ type: 'UPDATE_CONFIG', payload: { ...partialConfig, collapsable: true } })

        dispatch({ type: 'UPDATE_CONFIG', payload: { ...partialConfig } })
    }

    return (
        <>
            <Tooltip title={'The Dynamic Table has improved performance over the default table.'} placement="top">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={dynamic || collapsable}
                            disabled={collapsable}
                            onChange={e => handleUpdate(e.target.name, { dynamic: e.target.checked })}
                        />
                    }
                    name="dynamic"
                    label="Dynamic"
                />
            </Tooltip>
            <Tooltip
                title={
                    <span style={{ whiteSpace: 'pre-line' }}>
                        {`A Collapsable Table creates row groups based on the selected Category Columns (min: 2).
                        A Collapsable Table is a Dynamic Table.\nA Dynamic Table with Pivot is a Collapsable Table.`}
                    </span>
                }
                placement="top"
            >
                <FormControlLabel
                    control={
                        <Checkbox
                            disabled={
                                !config.categories || (!config.series[0]?.subSeries && config.categories?.length <= 1)
                            }
                            checked={collapsable}
                            onChange={e =>
                                handleUpdate(e.target.name, {
                                    collapsable: e.target.checked,
                                    dynamic: e.target.checked || dynamic,
                                })
                            }
                        />
                    }
                    name="collapsable"
                    label="Collapsable"
                />
            </Tooltip>
        </>
    )
}
