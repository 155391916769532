import React, { useState } from 'react'
import { EditRounded } from '@mui/icons-material'
import { TextField, Typography } from '@mui/material'

interface TitleBuilderProps {
    title: string
    onChange: (newTitle: string) => void
}

export default function FormTitleBuilder(props: TitleBuilderProps) {
    const { title, onChange } = props
    const [text, setText] = useState(title)
    const [editing, setEditing] = useState(false)

    const toggleEditing = () => setEditing(!editing)

    const submitTitle = () => {
        onChange(text)
        toggleEditing()
    }

    if (editing)
        return (
            <TextField
                autoFocus
                value={text}
                onBlur={submitTitle}
                onChange={e => setText(e.target.value)}
                onKeyPress={e => e.key === 'Enter' && submitTitle()}
            />
        )
    else
        return (
            <Typography variant="h6" style={{ cursor: 'pointer' }} onClick={toggleEditing}>
                {title} <EditRounded fontSize="small" style={{ verticalAlign: 'middle' }} />
            </Typography>
        )
}
