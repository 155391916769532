import { useContext } from 'react'
import { Box, Typography } from '@mui/material'
import LineIcon from '@mui/icons-material/ShowChart'
import BarIcon from '@mui/icons-material/BarChartRounded'
import AreaIcon from '@mui/icons-material/NetworkCell'
import { produce } from 'immer'

import { Axis, ColumnSeries, LineSeries, SeriesConfig } from 'genesis-suite/types/visualTypes'
import LabeledToggleButton from '../../LabeledToggleButton'
import { ConfigContext } from '../../edit_widget'
import Orientation from '../../edit_widget/config_fields/Orientation'
import EditorWrapper from '../../edit_widget/config_fields/EditorWrapper'
import SeriesDataLabelsEditor from '../../edit_widget/config_fields/SeriesEditor/SeriesDataLabelsEditor'
import AxisEditor from '../../edit_widget/config_fields/AxisEditor'
import LegendEditor from '../../edit_widget/config_fields/LegendEditor'

type Shape = LineSeries['shape'] | ColumnSeries['type']

const comboOptions: Array<{ label: any; value: Shape; tip: string }> = [
    { label: <BarIcon />, value: 'column', tip: 'Bar' },
    { label: <LineIcon />, value: 'spline', tip: 'Smooth' },
    { label: <LineIcon />, value: 'line', tip: 'Straight' },
    { label: <AreaIcon />, value: 'area', tip: 'Area' },
]

export default function ComboFormatter() {
    const { dispatch, ...rest } = useContext(ConfigContext)
    const config = rest.config as SeriesConfig
    const seriesIndex = 0
    const activeSeries = config.series[seriesIndex] as ColumnSeries | LineSeries
    let type: Shape = 'column'
    if (activeSeries?.type === 'line') type = activeSeries.shape || 'spline'

    const yAxisIndex = config.yAxis[0].opposite ? 1 : 0

    function handleYAxisChange(update: Partial<Axis>) {
        if (update.opposite != null) {
            const updatedAxisIndex = update.opposite ? 1 : 0

            if (updatedAxisIndex !== yAxisIndex) {
                dispatch({ type: 'FLIP_Y_AXIS', payload: update.opposite ? 1 : 0 })
                return
            }
        }

        const yAxis = config.yAxis.map((axis, index) => {
            if (index === yAxisIndex) return { ...axis, ...update }
            else {
                return axis
            }
        })
        dispatch({ type: 'UPDATE_CONFIG', payload: { yAxis } })
    }

    function handleXAxisChange(update: Partial<Axis>) {
        dispatch({ type: 'UPDATE_CONFIG', payload: { xAxis: { ...config.xAxis, ...update } } })
    }

    function handleTypeChange(e, shape: Shape) {
        const type = shape === 'column' ? 'column' : 'line'

        const updated = produce(activeSeries, draft => {
            draft.type = type
            if (draft.type === 'line' && shape !== 'column') draft.shape = shape
        })

        dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: updated })
    }

    return (
        <div>
            <EditorWrapper header="Basic" startOpen>
                <Typography variant="caption">Type</Typography>
                <LabeledToggleButton value={type} label="" onChange={handleTypeChange} options={comboOptions} />
                <Box mt={1} />
                <Typography variant="caption">Orientation</Typography>
                <Orientation />
            </EditorWrapper>
            <EditorWrapper header="Data labels">
                <SeriesDataLabelsEditor />
            </EditorWrapper>
            <AxisEditor value={config.xAxis} title="xAxis" onChange={handleXAxisChange} />
            <AxisEditor value={config.yAxis[yAxisIndex]} title="yAxis" onChange={handleYAxisChange} />
            <LegendEditor />
        </div>
    )
}
