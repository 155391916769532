import { ResourceType } from 'genesis-suite/types/networkTypes'
import {
    Aggregation,
    Basket,
    ChartType,
    DataSource,
    LocationType,
    MapConfig,
    MapHeatSeries,
    MapImageOverlay,
    MapMarkerSeries,
    MapSurface,
} from 'genesis-suite/types/visualTypes'
import { makeField, stringToFilters } from 'genesis-suite/utils'
import { WidgetConverterProps } from '../migrationTypes'
import { getPropertyFromResource, makeAggregation, makeColorGradient } from '../widgetConverterUtils'

export default async function webviewConverter({
    config,
    dashboardIdByOldId,
    resourceManager,
}: WidgetConverterProps): Promise<MapConfig> {
    const { Title, MapConfig } = config
    const { HeatMapSeries, MapConnectionSeries, MapViewType, MapType, MarkerSeries, OverlayConfigs, ZoomFactor } =
        MapConfig

    const surface =
        MapViewType === 'Standard'
            ? MapSurface.ROADMAP
            : MapViewType === 'Satellite'
            ? MapSurface.SATELLITE
            : MapViewType === 'Terrain'
            ? MapSurface.TERRAIN
            : MapSurface.HYBRID

    const overlays = (OverlayConfigs as any[])?.map<MapImageOverlay>(o => {
        const [top, left] = o.NorthWestCoordinates
        const [bottom, right] = o.SouthEastCoordinates

        return {
            urlString: o.FileToken,
            title: o.ImageName,
            placement: { top, left, bottom, right },
        }
    })

    const markerSeries =
        MarkerSeries &&
        (await (async () =>
            Promise.all(
                (MarkerSeries as any[]).map(async s => {
                    const insight = await resourceManager.getInsight(s.Source.ElementName)

                    const latValue: DataSource = {
                        field: makeField(insight.properties, s.LatitudeField),
                        aggregation: Aggregation.NONE,
                        basket: Basket.LATITUDE,
                    }
                    const lngValue: DataSource = {
                        field: makeField(insight.properties, s.LongitudeField),
                        aggregation: Aggregation.NONE,
                        basket: Basket.LONGITUDE,
                    }
                    const markerValue: DataSource = {
                        field: makeField(insight.properties, s.MarkerField),
                        aggregation: Aggregation.NONE,
                        basket: Basket.ID,
                        navigation: {
                            enabled: true,
                            ...(s.DefaultPerspective && { perspectiveId: dashboardIdByOldId[s.DefaultPerspective] }),
                        },
                    }
                    const scaleValue: DataSource = s.MarkerScaleFieldName && {
                        field: makeField(insight.properties, s.MarkerScaleFieldName),
                        aggregation: Aggregation.NONE,
                        basket: Basket.SIZE,
                    }
                    const values = [latValue, lngValue, markerValue, ...(scaleValue ? [scaleValue] : [])]

                    return {
                        type: 'marker',
                        locationType: LocationType.GEO,
                        service: { type: ResourceType.INSIGHT, name: insight.name, id: insight.id },
                        markerType: getMarkerType(s.MarkerType),
                        size: s.ScaleCoefficient,
                        title: s.SeriesName,
                        filters: stringToFilters(insight.properties, s.Source.Filters),
                        ...(s.Color && { colors: { [s.LatitudeField]: s.Color } }),
                        values,
                    } as MapMarkerSeries
                })
            ))())

    const heatSeries =
        HeatMapSeries &&
        (await (async () =>
            Promise.all(
                (HeatMapSeries as any[]).map(async s => {
                    const locationType =
                        MapType === 'World'
                            ? LocationType.COUNTRY
                            : MapType === 'USCounties'
                            ? LocationType.COUNTY
                            : LocationType.STATE

                    const insight = await resourceManager.getInsight(s.Source.ElementName)

                    const value: DataSource = {
                        field: makeField(insight.properties, s.ValueField),
                        aggregation: makeAggregation(getPropertyFromResource(insight, s.ValueField), s.AggregationType),
                    }

                    return {
                        type: 'heat',
                        locationType,
                        service: { type: ResourceType.INSIGHT, name: insight.name, id: insight.id },
                        subSeries: { field: makeField(insight.properties, s.ReferenceField) },
                        values: [value],
                        filters: stringToFilters(insight.properties, s.Source.Filters),
                        colorGradient: makeColorGradient(s.ColorRange),
                    } as MapHeatSeries
                })
            ))())

    // const connectionSeries =
    //     MapConnectionSeries &&
    //     (await (async () =>
    //         Promise.all(
    //             (MapConnectionSeries as any[]).map(async s => {
    //                 return {
    //                     type: 'connection',
    //                 } as MapConnectionSeries
    //             })
    //         ))())

    const series = [...(markerSeries || []), ...(heatSeries || [])] //,...connectionSeries||[]]

    return {
        version: '2',
        type: ChartType.MAP,
        categories: [],
        title: Title,
        surface,
        series,
        ...(overlays && { overlays }),
        ...(ZoomFactor && { zoom: ZoomFactor }),
    }
}

function getMarkerType(type): MapMarkerSeries['markerType'] {
    switch (type) {
        case 's':
            return 'square'
        case 'd':
            return 'diamond'
        case 'tu':
            return 'triangle-up'
        case 'td':
            return 'triangle-down'
        default:
            return 'circle'
    }
}
