import { useDispatch } from 'react-redux'
import { routePaths } from '../lib/routes'
import { navigationCreators } from '../actions/creators'
import { IconButton, Tooltip } from '@mui/material'
import { HomeWorkRounded } from '@mui/icons-material'

export default function ManageHomeButton({ iconStyle, buttonStyle }) {
    const dispatch = useDispatch()
    const goToManage = () => dispatch(navigationCreators.goTo(routePaths.MANAGE_HOME))

    return (
        <Tooltip title="Manage Home View">
            <IconButton size="small" sx={buttonStyle} onClick={goToManage}>
                <HomeWorkRounded sx={iconStyle} />
            </IconButton>
        </Tooltip>
    )
}
