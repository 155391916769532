import { useEffect, useState } from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    TextField,
    Checkbox,
    IconButton,
    Alert,
    Autocomplete,
} from '@mui/material'
import { CloseRounded } from '@mui/icons-material'

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

export default function LayoutPerspectiveDialog({
    openDialog,
    handleCloseDialog,
    classes,
    latestOptions,
    onClickDialogBtn,
    dialogError,
    tabRows,
    perspectives,
}) {
    const [dialogData, setDialogData] = useState({ perspective: [], name: '' })

    useEffect(() => {
        let obj = { perspective: [], name: '' }
        if (openDialog?.data) {
            const perspectiveArr = openDialog.data.perspective.map(item => item.Id)
            const perspectivesData = perspectives.filter(item => perspectiveArr.includes(item.Id))
            obj = { perspective: perspectivesData, name: openDialog?.data.name }
        }
        setDialogData(obj)
    }, [openDialog])

    return (
        <Dialog open={openDialog.open} onClose={handleCloseDialog} fullWidth maxWidth="md">
            <DialogTitle>
                Tab
                <IconButton
                    aria-label="close"
                    onClick={handleCloseDialog}
                    sx={{
                        color: 'inherit',
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseRounded />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <TabRow
                    dialogData={dialogData}
                    setDialogData={setDialogData}
                    classes={classes}
                    latestOptions={latestOptions}
                    tabRows={tabRows}
                />
                {dialogError && (
                    <Alert severity="error" sx={{ mt: 1 }}>
                        Please Enter both Tab Perspectives and Tab Name (Tab Name Should be Unique)
                    </Alert>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" type="submit" onClick={() => onClickDialogBtn(dialogData)}>
                    {openDialog?.data?.perspective ? 'Update' : 'Submit'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

function TabRow({ dialogData, setDialogData, classes, latestOptions, tabRows }) {
    const handleOnChange = (key, value) => {
        let data = { ...dialogData }
        if (key == 'perspective' && !data.name) {
            data.name = 'Tab ' + (tabRows.length + 1)
        }
        data[key] = value
        setDialogData(data)
    }

    return (
        <Box
            sx={{
                p: 1,
                display: 'flex',
                border: 1,
                borderColor: 'border.main',
                alignItems: 'center',
                gap: 2,
            }}
        >
            <Autocomplete
                sx={theme => ({
                    flex: '2',
                    '& .MuiInput-underline::before': {
                        borderBottomColor: theme.palette.text.primary,
                    },
                    '& .MuiFormLabel-root, .MuiAutocomplete-endAdornment svg': {
                        color: 'text.primary',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'text.primary',
                    },
                })}
                multiple
                disableCloseOnSelect
                options={latestOptions}
                getOptionLabel={option => option.Title}
                value={dialogData['perspective'].length > 0 ? dialogData['perspective'] : []}
                name="perspective"
                onChange={(event, value) => handleOnChange('perspective', value)}
                className={classes.labelStyle}
                renderInput={params => <TextField {...params} label="Perspective" required={true} />}
                renderOption={(props, option, { selected }) => {
                    const { key, ...optionProps } = props
                    return (
                        <li key={key} {...optionProps}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.Title}
                        </li>
                    )
                }}
            ></Autocomplete>

            <TextField
                sx={theme => ({
                    flex: '1',
                    '& .MuiInput-underline::before': {
                        borderBottomColor: theme.palette.text.primary,
                    },
                })}
                label="Tab Name"
                name="name"
                required
                value={dialogData.name}
                onChange={e => handleOnChange('name', e.target.value)}
                SelectProps={{ classes: { icon: classes.iconStyle } }}
                className={classes.labelStyle}
            />
        </Box>
    )
}
