import React, { useContext } from 'react'
import { BarChartRounded } from '@mui/icons-material'

import { ConfigContext } from '../ConfigContext'
import LabeledToggleButton from '../../LabeledToggleButton'

export default () => {
    const { config, dispatch } = useContext(ConfigContext)
    if (!config) return null
    const { invertAxis } = config

    const options = [
        { label: <BarChartRounded />, value: 'vertical', tip: 'Vertical' },
        { label: <BarChartRounded style={{ transform: 'rotate(90deg)' }} />, value: 'horizontal', tip: 'Horizontal' },
    ]

    return (
        <LabeledToggleButton
            value={invertAxis ? 'horizontal' : 'vertical'}
            options={options}
            onChange={(e, invertAxis) => {
                if (!invertAxis) return
                dispatch({ type: 'UPDATE_CONFIG', payload: { invertAxis: invertAxis === 'horizontal' } })
            }}
        />
    )
}
