import React, { useState } from 'react'
import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { FormColumn } from 'genesis-suite/types/visualTypes'
import { ICellEditorParams } from '@ag-grid-community/core'
import { FormAttachmentField } from '../FormWidget/FormAttachmentField'
import TadaFormSelect from '~/components/widgets2/form/TadaFormSelect'
import FormField from '~/components/widgets2/form/FormField'
import { getTransposeColumn } from '~/lib/formTableUtils'

const useStyles = makeStyles(({ palette }) => ({
    tadaSelect: { display: 'flex' },
    formField: { width: '100%' },
    //@ts-ignore
    cellRoot: { border: ({ flagged }) => (flagged ? `2px solid ${palette.status.error}` : '') },
}))

interface FormTableEditCellProps {
    params: ICellEditorParams
    column: FormColumn
    columns: FormColumn[]
    flagged?: boolean
    isTranspose?: boolean
}

export default function FormTableEditCell(props: FormTableEditCellProps) {
    const { params, column, columns, flagged, isTranspose } = props
    const { value, api, data: row } = params
    const _column = isTranspose ? getTransposeColumn(columns, row?.TransposeRow) : column
    const classes = useStyles({ flagged })
    const [open, setOpen] = useState(false)
    const { displayProperty, useTadaValues, lastUpdateField, required, property, editable } = _column
    const { semanticType } = property
    const isAttachment = semanticType.baseDataType === 'File'
    const displayValue = displayProperty ? row[displayProperty.name] : null
    
    const handleChange = async value => {
        // Set the value of the cell currently being edited
        const rowNode = params.node
        const column = params.column
        if (displayProperty) {
            const updatedData = { ...rowNode.data, [displayProperty.name]: value?.label }
            rowNode.setData(updatedData)
        }
        if (typeof value === 'object') {
            rowNode.setDataValue(column, value?.value ?? null)
        } else {
            rowNode.setDataValue(column, value ?? null)
        }

        // Stop editing the cell
        api.stopEditing()
    }

    const defaults = {
        row,
        value,
        field: _column,
        onEditComplete: handleChange,
    }
    const getComponent = () => {
        if (isAttachment) {
            const attachments = value && typeof value === 'string' ? value.split(',') : []
            return (
                <FormAttachmentField
                    required={required}
                    dataType={semanticType}
                    editable={editable}
                    onChange={handleChange}
                    propertyId={property.id}
                    attachments={attachments}
                />
            )
        } else if (useTadaValues && !lastUpdateField) {
            return (
                <TadaFormSelect
                    {...{
                        ...defaults,
                        displayValue,
                        initOpen: true,
                        hideLabel: true,
                        fields: columns,
                        disableUnderline: true,
                        inputStyle: { marginTop: '10px' },
                    }}
                />
            )
        } else {
            return (
                <FormField
                    {...{
                        ...defaults,
                        open,
                        location: 'cell',
                        onOpen: () => setOpen(true),
                        onClose: () => setOpen(false),
                        showAutocomplete: true,
                    }}
                />
            )
        }
    }

    return (
        <Box className={classes.cellRoot} width="100%" height="100%">
            {getComponent()}
        </Box>
    )
}
