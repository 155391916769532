import { Box, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useContext, useState } from 'react'

import { DebouncedTextField } from 'genesis-suite/components'
import { ChartType, SeriesConfig } from 'genesis-suite/types/visualTypes'
import { ConfigContext } from '../ConfigContext'
import { TrellisConstants } from '../../../types/TrellisTypes'
import { FieldPointer } from '../builderTypes'

interface Props {
    config: SeriesConfig
    pointer: FieldPointer
}

export default function TrellisEditor({ config, pointer }: Props) {
    const [columnCount, setColumnCount] = useState('')
    const [rowCount, setRowCount] = useState('')

    const [columnError, setColumnError] = useState(false)
    const [rowError, setRowError] = useState(false)

    const { dispatch } = useContext(ConfigContext)
    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const trellisEditor = showTrellisEditor(config, pointer)

    const showSnackbarError = (errorMessage: string) => {
        showSnackbar(errorMessage, {
            variant: 'error',
            preventDuplicate: true,
        })
    }

    const handleColumnCount = (inputColValue: string) => {
        const { isValid, errorMessage } = isValidInput(inputColValue, 'column')
        const isValidColumn = inputColValue ? isValid : true
        const gridColumns = isValidColumn && inputColValue ? Number.parseInt(inputColValue, 10) : undefined

        setColumnCount(inputColValue)
        setColumnError(!isValidColumn)

        if (!isValidColumn) showSnackbarError(errorMessage)

        dispatch({
            type: 'UPDATE_TRELLIS',
            payload: {
                layout: { ...config?.trellis?.layout, gridColumns },
            },
        })
    }

    const handleRowCount = (inputRowValue: string) => {
        const { isValid, errorMessage } = isValidInput(inputRowValue, 'row')
        const isValidRow = inputRowValue ? isValid : true
        const gridRows = isValidRow && inputRowValue ? Number.parseInt(inputRowValue, 10) : undefined

        setRowCount(inputRowValue)
        setRowError(!isValidRow)

        if (!isValidRow) showSnackbarError(errorMessage)

        dispatch({
            type: 'UPDATE_TRELLIS',
            payload: {
                layout: { ...config?.trellis?.layout, gridRows },
            },
        })
    }

    if (!trellisEditor) return null

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', mb: 1 }}>
            <Typography variant="caption">Column(s)</Typography>
            <DebouncedTextField
                sx={{
                    '& .MuiOutlinedInput-input': { padding: '10px' },
                    '& .MuiInputBase-root ': { width: 85, margin: '4px 0px 6px 0px' },
                }}
                type="number"
                variant="outlined"
                value={columnCount}
                onChange={handleColumnCount}
                inputProps={{ min: TrellisConstants.GRID_MIN, max: TrellisConstants.GRID_MAX }}
                debounceTime={800}
                error={columnError}
            />
            <Typography variant="caption">Row(s)</Typography>
            <DebouncedTextField
                sx={{
                    '& .MuiOutlinedInput-input': { padding: '10px' },
                    '& .MuiInputBase-root ': { width: 85, margin: '4px 0px 6px 0px' },
                }}
                type="number"
                fullWidth
                variant="outlined"
                value={rowCount}
                onChange={handleRowCount}
                inputProps={{ min: TrellisConstants.GRID_MIN, max: TrellisConstants.GRID_MAX }}
                debounceTime={800}
                error={rowError}
            />
        </Box>
    )
}

function isValidInput(value: string, inputName: string) {
    const count = Number.parseInt(value, 10)
    let isValid = false
    let errorMessage: string

    switch (true) {
        case Number.isNaN(count): {
            errorMessage = `Please enter a valid ${inputName} value.`
            break
        }
        case count < TrellisConstants.GRID_MIN: {
            errorMessage = `Please enter a ${inputName} value greater than or equal to ${TrellisConstants.GRID_MIN}.`
            break
        }
        case count > TrellisConstants.GRID_MAX: {
            errorMessage = `Please enter a ${inputName} value less than ${TrellisConstants.GRID_MAX}.`
            break
        }
        default: {
            isValid = true
        }
    }

    return { isValid, errorMessage }
}

function showTrellisEditor(config: SeriesConfig, pointer: FieldPointer) {
    const { type, categories } = config
    const trellisPropSelected = pointer.index === 0

    switch (type) {
        case ChartType.COMBO: {
            return categories.length >= 2 && trellisPropSelected
        }
        default:
            return false
    }
}
