import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'
import { BareInsight } from 'genesis-suite/types/networkTypes'
import { Autocomplete } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Checkbox, TextField, FormGroup, FormControlLabel, Tab, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { TableFormConfig } from 'genesis-suite/types/visualTypes'
import ConfigHeader from './ConfigHeader'
import { BuilderProps } from './EditFormTypes'
import { insightSelectors } from '../../selectors'
import FormPaletteBuilder from './builders/FormPaletteBuilder'

const useStyles = makeStyles(({ custom, palette, spacing }) => ({
    formOptsRoot: { marginBottom: spacing(1) },
    contentRoot: { ...custom.builderContainer },
    tabPanel: { padding: spacing(1) },
    contentTab: {
        flex: '1 1 auto',
        minWidth: 'unset',
        maxWidth: 'unset',
        fontSize: '.75rem',
        borderBottom: `1px solid ${palette.grayscale.light}`,
    },
    inputs: {
        alignItems: 'center',
        '&>*': { minWidth: 250, margin: spacing(0, 1) },
    },
    toggles: {
        alignItems: 'center',
        '&>*': { minWidth: 175, margin: spacing(0, 1) },
    },
}))

export default function FormOptions(props: BuilderProps) {
    const classes = useStyles({})
    const [value, setValue] = useState('general')
    const { config } = props

    const makeTabs = () => {
        const items = [
            {
                label: 'General',
                value: 'general',
                render: <GeneralOptions {...props} />,
            },
            {
                label: 'Color Palette',
                value: 'colors',
                render: <FormPaletteBuilder palette={config.palette} onChange={props.onChange} />,
            },
        ]
        if (config.formType === 'table')
            items.push({
                label: 'Edit Options',
                value: 'editOpts',
                render: <TableEditOptions config={config as TableFormConfig} onChange={props.onChange} />,
            })
        return items
    }

    const tabs = useMemo(makeTabs, [config])

    return (
        <Box className={classes.formOptsRoot}>
            <ConfigHeader title="Form Options" />
            <Box className={classes.contentRoot}>
                <TabContext value={value}>
                    <TabList onChange={(e, value) => setValue(value)}>
                        {tabs.map(({ label, value }) => (
                            <Tab
                                key={value}
                                value={value}
                                className={classes.contentTab}
                                label={
                                    <Tooltip title={label}>
                                        <span>{label}</span>
                                    </Tooltip>
                                }
                            />
                        ))}
                    </TabList>
                    {tabs.map(({ value, render }) => (
                        <TabPanel key={value} className={classes.tabPanel} value={value}>
                            {render}
                        </TabPanel>
                    ))}
                </TabContext>
            </Box>
        </Box>
    )
}

function GeneralOptions(props: BuilderProps) {
    const classes = useStyles({})
    const { config, onChange } = props
    const { loadInitialData } = config
    const insights: BareInsight[] = useSelector(insightSelectors.getBareList)

    const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
        onChange({ [e.target.name]: checked })

    const handleTextEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {
            target: { name, value },
        } = e
        handleTextChange(name, value)
    }

    const handleTextChange = useDebouncedCallback((name, value) => {
        onChange({ [name]: value })
    }, 200)

    return (
        <FormGroup className={classes.inputs} row>
            <FormControlLabel
                control={<Checkbox name="loadInitialData" checked={loadInitialData} onChange={handleCheckbox} />}
                label="Load Initial Data"
            />
            <Autocomplete
                options={insights}
                value={config.insight}
                getOptionLabel={opt => opt.name}
                onChange={(e, insight: BareInsight | null, reason) => {
                    if (reason !== 'blur') onChange({ insight })
                }}
                renderInput={params => <TextField {...params} label="Insight" />}
            />
            {config.formType === 'table' && (
                <TextField
                    name="exportFileName"
                    label="Export File Name"
                    onChange={handleTextEvent}
                    defaultValue={config.exportFileName}
                />
            )}
        </FormGroup>
    )
}

interface TableEditOptProps {
    config: TableFormConfig
    onChange: (change: Partial<TableFormConfig>) => void
}

function TableEditOptions({ config, onChange }: TableEditOptProps) {
    const classes = useStyles({})
    const { canAddNew, canDelete, canInputEdit, canCellEdit, canUploadExcel } = config

    const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
        onChange({ [e.target.name]: checked })

    return (
        <FormGroup className={classes.toggles} row>
            <FormControlLabel
                control={<Checkbox name="canAddNew" checked={canAddNew} onChange={handleCheckbox} />}
                label="Allow Add"
            />
            <FormControlLabel
                control={<Checkbox name="canDelete" checked={canDelete} onChange={handleCheckbox} />}
                label="Allow Delete"
            />
            <FormControlLabel
                control={<Checkbox name="canInputEdit" checked={canInputEdit} onChange={handleCheckbox} />}
                label="Allow Form Edit"
            />
            <FormControlLabel
                control={<Checkbox name="canCellEdit" checked={canCellEdit} onChange={handleCheckbox} />}
                label="Allow Cell Edit"
            />
            <FormControlLabel
                control={<Checkbox name="canUploadExcel" checked={canUploadExcel} onChange={handleCheckbox} />}
                label="Allow Excel Upload"
            />
        </FormGroup>
    )
}
