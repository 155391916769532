import { useDispatch } from 'react-redux'
import { MenuIcon } from 'genesis-suite/components'
import { Profile as ProfileIcon } from 'genesis-suite/icons'
import { interactionCreators } from '../../actions/creators'
import { useIsMobile } from '../../hooks/useIsMobile'
import { useFeature } from '../../lib/featureFlags'
import { styled } from '@mui/material';

const StyledProfileIcon = styled(ProfileIcon)(({ theme }) => ({
    color: theme.palette.text.primary,
}))

/** show a profile button given node name, field and value */
export default function ProfileButton({ nodeName, fieldName, value, disabled = false, onClick }) {
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const [showProfiles] = useFeature('profiles')

    if (!showProfiles) return null

    function handleOpen(e) {
        dispatch(interactionCreators.openProfile(isMobile, nodeName, fieldName, value))
        onClick && onClick(e)
    }

    return <MenuIcon icon={<StyledProfileIcon />} tooltip="Open profile" onClick={handleOpen} disabled={disabled} />
}
