import { Dispatch, SetStateAction } from 'react'
import { isEmpty } from 'lodash'

import { SemanticTypeById } from 'genesis-suite/types/architectureTypes'
import { Dashboard, Module, Element, View } from 'genesis-suite/types/visualTypes'
import { formsService, moduleService, visualService } from '../../lib/services'
import { createSlug } from '../../lib/utils'
import { Converted, Progress, Status } from './migrationTypes'
import convertWidget from './convertWidget'
import { ResourceManager, Timer } from './widgetConverterUtils'

/** Convert visor module to builder module returning new builder module if successful */
export default class Migrator {
    private appName: string
    private moduleName: string
    private moduleNames: string
    private resourceManager: ResourceManager
    private formConfigs: any
    private setStatus: Dispatch<SetStateAction<Status>>
    private setProgress: Dispatch<SetStateAction<Progress>>
    private merge: boolean

    private requestStop = false

    constructor(
        appName,
        moduleName,
        moduleNames,
        semanticTypeById: SemanticTypeById,
        setStatus: Dispatch<SetStateAction<Status>>,
        setProgress: Dispatch<SetStateAction<Progress>>,
        merge = false
    ) {
        this.appName = appName
        this.moduleName = moduleName
        this.moduleNames = moduleNames
        this.resourceManager = new ResourceManager(appName, semanticTypeById)
        this.setStatus = setStatus
        this.setProgress = setProgress
        this.merge = merge
    }

    async start() {
        this.setStatus('converting-perspectives')
        const visorModule = await moduleService.getByName(this.appName, this.moduleName)
        const existingModule = await visualService.getModuleByVisorId(this.appName, visorModule.Id)
        if (!existingModule && this.merge) this.merge = false
        const builderModule = this.merge ? existingModule : await this.createModule(visorModule)

        this.formConfigs = await formsService.getForms(this.appName)

        const configs = visorModule.Widgets
        const perspectives = configs.filter(w => w.Type === 'Container')
        this.setProgress({
            totalPerspectives: perspectives.length,
            totalWidgets: configs.length - perspectives.length,
            convertedPerspectiveCount: 0,
            convertedWidgets: [],
        })

        /** Update progress returning true if user has requested the migration to stop */
        const handleUpdate = (progress: SetStateAction<Progress>) => {
            if (this.requestStop) {
                this.deleteModule(builderModule.id)
                return true
            }

            this.setProgress(progress)
            return false
        }

        const dashboardIdByOldId = await this.convertPerspectives(perspectives, builderModule.id, handleUpdate)
        if (this.requestStop) return this.resetStop()

        this.setStatus('converting-widgets')
        const widgetIdByOldId = await this.convertWidgets(configs, builderModule.id, dashboardIdByOldId, handleUpdate)
        if (this.requestStop) return this.resetStop()

        const builderModuleWithMenu = await this.updateModule(
            builderModule,
            visorModule,
            dashboardIdByOldId,
            widgetIdByOldId
        )

        this.resourceManager.clearCache()

        if (existingModule && !this.merge) await this.deleteModule(existingModule.id)

        return builderModuleWithMenu
    }

    stop() {
        this.requestStop = true
    }

    private async deleteModule(id) {
        await visualService.deleteModule(this.appName, id)
    }

    private resetStop() {
        this.requestStop = false
    }

    private async createModule(visorModule) {
        const body: Module = {
            name: createSlug(`${visorModule.Name}-builder`, this.moduleNames),
            title: visorModule.Title + ' (Builder)',
            migratedVisorId: visorModule.Id,
            theme: visorModule.Theme,
            backgroundImagePath: visorModule.BackgroundImageName,
            hideBusinessExplorer: !visorModule.ShowBusinessExplorer,
            hideNetworkDiagram: !visorModule.EnableNetworkDiagram,
        }
        const builderModule: Module = await visualService.createModule(this.appName, body)
        return builderModule
    }

    private async convertPerspectives(
        perspectives,
        builderModuleId,
        onProgressChange: (progress: SetStateAction<Progress>) => boolean
    ) {
        const dashboardIdByOldId = {}
        const timer = new Timer(500)

        for (const p of perspectives) {
            const existingDashboard = this.merge
                ? await visualService.getPerspectiveByVisorId(this.appName, p.Id)
                : undefined
            const dashboard = existingDashboard || (await this.convertPerspective(p, builderModuleId))
            dashboardIdByOldId[p.Id] = dashboard.id

            if (timer.done) {
                const convertedPerspectiveCount = Object.keys(dashboardIdByOldId).length
                const stop = onProgressChange(s => ({ ...s, convertedPerspectiveCount }))
                if (stop) break

                timer.reset()
            }
        }
        onProgressChange(s => ({ ...s, convertedPerspectiveCount: perspectives.length }))

        return dashboardIdByOldId
    }

    private convertPerspective(visorPerspective, builderModuleId): Promise<Dashboard> {
        const { ContainerConfig, FilterConfig, Title, Id } = visorPerspective
        const { FocalPoint } = ContainerConfig

        const filters = FilterConfig?.map(c => ({
            title: c.Title || 'Empty',
            nodeName: c.Source.ElementName,
            keyPropertyName: c.KeyPropertyName,
            displayPropertyName: c.DisplayPropertyName,
            isMulti: c.AllowMultiple,
        }))

        const body: Dashboard = {
            title: Title,
            focalPoint: FocalPoint,
            migratedVisorId: Id,
            moduleId: builderModuleId,
            ...(filters && { filters }),
        }

        return visualService.createDashboard(this.appName, body)
    }

    private async convertWidgets(
        configs,
        builderModuleId,
        dashboardIdByOldId,
        onProgressChange: (progress: SetStateAction<Progress>) => boolean
    ) {
        const perspectives = configs.filter(w => w.Type === 'Container')
        let convertedWidgets: Converted[] = []

        const timer = new Timer(500)

        for (const p of perspectives) {
            let convertedPerspectiveWidgets: Converted[] = []
            const visorPerspectiveCells = p.ContainerConfig.Cells
            if (!visorPerspectiveCells?.length) return

            for (const { WidgetId } of visorPerspectiveCells) {
                if (!WidgetId) continue

                let convertedWidgets: Converted[]
                const existingWidget = this.merge
                    ? await visualService.getWidgetByVisorId(this.appName, WidgetId)
                    : undefined
                if (existingWidget) {
                    convertedWidgets = [
                        {
                            status: 'already-converted',
                            type: existingWidget.type,
                            visorId: WidgetId,
                            visorPerspectiveId: p.Id,
                            title: existingWidget.title,
                            builderId: existingWidget.id,
                        },
                    ]
                } else {
                    const widget = configs.find(c => c.Id === WidgetId)
                    if (!widget) continue

                    convertedWidgets = await convertWidget(
                        widget,
                        this.appName,
                        builderModuleId,
                        this.resourceManager,
                        this.formConfigs,
                        dashboardIdByOldId
                    )
                }

                convertedPerspectiveWidgets = [
                    ...convertedPerspectiveWidgets,
                    ...convertedWidgets.map(w => ({ ...w, visorPerspectiveId: p.Id })),
                ]
            }

            await this.addWidgetsToPerspective(p, convertedPerspectiveWidgets, dashboardIdByOldId[p.Id])
            convertedWidgets = [...convertedWidgets, ...convertedPerspectiveWidgets]

            if (timer.done) {
                const stop = onProgressChange(s => ({ ...s, convertedWidgets }))
                if (stop) break

                timer.reset()
            }
        }
        onProgressChange(s => ({ ...s, convertedWidgets }))

        const widgetIdByOldId = convertedWidgets.reduce((acc, cur) => ({ ...acc, [cur.visorId]: cur.builderId }), {})
        return widgetIdByOldId
    }

    private async addWidgetsToPerspective(visorPerspective, convertedWidgets: Converted[], dashboardId) {
        const existingDashboard = this.merge
            ? await visualService.getDashboardById(this.appName, dashboardId)
            : undefined

        let widgets = existingDashboard?.widgets
        if (!widgets) {
            const visorPerspectiveWidgets = visorPerspective.ContainerConfig.Cells
            widgets = convertedWidgets
                .filter(w => w.status === 'success' || w.status === 'already-converted')
                .map(w => {
                    const { Small, Large } = visorPerspectiveWidgets.find(c => c.WidgetId === w.visorId) || {}
                    const small = { ...Small, ...(w.top && { top: w.top }) }
                    const large = { ...Large, ...(w.top && { top: w.top }) }

                    const positions = {
                        ...(!isEmpty(small) && { small }),
                        ...(!isEmpty(large) && { large }),
                    }
                    return { id: w.builderId, ...(!isEmpty(positions) && { positions }) }
                })

            if (!widgets.length) return
        } else {
            const newWidgets = convertedWidgets.filter(c => c.status === 'success').map(w => ({ id: w.builderId }))

            if (!newWidgets.length) return

            widgets = [...widgets, ...newWidgets]
        }

        await visualService.updateDashboard(this.appName, dashboardId, { widgets })
    }

    private async updateModule(baseModule: Module, visorObj, dashboardIdByOldId, widgetIdByOldId): Promise<Module> {
        const { Elements, PowerNavConfig } = visorObj

        const traditionalElements = Elements?.filter(e => !e.WidgetId)

        const elements: Element[] = traditionalElements?.map(
            (e: any): Element => ({
                title: e.DisplayName,
                ...(e.IconPath && { icon: { file: e.IconPath } }),
                nodeName: e.ServiceName,
            })
        )

        function parseView(v): View {
            const { DisplayName, Title, IconPath, PowerNavItems, WidgetId } = v
            const base = { title: Title || DisplayName, ...(IconPath && { icon: { file: IconPath } }) }

            if (PowerNavItems) {
                const views = PowerNavItems.map(parseView)
                return { ...base, type: 'group', views }
            } else {
                const dashboardId = dashboardIdByOldId[WidgetId]
                const widgetId = widgetIdByOldId[WidgetId]
                return { ...base, type: dashboardId ? 'dashboard' : 'widget', id: dashboardId || widgetId }
            }
        }

        let views = PowerNavConfig.PowerNavItems?.map(parseView)

        const perspectiveElements = Elements?.filter(e => Boolean(e.WidgetId))
        if (perspectiveElements?.length) {
            const businessElementViews = perspectiveElements.map(parseView)
            views = [...businessElementViews, ...views]
        }

        const updated: Partial<Module> = { elements, views }

        await visualService.updateModule(this.appName, baseModule.id, updated)

        return { ...baseModule, ...updated }
    }
}
