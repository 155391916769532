import { TooltipType, Tooltip } from 'genesis-suite/types/visualTypes'

/** determine whether count is needed for tooltip */
export function getShowCount(tooltip: Tooltip) {
    if (tooltip?.type !== TooltipType.CUSTOM) return false

    const { totalRecords, totalCategoryRecords, totalSubSeriesRecords } = aggregateFunction
    const requireTotals = [totalRecords.value, totalCategoryRecords.value, totalSubSeriesRecords.value]
    return requireTotals.some(s => tooltip.markdown.indexOf(s) > -1)
}

export const aggregateFunction = {
    valueTotal: {
        label: 'Total of value',
        valueString: 'Total value: valueTotal(property)',
        regexp: /valueTotal\((.*?)\)/,
        value: 'valueTotal',
        description: 'Total of property aggregate value',
        example: 'valueTotal([sum | sales])',
    },
    categoryValueTotal: {
        label: 'Total of category value',
        valueString: 'Total of [category]: categoryValueTotal(property)',
        regexp: /categoryValueTotal\((.*?)\)/,
        value: 'categoryValueTotal',
        description: 'Total of property aggregate value at category',
        example: 'categoryValueTotal([sum | sales])',
    },
    percentOfTotal: {
        label: '% of total',
        valueString: '% of total: percentOfTotal(property)',
        regexp: /percentOfTotal\((.*?)\)/,
        value: 'percentOfTotal',
        description: '% of value to total property aggregate value',
        example: 'percentOfTotal([sum | sales])',
    },
    percentOfCategory: {
        label: '% of category',
        valueString: '% of [category]: percentOfCategory(property)',
        regexp: /percentOfCategory\((.*)\)/,
        value: 'percentOfCategory',
        description: '% of value to property aggregate value of active category',
        example: 'percentOfCategory([sum | sales])',
    },
    totalRecords: {
        label: 'Total records',
        valueString: 'Total Records: totalRecords()',
        regexp: /totalRecords\(\)/,
        value: 'totalRecords',
        description: 'Total record count of data',
        example: 'totalRecords()',
    },
    totalCategoryRecords: {
        label: 'Total category records',
        valueString: 'Total [category] Records: totalCategoryRecords()',
        regexp: /totalCategoryRecords\(\)/,
        value: 'totalCategoryRecords',
        description: 'Total record count of data of active category',
        example: 'totalCategoryRecords()',
    },
    totalSubSeriesRecords: {
        label: 'Total sub-series records',
        valueString: 'Total [sub-series] Records: totalSubSeriesRecords()',
        regexp: /totalSubSeriesRecords\(\)/,
        value: 'totalSubSeriesRecords',
        description: 'Total record count of data of active sub-series',
        example: 'totalSubSeriesRecords()',
    },
}

export const arithmeticFunction = {
    addition: {
        label: 'Addition',
        valueString: 'Add(a,b)',
        regexp: /Add\(\s*(.*?)\s*,\s*(.*?)\s*\)/i,
        value: 'Add',
        description: 'Addition of given values',
        example: 'Add(5,5)',
    },
    subtraction: {
        label: 'Subtraction',
        valueString: 'Subtract(a,b)',
        regexp: /Subtract\(\s*(.*?)\s*,\s*(.*?)\s*\)/i,
        value: 'Subtraction',
        description: 'Subtraction of given values',
        example: 'Subtract(7,5)',
    },
    multiplication: {
        label: 'Multiplication',
        valueString: 'Multiply(a,b)',
        regexp: /Multiply\(\s*(.*?)\s*,\s*(.*?)\s*\)/i,
        value: 'Multiplication',
        description: 'Multiplication of given values',
        example: 'Multiply(7,5)',
    },
    division: {
        label: 'Division',
        valueString: 'Divide(a,b)',
        regexp: /Divide\(\s*(.*?)\s*,\s*(.*?)\s*\)/i,
        value: 'Division',
        description: 'Division of given values',
        example: 'Divide(10,5)',
    },
}
