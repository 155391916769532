import { Box, useTheme, Tooltip, IconButton } from '@mui/material'
import { ChopText } from 'genesis-suite/components'
import makeStyles from '@mui/styles/makeStyles'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import ReorderIcon from '@mui/icons-material/Reorder'
import { appearanceConstants } from '~/constants'
import { LayoutPerspectiveContext } from '~/components/contexts/LayoutPerspectiveContext'
import { useContext } from 'react'
const { ScrollBarWidth } = appearanceConstants

const useStyles = makeStyles(({ spacing, breakpoints, palette, border }) => ({
    rightTools: {
        alignSelf: 'center',
        backgroundColor: palette.background.sideNav,
        borderRadius: border.radius.round,
        display: 'flex',
        justifyContent: 'flex-end',
        padding: spacing(0.25),
    },
}))

export default function TabPerspectiveToolbar({ tabValue, onClickReorder }) {
    const classes = useStyles()
    const { palette, custom } = useTheme()
    const { tabRows, setTabRows, setTabValue, setOpenDialog } = useContext(LayoutPerspectiveContext)
    const defaultIconProps = { style: { fontSize: custom.perspectiveToolbar?.fontSize, color: palette.text.primary } }
    const buttonStyle = {
        padding: custom.perspectiveToolbar?.padding,
        '&.Mui-disabled': {
            cursor: 'default',
            pointerEvents: 'none',
            opacity: 0.5,
        },
    }

    const onClickDelete = () => {
        let data = [...tabRows]
        if (tabValue > -1) {
            data.splice(tabValue, 1)
        }
        setTabRows(data)
        setTabValue(0)
    }

    const onClickEdit = () => {
        setOpenDialog({ data: tabRows[tabValue], open: true })
    }

    return (
        <Box
            className={`tab-perspective-toolbar`}
            sx={{ backgroundColor: 'background.main', paddingX: `${ScrollBarWidth}px`, pt: 1, width: '100%' }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <PerspectiveTitle tabName={tabRows[tabValue].name} />
                <div className={classes.rightTools} data-html2canvas-ignore>
                    <Tooltip title="Edit Tab">
                        <span>
                            <IconButton sx={buttonStyle} onClick={onClickEdit}>
                                <EditIcon {...defaultIconProps} />
                            </IconButton>
                        </span>
                    </Tooltip>
                    {tabRows[tabValue]?.perspective?.length > 1 && (
                        <Tooltip title="Reorder Perspectives">
                            <span>
                                <IconButton sx={buttonStyle} onClick={onClickReorder}>
                                    <ReorderIcon {...defaultIconProps} />
                                </IconButton>
                            </span>
                        </Tooltip>
                    )}
                    <Tooltip title="Delete Tab">
                        <span>
                            <IconButton sx={buttonStyle} onClick={onClickDelete}>
                                <DeleteIcon {...defaultIconProps} />
                            </IconButton>
                        </span>
                    </Tooltip>
                </div>
            </Box>
        </Box>
    )
}

function PerspectiveTitle({ tabName }) {
    return (
        <Box sx={{ flex: 1, overflow: 'hidden', display: 'flex', alignItems: 'center', gap: 1 }}>
            <ChopText variant="h5" showEllipsis>
                {tabName}&nbsp;
            </ChopText>
        </Box>
    )
}
