import React, { useState } from 'react'
import { useSnackbar } from 'notistack'
import { makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import { Autocomplete } from '@mui/material'
import { InfiniteNav } from 'genesis-suite/icons'
import { Spinner } from 'genesis-suite/components'
import { LaunchRounded } from '@mui/icons-material'
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import EmptyIcon from '@mui/icons-material/ImageOutlined'
import { CognitiveIcon, CognitiveIconPicker, MenuIcon } from 'genesis-suite/components'

import { widgetSelectors } from '../../selectors'
import LabeledToggleButton from '../LabeledToggleButton'

const types = {
    FORM: 'Form',
    PERSPECTIVE: 'Container',
    WIDGET: 'CatchAll',
}

const getActionType = action => {
    const { WidgetType } = action || {}
    switch (WidgetType) {
        case undefined:
            return ''
        case 13:
            return types.PERSPECTIVE
        case 25:
            return types.FORM
        default:
            return types.WIDGET
    }
}

const initDraft = action => {
    const { IsPopUp, ...rest } = action || {}
    return {
        IsPopUp: IsPopUp === undefined ? 'pop' : IsPopUp ? 'pop' : 'go',
        ShortName: '',
        PerspectiveId: '',
        WidgetId: '',
        ...rest,
    }
}

const getWidgetTypeNum = actionType => {
    switch (actionType) {
        case types.FORM:
            return 25
        case types.PERSPECTIVE:
            return 13
        default:
            return 1
    }
}

const useStyles = makeStyles(({ palette, spacing }) => ({
    root: {
        height: '100%',
        display: 'flex',
        flexFlow: 'column',
    },
    actionField: { marginBottom: spacing(2.8) },
    btnWrapper: { display: 'flex', width: '100%', justifyContent: 'space-between' },
    deleteBtn: { backgroundColor: palette.status.error },
    submit: { marginLeft: 'auto' },
    iconSelector: { display: 'block', marginBottom: '15px' },
    iconLabel: { paddingLeft: '5px' },
}))

export default function ConfigureAction({ action, onSubmit, onDelete }) {
    const classes = useStyles({})
    const [options, setOptions] = useState([])
    const [loading, setLoading] = useState(false)
    const [draft, setDraft] = useState(initDraft(action))
    const [actionType, setActionType] = useState(getActionType(action))
    const [openIconPicker, setOpenIconPicker] = useState(false)

    const configs = useSelector(widgetSelectors.getWidgetConfigs)
    const { enqueueSnackbar } = useSnackbar()

    const getActionLabel = () => {
        switch (actionType) {
            case types.FORM:
                return 'form'
            case types.PERSPECTIVE:
                return 'perspective'
            default:
                return 'widget'
        }
    }

    const handleActionType = type => {
        setActionType(type)
        setDraft({ ...draft, PerspectiveId: null, WidgetId: null })
        const configArr = Object.values(configs)
        if (type !== types.WIDGET) {
            setOptions(configArr.filter(c => c.Type === type))
        } else {
            setOptions(configArr.filter(c => c.Type !== types.FORM && c.Type !== types.PERSPECTIVE))
        }
    }

    const handleChange = (key, val) => setDraft({ ...draft, [key]: val })

    const handleEvent = ({ target }) => {
        const { name, value } = target
        if (!name) return
        handleChange(name, value)
    }

    const handleWidgetChange = (e, widget) => {
        const key = actionType === types.PERSPECTIVE ? 'PerspectiveId' : 'WidgetId'
        handleChange(key, widget.Id)
    }

    const handleDelete = () => {
        setLoading(true)
        onDelete(action.id).finally(() => setLoading(false))
    }

    const handleSubmit = () => {
        const { WidgetId, IsPopUp } = draft
        const data = {
            ...draft,
            IsPopUp: IsPopUp === 'pop' ? true : false,
            WidgetType: getWidgetTypeNum(actionType),
        }
        if (WidgetId) {
            const perspectiveConfig = Object.values(configs).find(c =>
                c.ContainerConfig?.Cells?.some(cell => cell.WidgetId === WidgetId)
            )
            if (perspectiveConfig) data.PerspectiveId = perspectiveConfig.Id
            else {
                enqueueSnackbar('Error: Unable to find perspective for chosen widget', { variant: 'error' })
                return
            }
        }
        setLoading(true)
        onSubmit(data)
            .catch(() => enqueueSnackbar('An error occurred saving Action. Please try again.', { variant: 'error' }))
            .finally(() => setLoading(false))
    }

    const { ActionName, ShortName, PerspectiveId, WidgetId, IsPopUp, Icon } = draft
    const configVal = configs[WidgetId] || configs[PerspectiveId] || null

    const valid = ActionName && (PerspectiveId || WidgetId) && IsPopUp !== undefined

    const genTadaIcon = icon => ({
        file: icon,
        type: 'tada',
    })

    return (
        <Spinner show={loading}>
            <div className={classes.root}>
                <TextField
                    className={classes.actionField}
                    name="ShortName"
                    value={ShortName}
                    variant="outlined"
                    label="Action name"
                    onChange={handleEvent}
                    helperText="Appears as text on action"
                />
                <TextField
                    className={classes.actionField}
                    required
                    name="ActionName"
                    value={ActionName}
                    variant="outlined"
                    label="Tooltip value"
                    onChange={handleEvent}
                    helperText="Appears as a tooltip on action"
                />
                <FormControl variant="outlined">
                    <InputLabel required id="action-type">
                        Action
                    </InputLabel>
                    <Select
                        className={classes.actionField}
                        fullWidth
                        value={actionType}
                        label="Action"
                        labelId="action-type"
                        id="action-type-select"
                        onChange={e => handleActionType(e.target.value)}
                    >
                        <MenuItem value={types.FORM}>Form</MenuItem>
                        <MenuItem value={types.PERSPECTIVE}>Perspective</MenuItem>
                        <MenuItem value={types.WIDGET}>Widget</MenuItem>
                    </Select>
                </FormControl>
                {actionType && (
                    <Autocomplete
                        className={classes.actionField}
                        fullWidth
                        disableClearable
                        options={options}
                        value={configVal}
                        label={actionType}
                        disabled={!actionType}
                        onChange={handleWidgetChange}
                        getOptionLabel={opt => opt.Title}
                        renderInput={params => (
                            <TextField
                                label={actionType ? `Choose ${getActionLabel()}` : ''}
                                {...params}
                                variant="outlined"
                            />
                        )}
                    />
                )}
                <FormControl variant="outlined">
                    <div className={classes.iconLabel}>Icon</div>
                    <div className={classes.iconSelector}>
                        <MenuIcon
                            open={openIconPicker}
                            tooltip="Select Icon"
                            buttonProps={{ size: 'small' }}
                            popoverProps={{
                                anchorOrigin: { vertical: 'top', horizontal: 'left' },
                                transformOrigin: { vertical: 'bottom', horizontal: 'left' },
                            }}
                            icon={
                                Icon ? (
                                    <CognitiveIcon fontSize="small" icon={genTadaIcon(Icon)} />
                                ) : (
                                    <EmptyIcon fontSize="small" />
                                )
                            }
                            onClick={() => setOpenIconPicker(true)}
                            onClose={() => setOpenIconPicker(false)}
                        >
                            <CognitiveIconPicker
                                iconTypes={['tada']}
                                onSelect={icon => {
                                    setOpenIconPicker(false)
                                    handleChange('Icon', icon.file)
                                }}
                            />
                        </MenuIcon>
                    </div>
                </FormControl>
                <Box mb={2}>
                    <LabeledToggleButton
                        value={IsPopUp}
                        options={[
                            { label: <LaunchRounded />, value: 'pop', tip: 'Launch popup' },
                            { label: <InfiniteNav />, value: 'go', tip: 'Go to' },
                        ]}
                        onChange={(e, val) => handleChange('IsPopUp', val)}
                    />
                </Box>
                <div className={classes.btnWrapper}>
                    {onDelete && (
                        <Button className={classes.deleteBtn} variant="contained" onClick={handleDelete}>
                            Delete
                        </Button>
                    )}
                    <Button
                        className={classes.submit}
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={!valid}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </Spinner>
    )
}
