import {
    DeleteRounded,
    Edit,
    ExpandLessRounded,
    ExpandMoreRounded,
    ErrorRounded,
    PublishRounded,
    PublishedWithChangesRounded,
    DoneAllRounded,
} from '@mui/icons-material'

import {
    Box,
    Checkbox,
    Chip,
    Collapse,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListSubheader,
    Tooltip,
} from '@mui/material'
import { ChopText, SwalContext } from 'genesis-suite/components'
import { PublishScenarioStatus, Scenario } from 'genesis-suite/types/networkTypes'
import { useContext, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { scenarioCreators } from '../../actions/creators'
import { authSelectors, scenarioSelectors } from '../../selectors'
import CriteriaDisplay from './CriteriaDisplay'
import { EditScenario } from './EditScenario'
import Markdown from '../Markdown'
import { FilterGroup } from 'genesis-suite/types/visualTypes'
import { PublishScenario } from './PublishScenario'
import { formatDateTime } from '~/lib/utils'

interface DisplayProps {
    scenario: Scenario
    isSelected: boolean
    onDelete: () => void
    refreshScenarios: () => void
    onScenarioClick: (scenario: Scenario) => void
}

function ScenarioDisplay({ scenario, isSelected, onDelete, refreshScenarios, onScenarioClick }: DisplayProps) {
    const [open, setOpen] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [showPublish, setShowPublish] = useState(false)
    const dispatch = useDispatch()
    const { confirm } = useContext(SwalContext)

    const activeScenarios = useSelector(scenarioSelectors.getActiveScenarios)
    const isActive = activeScenarios.some(activeScenario => activeScenario.id === scenario.id)
    const isPublishScenario = useSelector(authSelectors.getCanPublishScenario)

    const prevent = (e: React.MouseEvent) => {
        e.stopPropagation()
        e.preventDefault()
    }

    const toggle = e => {
        prevent(e)
        setOpen(!open)
    }
    const handleEdit = e => {
        prevent(e)
        setShowEdit(true)
    }
    const handleClose = () => setShowEdit(false)

    const handleRequest = e => {
        prevent(e)
        setShowPublish(true)
    }

    const handlePublishClose = () => setShowPublish(false)

    const handlePublishDone = () => {
        refreshScenarios()
        handlePublishClose()
    }

    const handleDelete = async e => {
        prevent(e)
        const response = await confirm(`Delete ${scenario.name}?`, { type: 'warning' })
        if (response.dismiss) return
        onDelete()
    }

    const handleDone = (updated: Scenario) => {
        if (isActive) {
            const currentActiveScenarios = [...activeScenarios].filter(s => updated.id !== s.id)
            dispatch(scenarioCreators.setActiveScenarios([...currentActiveScenarios, updated]))
        }
        refreshScenarios()
        handleClose()
    }

    const errors = validateScenario(scenario)
    const formattedErrors = useMemo(() => formatErrors(errors), [errors])

    const { name, criterias, activeRequest } = scenario

    const isRequestOpen = useMemo(() => activeRequest?.status === PublishScenarioStatus.OPEN || false, [activeRequest])

    const isRequestApproved = useMemo(
        () => activeRequest?.status === PublishScenarioStatus.APPROVE || false,
        [activeRequest]
    )

    return (
        <>
            <ListItemButton
                sx={{ mt: 1, border: 1, borderColor: 'border.main', borderRadius: 1 }}
                onClick={() => onScenarioClick(scenario)}
            >
                <ListItemIcon>
                    <Checkbox color="primary" edge="start" disableRipple checked={isSelected} />
                </ListItemIcon>
                <ChopText tooltipProps={{ placement: 'top' }} showEllipsis variant="subtitle1">
                    {name}
                </ChopText>
                {isActive && <Chip label="Active" color="primary" variant="outlined" size="small" />}
                <Box sx={{ display: 'flex', ml: 'auto', alignItems: 'center' }}>
                    {errors.length > 0 && (
                        <Tooltip componentsProps={{ tooltip: { sx: { maxWidth: 500 } } }} title={formattedErrors}>
                            <ErrorRounded color="error" />
                        </Tooltip>
                    )}
                    {!isRequestApproved && !isRequestOpen && (
                        <Tooltip title="Edit Scenario">
                            <IconButton onClick={handleEdit}>
                                <Edit />
                            </IconButton>
                        </Tooltip>
                    )}
                    {isRequestOpen && (
                        <Tooltip
                            title={`Scenario Publish Requested by ${activeRequest?.requestedBy} on ${formatDateTime(
                                activeRequest?.requestedOn
                            )} CST`}
                        >
                            <IconButton>
                                <PublishedWithChangesRounded />
                            </IconButton>
                        </Tooltip>
                    )}
                    {isRequestApproved && (
                        <Tooltip
                            title={`Scenario Publish Approved by ${activeRequest?.approvedBy} on ${formatDateTime(
                                activeRequest?.approvedOn
                            )} CST`}
                        >
                            <IconButton>
                                <DoneAllRounded />
                            </IconButton>
                        </Tooltip>
                    )}
                    {!isRequestApproved && !isRequestOpen && isPublishScenario && (
                        <Tooltip title="Publish Scenario">
                            <IconButton onClick={handleRequest}>
                                <PublishRounded />
                            </IconButton>
                        </Tooltip>
                    )}
                    {!isRequestApproved && !isRequestOpen && (
                        <Tooltip title="Delete Scenario">
                            <IconButton onClick={handleDelete}>
                                <DeleteRounded />
                            </IconButton>
                        </Tooltip>
                    )}
                    <IconButton onClick={toggle}>{open ? <ExpandLessRounded /> : <ExpandMoreRounded />}</IconButton>
                </Box>
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List
                    sx={theam => ({
                        margin: 0,
                        paddingTop: 0,
                        padding: theam.spacing(1),
                        border: '1px solid',
                        borderColor: '#BDBDBD',
                        borderRadius: theam.spacing(0.5),
                        borderTop: 0,
                    })}
                    subheader={
                        <ListSubheader
                            sx={{
                                color: 'text.primary',
                                lineHeight: '28px',
                                fontWeight: 600,
                            }}
                        >
                            Scenario Conditions
                        </ListSubheader>
                    }
                >
                    {criterias?.map((c, i) => (
                        <Box key={i} sx={theam => ({ paddingLeft: theam.spacing(3) })}>
                            <CriteriaDisplay key={c.id} criteria={c} index={i} />
                        </Box>
                    ))}
                </List>
            </Collapse>
            {showEdit && <EditScenario scenario={scenario} onClose={handleClose} onDone={handleDone} />}
            {showPublish && (
                <PublishScenario scenario={scenario} onClose={handlePublishClose} onDone={handlePublishDone} />
            )}
        </>
    )
}

export default ScenarioDisplay

const formatErrors = (errors: string[]) => {
    return <Markdown>{errors.join('\n')}</Markdown>
}

const validateScenario = (scenario: Scenario): string[] => {
    const { criterias, resourceName, resourceId } = scenario
    const _errors = []
    if (!resourceName) _errors.push('Target Node/Link missing.')
    if (!resourceId) _errors.push('Target Node/Link misconfigured, please reselect.')
    if (criterias) {
        criterias.reduce((errors, criteria, i) => {
            const { scenarioPropertyName, scenarioValue, criteriaFilter } = criteria
            if (!scenarioPropertyName) errors.push(`Condition ${i + 1} missing target property.`)
            if (!scenarioValue) errors.push(`Condition ${i + 1} missing target value.`)
            if (criteriaFilter) {
                errors.concat(validateFilterGroup(criteriaFilter.items, errors, i))
            }
            return errors
        }, _errors)
    }
    return _errors
}

const validateFilterGroup = (items: FilterGroup['items'], _errors: string[], index: number): string[] => {
    if (!items) return _errors
    return items.reduce((errors, item) => {
        const { type } = item
        if (type === 'group') validateFilterGroup(item.items, errors, index)
        else {
            const { field, comparison, values } = item
            if (!field?.name) errors.push(`Condition ${index} missing filter property.`)
            if (!comparison) errors.push(`Condition ${index} missing filter comparison.`)
            if (!values || !values.length) errors.push(`Condition ${index} missing filter value(s).`)
        }
        return errors
    }, _errors)
}
