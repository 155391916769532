import { moduleService } from '../../lib/services'
import { moduleSelectors } from '../../selectors'
import { logoTypes } from '../types'

const logoSuccess = src => ({
    type: logoTypes.MODULE_LOGO_SUCCESS,
    payload: { src },
})

const logoFailure = () => ({ type: logoTypes.MODULE_LOGO_FAILURE })

const getModuleLogo = (appName, moduleName) => (dispatch, getState) => {
    const isV2 = moduleSelectors.getIsV2(getState())
    if (isV2) return dispatch(logoSuccess())

    moduleService
        .getLogo(appName, moduleName)
        .then(imageBlob => {
            dispatch(logoSuccess(URL.createObjectURL(imageBlob)))
        })
        .catch(() => {
            dispatch(logoFailure())
        })
}

export const logoCreators = { getModuleLogo }
