import { Box, Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { MenuIcon } from 'genesis-suite/components'
import { Close, ExportAsPDF } from 'genesis-suite/icons'
import { useRef, useState } from 'react'
import ReactToPrint from 'react-to-print'
import tadaWatermark from '../assets/img/tada-watermark.png'
import PerspectivePrintView from './widgets/PerspectivePrintView'
import LocalPrintshopRoundedIcon from '@mui/icons-material/LocalPrintshopRounded'

const useStyles = makeStyles(() => ({
    backdrop: { cursor: 'not-allowed' },
    waterMark: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        opacity: '0.2',
        width: '30%',
        zIndex: '999999',
    },
    displayAll: {
        overflow: 'visible !important',
    },
}))

export default function PerspectiveExport({ disabled, iconProps, buttonStyle, configs, layouts, currentLayout, id }) {
    const classes = useStyles()
    const printContent = useRef(null)

    const copyLayouts = { ...layouts }
    const copyConfigs = [...configs]

    const [openPrintDialog, setOpenPrintDialog] = useState(false)
    const dialogRef = useRef()

    const handleClick = () => {
        setOpenPrintDialog(!openPrintDialog)
    }

    const handleClose = () => {
        setOpenPrintDialog(!openPrintDialog)
    }

    const customToPrint = printWindow => {
        const printContentDoc = printWindow.contentDocument || printWindow.contentWindow?.document
        const printedPerspectiveContainer = printContentDoc.getElementById('perspective-clone-container')
        const printedPerspectiveLayout = printContentDoc.getElementById('perspective-clone-layout')
        const perspectiveWidgets = printedPerspectiveContainer.querySelector('.widget-parent-wrapper-clone')

        if (printContent && !document.getElementById('pdf-watermark')) {
            printedPerspectiveContainer.style.position = 'relative'
            const watermark = document.createElement('img')
            watermark.id = 'pdf-watermark'
            watermark.src = tadaWatermark
            Object.assign(watermark.style, {
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                opacity: '0.2',
                width: '30%',
                zIndex: '999999',
            })

            printedPerspectiveContainer.appendChild(watermark)
        }

        const widgetsHeight = perspectiveWidgets.scrollHeight + 200

        printedPerspectiveLayout.style.overflow = 'visible !important'
        printedPerspectiveLayout.style.height = `${widgetsHeight}px`
        printedPerspectiveContainer.style.height = `${widgetsHeight}px`

        printWindow.contentWindow.print()

        return Promise.resolve()
    }

    const Orientation = () => <style type="text/css">{`@media print{@page {size: landscape }`}</style>

    return (
        <>
            <MenuIcon
                buttonProps={{ sx: buttonStyle }}
                closeButton
                icon={<ExportAsPDF {...iconProps} />}
                disabled={disabled}
                title="Export as PDF"
                tooltip="Export perspective as pdf"
                onClick={handleClick}
            ></MenuIcon>

            <Dialog
                open={openPrintDialog}
                scroll="paper"
                onClose={handleClose}
                maxWidth="lg"
                fullWidth
                PaperProps={{ sx: { height: '80vh', width: '1740px', maxWidth: '1740px' } }}
                ref={dialogRef}
            >
                <DialogActions sx={{ cursor: 'pointer' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 1 }}>
                        <ReactToPrint
                            bodyClass={classes.displayAll}
                            print={customToPrint}
                            trigger={() => (
                                <Button variant="contained" color="primary" startIcon={<LocalPrintshopRoundedIcon />}>
                                    Print
                                </Button>
                            )}
                            content={() => printContent.current}
                        />
                        <Close onClick={handleClose} />
                    </Box>
                </DialogActions>
                <DialogContent>
                    <Box sx={{ height: '100%' }} ref={printContent}>
                        <PerspectivePrintView
                            configs={copyConfigs}
                            currentLayout={currentLayout}
                            layouts={copyLayouts}
                            id={id}
                            isEditing={false}
                        />
                    </Box>
                </DialogContent>
            </Dialog>
            {/* Setting the print page's width and height while printing */}
            <Orientation />
        </>
    )
}
