import { useSelector } from 'react-redux'
import useSWR, { SWRConfiguration, useSWRConfig } from 'swr'

import { DraftKey } from 'genesis-suite/types/visualTypes'
import { visualService } from '../lib/services'
import { applicationSelectors, deploymentSelectors } from '../selectors'
import { useFeature } from '../lib/featureFlags'

const makeFocalPointsKey = (appName: string, view: DraftKey, enabled: boolean): [string, string, DraftKey, boolean] => [
    'focal-points',
    appName,
    view,
    enabled,
]

const swrOptions: SWRConfiguration = {
    revalidateOnFocus: false,
    errorRetryCount: 2,
    dedupingInterval: 60000,
}

export default function useNavigableFocalPoints() {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const viewFlag = useSelector(deploymentSelectors.getDeploymentViewFlag)
    const [enabled] = useFeature('builder')

    return useSWR(
        makeFocalPointsKey(appName, viewFlag, enabled),
        ([_, appName, viewFlag, enabled]) =>
            enabled ? visualService.getDashboardDistinctList(appName, 'focalPoint', undefined, viewFlag) : undefined,
        swrOptions
    )
}

export function useRevalidateNavigableFocalPoints() {
    const { mutate } = useSWRConfig()
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const viewFlag = useSelector(deploymentSelectors.getDeploymentViewFlag)
    const [enabled] = useFeature('builder')

    return () => mutate(makeFocalPointsKey(appName, viewFlag, enabled))
}
