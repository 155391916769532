import React, { useEffect, useState, useRef } from 'react'
import { uniq } from 'lodash'
import { useSnackbar } from 'notistack'
import { Clear } from '@mui/icons-material'
import DoneIcon from '@mui/icons-material/Done'
import makeStyles from '@mui/styles/makeStyles'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import DragIcon from '@mui/icons-material/DragIndicator'
import { Box, MenuItem, TextField, IconButton, Button, Tooltip, Typography } from '@mui/material'

import { DragItem, DragList } from 'genesis-suite/components'
import { CustomFormField, FieldDisplayType } from 'genesis-suite/types/visualTypes'

const useStyles = makeStyles(({ palette, spacing }) => ({
    select: { width: 125 },
    dragItem: { display: 'flex', alignItems: 'center', gridGap: '8px', background: '#fff', padding: spacing(0.5) },
    disableCover: {
        zIndex: 2,
        position: 'absolute',
        top: 0,
        left: 0,
        background: `${palette.grayscale.dark}44`,
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

interface Props {
    config?: CustomFormField
    /** If it should be disabled, custom message to display */
    disableMessage?: string
    onChange: (customField: CustomFormField) => void
}

const NONE = 'none'

export default function CustomRenderBuilder({ config, disableMessage, onChange }: Props) {
    const classes = useStyles({})
    const { display, values } = config || {}

    const handleChange = (change: Partial<CustomFormField>) => onChange({ ...config, ...change })

    const handleDisplayChange = display => {
        if (display === NONE) onChange(undefined)
        else handleChange({ display })
    }

    const invalid = values && (values.length === 0 || uniq(values).length !== values.length)

    return (
        <>
            <Box mt={1} display="flex" flexDirection="column" height="100%" overflow="hidden">
                <Box display="flex" alignItems="flex-end" gap="8px">
                    <TextField
                        className={classes.select}
                        select
                        label="Type"
                        value={display || NONE}
                        onChange={e => handleDisplayChange(e.target.value)}
                    >
                        <MenuItem key={NONE} value={NONE}>
                            None
                        </MenuItem>
                        {Object.values(FieldDisplayType).map(type => (
                            <MenuItem key={type} value={type}>
                                {type}
                            </MenuItem>
                        ))}
                    </TextField>

                    {display === FieldDisplayType.RADIO && (
                        <Box>
                            <Tooltip
                                placement="right"
                                title="Lists will show as radio buttons in input form and a select drop down in the table"
                            >
                                <InfoIcon fontSize="small" />
                            </Tooltip>
                        </Box>
                    )}
                </Box>

                <Box mt={2} display="flex" flexDirection="column" overflow="hidden">
                    <ListBuilder config={config} onValuesChange={values => handleChange({ values })} />
                    <BooleanBuilder config={config} onValuesChange={values => handleChange({ values })} />
                </Box>

                {invalid && (
                    <Box mt={2}>
                        <Typography color="error">
                            {values.length === 0 ? 'You need at least one value' : 'Duplicate value'}
                        </Typography>
                    </Box>
                )}
            </Box>

            {disableMessage ? (
                <div className={classes.disableCover}>
                    <Typography>Custom rendering disabled. {disableMessage}</Typography>
                </div>
            ) : null}
        </>
    )
}

interface BuilderProps {
    config: CustomFormField
    onValuesChange: (values: Array<string | number>) => void
}

function ListBuilder({ config, onValuesChange }: BuilderProps) {
    const { display, values } = config || {}
    const { enqueueSnackbar: showSnackbar } = useSnackbar()

    const [newValue, setNewValue] = useState('')
    const [draft, setDraft] = useState([])

    const isList = display === FieldDisplayType.SELECT || display === FieldDisplayType.RADIO
    const classes = useStyles()

    const hasMounted = useRef(false)
    useEffect(() => {
        if (isList) {
            if (values && !hasMounted.current) setDraft(values)
            else onValuesChange(draft)
        }
        hasMounted.current = true
    }, [isList])

    function handleValuesChange(values) {
        setDraft(values)
        onValuesChange(values)
    }

    if (!isList) return null

    function handleNew() {
        if (values?.includes(newValue)) {
            showSnackbar('Value already exists!', { variant: 'error' })
            return
        }

        const nextValues = values ? [...values, newValue] : [newValue]
        setNewValue('')
        handleValuesChange(nextValues)
    }

    function handleDelete(index) {
        handleValuesChange(values.filter((_, i) => i !== index))
    }

    const deleteAll = () => handleValuesChange([])

    function handleDragEnd(values) {
        handleValuesChange(values)
    }

    return (
        <>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="subtitle1">Values</Typography>
                <Box visibility={draft.length ? 'visible' : 'hidden'}>
                    <Button startIcon={<Clear fontSize="small" />} onClick={deleteAll}>
                        Clear all
                    </Button>
                </Box>
            </Box>

            <Box overflow="auto">
                <DragList onDragEnd={handleDragEnd} items={draft}>
                    {draft.map((value, index) => (
                        <DragItem key={value} itemId={value} index={index}>
                            <div className={classes.dragItem}>
                                <DragIcon fontSize="small" />
                                <Typography>{value}</Typography>
                                <IconButton size="small" onClick={() => handleDelete(index)}>
                                    <Clear fontSize="small" />
                                </IconButton>
                            </div>
                        </DragItem>
                    ))}
                </DragList>
            </Box>

            <Box display="flex" alignItems="center" gap="8px" ml={1}>
                <TextField
                    fullWidth
                    value={newValue}
                    placeholder="New value"
                    onChange={e => setNewValue(e.target.value)}
                    onKeyPress={e => e.key === 'Enter' && handleNew()}
                />

                <IconButton disabled={!newValue || values?.includes(newValue)} onClick={handleNew} size="large">
                    <DoneIcon />
                </IconButton>
            </Box>
        </>
    )
}

function BooleanBuilder({ config, onValuesChange }: BuilderProps) {
    const { display, values } = config || {}

    const [draft, setDraft] = useState<Array<string | number>>(['No', 'Yes'])

    const isBoolean = display === FieldDisplayType.CHECKBOX || display === FieldDisplayType.TOGGLE

    const hasMounted = useRef(false)
    useEffect(() => {
        if (isBoolean) {
            if (values && !hasMounted.current) setDraft([values[0], values[1]])
            else onValuesChange(draft)
        }
        hasMounted.current = true
    }, [isBoolean])

    function updateValues(values) {
        setDraft(values)
        onValuesChange(values)
    }

    if (!isBoolean) return null

    return (
        <>
            <BooleanValue
                label="Off value:"
                value={draft[0]}
                onChange={value => setDraft(s => [value, s[1]])}
                onDone={() => updateValues(draft)}
                onClear={() => updateValues([null, draft[1]])}
            />

            <BooleanValue
                label="On value:"
                value={draft[1]}
                onChange={value => setDraft(s => [s[0], value])}
                onDone={() => updateValues(draft)}
                onClear={() => updateValues([draft[0], null])}
            />
        </>
    )
}

function BooleanValue({ label, value, onChange, onDone, onClear }) {
    function handleChange(e) {
        const value = e.target.value
        onChange(value ? value : null)
    }

    return (
        <Box display="grid" gridTemplateColumns="65px 250px 40px" alignItems="center" gap="8px">
            <Typography>{label}</Typography>

            <TextField
                fullWidth
                value={value || ''}
                placeholder="Null"
                onChange={handleChange}
                onKeyPress={e => e.key === 'Enter' && onDone()}
                onBlur={onDone}
            />

            <IconButton disabled={value == null} onClick={onClear} size="large">
                <Clear />
            </IconButton>
        </Box>
    )
}
