import { Box, Button, Typography } from '@mui/material'
import { VisualObjectByType, VisualType } from 'genesis-suite/types/visualTypes'
import { useState } from 'react'
import VisualHistoryDialog from './VisualHistoryDialog'

interface Props<T extends VisualType> {
    config: VisualObjectByType<T>
    type: T
}

export default function VisualVersionWithHistoryButton<T extends VisualType>({ config, type }: Props<T>) {
    const [open, setOpen] = useState(false)
    if (!config) return null

    const { active, draft, revision } = config

    if (!active) return null

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <InfoItem label="Version" value={`${(revision || 0) + 1}${draft ? ` (${draft.status} draft)` : ''}`} />
            {Boolean(revision || draft?.visualId) && (
                <>
                    <Button onClick={() => setOpen(true)}>See history</Button>
                    <VisualHistoryDialog config={config} onDone={() => setOpen(false)} open={open} type={type} />
                </>
            )}
        </Box>
    )
}

function InfoItem({ label, value }) {
    return (
        <Box display="flex" alignItems="center" gap="8px">
            <Typography variant="caption">{label}:</Typography>
            <Typography>{value}</Typography>
        </Box>
    )
}
