import amplitude from 'amplitude-js'

const api = window.TADA_APIS.AMPLITUDE_KEY

const amplitudeInstance = api ? amplitude.getInstance() : null

if (amplitudeInstance) amplitudeInstance.init(api)

const defaultProperties = { environment: window.TADA_APIS.ENVIRONMENT }

/**
 * Logs events to amplitude instance
 * @param {string} type Event name
 * @param {object=} properties optional properties tied to the event
 */
function logEvent(type, properties) {
    if (amplitudeInstance) {
        if (typeof type !== 'string') {
            console.error('Amplitude event not logged. Type must me a string')
            return
        }

        amplitudeInstance.logEvent(type, { ...defaultProperties, ...properties })
    }
}

function setUserId(userId) {
    if (amplitudeInstance) amplitudeInstance.setUserId(userId)
}

function setApplicationName(appName) {
    if (amplitudeInstance) defaultProperties.applicationName = appName
}

export { amplitudeInstance, defaultProperties, logEvent, setUserId, setApplicationName }
