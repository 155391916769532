import { IconButton, Tooltip } from '@mui/material'
import { useState } from 'react'
import WidgetGTMDialog from './WidgetGTMDialog'
import { DrillDown } from 'genesis-suite/icons'

interface GTMButtonParams {
    iconStyle: any
    buttonStyle: any
    widgetId: string
    transactionNodes: any[]
}

export default function WidgetGTMButton({ iconStyle, buttonStyle, widgetId, transactionNodes }: GTMButtonParams) {
    const [showWidgetGTM, setShowWidgetGTM] = useState(false)

    return (
        <>
            <Tooltip title="Show Transactions data">
                <IconButton sx={buttonStyle} onClick={() => setShowWidgetGTM(true)}>
                    <DrillDown sx={iconStyle} />
                </IconButton>
            </Tooltip>
            <WidgetGTMDialog
                open={showWidgetGTM}
                setOpen={setShowWidgetGTM}
                widgetId={widgetId}
                onClose={() => setShowWidgetGTM(false)}
                transactionNodes={transactionNodes}
            />
        </>
    )
}
