import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, matchPath, useNavigate } from 'react-router-dom'

import { authCreators, applicationCreators } from '../actions/creators'
import { routePaths } from '../lib/routes'
import userManager from '../lib/userManager'

const stateErrorMessage = 'No matching state found in storage'

export default function SSOCallback() {
    const [error, setError] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        ;(async () => {
            const user = await userManager.getUser()
            if (user) return successCallback(user)

            userManager
                .signinRedirectCallback()
                .then(successCallback)
                .catch(err => {
                    if (err.message === stateErrorMessage) {
                        navigate(routePaths.LOGIN)
                    } else {
                        console.error(err)
                        setError(true)
                    }
                })
        })()
    }, [])

    function successCallback(user) {
        dispatch(authCreators.loginSuccess(user))
        dispatch(applicationCreators.getAllApplications())
        navigate(validPath(user.state?.path))
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                color: '#000000',
                padding: '25px',
            }}
        >
            {error && (
                <Box>
                    <Typography>
                        Whoops! An error occurred while signing you in. If the problem persists, please contact support
                        with the following error message:
                    </Typography>
                    <Typography>
                        Please click <Link to={routePaths.LOGIN}>here</Link> to try again
                    </Typography>
                </Box>
            )}
        </Box>
    )
}

function validPath(path) {
    if (
        !path ||
        matchPath(routePaths.CALLBACK, path) ||
        matchPath(routePaths.EDIT, path) ||
        matchPath(routePaths.SILENT_RENEW, path)
    )
        return routePaths.SELECT
    return path
}
