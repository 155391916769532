import React, { useState } from 'react'
import {
    Checkbox,
    TextField,
    Button,
    Popper,
    Grow,
    Paper,
    Box,
    List,
    ListItem,
    ListItemText,
    IconButton,
    Tooltip,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { Property } from 'genesis-suite/types/networkTypes'
import { CheckedCircle, SelectColumns } from 'genesis-suite/icons'
import { FormColumn } from 'genesis-suite/types/visualTypes'
import { propertyToColumn } from '../../../lib/formUtils'

const useStyles = makeStyles(({ spacing }) => ({
    colBtn: { fontWeight: 'normal', fontSize: '.75rem', borderRadius: 4, marginRight: spacing(1) },
    colPaper: { display: 'flex', flexFlow: 'column', padding: spacing(1), maxHeight: '60vh' },
    colList: { overflow: 'auto' },
}))

interface ColSelectProps {
    columns: FormColumn[]
    properties: Property[]
    onFieldSelect: (id: string) => void
    onChange: (columns: FormColumn[]) => void
}

export default function ColumnSelector({ columns = [], properties, onFieldSelect, onChange }: ColSelectProps) {
    const classes = useStyles({})
    const [searchText, setSearchText] = useState<string>('')
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    function handleClick(event: React.MouseEvent<HTMLElement>) {
        setSearchText('')
        setAnchorEl(anchorEl ? null : event.currentTarget)
    }

    function hasColumn(property: Property): boolean {
        return Boolean(columns.find(col => col.property.id === property.id))
    }

    function getCol(property: Property): FormColumn {
        return columns.find(col => col.property.id === property.id)
    }

    function handlePropClick(property: Property) {
        if (!hasColumn(property)) {
            const column = propertyToColumn(property)
            onFieldSelect(column.id)
            onChange([...columns, column])
        } else {
            const nextColumns = columns.filter(col => col.property.id !== property.id)
            onChange(nextColumns)
        }
    }

    function unselectAll() {
        const nextColumns = columns.filter(col => col.property.isPrimary)
        onChange(nextColumns)
    }

    function selectAll() {
        const propsWithoutCols = properties.filter(prop => !hasColumn(prop))
        const nextColumns = columns.concat(propsWithoutCols.map(prop => propertyToColumn(prop)))
        onChange(nextColumns)
    }

    const open = Boolean(anchorEl)

    return (
        <div>
            <Tooltip title="Select Columns">
                <IconButton onClick={handleClick} size="large">
                    <SelectColumns />
                </IconButton>
            </Tooltip>
            <Popper open={open} anchorEl={anchorEl} style={{ zIndex: 2 }} transition>
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps}>
                        <Paper className={classes.colPaper}>
                            <Box display="flex" alignItems="center">
                                <TextField
                                    fullWidth
                                    autoFocus
                                    label="Find column"
                                    placeholder="Column title"
                                    style={{ marginBottom: 8 }}
                                    onChange={e => setSearchText(e.target.value)}
                                />
                                <Tooltip title="Confirm">
                                    <IconButton onClick={handleClick} size="large">
                                        <CheckedCircle fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Button variant="outlined" className={classes.colBtn} onClick={unselectAll}>
                                    Unselect All
                                </Button>
                                <Button variant="outlined" className={classes.colBtn} onClick={selectAll}>
                                    Select All
                                </Button>
                            </Box>
                            <List className={classes.colList} dense>
                                {properties
                                    .sort((a, b) => (a.isPrimary && b.isPrimary ? 0 : a.isPrimary ? -1 : 0))
                                    .filter(p => p.name.toLowerCase().includes(searchText.toLowerCase()))
                                    .map(p => (
                                        <ListItem
                                            key={p.id}
                                            disabled={p.isPrimary}
                                            button
                                            onClick={() => handlePropClick(p)}
                                        >
                                            <Checkbox checked={hasColumn(p)} />
                                            <ListItemText primary={hasColumn(p) ? getCol(p).title : p.name} />
                                        </ListItem>
                                    ))}
                            </List>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    )
}
