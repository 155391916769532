import { messengerCreators } from '~/actions/creators/messenger.creators'
import { toCamel } from 'genesis-suite/utils'

export const subscribeToMessageNotifications = (userId, domainId) => {
    const { $ } = window
    const { connection } = $

    const subscriber = {
        id: connection.hub.id,
        Filters: [
            {
                key: 'userId',
                value: userId,
            },
            {
                key: 'domainId',
                value: domainId,
            },
        ],
    }
    connection.ChatNotificationsHub.server.subscribe('ChatRoom', subscriber)
}

const processedMessages = new Map()

const debounceMessage = (messageId, delay) => {
    const now = Date.now()
    if (processedMessages.has(messageId)) {
        const lastProcessedTime = processedMessages.get(messageId)
        if (now - lastProcessedTime < delay) {
            return false
        }
    }
    processedMessages.set(messageId, now)
    return true
}

export const registerMessagesClientFunctions = dispatch => {
    const { $ } = window
    const messagesHub = $.connection.ChatNotificationsHub

    if (messagesHub) {
        messagesHub.client.notify = message => {
            const { Type } = message
            switch (Type) {
                case 'UserMessage':
                    let chatMessage = JSON.parse(message.MessageAsJsonText)
                    chatMessage = toCamel(chatMessage)
                    if (debounceMessage(chatMessage.id, 1000)) {
                        // 1000 ms debounce delay
                        // updating messages to show latest chat on top
                        dispatch(messengerCreators.addMessageToChat(chatMessage))
                        // updating user chat to show latest chat message
                        dispatch(messengerCreators.pushMessageToUserChat(chatMessage, chatMessage.senderId))
                    }
                    break
            }
        }
    }
}
