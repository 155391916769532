import { useDispatch, useSelector } from 'react-redux'
import { scenarioSelectors, getSelectedBackup } from '../selectors'
import { Scenario } from 'genesis-suite/types/networkTypes'
import { Chip, Tooltip, Box, Typography, ListItemText } from '@mui/material'
import { scenarioCreators, backupCreators } from '../actions/creators'
import { getCriteriaSentence } from './scenarios/helpers'
import moment from 'moment'
import { useMemo } from 'react'
import { perspectiveCreators } from '~/actions/creators/perspective.creators'

const Banner = () => {
    const activeScenarios = useSelector(scenarioSelectors.getActiveScenarios)
    const selectedBackup = useSelector(getSelectedBackup)
    const bannerContent = useMemo(() => {
        const contentArray = []
        if (activeScenarios.length > 0) {
            contentArray.push(<ScenarioBanner scenarios={activeScenarios} />)
        }
        if (selectedBackup.length > 0) {
            contentArray.push(<BackupBanner getSelectedBackup={selectedBackup} />)
        }
        return contentArray
    }, [activeScenarios, selectedBackup])

    return (
        <>
            {bannerContent?.length > 0 ? (
                <Box
                    sx={{
                        p: 1,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        bgcolor: 'primary.main',
                        justifyContent: 'center',
                        color: 'primary.contrastText',
                    }}
                >
                    {bannerContent.map(item => item)}
                </Box>
            ) : null}
        </>
    )
}

export default Banner

const ScenarioBanner = ({ scenarios }: { scenarios: Scenario[] }) => {
    const dispatch = useDispatch()
    const removeScenario = (scenario: Scenario) => dispatch(scenarioCreators.removeScenario(scenario.id))

    return (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <Typography variant="h6">Active Scenarios:</Typography>
            {scenarios.map(scenario => {
                if (!scenario.criterias) return null
                const tipTitle = scenario.criterias.map((c, i) => <ListItemText primary={getCriteriaSentence(c, i)} />)
                return (
                    <Tooltip key={scenario.id} title={tipTitle}>
                        <Chip
                            sx={{ color: 'inherit', borderColor: 'inherit', '& *': { color: 'inherit !important' } }}
                            variant="outlined"
                            key={scenario.id}
                            label={scenario.name}
                            onDelete={() => removeScenario(scenario)}
                        />
                    </Tooltip>
                )
            })}
        </Box>
    )
}

const BackupBanner = ({ getSelectedBackup }) => {
    const dispatch = useDispatch()

    const removeBackup = () => {
        dispatch(backupCreators.removeSelectedSchedules())
        dispatch(perspectiveCreators.refreshPerspective())
    }

    return (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <Typography variant="h6">Selected Backup:</Typography>
            {getSelectedBackup?.map(item => {
                return (
                    <Tooltip key={item.Id} title={moment(item.StartedOn).format('MM/DD/YYYY hh:mm:ss A')}>
                        <Chip
                            sx={{ color: 'inherit', borderColor: 'inherit', '& *': { color: 'inherit !important' } }}
                            variant="outlined"
                            key={`${item.Id}-chip`}
                            label={moment(item.StartedOn).format('MM/DD/YYYY hh:mm:ss A')}
                            onDelete={() => removeBackup()}
                        />
                    </Tooltip>
                )
            })}
        </Box>
    )
}
