import { ReactElement, useEffect, useRef } from 'react'
import { Autocomplete } from '@mui/material'
import { Box, Checkbox, FormControlLabel, Typography, TextField } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { FormSection } from 'genesis-suite/types/visualTypes'

interface SectionBuilderProps {
    section: FormSection
    sections: FormSection[]
    closeButton: ReactElement
    deleteButton: ReactElement
    onChange: (section: FormSection) => void
}

const useStyles = makeStyles(({ palette, spacing }) => ({
    titleEditor: {
        fontSize: '1.25rem',
    },
    footer: {
        width: '60%',
        display: 'flex',
        borderRadius: 4,
        padding: spacing(1),
        alignItems: 'center',
        border: `1px solid ${palette.grayscale.light}`,
        '& > *': { margin: spacing(0, 1) },
    },
}))

export default function SectionBuilder({
    section,
    sections,
    closeButton,
    deleteButton,
    onChange,
}: SectionBuilderProps) {
    const classes = useStyles()
    const { title, description, goTo } = section

    const titleRef = useRef(null)
    useEffect(() => {
        if (titleRef.current) {
            titleRef.current.select()
        }
    }, [])

    const sectionOptions = sections.filter(s => s.id !== section.id)
    const selected = sections.find(s => s.id === goTo)
    return (
        <Box display="flex" width="100%" alignItems="flex-start">
            {closeButton}
            <Box width="100%" display="flex" flexDirection="column">
                <Box display="flex" mb={1} mt={1}>
                    <TextField
                        autoFocus
                        fullWidth
                        variant="filled"
                        label="Section title (optional)"
                        defaultValue={title || 'Untitled Section'}
                        inputProps={{ ref: titleRef }}
                        InputProps={{ className: classes.titleEditor }}
                        onChange={e => onChange({ ...section, title: e.target.value })}
                    />
                </Box>
                <TextField
                    rows={3}
                    multiline
                    fullWidth
                    maxRows={4}
                    variant="outlined"
                    defaultValue={description}
                    label="Description (optional)"
                    onChange={e => onChange({ ...section, description: e.target.value })}
                />
                <Typography>After this section...</Typography>
                <Box className={classes.footer}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={!goTo}
                                onChange={() => onChange({ ...section, goTo: null })}
                            />
                        }
                        label="Submit Form"
                    />
                    <Typography>
                        <b>OR</b>
                    </Typography>
                    <Autocomplete
                        style={{ flex: 1 }}
                        value={goTo as any || null}
                        options={sectionOptions}
                        getOptionLabel={opt => opt.title || 'Untitled Section'}
                        isOptionEqualToValue={opt => {
                            return opt && opt.id === goTo
                        }}
                        onChange={(e, value) => {
                            if (!value) onChange({ ...section, goTo: null })
                            else if (typeof value !== 'string') {
                                onChange({ ...section, goTo: value.id })
                            }
                        }}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label="Go to..."
                                inputProps={
                                    selected
                                        ? { ...params.inputProps, value: selected.title || 'Untitled Section' }
                                        : { ...params.inputProps }
                                }
                            />
                        )}
                    />
                </Box>
            </Box>
            {deleteButton}
        </Box>
    )
}
