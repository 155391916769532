import { isEmpty, isEqual } from 'lodash'
import { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { filterSelectors } from '../../../selectors'
import { WidgetDataStatus } from '../../../types/WidgetTypes'
import { PerspectiveContext } from '../../contexts/PerspectiveContext'

/** Determine when a widget should fetch data (waiting if another widget in the perspective is a control widget) */
export default function useWidgetStatus(
    widgetId,
    isFormTable
): [WidgetDataStatus, Dispatch<SetStateAction<WidgetDataStatus>>, () => void] {
    const inlineFilters = useSelector(filterSelectors.getInlineFilters)
    const { controlWidget, updateControlWidgetStatus } = useContext(PerspectiveContext)
    const isControl = widgetId === controlWidget?.id

    const lastControlFilters = useRef()
    const lastOtherFilters = useRef()

    const initialStatus = !controlWidget || controlWidget.status !== 'fetching' || isControl ? 'fetching' : 'waiting'
    const [status, setStatus] = useState<WidgetDataStatus>(initialStatus)

    const reset = () => setStatus(initialStatus)

    // wait for control widget data fetch to finish
    useEffect(() => {
        if (!isControl) return
        if (status === 'waiting' || status === 'fetching') return

        updateControlWidgetStatus?.(status)
    }, [status])

    // allow other widgets to fetch after control widget is done
    useEffect(() => {
        if (!controlWidget || isControl) return
        if (controlWidget.status === 'fetching') return
        if (controlWidget.status !== 'data' && !isFormTable) return setStatus(controlWidget.status)
        const { [controlWidget.id]: controlFilters, [widgetId]: _, ...otherFilters } = inlineFilters?.contexts || {}
        if (isEmpty(controlFilters)) return

        const hasUpdatedControlFilters = !isEqual(controlFilters, lastControlFilters.current)
        const hasUpdatedOtherFilters = !isEqual(otherFilters, lastOtherFilters.current)
        if (status === 'waiting' || hasUpdatedControlFilters || hasUpdatedOtherFilters) {
            lastControlFilters.current = controlFilters
            lastOtherFilters.current = otherFilters
            setStatus('fetching')
        }
    }, [controlWidget, inlineFilters])

    return [status, setStatus, reset]
}
