import { combineReducers } from 'redux'
import { moduleTypes } from '../actions/types'
import { filterTypes } from '../actions/types/filter.types'

const initialConfigState = {}

const buildPerspectiveConfigs = widgets => {
    if (!widgets) return {}

    const perspectives = widgets.filter(widget => {
        if (typeof widget.Type !== 'string') return false
        return widget.Type.toLowerCase() === 'container'
    })

    return perspectives.reduce((acc, perspective) => {
        if (!perspective.FilterConfig) return acc

        perspective.FilterConfig.map(filter => {
            acc[filter.Name] = {
                ...filter,
                perspectiveId: perspective.Id,
            }
        })

        return acc
    }, {})
}

const buildGlobalConfigs = filters => {
    if (!filters || filters.length === 0) return {}

    return filters.reduce((acc, filter) => {
        acc[filter.Name] = {
            ...filter,
        }
        return acc
    }, {})
}

const configs = (state = initialConfigState, action) => {
    const { payload } = action
    switch (action.type) {
        case moduleTypes.MODULE_CHANGE_REQUEST:
            return initialConfigState
        case moduleTypes.MODULE_SUCCESS:
            const {
                appModule: { Widgets: widgets },
            } = payload
            const {
                appModule: { GlobalFilters: filters },
            } = payload
            return {
                ...buildPerspectiveConfigs(widgets),
                ...buildGlobalConfigs(filters),
            }
        default:
            return state
    }
}

const intialGlobalState = []

const global = (state = intialGlobalState, action) => {
    const { payload } = action
    switch (action.type) {
        case moduleTypes.MODULE_CHANGE_REQUEST:
            return intialGlobalState
        case moduleTypes.MODULE_SUCCESS:
            const {
                appModule: { GlobalFilters: filters },
            } = payload
            if (!filters) return []
            const {
                appModule: { Widgets: widgets },
            } = payload
            buildPerspectiveConfigs(widgets)
            return filters.map(filter => filter.Name)
        default:
            return state
    }
}

const globalApplied = (state = null, action) => {
    const { payload } = action
    switch (action.type) {
        case moduleTypes.MODULE_CHANGE_REQUEST:
            return null
        case filterTypes.GLOBAL_FILTER_CHANGE:
            return payload.filters
        default:
            return state
    }
}

const perspectiveDefaults = (state = null, action) => {
    const { payload } = action
    switch (action.type) {
        case filterTypes.PERSPECTIVE_DEFAULT_FILTER_CHANGE:
            return payload.filters
        default:
            return state
    }
}

export default combineReducers({ configs, global, globalApplied, perspectiveDefaults })
