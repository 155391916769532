import { default as ReactMarkdown, Options } from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'

export default function Markdown({ children }: Options) {
    if (!children) return null

    const corrected = children.replace(/\n|\\n/g, '  \n') // markdown needs 2 spaces before newlines
    return <ReactMarkdown children={corrected} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} />
}
