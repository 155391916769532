import { Dispatch, memo, SetStateAction, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Box, Typography, useTheme, Button, LinearProgress } from '@mui/material'
import { DataGridPro, GridRowProps, GridColDef, GridRow, GridOverlay } from '@mui/x-data-grid-pro'
import makeStyles from '@mui/styles/makeStyles'
import AddIcon from '@mui/icons-material/Add'
import { useDrag } from 'react-dnd'
import Measure from 'react-measure'

import { sleep } from 'genesis-suite/utils'
import { Dashboard } from 'genesis-suite/types/visualTypes'
import { SortBy } from 'genesis-suite/types/utilTypes'
import { NewDragItem } from '../manage_module_views/ViewTypes'
import { ModuleViewsContext } from '../manage_module_views/ModuleViewsContext'
import { authSelectors, moduleSelectors } from '../../selectors'
import { sortData, getComparator } from '../../lib/manageUtils'
import { makeColumns, makeRows, authorKey, createdBySortKey, DashboardFilter, useColumnWidths } from './utils'

const ROW_HEIGHT = 40

const useStyles = makeStyles(({ spacing }) => ({
    tableWrapper: { flex: 1 },
    cover: { display: 'flex', flexDirection: 'column' },
    cell: { '&:focus': { outline: 'none !important' } },
}))

interface Props {
    filter: DashboardFilter
    highlightId: string
    loading: boolean
    onSelect: (id: string) => void
    onNewButton: () => void
    onOpen: (viewId: string) => void
    onRefresh: (id: string) => void
    onScrollEnd: () => void
    onSort: Dispatch<SetStateAction<SortBy<Dashboard>>>
    onUpdateFilter: (updated: Partial<DashboardFilter>) => void
    sortBy: SortBy<Dashboard>
    data: Array<Dashboard>
}

export default function DashboardsTable(props: Props) {
    const { onNewView } = useContext(ModuleViewsContext)

    return <MemoizedTable {...props} onNewView={onNewView} />
}

interface RenderProps extends Props {
    onNewView: (item: NewDragItem, keep: boolean, isUsers: boolean) => Promise<boolean>
}

const MemoizedTable = memo(RenderDashboardsTable)

function RenderDashboardsTable({
    filter,
    highlightId,
    loading,
    onSelect,
    onNewButton,
    onNewView,
    onOpen,
    onRefresh,
    onScrollEnd,
    onSort,
    onUpdateFilter,
    sortBy,
    data,
}: RenderProps) {
    const userId = useSelector(authSelectors.getUserId)
    const isPowerUser = useSelector(authSelectors.getIsPowerUser)
    const moduleViewIds = useSelector(moduleSelectors.getModuleViewIds)

    const { columnWidths, setTableWidth } = useColumnWidths(isPowerUser)

    const columns = useMemo(
        () => makeColumns(columnWidths, filter, onOpen, onUpdateFilter, isPowerUser),
        [columnWidths, filter, onOpen, onUpdateFilter, isPowerUser]
    )
    const sortedData = useMemo(
        () => sortData(makeRows(data, moduleViewIds, userId), getComparator(sortBy)),
        [data, sortBy]
    )

    const classes = useStyles()

    return (
        <Box flex={1}>
            <Measure offset onResize={({ offset }) => setTableWidth(offset.width)}>
                {({ measureRef }) => <div ref={measureRef} className={classes.tableWrapper} />}
            </Measure>

            <Table
                {...{
                    classes,
                    columns,
                    data: sortedData,
                    highlightId,
                    isPowerUser,
                    loading,
                    onNewButton,
                    onNewView,
                    onRefresh,
                    onScrollEnd,
                    onSelect,
                    onSort,
                }}
            />
        </Box>
    )
}

const Table = memo(RenderTable)

interface TableProps {
    classes: any
    columns: GridColDef[]
    data: any
    highlightId: string
    loading: boolean
    isPowerUser: boolean
    onNewButton: RenderProps['onNewButton']
    onNewView: RenderProps['onNewView']
    onRefresh: RenderProps['onRefresh']
    onScrollEnd: RenderProps['onScrollEnd']
    onSelect: (id: string) => void
    onSort: RenderProps['onSort']
}

function RenderTable({
    classes,
    columns,
    data,
    highlightId,
    isPowerUser,
    loading,
    onNewButton,
    onNewView,
    onRefresh,
    onScrollEnd,
    onSelect,
    onSort,
}: TableProps) {
    function handleSort(model) {
        if (!model.length) return onSort({})

        const { field, sort } = model[0]
        const fieldName = field === authorKey ? createdBySortKey : field
        onSort({ [fieldName]: sort })
    }

    return (
        <DataGridPro
            classes={{ cell: classes.cell, columnHeader: classes.cell }}
            columns={columns}
            components={{
                LoadingOverlay,
                NoRowsOverlay: () => <EmptyRow onNew={onNewButton} />,
                Row: props => (
                    <Row
                        {...props}
                        isPowerUser={isPowerUser}
                        highlightId={highlightId}
                        onNewView={onNewView}
                        onRefresh={onRefresh}
                    />
                ),
            }}
            disableColumnFilter
            disableColumnSelector
            disableSelectionOnClick
            hideFooter
            loading={loading}
            onRowClick={p => onSelect(p.row.id)}
            onRowsScrollEnd={onScrollEnd}
            onSortModelChange={handleSort}
            pinnedColumns={{ right: ['actions'] }}
            rowHeight={ROW_HEIGHT}
            rows={data}
            rowThreshold={3}
            scrollEndThreshold={300}
            sortingMode="server"
        />
    )
}

function LoadingOverlay() {
    return (
        <GridOverlay>
            <div style={{ position: 'absolute', top: 0, width: '100%', zIndex: 1 }}>
                <LinearProgress />
            </div>
        </GridOverlay>
    )
}

interface RowProps extends GridRowProps {
    isPowerUser: boolean
    highlightId: boolean
    onRefresh: (id: string) => void
    onNewView: (item: NewDragItem, keep: boolean, isUsers: boolean) => Promise<boolean>
}

function Row({ isPowerUser, highlightId, onNewView, onRefresh, ...gridRowProps }: RowProps) {
    const { id, title, isUsers } = gridRowProps.row
    const theme = useTheme()

    const [{ opacity }, drag] = useDrag<NewDragItem, NewDragItem, { opacity: number }>({
        type: 'dashboard',
        item: { type: 'dashboard', id, status: 'new', dragId: 'draft', title },
        collect: monitor => ({ opacity: monitor.isDragging() ? 0.4 : 1 }),
        end: async (dropResult, monitor) => {
            try {
                const keep = monitor.didDrop()
                await onNewView(dropResult, keep, isUsers)
                if (!keep) return

                await sleep(200)
                onRefresh(dropResult.id)
            } catch (err) {
                console.error(err)
            }
        },
    })

    const rowStyle = {
        ...((isPowerUser || isUsers) && { cursor: 'pointer' }),
        ...(opacity !== 1 && { opacity }),
        ...(highlightId === id && { backgroundColor: theme.palette.grayscale.lighter }),
    }

    return (
        <div style={rowStyle} ref={node => (isPowerUser ? drag(node) : undefined)}>
            <GridRow {...gridRowProps} />
        </div>
    )
}

function EmptyRow({ onNew }) {
    return (
        <Box flex={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={1}>
            <Typography>No collections with filter criteria</Typography>
            <Button startIcon={<AddIcon />} variant="contained" onClick={onNew}>
                Create
            </Button>
        </Box>
    )
}
