import { useSelector } from 'react-redux'
import { Box, Typography } from '@mui/material'
import { applicationSelectors } from '../selectors'
import { formatDateTime } from '../lib/utils'
import { ChopText } from 'genesis-suite/components'
import { TourTag } from '../lib/tourSteps'

export default function ModelLastUpdated(props) {
    const lastUpdateDate = useSelector(applicationSelectors.getLastUpdateDate)
    const isPending = useSelector(applicationSelectors.getIsPending)

    let content
    if (isPending) content = ''
    else if (lastUpdateDate) content = formatDateTime(lastUpdateDate) + ' CST'
    else content = 'N/A'

    return (
        <Box
            data-tour={TourTag.LastUpdated}
            {...props}
            sx={{
                color: 'text.primary',
                display: 'flex',
                ml: '14px',
                ...props?.sx,
            }}
        >
            <Typography sx={{ fontWeight: 'bold', color: 'inherit', flexShrink: 0 }}>Last updated:&nbsp;</Typography>
            <ChopText showEllipsis color="inherit" tooltipProps={{ placement: 'bottom' }}>
                {content}
            </ChopText>
        </Box>
    )
}
