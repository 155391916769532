import { useSelector } from 'react-redux'
import { navigationSelectors, widgetSelectors } from '../selectors'

/** Check for route match with one to string or multiple items */
export default function useIsItemAtRoute(item) {
    const currentPerspectiveId = useSelector(widgetSelectors.getCurrentPerspectiveId)
    const currentElementName = useSelector(navigationSelectors.getCurrentBusinessElement)
    const currentWidgetId = useSelector(navigationSelectors.getCurrentWidgetId)
    const currentTreeName = useSelector(navigationSelectors.getCurrentTreeName)

    const { type, to, items } = item

    if (items)
        return items.some(i => itemAtRoute(i.type, i.to, currentPerspectiveId, currentElementName, currentWidgetId))
    return itemAtRoute(type, to, currentPerspectiveId, currentElementName, currentWidgetId, currentTreeName)
}

function itemAtRoute(type, to, currentPerspectiveId, currentElementName, currentWidgetId, currentTreeName) {
    if (type === 'viewId' && to && to === currentPerspectiveId) return true
    if (type === 'widgetId' && to && to === currentWidgetId) return true
    if (type === 'treeName' && to && to === currentTreeName) return true
    if (type === 'elementName' && to && to === currentElementName) return true
}
