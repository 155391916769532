import { MutableRefObject, useEffect, useRef, useState } from 'react'
import { Palette } from '@mui/material'
import axios from 'axios'
import isEqual from 'lodash/isEqual'
import * as topojson from 'topojson-client'
import { Feature, Geometry, Position } from 'geojson'

import {
    Basket,
    DataResponse,
    LocationType,
    MapConnectionSeries,
    MapHeatSeries,
    MapMarkerSeries,
    MapSeries,
    MapSurface,
} from 'genesis-suite/types/visualTypes'
import { makeId } from 'genesis-suite/utils'
import useWidgetColors from '../../../hooks/useWidgetColors'
import pickFromCarousel from '../utils/pickFromCarousel'

export type GeoData = Array<MarkerGeoData | HeatGeoData | ConnectionGeoData>
export interface MarkerGeoData extends CommonGeoData {
    type: 'marker'
    data: MarkerSeries
}
export interface HeatGeoData extends CommonGeoData {
    type: 'heat'
    data: HeatSeries
}
export interface ConnectionGeoData extends CommonGeoData {
    type: 'connection'
    data: ConnectionDataPoint[]
}

interface CommonGeoData {
    /** Unique key for rendering series */
    key: string
    color: string
}

interface Bounds {
    /** largest latitude */
    north: number
    /** smallest latitude */
    south: number
    /** largest longitude */
    east: number
    /** smallest longitude */
    west: number
}

export function getMapBounds(mapInstance: MutableRefObject<google.maps.Map>) {
    const northEastBounds = mapInstance.current.getBounds()?.getNorthEast()
    const southWestBounds = mapInstance.current.getBounds()?.getSouthWest()
    const bounds: Bounds = {
        north: northEastBounds?.lat(),
        south: southWestBounds?.lat(),
        east: northEastBounds?.lng(),
        west: southWestBounds?.lng(),
    }

    return {
        nwBound: `${bounds.north},${bounds.west}`,
        seBound: `${bounds.south},${bounds.east}`,
    }
}

export function getGridCellBounds(nwCoordinates: string, seCoordinates: string): Bounds {
    const [nwLat, nwLng] = nwCoordinates.replace(/\s+/g, '').split(',')
    const [seLat, seLng] = seCoordinates.replace(/\s+/g, '').split(',')

    return {
        north: Number(nwLat),
        south: Number(seLat),
        east: Number(seLng),
        west: Number(nwLng),
    }
}

export function createGridCellStyles(mapInstance: MutableRefObject<google.maps.Map>, palette: Palette) {
    const mapTypeId = mapInstance.current.getMapTypeId() as MapSurface

    switch (mapTypeId) {
        case MapSurface.SATELLITE:
        case MapSurface.HYBRID:
            return {
                strokeColor: palette.tada.teal,
                strokeWeight: 1,
                strokeOpacity: 1,
                fillOpacity: 0,
            }
        case MapSurface.ROADMAP:
        case MapSurface.TERRAIN:
            return {
                strokeColor: palette.tada.purple,
                strokeWeight: 1,
                strokeOpacity: 0.3,
                fillOpacity: 0,
            }
    }
}

export function getGridCellsResolution(zoom: number) {
    switch (true) {
        case zoom <= 4:
            return 6
        case zoom <= 7:
            return 5
        case zoom <= 10:
            return 4
        case zoom <= 14:
            return 3
        case zoom <= 17:
            return 2
        case zoom >= 18:
            return 1
    }
}

interface Center {
    lat: number
    lng: number
}

export function initializePosition(data: GeoData): { bounds: Bounds; center: Center } {
    if (!data) return

    let north, south, east, west

    data.forEach(series => {
        if (!series.data) return

        switch (series.type) {
            case 'connection':
                break
            case 'marker':
                series.data.data.forEach(({ latitude, longitude }) => {
                    north = north != null ? Math.max(north, latitude) : latitude
                    south = south != null ? Math.min(south, latitude) : latitude
                    east = east != null ? Math.max(east, longitude) : longitude
                    west = west != null ? Math.min(west, longitude) : longitude
                })
                break
            case 'heat':
                const b = series.data.bounds
                north = north != null ? Math.max(north, b.north) : b.north
                south = south != null ? Math.min(south, b.south) : b.south
                east = east != null ? Math.max(east, b.east) : b.east
                west = west != null ? Math.min(west, b.west) : b.west
                break
        }
    })

    if (north == null) return

    return {
        bounds: { north, south, east, west },
        center: { lat: (north + south) / 2, lng: (east + west) / 2 },
    }
}

export function useGeoData(series: MapSeries[], data: DataResponse) {
    const defaultColors = useWidgetColors()

    const [geoData, setGeoData] = useState<GeoData>(null)

    const lastSeries = useRef<MapSeries[]>()
    useEffect(() => {
        if (!series?.length || !data) return
        if (isEqual(series, lastSeries.current)) return

        lastSeries.current = series
        ;(async () => {
            const draft: GeoData = []
            for (let i = 0; i < series.length; i++) {
                const currentSeries = series[i]
                const color = getSeriesColor(currentSeries) ?? pickFromCarousel(defaultColors, i)

                switch (currentSeries.type) {
                    case 'marker': {
                        await draft.push({
                            color,
                            data: await createMarkerData(currentSeries, data[i]),
                            key: makeId(),
                            type: currentSeries.type,
                        })
                        break
                    }
                    case 'heat': {
                        await draft.push({
                            color,
                            data: await createHeatData(currentSeries, data[i]),
                            key: makeId(),
                            type: currentSeries.type,
                        })
                        break
                    }
                    case 'connection': {
                        draft.push({
                            color,
                            data: createConnectionData(currentSeries, data[i]),
                            key: makeId(),
                            type: 'connection',
                        })
                        break
                    }
                }
            }
            setGeoData(draft)
        })()
    })

    return geoData
}

export type MarkerSeries = RawMarkerSeries | StateMarkerSeries | CountryMarkerSeries | CountyMarkerSeries
interface RawMarkerSeries {
    type: LocationType.GEO
    data: Array<RawGeoPoint & BaseMarkerPoint>
}
interface RawGeoPoint {
    latitude: number
    longitude: number
}
interface StateMarkerSeries {
    type: LocationType.STATE
    data: Array<StateGeoPoint & BaseMarkerPoint>
}
interface CountryMarkerSeries {
    type: LocationType.COUNTRY
    data: Array<CountryGeoPoint & BaseMarkerPoint>
}
interface CountyMarkerSeries {
    type: LocationType.COUNTY
    data: Array<CountyGeoPoint & BaseMarkerPoint>
}
interface BaseMarkerPoint {
    size?: number
    marker?: string
    color?: number
}

async function createMarkerData(series: MapMarkerSeries, seriesData: DataResponse[0]): Promise<MarkerSeries> {
    const rawData = seriesData?.data[0]?.rawData
    if (!rawData) return

    let geoSeries: MarkerSeries

    const isGeo = !series.locationType || series.locationType === LocationType.GEO
    if (isGeo) {
        const latitudeIndex = series.values.findIndex(v => v.basket === Basket.LATITUDE)
        const longitudeIndex = series.values.findIndex(v => v.basket === Basket.LONGITUDE)

        const data = rawData.map(row => ({
            latitude: parseLatitude(row[latitudeIndex]),
            longitude: parseLongitude(row[longitudeIndex]),
        }))

        geoSeries = { type: LocationType.GEO, data }
    } else {
        const locationIndex = series.values.findIndex(v => v.basket === Basket.LOCATION)

        switch (series.locationType) {
            case LocationType.COUNTRY:
                geoSeries = { type: LocationType.COUNTRY, data: await getCountryData(rawData, locationIndex) }
                break
            case LocationType.STATE:
                geoSeries = { type: LocationType.STATE, data: await getStateData(rawData, locationIndex) }
                break
            case LocationType.COUNTY:
                geoSeries = { type: LocationType.COUNTY, data: await getCountyData(rawData, locationIndex) }
                break
        }
    }

    const colorIndex = series.values.findIndex(v => v.basket === Basket.COLOR)
    const markerIndex = series.values.findIndex(v => v.basket === Basket.ID)
    const sizeIndex = series.values.findIndex(v => v.basket === Basket.SIZE)

    return {
        ...geoSeries,
        //@ts-ignore
        data: geoSeries.data.map((r, i) => ({
            ...r,
            ...(colorIndex > -1 && { color: Number(rawData[i][colorIndex]) }),
            ...(markerIndex > -1 && { marker: String(rawData[i][markerIndex]) }),
            ...(sizeIndex > -1 && { size: Number(rawData[i][sizeIndex]) }),
        })),
    }
}

export type HeatSeries = StateHeatSeries | CountryHeatSeries | CountyHeatSeries
interface CountryHeatSeries {
    type: LocationType.COUNTRY
    bounds: Bounds
    data: Array<CountryGeoPoint & BaseHeatPoint>
}
interface CountyHeatSeries {
    type: LocationType.COUNTY
    bounds: Bounds
    data: Array<CountyGeoPoint & BaseHeatPoint>
}
interface StateHeatSeries {
    type: LocationType.STATE
    bounds: Bounds
    data: Array<StateGeoPoint & BaseHeatPoint>
}
interface BaseHeatPoint {
    /** Value used for navigation */
    navigationValue: string
    geometry: Geometry
    color?: number
}

async function createHeatData(series: MapHeatSeries, seriesData: DataResponse[0]): Promise<HeatSeries> {
    const flatData = seriesData.data.map(({ group, aggregatedData }) => [group, ...(aggregatedData ?? [])])
    const colorIndex = series.values.findIndex(v => v.basket === Basket.COLOR)
    const colorData = colorIndex > -1 && flatData.map(row => Number(row[colorIndex + 1]))

    switch (series.locationType) {
        case LocationType.COUNTY: {
            const pointData = await getCountyData(flatData, 0)
            const boundaryData = await getCountyBoundaryData()
            const data = pointData.map((d, row) => ({
                ...d,
                navigationValue: String(flatData[row][0]),
                geometry: boundaryData.find(b => b.id === d.fipsCode).geometry,
                ...(colorData && { color: colorData[row] }),
            }))
            const bounds = getBounds(data.map(d => d.geometry))
            return { type: LocationType.COUNTY, data, bounds }
        }
        case LocationType.COUNTRY: {
            const pointData = await getCountryData(flatData, 0)
            const boundaryData = await getCountryBoundaryData()
            const data = pointData.map((d, row) => ({
                ...d,
                navigationValue: String(flatData[row][0]),
                geometry: boundaryData.find(b => b.id === d.iso3166).geometry,
                ...(colorData && { color: colorData[row] }),
            }))
            const bounds = getBounds(data.map(d => d.geometry))
            return { type: LocationType.COUNTRY, data, bounds }
        }
        case LocationType.STATE: {
            const pointData = await getStateData(flatData, 0)
            const boundaryData = await getStateBoundaryData()
            const data = pointData.map((d, row) => ({
                ...d,
                navigationValue: String(flatData[row][0]),
                geometry: boundaryData.find(b => b.id === d.fipsCode).geometry,
                ...(colorData && { color: colorData[row] }),
            }))
            const bounds = getBounds(
                data.map(d => d.geometry),
                { east: -60 } // clip Alaska far western bounds
            )
            return { type: LocationType.STATE, bounds, data }
        }
    }
}

interface StateGeoPoint {
    name: string
    code: string
    fipsCode: string
    latitude: number
    longitude: number
}

let stateData: Array<StateGeoPoint>

async function getStateData(data: (string | number)[][], locationIndex: number): Promise<StateGeoPoint[]> {
    if (!stateData) {
        const mapData = await getMapData('state-geo.json')
        stateData = mapData
    }

    let type: 'code' | 'name' | 'fipsCode'
    for (const row of data) {
        const value = String(row[locationIndex]).toLocaleLowerCase()
        if (stateData.some(s => s.code.toLocaleLowerCase() === value)) {
            type = 'code'
            break
        }
        if (stateData.some(s => s.name.toLocaleLowerCase() === value)) {
            type = 'name'
            break
        }
        if (stateData.some(s => s.fipsCode.toLocaleLowerCase() === value)) {
            type = 'fipsCode'
            break
        }
    }

    return data.reduce((acc, row) => {
        const value = String(row[locationIndex]).toLocaleLowerCase()
        const p = stateData.find(s => s[type].toLocaleLowerCase() === value)
        if (p) return [...acc, p]

        console.error(`${value} is not a valid state ${type}`)
        return acc
    }, [] as StateGeoPoint[])
}

interface CountryGeoPoint {
    name: string
    /** two letter country code */
    code: string
    /** 3-number code */
    iso3166: string
    latitude: number
    longitude: number
}

let countryData: Array<CountryGeoPoint>

async function getCountryData(data: (string | number)[][], locationIndex: number): Promise<CountryGeoPoint[]> {
    if (!countryData) {
        const mapData = await getMapData('country-geo.json')
        countryData = mapData
    }

    let type: 'code' | 'iso3166'
    for (const row of data) {
        const value = String(row[locationIndex]).toLocaleLowerCase()
        if (countryData.some(s => s.code.toLocaleLowerCase() === value)) {
            type = 'code'
            break
        }
        if (countryData.some(s => s.name.toLocaleLowerCase() === value)) {
            type = 'iso3166'
            break
        }
    }

    return data.reduce((acc, row) => {
        const value = String(row[locationIndex]).toLocaleLowerCase()
        const p = countryData.find(s => s[type].toLocaleLowerCase() === value)
        if (p) return [...acc, p]

        console.error(`${row[locationIndex]} is not a valid country ${type}`)
        return acc
    }, [] as CountryGeoPoint[])
}

interface CountyGeoPoint {
    /** 5 digit code */
    fipsCode: string
    /** 8 digit code */
    ansiCode: string
    /** two letter state code */
    stateCode: string
    name: string
    latitude: number
    longitude: number
}

let countyData: Array<CountyGeoPoint>

async function getCountyData(data: (string | number)[][], locationIndex: number): Promise<CountyGeoPoint[]> {
    if (!countyData) {
        const mapData = await getMapData('county-geo.json')
        countyData = mapData
    }

    let type: 'fipsCode' | 'ansiCode'
    for (const row of data) {
        const value = String(row[locationIndex]).toLocaleLowerCase()
        if (countyData.some(s => s.fipsCode.toLocaleLowerCase() === value)) {
            type = 'fipsCode'
            break
        }
        if (countyData.some(s => s.ansiCode.toLocaleLowerCase() === value)) {
            type = 'ansiCode'
            break
        }
    }

    return data.reduce((acc, row) => {
        const value = String(row[locationIndex]).toLocaleLowerCase()
        const p = countyData.find(s => s[type].toLocaleLowerCase() === value)
        if (p) return [...acc, p]

        console.error(`${row[locationIndex]} is not a valid county ${type}`)
        return acc
    }, [] as CountyGeoPoint[])
}

export interface ConnectionDataPoint {
    fromId: string
    toId: string
}

function createConnectionData(series: MapConnectionSeries, seriesData: DataResponse[0]): Array<ConnectionDataPoint> {
    const rawData = seriesData.data[0].rawData

    const toIndex = series.values.findIndex(v => v.basket === Basket.ID)
    const fromIndex = series.values.findIndex(v => v.basket === Basket.ID2)
    return rawData.map((r, i) => ({
        ...(toIndex > -1 && { toId: String(r[toIndex]) }),
        ...(fromIndex > -1 && { fromId: String(r[fromIndex]) }),
    }))
}

let countyBoundaryData

async function getCountyBoundaryData(): Promise<Feature[]> {
    if (!countyBoundaryData) {
        const boundaryData = await getMapData('county-boundary.json')
        countyBoundaryData = boundaryData
    }

    return parseTopoJson(countyBoundaryData, 'counties')
}

let countryBoundaryData

async function getCountryBoundaryData(): Promise<Feature[]> {
    if (!countryBoundaryData) {
        const boundaryData = await getMapData('country-boundary.json')
        countryBoundaryData = boundaryData
    }

    return parseTopoJson(countryBoundaryData, 'countries')
}

let stateBoundaryData

async function getStateBoundaryData(): Promise<Feature[]> {
    if (!stateBoundaryData) {
        const boundaryData = await getMapData('state-boundary.json')
        stateBoundaryData = boundaryData
    }

    return parseTopoJson(stateBoundaryData, 'states')
}

function getBounds(geometries: Geometry[], limit?: Partial<Bounds>): Bounds {
    let north, south, east, west

    function updateBounds(p) {
        const { lat, lng } = getLatLng(p)
        north = Math.max(north ?? -90, Math.min(limit?.north ?? 90, lat))
        south = Math.min(south ?? 90, Math.max(limit?.south ?? -90, lat))
        east = Math.max(east ?? -180, Math.min(limit?.east ?? 180, lng))
        west = Math.min(west ?? 180, Math.max(limit?.west ?? -180, lng))
    }

    geometries.forEach(geometry => {
        switch (geometry.type) {
            case 'Polygon':
                geometry.coordinates[0].forEach(updateBounds)
                break
            case 'MultiPolygon':
                geometry.coordinates.forEach(poly => poly[0].forEach(updateBounds))
                break
            default:
                console.error(`Geometry type ${geometry.type} not supported`)
        }
    })

    return { north, south, east, west }
}

function parseTopoJson(topology, object): Feature[] {
    if (!topology) return

    const geoData = topojson.feature(topology, object)
    return geoData.features
}

function getMapData(fileName) {
    return axios.get(`/assets/map-data/${fileName}`).then(d => d.data)
}

function parseLatitude(value) {
    const corrected = Math.min(90, Math.max(-90, Number(value)))
    return isNaN(corrected) ? null : corrected
}

function parseLongitude(value) {
    const corrected = Math.min(180, Math.max(-180, Number(value)))
    return isNaN(corrected) ? null : corrected
}

export const getLatLng = ([lng, lat]: Position) => ({ lat, lng })

export interface Limits {
    min: number
    max: number
}

export function getSeriesColor(oneSeries: MapSeries) {
    const colorField = oneSeries.type === 'heat' ? oneSeries.subSeries?.field.name : oneSeries.values[0]?.field.name

    return oneSeries.colors?.[colorField].series
}

export const generateIconPath = (markerType?: MapMarkerSeries['markerType']) => {
    switch (markerType) {
        case 'diamond':
            return 'M0, -5 L3, 0 0, 5 -3, 0Z'
        case 'square':
            return 'M-3 ,-3 L3 ,-3  3 ,3  -3 ,3 Z'
        case 'triangle-up':
            return 'M0,-5 L5.75, 5  -5.75 ,5 Z'
        case 'triangle-down':
            return 'M0,5 L5.75, -5 -5.75, -5Z'
        default:
            return 'M 0, 0 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0'
    }
}
