import { useState, useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Collapse, Typography, Tooltip, Radio, Checkbox, Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classnames from 'classnames'
import ColorPicker from '../../ColorPicker'
import equal from 'fast-deep-equal'
import produce from 'immer'

import { SwalContext } from 'genesis-suite/components'
import { Help } from 'genesis-suite/icons'
import { sleep } from 'genesis-suite/utils'
import { moduleSelectors } from '../../../selectors'
import { themeCreators, moduleCreators } from '../../../actions/creators'
import { useThemes, isCustomized, getTadaThemeById, defaultTheme } from '../../../lib/tadaThemes'

/** server id for default theme */
const defaultId = 1

const useStyles = makeStyles(({ palette, spacing }) => ({
    container: { width: '600px', padding: '0 10px' },
    header: { display: 'flex', alignItems: 'center', marginBottom: '5px' },
    headerText: { paddingRight: '10px' },
    fourColumnGrid: { display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)' },
    tadaThemeContainer: { marginBottom: '20px' },
    tileImage: { width: '90px', paddingBottom: spacing(0.5) },
    themeOptionContainer: {
        cursor: 'pointer',
        '&:hover': { backgroundColor: palette.action.hover },
    },
    themeOptionHeader: { cursor: 'pointer', display: 'flex', alignItems: 'center' },
    themeOptionChildren: { margin: '7px 0 0 22px' },
    colorSelectorText: { fontWeight: 'bold' },
    buttons: { display: 'flex', justifyContent: 'flex-end', marginTop: '20px', '& > *': { marginLeft: '10px' } },
    radio: { padding: '5px', '& svg': { height: '15px', width: '15px', color: palette.text.primary } },
    flexDiv: { display: 'flex' },
    helpIcon: { fontSize: '1rem', margin: 'auto' },
}))

export default function Theme({ updateSaveHandle, updateCancelHandle, onLoading, module, setModule }) {
    const { alert, confirm } = useContext(SwalContext)
    const currentModuleId = useSelector(moduleSelectors.getModuleId)
    const selectedModuleTheme = module?.theme
    const isCurrentModuleSelected = module?.id === currentModuleId

    const dispatch = useDispatch()
    const [themes, status] = useThemes()
    const [draft, setDraft] = useState(selectedModuleTheme || defaultTheme)
    const [customSelected, setCustomSelected] = useState(false)
    const [selectedTheme, setSelectedTheme] = useState(selectedModuleTheme?.id ?? defaultTheme.id)

    const classes = useStyles()

    const updateTheme = theme => dispatch(themeCreators.updateAll(theme))

    const saveTheme = async () => {
        try {
            const { author, ...selectedModule } = module
            await dispatch(moduleCreators.saveTheme(selectedModule, draft, setModule, setDraft))
        } catch (error) {
            alert('There was a problem saving.', { type: 'error' })
            console.error(error)
        } finally {
            onLoading(false)
        }
    }

    useEffect(() => {
        setDraft(module?.theme || defaultTheme)
        setSelectedTheme(module?.theme?.id ?? defaultTheme.id)
    }, [module])

    useEffect(() => {
        if (isCurrentModuleSelected) updateTheme(draft)
    }, [draft, isCurrentModuleSelected])

    useEffect(() => {
        onLoading(false)

        if (status === 'loading') return

        if (isCustomized(draft)) setCustomSelected(true)

        const isSameAsModuleTheme = equal(draft, selectedModuleTheme || defaultTheme)

        if (isSameAsModuleTheme) {
            updateSaveHandle()
            updateCancelHandle()
        } else {
            updateSaveHandle({
                label: 'Save',
                onClick: async () => {
                    const response = await confirm(
                        `This will save the theme for all users of this application. Are you sure?`,
                        {
                            type: 'warning',
                            confirmButtonProps: { text: 'Ok' },
                        }
                    )
                    if (response.dismiss) return
                    onLoading(true)
                    saveTheme()
                },
            })
            updateCancelHandle(async () => {
                setDraft(selectedModuleTheme ?? defaultTheme)
                setSelectedTheme(selectedModuleTheme?.id ?? defaultTheme.id)
                setCustomSelected(false)
            })
        }
    }, [selectedModuleTheme, draft, status])

    const handleThemeChange = async id => {
        if (id === draft.id) return
        if (isCurrentModuleSelected) startLoading()

        changeTheme(id)
        setCustomSelected(false)
        setSelectedTheme(id)
    }

    const changeTheme = id => {
        setDraft(getTadaThemeById(id))
    }

    const handleCustomSelect = async () => {
        if (customSelected && isCustomized(draft)) {
            changeTheme(draft.id)
            setCustomSelected(false)
        } else setCustomSelected(prevOption => !prevOption)
    }

    const updateColor = (color, value) => {
        setDraft(updateOneColor(draft, color, value))
    }

    const startLoading = async () => {
        onLoading(true)
        // HACK - needed for spinner to show properly
        await sleep(500)
    }

    const rootProps = {
        classes,
        currentTheme: draft,
        selectedTheme,
        customSelected,
        onChange: updateColor,
        handleCustomSelect,
        handleThemeChange,
    }

    return (
        <>
            <Header classes={classes} />
            <ThemeOption {...rootProps} id={defaultId} name="Default" />
            <TadaThemes tiledThemes={themes.filter(theme => theme.id !== defaultId)} {...rootProps} />
            <CustomTheme {...rootProps} />
        </>
    )
}

const TadaThemes = ({ classes, tiledThemes, ...rest }) => (
    <div className={classnames(classes.fourColumnGrid, classes.tadaThemeContainer)}>
        {tiledThemes.map(({ id, name, imageSrc }) => (
            <ThemeOption {...rest} classes={classes} key={id} id={id} name={name}>
                <img src={imageSrc} alt={name} className={classes.tileImage} draggable={false} />
            </ThemeOption>
        ))}
    </div>
)

const Header = ({ classes }) => (
    <div className={classes.header}>
        <Typography variant="h6" classes={{ root: classes.headerText }}>
            Colors
        </Typography>

        <Tooltip title="Choose from Tada's predefined themes">
            <Help fontSize="small" />
        </Tooltip>
    </div>
)

const CustomTheme = ({ classes, currentTheme, customSelected, handleCustomSelect, onChange }) => {
    const colorSelectorProps = { classes, onChange }
    const {
        custom: { accent1, accent2, topBar, widget },
    } = currentTheme

    return (
        <div>
            <Typography variant="body1" className={classes.themeOptionHeader} onClick={() => handleCustomSelect()}>
                <Checkbox className={classes.radio} checked={customSelected} color="primary" size="small" />
                Custom colors
            </Typography>

            <Collapse in={customSelected} timeout="auto">
                <div className={classes.themeOptionChildren}>
                    <Box sx={{ mb: 1 }} className={classes.fourColumnGrid}>
                        <ColorSelector
                            {...colorSelectorProps}
                            name="Top bar"
                            tooltip="Changes color of top bar"
                            color={topBar.main}
                        />
                        <ColorSelector
                            {...colorSelectorProps}
                            name="Accent one"
                            tooltip="Changes color of tooltips, account avatar, & buttons"
                            color={accent1.main}
                        />
                        <ColorSelector
                            {...colorSelectorProps}
                            name="Accent two"
                            tooltip="Changes color of module avatar & badges"
                            color={accent2.main}
                        />
                    </Box>

                    <ColorSelector
                        {...colorSelectorProps}
                        name="Widget colors"
                        options={[0, 1, 2, 3, 4, 5, 6, 7]}
                        color={widget}
                    />
                </div>
            </Collapse>
        </div>
    )
}

/**
 * Wrapper for rendering one or array of ColorPickers
 * @param {string} props.name color selector name
 * @param {string=} props.tooltip optional text to place into tooltip
 * @param {string | string[]} props.color initial color of the picker. if options, then array of colors
 * @param {number[]=} props.options if exists, output an array of color pickers
 * @param {(name:string ,hex: string) => void} props.onChange callback to update the color
 */
const ColorSelector = ({ classes, name, tooltip, color, options, onChange }) => {
    return (
        <div>
            <div className={classes.flexDiv}>
                <Typography variant="body1" className={classes.colorSelectorText}>
                    {name}
                </Typography>
                {tooltip ? (
                    <Tooltip title={tooltip}>
                        <Help className={classes.helpIcon} />
                    </Tooltip>
                ) : null}
            </div>
            {options ? (
                <div className={classes.fourColumnGrid}>
                    {options.map(option => (
                        <ColorPickerWrapper
                            key={option}
                            name={`${name}|${option}`}
                            color={color[option]}
                            onChange={onChange}
                        />
                    ))}
                </div>
            ) : (
                <ColorPickerWrapper key={name} name={name} color={color} onChange={onChange} />
            )}
        </div>
    )
}

function ColorPickerWrapper({ name, color, onChange }) {
    const [draft, setDraft] = useState('')
    useEffect(() => {
        setDraft(color)
    }, [color])

    const handleColorChange = (name, updated, previous) => {
        const upper = updated.toUpperCase()
        if (upper === previous) return

        setDraft(upper)
        onChange(name, upper)
    }

    return <ColorPicker color={draft} onChangeComplete={({ hex }) => handleColorChange(name, hex, color)} />
}

const ThemeOption = ({ classes, id, name, selectedTheme, children, handleThemeChange }) => (
    <div onClick={() => handleThemeChange(id)} className={classes.themeOptionContainer}>
        <div className={classes.themeOptionHeader}>
            <Radio className={classes.radio} checked={selectedTheme === id} color="primary" size="small" />
            <Typography variant="body1">{name}</Typography>
        </div>

        <div className={classes.themeOptionChildren}>{children}</div>
    </div>
)

function updateOneColor(theme, color, value) {
    return produce(theme, draft => {
        switch (color) {
            case 'Accent one':
                draft.custom.accent1.main = value
                break
            case 'Accent two':
                draft.custom.accent2.main = value
                break
            case 'Top bar':
                draft.custom.topBar.main = value
                break

            //widget colors
            default:
                draft.custom.widget[color.split('|')[1]] = value
        }
    })
}
