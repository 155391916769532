import { lockoutTypes } from '../actions/types'
import { LockoutStore } from '../store/storeTypes'

const initialState: LockoutStore = {
    status: 'idle',
    items: [],
}

export default (state = initialState, action): LockoutStore => {
    const { type, payload } = action

    switch (type) {
        case lockoutTypes.ADD:
            return { ...state, items: [...state.items, payload] }
        case lockoutTypes.CONNECT:
            return { status: 'connected', items: [] }
        case lockoutTypes.REMOVE:
            return { ...state, items: state.items.filter(l => l.widgetId !== payload) }
        case lockoutTypes.SET:
            return { ...state, items: payload }
        case lockoutTypes.STOP:
            return { status: 'idle', items: [] }

        default:
            return state
    }
}
