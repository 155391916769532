import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'
import { useSnackbar } from 'notistack'
import { connect } from 'react-redux'

import { filterCreators } from '../actions/creators'
import FiltersSelector from '../components/FiltersSelector'
import { logEvent } from '../lib/amplitudeClient'
import { filterSelectors } from '../selectors'

const PerspectiveCompareFilters = props => {
    const { saveFiltersForAll, ...rest } = props
    const { enqueueSnackbar: showSnackbar, closeSnackbar } = useSnackbar()

    const defaultSnackbarConfig = {
        autoHideDuration: 5000,
        action: (
            <IconButton
                onClick={() => {
                    closeSnackbar()
                }}
                size="large"
            >
                <CloseIcon fontSize="small" htmlColor="#ffffff" />
            </IconButton>
        ),
    }

    const handleSaveFiltersForAll = filters => {
        saveFiltersForAll(filters)
            .then(() => {
                showSnackbar('New defaults saved successfully', { ...defaultSnackbarConfig, variant: 'success' })
            })
            .catch(() => {
                showSnackbar('An error occurred while saving default filters', {
                    ...defaultSnackbarConfig,
                    variant: 'error',
                })
            })
    }

    return <FiltersSelector saveFiltersForAll={handleSaveFiltersForAll} isCompare {...rest} />
}

const mapStateToProps = state => {
    return {
        filters: filterSelectors.getComparePerspectiveFiltersConfig(state),
        selectedValues: filterSelectors.getAppliedComparePerspectiveFilters(state),
        source: filterSelectors.getPerspectiveFiltersSource(state),
    }
}

const mapDispatchToProps = dispatch => ({
    applyFilters: filters => {
        dispatch(filterCreators.applyComparePerspectiveFilters(filters))
    },
    saveFilters: filters => {
        logEvent('PERSPECTIVE_FILTERS_SAVE_AS_DEFAULT')
        return dispatch(filterCreators.saveDefaultPerspectiveFilters(filters, false))
    },
    saveFiltersForAll: filters => {
        logEvent('PERSPECTIVE_FILTERS_SAVE_AS_DEFAULT_ALL')
        return dispatch(filterCreators.saveDefaultPerspectiveFilters(filters, true))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(PerspectiveCompareFilters)
