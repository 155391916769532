import { Box, Drawer, Tab, Tabs } from '@mui/material'
import { useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { MenuIcon } from 'genesis-suite/components'
import { Filter } from 'genesis-suite/icons'
import { appearanceCreators } from '../../../../actions/creators'
import { useIsMobile } from '../../../../hooks/useIsMobile'
import { appearanceSelectors } from '../../../../selectors'
import GlobalFilters2 from '../../../GlobalFilters2'
import { filterHasValue, useGlobalFilters } from '../../utils/contextFilterUtils'
import { DashboardContext } from '../DashboardContext'
import DashboardFilters from '../DashboardFilters'

export default function DashboardFilterAction() {
    const open = useSelector(appearanceSelectors.getShowPerspectiveFilterPopover) as boolean
    const { filters: globalFilters = [] } = useGlobalFilters() || {}
    const { filters } = useContext(DashboardContext)
    const dispatch = useDispatch()
    const isMobile = useIsMobile()

    const toggleOpen = () => dispatch(appearanceCreators.togglePerspectiveFilterPopover())

    const disableGlobal = !globalFilters?.length
    const disableDashboard = !filters?.length
    const [tabIndex, setTabIndex] = useState(!disableDashboard ? 1 : 0)
    const [openMobileFilters, setOpenMobileFilters] = useState(false)

    const combinedFilters = isMobile ? [...globalFilters, ...filters] : filters
    const disabled = disableGlobal && disableDashboard

    return (
        <>
            <MenuIcon
                badge={combinedFilters?.filter(filterHasValue).length}
                disabled={disabled}
                open={open}
                onClick={isMobile ? () => setOpenMobileFilters(true) : toggleOpen}
                onClose={toggleOpen}
                icon={<Filter />}
                title="Dashboard filters"
                tooltip="Dashboard filters"
            >
                {isMobile ? undefined : (
                    <Box width={filters?.length > 1 ? '450px' : undefined}>
                        <DashboardFilters onDone={toggleOpen} />
                    </Box>
                )}
            </MenuIcon>

            <Drawer anchor="right" open={openMobileFilters} onClose={() => setOpenMobileFilters(false)}>
                <Tabs value={tabIndex} onChange={(_, index) => setTabIndex(index)}>
                    <Tab sx={{ flex: 1 }} disabled={disableDashboard} label="Collection" />
                    <Tab sx={{ flex: 1 }} disabled={disableGlobal} label="Global" />
                </Tabs>

                <Box p={1}>
                    {tabIndex === 0 && <DashboardFilters onDone={() => setOpenMobileFilters(false)} />}
                    {tabIndex === 1 && <GlobalFilters2 onDone={() => setOpenMobileFilters(false)} />}
                </Box>
            </Drawer>
        </>
    )
}
