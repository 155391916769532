import { Box } from '@mui/material'

import { ScrollIntoView } from 'genesis-suite/components'
import { SeriesConfig, Widget } from 'genesis-suite/types/visualTypes'
import BusinessExplorerWidget from './BusinessExplorerWidget'

interface Props {
    configDictionary: { [id: string]: SeriesConfig }
    publishIds?: string[]
    editId?: string
    editConfig: Widget
    onSelect?: (id: string) => void
    onEdit?: (id: string) => void
    onDelete?: (id: string) => void
}

export default function BusinessExplorerWidgets({
    configDictionary,
    publishIds,
    editId,
    editConfig,
    onSelect,
    onEdit,
    onDelete,
}: Props) {
    const configIds = Object.keys(configDictionary)

    return (
        <Box display="flex" flexDirection="column" overflow="hidden" position="relative">
            <Box flex={1} pt={1} overflow="auto" display="flex" flexDirection="column">
                {configIds.map(id => {
                    return (
                        <BusinessExplorerWidget
                            key={id}
                            config={editId === id ? editConfig : configDictionary[id]}
                            selected={editId === id || publishIds.includes(id)}
                            onSelect={onSelect ? () => onSelect(id) : undefined}
                            onEdit={onEdit ? () => onEdit(id) : undefined}
                            onDelete={onDelete ? () => onDelete(id) : undefined}
                        />
                    )
                })}

                <ScrollIntoView count={configIds.length} />
            </Box>
        </Box>
    )
}
