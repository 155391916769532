import DragIcon from '@mui/icons-material/DragIndicator'
import AggregationIcon from '@mui/icons-material/Functions'
import {
    Box,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Skeleton,
    Typography,
} from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-pro'
import { capitalize, isEmpty, uniq, uniqBy } from 'lodash'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import useSWR from 'swr'

import { Collapsable, DateOrTime, DebouncedTextField } from 'genesis-suite/components'
import { aggregationDetailDictionary } from 'genesis-suite/components/aggregation_selector/aggregationOptions'
import { ResourceType } from 'genesis-suite/types/networkTypes'
import { letMeMap, SortBy } from 'genesis-suite/types/utilTypes'
import {
    Aggregation,
    ChartType,
    DataField,
    DraftMeta,
    Widget,
    WidgetRequestFilter,
} from 'genesis-suite/types/visualTypes'
import useResourceMeta from '../../hooks/useResourceMeta'
import { filterVisualObjectsByViewMode, userDisplayName } from '../../lib/manageUtils'
import { visualService } from '../../lib/services'
import { applicationSelectors, authSelectors, deploymentSelectors } from '../../selectors'
import TableHeaderCell from '../TableHeaderCell'
import ViewStatusIndicator from '../ViewStatusIndicator'

export function makeColumns(
    columnWidths: ColumnWidths,
    draggable: boolean,
    filter: WidgetRequestFilter,
    onUpdateFilter: (updated: Partial<WidgetRequestFilter>) => void,
    isPowerUser: (id: string) => void,
    onOpen?: (id: string) => void
): GridColDef[] {
    if (!columnWidths) return []

    return [
        {
            disableColumnMenu: true,
            field: 'title',
            pinnable: false,
            renderHeader: () => <TableHeaderCell title="Title" />,
            renderCell: ({ value, row }) => {
                return (
                    <Box display="flex" alignItems="center" gap={1}>
                        {isPowerUser && draggable && <DragIcon sx={{ cursor: 'grab' }} fontSize="small" />}
                        <Typography
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                ...(onOpen && { cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }),
                            }}
                            style={!value ? { fontStyle: 'italic' } : undefined}
                            onClick={e => {
                                if (!onOpen) return

                                e.stopPropagation()
                                onOpen(row.draft?.visualId ?? row.id)
                            }}
                        >
                            <ViewStatusIndicator type={row?.draft?.type} sx={{ mr: 1 }} />
                            {value || 'Untitled'}
                        </Typography>
                    </Box>
                )
            },
            sortable: true,
            sortingOrder: ['asc', 'desc', null],
            width: columnWidths.title,
        },
        {
            disableColumnMenu: true,
            field: authorKey,
            pinnable: false,
            renderHeader: () => <AuthorHeader filter={filter} onUpdateFilter={onUpdateFilter} />,
            sortable: true,
            sortingOrder: ['asc', 'desc', null],
            width: columnWidths.author,
        },
        {
            disableColumnMenu: true,
            field: 'updatedAt',
            pinnable: false,
            resizable: false,
            renderHeader: () => <TableHeaderCell title="Updated" />,
            renderCell: ({ value }) => <DateOrTime>{value}</DateOrTime>,
            sortable: true,
            sortingOrder: ['desc', 'asc', null],
            width: columnWidths.updatedAt,
        },
        {
            align: 'center',
            disableColumnMenu: true,
            field: 'active',
            hide: !columnWidths.active,
            pinnable: false,
            resizable: false,
            renderHeader: () => <ActiveHeader filter={filter} onUpdateFilter={onUpdateFilter} />,
            renderCell: ({ value }) => (value ? 'Yes' : 'No'),
            sortable: false,
            sortingOrder: ['asc', 'desc', null],
            width: columnWidths.active,
        },
        {
            disableColumnMenu: true,
            field: 'type',
            pinnable: false,
            renderHeader: () => <TypeHeader filter={filter} onUpdateFilter={onUpdateFilter} />,
            renderCell: ({ value }) => capitalize(value),
            sortable: true,
            sortingOrder: ['asc', 'desc', null],
            width: columnWidths.type,
        },
        {
            disableColumnMenu: true,
            field: 'aggregations',
            pinnable: false,
            renderHeader: () => <AggregationHeader filter={filter} onUpdateFilter={onUpdateFilter} />,
            renderCell: ({ value }) => (
                <Box>
                    {value.map(aggregation => {
                        const { Icon } = aggregationDetailDictionary[aggregation] || {}
                        return Icon && <Icon key={aggregation} />
                    })}
                </Box>
            ),
            sortable: false,
            width: columnWidths.aggregations,
        },
        {
            disableColumnMenu: true,
            field: 'aggregateProperties',
            pinnable: false,
            renderHeader: () => (
                <PropertiesHeader filter={filter} onUpdateFilter={onUpdateFilter} type="aggregateProperty" />
            ),
            renderCell: ({ value }) =>
                value.map(p => (p.resourceName === p.name ? p.name : `${p.resourceName}.${p.name}`)).join(', '),
            sortable: false,
            width: columnWidths.aggregateProperties,
        },
        {
            disableColumnMenu: true,
            field: 'valueProperties',
            pinnable: false,
            renderHeader: () => (
                <PropertiesHeader filter={filter} onUpdateFilter={onUpdateFilter} type="valueProperty" />
            ),
            renderCell: ({ value }) =>
                value.map(p => (p.resourceName === p.name ? p.name : `${p.resourceName}.${p.name}`)).join(', '),
            sortable: false,
            width: columnWidths.valueProperties,
        },
    ]
}

interface FilterableHeaderProps {
    filter: WidgetRequestFilter
    onUpdateFilter: (updated: Partial<WidgetRequestFilter>) => void
}

function ActiveHeader({ filter, onUpdateFilter }: FilterableHeaderProps) {
    const options = [
        { value: 'all', label: 'All' },
        { value: 'active', label: 'Public' },
        { value: 'not-active', label: 'Private' },
    ]
    const activeOptionValue = filter.active ? 'active' : filter.active === false ? 'not-active' : 'all'

    function handleChange(value) {
        const active = value === 'active' ? true : value === 'not-active' ? false : undefined
        onUpdateFilter({ active })
    }

    return (
        <TableHeaderCell
            title="Public"
            filterApplied={activeOptionValue !== 'all'}
            filter={
                <List dense disablePadding>
                    {options.map(f => (
                        <ListItemButton
                            key={f.value}
                            selected={f.value === activeOptionValue}
                            onClick={() => handleChange(f.value)}
                        >
                            <ListItemText primary={f.label} />
                        </ListItemButton>
                    ))}
                </List>
            }
            onClearFilter={() => onUpdateFilter({ active: undefined })}
        />
    )
}

function TypeHeader({ filter, onUpdateFilter }: FilterableHeaderProps) {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const view = useSelector(deploymentSelectors.getDeploymentViewFlag)
    const { type, ..._filter } = filter
    const { data } = useSWR(['widget type list', appName, _filter], ([_, appName, _filter]) =>
        visualService.getWidgetDistinctList(appName, 'type', makeFilterOptions(_filter), view)
    )

    return (
        <TableHeaderCell
            title="Type"
            filterApplied={Boolean(filter.type)}
            filter={
                <List dense disablePadding>
                    {data?.map(type => (
                        <ListItemButton
                            key={type}
                            selected={type === filter.type}
                            onClick={e => onUpdateFilter({ type })}
                        >
                            <ListItemText primary={capitalize(type)} />
                        </ListItemButton>
                    ))}
                </List>
            }
            onClearFilter={() => onUpdateFilter({ type: undefined })}
        />
    )
}

function AuthorHeader({ filter, onUpdateFilter }: FilterableHeaderProps) {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const userId = useSelector(authSelectors.getUserId)
    const view = useSelector(deploymentSelectors.getDeploymentViewFlag)
    const { createdBy, ..._filter } = filter
    const { data } = useSWR(['widget createdBy list', appName, _filter], ([_, appName, _filter]) =>
        visualService.getWidgetDistinctList(appName, 'createdBy', makeFilterOptions(_filter), view)
    )

    return (
        <TableHeaderCell
            title="Author"
            filterApplied={Boolean(filter.createdBy)}
            filter={
                <List dense disablePadding>
                    <ListItemButton
                        selected={filter.createdBy === userId}
                        onClick={e => onUpdateFilter({ createdBy: userId })}
                    >
                        <ListItemText primary="Yours" />
                    </ListItemButton>
                    <Divider />
                    {data
                        ?.filter(user => user.id !== userId)
                        .map(user => (
                            <ListItemButton
                                key={user.id}
                                selected={user.id === filter.createdBy}
                                onClick={e => onUpdateFilter({ createdBy: user.id })}
                            >
                                <ListItemText primary={`${user.firstName} ${user.lastName}`} />
                            </ListItemButton>
                        ))}
                </List>
            }
            onClearFilter={() => onUpdateFilter({ createdBy: undefined })}
        />
    )
}

function AggregationHeader({ filter, onUpdateFilter }: FilterableHeaderProps) {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const view = useSelector(deploymentSelectors.getDeploymentViewFlag)
    const { aggregation, hasAggregation, ..._filter } = filter
    const { data } = useSWR(['widget aggregation list', appName, _filter], ([_, appName, _filter]) => {
        return visualService.getWidgetDistinctList(appName, 'aggregation', makeFilterOptions(_filter), view)
    })

    return (
        <TableHeaderCell
            title={<AggregationIcon />}
            filterApplied={Boolean(filter.aggregation || filter.hasAggregation != null)}
            filter={
                <List dense disablePadding>
                    <ListItemButton
                        selected={!filter.aggregation && filter.hasAggregation == null}
                        onClick={e => onUpdateFilter({ aggregation: undefined, hasAggregation: undefined })}
                    >
                        <ListItemText primary="All" />
                    </ListItemButton>
                    <ListItemButton
                        selected={filter.hasAggregation}
                        onClick={e => onUpdateFilter({ aggregation: undefined, hasAggregation: true })}
                    >
                        <ListItemText primary="Only aggregations" />
                    </ListItemButton>
                    <ListItemButton
                        selected={filter.hasAggregation === false}
                        onClick={e => onUpdateFilter({ aggregation: undefined, hasAggregation: false })}
                    >
                        <ListItemText primary="No aggregations" />
                    </ListItemButton>
                    <Divider />
                    {data?.map(aggregation => {
                        const { Icon, label } = aggregationDetailDictionary[aggregation] || {}
                        return (
                            <ListItemButton
                                key={aggregation}
                                selected={aggregation === filter.aggregation}
                                onClick={e => onUpdateFilter({ aggregation, hasAggregation: undefined })}
                            >
                                {Icon && (
                                    <ListItemIcon>
                                        <Icon fontSize="small" />
                                    </ListItemIcon>
                                )}
                                <ListItemText primary={label} />
                            </ListItemButton>
                        )
                    })}
                </List>
            }
            onClearFilter={() => onUpdateFilter({ aggregation: undefined, hasAggregation: undefined })}
        />
    )
}

function PropertiesHeader({
    filter,
    onUpdateFilter,
    type,
}: FilterableHeaderProps & { type: 'aggregateProperty' | 'valueProperty' }) {
    const filterKey = type === 'aggregateProperty' ? 'aggregatePropertyId' : 'valuePropertyId'

    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const view = useSelector(deploymentSelectors.getDeploymentViewFlag)
    const { [filterKey]: _, ..._filter } = filter
    const { data } = useSWR([`widget ${type} list`, appName, _filter], ([_, appName, _filter]) => {
        return visualService.getWidgetDistinctList(appName, type, makeFilterOptions(_filter), view)
    })

    const [search, setSearch] = useState('')

    const resourceTypeAndNames = uniqBy(
        data?.map(f => ({ type: f.resourceType, name: f.resourceName })) ?? [],
        'name'
    ).sort()
    const propertiesInResources = resourceTypeAndNames.map(({ name, type }) => {
        return { name, type, properties: data.filter(p => p.resourceName === name) }
    })

    return (
        <TableHeaderCell
            title={type === 'aggregateProperty' ? 'Aggregated by' : 'Values'}
            filterApplied={Boolean(filter[filterKey])}
            filter={
                <Box display="flex" flexDirection="column" p={1} overflow="hidden">
                    <DebouncedTextField
                        autoFocus
                        InputProps={{ placeholder: 'Search' }}
                        onChange={setSearch}
                        onFocus={e => e.target.select()}
                        value={search}
                    />
                    <Box display="flex" flexDirection="column" overflow="auto">
                        {propertiesInResources.map(props => (
                            <ResourceItem
                                key={props.name}
                                {...props}
                                search={search.toLocaleLowerCase()}
                                selectedId={filter[filterKey]}
                                onClick={id => onUpdateFilter({ [filterKey]: id })}
                            />
                        ))}
                    </Box>
                </Box>
            }
            onClearFilter={() => onUpdateFilter({ [filterKey]: undefined })}
        />
    )
}

interface ResourceItemProps {
    name: string
    onClick: (propertyId: string) => void
    properties: DataField[]
    search: string
    selectedId: string
    type: ResourceType
}

function ResourceItem(props: ResourceItemProps) {
    const { name, search } = props
    const [open, setOpen] = useState(false)

    useEffect(() => {
        if (search && !open) setOpen(true)
        if (!search && open) setOpen(false)
    }, [search])

    return (
        <Collapsable open={open} onToggle={() => setOpen(s => !s)} header={name} collapseProps={{ mountOnEnter: true }}>
            <ResourceProperties {...props} />
        </Collapsable>
    )
}

function ResourceProperties({ name, onClick, properties, search, selectedId, type }: ResourceItemProps) {
    const [resource] = useResourceMeta(type, name)

    if (!resource) {
        return (
            <Box>
                <ListItem dense>
                    <Skeleton height={20} width="100%" />
                </ListItem>
                <ListItem dense>
                    <Skeleton height={20} width="100%" />
                </ListItem>
            </Box>
        )
    }

    const filteredProperties = properties
        .map(({ name }) => resource.properties.find(p => p.name === name))
        .filter(p => p.displayName.toLocaleLowerCase().includes(search) || p.name.toLocaleLowerCase().includes(search))

    return (
        <List dense disablePadding>
            {filteredProperties.map(p => (
                <ListItemButton key={p.id} dense selected={p.id === selectedId} onClick={e => onClick(p.id)}>
                    <ListItemText primary={p.name} />
                </ListItemButton>
            ))}
        </List>
    )
}

interface ColumnWidths {
    active?: number
    aggregations: number
    aggregateProperties: number
    author: number
    title: number
    type: number
    valueProperties: number
    updatedAt: number
}

export function useColumnWidths(isPowerUser: boolean): {
    columnWidths: ColumnWidths
    setTableWidth: Dispatch<SetStateAction<number>>
} {
    const [tableWidth, setTableWidth] = useState(0)
    const [widths, setWidths] = useState<ColumnWidths>(null)

    useEffect(() => {
        if (!tableWidth) return
        if (!isEmpty(widths)) return
        updateWidths()
    }, [tableWidth])

    function updateWidths() {
        const staticColumnWidths = {
            ...(isPowerUser && { active: 105 }),
            aggregations: 75,
            updatedAt: 110,
            type: 150,
        }
        const remainingWidth = tableWidth - 10 - Object.values(staticColumnWidths).reduce((acc, cur) => acc + cur, 0)

        function spaceColumn(percent: number, minWidth: number) {
            return Math.floor(Math.max(remainingWidth * percent, minWidth))
        }

        setWidths({
            ...staticColumnWidths,
            aggregateProperties: spaceColumn(0.2, 155),
            author: spaceColumn(0.3, 150),
            title: spaceColumn(0.3, 200),
            valueProperties: spaceColumn(0.2, 150),
        })
    }

    return { columnWidths: widths, setTableWidth }
}

export const authorKey = 'author'
export const createdBySortKey = 'createdBy.lastName'

export interface RowData {
    active: boolean
    author: string
    aggregations: Aggregation[]
    aggregateProperties: DataField[]
    draft: DraftMeta
    id: string
    isUsers: boolean
    thumbnail: string
    title: string
    type: ChartType
    valueProperties: DataField[]
    updatedAt: Date
}

export const makeRows = (widgets: Widget[], userId: string): RowData[] =>
    filterVisualObjectsByViewMode(widgets).map(w => {
        let aggregations: Aggregation[] = []
        let aggregateProperties: DataField[] = []
        let valueProperties: DataField[] = []

        if ('series' in w) {
            aggregateProperties = letMeMap(w.categories)?.map(c => c.field)
            for (const { values, subSeries } of w.series) {
                aggregations = [...aggregations, ...letMeMap(values).map(v => v.aggregation)]
                if (subSeries) aggregateProperties = [...aggregateProperties, subSeries.field]
                valueProperties = [...valueProperties, ...letMeMap(values).map(v => v.field)]
            }

            aggregations = uniq(aggregations).filter(a => a !== Aggregation.NONE)
            aggregateProperties = uniq(aggregateProperties)
            valueProperties = uniq(valueProperties)
        }

        return {
            active: w.active || false,
            aggregateProperties,
            aggregations,
            author: userDisplayName(w.createdBy),
            draft: w.draft,
            id: w.id,
            isUsers: w.createdBy.id === userId,
            thumbnail: w.thumbnail,
            title: w.title,
            type: w.type,
            valueProperties,
            updatedAt: w.updatedAt,
        }
    })

export function makeFilterOptions(filter: WidgetRequestFilter, sortBy?: SortBy, page?: number) {
    return {
        ...filter,
        sortBy,
        ...(page && { pageSize: 30, page }),
    }
}
