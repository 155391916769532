import Check from '@mui/icons-material/Check'
import Circle from '@mui/icons-material/Circle'
import { Box, Step, StepConnector, StepLabel, Stepper } from '@mui/material';
import { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/styles'
import makeStyles from '@mui/styles/makeStyles'
import { Spinner } from 'genesis-suite/components'
import { cognitiveIconBaseUrl } from 'genesis-suite/components/CognitiveIcon'
import { StatusTrackerConfig } from 'genesis-suite/types/visualTypes'
import { forwardRef, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import tinyColor from 'tinycolor2'
import useWidgetColors from '../../../hooks/useWidgetColors'
import { modelService } from '../../../lib/services'
import { applicationSelectors, themeSelectors } from '../../../selectors'
import { ThemeMode } from '../../../types/ThemeTypes'
import { WidgetProps } from '../../../types/WidgetTypes'

const useStyles = makeStyles(({ palette }) => ({
    label: {
        color: palette.text.primary,
    },
    stepIconDark: {
        color: 'grey',
        borderRadius: '50%',
    },
    stepIconLight: {
        backgroundColor: '#fff',
        color: '#fff',
        borderRadius: '50%',
    },
    textDark: {
        color: '#000',
        fill: '#000',
    },
    textLight: {
        color: '#fff ',
        fill: '#fff ',
    },
    completed: ({ defaultColors }: any) => ({
        color: defaultColors[0],
    }),
    spinner: { flex: 1, display: 'flex', flexDirection: 'column' },
}))

const StepConnectorStyled = styled(StepConnector)((props: { iscustom; defaultcolors }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: props.iscustom ? 25 : 10,
        left: props.iscustom ? 'calc(-50% + 30px)' : 'calc(-50% + 16px)',
        right: props.iscustom ? 'calc(50% + 30px)' : 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: props.defaultcolors[0],
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: props.defaultcolors[0],
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}))

const StepIconRoot = styled('div')((props: { defaultcolors }) => ({
    display: 'flex',
    height: '50px',
    alignItems: 'center',
    '& .QontoStepIcon-completedIcon': {
        color: props.defaultcolors[0],
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}))

function isDarkBackground(backgroundColor) {
    if (!backgroundColor) return false

    const color = tinyColor(backgroundColor)
    return color.isDark()
}

const CustomIconStyled = styled('div')((props: { themeMode; stage; activeStep; defaultColors }) => ({
    zIndex: 1,
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    backgroundColor: props.themeMode === ThemeMode.DARK ? '#fff' : '#ccc',
    ...(props.stage.order <= props.activeStep && {
        backgroundColor: props.defaultColors[0],
    }),
}))

function RenderIcon(defaultColors, activeStep, stage, themeMode) {
    const darkBackground = stage.order <= activeStep ? isDarkBackground(defaultColors[0]) : isDarkBackground('#ccc')
    const iconColorInvert = darkBackground ? '1' : '0.305'

    return stage?.icon ? (
        <CustomIconStyled
            themeMode={themeMode}
            stage={stage}
            activeStep={activeStep}
            defaultColors={defaultColors}
            style={{ padding: '10px' }}
        >
            <img
                src={`${cognitiveIconBaseUrl}${stage.icon.file}`}
                style={{ filter: `invert(${iconColorInvert})` }}
            ></img>
        </CustomIconStyled>
    ) : (
        <StepIconRoot defaultcolors={defaultColors}>
            {activeStep === stage.order ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <Circle className="QontoStepIcon-circle" />
            )}
        </StepIconRoot>
    )
}

function CustomStepper({ customStages, activeStep, stages, defaultColors, classes, themeMode }) {
    return customStages ? (
        <Stepper
            sx={{ paddingLeft: '5px', paddingRight: '5px' }}
            activeStep={activeStep}
            alternativeLabel
            connector={<StepConnectorStyled iscustom={true} defaultcolors={defaultColors} />}
        >
            {stages.map((stage, i) => (
                <Step key={i}>
                    <StepLabel
                        icon={RenderIcon(defaultColors, activeStep, stage, themeMode)}
                        classes={{ labelContainer: classes.label }}
                    >
                        {stage.title}
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    ) : (
        <Stepper
            sx={{ paddingLeft: '5px', paddingRight: '5px' }}
            activeStep={activeStep}
            alternativeLabel
            connector={<StepConnectorStyled iscustom={false} defaultcolors={defaultColors} />}
        >
            {stages?.map((stage, i) => (
                <Step key={i}>
                    <StepLabel
                        StepIconProps={{
                            classes: {
                                root: themeMode === ThemeMode.DARK ? classes.stepIconLight : classes.stepIconDark,
                                text:
                                    themeMode === ThemeMode.DARK && i !== activeStep
                                        ? classes.textDark
                                        : classes.textLight,
                                completed: classes.completed,
                            },
                        }}
                        classes={{ labelContainer: classes.label }}
                    >
                        {stage}
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    )
}

export default forwardRef<HTMLDivElement, WidgetProps<StatusTrackerConfig>>((props, ref) => {
    const { config, data } = props
    const defaultColors = useWidgetColors()
    const classes = useStyles({ defaultColors })
    const themeMode = useSelector(themeSelectors.getThemeMode)
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const [stageData, setStageData] = useState(undefined)
    const [loading, setLoading] = useState(false)
    const content = data[0].data[0].rawData
    const searchProp = {
        fieldNames: config?.series[0].values[0].field.name,
    }

    useEffect(() => {
        if (config?.series[0].customStages) return
        setLoading(false)
        modelService.getResourceValues(appName, config.series[0].service.name, searchProp).then(res => {
            setStageData(res)
            setLoading(false)
        })
    }, [config?.series[0].values[0].field.name])

    if (!content || !config) return null

    let stages = null
    let activeStep = 0
    const series = config.series[0]
    const customStages = series.customStages ? [...series.customStages] : null
    if (customStages) {
        stages = customStages.sort((a, b) => a.order - b.order)
        activeStep = stages.find(s => s.name === content.flat(1)[0])?.order ?? 0
    } else {
        stages = stageData?.values.map(d => d[series.values[0].field.name])
        activeStep = stages?.indexOf(content.flat(1)[0]) + 1
    }

    return (
        <Box ref={ref} sx={{ width: '100%', height: '100%', overflowX: 'auto', paddingTop: 5 }}>
            <Spinner className={classes.spinner} show={loading}>
                <CustomStepper
                    customStages={customStages}
                    activeStep={activeStep}
                    stages={stages}
                    defaultColors={defaultColors}
                    classes={classes}
                    themeMode={themeMode}
                />
            </Spinner>
        </Box>
    )
})
