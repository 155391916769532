import produce from 'immer'
import { dialogTypes } from '../actions/types'

export default (state = [], action) => {
    const { type, payload } = action

    return produce(state, draft => {
        switch (type) {
            case dialogTypes.SHOW_DIALOG:
                draft.push({
                    ...payload,
                    TitleIcon: payload.titleIcon,
                })
                return

            case dialogTypes.HIDE_DIALOG:
                draft.splice(draft.findIndex(dialog => dialog.key === payload.key), 1)
                return

            default:
                return
        }
    })
}
