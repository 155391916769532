import { useContext } from 'react'
import { Checkbox, FormControlLabel } from '@mui/material'

import { ChartType } from 'genesis-suite/types/visualTypes'
import EditorWrapper from '../EditorWrapper'
import SubSeriesSelector from '../../selectors/SubSeriesSelector'
import { ConfigContext } from '../../ConfigContext'
import ChartFormatEditor from '../FormatEditor/ChartFormatEditor'
import SeriesTooltipEditor from './SeriesTooltipEditor'
import AggregationSelect from '../../AggregationSelect'

export default function SeriesEditorPackedBubble() {
    const { config, selectedField, dispatch } = useContext(ConfigContext)
    if (config.type !== ChartType.PACKED_BUBBLE) return null

    const activeSeries = config.series[selectedField.index]

    return (
        <>
            <AggregationSelect />

            <SubSeriesSelector label="Grouping" />

            <CheckBox
                label="Split series"
                checked={activeSeries?.splitSeries ?? false}
                onChange={e => dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: { splitSeries: e.target.checked } })}
            />

            <ChartFormatEditor />

            <EditorWrapper header="Tooltip">
                <SeriesTooltipEditor />
            </EditorWrapper>
        </>
    )
}

function CheckBox({ label, ...rest }) {
    return <FormControlLabel control={<Checkbox color="primary" {...rest} />} label={label} />
}
