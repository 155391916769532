import { Box, Typography } from '@mui/material'
import Spinner from 'genesis-suite/components/Spinner'
import { ChartType, InlineNodeFilter, NodeFilterWithValue, Widget } from 'genesis-suite/types/visualTypes'
import hash from 'object-hash'
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import Measure from 'react-measure'
import { useDebouncedCallback } from 'use-debounce'
import { DeploymentView } from '../../types/DeploymentTypes'
import {
    CustomInteractions,
    ParsedResponse,
    WidgetInteractions,
    WidgetRenderProps,
    WidgetStatus,
} from '../../types/WidgetTypes'
import WidgetEmpty from '../widgets/WidgetEmpty'
import WidgetError from '../widgets/WidgetError'
import WidgetUnauthorized from '../widgets/WidgetUnauthorized'
import WidgetInteraction from './WidgetInteraction'
import WidgetVisual2 from './WidgetVisual2'
import makeWidgetLayout, { createTrellisAxes } from './utils/makeWidgetLayout'
import noData from './utils/noData'
import skipData from './utils/skipData'
import trellisData from './utils/trellisData'
import { useWidgetData } from './utils/useWidgetData'

export interface Props {
    /** Used to provide your own fetching mechanism (null will trigger this) */
    controlled?: WidgetRenderProps
    config: Widget
    customInteractions?: CustomInteractions
    deploymentView?: DeploymentView
    editing?: boolean
    interactions?: WidgetInteractions
    nodeFilters?: Array<NodeFilterWithValue>
    onData?: (response: ParsedResponse) => void
    onStatus?: (status: WidgetStatus) => void
}

interface WidgetContainerDimensions {
    width: number
    height: number
}

export default forwardRef<HTMLDivElement, Props>((props, ref) => {
    const {
        controlled,
        nodeFilters,
        interactions,
        onData,
        onStatus,
        config: inputConfig,
        deploymentView,
        customInteractions,
    } = props

    const [widgetContainerDim, setWidgetContainerDim] = useState<WidgetContainerDimensions>({ width: 0, height: 0 })
    const [trellisOverflow, setTrellisOverflow] = useState(false)

    const res = useWidgetData({ controlled, config: inputConfig, deploymentView, onData, onStatus, nodeFilters })
    const { refresh, status, page, updatePage, ...responseWithConfig } = res
    const { config, data, groupNames, yAxisMinMax } = responseWithConfig
    const trellisedData = trellisData(responseWithConfig)
    const widgetCount = trellisedData?.length ?? 1
    const layout = makeWidgetLayout(widgetContainerDim.width, widgetCount, config)
    const inlineFilters = nodeFilters?.filter(f => f.source === 'inline') as InlineNodeFilter[]

    const layoutWrapper = useRef<HTMLDivElement>()
    useEffect(() => {
        if (layoutWrapper?.current) {
            setTrellisOverflow(layoutWrapper.current.scrollHeight > layoutWrapper.current.clientHeight)
        }
    }, [config, widgetContainerDim])

    const debouncedResize = useDebouncedCallback(contentRect => {
        setWidgetContainerDim({ width: contentRect.entry?.width, height: contentRect.entry?.height })
    }, 200)

    const selectedPoints = useMemo(
        () => inlineFilters?.find(f => f.widgetId === config?.id)?.dataIndexes ?? [],
        [inlineFilters]
    )
    const configHash = hash(config || {})

    let content
    if (status === 'error') content = <WidgetError triggerRefresh={refresh} />
    else if (status === 'unauthorized') content = <WidgetUnauthorized />
    else if (!config) content = null
    else if (!skipData(inputConfig) && noData(data)) {
        content = <WidgetEmpty />
    } else
        content = (
            <WidgetInteraction {...responseWithConfig} inlineFilters={inlineFilters} interactions={interactions}>
                {(canClick, setSelectedPoint) => {
                    return (
                        <Box ref={layoutWrapper} sx={{ height: '100%' }}>
                            <Box ref={ref} sx={layout.containerStyle}>
                                {trellisedData.map((series, index) => {
                                    const { data, trellisGroup } = series || {}
                                    const trellisMetaData = { index, widgetCount, trellisOverflow }
                                    const { xAxis, yAxis } = createTrellisAxes(config, layout, trellisMetaData)
                                    const key = trellisGroup ? `${index}${trellisGroup}` : `${index}`
                                    const correctedConfig = {
                                        ...config,
                                        ...(xAxis && { xAxis }),
                                        ...(yAxis && { yAxis }),
                                    }
                                    return (
                                        <Box
                                            key={key}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                flex: 1,
                                                overflow: 'hidden',
                                            }}
                                        >
                                            <TrellisTitle config={config} title={trellisGroup} />
                                            <WidgetVisual2
                                                canClick={canClick}
                                                config={correctedConfig}
                                                customInteractions={customInteractions}
                                                data={data}
                                                page={page}
                                                selectedPoints={selectedPoints}
                                                updatePage={updatePage}
                                                setSelectedPoint={setSelectedPoint}
                                                trellis={trellisGroup ? { index, name: trellisGroup } : undefined}
                                                groupNames={groupNames}
                                                yAxisMinMax={yAxisMinMax}
                                            />
                                        </Box>
                                    )
                                })}
                            </Box>
                        </Box>
                    )
                }}
            </WidgetInteraction>
        )

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback} resetKeys={[configHash]}>
            <Measure onResize={debouncedResize}>
                {({ measureRef }) => (
                    <Box ref={measureRef} flex={1} overflow="hidden" height="100%">
                        <Spinner show={showSpinner(config?.type, status)}>{content}</Spinner>
                    </Box>
                )}
            </Measure>
        </ErrorBoundary>
    )
})

function TrellisTitle({ config, title }) {
    if (!title) return null

    switch (config.type) {
        case ChartType.BULLET:
        case ChartType.LABEL:
            return null
        default:
            return <Typography sx={{ fontWeight: 'bold' }}>{title}</Typography>
    }
}

function ErrorFallback({ error }) {
    return (
        <div>
            <Typography>Something went wrong:</Typography>
            <pre>{error.message}</pre>
        </div>
    )
}

function showSpinner(type: ChartType, status: WidgetStatus) {
    if (type === ChartType.LABEL) return false
    return status === 'loading'
}
