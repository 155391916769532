import { useEffect, useMemo, useRef, useState } from 'react'
import { isEmpty } from 'lodash'
import { useSnackbar } from 'notistack'
import { useSelector } from 'react-redux'
import { Trash } from 'genesis-suite/icons'
import { CheckRounded, CloseRounded, MoreVertRounded } from '@mui/icons-material'
import { Autocomplete, Box, Divider, IconButton, Menu, MenuItem, Popover, TextField } from '@mui/material'

import { parseFilter } from '../lib/utils'
import { filterService } from '../lib/services'
import { applicationSelectors, authSelectors, filterSelectors, widgetSelectors } from '../selectors'
import FilterGroupIcon from '@mui/icons-material/FilterBAndWRounded'
import makeStyles from '@mui/styles/makeStyles'

type PFilterConfig = {
    Name: string
}
type PFilter = { [key: string]: PFilterVal }
type PFilterVal = {
    values: any[]
    range: any
}

interface Props {
    filterConfigs: PFilterConfig[]
    onChange: (changedFilters: { filterVal: PFilterVal; filterConfig: PFilterConfig }[]) => void
    perspectiveFilters: PFilter
}

const useStyles = makeStyles(() => ({
    paper: {
        padding: '0px 8px 8px 8px',
    },
}))

const NeoFilterGroupController = ({ filterConfigs, onChange, perspectiveFilters }: Props) => {
    const [fgName, setFGName] = useState('')
    const [editing, setEditing] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [loadingFG, setLoadingFG] = useState(false)
    const [menuAnchor, setMenuAnchor] = useState(null)
    const [selectedFG, setSelectedFG] = useState(null)
    const [filterGroups, setFilterGroups] = useState([])
    const filterGroupRef = useRef(null)
    const [openFG, setOpenFG] = useState(false)

    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const persId = useSelector(widgetSelectors.getCurrentPerspectiveId)
    const { enqueueSnackbar } = useSnackbar()
    const classes = useStyles()

    useEffect(() => {
        ;(async () => {
            try {
                setLoadingFG(true)
                const groups = await filterService.getFilterGroups(appName, persId)
                setFilterGroups(groups)
            } catch (err) {
                console.error(err)
            } finally {
                setLoadingFG(false)
            }
        })()
    }, [])

    const handleFGChange = (e, fg) => {
        setSelectedFG(fg)
        onChange(
            fg?.Filters?.map(f => {
                const filterConfig = filterConfigs.find(fc => fc.Name === f.FilterName)
                return { filterVal: parseFilter(f), filterConfig }
            })
        )
    }
    const handleEditFG = e => {
        setFGName(selectedFG.Name)
        setEditing(true)
        setAnchorEl(e.target)
    }
    const handleDeleteFG = async () => {
        try {
            setLoadingFG(true)
            await filterService.deleteFilterGroup(appName, persId, selectedFG.Id)
            handleClose()
        } catch {
            enqueueSnackbar('An error occured deleting filter group!', { variant: 'error' })
        } finally {
            setLoadingFG(false)
        }
    }
    const pFiltersToContext = (perFilters: PFilter) => {
        const filtered = Object.entries(perFilters).filter(([k, v]) => v.values?.length || !isEmpty(v.range))
        return filtered.map(([k, v]) => {
            const filterConfig = filterConfigs.find(f => f.Name === k)
            return filterSelectors.getConfigurationFromSelection(v, filterConfig)
        })
    }
    const handleSaveFG = async () => {
        const request = {
            widgetId: persId,
            name: fgName,
            filters: pFiltersToContext(perspectiveFilters),
            id: undefined,
        }
        if (editing) request.id = selectedFG.Id
        try {
            setLoadingFG(true)
            const res = await filterService.saveFilterGroup(appName, request)
            if (!editing) {
                setFilterGroups([...filterGroups, res])
                setSelectedFG(res)
            } else {
                const { CreatedBy, ...rest } = res
                const newFG = { ...selectedFG, ...rest }
                setFilterGroups(filterGroups.map(fg => (fg.Id === res.Id ? { ...fg, ...rest } : fg)))
                setSelectedFG(newFG)
            }
            enqueueSnackbar('Filter group saved!', { variant: 'success' })
            handleClose()
        } catch (err) {
            console.error(err)
            enqueueSnackbar('Failed to save filter group', { variant: 'error' })
        } finally {
            setLoadingFG(false)
        }
    }
    const handleCloseMenu = () => setMenuAnchor(null)
    const handleClose = () => {
        setFGName('')
        setAnchorEl(null)
        setMenuAnchor(null)
        setEditing(false)
    }
    const openFGName = e => {
        setAnchorEl(e.target)
    }
    const noFilterValues = useMemo(
        () => !Object.values(perspectiveFilters).some(f => f.values?.length || !isEmpty(f.range)),
        [perspectiveFilters]
    )
    const user = useSelector(authSelectors.getUserId)
    const isOwner = useMemo(() => selectedFG?.CreatedBy === user, [user, selectedFG])

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', width: 50 }}>
            <IconButton
                aria-label="Filter Groups"
                onClick={() => setOpenFG(true)}
                sx={{
                    color: 'inherit',
                    p: 0,
                    pl: 0.25,
                }}
                ref={filterGroupRef}
            >
                <FilterGroupIcon sx={{ fontSize: '18px' }} />
            </IconButton>
            <Popover
                anchorEl={filterGroupRef.current}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                onClose={() => setOpenFG(false)}
                open={openFG}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                classes={{ paper: classes.paper }}
            >
                <Autocomplete
                    value={selectedFG}
                    loading={loadingFG}
                    options={filterGroups}
                    onChange={handleFGChange}
                    sx={{ width: 250, mr: 1 }}
                    getOptionLabel={opt => opt.Name}
                    isOptionEqualToValue={(opt, val) => opt.Id === val.Id}
                    clearOnBlur={false}
                    renderInput={params => (
                        <TextField
                            sx={{
                                '& .MuiFormLabel-root, .MuiAutocomplete-endAdornment svg': {
                                    color: 'text.primary',
                                },
                                '& .MuiInputBase-root:before': {
                                    borderBottomColor: 'text.primary',
                                },
                            }}
                            {...params}
                            label="Filter Group"
                        />
                    )}
                />
            </Popover>
            <IconButton sx={{ color: 'text.primary', p: 0 }} onClick={e => setMenuAnchor(e.target)}>
                <MoreVertRounded />
            </IconButton>
            <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={handleCloseMenu}>
                <MenuItem disabled={!isOwner} onClick={handleEditFG}>
                    Update selected Filter Group
                </MenuItem>
                <MenuItem disabled={noFilterValues} onClick={openFGName}>
                    Create new Filter Group
                </MenuItem>
            </Menu>
            <Popover
                PaperProps={{ sx: { p: 1, display: 'flex', alignItems: 'center' } }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorEl={anchorEl}
            >
                <TextField
                    autoFocus
                    size="small"
                    value={fgName}
                    variant="outlined"
                    placeholder="Enter Filter Group Name"
                    sx={{ display: 'inline', width: 200 }}
                    onChange={e => setFGName(e.target.value)}
                    onKeyDown={e => {
                        e.stopPropagation()
                        e.key === 'Enter' && handleSaveFG()
                    }}
                />
                <IconButton disabled={!fgName || loadingFG} size="small" onClick={handleSaveFG}>
                    <CheckRounded fontSize="small" />
                </IconButton>
                <IconButton size="small" onClick={handleClose}>
                    <CloseRounded fontSize="small" />
                </IconButton>
                {isOwner && editing && (
                    <IconButton onClick={handleDeleteFG}>
                        <Trash />
                    </IconButton>
                )}
            </Popover>
            <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={({ custom }) => ({
                    borderColor: 'text.primary',
                    mt: 0,
                    mb: 0,
                    mr: '4px',
                    ml: '4px',
                    borderWidth: '1px',
                    height: `${custom.topNav.height - 10}px`,
                    alignSelf: 'center',
                })}
            />
        </Box>
    )
}

export default NeoFilterGroupController
