import React, { useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import { LinearProgress } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'

import { appearanceSelectors } from '../selectors'
import { appearanceCreators } from '../actions/creators'

const useStyles = makeStyles(() => ({
    progress: {
        width: '100vw',
        position: 'absolute',
    },
}))

export default function TopLoader() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const showLoader = useSelector(appearanceSelectors.getShowLoader)

    useEffect(() => {
        if (showLoader) dispatch(appearanceCreators.toggleLoader())
    }, [])

    if (showLoader) return <LinearProgress className={classes.progress} />
    return null
}
