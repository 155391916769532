import React from 'react'
import { TabContext, TabList } from '@mui/lab'
import { Box, Tab, Tooltip, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { Property } from 'genesis-suite/types/networkTypes'
import { ColumnId, FormColumn } from 'genesis-suite/types/visualTypes'
import ColumnSelector from './ColumnSelector'
import ColumnEditorTabs from './ColumnEditorTabs'

const useStyles = makeStyles(({ custom, palette, spacing }) => ({
    columnTabsRoot: { ...custom.builderContainer, flex: 1, display: 'flex', overflow: 'auto' },
    columns: {
        height: 48,
        display: 'flex',
        padding: spacing(1),
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRight: '1px solid #bdbdbd',
        borderBottom: '1px solid #bdbdbd',
    },
    tabList: {
        flex: 1,
        width: 150,
        borderRight: `1px solid ${palette.grayscale.light}`,
    },
    panel: { padding: 0, flex: 2 },
    tab: {
        minWidth: 'unset',
        fontSize: '.75rem',
        '& > .MuiTab-wrapper': {
            width: 'auto',
            minHeight: 14,
            display: 'block',
            overflow: 'hidden',
            marginRight: 'auto',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
    },
}))

interface ColumnTabsProps {
    columnId: ColumnId
    columns: FormColumn[]
    properties: Property[]
    onFieldSelect: (key: ColumnId) => void
    onChange: (columns: FormColumn[]) => void
}

export default function ColumnTabs({
    columns,
    columnId,
    onFieldSelect,
    properties,
    onChange,
    ...rest
}: ColumnTabsProps) {
    const classes = useStyles({})

    const handleChange = (column: FormColumn) => {
        const index = columns.findIndex(f => f.id === columnId)
        const nextColumns = [...columns]
        nextColumns.splice(index, 1, column)
        onChange(nextColumns)
    }

    if (!columnId) return null
    const column = columns.find(f => f.id === columnId)

    return (
        <div className={classes.columnTabsRoot}>
            <TabContext value={columnId}>
                <Box display="flex" flexDirection="column">
                    <Box className={classes.columns}>
                        <Typography variant="caption">Columns</Typography>
                        <ColumnSelector
                            columns={columns}
                            onChange={onChange}
                            properties={properties}
                            onFieldSelect={onFieldSelect}
                        />
                    </Box>
                    <TabList
                        variant="scrollable"
                        orientation="vertical"
                        className={classes.tabList}
                        onChange={(e, value) => onFieldSelect(value)}
                    >
                        {columns.map(f => (
                            <Tab
                                key={f.id}
                                value={f.id}
                                className={classes.tab}
                                label={
                                    <Tooltip title={f.title}>
                                        <span>{f.title}</span>
                                    </Tooltip>
                                }
                            />
                        ))}
                    </TabList>
                </Box>
                <div className={classes.panel}>
                    <ColumnEditorTabs {...{ ...rest, key: columnId, column, properties, onChange: handleChange }} />
                </div>
            </TabContext>
        </div>
    )
}
