import DataIcon from '@mui/icons-material/AssessmentRounded'
import ExistingWidgetIcon from '@mui/icons-material/WidgetsRounded'
import { ChartType, WidgetWrapper } from 'genesis-suite/types/visualTypes'
import { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { navigationCreators } from '../../../actions/creators'
import { widgetConstants } from '../../../constants'
import { routePaths } from '../../../lib/routes'
import { authSelectors } from '../../../selectors'
import { DashboardContext } from '../dashboard/DashboardContext'
import isInlineWidget from './isInlineWidget'
import widgetDictionary from './widgetDictionary'

export default function useNewWidgetList(onlyNew = false, onlyStatic = false, onClose: () => void = undefined) {
    const isPowerUser = useSelector(authSelectors.getIsPowerUser)
    const {
        config: dashboard,
        onEditDone,
        toggleShowExistingWidgetsDialog,
        updateConfig: updateDashboard,
    } = useContext(DashboardContext) || {}

    const dispatch = useDispatch()
    async function createNew(type?: ChartType) {
        if (isInlineWidget(type)) {
            if (!dashboard) return console.error('Static widgets only go on dashboards')

            const newWidget: WidgetWrapper = { inline: { type } }
            const widgets = [...(dashboard.widgets ?? []), newWidget]
            updateDashboard({ widgets })
            onClose?.()
            return
        }

        let state: any = { type }
        if (dashboard) {
            await onEditDone()
            const { focalPoint, id } = dashboard
            state = { ...state, focalPoint, dashboardId: id }
        }
        dispatch(navigationCreators.goTo(routePaths.EDIT, widgetConstants.Edit.NEW_ROUTE, state))
    }

    const list = [
        {
            Icon: ExistingWidgetIcon,
            name: 'Existing',
            description: 'Select an existing widget.',
            onClick: () => {
                onClose && onClose()
                toggleShowExistingWidgetsDialog()
            },
            hide: onlyNew || onlyStatic,
        },
        {
            Icon: DataIcon,
            name: 'Data',
            description: 'Use the Nodes & Links of the Digital Duplicate to create a visualization.',
            onClick: () => createNew(),
            hide: onlyStatic,
        },
        {
            Icon: widgetDictionary[ChartType.WEBVIEW].Icon,
            name: widgetDictionary[ChartType.WEBVIEW].title,
            description: 'Embed an external webpage on your collection.',
            onClick: () => createNew(ChartType.WEBVIEW),
            hide: onlyStatic,
        },
        {
            Icon: widgetDictionary[ChartType.TEXT].Icon,
            name: widgetDictionary[ChartType.TEXT].title,
            description: 'Display rich text content on your collection.',
            onClick: () => createNew(ChartType.TEXT),
            hide: onlyStatic,
        },
        {
            Icon: widgetDictionary[ChartType.FORM].Icon,
            name: widgetDictionary[ChartType.FORM].title,
            description: 'Offer users to input data and offers the interaction capability with the app.',
            onClick: () => createNew(ChartType.FORM),
            hide: !isPowerUser || onlyStatic,
        },
        {
            Icon: widgetDictionary[ChartType.TREE].Icon,
            name: widgetDictionary[ChartType.TREE].title,
            description: 'Display information representing the hierarchical network.',
            onClick: () => createNew(ChartType.TREE),
            hide: !isPowerUser || onlyStatic,
        },
    ]

    if (dashboard?.homePage) {
        list.push({
            Icon: widgetDictionary[ChartType.CALCULATOR].Icon,
            name: widgetDictionary[ChartType.CALCULATOR].title,
            description: 'Display Calculator.',
            onClick: () => createNew(ChartType.CALCULATOR),
            hide: false,
        })

        list.push({
            Icon: widgetDictionary[ChartType.SPACIAL_MAP].Icon,
            name: widgetDictionary[ChartType.SPACIAL_MAP].title,
            description: 'Display Spacial Map.',
            onClick: () => createNew(ChartType.SPACIAL_MAP),
            hide: false,
        })
        list.push({
            Icon: widgetDictionary[ChartType.MODULE_SELECTOR].Icon,
            name: widgetDictionary[ChartType.MODULE_SELECTOR].title,
            description: 'Display Module Selector.',
            onClick: () => createNew(ChartType.MODULE_SELECTOR),
            hide: false,
        })
        list.push({
            Icon: widgetDictionary[ChartType.SEARCH].Icon,
            name: widgetDictionary[ChartType.SEARCH].title,
            description: 'Display Search Widget.',
            onClick: () => createNew(ChartType.SEARCH),
            hide: false,
        })
        list.push({
            Icon: widgetDictionary[ChartType.FAVORITES].Icon,
            name: widgetDictionary[ChartType.FAVORITES].title,
            description: 'Display Favorites at home page',
            onClick: () => createNew(ChartType.FAVORITES),
            hide: false,
        })
        list.push({
            Icon: widgetDictionary[ChartType.RECOMMENDED_WIDGET].Icon,
            name: widgetDictionary[ChartType.RECOMMENDED_WIDGET].title,
            description: 'Display Recommended widgets',
            onClick: () => createNew(ChartType.RECOMMENDED_WIDGET),
            hide: false,
        })
    }

    return list
}
