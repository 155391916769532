import {
    DataGridPro,
    DataGridProProps,
    GridCellEditCommitParams,
    GridRowModel,
    useGridApiRef,
} from '@mui/x-data-grid-pro'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'

interface EditTableProps extends DataGridProProps {
    onEditComplete: (data: GridRowModel, field: string) => Promise<any>
    onReorder?: (order: any) => void
}

export function EditTable(props: EditTableProps) {
    const { onEditComplete, onReorder, sx, ...rest } = props
    const apiRef = useGridApiRef()
    const { enqueueSnackbar } = useSnackbar()
    const errSnack = (message: string) => enqueueSnackbar(message, { variant: 'error' })

    useEffect(() => {
        if (apiRef.current) {
            return apiRef.current.subscribeEvent('columnHeaderDragEnd', () => {
                const columns = apiRef.current.getAllColumns().filter(column => column.field !== '__check__')
                onReorder(columns)
            })
        }
    }, [apiRef, onReorder])

    const handleCommit = ({ field, id }: GridCellEditCommitParams) => {
        setTimeout(async () => {
            const row = apiRef.current.getRow(id)
            const oldRow = rest.rows.find(r => r.id === id)
            if (oldRow) {
                const newVal = row[field]
                const oldVal = oldRow[field]
                if (newVal === oldVal || (newVal !== 0 && !newVal && !oldVal)) return
            }

            try {
                await onEditComplete(row, field)
            } catch (err) {
                console.error(err)
                errSnack(typeof err === 'string' ? err : 'An error occurred saving the cell')
            }
        }, 500)
    }

    return (
        <DataGridPro
            apiRef={apiRef}
            columnBuffer={5}
            onCellEditCommit={handleCommit}
            hideFooter={true}
            sx={{ ...sx, '& .MuiCheckbox-root': { color: 'text.primary' } }}
            {...rest}
        />
    )
}
