import { ConditionalFormat, Rule } from 'genesis-suite/types/visualTypes'

export function getConditionalFormat(data: Array<number | string>, rules: Rule[]) {
    if (!rules?.length) return

    for (const rule of rules) {
        const format = getFormat(data, rule)
        if (format) return format
    }
}

export interface conditionalFormatsDynamicTable {
    data: { [key: string]: any }
    titles?: { [key: string]: any }
}

export function getConditionalFormatDynamicTable(
    input: conditionalFormatsDynamicTable,
    value: any,
    rules: ConditionalFormat[]
) {
    if (!rules?.length) return
    if (!input?.data) return

    for (const rule of rules) {
        const targetValue =
            rule.target ||
            input.data[input.titles[rule.targetSource?.field.name]] ||
            input.data[rule.targetSource?.field.name]
        if (compareFormat(rule.rule, value, targetValue)) {
            return rule.format
        }
    }
}

function getFormat(data: Array<number | string>, { rule, target, format, sourceDataIndex, targetDataIndex }: Rule) {
    if (!data || sourceDataIndex == null) return

    const value = data[sourceDataIndex]
    const targetValue = targetDataIndex == null ? target : data[targetDataIndex]
    if (compareFormat(rule, value, targetValue)) return format
}

function compareFormat(rule, value, targetValue) {
    let compare

    switch (rule) {
        case '>':
            compare = (a, b) => a > b
            break
        case '<':
            compare = (a, b) => a < b
            break
        case '=':
            compare = (a, b) => a === b
            break
        case '<>':
            compare = (a, b) => a !== b
            break
        case '<=':
            compare = (a, b) => a <= b
            break
        case '>=':
            compare = (a, b) => a >= b
            break
        default:
            compare = () => false
    }

    return compare(parseFloat(value), parseFloat(targetValue))
}
