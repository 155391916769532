import { DataResponse, DataIndexes, DataGroup } from 'genesis-suite/types/visualTypes'

export default function getDataGroup(res: DataResponse, dataIndexes: DataIndexes): DataGroup {
    const series = dataIndexes?.series || 0

    if (!dataIndexes?.groupNames) return res[series]?.data[0]

    const groupNames = [...(dataIndexes.groupNames || [])]

    const firstCategory = groupNames.shift()
    let dataPoint = res[series]?.data.find(p => p.group === firstCategory)
    if (!groupNames?.length || !dataPoint) return dataPoint

    for (const group of groupNames) {
        dataPoint = dataPoint.children?.find(c => c.group === group)
        if (!dataPoint) return
    }

    return dataPoint
}
