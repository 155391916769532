import SizingIcon from '@mui/icons-material/Height'
import { Box, MenuItem, Typography } from '@mui/material'
import { useContext } from 'react'

import { DebouncedTextField, MenuIcon } from 'genesis-suite/components'
import { LayoutDetails } from 'genesis-suite/types/visualTypes'
import { DashboardContext } from '../DashboardContext'

const minTopHeight = (units: 'px' | '%') => (units === 'px' ? 50 : 7)
const maxTopHeight = (units: 'px' | '%') => (units === 'px' ? 800 : 60)
const minTopRowCount = 1
const maxTopRowCount = 20
const minRowHeight = 40
const maxRowHeight = 200
const minRowCount = 1
const maxRowCount = 30

export default function DashboardRowResizingAction() {
    const { config, devicePreview, layout, updateConfig, widgets } = useContext(DashboardContext)

    const topWidgets = widgets.filter(w => w.position?.top)
    const hasTop = topWidgets?.length > 0
    const { topHeightUnits, topHeight, topRowCount, rowCount, rowHeight } = layout

    function handleUpdate(update: Partial<LayoutDetails>) {
        const merged = { ...layout, ...update }

        updateConfig({
            layoutByDevice: {
                ...config.layoutByDevice,
                [devicePreview]: {
                    ...merged,
                    topHeight: updateWithinRange(
                        merged.topHeight,
                        minTopHeight(merged.topHeightUnits),
                        maxTopHeight(merged.topHeightUnits)
                    ),
                    topRowCount: updateWithinRange(merged.topRowCount, minTopRowCount, maxTopRowCount),
                    rowHeight: updateWithinRange(merged.rowHeight, minRowHeight, maxRowHeight),
                    rowCount: updateWithinRange(merged.rowCount, minRowCount, maxRowCount),
                },
            },
        })
    }

    return (
        <MenuIcon
            buttonProps={{ sx: { color: 'inherit' } }}
            icon={<SizingIcon />}
            title="Content positioning"
            tooltip="Adjust content positioning"
        >
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
                Top panel
            </Typography>
            <Box sx={{ alignItems: 'center', display: 'grid', gap: 1, gridTemplateColumns: '70px auto' }}>
                <Typography>Total height:</Typography>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <Field
                        disabled={!hasTop}
                        inputProps={{ min: minTopHeight(topHeightUnits), max: maxTopHeight(topHeightUnits) }}
                        onChange={val => handleUpdate({ topHeight: Number(val) })}
                        sx={{ width: '75px' }}
                        type="number"
                        value={topHeight}
                    />
                    <Field
                        disabled={!hasTop}
                        onChange={(val: 'px' | '%') =>
                            handleUpdate({ topHeightUnits: val, topHeight: val === 'px' ? 90 : 20 })
                        }
                        select
                        sx={{ width: '50px' }}
                        value={topHeightUnits}
                    >
                        <MenuItem value="px">px</MenuItem>
                        <MenuItem value="%">%</MenuItem>
                    </Field>
                </Box>

                <Typography>Row count:</Typography>
                <Field
                    disabled={!hasTop}
                    inputProps={{ min: minTopRowCount, max: maxTopRowCount }}
                    onChange={val => handleUpdate({ topRowCount: Number(val) })}
                    sx={{ width: '75px' }}
                    type="number"
                    value={topRowCount}
                />
            </Box>

            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
                Main panel
            </Typography>
            <Box sx={{ alignItems: 'center', display: 'grid', gap: 1, gridTemplateColumns: '70px auto' }}>
                <Typography>Method:</Typography>
                <Field
                    onChange={val => handleUpdate({ rowHeight: val === 'fixed' ? 40 : undefined })}
                    select
                    value={rowCount ? 'fixed' : 'flex'}
                >
                    <MenuItem value="fixed">Fixed</MenuItem>
                    <MenuItem value="flex">Flexible</MenuItem>
                </Field>

                {rowHeight ? (
                    <>
                        <Typography>Row height</Typography>
                        <Field
                            inputProps={{ min: minRowHeight, max: maxRowHeight }}
                            onChange={val => handleUpdate({ rowHeight: Number(val) })}
                            type="number"
                            InputProps={{ endAdornment: 'px' }}
                            value={rowHeight}
                        />
                    </>
                ) : (
                    <>
                        <Typography>Row count</Typography>
                        <Field
                            inputProps={{ min: minRowCount, max: maxRowCount }}
                            onChange={val => handleUpdate({ rowCount: Number(val) })}
                            type="number"
                            value={rowCount}
                        />
                    </>
                )}
            </Box>
        </MenuIcon>
    )
}

function Field(props) {
    return <DebouncedTextField size="small" variant="outlined" {...props} />
}

function updateWithinRange(value: number, min: number, max: number) {
    if (value == null) return
    return Math.min(max, Math.max(min, value))
}
