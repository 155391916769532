import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'

import '../styles/FullscreenWidget.css'
import FullScreenContainer from '../containers/FullScreenContainer'
import { widgetSelectors } from '../selectors'
import NavTitle from '../containers/NavTitle'

export default function FullscreenWidgetView() {
    const { perspectiveID } = useParams()
    const [{ start }] = useSearchParams()
    const location = useLocation()
    const networkContext = location.state.context

    const configs = useSelector(s => widgetSelectors.getWidgetConfigsForPerspective(s, perspectiveID))

    const [inputValue, setInputValue] = useState(() => initInputValue(configs, start))

    useEffect(() => {
        document.documentElement.style.setProperty('--thumb-width', `calc(100vw/${configs.length})`)
    }, [])

    return (
        <div className="fullscreen-widget-view">
            <div className="fullscreen-widget-title-div">
                <NavTitle />
            </div>
            <FullScreenContainer widgets={configs} networkContext={networkContext} goTo={Math.round(inputValue)} />
            {configs.length > 1 && (
                <input
                    onChange={e => setInputValue(e.target.value)}
                    onTouchEnd={e => setInputValue(Math.round(e.target.value))}
                    onMouseUp={e => setInputValue(Math.round(e.target.value))}
                    value={inputValue}
                    type="range"
                    min={0}
                    max={configs.length - 1}
                    step={0.0001}
                />
            )}
        </div>
    )
}

function initInputValue(configs, start) {
    if (!start) return 0

    const index = configs.findIndex(config => config.Id === start)
    return Math.max(index, 0)
}
