import { Box, Tooltip, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useContext } from 'react'

import { Category } from 'genesis-suite/types/visualTypes'
import { ConfigContext } from '../ConfigContext'
import NavigationEditor from './NavigationEditor'
import SortEditor from './SortEditor'
import TrellisEditor from './TrellisEditor'
import HelpIcon from '@mui/icons-material/HelpOutline'

const useStyles = makeStyles(({ spacing }) => ({
    group: { marginBottom: spacing() },
}))

export default function CategoryEditor() {
    const { config, selectedField, dispatch } = useContext(ConfigContext)
    const { categories } = config

    const classes = useStyles()

    if (!categories || categories.length === 0 || selectedField.type !== 'category') return null

    const category = categories[selectedField.index] || categories[0]
    const { field, navigation } = category

    const handleUpdate = (change: Partial<Category>) => {
        const category = categories[selectedField.index]
        const newCats = Array.from(categories)
        newCats.splice(selectedField.index, 1, { ...category, ...change })
        dispatch({ type: 'UPDATE_CATEGORIES', payload: newCats })
    }

    return (
        <div>
            <div className={classes.group}>
                <Typography variant="caption">Navigation</Typography>
                <NavigationEditor
                    focalPoint={field.resourceName}
                    navigation={navigation}
                    onUpdate={navigation => handleUpdate({ navigation })}
                />
            </div>
            <div className={classes.group}>
                <Box display="flex" alignItems="center" gap="8px" marginBottom="2px">
                    <Typography variant="caption">Sorting</Typography>
                    <Tooltip
                        title="In a Dynamic Table you can click on the column header to sort the results. You can also sort by multiple columns by holding down the shift key while clicking on the column
                        header."
                    >
                        <HelpIcon fontSize="small" />
                    </Tooltip>
                </Box>
                <SortEditor pointer={selectedField} />
            </div>
            <TrellisEditor config={config} pointer={selectedField} />
        </div>
    )
}
