import { Button, ButtonProps, Popover, Tooltip, Typography } from '@mui/material'
import { useContext, useRef, useState } from 'react'

import AggregateMenu from 'genesis-suite/components/aggregation_selector/AggregateMenu'
import { aggregationDetailDictionary } from 'genesis-suite/components/aggregation_selector/aggregationOptions'
import { SeriesConfig } from 'genesis-suite/types/visualTypes'
import { ConfigContext } from '../edit_widget'

export default function AggregationSelect(props: ButtonProps) {
    const { dispatch, selectedField, ...rest } = useContext(ConfigContext)
    const [open, setOpen] = useState(false)
    const anchorEl = useRef()

    const config = rest.config as SeriesConfig
    const seriesIndex = selectedField?.index ?? 0
    const valueIndex = (selectedField?.type === 'series' && selectedField.valueIndex) || 0
    const seriesValues = config.series?.[seriesIndex]?.values
    const source = seriesValues?.[valueIndex]
    if (!source) return null

    const value = source.aggregation
    const { label, Icon } = aggregationDetailDictionary[value]

    function handleChange(e, aggregation) {
        const values = seriesValues.map((v, i) => (i === valueIndex ? { ...source, aggregation } : v))
        dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: { values } })
        setOpen(false)
    }

    return (
        <>
            <Tooltip title="Change aggregation">
                <Button ref={anchorEl} variant="outlined" onClick={() => setOpen(true)} sx={{ mb: 1 }} {...props}>
                    <Typography variant="caption">Aggregation: </Typography>
                    <Icon fontSize="small" />
                    <Typography variant="subtitle2">{label}</Typography>
                </Button>
            </Tooltip>

            <Popover
                anchorEl={anchorEl.current}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={open}
                onClose={() => setOpen(false)}
            >
                <AggregateMenu value={value} onChange={handleChange} />
            </Popover>
        </>
    )
}
