import { useTheme } from '@mui/material'
import { useSelector } from 'react-redux'

import { moduleSelectors } from '../../../selectors'
import BrickGrid from '../../BrickGrid'

import { filterVisualObjectsByViewMode } from '../../../lib/manageUtils'

export default function ModuleSelector({ onSelect, ...props }) {
    const allModules = filterVisualObjectsByViewMode(useSelector(moduleSelectors.getActiveModules))
    let modules =
        props.module !== 'AllModules' ? allModules.filter(m => m.modules?.find(n => n.Id === props.module)) : allModules
    const purple = useTheme().palette.tada.purple

    const items = modules
        .map(m => ({
            id: m.id,
            title: m.title,
            backgroundColor: m.theme?.custom?.accent1.main ?? purple,
        }))
        .sort((a, b) => (a.title || '').localeCompare(b.title))

    function handleSelect(id) {
        const module = modules.find(m => m.id === id)
        if (module) onSelect(module.name)
    }

    return <BrickGrid items={items} {...props} onSelect={handleSelect} />
}
