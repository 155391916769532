import React, { useRef } from 'react'
import { Skeleton } from '@mui/material';

const ParagraphSkeleton = ({ lineCount }) => {

    function getRandomIntInclusive(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min; //The maximum is inclusive and the minimum is inclusive 
    }

    const totalLines = useRef(lineCount || getRandomIntInclusive(2, 6))
    const lineLengths = useRef(Array(totalLines.current).fill().map(_ => getRandomIntInclusive(30, 100)))

    return (
        <div>
            {lineLengths.current.map((length, i) => (
                <Skeleton key={i} variant="text" width={`${length}%`} />
            ))}
        </div>
    )
}

export default ParagraphSkeleton