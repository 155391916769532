import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { LinearProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { DataGridPro, GridOverlay } from '@mui/x-data-grid-pro'

import { Publish } from 'genesis-suite/types/visualTypes'
import { SortBy } from 'genesis-suite/types/utilTypes'
import { createdBySortKey, makeColumns, makeFilterOptions } from './utils'
import { PublishContext } from '../contexts/PublishContext'
import { applicationSelectors, deploymentSelectors } from '../../selectors'
import { visualService } from '../../lib/services'
import { getComparator, sortData } from '../../lib/manageUtils'

interface PublishData {
    results: Publish[]
    totalRecords: number
    nextPage: number
}

export default function PublishTable({ onDone }) {
    const { publish, setPublish } = useContext(PublishContext)
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const viewFlag = useSelector(deploymentSelectors.getDeploymentViewFlag)

    const [data, setData] = useState<PublishData>(initData)
    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState<{ createdBy: string }>({ createdBy: undefined })
    const [sortBy, setSortBy] = useState<SortBy<Publish>>({ createdAt: 'desc' })

    const { results, nextPage, totalRecords } = data
    const haveAllRecords = totalRecords && results.length >= totalRecords

    useEffect(() => {
        getPublished()
        return () => cancelCall.current?.()
    }, [filter, sortBy])

    /** If getting records, function to cancel the request */
    const cancelCall = useRef(null)

    const paginateRecords = useCallback(() => {
        if (haveAllRecords || cancelCall.current || nextPage === 1) return
        getPublished(nextPage)
    }, [haveAllRecords, cancelCall.current, nextPage, appName, viewFlag, filter, sortBy])

    /** Fetch paginated dashboards. If page == 1, replace data results, else append to end */
    function getPublished(page = 1) {
        setLoading(true)

        const options = makeFilterOptions(filter, sortBy, page)
        visualService
            .getPublished(appName, options, viewFlag, cancel => (cancelCall.current = cancel))
            .then(({ data, totalRecords }) => {
                setData(s => ({
                    results: page === 1 ? data : [...s.results, ...data],
                    nextPage: page === 1 ? 2 : s.nextPage + 1,
                    totalRecords,
                }))

                cancelCall.current = null
            })
            .finally(() => setLoading(false))
    }

    function handleSelect(id) {
        if (!id || id === publish.id) return

        const selected = results.find(p => p.id === id)
        setPublish(selected)
        onDone()
    }

    const updateFilter = updated => setFilter(s => ({ ...s, ...updated }))
    const columns = makeColumns(filter, updateFilter)
    const sorted = sortData(results, getComparator(sortBy))

    return (
        <Table
            {...{
                columns,
                data: sorted,
                filter,
                loading,
                onFilter: updateFilter,
                onScrollEnd: paginateRecords,
                onSelect: handleSelect,
                onSort: setSortBy,
                selectedId: publish.id,
            }}
        />
    )
}

const initData = {
    results: [],
    totalRecords: null,
    nextPage: 1,
}

const ROW_HEIGHT = 40

const useStyles = makeStyles({
    tableWrapper: { flex: 1 },
    cover: { display: 'flex', flexDirection: 'column' },
    cell: { '&:focus': { outline: 'none !important' } },
    row: { cursor: 'pointer' },
})

interface TableProps {
    data: any
    filter: { createdBy: string }
    loading: boolean
    onFilter: (update: { createdBy: string }) => void
    onScrollEnd: () => void
    onSelect: (id: string) => void
    onSort: Dispatch<SetStateAction<SortBy<Publish>>>
    selectedId: string
}

function Table({ data, filter, loading, onFilter, onScrollEnd, onSelect, onSort, selectedId }: TableProps) {
    const classes = useStyles()

    const columns = makeColumns(filter, onFilter)

    function handleSort(model) {
        if (!model.length) return onSort({})

        const { field, sort } = model[0]
        const fieldName = field === 'createdBy' ? createdBySortKey : field
        onSort({ [fieldName]: sort })
    }

    return (
        <DataGridPro
            classes={{ cell: classes.cell, columnHeader: classes.cell, row: classes.row }}
            columns={columns}
            components={{ LoadingOverlay }}
            disableColumnFilter
            disableColumnReorder
            disableColumnSelector
            hideFooter
            loading={loading}
            onRowsScrollEnd={onScrollEnd}
            onSortModelChange={handleSort}
            pinnedColumns={{ right: ['actions'] }}
            rowHeight={ROW_HEIGHT}
            rows={data}
            rowThreshold={3}
            scrollEndThreshold={300}
            onSelectionModelChange={([id]) => onSelect(id as string)}
            selectionModel={selectedId ? [selectedId] : []}
            sortingMode="server"
        />
    )
}

function LoadingOverlay() {
    return (
        <GridOverlay>
            <div style={{ position: 'absolute', top: 0, width: '100%', zIndex: 1 }}>
                <LinearProgress />
            </div>
        </GridOverlay>
    )
}
