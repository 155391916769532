import {
    BaseDataRequest,
    ComparisonOperator,
    DataRequest,
    NodeFilterWithValue,
    Pagination,
} from 'genesis-suite/types/visualTypes'

/** take baseDataRequest built from config and add dynamic props - filtering, sorting, pagination, ... */
export default function makeDataRequest(
    baseDataRequest: BaseDataRequest,
    /** (optional) only needed if inlineFilters provided */
    id?: string,
    networkFilters?: Array<NodeFilterWithValue>,
    page?: Pagination
): DataRequest {
    if (!baseDataRequest) return

    const series = baseDataRequest.series.map(s => ({ ...s, ...(page && { page }) }))
    const correctedFilters = networkFilters?.filter(f => f.source !== 'inline' || f.widgetId !== id)
    return { ...baseDataRequest, series, context: makeContext(correctedFilters, baseDataRequest.application) }
}

function makeContext(filters: Array<NodeFilterWithValue>, appName: string) {
    if (!filters?.length) return

    const firstNavFilter = filters.find(f => f.source === 'navigation')

    return {
        CloudName: appName,
        ModelName: appName,
        Type: 'Node',
        Name: firstNavFilter?.nodeName,
        FieldName: firstNavFilter?.keyPropertyName,
        Value: firstNavFilter?.values?.[0]?.value,
        Filters: filters?.map(f => {
            const base = {
                ResourceName: f.nodeName,
                ResourceType: 'Concept',
                PropertyName: f.keyPropertyName,
            }

            if (f.source === 'dashboard') {
                return {
                    ...base,
                    IsTemporal: f.isTemporal,
                    UseLastRefreshDate: f.useLastRefreshDate,
                    Operator: makeOperator(f.operator),
                    ...(f.type === 'values'
                        ? { Values: f.values?.map(v => v.value) }
                        : f.type === 'clickRangeName'
                        ? { Range: f.range }
                        : { ClickRangeName: f.clickRangeName }),
                }
            } else {
                return {
                    ...base,
                    Operator: makeOperator(ComparisonOperator.EQUAL),
                    Values: f.values?.map(v => v.value),
                }
            }
        }),
    }
}

function makeOperator(o: ComparisonOperator) {
    switch (o) {
        case ComparisonOperator.CONTAINS:
            return 'Contains'
        case ComparisonOperator.ENDS_WITH:
            return 'EndsWith'
        case ComparisonOperator.EQUAL:
            return 'EqualTo'
        case ComparisonOperator.GREATER_THAN:
            return 'GreaterThan'
        case ComparisonOperator.GREATER_THAN_OR_EQUAL:
            return 'GreaterThanorEqualTo'
        case ComparisonOperator.LESS_THAN:
            return 'LessThan'
        case ComparisonOperator.LESS_THAN_OR_EQUAL:
            return 'LessThanorEqualTo'
        case ComparisonOperator.NOT_CONTAINS:
            return 'NotContains'
        case ComparisonOperator.NOT_EQUAL:
            return 'NotEqualTo'
        case ComparisonOperator.STARTS_WITH:
            return 'StartsWith'
    }
}
