import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'
import { useSnackbar } from 'notistack'
import { connect } from 'react-redux'
import { filterCreators } from '../actions/creators'
import FiltersSelector from '../components/FiltersSelector'
import { logEvent } from '../lib/amplitudeClient'
import { filterSelectors, moduleSelectors, widgetSelectors } from '../selectors'

const PerspectiveFilters = props => {
    const { moduleId, perspective, saveFiltersForAll, ...rest } = props
    const { enqueueSnackbar: showSnackbar, closeSnackbar } = useSnackbar()

    const defaultSnackbarConfig = {
        autoHideDuration: 5000,
        action: (
            <IconButton
                onClick={() => {
                    closeSnackbar()
                }}
                size="large"
            >
                <CloseIcon fontSize="small" htmlColor="#ffffff" />
            </IconButton>
        ),
    }

    let handleSaveFiltersForAll
    const { IsGlobal, ParentVisorId } = perspective || {}
    if (!IsGlobal || (IsGlobal && ParentVisorId === moduleId)) {
        handleSaveFiltersForAll = filters => {
            saveFiltersForAll(filters)
                .then(() => {
                    showSnackbar('New defaults saved successfully', { ...defaultSnackbarConfig, variant: 'success' })
                })
                .catch(() => {
                    showSnackbar('An error occured while saving default filters', {
                        ...defaultSnackbarConfig,
                        variant: 'error',
                    })
                })
        }
    }
    return <FiltersSelector saveFiltersForAll={handleSaveFiltersForAll} {...rest} />
}

const mapStateToProps = state => {
    return {
        filters: filterSelectors.getPerspectiveFiltersConfig(state),
        moduleId: moduleSelectors.getModuleId(state),
        perspective: widgetSelectors.getCurrentPerspectiveConfig(state),
        selectedValues: filterSelectors.getAppliedPerspectiveFilters(state),
        source: filterSelectors.getPerspectiveFiltersSource(state),
    }
}

const mapDispatchToProps = dispatch => ({
    applyFilters: filters => {
        dispatch(filterCreators.applyPerspectiveFilters(filters))
    },
    saveFilters: filters => {
        logEvent('PERSPECTIVE_FILTERS_SAVE_AS_DEFAULT')
        return dispatch(filterCreators.saveDefaultPerspectiveFilters(filters, false))
    },
    saveFiltersForAll: filters => {
        logEvent('PERSPECTIVE_FILTERS_SAVE_AS_DEFAULT_ALL')
        return dispatch(filterCreators.saveDefaultPerspectiveFilters(filters, true))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(PerspectiveFilters)
