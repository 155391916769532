import { persistStore } from 'redux-persist'
import store from './store'
import { applicationCreators } from '../actions/creators'
import { applicationSelectors } from '../selectors'

const persistor = persistStore(store, {}, () => {
    const state = store.getState()
    const currentApplication = applicationSelectors.getCurrentAppName(state)

    store.dispatch(applicationCreators.getApplicationInfo(currentApplication))
})

export default persistor
