import { combineReducers } from 'redux'
import { collaborationTypes } from '../actions/types'

const initialState = {
    id: '',
    connectionPending: false,
    connectionError: null,
}

const hub = (state = initialState, action) => {
    const { payload } = action

    switch (action.type) {
        case collaborationTypes.COLLABORATION_CONNECTION_REQUEST:
            return { ...state, connectionPending: true }
        case collaborationTypes.COLLABORATION_CONNECTION_SUCCESS:
            return { ...state, connectionPending: false, id: payload.hubID }
        case collaborationTypes.COLLABORATION_CONNECTION_FAILURE:
            return { connectionPending: false, connectionError: payload.error }
        case collaborationTypes.COLLABORATION_CONNECTION_STOP:
            return initialState
        case collaborationTypes.COLLABORATION_REGISTERED:
            return { ...state, sessionName: payload.sessionName }
        case collaborationTypes.COLLABORATION_UNREGISTERED:
        default:
            return state
    }
}

const collaborators = (state = [], action) => {
    const { payload } = action

    switch (action.type) {
        case collaborationTypes.COLLABORATION_COLLABORATORS:
            return Object.keys(payload.clients).map(key => {
                return { id: key, name: payload.clients[key] }
            })
        case collaborationTypes.COLLABORATION_CONNECTION_STOP:
            return []
        default:
            return state
    }
}

export default combineReducers({ hub, collaborators })
