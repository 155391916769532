// @ts-nocheck
import { scenarioTypes } from '../actions/types'
import { ScenarioStore } from '~/store/storeTypes'

const initialState: ScenarioStore = {
    scenario: [],
    userScenarios: [],
    createdScenarios: [],
    assignedScenarios: [],
}

export default (state = initialState, action): ScenarioStore => {
    const { type, payload } = action
    switch (type) {
        case scenarioTypes.SET_ACTIVE_SCENARIOS: {
            return { ...state, scenario: payload }
        }
        case scenarioTypes.SET_USER_SCENARIOS: {
            return {
                ...state,
                createdScenarios: payload.createdScenarios,
                assignedScenarios: payload.assignedScenarios,
            }
        }
        case scenarioTypes.REMOVE_SCENARIO: {
            const filtered = state.scenario.filter(scenario => scenario.id !== payload)
            return { ...state, scenario: filtered }
        }
        default:
            return state
    }
}
