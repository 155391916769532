export const appearanceTypes = {
    OPEN_RIGHT_NAV: 'OPEN_RIGHT_NAV',
    CLOSE_RIGHT_NAV: 'CLOSE_RIGHT_NAV',
    SET_LAYOUT: 'SET_LAYOUT',
    SET_FONT_SIZE: 'SET_FONT_SIZE',
    SET_TOP_NAV: 'SET_TOP_NAV',
    TOGGLE_LOADER: 'TOGGLE_LOADER',
    TOGGLE_SETTINGS: 'TOGGLE_SETTINGS',
    TOOGLE_COOKIE_CONSENT: 'TOOGLE_COOKIE_CONSENT',
    TOGGLE_SIDE_NAV: 'TOGGLE_SIDE_NAV',
    TOGGLE_COMPARE_PERSPECTIVE_FILTER_PANEL: 'TOGGLE_COMPARE_PERSPECTIVE_FILTER_PANEL',
    TOGGLE_PERSPECTIVE_FILTER_POPOVER: 'TOGGLE_PERSPECTIVE_FILTER_POPOVER',
    TOGGLE_PERSPECTIVE_COMPARE_FILTER_POPOVER: 'TOGGLE_PERSPECTIVE_COMPARE_FILTER_POPOVER',
    TOGGLE_GLOBAL_FILTERS: 'TOGGLE_GLOBAL_FILTERS',
    TOGGLE_HELP: 'TOGGLE_HELP',
    CLOSE_SIDE_NAV: 'CLOSE_SIDE_NAV',
    TOGGLE_APP_CONTENT: 'TOGGLE_APP_CONTENT',
    SET_SHOW_NEO_CLASSIC_LAYOUT: 'SET_SHOW_NEO_CLASSIC_LAYOUT',
    TOGGLE_NEO_CLASSIC_GLOBAL_FILTERS: 'TOGGLE_NEO_CLASSIC_GLOBAL_FILTERS',
    SET_SHOW_APPLICATION_CONTROLLER: 'SET_SHOW_APPLICATION_CONTROLLER',
    TOGGLE_BETA: 'TOGGLE_BETA',
}
