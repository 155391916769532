import { DraftKey } from 'genesis-suite/types/visualTypes'
import DashboardDisplay from './DashboardDisplay'
import useDashboard from './useDashboard'

interface Props {
    hideHeader?: boolean
    id: string
    /** (optional) Override current view */
    view?: DraftKey
}

export default function Dashboard({ id, view, ...rest }: Props) {
    const { data, mutate } = useDashboard(id, view)

    return <DashboardDisplay {...rest} config={data?.dashboard} widgets={data?.widgets} getWidgets={() => mutate()} />
}
