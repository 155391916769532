import { useContext } from 'react'
import { Checkbox, FormControlLabel } from '@mui/material'

import { BaseSeries, ChartType, DataLabels, SeriesConfig, PieSeries } from 'genesis-suite/types/visualTypes'
import DisplayFormatFields from '../DisplayFormatFields'
import BorderedSection from '../../../BorderedSection'
import { ConfigContext } from '../../ConfigContext'

export default function SeriesDataLabelsEditor() {
    const { selectedField, dispatch, ...rest } = useContext(ConfigContext)
    const config = rest.config as SeriesConfig

    const activeSeries = config.series[selectedField?.index ?? 0] as BaseSeries
    const { value, format, percent, categories, total } = activeSeries?.dataLabels ?? {}
    const pieSeries = config.type === ChartType.PIE ? (config.series[selectedField?.index ?? 0] as PieSeries) : null
    function handleChange(change: Partial<DataLabels>) {
        dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: { dataLabels: { ...activeSeries.dataLabels, ...change } } })
    }

    return (
        <div>
            <CheckBox
                label="Value"
                checked={value ?? false}
                onChange={e => handleChange({ value: e.target.checked })}
            />

            {(config.type === ChartType.PIE || config.type === ChartType.SORTED) && (
                <>
                    <CheckBox
                        label="Percent"
                        checked={percent || false}
                        onChange={e => handleChange({ percent: e.target.checked })}
                    />
                    <CheckBox
                        label="Category"
                        checked={categories || false}
                        onChange={e => handleChange({ categories: e.target.checked })}
                    />
                    {pieSeries?.doughnut && (
                        <CheckBox
                            label="Total"
                            checked={total || false}
                            onChange={e => handleChange({ total: e.target.checked })}
                        />
                    )}
                </>
            )}

            <BorderedSection header="Value format" collapsable startCollapsed>
                <DisplayFormatFields format={format} onChange={format => handleChange({ format })} />
            </BorderedSection>
        </div>
    )
}

function CheckBox({ label, ...rest }) {
    return <FormControlLabel control={<Checkbox color="primary" {...rest} />} label={label} />
}
