import { useSnackbar } from 'notistack'
import { useHover } from 'genesis-suite/hooks'
import makeStyles from '@mui/styles/makeStyles'
import DoneIcon from '@mui/icons-material/Done'
import EditIcon from '@mui/icons-material/Edit'
import CancelIcon from '@mui/icons-material/Clear'
import { useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { IconButton, Dialog, TextField, Box } from '@mui/material'

import { widgetCreators } from '../../actions/creators'
import { moduleSelectors, widgetSelectors } from '../../selectors'

const useStyles = makeStyles(({ palette, spacing }) => ({
    cover: {
        zIndex: 2,
        position: 'absolute',
        height: '100%',
        width: '100%',
        borderRadius: '15px',
        borderBottomRightRadius: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    actions: {
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText,
        position: 'absolute',
        top: spacing(),
        right: spacing(),
        borderRadius: '8px',
    },
    input: { width: '300px' },
    doneButton: { margin: spacing(0, 1) },
    doneButtonIconStyle: { color: palette.text.primary },
    cancelButtonIconStyle: { color: palette.text.primary },
    icon: { color: palette.primary.contrastText },
}))

export default function WidgetEditCover({ config, isDraggable }) {
    const classes = useStyles()
    const [title, setTitle] = useState('')
    const ref = useRef()

    const isHovering = useHover(ref)
    const { enqueueSnackbar: showSnackbar } = useSnackbar()

    const moduleId = useSelector(moduleSelectors.getModuleId)
    const dashboard = useSelector(widgetSelectors.getCurrentPerspectiveConfig)
    const draftTitle = useSelector(widgetSelectors.getDraftTitles)[config.Id]
    const dispatch = useDispatch()

    function handleOpen() {
        const { IsGlobal, ParentVisorId, ParentVisorName } = config
        if (IsGlobal && ParentVisorId !== moduleId) {
            showSnackbar(`Edit this global widget in ${ParentVisorName} module`, { variant: 'error' })
            return
        }

        setTitle(draftTitle || config.Title)
    }

    const handleCancel = e => {
        e.stopPropagation()
        e.preventDefault()
        setTitle('')
    }

    const handleSave = () => {
        dispatch(widgetCreators.changeTitle(config.Id, title))
        setTitle('')
    }

    return (
        <div
            ref={ref}
            data-cy="widget-edit-cover"
            className={classes.cover}
            style={{ cursor: isDraggable ? 'grab' : 'inherit' }}
        >
            {isHovering && (
                <div className={classes.actions}>
                    <IconButton size="small" onClick={handleOpen} data-cy="edit-widget-btn">
                        <EditIcon className={classes.icon} />
                    </IconButton>
                </div>
            )}

            <Dialog open={Boolean(title)} onClose={handleSave}>
                <Box display="flex" alignItems="center" p={2}>
                    <TextField
                        data-cy="edit-widget-title-input"
                        className={classes.input}
                        autoFocus
                        fullWidth
                        value={title}
                        onFocus={e => e.target.select()}
                        onChange={e => setTitle(e.target.value)}
                        onKeyPress={e => e.key === 'Enter' && handleSave()}
                    />
                    <IconButton size="small" className={classes.doneButton} onClick={handleSave}>
                        <DoneIcon fontSize="small" className={classes.doneButtonIconStyle} />
                    </IconButton>
                    <IconButton size="small" onClick={handleCancel}>
                        <CancelIcon fontSize="small" className={classes.cancelButtonIconStyle} />
                    </IconButton>
                </Box>
            </Dialog>
        </div>
    )
}
