import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Tab } from '@mui/material'
import { MenuIcon } from 'genesis-suite/components'
import { useHotKey } from 'genesis-suite/hooks'
import { Filter } from 'genesis-suite/icons'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { appearanceCreators } from '../actions/creators'
import { logEvent } from '../lib/amplitudeClient'
import { HotKeys, createTip } from '../lib/hotkeys'
import { appearanceSelectors, filterSelectors, widgetSelectors } from '../selectors'
import PerspectiveFilters from './PerspectiveFilters'
import NetworkFilterTabContent from '~/components/network-filters/NetworkFilterTabContent'

export default function PerspectiveFilterController({ disabled, iconProps, buttonStyle, handleResetWidgets }) {
    const [tabVal, setTabVal] = useState('perspective')
    const handleChange = (event, newValue) => {
        setTabVal(newValue)
    }

    const perspectiveFilters = useSelector(filterSelectors.getPerspectiveFiltersConfig)
    const filterNames = perspectiveFilters.map(filter => filter.Name)
    const filterCount = useSelector(state => filterSelectors.getFilterSelectedValueCount(state, filterNames))
    const openFlag = useSelector(appearanceSelectors.getShowPerspectiveFilterPopover)
    const perspectiveId = useSelector(widgetSelectors.getCurrentPerspectiveId)

    const dispatch = useDispatch()
    const togglePopover = () => dispatch(appearanceCreators.togglePerspectiveFilterPopover())

    const togglePopoverWidget = () => {
        if (handleResetWidgets) handleResetWidgets()
        togglePopover()
    }

    function handleApply() {
        logEvent('PERSPECTIVE_FILTERS_APPLY', { method: 'popover' })
        togglePopoverWidget()
    }

    useHotKey(HotKeys.PerspectiveFilter, togglePopover)
    const keyTip = createTip(HotKeys.PerspectiveFilter)

    const _disabled = !perspectiveFilters.length || disabled
    let _iconProps = { ...iconProps }
    if (_disabled) _iconProps = { ..._iconProps, style: { ..._iconProps?.style, color: 'gray' } } // HACK - dark theme overrides disabled states

    const tabs = [
        {
            label: 'Perspective',
            value: 'perspective',
            content: (
                <PerspectiveFilters
                    onCancel={togglePopoverWidget}
                    onSave={togglePopoverWidget}
                    onApply={handleApply}
                    onSaveForAll={togglePopoverWidget}
                />
            ),
        },
        {
            label: 'Network',
            value: 'network',
            content: (
                <NetworkFilterTabContent
                    perspectiveId={perspectiveId}
                    isCompare={false}
                    onClick={togglePopoverWidget}
                    onApply={togglePopoverWidget}
                    onSave={togglePopoverWidget}
                    onSaveForAll={togglePopoverWidget}
                />
            ),
        },
    ]
    return (
        <MenuIcon
            disabled={_disabled}
            open={openFlag}
            onClick={togglePopover}
            onClose={togglePopover}
            icon={<Filter {..._iconProps} />}
            tooltip={`Perspective filters ${keyTip}`}
            badge={filterCount}
            buttonProps={{ sx: buttonStyle, 'data-cy': 'filter-perspective-btn' }}
            popoverProps={{ PaperProps: { sx: { width: 700 } } }}
        >
            <TabContext value={tabVal}>
                <TabList onChange={handleChange}>
                    {tabs.map(tab => (
                        <Tab key={tab.value} sx={{ color: 'text.primary' }} label={tab.label} value={tab.value} />
                    ))}
                </TabList>
                {tabs.map(tab => (
                    <TabPanel key={tab.value} value={tab.value} sx={{ p: 0 }}>
                        {tab.content}
                    </TabPanel>
                ))}
            </TabContext>
        </MenuIcon>
    )
}
