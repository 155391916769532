import { useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { appearanceConstants } from '~/constants'
import { Box, Tabs, Tab, Typography, useTheme } from '@mui/material'
import PerspectiveWidget from '~/components/widgets/PerspectiveWidget'
import { filterSelectors, widgetSelectors, scenarioSelectors, moduleSelectors } from '~/selectors'
import { MemoizedTabPerspective } from '../tabPerspective/TabPerspective'
import { PerspectiveProvider } from '~/components/contexts/PerspectiveContext'
import { parsePerspectiveConfig } from './utils'
import TabAccordionPerspective from '../tabPerspective/TabAccordionPerspective'
import FocalPointSelect from '~/components/widgets/FocalPointSelect'
import PerspectiveFilterController from '~/containers/PerspectiveFilterController'
import ComparePerspectiveToolbar from '~/components/ComparePerspectiveToolbar'
import PerspectiveCompareFilterController from '~/containers/PerspectiveCompareFilterController'
import SearchFilter from '../../containers/CompareSearchFilter'

const { ScrollBarWidth } = appearanceConstants
const useStyles = makeStyles(({ palette, spacing, border }) => ({
    root: {
        flex: 1,
        backgroundColor: palette.background.main,
        paddingLeft: ScrollBarWidth,
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'hidden',
        overflowY: 'scroll',
    },
}))

export default function ComparePerspectiveLayout({ config, id }) {
    const classes = useStyles()
    const widgetRef = useRef()
    const dispatch = useDispatch()

    const perspectiveConfig = parsePerspectiveConfig(config, id)
    const { perspectiveLayoutName, layoutWidget, tabs } = perspectiveConfig

    const widgetConfigs = useSelector(s => widgetSelectors.getWidgetConfig(s, layoutWidget))
    const filters = useSelector(filterSelectors.currentFiltersConfiguration)
    const networkContext = useSelector(filterSelectors.getCoord)
    const inlineFilters = useSelector(filterSelectors.getInlineFilters)
    const perspectives = useSelector(widgetSelectors.getAllPerspectives)
    const activeScenarios = useSelector(scenarioSelectors.getActiveScenarioIds)
    const showSearchFilter = useSelector(moduleSelectors.getEnableSearch)

    const [tabValue, setTabValue] = useState(0)

    const handleChange = (event, newValue) => {
        setTabValue(newValue)
    }

    const { palette } = useTheme()

    const defaultIconProps = { style: { color: palette.text.primary } }

    const baseGrid = {
        display: 'grid',
        gridTemplateColumns: 'repeat(2,1fr)',
        gap: 1,
        overflowX: 'hidden',
        overflowY: 'scroll',
    }

    return (
        <>
            <ComparePerspectiveToolbar perspectiveID={id} />
            <Box
                sx={{
                    backgroundColor: 'background.main',
                    paddingX: `${ScrollBarWidth}px`,
                    pt: 1,
                    width: '100%',
                    height: '100%',
                    overflowY: 'scroll',
                }}
            >
                <Box sx={baseGrid} style={{ paddingLeft: ScrollBarWidth }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
                        <Typography variant="h5">Original</Typography>
                        <FocalPointSelect textFieldProps={{ variant: 'standard' }} />
                        <PerspectiveFilterController iconProps={defaultIconProps} />
                        {showSearchFilter && <SearchFilter />}
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
                        <Typography variant="h5">Compare</Typography>
                        <FocalPointSelect source="compare" textFieldProps={{ variant: 'standard' }} />
                        <PerspectiveCompareFilterController iconProps={defaultIconProps} />
                        {showSearchFilter && <SearchFilter isCompare />}
                    </Box>
                </Box>
                {layoutWidget && (
                    <PerspectiveProvider id={layoutWidget} configs={[widgetConfigs]}>
                        <Box sx={{ ...baseGrid, height: '70%', flex: 1 }}>
                            <PerspectiveWidget
                                config={widgetConfigs}
                                perspectiveId={widgetConfigs.Id}
                                {...{ filters, networkContext, inlineFilters }}
                            />

                            <PerspectiveWidget
                                config={widgetConfigs}
                                perspectiveId={widgetConfigs.Id}
                                {...{ filters, networkContext, inlineFilters }}
                                activeScenarios={activeScenarios}
                                isCompare
                            />
                        </Box>
                    </PerspectiveProvider>
                )}
                <Box sx={{ overflow: 'auto' }}>
                    <Tabs
                        value={tabValue}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        {tabs.map((tab, key) => (
                            <Tab label={tab.name} key={key} sx={{ color: 'inherit', fontWeight: 600 }} />
                        ))}
                    </Tabs>
                </Box>
                {tabs.length > 0 ? (
                    tabs[tabValue]?.perspective?.length > 1 ? (
                        <Box sx={{ py: 2 }}>
                            {tabs[tabValue]?.perspective.map(item => (
                                <TabAccordionPerspective
                                    id={item.Id}
                                    perspectiveLayout={item.Position}
                                    isEdit={false}
                                    perspectives={perspectives}
                                    isCompare={true}
                                />
                            ))}
                        </Box>
                    ) : (
                        <Box sx={{ py: 2 }}>
                            <MemoizedTabPerspective
                                id={tabs[tabValue].perspective[0].Id}
                                perspectiveLayout={tabs[tabValue].perspective[0].Position}
                                isEdit={false}
                                isCompare={true}
                            />
                        </Box>
                    )
                ) : null}
            </Box>
        </>
    )
}
