import moment from 'moment'
import {
    BaseVisual,
    Dashboard,
    DraftKey,
    Module,
    User,
    VisualObject,
    VisualObjectByType,
    VisualType,
    Widget,
} from 'genesis-suite/types/visualTypes'
import { SortBy } from 'genesis-suite/types/utilTypes'
import { visualService } from './services'

export const authorKey = 'author'
export const createdBySortKey = 'createdBy.lastName'

export function filterVisualObjectsByViewMode<T extends VisualObject>(visualObjects: T[]): T[] {
    return visualObjects?.filter(target => {
        return !visualObjects.find(v => v?.draft?.visualId === target.id)
    })
}

export function getComparator<T>(sortBy: SortBy<T>) {
    const key = Object.keys(sortBy)?.[0]
    const fieldName = key === createdBySortKey ? authorKey : key

    const order = sortBy[fieldName]
    if (fieldName === 'modules')
        return (a, b) => {
            const aValue = (a[fieldName][0]?.Name ?? '').toLowerCase()
            const bValue = (b[fieldName][0]?.Name ?? '').toLowerCase()
            return aValue.localeCompare(bValue) * (order === 'asc' ? 1 : -1)
        }
    else
        return (a, b) => {
            const aValue = (a[fieldName] ?? '').toLowerCase()
            const bValue = (b[fieldName] ?? '').toLowerCase()
            return aValue.localeCompare(bValue) * (order === 'asc' ? 1 : -1)
        }
}

export function sortData(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })
    return stabilizedThis.map(el => el[0])
}

/** Given visual object, return created and updated strings */
export function getInfoPanelDateProps(data: BaseVisual) {
    if (!data) return

    const { createdAt, updatedAt, createdBy, updatedBy } = data
    const createdDate = moment(createdAt)
    const createdFormat = createdDate.isSame(moment(), 'day') ? 'h:mma' : 'MM/DD/YY h:mma'

    const updatedDate = moment(updatedAt)
    const updatedFormat = updatedDate.isSame(moment(), 'day') ? 'h:mma' : 'MM/DD/YY h:mma'

    return {
        created: `${createdDate.format(createdFormat)} by ${userDisplayName(createdBy)}`,
        updated: updatedBy && `${moment(updatedAt).format(updatedFormat)} by ${userDisplayName(updatedBy)}`,
    }
}

export async function updateDashboardOrCreateDraft(
    appName: string,
    id: string,
    body: Partial<Dashboard>,
    viewFlag?: DraftKey
) {
    try {
        const config = await visualService.getDashboardById(appName, id, false, viewFlag)
        const createDraft = viewFlag && config.active && !config.draft

        return createDraft
            ? await visualService.createDashboardDraft(appName, id, { ...config, ...body }, viewFlag)
            : await visualService.updateDashboard(appName, config.id, body)
    } catch (err) {
        console.error(err)
        throw err
    }
}

export async function updateModuleOrCreateDraft(
    appName: string,
    id: string,
    body: Partial<Module>,
    viewFlag?: DraftKey
) {
    try {
        const config = await visualService.getModuleById(appName, id, viewFlag)
        const createDraft = viewFlag && config.active && !config.draft

        return createDraft
            ? await visualService.createModuleDraft(appName, id, { ...config, ...body }, viewFlag)
            : await visualService.updateModule(appName, config.id, body)
    } catch (err) {
        console.error(err)
        throw err
    }
}

export async function updateWidgetOrCreateDraft(
    appName: string,
    id: string,
    body: Partial<Widget>,
    viewFlag?: DraftKey
) {
    try {
        const config = await visualService.getWidgetById(appName, id, viewFlag)
        const createDraft = viewFlag && config.active && !config.draft

        return createDraft
            ? //@ts-ignore
              await visualService.createWidgetDraft(appName, id, { ...config, ...body }, viewFlag)
            : await visualService.updateWidget(appName, config.id, body)
    } catch (err) {
        console.error(err)
        throw err
    }
}

export async function getVisualById<T extends VisualType>(
    appName: string,
    type: T,
    id: string,
    viewFlag?: DraftKey
): Promise<VisualObjectByType<T>> {
    switch (type) {
        case VisualType.DASHBOARD:
            //@ts-ignore
            return visualService.getDashboardById(appName, id, false, viewFlag)
        case VisualType.MODULE:
            //@ts-ignore
            return visualService.getModuleById(appName, id, viewFlag)
        case VisualType.WIDGET:
            //@ts-ignore
            return visualService.getWidgetById(appName, id, viewFlag)
    }
}

export const userDisplayName = (user: User) => `${user?.firstName} ${user?.lastName}`
