import { ArrowDownwardRounded, ArrowUpwardRounded } from '@mui/icons-material'
import {
    Box,
    Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useContext, useState } from 'react'

import { DebouncedTextField } from 'genesis-suite/components'
import HelpIcon from '@mui/icons-material/HelpOutline'
import { letMeMap } from 'genesis-suite/types/utilTypes'
import { SeriesConfig, SortProps, SortType, TableConfig } from 'genesis-suite/types/visualTypes'
import LabeledToggleButton from '../../LabeledToggleButton'
import { FieldPointer, SeriesFieldPointer } from '../builderTypes'
import { ConfigContext } from '../ConfigContext'
import { useTableStyleChanges } from '../../widgets2/contexts/TableStyleChangesContext'

interface Props {
    pointer: FieldPointer
    label?: string
    className?: string
}

export default function SortEditor({ pointer, label, className }: Props) {
    const { dispatch, selectedField, ...rest } = useContext(ConfigContext)
    const { setTableStyleChange } = useTableStyleChanges()
    const config = rest.config as SeriesConfig
    const isDynamicTable = (config as TableConfig).dynamic

    const [showHelp, setShowHelp] = useState(false)

    const { sort, sortType, dateFormat } = getSortDetails(config, pointer)

    function handleUpdate(sortProps: Partial<SortProps>) {
        if (isDynamicTable) setTableStyleChange(false)
        dispatch({ type: 'UPDATE_SORT', payload: { pointer, ...sortProps } })
    }

    return (
        <div className={className}>
            <Box display="flex" alignItems="center" gap={1}>
                <LabeledToggleButton
                    size="small"
                    value={sort}
                    label={label}
                    options={directionOptions}
                    onChange={(e, sort) => handleUpdate({ sort: sort || undefined })}
                />
                {pointer?.type !== 'series' && !isDynamicTable && (
                    <>
                        <Typography>as</Typography>
                        <TextField
                            inputProps={{ sx: { p: 1 } }}
                            margin="none"
                            onChange={e => handleUpdate({ sortType: e.target.value as SortType })}
                            select
                            sx={{ flex: 1 }}
                            value={sortType}
                            variant="outlined"
                        >
                            {typeOptions.map(({ value, label }) => (
                                <MenuItem key={value} value={value}>
                                    {label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </>
                )}
            </Box>

            <Collapse in={pointer?.type !== 'series' && sortType === 'date'}>
                <Box mt={1} ml={1}>
                    <Box display="flex" alignItems="center" gap="8px">
                        <Typography variant="caption">Date format</Typography>
                        <Tooltip title="Format of date. Click to see options">
                            <IconButton size="small" onClick={() => setShowHelp(true)}>
                                <HelpIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <DebouncedTextField
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ sx: { p: 1 } }}
                        margin="none"
                        onChange={dateFormat => handleUpdate({ dateFormat })}
                        placeholder="Format string"
                        variant="outlined"
                        value={dateFormat ?? ''}
                    />
                </Box>
            </Collapse>

            <Dialog open={showHelp} onClose={() => setShowHelp(false)}>
                <DialogTitle>Date sorting</DialogTitle>
                <DialogContent>
                    <SortHelp />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={() => setShowHelp(false)}>
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

function getSortDetails(config: SeriesConfig, pointer: FieldPointer): SortProps {
    if (!pointer?.type) return

    const { type, index = 0 } = pointer
    const { categories, series } = config
    const activeSeries = series[index]

    switch (type) {
        case 'category': {
            const anySeriesHaveSort = letMeMap(series).some(s => s.values?.some(v => Boolean(v.sort)))
            const lastCategory = categories.length === index + 1
            if (lastCategory && anySeriesHaveSort) return { sortType: 'string' }
            const { sortType = 'string', sort, dateFormat } = categories[index] ?? {}
            return { sortType, sort, dateFormat }
        }
        case 'subseries': {
            const { sortType = 'string', sort = 'ascending', dateFormat } = activeSeries?.subSeries ?? {}
            return { sortType, sort, dateFormat }
        }
        case 'series': {
            const valueIndex = (pointer as SeriesFieldPointer).valueIndex
            return { sortType: 'number', sort: activeSeries?.values[valueIndex]?.sort }
        }
    }
}

const directionOptions = [
    { value: 'ascending', label: <ArrowUpwardRounded fontSize="small" />, tip: 'Ascending' },
    { value: 'descending', label: <ArrowDownwardRounded fontSize="small" />, tip: 'Descending' },
]

const typeOptions: Array<{ value: SortType; label: string }> = [
    { value: 'string', label: 'String' },
    { value: 'number', label: 'Number' },
    { value: 'date', label: 'Date' },
]

const useSortHelpStyles = makeStyles(({ spacing }) => ({
    helpTableCell: { padding: spacing(0.5) },
}))

function SortHelp() {
    const classes = useSortHelpStyles()

    return (
        <div>
            <Typography gutterBottom>Sort dates by using a combination of inputs below</Typography>
            <Typography>Example 1. Use "D/M/YY" for "3/13/87"</Typography>
            <Typography>Example 2. Use "MMM-YYYY" for "Aug-2022"</Typography>

            <Box mt={2} />

            <Table padding="none">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <strong>Input</strong>
                        </TableCell>
                        <TableCell>
                            <strong>Example</strong>
                        </TableCell>
                        <TableCell>
                            <strong>Description</strong>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {helpTable.map((row, i) => (
                        <TableRow key={i}>
                            {row.map((value, j) => (
                                <TableCell className={classes.helpTableCell} key={j}>
                                    {value}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    )
}

const helpTable = [
    ['YYYY', '2014', '4 digit year'],
    ['YY', '14', '2 digit year'],
    ['Q', '1 - 4', 'Quarter of year'],
    ['M MM', '1 - 12', 'Month number'],
    ['MMM MMMM', 'Jan - December', 'Month name in locale'],
    ['D DD', '1 - 31', 'Day of month'],
    ['Do', '1st - 31st', 'Day of month with ordinal'],
    ['DDD DDDD', '1 - 365', 'Day of year'],
    ['X', '1410715640.579', 'Unix timestamp'],
    ['x', '1410715640579', 'Unix ms timestamp'],
    ['w ww', '1 - 53', 'Locale week of year'],
    ['e', '0 - 6', 'Locale day of week'],
    ['ddd dddd', 'Mon - Sunday', 'Day name in locale'],
    ['H HH', '0 - 23', 'Hours (24 hour time)'],
    ['h hh', '1 - 12', 'Hours (12 hour time used with a A)'],
    ['k kk', '1 - 24', 'Hours (24 hour time from 1 to 24)'],
    ['a A', 'am pm', 'Post or ante meridiem'],
    ['m mm', '0 - 59', 'Minutes'],
    ['s ss', '0 - 59', 'Seconds'],
]
