import { Log, UserManager, WebStorageStateStore, CreateSigninRequestArgs } from 'oidc-client-ts'
import { getBaseUrl } from './utils'

const url = getBaseUrl()
const isDev = process.env.NODE_ENV === 'development'
if (isDev) Log.setLogger(console)

const userManager = new UserManager({
    authority: window.TADA_APIS.SSO,
    automaticSilentRenew: true,
    client_id: 'tada-app',
    includeIdTokenInSilentRenew: false,
    monitorSession: false,
    post_logout_redirect_uri: `${url}/`,
    redirect_uri: `${url}/callback`,
    response_type: 'code',
    scope: 'openid profile email collaboration',
    silent_redirect_uri: `${url}/silent_renew`,
    silentRequestTimeoutInSeconds: 20000,
    userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    loadUserInfo: true,
})

export function login(params?: CreateSigninRequestArgs['extraQueryParams']) {
    const path = window.location.pathname
    const extraQueryParams = { _target: 'app', ...params }
    userManager.signinRedirect({ extraQueryParams, state: { path } })
}

export default userManager
