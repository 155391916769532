import { format as f } from 'd3-format'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'

import { cleanDirectory, uniqueString } from 'genesis-suite/utils'
import { WIDGET_CONFIG_MAP } from '../components/widgets/lib/configMap'
import { logEvent } from './amplitudeClient'

const defaultPageSizeOptions = [
    { label: '100', value: 100 },
    { label: '500', value: 500 },
    { label: '1000', value: 1000 },
    { label: '1500', value: 1500 },
    { label: '2000', value: 2000 },
]

const getPageSizeOptions = (defaultPageSize, maxPageSize) => {
    let pageSizeOptions = defaultPageSizeOptions.filter(option => option.value <= maxPageSize)
    pageSizeOptions = pageSizeOptions.filter(option => option.value >= defaultPageSize)
    if (pageSizeOptions.findIndex(option => option.value === maxPageSize) === -1)
        pageSizeOptions.push({ label: maxPageSize, value: maxPageSize })
    if (pageSizeOptions.findIndex(option => option.value === defaultPageSize) === -1)
        pageSizeOptions.unshift({ label: defaultPageSize.toString(), value: defaultPageSize })
    return pageSizeOptions
}

const converttoHMS = value => {
    const h = Math.floor(value / 3600)
    const m = Math.floor((value - h * 3600) / 60)
    const s = Math.floor(value - (h * 3600 + m * 60))
    return `${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}:${s < 10 ? `0${s}` : s}`
}

const converttoMS = value => {
    const m = Math.floor(value / 60)
    const s = Math.floor(value - m * 60)
    return `${m < 10 ? `0${m}` : m}:${s < 10 ? `0${s}` : s}`
}

const formatNumber = (val, displayFormat, currencyPrefix, referenceValue, baseFormat, isDecimalPercentNumber) => {
    if (val == null) return val

    if (isNaN(val)) return val

    if (
        (displayFormat == null || displayFormat.trim().length == 0) &&
        (currencyPrefix == null || currencyPrefix.trim().length == 0) &&
        (baseFormat == null || baseFormat.trim().length == 0 || baseFormat == 'None')
    )
        return val.toString()

    let suffix = ''

    if (displayFormat != null && !isNaN(val) && displayFormat.toLocaleLowerCase() == 'hms') return converttoHMS(val)

    if (displayFormat != null && !isNaN(val) && displayFormat.toLocaleLowerCase() == 'ms') return converttoMS(val)

    if (currencyPrefix == null) currencyPrefix = ''

    if (displayFormat == null || displayFormat.trim().length == 0 || displayFormat == ',.f') displayFormat = ',.0f'

    if (displayFormat.indexOf('%') > -1 && displayFormat.indexOf('f') > -1) {
        displayFormat = displayFormat.split('%')[0]
        suffix = '%'
    }

    if (displayFormat.indexOf('f') > -1) {
        const fIndex = displayFormat.indexOf('f')
        if (fIndex > 0) {
            const decimals = displayFormat.charAt(fIndex - 1)
            if (isNaN(decimals)) {
                val = val.toFixed(2)
            }
        }
    }

    try {
        if (referenceValue == null) referenceValue = val

        var formattedValue = ''
        var format = f(displayFormat)

        if (baseFormat != null) {
            switch (baseFormat.toLowerCase()) {
                case 'k':
                    formattedValue = `${currencyPrefix + format((val / 1000).toFixed(2))}K${suffix}`
                    break
                case 'm':
                    formattedValue = `${currencyPrefix + format((val / 1000000).toFixed(2))}M${suffix}`
                    break
                case 'b':
                    formattedValue = `${currencyPrefix + format((val / 1000000000).toFixed(2))}B${suffix}`
                    break
                case 'd':
                    {
                        if (
                            (referenceValue >= 1000 && referenceValue < 1000000) ||
                            (referenceValue > -1000000 && referenceValue <= -1000)
                        )
                            formattedValue = `${currencyPrefix + format((val / 1000).toFixed(2))}K${suffix}`
                        else if (
                            (referenceValue >= 1000000 && referenceValue < 1000000000) ||
                            (referenceValue > -1000000000 && referenceValue <= -1000000)
                        )
                            formattedValue = `${currencyPrefix + format((val / 1000000).toFixed(2))}M${suffix}`
                        else if (referenceValue >= 1000000000 || referenceValue <= -1000000000)
                            formattedValue = `${currencyPrefix + format((val / 1000000000).toFixed(2))}B${suffix}`
                    }
                    break
            }
        }

        if (isDecimalPercentNumber) val *= 100

        if (formattedValue.length < 1) formattedValue = currencyPrefix + format(val) + suffix

        if (formattedValue.length > 0) {
            if (val < 0) formattedValue = `${formattedValue.toString().replace('-', '(')})`
        }

        return formattedValue
    } catch (e) {
        if (baseFormat != null) {
            switch (baseFormat.toLowerCase()) {
                case 'k':
                    formattedValue = `${currencyPrefix + (val / 1000).toFixed(2).toLocaleString()}K${suffix}`
                    break
                case 'm':
                    formattedValue = `${currencyPrefix + (val / 1000000).toFixed(2).toLocaleString()}M${suffix}`
                    break
                case 'b':
                    formattedValue = `${currencyPrefix + (val / 1000000000).toFixed(2).toLocaleString()}B${suffix}`
                    break
                case 'd':
                    {
                        if (
                            (referenceValue >= 1000 && referenceValue < 1000000) ||
                            (referenceValue > -1000000 && referenceValue <= -1000)
                        )
                            formattedValue = `${currencyPrefix + format((val / 1000).toFixed(2))}K${suffix}`
                        else if (
                            (referenceValue >= 1000000 && referenceValue < 1000000000) ||
                            (referenceValue > -1000000000 && referenceValue <= -1000000)
                        )
                            formattedValue = `${currencyPrefix + format((val / 1000000).toFixed(2))}M${suffix}`
                        else if (referenceValue >= 1000000000 || referenceValue <= -1000000000)
                            formattedValue = `${currencyPrefix + format((val / 1000000000).toFixed(2))}B${suffix}`
                    }
                    break
            }
        }

        if (isDecimalPercentNumber) val *= 100

        if (formattedValue.length < 1) {
            formattedValue = currencyPrefix + val.toFixed(2).toLocaleString() + suffix
        }

        if (formattedValue.length > 0) {
            if (val < 0) formattedValue = `${formattedValue.toString().replace('-', '(')})`
        }
        return formattedValue
    }
}

function roundNumber(number, places = 0) {
    return +(Math.round(number + 'e+' + places) + 'e-' + places)
}

const sortList = ['hour', 'day', 'date', 'month', 'year']

const isSpecialSort = fieldName => {
    for (let i = 0; i < sortList.length; i++) {
        const item = sortList[i]
        if (fieldName.toLocaleLowerCase().indexOf(item) > -1) return true
    }
    return false
}

const formatDateTime = dateString => moment(dateString).format('M/D/YYYY, h:mm:ss a')

function createFetchConfig(
    baseConfig,
    networkContext,
    globalAndPerspectiveFilters,
    inlineFilters,
    widgetFilter,
    widgetControl,
    actionFilters = []
) {
    const visualConfigKey = WIDGET_CONFIG_MAP[baseConfig.Type]?.ConfigKey
    const { pageSize, pageNumber, sortOrders, searchUrl } = widgetControl || {}

    const subWidgetConfig = {
        ...baseConfig[visualConfigKey],
        ...(sortOrders != null && { SortOrders: sortOrders }),
        ...(pageNumber != null && { PageNumber: pageNumber }),
        ...(pageSize != null && { PageSize: pageSize }),
        ...(searchUrl != null && { DataUrl: searchUrl }),
    }

    const appliedInlineFilters = !baseConfig.ForControl
        ? inlineFilters?.filters?.filter(f => f.WidgetId !== baseConfig.Id)
        : []

    const Filters = combineFilters(
        networkContext?.Filters,
        globalAndPerspectiveFilters,
        appliedInlineFilters,
        widgetFilter?.search,
        widgetFilter?.temporal,
        actionFilters
    )

    const context = { ...baseConfig.Context, ...(networkContext || {}), Filters }
    return {
        ...baseConfig,
        DynamicFilters: makeVisorWidgetFilters(widgetFilter?.dynamic),
        [visualConfigKey]: subWidgetConfig,
        Context: JSON.stringify(context),
    }
}

export const combineFilters = (
    contextFilters,
    globalAndPerspectiveFilters,
    appliedInlineFilters,
    searchFilters,
    temporalFilters,
    widgetDateRangeFilter
) => {
    let filterByName = {}

    function addFilters(newFilters) {
        if (!newFilters?.length) return

        newFilters.forEach(f => {
            if (filterByName[f.FilterName]) return

            filterByName[f.FilterName] = f
        })
    }

    addFilters(contextFilters)
    addFilters(globalAndPerspectiveFilters)
    addFilters(appliedInlineFilters)
    addFilters(searchFilters)
    addFilters(temporalFilters)
    addFilters(widgetDateRangeFilter)

    return isEmpty(filterByName) ? undefined : Object.values(filterByName)
}

/** For context filters that can be or'd (operator == 'EqualTo' | 'NotEqualTo'), combine new filter with current or push on the end  */
export function updateOrableContextFilters(filters, filterName, operator, newFilter) {
    const existingFilterIndex = filters.findIndex(f => f.FilterName === filterName)
    if (existingFilterIndex > -1) {
        return filters.map((f, i) => {
            if (i !== existingFilterIndex) return f
            if (f.Values.includes(newFilter.Value)) return f

            return {
                ...f,
                DisplayValues: [...f.DisplayValues, newFilter.DisplayValue],
                Values: [...f.Values, newFilter.Value],
            }
        })
    } else {
        return [
            ...filters,
            {
                DisplayValues: [newFilter.DisplayValue],
                FilterName: filterName,
                Operator: operator,
                PostCalculation: false,
                PropertyName: newFilter.FieldName,
                ResourceName: newFilter.Name,
                ResourceType: 'Concept',
                Values: [newFilter.Value],
            },
        ]
    }
}
const isWidgetDateRangeFilterEmpty = filter => {
    const { values, range, rangeOffset, clickRangeName } = filter || {}
    if (values?.length > 0) return false
    if (Boolean(range?.min || range?.max)) return false
    if (Boolean(rangeOffset?.min || rangeOffset?.max)) return false
    if (clickRangeName) return false
    return true
}
function makeVisorWidgetFilters(widgetFilters) {
    const propertyNames = Object.keys(widgetFilters || {})
    if (!propertyNames.length) return null

    return propertyNames.map(name => {
        const filter = widgetFilters[name]
        const datePickerFilter = filter?.datePickerFilter ?? null

        return {
            Id: filter.id,
            Operator: filter.operator,
            PropertyName: name,
            ResourceType: 'Insight',
            ResourceName: filter.resource.name,
            Values: filter?.values?.map(v => v.value) || [],
            DisplayValues: (filter?.values && filter?.values?.map(filterSelection => filterSelection.label)) || [],
            ...configureWidgetDateRangeFilter(datePickerFilter),
        }
    })
}

function configureWidgetDateRangeFilter(datePickerFilter) {
    if (datePickerFilter === null || isWidgetDateRangeFilterEmpty(datePickerFilter)) {
        return {}
    }

    const dateProperties = {
        ...(isEmpty(datePickerFilter?.range)
            ? {}
            : {
                  Range: { MinValue: datePickerFilter?.range?.min, MaxValue: datePickerFilter?.range?.max },
              }),
        RangeOffset: {
            ...(datePickerFilter?.rangeOffset?.min !== undefined ? { Min: datePickerFilter.rangeOffset.min } : {}),
            ...(datePickerFilter?.rangeOffset?.max !== undefined ? { Max: datePickerFilter.rangeOffset.max } : {}),
        },
        UseLastRefreshDate: datePickerFilter?.useLastRefreshDate || false,
        ...(datePickerFilter?.clickRangeName ? { ClickRangeName: datePickerFilter?.clickRangeName } : {}),
        FilterName: datePickerFilter?.FilterName,
        IsTemporal: true,
        PostCalculation: false,
    }

    return dateProperties
}
const parseWidgetData = (data, tryAll) => {
    if (data && !(Object.keys(data).length === 1 && 'Config' in data)) {
        if (tryAll) {
            return Object.keys(data).reduce((acc, key) => {
                try {
                    acc[key] = JSON.parse(data[key])
                } catch (e) {
                    acc[key] = data[key]
                }

                return acc
            }, {})
        } else {
            if (data.seriesData) {
                return JSON.parse(data.seriesData)
            }
            if (data.data) {
                return JSON.parse(data.data)
            }
            if (data.markerData) {
                return JSON.parse(data.markerData)
            }
        }
    } else {
        return null
    }
}

/** open url in new tab appending "https://"" if needed */
function openInNewTab(url) {
    logEvent('OPEN_SHORTCUT')
    const _url = url.match(/^https?:\/\//i) ? url : 'http://' + url
    const win = window.open(_url, '_blank')
    win.focus()
}

/** return morning, afternoon or evening based on current time of day */
function getGreetingTime() {
    const now = moment()

    var split_afternoon = 12 //24hr time to split the afternoon
    var split_evening = 17 //24hr time to split the evening
    var currentHour = parseFloat(now.format('HH'))

    if (currentHour >= split_afternoon && currentHour <= split_evening) return 'Afternoon'
    else if (currentHour >= split_evening) return 'Evening'
    else return 'Morning'
}

function sortByDate(date1, date2, reverse) {
    if (!moment(date1).isValid() || !moment(date2).isValid() || moment(date1).isSame(date2)) return 0
    else if (moment(date1).isBefore(date2)) return reverse ? 1 : -1
    else if (moment(date1).isAfter(date2)) return reverse ? -1 : 1
}

/**
 * Parses a filter object from the server
 * @param {Object} filter - filter configuration object
 */
const parseFilter = ({
    DisplayValues,
    Values,
    Range,
    RangeOffset,
    DateRangeOffset,
    UseLastRefreshDate,
    ClickRangeName,
    Operator,
}) => ({
    values: Values && Values.map((value, i) => ({ value, label: (DisplayValues && DisplayValues[i]) || value })),
    range: (Range?.MinValue != null || Range?.MaxValue != null) && { min: Range.MinValue, max: Range.MaxValue },
    rangeOffset: RangeOffset
        ? { min: RangeOffset.Min, max: RangeOffset.Max }
        : DateRangeOffset // TODO - remove this old prop conversion once no longer used
        ? { min: DateRangeOffset, max: 0 }
        : {},
    dateRangeOffset: undefined,
    useLastRefreshDate: UseLastRefreshDate || false,
    clickRangeName: ClickRangeName,
    operator: Operator,
})

function makeTemporalFilterDisplayText(parsedFilter, pastDateType, futureDateType) {
    const { range, rangeOffset, useLastRefreshDate, clickRangeName } = parsedFilter

    let text = ''

    if (!isEmpty(range)) {
        const { min, max } = range
        if (min && max) {
            if (min !== max) text = `${min} to ${max}`
            else text = min
        }
    } else if (!isEmpty(rangeOffset)) {
        const { min, max } = rangeOffset
        const a =
            min === 0
                ? useLastRefreshDate
                    ? 'Data last updated'
                    : 'Today'
                : min
                ? `Last ${-min} ${pastDateType}`
                : 'The start'
        const b =
            max === 0
                ? useLastRefreshDate
                    ? 'data last updated'
                    : 'today'
                : max
                ? `next ${max} ${futureDateType}`
                : 'the end'
        text = `${a} to ${b}`
    } else if (clickRangeName) {
        text = clickRangeName
    }

    return text
}

/**
	Super-simple Mustache-style text-replacement.

	Example:
	var data = {name: "James", location: "Mars"};
	mustache("Welcome to {{location}}, {{ name }}.", data); // => Welcome to Mars, James.
 * @param {string} string content w/ mustache values
 * @param {*} data to replace the values
 */
function replaceMustacheValues(string, data = {}, flags = 'g') {
    if (!string) return string

    return Object.entries(data).reduce(
        (res, [key, value]) => res.replace(new RegExp(`{{\\s*${key}\\s*}}`, flags), value),
        string
    )
}

/** Confirm if string is valid css color */
const isColor = c => {
    let img = document.createElement('img')
    img.style = 'background: rgb(0, 0, 0)'
    img.style = 'background: ' + c
    if (img.style.background != 'rgb(0, 0, 0)' && img.style.background != '') return true
    img.style = 'background: rgb(255, 255, 255)'
    img.style = 'background: ' + c
    return img.style.background != 'rgb(255, 255, 255)' && img.style.background != ''
}

const getColor = bgColor => {
    if (!isColor(bgColor)) return '#FFF'
    else if (bgColor.includes('linear-gradient')) {
        let colors = []
        bgColor.replace(/#[0-9A-F]{6}/gi, function (color) {
            colors.push(color)
        })
        return colors[0]
    } else return bgColor
}

/** Create a url slug (replacing title spaces w/ dashes) and placing incremented index on end to prevent collision with usedSlugs */
function createSlug(title, usedSlugs) {
    const base = title.replace(/ /g, '-').replace(/[^\w-]+/g, '')
    return uniqueString(
        base.toLocaleLowerCase(),
        usedSlugs.map(s => s.toLocaleLowerCase())
    )
}

function getBaseUrl() {
    const { location } = window
    const { protocol, hostname, port } = location

    return `${protocol}//${hostname}${port ? `:${port}` : ''}${cleanDirectory(process.env.PUBLIC_URL)}`
}

function getRageOffSetForTimePeriod(timeId) {
    const currentDate = new Date()
    const targetDate = getTargetData(currentDate, timeId)
    const timeDifference = targetDate - currentDate
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
    if (days > 0) return { Min: 0, Max: days, resolution: 'days' }
    else return { Min: days, Max: 0, resolution: 'days' }
}

function getTargetData(currentDate, timeId) {
    const targetDate = new Date()

    const [type, direction, value] = timeId.split('_')
    // multiplier on direction (l for "last", n for "next")
    const multiplier = direction === 'l' ? -1 : 1

    switch (type) {
        case 'd': // Day
            targetDate.setDate(currentDate.getDate() + multiplier * value)
            break
        case 'm': // Month
            targetDate.setMonth(currentDate.getMonth() + multiplier * value)
            break
        case 'y': // Year
            targetDate.setFullYear(currentDate.getFullYear() + multiplier * value)
            break
        default:
            break
    }

    return targetDate
}

export function computeHiddenCount(
    ref,
    itemCountToDelete,
    widthToSubtract = 0,
    parentWidth = ref.current?.clientWidth
) {
    const { children } = ref.current || {}
    if (!children?.length) return 0

    const widths = [...children].map(child => child.clientWidth)
    while (itemCountToDelete--) widths.pop()

    const totalWidth = parentWidth - widthToSubtract
    return getOverflowCount(widths, totalWidth)
}

function getOverflowCount(widths, totalWidth) {
    let cumulativeWidth = 0

    for (let i = 0; i < widths.length; i++) {
        const width = widths[i]
        cumulativeWidth += width
        if (cumulativeWidth > totalWidth) return widths.length - i
    }

    return 0
}

const getLastWordOfString = content => {
    const words = content.split(/[\s\n]+/)
    const lastWord = words[words.length - 1]
    return lastWord.length > 0 ? lastWord : ''
}

const getCaretCoordinates = () => {
    const selection = window.getSelection()
    // if (selection.rangeCount === 0) return { top: 0, left: 0 }

    const range = selection.getRangeAt(0)
    const rect = range.getBoundingClientRect()

    return {
        top: rect.top + window.scrollY,
        left: rect.left + window.scrollX,
    }
}

const parseHTMLToArrayTGPT = htmlString => {
    const tempDiv = document.createElement('div')
    tempDiv.innerHTML = htmlString

    const resultArray = []

    const paragraph = tempDiv.querySelector('p')
    if (paragraph) {
        paragraph.childNodes.forEach(node => {
            if (node.nodeType === Node.TEXT_NODE) {
                const textContent = node.textContent?.trim()
                if (textContent) {
                    resultArray.push({
                        type: 'text',
                        resourceType: 'Unknown',
                        value: null,
                        text: textContent,
                    })
                }
            } else if (node.nodeType === Node.ELEMENT_NODE && node.tagName === 'SPAN') {
                const spanElement = node
                const dataTag = JSON.parse(spanElement.getAttribute('data-tag') || '{}')

                resultArray.push({
                    type: 'tag',
                    resourceType: dataTag.resourceType,
                    text: dataTag.value,
                    value: dataTag.name,
                })
            }
        })
    }
    return resultArray
}

const stripHtmlDOM = htmlString => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlString, 'text/html')
    return doc.body.textContent || ''
}

export {
    formatNumber as format,
    roundNumber,
    isSpecialSort,
    formatDateTime,
    parseWidgetData,
    openInNewTab,
    getPageSizeOptions,
    getGreetingTime,
    sortByDate,
    parseFilter,
    makeTemporalFilterDisplayText,
    replaceMustacheValues,
    createFetchConfig,
    isColor,
    getColor,
    createSlug,
    getBaseUrl,
    getRageOffSetForTimePeriod,
    stripHtmlDOM,
    isWidgetDateRangeFilterEmpty,
    getLastWordOfString,
    getCaretCoordinates,
    parseHTMLToArrayTGPT,
}
