import { Box, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useUpdateEffect } from 'genesis-suite/hooks'
import { debounce, isEqual } from 'lodash'
import { useEffect, useRef, useState, useContext } from 'react'
import { Responsive as GridLayout } from 'react-grid-layout'
import 'react-grid-layout/css/styles.css'
import Measure from 'react-measure'
import { useSelector } from 'react-redux'
import 'react-resizable/css/styles.css'

import { Spinner } from 'genesis-suite/components'
import { appearanceConstants } from '../../constants'
import ShowDetailsContainer from '../../containers/ShowDetailsContainer'
import { pageview } from '../../lib/googleAnalyticsClient'
import { scenarioSelectors, perspectiveSelectors } from '../../selectors'
import PerspectiveToolbar from '../PerspectiveToolbar'
import { PerspectiveContext, PerspectiveProvider } from '../contexts/PerspectiveContext'
import useFetchPerspectiveDefaultFilters from '../widgets/hooks/useFetchPerspectiveDefaultFilters'
import PerspectiveWidget from './PerspectiveWidget'
import WidgetEditCover from './WidgetEditCover'
import WidgetMinimised from './WidgetMinimised'
import TopLabels from './visuals/LabelWidget/TopLabels'
import PerspectiveSummary from './PerspectiveSummary'
import { sleep } from 'genesis-suite/utils'
import HiddenWidgets from './HiddenWidgets'

const mobileScale = 0.8
const { ScrollBarWidth } = appearanceConstants

const smCols = 4
const lgCols = 18 // divisible by 2 and 3
const rows = 18 // default row count per perspective clientHeight
const minHeight = 2 // minimum number of rows for widget
const defaultWidgetRows = rows / 2
const gridGap = 8
const smBreakpoint = 800

const useStyles = makeStyles(({ spacing, palette }) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    layout: {
        flex: 1,
        backgroundColor: palette.background.main,
        paddingLeft: ScrollBarWidth,
    },
    wrapper: {
        margin: p => (p.containerWidth === '100%' ? undefined : `auto`),
        paddingBottom: spacing(),
        paddingTop: spacing(),
        width: p => p.containerWidth,
        height: p =>
            p.containerWidth === '100%' ? '100%' : `calc(${100 / mobileScale}% - ${spacing(2) / mobileScale}px)`,
        transformOrigin: 'top',
        transform: p => (p.containerWidth === '100%' ? 'inherit' : `scale(${mobileScale})`),
        border: p => (p.containerWidth === '100%' ? '' : '5px solid black'),
        borderRadius: p => (p.containerWidth === '100%' ? '' : '15px'),
        '& .react-grid-placeholder': { background: palette.primary.main },
        '& .react-resizable-handle.react-resizable-handle-se': {
            borderBottom: `4px solid ${palette.primary.main}`,
            borderRight: `4px solid ${palette.primary.main}`,
            width: '15px',
            height: '15px',
            backgroundImage: 'none',
            '&::after': { display: 'none' },
        },
    },
    widgetContainer: { '&:hover': { zIndex: p => (p.isEditing ? '' : 2) } }, // allow tooltip to cover other widgets
    forceScroll: { height: '200%' },
    summary: {
        marginTop: spacing(1),
        marginRight: spacing(1),
        border: `1px solid ${palette.border?.main}`,
        borderRadius: '15px',
        padding: spacing(1),
        overflow: 'hidden',
    },
}))

/**
 * Renders a perspective
 * @param {function} props.changeLayouts callback to update the draft layout in redux
 * @param {string=} props.containerWidth width of the perspective container. 100% by default. used to simulate different device
 * @param {array} props.configs array of widgetConfig objects
 * @param {string} props.id id of the perspective being rendered
 * @param {boolean=} props.isEditing optional. if true perspective is being edited
 * @param {*} props.layouts device layouts object from perspective config or redux draft state
 * @param {number=} props.currentLayout if provided, override layouts with grid columns based on value provided
 * @param {function} props.onChange callback for when the perspective being rendered changes to a new collection of widgets
 * @param {boolean} props.hasFetched if false, v2 module requiring perspective fetching
 */
export default function Perspective({
    changeLayouts,
    containerWidth = '100%',
    configs,
    id,
    isEditing = false,
    layouts,
    currentLayout,
    onChange = () => {},
    hasFetched,
    onEditPerspective,
    ...rest
}) {
    const widgetContainer = useRef()
    const [width, setWidth] = useState(1000)
    const [height, setHeight] = useState(0)
    const topConfigs = configs.filter(topLabelFilter)
    const [widgetConfigs, setWidgetConfigs] = useState(configs.filter(c => !topLabelFilter(c)))

    const [minimizedWidgets, setMinimizedWidgets] = useState([])
    let temporaryWidgetConfigs = []
    const hasFormWidget = configs.some(c => c.Type?.toUpperCase() === 'FORM')
    const [render, setRender] = useState(true)

    const isEditingPerspective = isEditing && widgetConfigs.length > 1
    const breakpoint = width < smBreakpoint ? 'sm' : 'lg'
    const [_layouts, isOneRow] = makeLayouts(widgetConfigs, currentLayout, breakpoint, layouts, isEditingPerspective)
    const rowPxHeight =
        isOneRow && !isEditingPerspective
            ? height / minHeight - 1.5 * gridGap
            : Math.max(Math.floor(height / rows - gridGap), 36)

    const classes = useStyles({ containerWidth, isEditing })
    const readyToRender = useFetchPerspectiveDefaultFilters(id)
    const showPerspectiveSummary = useSelector(perspectiveSelectors.getShowPerspectiveSummary)

    useEffect(() => {
        onChange(id)
        pageview(window.location.pathname + window.location.search)

        if (widgetContainer.current) widgetContainer.current.scrollTo(0, 0)
    }, [id])

    // HACK - needed to prevent sm layout from sticking when transitioning to lg (in ReactGridLayout component state)?
    const [isTransitioning, setIsTransitioning] = useState(false)
    useUpdateEffect(() => {
        setIsTransitioning(true)
        setTimeout(() => setIsTransitioning(false), 0)
    }, [breakpoint])

    function handleLayoutChange(_, u) {
        if (!isEditingPerspective) return
        const updated = {
            sm: u.sm.map(({ i, x, y, w, h }) => ({ i, x, y, w, h })),
            lg: u.lg.map(({ i, x, y, w, h }) => ({ i, x, y, w, h })),
        }
        if (isEqual(_layouts, updated)) return
        changeLayouts(updated)
    }

    const onShowAllWidgets = async () => {
        setRender(false)
        await sleep(500)
        setRender(true)
    }

    const minimizeWidgetCB = config => {
        if (currentLayout !== 0) {
            temporaryWidgetConfigs.push(config)
            updateWidgetConfigList()
        }
    }

    const triggerRefreshWidget = config => {
        const tMinimizedWidgets = minimizedWidgets.filter(obj => obj.Id !== config.Id)
        setMinimizedWidgets(tMinimizedWidgets)

        setWidgetConfigs(prev => [...prev, config])
    }

    const updateWidgetConfigList = debounce(() => {
        const tempList = [...temporaryWidgetConfigs]
        const minimizedWidgets = new Set(tempList.map(obj => obj.Id))
        const filteredWidgetConfigs = widgetConfigs.filter(obj => !minimizedWidgets.has(obj.Id))

        temporaryWidgetConfigs = []
        setMinimizedWidgets(prev => [...prev, ...tempList])
        setWidgetConfigs(filteredWidgetConfigs)
    }, 300)

    const handleResetWidgets = () => {
        setWidgetConfigs(configs.filter(c => !topLabelFilter(c)))
        setMinimizedWidgets([])
    }

    const perspectiveLayoutProps = {
        containerWidth,
        handleLayoutChange,
        id,
        isEditing,
        isEditingPerspective,
        isTransitioning,
        _layouts,
        rowPxHeight,
        setHeight,
        setWidth,
        widgetConfigs,
        width,
        minimizeWidgetCB,
        ...rest,
    }
    if (!readyToRender) return <Spinner />

    return (
        <PerspectiveProvider id={id} configs={configs}>
            <Box id="perspective-container" className={classes.root}>
                <PerspectiveToolbar
                    perspectiveID={id}
                    handleResetWidgets={handleResetWidgets}
                    hasFormWidget={hasFormWidget}
                    configs={configs}
                    layouts={layouts}
                    currentLayout={currentLayout}
                    id={id}
                />

                <Box
                    sx={{
                        display: 'grid',
                        height: '100%',
                        gridTemplateColumns: showPerspectiveSummary ? 'repeat(2,1fr)' : 'minmax(0, 1fr)',
                        gap: 1,
                    }}
                >
                    <Box className={classes.root}>
                        <TopLabels configs={topConfigs} isEdit={isEditing} />
                        <div className={classes.layout}>
                            {render && <PerspectiveLayout {...perspectiveLayoutProps} />}
                            <ShowDetailsContainer />
                        </div>
                        {currentLayout !== 0 && (
                            <WidgetMinimised
                                minimizedWidgets={minimizedWidgets}
                                triggerRefresh={triggerRefreshWidget}
                            />
                        )}
                        <HiddenWidgets onDone={onShowAllWidgets} />
                    </Box>
                    {showPerspectiveSummary && (
                        <Box className={classes.summary}>
                            <PerspectiveSummary />
                        </Box>
                    )}
                </Box>
            </Box>
        </PerspectiveProvider>
    )
}

function PerspectiveLayout(props) {
    const activeScenarios = useSelector(scenarioSelectors.getActiveScenarioIds)
    const hasActiveScenarios = activeScenarios.length > 0
    const baseStyles = { display: 'grid', height: '100%', overflowX: 'hidden', overflowY: 'scroll' }

    return hasActiveScenarios ? (
        <Box id="perspective-layout" sx={{ ...baseStyles, gridTemplateColumns: 'repeat(2,1fr)', gap: 1 }}>
            <PerspectiveContent contentTitle={'Original'} {...props} />
            <PerspectiveContent contentTitle={'Scenario'} activeScenarios={activeScenarios} {...props} />
        </Box>
    ) : (
        <Box id="perspective-layout" sx={{ ...baseStyles, gridTemplateColumns: '1fr' }}>
            <PerspectiveContent {...props} />
        </Box>
    )
}

export function PerspectiveContent({
    activeScenarios,
    containerWidth,
    contentTitle,
    handleLayoutChange,
    id,
    isEditing,
    isEditingPerspective,
    isTransitioning,
    _layouts,
    rowPxHeight,
    setHeight,
    setWidth,
    widgetConfigs,
    width,
    isCompare = false,
    currentLayout,
    ...rest
}) {
    const classes = useStyles({ containerWidth, isEditing })
    const { updateWPerspectiveDataById, hiddenWidgets } = useContext(PerspectiveContext)

    const onPerspectiveDataLoaded = (widgetConfig, data) => {
        const { Name, Description, Title } = widgetConfig
        const widgetData = data?.seriesData ? data.seriesData : data?.data ? data.data : data
        updateWPerspectiveDataById(Name, { Name, Description, Title, data: widgetData })
    }

    return (
        <Measure
            client
            onResize={({ client }) => {
                setWidth(client.width - 1) // prevent overlap w/ scrollbar
                setHeight(client.height)
            }}
        >
            {({ measureRef }) => (
                <div ref={measureRef} className={`${classes.wrapper} widget-parent-wrapper`}>
                    {contentTitle && (
                        <Typography sx={{ mb: 1 }} variant="h5">
                            {contentTitle}
                        </Typography>
                    )}
                    <GridLayout
                        draggableCancel={'.noDrag'}
                        isDraggable={isEditingPerspective}
                        isResizable={isEditingPerspective}
                        width={isTransitioning ? smBreakpoint : width}
                        rowHeight={rowPxHeight}
                        margin={[gridGap, gridGap]}
                        autoSize={false}
                        verticalCompact={true}
                        preventCollision={false}
                        transformScale={containerWidth === '100%' ? 1 : mobileScale}
                        cols={{ sm: smCols, lg: lgCols }}
                        containerPadding={[0, 0]}
                        breakpoints={{ sm: 0, lg: smBreakpoint }}
                        layouts={addFixedPropsToLayouts(_layouts)}
                        onLayoutChange={handleLayoutChange}
                    >
                        {widgetConfigs
                            .filter(c => !hiddenWidgets.includes(c.Id))
                            .map(widgetConfig => {
                                widgetConfig = { ...widgetConfig, Scenario: activeScenarios ? true : false }
                                return (
                                    <div key={widgetConfig.Id} className={classes.widgetContainer}>
                                        {isEditing && (
                                            <WidgetEditCover config={widgetConfig} isDraggable={isEditingPerspective} />
                                        )}
                                        <PerspectiveWidget
                                            config={widgetConfig}
                                            perspectiveId={id}
                                            isEditing={isEditing}
                                            activeScenarios={activeScenarios}
                                            isCompare={isCompare}
                                            onPerspectiveDataLoaded={data =>
                                                onPerspectiveDataLoaded(widgetConfig, data)
                                            }
                                            {...rest}
                                        />
                                    </div>
                                )
                            })}
                    </GridLayout>

                    {isEditingPerspective && <div className={classes.forceScroll} />}
                </div>
            )}
        </Measure>
    )
}

export function makeLayouts(configs, currentLayout, breakpoint, layouts, isEditing) {
    if (configs?.length === 1) {
        const [sm] = makeLayout(configs, smCols, rows, smCols, defaultWidgetRows)
        const [lg] = makeLayout(configs, lgCols, rows, lgCols, defaultWidgetRows)
        return [{ sm, lg }, true]
    }

    const cleanedLayouts = cleanLayouts(layouts, configs)

    if (!isEditing && currentLayout) {
        const orderedConfigs = cleanedLayouts
            ? getOrderFromLayouts(cleanedLayouts, breakpoint).map(id => configs.find(c => c.Id === id))
            : configs
        const [layout, isOneRow] =
            breakpoint === 'sm'
                ? makeLayout(orderedConfigs, smCols, rows, smCols, defaultWidgetRows)
                : currentLayout === 1
                ? makeLayout(orderedConfigs, lgCols, minHeight, lgCols, minHeight)
                : makeLayout(orderedConfigs, lgCols, rows, lgCols / currentLayout, defaultWidgetRows)

        return [{ sm: layout, lg: layout }, isOneRow]
    }

    if (cleanedLayouts) return [cleanedLayouts, false]

    const [sm] = makeLayout(configs, smCols, rows, smCols, defaultWidgetRows, isEditing)
    const [lg, isOneRow] = makeLayout(configs, lgCols, rows, lgCols / 2, defaultWidgetRows, isEditing)

    return [{ sm, lg }, breakpoint === 'lg' && isOneRow]
}

/**
 * Make a grid perspective layout with given configs and desired sizes
 * @param {*} configs widget configs array
 * @param {number} width total grid column count
 * @param {number} height total grid row count per perspective clientHeight
 * @param {number} itemWidth columns a widget should use
 * @param {number} itemHeight rows a widget should use
 * @param {boolean=} isEditing if true, user is editing,
 * @param {number=} startRow optional. row to start making layout
 */
function makeLayout(configs, width, height, itemWidth, itemHeight, isEditing, startRow = 0) {
    let w = itemWidth
    let h = itemHeight
    let x = 0
    let y = startRow
    let lastRowAdj = false
    let isOneRow = height === minHeight

    const layout = configs?.map(({ Id }, index) => {
        // last row adjustments
        const widgetsLeft = configs.length - index
        if (!lastRowAdj && x === 0 && widgetsLeft * w <= width) {
            w = width / widgetsLeft // share remaining width
            // one row perspective - fit full height
            if (y === 0) {
                isOneRow = true
                h = isEditing ? height : minHeight
            }
            lastRowAdj = true
        }

        const layout = { i: Id, x, y, w, h }
        const fitOnRow = x + 2 * w <= width
        y = fitOnRow ? y : y + h
        x = fitOnRow ? x + w : 0
        return layout
    })

    return [layout, isOneRow]
}

/** given the flex layouts and current breakpoint, determines the order (left to right) */
function getOrderFromLayouts(layouts, breakpoint) {
    const layout = layouts[breakpoint].sort((a, b) => a.x - b.x)
    let y = 0
    let sorted = []

    const getIds = (layout, y) => layout.filter(l => l.y === y).map(w => w.i)

    while (layout.length > sorted.length || y < 1000) {
        const ids = getIds(layout, y)
        sorted = sorted.concat(ids)
        y++
    }

    return sorted
}

/** check for new widgets not in current layouts and append to the end, remove any layout options that don't have a config and return undefined if no layouts */
function cleanLayouts(layouts, configs) {
    if (!layouts) return

    const hasConfig = layout => configs?.some(c => c.Id === layout.i)
    const adjLayouts = { sm: layouts.sm.filter(hasConfig), lg: layouts.lg.filter(hasConfig) }
    if (!adjLayouts.sm.length || !adjLayouts.lg.length) return

    const newConfigs = configs.filter(w => !adjLayouts.sm.some(l => l.i === w.Id))
    if (!newConfigs.length) return adjLayouts

    const smStartRow = adjLayouts.sm.reduce((acc, cur) => Math.max(acc, cur.y + cur.h), 0)
    const lgStartRow = adjLayouts.lg.reduce((acc, cur) => Math.max(acc, cur.y + cur.h), 0)

    const [sm] = makeLayout(newConfigs, smCols, rows, smCols, defaultWidgetRows, false, smStartRow)
    const [lg] = makeLayout(newConfigs, lgCols, rows, lgCols / 2, defaultWidgetRows, false, lgStartRow)

    return { sm: [...adjLayouts.sm, ...sm], lg: [...adjLayouts.lg, ...lg] }
}

export const topLabelFilter = c => c.Type === 'Label' && c.LabelConfig?.AlignTop

export function addFixedPropsToLayouts(layouts) {
    const minW = 2

    return {
        sm: layouts.sm?.map(l => ({ ...l, minH: minHeight })),
        lg: layouts.lg?.map(l => ({ ...l, minH: minHeight, minW })),
    }
}
