import { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useSelector } from 'react-redux'
import { IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import ApprovedIcon from '@mui/icons-material/AssignmentTurnedInRounded'
import EditingIcon from '@mui/icons-material/AssignmentLateRounded'
import PendingIcon from '@mui/icons-material/AssignmentRounded'
import RejectedIcon from '@mui/icons-material/AssignmentReturnRounded'

import { DraftAction, DraftStatus, VisualTypeAndConfig } from 'genesis-suite/types/visualTypes'
import { visualService } from '../lib/services'
import { applicationSelectors } from '../selectors'
import VisualHistoryDialog from './widgets2/VisualHistoryDialog'

type Props = VisualTypeAndConfig & {
    /** (optional) only view icon w/ tooltip w/o click */
    viewOnly?: boolean
}

export default function DraftIndicator({ viewOnly, ...rest }: Props) {
    const { type, config } = rest
    const [open, setOpen] = useState(false)
    const [status, setStatus] = useState<DraftStatus>(null)
    const [anchorEl, setAnchorEl] = useState(null)

    const appName = useSelector(applicationSelectors.getCurrentAppName)

    const { enqueueSnackbar: showSnackbar } = useSnackbar()

    useEffect(() => {
        if (config?.draft?.status) setStatus(config.draft.status)
    }, [config])

    if (!config?.draft || !status) return null

    const { draft } = config

    const handleViewChanges = () => {
        setOpen(true)
        setAnchorEl(null)
    }

    const handleSubmit = async () => {
        setAnchorEl(null)
        try {
            const nextStatus = status === DraftStatus.PENDING ? DraftStatus.EDITING : DraftStatus.PENDING
            await visualService.partialUpdate(appName, type, config.id, 'draft.status', nextStatus)
            setStatus(nextStatus)
        } catch (err) {
            showSnackbar(err.message, { variant: 'error' })
            console.log(err)
        }
    }

    const textStyle = { color: '#FFF' }
    const Icon = getIcon(status)

    return (
        <>
            <Tooltip
                title={
                    <>
                        <Typography sx={textStyle}>You are viewing a draft.</Typography>
                        <Typography sx={textStyle}>
                            This {type} has been {draft.action}.
                        </Typography>
                        {draft.action !== DraftAction.REMOVED && (
                            <>
                                <Typography sx={textStyle}>Its status is {status}.</Typography>
                                <Typography sx={textStyle}>Click for options.</Typography>
                            </>
                        )}
                    </>
                }
            >
                {!viewOnly && draft.action !== DraftAction.REMOVED ? (
                    <IconButton size="small" onClick={e => setAnchorEl(e.currentTarget)}>
                        <Icon sx={{ color: getColor(draft.action) }} />
                    </IconButton>
                ) : (
                    <Icon sx={{ color: getColor(draft.action) }} />
                )}
            </Tooltip>

            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                {draft.action === DraftAction.UPDATED && <MenuItem onClick={handleViewChanges}>View Changes</MenuItem>}
                <MenuItem onClick={handleSubmit}>
                    {status === DraftStatus.PENDING ? 'Back to editing' : 'Submit for review'}
                </MenuItem>
            </Menu>

            <VisualHistoryDialog open={open} onDone={() => setOpen(false)} mode="compare-previous" {...rest} />
        </>
    )
}

const getColor = (action: DraftAction) => {
    switch (action) {
        case DraftAction.ADDED:
            return 'status.success'
        case DraftAction.UPDATED:
            return 'status.warning'
        case DraftAction.REMOVED:
            return 'status.error'
        default:
            return 'tada.purple'
    }
}

function getIcon(status: DraftStatus) {
    switch (status) {
        case DraftStatus.APPROVED:
            return ApprovedIcon
        case DraftStatus.EDITING:
            return EditingIcon
        case DraftStatus.PENDING:
            return PendingIcon
        case DraftStatus.REJECTED:
            return RejectedIcon
    }
}
