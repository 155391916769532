import { useDispatch, useSelector } from 'react-redux'
import { IconButton, Tooltip } from '@mui/material'
import DarkModeIcon from '@mui/icons-material/DarkMode'
import LightModeIcon from '@mui/icons-material/LightMode'

import { ThemeMode } from '../../../types/ThemeTypes'
import { themeCreators } from '../../../actions/creators'
import { themeSelectors } from '../../../selectors'
import { useHotKey } from 'genesis-suite/hooks'
import { HotKeys, createTip } from '../../../lib/hotkeys'

export default function ThemeModeButton({ iconStyle, buttonStyle }) {
    const dispatch = useDispatch()
    const themeMode: ThemeMode = useSelector(themeSelectors.getThemeMode)

    const handleClick = () => {
        const mode: ThemeMode = themeMode === ThemeMode.LIGHT ? ThemeMode.DARK : ThemeMode.LIGHT
        dispatch(themeCreators.setThemeMode(mode))
    }

    useHotKey(HotKeys.ThemeMode, handleClick)
    const keyTip = createTip(HotKeys.ThemeMode)

    const ThemeModeIcon = () =>
        themeMode === ThemeMode.LIGHT ? (
            <Tooltip title={`Dark mode ${keyTip}`}>
                <DarkModeIcon sx={iconStyle} />
            </Tooltip>
        ) : (
            <Tooltip title={`Light mode ${keyTip}`}>
                <LightModeIcon sx={iconStyle} />
            </Tooltip>
        )

    return (
        <IconButton sx={buttonStyle} onClick={handleClick}>
            <ThemeModeIcon />
        </IconButton>
    )
}
