import produce from 'immer'
import { useContext } from 'react'

import { PropertyType } from 'genesis-suite/types/architectureTypes'
import { Property } from 'genesis-suite/types/networkTypes'
import { Aggregation, ChartType, SeriesConfig } from 'genesis-suite/types/visualTypes'
import { useSemanticTypeById } from '../../../hooks/useSemanticTypes'
import useWidgetColors from '../../../hooks/useWidgetColors'
import { TrellisConstants } from '../../../types/TrellisTypes'
import { DisplayProperty, DragField, FieldPointer } from '../builderTypes'
import { ConfigContext } from '../ConfigContext'
import { getDataFieldProperty } from '../utils'
import DnDPropertySelector from './DnDPropertySelector'

interface Props {
    label?: string
    numericOnly?: boolean
}

export default ({ label, numericOnly }: Props) => {
    const semanticTypeById = useSemanticTypeById()
    const { config, dispatch, selectedField, resources } = useContext(ConfigContext)
    const { categories } = config
    const colors = useWidgetColors()
    const { show, hasProperty } = parseConfig(config)

    if (!show) return null

    const trellisField = categories[TrellisConstants.CATEGORY_INDEX]?.field
    const properties = Object.values(resources.byId).reduce<Array<Property>>((prevProperties, resource) => {
        return [...prevProperties, ...resource.properties]
    }, [])
    const { displayName, semanticType } = getDataFieldProperty(trellisField, properties) ?? {}
    const formattedProperties: DisplayProperty[] = hasProperty
        ? [
              {
                  type: semanticType?.type,
                  title: displayName,
                  field: trellisField,
                  aggregation: Aggregation.NONE,
                  selected:
                      selectedField?.type === 'category' && selectedField?.index === TrellisConstants.CATEGORY_INDEX,
                  pointer: { type: 'category', index: TrellisConstants.CATEGORY_INDEX },
                  color: colors[0],
              },
          ]
        : []

    const handleAdd = (dragField: DragField, _index?: number) => {
        const index = TrellisConstants.CATEGORY_INDEX
        const { pointer, type, ...field } = dragField
        const to: FieldPointer = { type: 'category', index }
        const source = { field, aggregation: Aggregation.NONE }
        const options = pointer ? { operation: 'move' as const, from: pointer } : { operation: 'new' as const, source }

        dispatch({ type: 'UPDATE_TRELLIS', payload: { enabled: true } })
        dispatch({ type: 'UPDATE_SELECTED_PROPERTY', payload: { to, ...options, semanticTypeById } })
    }

    const handleRemove = index => {
        dispatch({ type: 'SET_SELECTED_FIELD', payload: null })
        dispatch({ type: 'UPDATE_TRELLIS', payload: { enabled: false, layout: undefined } })
        dispatch({
            type: 'UPDATE_CATEGORIES',
            payload: produce(categories, draft => {
                draft.splice(index, 1)
            }),
        })
    }

    return (
        <DnDPropertySelector
            label={label}
            accept={[PropertyType.DEFINING, PropertyType.QUALITATIVE]}
            limit={1}
            onAdd={handleAdd}
            onRemove={handleRemove}
            properties={formattedProperties}
            onFieldSelect={i => dispatch({ type: 'SET_SELECTED_FIELD', payload: { type: 'category', index: i } })}
        />
    )
}

function parseConfig(config: SeriesConfig): { show: boolean; hasProperty: boolean } {
    const { type, series, categories } = config

    switch (type) {
        case ChartType.BULLET:
        case ChartType.LABEL:
            return { show: series.length > 0, hasProperty: categories.length > 0 }

        case ChartType.COMBO:
        case ChartType.PIE:
        case ChartType.SORTED:
            return { show: series.length > 0 && categories.length > 0, hasProperty: categories.length > 1 }

        default:
            return { show: false, hasProperty: false }
    }
}
