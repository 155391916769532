import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Typography, List, ListItemText, Divider, ListItemButton } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-pro'
import DragIcon from '@mui/icons-material/DragIndicator'
import { isEmpty } from 'lodash'
import useSWR from 'swr'

import { SortBy } from 'genesis-suite/types/utilTypes'
import { DateOrTime } from 'genesis-suite/components'
import { Dashboard, DraftMeta, DashboardRequestFilter } from 'genesis-suite/types/visualTypes'
import { filterVisualObjectsByViewMode, userDisplayName } from '../../lib/manageUtils'
import { applicationSelectors, authSelectors, deploymentSelectors } from '../../selectors'
import TableHeaderCell from '../TableHeaderCell'
import ViewStatusIndicator from '../ViewStatusIndicator'
import { visualService } from '../../lib/services'

export enum DashboardType {
    ALL = '__ALL',
    ONLY_PERSPECTIVES = '__ONLY_PERSPECTIVES',
    NO_PERSPECTIVES = '__NO_PERSPECTIVES',
}

export function makeColumns(
    columnWidths: ColumnWidths,
    filter: DashboardFilter,
    onOpen: (dashboardId: string) => void,
    onUpdateFilter: (updated: Partial<DashboardFilter>) => void,
    isPowerUser: boolean
): GridColDef[] {
    if (!columnWidths) return []

    return [
        {
            disableColumnMenu: true,
            field: 'title',
            pinnable: false,
            renderHeader: () => <TableHeaderCell title="Title" />,
            renderCell: ({ value, row }) => {
                return (
                    <Box display="flex" alignItems="center" gap={1}>
                        {isPowerUser && <DragIcon sx={{ cursor: 'grab' }} fontSize="small" />}
                        <Typography
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                '&:hover': { textDecoration: 'underline' },
                            }}
                            onClick={() => onOpen(row.draft?.visualId ?? row.id)}
                        >
                            <ViewStatusIndicator type={row?.draft?.type} sx={{ mr: 1 }} />
                            {value}
                        </Typography>
                    </Box>
                )
            },
            sortable: true,
            sortingOrder: ['asc', 'desc', null],
            width: columnWidths.title,
        },
        {
            disableColumnMenu: true,
            field: authorKey,
            pinnable: false,
            renderHeader: () => <AuthorHeader filter={filter} onUpdateFilter={onUpdateFilter} />,
            sortable: true,
            sortingOrder: ['asc', 'desc', null],
            width: columnWidths.author,
        },
        {
            disableColumnMenu: true,
            field: 'updatedAt',
            pinnable: false,
            resizable: false,
            renderHeader: () => <TableHeaderCell title="Updated" />,
            renderCell: ({ value }) => <DateOrTime>{value}</DateOrTime>,
            sortable: true,
            sortingOrder: ['desc', 'asc', null],
            width: columnWidths.updatedAt,
        },
        {
            align: 'center',
            disableColumnMenu: true,
            field: 'active',
            hide: !columnWidths.active,
            pinnable: false,
            resizable: false,
            renderHeader: () => <ActiveHeader filter={filter} onUpdateFilter={onUpdateFilter} />,
            renderCell: ({ row }) => (row.version === '1' ? '' : row.active ? 'Yes' : 'No'),
            sortable: false,
            sortingOrder: ['asc', 'desc', null],
            width: columnWidths.active,
        },
        {
            disableColumnMenu: true,
            field: 'focalPoint',
            pinnable: false,
            renderHeader: () => <FocalPointHeader filter={filter} onUpdateFilter={onUpdateFilter} />,
            sortable: true,
            sortingOrder: ['asc', 'desc', null],
            width: columnWidths.focalPoint,
        },
    ]
}

interface FilterableHeaderProps {
    filter: DashboardFilter
    onUpdateFilter: (updated: Partial<DashboardFilter>) => void
}

function ActiveHeader({ filter, onUpdateFilter }: FilterableHeaderProps) {
    const options = [
        { value: 'all', label: 'All' },
        { value: 'active', label: 'Public' },
        { value: 'not-active', label: 'Private' },
    ]
    const activeOptionValue = filter.active ? 'active' : filter.active === false ? 'not-active' : 'all'

    function handleChange(value) {
        const active = value === 'active' ? true : value === 'not-active' ? false : undefined
        onUpdateFilter({ active })
    }

    return (
        <TableHeaderCell
            title="Public"
            filterApplied={activeOptionValue !== 'all'}
            filter={
                <List dense disablePadding>
                    {options.map(f => (
                        <ListItemButton
                            key={f.value}
                            selected={f.value === activeOptionValue}
                            onClick={() => handleChange(f.value)}
                        >
                            <ListItemText primary={f.label} />
                        </ListItemButton>
                    ))}
                </List>
            }
            onClearFilter={() => onUpdateFilter({ active: undefined })}
        />
    )
}

function AuthorHeader({ filter, onUpdateFilter }: FilterableHeaderProps) {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const userId = useSelector(authSelectors.getUserId)
    const view = useSelector(deploymentSelectors.getDeploymentViewFlag)
    const { data } = useSWR(['dashboard createdBy list', appName, filter], ([_, appName, filter]) =>
        visualService.getDashboardDistinctList(appName, 'createdBy', makeFilterOptions(filter), view)
    )

    return (
        <TableHeaderCell
            title="Author"
            filterApplied={Boolean(filter.createdBy)}
            filter={
                <List dense disablePadding>
                    <ListItemButton
                        selected={filter.createdBy === userId}
                        onClick={e => onUpdateFilter({ createdBy: userId })}
                    >
                        <ListItemText primary="Yours" />
                    </ListItemButton>
                    <Divider />
                    {data
                        ?.filter(user => user.id !== userId)
                        .map(user => (
                            <ListItemButton
                                key={user.id}
                                selected={user.id === filter.createdBy}
                                onClick={e => onUpdateFilter({ createdBy: user.id })}
                            >
                                <ListItemText primary={`${user.firstName} ${user.lastName}`} />
                            </ListItemButton>
                        ))}
                </List>
            }
            onClearFilter={() => onUpdateFilter({ createdBy: undefined })}
        />
    )
}

function FocalPointHeader({ filter, onUpdateFilter }: FilterableHeaderProps) {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const view = useSelector(deploymentSelectors.getDeploymentViewFlag)
    const { data } = useSWR(['dashboard focalPoint list', appName, filter], ([_, appName, filter]) =>
        visualService.getDashboardDistinctList(appName, 'focalPoint', makeFilterOptions(filter), view)
    )

    return (
        <TableHeaderCell
            title="Focal point"
            filterApplied={filter.type !== DashboardType.ALL || Boolean(filter.focalPoint)}
            filter={
                <List dense disablePadding>
                    {focalPointStaticOptions.map(f => (
                        <ListItemButton
                            key={f.value}
                            selected={!filter.focalPoint && f.value === filter.type}
                            onClick={e => onUpdateFilter({ type: f.value, focalPoint: undefined })}
                        >
                            <ListItemText primary={f.label} />
                        </ListItemButton>
                    ))}
                    <Divider />
                    {data?.map(focalPoint => (
                        <ListItemButton
                            key={focalPoint}
                            selected={focalPoint === filter.focalPoint}
                            onClick={e => onUpdateFilter({ type: DashboardType.ALL, focalPoint })}
                        >
                            <ListItemText primary={focalPoint} />
                        </ListItemButton>
                    ))}
                </List>
            }
            onClearFilter={() => onUpdateFilter({ type: DashboardType.ALL, focalPoint: undefined })}
        />
    )
}

const focalPointStaticOptions = [
    { value: DashboardType.ALL, label: 'All collections' },
    { value: DashboardType.NO_PERSPECTIVES, label: 'No perspectives' },
    { value: DashboardType.ONLY_PERSPECTIVES, label: 'Only perspectives' },
]

interface ColumnWidths {
    active?: number
    author: number
    focalPoint?: number
    title: number
    updatedAt: number
}

export function useColumnWidths(isPowerUser: boolean): {
    columnWidths: ColumnWidths
    setTableWidth: Dispatch<SetStateAction<number>>
} {
    const [tableWidth, setTableWidth] = useState(0)
    const [widths, setWidths] = useState<ColumnWidths>(null)

    useEffect(() => {
        if (!tableWidth) return
        if (!isEmpty(widths)) return
        updateWidths()
    }, [tableWidth])

    function updateWidths() {
        const staticColumnWidths = {
            updatedAt: 110,
            ...(isPowerUser && { active: 105 }),
        }
        const remainingWidth = tableWidth - 10 - Object.values(staticColumnWidths).reduce((acc, cur) => acc + cur, 0)

        setWidths({
            ...staticColumnWidths,
            author: Math.floor(remainingWidth * 0.3),
            focalPoint: Math.floor(remainingWidth * 0.3),
            title: Math.floor(remainingWidth * 0.4),
        })
    }

    return { columnWidths: widths, setTableWidth }
}

export const authorKey = 'author'
export const createdBySortKey = 'createdBy.lastName'

export interface RowData {
    active: boolean
    author: string
    draft: DraftMeta
    focalPoint: string
    id: string
    inModuleViews: boolean
    inNavigation: boolean
    isUsers: boolean
    title: string
    updatedAt: Date
}

export const makeRows = (views: Dashboard[], moduleViewIds: string[], userId: string): RowData[] =>
    filterVisualObjectsByViewMode(views).map(v => ({
        id: v.id,
        title: v.title,
        isUsers: v.createdBy.id === userId,
        author: userDisplayName(v.createdBy),
        draft: v.draft,
        updatedAt: v.updatedAt,
        focalPoint: v.focalPoint ?? '',
        inNavigation: v.inNavigation ?? false,
        inModuleViews: moduleViewIds.some(id => id === v.id || id === v.draft?.visualId),
        active: v.active ?? false,
    }))

export interface DashboardFilter {
    /** (optional) */
    createdBy?: string
    /** (optional) the node name (focal point) */
    focalPoint?: string
    type?: DashboardType
    inNavigation?: boolean
    active?: boolean
    textSearch?: string
}

export type FiltersWithSearch = DashboardFilter

export function makeFilterOptions(filter: FiltersWithSearch, sortBy?: SortBy, page?: number): DashboardRequestFilter {
    const { active, focalPoint, inNavigation, createdBy, textSearch, type } = filter
    return {
        active,
        createdBy,
        inNavigation,
        sortBy,
        ...(page && { pageSize: 30, page }),
        focalPoint,
        textSearch,
        type:
            type === DashboardType.NO_PERSPECTIVES
                ? 'no-perspectives'
                : type === DashboardType.ONLY_PERSPECTIVES
                ? 'only-perspectives'
                : undefined,
    }
}
