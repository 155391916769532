import React, { useState, useEffect, useRef } from 'react'
import { useHover } from 'genesis-suite/hooks'
import { MenuItem, ListItemIcon, ListItemText, Popper } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import classnames from 'classnames'

const delay = 100

const styles = ({ palette }) => {
    const topBarColor = palette.background.topBar
    const topBarContrast = palette.getContrastText(topBarColor)

    return {
        itemIcon: {
            width: 24,
            justifyContent: 'center',
            '& svg': { color: topBarContrast },
        },
        optionText: {
            color: topBarContrast,
        },
        hoveredItem: {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
    }
}

const MenuListItem = ({ classes, item, closeMenu }) => {
    const itemRef = useRef()
    const timeout = useRef(null)
    const [open, setOpen] = useState(false)

    let isHovered = useHover(itemRef)

    useEffect(() => {
        if (item.Component) {
            if (isHovered) {
                clearTimeout(timeout.current)
                setOpen(true)
            } else {
                timeout.current = setTimeout(() => setOpen(false), delay)
            }
        }
    }, [isHovered])

    return (
        <div ref={itemRef}>
            <MenuItem
                onClick={() => {
                    if (!item.Component) {
                        item.click()
                        closeMenu()
                    }
                }}
                className={classnames({ [classes.hoveredItem]: open })}
            >
                <ListItemIcon className={classes.itemIcon}>{item.icon}</ListItemIcon>
                <ListItemText classes={{ primary: classes.optionText }}>{item.name}</ListItemText>
                {item.Component && (
                    <ListItemIcon className={classes.itemIcon}>
                        <ChevronRightRoundedIcon />
                    </ListItemIcon>
                )}
            </MenuItem>
            {item.Component && (
                <Popper open={open} anchorEl={itemRef.current} placement="right-start" disablePortal>
                    <item.Component />
                </Popper>
            )}
        </div>
    )
}

export default withStyles(styles)(MenuListItem)
