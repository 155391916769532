import Amplitude, { logEvent } from '@redux-beacon/amplitude'
import { matchPath } from 'react-router-dom'
import { createMiddleware } from 'redux-beacon'
import { LOCATION_CHANGE } from 'redux-first-history'

import { appearanceTypes, filterTypes, moduleTypes, widgetTypes } from '../actions/types'
import { amplitudeInstance, defaultProperties } from '../lib/amplitudeClient'
import { routePaths } from '../lib/routes'
import { appearanceSelectors, perspectiveSelectors } from '../selectors'

// Amplitude
const simpleEvent = logEvent(action => ({ type: action.type, properties: defaultProperties }))

const sideNavEvent = logEvent((action, prevState, nextState) => {
    const sideNavIsOpen = appearanceSelectors.getSideNavState(nextState)

    return {
        type: sideNavIsOpen ? 'EXPAND_SIDE_NAV' : 'COLLAPSE_SIDE_NAV',
        properties: defaultProperties,
    }
})

const locationEvent = (action, prevState, nextState) => {
    const perspectiveMatch = matchPath(routePaths.PERSPECTIVE, action.payload.location.pathname)

    if (!perspectiveMatch) return []

    return logEvent(() => {
        const { perspectiveID: id } = perspectiveMatch.params
        const title = perspectiveSelectors.getTitle(nextState)

        return {
            type: 'CHANGE_PERSPECTIVE',
            properties: { ...defaultProperties, id, title },
        }
    })()
}

const moduleSelectEvent = logEvent(action => ({
    type: action.type,
    properties: { ...defaultProperties, module: action.payload.appModule.Name },
}))

const interactionEvent = logEvent(action => ({
    type: action.type,
    properties: { ...defaultProperties, interaction: action.payload.interactionType },
}))

const amplitudeMapper = action => {
    switch (action.type) {
        case widgetTypes.WIDGET_REFRESH:
        case filterTypes.PERSPECTIVE_FILTER_CHANGE:
        case filterTypes.COMPARE_PERSPECTIVE_FILTER_CHANGE:
        case filterTypes.GLOBAL_FILTER_CHANGE:
            return simpleEvent

        case moduleTypes.MODULE_SUCCESS:
            return moduleSelectEvent

        case appearanceTypes.TOGGLE_SIDE_NAV:
        case appearanceTypes.CLOSE_SIDE_NAV:
            return sideNavEvent

        case LOCATION_CHANGE:
            return locationEvent

        case widgetTypes.INTERACTION_CHANGE:
            return interactionEvent

        default:
            return []
    }
}

export const amplitudeMiddleware = amplitudeInstance
    ? createMiddleware(amplitudeMapper, Amplitude({ instance: amplitudeInstance }))
    : null
