import { v4 as uuid } from 'uuid'

import {
    FormType,
    FormConfig,
    FieldVisibility,
    InputFormConfig,
    TableFormConfig,
    KanbanFormConfig,
} from 'genesis-suite/types/visualTypes'

const { INPUT, TABLE, KANBAN } = FormType

export function toKanban(config: FormConfig): KanbanFormConfig {
    switch (config.formType) {
        case INPUT: {
            return {
                ...config,
                formType: KANBAN,
                canAddNew: false,
                canDelete: false,
                cardContent: config.sections
                    .map(s => s.prompts.filter(p => p.visibility === FieldVisibility.SHOW).map(p => p.property))
                    .flat()
                    .filter(item => item),
            }
        }
        case TABLE: {
            const { columns, canCellEdit, canInputEdit, canUploadExcel, exportFileName, sortOrders, ...rest } = config
            return {
                ...rest,
                formType: KANBAN,
                cardContent: columns.filter(c => c.visibility === FieldVisibility.SHOW).map(c => c.property),
                sections: [
                    {
                        id: uuid(),
                        required: true,
                        prompts: columns.map(({ width, ...rest }) => ({
                            ...rest,
                        })),
                    },
                ],
            }
        }
        default:
            return config
    }
}

export function toInput(config: FormConfig): InputFormConfig {
    switch (config.formType) {
        case KANBAN: {
            const { canAddNew, canDelete, formType, ...rest } = config
            return {
                ...rest,
                formType: INPUT,
                sectionOrientation: 'horizontal',
            }
        }
        case TABLE: {
            const {
                columns,
                canAddNew,
                canDelete,
                canCellEdit,
                canInputEdit,
                canUploadExcel,
                exportFileName,
                sortOrders,
                ...rest
            } = config
            return {
                ...rest,
                formType: INPUT,
                sections: [
                    {
                        id: uuid(),
                        required: true,
                        prompts: columns.map(({ width, ...rest }) => ({
                            ...rest,
                        })),
                    },
                ],
                sectionOrientation: 'horizontal',
            }
        }
        default:
            return config
    }
}

export function toTable(config: FormConfig): TableFormConfig {
    switch (config.formType) {
        case INPUT:
        case KANBAN:
            const { formType, sections, ...rest } = config
            return {
                ...rest,
                formType: TABLE,
                columns: sections.reduce((acc, sec) => {
                    sec.prompts.forEach(({ id, property, ...rest }) => {
                        if (property) acc.push({ ...rest, id, width: 150, property })
                    })
                    return acc
                }, []),
                exportFileName: '',
                canAddNew: true,
                canDelete: true,
                canCellEdit: true,
                canInputEdit: true,
                canUploadExcel: true,
            }
        default:
            return config
    }
}
