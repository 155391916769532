import React, { useEffect, useState, useCallback } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import { useSelector } from 'react-redux'
import { applicationSelectors, authSelectors, moduleSelectors } from '~/selectors'
import { announcementService, baseUrls, moduleService, platformService } from '~/lib/services'
import AnnouncementsModal from './AnnouncementsModal'
import { AnnouncementAppMode, PlatformAnnouncement, SolutionAnnouncement } from 'genesis-suite/types/announcementTypes'
import CampaignSharpIcon from '@mui/icons-material/CampaignSharp'

const AnnouncementButton = ({ iconStyle, buttonStyle }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [solutionAnnouncements, setSolutionAnnouncements] = useState<SolutionAnnouncement[]>([])
    const [platformAnnouncements, setPlatformAnnouncements] = useState<PlatformAnnouncement[]>([])
    const [isSolutionLoading, setIsSolutionLoading] = useState<boolean>(false)
    const [isPlatformLoading, setIsPlatformLoading] = useState<boolean>(false)
    const [isSolutionError, setIsSolutionError] = useState<boolean>(false)
    const [isPlatformError, setIsPlatformError] = useState<boolean>(false)
    const [activeAnnouncement, setActiveAnnouncement] = useState<SolutionAnnouncement | PlatformAnnouncement | null>(
        null
    )

    const { cloudId, appId } = useSelector(applicationSelectors.getAppInfo)
    const moduleId = useSelector(moduleSelectors.getModuleId)
    const accessKey = useSelector(authSelectors.getAccessKey) as string

    const activeEnvName = window.TADA_APIS.ENVIRONMENT

    const getSolutionAnnouncements = useCallback(async () => {
        if (!cloudId || !appId || !moduleId) return

        try {
            setIsSolutionLoading(true)
            setIsSolutionError(false)

            const response = await announcementService.getAnnouncements({
                CloudId: cloudId,
                AppId: appId,
                PortalId: moduleId,
            })
            setSolutionAnnouncements(response?.announcements || [])
        } catch (error) {
            console.error('Error fetching solution announcements:', error)
            setIsSolutionError(true)
        } finally {
            setIsSolutionLoading(false)
        }
    }, [cloudId, appId, moduleId])

    const getPlatformAnnouncements = useCallback(async () => {
        if (!activeEnvName) {
            console.warn('Active environment name is not set. Cannot fetch platform announcements.')
            return
        }

        try {
            setIsPlatformLoading(true)
            setIsPlatformError(false)
            const response = await platformService.getAnnouncements({ scopes: [activeEnvName] })
            setPlatformAnnouncements(response?.announcements || [])
        } catch (error) {
            console.error('Error fetching platform announcements:', error)
            setIsPlatformError(true)
        } finally {
            setIsPlatformLoading(false)
        }
    }, [activeEnvName])

    useEffect(() => {
        getSolutionAnnouncements()
    }, [getSolutionAnnouncements])

    useEffect(() => {
        getPlatformAnnouncements()
    }, [getPlatformAnnouncements])

    const getSrcUrlFromAttachmentToken = useCallback(
        (token: string) => {
            if (activeAnnouncement.mode !== AnnouncementAppMode.Platform && !accessKey) {
                console.warn('User not authenticated')
                return ''
            }
            return activeAnnouncement.mode === AnnouncementAppMode.Platform
                ? `${baseUrls.platform}/File/id/${token}`
                : `${baseUrls.integration}/files?fileToken=${token}&accessKey=${accessKey}`
        },
        [activeAnnouncement, accessKey]
    )

    const handleRefresh = async () => {
        await Promise.all([getPlatformAnnouncements(), getSolutionAnnouncements()])
    }

    const handleOpen = () => setIsOpen(true)
    const handleClose = () => {
        setIsOpen(false)
        setActiveAnnouncement(null)
    }

    const onDetailsClick = useCallback(
        (announcementId: string) => {
            const selectedAnnouncement = solutionAnnouncements.find(a => a.id === announcementId)
            setActiveAnnouncement(selectedAnnouncement || null)
        },
        [solutionAnnouncements]
    )

    const onPlatformDetailsClick = useCallback(
        (announcementId: string) => {
            const selectedAnnouncement = platformAnnouncements.find(a => a.id === announcementId)
            setActiveAnnouncement(selectedAnnouncement || null)
        },
        [platformAnnouncements]
    )

    return (
        <>
            <IconButton onClick={handleOpen} sx={buttonStyle}>
                <Tooltip title="Announcements">
                    <CampaignSharpIcon sx={iconStyle} />
                </Tooltip>
            </IconButton>

            {isOpen && (
                <AnnouncementsModal
                    onRefresh={handleRefresh}
                    open={isOpen}
                    onClose={handleClose}
                    activeAnnouncement={activeAnnouncement}
                    solutionAnnouncements={solutionAnnouncements}
                    platformAnnouncements={platformAnnouncements}
                    isSolutionLoading={isSolutionLoading}
                    isPlatformLoading={isPlatformLoading}
                    isSolutionError={isSolutionError}
                    isPlatformError={isPlatformError}
                    onDetailsClick={onDetailsClick}
                    onPlatformDetailsClick={onPlatformDetailsClick}
                    handleBackClick={() => setActiveAnnouncement(null)}
                    getSrcUrlFromAttachmentToken={getSrcUrlFromAttachmentToken}
                    getAttachment={
                        activeAnnouncement?.mode === AnnouncementAppMode.Platform
                            ? platformService.getPlatformAttachment
                            : moduleService.getAttachment
                    }
                />
            )}
        </>
    )
}

export default AnnouncementButton
