import { FC, useState } from 'react'
import { Button, Menu, type ButtonProps, IconButton } from '@mui/material'

interface MenuButtonProps extends Omit<ButtonProps, 'title'> {
    title: string | JSX.Element
    buttonType?: 'button' | 'icon'
}

const MenuButton: FC<MenuButtonProps> = ({ children, title, buttonType = 'button', ...props }) => {
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = e => {
        setAnchorEl(e.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            {buttonType === 'icon' ? (
                <IconButton {...props} onClick={handleClick}>
                    {title}
                </IconButton>
            ) : (
                <Button {...props} onClick={handleClick}>
                    {title}
                </Button>
            )}
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                {children}
            </Menu>
        </>
    )
}

export default MenuButton
