import { type HotKeyConfig } from 'genesis-suite/hooks/useHotKey'

export const createTip = (config: HotKeyConfig) => {
    const { options } = config
    const { altKey, ctrlKey, shiftKey } = options
    let keyMods = ''
    if (ctrlKey) keyMods += 'ctrl+'
    if (altKey) keyMods += 'alt+'
    if (shiftKey) keyMods += 'shift+'
    return `(${keyMods}${config.key})`
}

export const HotKeys = {
    Applications: {
        key: 'a',
        options: { altKey: true },
    },
    Appearance: {
        key: 'h',
        options: { altKey: true },
    },
    Calculator: {
        key: 'l',
        options: { altKey: true },
    },
    Compare: {
        key: 'c',
        options: { altKey: true },
    },
    Forecast: {
        key: 'p',
        options: { altKey: true },
    },
    Fullscreen: {
        key: 'r',
        options: { altKey: true },
    },
    Home: {
        key: 'm',
        options: { altKey: true },
    },
    Network: {
        key: 'n',
        options: { altKey: true },
    },
    PerspectiveFilter: {
        key: 't',
        options: { altKey: true },
    },
    SideNav: {
        key: 'v',
        options: { altKey: true },
    },
    Scenarios: {
        key: 's',
        options: { altKey: true },
    },
    ShowHotKeys: {
        key: '`',
        options: { altKey: true },
    },
    TadaGPT: {
        key: 'g',
        options: { altKey: true },
    },
    ThemeMode: {
        key: 'k',
        options: { altKey: true },
    },
    Interaction: {
        key: 'i',
        options: { altKey: true },
    },
    Search: {
        key: 'k',
        options: { ctrlKey: true },
    },
} satisfies Record<string, HotKeyConfig>
