import { CloseRounded, WarningRounded } from '@mui/icons-material'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'

import { FormPalette } from 'genesis-suite/types/visualTypes'
import { getFormBackground } from '../../../lib/formUtils'

interface InputFormHeaderProps {
    title: string
    palette: FormPalette
    multiEdit?: boolean
    onClose?: () => void
}

export default function InputFormHeader({ palette, title, multiEdit, onClose }: InputFormHeaderProps) {
    const { background, fontColor } = palette
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                background: getFormBackground('text.primary'),
                '& > h6': { color: 'text.primary' },
            }}
        >
            <Typography variant="h6" display="inline">
                {title}
                {multiEdit && (
                    <Tooltip title="Cannot edit primary properties when editing multiple records">
                        <WarningRounded sx={{ ml: 1, color: 'status.warning', verticalAlign: 'text-bottom' }} />
                    </Tooltip>
                )}
            </Typography>
            {onClose && (
                <IconButton sx={{ ml: 'auto', color: 'text.primary' }} onClick={onClose} size="large">
                    <CloseRounded />
                </IconButton>
            )}
        </Box>
    )
}
