import { useDispatch, useSelector } from "react-redux"
import { authCreators } from "../actions/creators"

import { authSelectors } from "../selectors"

const useFavorites = () => {
    const favorites = useSelector(s => authSelectors.getPreference(s, 'widgetFavorites'))

    const dispatch = useDispatch()
    const updateFavorite = (favorite: string) => dispatch(authCreators.saveFavorite('widgetFavorites', favorite))

    const isFavorite = (id: string): boolean => favorites.includes(id)

    return {
        favorites,
        isFavorite,
        addFavorite: updateFavorite,
        removeFavorite: updateFavorite,
    }
}

export default useFavorites