import { Box, Button, Divider, MenuItem, Popover, Select, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useState } from 'react'

const useStyles = makeStyles(theme => ({
    icon: {
        fill: theme.palette.text.primary,
    },
}))

const TemporalDateSelect = ({ handleFilterUpdate }) => {
    const classes = useStyles()
    const [dateOptionValue, setDateOptionValue] = useState({
        value: '0',
        type: '',
    })
    const [selectValue, setSelectValue] = useState('0')
    const [anchorEl, setAnchorEl] = useState(null)

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    const updateSelect = (id: string, type: string) => {
        setDateOptionValue({
            value: id,
            type: type,
        })
    }

    const handleClick = event => setAnchorEl(event.currentTarget)

    const handleClose = () => setAnchorEl(null)

    const handleSaveDate = () => {
        setSelectValue(dateOptionValue?.value)
        handleFilterUpdate(dateOptionValue?.value)
        handleClose()
    }

    const handleClear = () => {
        setDateOptionValue({ value: '0', type: '' })
        setSelectValue('0')
        handleClose()
        handleFilterUpdate('0')
    }

    return (
        <>
            <Select
                sx={theme => ({
                    '& .MuiSelect-select': {
                        pl: 1,
                        py: 0.2,
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.text.primary,
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.text.primary,
                    },
                })}
                value={selectValue}
                variant="outlined"
                inputProps={{
                    classes: {
                        icon: classes.icon,
                    },
                }}
                open={false}
                aria-describedby={id}
                onClick={handleClick}
            >
                {temporalDateSelectOptions.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.displayName} {option?.type ?? ''}
                    </MenuItem>
                ))}
            </Select>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={theme => ({
                    '& .MuiPopover-paper': { height: '185px !important' },
                    '& .MuiSelect-select': { pl: 1, py: 0.2 },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.text.primary,
                    },
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white',
                    },
                    '.MuiSvgIcon-root ': {
                        fill: 'white !important',
                    },
                })}
            >
                {['Days', 'Months', 'Years'].map((selectType, index) => {
                    return (
                        <Box
                            key={index}
                            sx={{
                                minWidth: '200px',
                                display: 'flex',
                                alignItems: 'center',
                                m: 2,
                            }}
                        >
                            <Select
                                onChange={e => updateSelect(e.target.value, selectType)}
                                variant="outlined"
                                value={dateOptionValue?.type === selectType ? dateOptionValue?.value : ''}
                                sx={{ width: '150px' }}
                            >
                                {temporalDateSelectOptions
                                    .filter(obj => obj.type === selectType)
                                    .map(option => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.displayName}
                                        </MenuItem>
                                    ))}
                            </Select>
                            <Typography sx={{ pl: 2 }}>{selectType}</Typography>
                        </Box>
                    )
                })}
                <Divider />
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        m: 1,
                    }}
                >
                    <Button variant="contained" color={'error'} onClick={handleClear}>
                        Clear
                    </Button>
                    <Button variant="contained" color={'primary'} onClick={handleSaveDate}>
                        Done
                    </Button>
                </Box>
            </Popover>
        </>
    )
}

export const temporalDateSelectOptions = [
    { id: '0', displayName: 'Select...' },
    { id: 'd_l_7', displayName: 'Last 7', type: 'Days' },
    { id: 'd_l_14', displayName: 'Last 14', type: 'Days' },
    { id: 'd_l_21', displayName: 'Last 21', type: 'Days' },
    { id: 'd_l_28', displayName: 'Last 28', type: 'Days' },
    { id: 'd_n_7', displayName: 'Next 7', type: 'Days' },
    { id: 'd_n_14', displayName: 'Next 14', type: 'Days' },
    { id: 'd_n_21', displayName: 'Next 21', type: 'Days' },
    { id: 'd_n_28', displayName: 'Next 28', type: 'Days' },
    { id: 'm_l_1', displayName: 'Last 1', type: 'Months' },
    { id: 'm_l_3', displayName: 'Last 3', type: 'Months' },
    { id: 'm_l_6', displayName: 'Last 6', type: 'Months' },
    { id: 'm_l_9', displayName: 'Last 9', type: 'Months' },
    { id: 'm_l_12', displayName: 'Last 12', type: 'Months' },
    { id: 'm_n_1', displayName: 'Next 1', type: 'Months' },
    { id: 'm_n_3', displayName: 'Next 3', type: 'Months' },
    { id: 'm_n_6', displayName: 'Next 6', type: 'Months' },
    { id: 'm_n_9', displayName: 'Next 9', type: 'Months' },
    { id: 'm_n_12', displayName: 'Next 12', type: 'Months' },
    { id: 'y_l_1', displayName: 'Last 1', type: 'Years' },
    { id: 'y_l_2', displayName: 'Last 2', type: 'Years' },
    { id: 'y_n_1', displayName: 'Next 1', type: 'Years' },
    { id: 'y_n_2', displayName: 'Next 2', type: 'Years' },
]

export default TemporalDateSelect
