import { useState } from 'react'
import { useSelector } from 'react-redux'

import { MenuIcon } from 'genesis-suite/components'
import { Present as PresentIcon } from 'genesis-suite/icons'
import Slideshow from '~/components/Slideshow'
import { logEvent } from '~/lib/amplitudeClient'
import { widgetSelectors } from '~/selectors'

export default function SlideShowToggle() {
    const perspectiveId = useSelector(widgetSelectors.getCurrentPerspectiveId)
    const widgets = useSelector(s => widgetSelectors.getWidgetConfigsForPerspective(s, perspectiveId))

    const [open, setOpen] = useState(false)

    return (
        <>
            <MenuIcon
                buttonProps={{ sx: { color: 'inherit' } }}
                icon={<PresentIcon />}
                tooltip="Slideshow"
                onClick={() => {
                    logEvent('START_SLIDESHOW_FROM_PERSPECTIVE')
                    setOpen(true)
                }}
            />

            <Slideshow open={open} onClose={() => setOpen(false)} configs={widgets} />
        </>
    )
}
