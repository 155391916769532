import {
    Autocomplete,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'classnames'
import React from 'react'
import { useDebouncedCallback } from 'use-debounce'

import { Property } from 'genesis-suite/types/networkTypes'
import { FieldVisibility, FormColumn, FormPrompt } from 'genesis-suite/types/visualTypes'
import { propertyIsDate } from '../../edit_widget/utils'
import { AutogenerateBuilder } from './AutogenerateBuilder'
import { DateFormatBuilder } from './DateFormatBuilder'

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
    root: { justifyContent: 'center', '& > *': { flex: `1 0 30%`, margin: spacing(0.5), marginLeft: 0 } },
    description: { flex: '3 0 100%', margin: spacing(0.5), marginLeft: 0 },
}))

type Field = FormColumn | FormPrompt
interface FormFieldProps {
    field: Field
    properties: Property[]
    onChange: (field: Field) => void
    className?: string
}

export default function FormFieldBuilder({ className, field, onChange, properties }: FormFieldProps) {
    const classes = useStyles()
    const { property } = field
    const primary = property ? property.isPrimary : false
    const handleChange = (change: Partial<Field>) => {
        onChange({ ...field, ...change })
    }

    const handleTextChange = useDebouncedCallback((text: string, key: string) => {
        handleChange({ [key]: text })
    }, 200)

    if (!field) return null

    const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
        handleChange({ [e.target.name]: checked })

    const visOptions: FieldVisibility[] = [
        FieldVisibility.SHOW,
        FieldVisibility.FORM,
        FieldVisibility.TABLE,
        FieldVisibility.HIDE,
    ]

    const propOptions = properties.filter(p => p.name !== field.property?.name)

    return (
        <FormGroup className={clsx(classes.root, className)} row>
            <FormControlLabel
                label="Make Editable"
                control={<Checkbox name="editable" checked={field.editable} onChange={handleCheckbox} />}
            />
            <Tooltip title={primary ? 'Primary properties must be required' : ''}>
                <FormControlLabel
                    label="Make Required"
                    control={
                        <Checkbox
                            name="required"
                            disabled={primary}
                            checked={field.required}
                            onChange={handleCheckbox}
                        />
                    }
                />
            </Tooltip>
            {propertyIsDate(field.property) && (
                <FormControlLabel
                    label="Use as Last Update Date"
                    control={
                        <Checkbox name="lastUpdateField" onChange={handleCheckbox} checked={field.lastUpdateField} />
                    }
                />
            )}
            <FormControlLabel
                control={<Checkbox name="useTadaValues" checked={field.useTadaValues} onChange={handleCheckbox} />}
                label="Use Tada Values"
            />
            {primary && (
                <Tooltip title={!field.useTadaValues ? 'Must use Tada values to make field multi-add' : ''}>
                    <FormControlLabel
                        control={<Checkbox name="multiAdd" onChange={handleCheckbox} checked={field.multiAdd} />}
                        label="Make Multi-Add"
                    />
                </Tooltip>
            )}
            <Autocomplete
                multiple
                filterSelectedOptions
                options={propOptions}
                value={field.dependencies}
                disabled={!field.useTadaValues}
                getOptionLabel={(opt: Property) => opt.displayName}
                onChange={(e, values: Property[]) => handleChange({ dependencies: values })}
                renderInput={params => (
                    <Tooltip title={!field.useTadaValues ? 'Must use Tada values to set filters' : ''}>
                        <TextField label="Filter Properties" {...params} />
                    </Tooltip>
                )}
            />
            <Autocomplete
                options={propOptions}
                value={field.displayProperty}
                disabled={!field.useTadaValues}
                getOptionLabel={(opt: Property) => opt.displayName}
                isOptionEqualToValue={o => o.name === field.displayProperty.name}
                onChange={(e, displayProperty: Property) => handleChange({ displayProperty })}
                renderInput={params => (
                    <Tooltip title={!field.useTadaValues ? 'Must use Tada values to set Display Property' : ''}>
                        <TextField label="Display Property" {...params} />
                    </Tooltip>
                )}
            />
            <FormControl>
                <InputLabel id="vis">Visibility</InputLabel>
                <Select
                    fullWidth
                    labelId="vis"
                    value={field.visibility}
                    onChange={e => handleChange({ visibility: e.target.value as FieldVisibility })}
                >
                    {visOptions.map(o => (
                        <MenuItem key={o} value={o}>
                            {o}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {propertyIsDate(field.property) && (
                <DateFormatBuilder onChange={handleChange} dateFormat={field.dateFormat} />
            )}
            <AutogenerateBuilder
                onChange={handleChange}
                property={field.property}
                autogenerate={field.autogenerate}
                autogeneratePrefix={field.autogeneratePrefix}
            />
            <TextField
                rows={3}
                multiline
                variant="outlined"
                label="Tooltip / Description"
                className={classes.description}
                defaultValue={field?.description}
                onChange={e => handleTextChange(e.target.value, 'description')}
            />
        </FormGroup>
    )
}
