import { combineReducers } from 'redux'
import { LOCATION_CHANGE } from 'redux-first-history'
import { moduleTypes, applicationTypes } from '../actions/types'

const initState = {
    current: null,
    all: null,
    error: null,
    isPending: false,
    /** View flag of the current module */
    viewFlag: null,
}

const current = (state = initState.current, action) => {
    const { payload } = action
    switch (action.type) {
        case moduleTypes.MODULE_SUCCESS: {
            const { appModule } = payload
            return {
                id: appModule.Id,
                name: appModule.Name,
                title: appModule.Title,
                cloud: appModule.Cloud,
                colors: appModule.Colors,
                colorScheme: appModule.ColorScheme,
                enablePowerNav: appModule.EnablePowerNav,
                enableSearch: appModule.EnableSearchBar,
                theme: appModule.Theme,
                hideNetworkDiagram: !appModule.EnableNetworkDiagram,
                hideBusinessExplorer: !appModule.ShowBusinessExplorer,
                active: true,
                version: '1',
                enableAIChat: appModule.EnableAIChat,
                appOptions: appModule.AppOptions,
            }
        }
        case moduleTypes.MODULE_2_SUCCESS: {
            return payload.body
        }
        case moduleTypes.MODULE_UPDATE:
        case moduleTypes.CURRENT_MODULE_UPDATE:
            return { ...state, ...payload.body }
        case moduleTypes.UPDATE_THEME:
            const { theme } = payload
            return { ...state, theme }
        case moduleTypes.UPDATE_FILTERS:
            const { filters } = payload
            return { ...state, filters }
        case applicationTypes.APPLICATION_SELECT:
            return null
        case moduleTypes.MODULE_UPDATE_DETECTED:
            return {
                ...state,
                isStale: true,
                updateDismissed: false,
            }
        case moduleTypes.MODULE_UPDATE_DISMISSED:
            return {
                ...state,
                updateDismissed: true,
            }
        case moduleTypes.CLEAR_MODULES:
        case moduleTypes.MODULE_FAILURE:
            return initState.current
        default:
            return state
    }
}

const all = (state = initState.all, action) => {
    const { payload } = action
    switch (action.type) {
        case moduleTypes.CLEAR_MODULES:
        case moduleTypes.APP_MODULES_REQUEST:
        case applicationTypes.APPLICATION_SELECT:
            return initState.all
        case moduleTypes.APP_MODULES_SUCCESS:
            return payload.modules
        case moduleTypes.MODULE_CREATE:
            return [...state, payload]
        case moduleTypes.MODULE_UPDATE:
        case moduleTypes.ALL_MODULE_UPDATE: {
            const { id, body } = payload
            return state.map(m => (m.id === id ? { ...m, ...body } : m))
        }
        case moduleTypes.UPDATE_THEME:
            const { moduleId, theme } = payload
            return state.map(m => (m.id === moduleId ? { ...m, theme } : m))
        case moduleTypes.MODULE_DELETE:
            return state.filter(m => m.id !== payload.id)
        default:
            return state
    }
}

const error = (state = initState.error, action) => {
    switch (action.type) {
        case moduleTypes.MODULE_CHANGE_REQUEST:
        case moduleTypes.MODULE_RELOAD_REQUEST:
        case moduleTypes.MODULE_SUCCESS:
        case moduleTypes.MODULE_2_SUCCESS:
        case moduleTypes.CLEAR_MODULES:
        case LOCATION_CHANGE:
            return false
        case moduleTypes.MODULE_FAILURE:
            return action.payload.error.Message || 'Module failed to load'
        default:
            return state
    }
}

const isMigrating = (state = false, action) => {
    switch (action.type) {
        case moduleTypes.TOGGLE_MIGRATION:
            return !state
        case moduleTypes.MODULE_SUCCESS:
        case moduleTypes.MODULE_2_SUCCESS:
        case moduleTypes.MODULE_FAILURE:
            return false
        default:
            return state
    }
}

const isPending = (state = initState.isPending, action) => {
    switch (action.type) {
        case moduleTypes.APP_MODULES_REQUEST:
        case moduleTypes.MODULE_CHANGE_REQUEST:
        case moduleTypes.MODULE_RELOAD_REQUEST:
            return true
        case moduleTypes.APP_MODULES_SUCCESS:
        case moduleTypes.MODULE_SUCCESS:
        case moduleTypes.MODULE_2_SUCCESS:
        case moduleTypes.MODULE_FAILURE:
        case moduleTypes.CLEAR_MODULES:
            return false
        default:
            return state
    }
}

const viewFlag = (state = initState.viewFlag, action) => {
    switch (action.type) {
        case moduleTypes.MODULE_2_SUCCESS:
            return action.payload.viewFlag || null
        default:
            return state
    }
}

export default combineReducers({
    current,
    all,
    error,
    isMigrating,
    isPending,
    viewFlag,
})
