import { HotKeys, createTip } from '../lib/hotkeys'
import { Box, Typography, Unstable_Grid2 as Grid } from '@mui/material';

export default function HotKeyDisplay() {
    return (
        <Box sx={{ width: 600 }}>
            <Typography variant="h5">Hotkeys</Typography>
            <Grid container gap={1}>
                {Object.entries(HotKeys).map(([key, value]) => {
                    return (
                        <Grid key={key} xs={3}>
                            <Typography variant="body1">
                                <strong>{key}</strong> - {createTip(value)}
                            </Typography>
                        </Grid>
                    )
                })}
            </Grid>
        </Box>
    )
}
