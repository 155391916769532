import { ListItem, ListItemText } from "@mui/material";
import { Criteria } from "genesis-suite/types/networkTypes";
import { getCriteriaSentence } from "./helpers";

interface CriteriaDisplayProps {
    criteria: Criteria
    index: number
}

export default function CriteriaDisplay({ criteria, index }: CriteriaDisplayProps) {
    return (
        <ListItem key={criteria.id} disablePadding>
            <ListItemText primary={getCriteriaSentence(criteria, index)} />
        </ListItem>
    )
}
