import { widgetConstants } from '../../../constants'

export const WIDGET_CONFIG_MAP = {
    Chart: {
        ConfigKey: 'ChartConfig',
        SubTypeKey: 'ChartType',
    },
    Table: {
        ConfigKey: 'TableConfig',
        SubTypeKey: null,
    },
    BubbleChart: {
        ConfigKey: 'BubbleChartConfig',
        SubTypeKey: null,
    },
    PnL: {
        ConfigKey: 'PnLConfig',
        SubTypeKey: null,
    },
    Map: {
        ConfigKey: 'MapConfig',
        SubTypeKey: 'MapViewType',
    },
    Label: {
        ConfigKey: 'LabelConfig',
        SubTypeKey: null,
    },
    Histogram: {
        ConfigKey: 'HistogramConfig',
        SubTypeKey: null,
    },
    WebView: {
        ConfigKey: 'WebViewConfig',
        SubTypeKey: null,
    },
    TwoByTwo: {
        ConfigKey: 'TwoByTwoConfig',
        SubTypeKey: null,
    },
    Gauge: {
        ConfigKey: 'GaugeConfig',
        SubTypeKey: 'GaugeType',
    },
    Flow: {
        ConfigKey: 'FlowConfig',
        SubTypeKey: 'FlowType',
    },
    Bullet: {
        ConfigKey: 'BulletConfig',
        SubTypeKey: null,
    },
    Heatmap: {
        ConfigKey: 'HeatmapConfig',
        SubTypeKey: null,
    },
    Treemap: {
        ConfigKey: 'TreemapConfig',
        SubTypeKey: null,
    },
    Form: {
        ConfigKey: 'FormConfig',
        SubTypeKey: null,
    },
    Iris: {
        ConfigKey: 'IrisConfig',
        SubTypeKey: null,
    },
    RichText: {
        ConfigKey: 'RichTextConfig',
        SubTypeKey: null,
    },
    XRange: {
        ConfigKey: 'XRangeConfig',
        SubTypeKey: null,
    },
    StatusTracker: {
        ConfigKey: 'StatusTrackerConfig',
        SubTypeKey: null,
    },
    ReportWidget: {
        ConfigKey: 'ReportWidgetConfig',
        SubTypeKey: null,
    },
    Attachment: {
        ConfigKey: 'AttachmentConfig',
        SubTypeKey: null,
    },
    Gantt: {
        ConfigKey: 'GanttConfig',
        SubTypeKey: null,
    },
    TreeGraph: {
        ConfigKey: 'TreeGraphConfig',
        SubTypeKey: null,
    },
    [widgetConstants.Type.BUSINESS_ELEMENT]: {
        ConfigKey: null,
    },
}
