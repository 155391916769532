import { useSnackbar } from 'notistack'
import { FC, useContext, useState } from 'react'
import { useSelector } from 'react-redux'

import { AttachFileRounded } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import DownloadIcon from '@mui/icons-material/DownloadRounded'
import VisibilityRounded from '@mui/icons-material/VisibilityRounded'
import {
    Box,
    CircularProgress,
    ClickAwayListener,
    IconButton,
    Paper,
    Popper,
    Stack,
    styled,
    Tooltip,
    Typography,
} from '@mui/material'
import {
    Csv,
    Doc,
    Docx,
    Generic,
    Gif,
    Jpg,
    Pdf,
    Png,
    Ppt,
    Pptx,
    Svg,
    Txt,
    XlsFile,
    Xlsx,
} from 'genesis-suite/icons/files'
import { IFrameViewer } from '~/components/FileViewer/IFrameViewer'
import { getFileType } from '~/components/FileViewer/utils'
import { useIsMobile } from '../../../hooks/useIsMobile'
import { formsService } from '../../../lib/services'
import { applicationSelectors } from '../../../selectors'
import { FullScreenDialogContext } from '../../contexts/FullScreenDialogContext'
import { PopoutContext } from '../../contexts/PopoutWidgetContext'
import { getFileViewerDocs } from '../utils/getFileViewerDocs'
import FormButton from './FormButton'
interface AttachmentButtonProps {
    attachments: string[]
    onRemove?: (id: string) => void
}

const CustomPopper = styled(Popper)(() => ({
    zIndex: 2,
}))

const AttachmentButton: FC<AttachmentButtonProps> = ({ attachments = [], onRemove }) => {
    const isMobile = useIsMobile()
    const popOut: any = useContext(PopoutContext)
    const { dialogContent, handleClickOpen, dialogTitle } = useContext(FullScreenDialogContext)
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const { closeSnackbar, enqueueSnackbar } = useSnackbar()
    const errSnack = (msg: string) => enqueueSnackbar(msg, { variant: 'error' })
    const [filesInfo, setFilesInfo] = useState<any>([])
    const [loading, setLoading] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const [dialogData, setDialogData] = useState({})

    const onClickDialog = () => {
        setOpenDialog(!openDialog)
        setDialogData({})
    }

    const getFiles = async (files: string[]) => {
        let snackBarKey

        try {
            snackBarKey = enqueueSnackbar('Opening File Viewer...', { persist: true })
            const docs = await getFileViewerDocs({ fileIds: files, appName })
            return docs
        } catch (error) {
            errSnack(error)
        } finally {
            closeSnackbar(snackBarKey)
        }
    }

    const showFiles = async (e, files: string[]) => {
        e.stopPropagation()
        const docs = await getFiles(files)

        if (!docs) return

        // if (isMobile) {
        //     dialogTitle('File Viewer')
        //     dialogContent(<FileViewer docs={docs} isMobile={isMobile} />)
        //     handleClickOpen()
        //     return
        // }
        const file = [...filesInfo].find(item => item.Id == files)
        const data = {
            file: file,
            docs: docs,
        }

        setDialogData(data)
        setOpenDialog(true)
    }

    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const isPopperOpen = Boolean(anchorEl)

    const handleClick = async (event: React.MouseEvent<HTMLElement>) => {
        if (!open && filesInfo.length === 0) {
            setLoading(true)
            setAnchorEl(event.currentTarget)
            setOpen(previousOpen => !previousOpen)

            const meta = []

            try {
                for (let index = 0; index < attachments.length; index++) {
                    const data = await formsService.getAttachmentMeta(attachments[index], appName)
                    meta.push(data)
                }
                setFilesInfo(meta)
            } catch (error) {
                enqueueSnackbar(`An error occurred fetching files information.`, { variant: 'error' })
                setOpen(false)
                setFilesInfo([])
            } finally {
                setLoading(false)
            }
        } else {
            setAnchorEl(event.currentTarget)
            setOpen(previousOpen => !previousOpen)
        }
    }

    const handleClickAway = () => {
        setOpen(false)
    }

    const getAttachment = async (id: string) => {
        const key = enqueueSnackbar('Downloading file...', { persist: true })
        try {
            await formsService.getAttachment(id, appName)
        } catch (err) {
            errSnack('An error occurred downloading the attachment.')
        } finally {
            closeSnackbar(key)
        }
    }

    const removeAttachment = (e, id: string) => {
        e.stopPropagation()
        onRemove(id)
    }

    if (!attachments.length) return null
    else
        return (
            <>
                <ClickAwayListener onClickAway={handleClickAway}>
                    <Box>
                        <FormButton startIcon={<AttachFileRounded />} onClick={handleClick}>
                            {attachments.length} {attachments.length === 1 ? 'File' : 'Files'}
                        </FormButton>

                        {isPopperOpen ? (
                            <CustomPopper
                                open={open}
                                anchorEl={anchorEl}
                                disablePortal={false}
                                sx={{ zIndex: 2000 }}
                                className="ag-custom-component-popup"
                            >
                                <Paper elevation={4} sx={{ marginLeft: 0.5 }}>
                                    {loading ? (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                padding: 2,
                                                justifyContent: 'center',
                                                width: '250px',
                                            }}
                                        >
                                            <CircularProgress />
                                        </Box>
                                    ) : (
                                        <Stack
                                            maxHeight={195}
                                            overflow="auto"
                                            spacing={1}
                                            sx={{
                                                p: 1,
                                                zIndex: 2000,
                                            }}
                                        >
                                            {[...filesInfo]?.map(fileInfo => (
                                                <FileInfoItem
                                                    key={fileInfo.Id}
                                                    fileInfo={fileInfo}
                                                    showFiles={showFiles}
                                                    getAttachment={getAttachment}
                                                    removeAttachment={
                                                        onRemove ? e => removeAttachment(e, fileInfo.Id) : undefined
                                                    }
                                                />
                                            ))}
                                        </Stack>
                                    )}
                                </Paper>
                            </CustomPopper>
                        ) : null}
                    </Box>
                </ClickAwayListener>
                <IFrameViewer
                    openDialog={openDialog}
                    onClickDialog={onClickDialog}
                    data={dialogData}
                    isMobile={isMobile}
                />
            </>
        )
}

export interface AttachmentTypeIconProps {
    fileType: string
    style?: React.CSSProperties
}

export const AttachmentTypeIcon = ({ fileType, style = {} }: AttachmentTypeIconProps) => {
    const defaultSx = { height: '1.5em', width: '1.5em', alignSelf: 'flex-end' }
    const sx = { ...defaultSx, ...style }

    switch (fileType) {
        case 'pdf':
            return <Pdf sx={sx} />
        case 'doc':
            return <Doc sx={sx} />
        case 'docx':
            return <Docx sx={sx} />
        case 'xls':
            return <XlsFile sx={sx} />
        case 'xlsx':
            return <Xlsx sx={sx} />
        case 'excel':
            return <Xlsx sx={sx} />
        case 'ppt':
            return <Ppt sx={sx} />
        case 'pptx':
            return <Pptx sx={sx} />
        case 'txt':
            return <Txt sx={sx} />
        case 'csv':
            return <Csv sx={sx} />
        case 'jpg':
            return <Jpg sx={sx} />
        case 'jpeg':
            return <Jpg sx={sx} />
        case 'png':
            return <Png sx={sx} />
        case 'gif':
            return <Gif sx={sx} />
        case 'svg':
            return <Svg sx={sx} />
        default:
            return <Generic sx={sx} />
    }
}
interface FileInfoItemProps {
    fileInfo: any
    showFiles: (e, files: string[]) => void
    getAttachment: (id: string) => void
    removeAttachment?: (e, id: string) => void
}

export const FileInfoItem: FC<FileInfoItemProps> = ({ fileInfo, showFiles, getAttachment, removeAttachment }) => (
    <Box display="flex" justifyContent="space-between">
        <Tooltip title={`${fileInfo.Type} file`}>
            <Box display="flex">
                <AttachmentTypeIcon fileType={fileInfo.Type?.toLowerCase()} />
            </Box>
        </Tooltip>
        <Tooltip title={fileInfo.FullName}>
            <Typography width={150} alignSelf="center" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                {fileInfo.FullName}
            </Typography>
        </Tooltip>
        <Box display="flex">
            {(getFileType(fileInfo.MimeType) === 'image' || getFileType(fileInfo.MimeType) === 'pdf') && (
                <Tooltip title="View">
                    <IconButton onClick={e => showFiles(e, [fileInfo.Id])}>
                        <VisibilityRounded />
                    </IconButton>
                </Tooltip>
            )}
            <Tooltip title="Download">
                <IconButton onClick={() => getAttachment(fileInfo.Id)}>
                    <DownloadIcon />
                </IconButton>
            </Tooltip>
            {removeAttachment && (
                <Tooltip title="Delete">
                    <IconButton onClick={e => removeAttachment(e, fileInfo.Id)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Box>
    </Box>
)

export default AttachmentButton
