import { ChartType, InlineWidget as InlineWidgetConfig } from 'genesis-suite/types/visualTypes'
import CalculatorWidget from './CalculatorWidget'
import FavoritesWidget from './FavoritesWidget'
import ModuleSelectorWidget from './module_selector/ModuleSelectorWidget'
import NodeLocationsMapWidget from './NodeLocationsMapWidget'
import SearchWidget from './SearchWidget'
import WidgetRecommendations from '../widgets/WidgetRecommendations'

interface Props {
    config: InlineWidgetConfig
}

export default function InlineWidget({ config }: Props) {
    switch (config.type) {
        case ChartType.CALCULATOR:
            return <CalculatorWidget config={config} />
        case ChartType.SPACIAL_MAP:
            return <NodeLocationsMapWidget config={config} />
        case ChartType.FAVORITES:
            return <FavoritesWidget config={config} />
        case ChartType.MODULE_SELECTOR:
            return <ModuleSelectorWidget config={config} />
        case ChartType.SEARCH:
            return <SearchWidget config={config} />
        case ChartType.RECOMMENDED_WIDGET:
            return <WidgetRecommendations />
    }
}
