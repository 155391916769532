import copperwireLogoLight from '../copperwire/copperwire-logo-light.svg'
import copperwireLogoDark from '../copperwire/copperwire-logo-dark.svg'
import copperwireLogoSmall from '../copperwire/copperwire-logo-small.svg'

export default {
    light: copperwireLogoLight,
    dark: copperwireLogoDark,
    smallLight: copperwireLogoSmall,
    smallDark: copperwireLogoSmall,
}
