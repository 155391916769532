import { useContext, useState, useEffect, useRef } from 'react'
import { Box, TextField, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import PyramidIcon from '@mui/icons-material/ExpandLessRounded'

import FunnelIcon from 'genesis-suite/icons/Filter'
import { ChartType, SortedSeries } from 'genesis-suite/types/visualTypes'
import LabeledToggleButton from '../../../LabeledToggleButton'
import { ConfigContext } from '../../ConfigContext'
import EditorWrapper from '../EditorWrapper'
import ChartFormatEditor from '../FormatEditor/ChartFormatEditor'
import SeriesDataLabelsEditor from './SeriesDataLabelsEditor'
import SeriesTooltipEditor from './SeriesTooltipEditor'
import SortEditor from '../SortEditor'
import AggregationSelect from '../../AggregationSelect'

const useStyles = makeStyles(({ spacing }) => ({
    group: { marginBottom: spacing() },
}))

type Type = SortedSeries['type']

export const typeOptions: { label: any; value: Type; tip: string }[] = [
    { label: <PyramidIcon />, value: 'pyramid', tip: 'Pyramid' },
    { label: <FunnelIcon />, value: 'funnel', tip: 'Funnel' },
]

export default function SeriesEditorFunnel() {
    const { config, dispatch, selectedField } = useContext(ConfigContext)
    const [draftFunnelNeckHeight, setDraftFunnelNeckHeight] = useState(0)

    const funnelNeckHeight = config.type === ChartType.SORTED && config.series?.[0].funnelNeckHeight

    const classes = useStyles()

    useEffect(() => {
        setDraftFunnelNeckHeight(funnelNeckHeight)
    }, [funnelNeckHeight])

    const timeout = useRef(null)
    useEffect(() => {
        clearTimeout(timeout.current)
        timeout.current = setTimeout(
            () => dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: { funnelNeckHeight: draftFunnelNeckHeight } }),
            200
        )
    }, [draftFunnelNeckHeight])

    if (config.type !== ChartType.SORTED) return null

    const activeSeries = config.series[selectedField.index]
    const { type } = activeSeries || {}

    return (
        <>
            <AggregationSelect />

            <div className={classes.group}>
                <Typography variant="caption">Type</Typography>
                <LabeledToggleButton
                    value={type || 'pyramid'}
                    label=""
                    onChange={(e, type) => dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: { type } })}
                    options={typeOptions}
                />
            </div>

            <div className={classes.group}>
                <Typography variant="caption">Sorting</Typography>
                <SortEditor pointer={selectedField} />
            </div>

            {type === 'funnel' && (
                <>
                    <Box mt={2} />
                    <TextField
                        type="number"
                        inputProps={{ min: '0', max: '100' }}
                        label="Neck height"
                        size="small"
                        variant="outlined"
                        value={draftFunnelNeckHeight ?? 25}
                        onChange={({ target }) => setDraftFunnelNeckHeight(parseInt(target.value))}
                    />
                </>
            )}

            <EditorWrapper header="Data labels">
                <SeriesDataLabelsEditor />
            </EditorWrapper>

            <ChartFormatEditor />

            <EditorWrapper header="Tooltip">
                <SeriesTooltipEditor />
            </EditorWrapper>
        </>
    )
}
