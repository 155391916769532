import React from 'react'
import { Typography } from '@mui/material'

export default function CollaborationDialog({ userName, perspectiveName, isPerspectiveInModule }) {
    return (
        <div>
            <Typography>
                <b>{userName}</b> wants to share <b>{perspectiveName}</b> with you.
            </Typography>

            {!isPerspectiveInModule && (
                <Typography color="error">This will navigate you to a different Module.</Typography>
            )}
        </div>
    )
}
