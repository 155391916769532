import { Box, Button, Typography } from '@mui/material'
import { buildRoute } from 'genesis-suite/utils'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { navigationCreators } from '../actions/creators'
import logo from '../assets/logos/tadanow/tada-logo-dark.svg'
import { routePaths } from '../lib/routes'
import { notificationService } from '../lib/services'

type NotificationError = 'missing-id' | 'bad-id'

export default function NotificationView() {
    const id = useId()
    const dispatch = useDispatch()
    const [status, setStatus] = useState<NotificationError>(null)

    useEffect(() => {
        if (!id) setStatus('missing-id')

        notificationService
            .getById(id)
            .then(body => {
                const { AppName, PortalName, PerspectiveId, Filters } = body
                const path = buildRoute(routePaths.PERSPECTIVE, AppName, PortalName, PerspectiveId)
                const context = buildContext(Filters, PerspectiveId)
                dispatch(navigationCreators.goToPath(path, { context }))
            })
            .catch(err => {
                console.error(err)
                setStatus('bad-id')
            })
    }, [id])

    const goToAppSelect = () => dispatch(navigationCreators.goToPath(routePaths.SELECT))

    switch (status) {
        case null:
            return null
        case 'missing-id':
            goToAppSelect()
            return null
        case 'bad-id':
            return (
                <Box
                    sx={{
                        alignItems: 'center',
                        backgroundColor: 'background.main',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 4,
                        margin: 'auto',
                        maxWidth: '400px',
                    }}
                >
                    <img alt="Tada logo" src={logo} style={{ height: '50px' }} />
                    <Typography variant="h3">No access</Typography>
                    <Typography>Please check the notification or reach out to your Tada admin for help.</Typography>
                    <Button variant="contained" onClick={goToAppSelect}>
                        Go to application
                    </Button>
                </Box>
            )
    }
}

function useId() {
    const splat = useParams()['*']
    const [id] = splat.split('/')
    return id
}

export function buildContext(filters, perspectiveId) {
    const [first, ...remaining] = filters
    const crumb = buildCrumb(first, perspectiveId)
    return { ...crumb, Filters: remaining }
}

function buildCrumb(filter, perspectiveId) {
    return {
        Name: filter.FilterName,
        FieldName: filter.PropertyName,
        Value: filter.Values[0],
        DefaultPerspective: perspectiveId,
    }
}
