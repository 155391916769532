import { MenuIcon } from 'genesis-suite/components'
import { Globe } from 'genesis-suite/icons'
import { connect, useDispatch } from 'react-redux'
import { appearanceCreators } from '~/actions/creators'
import { appearanceSelectors, filterSelectors } from '~/selectors'

function NeoClassicGlobalFiltersController(props) {
    const { disabled, filterCount, openFlag, iconStyle, buttonStyle } = props
    const dispatch = useDispatch()

    if (disabled) return null

    return (
        <>
            <MenuIcon
                disabled={disabled}
                open={openFlag}
                onClick={() => dispatch(appearanceCreators.toggleNeoClassicGlobalFilters())}
                icon={<Globe sx={iconStyle} />}
                buttonProps={{ sx: buttonStyle, 'data-cy': 'global-filters-btn' }}
                title="Global Filters"
                tooltip="Global filters"
                badge={filterCount}
            ></MenuIcon>
        </>
    )
}

const mapStateToProps = state => {
    const globalFilters = filterSelectors.getGlobalFiltersConfig(state)
    const filterNames = globalFilters.map(filter => filter.Name)
    const filterCount = filterSelectors.getFilterSelectedValueCount(state, filterNames)
    const openFlag = appearanceSelectors.getShowGlobalFilters(state)

    return { disabled: !globalFilters.length, filterCount, openFlag }
}

export default connect(mapStateToProps)(NeoClassicGlobalFiltersController)
