import { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Checkbox, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'

import { Widget } from 'genesis-suite/types/visualTypes'
import Widget2 from '../widgets2/Widget2'
import { navigationCreators } from '../../actions/creators'
import { logEvent } from '../../lib/amplitudeClient'

let navigated = false

const useStyles = makeStyles(({ palette, spacing }) => ({
    widgetWrapper: {
        background: '#fff',
        position: 'relative',
        flex: 1,
        minHeight: '300px',
        margin: spacing(0, 1, 1),
        overflow: 'hidden',
        borderRadius: spacing(),
        border: (p: any) => `2px solid ${p.selected ? palette.primary.main : 'transparent'}`,
    },
    checkbox: { '& svg': { color: palette.primary.main } },
}))

interface Props {
    config: Widget
    selected: boolean
    onSelect?: () => void
    onEdit?: () => void
    onDelete?: () => void
}

export default function BusinessExplorerWidget({ config, selected, onSelect, onEdit, onDelete }: Props) {
    const dispatch = useDispatch()

    const classes = useStyles({ selected })
    const [loading, setLoading] = useState(true)

    const interactions = useMemo(
        () => ({
            onNavigate: (perspectiveId, filters) => {
                if (!navigated) {
                    navigated = true
                    logEvent('NAVIGATED_FROM_BUSINESS_EXPLORER')
                }

                dispatch(navigationCreators.goToDashboardWithFilters(perspectiveId, filters))
            },
            // onInlineFilter: inlineFilters => dispatch(filterCreators.setInlineFilters2(inlineFilters)),
        }),
        []
    )

    const handleData = useCallback(() => setLoading(false), [])

    return (
        <div className={classes.widgetWrapper}>
            <Widget2 config={config} interactions={interactions} onData={handleData} />

            <Box position="absolute" right="8px" top="8px" display="flex">
                {onSelect ? (
                    <Checkbox checked={selected} className={classes.checkbox} onClick={onSelect} />
                ) : (
                    !loading && (
                        <>
                            <IconButton size="small" onClick={onEdit}>
                                <EditIcon color="primary" fontSize="small" />
                            </IconButton>
                            <IconButton size="small" onClick={onDelete}>
                                <DeleteIcon color="primary" fontSize="small" />
                            </IconButton>
                        </>
                    )
                )}
            </Box>
        </div>
    )
}
