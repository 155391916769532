import { useState, useLayoutEffect } from 'react'
import { isEqual } from 'lodash'
import { Accordion, AccordionSummary, Typography, Tooltip } from '@mui/material'
import Measure from 'react-measure'
import { Responsive as GridLayout } from 'react-grid-layout'

export default function ReorderTabsDialogContent({ layout, setLayout, tabRows }) {
    const [width, setWidth] = useState(100)

    const defaultLayout = tabs => {
        return tabs?.map((item, index) => {
            const obj = {
                i: item.name.replace(/\s/g, ''),
                x: 0,
                y: index,
                w: 1,
                h: 1,
            }
            return obj
        })
    }

    const customLayout = tabs => {
        return tabs?.map((item, index) => {
            const obj = {
                i: item.name.replace(/\s/g, ''),
                x: 0,
                y: item.Order,
                w: 1,
                h: 1,
            }
            return obj
        })
    }

    useLayoutEffect(() => {
        let data = []
        if (tabRows?.[tabRows?.length - 1]?.Order) {
            data = customLayout(tabRows)
        } else {
            data = defaultLayout(tabRows)
        }
        if (isEqual(layout, data)) return
        setLayout(data)
    }, [])

    const onChangeLayout = (_layout, layouts) => {
        const updated = layouts?.lg?.map(({ i, x, y, w, h }) => ({ i, x, y, w, h }))
        if (isEqual(layouts.lg, updated)) return
        setLayout(updated)
    }

    return (
        <Measure
            client
            onResize={({ client }) => {
                setWidth(client.width - 1)
            }}
        >
            {({ measureRef }) => (
                <div ref={measureRef}>
                    <GridLayout
                        layouts={{ lg: layout }}
                        compactType={'vertical'}
                        breakpoints={{ lg: 1200 }}
                        cols={{ lg: 1 }}
                        rowHeight={50}
                        isDraggable={true}
                        isResizable={false}
                        width={width}
                        onLayoutChange={onChangeLayout}
                    >
                        {tabRows.map((item, key) => (
                            <div key={item.name.replace(/\s/g, '')}>
                                <Accordion expanded={false}>
                                    <Tooltip title="Drag and Drop to Change the Order">
                                        <AccordionSummary id="panel2-header">
                                            <Typography variant="h6" style={{ fontWeight: '600' }}>
                                                {item.name}
                                            </Typography>
                                        </AccordionSummary>
                                    </Tooltip>
                                </Accordion>
                            </div>
                        ))}
                    </GridLayout>
                </div>
            )}
        </Measure>
    )
}
