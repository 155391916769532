import { createSelector } from 'reselect'

const getAppName = s => s.insight.appName

const getList = s => s.insight.list ?? []

const getHasFetched = s => s.insight.list || s.insight.isPending

const getIsPending = s => s.insight.isPending

const getFocalPoints = createSelector(getList, insights => {
    let focalPoints = new Set()
    for (let { nodeNames } of insights) {
        for (let name of nodeNames) {
            focalPoints.add(name)
        }
    }
    return Array.from(focalPoints).sort()
})

const getBareList = s => {
    if (s.insight.list) {
        return s.insight.list.map(i => ({ id: i.id, name: i.name }))
    } else return []
}

const getInsightsWithFocalPoint = (s, focalPoint) => {
    if (!focalPoint) return []
    const list = getList(s)
    return list.filter(i => i.nodeNames.includes(focalPoint)).map(i => ({ id: i.id, name: i.name }))
}

export const insightSelectors = {
    getAppName,
    getList,
    getHasFetched,
    getIsPending,
    getFocalPoints,
    getBareList,
    getInsightsWithFocalPoint,
}
