export enum ComparisonOperatorAgGrid {
    CONTAINS = 'contains',
    NOT_CONTAINS = 'notContains',
    EQUAL = 'equals',
    NOT_EQUAL = 'notEqual',
    STARTS_WITH = 'startsWith',
    ENDS_WITH = 'endsWith',
    GREATER_THAN = 'greaterThan',
    LESS_THAN = 'lessThan',
    LESS_THAN_OR_EQUAL = 'lessThanOrEqual',
    GREATER_THAN_OR_EQUAL = 'greaterThanOrEqual',
    IN_RANGE = 'inRange',
}
