import { Collapse, FormControlLabel, Switch } from '@mui/material'
import produce from 'immer'
import { useContext, useEffect, useState } from 'react'

import { letMeMap } from 'genesis-suite/types/utilTypes'
import { Aggregation, TableConfig } from 'genesis-suite/types/visualTypes'
import { ConfigContext } from '../ConfigContext'
import { getDefaultAggregation } from '../utils'
import CategorySelector from './CategorySelector'
import SubSeriesSelector from './SubSeriesSelector'

export default function TableAggregationSelector() {
    const { config, dispatch, resources } = useContext(ConfigContext)
    const [aggregate, setAggregate] = useState(false)

    const properties = resources.byId[resources.selectedId]?.properties

    useEffect(() => {
        const seriesHasAggregates = letMeMap(config.series).some(s =>
            s.values.some(v => v.aggregation !== Aggregation.NONE)
        )
        setAggregate(Boolean(config?.categories?.length) || seriesHasAggregates)
    }, [config])

    function handleChange() {
        if (!config.series?.length) return setAggregate(s => !s)

        let updated: TableConfig
        if (aggregate) {
            updated = produce(config as TableConfig, draft => {
                draft.categories = []
                draft.series = letMeMap(draft.series).map(s => {
                    delete s.subSeries
                    const values = letMeMap(s.values).map(v => ({ ...v, aggregation: Aggregation.NONE }))
                    return { ...s, values }
                })
                draft.collapsable = false
            })
        } else {
            updated = produce(config as TableConfig, draft => {
                draft.series[0].values = letMeMap(draft.series[0].values).map(v => {
                    const property = properties.find(p => p.name === v.field.name)
                    const aggregation = getDefaultAggregation(property?.semanticType)
                    return { ...v, aggregation }
                })
            })
        }
        dispatch({ type: 'UPDATE_CONFIG', payload: updated })
    }

    return (
        <>
            <FormControlLabel control={<Switch checked={aggregate} onChange={handleChange} />} label="Aggregate" />

            <Collapse key={3} in={aggregate}>
                <CategorySelector label="Category Columns" />
                <SubSeriesSelector label="Pivot" />
            </Collapse>
        </>
    )
}
