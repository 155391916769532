import React, { useContext } from 'react'
import { Box, MenuItem, TextField, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import LineIcon from '@mui/icons-material/ShowChart'
import BarIcon from '@mui/icons-material/BarChartRounded'
import AreaIcon from '@mui/icons-material/NetworkCell'

import { DebouncedTextField } from 'genesis-suite/components'
import { ColumnSeries, ComboConfig, LineSeries } from 'genesis-suite/types/visualTypes'
import LabeledToggleButton from '../../../LabeledToggleButton'
import EditorWrapper from '../EditorWrapper'
import SubSeriesSelector from '../../selectors/SubSeriesSelector'
import { ConfigContext } from '../../ConfigContext'
import ChartFormatEditor from '../FormatEditor/ChartFormatEditor'
import SeriesDataLabelsEditor from './SeriesDataLabelsEditor'
import SeriesTooltipEditor from './SeriesTooltipEditor'
import SortEditor from '../SortEditor'
import FilterSelector from '../../selectors/FilterSelector'
import AggregationSelect from '../../AggregationSelect'

const useStyles = makeStyles(({ spacing }) => ({
    group: { marginBottom: spacing() },
    stackingInput: { width: '85px' },
}))

type Shape = LineSeries['shape'] | ColumnSeries['type']

const comboOptions: Array<{ label: any; value: Shape; tip: string }> = [
    { label: <BarIcon />, value: 'column', tip: 'Bar' },
    { label: <LineIcon />, value: 'spline', tip: 'Smooth' },
    { label: <LineIcon />, value: 'line', tip: 'Straight' },
    { label: <AreaIcon />, value: 'area', tip: 'Area' },
]

const NO_STACK = 'None'

export default function SeriesEditorCombo() {
    const { selectedField, dispatch, ...rest } = useContext(ConfigContext)
    const config = rest.config as ComboConfig
    const classes = useStyles()

    const activeSeries = config.series[selectedField.index]
    let type: Shape = 'column'
    if (activeSeries?.type === 'line') type = activeSeries.shape || 'spline'

    function handleTypeChange(e, val: Shape) {
        const type = val === 'column' ? 'column' : 'line'
        updateActiveSeries({ type, ...(val !== 'column' && { shape: val }) })
    }

    function handleStackChange(e) {
        const v = e.target.value
        updateActiveSeries({ stack: v === NO_STACK ? undefined : v })
    }

    function updateActiveSeries(payload: Partial<LineSeries | ColumnSeries>) {
        dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload })
    }

    return (
        <>
            <AggregationSelect />

            <div className={classes.group}>
                <Typography variant="caption">Type</Typography>
                <LabeledToggleButton value={type} label="" onChange={handleTypeChange} options={comboOptions} />
            </div>

            <FilterSelector />

            <div className={classes.group}>
                <Typography variant="caption">Stacking</Typography>
                <Box display="flex" alignItems="center" gap="8px">
                    <TextField
                        variant="outlined"
                        size="small"
                        select
                        className={classes.stackingInput}
                        value={activeSeries?.stack || NO_STACK}
                        onChange={handleStackChange}
                    >
                        {[NO_STACK, ...getAxisStacks(config.series)].map(s => (
                            <MenuItem key={s} value={s}>
                                {s}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        variant="outlined"
                        size="small"
                        select
                        value={config.stackType || 'normal'}
                        onChange={e =>
                            dispatch({
                                type: 'UPDATE_CONFIG',
                                payload: { stackType: e.target.value as 'normal' | 'percent' },
                            })
                        }
                    >
                        {['normal', 'percent'].map(s => (
                            <MenuItem key={s} value={s}>
                                {s}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
            </div>

            <div className={classes.group}>
                <Typography variant="caption">Sorting</Typography>
                <SortEditor pointer={selectedField} />
            </div>

            <div className={classes.group}>
                <SubSeriesSelector label="Sub series" />
            </div>

            {!activeSeries?.subSeries && (
                <Box py={1}>
                    <DebouncedTextField
                        variant="outlined"
                        size="small"
                        label="Legend title"
                        fullWidth
                        value={activeSeries?.title ?? activeSeries?.values?.[0].field.name ?? ''}
                        onChange={title => updateActiveSeries({ title })}
                    />
                </Box>
            )}

            <EditorWrapper header="Data labels">
                <SeriesDataLabelsEditor />
            </EditorWrapper>

            <ChartFormatEditor />

            <EditorWrapper header="Tooltip">
                <SeriesTooltipEditor />
            </EditorWrapper>
        </>
    )
}

/** Return an array of stack numbers including one extra */
function getAxisStacks(seriesConfig: Array<LineSeries | ColumnSeries>) {
    const maxStack = seriesConfig.reduce((acc, cur) => Math.max(acc, cur.stack || 0), 0 as number)
    return new Array(maxStack + 1).fill(null).map((v, i) => i + 1)
}
