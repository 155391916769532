import { Button, Divider } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import Spinner from 'genesis-suite/components/Spinner'
import produce from 'immer'
import { useSnackbar } from 'notistack'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { authCreators } from '../../../actions/creators'
import { snackbarConfigs } from '../../../lib/snackbarConfigs'
import { authSelectors, moduleSelectors } from '../../../selectors'
import AutoLaunchPrefs from './AutoLaunchPref'
import AxisSliderToggle from './AxisSliderToggle'
import DefaultModulePref from './DefaultModulePref'
import GlobalFilterPrefs from './GlobalFilterPrefs'
import MultiEditToggle from './MultiEditToggle'
import PerspectiveFilterPrefs from './PerspectiveFilterPrefs'

const styles = theme => ({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        padding: theme.spacing(1),
    },
    section: {
        display: 'flex',
        alignItems: 'center',
    },
    divider: {
        margin: theme.spacing(1, 0),
        height: 2,
        backgroundColor: theme.palette.border?.main,
        borderRadius: 1,
    },
})

const formatFilter = filter => ({
    ...filter,
    perspectiveId: filter.Key.split(/-(.+)/)[1],
    Value: JSON.parse(filter.Value),
})

const prefKeys = {
    DEFAULT_MODULE: 'defaultModule',
    GLOBAL_FILTERS: 'GlobalFilters',
    PERSPECTIVE_FILTER: 'PerspectiveFilter',
    AUTO_LAUNCH: 'AutoShowPerspective',
    FORM_MULTI_EDIT: 'formsMultiEditEnabled',
    Y_AXIS_SLIDER: 'dynamicYAxisRangeEnabled',
}

const ManagePreferences = ({ classes, updateSaveHandle, updateCancelHandle }) => {
    const preferences = useSelector(authSelectors.getUserPreferences)
    const moduleId = useSelector(moduleSelectors.getModuleId)
    const dispatch = useDispatch()
    const savePreferences = prefs => dispatch(authCreators.saveUserPreferencesForCurrentApplication(prefs))

    const [formattedPrefs, setFormattedPrefs] = useState([])
    const [stagedRemoves, setStagedRemoves] = useState({})
    const [pending, setPending] = useState(false)
    const { enqueueSnackbar: showSnackbar } = useSnackbar()

    const isModuleAutoLaunch = pref => pref.Key.includes(prefKeys.AUTO_LAUNCH) && pref.VisorId === moduleId

    useEffect(() => {
        if (preferences) {
            setFormattedPrefs(() => {
                return preferences.reduce((acc, pref) => {
                    const { Key } = pref
                    if (!Key) return acc
                    if (Key.includes(prefKeys.DEFAULT_MODULE)) {
                        acc.push(pref)
                    } else if (Key.includes(prefKeys.GLOBAL_FILTERS) || Key.includes(prefKeys.PERSPECTIVE_FILTER)) {
                        acc.push(formatFilter(pref))
                    } else if (
                        isModuleAutoLaunch(pref) ||
                        Key.includes(prefKeys.FORM_MULTI_EDIT) ||
                        Key.includes(prefKeys.Y_AXIS_SLIDER)
                    ) {
                        acc.push({ ...pref, Value: JSON.parse(pref.Value) })
                    }
                    return acc
                }, [])
            })
            initStagedRemovals()
        } else {
            setFormattedPrefs([])
            setStagedRemoves({})
        }
    }, [preferences])

    const isFormMultiEditEnabled = useMemo(() => {
        const pref = formattedPrefs.find(pref => pref.Key === prefKeys.FORM_MULTI_EDIT)
        return pref?.Value ?? false
    }, [formattedPrefs])

    const isYAxisRangeEnabled = useMemo(() => {
        const pref = formattedPrefs.find(pref => pref.Key === prefKeys.Y_AXIS_SLIDER)
        return pref?.Value ?? false
    }, [formattedPrefs])

    const initStagedRemovals = () => {
        setStagedRemoves({
            [prefKeys.DEFAULT_MODULE]: false,
            [prefKeys.AUTO_LAUNCH]: false,
            [prefKeys.FORM_MULTI_EDIT]: false,
            [prefKeys.Y_AXIS_SLIDER]: false,
            ...preferences.reduce((acc, pref) => {
                const { Key } = pref
                if (Key.includes(prefKeys.GLOBAL_FILTERS) || Key.includes(prefKeys.PERSPECTIVE_FILTER)) {
                    acc[pref.Key] = JSON.parse(pref.Value)?.reduce((acc, val) => {
                        acc[val.FilterName] = false
                        return acc
                    }, {})
                }
                return acc
            }, {}),
        })
    }

    const handleDefaultModule = () => {
        const staged = stagedRemoves[prefKeys.DEFAULT_MODULE]
        setStagedRemoves({ ...stagedRemoves, [prefKeys.DEFAULT_MODULE]: !staged })
    }

    const handleAutoLaunchModule = () => {
        const staged = stagedRemoves[prefKeys.AUTO_LAUNCH]
        setStagedRemoves({ ...stagedRemoves, [prefKeys.AUTO_LAUNCH]: !staged })
    }

    const handleFilterChange = (key, filterName) => {
        const staged = stagedRemoves[key][filterName]
        setStagedRemoves({
            ...stagedRemoves,
            [key]: {
                ...stagedRemoves[key],
                [filterName]: !staged,
            },
        })
    }

    const handleToggleChange = (value, key) => {
        const staged = stagedRemoves[key]
        setStagedRemoves({ ...stagedRemoves, [key]: !staged })
        const existingPref = formattedPrefs.find(pref => pref.Key === key)
        if (!existingPref) {
            setFormattedPrefs([
                ...formattedPrefs,
                ...[
                    {
                        Key: key,
                        Value: value,
                    },
                ],
            ])
        } else {
            setFormattedPrefs(formattedPrefs.map(pref => (pref.Key === key ? { ...pref, Value: value } : pref)))
        }
    }

    const submitPrefs = () => {
        const updatedPrefs = produce(formattedPrefs, draft => {
            draft.forEach(pref => {
                const { Key } = pref
                if (Key === prefKeys.DEFAULT_MODULE && stagedRemoves[prefKeys.DEFAULT_MODULE]) pref.Value = null
                if (isModuleAutoLaunch(pref) && stagedRemoves[prefKeys.AUTO_LAUNCH]) pref.Value = null
                if (Key.includes(prefKeys.GLOBAL_FILTERS) || Key.includes(prefKeys.PERSPECTIVE_FILTER)) {
                    const notRemoved = pref.Value.filter(filter => !stagedRemoves[Key][filter.FilterName])
                    if (notRemoved.length === 0) pref.Value = null
                    else pref.Value = JSON.stringify(notRemoved)
                }
            })
        })
        setPending(true)
        savePreferences(updatedPrefs)
            .then(() => {
                setPending(false)
                showSnackbar('Successfully saved Account Preferences!', snackbarConfigs.success)
            })
            .catch(error => {
                console.error(error)
                setPending(false)
                showSnackbar(
                    'An error occurred saving your Account Preferences. Please try again.',
                    snackbarConfigs.error
                )
            })
    }

    const modulePref = formattedPrefs.find(({ Key }) => Key === prefKeys.DEFAULT_MODULE)
    const globalFilterPref = formattedPrefs.filter(
        ({ Key, VisorId }) => Key === prefKeys.GLOBAL_FILTERS && VisorId === moduleId
    )
    const persFilterPrefArr = formattedPrefs
        .filter(({ Key }) => Key.includes(prefKeys.PERSPECTIVE_FILTER))
        .filter((val, i) => formattedPrefs.indexOf(val) === i)
    const autoLaunchPref = formattedPrefs.find(({ Key }) => Key === prefKeys.AUTO_LAUNCH)

    const removeIsStaged = Object.values(stagedRemoves).some(val => {
        if (typeof val === 'object') return Object.values(val).some(subVal => subVal)
        else if (typeof val === 'boolean') return val
        else return false
    })

    useEffect(() => {
        if (!removeIsStaged || pending) {
            updateSaveHandle()
            updateCancelHandle()
        } else {
            updateSaveHandle({ label: 'Save', onClick: submitPrefs })
            updateCancelHandle(initStagedRemovals)
        }
    }, [removeIsStaged, pending, stagedRemoves])

    const clearSavedFilters = () => {
        savePreferences([
            {
                id: null,
                key: 'savedFilters',
                value: null,
            },
        ])
    }

    const clearChartPrefs = () => {
        savePreferences([
            {
                id: null,
                key: 'chartPrefs',
                value: null,
            },
        ])
    }

    return (
        <>
            <div className={classes.container}>
                <Spinner show={pending}>
                    <DefaultModulePref
                        className={classes.section}
                        appModule={modulePref && modulePref.Value}
                        removed={stagedRemoves[prefKeys.DEFAULT_MODULE]}
                        onChange={handleDefaultModule}
                    />
                    <Divider className={classes.divider} />
                    <AutoLaunchPrefs
                        className={classes.section}
                        preference={autoLaunchPref}
                        removed={stagedRemoves[prefKeys.AUTO_LAUNCH]}
                        onChange={handleAutoLaunchModule}
                    />
                    <Divider className={classes.divider} />
                    <GlobalFilterPrefs
                        className={classes.section}
                        preferences={globalFilterPref}
                        stagedRemoves={stagedRemoves}
                        onChange={handleFilterChange}
                    />
                    <Divider className={classes.divider} />
                    <PerspectiveFilterPrefs
                        className={classes.section}
                        preferences={persFilterPrefArr}
                        stagedRemoves={stagedRemoves}
                        onChange={handleFilterChange}
                    />
                    <Divider className={classes.divider} />
                    <MultiEditToggle
                        className={classes.section}
                        value={isFormMultiEditEnabled}
                        onChange={value => handleToggleChange(value, prefKeys.FORM_MULTI_EDIT)}
                    />
                    <AxisSliderToggle
                        className={classes.section}
                        value={isYAxisRangeEnabled}
                        onChange={value => handleToggleChange(value, prefKeys.Y_AXIS_SLIDER)}
                    />
                    <Divider className={classes.divider} />
                    <Button sx={{ display: 'none' }} onClick={clearSavedFilters}>
                        Clear Saved Filtes
                    </Button>
                    <Button sx={{ display: 'none' }} onClick={clearChartPrefs}>
                        Clear Chart Prefs
                    </Button>
                </Spinner>
            </div>
        </>
    )
}

export default withStyles(styles)(ManagePreferences)
