import useSWR, { SWRConfiguration } from 'swr'
import { architectureService } from '../lib/services'

const swrOptions: SWRConfiguration = {
    revalidateOnFocus: false,
    errorRetryCount: 2,
    dedupingInterval: 60000,
}

export default function useArchitecture(appName: string) {
    const { data } = useSWR(
        ['architecture', appName],
        ([_, appName]) => architectureService.getNetwork(appName),
        swrOptions
    )

    return data
}
