import { AnalyticsRounded } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import { useHotKey } from 'genesis-suite/hooks'
import { useDispatch } from 'react-redux'
import { navigationCreators } from '../actions/creators'
import { routePaths } from '../lib/routes'
import { HotKeys, createTip } from '../lib/hotkeys'

export default function ForecastButton({ iconStyle, buttonStyle }) {
    const dispatch = useDispatch()
    const goToAnalytics = () => dispatch(navigationCreators.goTo(routePaths.ANALYTICS))
    
    useHotKey(HotKeys.Forecast, goToAnalytics)

    return (
        <Tooltip title={`Predictive Forecast ${createTip(HotKeys.Forecast)}`}>
            <IconButton size="small" sx={buttonStyle} onClick={goToAnalytics}>
                <AnalyticsRounded sx={iconStyle} />
            </IconButton>
        </Tooltip>
    )
}
