import React, { useRef, useState } from 'react'
import { Popover } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ColorPicker from 'react-color/lib/Sketch'
import useWidgetColors from '../../../hooks/useWidgetColors'

const useStyles = makeStyles(({ border, palette, spacing, shadows }) => ({
    colorButton: {
        height: '100%',
        padding: spacing(0.5),
        background: '#fff',
        borderRadius: border.radius.round,
        boxShadow: shadows['1'],
        cursor: 'pointer',
    },
    color: { height: '100%', borderRadius: border.radius.round },
}))

interface Props {
    /** hex color value */
    value: string
    onChange: (val:{ hex: string }) => void
}

export default function WidgetColorPicker({ value, onChange }: Props) {
    const widgetColors = useWidgetColors()
    const [open, setOpen] = useState(false)
    const buttonRef = useRef(null)
    const classes = useStyles()

    return (
        <>
            <div ref={buttonRef} className={classes.colorButton} onClick={() => setOpen(true)}>
                <div className={classes.color} style={{ background: value }} />
            </div>

            <Popover
                open={open}
                anchorEl={buttonRef.current}
                onClose={() => setOpen(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                <ColorPicker
                    disableAlpha
                    presetColors={widgetColors.map(c => ({ title: 'test', color: c }))}
                    width={150}
                    color={value}
                    onChangeComplete={onChange}
                />
            </Popover>
        </>
    )
}
