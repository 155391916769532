import { filterCreators } from '~/actions/creators'
import { applicationTypes } from '../actions/types'

const initialState = {
    appliedIds: [],
    appliedCompareIds: [],
    appliedIdsbyPerspectiveId: {},
}

const types = {
    NETWORK_FILTER_APPLY: 'NETWORK_FILTER_APPLY',
    NETWORK_FILTER_APPLY_COMPARE: 'NETWORK_FILTER_APPLY_COMPARE',
    NETWORK_FILTER_PERSPECTIVE_APPLY: 'NETWORK_FILTER_PERSPECTIVE_APPLY',
}

const actions = {
    apply: ids => ({
        type: types.NETWORK_FILTER_APPLY,
        payload: ids,
    }),
    applyCompare: ids => ({
        type: types.NETWORK_FILTER_APPLY_COMPARE,
        payload: ids,
    }),
    applyForPerspective: (ids, perspectiveId) => ({
        type: types.NETWORK_FILTER_PERSPECTIVE_APPLY,
        payload: { ids, perspectiveId },
    }),
    saveDefaultNetworkFilters: (ids, perspectiveId, saveForAll) => dispatch => {
        return dispatch(filterCreators.saveDefaultNetworkFilters(ids, perspectiveId, saveForAll))
    },
}

const selectors = {
    getAppliedIds: state => state.networkFilters.appliedIds,
    getAppliedCompareIds: state => state.networkFilters.appliedCompareIds,
    getAppliedIdsForPerspective: (state, perspectiveId) =>
        state.networkFilters.appliedIdsbyPerspectiveId[perspectiveId],
}

export default function networkFilterReducer(state = initialState, action) {
    switch (action.type) {
        case types.NETWORK_FILTER_APPLY:
            return {
                ...state,
                appliedIds: action.payload,
            }
        case types.NETWORK_FILTER_APPLY_COMPARE:
            return {
                ...state,
                appliedCompareIds: action.payload,
            }
        case types.NETWORK_FILTER_PERSPECTIVE_APPLY:
            return {
                ...state,
                appliedIdsbyPerspectiveId: {
                    ...state.appliedIdsbyPerspectiveId,
                    [action.payload.perspectiveId]: action.payload.ids,
                },
            }
        case applicationTypes.APPLICATION_SELECT:
            return initialState
        default:
            return state
    }
}
export { types as networkFilterTypes, actions as networkFilterActions, selectors as networkFilterSelectors }
