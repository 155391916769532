import { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react'

import { VisualType, Publish } from 'genesis-suite/types/visualTypes'

interface PublishContextProps {
    publish: Publish
    selectedChange: SelectedChange
    setPublish: Dispatch<SetStateAction<Publish>>
    setSelectedChange: Dispatch<SetStateAction<SelectedChange>>
}

interface SelectedChange {
    type: VisualType
    id?: string
}

const PublishContext = createContext<Partial<PublishContextProps>>({})

function PublishProvider({ children }) {
    const [publish, setPublish] = useState<Publish>()
    const [selectedChange, setSelectedChange] = useState<SelectedChange>(null)

    useEffect(() => {
        if (!publish) return

        const { dashboard, widget } = publish.counts
        const type = dashboard.total ? VisualType.DASHBOARD : widget.total ? VisualType.WIDGET : VisualType.MODULE
        setSelectedChange({ type })
    }, [publish])

    return (
        <PublishContext.Provider value={{ publish, selectedChange, setPublish, setSelectedChange }}>
            {children}
        </PublishContext.Provider>
    )
}

export { PublishContext, PublishProvider }
