import { useSnackbar } from 'notistack'
import { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { isEmpty } from 'lodash'
import { ColOptionsContext } from '~/components/contexts/ColumnOptionsContext'
import { PerspectiveContext } from '~/components/contexts/PerspectiveContext'
import { appearanceCreators } from '../../../actions/creators'
import { logEvent } from '../../../lib/amplitudeClient'
import { widgetService } from '../../../lib/services'
import { createFetchConfig } from '../../../lib/utils'
import { applicationSelectors, authSelectors, filterSelectors, moduleSelectors } from '../../../selectors'

// { config, icon, name, type = 'Excel', onClick, iconClass, textClasses })
export default function useWidgetExport(config, type = 'Excel') {
    const dispatch = useDispatch()

    const accessKey = useSelector(authSelectors.getAccessKey)
    const networkContext = useSelector(filterSelectors.getCoord)
    const cloudName = useSelector(moduleSelectors.getModuleCloud)
    const inlineFilters = useSelector(filterSelectors.getInlineFilters)
    const modelName = useSelector(applicationSelectors.getCurrentAppName)
    const filters = useSelector(filterSelectors.currentFiltersConfiguration)
    const { filterByWidgetId } = useContext(PerspectiveContext)
    const { options } = useContext(ColOptionsContext) || {}

    const { enqueueSnackbar: showSnackbar } = useSnackbar()

    const exportWidget = async () => {
        logEvent('EXCEL_EXPORT')
        if (typeof config !== 'object') {
            console.error('Config is not an object')
            showSnackbar('An error occurred exporting the table.', { variant: 'error' })
            return
        }

        let widgetConfig = { ...config }

        if (widgetConfig?.TableConfig?.Fields) {
            const sortOrders = {}
            options?.forEach(opt => {
                if (opt?.sort) {
                    const field = widgetConfig.TableConfig.Fields.find(field => field.HeaderName === opt.colId)
                    if (field?.FieldName) sortOrders[field.FieldName] = opt.sort === 'asc' ? 'Ascending' : 'Descending'
                }
            })

            if (!isEmpty(sortOrders))
                widgetConfig = { ...widgetConfig, TableConfig: { ...widgetConfig.TableConfig, SortOrders: sortOrders } }
        }

        const combined = createFetchConfig(
            widgetConfig,
            networkContext,
            filters,
            inlineFilters,
            filterByWidgetId?.[config.Id]
        )
        combined.Source = {}
        combined.Source.BaseUrl = window.TADA_APIS.INTEGRATION
        combined.Source.AccessKey = accessKey
        combined.Source.Model = modelName

        showSnackbar('Exporting table, please wait...', { variant: 'info' })
        dispatch(appearanceCreators.toggleLoader())
        widgetService
            .export({
                Config: JSON.stringify(combined),
                Model: modelName,
                Cloud: cloudName,
                Name: config.Title,
                ExportType: type,
            })
            .then(response => {
                window.location = `${response}&accessKey=${accessKey}`
            })
            .catch(() => showSnackbar('An error occurred exporting the table.', { variant: 'error' }))
            .finally(() => dispatch(appearanceCreators.toggleLoader()))
    }

    return { exportWidget }
}
