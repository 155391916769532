import { Dispatch, SetStateAction, createContext, useState } from 'react'

type ContextProps = {
    tabRows: any
    tabValue: any
    openDialog: any
    setTabRows: Dispatch<SetStateAction<any>>
    setTabValue: Dispatch<SetStateAction<any>>
    setOpenDialog: Dispatch<SetStateAction<any>>
}

const LayoutPerspectiveContext = createContext<Partial<ContextProps>>({})

function LayoutPerspectiveProvider({ children }) {
    const [tabRows, setTabRows] = useState([])
    const [tabValue, setTabValue] = useState(0)
    const [openDialog, setOpenDialog] = useState({ data: '', open: false })

    return (
        <LayoutPerspectiveContext.Provider
            value={{
                tabRows,
                tabValue,
                openDialog,
                setTabRows,
                setTabValue,
                setOpenDialog,
            }}
        >
            {children}
        </LayoutPerspectiveContext.Provider>
    )
}

export { LayoutPerspectiveContext, LayoutPerspectiveProvider }
