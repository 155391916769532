import { SemanticType } from 'genesis-suite/types/architectureTypes'
import { PropertyMetaWithSemantic, ResourceType } from 'genesis-suite/types/networkTypes'
import { Aggregation, DataResponse, TableColumn, TableConfig } from 'genesis-suite/types/visualTypes'
import { getPropertyMeta } from '../../../hooks/useProperties'
import { GroupNames } from '../../../types/WidgetTypes'
import isRawSeries from '../utils/isRawSeries'
import makeBaseDataRequest from '../utils/makeBaseDataRequest'
import makeConditionalRules from '../utils/makeConditionalRules'
import flattenData from './flattenData'
import { Column } from './renderTableTypes'

export default function makeTableRowsAndColumns(
    config: TableConfig,
    data: DataResponse,
    groupNames: GroupNames,
    properties: PropertyMetaWithSemantic[]
): { rows: Array<Array<string | number>>; columns: Array<Column> } {
    const { categories, series } = config
    const tableData = data[0].data
    const pivotColumnHeaders = groupNames?.bySeries?.[0] ?? []
    const isRaw = isRawSeries(series?.[0])
    const rows = isRaw ? tableData[0].rawData ?? [] : flattenData(tableData, config, pivotColumnHeaders)
    const dataRequest = makeBaseDataRequest(config)
    const seriesRequest = dataRequest.series[0]

    const { values, subSeries } = series?.[0] || {}

    const categoryColumns: Array<Column> =
        categories?.map(({ conditionalFormats, title, ...rest }) => {
            const { displayName } = getPropertyMeta(properties, rest.field) || {}
            return {
                ...rest,
                rules: makeConditionalRules(conditionalFormats, seriesRequest, categories.length),
                title: title || displayName || rest.field.name,
            }
        }) || []

    const metricColumns: Array<Column> = subSeries
        ? [
              ...(values.length > 1 ? [pivotDataColumn] : []),
              ...pivotColumnHeaders.map((title, i) => ({
                  field: subSeries?.field,
                  title,
                  justification: 'left' as const,
                  numberFormat: values[0]?.numberFormat,
                  rules: makeConditionalRules(values[0]?.conditionalFormats, seriesRequest, categories.length + i),
              })),
          ]
        : values?.map(({ align, conditionalFormats, title, ...rest }) => {
              const { displayName, semanticType } = getPropertyMeta(properties, rest.field) || {}
              return {
                  ...rest,
                  align: align ?? defaultAlign(semanticType, rest.aggregation),
                  rules: makeConditionalRules(conditionalFormats, seriesRequest, categories.length),
                  title: title || displayName || rest.field.name,
              }
          }) || []

    const columns = [...categoryColumns, ...metricColumns]

    return { rows, columns }
}

function defaultAlign(type: SemanticType, aggregation: Aggregation): TableColumn['align'] {
    if (aggregation !== Aggregation.NONE) return 'right'

    switch (type?.baseDataType) {
        case 'Binary':
        case 'Currency':
        case 'Decimal':
        case 'Integer':
        case 'Large Number':
        case 'Percentage':
        case 'Small Number':
        case 'True - False':
            return 'right'
    }

    return 'left'
}

const pivotDataColumn: Column = {
    title: 'Data',
    field: { resourceType: ResourceType.NODE, resourceId: '', resourceName: '', id: '', name: '', dataTypeId: null },
}
