import { isEmpty, isEqual } from 'lodash'
import { useSnackbar } from 'notistack'
import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Dashboard, DashboardDevice, DashboardNodeFilter, LayoutDetails, Widget } from 'genesis-suite/types/visualTypes'
import { Layout } from 'react-grid-layout'
import { filterCreators } from '../../../actions/creators'
import { logEvent } from '../../../lib/amplitudeClient'
import { updateDashboardOrCreateDraft } from '../../../lib/manageUtils'
import { applicationSelectors, deploymentSelectors, filterSelectors } from '../../../selectors'
import { FilterApplyMethod, FilterSourceType } from '../../../types/FilterTypes'
import { emptyDashboardFilterValues, filterHasValue, filtersMatch } from '../utils/contextFilterUtils'
import { getPositionFromLayout, makeLayout, makeWidgetsWithPosition, WidgetWithPosition } from './dashboardUtils'
import { DashboardAction } from './types'
import { useIsMobile } from '../../../hooks/useIsMobile'
import { useHomeDashboardContext } from './HomeDashboardContext'

interface Props {
    actions?: DashboardAction[]
    children: ReactNode
    config: Dashboard
    /** (default true) */
    editable?: boolean
    /** Request new widgets (add/remove widget) */
    getWidgets?: () => void
    widgets: Widget[]
}

type ContextProps = {
    actions?: DashboardAction[]
    config: Dashboard
    devicePreview: DashboardDevice
    editable: boolean
    editing: boolean
    filters: DashboardNodeFilter[]
    filterSource: FilterSourceType
    hiddenWidgets: WidgetWithPosition[]
    layout: LayoutDetails
    onEditStart: () => void
    onEditDone: (cancelChanges?: boolean, saveHomeDashboardForAll?: boolean) => Promise<void>
    /** Based on the current device, update the position of only the widgets passed */
    positionWidgets: (updated: Layout[]) => void
    setDevicePreview: Dispatch<SetStateAction<DashboardDevice>>
    showExistingWidgetsDialog: boolean
    toggleShowExistingWidgetsDialog: () => void
    updateConfig: (body: Partial<Dashboard>, save?: boolean) => void
    updateFilters: (filters: DashboardNodeFilter[], method: FilterApplyMethod) => Promise<void>
    widgets: WidgetWithPosition[]
}

const DashboardContext = createContext<Partial<ContextProps>>({})

/** State management for editing Series widgets (have a series property for data) */
const DashboardProvider = ({ actions, children, config: _config, editable = true, getWidgets, widgets }: Props) => {
    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const isMobile = useIsMobile()

    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const viewFlag = useSelector(deploymentSelectors.getDeploymentViewFlag)
    const dispatch = useDispatch()

    const [config, setConfig] = useState(_config)
    const [editing, setEditing] = useState(false)
    const [dirty, setDirty] = useState(false)
    const [devicePreview, setDevicePreview] = useState(DashboardDevice.LARGE)
    const [showExistingWidgetsDialog, setShowExistingWidgetsDialog] = useState(false)

    let updateUserDashboard: ((dashboard: Dashboard) => void) | undefined
    let addAppLevelDashboard: ((newDashboard: Dashboard) => void) | undefined
    try {
        const homeDashboardContext = useHomeDashboardContext()
        updateUserDashboard = homeDashboardContext.updateUserDashboard
        addAppLevelDashboard = homeDashboardContext.addAppLevelDashboard
    } catch (e) {
        updateUserDashboard = undefined
        addAppLevelDashboard = undefined
    }

    const device = isMobile ? DashboardDevice.SMALL : devicePreview
    const widgetsWithPosition = makeWidgetsWithPosition(config, widgets, device)
    const visibleWidgets = widgetsWithPosition.filter(w => !w.position?.hide)
    const hiddenWidgets = widgetsWithPosition.filter(w => w.position?.hide)
    const layout = makeLayout(config, device)

    useEffect(() => {
        if (!_config) return

        setDirty(false)
        setConfig(_config)
    }, [_config])

    const { filters = [], source: filterSource } = useFilters(config?.filters) || {}
    const id = config?.id

    async function updateConfig(body: Partial<Dashboard>, save?: boolean) {
        setDirty(true)
        const newConfig = { ...config, ...body }
        setConfig(newConfig)

        if (save) await handleSave(newConfig)
    }

    async function onEditDone(cancelChanges: boolean, saveHomeDashboardForAll = false) {
        setEditing(false)
        if (editing && devicePreview !== DashboardDevice.LARGE) setDevicePreview(DashboardDevice.LARGE)

        if (!dirty) return
        if (cancelChanges) return setConfig(_config)

        await handleSave(config, saveHomeDashboardForAll)
    }

    async function handleSave(config: Dashboard, saveHomeDashboardForAll = false) {
        try {
            let newConfig: Dashboard
            if (config.homePage && updateUserDashboard) {
                updateUserDashboard(config)
                if (saveHomeDashboardForAll && addAppLevelDashboard) addAppLevelDashboard(config)
                newConfig = config
            } else {
                newConfig = await updateDashboardOrCreateDraft(appName, id, config, viewFlag)
            }
            setConfig(newConfig)
            if (getWidgets) {
                const previousWidgetIds = _config.widgets?.map(w => w.id)
                const updateWidgetIds = config.widgets?.map(w => w.id)
                if (!isEqual(updateWidgetIds, previousWidgetIds)) getWidgets()
            }
        } catch (err) {
            console.error(err)
            showSnackbar(err?.message ? err.message : 'An error occurred saving collection', { variant: 'error' })
        }
    }

    async function positionWidgets(updated: Layout[]) {
        if (!editing) return

        const widgets = config?.widgets.map((w, index) => {
            const position = getPositionFromLayout(updated, w.id || index.toString())
            if (!position) {
                return w
            }

            const { hide, shrink, top } = w.positions?.[devicePreview] || {}
            const otherProps = { ...(hide && { hide }), ...(shrink && { shrink }), ...(top && { top }) }
            return { ...w, positions: { ...w.positions, [devicePreview]: { ...otherProps, ...position } } }
        })

        if (isEqual(widgets, config?.widgets)) return

        return updateConfig({ widgets })
    }

    const toggleShowExistingWidgetsDialog = useCallback(() => setShowExistingWidgetsDialog(s => !s), [])

    async function updateFilters(filters: DashboardNodeFilter[], method: FilterApplyMethod) {
        const withValues = filters.filter(filterHasValue)

        switch (method) {
            case 'apply-only':
                dispatch(filterCreators.setBuilderFilters('dashboard', withValues))
                break
            case 'save':
                logEvent('PERSPECTIVE_FILTERS_SAVE_AS_DEFAULT')
                dispatch(filterCreators.saveDefaultPerspectiveFiltersForUser(withValues, id))
                break
            case 'save-for-all':
                logEvent('PERSPECTIVE_FILTERS_SAVE_AS_DEFAULT_ALL')
                const updatedFilters = config.filters.map(
                    f => withValues.find(updated => filtersMatch(updated, f)) ?? emptyDashboardFilterValues(f)
                )
                await updateConfig({ filters: updatedFilters })
                break
        }
    }

    return (
        <DashboardContext.Provider
            value={{
                actions,
                config,
                devicePreview,
                editable,
                editing,
                filters,
                filterSource,
                hiddenWidgets,
                layout,
                onEditDone,
                onEditStart: () => setEditing(true),
                positionWidgets,
                setDevicePreview,
                showExistingWidgetsDialog,
                toggleShowExistingWidgetsDialog,
                updateConfig,
                updateFilters,
                widgets: visibleWidgets,
            }}
        >
            {children}
        </DashboardContext.Provider>
    )
}

function useFilters(dashboardFilters: DashboardNodeFilter[]) {
    const appliedFilters = useSelector(filterSelectors.getBuilderFilters) as DashboardNodeFilter[]
    const sessionFilters = appliedFilters.filter(f => f.source === 'dashboard')
    const userFilters: DashboardNodeFilter[] = useSelector(filterSelectors.getUserPerspectiveDefaults)

    if (!dashboardFilters?.length) return

    const emptyDashboardFilters = dashboardFilters.map(emptyDashboardFilterValues)

    function combine(usedFilters: DashboardNodeFilter[]) {
        return emptyDashboardFilters.map(empty => {
            return usedFilters.find(used => filtersMatch(used, empty)) ?? empty
        })
    }

    if (sessionFilters.length) return { filters: combine(sessionFilters), source: FilterSourceType.SESSION }
    if (!isEmpty(userFilters)) return { filters: combine(userFilters), source: FilterSourceType.USER_DEFAULTS }
    return {
        filters: dashboardFilters,
        source: dashboardFilters.some(filterHasValue) ? FilterSourceType.WIDGET_DEFAULTS : undefined,
    }
}

export { DashboardProvider, DashboardContext }
