import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect'

import { filtersMatch } from '../components/widgets2/utils/contextFilterUtils'
import { parseFilter } from '../lib/utils'
import { FilterSourceType } from '../types/FilterTypes'
import { applicationSelectors, authSelectors } from './'
import { moduleSelectors } from './module.selectors'
import { widgetSelectors } from './widget.selectors'

/** UTILITIES */

const isFilterEmpty = filter => {
    const { values, range, rangeOffset, clickRangeName } = filter
    if (values?.length > 0) return false
    if (Boolean(range?.min || range?.max)) return false
    if (Boolean(rangeOffset?.min || rangeOffset?.max)) return false
    if (clickRangeName) return false
    return true
}
const removeEmptyFilters = filters => {
    const nonEmptyFilters = Object.keys(filters).filter(
        filterId => filterId.includes(SEARCH_PREFIX) || !isFilterEmpty(filters[filterId])
    )
    return pick(filters, nonEmptyFilters)
}
const removerDefaultFirstValueFilters = (state, filters) => {
    const nonDefaultFirstValueFilters = Object.keys(filters).filter(filterId => {
        const filterConfig = getFilterConfigById(state, filterId)
        return !filterConfig.DefaultFirstValue
    })
    return pick(filters, nonDefaultFirstValueFilters)
}

/**
 * Builds the filter configuration object to be sent to the server based on a filter selection
 * @param {Object} selection - the selected input for the filter (either an array of values or a range)
 * @param {Object} config - the base filter config
 */
const getConfigurationFromSelection = (selection, config) => ({
    FilterName: config.Name,
    ResourceName: config.Source.ElementName,
    ResourceType: 'Concept',
    PropertyName: config.KeyPropertyName,
    IsTemporal: config.IsTemporal,
    Values: (selection.values && selection.values.map(filterSelection => filterSelection.value)) || [],
    DisplayValues: (selection.values && selection.values.map(filterSelection => filterSelection.label)) || [],
    ...(!isEmpty(selection.range) && { Range: { MinValue: selection.range.min, MaxValue: selection.range.max } }),
    RangeOffset: {
        ...(selection.rangeOffset?.min !== undefined && { Min: selection.rangeOffset.min }),
        ...(selection.rangeOffset?.max !== undefined && { Max: selection.rangeOffset.max }),
    },
    UseLastRefreshDate: selection.useLastRefreshDate || false,
    ...(selection.clickRangeName && { ClickRangeName: selection.clickRangeName }),
    Operator: selection.operator,
    PostCalculation: config.PostCalculation,
})

const getV1Configs = state => state.filters.configs
const getFilterConfigById = (state, filterId) => {
    return state.filters.configs[filterId]
}

const getFilterConfigByName = (state, filterName) => {
    const filterId = Object.keys(state.filters.configs).find(
        key => state.filters.configs[key]?.Source?.ElementName === filterName
    )
    return getFilterConfigById(state, filterId)
}

/**PERSPECTIVE FILTERS */

const getPerspectiveFiltersConfig = createSelector(
    [widgetSelectors.getCurrentPerspectiveConfig],
    config => config?.FilterConfig || []
)

const getComparePerspectiveFiltersConfig = createSelector(
    [widgetSelectors.getCurrentPerspectiveConfig],
    config => config?.FilterConfig || []
)

const currentHistorySelector = ({ history }) => history.back[history.back.length - 1] || {}
const getSessionPerspectiveFilters = createSelector(currentHistorySelector, h => h.filters || {})
const getSessionComparePerspectiveFilters = createSelector(currentHistorySelector, h => h.compareFilters || {})
const getBuilderFilters = createSelector(currentHistorySelector, h => h.location?.state?.filters ?? [])
const getUserPerspectiveDefaults = state => state.filters.perspectiveDefaults

/**
 * Gets the default filters for the perspective as specified in the widget config
 */
const getPerspectiveWidgetDefaults = createSelector([widgetSelectors.getCurrentPerspectiveDefaultfilters], filters =>
    filters.reduce((acc, filter) => {
        const { FilterName } = filter
        acc[FilterName] = parseFilter(filter)
        return acc
    }, {})
)

const getPerspectiveWidgetNetworkDefaults = createSelector(
    [widgetSelectors.getCurrentPerspectiveDefaultNetworkfilters],
    filters => filters
)

const getPerspectiveFiltersSource = createSelector(
    [getUserPerspectiveDefaults, getPerspectiveWidgetDefaults, getSessionPerspectiveFilters],
    (userPreferences, widgetDefaults, sessionFilters) => {
        if (!isEmpty(sessionFilters)) return FilterSourceType.SESSION
        else if (!isEmpty(userPreferences)) return FilterSourceType.USER_DEFAULTS
        else if (!isEmpty(widgetDefaults)) return FilterSourceType.WIDGET_DEFAULTS
        else return FilterSourceType.SESSION
    }
)

const getAppliedPerspectiveFilters = createSelector(
    [
        getUserPerspectiveDefaults,
        getPerspectiveWidgetDefaults,
        getSessionPerspectiveFilters,
        getPerspectiveFiltersSource,
    ],
    (userPreferences, widgetDefaults, sessionFilters, source) => {
        switch (source) {
            case FilterSourceType.SESSION:
                return sessionFilters
            case FilterSourceType.USER_DEFAULTS:
                return userPreferences
            case FilterSourceType.WIDGET_DEFAULTS:
                return widgetDefaults
            default:
                return sessionFilters
        }
    }
)

const getAppliedComparePerspectiveFilters = createSelector(
    [getSessionComparePerspectiveFilters],
    sessionFilters => sessionFilters
)

const getAppliedSearchFilters = createSelector([currentHistorySelector], h => h.searchFilters || [])
const getAppliedCompareSearchFilters = createSelector([currentHistorySelector], h => h.compareSearchFilters || [])

/**GLOBAL FILTERS */

const getV1GlobalFilters = state => {
    const globalFilterIds = state.filters.global
    const { configs } = state.filters

    if (globalFilterIds.length === 0) return []

    return globalFilterIds.map(filterId => configs[filterId])
}

const getGlobalFiltersConfig = createSelector(
    [moduleSelectors.getIsV2, moduleSelectors.getFilters, getV1GlobalFilters],
    (isV2, filters, v1Filters) => (isV2 ? filters : v1Filters)
)

const getGlobalApplied = state => state.filters.globalApplied

const globalAppliedSelector = createSelector(
    [getGlobalApplied, moduleSelectors.getIsV2, widgetSelectors.isV2Dashboard],
    (applied, isV2Module, isV2Dashboard) => {
        if (isV2Module) return applied || []
        if (isV2Dashboard) return []
        return applied
    }
)

const getUserGlobalDefaults = state => {
    const isV2 = moduleSelectors.getIsV2(state)
    const filters = getFilterPreference(state, 'GlobalFilters')
    const globalFiltersConfig = getGlobalFiltersConfig(state)
    if (isEmpty(filters)) return

    // only set filters that are found in config
    if (isV2) {
        return filters.filter(a => globalFiltersConfig.some(b => filtersMatch(a, b)))
    } else {
        for (const filter in filters) {
            if (globalFiltersConfig.findIndex(config => config.Name === filter) === -1) delete filters[filter]
        }

        return filters
    }
}

const getGlobalFiltersSource = createSelector(
    [getUserGlobalDefaults, globalAppliedSelector],
    (userPreferences, sessionFilters) => {
        if (!isEmpty(sessionFilters)) return FilterSourceType.SESSION
        else if (!isEmpty(userPreferences)) return FilterSourceType.USER_DEFAULTS
        else return FilterSourceType.SESSION
    }
)

const getAppliedGlobalFilters = createSelector(
    [getUserGlobalDefaults, globalAppliedSelector, getGlobalFiltersSource],
    (defaultFilters, sessionFilters, source) => {
        switch (source) {
            case FilterSourceType.SESSION:
                return sessionFilters
            case FilterSourceType.USER_DEFAULTS:
                return defaultFilters
            default:
                return sessionFilters
        }
    }
)

/** LESS SIMPLE SELECTORS */
const createDeepEqualsSelector = createSelectorCreator(defaultMemoize, (a, b) => isEqual(a, b))

const currentFilterValues = createSelector(
    [getAppliedGlobalFilters, getAppliedPerspectiveFilters, getAppliedSearchFilters],
    makeFilterValues
)

const currentCompareFilterValues = createSelector(
    [getAppliedGlobalFilters, getAppliedComparePerspectiveFilters, getAppliedCompareSearchFilters],
    makeFilterValues
)

const currentGlobalFilterValues = createSelector([getAppliedGlobalFilters], makeGlobalFilterValues)

const SEARCH_PREFIX = 'search|'

function makeFilterValues(global, perspective, search) {
    const searchByKey = search.reduce((acc, filter, index) => {
        acc[`${SEARCH_PREFIX}${index}`] = filter
        return acc
    }, {})
    return removeEmptyFilters({ ...global, ...perspective, ...searchByKey })
}

function makeGlobalFilterValues(global) {
    return removeEmptyFilters({ ...global })
}

const currentFiltersConfiguration = createDeepEqualsSelector(
    [getV1Configs, currentFilterValues],
    makeFiltersConfiguration
)

const currentCompareFiltersConfiguration = createDeepEqualsSelector(
    [getV1Configs, currentCompareFilterValues],
    makeFiltersConfiguration
)

const currentGlobalFiltersConfiguration = createDeepEqualsSelector(
    [getV1Configs, currentGlobalFilterValues],
    makeFiltersConfiguration
)

function makeFiltersConfiguration(configs, filters) {
    if (isEmpty(filters)) return

    return Object.keys(filters)
        .map(filterId => {
            if (filterId.includes(SEARCH_PREFIX)) return filters[filterId]

            let filterConfig = configs[filterId]
            if (!filterConfig) filterConfig = filters[filterId]?.filterConfig
            if (!filterConfig) return null

            return getConfigurationFromSelection(filters[filterId], filterConfig)
        })
        .filter(Boolean)
}

const currentPerspectiveFilterValues = createSelector(getAppliedPerspectiveFilters, appliedPerspectiveFilters =>
    removeEmptyFilters({ ...appliedPerspectiveFilters })
)

const currentPerspectiveFiltersConfiguration = createSelector(
    getV1Configs,
    currentPerspectiveFilterValues,
    (configs, filters) => {
        if (!isEmpty(filters)) {
            return Object.keys(filters)
                .map(filterId => {
                    const filterConfig = configs[filterId]
                    if (!filterConfig) return null
                    return getConfigurationFromSelection(filters[filterId], filterConfig)
                })
                .filter(Boolean)
        }
        return undefined
    }
)

const getFilterSelectedValueCount = (state, filters) => {
    const filterValues = currentFilterValues(state)
    return filters.reduce((acc, filterId) => {
        const selection = filterValues[filterId]
        if (!selection) return acc
        const { values, range, rangeOffset, clickRangeName } = selection
        if (!isEmpty(values)) return (acc += values.length)
        else if (!isEmpty(range) || rangeOffset?.min != null || rangeOffset?.max != null || clickRangeName)
            return (acc += 1)
        else return acc
    }, 0)
}

const getCompareFilterSelectedValueCount = (state, filters) => {
    const filterValues = currentCompareFilterValues(state)
    return filters.reduce((acc, filterId) => {
        const selection = filterValues[filterId]
        if (!selection) return acc
        const { values, range, rangeOffset, clickRangeName } = selection
        if (!isEmpty(values)) return (acc += values.length)
        else if (!isEmpty(range) || rangeOffset?.min != null || rangeOffset?.max != null || clickRangeName)
            return (acc += 1)
        else return acc
    }, 0)
}

const getFilterPreference = (state, preferenceKey) => {
    const preferences = authSelectors.getUserPreferences(state)
    const isV2 = moduleSelectors.getIsV2(state)

    if (!preferences) return {}

    const moduleId = moduleSelectors.getModuleId(state)
    const filterPreference = preferences.find(
        ({ Key, VisorId }) =>
            Key.toLowerCase() === preferenceKey.toLowerCase() &&
            (preferenceKey === 'GlobalFilters' ? VisorId === moduleId : true)
    )
    if (!filterPreference) return {}
    const parsedFilters = JSON.parse(filterPreference.Value)
    if (!Array.isArray(parsedFilters)) return {}

    if (isV2) return parsedFilters

    return parsedFilters.reduce((acc, filter) => {
        const { FilterName } = filter
        acc[FilterName] = parseFilter(filter)
        return acc
    }, {})
}

const getInlineFilters = createSelector(currentHistorySelector, h => h.inlineFilters || null)

const getCoord = createSelector(
    currentHistorySelector,
    moduleSelectors.getModuleCloud,
    applicationSelectors.getCurrentAppName,
    moduleSelectors.getIsV2,
    (history, cloud, model, isV2) => {
        const context = history?.location?.state?.context ?? {}
        return { ...context, ...(!isV2 && { CloudName: cloud, ModelName: model, Type: 'Node' }) } || {}
    }
)

const getCompareCoord = createSelector(
    currentHistorySelector,
    moduleSelectors.getModuleCloud,
    applicationSelectors.getCurrentAppName,
    moduleSelectors.getIsV2,
    (history, cloud, model, isV2) => {
        const context = history?.location?.state?.compareContext ?? {}
        return { ...context, ...(!isV2 && { CloudName: cloud, ModelName: model, Type: 'Node' }) } || {}
    }
)

const getDeletedFilters = createSelector(currentHistorySelector, h => h.deletedFilters || {})

const getTrendLines = (state, configId) => currentHistorySelector(state).widgetsInfo?.[configId]?.trendLines

const getCurrentWidgetId = createSelector(currentHistorySelector, h => h.currentWidgetId)

export const filterSelectors = {
    isFilterEmpty,
    removeEmptyFilters,
    getUserPerspectiveDefaults,
    getGlobalFiltersConfig,
    getPerspectiveFiltersConfig,
    getComparePerspectiveFiltersConfig,
    getAppliedPerspectiveFilters,
    getAppliedComparePerspectiveFilters,
    getAppliedSearchFilters,
    getAppliedCompareSearchFilters,
    getPerspectiveFiltersSource,
    getGlobalFiltersSource,
    getAppliedGlobalFilters,
    currentFiltersConfiguration,
    currentCompareFiltersConfiguration,
    getFilterConfig: getFilterConfigById,
    getFilterSelectedValueCount,
    getCompareFilterSelectedValueCount,
    getFilterPreference,
    getInlineFilters,
    getCoord,
    getCompareCoord,
    getDeletedFilters,
    getCurrentWidgetId,
    currentPerspectiveFiltersConfiguration,
    getTrendLines,
    getConfigurationFromSelection,
    getSessionPerspectiveFilters,
    globalAppliedSelector,
    getUserGlobalDefaults,
    getBuilderFilters,
    getPerspectiveWidgetNetworkDefaults,
    removerDefaultFirstValueFilters,
    getFilterConfigByName,
    currentGlobalFiltersConfiguration,
    getV1Configs,
}
