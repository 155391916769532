import React from 'react'
import { Typography, Tooltip } from '@mui/material'
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import { ToggleButtonGroupProps, ToggleButtonProps } from '@mui/lab';

interface Option {
    value: string
    label: string | JSX.Element
    /** (optional) tooltip */
    tip?: string
}

interface Props extends ToggleButtonGroupProps {
    options: Array<Option>
    label?: string
    disabled?: boolean
    exclusive?: boolean
}

export default function LabeledToggleButton({ label, disabled, options, exclusive = true, ...props }: Props) {
    return (
        <div>
            {label && <Typography display="inline">{label} </Typography>}
            <ToggleButtonGroup exclusive={exclusive} {...props}>
                {options.map((option, i) => (
                    <ToggleButtonWithTooltip key={i} disabled={disabled} {...option} />
                ))}
            </ToggleButtonGroup>
        </div>
    )
}

type ToggleButtonWithTooltipProps = Omit<ToggleButtonProps, 'value'> & Option

function ToggleButtonWithTooltip({ value, label, tip, ...rest }: ToggleButtonWithTooltipProps) {
    return (
        <Tooltip key={value} disableHoverListener={!tip} title={tip || ''}>
            <ToggleButton {...rest} value={value}>
                {label}
            </ToggleButton>
        </Tooltip>
    )
}
