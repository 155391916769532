import React from 'react'
import { ListItemText } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import WidgetInfoEmptyText from './WidgetInfoEmptyText'
import { useSelector } from 'react-redux'
import { filterSelectors } from '../../selectors'

const useStyles = makeStyles(({ palette }) => ({
    filterContent: { fontSize: 12 },
}))

export default function WidgetInfoPerspectiveFilters() {
    const classes = useStyles()

    const perspectiveFiltersApplied: any = useSelector(filterSelectors.currentPerspectiveFiltersConfiguration)

    return (
        <div>
            {perspectiveFiltersApplied?.length > 0 ? (
                perspectiveFiltersApplied.map((filterKeys, index) => (
                    <ListItemText key={index}>
                        <b>{filterKeys.ResourceName + ':'}</b>
                        <ListItemText key={index}>
                            {filterKeys.DisplayValues.map((value, index) =>
                                filterKeys.Values.length === index + 1 ? (
                                    <span key={index} className={classes.filterContent}>
                                        {value}
                                    </span>
                                ) : (
                                    <span key={index} className={classes.filterContent}>
                                        {value + ', '}
                                    </span>
                                )
                            )}
                        </ListItemText>
                    </ListItemText>
                ))
            ) : (
                <WidgetInfoEmptyText>No perspective filter(s) applied</WidgetInfoEmptyText>
            )}
        </div>
    )
}
