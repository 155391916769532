import { Box, Typography } from '@mui/material'
import { isEmpty } from 'lodash'

import { Widget } from 'genesis-suite/types/visualTypes'
import Widget2 from '../widgets2/Widget2'

interface Props {
    after: ConfigAndLabel
    before?: ConfigAndLabel
}

interface ConfigAndLabel {
    config: Widget
    label?: string | JSX.Element
}

export default function WidgetCompare({ after, before }: Props) {
    return (
        <Box
            flex={1}
            display="grid"
            gridTemplateColumns={isEmpty(before) ? undefined : '1fr 1fr'}
            gap={1}
            overflow="hidden"
        >
            <WidgetContainer {...after} />
            {!isEmpty(before) && <WidgetContainer {...before} />}
        </Box>
    )
}

function WidgetContainer({ config, label }: ConfigAndLabel) {
    return (
        <Box sx={{ flex: 1, display: 'flex', gap: 1, overflow: 'hidden', flexDirection: 'column' }}>
            {Boolean(label) && typeof label === 'string' ? <Typography variant="h5">{label}</Typography> : label}

            <Box
                sx={{
                    backgroundColor: 'background.widget',
                    borderRadius: '15px',
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    overflow: 'hidden',
                    p: 1,
                }}
            >
                {config ? (
                    <>
                        <Typography variant="h6">{config.title}</Typography>
                        <Widget2 config={config} />
                    </>
                ) : null}
            </Box>
        </Box>
    )
}
