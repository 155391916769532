export const authTypes = {
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    SESSION_EXTEND: 'SESSION_EXTEND',
    SESSION_EXPIRED: 'SESSION_EXPIRED',
    USER_PREFERENCES_SAVE_REQUEST: 'USER_PREFERENCES_SAVE_REQUEST',
    USER_PREFERENCES_SAVE_SUCCESS: 'USER_PREFERENCES_SAVE_SUCCESS',
    USER_PREFERENCES_SAVE_FAILURE: 'USER_PREFERENCES_SAVE_FAILURE',
    USER_PROFILE_REQUEST: 'USER_PROFILE_REQUEST',
    USER_PROFILE_SUCCESS: 'USER_PROFILE_SUCCESS',
    USER_PROFILE_FAILURE: 'USER_PROFILE_FAILURE',
    USER_PREF_UPDATE: 'USER_PREF_UPDATE',
    APP_PREF_UPDATE: 'APP_PREF_UPDATE',
}
