import React, { useState, ReactNode } from 'react'
import { Collapsable } from 'genesis-suite/components'
import { ExpandLess, ExpandMore, CloseRounded } from '@mui/icons-material'
import { Paper, Typography, Tooltip, IconButton } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

interface Props {
    header: string
    children: ReactNode
    collapsable?: boolean
    startCollapsed?: boolean
    onRemove?: () => void
}

const useStyles = makeStyles(({ spacing, palette }) => ({
    root: {
        display: 'flex',
        flexFlow: 'column nowrap',
        marginBottom: spacing(),
    },
    headerWrapper: { padding: 0 },
    header: {
        width: '100%',
        display: 'flex',
        fontWeight: 'bold',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    borderedSection: {
        padding: spacing(1),
        border: `1px solid ${palette.grayscale.light}`,
    },
}))

const BorderedSection = ({ header, children, collapsable, startCollapsed, onRemove }: Props) => {
    const classes = useStyles({})
    const [open, setOpen] = useState(!startCollapsed)

    if (collapsable)
        return (
            <Collapsable
                open={open}
                direction="down"
                className={classes.root}
                ExpandIcon={ExpandMore}
                CollapseIcon={ExpandLess}
                headerClass={classes.headerWrapper}
                onToggle={() => setOpen(!open)}
                header={
                    <Typography className={classes.header}>
                        {header}
                        {onRemove && (
                            <Tooltip title="Remove">
                                <IconButton onClick={onRemove} size="large">
                                    <CloseRounded />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Typography>
                }
            >
                <Paper elevation={0} className={classes.borderedSection}>
                    {children}
                </Paper>
            </Collapsable>
        );
    return (
        <div className={classes.root}>
            <Typography className={classes.header} gutterBottom>
                {header}
            </Typography>
            <Paper elevation={0} className={classes.borderedSection}>
                {children}
            </Paper>
        </div>
    )
}

export default BorderedSection
