import { matchPath } from 'react-router-dom'

import { tourTypes } from '../types'
import { authSelectors } from '../../selectors'
import { authCreators } from './'
import history from '../../store/history'
import { routePaths } from '../../lib/routes'

const openTour = () => ({ type: tourTypes.TOUR_OPEN })
const closeTour = () => ({ type: tourTypes.TOUR_CLOSED })

const updateTourStep = step => ({ type: tourTypes.UPDATE_TOUR_STEP, payload: { step } })

/** Call on app startup to open tour, if it's the user's first time using the app */
const showTourIfNew = () => {
    return (dispatch, getState) => {
        const state = getState()

        if (authSelectors.getGlobalPreferenceByKey(state, 'WatchedTour')) return

        const atHome = state.router.location && matchPath(routePaths.HOME, state.router.location.pathname)
        if (!atHome) history.replace(routePaths.HOME)

        dispatch(openTour())
    }
}

/** When user closes the tour, create a user preference to prevent opening again automatically */
const watchedTour = () => {
    return (dispatch, getState) => {
        if (!authSelectors.getGlobalPreferenceByKey(getState(), 'WatchedTour')) {
            const newPreference = {
                Id: null,
                Key: 'WatchedTour',
                Value: 'Done',
            }

            dispatch(authCreators.saveUserPreferencesForCurrentApplication([newPreference]))
        }

        dispatch(closeTour())
    }
}

export const tourCreators = { openTour, updateTourStep, showTourIfNew, watchedTour }
