import React from 'react'
import { ReactourStep, ReactourProps } from 'reactour'
import { Typography } from '@mui/material'

export enum TourTag {
    AppOptions = 'app-options',
    BusinessElements = 'business-elements',
    HistoryController = 'history-controller',
    LastUpdated = 'last-updated',
    ModuleController = 'module-controller',
    ModuleTools = 'module-tools',
    PerspectiveOptions = 'perspective-options',
    Shortcuts = 'shortcuts',
    UserProfile = 'user-profile',
    WidgetMenuButton = 'widget-menu-button',
    _360Views = '_360-views',
}

export const makeSteps = (showComments, showProfiles, showTasks, textColor): Step[] => [
    {
        content: (
            <Typography sx={{ color: textColor }}>
                <b>Welcome!</b> Looks like this is the first time you've used Tada. Let's take this time to quickly
                familiarize you with the platform.
            </Typography>
        ),
    },
    {
        selector: makeSelector(TourTag.UserProfile),
        content: (
            <Typography sx={{ color: textColor }}>
                Click your <b>User Profile</b> if you need to view more about your account or want to sign out.
            </Typography>
        ),
    },
    {
        selector: makeSelector(TourTag.ModuleController),
        content: (
            <Typography sx={{ color: textColor }}>
                This controls which <b>application</b> is active within your app. Each application reflects a different
                segment of your overall business and provides you with a different set of visuals based on your app's
                Digital Duplicate structure.
            </Typography>
        ),
    },
    {
        selector: makeSelector(TourTag.BusinessElements),
        content: (
            <Typography sx={{ color: textColor }}>
                These <b>Business Elements</b> contain some of the items in your Digital Duplicate for the active
                module.
            </Typography>
        ),
    },
    {
        selector: makeSelector(TourTag._360Views),
        content: (
            <Typography sx={{ color: textColor }}>
                <b>Views</b> are a collection of perspectives for getting an in-depth picture of your business.
            </Typography>
        ),
    },
    {
        selector: makeSelector(TourTag.Shortcuts),
        content: (
            <Typography sx={{ color: textColor }}>
                <b>Shortcuts</b> let you save links to your favorite websites for quick and easy access. Links saved
                here will not be visible to other users.
            </Typography>
        ),
    },
    {
        selector: makeSelector(TourTag.AppOptions),
        content: (
            <Typography sx={{ color: textColor }}>
                The <b>Application Options</b> provide app-wide navigation and filtering. Try searching for a certain
                item, setting default global filters, or viewing your notifications. Find out more{' '}
                <u>
                    <a
                        href="https://tada.zendesk.com/hc/en-us/sections/360006114754-Application-Features"
                        target="_blank"
                    >
                        here
                    </a>
                </u>
                .
            </Typography>
        ),
    },
    {
        selector: makeSelector(TourTag.ModuleTools),
        content: makeModuleToolsContent(showComments, showProfiles, showTasks, textColor),
    },
    {
        selector: makeSelector(TourTag._360Views),
        content: (
            <Typography sx={{ color: textColor }}>
                <b>Click on any perspective to the left to keep exploring Tada.</b>
            </Typography>
        ),
        hideButtons: true,
        hideNavigation: true,
        enableInteraction: true,
        disableKeyboardNavigation: ['right'],
        goForwardOnPerspective: true,
        openSideNav: true,
        resizeObservables: [makeSelector(TourTag._360Views)],
    },
    {
        selector: makeSelector(TourTag.PerspectiveOptions),
        requiredRoute: 'perspective',
        prevButton: <span />,
        content: (
            <Typography sx={{ color: textColor }}>
                This is a perspective. Awesome! You now have access to multiple <b>Perspective Options</b> that include:
                data filtering, slideshow mode, viewing layouts, and more. Learn more about perspective options{' '}
                <u>
                    <a
                        href="https://tada.zendesk.com/hc/en-us/sections/360006138253-Perspective-Features"
                        target="_blank"
                    >
                        here
                    </a>
                </u>
                .
            </Typography>
        ),
    },
    {
        selector: makeSelector(TourTag.HistoryController),
        requiredRoute: 'perspective',
        content: (
            <Typography sx={{ color: textColor }}>
                After opening a few perspectives, you may want to go back to a prior view. The <b>History Controller</b>{' '}
                will keep track of this for you.
            </Typography>
        ),
    },
    {
        selector: makeSelector(TourTag.LastUpdated),
        requiredRoute: 'perspective',
        content: (
            <Typography sx={{ color: textColor }}>
                The data presented in the widgets depends on when the data was last updated. If available, that date
                will appear here.
            </Typography>
        ),
    },
    {
        selector: makeSelector(TourTag.WidgetMenuButton),
        requiredRoute: 'perspective',
        content: (
            <Typography sx={{ color: textColor }}>
                Explore the <b>Widget Options</b> to do more with a particular widget. Read more about widget options{' '}
                <u>
                    <a href="https://tada.zendesk.com/hc/en-us/sections/360006115014-Widget-Features" target="_blank">
                        here
                    </a>
                </u>
                .
            </Typography>
        ),
    },
    {
        content: (
            <Typography sx={{ color: textColor }}>
                This concludes our tour. Awesome work! Now get out there and explore the tool!
            </Typography>
        ),
    },
]

function makeModuleToolsContent(showComments, showProfiles, showTasks, textColor) {
    const oneModuleTool =
        (showComments && !showProfiles && !showTasks) ||
        (!showComments && showProfiles && !showTasks) ||
        (showComments && !showProfiles && showTasks)

    if (oneModuleTool) {
        if (showComments)
            return (
                <Typography sx={{ color: textColor }}>
                    <b>Widget Commenting</b> enables collaboration with other users in your application
                </Typography>
            )
        if (showProfiles)
            return (
                <Typography sx={{ color: textColor }}>
                    <b>Profiles</b> allow you to explore the data connected to a business element and see interconnected
                    elements
                </Typography>
            )
        if (showTasks)
            return (
                <Typography sx={{ color: textColor }}>
                    <b>Tasks</b> help you stay organized to complete actionable items
                </Typography>
            )
    }

    const multiToolArray = [
        ...(showProfiles ? ['view profile information about a selected business element'] : []),
        ...(showComments ? ['add comments to particular widgets'] : []),
        ...(showTasks ? ['add tasks to keep track of actions to accomplish'] : []),
    ]
    multiToolArray[multiToolArray.length - 1] = `and ${multiToolArray[multiToolArray.length - 1]}.`

    return (
        <Typography sx={{ color: textColor }}>
            <b>Module tools</b> allow you to {multiToolArray.join(', ')}
        </Typography>
    )
}

export interface Step extends ReactourStep {
    /** (optional) */
    disableKeyboardNavigation?: ReactourProps['disableKeyboardNavigation']
    /** (optional) Allow user to interact w/n highlighted region  */
    enableInteraction?: boolean
    /** (optional) Watch route changes and automatically go to next step if on a perspective */
    goForwardOnPerspective?: boolean
    /** (optional) */
    hideButtons?: boolean
    /** (optional) */
    hideNavigation?: boolean
    /** (optional) Force side nav panel open or closed */
    openSideNav?: boolean
    /** (optional) render a different previous button */
    prevButton?: ReactourProps['prevButton']
    /** (optional) Switch to this route if on this step */
    requiredRoute?: 'home' | 'perspective'
}

function makeSelector(tag: TourTag) {
    return `[data-tour="${tag}"]`
}
