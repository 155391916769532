import { calculatorConstants } from '../constants'

const initialState = {
    currentVal: null,
}

export default (state = initialState, action) => {
    const { payload } = action
    switch (action.type) {
        case calculatorConstants.CURRENTVAL:
            return { currentVal: payload.currentVal }
        default:
            return state
    }
}
