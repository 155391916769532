const getCurrentAppName = state => state.applications.current

const getAppInfo = state => state.applications.appInfo

const getLastUpdateDate = state => state.applications.lastUpdateDate

const getAllApplications = state => state.applications.all

const getIsPending = state => state.applications.isPending

const getPublicAccessEnabled = state => state.applications.publicAccessEnabled

const getAllAuthorizedApps = state => state.applications.authorizedApps

export const applicationSelectors = {
    getCurrentAppName,
    getAppInfo,
    getLastUpdateDate,
    getAllApplications,
    getIsPending,
    getPublicAccessEnabled,
    getAllAuthorizedApps,
}
