import { FormControlLabel, Paper, Switch } from '@mui/material'
import { MapGridOptions } from 'genesis-suite/types/visualTypes'
import { useState } from 'react'

interface Props extends MapGridOptions {
    onToggle: (show: boolean) => void
}

export default function GridLineToggle({ onToggle, show, toggle }: Props) {
    const [checked, setChecked] = useState(show ?? false)

    if (!toggle) return null

    function handleChange(e) {
        const show = e.target.checked
        setChecked(show)
        onToggle(show)
    }

    return (
        <Paper elevation={3} sx={{ background: '#ffffffdd', color: 'text.default' }}>
            <FormControlLabel
                control={<Switch checked={checked} onChange={handleChange} />}
                label="Grid lines"
                sx={{ m: 0, mr: 1 }}
            />
        </Paper>
    )
}
