import { useState } from 'react'
import MoreIcon from '@mui/icons-material/MoreVertRounded'
import { MenuIcon } from 'genesis-suite/components'
import { MenuList } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import MenuListItem from './MenuListItem'

const styles = ({ palette, border }) => {
    const topBarColor = palette.background.topBar

    return {
        menu: {
            backgroundColor: topBarColor,
            borderRadius: border.radius.round,
            minWidth: 150,
        },
    }
}

/**
 * Renders a 3 dot menu and popover options
 * @param {array} props.items - array of items to be rendered into the menu in the form { name, icon, click, hide }
 */
const Menu = ({ classes, items, iconClass, iconProps, buttonStyle, onToggle }) => {
    const [open, setOpen] = useState(false)

    if (items.filter(item => !item.hide).length === 0) return null

    const closeMenu = () => setOpen(false)

    function handleToggle() {
        const updatedOpen = !open
        onToggle?.(updatedOpen)
        setOpen(updatedOpen)
    }

    return (
        <MenuIcon
            buttonProps={{ sx: buttonStyle }}
            open={open}
            onClick={handleToggle}
            onClose={handleToggle}
            icon={<MoreIcon {...iconProps} className={iconClass} />}
            tooltip="More Options"
            noPadding
        >
            <MenuList className={classes.menu}>
                {items.map(item => {
                    const { hide } = item
                    if (hide) return null
                    return <MenuListItem item={item} closeMenu={closeMenu} key={item.name}></MenuListItem>
                })}
            </MenuList>
        </MenuIcon>
    )
}

export default withStyles(styles)(Menu)
