import { useContext, useState } from 'react'
import { Box, Typography } from '@mui/material'
import EmptyIcon from '@mui/icons-material/ImageOutlined'

import { MenuIcon, CognitiveIcon, CognitiveIconPicker } from 'genesis-suite/components'
import { ChartType, LabelSeries } from 'genesis-suite/types/visualTypes'
import { ConfigContext } from '../../ConfigContext'
import EditorWrapper from '../EditorWrapper'
import DisplayFormatFields from '../DisplayFormatFields'
import CellFormatEditor from '../FormatEditor/CellFormatEditor'
import AggregationSelect from '../../AggregationSelect'

export default function SeriesEditorLabel() {
    const { config, dispatch, selectedField } = useContext(ConfigContext)
    const [openIconPicker, setOpenIconPicker] = useState(false)

    if (config.type !== ChartType.LABEL) return null

    const activeSeries = config.series[selectedField.index]
    const { icon, numberFormat, format, conditionalFormats, values } = activeSeries || {}
    const { field, aggregation } = values[0]

    function update(update: Partial<LabelSeries>) {
        dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: update })
    }

    return (
        <>
            <AggregationSelect />

            <Box display="flex" alignItems="center" gap={1} mb={1}>
                <Typography variant="h6">Icon:</Typography>
                <MenuIcon
                    open={openIconPicker}
                    buttonProps={{ size: 'small' }}
                    popoverProps={{
                        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                        transformOrigin: { vertical: 'top', horizontal: 'left' },
                    }}
                    icon={icon ? <CognitiveIcon fontSize="large" icon={icon} /> : <EmptyIcon fontSize="large" />}
                    onClick={() => setOpenIconPicker(true)}
                    onClose={() => setOpenIconPicker(false)}
                >
                    <CognitiveIconPicker
                        onSelect={icon => {
                            setOpenIconPicker(false)
                            update({ icon })
                        }}
                    />
                </MenuIcon>
            </Box>

            <EditorWrapper header="Number format">
                <DisplayFormatFields format={numberFormat} onChange={numberFormat => update({ numberFormat })} />
            </EditorWrapper>

            <CellFormatEditor
                serviceId={activeSeries.service.id}
                format={format}
                conditionalFormats={conditionalFormats}
                field={field}
                aggregation={aggregation}
                onFormatChange={format => update({ format })}
                onConditionalFormatsChange={conditionalFormats => update({ conditionalFormats })}
            />
        </>
    )
}
