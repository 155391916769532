import { useState, useContext, useEffect, useCallback } from 'react'
import { sleep } from 'genesis-suite/utils'
import makeStyles from '@mui/styles/makeStyles'
import { useSelector, useDispatch } from 'react-redux'
import { SwalContext, Spinner } from 'genesis-suite/components'
import { Close, Settings as SettingsIcon } from 'genesis-suite/icons'
import {
    Box,
    Tabs,
    Tab,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    IconButton,
} from '@mui/material'

import ManageAppearance from './ManageAppearance'
import ManagePreferences from './ManagePreferences'
import { appearanceCreators } from '../../actions/creators'
import { appearanceSelectors } from '../../selectors'

const initialTab = 'preferences'

const useStyles = makeStyles(({ palette, spacing }) => ({
    root: { height: '600px', maxHeight: '90vh', width: '600px', maxWidth: '90vw', cursor: 'default' },
    backdrop: { cursor: 'not-allowed' },
    cover: { display: 'flex', flexDirection: 'column' },
    titleContainer: { padding: spacing(1, 1, 0) },
    titleText: { marginLeft: spacing() },
    tab: { minWidth: '0' },
    contentContainer: {
        padding: 0,
        borderTop: `1px solid ${palette.border?.main}`,
        borderBottom: `1px solid ${palette.border?.main}`,
    },
}))

export default function Settings() {
    const open = useSelector(appearanceSelectors.getShowSettings)
    const dispatch = useDispatch()

    const [tab, setTab] = useState(initialTab)
    const [saveHandle, setSaveHandle] = useState(undefined)
    const [cancelHandle, setCancelHandle] = useState(undefined)
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)

    const { confirm } = useContext(SwalContext)
    const classes = useStyles()

    useEffect(() => {
        setIsLoading(false)
    }, [tab])

    const updateSaveHandle = useCallback(cb => setSaveHandle(() => cb), [])
    const updateCancelHandle = useCallback(cb => setCancelHandle(() => cb), [])
    const handleLoading = useCallback(setIsLoading, [])

    const tabs = [
        { value: initialTab, label: 'Preferences', Component: ManagePreferences },
        { value: 'appearance', label: 'Appearance', Component: ManageAppearance },
    ]

    const Content = tabs.find(t => t.value === tab)?.Component ?? ManagePreferences

    async function handleChangeTab(e, v) {
        const shouldContinue = await triggerCancel()
        if (shouldContinue) setTab(v)
    }

    async function handleClose() {
        const shouldContinue = await triggerCancel()
        if (!shouldContinue) return

        dispatch(appearanceCreators.toggleSettings())
        await sleep(200)
        setTab(initialTab)
    }

    async function triggerCancel() {
        if (!cancelHandle) return true

        const response = await confirm(`Your unsaved changes will be lost, press Ok to confirm`, {
            type: 'warning',
            confirmButtonProps: { text: 'Ok' },
        })
        if (response.dismiss) return false

        await cancelHandle()
        return true
    }

    return (
        <Dialog hideBackdrop open={open} classes={{ container: classes.backdrop, paper: classes.root }}>
            <Spinner
                show={isLoading}
                coverOverride={<Typography variant="h6">Updating...</Typography>}
                className={classes.cover}
            >
                <DialogTitle className={classes.titleContainer}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Box display="flex" alignItems="center">
                            <SettingsIcon />
                            <Typography className={classes.titleText} variant="h6">
                                Settings
                            </Typography>
                        </Box>
                        <IconButton sx={{ color: 'text.primary' }} onClick={handleClose} size="large">
                            <Close />
                        </IconButton>
                    </Box>
                    <Tabs
                        value={tabs.findIndex(t => t.value === tab) === -1 ? initialTab : tab}
                        onChange={handleChangeTab}
                    >
                        {tabs.map(({ value, label }) => (
                            <Tab
                                sx={{ color: 'text.primary' }}
                                key={value}
                                value={value}
                                label={label}
                                className={classes.tab}
                            />
                        ))}
                    </Tabs>
                </DialogTitle>

                <DialogContent className={classes.contentContainer}>
                    <Content
                        updateSaveHandle={updateSaveHandle}
                        updateCancelHandle={updateCancelHandle}
                        onLoading={handleLoading}
                        onError={setIsError}
                    />
                </DialogContent>

                <DialogActions>
                    {cancelHandle && <Button onClick={cancelHandle}>Cancel</Button>}
                    <Button
                        variant="contained"
                        disabled={isError}
                        color={saveHandle ? 'primary' : undefined}
                        onClick={saveHandle?.onClick ?? handleClose}
                    >
                        {saveHandle?.label ?? 'Done'}
                    </Button>
                </DialogActions>
            </Spinner>
        </Dialog>
    )
}
