import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { ColDef, ModuleRegistry } from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import '@ag-grid-community/styles/ag-grid.css'
import '@ag-grid-community/styles/ag-theme-alpine.css'
import { CloudUploadRounded, Create } from '@mui/icons-material'
import ForwardArrow from '@mui/icons-material/ArrowForward'
import GoodIcon from '@mui/icons-material/CheckCircle'
import UnacceptableIcon from '@mui/icons-material/Error'
import InvalidIcon from '@mui/icons-material/Warning'
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tabs,
    TextField,
    Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import produce from 'immer'
import { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react'

import { DropZone, FILE_TYPES, MenuIcon, Spinner, SwalContext } from 'genesis-suite/components'
import { Close } from 'genesis-suite/icons'
import { FormColumn, TableFormConfig } from 'genesis-suite/types/visualTypes'
import { useSelector } from 'react-redux'
import { applicationSelectors, themeSelectors } from '~/selectors'
import { formsService } from '../../../../lib/services'

ModuleRegistry.registerModules([ClientSideRowModelModule])

const useStyles = makeStyles(({ palette, spacing }) => ({
    backdrop: { cursor: 'not-allowed' },
    cover: { display: 'flex', flexDirection: 'column' },
    titleContainer: { padding: spacing(1, 1, 0) },
    titleText: { marginLeft: spacing() },
    column: { display: 'flex', flexFlow: 'column nowrap' },
    cancelBtn: { marginRight: spacing(1), color: palette.text.primary },
    icon: { color: palette.text.primary },
}))

interface Props {
    form: TableFormConfig
    file: any
    updateFile: (file?: any) => void
    onSubmit: (excelData: any, tableName: string, mappedFields: string[]) => Promise<void>
}

interface FileMeta {
    fields: ExcelField[]
    name: string
    totalRecords: number
}

interface ExcelField {
    test: string
}

type UploadStep = 'init' | 'mapping' | 'validating'

export default function UploadFormExcelDialog({ form, onSubmit, file, updateFile }: Props) {
    const classes = useStyles({})
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const { confirm } = useContext(SwalContext)

    const [error, setError] = useState(null)
    const [mappings, setMappings] = useState<Mappings>({})
    const [fileMeta, setFileMeta] = useState<FileMeta>(null)
    const [openFileDrop, setOpenFileDrop] = useState(false)
    const [step, setStep] = useState<UploadStep>('init')
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState<ExcelData>(null)

    const formFields = useMemo(() => form?.columns.filter(c => c.editable || c.required), [form?.columns])
    const { fileName, fileToken } = file || {}

    useEffect(() => {
        if (!formFields.length || !fileToken) return

        setLoading(true)
        formsService
            .getExcelColumns(fileToken, appName)
            .then(({ fields: fileFields, totalRecords, tableName }) => {
                const newMappings = {}
                formFields.forEach(f => {
                    const columnMatch = fileFields.find(({ name }) => isMatch(name, f.title))
                    if (columnMatch) newMappings[f.property.name] = columnMatch.name
                })
                setMappings(newMappings)
                setFileMeta({ fields: fileFields, name: tableName, totalRecords })
            })
            .finally(() => {
                setLoading(false)
                setStep('mapping')
            })

        return () => {
            setMappings({})
        }
    }, [formFields, fileToken])

    function handleFileDrop(fileArr) {
        setOpenFileDrop(false)
        updateFile(fileArr)
        setData(null)
        setFileMeta(null)
        setMappings({})
        setStep('init')
    }

    async function handleConfirm() {
        switch (step) {
            case 'mapping':
                return handleMapping()
            case 'validating':
                return handleSubmit()
        }
    }

    async function handleMapping() {
        const excelColumns = Object.values(mappings)
        if (new Set(excelColumns).size !== excelColumns.length) {
            return setError('Invalid mappings. All fields must be mapped with unique Excel fields.')
        }

        const mappingFields = Object.keys(mappings)
        const requiredFields = formFields.filter(f => f.required)
        if (!requiredFields.every(f => mappingFields.includes(f.property.name))) {
            return setError('All key properties must be mapped before continuing.')
        }

        setError(null)
        setLoading(true)
        const serverMappings = mappingFields.map(fieldName => ({
            TableName: fileMeta.name,
            FieldName: fieldName,
            ColumnName: mappings[fieldName],
        }))

        const dataFormatter = (data: any[], type: DataType) => data?.map(r => ({ ...r, [typeField]: type })) || []

        formsService
            .mapExcelToFormColumns(appName, form.id, fileToken, serverMappings)
            .then(res => {
                const rows = [
                    ...dataFormatter(res.Data, 'good'),
                    ...dataFormatter(res.InvalidData, 'invalid'),
                    ...dataFormatter(res.UnAcceptedData, 'unacceptable'),
                ]
                if (!rows.length) return setError('No data was found in the Excel document. Please try again.')

                const header = Object.keys(rows[0])
                    .map(columnName => {
                        let mapping = serverMappings.find(m => m.ColumnName === columnName)
                        if (!mapping) mapping = serverMappings.find(m => m.FieldName === columnName)
                        const fieldName = mapping?.FieldName
                        if (!fieldName) return

                        const displayName = formFields.find(f => f.property.name === fieldName)?.title
                        return { columnName, displayName }
                    })
                    .filter(p => Boolean(p))

                const mergeTableName = res.MergeTableName
                const validRowCount = res.ValidCount
                const invalidRowCount = res.InvalidCount
                const unacceptableRowCount = res.UnAcceptedCount

                setData({ rows, header, mergeTableName, validRowCount, invalidRowCount, unacceptableRowCount })
                setStep('validating')
            })
            .catch(err => {
                console.error(err)
                setError('An error occurred uploading the Excel file. Please try again.')
            })
            .finally(() => setLoading(false))
    }

    async function handleSubmit() {
        const { mergeTableName, rows } = data
        const goodRows = rows.filter(r => r[typeField] === 'good')

        if (goodRows.length < rows.length) {
            const response = await confirm(`Only the valid rows will be uploaded`, {
                type: 'warning',
                confirmButtonProps: { text: 'Ok' },
            })
            if (response.dismiss) return
        }

        onSubmit(goodRows, mergeTableName, Object.keys(mappings))
            .catch(err => setError(err))
            .finally(() => setStep('validating'))
    }

    async function handleCancel() {
        const response = await confirm(`Your changes will be lost, press Ok to confirm`, {
            type: 'warning',
            confirmButtonProps: { text: 'Ok' },
        })
        if (response.dismiss) return
        updateFile()
    }

    function backToMappings() {
        setStep('mapping')
        setData(null)
    }

    return (
        <Dialog
            classes={{ container: classes.backdrop }}
            hideBackdrop
            open={Boolean(fileToken)}
            PaperProps={{
                sx: { width: '1000px', maxWidth: '90vw', cursor: 'default', height: '600px', maxHeight: '90vh' },
            }}
        >
            <DialogTitle className={classes.titleContainer}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box display="flex" alignItems="center">
                        <CloudUploadRounded />
                        <Typography className={classes.titleText} variant="h6">
                            Upload file
                        </Typography>
                    </Box>
                    <IconButton onClick={handleCancel} size="large">
                        <Close className={classes.icon} />
                    </IconButton>
                </Box>

                <Box
                    sx={theme => ({
                        '.MuiSvgIcon-root ': {
                            fill: theme.palette.text.primary,
                        },
                    })}
                    display="flex"
                    alignItems="center"
                    gap={1}
                >
                    <Typography>
                        File: {fileName} {fileMeta?.totalRecords ? `(${fileMeta.totalRecords} records)` : ''}
                    </Typography>
                    <MenuIcon
                        tooltip="New/Updated file"
                        icon={<Create fontSize="small" />}
                        buttonProps={{ size: 'small' }}
                        open={openFileDrop}
                        onClick={() => setOpenFileDrop(true)}
                        onClose={() => setOpenFileDrop(false)}
                    >
                        <DropZone
                            onDrop={handleFileDrop}
                            accept={FILE_TYPES.EXCEL}
                            message="Drop an Excel file or click to choose"
                        />
                    </MenuIcon>
                    {step === 'validating' && (
                        <>
                            <ForwardArrow sx={{ mx: 2 }} />
                            <Typography>Mappings: {Object.keys(mappings)?.length} columns</Typography>
                            <MenuIcon
                                tooltip="Edit"
                                icon={<Create fontSize="small" />}
                                buttonProps={{ size: 'small' }}
                                onClick={backToMappings}
                            />
                        </>
                    )}
                </Box>
            </DialogTitle>

            <DialogContent sx={{ p: 0 }}>
                <Spinner show={loading} className={classes.cover}>
                    {step === 'mapping' && fileMeta ? (
                        <Mapping
                            fileFields={fileMeta.fields}
                            formFields={formFields}
                            mappings={mappings}
                            onUpdate={setMappings}
                        />
                    ) : step === 'validating' && data ? (
                        <PreviewData data={data} />
                    ) : null}
                </Spinner>
            </DialogContent>

            <Box
                sx={{
                    display: 'flex',
                    p: 1,
                    flexDirection: 'column',
                    borderColor: `divider`,
                    borderTop: 1,
                    gap: 1,
                }}
            >
                {error && <Typography color="error">{error}</Typography>}
                <Box display="flex" alignItems="center" justifyContent="flex-end">
                    <Button
                        color="primary"
                        size="large"
                        onClick={handleCancel}
                        variant="outlined"
                        className={classes.cancelBtn}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        disabled={loading || data?.rows.filter(r => r[typeField] === 'good').length === 0}
                        onClick={handleConfirm}
                        size="large"
                        variant="contained"
                    >
                        {step === 'validating' ? 'Upload' : 'Next'}
                    </Button>
                </Box>
            </Box>
        </Dialog>
    )
}

interface MappingProps {
    fileFields: any[]
    formFields: FormColumn[]
    mappings: Mappings
    onUpdate: Dispatch<SetStateAction<Mappings>>
}

type Mappings = { [fieldName: string]: string }

function Mapping({ fileFields, formFields, mappings, onUpdate }: MappingProps) {
    const handleChange = name => e => {
        const val = e.target.value
        onUpdate(s =>
            produce(s, draft => {
                if (val) draft[name] = val
                else if (name in draft) delete draft[name]
            })
        )
    }

    return (
        <Table size="small" stickyHeader>
            <TableHead>
                <TableRow>
                    <TableCell>Form Field</TableCell>
                    <TableCell>Excel File Field</TableCell>
                    <TableCell>Sample Record</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {formFields.map(({ property, required, title }) => {
                    const fieldName = property.name
                    const val = mappings[fieldName]
                    return (
                        <TableRow key={fieldName}>
                            <TableCell sx={{ borderBottom: 'none' }}>
                                {title} {required && '*'}
                            </TableCell>
                            <TableCell sx={{ borderBottom: 'none' }}>
                                <TextField
                                    select
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    sx={theme => ({
                                        m: 0,
                                        svg: { color: theme.palette.text.primary },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: theme.palette.text.primary,
                                        },
                                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: theme.palette.text.primary,
                                        },
                                    })}
                                    value={val || ''}
                                    onChange={handleChange(fieldName)}
                                >
                                    {!required && <MenuItem sx={{ fontStyle: 'italic' }}>unset</MenuItem>}
                                    {fileFields.map(f => (
                                        <MenuItem key={f.name} value={f.name}>
                                            {f.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </TableCell>
                            <TableCell sx={{ borderBottom: 'none' }}>
                                {fileFields.find(f => f.name === val)?.firstRecord}
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
}

interface PreviewDataProps {
    data: ExcelData
}

function PreviewData({ data }: PreviewDataProps) {
    const themeMode = useSelector(themeSelectors.getThemeMode)
    const columnDefs = useMemo<ColDef[]>(() => {
        const dataTypeColumn: ColDef = {
            cellRenderer: DataTypeCellRenderer,
            field: typeField,
            headerName: '',
            pinned: 'left',
            suppressHeaderMenuButton: true,
            width: 57,
        }
        const fields = data.header.map<ColDef>(f => ({
            field: f.columnName,
            flex: 1,
            headerName: f.displayName,
            minWidth: 150,
            resizable: true,
            sortable: true,
        }))
        return [dataTypeColumn, ...fields]
    }, [data.header])

    const [filter, setFilter] = useState<DataType>('good')

    const goodRows = data.rows.filter(r => r[typeField] === 'good')
    const invalidRows = data.rows.filter(r => r[typeField] === 'invalid')
    const unacceptableRows = data.rows.filter(r => r[typeField] === 'unacceptable')
    const filteredRows = filter === 'good' ? goodRows : filter === 'invalid' ? invalidRows : unacceptableRows

    return (
        <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <Tabs value={filter} onChange={(e, v) => setFilter(v)} aria-label="basic tabs example">
                <Tab
                    label={`Valid (${data.validRowCount ? data.validRowCount : goodRows.length} records)`}
                    value="good"
                    sx={{ color: 'inherit' }}
                />
                <Tab
                    label={`Format errors (${
                        data.invalidRowCount ? data.invalidRowCount : invalidRows.length
                    } records)`}
                    value="invalid"
                    sx={{ color: 'inherit' }}
                />
                <Tab
                    label={`Key mismatch (${
                        data.unacceptableRowCount ? data.unacceptableRowCount : unacceptableRows.length
                    } records)`}
                    value="unacceptable"
                    sx={{ color: 'inherit' }}
                />
            </Tabs>

            <AgGridReact
                className={`ag-theme-alpine${themeMode === 'dark' ? '-dark' : ''}`}
                columnDefs={columnDefs}
                rowData={filteredRows}
            />
        </Box>
    )
}

function DataTypeCellRenderer(props) {
    const type: DataType = props.value
    const Icon = type === 'good' ? GoodIcon : type === 'invalid' ? InvalidIcon : UnacceptableIcon

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <Icon />
        </Box>
    )
}

const isMatch = (a, b) => a.replace(/\s/g, '').toLowerCase() === b.replace(/\s/g, '').toLowerCase()

const typeField = '__type'

interface ExcelData {
    header: Array<{ displayName: string; columnName: string }>
    rows: Array<Row>
    mergeTableName: string
    validRowCount?: number
    invalidRowCount?: number
    unacceptableRowCount?: number
}

interface Row {
    [typeField]: DataType
    [fieldName: string]: any
}

type DataType = 'good' | 'invalid' | 'unacceptable'
