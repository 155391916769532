import React, { useRef } from 'react'
import { useHover } from 'genesis-suite/hooks'
import { Spinner } from 'genesis-suite/components'
import CollapsableListItem from './CollapsableListItem'

import { List } from '@mui/material';

let CollapsableList = ({ loading, loader, items, collapsed }) => {
    const listRef = useRef()
    const listHover = useHover(listRef)

    if (loading) return loader || <Spinner />

    if (items.length === 0) return null

    return (
        <List disablePadding ref={listRef}>
            {items.map((item, index) => {
                return (
                    <CollapsableListItem
                        key={`${index}-${item.text}-${item.to}`}
                        {...item}
                        collapsed={collapsed}
                        showTooltips={listHover && collapsed}
                    />
                )
            })}
        </List>
    )
}

CollapsableList.defaultProps = {
    items: [],
}

export default CollapsableList
