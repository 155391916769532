import DownArrowIcon from '@mui/icons-material/ArrowDownward'
import UpArrowIcon from '@mui/icons-material/ArrowUpward'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import EditIcon from '@mui/icons-material/Edit'
import LockIcon from '@mui/icons-material/LockOutlined'
import MoreIcon from '@mui/icons-material/MoreVert'
import ShrinkIcon from '@mui/icons-material/UnfoldLess'
import ExpandIcon from '@mui/icons-material/UnfoldMore'
import HideIcon from '@mui/icons-material/VisibilityOff'
import { Box, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material'
import { useContext, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ChopText, MenuIcon } from 'genesis-suite/components'
import { useHover } from 'genesis-suite/hooks'
import {
    ChartType,
    InlineNodeFilter,
    InlineWidget as InlineWidgetConfig,
    NodeFilterWithValue,
    VisualType,
    Widget,
    WidgetPosition,
} from 'genesis-suite/types/visualTypes'
import { ColOptionsProvider } from '~/components/contexts/ColumnOptionsContext'
import { filterCreators, navigationCreators } from '../../../actions/creators'
import { routePaths } from '../../../lib/routes'
import { TourTag } from '../../../lib/tourSteps'
import { deploymentSelectors, filterSelectors, lockoutSelectors, widgetSelectors } from '../../../selectors'
import DraftIndicator from '../../DraftIndicator'
import WidgetMenu from '../../widgets/WidgetMenu'
import InlineWidget from '../InlineWidget'
import Widget2 from '../Widget2'
import { filterHasValue } from '../utils/contextFilterUtils'
import isInlineWidget from '../utils/isInlineWidget'
import { DashboardContext } from './DashboardContext'
import { WidgetWithPosition } from './dashboardUtils'

interface Props {
    config: WidgetWithPosition
    isControl?: boolean
}

export default function DashboardWidget({ config, isControl }: Props) {
    const lockedWidgets = useSelector(lockoutSelectors.getItems)
    const globalFilters = useSelector(filterSelectors.getAppliedGlobalFilters)
    const historyFilters = useSelector(filterSelectors.getBuilderFilters)
    const interactionType = useSelector(widgetSelectors.getInteractionType)
    const deploymentView = useSelector(deploymentSelectors.getDeploymentView)
    const dispatch = useDispatch()
    const { editing, filters: dashboardFilters } = useContext(DashboardContext)

    const lock = lockedWidgets.find(l => l.widgetId === config.id)
    const visualRef = useRef()
    const shrunkContainerRef = useRef()
    const isHovering = useHover(shrunkContainerRef)
    const [menuOpen, setMenuOpen] = useState(false)

    // TODO: Fix global filters because V1 filters (object NOT array) get in here now as a result of custom home screen
    const nodeFilters = useMemo(() => {
        const otherFilters = historyFilters.filter(f => f.source !== 'dashboard')
        return makeNetworkFilters([
            ...otherFilters,
            ...(dashboardFilters || []),
            ...(Array.isArray(globalFilters) ? globalFilters || [] : []),
        ])
    }, [dashboardFilters, historyFilters, globalFilters])

    const isInline = isInlineWidget(config.type)

    function handleNavigation(perspectiveId, filters, interactionTypeOverride) {
        dispatch(
            navigationCreators.goToDashboardWithFilters(
                perspectiveId,
                filters,
                interactionTypeOverride || interactionType
            )
        )
    }

    function handleInlineFilter(filters: InlineNodeFilter[]) {
        dispatch(filterCreators.setBuilderFilters('inline', filters))
    }

    return (
        <ColOptionsProvider id={config.id}>
            <Box sx={{ height: '100%', position: 'relative' }}>
                {editing && <WidgetEditCover config={config} />}

                {isShrunk(config) ? (
                    <Box ref={shrunkContainerRef} sx={{ height: '100%', position: 'relative' }}>
                        {isInline ? (
                            <InlineWidget config={config as InlineWidgetConfig} />
                        ) : (
                            <Widget2
                                ref={visualRef}
                                config={config as Widget}
                                deploymentView={deploymentView}
                                nodeFilters={nodeFilters}
                                interactions={{ onNavigate: handleNavigation, onInlineFilter: handleInlineFilter }}
                            />
                        )}

                        <Box position="absolute" right={0} top={0} display="flex" alignItems="center" gap={1}>
                            {!isInline && (
                                <>
                                    <LockIndicator lock={lock} />
                                    <DraftIndicator type={VisualType.WIDGET} config={config as Widget} />
                                </>
                            )}
                            {(isHovering || menuOpen) && !isInline && (
                                <WidgetMenu visualRef={visualRef} config={config} onToggle={setMenuOpen} />
                            )}
                        </Box>
                    </Box>
                ) : (
                    <Box
                        sx={{
                            bgcolor: 'background.widget',
                            border: 1,
                            borderColor: isControl ? 'primary.main' : 'grayscale.lightest',
                            borderRadius: '15px',
                            display: 'flex',
                            height: '100%',
                            flexDirection: 'column',
                            overflow: 'hidden',
                            p: 1,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Box display="flex" alignItems="center" gap={1}>
                                <ChopText variant="h6" showEllipsis tooltipProps={{ placement: 'top' }}>
                                    {(config as Widget).title}
                                </ChopText>
                                <DraftIndicator type={VisualType.WIDGET} config={config as Widget} />
                            </Box>

                            <Box
                                data-tour={TourTag.WidgetMenuButton}
                                sx={{ display: 'flex', alignItems: 'center', flexShrink: 0 }}
                            >
                                {!isInlineWidget(config.type) && <WidgetMenu visualRef={visualRef} config={config} />}
                            </Box>
                        </Box>

                        {isInline ? (
                            <InlineWidget config={config as InlineWidgetConfig} />
                        ) : (
                            <Widget2
                                ref={visualRef}
                                config={config as Widget}
                                deploymentView={deploymentView}
                                nodeFilters={nodeFilters}
                                interactions={{ onNavigate: handleNavigation, onInlineFilter: handleInlineFilter }}
                            />
                        )}
                    </Box>
                )}
            </Box>
        </ColOptionsProvider>
    )
}

function WidgetEditCover({ config }: { config: WidgetWithPosition }) {
    const lockedWidgets = useSelector(lockoutSelectors.getItems)
    const dispatch = useDispatch()

    const { config: dashboard, updateConfig: updateDashboard, devicePreview, widgets } = useContext(DashboardContext)
    const ref = useRef()
    const isHovering = useHover(ref)
    const [openMore, setOpenMore] = useState(false)

    const lock = lockedWidgets.find(l => l.widgetId === config?.id)
    const isDraggable = dashboard.widgets?.length > 1
    const onlyOneMainWidget = widgets.filter(w => !w.position?.top)?.length === 1
    const { hide, top } = config?.position || {}
    const shrink = isShrunk(config)
    const isInline = isInlineWidget(config.type)

    function handleEdit() {
        const { type, id } = config
        dispatch(navigationCreators.goTo(routePaths.EDIT, id, { type }))
    }

    function updatePosition(update: Partial<WidgetPosition>) {
        const widgets = dashboard.widgets.map((w, i) => {
            if (w.id) {
                if (w.id !== config.id) return w
            } else if (String(i) !== config.id) {
                return w
            }

            const resetPosition = 'top' in update || 'hide' in update
            const existing = resetPosition ? undefined : w.positions[devicePreview]
            return { ...w, positions: { ...w.positions, [devicePreview]: { ...existing, ...update } } }
        })

        updateDashboard({ widgets })
    }

    function handleRemove() {
        const isInline = isInlineWidget(config.type)
        const widgets = dashboard.widgets.filter((w, i) => (isInline ? Number(config.id) !== i : config.id !== w.id))
        updateDashboard({ widgets })
    }

    return (
        <Box
            ref={ref}
            sx={{
                zIndex: 2,
                position: 'absolute',
                height: '100%',
                width: '100%',
                borderRadius: '15px',
                borderBottomRightRadius: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            style={{ cursor: isDraggable ? 'grab' : 'inherit', background: lock ? '#00000055' : undefined }}
        >
            {(isHovering || openMore) && !lock && (
                <Box
                    sx={{
                        backgroundColor: 'primary.main',
                        color: 'primary.contrastText',
                        position: 'absolute',
                        top: 1,
                        right: 1,
                        borderRadius: '8px',
                    }}
                >
                    {!isInline && (
                        <IconButton size="small" onClick={handleEdit}>
                            <EditIcon sx={{ color: 'primary.contrastText' }} />
                        </IconButton>
                    )}

                    <MenuIcon
                        buttonProps={{ size: 'small' }}
                        icon={<MoreIcon sx={{ color: 'primary.contrastText' }} />}
                        onClick={() => setOpenMore(true)}
                        onClose={() => setOpenMore(false)}
                        open={openMore}
                    >
                        <List disablePadding>
                            {!dashboard?.homePage && (
                                <MoreItem
                                    disabled={!top && onlyOneMainWidget}
                                    Icon={top ? DownArrowIcon : UpArrowIcon}
                                    onClick={() => updatePosition({ top: !top })}
                                >
                                    Move {top ? 'to main panel' : 'to top panel'}
                                </MoreItem>
                            )}
                            {!defaultShrink(config.type) && !isInline && (
                                <MoreItem
                                    Icon={shrink ? ExpandIcon : ShrinkIcon}
                                    onClick={() => updatePosition({ shrink: !shrink })}
                                >
                                    {shrink ? 'Restore container' : 'Remove container'}
                                </MoreItem>
                            )}
                            <MoreItem Icon={HideIcon} onClick={() => updatePosition({ hide: !hide })}>
                                Hide widget from device
                            </MoreItem>
                            <MoreItem Icon={DeleteIcon} onClick={handleRemove}>
                                Remove from collection
                            </MoreItem>
                        </List>
                    </MenuIcon>
                </Box>
            )}

            {Boolean(lock) && (
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    gap={1}
                    color="#fff"
                >
                    <LockIcon sx={{ fontSize: '50px' }} />
                    <Typography color="inherit">{lock.user.firstName} is editing this widget</Typography>
                </Box>
            )}
        </Box>
    )
}

function LockIndicator({ lock }) {
    if (!lock) return null

    return (
        <Tooltip title={`${lock.user.firstName} is editing this widget`}>
            <LockIcon fontSize="small" color="warning" />
        </Tooltip>
    )
}

function MoreItem({ Icon, children, ...rest }) {
    return (
        <ListItemButton dense {...rest}>
            <ListItemIcon>
                <Icon />
            </ListItemIcon>
            <ListItemText primary={children} />
        </ListItemButton>
    )
}

function makeNetworkFilters(filters: NodeFilterWithValue[]) {
    const withValues = filters.filter(filterHasValue)
    // TODO - add more advanced logic to combine filters removing redundancy
    return withValues
}

const isShrunk = (config: WidgetWithPosition) =>
    defaultShrink(config.type) || !config.title || (config.position?.shrink ?? false)

function defaultShrink(type: ChartType) {
    switch (type) {
        case ChartType.LABEL:
            return true
        default:
            return false
    }
}
