import { Box, Typography } from '@mui/material'
import { useContext } from 'react'

import { DefaultErrorBoundary } from 'genesis-suite/components'
import { ChartType } from 'genesis-suite/types/visualTypes'
import { ConfigContext } from '../ConfigContext'
import BoxPlotSeriesSelector from '../selectors/BoxPlotSeriesSelector'
import CategorySelector from '../selectors/CategorySelector'
import ChartSelector from '../selectors/ChartSelector'
import FilterSelector from '../selectors/FilterSelector'
import MapSelector from '../selectors/MapSelector'
import SeriesSelector from '../selectors/SeriesSelector'
import SubSeriesSelector from '../selectors/SubSeriesSelector'
import TableAggregationSelector from '../selectors/TableAggregationSelector'
import TrellisSelector from '../selectors/TrellisSelector'
import ValuesSelector from '../selectors/ValuesSelector'
import DescriptionEditor from './DescriptionEditor'
import EditorWrapper from './EditorWrapper'
import LegendEditor from './LegendEditor'
import Orientation from './Orientation'
import PaginationEditor from './PaginationEditor'
import SortOrderEditor from './SortOrderEditor'
import { DynamicTableEditor } from './DynamicTableEditor'

const BasicFields = ({ className }) => {
    const { config } = useContext(ConfigContext)
    const { type, categories } = config

    const getComponents = () => {
        switch (type) {
            case ChartType.COMBO:
                return [
                    <FilterSelector key="0" root />,
                    <CategorySelector key="1" limit={1} label="Category axis" />,
                    <SeriesSelector key="2" label="Series" />,
                    <TrellisSelector key="3" label="Trellis" />,
                    <EditorWrapper key="4" header="Orientation">
                        <Orientation />
                    </EditorWrapper>,
                    <LegendEditor key="5" />,
                ]
            case ChartType.SORTED:
                return [
                    <FilterSelector key="0" />,
                    <CategorySelector key="1" limit={1} label="Category" />,
                    <ValuesSelector key="2" limit={1} label="Values" />,
                    <TrellisSelector key="3" label="Trellis" />,
                    <LegendEditor key="4" />,
                ]
            case ChartType.HEATMAP:
                return [
                    <FilterSelector key="0" />,
                    <CategorySelector key="1" limit={1} label="X Axis" />,
                    <SubSeriesSelector key="2" label="Y Axis" />,
                    <ValuesSelector key="3" limit={1} label="Values" />,
                    <LegendEditor key="4" />,
                ]
            case ChartType.LABEL:
                return [
                    <FilterSelector key="0" />,
                    <ValuesSelector key="2" limit={1} label="Value" />,
                    <TrellisSelector key="3" label="Trellis" />,
                ]
            case ChartType.MAP:
                return [<FilterSelector key="0" />, <MapSelector key="1" />]
            case ChartType.PIE:
                return [
                    <FilterSelector key="0" />,
                    <CategorySelector key="1" limit={1} label="Category" />,
                    <ValuesSelector key="2" limit={1} label="Values" />,
                    <TrellisSelector key="3" label="Trellis" />,
                    <LegendEditor key="4" />,
                ]
            case ChartType.PACKED_BUBBLE:
                return [
                    <FilterSelector key="0" />,
                    <CategorySelector key="1" limit={1} label="Category" />,
                    <ValuesSelector key="2" limit={1} label="Values" />,
                    <TrellisSelector key="3" label="Trellis" />,
                    <LegendEditor key="4" />,
                ]
            case ChartType.TABLE:
                return [
                    <FilterSelector key="0" />,
                    <ValuesSelector key="1" label="Columns" />,
                    <TableAggregationSelector key="2" />,
                    <DynamicTableEditor key="3" />,
                    <SortOrderEditor key="4" />,
                    <PaginationEditor key="5" />,
                ]
            case ChartType.BOX_PLOT:
                return [
                    <FilterSelector key="0" />,
                    <CategorySelector key="1" limit={1} label="Category axis" />,
                    <BoxPlotSeriesSelector key="2" label="Series" />,
                    <EditorWrapper key="3" header="Orientation">
                        <Orientation />
                    </EditorWrapper>,
                    <LegendEditor key="4" />,
                ]

            case ChartType.BULLET:
                return [
                    <FilterSelector key="0" />,
                    <SeriesSelector key="1" limit={categories.length > 0 ? 1 : undefined} label="Series" />,
                    <TrellisSelector key="2" label="Trellis" />,
                ]

            case ChartType.STATUS_TRACKER:
                return [<ValuesSelector key="0" label="Status" />]
            default:
                return [<div key="coming">Coming soon...</div>]
        }
    }

    return (
        <DefaultErrorBoundary>
            <div className={className}>
                <Typography gutterBottom variant="h6">
                    Widget config
                </Typography>
                <Box overflow="auto" flex="1">
                    <ChartSelector />
                    {getComponents().map(component => component)}
                    <DescriptionEditor />
                </Box>
            </div>
        </DefaultErrorBoundary>
    )
}

export default BasicFields