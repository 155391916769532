import React, { useState, useEffect, useRef, useContext } from 'react'
import { useSnackbar } from 'notistack'
import { useSelector } from 'react-redux'
import { Trash } from 'genesis-suite/icons'
import makeStyles from '@mui/styles/makeStyles'
import { ArrowDropDownRounded } from '@mui/icons-material'
import { DimLoader, SwalContext } from 'genesis-suite/components'
import { Button, Divider, IconButton, Menu, MenuItem } from '@mui/material'

import NewListDialog from './NewListDialog'
import TasksController from './TasksController'
import { taskService } from '../../lib/services'
import { moduleSelectors } from '../../selectors'
import { logEvent } from '../../lib/amplitudeClient'

const useTaskMenuStyles = makeStyles(({ palette }) => ({
    btns: {
        color: palette.text.primary,
    },
}))

export default function TaskListsController({ Header }) {
    const moduleId = useSelector(moduleSelectors.getCurrentModule).id

    const [lists, setLists] = useState([])
    const [tasks, setTasks] = useState([])
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [taskList, setTaskList] = useState(null)

    const { confirm } = useContext(SwalContext)

    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const errorSnackbar = err => showSnackbar(err, { variant: 'error' })

    const getLists = () => {
        logEvent('TASK_LISTS_FETCHED')
        setLoading(true)
        taskService
            .getTaskLists(moduleId)
            .then(lists => {
                setLists(lists)
                if (lists.length < 1) {
                    handleAddList('My task list')
                } else {
                    setTaskList(lists[0])
                }
            })
            .catch(({ message }) => errorSnackbar(message))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        getLists()
    }, [])

    useEffect(() => {
        if (taskList) {
            setLoading(true)
            taskService
                .getTasks(moduleId, taskList._id)
                .then(setTasks)
                .catch(({ message }) => errorSnackbar(message))
                .finally(() => setLoading(false))
        }
    }, [taskList])

    const handleAddList = name => {
        logEvent('TASK_LIST_CREATED')
        setOpen(false)
        setLoading(true)
        taskService
            .createList(moduleId, name)
            .then(list => {
                setLists([...lists, list])
                setTaskList(list)
            })
            .catch(({ message }) => errorSnackbar(message))
            .finally(() => setLoading(false))
    }

    const handleDeleteList = async () => {
        if (lists.length === 1) {
            errorSnackbar('You cannot delete your only list.')
            return
        } else {
            try {
                const response = await confirm('Delete this task list?', { type: 'question' })
                if (response.dismiss) return
                logEvent('TASK_LIST_DELETED')
                await taskService.deleteList(moduleId, taskList._id)
                setLists(lists.filter(l => l._id !== taskList._id))
                const newList = lists.find(l => l._id !== taskList._id)
                setTaskList(newList)
            } catch ({ message }) {
                errorSnackbar(message)
            }
        }
    }

    return (
        <>
            <Header>
                <TaskMenu
                    lists={lists}
                    onAdd={() => setOpen(true)}
                    taskList={taskList}
                    onSelect={setTaskList}
                    onDelete={handleDeleteList}
                />
            </Header>
            <TasksController listId={taskList?._id} tasks={tasks} onChange={setTasks} />
            {loading && <DimLoader />}
            <NewListDialog open={open} onClose={() => setOpen(false)} onSubmit={handleAddList} />
        </>
    )
}

const TaskMenu = ({ lists, taskList, onSelect, onAdd, onDelete }) => {
    const anchor = useRef(null)
    const [open, setOpen] = useState(false)

    const styles = useTaskMenuStyles()

    return (
        <>
            <Button className={styles.btns} ref={anchor} onClick={() => setOpen(true)}>
                {taskList?.name}
                <ArrowDropDownRounded />
            </Button>
            <IconButton
                onClick={onDelete}
                className={styles.btns}
                disabled={lists.length === 1}
            >
                <Trash fontSize="small" />
            </IconButton>
            <Menu anchorEl={anchor.current} open={open} onClose={() => setOpen(false)}>
                {lists.map(list => (
                    <MenuItem
                        key={list._id}
                        onClick={() => {
                            onSelect(list)
                            setOpen(false)
                        }}
                    >
                        {list.name}
                    </MenuItem>
                ))}
                <Divider />
                <MenuItem onClick={onAdd}>Create New List</MenuItem>
            </Menu>
        </>
    )
}
