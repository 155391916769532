import DownIcon from '@mui/icons-material/ArrowDropDown'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import DoneRoundedIcon from '@mui/icons-material/DoneRounded'
import SaveRoundedIcon from '@mui/icons-material/SaveRounded'
import { Box, IconButton, Menu, MenuItem, Tooltip } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import classNames from 'classnames'
import { MenuIcon } from 'genesis-suite/components'
import { useHover } from 'genesis-suite/hooks'
import { isEmpty } from 'lodash'
import { useEffect, useMemo, useReducer, useRef, useState } from 'react'
import Measure from 'react-measure'
import { useSelector } from 'react-redux'
import { useIsMobile } from '../hooks/useIsMobile'
import { authSelectors } from '../selectors'
import { FilterSourceType } from '../types/FilterTypes'
import FilterInput from './FilterInput'
import { isSelect } from './FilterOperatorButton'
import NeoFilterGroupController from './NeoFilterGroupController'

const filterWidth = 200
const moreButtonWidth = 40
const styles = ({ palette, spacing, breakpoints }) => ({
    wrapper: {
        flex: 1,
        display: 'flex',
        flexFlow: 'column wrap',
        justifyContent: 'space-around',
    },
    sourceInfo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    infoIcon: {
        margin: spacing(1),
    },
    contentContainer: {
        overflow: 'hidden',
        display: 'flex',
        alignContent: 'center',
        width: '100%',
    },
    noFiltersText: { padding: spacing(1), margin: 0 },
    controlsContainer: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '25px',
        position: 'absolute',
        right: 0,
        [breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            alignItems: 'center',
        },
        width: 120,
    },
    buttons: {
        height: 24,
        width: 24,
        margin: '2px',
        [breakpoints.down('sm')]: {
            width: '200px',
        },
    },
    splitButtons: {
        margin: spacing(1),
    },
    primarySplitButton: {
        [breakpoints.down('sm')]: {
            width: '200px',
        },
    },
    clearAllButton: {
        color: 'red',
        border: '2px solid',
    },
    saveButton: {
        color: 'green',
        border: '2px solid',
    },
    applyButton: { backgroundColor: palette.secondary.main, color: palette.getContrastText(palette.secondary.main) },
    cancelButton: {
        backgroundColor: palette.grayscale.lighter,
        color: palette.getContrastText(palette.grayscale.lighter),
    },
    defaultButton: {
        backgroundColor: palette.grayscale.lighter,
        color: palette.getContrastText(palette.grayscale.lighter),
    },
    listContainer: { display: 'flex', flexFlow: 'row wrap', marginBottom: '15px' },
    filterLabel: { fontWeight: 'bold' },
    downIcon: {
        backgroundColor: '#FDBD01',
        color: '#24292E',
        '&:hover': {
            backgroundColor: '#24292E',
            color: '#FDBD01 !important',
        },
    },
})

const CHANGE_VALUE = 'CHANGE_VALUE'
const CHANGE_RANGE = 'CHANGE_RANGE'
const CHANGE_RANGE_OFFSET = 'CHANGE_RANGE_OFFSET'
const CHANGE_CLICK_RANGE = 'CHANGE_CLICK_RANGE'
const CHANGE_OPERATOR = 'CHANGE_OPERATOR'
const CLEAR_ALL = 'CLEAR_ALL'
const CANCEL = 'CANCEL'
const INIT = 'INIT'

const NeoClassicFiltersSelector = ({
    filters: filterConfigs,
    selectedValues,
    saveFilters,
    saveFiltersForAll,
    applyFilters,
    onChange,
    onClear,
    onCancel,
    onSave,
    onSaveForAll,
    onApply,
    source,
    type,
    classes,
    isCompare = false,
}) => {
    const reducer = (state, action) => {
        const { type, payload } = action

        switch (type) {
            case CHANGE_VALUE: {
                const { id, values } = payload
                return {
                    ...state,
                    [id]: { ...state[id], values: values.filter(Boolean) },
                }
            }
            case CHANGE_RANGE: {
                const { id, range } = payload
                return {
                    ...state,
                    [id]: { ...state[id], range },
                }
            }
            case CHANGE_RANGE_OFFSET: {
                const { id, ...rest } = payload
                return {
                    ...state,
                    [id]: { ...state[id], ...rest },
                }
            }
            case CHANGE_CLICK_RANGE: {
                const { id, clickRangeName } = payload
                return {
                    ...state,
                    [id]: { ...state[id], clickRangeName },
                }
            }
            case CHANGE_OPERATOR: {
                const { id, operator } = payload
                const wasSelect = isSelect(state[id].operator)
                const toBeSelect = isSelect(operator)
                const values =
                    wasSelect && !toBeSelect
                        ? [state[id].values[0]?.label ?? '']
                        : !wasSelect && toBeSelect
                        ? []
                        : state[id].values

                return {
                    ...state,
                    [id]: { ...state[id], operator, values },
                }
            }
            case CANCEL:
                return filterConfigs.reduce((acc, filterConfig) => {
                    const { Name: id } = filterConfig
                    acc[id] = {
                        values: [],
                        range: {},
                        ...selectedValues[id],
                    }
                    return acc
                }, {})
            case CLEAR_ALL:
                return filterConfigs.reduce((acc, filterConfig) => {
                    acc[filterConfig.Name] = emptyFilter
                    return acc
                }, {})
            case INIT:
                return filterConfigs.reduce((acc, filterConfig) => {
                    const { Name: id } = filterConfig
                    acc[id] = { ...emptyFilter, ...selectedValues[id] }
                    return acc
                }, {})
            default:
                return
        }
    }

    const [filters, dispatch] = useReducer(reducer, {})
    const [width, setWidth] = useState(0)
    const all = filterConfigs || []
    const [openHidden, setOpenHidden] = useState(false)

    const visibleAmount =
        width > moreButtonWidth ? Math.min(Math.floor((width - moreButtonWidth) / filterWidth), all.length) : 0
    const visible = all.slice(0, visibleAmount)
    const hidden = all.slice(visibleAmount, all.length)

    useEffect(() => {
        dispatch({ type: INIT })
    }, [filterConfigs, selectedValues])

    const sourceInfo = useMemo(() => {
        switch (source) {
            case FilterSourceType.USER_DEFAULTS:
                return 'You are seeing these filters because you have saved them to your user preferences.'
            case FilterSourceType.WIDGET_DEFAULTS:
                return 'You are seeing these filters because they were set by your system administrator.'
            default:
                return
        }
    }, [source])

    const handleValueChange = (filter, values, action) => {
        const { Name: id } = filter
        if (!Array.isArray(values)) values = [values]
        dispatch({
            type: CHANGE_VALUE,
            payload: { id, values },
        })
        onChange(filter, values, action)
    }

    const handleRangeChange = (filter, range) => {
        const { Name: id } = filter
        dispatch({
            type: CHANGE_RANGE,
            payload: { id, range },
        })
    }

    const handleDateOffsetChange = (filter, rangeOffset, useLastRefreshDate, startDate, endDate) => {
        const { Name: id } = filter
        dispatch({
            type: CHANGE_RANGE_OFFSET,
            payload: { id, rangeOffset, useLastRefreshDate, startDate, endDate },
        })
    }

    const handleClickRangeChange = (filter, clickRangeName) => {
        const { Name: id } = filter
        dispatch({
            type: CHANGE_CLICK_RANGE,
            payload: { id, clickRangeName },
        })
    }

    const handleOperatorChange = (filter, operator) => {
        const { Name: id } = filter
        dispatch({
            type: CHANGE_OPERATOR,
            payload: { id, operator },
        })
    }

    const handleClear = () => {
        dispatch({
            type: CLEAR_ALL,
        })
        onClear()
    }

    const [dirty, setDirty] = useState(false)
    const makeDirty =
        cb =>
        (...args) => {
            if (!dirty) setDirty(true)
            cb(...args)
        }

    const handleCancel = () => {
        dispatch({
            type: CANCEL,
        })
        onCancel()
        setDirty(false)
    }

    const handleSave = (saveFunc, saveCallback) => {
        saveFunc(filters)
        saveCallback()
    }

    const handleApply = () => {
        applyFilters(filters)
        onApply()
        setDirty(false)
    }

    const handleFGApply = filtersToApply => {
        handleClear()
        filtersToApply?.map(({ filterVal, filterConfig }) => {
            const { values, range, dateRangeOffset, useLastRefreshDate, operator } = filterVal
            if (operator) handleOperatorChange(filterConfig, operator)
            if (values.length) handleValueChange(filterConfig, values)
            if (!isEmpty(range)) handleRangeChange(filterConfig, range)
            if (!isEmpty(dateRangeOffset) || useLastRefreshDate)
                handleDateOffsetChange(filterConfig, dateRangeOffset, useLastRefreshDate)
        })
        setDirty(true)
    }

    const isMobile = useIsMobile()
    const isPowerUser = useSelector(authSelectors.getIsPowerUser)

    const containerRef = useRef()
    const isHovering = useHover(containerRef)
    const [delayedHovering, setDelayedHovering] = useState(false)
    const [menuAnchor, setMenuAnchor] = useState(null)
    const hoverTimeout = useRef()
    useEffect(() => {
        clearTimeout(hoverTimeout.current)
        if (!isHovering) setDelayedHovering(false)
        else hoverTimeout.current = setTimeout(() => setDelayedHovering(true), 400)
    }, [isHovering])

    const saveOptions = [{ onClick: () => handleSave(saveFilters, onSave), label: 'Save as Default' }]

    if (saveFiltersForAll && isPowerUser && !isMobile)
        saveOptions.push({ onClick: () => handleSave(saveFiltersForAll, onSaveForAll), label: 'Save for All' })

    const handleSaveClick = e => {
        if (saveOptions?.length > 1) {
            setMenuAnchor(e.target)
            return
        }

        handleSave(saveFilters, onSave)
    }

    if (!filterConfigs || filterConfigs.length < 1) {
        return <h2 className={classes.noFiltersText}>No filters to display</h2>
    }

    // 45vh is the maxHeight of the contentContainer
    const maxFilterHeight = 50 / filterConfigs.length
    const compoundFilterConfigs = filterConfigs?.filter(conf => conf.IsCompound)

    const filterComponent = filter => {
        const { Name: id } = filter
        const { [id]: selected } = filters
        const compoundFilters = []

        if (filter.IsCompound && !isEmpty(compoundFilterConfigs)) {
            compoundFilterConfigs.forEach(comFilConf => {
                if (comFilConf.Name !== filter.Name && !isEmpty(filters[comFilConf.Name])) {
                    const { Name, Source, KeyPropertyName, IsTemporal, PostCalculation } = comFilConf

                    const { ElementName, ElementType } = Source

                    const { range, values, rangeOffset, clickRangeName, useLastRefreshDate, operator } = filters[Name]

                    if (!isEmpty(range) || rangeOffset?.min != null || rangeOffset?.max != null || !isEmpty(values)) {
                        const filterObj = {
                            FilterName: Name,
                            ResourceName: ElementName,
                            ResourceType: ElementType,
                            PropertyName: KeyPropertyName,
                            ClickRangeName: clickRangeName,
                            IsTemporal: IsTemporal,
                            UseLastRefreshDate: useLastRefreshDate,
                            PostCalculation: PostCalculation,
                            Operator: operator,
                        }
                        /**
                         * At a time either values or range or rangeoffset can be present in filter.
                         * All 3 together in single filter not likely as these come from different filter inputs.
                         * Hence including only one of these based on the availability
                         */
                        if (!isEmpty(values)) filterObj['Values'] = values.map(val => val.value)
                        else if (rangeOffset?.min != null || rangeOffset?.max != null)
                            filterObj['DateRangeOffset'] = rangeOffset
                        else if (!isEmpty(range)) filterObj['Range'] = range

                        compoundFilters.push(filterObj)
                    }
                }
            })
        }

        return (
            <FilterInput
                key={id}
                filter={filter}
                borderRadius={type === 'flex' ? '4px' : ''}
                labelFontSize={'10px'}
                selectedValues={selected || emptyFilter}
                contextFilters={compoundFilters}
                onValueChange={makeDirty(handleValueChange)}
                onRangeChange={makeDirty(handleRangeChange)}
                onDateOffsetChange={makeDirty(handleDateOffsetChange)}
                onClickRangeChange={makeDirty(handleClickRangeChange)}
                onOperatorChange={makeDirty(handleOperatorChange)}
                maxFilterHeight={maxFilterHeight}
                sx={{
                    width: filterWidth,
                    minWidth: filterWidth,
                    maxWidth: 400,
                    flex: '1 1 auto',
                    mx: 0.25,
                }}
                data-cy="autocomplete-input"
                showCheckBoxes={true}
                filterSelectedOptions={false}
                maxNumberOfTags={1}
                inputStyleProps={{
                    '& .MuiInputBase-root': {
                        maxHeight: '36px',
                        '& .MuiAutocomplete-tag': {
                            maxWidth: 'calc(100% - 26px)',
                        },
                    },
                }}
            />
        )
    }

    return (
        <div ref={containerRef} className={classes.wrapper}>
            <div className={classes.contentContainer}>
                <NeoFilterGroupController
                    filterConfigs={filterConfigs}
                    perspectiveFilters={filters}
                    onChange={handleFGApply}
                />
                <Measure offset onResize={contentRect => setWidth(contentRect.offset?.width)}>
                    {({ measureRef }) => (
                        <Box
                            sx={{
                                display: 'flex',
                                width: 'calc(100% - 180px)',
                                overflowX: 'auto',
                                overflowY: 'hidden',
                                mb: 0.5,
                            }}
                            ref={measureRef}
                        >
                            {visible.map(filter => {
                                return filterComponent(filter)
                            })}

                            {hidden.length > 0 && (
                                <MenuIcon
                                    buttonProps={{
                                        sx: {
                                            visibility: hidden.length > 0 ? 'visible' : 'hidden',
                                            color: 'text.primary',
                                            paddingTop: '20px',
                                        },
                                    }}
                                    icon={
                                        <DownIcon
                                            fontSize="large"
                                            sx={{
                                                cursor: 'pointer',
                                                borderRadius: 35 / 2,
                                            }}
                                            className={classes.downIcon}
                                        />
                                    }
                                    onClick={() => setOpenHidden(true)}
                                    onClose={() => setOpenHidden(false)}
                                    open={openHidden}
                                    tooltip="More Filters"
                                    popoverProps={{
                                        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                                        transformOrigin: { vertical: 'top', horizontal: 'left' },
                                    }}
                                >
                                    <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', maxWidth: '500px' }}>
                                        {hidden.map(filter => {
                                            return filterComponent(filter)
                                        })}
                                    </Box>
                                </MenuIcon>
                            )}
                        </Box>
                    )}
                </Measure>
                <div className={classes.controlsContainer}>
                    <Tooltip title="Clear All">
                        <IconButton
                            className={classNames(classes.buttons, classes.clearAllButton)}
                            onClick={makeDirty(handleClear)}
                        >
                            <ClearRoundedIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    {!isCompare && (
                        <>
                            <Tooltip title="Save Filters">
                                <IconButton
                                    className={classNames(classes.buttons, classes.saveButton)}
                                    onClick={e => handleSaveClick(e)}
                                >
                                    <SaveRoundedIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={() => setMenuAnchor(null)}>
                                <MenuItem onClick={() => handleSave(saveFilters, onSave)}>Save as default</MenuItem>
                                <MenuItem onClick={() => handleSave(saveFiltersForAll, onSaveForAll)}>
                                    Save for All
                                </MenuItem>
                            </Menu>
                        </>
                    )}
                    <Tooltip title="Cancel">
                        <IconButton
                            className={classNames(classes.buttons)}
                            sx={{
                                color: 'text.primary',
                            }}
                            onClick={handleCancel}
                        >
                            <ClearRoundedIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Apply">
                        <IconButton
                            className={classes.buttons}
                            onClick={handleApply}
                            disabled={!dirty}
                            data-cy="filter-apply-btn"
                            sx={{ color: 'green' }}
                        >
                            <DoneRoundedIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        </div>
    )
}

const emptyFilter = {
    values: [],
    range: {},
    rangeOffset: {},
    useLastRefreshDate: false,
    clickRangeName: null,
    operator: 'EqualTo',
}

NeoClassicFiltersSelector.defaultProps = {
    onChange: () => {},
    onClear: () => {},
    onCancel: () => {},
    onSave: () => {},
    onSaveForAll: () => {},
    onApply: () => {},
}

export default withStyles(styles)(NeoClassicFiltersSelector)
