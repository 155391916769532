import makeStyles from '@mui/styles/makeStyles'

import { DebouncedTextField } from 'genesis-suite/components'
import Widget2 from '../widgets2/Widget2'

const useStyles = makeStyles(({ spacing }) => ({
    widgetWrapper: { flex: 1, margin: spacing(0, 1, 1, 0), overflow: 'hidden' },
}))

export default function EditTree({ className, config, onChange }) {
    const classes = useStyles()
    if (config === null || config.treeName === '') return null
    return (
        <div className={className}>
            <DebouncedTextField
                debounceTime={350}
                sx={{ width: '400px' }}
                InputProps={{ sx: { typography: 'h6' } }}
                value={config.title}
                onChange={title => onChange(c => ({ ...c, title }))}
                placeholder="Widget title"
                autoFocus={!config?.title}
            />

            <div className={classes.widgetWrapper}>
                <Widget2 editing={true} config={config} />
            </div>
        </div>
    )
}
