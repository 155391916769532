import { useContext } from 'react'
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'

import { PieSeries, SeriesConfig } from 'genesis-suite/types/visualTypes'
import LabeledToggleButton from '../../LabeledToggleButton'
import { ConfigContext } from '../../edit_widget'
import EditorWrapper from '../../edit_widget/config_fields/EditorWrapper'
import SeriesDataLabelsEditor from '../../edit_widget/config_fields/SeriesEditor/SeriesDataLabelsEditor'
import { pieHemisphereOptions, PieOption } from '../../edit_widget/config_fields/SeriesEditor/SeriesEditorPie'
import LegendEditor from '../../edit_widget/config_fields/LegendEditor'

export default function PieFormatter() {
    const { dispatch, ...rest } = useContext(ConfigContext)
    const config = rest.config as SeriesConfig
    const seriesIndex = 0
    const activeSeries = config.series[seriesIndex] as PieSeries
    const { doughnut, hemisphere } = activeSeries || {}

    function handleUpdate(updated: Partial<PieSeries>) {
        dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: updated })
    }

    return (
        <div>
            <EditorWrapper header="Basic" startOpen>
                <FormControlLabel
                    label="Donut"
                    control={
                        <Checkbox
                            color="secondary"
                            checked={doughnut ?? false}
                            onChange={e => handleUpdate({ doughnut: e.target.checked })}
                        />
                    }
                />
                <Box mt={1} />
                <Typography variant="caption">Arc</Typography>
                <LabeledToggleButton
                    value={hemisphere ?? PieOption.FULL}
                    label=""
                    onChange={(e, hemisphere) => handleUpdate({ hemisphere })}
                    options={pieHemisphereOptions}
                />
            </EditorWrapper>

            <EditorWrapper header="Data labels">
                <SeriesDataLabelsEditor />
            </EditorWrapper>

            <LegendEditor />
        </div>
    )
}
