import { isEmpty } from 'lodash'

import { ChartType, DataResponse, SeriesConfig, Widget } from 'genesis-suite/types/visualTypes'
import { ParsedResponseWithConfig } from '../../../types/WidgetTypes'
import skipData from './skipData'

interface TrellisedData {
    data: DataResponse
    trellisGroup?: string
}

export default function trellisData(res: ParsedResponseWithConfig): TrellisedData[] {
    const { data, groupNames } = res
    if (isEmpty(data) || !res.config || skipData(res.config)) return [{ data: null }]

    const config = res.config as SeriesConfig

    if (config.trellis?.enabled && groupNames?.byCategory.length) {
        return groupNames?.byCategory[0].map(trellisGroup => {
            const parsedData = data.map(s => {
                const dataGroup = s.data.find(d => {
                    return d.group === trellisGroup
                })
                return {
                    meta: s.meta,
                    data: config.categories.length > 1 ? dataGroup.children : [dataGroup],
                }
            })

            return { data: parsedData, trellisGroup }
        })
    } else if (multiSeriesTrellis(config)) {
        return data.map((s, i) => {
            const { values, title } = config.series[i]
            return { data: [s], trellisGroup: title ?? values[0].field.name }
        })
    } else {
        return [{ data }]
    }
}

function multiSeriesTrellis(config: Widget) {
    const { type } = config

    switch (type) {
        case ChartType.BULLET: {
            return true
        }
        default:
            return false
    }
}
