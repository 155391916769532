import { perspectiveTypes } from '~/actions/types/perspective.types'
import { getPreference, setPreference } from '~/lib/browserPreferences'

const initialState = {
    autoRefresh: getPreference('perspectiveAutoRefresh') ?? true,
    showPerspectiveSummary: false,
}

export default (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case perspectiveTypes.TOGGLE_AUTO_REFRESH:
            setPreference('perspectiveAutoRefresh', !state.autoRefresh)
            return {
                ...state,
                autoRefresh: !state.autoRefresh,
            }
        case perspectiveTypes.TOGGLE_PERSPECTIVE_SUMMARY:
            return {
                ...state,
                showPerspectiveSummary: payload.value,
            }
        default:
            return state
    }
}
