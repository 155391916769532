import { WidgetActionDynamicStatus } from 'genesis-suite/types/visualTypes'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { widgetConstants } from '~/constants'
import { createFetchConfig } from '~/lib/utils'
import { widgetSelectors } from '~/selectors'
import { PerspectiveContext } from '../contexts/PerspectiveContext'
import { makeInitialWidgetControl } from '../widgets/Widget'
import { useWidgetData } from '../widgets/hooks/useWidgetData'
import { isEmpty } from 'lodash'
import hasData from '../widgets2/utils/hasData'

const { ERROR, WAITING, FETCHING, EMPTY } = widgetConstants.DataStatus
const { DISABLE, HIDE, NONE } = WidgetActionDynamicStatus

const useWidgetActionCall = ({ action, config, filters, inlineFilters, networkContext }) => {
    const { WidgetId, DynamicStatus, DynamicStatusWidgetId } = action
    const targetWidgetId = WidgetId ?? DynamicStatusWidgetId
    let preValidateWidgetActions =
        targetWidgetId && (DynamicStatus === DISABLE || DynamicStatus === HIDE) ? true : false
    const [isLoading, setIsLoading] = useState(preValidateWidgetActions)
    const [actionStatus, setActionStatus] = useState(preValidateWidgetActions ? DISABLE : NONE)
    const targetWidgetConfig = useSelector(state => widgetSelectors.getWidgetConfig(state, targetWidgetId))
    preValidateWidgetActions = isEmpty(targetWidgetConfig) ? false : preValidateWidgetActions

    if (preValidateWidgetActions) {
        const { filterByWidgetId } = useContext(PerspectiveContext)
        const widgetControl = makeInitialWidgetControl(targetWidgetConfig)
        const _config = useMemo(() => {
            return createFetchConfig(
                targetWidgetConfig,
                networkContext,
                filters,
                inlineFilters,
                filterByWidgetId?.[config.Id],
                widgetControl
            )
        }, [targetWidgetConfig, networkContext, filters, inlineFilters, filterByWidgetId, widgetControl])

        const { status, data } = useWidgetData(_config)

        useEffect(() => {
            if (status) {
                setIsLoading(() => handleLoading(status))
                setActionStatus(() => handleActionStatus(status))
            } else {
                setIsLoading(false)
                setActionStatus(NONE)
            }
        }, [status])

        const handleActionStatus = statusVal => {
            if ([WAITING, FETCHING].includes(statusVal)) {
                return DISABLE
            }

            if ([ERROR, EMPTY].includes(statusVal)) {
                return DynamicStatus
            }

            if (!hasData(targetWidgetConfig, data)) {
                return DynamicStatus
            }
            return NONE
        }

        const handleLoading = statusVal => {
            if ([WAITING, FETCHING].includes(statusVal)) {
                return true
            }
            return false
        }
    }
    return [isLoading, actionStatus]
}

export default useWidgetActionCall
