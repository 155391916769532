import { backupTypes } from '~/actions/types'

const initialState = {
    selected_backup: [],
}

export default (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case backupTypes.SELECTED_BACKUP: {
            return {
                ...state,
                selected_backup: payload,
            }
        }
        default:
            return state
    }
}
