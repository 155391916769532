import { useEffect, useMemo, useState, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import LayoutPerspectiveDialog from './LayoutPerspectiveDialog'
import makeStyles from '@mui/styles/makeStyles'
import { appearanceConstants } from '~/constants'
import { LayoutPerspectiveContext } from '~/components/contexts/LayoutPerspectiveContext'
import {
    Box,
    InputLabel,
    FormControl,
    Autocomplete,
    Typography,
    Tooltip,
    IconButton,
    TextField,
    Tabs,
    Tab,
} from '@mui/material'
import { AddRounded, Reorder } from '@mui/icons-material'
import { widgetCreators } from '~/actions/creators'
import { widgetSelectors } from '~/selectors'
import LayoutPerspectiveToolbar from './LayoutPerspectiveToolbar'
import useDashboardId from '~/hooks/useDashboardId'
import CancelPerspectiveDialog from './CancelPerspectiveDialog'
import TabsView from '../tabPerspective/TabsView'
import ReorderTabsDialog from './ReorderTabsDialog'

const { ScrollBarWidth } = appearanceConstants
const useStyles = makeStyles(({ palette, spacing, border }) => ({
    container: {
        position: 'relative',
        border: p => `1px solid ${palette.border?.main}`,
        backgroundColor: palette.background.widget,
        borderRadius: 15,
        display: 'flex',
        flexDirection: 'column',
        padding: '10px',
        marginTop: '10px',
        overflow: 'hidden',
        '& .ui.dimmer': {
            borderRadius: '15px',
            boxSizing: 'border-box',
        },
    },
    formControl: {
        margin: spacing(1),
        minWidth: 300,
    },
    divControl: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    tabControl: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    label: {
        display: 'flex',
        fontWeight: 'bold',
    },
    iconStyle: {
        color: palette.text.primary,
    },
    labelStyle: {
        '& label': {
            color: palette.text.primary,
        },
        '& label.Mui-focused': {
            color: palette.text.primary,
        },
    },
}))

export default function LayoutPerspectiveView() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { id } = useDashboardId()

    const { tabRows, tabValue, openDialog, setTabRows, setTabValue, setOpenDialog } =
        useContext(LayoutPerspectiveContext)

    const perspectives = useSelector(widgetSelectors.getAllPerspectives)
    const widgets = useSelector(widgetSelectors.getAllWidgets)
    const currentPerspective = useSelector(widgetSelectors.getCurrentPerspectiveConfig)
    const layoutPerspective = useSelector(widgetSelectors.getPerspectiveLayout)
    const perspectiveLayoutConfig = useSelector(widgetSelectors.getPerspectiveLayoutConfig(id))

    const [lpName, setLPName] = useState('')
    const [selectVal, setSelectVal] = useState(null)
    const [dialogError, setDialogError] = useState(false)
    const [cancelDialogObj, setCancelDialogObj] = useState({ open: false, message: '', success: '', cancel: '' })

    const [reOrder, setReOrder] = useState(false)

    useEffect(() => {
        if (currentPerspective?.Title) {
            setLPName(currentPerspective?.Title)
        }
    }, [currentPerspective])

    useEffect(() => {
        if (layoutPerspective.id === id && layoutPerspective?.data) {
            setLPName(layoutPerspective?.data?.perspectiveLayoutName)
            const layoutWidget = widgets.find(item => item.Id == layoutPerspective?.data?.layoutWidget)
            setSelectVal(layoutWidget)
            setTabRows(layoutPerspective?.data?.tabs)
        }
    }, [])

    const handleCloseDialog = () => {
        setOpenDialog({ data: '', open: false })
    }

    const handleOpenDialog = () => {
        setOpenDialog({ data: '', open: true })
    }

    const onClickReorderDialog = () => {
        setReOrder(!reOrder)
    }

    const onSubmit = obj => {
        let data = [...tabRows]
        const mappedPerspectives = obj.perspective.map(item => ({ Id: item.Id }))
        obj.perspective = mappedPerspectives
        data.push(obj)
        setTabRows(data)
        setTabValue(data.length - 1)
        handleCloseDialog()
    }

    const onUpdate = obj => {
        let data = [...tabRows]
        const mappedPerspectives = obj.perspective.map(item => ({ Id: item.Id }))
        obj.perspective = mappedPerspectives
        if (tabValue > -1) {
            data[tabValue] = obj
        }
        setTabRows(data)
        handleCloseDialog()
    }

    const onClickDialogBtn = obj => {
        const nameMatched = tabRows.some(o => o.name === obj.name)
        if (obj.perspective.length > 0 && obj.name && !nameMatched) {
            setDialogError(false)
            if (openDialog?.data?.perspective?.length > 0) {
                onUpdate(obj)
            } else {
                onSubmit(obj)
            }
        } else {
            setDialogError(true)
        }
    }

    const latestOptions = useMemo(() => {
        let result = tabRows?.flatMap(each => each.perspective.map(item => item.Id))
        if (openDialog?.data?.perspective?.length > 0) {
            const perspectiveArr = openDialog?.data?.perspective.map(item => item.Id)
            result = result.filter(item => !perspectiveArr.includes(item))
        }
        return perspectives.filter(item => !result.includes(item.Id)).sort((a, b) => a.Title.localeCompare(b.Title))
    }, [tabRows, openDialog])

    const handleChange = (event, newValue) => {
        setTabValue(newValue)
    }

    const onClickDone = () => {
        if (tabRows?.length > 0) {
            let obj = {
                perspectiveLayoutName: lpName,
                perspectiveId: id,
                layoutWidget: selectVal?.Id,
                tabs: tabRows,
            }
            dispatch(widgetCreators.savePerspectiveLayout(obj))
        } else {
            let obj = {
                open: true,
                message: 'Please select minimum one tab to Save Perspective Layout.',
                success: '',
                cancel: onClickCancelDialog,
            }
            setCancelDialogObj(obj)
        }
    }

    const onClickCancelDialog = () => {
        setCancelDialogObj({ open: false, message: '', success: '', cancel: '' })
    }

    const onClickCancel = () => {
        let obj = {
            open: true,
            message: 'Confirm the Cancellation of Perspective Layout by clicking on Submit button',
            success: onClickCancelDialogSubmit,
            cancel: onClickCancelDialog,
        }
        setCancelDialogObj(obj)
    }

    const onClickCancelDialogSubmit = () => {
        onClickCancelDialog()
        dispatch(widgetCreators.deletePerspectiveLayout(id))
    }

    const sortedWidgets = useMemo(() => {
        return widgets.sort((a, b) => a.Title.localeCompare(b.Title))
    }, [widgets])

    const orderedTabs = useMemo(() => {
        return tabRows.sort((a, b) => a?.Order - b?.Order)
    }, [tabRows])

    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'background.main',
                    paddingX: `${ScrollBarWidth}px`,
                    pt: 1,
                    width: '100%',
                    height: '100%',
                    overflowY: 'scroll',
                }}
            >
                <LayoutPerspectiveToolbar
                    lpName={lpName}
                    setLPName={setLPName}
                    onClickDone={onClickDone}
                    onClickCancel={onClickCancel}
                    isPerspectiveHasLayout={perspectiveLayoutConfig ? true : false}
                />
                <div className={classes.container}>
                    <div className={classes.divControl}>
                        <InputLabel
                            className={classes.label}
                            sx={{
                                color: 'text.primary',
                            }}
                        >
                            Select a Header :
                        </InputLabel>
                        <FormControl className={classes.formControl}>
                            <Autocomplete
                                size="small"
                                onChange={(event, value) => setSelectVal(value)}
                                value={selectVal}
                                options={sortedWidgets}
                                autoHighlight
                                getOptionLabel={option => option.Title}
                                renderInput={params => <TextField {...params} variant="outlined" />}
                                sx={{
                                    '& .MuiFormLabel-root, .MuiAutocomplete-endAdornment svg': {
                                        color: 'text.primary',
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'text.primary',
                                    },
                                }}
                            ></Autocomplete>
                        </FormControl>
                    </div>
                </div>
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            borderBottom: 2,
                            borderColor: 'border.main',
                            mt: 1,
                        }}
                    >
                        <Typography variant="h6" display="inline" fontWeight={700}>
                            Tabs
                        </Typography>
                        <Tooltip title="Add New Tab">
                            <span>
                                <IconButton sx={{ ml: 1 }} onClick={handleOpenDialog} className={classes.iconStyle}>
                                    <AddRounded />
                                </IconButton>
                            </span>
                        </Tooltip>
                        {tabRows.length > 1 && (
                            <Tooltip title="Reorder Tabs">
                                <span>
                                    <IconButton onClick={onClickReorderDialog} className={classes.iconStyle}>
                                        <Reorder />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        )}
                    </Box>
                    <Box sx={{ overflow: 'auto' }}>
                        <>
                            <Tabs
                                value={tabValue}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"
                            >
                                {orderedTabs.map((tab, key) => (
                                    <Tab label={tab.name} key={key} sx={{ color: 'inherit', fontWeight: 600 }} />
                                ))}
                            </Tabs>
                        </>
                    </Box>

                    <TabsView tabs={tabRows} tabValue={tabValue} isEdit={true} perspectives={perspectives} />
                </Box>
            </Box>
            <LayoutPerspectiveDialog
                openDialog={openDialog}
                handleCloseDialog={handleCloseDialog}
                classes={classes}
                latestOptions={latestOptions}
                onClickDialogBtn={onClickDialogBtn}
                dialogError={dialogError}
                tabRows={tabRows}
                perspectives={perspectives}
            />
            <CancelPerspectiveDialog cancelObj={cancelDialogObj} />
            {tabRows.length > 1 && (
                <ReorderTabsDialog
                    tabRows={tabRows}
                    setTabRows={setTabRows}
                    reOrder={reOrder}
                    onClose={onClickReorderDialog}
                />
            )}
        </>
    )
}
