import { useSelector } from 'react-redux'
import { Box, Tab } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import { TabContext, TabList, TabPanel } from '@mui/lab'

import { SideNav } from '.'
import ManageApprovals from '../components/ManageApprovals'
import PendingDraftsList from '../components/ManageApprovals/PendingDraftsList'
import { ApprovalsProvider } from '../components/contexts/ApprovalsContext'
import ManageDashboards from '../components/ManageDashboards'
import ManageModuleViews from '../components/manage_module_views/ManageModuleViews'
import ManageModules from '../components/ManageModules'
import ManageWidgets from '../components/ManageWidgets'
import ManagePublish from '../components/ManagePublish'
import { useFeature } from '../lib/featureFlags'
import { deploymentSelectors } from '../selectors'
import { ModuleViewsProvider } from '../components/manage_module_views/ModuleViewsContext'
import { PublishProvider } from '../components/contexts/PublishContext'
import PublishSideNav from '../components/ManagePublish/PublishSideNav'
import ManageIcons from './ManageIcons'

export default function ManageView() {
    const viewFlag = useSelector(deploymentSelectors.getDeploymentViewFlag)
    const [params, setSearchParams] = useSearchParams()
    const tab = params.get('tab') || 'modules'
    const [enable] = useFeature('builder')
    const [showApprovals] = useFeature('builder_drafts')

    const tabOptions: TabOption[] = [
        { value: 'modules', label: 'Apps', Content: ManageModules },
        {
            disabled: !enable,
            value: 'collections',
            label: 'Collections',
            Content: ManageDashboards,
            SideContent: ManageModuleViews,
        },
        {
            disabled: !enable,
            value: 'widgets',
            label: 'Widgets',
            Content: ManageWidgets,
            SideContent: ManageModuleViews,
        },
        {
            disabled: !enable || !showApprovals || !viewFlag,
            value: 'approvals',
            label: 'Approvals',
            Content: ManageApprovals,
            SideContent: PendingDraftsList,
        },
        {
            disabled: !enable || !showApprovals || !viewFlag,
            value: 'published',
            label: 'Published',
            Content: ManagePublish,
            SideContent: PublishSideNav,
        },
        {
            value: 'icons',
            label: 'Icons',
            Content: ManageIcons,
        },
    ]

    const currentTab = tabOptions.find(t => t.value === tab)
    const { disabled, SideContent } = currentTab || {}
    if (disabled) setSearchParams({ tab: 'modules' })

    return (
        <ModuleViewsProvider>
            <ApprovalsProvider>
                <PublishProvider>
                    <Box sx={{ flex: 1, overflow: 'hidden', display: 'flex' }}>
                        <SideNav forceOpen>{SideContent ? <SideContent /> : null}</SideNav>
                        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                            <TabContext value={tab}>
                                <Box sx={{ borderBottom: 1, borderColor: 'border.main' }}>
                                    <TabList
                                        TabIndicatorProps={{
                                            sx: {
                                                backgroundColor: 'text.primary',
                                            },
                                        }}
                                        onChange={(e, tab) => setSearchParams({ tab })}
                                        aria-label="Manage tabs"
                                    >
                                        {tabOptions.map(tab => (
                                            <Tab
                                                sx={{
                                                    color: 'text.primary',
                                                    '&.Mui-selected': {
                                                        color: 'text.primary',
                                                    },
                                                }}
                                                key={tab.value}
                                                disabled={tab.disabled}
                                                value={tab.value}
                                                label={tab.label}
                                            />
                                        ))}
                                    </TabList>
                                </Box>

                                {tabOptions.map(({ value, Content }) => (
                                    <TabPanel
                                        key={value}
                                        style={{ display: value === tab ? 'flex' : 'none' }}
                                        sx={{ flex: 1, p: 0, overflow: 'auto' }}
                                        value={value}
                                    >
                                        <Content />
                                    </TabPanel>
                                ))}
                            </TabContext>
                        </Box>
                    </Box>
                </PublishProvider>
            </ApprovalsProvider>
        </ModuleViewsProvider>
    )
}

interface TabOption {
    disabled?: boolean
    value: string
    label: string
    Content: (props: any) => JSX.Element | null
    SideContent?: (props: any) => JSX.Element | null
}
