import { useSelector, useDispatch } from 'react-redux'
import { SelectPopover } from 'genesis-suite/components'
import { OneCol, TwoCol, ThreeCol, TileLayout } from 'genesis-suite/icons'
import { widgetSelectors } from '../selectors'
import { widgetCreators } from '../actions/creators'

const makeConfig = hasLayouts => [
    { Icon: TileLayout, label: 'Flexible', value: 0, disabled: !hasLayouts },
    { Icon: OneCol, label: 'Comfortable', value: 1 },
    { Icon: TwoCol, label: 'Standard', value: 2 },
    { Icon: ThreeCol, label: 'Compact', value: 3 },
]

const SelectLayout = ({ iconProps, buttonStyle, disabled, handleResetWidgets }) => {
    const dispatch = useDispatch()
    const hasLayouts = Boolean(useSelector(widgetSelectors.getCurrentPerspectiveLayouts))
    const config = makeConfig(hasLayouts)

    const handleChange = item => {
        if (item.value === 0 && handleResetWidgets) {
            handleResetWidgets()
        }
        dispatch(widgetCreators.changeSelectedLayout(item.value))
    }

    return (
        <SelectPopover
            PlaceHolderIcon={TileLayout}
            value={config[useSelector(widgetSelectors.getCurrentLayout)]}
            tooltip="Change layout"
            items={config}
            iconProps={iconProps}
            buttonProps={{ sx: buttonStyle }}
            disabled={disabled}
            onChange={handleChange}
        />
    )
}

export default SelectLayout
