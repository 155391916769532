import { useContext } from 'react'
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import FullCircleIcon from '@mui/icons-material/TripOrigin'
import HalfCircleIcon from '@mui/icons-material/Looks'

import { ChartType } from 'genesis-suite/types/visualTypes'
import LabeledToggleButton from '../../../LabeledToggleButton'
import { ConfigContext } from '../../ConfigContext'
import EditorWrapper from '../EditorWrapper'
import ChartFormatEditor from '../FormatEditor/ChartFormatEditor'
import SeriesDataLabelsEditor from './SeriesDataLabelsEditor'
import SeriesTooltipEditor from './SeriesTooltipEditor'
import SortEditor from '../SortEditor'
import AggregationSelect from '../../AggregationSelect'

const useStyles = makeStyles(({ spacing }) => ({
    group: { marginBottom: spacing() },
}))

export enum PieOption {
    FULL = 'full',
    TOP = 'top',
    BOTTOM = 'bottom',
}

export const pieHemisphereOptions = [
    { label: <FullCircleIcon />, value: PieOption.FULL, tip: 'Full' },
    { label: <HalfCircleIcon />, value: PieOption.TOP, tip: 'Top' },
    { label: <HalfCircleIcon style={{ transform: 'rotate(180deg)' }} />, value: PieOption.BOTTOM, tip: 'Bottom' },
]

export default function SeriesEditorPie() {
    const { config, dispatch, selectedField } = useContext(ConfigContext)
    const classes = useStyles()

    if (config.type !== ChartType.PIE) return null

    const activeSeries = config.series[selectedField.index]

    const { doughnut, hemisphere } = activeSeries || {}

    return (
        <>
            <Box>
                <AggregationSelect />
            </Box>

            <CheckBox
                label="Donut"
                checked={doughnut || false}
                onChange={e => dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: { doughnut: e.target.checked } })}
            />

            <div className={classes.group}>
                <Typography variant="caption">Arc</Typography>
                <LabeledToggleButton
                    value={hemisphere || PieOption.FULL}
                    label=""
                    onChange={(e, hemisphere) => dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: { hemisphere } })}
                    options={pieHemisphereOptions}
                />
            </div>

            <div className={classes.group}>
                <Typography variant="caption">Sorting</Typography>
                <SortEditor pointer={selectedField} />
            </div>

            <EditorWrapper header="Data labels">
                <SeriesDataLabelsEditor />
            </EditorWrapper>

            <ChartFormatEditor />

            <EditorWrapper header="Tooltip">
                <SeriesTooltipEditor />
            </EditorWrapper>
        </>
    )
}

function CheckBox({ label, ...rest }) {
    return <FormControlLabel control={<Checkbox color="primary" {...rest} />} label={label} />
}
