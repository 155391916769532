import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { moduleSelectors } from '../selectors'
import { moduleService } from '../lib/services'

export default function useBackgroundImage() {
    const imageFileToken = useSelector(moduleSelectors.getModuleImageFileToken)
    const [imageBlob, setImageBlob] = useState('')

    useEffect(() => {
        if (!imageFileToken) return setImageBlob('')

        moduleService
            .getFiles(imageFileToken)
            .then(URL.createObjectURL)
            .then(setImageBlob)
            .catch(err => {
                console.error(err)
                setImageBlob('')
            })
    }, [imageFileToken])

    return imageBlob
}
