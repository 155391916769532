import { Hidden } from '@mui/material'
import { connect } from 'react-redux'

import { MenuIcon } from 'genesis-suite/components'
import { Globe } from 'genesis-suite/icons'
import { appearanceCreators } from '../actions/creators'
import { appearanceSelectors, filterSelectors } from '../selectors'
import GlobalFilters from './GlobalFilters'

const GlobalFiltersController = props => {
    const { disabled, filterCount, openFlag, togglePopover, iconStyle, buttonStyle } = props

    if (disabled) return null

    return (
        <Hidden mdDown>
            <MenuIcon
                disabled={disabled}
                open={openFlag}
                onClick={() => togglePopover()}
                onClose={() => togglePopover()}
                icon={<Globe sx={iconStyle} />}
                buttonProps={{ sx: buttonStyle, 'data-cy': 'global-filters-btn' }}
                title="Global Filters"
                tooltip="Global filters"
                badge={filterCount}
            >
                <GlobalFilters
                    onCancel={() => togglePopover()}
                    onSave={() => togglePopover()}
                    onApply={() => togglePopover()}
                />
            </MenuIcon>
        </Hidden>
    )
}

const mapStateToProps = state => {
    const globalFilters = filterSelectors.getGlobalFiltersConfig(state)
    const filterNames = globalFilters.map(filter => filter.Name)
    const filterCount = filterSelectors.getFilterSelectedValueCount(state, filterNames)
    const openFlag = appearanceSelectors.getShowGlobalFilters(state)

    return { disabled: !globalFilters.length, filterCount, openFlag }
}

const mapDispatchToProps = dispatch => ({
    togglePopover: () => {
        dispatch(appearanceCreators.toggleGlobalFilters())
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(GlobalFiltersController)
