import React from 'react'
import { Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ palette, lineShow, layout }) => ({
    emptyText: {
        fontStyle: 'italic',
        color: palette.grayscale.light,
    },
}))

export default function WidgetInfoEmptyText({ children }) {
    const classes = useStyles()

    return <Typography classes={{ root: classes.emptyText }}>{children}</Typography>
}
