import { WebviewConfig, ChartType } from 'genesis-suite/types/visualTypes'

export default async function webviewConverter(config): Promise<WebviewConfig> {
    const { Title, WebViewConfig } = config

    return {
        version: '2',
        type: ChartType.WEBVIEW,
        title: Title,
        url: WebViewConfig.Url,
    }
}
