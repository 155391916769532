import uniq from 'lodash/uniq'

import { insightTypes } from '../types'
import { insightSelectors } from '../../selectors'
import { applicationService } from '../../lib/services'

const getList = appName => (dispatch, getState) => {
    const state = getState()
    const hasFetched = insightSelectors.getHasFetched(state)
    const insightAppName = insightSelectors.getAppName(state)
    if (!appName || hasFetched || appName === insightAppName) return

    dispatch({ type: insightTypes.INSIGHTS_REQUEST })
    return applicationService
        .getInsights(appName)
        .then(data => {
            if (!data) return

            const insights = data.map(({ id, name, properties }) => ({
                id,
                name,
                nodeNames: uniq(
                    properties &&
                        properties.reduce((acc, cur) => {
                            if (cur.containerType === 'node') acc.push(cur.containerName)
                            return acc
                        }, [])
                ).sort(),
            }))

            dispatch({ type: insightTypes.INSIGHTS_SUCCESS, payload: { appName, insights } })
        })
        .catch(err => {
            console.error(err)
            dispatch({ type: insightTypes.INSIGHTS_FAILURE })
        })
}

export const insightCreators = {
    getList,
}
