import { Box, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

import { useHotKey } from 'genesis-suite/hooks'
import { House, Link, SideNavCollapse, SideNavOpen } from 'genesis-suite/icons'
import { appearanceCreators, dialogCreators, navigationCreators } from '../actions/creators'
import CollapsableList from '../components/CollapsableList'
import DockingMenuItem from '../components/DockingMenuItem'
import EditShortcuts, { editShortcutsDialogKey } from '../components/EditShortcuts'
import HistoryController from '../components/HistoryController'
import SessionHistory from '../components/SessionHistory'
import { AppearanceButton } from '../components/settings/ManageAppearance'
import { appearanceConstants } from '../constants'
import { useIsMobile } from '../hooks/useIsMobile'
import { HotKeys, createTip } from '../lib/hotkeys'
import { routePaths } from '../lib/routes'
import { TourTag } from '../lib/tourSteps'
import { appearanceSelectors, authSelectors, moduleSelectors, themeSelectors } from '../selectors'
import { ThemeMode } from '../types/ThemeTypes'
import BusinessElements from './sidenav/BusinessElements'
import Views from './sidenav/Views'
import { AppLogo, EditShortcutsButton } from './sidenav/shared'

const editButtonSize = 30
const commonButtonStyles = { color: 'text.primary' }

const useStyles = makeStyles(({ palette, spacing, custom }) => ({
    container: {
        backgroundColor: palette.background.sideNav,
        boxShadow: '6px 0 5px -4px #888',
        display: 'flex',
        flexDirection: 'column',
        width: custom.sideNav.width,
        zIndex: 1,
    },
    containerCollapsed: { width: custom.sideNav.collapsedWidth },
    divider: {
        width: 1,
        height: 20,
        backgroundColor: palette.border?.main,
        marginLeft: spacing(1),
    },
    header: {
        borderBottom: `1px solid ${palette.border?.main}`,
        display: 'flex',
        minHeight: appearanceConstants.SideNav.HEADER_HEIGHT,
        alignItems: 'center',
        position: 'relative',
    },
    home: { paddingTop: 0, paddingBottom: 0, flex: 1 },
    manage: { paddingTop: 0, paddingBottom: 0, flex: '1 0 auto' },
    menuButton: { margin: 'auto', color: palette.text.primary },
    editButton: {
        position: 'absolute',
        minHeight: 0,
        height: editButtonSize,
        width: editButtonSize,
        right: -editButtonSize / 2,
        bottom: -editButtonSize / 2,
        zIndex: 2,
    },
    content: {
        width: '100%',
        flex: 1,
        position: 'relative',
        overflow: 'auto',
    },
    footer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderTop: `1px solid ${palette.border?.main}`,
        gap: spacing(),
        height: appearanceConstants.SideNav.FOOTER_HEIGHT,
        padding: spacing(),
    },
    logo: { height: '16px' },
    logoSmall: { height: 24 },
    itemsWrapper: {
        display: 'flex',
        flexFlow: 'column nowrap',
        overflowY: 'auto',
        width: '100%',
    },
    scrolling: { borderBottom: `2px solid ${palette.grayscale.lighter}` },
    emptyText: ({ collapsed }) => ({
        fontStyle: 'italic',
        color: palette.text.primary,
        textAlign: collapsed ? 'center' : 'initial',
    }),
    _360Icon: { fontSize: 32 },
}))

export default function SideNav({ children, forceOpen = false }) {
    const sideNavOpen = useSelector(appearanceSelectors.getSideNavState)
    const open = forceOpen || sideNavOpen

    const classes = useStyles({ collapsed: !open })

    const shortcuts = useSelector(authSelectors.getShortcuts)
    const isPowerUser = useSelector(authSelectors.getIsPowerUser)
    const layout = useSelector(appearanceSelectors.getApplicationLayout)
    const modulePending = useSelector(moduleSelectors.getModulesPending)
    const themeMode = useSelector(themeSelectors.getThemeMode)
    const dispatch = useDispatch()
    const goHome = () => dispatch(navigationCreators.goTo(routePaths.HOME))
    const toggleSideNav = () => dispatch(appearanceCreators.toggleSideNav())
    const openEditShortcuts = () =>
        dispatch(dialogCreators.showDialog(editShortcutsDialogKey, EditShortcuts, { hideTitle: true }))

    useHotKey(HotKeys.SideNav, toggleSideNav)
    useHotKey(HotKeys.Home, goHome)
    const sideNavTip = createTip(HotKeys.SideNav)
    const homeTip = createTip(HotKeys.Home)
    const { spacing } = useTheme()

    const isMobile = useIsMobile()
    if (isMobile || layout === 'classic') return null

    const Home = () => (
        <DockingMenuItem
            noUnderline
            title={!open ? `Home ${homeTip}` : 'Home'}
            Icon={House}
            collapsed={!open}
            className={classes.home}
            onClick={!modulePending && goHome}
        />
    )

    const Header = () => (
        <div
            className={classes.header}
            style={{
                flexDirection: !open ? 'column' : 'row',
                justifyContent: !open ? 'center' : 'inherit',
                paddingRight: !open ? 0 : spacing(),
            }}
        >
            <Home />
            <AppearanceButton />
            {open ? <HistoryController /> : <SessionHistory buttonProps={{ sx: commonButtonStyles }} />}
            {!forceOpen && (
                <>
                    <div className={classes.divider} style={{ display: !open ? 'none' : 'initial' }} />
                    <Tooltip title={open ? `Collapse ${sideNavTip}` : `Open sidebar ${sideNavTip}`} placement="right">
                        <IconButton className={classes.menuButton} onClick={toggleSideNav} size="small">
                            {open ? <SideNavCollapse fontSize="small" /> : <SideNavOpen fontSize="small" />}
                        </IconButton>
                    </Tooltip>
                </>
            )}
        </div>
    )

    const Footer = () => (
        <Box className={classes.footer}>
            <AppLogo classes={classes} collapse={!open} />
            {open && (
                <Typography
                    sx={{
                        color: themeMode === ThemeMode.LIGHT ? 'tada.purple' : undefined,
                        fontSize: '11px',
                        fontStyle: 'italic',
                        lineHeight: 1,
                    }}
                >
                    secures your data with <nobr>end-to-end</nobr> encryption.
                </Typography>
            )}
        </Box>
    )

    const Shortcuts = () => {
        const rightIcon = () => <EditShortcutsButton sx={commonButtonStyles} />
        return (
            <DockingMenuItem
                dataTour={TourTag.Shortcuts}
                title="Shortcuts"
                Icon={Link}
                collapsed={!open}
                RightIcon={!modulePending && rightIcon}
                onRightIconClick={openEditShortcuts}
                noUnderline
                collapsable
            >
                <CollapsableList collapsed={!open} items={shortcuts} />
            </DockingMenuItem>
        )
    }

    const defaultContent = (
        <>
            <BusinessElements open={open} classes={classes} isPowerUser={isPowerUser} modulePending={modulePending} />
            <Views open={open} classes={classes} isPowerUser={isPowerUser} modulePending={modulePending} />
            <Shortcuts />
        </>
    )

    return (
        <div className={classnames(classes.container, { [classes.containerCollapsed]: !open })}>
            <Header />
            <div className={classes.content}>{children ? children : defaultContent}</div>
            <Footer />
        </div>
    )
}
