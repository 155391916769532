import { isEmpty } from 'lodash'
import { DashboardNodeFilter, NodeFilter, NodeFilterBase, NodeFilterWithValue } from 'genesis-suite/types/visualTypes'
import { useSelector } from 'react-redux'
import { filterSelectors } from '../../../selectors'
import { FilterSourceType } from '../../../types/FilterTypes'

export function filterHasValue(filter: NodeFilterWithValue) {
    if (!isEmpty(filter.values)) return true
    if (filter.source === 'dashboard') {
        if (filter.clickRangeName) return true
        if (!isEmpty(filter.range)) return true
    }
    return false
}

export function emptyDashboardFilterValues(filter: DashboardNodeFilter): NodeFilter {
    const { clickRangeName, operator, range, values, ...rest } = filter
    return rest
}

export function filtersMatch(a: NodeFilterBase, b: NodeFilterBase) {
    return a.nodeName === b.nodeName && a.keyPropertyName === b.keyPropertyName
}

export function useGlobalFilters() {
    const filters: DashboardNodeFilter[] = useSelector(filterSelectors.getGlobalFiltersConfig)
    const sessionFilters: DashboardNodeFilter[] = useSelector(filterSelectors.globalAppliedSelector)
    const userFilters: DashboardNodeFilter[] = useSelector(filterSelectors.getUserGlobalDefaults)

    if (!filters?.length) return

    const emptyFilters = filters.map(emptyDashboardFilterValues)
    function combine(usedFilters: DashboardNodeFilter[]): DashboardNodeFilter[] {
        return emptyFilters.map(empty => {
            return usedFilters?.find(used => filtersMatch(used, empty)) ?? empty
        })
    }

    if (sessionFilters) return { filters: combine(sessionFilters), source: FilterSourceType.SESSION }
    if (userFilters) return { filters: combine(userFilters), source: FilterSourceType.USER_DEFAULTS }
    return { filters, source: FilterSourceType.SESSION }
}
