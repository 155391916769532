import { Box } from '@mui/system'
import { isEqual } from 'lodash'
import { Dispatch, memo, useContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { DebouncedTextField } from 'genesis-suite/components'
import { ChartType, SeriesConfig } from 'genesis-suite/types/visualTypes'
import { ConfigContext } from '.'
import { applicationSelectors } from '../../selectors'
import cleanConfig from '../widgets2/utils/cleanConfig'
import Widget2 from '../widgets2/Widget2'
import { BuilderAction } from './builderReducer'
import { InteractionProps } from '../../types/WidgetTypes'

export default function EditWidget({ className }) {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const { config, dispatch } = useContext(ConfigContext)
    const [renderConfig, setRenderConfig] = useState(null)

    const lastConfig = useRef(null)
    useEffect(() => {
        const cleanedConfig = cleanConfig({ ...config, appName })
        if (!cleanedConfig) return

        if (isEqual(cleanedConfig, lastConfig.current)) return
        lastConfig.current = cleanedConfig
        setRenderConfig(cleanedConfig)
    }, [config])

    return <EditWidgetRender className={className} config={renderConfig} dispatch={dispatch} />
}

const EditWidgetRender = memo<{ className?: string; config: SeriesConfig; dispatch: Dispatch<BuilderAction> }>(
    ({ className, config, dispatch }) => {
        if (!config) return null

        function handlePointSelect(point: InteractionProps) {
            const { series = 0, groupNames } = point.indexes

            if (!groupNames) {
                dispatch({
                    type: 'SET_SELECTED_FIELD',
                    payload: { type: 'series', index: series },
                })
            } else {
                const categories = [...groupNames]
                const subSeries = config.series[series ?? 0].subSeries && categories.pop()
                dispatch({
                    type: 'SET_SELECTED_FIELD',
                    payload: { type: 'series', index: series, ...(subSeries && { subSeries }), categories },
                })
            }
        }

        return (
            <div className={className}>
                <DebouncedTextField
                    debounceTime={350}
                    sx={{ width: '400px' }}
                    InputProps={{ sx: { typography: 'h6' } }}
                    value={config?.title ?? ''}
                    onChange={title => dispatch({ type: 'UPDATE_CONFIG', payload: { title } })}
                    placeholder="Widget title"
                    autoFocus={!config?.title}
                />

                <Box mt={1} overflow="hidden" flexGrow={isFullHeight(config) ? 1 : 0}>
                    <Widget2
                        editing={true}
                        config={config}
                        interactions={{ onPointSelect: config.type !== ChartType.TABLE ? handlePointSelect : null }}
                        onData={res => dispatch({ type: 'SET_DATA_RESPONSE', payload: res })}
                    />
                </Box>
            </div>
        )
    }
)

function isFullHeight(config: SeriesConfig) {
    if (config.type === ChartType.LABEL) return false

    return true
}
