import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { DraftAction, VisualObjectByType, VisualType } from 'genesis-suite/types/visualTypes'
import { applicationSelectors } from '../../../selectors'
import { getVisualById } from '../../../lib/manageUtils'

/** Get the live config of a draft config if it is not new */
export default function useLiveWithDraft<T extends VisualType>(
    type: T,
    draftConfig: VisualObjectByType<T>
): { isNew: boolean; liveConfig: VisualObjectByType<T> } {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const [liveConfig, setLiveConfig] = useState(null)

    const isNew = draftConfig.draft.action === DraftAction.ADDED

    useEffect(() => {
        if (isNew) {
            if (liveConfig) setLiveConfig(null)
            return
        }

        getVisualById(appName, type, draftConfig.draft.visualId).then(setLiveConfig)
    }, [draftConfig, type])

    return { isNew, liveConfig }
}
