export const moduleTypes = {
    ALL_MODULE_UPDATE: 'ALL_MODULE_UPDATE',
    APP_MODULES_REQUEST: 'APP_MODULES_REQUEST',
    APP_MODULES_SUCCESS: 'APP_MODULES_SUCCESS',
    APP_MODULES_FAILURE: 'APP_MODULES_FAILURE',
    CURRENT_MODULE_UPDATE: 'CURRENT_MODULE_UPDATE',
    MODULE_CHANGE_REQUEST: 'MODULE_CHANGE_REQUEST',
    MODULE_RELOAD_REQUEST: 'MODULE_RELOAD_REQUEST',
    MODULE_SUCCESS: 'MODULE_SUCCESS',
    MODULE_2_SUCCESS: 'MODULE_2_SUCCESS',
    MODULE_FAILURE: 'MODULE_FAILURE',
    MODULE_CREATE: 'MODULE_CREATE',
    MODULE_UPDATE: 'MODULE_UPDATE',
    MODULE_DELETE: 'MODULE_DELETE',
    MODULE_UPDATE_DETECTED: 'MODULE_UPDATE_DETECTED',
    MODULE_UPDATE_DISMISSED: 'MODULE_UPDATE_DISMISSED',
    UPDATE_THEME: 'UPDATE_THEME',
    UPDATE_FILTERS: 'UPDATE_FILTERS',
    TOGGLE_MIGRATION: 'TOGGLE_MIGRATION',
    CLEAR_MODULES: 'CLEAR_MODULES'
}
