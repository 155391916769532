import { createSelector } from 'reselect'
import Store from '../store/storeTypes'

const getAnchorId = (s: Store) => s.businessExplorer.anchorId
const getConfigDictionary = (s: Store) => s.businessExplorer.configDictionary
const getSelectedConfigId = (s: Store) => s.businessExplorer.selectedConfigId
const getService = (s: Store) => s.businessExplorer.service

const getSelectedConfig = createSelector(
    [getConfigDictionary, getSelectedConfigId],
    (configs, selectedIndex) => configs?.[selectedIndex ?? 0]
)

export const businessExplorerSelectors = {
    getAnchorId,
    getConfigDictionary,
    getSelectedConfig,
    getSelectedConfigId,
    getService,
}
