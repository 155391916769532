import { LinearProgress } from '@mui/material'
import {
    GridOverlay,
    SortGridMenuItems,
    GridColumnMenuProps,
    GridColumnMenuContainer,
    GridColumnPinningMenuItems,
} from '@mui/x-data-grid-pro'

function ColumnMenu(props: GridColumnMenuProps) {
    return (
        <GridColumnMenuContainer {...props}>
            <SortGridMenuItems onClick={props.hideMenu} column={props.currentColumn} />
            <GridColumnPinningMenuItems onClick={props.hideMenu} column={props.currentColumn} />
        </GridColumnMenuContainer>
    )
}

function LoadingOverlay() {
    return (
        <GridOverlay>
            <div style={{ position: 'absolute', top: 0, width: '100%', zIndex: 1 }}>
                <LinearProgress />
            </div>
        </GridOverlay>
    )
}

export const TableComponents = { ColumnMenu, LoadingOverlay }
