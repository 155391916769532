import { Box, Typography } from '@mui/material'

import { DraftAction, Module, VisualType } from 'genesis-suite/types/visualTypes'
import DisplayDraftDiff from '../widgets2/DisplayDraftDiff'

interface Props {
    config: Module
}

export default function ApprovalModule({ config }: Props) {
    return (
        <Box display="flex" flexDirection="column" overflow="hidden">
            <Typography variant="h5" gutterBottom sx={{ ml: 1 }}>
                {config.draft.action === DraftAction.ADDED ? 'New App' : 'Updated App'}
            </Typography>
            <DisplayDraftDiff config={config} type={VisualType.MODULE} />
        </Box>
    )
}
