import DesktopIcon from '@mui/icons-material/DesktopWindowsOutlined'
import PhoneIcon from '@mui/icons-material/PhoneIphone'
import { IconButton, Tooltip } from '@mui/material'
import { useContext } from 'react'

import { DashboardDevice } from 'genesis-suite/types/visualTypes'
import { DashboardContext } from '../DashboardContext'

export default function DashboardDevicePreviewToggle() {
    const { devicePreview, setDevicePreview } = useContext(DashboardContext)

    function handlePhoneToggle() {
        setDevicePreview(devicePreview === DashboardDevice.LARGE ? DashboardDevice.SMALL : DashboardDevice.LARGE)
    }

    const Icon = devicePreview === DashboardDevice.LARGE ? DesktopIcon : PhoneIcon

    return (
        <Tooltip title="Change device preview">
            <IconButton onClick={handlePhoneToggle}>
                <Icon />
            </IconButton>
        </Tooltip>
    )
}
