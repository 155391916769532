const getChatMessages = state => state.chat.chatMessages

const getAIChatMessages = state => state.chat.messages

const isSplitScreenOn = state => state.chat.splitScreenOn

const getAIChatSessionId = state => state.chat.aiChatSessionId

const getChatDensity = state => state.chat.chatDensity

const getChatSettings = state => state.chat.settings

const getAIChatVersion = state => state.chat.version

export const chatSelectors = {
    getChatMessages,
    getAIChatMessages,
    isSplitScreenOn,
    getAIChatSessionId,
    getChatDensity,
    getChatSettings,
    getAIChatVersion,
}
