import { Button, Drawer } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Menu } from 'genesis-suite/icons'
import { useState } from 'react'
import { MenuView } from '../views'

const useStyles = makeStyles(theme => ({
    button: {
        minWidth: 'unset',
        padding: 0,
    },
    drawer: {
        width: '80%',
    },
    icon: {
        color: theme.palette.getContrastText(theme.palette.background.topBar),
    },
}))

const MobileMenu = () => {
    const [open, setOpen] = useState(false)
    const classes = useStyles()

    return (
        <>
            <Button classes={{ root: classes.button }} onClick={() => setOpen(true)}>
                <Menu className={classes.icon} />
            </Button>
            <Drawer classes={{ paper: classes.drawer }} open={open} onClose={() => setOpen(false)}>
                <MenuView onClose={() => setOpen(false)} />
            </Drawer>
        </>
    )
}

export default MobileMenu
