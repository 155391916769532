import { ClickAwayListener, Paper, Popper, Typography } from '@mui/material'
import { isEqual } from 'lodash'
import { useContext, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import DebouncedTextField, { Props } from 'genesis-suite/components/DebouncedTextField'
import { filterCreators } from '~/actions/creators'
import useSearch from '~/hooks/useSearch'
import { updateOrableContextFilters } from '~/lib/utils'
import { filterSelectors, moduleSelectors } from '~/selectors'
import GroupedList from '../GroupedList'
import { PerspectiveContext } from '../contexts/PerspectiveContext'

export default function PerspectiveSearchFilter(props: Props) {
    const enabled = useSelector(moduleSelectors.getEnableSearch)
    const filters = useSelector(filterSelectors.getAppliedSearchFilters)

    const { id } = useContext(PerspectiveContext)
    const [text, setText] = useState('')
    const inputRef = useRef()

    const dispatch = useDispatch()
    const onUpdate = filters => dispatch(filterCreators.applySearchFilters(filters))

    const [searchResults] = useSearch(text, id)
    const pending = text && !searchResults
    const noResults = searchResults?.length === 0

    const listItems = useMemo(() => {
        if (!searchResults) return null

        return searchResults.reduce((acc, cur) => {
            const { nodeName, displayProperty, context } = cur
            if (filters.some(f => isEqual(f, context))) return acc

            let nodeListIndex = acc.findIndex(l => l.primary === nodeName)
            if (nodeListIndex === -1) {
                acc.push({ primary: nodeName, list: [] })
                nodeListIndex = acc.length - 1
            }

            acc[nodeListIndex].list.push({
                primary: displayProperty.value,
                onClick: () => handleSelect(context),
            })
            return acc
        }, [])
    }, [filters, searchResults])

    if (!enabled) return

    function handleSelect(context) {
        const filterName = `search|${context.Name}|${context.FieldName}`
        onUpdate(updateOrableContextFilters(filters, filterName, 'EqualTo', context))
        setText('')
    }

    return (
        <>
            <div id="perspective-search">
                <DebouncedTextField
                    debounceTime={500}
                    inputRef={inputRef}
                    onChange={setText}
                    placeholder="Find"
                    value={text}
                    {...props}
                    sx={{
                        width: '100px',
                        '& .MuiInputBase-root': {
                            '&.MuiInput-root': {
                                '&.MuiInput-underline': {
                                    '&:before, &:after': {
                                        borderBottomColor: 'text.primary',
                                    },
                                },
                            },
                        },
                        ...props.sx,
                    }}
                />
            </div>

            <Popper anchorEl={inputRef.current} open={Boolean(text)} placement="bottom-start" sx={{ zIndex: 1301 }}>
                <ClickAwayListener onClickAway={() => setText('')}>
                    <Paper
                        style={
                            pending || noResults ? { display: 'flex', minWidth: '150px', minHeight: '60px' } : undefined
                        }
                        sx={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            maxHeight: '400px',
                            maxWidth: '600px',
                            overflow: 'auto',
                        }}
                    >
                        <GroupedList data={listItems} pending={pending} />
                        {noResults && <Typography>No results</Typography>}
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </>
    )
}
