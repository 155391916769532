import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
    Box,
    Button,
    IconButton,
    ListItem,
    ListItemText,
    MenuItem,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import AddIcon from '@mui/icons-material/AddCircle'
import AddAllIcon from '@mui/icons-material/DoneAll'
import RemoveIcon from '@mui/icons-material/Clear'
import DragIcon from '@mui/icons-material/DragIndicator'
import HelpIcon from '@mui/icons-material/HelpOutline'
import ErrorIcon from '@mui/icons-material/ErrorOutline'
import { useSnackbar } from 'notistack'
import produce from 'immer'
import { useDrag, useDrop } from 'react-dnd'
import clsx from 'clsx'

import { Property, ResourceType, ProfileConfig, ServerProfileConfig, IdAndName } from 'genesis-suite/types/networkTypes'
import { makeVisibleNodesAndLinks } from 'genesis-suite/utils/networkUtils'
import { ChopText, Collapsable, ErrorButton, MenuIcon, Spinner } from 'genesis-suite/components'
import { applicationSelectors, linksSelectors } from '../../selectors'
import useResourceMeta from '../../hooks/useResourceMeta'
import { architectureService } from '../../lib/services'
import ParagraphSkeleton from '../loaders/ParagraphSkeleton'
import BooleanSelect from '../BooleanSelect'

const useStyles = makeStyles(({ spacing, palette }) => ({
    overflowParent: { display: 'flex', flexDirection: 'column', overflow: 'hidden' },
    select: { padding: spacing() },
    disabledSelect: { color: 'inherit !important' },
    linkedNodeHeader: { flex: 1, display: 'flex', alignItems: 'center', gridGap: spacing(), padding: spacing(0.5, 0) },
    property: {
        display: 'flex',
        alignItems: 'center',
        gridGap: spacing(),
        padding: spacing(0.25, 0, 0.25, 1),
        cursor: 'grab',
    },
    newItemContainer: { display: 'flex', alignItems: 'center' },
    borderTop: { borderTop: '2px solid grey' },
    linkedNodeProperties: {
        margin: spacing(0, 1),
        padding: spacing(0.5, 0, 0.5, 1),
        background: palette.background.main,
        borderRadius: '4px',
    },
    lastItem: { height: '20px' },
    dragIcon: { cursor: 'grab' },
    newItem: { padding: 0 },
    colorBtn: { color: palette.text.primary },
    actionBtn: { background: palette.background.main, color: palette.text.primary },
}))

interface Props {
    initialNodeName: string
    preventNodeChange?: boolean
    onDone: () => void
}

enum dragType {
    LINKED_NODE = 'LINKED_NODE',
    PROPERTY = 'PROPERTY',
}

export default function EditProfile({ initialNodeName, preventNodeChange, onDone }: Props) {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const links = useSelector(linksSelectors.getLinks)
    const linkedNodes = useSelector(linksSelectors.getLinkedNodes)

    const { enqueueSnackbar: showSnackbar } = useSnackbar()

    const [nodeName, setNodeName] = useState('')
    const [neighborNodes, setNeighborNodes] = useState<IdAndName[]>(null)
    const [draftProfile, setDraftProfile] = useState<ProfileConfig>(null)
    const [initialLinkedNodeIds, setInitialLinkedNodeIds] = useState<string[]>([])
    const [dirty, setDirty] = useState(false)
    const [loading, setLoading] = useState(false)

    const [node, refreshNode] = useResourceMeta(ResourceType.NODE, nodeName)

    const unusedNodes = neighborNodes
        ?.filter(n => !draftProfile?.linkedNodes?.some(l => l.id === n.id))
        .sort((a, b) => a.name.localeCompare(b.name))
    const errors = checkForErrors(nodeName, draftProfile)

    const classes = useStyles()

    useEffect(() => {
        if (initialNodeName) setNodeName(initialNodeName)
    }, [initialNodeName])

    useEffect(() => {
        if (!links || !nodeName || !node) return

        const nodeId = node.id
        const neighborLinkAndNodeIds = makeVisibleNodesAndLinks(links, [nodeId], 2)
        const neighborNodes = neighborLinkAndNodeIds
            ?.map(id => linkedNodes?.find(n => n.id === id))
            .filter(n => Boolean(n) && n.id !== nodeId)
        setNeighborNodes(neighborNodes)
    }, [links, linkedNodes, nodeName, node])

    useEffect(() => {
        setDirty(false)
        setDraftProfile(!node ? null : node.profile ?? { properties: [], linkedNodes: [] })
        setInitialLinkedNodeIds(node?.profile?.linkedNodes.map(n => n.id) ?? [])
    }, [node])

    function handleChangeNode(e) {
        const name = e.target.value
        setNodeName(name)
    }

    function handleRootPropertyChange(v) {
        setDirty(true)
        setDraftProfile(s => ({ ...s, properties: v }))
    }

    function handleLinkedNodesReorder(fromId, beforeId) {
        setDirty(true)
        const linkedNodes = reorderItems(draftProfile.linkedNodes, fromId, beforeId)
        setDraftProfile(s => ({ ...s, linkedNodes }))
    }

    function handleAddLinkedNodes(ids) {
        const newNodes: LinkedNode[] = ids.map(id => {
            const n = linkedNodes.find(n => n.id === id)
            return { id: n.id, name: n.name, properties: [] }
        })
        setDraftProfile(s => ({ ...s, linkedNodes: [...s.linkedNodes, ...newNodes] }))
        setDirty(true)
    }

    function handleRemoveLinkedNode(id) {
        const linkedNodes = draftProfile.linkedNodes.filter(n => n.id !== id)
        setDraftProfile(s => ({ ...s, linkedNodes }))
        setDirty(true)
    }

    function handleUpdateLinkedNodeProperties(id, properties) {
        setDirty(true)
        setDraftProfile(s =>
            produce(s, draft => {
                const index = draft.linkedNodes.findIndex(n => n.id === id)
                draft.linkedNodes[index].properties = properties
            })
        )
    }

    async function handleSave() {
        setLoading(true)
        const body = convertToSaveProfile(nodeName, draftProfile)
        try {
            await architectureService.saveProfile(appName, body)
            await refreshNode()
            setDirty(false)
        } catch (err) {
            console.error(err)
            showSnackbar('Failed to save', { variant: 'error' })
        }
        setLoading(false)
    }

    return (
        <Spinner hideCover className={classes.overflowParent} show={loading}>
            <div className={classes.overflowParent}>
                {!preventNodeChange && (
                    <TextField
                        select
                        variant="outlined"
                        fullWidth
                        className={classes.select}
                        InputProps={{ classes: { disabled: classes.disabledSelect } }}
                        disabled={dirty}
                        value={nodeName}
                        onChange={handleChangeNode}
                    >
                        {linkedNodes.map(({ name }) => (
                            <MenuItem key={name} value={name}>
                                {name}
                            </MenuItem>
                        ))}
                    </TextField>
                )}

                <div className={classes.overflowParent}>
                    <Box display="flex" flexDirection="column" overflow="auto" p={1}>
                        <Box display="flex" alignItems="center" gap="8px">
                            <Typography variant="caption">Properties</Typography>
                            {node && !node.properties.length && (
                                <Tooltip title="Select at least one property">
                                    <ErrorIcon fontSize="small" />
                                </Tooltip>
                            )}
                        </Box>

                        <ManageProperties
                            classes={classes}
                            parentId="properties"
                            properties={node?.properties}
                            selected={draftProfile?.properties}
                            onChange={handleRootPropertyChange}
                        />

                        <Box display="flex" alignItems="center" gap="8px" mt={2}>
                            <Typography variant="caption">Related elements</Typography>
                            <Tooltip title="Elements that are connected to current profile element. Expand each element to select desired properties.">
                                <HelpIcon fontSize="small" />
                            </Tooltip>
                        </Box>
                        {draftProfile?.linkedNodes ? (
                            <>
                                <div>
                                    {draftProfile.linkedNodes.map((n, index) => (
                                        <ManageLinkedNode
                                            key={n.id}
                                            {...n}
                                            classes={classes}
                                            expanded={!initialLinkedNodeIds.includes(n.id)}
                                            onOrder={handleLinkedNodesReorder}
                                            onRemove={() => handleRemoveLinkedNode(n.id)}
                                            onUpdateProperties={v => handleUpdateLinkedNodeProperties(n.id, v)}
                                        />
                                    ))}

                                    <LastItem
                                        classes={classes}
                                        accept={dragType.LINKED_NODE}
                                        onOrder={handleLinkedNodesReorder}
                                    />
                                </div>

                                {unusedNodes?.length ? (
                                    <ManageNewItems
                                        classes={classes}
                                        caption={`${unusedNodes.length} unused element${
                                            unusedNodes.length === 1 ? '' : 's'
                                        }`}
                                        items={unusedNodes}
                                        onAdd={handleAddLinkedNodes}
                                    />
                                ) : null}
                            </>
                        ) : (
                            <ParagraphSkeleton lineCount={5} />
                        )}
                    </Box>

                    <Box display="flex" justifyContent="flex-end" gap="8px" p={1}>
                        {dirty && (
                            <Button className={classes.colorBtn} onClick={onDone}>
                                Cancel
                            </Button>
                        )}
                        <ErrorButton
                            className={classes.actionBtn}
                            errors={dirty && errors}
                            onClick={dirty ? handleSave : onDone}
                        >
                            {dirty ? 'Save' : 'Done'}
                        </ErrorButton>
                    </Box>
                </div>
            </div>
        </Spinner>
    )
}

type LinkedNode = ProfileConfig['linkedNodes'][0]

interface ManageLinkedNodeProps extends LinkedNode {
    classes: any
    /** starting open state */
    expanded: boolean
    onOrder: (fromId: string, beforeId?: string) => void
    onRemove: () => void
    onUpdateProperties: (properties: LinkedNode['properties']) => void
}

function ManageLinkedNode(props: ManageLinkedNodeProps) {
    const { classes, expanded, name, id, properties, onOrder, onRemove } = props
    const [open, setOpen] = useState(expanded)

    const [{ isDragging }, drag] = useDrag({
        type: dragType.LINKED_NODE,
        item: { type: dragType.LINKED_NODE, id },
        collect: monitor => ({ isDragging: monitor.isDragging() }),
    })
    const [{ isOver }, drop] = useDrop<IDragProperty, unknown, { isOver: boolean }>({
        accept: dragType.LINKED_NODE,
        drop: item => onOrder(item.id, id),
        collect: monitor => ({ isOver: monitor.isOver() && monitor.canDrop() }),
    })

    if (isDragging) return null

    function handleRemove(e) {
        e.stopPropagation()
        onRemove()
    }

    return (
        <Collapsable
            ref={n => drop(drag(n))}
            className={clsx({ [classes.borderTop]: isOver })}
            direction="down"
            open={open}
            onToggle={() => setOpen(s => !s)}
            collapseProps={{ mountOnEnter: true }}
            header={
                <div className={classes.linkedNodeHeader}>
                    <DragIcon fontSize="small" className={classes.dragIcon} />
                    <ChopText>{name}</ChopText>
                    <IconButton className={classes.colorBtn} size="small" onClick={handleRemove}>
                        <RemoveIcon fontSize="small" />
                    </IconButton>

                    <Box flex={1} />

                    {!properties.length && (
                        <Tooltip title="Select at least one property">
                            <ErrorIcon fontSize="small" />
                        </Tooltip>
                    )}
                </div>
            }
        >
            <ManageLinkedNodeProperties classes={classes} {...props} />
        </Collapsable>
    )
}

function ManageLinkedNodeProperties(props: Omit<ManageLinkedNodeProps, 'onRemove'>) {
    const { classes, id, name, properties, onUpdateProperties } = props
    const [node] = useResourceMeta(ResourceType.NODE, name)

    return (
        <div className={classes.linkedNodeProperties}>
            <ManageProperties
                classes={classes}
                parentId={id}
                properties={node?.properties}
                selected={properties}
                onChange={onUpdateProperties}
            />
        </div>
    )
}

interface ManagePropertiesProps {
    classes: any
    parentId: string
    properties: Property[]
    selected: IdAndName[]
    onChange: (selected: IdAndName[]) => void
}

function ManageProperties({ classes, parentId, properties, selected, onChange }: ManagePropertiesProps) {
    if (!properties) return <ParagraphSkeleton lineCount={3} />

    const usedProperties = selected
        ?.map(s => properties.find(p => p.id === s.id || p.name === s.name))
        .filter(p => Boolean(p))
    const unusedProperties = properties
        .filter(p => !selected?.some(s => s.id === p.id))
        .sort((a, b) => a.displayName.localeCompare(b.displayName))

    function handleAdd(ids) {
        const newProps = ids.map(id => {
            const prop = properties.find(p => p.id === id)
            return { id: prop.id, name: prop.name }
        })
        onChange([...selected, ...newProps])
    }

    function handleOrder(fromId, beforeId) {
        onChange(reorderItems(selected, fromId, beforeId))
    }

    function handleRemove(id) {
        onChange(selected.filter(p => p.id !== id))
    }

    return (
        <>
            {Boolean(usedProperties?.length) && (
                <div>
                    {usedProperties?.map(({ id, displayName }) => (
                        <DragProperty
                            key={id}
                            parentId={parentId}
                            id={id}
                            name={displayName}
                            classes={classes}
                            onOrder={handleOrder}
                            onRemove={() => handleRemove(id)}
                        />
                    ))}

                    <LastItem classes={classes} accept={dragType.PROPERTY} parentId={parentId} onOrder={handleOrder} />
                </div>
            )}

            {Boolean(unusedProperties?.length) ? (
                <ManageNewItems
                    classes={classes}
                    caption={`${unusedProperties.length} unused propert${unusedProperties.length === 1 ? 'y' : 'ies'}`}
                    items={unusedProperties}
                    onAdd={handleAdd}
                />
            ) : null}
        </>
    )
}

interface IDragProperty {
    type: dragType.PROPERTY
    id: string
    parentId?: string
}

function DragProperty({ classes, parentId, name, id, onOrder, onRemove }) {
    const [{ isDragging }, drag] = useDrag({
        type: dragType.PROPERTY,
        item: { type: dragType.PROPERTY, id, parentId },
        collect: monitor => ({ isDragging: monitor.isDragging() }),
    })
    const [{ isOver }, drop] = useDrop<IDragProperty, unknown, { isOver: boolean }>({
        accept: dragType.PROPERTY,
        canDrop: item => !parentId || item.parentId === parentId,
        drop: item => onOrder(item.id, id),
        collect: monitor => ({ isOver: monitor.isOver() && monitor.canDrop() }),
    })

    if (isDragging) return null

    return (
        <div ref={n => drop(drag(n))} className={clsx(classes.property, { [classes.borderTop]: isOver })}>
            <DragIcon fontSize="small" />
            <ChopText>{name}</ChopText>
            <IconButton className={classes.colorBtn} size="small" onClick={onRemove}>
                <RemoveIcon fontSize="small" />
            </IconButton>
        </div>
    )
}

interface ManageNewItemsProps {
    classes: any
    caption: string
    items: IdAndName[]
    onAdd: (itemIds: string[]) => void
}

function ManageNewItems({ classes, caption, items, onAdd }: ManageNewItemsProps) {
    const [open, setOpenNew] = useState(false)
    const [addIds, setAddIds] = useState([])

    function handleSelect(id) {
        if (!addIds.includes(id)) return setAddIds(s => [...s, id])
        setAddIds(addIds.filter(_id => _id !== id))
    }

    function handleAddAll() {
        onAdd(items.map(p => p.id))
        cleanUp()
    }

    function handleDone() {
        if (addIds.length) onAdd(addIds)
        cleanUp()
    }

    function cleanUp() {
        setOpenNew(false)
        setAddIds([])
    }

    return (
        <div className={classes.newItemContainer}>
            <MenuIcon
                icon={<AddIcon color="primary" />}
                open={open}
                popoverProps={{
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                    transformOrigin: { vertical: 'top', horizontal: 'left' },
                }}
                onClick={() => setOpenNew(true)}
                onClose={handleDone}
            >
                <Box display="flex" flexDirection="column" maxHeight="400px" overflow="hidden">
                    <Typography variant="h6">Select items to add</Typography>

                    <Box display="flex" flexDirection="column" overflow="auto">
                        {items.map(({ id, name }) => (
                            <ListItem key={id} button className={classes.newItem} onClick={() => handleSelect(id)}>
                                <BooleanSelect color="primary" checked={addIds.includes(id)} />
                                <ListItemText>{name}</ListItemText>
                            </ListItem>
                        ))}
                    </Box>

                    <Box display="flex" alignItems="center" gap="8px" justifyContent="flex-end">
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<AddAllIcon style={{ color: 'inherit' }} fontSize="small" />}
                            onClick={handleAddAll}
                        >
                            Add all
                        </Button>

                        <Button variant="contained" color="primary" onClick={handleDone}>
                            Done
                        </Button>
                    </Box>
                </Box>
            </MenuIcon>
            <Typography variant="caption">{caption}</Typography>
        </div>
    )
}

interface LastItemProps {
    classes: any
    accept: string | string[]
    parentId?: string
    onOrder: (fromId: string, beforeId?: string) => void
}

function LastItem({ classes, accept, parentId, onOrder }: LastItemProps) {
    const [{ canDrop, isOver }, drop] = useDrop<IDragProperty, unknown, { canDrop: boolean; isOver: boolean }>({
        accept,
        canDrop: item => !parentId || item.parentId === parentId,
        drop: item => onOrder(item.id),
        collect: monitor => ({ canDrop: monitor.canDrop(), isOver: monitor.isOver() && monitor.canDrop() }),
    })

    return <div ref={drop} className={clsx({ [classes.lastItem]: canDrop, [classes.borderTop]: isOver })} />
}

function convertToSaveProfile(nodeName: string, draftProfile: ProfileConfig): ServerProfileConfig {
    const { properties, linkedNodes } = draftProfile

    const makeServerProperty = (p: IdAndName) => ({ PropertyId: p.id, PropertyName: p.name })

    return {
        EntityName: nodeName,
        ProfileProperties: properties.map(makeServerProperty),
        LinkedEntities: linkedNodes.map(n => ({
            ChildNodeId: n.id,
            ChildNodeName: n.name,
            Properties: n.properties.map(makeServerProperty),
        })),
    }
}

function checkForErrors(nodeName: string, profile: ProfileConfig) {
    if (!profile) return

    const { properties, linkedNodes } = profile
    const errors: string[] = []

    if (!properties.length) errors.push(`${nodeName} needs at least one property`)
    linkedNodes?.forEach(n => {
        if (!n.properties.length) errors.push(`${n.name} needs at least one property`)
    })

    return errors
}

function reorderItems<T extends { id: string }>(items: T[], fromId: string, beforeId?: string): T[] {
    const ordered = Array.from(items)
    const startIndex = items.findIndex(s => s.id === fromId)
    const [removed] = ordered.splice(startIndex, 1)

    if (beforeId == null) {
        ordered.push(removed)
    } else {
        const endIndex = items.findIndex(s => s.id === beforeId)
        ordered.splice(endIndex - (startIndex < endIndex ? 1 : 0), 0, removed)
    }

    return ordered
}
