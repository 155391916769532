import { interactionTypes } from '../actions/types'
import produce from 'immer'

let initialState = {
    showDetailsModal: {
        show: false,
        widgetConfig: {},
        context: {},
    },
    profiles: [],
    profileIndex: 0,
    isEditingProfile: false,
}

export default (state = initialState, action) => {
    const { payload } = action
    switch (action.type) {
        case interactionTypes.OPEN_WIDGET_DETAILS: {
            let newState = produce(state, draftState => {
                draftState.showDetailsModal = { show: true, ...payload }
            })

            return newState
        }
        case interactionTypes.OPEN_PROFILE:
            return { ...state, profileIndex: 0, profiles: [payload] }

        case interactionTypes.PUSH_PROFILE: {
            const profileIndex = state.profileIndex + 1
            return { ...state, profileIndex, profiles: [...state.profiles.slice(0, profileIndex), payload] }
        }
        case interactionTypes.CLOSE_WIDGET_DETAILS:
            return initialState

        case interactionTypes.CHANGE_PROFILE_INDEX:
            return { ...state, profileIndex: payload.value }

        case interactionTypes.TOGGLE_PROFILE_EDIT:
            return { ...state, isEditingProfile: !state.isEditingProfile }

        default:
            return state
    }
}
