import React from 'react'
import { connect } from 'react-redux'
import { Modal } from '@mui/material'
import { Xls } from 'genesis-suite/icons'
import withStyles from '@mui/styles/withStyles';
import { Paper, Typography, IconButton, Tooltip } from '@mui/material'

import CloseIcon from 'genesis-suite/icons/Close'
import Widget from '../components/widgets/Widget'
import { interactionSelectors } from '../selectors'
import { interactionCreators } from '../actions/creators'
import useWidgetExport from '../components/widgets/hooks/useWidgetExport'

const height = '80vh'
const width = '80vw'
const titleHeight = '48px'

const styles = theme => ({
    paper: {
        width: width,
        height: height,
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -40%)',
    },
    widget: {
        height: `calc(${height} - ${titleHeight})`,
        '& ::-webkit-scrollbar': {
            width: 12,
            height: 12,
        },
        '& .widget-setup-container': {
            height: '100%',
        },
    },
    titleWrapper: {
        maxHeight: titleHeight,
        padding: theme.spacing(),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title: {
        width: '90%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    item: {},
    grid: {},
    wrapper: {},
    container: {},
})

/*
 * @param {(true | false)} props.show - flag to show/hide show details modal
 * @param {function} props.hideDetails - callback to hide details modal
 * @param {Object} props.context - context object
 * @param {Object} props.widgetConfig - config for widget which triggered interaction
 */
const ShowDetailsContainer = ({ widgetConfig, show, context, classes, hideDetails }) => {
    const { exportWidget } = useWidgetExport(widgetConfig)

    // Render Table widget
    const renderTableWidget = show => {
        return show ? (
            <Paper className={classes.paper}>
                <div className={classes.titleWrapper}>
                    <Typography className={classes.title} variant="h5">
                        {widgetConfig.Title}
                    </Typography>
                    <div>
                        <Tooltip title="Excel Download">
                            <IconButton onClick={exportWidget} size="large">
                                <Xls />
                            </IconButton>
                        </Tooltip>
                        <IconButton onClick={hideDetails} size="large">
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                <div className={classes.widget}>
                    <Widget config={widgetConfig} networkContext={context} interactions={false} />
                </div>
            </Paper>
        ) : (
            <></>
        );
    }

    return (
        <Modal open={show} disableEnforceFocus disableAutoFocus>
            {renderTableWidget(show)}
        </Modal>
    )
}

const mapStateToProps = state => ({
    ...interactionSelectors.getShowDetailsConfig(state),
})

const mapDispatchToProps = dispatch => ({
    hideDetails: () => {
        dispatch(interactionCreators.hideDetails())
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ShowDetailsContainer))
