import { ArrowBackRounded as ArrowLeft } from '@mui/icons-material'
import { Button, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { _360 } from 'genesis-suite/icons'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useMatch } from 'react-router-dom'
import { navigationCreators } from '../actions/creators'
import { routePaths } from '../lib/routes'
import { widgetSelectors } from '../selectors'

const useStyles = makeStyles(({ palette }) => ({
    icon: {
        color: palette.primary.contrastText,
    },
}))

const RootPerspectiveController = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { params: { perspectiveID: widgetId } = {} } = useMatch(routePaths.PERSPECTIVE) || {}
    const { search } = useLocation()
    const perspectiveId = useSelector(state => widgetSelectors.getPerspectiveIdFromWidgetId(state, widgetId))

    if (!perspectiveId) return null
    if (!search) return null

    const params = new URLSearchParams(search)
    const source = params.get('source')
    if (source !== 'widget_share') return null

    const handleClick = () => {
        dispatch(navigationCreators.goToPerspective(perspectiveId))
    }

    return (
        <Tooltip title="Go to containing perspective" placement="bottom" arrow>
            <Button
                size="small"
                variant="contained"
                color="primary"
                startIcon={<ArrowLeft className={classes.icon} />}
                endIcon={<_360 className={classes.icon} />}
                onClick={handleClick}
            />
        </Tooltip>
    )
}

export default RootPerspectiveController
