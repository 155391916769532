import { lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import SuspenseLoader from '../../components/SuspenseLoader'
import { routePaths } from '../../lib/routes'
import ApplicationSelectView from '../ApplicationSelectView'
import LoginView from '../LoginView'
import PublicView from '../PublicView'
import SSOCallback from '../SSOCallback'
import SilentRenewCallback from '../SilentRenewCallback'

const MaintenanceGuard = lazy(() => import('../../guards/MaintenanceGuard'))
const ModuleSelectView = lazy(() => import('../../views/ModuleSelectView'))
const NotificationView = lazy(() => import('../../views/NotificationView'))
const AppView = lazy(() => import('../AppView'))
const AppLayout = lazy(() => import('../../views/AppLayout'))

const AppRouter = () => {
    const routes = [
        {
            path: routePaths.ROOT,
            element: <Navigate to={routePaths.SELECT} />,
        },
        {
            path: `${routePaths.PUBLIC}/*`,
            element: <PublicView />,
        },
        {
            path: `${routePaths.PRIVATE}/*`,
            element: <PublicView />,
        },
        {
            path: routePaths.LOGIN,
            element: <LoginView />,
        },
        {
            path: routePaths.CALLBACK,
            element: <SSOCallback />,
        },
        {
            path: routePaths.SILENT_RENEW,
            element: <SilentRenewCallback />,
        },
        {
            path: routePaths.SELECT,
            element: <ApplicationSelectView />,
        },
        {
            path: routePaths.MODULE_SELECT,
            element: (
                <SuspenseLoader>
                    <MaintenanceGuard excludePortalAnnouncements={true}>
                        <ModuleSelectView />
                    </MaintenanceGuard>
                </SuspenseLoader>
            ),
        },
        {
            path: `${routePaths.NOTIFICATIONS}/*`,
            element: (
                <SuspenseLoader>
                    <NotificationView />
                </SuspenseLoader>
            ),
        },
        {
            path: routePaths.APP,
            element: (
                <SuspenseLoader>
                    <MaintenanceGuard>
                        <AppView />
                    </MaintenanceGuard>
                </SuspenseLoader>
            ),
        },
        {
            path: routePaths.BASE + '/*',
            element: (
                <SuspenseLoader>
                    <MaintenanceGuard>
                        <AppLayout />
                    </MaintenanceGuard>
                </SuspenseLoader>
            ),
        },
    ]
    return (
        <Routes>
            {routes.map(props => (
                <Route key={props.path} {...props} />
            ))}
            <Route path="*" element={<Navigate to={routePaths.ROOT} />} />
        </Routes>
    )
}

export default AppRouter
