import React, { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { useSelector } from 'react-redux'
import Spinner from 'genesis-suite/components/Spinner'
import { List, ListItem, ListItemText, Typography, Button, CircularProgress } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

import Rule from './Rule'
import { applicationSelectors } from '../../selectors'
import { notificationService } from '../../lib/services'
import { snackbarConfigs } from '../../lib/snackbarConfigs'

const useStyles = makeStyles(({ palette, spacing }) => ({
    root: { height: '40vh', display: 'flex', flexFlow: 'column', position: 'relative' },
    rootList: { flex: 1, overflow: 'scroll', paddingRight: spacing(1) },
    group: {
        borderBottom: 0,
        marginBottom: spacing(1),
        border: `1px solid ${palette.grayscale.light}`,
    },
    groupItem: { borderBottom: `1px solid ${palette.grayscale.light}`, paddingLeft: spacing(1) },
    boldText: { fontWeight: 600 },
    submitBtn: { display: 'block', marginLeft: 'auto' },
    noRulesText: { fontWeight: 700, textAlign: 'center', margin: 'auto' },
    nestedList: { padding: 0 },
}))

export default () => {
    const classes = useStyles({})
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [savePending, setSavePending] = useState(false)
    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const appName = useSelector(applicationSelectors.getCurrentAppName)

    const handleError = (message, error) => {
        console.error(error)
        showSnackbar(message, snackbarConfigs.error)
    }

    const getBusinessRules = async () => {
        try {
            const groups = await notificationService.getBusinessRules(appName)
            if (groups && groups.length > 0) {
                const result = groups.reduce((acc, { GroupName: groupName, BusinessRules: bizRules }) => {
                    acc.push({
                        groupName,
                        rules: bizRules.reduce(
                            (acc2, { BusinessRuleTitle: ruleName, Id: ruleId, Description: description }) => {
                                acc2.push({ ruleName, ruleId, description })
                                return acc2
                            },
                            []
                        ),
                    })
                    return acc
                }, [])
                setData(result)
            }
        } catch (err) {
            handleError('An error occurred fetching Rules!', err)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getBusinessRules()
    }, [])

    if (loading)
        return (
            <div className={classes.root}>
                <Spinner />
            </div>
        )

    const handleSubChange = (rule, groupName) => {
        setData(
            data.map(grp => {
                if (grp.groupName === groupName) {
                    return {
                        ...grp,
                        rules: grp.rules.map(r => (r.ruleId === rule.ruleId ? rule : r)),
                    }
                } else return grp
            })
        )
    }

    const handleSave = async () => {
        const saveRequests = data.reduce((acc, { rules }) => {
            const newAcc = acc.concat(
                rules.map(({ ruleId, subs: { email, sms } }) =>
                    notificationService.saveRuleSubscriptions({ ruleId, email: Boolean(email), sms: Boolean(sms) })
                )
            )
            return newAcc
        }, [])
        try {
            setSavePending(true)
            await Promise.all(saveRequests)
            showSnackbar('Settings saved!', { variant: 'success' })
        } catch (err) {
            handleError('An error occurred saving Notification settings!', err)
        } finally {
            setSavePending(false)
        }
    }

    const hasRules = data && data.length > 0

    return (
        <div className={classes.root}>
            {hasRules ? (
                <List className={classes.rootList}>
                    {data
                        .sort(({ groupName: aName }, { groupName: bName }) =>
                            aName < bName ? -1 : aName > bName ? 1 : 0
                        )
                        .map(({ groupName, rules }) => {
                            const secondary = rules.length > 1 ? `${rules.length} rules` : `${rules.length} rule`
                            return (
                                <div key={groupName} className={classes.group}>
                                    <ListItem dense disableGutters className={classes.groupItem}>
                                        <ListItemText
                                            secondary={secondary}
                                            primary={groupName}
                                            classes={{ primary: classes.boldText }}
                                            primaryTypographyProps={{ variant: 'subtitle1' }}
                                            secondaryTypographyProps={{ color: 'text.primary' }}
                                        />
                                    </ListItem>
                                    <List className={classes.nestedList}>
                                        {rules.map(rule => (
                                            <Rule
                                                key={rule.ruleId}
                                                rule={rule}
                                                onChange={rule => handleSubChange(rule, groupName)}
                                            />
                                        ))}
                                    </List>
                                </div>
                            )
                        })}
                </List>
            ) : (
                <Typography variant="subtitle1" className={classes.noRulesText}>
                    No notification rules configured
                </Typography>
            )}
            {hasRules && (
                <Button
                    className={classes.submitBtn}
                    color="primary"
                    variant="contained"
                    onClick={handleSave}
                    disabled={savePending}
                >
                    {savePending ? <CircularProgress size={12} color="secondary" /> : 'Save'}
                </Button>
            )}
        </div>
    )
}
