import React, { useEffect, useState, forwardRef } from 'react'
import { v4 as uuid } from 'uuid'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'

import { sleep } from 'genesis-suite/utils'
import { ResourceType } from 'genesis-suite/types/networkTypes'
import { DataResponse, FormConfig, FormDataWithId, FormType, TableFormConfig } from 'genesis-suite/types/visualTypes'
import TableForm from './TableForm'
import InputForm from './InputForm'
import { modelService } from '../../../lib/services'
import { applicationSelectors } from '../../../selectors'
import { buildLinkReq, buildNodeReq, getallPrompts, getFieldNamesFromConfig } from '../../../lib/formUtils'
import { Typography } from '@mui/material'

const useStyles = makeStyles({
    formWrapper: { height: '100%' },
})

interface Props {
    config: FormConfig
    data: DataResponse
}

const FormWidget = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const classes = useStyles()
    const [data, setData] = useState<FormDataWithId[]>(null)
    const { appName, appId } = useSelector(applicationSelectors.getAppInfo)
    const { config, data: fetchedData } = props
    const { id: formId, source, migratedVisorId } = config

    useEffect(() => {
        const names = getFieldNamesFromConfig(config)
        const parsed = fetchedData?.[0].data[0].rawData.map(row => {
            const data = row.reduce((acc, cur, index) => ({ ...acc, [names[index]]: cur }), {})
            return { id: uuid(), ...data }
        })
        setData(parsed)
    }, [config, fetchedData])

    const doSave = async (data, tableName) => {
        return await modelService.saveResourceValues(
            appName,
            source.name,
            data,
            tableName,
            migratedVisorId,
            '',
            '',
            '',
            appId
        )
    }

    const buildRequest = rows => {
        const fields = config.formType === 'table' ? config.columns : getallPrompts(config)
        return source.type === ResourceType.NODE
            ? buildNodeReq(rows, data, fields, source.name)
            : buildLinkReq(rows, data, fields)
    }

    const handleSave = async rows => {
        const request = buildRequest(rows)
        console.log(request)
        try {
            await sleep(1000) // TODO - save data
            setData(s => s.map(row => rows.find(r => r.id === row.id) ?? row))
        } catch (err) {
            setData(data)
            return Promise.reject()
        }
    }

    const handleUpload = async (rows, fileName) => {
        if (!fileName) {
            const request = buildRequest(rows)
            console.log(request)
        } else {
            console.log(fileName)
        }
        return Promise.resolve()
    }

    const formatForDelete = (row: any) => {
        const { columns } = config as TableFormConfig
        if (source.type === ResourceType.NODE) {
            const {
                property: { name },
            } = columns.find(c => c.property.isPrimary)
            return row[name]
        } else {
            return columns.reduce((acc, { property }) => {
                const { name, isPrimary } = property
                if (isPrimary) acc[name] = row[name]
                return acc
            }, {})
        }
    }

    const handleDelete = async (rows: any[]) => {
        const { name: sourceName } = source
        const request = rows.map(row => formatForDelete(row))
        await modelService.deleteResourceValues(appName, sourceName, request)
    }

    if (!data) return null
    const getContent = () => {
        switch (config.formType) {
            case FormType.INPUT:
                return <InputForm config={config} data={data[0]} defaults={{}} onSave={row => handleSave([row])} />
            case FormType.TABLE:
                return (
                    <TableForm
                        data={data}
                        config={config}
                        defaults={{}}
                        onExport={() => Promise.resolve()}
                        onSave={handleSave}
                        onDelete={handleDelete}
                        onExcelUpload={handleUpload}
                    />
                )
            default:
                return <Typography>No form found for type {config.formType}!</Typography>
        }
    }

    return (
        <div ref={ref} className={classes.formWrapper}>
            {getContent()}
        </div>
    )
})

export default FormWidget
