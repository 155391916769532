import { useSelector, useDispatch } from 'react-redux'
import { IconButton, Tooltip } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { useSnackbar } from 'notistack'

import { widgetCreators } from '../actions/creators'
import { authSelectors, widgetSelectors, moduleSelectors } from '../selectors'

export default function EditPerspectiveTrigger({ disabled, iconProps, buttonStyle }) {
    const { enqueueSnackbar: showSnackbar } = useSnackbar()

    const moduleId = useSelector(moduleSelectors.getModuleId)
    const perspective = useSelector(widgetSelectors.getCurrentPerspectiveConfig)
    const isPowerUser = useSelector(authSelectors.getIsPowerUser)

    const dispatch = useDispatch()

    const show = Boolean(perspective) && isPowerUser

    function handleClick() {
        const { IsGlobal, ParentVisorId, ParentVisorName } = perspective
        if (IsGlobal && ParentVisorId !== moduleId) {
            showSnackbar(`Edit this global perspective in ${ParentVisorName} module`, { variant: 'error' })
            return
        }

        dispatch(widgetCreators.startEditingPerspective())
    }

    return (
        <Tooltip title="Edit perspective">
            <span>
                <IconButton
                    data-cy="edit-perspective"
                    sx={buttonStyle}
                    onClick={handleClick}
                    disabled={disabled || !show}
                >
                    <EditIcon {...iconProps} />
                </IconButton>
            </span>
        </Tooltip>
    )
}
