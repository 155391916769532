import { Box } from '@mui/material'
import { CalculatorWidgetConfig } from 'genesis-suite/types/visualTypes'
import { forwardRef } from 'react'
import CalculatorController from '../calculator/CalculatorController'

export default forwardRef<HTMLDivElement, { config: CalculatorWidgetConfig }>(({ config }, ref) => {
    return (
        <Box
            ref={ref}
            sx={{
                backgroundColor: 'background.main',
                border: 1,
                borderColor: 'border.main',
                borderRadius: '15px',
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                height: '100%',
                p: 1,
            }}
        >
            <Box
                flex={1}
                height="100%"
                overflow="hidden"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
            >
                <CalculatorController Header={() => <div></div>} onClose={() => null} />
            </Box>
        </Box>
    )
})
