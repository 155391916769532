import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Box, Button, ButtonGroup, Checkbox, FormControlLabel, MenuItem, Menu, Switch, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { HelpText } from 'genesis-suite/components'
import { Dashboard } from 'genesis-suite/types/visualTypes'
import { navigationCreators } from '~/actions/creators'
import NewWidgetButton from '~/components/NewWidgetButton'
import defaultDashboard from '~/lib/defaultHomePage'
import { routePaths } from '~/lib/routes'
import { applicationSelectors, authSelectors, moduleSelectors } from '~/selectors'
import { DashboardContext } from './DashboardContext'
import RestoreHiddenWidgets from './RestoreHiddenWidgets'
import { DashboardDevicePreviewToggle } from './actions'
import { useHomeDashboardContext } from './HomeDashboardContext'

export default function HomePageHeader() {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const moduleId = useSelector(moduleSelectors.getModuleId)
    const isV2 = useSelector(moduleSelectors.getIsV2)
    const isPowerUser = useSelector(authSelectors.getIsPowerUser)
    const dispatch = useDispatch()

    const { dashboard, appOptionsDashboard, createUserDashboard, updateUserDashboard, deleteUserDashboard } =
        useHomeDashboardContext()
    const { editing, onEditDone, onEditStart } = useContext(DashboardContext)

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    useEffect(() => {
        if (dashboard && !editing) onEditStart()
    }, [dashboard, editing])

    const type = getType(dashboard)

    async function handleSave() {
        if (editing) await onEditDone()
        dispatch(navigationCreators.goTo(routePaths.HOME))
    }

    async function handleSaveAll() {
        if (editing) await onEditDone(false, true)
        dispatch(navigationCreators.goTo(routePaths.HOME))
    }

    async function handleCancel() {
        if (editing) onEditDone(true)
        dispatch(navigationCreators.goTo(routePaths.HOME))
    }

    async function handleScopeChange() {
        switch (type) {
            case 'default': {
                const moduleScopedDashboard: Dashboard = {
                    ...(appOptionsDashboard || defaultDashboard),
                    ...(isV2 ? { moduleId } : { visorModuleId: moduleId }),
                    appName,
                    id: `dashboard-${Date.now()}`,
                }
                createUserDashboard(moduleScopedDashboard)
                break
            }
            case 'all-modules': {
                const updatedDashboard = {
                    ...dashboard,
                    ...(isV2 ? { moduleId } : { visorModuleId: moduleId }),
                }
                updateUserDashboard(updatedDashboard)
                break
            }
            case 'single-module': {
                const updatedDashboard = { ...dashboard }
                delete updatedDashboard.moduleId
                delete updatedDashboard.visorModuleId
                updateUserDashboard(updatedDashboard)
                break
            }
        }
    }

    async function handleCustomChange() {
        switch (type) {
            case 'default': {
                const newDashboard: Dashboard = {
                    ...(appOptionsDashboard || defaultDashboard),
                    appName,
                    id: `dashboard-${Date.now()}`,
                }
                createUserDashboard(newDashboard)
                break
            }
            case 'all-modules': {
                if (dashboard?.id) {
                    deleteUserDashboard(dashboard.id)
                }
                break
            }
        }
    }

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    const handleMenuItemClick = async (option: 'save-all') => {
        setAnchorEl(null)
        if (option === 'save-all') {
            await handleSaveAll()
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                py: 1,
                px: 2,
                borderColor: 'text.primary',
                borderBottom: 1,
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Typography variant="h6">Manage Home View</Typography>

                <FormControlLabel
                    checked={type === 'single-module'}
                    control={<Switch color="primary" />}
                    label={
                        <HelpText help="Home view applies for this application only">This application only</HelpText>
                    }
                    labelPlacement="end"
                    onChange={handleScopeChange}
                />
                <FormControlLabel
                    control={<Checkbox checked={type !== 'default'} onChange={handleCustomChange} />}
                    disabled={type === 'single-module'}
                    label={<HelpText help="Design a custom layout">Custom</HelpText>}
                />
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {type !== 'default' && (
                    <>
                        <RestoreHiddenWidgets />
                        <NewWidgetButton onlyStatic={true} />
                        <DashboardDevicePreviewToggle />
                    </>
                )}
                <Button onClick={handleCancel} startIcon={<CloseIcon />} variant="contained" color="secondary">
                    Cancel
                </Button>
                {isPowerUser && isModuleDashboard(dashboard) ? (
                    <ButtonGroup variant="contained" aria-label="split button">
                        <Button onClick={handleSave} startIcon={<DoneIcon />}>
                            Save
                        </Button>
                        <Button
                            size="small"
                            aria-controls={open ? 'split-button-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-label="select save option"
                            aria-haspopup="menu"
                            onClick={handleMenuClick}
                        >
                            <ArrowDropDownIcon />
                        </Button>
                    </ButtonGroup>
                ) : (
                    <Button onClick={handleSave} startIcon={<DoneIcon />} variant="contained">
                        Save
                    </Button>
                )}
                <Menu
                    id="split-button-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleMenuClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <MenuItem onClick={() => handleMenuItemClick('save-all')}>Save for All</MenuItem>
                </Menu>
            </Box>
        </Box>
    )
}

type HomePageType = 'default' | 'all-modules' | 'single-module'

function getType(dashboard?: Dashboard): HomePageType {
    if (!dashboard) return 'default'
    if (dashboard.moduleId || dashboard.visorModuleId) return 'single-module'
    return 'all-modules'
}

const isModuleDashboard = (dashboard?: Dashboard) => {
    return dashboard?.moduleId || dashboard?.visorModuleId
}
