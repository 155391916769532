const getProfileIndex = s => s.interaction.profileIndex
const getProfiles = s => s.interaction.profiles
const getIsEditingProfile = s => s.interaction.isEditingProfile
const getShowDetailsConfig = s => s.interaction.showDetailsModal

export const interactionSelectors = {
    getProfileIndex,
    getProfiles,
    getIsEditingProfile,
    getShowDetailsConfig,
}
