import { Box, Typography, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useUpdateEffect } from 'genesis-suite/hooks'
import isEqual from 'lodash/isEqual'
import { useEffect, useRef, useState } from 'react'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

import { useSelector } from 'react-redux'
import PerspectiveCompareFilterController from '~/containers/PerspectiveCompareFilterController'
import SearchFilter from '~/containers/CompareSearchFilter'
import { moduleSelectors } from '~/selectors'
import { appearanceConstants } from '../../constants'
import PerspectiveFilterController from '../../containers/PerspectiveFilterController'
import ShowDetailsContainer from '../../containers/ShowDetailsContainer'
import { pageview } from '../../lib/googleAnalyticsClient'
import ComparePerspectiveToolbar from '../ComparePerspectiveToolbar'
import { PerspectiveProvider } from '../contexts/PerspectiveContext'
import FocalPointSelect from './FocalPointSelect'
import { makeLayouts, PerspectiveContent, topLabelFilter } from './Perspective'
import TopLabels from './visuals/LabelWidget/TopLabels'

const { ScrollBarWidth } = appearanceConstants

const rows = 18 // default row count per perspective clientHeight
const minHeight = 2 // minimum number of rows for widget
const gridGap = 8
const smBreakpoint = 800

const useStyles = makeStyles(({ palette }) => ({
    root: {
        flex: 1,
        backgroundColor: palette.background.main,
        paddingLeft: ScrollBarWidth,
        display: 'flex',
        flexDirection: 'column',
    },
    widgetContainer: { '&:hover': { zIndex: p => (p.isEditing ? '' : 2) } }, // allow tooltip to cover other widgets
}))

/**
 * Renders a perspective
 * @param {function} props.changeLayouts callback to update the draft layout in redux
 * @param {string=} props.containerWidth width of the perspective container. 100% by default. used to simulate different device
 * @param {array} props.configs array of widgetConfig objects
 * @param {string} props.id id of the perspective being rendered
 * @param {boolean=} props.isEditing optional. if true perspective is being edited
 * @param {*} props.layouts device layouts object from perspective config or redux draft state
 * @param {number=} props.currentLayout if provided, override layouts with grid columns based on value provided
 * @param {function} props.onChange callback for when the perspective being rendered changes to a new collection of widgets
 * @param {boolean} props.hasFetched if false, v2 module requiring perspective fetching
 */
export default function ComparePerspective({
    changeLayouts,
    containerWidth = '100%',
    configs,
    id,
    isEditing = false,
    layouts,
    currentLayout,
    onChange = () => {},
    hasFetched,
    onEditCompare,
    ...rest
}) {
    const widgetContainer = useRef()
    const [width, setWidth] = useState(1000)
    const [height, setHeight] = useState(0)

    const topConfigs = configs.filter(topLabelFilter)
    const widgetConfigs = configs.filter(c => !topLabelFilter(c))
    const isEditingPerspective = isEditing && widgetConfigs.length > 1
    const breakpoint = width < smBreakpoint ? 'sm' : 'lg'
    const [_layouts, isOneRow] = makeLayouts(widgetConfigs, currentLayout, breakpoint, layouts, isEditingPerspective)
    const rowPxHeight =
        isOneRow && !isEditingPerspective
            ? height / minHeight - 1.5 * gridGap
            : Math.max(Math.floor(height / rows - gridGap), 36)
    const classes = useStyles({ containerWidth, isEditing })

    useEffect(() => {
        onChange(id)
        pageview(window.location.pathname + window.location.search)

        if (widgetContainer.current) widgetContainer.current.scrollTo(0, 0)
    }, [id])

    // HACK - needed to prevent sm layout from sticking when transitioning to lg (in ReactGridLayout component state)?
    const [isTransitioning, setIsTransitioning] = useState(false)
    useUpdateEffect(() => {
        setIsTransitioning(true)
        setTimeout(() => setIsTransitioning(false), 0)
    }, [breakpoint])

    function handleLayoutChange(_, u) {
        if (!isEditingPerspective) return
        const updated = {
            sm: u.sm.map(({ i, x, y, w, h }) => ({ i, x, y, w, h })),
            lg: u.lg.map(({ i, x, y, w, h }) => ({ i, x, y, w, h })),
        }
        if (isEqual(_layouts, updated)) return
        changeLayouts(updated)
    }

    const perspectiveLayoutProps = {
        containerWidth,
        handleLayoutChange,
        id,
        isEditing,
        isEditingPerspective,
        isTransitioning,
        _layouts,
        rowPxHeight,
        setHeight,
        setWidth,
        topConfigs,
        widgetConfigs,
        width,
        ...rest,
    }

    return (
        <PerspectiveProvider configs={configs} id={id}>
            <ComparePerspectiveToolbar perspectiveID={id} />
            <div className={classes.root}>
                <PerspectiveLayout {...perspectiveLayoutProps} />
                <ShowDetailsContainer />
            </div>
        </PerspectiveProvider>
    )
}

function PerspectiveLayout({ topConfigs, isEditingPerspective, ...props }) {
    const showSearchFilter = useSelector(moduleSelectors.getEnableSearch)
    const { palette } = useTheme()

    const defaultIconProps = { style: { color: palette.text.primary } }

    const baseGrid = {
        display: 'grid',
        gridTemplateColumns: 'repeat(2,1fr)',
        gap: 1,
        overflowX: 'hidden',
        overflowY: 'scroll',
    }

    return (
        <>
            <Box sx={baseGrid}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
                    <Typography variant="h5">Original</Typography>
                    <FocalPointSelect textFieldProps={{ variant: 'standard' }} />
                    <PerspectiveFilterController iconProps={defaultIconProps} />
                    {showSearchFilter && <SearchFilter />}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
                    <Typography variant="h5">Compare</Typography>
                    <FocalPointSelect source="compare" textFieldProps={{ variant: 'standard' }} />
                    <PerspectiveCompareFilterController iconProps={defaultIconProps} />
                    {showSearchFilter && <SearchFilter isCompare />}
                </Box>
                <TopLabels configs={topConfigs} isEdit={isEditingPerspective} />
                <TopLabels isCompare configs={topConfigs} isEdit={isEditingPerspective} />
            </Box>

            <Box sx={{ ...baseGrid, flex: 1 }}>
                <PerspectiveContent {...props} />
                <PerspectiveContent isCompare {...props} />
            </Box>
        </>
    )
}
