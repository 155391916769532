import { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Box,
    ButtonBase,
    Collapse,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'

import { ChopText, CognitiveIcon, MenuIcon } from 'genesis-suite/components'
import { NodeResource, ProfileConfig, ResourceType } from 'genesis-suite/types/networkTypes'
import { Profile as ProfileIcon } from 'genesis-suite/icons'
import { dialogCreators, interactionCreators, navigationCreators } from '../../actions/creators'
import CollapsableTwist from '../CollapsableTwist'
import { applicationSelectors, widgetSelectors } from '../../selectors'
import { logEvent } from '../../lib/amplitudeClient'
import { useIsMobile } from '../../hooks/useIsMobile'
import useResourceMeta from '../../hooks/useResourceMeta'
import useArchitecture from '../../hooks/useArchitecture'

const useStyles = makeStyles(({ palette }) => ({
    headerText: { borderBottom: `1px solid ${palette.grayscale.light}` },
}))

interface DataRow {
    [propertyName: string]: number | string
}

interface Props {
    linkedNodeConfig: ProfileConfig['linkedNodes']
    data: { [nodeName: string]: DataRow[] }
}

export default function ProfileLinkedNodes({ linkedNodeConfig, data }: Props) {
    const appName = useSelector(applicationSelectors.getCurrentAppName) as string
    const [selectedIds, setSelectedIds] = useState([])
    const architectureElements = useArchitecture(appName)?.elements

    const classes = useStyles()
    const cleanedData = useMemo(() => {
        if (!linkedNodeConfig || !data) return

        return linkedNodeConfig
            .filter(c => c.name)
            .map(({ id, name, properties }) => {
                const dataRow = name
                    ? data[name]?.map(row =>
                          properties.reduce<DataRow>((acc, cur) => ({ ...acc, [cur.name]: row[cur.name] }), {})
                      )
                    : []
                return { id, name, values: dataRow.map((row, index) => ({ ...row, id: index })) }
            })
    }, [linkedNodeConfig, data])

    useEffect(() => {
        if (!cleanedData) return

        setSelectedIds([])
    }, [cleanedData])

    function handleToggle(nodeId) {
        const index = selectedIds.indexOf(nodeId)
        setSelectedIds(ids => (index > -1 ? ids.filter(id => id !== nodeId) : [...ids, nodeId]))
    }

    return (
        <Box flex={1} overflow="hidden" m={0.5} mr={0} display="flex" flexDirection="column">
            <Typography className={classes.headerText} display="block" variant="h6">
                Related:
            </Typography>

            <Box overflow="auto">
                {cleanedData?.map(({ id, name, values }) => (
                    <NodeItem
                        key={id}
                        name={name}
                        data={values}
                        iconPath={architectureElements?.find(a => a.Name === name)?.IconFileName}
                        open={selectedIds.includes(id)}
                        onToggle={() => handleToggle(id)}
                    />
                ))}
            </Box>
        </Box>
    )
}

const useNodeItemStyles = makeStyles({
    button: { width: '100%', justifyContent: 'flex-start' },
    treeItem: { width: 'calc(100% - 10px)', justifyContent: 'space-between', marginLeft: '8px' },
    clickableItem: { cursor: 'pointer', color: '#1E90FF', textDecoration: 'underline' },
})

interface NodeItemProps {
    name: string
    data: DataRow[]
    iconPath?: string
    open: boolean
    onToggle: () => void
}

function NodeItem({ name, data, iconPath, open, onToggle }: NodeItemProps) {
    const [node] = useResourceMeta(ResourceType.NODE, name)
    const classes = useNodeItemStyles()

    return (
        <>
            <ButtonBase className={classes.button} onClick={onToggle}>
                <CollapsableTwist open={open} />
                {iconPath ? (
                    <>
                        <CognitiveIcon icon={{ file: iconPath }} />
                        <Box ml={1} />
                    </>
                ) : null}
                <ChopText variant="subtitle1" showEllipsis tooltipProps={{ placement: 'top' }}>
                    {name} ({data.length})
                </ChopText>
            </ButtonBase>

            <Collapse in={open}>
                <NodePropertyTree node={node} data={data} />
            </Collapse>
        </>
    )
}

const useNodePropertyTableStyles = makeStyles(({ palette, spacing }) => ({
    root: {
        display: 'flex',
        overflow: 'auto',
        background: '#fff',
        maxHeight: '300px',
        borderRadius: spacing(0.5),
        margin: spacing(0.5),
    },
    headerCell: { backgroundColor: palette.grayscale.lightest, fontWeight: 600 },
    profileButtonCell: { padding: 0, width: '36px' },
    dataCell: { whiteSpace: 'nowrap', maxWidth: '500px', overflow: 'hidden', textOverflow: 'ellipsis' },
    clickableCell: { cursor: 'pointer', color: 'blue', textDecoration: 'underline' },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}))

function NodePropertyTable({ node, data }: { node: NodeResource; data: DataRow[] }) {
    const isMobile = useIsMobile()
    //@ts-ignore
    const defaultPerspective = useSelector(s => widgetSelectors.getDefaultPerspectiveForNode(s, node?.name))
    const dispatch = useDispatch()

    const [order, setOrder] = useState<'asc' | 'desc'>('asc')
    const [orderBy, setOrderBy] = useState('')

    const keyPropertyName = node?.properties.find(p => p.isPrimary)?.name
    const headers = Object.keys(data[0] ?? {})
    const classes = useNodePropertyTableStyles()

    function openPerspective(row) {
        const crumb = {
            DefaultPerspective: defaultPerspective,
            FieldName: keyPropertyName,
            Name: node.name,
            Value: row[keyPropertyName],
        }
        dispatch(navigationCreators.goToPerspective(defaultPerspective, { context: crumb }))
        if (isMobile) dispatch(dialogCreators.hideDialog('profiles'))
        logEvent('OPEN_PERSPECTIVE_FROM_PROFILE')
    }

    function openProfile(e, row) {
        e.stopPropagation()
        dispatch(interactionCreators.pushProfile(node.name, keyPropertyName, row[keyPropertyName]))
    }

    function handleRequestSort(event, property) {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const createSortHandler = property => event => {
        handleRequestSort(event, property)
    }

    return (
        <div className={classes.root}>
            <Table size="small" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell className={clsx(classes.headerCell, classes.profileButtonCell)} />

                        {headers.map(cell => (
                            <TableCell
                                key={cell}
                                className={classes.headerCell}
                                padding="normal"
                                sortDirection={orderBy === cell ? order : false}
                            >
                                <TableSortLabel
                                    active={orderBy === cell}
                                    direction={orderBy === cell ? order : 'asc'}
                                    onClick={createSortHandler(cell)}
                                >
                                    {cell}
                                    {orderBy === cell ? (
                                        <span className={classes.visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {stableSort(data, getComparator(order, orderBy)).map((row, index) => (
                        <TableRow key={index} hover>
                            <TableCell className={classes.profileButtonCell}>
                                <MenuIcon
                                    icon={<ProfileIcon fontSize="small" />}
                                    tooltip="Open profile"
                                    onClick={e => openProfile(e, row)}
                                />
                            </TableCell>

                            {Object.keys(row).map((key, index) => {
                                const clickable = key === keyPropertyName && defaultPerspective
                                return (
                                    <TableCell
                                        key={index}
                                        className={clsx(classes.dataCell, { [classes.clickableCell]: clickable })}
                                        onClick={clickable ? () => openPerspective(row) : undefined}
                                    >
                                        {row[key]}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    )
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })

    return stabilizedThis.map(el => el[0])
}

const getComparator = (order, orderBy) =>
    order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy)

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) return -1
    if (b[orderBy] > a[orderBy]) return 1
    return 0
}

const useNodeTreeStyles = makeStyles(({ palette }) => ({
    button: { width: '100%', justifyContent: 'flex-start' },
    treeItem: { width: 'calc(100% - 10px)', justifyContent: 'space-between', marginLeft: '8px' },
    clickableItem: { cursor: 'pointer', color: '#1E90FF', textDecoration: 'underline' },
    disabled: { color: palette.text.disabled, cursor: 'not-allowed' },
}))

const NodePropertyTree = ({ node, data }) => {
    const [selectedRows, setSelectedRows] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const classes = useNodeTreeStyles()

    function onRowToggle(nodeId) {
        const index = selectedRows.indexOf(nodeId)
        setSelectedRows(ids => (index > -1 ? ids.filter(id => id !== nodeId) : [...ids, nodeId]))
    }

    const currentPageData = useMemo(() => {
        if (!data?.length) return []
        return data.slice((currentPage - 1) * 10, currentPage * 10)
    }, [currentPage, data])

    if (!node || !data?.length) return null

    const totalPages = data.length > 10 ? Math.ceil(data.length / 10) : 1

    return (
        <>
            {currentPageData.map((row, index) => {
                return (
                    <NodePropertyTreeItem
                        key={index}
                        node={node}
                        row={row}
                        open={selectedRows.includes(row.id)}
                        onToggle={() => onRowToggle(row.id)}
                    />
                )
            })}
            {totalPages > 1 && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <ChopText
                        variant="caption"
                        className={currentPage === 1 ? classes.disabled : classes.clickableItem}
                        onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
                    >
                        Previous
                    </ChopText>
                    <ChopText sx={{ mx: 0.5 }} variant="caption">
                        {`${(currentPage - 1) * 10 + 1} - ${Math.min(currentPage * 10, data.length)} of ${data.length}`}
                    </ChopText>
                    <ChopText
                        className={currentPage === totalPages ? classes.disabled : classes.clickableItem}
                        variant="caption"
                        onClick={() => setCurrentPage(p => Math.min(totalPages, p + 1))}
                    >
                        Next
                    </ChopText>
                </Box>
            )}
        </>
    )
}

const NodePropertyTreeItem = ({ node, row, open, onToggle }) => {
    const isMobile = useIsMobile()
    //@ts-ignore
    const defaultPerspective = useSelector(s => widgetSelectors.getDefaultPerspectiveForNode(s, node?.name))
    const dispatch = useDispatch()

    const keyPropertyName = node?.properties.find(p => p.isPrimary)?.name
    const classes = useNodeItemStyles()

    function openPerspective(e, row) {
        e.stopPropagation()
        const crumb = {
            DefaultPerspective: defaultPerspective,
            FieldName: keyPropertyName,
            Name: node.name,
            Value: row[keyPropertyName],
        }
        dispatch(navigationCreators.goToPerspective(defaultPerspective, { context: crumb }))
        if (isMobile) dispatch(dialogCreators.hideDialog('profiles'))
        logEvent('OPEN_PERSPECTIVE_FROM_PROFILE')
    }

    function openProfile(e, row) {
        e.stopPropagation()
        dispatch(interactionCreators.pushProfile(node.name, keyPropertyName, row[keyPropertyName]))
    }
    return (
        <>
            <ButtonBase className={classes.treeItem} onClick={onToggle}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        maxWidth: 'calc(100% - 35px)',
                    }}
                >
                    <CollapsableTwist open={open} />
                    <ChopText
                        variant="subtitle1"
                        showEllipsis
                        tooltipProps={{ placement: 'top' }}
                        onClick={defaultPerspective ? e => openPerspective(e, row) : undefined}
                        className={defaultPerspective ? classes.clickableItem : undefined}
                    >
                        {row[keyPropertyName]}
                    </ChopText>
                </div>

                <MenuIcon
                    buttonProps={{ sx: { color: 'inherit' } }}
                    icon={<ProfileIcon fontSize="small" />}
                    tooltip="Open profile"
                    onClick={e => openProfile(e, row)}
                />
            </ButtonBase>

            <Collapse in={open}>
                {Object.keys(row).map((key, index) => {
                    if (key === 'id') return null
                    return (
                        <Box key={index} sx={{ marginLeft: 3, display: 'flex', justifyContent: 'flex-start' }}>
                            <ChopText variant="subtitle1" fontWeight={600}>
                                {key} :
                            </ChopText>
                            <ChopText showEllipsis variant="subtitle1">
                                {row[key]}
                            </ChopText>
                        </Box>
                    )
                })}
            </Collapse>
        </>
    )
}
