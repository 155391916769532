import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { lazy, useEffect, useState } from 'react'
import { logEvent } from '../../lib/amplitudeClient'
import SuspenseLoader from '../SuspenseLoader'

const Calculator = lazy(() => import('./Calculator'))

type HistoryItem = {
    expression: string
    result: string
    key: number
}

interface addComputation {
    expression: string
    result: string
}

const useStyles = makeStyles(() => ({
    Container: {
        width: '100%',
        height: 'calc(100% - 40px)',
        display: 'flex',
        alignItems: 'end',
    },
    Calculator: {
        width: '100%',
        height: '300px',
    },
    Panel: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    History: {
        padding: '10px',
        textAlign: 'right',
        maxHeight: '270px',
        overflow: 'scroll',
        display: 'flex',
        flexDirection: 'column',
    },
    Result: {
        cursor: 'pointer',
    },
}))

export default function CalculatorHistory() {
    const [history, setHistory] = useState<Array<HistoryItem>>([])
    const [currentResult, setCurrentresult] = useState<string>(null)

    const styles = useStyles()

    const HISTORYKEY = 'computationHistory'

    function addComputation(values: addComputation) {
        if (values.expression === '') return null

        let _history = []
        if (sessionStorage.getItem(HISTORYKEY)) {
            _history = JSON.parse(sessionStorage.getItem(HISTORYKEY))
        }

        _history.push({ ...values, key: _history.length })
        sessionStorage.setItem(HISTORYKEY, JSON.stringify(_history))
        setHistory(_history)
    }

    function loadHistory() {
        if (sessionStorage.getItem(HISTORYKEY)) {
            const _history = JSON.parse(sessionStorage.getItem(HISTORYKEY))
            setHistory(_history)
        }
    }

    useEffect(loadHistory, [sessionStorage])

    useEffect(() => {
        if (Boolean(currentResult)) {
            setCurrentresult(null)
        }
    }, [currentResult])

    useEffect(() => {
        logEvent('OPEN_CALCULATOR')
    }, [])

    return (
        <div className={styles.Container}>
            <div className={styles.Panel}>
                {history.length > 0 && (
                    <div className={styles.History}>
                        {history.map(item => (
                            <div className={styles.Result} key={item.key} onClick={() => setCurrentresult(item.result)}>
                                <Typography>{`${item.expression}${item.result}`}</Typography>
                            </div>
                        ))}
                    </div>
                )}
                <div className={styles.Calculator}>
                    <SuspenseLoader>
                        <Calculator value={currentResult} onResultChange={result => addComputation(result)} />
                    </SuspenseLoader>
                </div>
            </div>
        </div>
    )
}
