import { useContext, useEffect, useRef, useState } from 'react'
import { Box, Button, ButtonProps as MuiButtonProps, Popover, Typography } from '@mui/material'
import { isEmpty } from 'lodash'

import { DraftStatus, VisualType } from 'genesis-suite/types/visualTypes'
import { SwalContext } from 'genesis-suite/components'
import ApprovalDashboard from './ApprovalDashboard'
import { ApprovalsContext } from '../contexts/ApprovalsContext'
import ApprovalWidget from './ApprovalWidget'
import ApprovalModule from './ApprovalModule'
import VisualReferences from '../widgets2/VisualReferences'
import { useCounts } from './utils'
import DraftIndicator from '../DraftIndicator'

export default function ManageApprovals() {
    const { confirm } = useContext(SwalContext)
    const { changeStatus, visual, deleteVisual, setCounts } = useContext(ApprovalsContext)
    const { data: counts, mutate: refreshCounts } = useCounts()

    useEffect(() => {
        setCounts(counts)
    }, [counts])

    const [showReferences, setShowReferences] = useState(false)
    const referencesButtonRef = useRef(null)

    if (!visual)
        return (
            <Box flex={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Typography gutterBottom variant="h4">
                    Approvals
                </Typography>
                <Typography variant="h6">
                    {counts?.all.all.total
                        ? 'Select a change to review'
                        : 'Nothing to do here. Once created, drafts will show up here.'}
                </Typography>
            </Box>
        )

    const { type, config } = visual

    async function handleAction(action: ButtonAction) {
        try {
            if (action.type === 'delete') {
                const response = await confirm('Are you sure you want to delete this draft?', { type: 'question' })
                if (response.dismiss) return

                await deleteVisual(config.id)
            } else {
                await changeStatus(action.status)
            }
            refreshCounts()
        } catch (err) {
            console.error(err)
        }
    }

    const liveId = config.draft?.visualId || config.id
    const { bad, good } = makeButtonProps(config.draft.status)
    const { action: badAction, ...badButtonProps } = bad || {}
    const { action: goodAction, ...goodButtonProps } = good || {}

    return (
        <Box flex={1} display="flex" flexDirection="column" overflow="hidden">
            <Box display="flex" alignItems="center" justifyContent="space-between" p={1}>
                <Box display="flex" alignItems="center" gap={1}>
                    <DraftIndicator {...visual} viewOnly />
                    <Typography variant="h4">
                        {config.title} (Status: {config.draft.status})
                    </Typography>
                </Box>

                <Box display="flex" alignItems="center" gap={1}>
                    {type !== VisualType.MODULE && (
                        <>
                            <Button ref={referencesButtonRef} onClick={() => setShowReferences(true)}>
                                References
                            </Button>
                            <Popover
                                anchorEl={referencesButtonRef.current}
                                open={showReferences}
                                onClose={() => setShowReferences(false)}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            >
                                <Box width="510px" height="400px" display="flex">
                                    <VisualReferences id={liveId} type={type} />
                                </Box>
                            </Popover>
                        </>
                    )}
                    {!isEmpty(badButtonProps) && (
                        <Button
                            color="error"
                            variant="contained"
                            onClick={() => handleAction(badAction)}
                            {...badButtonProps}
                        />
                    )}
                    {!isEmpty(goodButtonProps) && (
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => handleAction(goodAction)}
                            {...goodButtonProps}
                        />
                    )}
                </Box>
            </Box>

            <Content type={type} config={config} />
        </Box>
    )
}

interface ButtonAction {
    type: 'update-status' | 'delete'
    /** if update-status, the new status */
    status?: DraftStatus
}

interface ButtonProps extends Omit<MuiButtonProps, 'action'> {
    action: ButtonAction
}

function makeButtonProps(status: DraftStatus): { bad?: ButtonProps; good?: ButtonProps } {
    switch (status) {
        case DraftStatus.EDITING:
            return {
                bad: { children: 'Delete draft', action: { type: 'delete' } },
                good: { children: 'Submit for review', action: { type: 'update-status', status: DraftStatus.PENDING } },
            }
        case DraftStatus.PENDING:
            return {
                bad: { children: 'Reject', action: { type: 'update-status', status: DraftStatus.REJECTED } },
                good: { children: 'Approve', action: { type: 'update-status', status: DraftStatus.APPROVED } },
            }
        case DraftStatus.APPROVED:
            return {
                bad: { children: 'Reject', action: { type: 'update-status', status: DraftStatus.REJECTED } },
                good: {
                    action: { type: 'update-status', status: DraftStatus.PENDING },
                    children: 'Back to pending',
                    color: 'inherit',
                },
            }
        case DraftStatus.REJECTED:
            return {
                good: {
                    action: { type: 'update-status', status: DraftStatus.PENDING },
                    children: 'Back to pending',
                    color: 'inherit',
                },
            }
    }
}

function Content({ type, config }) {
    switch (type) {
        case 'module':
            return <ApprovalModule config={config} />
        case 'dashboard':
            return <ApprovalDashboard config={config} />
        case 'widget':
            return <ApprovalWidget config={config} />
    }
}
