import { IconButton, Typography } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Close } from 'genesis-suite/icons'
import { filterCreators, navigationCreators } from '../actions/creators'
import { ColOptionsProvider } from '../components/contexts/ColumnOptionsContext'
import Widget from '../components/widgets/Widget'
import { filterSelectors, widgetSelectors } from '../selectors'
import WidgetFilters from '~/components/widgets/WidgetFilters'

const height = '80vh'
const titleHeight = '64px'

const styles = theme => ({
    container: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    widget: {
        width: '100%',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        height: `calc(${height} - ${titleHeight})`,
        '& ::-webkit-scrollbar': {
            width: 12,
            height: 12,
        },
        '& .widget-setup-container': {
            height: '100%',
        },
    },
    titleWrapper: {
        maxHeight: titleHeight,
        width: '100%',
        padding: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'center',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    title: {
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    controls: {
        position: 'absolute',
        right: 0,
        zIndex: 2,
        padding: theme.spacing(1, 1, 0, 0),
        backgroundColor: theme.palette.background.main,
    },
    content: {
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
        display: 'flex',
        justifyContent: 'center',
        flex: 1,
        flexDirection: 'column',
    },
})

const SyncChartContainer = ({ classes, onRequestClose, widgetConfig }) => {
    const widgetRef = useRef()
    const dispatch = useDispatch()
    const buttonStyle = { color: 'text.primary' }

    const filters = useSelector(filterSelectors.currentFiltersConfiguration)
    const inlineFilters = useSelector(filterSelectors.getInlineFilters)
    const networkContext = useSelector(filterSelectors.getCoord)
    const interactionType = useSelector(widgetSelectors.getInteractionType)

    const setInlineFilters = inlineFilters => dispatch(filterCreators.setInlineFilters(inlineFilters))

    const goToPerspective = (perspectiveId, context) => {
        onRequestClose()
        dispatch(navigationCreators.goToPerspective(perspectiveId, { context }))
    }

    return (
        <div className={classes.container}>
            <div className={classes.controls}>
                <WidgetFilters
                    buttonStyle={buttonStyle}
                    filterConfigs={widgetConfig.DynamicFiltersConfig}
                    widgetId={widgetConfig.Id}
                />
                <IconButton sx={buttonStyle} onClick={onRequestClose}>
                    <Close />
                </IconButton>
            </div>
            <div className={classes.content}>
                <ColOptionsProvider id={widgetConfig.Id}>
                    <div style={{ height: '100%' }}>
                        <div className={classes.titleWrapper}>
                            <Typography className={classes.title} variant="h5">
                                {widgetConfig.Title}
                            </Typography>
                        </div>
                        <div className={classes.widget}>
                            <Widget
                                ref={widgetRef}
                                config={widgetConfig}
                                filters={filters}
                                networkContext={networkContext}
                                inlineFilters={inlineFilters}
                                appMode="presentation"
                                interactionType={interactionType}
                                goToPerspective={(perspectiveId, crumb) => goToPerspective(perspectiveId, crumb)}
                                interactions={{ disableContextMenu: true }}
                                setInlineFilters={setInlineFilters}
                                isSyncChart={true}
                            />
                        </div>
                    </div>
                </ColOptionsProvider>
            </div>
        </div>
    )
}
export default withStyles(styles)(SyncChartContainer)
