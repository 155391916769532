import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, List, ListItem, ListItemButton, Typography } from '@mui/material'
import produce from 'immer'
import { has, isEmpty } from 'lodash'

import { DateOrTime } from 'genesis-suite/components'
import { ChangeEntry, DraftKey, VisualObject, VisualObjectByType, VisualType } from 'genesis-suite/types/visualTypes'
import { visualService } from '../../lib/services'
import { applicationSelectors } from '../../selectors'
import WidgetCompare from '../ManageWidgets/WidgetCompare'
import ViewStatusIndicator from '../ViewStatusIndicator'
import DisplayDiff from './DisplayDiff'
import CustomDashboard from './dashboard/CustomDashboard'
import { DashboardAction } from './dashboard/types'
import { getVisualById, userDisplayName } from '../../lib/manageUtils'

export const changesColumnWidth = 350
const gridTemplateColumns = '18px 10px 80px 10px 150px 10px auto'

export type HistoryCompareMode = 'selected' | 'compare-live' | 'compare-previous'

export interface Props<T extends VisualType> {
    config: VisualObjectByType<T>
    /** (default selected) Choose how to compare changes */
    mode?: HistoryCompareMode
    /** (optional) if provided and is in log, select that change */
    publishId?: string
    type: T
}

/** Show visual revision changes. If it is draft, show it as the last option */
export default function VisualHistory<T extends VisualType>({ config, mode = 'selected', publishId, type }: Props<T>) {
    const isDraft = Boolean(config.draft)
    const liveId = config.draft?.visualId ?? config.id

    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const [changeLog, setChangeLog] = useState<ChangeEntry[]>(null)
    const [selectedRevision, setSelectedRevision] = useState<number>(null)
    const [liveConfig, setLiveConfig] = useState<VisualObject>(isDraft ? null : config)

    useEffect(() => {
        visualService.getChangesById(appName, liveId).then(changes => {
            if (!changes) {
                setChangeLog([])
                setSelectedRevision(isDraft ? 1 : 0)
                return
            }

            const fromDraft = isEmpty(changes.log[0].diff)
            const log = changes.log.reverse()
            const count = changes.count - (fromDraft ? 1 : 0)
            setChangeLog(log)
            if (publishId) setSelectedRevision(count - log.findIndex(c => c.publish?.id === publishId) ?? 0)
            else setSelectedRevision(count + (isDraft ? 1 : 0))
        })

        if (isDraft) getVisualById(appName, type, liveId).then(setLiveConfig)
    }, [liveId])

    if (!changeLog?.length || !liveConfig) return null

    const draftEntry: ChangeEntry = { createdAt: config.updatedAt, createdBy: config.updatedBy, diff: '' }
    const firstEntry: ChangeEntry = !isEmpty(changeLog[0].diff) && {
        createdAt: liveConfig.createdAt,
        createdBy: liveConfig.createdBy,
        diff: '',
    }
    const items = [...(isDraft ? [draftEntry] : []), ...changeLog, ...(firstEntry ? [firstEntry] : [])]
    const { after, before } = makeBeforeAndAfter(config, liveConfig, changeLog, selectedRevision, mode)

    return (
        <Box flex={1} display="flex" overflow="hidden" bgcolor="background.content">
            <Box
                sx={{
                    borderRight: 1,
                    borderColor: 'divider',
                    display: 'flex',
                    gap: 1,
                    flexDirection: 'column',
                    overflow: 'auto',
                    width: changesColumnWidth,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'hidden',
                        ...(type !== VisualType.MODULE && {
                            flexShrink: 0,
                            maxHeight: '30%',
                            borderBottom: 1,
                            borderColor: 'divider',
                        }),
                    }}
                >
                    <List dense disablePadding>
                        <ListItem sx={{ display: 'grid', gridTemplateColumns }}>
                            <Typography variant="caption">Ver</Typography>
                            <Typography variant="caption">|</Typography>
                            <Typography variant="caption">Date</Typography>
                            <Typography variant="caption">|</Typography>
                            <Typography variant="caption">Author</Typography>
                            <Typography variant="caption">|</Typography>
                            <Typography variant="caption">Publish</Typography>
                        </ListItem>
                    </List>

                    <List sx={{ overflow: 'auto' }}>
                        {items.map((c, index) => {
                            const revision = items.length - index - 1
                            return (
                                <ListItemButton
                                    key={revision}
                                    onClick={() => setSelectedRevision(revision)}
                                    selected={selectedRevision === revision}
                                    sx={{ display: 'grid', gridTemplateColumns }}
                                >
                                    <Box margin="auto">
                                        {index === 0 ? (
                                            <ViewStatusIndicator type={isDraft ? config.draft.type : undefined} />
                                        ) : isDraft && index === 1 ? (
                                            <ViewStatusIndicator />
                                        ) : (
                                            <Typography>{revision + 1}</Typography>
                                        )}
                                    </Box>
                                    <Typography>&nbsp;</Typography>
                                    <DateOrTime>{c.createdAt}</DateOrTime>
                                    <Typography>&nbsp;</Typography>
                                    <Typography>{userDisplayName(c.createdBy)}</Typography>
                                    <Typography>&nbsp;</Typography>
                                    <Typography>{c.publish?.type ?? ' '}</Typography>
                                </ListItemButton>
                            )
                        })}
                    </List>
                </Box>

                {type !== VisualType.MODULE && (
                    <Box display="flex" flexDirection="column" overflow="hidden">
                        <Typography variant="h5" gutterBottom sx={{ ml: 1 }}>
                            Config
                        </Typography>
                        <DisplayDiff before={before.config} after={after.config} />
                    </Box>
                )}
            </Box>

            <Box display="flex" flex={1} flexDirection="column" gap={1} overflow="hidden" p={1}>
                {
                    {
                        [VisualType.DASHBOARD]: (
                            <CustomDashboard actions={dashboardActions} config={after.config as any} editable={false} />
                        ),
                        [VisualType.MODULE]: <DisplayDiff before={before.config} after={after.config} />,
                        [VisualType.WIDGET]: <WidgetCompare after={after as any} before={before as any} />,
                    }[type]
                }
            </Box>
        </Box>
    )
}

const dashboardActions: DashboardAction[] = ['device-toggle', 'filters']

interface ConfigAndLabel<T extends VisualType> {
    config?: VisualObjectByType<T>
    label?: string | JSX.Element
}

function makeBeforeAndAfter<T extends VisualType>(
    config: VisualObject,
    liveConfig: VisualObject,
    changeLog: ChangeEntry[],
    selectedRevision: number,
    mode: HistoryCompareMode
) {
    const after: ConfigAndLabel<T> = {}
    const before: ConfigAndLabel<T> = {}

    function TitleWithIndicator({ type }: { type?: DraftKey }) {
        return (
            <Box display="flex" alignItems="center" gap={1}>
                <ViewStatusIndicator type={type} />
                <Typography variant="h5">{type ? 'Draft' : `Version ${changeLog.length + 1} (Live)`}</Typography>
            </Box>
        )
    }

    if (selectedRevision === changeLog.length + 1) {
        after.config = removeUnusedProps(config)
        if (mode !== 'selected') {
            after.label = <TitleWithIndicator type={config.draft?.type} />
            before.config = removeUnusedProps(liveConfig)
            before.label = <TitleWithIndicator />
        }
    } else if (selectedRevision === changeLog.length) {
        after.config = removeUnusedProps(liveConfig)
        if (mode === 'compare-previous') {
            after.label = <TitleWithIndicator />
            before.config = makePreviousConfig(liveConfig, changeLog, selectedRevision)
            before.label = `Version ${selectedRevision}`
        }
    } else if (selectedRevision === 0) {
        after.config = makePreviousConfig(liveConfig, changeLog, selectedRevision + 1)
        if (mode === 'compare-live') {
            after.label = `Version 1`
            before.config = removeUnusedProps(liveConfig)
            before.label = <TitleWithIndicator />
        }
    } else {
        after.config = makePreviousConfig(liveConfig, changeLog, selectedRevision + 1)
        if (mode === 'compare-previous') {
            after.label = `Version ${selectedRevision + 1}`
            before.config = makePreviousConfig(liveConfig, changeLog, selectedRevision)
            before.label = `Version ${selectedRevision}`
        } else if (mode === 'compare-live') {
            after.label = `Version ${selectedRevision + 1}`
            before.config = removeUnusedProps(liveConfig)
            before.label = <TitleWithIndicator />
        }
    }

    return { after, before }
}

function makePreviousConfig(config: VisualObject, changeLog: ChangeEntry[], selectedRevision?: number) {
    if (!selectedRevision || !changeLog.length) return

    const steps = changeLog.length - selectedRevision + 1
    const changesToApply = changeLog.slice(0, steps)

    function applyMultipleDiffs(config, changesToApply: ChangeEntry[]) {
        const change = changesToApply.shift()
        const newConfig = applyDiff(config, change.diff)
        return changesToApply.length ? applyMultipleDiffs(newConfig, changesToApply) : newConfig
    }

    return applyMultipleDiffs(removeUnusedProps(config), changesToApply)
}

function applyDiff(config, diff) {
    function setter(object: object, keys: string[], value) {
        const [key, ...nextKeys] = keys
        if (nextKeys.length === 0) object[key] = value
        else if (object[key] !== undefined) setter(object[key], nextKeys, value)
    }

    return produce(config, draft => {
        function apply(diff, path = '') {
            if (!diff) return

            Object.entries(diff).forEach(([key, value]) => {
                const pathWithKey = path ? `${path}.${key}` : key
                const pathArray = pathWithKey.split('.')

                if (value === '__delete') setter(draft, pathArray, undefined)
                else if (has(draft, pathWithKey) && typeof value === 'object') apply(value, pathWithKey)
                else setter(draft, pathArray, value)
            })
        }
        apply(diff)

        function removeEmpty(object: object) {
            let arrayItemDeleteCount = 0
            Object.entries(object).forEach(([key, value]) => {
                if (typeof value === 'object') {
                    removeEmpty(value)
                } else if (value === undefined) {
                    if (Array.isArray(object)) {
                        object.splice(Number(key) - arrayItemDeleteCount, 1)
                        arrayItemDeleteCount++
                    } else {
                        delete object[key]
                    }
                }
            })
        }
        removeEmpty(draft)
    })
}

function removeUnusedProps(config: VisualObject): any {
    const { active, updatedAt, createdAt, updatedBy, ...rest } = config
    return rest
}
