import produce from 'immer'
import { userNavTypes, moduleTypes } from '../actions/types'

const initialState = {
    data: {},
}

export default (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case userNavTypes.USER_NAVS_SET: {
            const data = payload.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {})
            return { ...state, data }
        }
        case userNavTypes.USER_NAV_SET: {
            return { ...state, data: { ...state.data, [payload.id]: payload } }
        }
        case userNavTypes.USER_NAV_DELETE: {
            return produce(state, draft => {
                delete draft.data[payload]
            })
        }
        case moduleTypes.MODULE_2_SUCCESS:
            return initialState
        default:
            return state
    }
}
