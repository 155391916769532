import { Box, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { GoogleMap, LoadScript, Rectangle } from '@react-google-maps/api'
import { SpatialGridCell } from 'genesis-suite/types/spatialTypes'
import { MapConfig } from 'genesis-suite/types/visualTypes'
import produce from 'immer'
import { forwardRef, memo, useEffect, useMemo, useRef, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { spatialService } from '../../../lib/services'
import { WidgetProps } from '../../../types/WidgetTypes'
import GridLineToggle from './GridLineToggle'
import RenderMapHeat from './MapHeat'
import MapLegend from './MapLegend'
import RenderMapMarkers from './MapMarkers'
import MapOverlays from './MapOverlays'
import {
    createGridCellStyles,
    getGridCellBounds,
    getGridCellsResolution,
    getMapBounds,
    initializePosition,
    useGeoData,
} from './mapUtils'

const useStyles = makeStyles(() => ({
    fullHeight: { height: '100%' },
}))

const MapWidget2 = forwardRef<HTMLDivElement, WidgetProps<MapConfig>>(({ customInteractions, data, ...props }, ref) => {
    const { zoom, surface, legend, options, overlays, series, grid } = props.config
    const { onMapGridToggle } = customInteractions || {}

    const classes = useStyles()
    const { palette } = useTheme()

    const [disabledSeriesIndexes, setDisabledSeriesIndexes] = useState([])
    const [gridCells, setGridCells] = useState<Array<SpatialGridCell>>([])
    const [gridCellStyles, setGridCellStyles] = useState({})

    const geoData = useGeoData(series, data)
    const { bounds, center } = useMemo(() => initializePosition(geoData), [geoData]) || {}

    const mapInstance = useRef<google.maps.Map>()
    const cancelCall = useRef(null)

    useEffect(() => {
        if (!mapInstance.current) return
        refresh()
    }, [surface, zoom])

    function refresh() {
        if (surface) mapInstance.current.setMapTypeId(surface)
        if (zoom == null && bounds) mapInstance.current.fitBounds(bounds)
        setGridCellStyles(createGridCellStyles(mapInstance, palette))
    }

    function handleLoad(map) {
        mapInstance.current = map
        refresh()
    }

    function handleLegendClick(index: number) {
        const disabledIndex = disabledSeriesIndexes.indexOf(index)
        setDisabledSeriesIndexes(s =>
            produce(s, draft => {
                if (disabledIndex === -1) draft.push(index)
                else draft.splice(disabledIndex, 1)
            })
        )
    }

    const getGrid = useDebouncedCallback(async () => {
        const { nwBound, seBound } = getMapBounds(mapInstance)
        const currentZoom = mapInstance.current.getZoom()
        const resolution = getGridCellsResolution(currentZoom)

        try {
            cancelCall.current?.()

            if (resolution === 6) {
                const allGridCells = await spatialService.getAllGridCells(cancel => (cancelCall.current = cancel))
                setGridCells(allGridCells)
            } else {
                const { gridCells } = await spatialService.getGrid(
                    nwBound,
                    seBound,
                    resolution,
                    cancel => (cancelCall.current = cancel)
                )
                setGridCells(gridCells)
            }
        } catch (error) {
            if (error.__CANCEL__) return
            console.error(error)
        }
    }, 200)

    function handleBoundsChange() {
        if (!grid?.show) return
        getGrid()
    }

    function handleMapTypeChange() {
        if (!mapInstance.current) return
        setGridCellStyles(createGridCellStyles(mapInstance, palette))
    }

    function handleGridToggle(show) {
        if (show) getGrid()
        else setGridCells([])
        onMapGridToggle?.(show)
    }

    if (!geoData) return null

    const enabledGeoData = geoData.filter((_, i) => !disabledSeriesIndexes.includes(i))

    return (
        <div ref={ref} className={classes.fullHeight} data-cy="maps">
            <LoadScript googleMapsApiKey={window.TADA_APIS.GOOGLE_MAPS_KEY}>
                <GoogleMap
                    mapContainerClassName={classes.fullHeight}
                    zoom={zoom ?? 1}
                    center={center}
                    options={{
                        disableDefaultUI: true,
                        fullscreenControl: true,
                        mapTypeControl: true,
                        panControl: true,
                        rotateControl: false,
                        scaleControl: false,
                        streetViewControl: false,
                        zoomControl: true,
                        ...options,
                    }}
                    onLoad={handleLoad}
                    onBoundsChanged={handleBoundsChange}
                    onMapTypeIdChanged={handleMapTypeChange}
                >
                    <MapOverlays overlays={overlays} />
                    <Box
                        sx={{
                            alignItems: 'flex-start',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                            left: 10,
                            position: 'absolute',
                            top: options?.mapTypeControl === false ? 10 : 56,
                        }}
                    >
                        <MapLegend
                            enabled={legend?.enabled ?? true}
                            configSeries={series}
                            mapData={data}
                            disabledIndexes={disabledSeriesIndexes}
                            onClick={handleLegendClick}
                        />
                        <GridLineToggle {...grid} onToggle={handleGridToggle} />
                    </Box>
                    {gridCells?.map(({ absoluteId, northWestCoordinates, southEastCoordinates }, index) => (
                        <Rectangle
                            key={`${absoluteId}-${index.toString()}`}
                            options={{ ...gridCellStyles }}
                            bounds={getGridCellBounds(northWestCoordinates, southEastCoordinates)}
                        />
                    ))}
                    {enabledGeoData.map((s, index) =>
                        s.type === 'marker' ? (
                            <MapMarkers key={s.key} {...props} {...s} seriesIndex={index} />
                        ) : s.type === 'heat' ? (
                            <MapHeat key={s.key} {...props} {...s} seriesIndex={index} />
                        ) : null
                    )}
                </GoogleMap>
            </LoadScript>
        </div>
    )
})

export default MapWidget2

const MapMarkers = memo(RenderMapMarkers)
const MapHeat = memo(RenderMapHeat)
