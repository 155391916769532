import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'

import { applicationSelectors, navigationSelectors, deploymentSelectors } from '../selectors'
import { visualService } from '../lib/services'
import DashboardWidget from '../components/widgets2/dashboard/DashboardWidget'

export default function WidgetView() {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const id = useSelector(navigationSelectors.getCurrentWidgetId)
    const viewFlag = useSelector(deploymentSelectors.getDeploymentViewFlag)
    const [config, setConfig] = useState(null)

    useEffect(() => {
        if (!id) return

        visualService.getWidgetById(appName, id, viewFlag).then(setConfig)
    }, [id])

    if (!config) return null

    return (
        <Box flex={1} m={2}>
            <DashboardWidget config={config} />
        </Box>
    )
}
