import { useRef } from 'react'
import classnames from 'classnames'
import { IconButton } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { ChevronRight } from 'genesis-suite/icons'
import { useHover } from 'genesis-suite/hooks'

const styles = theme => ({
    container: {
        height: '100%',
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    arrowButton: {
        height: '35px',
        width: '35px',
        margin: '1px',
        background: theme.palette.primary.main,
        borderRadius: '60rem',
        opacity: 0,
        transition: 'opacity 0.5s',
    },
    left: {
        transform: 'rotate(180deg)',
    },
    right: {
        transform: 'rotate(0deg)',
    },
    active: {
        opacity: 1,
    },
    disabled: {
        opacity: 0.3,
    },
})

/**
 * Renders a panel with a button that is visible when hovering anywhere in the panel
 * @param {() => void} onClick - callback when the button is clicked
 * @param {boolean} disabled - whether or not the button should be disabled
 * @param {("left" | "right")} - orientation of the panel relative to the content
 */
const ArrowPanel = withStyles(styles)(({ onClick, disabled, orientation, classes }) => {
    const ref = useRef()
    const hover = useHover(ref)
    return (
        <div className={classes.container} ref={ref}>
            <IconButton
                disabled={disabled}
                className={classnames(classes.arrowButton, {
                    [classes.active]: hover,
                    [classes.disabled]: disabled && hover,
                })}
                onClick={onClick}
                size="large">
                <ChevronRight className={classes[orientation]} />
            </IconButton>
        </div>
    );
})

export default ArrowPanel
