import { useRef, useState } from 'react'
import { Popover, IconButton, Box, TextField, Button } from '@mui/material'
import Cancel from '@mui/icons-material/Cancel'
import Done from '@mui/icons-material/Done'
import AddIcon from '@mui/icons-material/Add'

export default function NewModuleButton({ onNew }) {
    const [open, setOpen] = useState(false)
    const [title, setTitle] = useState('')
    const buttonRef = useRef(null)
    const buttonStyles = { '&.MuiButtonBase-root:hover': { backgroundColor: 'action.hover' }, color: 'text.primary' }

    async function handleSave() {
        if (!title) return
        handleDone()
        onNew(title)
    }

    function handleDone() {
        setTitle('')
        setOpen(false)
    }

    return (
        <>
            <Button
                ref={buttonRef}
                variant="contained"
                startIcon={<AddIcon fontSize="small" />}
                color="secondary"
                onClick={() => setOpen(true)}
            >
                Add
            </Button>
            <Popover
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={open}
                onClose={handleDone}
                anchorEl={buttonRef.current}
            >
                <Box display="flex" alignItems="center" p={2}>
                    <TextField
                        placeholder="Application Title"
                        autoFocus
                        fullWidth
                        value={title}
                        onFocus={e => e.target.select()}
                        onChange={e => setTitle(e.target.value)}
                        onKeyDown={e => {
                            e.stopPropagation()
                            e.key === 'Enter' && handleSave()
                        }}
                    />
                    <IconButton sx={buttonStyles} size="small" onClick={handleSave}>
                        <Done fontSize="small" />
                    </IconButton>
                    <IconButton sx={buttonStyles} size="small" onClick={handleDone}>
                        <Cancel fontSize="small" />
                    </IconButton>
                </Box>
            </Popover>
        </>
    )
}
