import { visualService } from '../../lib/services'
import { applicationSelectors, moduleSelectors, deploymentSelectors } from '../../selectors'
import { userNavTypes } from '../types'

/** Fetch dashboards user has selected as nav */
const get = () => (dispatch, getState) => {
    if (!window.TADA_APIS.VISUALS) return

    const state = getState()
    const appName = applicationSelectors.getCurrentAppName(state)
    const moduleId = moduleSelectors.getModuleId(state)
    const isV2 = moduleSelectors.getIsV2(state)
    const viewFlag = deploymentSelectors.getDeploymentViewFlag(state)

    const options = {
        author: 'user',
        global: true,
        inNavigation: true,
        ...(isV2 && { moduleId }),
    }
    visualService.getMetaDashboards(appName, options, viewFlag).then(({ data }) => {
        if (!data) return
        dispatch({ type: userNavTypes.USER_NAVS_SET, payload: data })
    })
}

const add = data => ({ type: userNavTypes.USER_NAV_SET, payload: data })
const remove = id => ({ type: userNavTypes.USER_NAV_DELETE, payload: id })

export const userNavCreators = { get, add, remove }
