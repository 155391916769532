import ChevronLeft from '@mui/icons-material/ChevronLeft'
import ChevronRight from '@mui/icons-material/ChevronRight'
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Chip,
    Fade,
    FormControlLabel,
    IconButton,
    MenuItem,
    Popover,
    Select,
    Tab,
    Tabs,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import isEmpty from 'lodash/isEmpty'
import uniqBy from 'lodash/uniqBy'
import moment from 'moment'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DayPickerRangeController } from 'react-dates'
import 'react-dates/initialize'
import { useSelector } from 'react-redux'

import { searchService } from '../lib/services'
import { makeTemporalFilterDisplayText } from '../lib/utils'
import { applicationSelectors } from '../selectors'
import '../styles/ReactDatesCalendar.css'

const DATE_FORMAT = 'MM/DD/YYYY'
const INPUT_DATE_FORMAT = 'YYYY-MM-DD'
const NO_FILTER_TEXT = 'New filter'
const ROLLING_TYPE = 'Rolling'
const FIXED_TYPE = 'Fixed'
const CUSTOM_TYPE = 'Custom'

const OPTION_START = 'start'
const OPTION_END = 'end'
const OPTION_PREVIOUS = 'previous'
const OPTION_FUTURE = 'future'
const OPTION_CENTER = 'center'

const makeFromOptions = useLastUpdated => ({
    [OPTION_START]: 'The beginning',
    [OPTION_PREVIOUS]: 'Previous',
    [OPTION_CENTER]: useLastUpdated ? 'Data last updated' : 'Today',
})

const makeUntilOptions = (useLastUpdated, showToday) => ({
    [OPTION_END]: 'The end',
    [OPTION_FUTURE]: 'Future',
    ...(showToday && { [OPTION_CENTER]: useLastUpdated ? 'Data last updated' : 'Today' }),
})

const useStyles = makeStyles(({ palette, spacing }) => ({
    button: { height: '24px', marginTop: spacing(1) },
    header: { display: 'flex', justifyContent: 'space-between' },
    tabs: {
        minHeight: 0,
        width: '100%',
        padding: spacing(0, 1),
        margin: spacing(1, 0),
        borderBottom: `1px solid ${palette.grayscale.lightest}`,
    },
    tab: { minWidth: '85px', minHeight: 0, color: palette.text.primary },
    tabContent: { flex: 1, margin: spacing(0, 2), minHeight: '70px' },
    contentContainer: { minHeight: '88px' },
    selectsContainer: { display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center' },
    helpText: { color: palette.grey['500'], fontStyle: 'italic' },
    selectContainer: {
        width: '250px',
        display: 'flex',
        alignItems: 'center',
        '& > *': { paddingRight: spacing() },
        '& > *:last-child': { paddingRight: 0 },
    },
    dateInput: {
        '&::-webkit-clear-button': { display: 'none' },
        '&::-webkit-inner-spin-button': { display: 'none' },
        '&::-webkit-calendar-picker-indicator': { display: 'none' },
    },
    pickerWrapper: {
        '& .CalendarDay': { cursor: p => (p.selectedType !== FIXED_TYPE ? 'not-allowed' : '') },
        '& > div': { margin: 'auto' },
    },
    textFieldStyle: {
        '& .MuiAutocomplete-endAdornment svg': {
            color: palette.text.primary,
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.text.primary,
        },
        '&:hover .MuiOutlinedInput-notchedOutline,': {
            borderColor: palette.text.primary,
        },
        '& .MuiSvgIcon-root': {
            color: palette.text.primary,
        },
    },
}))

export default function DateFilter({
    filter,
    rawValues,
    selectedValues,
    onRangeChange,
    onDateOffsetChange,
    onClickRangeChange,
    isWidget = false,
}) {
    const { ClickRangeNames } = filter

    const applicationName = useSelector(applicationSelectors.getCurrentAppName)
    const lastRefreshDate = useSelector(applicationSelectors.getLastUpdateDate)

    const [focused, setFocused] = useState('startDate')
    const [open, setOpen] = useState(false)
    const [selectedType, setSelectedType] = useState(isWidget ? FIXED_TYPE : ROLLING_TYPE)

    const [rollingFrom, setRollingFrom] = useState(OPTION_START)
    const [rollingMin, setRollingMin] = useState(null)
    const [pastDateType, setPastDateType] = useState('days')
    const [futureDateType, setFutureDateType] = useState('days')
    const [rollingUntil, setRollingUntil] = useState(OPTION_END)
    const [rollingMax, setRollingMax] = useState(null)
    const [rollingUseLastDate, setRollingUseLastDate] = useState(false)

    const [clickRangeValue, setClickRangeValue] = useState(null)

    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    // separate calendar dates needed to prevent calendar from rendering until user is done manually typing date
    const [calendarStartDate, setCalendarStartDate] = useState('')
    const [calendarEndDate, setCalendarEndDate] = useState('')

    const buttonRef = useRef(null)
    const startDateRef = useRef(null)
    const endDateRef = useRef(null)

    const theme = useTheme()
    const isPhone = useMediaQuery(theme.breakpoints.down('sm'))

    const classes = useStyles({ selectedType })

    const types = [
        { value: ROLLING_TYPE, label: 'Rolling' },
        { value: FIXED_TYPE, label: 'Fixed' },
        ...(isEmpty(ClickRangeNames) ? [] : [{ value: CUSTOM_TYPE, label: 'Custom' }]),
    ]

    const fromOptions = makeFromOptions(rollingUseLastDate)
    const untilOptions = makeUntilOptions(rollingUseLastDate, rollingFrom !== OPTION_CENTER)
    const buttonText = makeTemporalFilterDisplayText(selectedValues, pastDateType, futureDateType) || NO_FILTER_TEXT

    const dateTypeMap = {
        days: 'days',
        months: 'months',
        years: 'years',
    }
    // init
    useEffect(() => {
        if (!open) return
        const { range, rangeOffset, useLastRefreshDate, clickRangeName } = selectedValues
        let selectedType = isWidget ? FIXED_TYPE : ROLLING_TYPE
        if (!isEmpty(range)) {
            const { min, max } = range
            const startDate = min ? moment(min).format(INPUT_DATE_FORMAT) : ''
            const endDate = max ? moment(max).format(INPUT_DATE_FORMAT) : ''
            setStartDate(startDate)
            setCalendarStartDate(startDate)
            setEndDate(endDate)
            setCalendarEndDate(endDate)
            selectedType = FIXED_TYPE
        } else if (!isEmpty(rangeOffset)) {
            const { min, max } = rangeOffset

            if (min === undefined) setRollingFrom(OPTION_START)
            else if (min === 0) setRollingFrom(OPTION_CENTER)
            else {
                setRollingFrom(OPTION_PREVIOUS)
                setRollingMin(`${-min}`)
            }

            if (max === undefined) setRollingUntil(OPTION_END)
            else if (max === 0) setRollingUntil(OPTION_CENTER)
            else {
                setRollingUntil(OPTION_FUTURE)
                setRollingMax(`${max}`)
            }

            setRollingUseLastDate(useLastRefreshDate)
        } else if (clickRangeName) {
            setClickRangeValue(clickRangeName)
            selectedType = CUSTOM_TYPE
        }
        setSelectedType(selectedType)
    }, [open, selectedValues])
    // update local dates
    useEffect(() => {
        if (selectedType === FIXED_TYPE) return // controlled by dates directly

        let startDate, endDate
        switch (selectedType) {
            case ROLLING_TYPE: {
                const centerDate = rollingUseLastDate ? lastRefreshDate : undefined
                const min = parseInt(rollingMin)
                const max = parseInt(rollingMax)
                if (rollingFrom === OPTION_CENTER) startDate = moment(centerDate)
                else if (rollingFrom === OPTION_PREVIOUS)
                    startDate = moment(centerDate).subtract(min, dateTypeMap[pastDateType])
                if (rollingUntil === OPTION_CENTER) endDate = moment(centerDate)
                else if (rollingUntil === OPTION_FUTURE)
                    endDate = moment(centerDate).add(max, dateTypeMap[futureDateType])
                break
            }
            case CUSTOM_TYPE: {
                const [min, max] = getClickRangeDates()
                startDate = min
                endDate = max
                break
            }
            default:
                break
        }

        setStartDate(startDate ? startDate.format(INPUT_DATE_FORMAT) : '')
        setCalendarStartDate(startDate ? startDate.format(INPUT_DATE_FORMAT) : '')
        setEndDate(endDate ? endDate.format(INPUT_DATE_FORMAT) : '')
        setCalendarEndDate(endDate ? endDate.format(INPUT_DATE_FORMAT) : '')
    }, [selectedType, rollingFrom, rollingMin, rollingUntil, rollingMax, rollingUseLastDate, clickRangeValue])

    async function getClickRangeDates() {
        const { Min, Max } = await searchService.getRangeFilterBoundaries(clickRangeValue, applicationName)
        return [moment(Min || undefined), moment(Max || undefined)]
    }

    useEffect(() => {
        if (!startDateRef.current || !endDateRef.current) return
        handleFixedInputBlur()

        if (focused === 'startDate') {
            endDateRef.current.blur()
            if (document.activeElement !== startDateRef.current) startDateRef.current.select()
        } else if (focused === 'endDate') {
            startDateRef.current.blur()
            if (document.activeElement !== endDateRef.current) endDateRef.current.select()
        }
    }, [focused])

    // HACK: calendar requires rerender to populate endDate on mount?
    const [update, setUpdate] = useState(false)
    useEffect(() => {
        if (!open) return
        setTimeout(() => setUpdate(u => !u), 0)
    }, [open])

    const handleFixedDateChange = key => e => {
        const value = e.target.value

        if (key === 'startDate') setStartDate(value)
        else setEndDate(value)
    }

    const handleFixedInputBlur = () => {
        setCalendarStartDate(startDate)
        setCalendarEndDate(endDate)
    }

    // heavily optimized to prevent CalendarPicker from unnecessarily rendering
    const notFixed = useRef()
    notFixed.current = selectedType !== FIXED_TYPE
    const handleRangeChange = useCallback(
        ({ startDate, endDate }) => {
            if (notFixed.current) return
            if (startDate) {
                setStartDate(startDate.format(INPUT_DATE_FORMAT))
                setCalendarStartDate(startDate.format(INPUT_DATE_FORMAT))
            }
            if (endDate) {
                setEndDate(endDate.format(INPUT_DATE_FORMAT))
                setCalendarEndDate(endDate.format(INPUT_DATE_FORMAT))
            }
        },
        [filter]
    )

    const handleFocusChange = useCallback(focusedInput => {
        setFocused(focusedInput || 'startDate')
    }, [])

    const handleSubmit = () => {
        setOpen(false)

        switch (selectedType) {
            case ROLLING_TYPE: {
                const min =
                    rollingFrom === OPTION_CENTER
                        ? 0
                        : rollingFrom === OPTION_PREVIOUS
                        ? -parseInt(rollingMin)
                        : undefined
                const max =
                    rollingUntil === OPTION_CENTER
                        ? 0
                        : rollingUntil === OPTION_FUTURE
                        ? parseInt(rollingMax)
                        : undefined
                if (min === undefined && max === undefined) handleClear()
                else {
                    onDateOffsetChange(filter, { min, max }, rollingUseLastDate, startDate, endDate)
                    handleClear('onDateOffsetChange')
                }
                break
            }
            case FIXED_TYPE: {
                let m = moment(startDate).format(DATE_FORMAT)
                let M = moment(endDate).format(DATE_FORMAT)

                onRangeChange(filter, { min: m || M, max: M || m })
                handleClear('onRangeChange')
                break
            }
            case CUSTOM_TYPE: {
                onClickRangeChange(filter, clickRangeValue)
                handleClear('onClickRangeChange')
                break
            }
            default:
                break
        }
    }

    const handleClear = exception => {
        const { range, rangeOffset, clickRangeName } = selectedValues

        if (isWidget) setSelectedType(ROLLING_TYPE)

        if (!isEmpty(range) && exception !== 'onRangeChange') onRangeChange(filter, {})
        else if (rangeOffset && exception !== 'onDateOffsetChange') onDateOffsetChange(filter, '', false)
        else if (clickRangeName && exception !== 'onClickRangeChange') onClickRangeChange(filter, '')
    }

    let helpText = ''
    let content
    switch (selectedType) {
        case ROLLING_TYPE:
            helpText = 'Dynamic rolling range'
            content = (
                <>
                    <div className={classes.selectsContainer}>
                        <div className={classes.selectContainer}>
                            <TextField
                                select
                                fullWidth
                                label="From"
                                variant="outlined"
                                margin="dense"
                                value={rollingFrom}
                                onChange={e => setRollingFrom(e.target.value)}
                                className={classes.textFieldStyle}
                                InputLabelProps={{
                                    sx: {
                                        color: 'text.primary',
                                    },
                                }}
                            >
                                {Object.keys(fromOptions).map(o => (
                                    <MenuItem key={o} value={o}>
                                        {fromOptions[o]}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <>
                                {rollingFrom === OPTION_PREVIOUS && (
                                    <DaySelect value={rollingMin} onChange={setRollingMin} dataType={pastDateType} />
                                )}
                                {rollingFrom === OPTION_PREVIOUS && (
                                    <DateTypeSelect value={pastDateType} onChange={setPastDateType} />
                                )}
                            </>
                        </div>

                        {!isPhone && <ChevronRight />}

                        <div className={classes.selectContainer}>
                            <TextField
                                select
                                fullWidth
                                label="Until"
                                variant="outlined"
                                margin="dense"
                                value={rollingUntil}
                                onChange={e => setRollingUntil(e.target.value)}
                                className={classes.textFieldStyle}
                                InputLabelProps={{
                                    sx: {
                                        color: 'text.primary',
                                    },
                                }}
                            >
                                {Object.keys(untilOptions).map(o => (
                                    <MenuItem key={o} value={o}>
                                        {untilOptions[o]}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <>
                                {rollingUntil === OPTION_FUTURE && (
                                    <DaySelect value={rollingMax} onChange={setRollingMax} dataType={futureDateType} />
                                )}
                                {rollingUntil === OPTION_FUTURE && (
                                    <DateTypeSelect value={futureDateType} onChange={setFutureDateType} />
                                )}
                            </>
                        </div>
                    </div>

                    <FormControlLabel
                        className={classes.offsetLabel}
                        control={
                            <Checkbox
                                size="small"
                                className={classes.lastUpdated}
                                checked={rollingUseLastDate}
                                onChange={() => setRollingUseLastDate(s => !s)}
                                sx={{ color: 'text.primary' }}
                            />
                        }
                        label="Use last updated date"
                    />
                </>
            )
            break
        case FIXED_TYPE:
            helpText = 'Fixed start and stop end date range'
            content = (
                <div className={classes.selectsContainer}>
                    <TextField
                        fullWidth
                        inputRef={startDateRef}
                        label="From"
                        type="date"
                        variant="outlined"
                        margin="dense"
                        value={startDate}
                        onChange={handleFixedDateChange('startDate')}
                        onKeyPress={e => e.key === 'Enter' && handleFixedInputBlur()}
                        onBlur={handleFixedInputBlur}
                        onClick={() => setFocused('startDate')}
                        InputLabelProps={{ shrink: true, sx: { color: 'text.primary' } }}
                        className={`${classes.selectContainer} ${classes.textFieldStyle}`}
                        inputProps={{ className: classes.dateInput }}
                    />
                    {!isPhone && <ChevronRight />}
                    <TextField
                        fullWidth
                        inputRef={endDateRef}
                        label="Until"
                        type="date"
                        variant="outlined"
                        margin="dense"
                        value={endDate}
                        onChange={handleFixedDateChange('endDate')}
                        onKeyPress={e => e.key === 'Enter' && handleFixedInputBlur()}
                        onBlur={handleFixedInputBlur}
                        onClick={() => setFocused('endDate')}
                        InputLabelProps={{ shrink: true, sx: { color: 'text.primary' } }}
                        className={`${classes.selectContainer} ${classes.textFieldStyle}`}
                        inputProps={{ className: classes.dateInput }}
                    />
                </div>
            )
            break
        case CUSTOM_TYPE:
            helpText = 'Custom date ranges'
            content = (
                <Autocomplete
                    renderInput={params => <TextField {...params} />}
                    value={clickRangeValue}
                    options={ClickRangeNames}
                    onChange={(e, v) => setClickRangeValue(v)}
                />
            )
            break
        default:
            break
    }

    const selectedDays = useMemo(() => {
        const v = rawValues
            .map(value => moment(value[filter.KeyPropertyName]))
            .sort((a, b) => {
                if (a.isBefore(b)) return -1
                if (a.isAfter(b)) return 1
                else return 0
            })

        //some temporal filters have data down to the minute, but we only need individual dates for now
        //take only unique dates to help performance
        return uniqBy(v, x => x.format(DATE_FORMAT))
    }, [rawValues, filter.KeyPropertyName])
    return (
        <>
            <Tooltip title={buttonText}>
                <Chip
                    className={classes.button}
                    ref={buttonRef}
                    label={buttonText}
                    onClick={() => setOpen(true)}
                    onDelete={buttonText !== NO_FILTER_TEXT ? handleClear : undefined}
                />
            </Tooltip>

            <Popover
                keepMounted
                anchorEl={buttonRef.current}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                open={open}
                onClose={() => setOpen(false)}
                TransitionComponent={Fade}
            >
                {!isWidget && (
                    <div className={classes.header}>
                        <Tabs
                            indicatorColor="primary"
                            className={classes.tabs}
                            value={selectedType}
                            onChange={(e, index) => setSelectedType(index)}
                        >
                            {types.map(({ value, label }) => (
                                <Tab key={value} value={value} label={label} className={classes.tab} />
                            ))}
                        </Tabs>
                    </div>
                )}

                <div className={classes.tabContent}>
                    <Typography className={classes.helpText} variant="body1">
                        {helpText}
                    </Typography>

                    <div className={classes.contentContainer}>{content}</div>
                </div>

                <div className={classes.pickerWrapper}>
                    <CalendarPicker
                        startDate={calendarStartDate}
                        endDate={calendarEndDate}
                        selectedDays={selectedDays.length < 500 ? selectedDays : undefined}
                        isPhone={isPhone}
                        focused={focused}
                        onRangeChange={handleRangeChange}
                        onFocusChange={handleFocusChange}
                    />
                </div>

                <Box display="flex" alignItems="center" justifyContent="flex-end" m={1}>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Done
                    </Button>
                </Box>
            </Popover>
        </>
    )
}

const DaySelect = ({ value, onChange, dataType }) => (
    <>
        <Autocomplete
            size="small"
            clearOnEscape
            disableClearable
            options={
                dataType === 'days'
                    ? ['7', '14', '30']
                    : dataType === 'months'
                    ? ['3', '6', '9', '12']
                    : ['1', '5', '10']
            }
            renderInput={params => <TextField {...params} margin="dense" variant="outlined" />}
            value={value}
            onChange={(e, val) => onChange(val)}
            onBlur={e => onChange(e.target.value)}
            sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'text.primary',
                },
                '&:hover .MuiOutlinedInput-notchedOutline,': {
                    borderColor: 'text.primary',
                },
                '& .MuiFormLabel-root, .MuiAutocomplete-endAdornment svg': {
                    color: 'text.primary',
                },
            }}
        />
    </>
)

const DateTypeSelect = ({ value, onChange }) => (
    <Select
        value={value}
        onChange={event => onChange(event.target.value)}
        sx={theme => ({
            '& .MuiSelect-icon': { fill: theme.palette.text.primary },
            '&.MuiInput-root': {
                '&.MuiInput-underline': {
                    '&:before, &:after': {
                        borderBottomColor: theme.palette.text.primary,
                    },
                },
            },
        })}
    >
        <MenuItem value="days">Days</MenuItem>
        <MenuItem value="months">Months</MenuItem>
        <MenuItem value="years">Years</MenuItem>
    </Select>
)
const CalendarPicker = memo(
    withStyles(({ spacing, palette }) => ({
        calendarNavPrev: { position: 'absolute', left: spacing(), top: '18px', color: palette.grayscale.dark },
        calendarNavNext: { position: 'absolute', right: spacing(), top: '18px', color: palette.grayscale.dark },
    }))(({ classes, selectedDays, startDate, endDate, isPhone, onRangeChange, onFocusChange, focused }) => (
        <DayPickerRangeController
            hideKeyboardShortcutsPanel
            initialVisibleMonth={() =>
                endDate ? (isPhone ? moment(endDate) : moment(endDate).subtract(1, 'months')) : moment()
            }
            onDatesChange={onRangeChange}
            onFocusChange={onFocusChange}
            numberOfMonths={isPhone ? 1 : 2}
            focusedInput={focused}
            isDayHighlighted={day => !isEmpty(selectedDays) && !selectedDays.some(d => d.isSame(day, 'day'))}
            startDate={startDate ? moment(startDate) : moment().subtract(5, 'years')}
            endDate={endDate ? moment(endDate) : moment().add(5, 'years')}
            minimumNights={0}
            daySize={29}
            noBorder
            navPrev={
                <IconButton size="small" className={classes.calendarNavPrev}>
                    <ChevronLeft />
                </IconButton>
            }
            navNext={
                <IconButton size="small" className={classes.calendarNavNext}>
                    <ChevronRight />
                </IconButton>
            }
        />
    ))
)
