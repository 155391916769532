import ArrowBack from '@mui/icons-material/ArrowBack'
import { Box, Button } from '@mui/material'
import { useSelector } from 'react-redux'

import { useNavigate } from 'react-router-dom'
import { appearanceConstants } from '../constants'
import NavTitle from '../containers/NavTitle'
import { authSelectors } from '../selectors'

const { ScrollBarWidth } = appearanceConstants

export default function ComparePerspectiveToolbar({ perspectiveID }) {
    const navigate = useNavigate()
    const isPublic = useSelector(authSelectors.getIsPublicLogin)

    if (isPublic)
        return (
            <Box sx={{ mt: 1, paddingX: `${ScrollBarWidth}px` }}>
                <PerspectiveTitle perspectiveID={perspectiveID} />
            </Box>
        )

    return (
        <Box
            sx={{
                paddingX: `${ScrollBarWidth}px`,
                pt: 1,
                overflow: 'hidden',
                display: 'flex',
                gap: 1,
            }}
        >
            <NavTitle />
            <Button variant="outlined" onClick={() => navigate(-1)} size="small" startIcon={<ArrowBack />}>
                Go back to perspective
            </Button>
        </Box>
    )
}
