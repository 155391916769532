import React, { useState, useEffect, useContext, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Close } from '@mui/icons-material'
import makeStyles from '@mui/styles/makeStyles'
import { Box, Button, IconButton, Typography } from '@mui/material'

import { functionsFromString } from 'genesis-suite/utils'
import { useExpressionFunctionSuggestions } from 'genesis-suite/hooks'
import { ResourceType } from 'genesis-suite/types/networkTypes'
import {
    Spinner,
    ErrorButton,
    SwalContext,
    EditableLabel,
    DataTypePicker,
    ExpressionEditor,
} from 'genesis-suite/components'
import { ConfigContext } from './ConfigContext'
import { architectureService, definitionService } from '../../lib/services'
import { applicationSelectors } from '../../selectors'
import useSemanticTypes from '../../hooks/useSemanticTypes'

const columnWidth = 236

const useStyles = makeStyles(({ palette, spacing }) => ({
    editorPosition: {
        position: 'absolute',
        transform: (p: any) => `translateX(${p.open ? '' : '-'}${columnWidth}px)`,
        transition: 'all 200ms',
        width: 2 * columnWidth,
        height: '100%',
        zIndex: 1,
    },
    editorContent: {
        height: '100%',
        backgroundColor: palette.grayscale.lightest,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        zIndex: 2,
    },
    header: { padding: spacing(1, 1, 0) },
    headerWrap1: { display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' },
    headerWrap2: { overflow: 'hidden' },
    headerText: { overflow: 'hidden' },
    typeIcon: { width: '16px', marginRight: spacing() },
    nameInput: { display: 'block', width: '400px' },
    resourceTitles: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: spacing(),
        padding: spacing(0, 1),
        borderBottom: `1px solid ${palette.grey['500']}`,
    },
    body: { flex: 1, padding: spacing(1, 1, 0), display: 'flex', flexDirection: 'column', overflow: 'hidden' },
    errorText: { paddingLeft: spacing() },
    footer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: spacing(),
        borderTop: `1px solid ${palette.grey['500']}`,
    },
    deleteButton: { color: palette.status.error },
}))

export default function CalculatedPropertyEditor() {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const semanticTypes = useSemanticTypes()

    const { dispatch, calculatedPropertyEditor, refreshProperties, resources } = useContext(ConfigContext)
    const resource = resources?.byId[resources.selectedId]
    const properties = resource?.properties
    const { open, editId, selectedId } = calculatedPropertyEditor
    const { confirm } = useContext(SwalContext)

    const { expressionFunctions, suggestions } = useExpressionFunctionSuggestions(() =>
        definitionService.getExpressionFunctions()
    )

    const [madeChange, setMadeChange] = useState(false)
    const [draft, setDraft] = useState(initialState)
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    const expressionRef = useRef<any>(null)

    const classes = useStyles({ open })

    // init
    useEffect(() => {
        if (!open) return

        setMadeChange(false)
        const p = properties.find(p => p.id === editId)
        expressionRef.current.focus()
        if (!p) return setDraft(initialState)

        const { id, name, computeDefinition, semanticType } = p
        if (computeDefinition.type !== 'expression') {
            console.error('Calculated properties can only be expression-based')
            return
        }

        const expression = computeDefinition.value
        setDraft({ id, name, expression, dataTypeId: semanticType.id })
    }, [open, editId, properties])

    // watch for selected properties
    useEffect(() => {
        if (!selectedId) return

        const property = properties.find(p => p.id === selectedId)
        if (!property) return

        const addPosition = expressionRef.current?.selectionStart
        const propertyString = `[${property.name}]`
        setDraft(s => ({
            ...s,
            expression:
                addPosition === null
                    ? `${s.expression}${propertyString}`
                    : `${s.expression.slice(0, addPosition)}${propertyString}${s.expression.slice(addPosition)}`,
        }))
        setMadeChange(true)
        if (expressionRef.current) expressionRef.current.focus()

        dispatch({ type: 'UPDATE_CALCULATED_PROPERTY_PANEL', payload: { selectedId: undefined } })
    }, [selectedId])

    const expressionTimeout = useRef<any>()
    useEffect(() => {
        clearTimeout(expressionTimeout.current)
        expressionTimeout.current = setTimeout(() => validateExpression(draft.expression), 500)
    }, [draft.expression, expressionFunctions])

    function validateExpression(expression) {
        const functionNames = functionsFromString(expression).map(f => f)
        const allFunctionNames = expressionFunctions.map(f => f.name.toLocaleLowerCase())
        const badFunctionName = functionNames.find(n => !allFunctionNames.includes(n.toLocaleLowerCase()))
        if (badFunctionName) return setError(`Function ${badFunctionName} does not exist`)

        const matchedPropertyNames = getMatchingProperties(expression)

        for (const propertyName of matchedPropertyNames) {
            if (!properties.some(p => p.name === propertyName)) return setError(`${propertyName} is not a property`)
        }

        if (error) setError('')
    }

    function handleExpressionChange(e, newValue, method) {
        setMadeChange(true)
        if (method !== 'up' && method !== 'down') {
            setDraft(s => ({ ...s, expression: newValue }))
        }
    }

    function handleChangeName(value) {
        if (value === draft.name) return
        setMadeChange(true)
        setDraft(s => ({ ...s, name: value }))
    }

    function handleDataTypeChange(dataTypeId) {
        setMadeChange(true)
        setDraft(s => ({ ...s, dataTypeId }))
    }

    async function handleCancel() {
        return dispatch({
            type: 'UPDATE_CALCULATED_PROPERTY_PANEL',
            payload: { open: false, editId: undefined, selectedId: undefined },
        })
    }

    async function handleSave() {
        setLoading(true)
        try {
            const p = await architectureService.setFunction(appName, ResourceType.INSIGHT, resource.name, {
                ...draft,
                IsOptional: true,
            })

            refreshProperties(resource.id)
            handleDone()
        } catch (err) {
            console.error(err)
        }
        setLoading(false)
    }

    async function handleDelete() {
        const name = properties.find(p => p.id === editId)?.name
        const haveDependentProperties = properties
            .filter(p => p.computed && p.isOptional && p.name !== name)
            .some(p => getMatchingProperties(p.computeDefinition.value as string).includes(name))

        const response = await confirm(
            haveDependentProperties ? `There are other calculated properties that depend on ${name}` : ``,
            { type: 'question' }
        )
        if (response.dismiss) return

        setLoading(true)
        try {
            await architectureService.deleteFunction(appName, draft.id)
            dispatch({ type: 'REMOVE_PROPERTY', payload: draft.id })
            handleDone()
        } catch (err) {
            console.error(err)
        }
        setLoading(false)
    }

    function handleDone() {
        dispatch({ type: 'UPDATE_CALCULATED_PROPERTY_PANEL', payload: { open: false, editId: undefined } })
    }

    const helpErrors = []
    if (madeChange && !draft.name) helpErrors.push('Name your function')
    if (madeChange && !draft.expression) helpErrors.push('Your expression is empty')
    if (madeChange && Boolean(error)) helpErrors.push('Check your expression for errors')
    if (madeChange && !draft.dataTypeId) helpErrors.push('Select a data type')

    return (
        <div className={classes.editorPosition}>
            <Spinner show={loading}>
                <div className={classes.editorContent}>
                    <div className={classes.header}>
                        <div className={classes.headerWrap1}>
                            <div className={classes.headerWrap2}>
                                <Typography variant="caption">Calculated Property Editor</Typography>

                                <EditableLabel
                                    value={draft.name}
                                    keyValidation={key => Boolean(key.match(/^[a-z0-9 _-]+$/i))}
                                    keyValidationMessage="Only alpha-numeric characters allowed"
                                    onChange={handleChangeName}
                                    changeValidation={name =>
                                        !properties
                                            .filter(p => p.id !== draft.id)
                                            .some(p => p.name === name || p.displayName === name)
                                    }
                                    changeValidationMessage="Already used! Cannot have two properties with the same name"
                                    inputProps={{ placeholder: 'Function Name', className: classes.nameInput }}
                                    textProps={{ variant: 'h6' }}
                                />
                            </div>
                            <IconButton onClick={handleCancel} size="large">
                                <Close />
                            </IconButton>
                        </div>
                    </div>

                    <div className={classes.body}>
                        <ExpressionEditor
                            expression={draft.expression || ''}
                            onChange={handleExpressionChange}
                            onMount={ref => (expressionRef.current = ref)}
                            suggestions={suggestions}
                            textFieldProps={{ rows: 12 }}
                        />

                        <Typography display="block" className={classes.errorText} variant="caption" color="error">
                            {error}&nbsp;
                        </Typography>

                        <DataTypePicker
                            semanticTypes={semanticTypes}
                            selectedId={draft.dataTypeId}
                            onChange={handleDataTypeChange}
                        />

                        <Help />

                        <div className={classes.footer}>
                            {editId && (
                                <Button onClick={handleDelete} className={classes.deleteButton}>
                                    Delete
                                </Button>
                            )}
                            {madeChange && <Button onClick={handleCancel}>Cancel</Button>}
                            <ErrorButton errors={helpErrors} onClick={madeChange ? handleSave : handleDone}>
                                {madeChange ? 'Save' : 'Done'}
                            </ErrorButton>
                        </div>
                    </div>
                </div>
            </Spinner>
        </div>
    );
}

function Help() {
    return (
        <Box flex="1" display="flex" flexDirection="column" overflow="hidden" my={1}>
            <Typography variant="h6">Help</Typography>

            <Box display="flex" flexDirection="column" overflow="auto">
                <Typography gutterBottom>
                    Define a calculated property by an expression. Right click in the expression box to see a list of
                    available functions.
                </Typography>
                <Typography gutterBottom>
                    Expressions can reference other properties. To use another property select it on the left.
                </Typography>
                <Typography gutterBottom>
                    The data type is used to determine how the property will be understood within the network.
                </Typography>
            </Box>
        </Box>
    )
}

const initialState = {
    id: '',
    name: '',
    expression: '',
    dataTypeId: null,
}

const getMatchingProperties = (expression: string): string[] =>
    (expression.match(/\[.*?\]/g) || []).map(item => item.substr(1, item.length - 2))
