import { Box, Button, FormControlLabel, Radio, Switch, Typography, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useSnackbar } from 'notistack'
import { moduleService } from '~/lib/services'
import { appearanceCreators, moduleCreators } from '../../../actions/creators'
import { appearanceConstants } from '../../../constants'
import { appearanceSelectors, applicationSelectors, authSelectors, moduleSelectors } from '../../../selectors'
import ClassicLayoutTile from './ClassicLayoutTile'
import ModernLayoutTile from './ModernLayoutTile'
import NeoClassicLayoutTile from './NeoClassicLayoutTile'

const useStyles = makeStyles(({ spacing }) => ({
    root: { width: '100%', padding: spacing(), height: '100%' },
}))

const useTileOptionStyles = makeStyles(({ palette }) => ({
    container: { cursor: 'pointer', '&:hover': { backgroundColor: palette.action.hover } },
    header: { cursor: 'pointer', display: 'flex', alignItems: 'center' },
    radio: {
        color: palette.text.primary,
        padding: '5px',
        '& svg': { height: '15px', width: '15px' },
    },
    imageContainer: { margin: '7px 0 0 22px' },
}))

const { CLASSIC, MODERN, NEO } = appearanceConstants.Layout
const { ICONS_ONLY, ICONS_AND_TEXT } = appearanceConstants.TopNav

const TopBarSettings = ({ topNav, onClick }) => {
    const classes = useTileOptionStyles()
    const dispatch = useDispatch()
    const showNeoClassic = useSelector(appearanceSelectors.getShowNeoClassicLayOut)
    const isBetaTurnedOn = useSelector(appearanceSelectors.getIsBetaTurnedOn)
    const isGlobalFiltersOpen = useSelector(appearanceSelectors.isNeoClassicGlobalFiltersOpen)
    const onBetaToggleChange = () => {
        if (isBetaTurnedOn && isGlobalFiltersOpen) {
            dispatch(appearanceCreators.toggleNeoClassicGlobalFilters())
        }
        dispatch(appearanceCreators.toggleBeta(!isBetaTurnedOn))
    }
    return (
        <div>
            <Typography variant="h6">Top bar</Typography>
            <Box alignItems="center">
                <Radio
                    className={classes.radio}
                    value={ICONS_AND_TEXT}
                    checked={topNav === ICONS_AND_TEXT}
                    color="primary"
                    size="small"
                    onClick={() => onClick(ICONS_AND_TEXT)}
                />
                Icons and text
                <Radio
                    className={classes.radio}
                    value={ICONS_ONLY}
                    checked={topNav === ICONS_ONLY}
                    color="primary"
                    size="small"
                    onClick={() => onClick(ICONS_ONLY)}
                />
                Icons only
            </Box>
            <Box mt={2} />
            {showNeoClassic && (
                <FormControlLabel
                    control={
                        <Switch
                            color="primary"
                            checked={isBetaTurnedOn}
                            onChange={onBetaToggleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    sx={{
                        margin: 0,
                    }}
                    label={
                        <Typography display="inline" variant="subtitle1" fontWeight={'bold'}>
                            Try our new changes in Neo layout
                        </Typography>
                    }
                    labelPlacement="start"
                />
            )}
        </div>
    )
}

export default function ManageAppearance({ updateSaveHandle, updateCancelHandle }) {
    const classes = useStyles()

    const layout = useSelector(appearanceSelectors.getApplicationLayout)
    const topNav = useSelector(appearanceSelectors.getTopNav)
    const showNeoClassic = useSelector(appearanceSelectors.getShowNeoClassicLayOut)
    const dispatch = useDispatch()
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const visorId = useSelector(moduleSelectors.getModuleId)
    const permission = useSelector(authSelectors.getAppPermission)
    const isAdmin = permission === 'Admin' || permission === 'Owner'
    const [dirty, setDirty] = useState(false)
    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const appOptions = JSON.parse(useSelector(moduleSelectors.getAppOptions) || null)
    useEffect(() => {
        updateSaveHandle()
        updateCancelHandle()
    }, [])

    async function handleLayoutChange(layout) {
        let showNeoClassic = false
        if (layout === NEO) {
            showNeoClassic = true
            dispatch(appearanceCreators.setShowNeoClassicLayout(true))
            layout = CLASSIC
        } else {
            dispatch(appearanceCreators.setShowNeoClassicLayout(false))
        }
        dispatch(appearanceCreators.setLayout(layout))
        setDirty(true)
    }

    const saveOptions = async () => {
        const options = {
            visorId,
            appName,
            appOptions: JSON.stringify({ ...appOptions, layout, showNeoClassic }),
        }

        try {
            await moduleService.updateAppOptions(options)
        } catch (ex) {
            let message = 'Layout preferences cannot be saved'
            if (ex?.Message) message = ex.Message
            showSnackbar(message, { variant: 'error' })
            return
        }

        showSnackbar('Saved layout preferences', { variant: 'success' })
        dispatch(moduleCreators.updateModuleOptions(options))
    }

    async function handleTopNavChange(topNav) {
        dispatch(appearanceCreators.setTopNav(topNav))
    }

    return (
        <div className={classes.root}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h6">Layout</Typography>
                {isAdmin && (
                    <Button variant="contained" disabled={!dirty} onClick={saveOptions}>
                        Save
                    </Button>
                )}
            </Box>
            <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" alignItems="center">
                <TileOption
                    name="Modern"
                    Tile={ModernLayoutTile}
                    isSelected={layout === MODERN}
                    onClick={() => handleLayoutChange(MODERN)}
                />
                <TileOption
                    name="Classic"
                    Tile={ClassicLayoutTile}
                    isSelected={layout === CLASSIC && !showNeoClassic}
                    onClick={() => handleLayoutChange(CLASSIC)}
                />
                <TileOption
                    name="Neo"
                    Tile={NeoClassicLayoutTile}
                    isSelected={showNeoClassic === true}
                    onClick={() => handleLayoutChange(NEO)}
                />
            </Box>
            <Box mt={2} />

            {layout === CLASSIC ? (
                <TopBarSettings classes={classes} topNav={topNav} onClick={handleTopNavChange} />
            ) : null}
        </div>
    )
}

function TileOption({ name, Tile, isSelected, onClick }) {
    const classes = useTileOptionStyles()

    const { palette } = useTheme()
    const {
        background: { sideNav, content, topBar, widget },
        primary: { main },
    } = palette

    const colors = { primary: main, sideNav, content, topBar, widget }

    return (
        <div onClick={onClick} className={classes.container}>
            <div className={classes.header}>
                <Radio className={classes.radio} checked={isSelected} color="primary" size="small" />
                <Typography variant="body1">{name}</Typography>
            </div>

            <div className={classes.imageContainer}>
                <Tile colors={colors} />
            </div>
        </div>
    )
}
