import React from 'react'
import { Box, Checkbox, FormControlLabel, TextField } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { Base, NumberFormat } from 'genesis-suite/types/visualTypes'
import LabeledToggleButton from '../../LabeledToggleButton'

const useStyles = makeStyles(() => ({
    fieldWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        '&>div': { width: '45%' },
    },
}))

const baseOptions = [
    { value: Base.K, label: 'K', tip: 'Thousands' },
    { value: Base.M, label: 'M', tip: 'Millions' },
    { value: Base.B, label: 'B', tip: 'Billions' },
    { value: Base.DYNAMIC, label: 'D', tip: 'Dynamic' },
]

interface Props {
    format: NumberFormat
    disabled?: boolean
    onChange: (format: NumberFormat) => void
}

export default function DisplayFormatFields({ format = {}, disabled, onChange }: Props) {
    const classes = useStyles()
    const handleFormatChange = change => onChange({ ...format, ...change })

    const { decimalPlaces, isPercentage, removeCommas, base, prefix, suffix } = format

    return (
        <Box display="flex" flexDirection="column">
            <TextField
                disabled={disabled || false}
                type="number"
                value={decimalPlaces === undefined ? '' : decimalPlaces}
                label="Decimal places"
                inputProps={{ min: '0' }}
                onChange={e => handleFormatChange({ decimalPlaces: e.target.value })}
            />
            <FormControlLabel
                disabled={disabled || false}
                control={
                    <Checkbox
                        color="primary"
                        checked={isPercentage || false}
                        onChange={() => handleFormatChange({ isPercentage: !isPercentage })}
                    />
                }
                label="Percent"
            />
            <FormControlLabel
                disabled={disabled || false}
                control={
                    <Checkbox
                        color="primary"
                        checked={removeCommas || false}
                        onChange={() => handleFormatChange({ removeCommas: !removeCommas })}
                    />
                }
                label="Hide commas"
            />
            <LabeledToggleButton
                label="Base:"
                disabled={disabled}
                value={base}
                onChange={(e, base) => handleFormatChange({ base })}
                options={baseOptions}
            />
            <div className={classes.fieldWrapper}>
                <TextField
                    fullWidth
                    label="Prefix"
                    disabled={disabled || false}
                    value={prefix || ''}
                    onChange={e => handleFormatChange({ prefix: e.target.value })}
                />
                <TextField
                    fullWidth
                    label="Suffix"
                    disabled={disabled || false}
                    value={suffix || ''}
                    onChange={e => handleFormatChange({ suffix: e.target.value })}
                />
            </div>
        </Box>
    )
}
