import { Scenario } from 'genesis-suite/types/networkTypes'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import useSWR, { KeyedMutator } from 'swr'
import { scenarioService } from '../../lib/services'
import { applicationSelectors } from '../../selectors'

function useScenarios(): [Scenario[], KeyedMutator<Scenario[]>] {
    const appName: string = useSelector(applicationSelectors.getCurrentAppName)

    const { data, error, mutate } = useSWR(
        ['scenarioService.getScenarios', appName],
        ([_, appName]) => scenarioService.getScenarios(appName),
        { revalidateOnFocus: false, shouldRetryOnError: false }
    )

    const { enqueueSnackbar: showSnackbar } = useSnackbar()

    useEffect(() => {
        if (error) {
            console.error(error)
            showSnackbar('An error occurred fetching Scenarios', { variant: 'error', preventDuplicate: true })
        }
    }, [error])

    return [data, mutate]
}

export default useScenarios
