import { Box, Typography } from '@mui/material'

import { Dashboard as DashboardType, DraftAction, VisualType } from 'genesis-suite/types/visualTypes'
import CustomDashboard from '../widgets2/dashboard/CustomDashboard'
import DisplayDraftDiff from '../widgets2/DisplayDraftDiff'

interface ApprovalDashboardProps {
    config: DashboardType
}

export default function ApprovalDashboard({ config }: ApprovalDashboardProps) {
    return (
        <Box flex={1} display="grid" gridTemplateColumns="350px auto" gap={1} overflow="hidden">
            <Box display="flex" flexDirection="column" overflow="hidden" borderRight={1} borderColor="divider">
                <Typography variant="h5" gutterBottom sx={{ ml: 1 }}>
                    {config.draft.action === DraftAction.ADDED ? 'New Collection' : 'Updated Collection'}
                </Typography>
                <DisplayDraftDiff config={config} type={VisualType.DASHBOARD} />
            </Box>
            <CustomDashboard actions={['device-toggle', 'filters']} config={config} editable={false} />
        </Box>
    )
}
