import { useContext } from 'react'
import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { ChartType } from 'genesis-suite/types/visualTypes'
import { ConfigContext } from '../../ConfigContext'
import EditorWrapper from '../EditorWrapper'
import SeriesDataLabelsEditor from './SeriesDataLabelsEditor'
import SeriesTooltipEditor from './SeriesTooltipEditor'
import ColorGradientEditor from '../ColorGradientEditor'
import SortEditor from '../SortEditor'
import AggregationSelect from '../../AggregationSelect'

const useStyles = makeStyles(({ spacing }) => ({
    group: { marginBottom: spacing() },
}))

export default function SeriesEditorHeatmap() {
    const { config, dispatch, selectedField } = useContext(ConfigContext)
    const classes = useStyles()
    if (config.type !== ChartType.HEATMAP) return null

    const activeSeries = config.series[selectedField.index]

    return (
        <>
            <AggregationSelect />

            <div className={classes.group}>
                <Typography variant="caption">Sorting</Typography>
                <SortEditor pointer={selectedField} />
            </div>

            <EditorWrapper header="Data labels">
                <SeriesDataLabelsEditor />
            </EditorWrapper>

            <EditorWrapper header="Color">
                <ColorGradientEditor
                    colorGradient={activeSeries.colorGradient}
                    onChange={colorGradient => dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: { colorGradient } })}
                />
            </EditorWrapper>

            <EditorWrapper header="Tooltip">
                <SeriesTooltipEditor />
            </EditorWrapper>
        </>
    )
}
