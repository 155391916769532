import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, Box, IconButton } from '@mui/material'
import { SyncFusionRTE } from 'genesis-suite/components'
import makeStyles from '@mui/styles/makeStyles'
import React, { useState, useContext } from 'react'
import { Close } from 'genesis-suite/icons'
import { useDispatch } from 'react-redux'
import { widgetCreators } from '~/actions/creators'
import { useSnackbar } from 'notistack'
import { PerspectiveWidgetContext } from '~/components/contexts/PerspectiveWidgetContext'
import { widgetTypes } from '~/actions/types'
import { Spinner } from 'genesis-suite/components'

const useStyles = makeStyles(({ palette }) => ({
    backdrop: {
        cursor: 'not-allowed',
    },
    icon: { color: palette.text.primary },
    spinner: {
        zIndex: 10,
    },
}))
interface EditTextWidgetModalProps {
    config: any
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const EditTextWidgetModal = ({ config, open, setOpen }: EditTextWidgetModalProps) => {
    const classes = useStyles({})
    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const dispatch = useDispatch()
    const content = config?.RichTextConfig?.Content
    const [inputVal, setInputVal] = useState(content ?? '')
    const { updateConfigAndRefresh } = useContext(PerspectiveWidgetContext)
    const [loading, setLoading] = useState(false)

    const handleClose = () => {
        setOpen(false)
    }
    const handleDialogClose = (event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            handleClose()
        }
    }

    const handleSave = async () => {
        try {
            if (inputVal === content) return
            setLoading(true)
            const updatedConfig = { ...config, RichTextConfig: { ...config.RichTextConfig, Content: inputVal } }
            dispatch({ type: widgetTypes.START_SAVING_WIDGETS })
            await dispatch(widgetCreators.saveWidget(updatedConfig))
            dispatch({ type: widgetTypes.STOP_SAVING_WIDGETS })
            updateConfigAndRefresh?.(updatedConfig)
            handleClose()
        } catch (error) {
            showSnackbar('Failed to save', { variant: 'error' })
        } finally {
            setLoading(false)
        }
    }
    return (
        <React.Fragment>
            {open && (
                <Dialog
                    open={open}
                    className={classes.backdrop}
                    onClose={handleDialogClose}
                    fullWidth={true}
                    maxWidth={'lg'}
                    hideBackdrop
                    PaperProps={{
                        sx: { cursor: 'default', backgroundImage: 'none' },
                    }}
                >
                    <DialogTitle>
                        {' '}
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Box display="flex" alignItems="center">
                                <Typography variant="h5">Edit Text Editor</Typography>
                            </Box>
                            <IconButton onClick={handleClose} size="large">
                                <Close className={classes.icon} />
                            </IconButton>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        {' '}
                        <Spinner hideCover show={loading} className={classes.spinner}>
                            <Box
                                sx={{
                                    filter: loading ? 'blur(5px)' : 'none',
                                    pointerEvents: loading ? 'none' : 'auto',
                                }}
                            >
                                <SyncFusionRTE
                                    minHeight={350}
                                    value={inputVal ?? ''}
                                    onChange={value => setInputVal(value)}
                                />
                            </Box>
                        </Spinner>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} disabled={loading} color="primary" variant="outlined">
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleSave}
                            color="primary"
                            disabled={inputVal === content || loading}
                        >
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </React.Fragment>
    )
}
export default EditTextWidgetModal
