import { Box } from '@mui/material'
import { VirtualTable } from 'genesis-suite/components'
import { VirtualTableColumn } from 'genesis-suite/components/tables/VirtualTable/types'
import { ResourceType } from 'genesis-suite/types/networkTypes'
import { Aggregation, CategoryTableColumn, TableConfig, TableSeries } from 'genesis-suite/types/visualTypes'
import { forwardRef } from 'react'
import useProperties from '../../../hooks/useProperties'
import { CanClick, WidgetProps } from '../../../types/WidgetTypes'
import isRawSeries from '../utils/isRawSeries'
import TableWidgetCell2 from './TableWidgetCell2'
import makeTableRowsAndColumns from './makeTableRowsAndColumns'

export default forwardRef<HTMLDivElement, WidgetProps<TableConfig>>((props, ref) => {
    const { config, data, groupNames, canClick, setSelectedPoint } = props
    const configSeries = config.series[0]
    const properties = useProperties()

    if (!data) return null

    const { rows, columns } = makeTableRowsAndColumns(config, data, groupNames, properties)

    const tableColumns: VirtualTableColumn[] = columns.map((col, i): VirtualTableColumn => {
        const { field, title } = col
        return {
            field: field.name,
            headerName: title,
            width: 200,
            sortable: true,
            resizable: true,
            cellRenderer: params => {
                const { column, row, rowIndex } = params
                return (
                    <TableWidgetCell2
                        rowData={row as Array<string | number>}
                        columnIndex={i}
                        config={config}
                        column={column}
                        columns={columns}
                        rowIndex={rowIndex}
                        cellData={row[column.field]}
                        setSelectedPoint={setSelectedPoint}
                        canNavigate={canNavigateColumn(
                            i,
                            configSeries,
                            config.categories,
                            canClick,
                            isRawSeries(configSeries)
                        )}
                    />
                )
            },
        }
    })

    const tableData = [...rows].map((d, i) => ({ ...d, id: i }))

    return (
        <Box sx={{ height: '100%' }}>
            <VirtualTable ref={ref} rows={tableData} columns={tableColumns} />
        </Box>
    )
})

export function canNavigateColumn(
    columnIndex: number,
    configSeries: TableSeries,
    categories: CategoryTableColumn[],
    canClick: CanClick,
    isRawSeries: boolean
) {
    let column: CategoryTableColumn

    if (!canClick) return false
    if (isRawSeries) {
        const rawColumn = configSeries.values[columnIndex]
        const { field, aggregation } = rawColumn
        if (aggregation && aggregation !== Aggregation.NONE) return false
        if (field.resourceType !== ResourceType.NODE) return false

        column = rawColumn
    } else {
        const lastCategoryIndex = categories.length - 1
        const isPivot = Boolean(configSeries?.subSeries)
        if (columnIndex > lastCategoryIndex && !isPivot) return false
        column = categories[Math.min(columnIndex, lastCategoryIndex)]
    }

    const { field, navigation } = column
    if (navigation && navigation?.enabled === false) return false
    if (navigation && navigation?.perspectiveId) return true

    return canClick(field.resourceName)
}
