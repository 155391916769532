import { Box } from '@mui/material'
import {
    DataGridProProps,
    GRID_CHECKBOX_SELECTION_COL_DEF,
    GridColDef,
    GridColumnResizeParams,
    GridRowId,
    GridRowModel,
} from '@mui/x-data-grid-pro'
import { SwalContext } from 'genesis-suite/components'
import { FormData, FormDataWithId, TableFormConfig } from 'genesis-suite/types/visualTypes'
import { useSnackbar } from 'notistack'
import { useContext, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useIsMobile } from '../../../hooks/useIsMobile'
import { LoadingCell, buildXGridColumns, validateUpdatedRow } from '../../../lib/formUtils'
import { applicationSelectors } from '../../../selectors'
import { ColOptionsContext } from '../../contexts/ColumnOptionsContext'
import { EditTable } from '../edit_table/EditTable'
import SlideoutForm from './SlideoutForm'
import { TableComponents } from './TableComponents'
import TableFormHeader from './TableFormHeader'

interface TableFormProps extends Partial<DataGridProProps> {
    data: FormDataWithId[]
    config: TableFormConfig
    defaults: FormData
    onExport: () => Promise<any>
    onDelete: (data: FormData[]) => Promise<any>
    onExcelUpload: (rows: any[], tableName: string, mappedFields: string[]) => Promise<any>
    onSave: (data: FormData[], shouldRefresh?: boolean) => Promise<any>
    className?: string
    extraColumns?: GridColDef[]
    columnProps?: Partial<GridColDef>
    onReorder?: (ids: GridRowId[]) => void
}

export default function TableForm({
    data,
    config,
    defaults,
    onExport,
    onSave,
    onDelete,
    onExcelUpload,
    className,
    extraColumns = [],
    columnProps,
    ...rest
}: TableFormProps) {
    const { columns } = config
    const [flagged, setFlagged] = useState<any>({})
    const [inputData, setInputData] = useState(null)
    const [selectedIds, setSelectedIds] = useState<GridRowId[]>([])
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const [loadingCells, setLoadingCells] = useState<Array<LoadingCell>>([])
    const { options, setOptions } = useContext(ColOptionsContext)

    const isMobile = useIsMobile()

    const tableColumns = useMemo((): GridColDef[] => {
        return buildXGridColumns(columns, flagged, columnProps, options, loadingCells).concat(extraColumns)
    }, [columns, flagged, columnProps, options, loadingCells, extraColumns])

    const { confirm } = useContext(SwalContext)

    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const errSnack = (message: string) => showSnackbar(message, { variant: 'error' })

    const setColumnOrder = (columns: GridColDef[]) => {
        const newOptions = columns.map(({ field, width }) => ({ colId: field, width }))
        setOptions(newOptions)
    }
    const handleWidthChange = (column: GridColDef, width: number) => {
        const { field } = column
        if (options.find(o => o.colId === field)) {
            setOptions(options.map(o => (o.colId === field ? { ...o, width } : o)))
        } else {
            setOptions([...options, { colId: field, width }])
        }
    }

    const addLoadingCell = (cell: LoadingCell) => setLoadingCells(prev => [...prev, cell])
    const removeLoadingCell = (cell: LoadingCell) => {
        setLoadingCells(prev => prev.filter(c => !(c.rowId === cell.rowId && c.fieldName === cell.fieldName)))
    }

    const handleAdd = () => setInputData([{ isNew: true }])
    const openForm = () => setInputData(selectedIds.map(id => data.find(d => d.id === id)))
    const closeForm = () => setInputData(null)

    const handleFormSubmit = async (rows: FormData[]) => onSave(rows, true)

    const handleCellSave = async (rows: GridRowModel[], loadingCell: LoadingCell) => {
        try {
            addLoadingCell(loadingCell)
            await onSave(rows)
        } catch (err) {
            console.error(err)
            errSnack('An error occurred saving the form')
        } finally {
            removeLoadingCell(loadingCell)
        }
    }

    const handleCellEdit = async (newRow: GridRowModel, updatedField: string) => {
        const rowId: string = newRow.id
        const loadingCell: LoadingCell = { rowId, fieldName: updatedField, originalFieldName: updatedField }
        const flaggedColumnNames = await validateUpdatedRow(appName, columns, newRow, updatedField)
        if (!flaggedColumnNames || !flaggedColumnNames.length) {
            if (flagged[rowId]) {
                if (flagged[rowId].includes(updatedField)) {
                    const newValues = flagged[rowId].filter(n => n !== updatedField)
                    if (newValues.length === 0) {
                        const { [rowId]: vals, ...rest } = flagged
                        setFlagged(rest)
                        return handleCellSave([newRow], loadingCell)
                    } else setFlagged({ ...flagged, [rowId]: newValues })
                }
            } else return handleCellSave([newRow], loadingCell)
        } else {
            // if the new flaggedColumnNames has no new members dont show the snackbar
            const oldColumnNames = flagged[rowId]
            if (!oldColumnNames || !flaggedColumnNames.every(name => oldColumnNames.includes(name)))
                errSnack(
                    `Save failed. Your last edit caused the following column(s) to become invalid: \n${flaggedColumnNames.join(
                        ', '
                    )} \nPlease update and try again.`
                )
            setFlagged({ ...flagged, [rowId]: flaggedColumnNames })
        }
    }

    const handleDelete = async () => {
        const rows = selectedIds.map(id => data.find(d => d.id === id))
        const response = await confirm(`Delete ${rows.length} selected row(s)?`, { type: 'warning' })
        if (response.dismiss) return
        try {
            await onDelete(rows)
        } catch {
            errSnack('An error occurred deleting selected rows')
        }
    }

    const initTableState = {
        pinnedColumns: {
            left: isMobile ? [] : [GRID_CHECKBOX_SELECTION_COL_DEF.field],
        },
    }

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                position: 'relative',
                flexDirection: 'column',
                '& .tadaCell.MuiDataGrid-cell': { p: 0 },
                '& .flaggedCell': { border: 2, borderColor: 'status.error' },
                '& .MuiDataGrid-columnHeaders, & .MuiDataGrid-row .MuiDataGrid-cell': {
                    borderColor: 'border.main',
                    '& .MuiDataGrid-columnSeparator': { color: 'border.main' },
                    '& .MuiDataGrid-iconButtonContainer, & .MuiDataGrid-menuIcon': {
                        '& .MuiButtonBase-root': { color: 'text.primary' },
                    },
                },
            }}
        >
            <TableFormHeader
                config={config}
                onAdd={handleAdd}
                rows={data.length}
                onInputEdit={openForm}
                onDelete={handleDelete}
                onUpload={onExcelUpload}
                onExcelExport={onExport}
                selectedIds={selectedIds}
                totalRows={rest.rowCount}
            />
            <EditTable
                rows={data}
                columns={tableColumns}
                onReorder={setColumnOrder}
                selectionModel={selectedIds}
                components={TableComponents}
                initialState={initTableState}
                onEditComplete={handleCellEdit}
                onSelectionModelChange={setSelectedIds}
                checkboxSelection={isMobile ? false : true}
                disableSelectionOnClick={isMobile ? false : true}
                onColumnWidthChange={({ colDef, width }: GridColumnResizeParams) => handleWidthChange(colDef, width)}
                {...rest}
            />
            <SlideoutForm
                config={config}
                data={inputData}
                onClose={closeForm}
                defaults={defaults}
                onSubmit={handleFormSubmit}
            />
        </Box>
    )
}
