import { Typography } from '@mui/material'
import { BusinessElement } from 'genesis-suite/icons'
import { useDispatch, useSelector } from 'react-redux'

import { EditButton } from './shared'
import { TourTag } from '../../lib/tourSteps'
import { appearanceConstants } from '../../constants'
import { dialogCreators } from '../../actions/creators'
import CollapsableList from '../../components/CollapsableList'
import DockingMenuItem from '../../components/DockingMenuItem'
import ParagraphSkeleton from '../../components/loaders/ParagraphSkeleton'
import { appearanceSelectors, menuSelectors, moduleSelectors } from '../../selectors'
import ManageBusinessElements, { editManageBusinessElementsDialogKey } from '../../components/ManageBusinessElements'

export default function BusinessElements({ modulePending, open, classes, isPowerUser }) {
    const isV2 = useSelector(moduleSelectors.getIsV2)
    const menuItems = useSelector(menuSelectors.getTopNav)
    const layout = useSelector(appearanceSelectors.getApplicationLayout)

    const dispatch = useDispatch()
    const openManageBusinessElements = () =>
        dispatch(
            dialogCreators.showDialog(editManageBusinessElementsDialogKey, ManageBusinessElements, {
                title: 'Manage Elements',
            })
        )

    if (layout === appearanceConstants.Layout.CLASSIC) return null

    return (
        <DockingMenuItem
            dataTour={TourTag.BusinessElements}
            title="Business Elements"
            Icon={BusinessElement}
            collapsed={!open}
            noUnderline
            collapsable
            RightIcon={
                isV2 && isPowerUser
                    ? () => <EditButton sx={{ color: 'text.primary' }} title="Manage Business Elements" />
                    : null
            }
            onRightIconClick={openManageBusinessElements}
        >
            {menuItems.length === 0 && !modulePending ? (
                !open ? (
                    <Typography classes={{ root: classes.emptyText }}>N/A</Typography>
                ) : (
                    <Typography classes={{ root: classes.emptyText }}>No business elements to display</Typography>
                )
            ) : (
                <CollapsableList
                    collapsed={!open}
                    items={menuItems}
                    loading={modulePending}
                    loader={<ParagraphSkeleton lineCount={2} />}
                />
            )}
        </DockingMenuItem>
    )
}
