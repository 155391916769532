import { alpha, Button, styled } from "@mui/material"

const FormButton = styled(Button)(({ theme }) => {
    const { palette } = theme
    const bgColor = alpha(palette.secondary.light, 0.7)
    return {
        fontWeight: 'normal',
        backgroundColor: bgColor,
        border: `1px solid ${palette.secondary.main}`,
        color: palette.getContrastText(bgColor),
    }
})

export default FormButton