import moment from 'moment'
import ReactLoading from 'react-loading'
import { Box, Tooltip, useTheme } from '@mui/material'
import { GridCellParams, GridRowModel } from '@mui/x-data-grid-pro'
import { TadaColDef, ComparisonOperator, NumberFormat, FormConditionalFormat } from 'genesis-suite/types/visualTypes'

import { getType } from '../../../lib/formUtils'
import formatNumber from '../utils/formatNumber'
import AttachmentButton from './AttachmentButton'

interface TableFormCellProps extends GridCellParams {
    conditionalFormatting: FormConditionalFormat[]
    colDef: TadaColDef
    numberFormat: NumberFormat
    isLoading?: boolean
}

export default function TableFormCell({
    row,
    value,
    conditionalFormatting,
    colDef,
    isLoading,
    numberFormat,
}: TableFormCellProps) {
    const {
        palette: { primary, getContrastText },
    } = useTheme()
    const { dateFormat, displayProperty, property } = colDef
    const val = displayProperty ? row[displayProperty.name] : value
    const type = getType(property, dateFormat)

    const formattedVal = processValue(val, type, dateFormat, numberFormat)
    return (
        <Tooltip title={formattedVal && type !== 'attachment' ? formattedVal : ''}>
            <Box
                sx={{
                    p: '0 10px',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'inherit',
                    '& .loading': { position: 'absolute' },
                    ...processFormatting(conditionalFormatting, row, getContrastText),
                    ...(isLoading && { opacity: 0.75, backgroundColor: 'grayscale.light' }),
                }}
            >
                {isLoading && <ReactLoading color={primary.main} type="bubbles" className="loading" />}
                {type === 'attachment' ? <AttachmentButton {...{ attachments: formattedVal }} /> : formattedVal}
            </Box>
        </Tooltip>
    )
}

const processValue = (val, type, dateFormat, numberFormat) => {
    if (val && (type === 'date' || type === 'dateTime')) {
        const date = moment(val).format(dateFormat || undefined)
        if (date === 'Invalid date') return val
        else return date
    } else if (type === 'attachment') {
        return val?.split(',') ?? []
    } else if (type === 'number') return formatNumber(val, numberFormat)
    else return val
}

const processFormatting = (formats: FormConditionalFormat[], data: GridRowModel, getContrast) => {
    if (!formats?.length) return {}
    for (let i = 0; i < formats.length; i++) {
        const { property, format, rule, target } = formats[i]
        if (!property || !rule || !target) return {}
        const { backgroundColor, borderColor } = format

        const value = lowerIfString(data[property.name])
        const targetVal =
            typeof target === 'string' || typeof target === 'number'
                ? lowerIfString(target)
                : lowerIfString(data[target.name])

        if (evaluate(value, targetVal, rule)) {
            return { backgroundColor, color: getContrast(backgroundColor), borderColor }
        }
    }
}

const lowerIfString = value => {
    if (typeof value === 'string') return value.toLowerCase()
    else return value
}

const evaluate = (x, y, op: ComparisonOperator) => {
    switch (op) {
        case '=':
            return x == y
        case '<':
            return x < y
        case '>':
            return x > y
        case '<=':
            return x <= y
        case '>=':
            return x >= y
        default:
            return false
    }
}
