import { useSelector, useDispatch } from 'react-redux'
import { Box } from '@mui/material'
import { deploymentSelectors, lockoutSelectors, widgetSelectors } from '../selectors'
import Perspective from '../components/widgets/Perspective'
import PerspectiveInfoBar from '../components/PerspectiveInfoBar'
import LayoutPerspectiveView from './layoutPerspective/LayoutPerspectiveView'
import PerspectiveLayoutView from './perspectivelayout/PerspectiveViewLayout'
import { LayoutPerspectiveProvider } from '~/components/contexts/LayoutPerspectiveContext'
import { networkCreators, widgetCreators } from '../actions/creators'
import Dashboard from '../components/widgets2/dashboard/Dashboard'
import useDashboardId from '../hooks/useDashboardId'

export default function PerspectiveView() {
    const { id, isV2 } = useDashboardId()
    const editingPerspective = useSelector(widgetSelectors.getEditingPerspective)
    const changeToPerspectiveLayout = useSelector(widgetSelectors.getPerspectiveLayout)
    const draftLayouts = useSelector(widgetSelectors.getDraftPerspectiveLayouts)
    const currentLayouts = useSelector(widgetSelectors.getCurrentPerspectiveLayouts)
    const perspectiveLayoutConfig = useSelector(widgetSelectors.getPerspectiveLayoutConfig(id))
    const view = useSelector(deploymentSelectors.getDeploymentView)
    const dispatch = useDispatch()

    const perspectiveLayoutBool = changeToPerspectiveLayout?.id ? changeToPerspectiveLayout?.id === id : false
    const perspectiveLayoutView = perspectiveLayoutConfig != undefined ? true : false

    const props = {
        id,
        editingPerspective,
        changeToPerspectiveLayout: perspectiveLayoutBool,
        layouts: draftLayouts || currentLayouts,
        containerWidth: useSelector(widgetSelectors.getPerspectiveWidth),
        isEditing: editingPerspective === id,
        currentLayout: useSelector(widgetSelectors.getCurrentLayout),
        configs: useSelector(s => widgetSelectors.getWidgetConfigsForPerspective(s, id)),
        hasFetched: useSelector(widgetSelectors.getHasFetchedPerspective),
        lockedWidgets: useSelector(lockoutSelectors.getItems),
        onChange: id => dispatch(networkCreators.perspectiveChange(id)),
        changeLayouts: layouts => dispatch(widgetCreators.changePerspectiveLayouts(layouts)),
        onEditPerspective: () => dispatch(widgetCreators.startEditingPerspective()),
    }

    return (
        <>
            {isV2 ? (
                <Box sx={{ display: 'flex', flex: 1 }}>
                    <Dashboard id={id} view={view} />
                </Box>
            ) : perspectiveLayoutBool ? (
                <LayoutPerspectiveProvider>
                    <LayoutPerspectiveView />
                </LayoutPerspectiveProvider>
            ) : perspectiveLayoutView ? (
                <PerspectiveLayoutView config={perspectiveLayoutConfig} id={id} />
            ) : (
                <Perspective key={id} {...props} />
            )}
            <PerspectiveInfoBar />
        </>
    )
}
