import OpenIcon from '@mui/icons-material/FullscreenRounded'
import CloseIcon from '@mui/icons-material/FullscreenExitRounded'
import { useEffect, useState } from 'react'
import { MenuIcon } from 'genesis-suite/components'
import { logEvent } from '../lib/amplitudeClient'
import { useHotKey } from 'genesis-suite/hooks'
import { HotKeys, createTip } from '../lib/hotkeys'

export default function FullScreenButton({ iconStyle, buttonStyle }) {
    const [open, setOpen] = useState(false)

    useEffect(() => {
        document.addEventListener('fullscreenchange', () => {
            setOpen(Boolean(document.fullscreenElement))
        })
    }, [])

    function handleClick() {
        logEvent('FULL_SCREEN_TOGGLE')
        if (open) document.exitFullscreen?.()
        else document.documentElement.requestFullscreen()
    }

    useHotKey(HotKeys.Fullscreen, handleClick)
    const keyTip = createTip(HotKeys.Fullscreen)

    const Icon = open ? CloseIcon : OpenIcon

    return (
        <MenuIcon
            icon={<Icon sx={iconStyle} />}
            buttonProps={{ sx: buttonStyle }}
            onClick={handleClick}
            tooltip={open ? 'Close ' + keyTip : 'Full screen ' + keyTip}
        />
    )
}
