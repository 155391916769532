import { IconButton, Popover, Tooltip, Typography } from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { SearchBar } from 'genesis-suite/components'
import { useDebouncedCallback } from 'use-debounce'
import { logEvent } from '../../lib/amplitudeClient'
import { authSelectors, moduleSelectors } from '../../selectors'
import SearchListByNode from './SearchListByNode'
import { AIIcon } from 'genesis-suite/icons'
import AIChatDialog from '~/views/rightnav/AIChatDialog'

const useStyles = makeStyles(theme => ({
    searchBarContainer: { width: '400px', marginLeft: 'auto' },
    searchBar: {
        border: `1px solid ${theme.palette.grayscale.lighter}`,
        '& .MuiInput-input': { color: theme.palette.text.default },
        width: (p: any) => (p.width ? p.width : 'inherit'),
    },
    paper: { boxShadow: '0 6px 20px 0 rgba(0, 0, 0, 0.3)', marginTop: theme.spacing() },
    resultsContainer: { width: '450px', maxWidth: '100%', height: '342px' },
    title: { padding: theme.spacing(1), height: 50 },
    searchContentsContainer: { height: 'calc(100% - 50px)' },
    aiIcon: {
        transition: 'transform 0.2s ease-in-out',
        '&:focus': {
            outline: 'none',
            transform: 'scale(1.2)',
        },
    },
}))

/** Handle the main text and voice searching for the app returning results with links to related perspectives */
export default function SearchController({
    popoverProps = undefined,
    preSearchText = '',
    setPreSearchText = null,
    onCloseSearch = null,
    width = 'inherit',
}) {
    const modulesPending = useSelector(moduleSelectors.getModulesPending)
    const enabled = useSelector(moduleSelectors.getEnableSearch)
    const theme = useTheme()
    const aiIconRef = useRef()

    const searchBarRef = useRef()
    const [text, setText] = useState(preSearchText)
    const [openSearch, setOpenSearch] = useState(false)
    const [searchQuery, setSearchQuery] = useState(preSearchText)
    const [openAiChat, setOpenAiChat] = useState(false)
    const isAdmin = useSelector(authSelectors.getIsAdmin)
    const enableAIChat = useSelector(moduleSelectors.getEnableAIChat)

    const classes = useStyles({ width })

    const handleChangeDebounce = useDebouncedCallback(handlePerformSearch, 1000)

    useEffect(() => {
        const handleKeyDown = event => {
            if (event.key === 'Tab') {
                event.preventDefault()
                //@ts-ignore
                aiIconRef?.current?.focus()
            }
        }

        document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [])

    useEffect(() => {
        const handleAiIconKeyDown = event => {
            if (event.key === 'Enter') {
                handleDismissResults()
                setOpenAiChat(true)
            }
        }

        const aiIconElement = aiIconRef.current as any
        if (aiIconElement) {
            const handleFocus = () => {
                aiIconElement.addEventListener('keydown', handleAiIconKeyDown)
            }

            const handleBlur = () => {
                aiIconElement.removeEventListener('keydown', handleAiIconKeyDown)
            }

            aiIconElement.addEventListener('focus', handleFocus)
            aiIconElement.addEventListener('blur', handleBlur)

            return () => {
                aiIconElement.removeEventListener('focus', handleFocus)
                aiIconElement.removeEventListener('blur', handleBlur)
            }
        }
    }, [aiIconRef.current])

    if (!enabled) return

    function handlePerformSearch(query, usedVoice) {
        if (!query) return
        if (usedVoice) logEvent('VOICE_EVENT', { result: query })

        if (!openSearch) setOpenSearch(true)
        else setSearchQuery(query)
    }

    function handleDismissResults() {
        setOpenSearch(false)
    }

    function onSearchResultSelect() {
        setText('')
        setPreSearchText?.('')
        handleDismissResults()
        onCloseSearch?.()
    }

    function onSearchTextChange(searchText) {
        setText(searchText)
        if (!openSearch) setSearchQuery(searchText)
        handleChangeDebounce(searchText, false)
    }

    const AdditionalSearchActions = () => {
        if (!isAdmin || !enableAIChat) return null
        return (
            <Tooltip title="Search AI" placement="top">
                <IconButton
                    sx={{ cursor: 'pointer', color: 'rgba(0, 0, 0, 0.54)' }}
                    onClick={() => setOpenAiChat(true)}
                    disabled={!text}
                >
                    <AIIcon ref={aiIconRef} tabIndex={0} className={classes.aiIcon} />
                </IconButton>
            </Tooltip>
        )
    }

    return (
        <>
            <SearchBar
                className={classes.searchBar}
                placeholder="Search Tada"
                ref={searchBarRef}
                height="small"
                onEnter={handlePerformSearch}
                onEscape={onSearchResultSelect}
                disabled={modulesPending}
                value={text}
                onChange={onSearchTextChange}
                collapsable={false}
                iconColor={theme.palette.getContrastText(theme.palette.background.topBar)}
                radius
                inputColor={'#F9FAFB'}
                InputProps={{ disableUnderline: true }}
                autoFocus={true}
                AdditionalActions={AdditionalSearchActions}
            />
            <Popover
                open={openSearch}
                anchorEl={searchBarRef.current}
                onClose={handleDismissResults}
                classes={{ paper: classes.paper }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                disableAutoFocus
                disableEnforceFocus
                {...popoverProps}
            >
                <div className={classes.resultsContainer}>
                    <Typography variant="h6" className={classes.title}>
                        Search Results
                    </Typography>

                    <div className={classes.searchContentsContainer}>
                        <SearchListByNode text={searchQuery} onNodeSelect={onSearchResultSelect} />
                    </div>
                </div>
            </Popover>
            <AIChatDialog
                openChatDialog={openAiChat}
                closeDialog={() => setOpenAiChat(false)}
                inputQuestion={text}
            ></AIChatDialog>
        </>
    )
}
