import { useState, forwardRef, Ref, ReactElement, createContext } from 'react'

import makeStyles from '@mui/styles/makeStyles'
import CloseIcon from '@mui/icons-material/Close'
import { Typography, IconButton, Dialog, Slide, AppBar, Toolbar } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

const useStyles = makeStyles(({ palette }) => ({
    appBar: {
        backgroundColor: palette.background.topBar,
        color: palette.getContrastText(palette.background.topBar),
        position: 'relative',
    },
}))

const FullScreenDialogContext = createContext({
    dialogContent: (content: any) => null,
    dialogTitle: (title: string) => null,
    handleClose: () => null,
    handleClickOpen: () => null,
})

const FullScreenDialogProvider = ({ children }) => {
    const classes = useStyles({})
    const [open, setOpen] = useState(false)
    const [content, setContent] = useState(<></>)
    const [title, setTitle] = useState('File Viewer')

    const handleClickOpen = async () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const dialogContent = (content: any) => {
        setContent(content)
    }

    const dialogTitle = (title: string) => {
        setTitle(title)
    }

    return (
        <FullScreenDialogContext.Provider value={{ handleClose, handleClickOpen, dialogContent, dialogTitle }}>
            {children}
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography sx={{ flex: 1 }} variant="h6" component="div">
                            {title}
                        </Typography>
                        <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                {content}
            </Dialog>
        </FullScreenDialogContext.Provider>
    )
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement
    },
    ref: Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})

export { FullScreenDialogContext, FullScreenDialogProvider }
