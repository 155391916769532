import React from 'react'
import { Box, Divider, List, ListItem, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AnchorIcon from '@mui/icons-material/PlaceRounded'

import { PropertyType } from 'genesis-suite/types/architectureTypes'
import { Property } from 'genesis-suite/types/networkTypes'
import { ChopText } from 'genesis-suite/components'

interface Props {
    properties: Property[]
    anchorId?: string
    selectedPropertyIds: string[]
    onPropertySelect: (id: string) => void
}

export default function BusinessExplorerProperties({ properties, ...rest }: Props) {
    const elementProperties = properties.filter(p => p.semanticType.type !== PropertyType.QUANTITATIVE)
    const metricProperties = properties.filter(p => p.semanticType.type === PropertyType.QUANTITATIVE)

    return (
        <Box
            borderRight="1px solid #bdbdbd"
            display="grid"
            gridTemplateRows="50% 1px auto"
            height="100%"
            overflow="hidden"
        >
            <PropertyList title="Elements" properties={elementProperties} {...rest} />
            <Divider />
            <PropertyList title="Metrics" properties={metricProperties} {...rest} />
        </Box>
    )
}

const usePropertyListStyles = makeStyles(({ spacing }) => ({
    title: { padding: spacing(1, 1, 0) },
    item: { padding: spacing(0.5, 0, 0.5, 1) },
    checkbox: { padding: 0, marginRight: '4px' },
}))

interface PropertyListProps extends Props {
    title: string
}

function PropertyList({ title, properties, anchorId, selectedPropertyIds, onPropertySelect }: PropertyListProps) {
    const classes = usePropertyListStyles()

    function handlePropertySelect(e, id) {
        e.stopPropagation()
        e.preventDefault()
        onPropertySelect(id)
    }

    const hasAnchor = properties.some(p => p.id === anchorId)

    return (
        <Box overflow="hidden" display="flex" flexDirection="column">
            <Typography className={classes.title} variant="h6">
                {title}
            </Typography>
            <Box overflow="hidden auto">
                <List>
                    {properties.map(({ id, displayName }) => (
                        <ListItem
                            key={id}
                            button
                            disabled={hasAnchor && id !== anchorId}
                            className={classes.item}
                            selected={selectedPropertyIds.includes(id)}
                            onClickCapture={e => handlePropertySelect(e, id)}
                        >
                            <Box display="flex" alignItems="center" overflow="hidden">
                                <ChopText showEllipsis>{displayName}</ChopText>
                                {anchorId === id && <AnchorIcon color="primary" />}
                            </Box>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Box>
    )
}
