import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import makeStyles from '@mui/styles/makeStyles'
import { Search, CloseRounded } from '@mui/icons-material'
import {
    Avatar,
    Badge,
    Box,
    Divider,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'

import { Spinner } from 'genesis-suite/components'
import { messengerSelectors } from '~/selectors/messenger.selectors'
import { authSelectors, collaborationSelectors } from '~/selectors'

const useStyles = makeStyles(({ palette }) => ({
    messageList: {
        overflow: 'scroll',
        maxHeight: '360px',
    },
    listContent: {
        color: palette.text.primary,
    },
    disabled: {
        opacity: 0.5,
        cursor: 'default',
    },
}))

const NewChatBox = ({ classes, onClose, onChatOpen }) => {
    const chatClasses = useStyles()
    const [isOpen, setIsOpen] = useState(true)

    const usersList = useSelector(messengerSelectors.getUserList)
    const loader = useSelector(messengerSelectors.getLoader)
    const userId = useSelector(authSelectors.getUserId)
    const users = useSelector(collaborationSelectors.collaborators)

    const [search, setSearch] = useState('')
    const [animateClass, setAnimateClass] = useState(true)
    const [openClass, setOpenClass] = useState(true)

    const toggleChatBox = () => {
        setIsOpen(!isOpen)
    }

    const handleClose = () => {
        if (isOpen) {
            setAnimateClass(false)
            setTimeout(() => onClose(), 500)
        } else {
            onClose()
        }
    }

    const handleOpenChat = user => {
        if (!user.DomainId) return

        const userObj = { id: user.UserId, ScreenAlias: user.ScreenAlias }
        if (isOpen) {
            setAnimateClass(false)
            setTimeout(() => onChatOpen(userObj), 500)
        } else {
            onChatOpen(userObj)
        }
    }

    useEffect(() => {
        setTimeout(() => setOpenClass(false), 700)
    }, [])

    return (
        <Box
            className={classNames(classes.chatBoxContainer, {
                [classes.chatBoxOpen]: isOpen,
                'slide-in-bottom': animateClass && openClass,
                'slide-out-bottom': !animateClass && isOpen,
            })}
        >
            <Box className={classNames(classes.chatBoxToggle, classes.chatHeader)} onClick={toggleChatBox}>
                <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center', ml: 0.5 }}>
                    <Typography variant="body1" sx={{ fontSize: '1.2rem', fontWeight: 500, marginRight: '10px' }}>
                        New Chat
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <IconButton className={classes.buttonStyle} size="small" onClick={handleClose} data-cy="close-chat">
                        <CloseRounded />
                    </IconButton>
                </Box>
            </Box>
            <Box className={classes.chatBox}>
                <TextField
                    id="search-box"
                    placeholder="Search Users"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={search}
                    className={classes.searchBox}
                    onChange={e => setSearch(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                        style: { borderRadius: 10, height: 30 },
                    }}
                />
                {loader ? (
                    <Box my={1}>
                        <Spinner />
                    </Box>
                ) : (
                    <List className={chatClasses?.messageList}>
                        {usersList
                            .filter(user => user?.UserId.toLowerCase() !== userId)
                            .filter(user => user?.ScreenAlias.toLowerCase().includes(search.toLowerCase()))
                            .map(user => {
                                const isOnline = users.some(u => u.name === user?.ScreenAlias)
                                return (
                                    <>
                                        <Tooltip
                                            title={
                                                user?.DomainId
                                                    ? 'Start Chat'
                                                    : 'You cannot chat with this user as user does not have domain'
                                            }
                                        >
                                            <ListItem
                                                key={user?.UserId}
                                                onClick={() => handleOpenChat(user)}
                                                className={classNames(chatClasses?.listContent, {
                                                    [chatClasses?.disabled]: !user.DomainId,
                                                })}
                                                sx={{ cursor: 'pointer', alignItems: 'flex-start' }}
                                            >
                                                {/* Disable if user doesnt have domain */}
                                                <ListItemAvatar sx={{ margin: 0 }}>
                                                    <Badge
                                                        overlap="circular"
                                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                        variant="dot"
                                                        invisible={!isOnline}
                                                        color={'success'}
                                                    >
                                                        <Avatar className={classes.avatar}>
                                                            {user?.ScreenAlias[0].toUpperCase()}
                                                        </Avatar>
                                                    </Badge>
                                                </ListItemAvatar>
                                                <ListItemText primary={user?.ScreenAlias} />
                                            </ListItem>
                                        </Tooltip>
                                        <Divider />
                                    </>
                                )
                            })}
                    </List>
                )}
            </Box>
        </Box>
    )
}

export default NewChatBox
