import { GridApi, IServerSideGetRowsParams } from '@ag-grid-community/core'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Tab, Tabs, Typography } from '@mui/material'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { agGridConstants } from '~/constants/aggrid.constants'
import { widgetService } from '~/lib/services'
import { applicationSelectors, moduleSelectors } from '~/selectors'
import { BaseAgGridTable } from '../widgets2/agGridTable/BaseAgGridTable'

interface WidgetGTMDialogParams {
    open: boolean
    setOpen: (open: boolean) => void
    onClose: () => void
    widgetId: string
    transactionNodes: any[]
}

export default function WidgetGTMDialog({ open, setOpen, onClose, widgetId, transactionNodes }: WidgetGTMDialogParams) {
    const [tabs, setTabs] = useState<Array<any>>([])
    const [tabIndex, setTabIndex] = useState<number>(0)
    const Model = useSelector(applicationSelectors.getCurrentAppName)
    const moduleId = useSelector(moduleSelectors.getModuleId)
    const [noDataMessage, setNoDataMessage] = useState<string>('')
    useEffect(() => {
        if (open && transactionNodes?.length) {
            const tabs = transactionNodes.map((node: any) => {
                return {
                    name: node.Name,
                    title: node.DisplayName,
                    id: node.Id,
                    TransactionalNodeId: node.TransactionalNodeId,
                    columns: node.Columns.map((col: any) => {
                        const {
                            Name,
                            DisplayName,
                            Id,
                            Description,
                            ShortName,
                            TransactionalDataNodeId,
                            Datatype,
                            IncludeInHash,
                            SemanticTypeId,
                            SemanticType,
                            TransactionalColumnId,
                        } = col
                        return {
                            Name,
                            DisplayName,
                            Id,
                            Description,
                            ShortName,
                            TransactionalDataNodeId,
                            Datatype,
                            IncludeInHash,
                            SemanticTypeId,
                            SemanticType,
                            TransactionalColumnId,
                        }
                    }),
                }
            })
            setTabs(tabs)
        }
    }, [open, transactionNodes])
    return (
        <Dialog open={open} maxWidth="lg" fullWidth onClose={onClose}>
            <DialogTitle>
                <Tabs
                    value={tabIndex}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    sx={{
                        mr: '50px',
                        '& .MuiTabs-scrollButtons.Mui-disabled': {
                            opacity: 0.3,
                        },
                    }}
                >
                    {tabs.map(tab => (
                        <Tab key={tab.id} label={tab.title} />
                    ))}
                </Tabs>
                <IconButton
                    onClick={() => setOpen(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ height: 600 }}>
                {noDataMessage ? (
                    <Typography sx={{ textAlign: 'center' }}>{noDataMessage}</Typography>
                ) : (
                    tabs.map((tab, index) => (
                        <TabPanel key={tab.title} value={tabIndex} index={index}>
                            <TabContent tabConfig={tab}></TabContent>
                        </TabPanel>
                    ))
                )}
            </DialogContent>
        </Dialog>
    )
}

interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props

    return (
        <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} style={{ height: '100%' }} {...other}>
            <Box height="100%">{children}</Box>
        </div>
    )
}

interface TabContentProps {
    tabConfig: any
}

const TabContent = memo(({ tabConfig }: TabContentProps) => {
    const Model = useSelector(applicationSelectors.getCurrentAppName)

    const createColumnDefs = useCallback(() => {
        return tabConfig.columns.map((c: any) => {
            return {
                headerName: c.DisplayName,
                colId: c.Name,
                headerTooltip: c.DisplayName,
                field: c.Name,
            }
        })
    }, [tabConfig])

    const defaultColDef = useMemo(() => {
        return {
            minWidth: 120,
            resizable: true,
            sortable: true,
            unSortIcon: true,
        }
    }, [])

    const getDataSource = useCallback(() => {
        return {
            getRows: async (params: IServerSideGetRowsParams) => {
                try {
                    const data = {
                        pageSize: 100,
                        pageIndex: params.request.endRow / agGridConstants.CACHE_BLOCK_SIZE,
                    }

                    const result = await widgetService.getGTMDataByTransactionNode(Model, tabConfig.id, data)

                    if (!result.Data) {
                        params.success({
                            rowData: [],
                            rowCount: 0,
                        })
                        return
                    }

                    params.success({
                        rowData: result.Data,
                        rowCount: result.RowCount,
                    })
                } catch (error) {
                    console.error('error', error)
                    params.fail()
                }
            },
        }
    }, [tabConfig])

    const onGridReady = useCallback(
        (params: { api: GridApi }) => {
            // register the datasource with the grid
            params.api.setGridOption('serverSideDatasource', getDataSource())
        },
        [getDataSource]
    )

    return (
        <BaseAgGridTable
            autoGroupColumnDef={{}}
            createColumnDefs={createColumnDefs()}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            pagination={true}
            paginationPageSize={100}
            pivotMode={false}
        />
    )
})
