import React, { useEffect, useRef, useState, lazy, Suspense } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import Slick from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import { SelectPopover } from 'genesis-suite/components'
import { useInterval } from 'genesis-suite/hooks'
import { Close, Expand, Stopwatch } from 'genesis-suite/icons'
import { navigationCreators } from '../actions/creators'
import ArrowPanel from '../components/ArrowPanel'
import { logEvent } from '../lib/amplitudeClient'
import { filterSelectors, widgetSelectors } from '../selectors'
import WidgetFilters from '~/components/widgets/WidgetFilters'

const height = '80vh'
const titleHeight = '64px'

const styles = theme => ({
    container: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        '& .slick-next, .slick-prev': {
            width: 55,
            height: 55,
            background: theme.palette.primary.main,
            borderRadius: '60rem',
            '&:before': {
                color: theme.palette.primary.contrastText,
                borderStyle: 'solid',
                borderWidth: '3px 3px 0 0',
                content: '""',
                display: 'inline-block',
                height: 14,
                width: 14,
                opacity: 1,
                position: 'relative',
            },
        },
        '& .slick-next': {
            right: -72,
            '&:before': {
                right: 2,
                transform: 'rotate(45deg)',
            },
        },
        '& .slick-prev': {
            left: -72,
            '&:before': {
                right: -2,
                transform: 'rotate(-135deg)',
            },
        },
        '& .slick-disabled': {
            opacity: '.3',
            cursor: 'default',
        },
        '& .slick-slide.slick-active.slick-current': {
            position: 'relative',
        },
    },
    widget: {
        width: '100%',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        height: `calc(${height} - ${titleHeight})`,
        '& ::-webkit-scrollbar': {
            width: 12,
            height: 12,
        },
        '& .widget-setup-container': {
            height: '100%',
        },
    },
    titleWrapper: {
        maxHeight: titleHeight,
        width: '100%',
        padding: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'center',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    title: {
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    slideIndicatorWrapper: {
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(1.25, 0, 1.25, 0),
    },
    slideIndicator: {
        background: '#000000',
        color: '#ffffff',
        width: 86,
        height: 28,
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    controls: {
        position: 'absolute',
        right: 0,
        zIndex: 2,
        padding: theme.spacing(1, 1, 0, 0),
        backgroundColor: theme.palette.background.main,
    },
    content: {
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
        display: 'flex',
        justifyContent: 'center',
        flex: 1,
        '& .slick-slider': {
            width: '100%',
            display: 'flex',
            '& .slick-list': {
                flex: 1,
                '& .slick-track': {
                    height: '100%',
                    '& .slick-slide': {
                        '& > div': {
                            height: '100%',
                            '& > div': {
                                height: '100%',
                                display: 'flex !important',
                                flexDirection: 'column',
                                justifyContent: 'center',
                            },
                        },
                    },
                },
            },
        },
    },
    intervalOption: {
        fontWeight: 600,
        textAlign: 'center',
    },
})

const intervalOptions = [
    { Icon: Stopwatch, value: null },
    { label: ' 5s', value: 5000 },
    { label: '10s', value: 10000 },
    { label: '15s', value: 15000 },
]

const SlideshowContainer = ({ classes, onRequestClose, onExpand, startIndex = 0, expanded, widgetConfigs }) => {
    const filters = useSelector(filterSelectors.currentFiltersConfiguration)
    const inlineFilters = useSelector(filterSelectors.getInlineFilters)
    const networkContext = useSelector(filterSelectors.getCoord)
    const interactionType = useSelector(widgetSelectors.getInteractionType)

    const dispatch = useDispatch()
    const [index, setIndex] = useState(0)
    const [delay, setDelay] = useState(intervalOptions[0].value)
    const [loadedWidgets, setLoadedWidgets] = useState({})
    const sliderRef = useRef(null)

    const buttonStyle = { color: 'text.primary' }

    useEffect(() => {
        sliderRef.current.slickGoTo(startIndex)

        window.addEventListener('onkeydown', handleKeyPress)
        return () => {
            window.removeEventListener('onkeydown', handleKeyPress)
        }
    }, [])

    useInterval(() => {
        const nextSlide = (index + 1) % widgetConfigs.length
        if (sliderRef.current) sliderRef.current.slickGoTo(nextSlide)
    }, delay)

    const handleKeyPress = e => {
        switch (e.keyCode) {
            case 37:
                if (index > 1) sliderRef.current.slickGoTo(index - 1)
                break
            case 39:
                if (index < widgetConfigs.length) sliderRef.current.slickGoTo(index + 1)
                break
            default:
                return
        }
    }

    const settings = {
        dots: false,
        infinite: true,
        arrows: true,
        swipe: false,
        waitForAnimate: false,
        lazyLoad: 'ondemand',
        prevArrow: expanded && <ArrowPanel orientation="left" />,
        nextArrow: expanded && <ArrowPanel orientation="right" />,
        beforeChange: (current, next) => setIndex(next),
    }

    const goToPerspective = (perspectiveId, context) => {
        onRequestClose()
        dispatch(navigationCreators.goToPerspective(perspectiveId, { context }))
    }

    const handleDelayChange = interval => {
        logEvent(`SLIDESHOW_INTERVAL_CHANGE_${interval.value ? interval.value / 1000 : 'Off'}`)
        setDelay(interval.value)
    }

    const renderWidget = config => {
        if (loadedWidgets[config.Id]) {
            return loadedWidgets[config.Id]
        }
        const LazySlideshowWidget = lazy(() => import('./SlideshowWidget'))
        const widget = (
            <Suspense fallback={<div>Loading...</div>} key={config.Id}>
                <LazySlideshowWidget
                    classes={classes}
                    config={config}
                    filters={filters}
                    networkContext={networkContext}
                    inlineFilters={inlineFilters}
                    expanded={expanded}
                    interactionType={interactionType}
                    goToPerspective={goToPerspective}
                />
            </Suspense>
        )

        setLoadedWidgets(prev => ({ ...prev, [config.Id]: widget }))
        return widget
    }

    const currentConfig = widgetConfigs[index]

    return (
        <div className={classes.container}>
            <div className={classes.controls}>
                <WidgetFilters
                    buttonStyle={buttonStyle}
                    filterConfigs={currentConfig?.DynamicFiltersConfig}
                    widgetId={currentConfig?.Id}
                />
                <SelectPopover
                    items={intervalOptions}
                    defaultValue={intervalOptions[0]}
                    tooltip="Time interval"
                    onChange={interval => handleDelayChange(interval)}
                    iconProps={{ sx: { ...buttonStyle } }}
                />
                <Tooltip title="Expand">
                    <IconButton sx={buttonStyle} onClick={onExpand}>
                        <Expand />
                    </IconButton>
                </Tooltip>
                <IconButton sx={buttonStyle} onClick={onRequestClose}>
                    <Close />
                </IconButton>
            </div>
            <div className={classnames({ [classes.content]: expanded })}>
                <Slick ref={sliderRef} {...settings}>
                    {widgetConfigs.map(config => (
                        <div key={config.Id}>{index === widgetConfigs.indexOf(config) && renderWidget(config)}</div>
                    ))}
                </Slick>
            </div>
            <div className={classes.slideIndicatorWrapper}>
                <div className={classes.slideIndicator}>
                    {index + 1} of {widgetConfigs ? widgetConfigs.length : 1}
                </div>
            </div>
        </div>
    )
}

export default withStyles(styles)(SlideshowContainer)
