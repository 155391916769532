import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'
import { matchPath } from 'react-router-dom'
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect'

import { routePaths } from '../lib/routes'
import { widgetConstants } from '../constants'
import { navigationSelectors } from './navigation.selectors'
import { moduleSelectors } from './module.selectors'

const createArrayValuesEqualitySelector = createSelectorCreator(defaultMemoize, (a, b) => {
    if (!Array.isArray(a)) return a === b
    if (a.length !== b?.length) return false
    let result = true
    a.forEach((value, i) => {
        if (!(value === b?.[i])) result = false
    })
    return result
})

const getCurrentPerspectiveId = createSelector(navigationSelectors.getRouter, router => {
    const params = matchPath(routePaths.PERSPECTIVE, router.location.pathname)?.params
    if (params && params.perspectiveID) {
        const id = params.perspectiveID
        return id
    } else {
        const nparams = matchPath(routePaths.COMPARE_PERSPECTIVE, router.location.pathname)?.params
        if (nparams && nparams.perspectiveID) {
            const id = nparams.perspectiveID
            return id
        }
        return null
    }
})

const isV2Dashboard = createSelector(getCurrentPerspectiveId, dashboardId =>
    dashboardId ? !dashboardId.includes('-') : false
)

/**
 * @param {object} state - the redux store state
 * @param {string} id - guid id of the widget to get the config for
 * @returns {object} the widget configuration object
 */
const getWidgetConfig = (state, id) => {
    const config = state.widgets.configs[id]
    return config
}

/**
 * Selector to get all widget configuration objects
 * @param {object} state - the redux store state
 * @returns {object} - object of widget configurations key'd by Id
 */
const getWidgetConfigs = state => state.widgets.configs

const getCurrentPerspectiveConfig = createSelector(
    [getCurrentPerspectiveId, getWidgetConfigs],
    (id, allConfigs) => allConfigs[id]
)

const getIsAPerspective = createSelector([getCurrentPerspectiveConfig], config => {
    if (config?.Type !== 'Container') return false
    if (config?.ContainerConfig?.Cells?.length < 2) return false
    return true
})

const getCurrentPerspectiveLayouts = createSelector([getCurrentPerspectiveConfig], config => {
    if (!config) return null

    let sm = [],
        lg = []

    if (config.Type !== 'Container') return null

    for (let { WidgetId, Small, Large } of config.ContainerConfig.Cells) {
        if (!WidgetId) continue

        if (Small) sm.push({ i: WidgetId, ...Small })
        if (Large) lg.push({ i: WidgetId, ...Large })
    }

    return { sm, lg }
})

const getPerspectiveLayoutsByPerspectiveId = Id =>
    createSelector([getWidgetConfigs], allConfigs => {
        const config = allConfigs[Id]

        if (!config) return null

        let sm = [],
            lg = []

        if (config.Type !== 'Container') return null

        for (let { WidgetId, Small, Large } of config.ContainerConfig.Cells) {
            if (!WidgetId) continue

            if (Small) sm.push({ i: WidgetId, ...Small })
            if (Large) lg.push({ i: WidgetId, ...Large })
        }

        return { sm, lg }
    })

/** get filters for v2 perspectives */
const getCurrentPerspectiveFilters = createSelector([getCurrentPerspectiveConfig], config => config?.filters)

const getCurrentPerspectiveDefaultfilters = createSelector(
    [getCurrentPerspectiveConfig],
    perspectiveConfig => (perspectiveConfig && perspectiveConfig.DefaultFilters) || []
)

const getCurrentPerspectiveDefaultNetworkfilters = createSelector(
    [getCurrentPerspectiveConfig],
    perspectiveConfig => (perspectiveConfig && perspectiveConfig.DefaultNetworkFilters) || []
)

const getIsPerspectiveAutoLaunch = createSelector([getCurrentPerspectiveConfig], perspectiveConfig =>
    Boolean(perspectiveConfig?.ContainerConfig?.AutoLaunch)
)

const getAutoLaunchPerspectiveId = createSelector(
    [getWidgetConfigs, moduleSelectors.getModuleId],
    (configs, currentModuleId) => {
        const autoLaunchConfig = Object.values(configs).find(c => c?.ContainerConfig?.AutoLaunch)
        return autoLaunchConfig?.ParentVisorId === currentModuleId ? autoLaunchConfig.Id : undefined
    }
)

/**
 * Gets an array of widget ids for a given perspective Id
 * @param {object} state - the redux store state
 * @param {string} perspectiveId - guid id of the perspective
 * @returns {array[string]} - array of guids widget ids
 */
const getWidgetsForPerspective = createSelector([getWidgetConfig], perspectiveConfig => {
    if (!perspectiveConfig) return []

    const { ContainerConfig, Id } = perspectiveConfig
    if (!ContainerConfig) return Id ? [Id] : []

    return ContainerConfig.Cells.filter(cell => cell.WidgetId !== null)
        .sort((a, b) => (a.Top - b.Top === 0 ? a.Left - b.Left : a.Top - b.Top))
        .map(cell => cell.WidgetId)
})

const getFocalPointForPerspective = createSelector(
    [getWidgetConfig],
    perspectiveConfig => perspectiveConfig && perspectiveConfig.Element
)

/**
 * Gets an array of widget configurations for a given perspective Id
 * @param {object} state - the redux store state
 * @param {string} perspectiveId - guid id of the perspective
 * @returns {array[string]} - array of guids widget ids
 */
const getBaseWidgetConfigsForPerspective = createSelector(
    [getWidgetConfigs, getWidgetsForPerspective],
    (allConfigs, widgetIds) => {
        return widgetIds.map(id => allConfigs[id])
    }
)

/**
 * Gets an array of widget configurations for a given perspective Id
 * @param {object} state - the redux store state
 * @param {string} perspectiveId - guid id of the perspective
 * @returns {array[string]} - array of guids widget ids
 */
const getWidgetConfigsForPerspective = createArrayValuesEqualitySelector(
    [getBaseWidgetConfigsForPerspective, getFocalPointForPerspective],
    (widgetConfigs, element) => {
        return widgetConfigs
            .filter(c => Boolean(c))
            .map(widgetConfig => {
                const config = cloneDeep(widgetConfig)
                config.Context = {
                    Type: 'Node',
                    Name: element,
                }
                return config
            })
    }
)

/**
 * Given the id of a widget, finds the containing perspective id
 * @param {object} state - the redux store state
 * @param {string} widgetId - the id of the widget to find the containing perspective for
 * @returns {string} - id of the default perspective
 */
const getPerspectiveIdFromWidgetId = createSelector([getWidgetConfigs, (_, id) => id], (allConfigs, widgetId) => {
    return Object.values(allConfigs)
        .filter(config => !!config.ContainerConfig)
        .find(({ ContainerConfig }) => ContainerConfig.Cells.some(({ WidgetId }) => widgetId === WidgetId))?.Id
})

/**
 * Given the id of a widget, finds the containing perspective
 * @param {object} state - the redux store state
 * @param {string} widgetId - the id of the widget to find the containing perspective for
 * @returns {string} - id of the default perspective
 */
const getPerspectiveFromWidgetId = createSelector([getWidgetConfigs, (_, id) => id], (allConfigs, widgetId) => {
    return Object.values(allConfigs)
        .filter(config => !!config.ContainerConfig)
        .find(({ ContainerConfig }) => ContainerConfig.Cells.some(({ WidgetId }) => widgetId === WidgetId))
})

/**
 * Given the name of a node, finds the default perspective, if any
 * @param {object} state - the redux store state
 * @param {string} nodeName - the name of the node to find the default perspective for
 * @returns {string} - id of the default perspective
 */
const getDefaultPerspectiveForNode = createSelector(
    [getWidgetConfigs, (_, nodeName) => nodeName],
    (allConfigs, nodeName) =>
        nodeName &&
        Object.keys(allConfigs)
            .filter(widgetId => allConfigs[widgetId].ContainerConfig && allConfigs[widgetId].ContainerConfig.IsDefault)
            .find(widgetId => allConfigs[widgetId].Element.trim().toLowerCase() === nodeName.toLowerCase().trim())
)

const getCurrentLayout = state => state.widgets.currentLayout

const getSelectedLayout = state => state.widgets.selectedLayout

const getPreviousLayout = state => state.widgets.previousLayout

const getInteractionType = state => {
    let value = state.widgets.interactionType

    // TODO - remove this. temporary to replace old persisted redux state
    if (typeof value === 'object') value = widgetConstants.Interactions.INFINITE

    return value
}

const getWidgetTitle = (state, id) => {
    if (id === '__home') return 'Home'
    const config = state.widgets.configs[id]
    return config ? config.Title : null
}

const getDefaultWidgetFilters = createSelector([getWidgetConfig], perspectiveConfig => perspectiveConfig.DefaultFilters)

const getEditingPerspective = s => s.widgets.editingPerspective

const getPerspectiveLayout = s => s.widgets.changeToPerspectiveLayout

const getIsSavingWidgets = s => s.widgets.isSavingWidgets

const getDraftPerspectiveLayouts = s => s.widgets.draftPerspectiveLayouts

const getDraftTitles = s => s.widgets.draftTitles

const getHasEditedPerspectiveLayouts = createSelector(
    [getCurrentPerspectiveLayouts, getDraftPerspectiveLayouts],
    (layouts, layoutDrafts) => {
        if (!layoutDrafts) return false

        const sortLayouts = layouts => {
            const sorted = {}
            if (layouts)
                Object.keys(layouts).forEach(key => {
                    sorted[key] = layouts[key].sort((a, b) => a.i.localeCompare(b.i))
                })

            return sorted
        }

        return !isEqual(sortLayouts(layouts), sortLayouts(layoutDrafts))
    }
)

const getHasFetchedPerspective = createSelector([getCurrentPerspectiveConfig], perspective => Boolean(perspective))

const getHasFetchedPerspectiveById = Id =>
    createSelector([getWidgetConfigs], allConfigs => {
        const config = allConfigs[Id]

        return Boolean(config)
    })

const getPerspectiveLayoutConfig = Id =>
    createSelector([getWidgetConfigs], allConfigs => {
        const config = allConfigs[Id]?.ContainerConfig?.Layout
        return config
    })

const getPerspectiveWidth = s => s.widgets.perspectiveWidth

const getCommentingWidgetId = s => s.widgets.commentingWidget

const getRefreshWidgets = s => s.widgets.shouldRefresh

const getDiagnostics = s => s.widgets.diagnostics

const getLabelWidgetLayouts = s => s.widgets.labelWidgetLayouts

const getTopLabelWidgetLayouts = s => s.widgets.topLabelWidgetLayouts

const getV1Perspectives = createSelector([getWidgetConfigs], configs =>
    Object.values(configs)
        .filter(c => Boolean(c.ContainerConfig))
        .map(c => ({
            id: c.Id,
            title: c.Title,
            focalPoint: c.ContainerConfig.FocalPoint,
            showInExplorer: c.ContainerConfig.ShowInExplorer,
        }))
)

const getAllPerspectives = createSelector([getWidgetConfigs], configs =>
    Object.values(configs).filter(c => Boolean(c.ContainerConfig))
)
const getAllWidgets = createSelector([getWidgetConfigs], configs =>
    Object.values(configs).filter(c => !Boolean(c.ContainerConfig))
)

const getThumbnailRequests = s => s.widgets.thumbnailRequests

export const widgetSelectors = {
    getCurrentPerspectiveId,
    getCurrentPerspectiveConfig,
    getIsAPerspective,
    getCurrentPerspectiveLayouts,
    getCurrentPerspectiveFilters,
    getCurrentPerspectiveDefaultfilters,
    getWidgetsForPerspective,
    getWidgetConfigsForPerspective,
    getPerspectiveIdFromWidgetId,
    getPerspectiveFromWidgetId,
    getDefaultPerspectiveForNode,
    getIsPerspectiveAutoLaunch,
    getAutoLaunchPerspectiveId,
    getWidgetConfig,
    getDefaultWidgetFilters,
    getCurrentLayout,
    getSelectedLayout,
    getWidgetTitle,
    getWidgetConfigs,
    getInteractionType,
    getEditingPerspective,
    getIsSavingWidgets,
    getHasEditedPerspectiveLayouts,
    getDraftPerspectiveLayouts,
    getDraftTitles,
    getHasFetchedPerspective,
    getPerspectiveWidth,
    getCommentingWidgetId,
    getRefreshWidgets,
    getDiagnostics,
    getV1Perspectives,
    getAllPerspectives,
    getThumbnailRequests,
    isV2Dashboard,
    getCurrentPerspectiveDefaultNetworkfilters,
    getPerspectiveLayout,
    getAllWidgets,
    getPerspectiveLayoutsByPerspectiveId,
    getHasFetchedPerspectiveById,
    getLabelWidgetLayouts,
    getTopLabelWidgetLayouts,
    getPerspectiveLayoutConfig,
}
