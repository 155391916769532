import { useRef, useState } from 'react'
import { Button, ButtonProps, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import HelpIcon from '@mui/icons-material/HelpOutline'

import useNewWidgetList from './widgets2/utils/useNewWidgetList'
import { makeStyles } from '@mui/styles'

interface Props extends ButtonProps {
    /** (optional) only allow creating new widgets */
    onlyNew?: boolean
    /** (optional) only allow static(inline) widgets */
    onlyStatic?: boolean
    /** (optional) if controlled  */
    open?: boolean
    /** (optional) if controlled */
    onClick?: () => void
    /** (optional) if controlled */
    onClose?: () => void
}
const useStyles = makeStyles(theme => ({
    icon: {
        color: theme.palette.text.primary,
    },
}))

/** Button that opens a popover with the list of widget types */
export default function NewWidgetButton({
    onlyNew,
    onlyStatic,
    open: parentOpen,
    onClick,
    onClose,
    ...buttonProps
}: Props) {
    const actions = useNewWidgetList(onlyNew, onlyStatic, handleClose)
    const [open, setOpen] = useState(false)
    const buttonRef = useRef()

    function handleOpen() {
        if (onClick) onClick()
        else setOpen(true)
    }

    function handleClose() {
        if (onClose) onClose()
        else setOpen(false)
    }

    return (
        <>
            <Button
                ref={buttonRef}
                color="secondary"
                variant="outlined"
                startIcon={<AddIcon fontSize="small" />}
                onClick={handleOpen}
                children="Add"
                {...buttonProps}
            />

            <Menu
                anchorEl={buttonRef.current}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={parentOpen ?? open}
                onClose={handleClose}
            >
                {actions.map((props, i) => (
                    <Action key={i} {...props} />
                ))}
            </Menu>
        </>
    )
}

function Action({ hide = false, name, Icon, description, onClick }) {
    if (hide) return null
    const classes = useStyles()
    return (
        <MenuItem key={name} onClick={onClick}>
            <ListItemIcon className={classes.icon}>
                <Icon />
            </ListItemIcon>
            <ListItemText>{name}</ListItemText>
            <Tooltip title={description}>
                <HelpIcon sx={{ ml: 1, fontSize: '14px' }} />
            </Tooltip>
        </MenuItem>
    )
}
