import { ChartType, DataGroup, DataResponse, InlineWidget, Widget } from 'genesis-suite/types/visualTypes'
import produce from 'immer'
import { isEqual } from 'lodash'
import { dataSourceIsEqual } from '../../edit_widget/utils'
import isRawSeries from './isRawSeries'
import makeBaseDataRequest from './makeBaseDataRequest'

/** See if next config is a simple transformation (values/series reorder or sort or delete) of current config and transform data */
export default function makeTransformedData(
    currentConfig: Widget | InlineWidget,
    currentData: DataResponse,
    nextConfig: Widget
): DataResponse {
    if (!currentConfig || !currentData) return
    if (
        currentConfig.type === ChartType.TEXT ||
        currentConfig.type === ChartType.FORM ||
        currentConfig.type === ChartType.WEBVIEW ||
        currentConfig.type === ChartType.TREE ||
        currentConfig.type === ChartType.STATUS_TRACKER ||
        currentConfig.type === ChartType.FLOWCHART ||
        currentConfig.type === ChartType.SEARCH ||
        currentConfig.type === ChartType.MODULE_SELECTOR ||
        currentConfig.type === ChartType.FAVORITES ||
        currentConfig.type === ChartType.SPACIAL_MAP ||
        currentConfig.type === ChartType.CALCULATOR ||
        currentConfig.type === ChartType.RECOMMENDED_WIDGET
    )
        return
    if (!isMetaEqual(currentConfig, nextConfig)) return
    if (currentConfig.type !== nextConfig.type) return
    if (currentConfig.series?.length !== nextConfig.series?.length) return
    if (
        currentConfig.type === ChartType.TABLE &&
        nextConfig.type === ChartType.TABLE &&
        currentConfig.series?.[0].pageSize !== nextConfig.series?.[0].pageSize
    )
        return

    const currentSeries = makeBaseDataRequest(currentConfig).series
    const nextSeries = makeBaseDataRequest(nextConfig)?.series
    if (!nextSeries) return

    const isMulti =
        currentConfig.type === ChartType.BOX_PLOT ||
        currentConfig.type === ChartType.BULLET ||
        currentConfig.type === ChartType.COMBO ||
        currentConfig.type === ChartType.MAP
    const orders: number[] = []
    let removed = 0

    if (isMulti) {
        for (let i = 0; i < currentSeries.length; i++) {
            const index = nextSeries.findIndex((s, index) => {
                if (orders.indexOf(index) > -1) return false
                if (s.subSeriesField?.name !== currentSeries[i].subSeriesField?.name) return false

                return s.values.some(v => currentSeries[i].values.every(cV => dataSourceIsEqual(v, cV)))
            })
            if (index === -1) {
                removed++
                continue
            }

            // not a transform - value added
            if (nextSeries[index].values.length > currentSeries[i].values.length) return

            orders.push(index)
        }

        // not a transform - series added
        if (nextSeries.length + removed !== currentSeries.length) return

        return orders.map(o => currentData[o])
    } else {
        const currentValues = currentSeries[0].values
        const currentSubSeries = currentSeries[0].subSeriesField
        const nextValues = nextSeries[0].values
        const nextSubSeries = nextSeries[0].subSeriesField
        if (currentSubSeries?.name !== nextSubSeries?.name) return

        for (let i = 0; i < currentValues.length; i++) {
            const index = nextValues.findIndex(v => dataSourceIsEqual(v, currentValues[i]))
            if (index === -1) {
                removed++
                continue
            }

            orders.push(index + removed)
        }

        // not a transform - value added
        if (nextValues.length + removed !== currentValues.length) return

        const isRaw = currentConfig.series.every(isRawSeries)
        if (isRaw) {
            return produce(currentData, draft => {
                draft[0].data[0].rawData = draft[0].data[0].rawData.map(row => orders.map(i => row[i]))
            })
        }

        const aggregateLevels = currentConfig.categories?.length ?? 0 + (currentSeries[0].subSeriesField ? 1 : 0)
        const sorter = (data: DataGroup, currentLevel: number): DataGroup => {
            if (currentLevel < aggregateLevels)
                return { ...data, children: data.children.map(c => sorter(c, currentLevel + 1)) }

            return { ...data, aggregatedData: orders.map(o => data.aggregatedData?.[o]) }
        }

        return [{ ...currentData[0], data: currentData[0].data.map(g => sorter(g, 1)) }]
    }
}

function isMetaEqual(a: Widget, b: Widget) {
    const aChunk = [a.categories, a.filters, a.id, a.appName, a.moduleId]
    const bChunk = [b.categories, b.filters, b.id, b.appName, b.moduleId]
    return isEqual(aChunk, bChunk)
}
