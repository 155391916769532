import { Box, Typography } from '@mui/material'
import { diff } from 'deep-object-diff'
import { capitalize, isEmpty } from 'lodash'
import moment from 'moment'

import { DraftAction, VisualObject } from 'genesis-suite/types/visualTypes'

interface Props {
    before?: VisualObject
    after: VisualObject
}

export default function DisplayDiff({ before, after }: Props) {
    const rows = makeFormattedOutput(after, before)

    return (
        <Box overflow="auto">
            {rows?.map((row, index) => (
                <Row key={`${index}-${row.property}`} {...row} />
            ))}
        </Box>
    )
}

function Row({ action, property, previousValue, tab, value }: RowDisplay) {
    const backgroundColor =
        action === DraftAction.ADDED
            ? '#8BDC87'
            : action === DraftAction.UPDATED
            ? '#A0B8DF'
            : action
            ? '#DFA5A0'
            : undefined

    return (
        <Box sx={{ pl: tab + 1, display: 'flex', gap: 1 }} style={{ backgroundColor }}>
            <Typography sx={{ textDecoration: action === DraftAction.REMOVED ? 'line-through' : undefined }}>
                {property ? `${formatProperty(property)}: ` : null}
                {value}
            </Typography>
            {action === DraftAction.UPDATED && (
                <Typography sx={{ textDecoration: 'line-through' }}>{previousValue}</Typography>
            )}
        </Box>
    )
}

function formatProperty(property) {
    const withSpaces = property.replace(/([A-Z])/g, ' $1')
    return capitalize(withSpaces)
}

interface RowDisplay {
    action?: DraftAction
    property?: string
    /** if action is updated, the previous value */
    previousValue?: string
    /** how far to tab content over */
    tab: number
    value: string
}

function makeFormattedOutput(after: VisualObject, before?: VisualObject): RowDisplay[] {
    if (!after) return

    const rows: RowDisplay[] = []

    function makeRows(config: object, changes?: object, baseKeys = [], _action?: DraftAction) {
        function makeRow(action, changedValue, keys, property, value) {
            const tab = baseKeys.length
            if (typeof value === 'object') {
                const isArray = Array.isArray(value)
                rows.push({ action, property, tab, value: isArray ? '[' : '{' })
                makeRows(value, changedValue, keys, action)
                rows.push({ action, tab, value: isArray ? ']' : '}' })
            } else {
                rows.push({
                    action,
                    property,
                    tab,
                    ...(action === DraftAction.UPDATED ? { previousValue: value, value: changedValue } : { value }),
                })
            }
        }

        // Check for added
        if (changes) {
            for (const [property, value] of Object.entries(changes)) {
                if (property in config) continue

                const keys = [...baseKeys, property]
                if (hiddenKeys.includes(keys.join('.'))) continue

                makeRow(DraftAction.ADDED, undefined, keys, property, value)
            }
        }

        // Check for updated/removed
        for (const [property, value] of Object.entries(config)) {
            let action = _action
            const keys = [...baseKeys, property]
            if (hiddenKeys.includes(keys.join('.'))) continue

            const changedValue = changes?.[property]
            const hasDiff = changes && property in changes && typeof changedValue !== 'object'
            if (!action && hasDiff) {
                action = changedValue === undefined ? DraftAction.REMOVED : DraftAction.UPDATED
            }

            makeRow(action, changedValue, keys, property, value)
        }
    }

    function formatConfig(config, isArray = false) {
        if (isArray) {
            return config.map((value, key) => {
                const formattedValue = formatValue(key, value)
                if (typeof formattedValue === 'object') return formatConfig(value, Array.isArray(formattedValue))
                return formattedValue
            })
        } else {
            return Object.entries(config).reduce((acc, [key, value]) => {
                const formattedValue = formatValue(key, value)
                if (isEmpty(value)) return acc
                if (typeof formattedValue === 'object')
                    return { ...acc, [key]: formatConfig(value, Array.isArray(formattedValue)) }
                return { ...acc, [key]: formattedValue }
            }, {})
        }
    }

    if (before) {
        const beforeFormatted = formatConfig(before)
        const changes = diff(beforeFormatted, formatConfig(after))
        makeRows(beforeFormatted, changes)
    } else {
        makeRows(formatConfig(after))
    }

    return rows
}

function formatValue(key, value) {
    switch (key) {
        case 'createdAt':
        case 'updatedAt':
            return moment(value).calendar()
        case 'createdBy':
        case 'updatedBy':
            return value ? `${value.firstName} ${value.lastName}` : undefined
        case 'field':
            return value?.name ?? value
        case 'service':
            return value.name ? `${value?.name} (${value?.type})` : value
        default:
            if (typeof value === 'boolean' || typeof value === 'number') return String(value)
            return value
    }
}

const hiddenKeys = [
    '__v',
    '_id',
    'appName',
    'createdAt',
    'draft',
    'id',
    'meta',
    'moduleId',
    'revision',
    'thumbnail',
    'version',
]
