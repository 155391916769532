import { insightTypes, applicationTypes } from '../actions/types'

const initialState = {
    list: null,
    isPending: false,
    appName: null,
}

export default (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case insightTypes.INSIGHTS_REQUEST:
            return { ...state, isPending: true }
        case insightTypes.INSIGHTS_SUCCESS:
            return { isPending: false, list: payload.insights, appName: payload.appName }
        case insightTypes.INSIGHTS_FAILURE:
            return { ...state, isPending: false }
        case applicationTypes.APPLICATION_SELECT:
            return initialState
        default:
            return state
    }
}
