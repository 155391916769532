import { interactionTypes, appearanceTypes } from '../types'
import { dialogCreators } from './dialog.creators'
import MobileProfileContainer from '../../containers/MobileProfileContainer'
import { interactionSelectors } from '../../selectors'

const changeProfileIndex = value => ({ type: interactionTypes.CHANGE_PROFILE_INDEX, payload: { value } })

const openDetails = (widgetConfig, context) => ({
    type: interactionTypes.OPEN_WIDGET_DETAILS,
    payload: { widgetConfig, context },
})

const openProfile = (isMobile, nodeName, field, value) => dispatch => {
    dispatch({ type: interactionTypes.OPEN_PROFILE, payload: { nodeName, field, value } })
    if (isMobile) dispatch(dialogCreators.showDialog('profiles', MobileProfileContainer, { hideTitle: true }))
    else dispatch({ type: appearanceTypes.OPEN_RIGHT_NAV, payload: { tab: 'profile' } })
}

const pushProfile = (nodeName, field, value) => ({
    type: interactionTypes.PUSH_PROFILE,
    payload: { nodeName, field, value },
})

const toggleProfileEdit = edit => (dispatch, getState) => {
    const isEditing = interactionSelectors.getIsEditingProfile(getState())
    if (edit !== isEditing) dispatch({ type: interactionTypes.TOGGLE_PROFILE_EDIT })
}

const hideDetails = () => ({ type: interactionTypes.CLOSE_WIDGET_DETAILS })

const setTrendLines = (trendlineSeries, widgetId) => ({
    type: interactionTypes.SET_TRENDLINES,
    payload: { trendlineSeries, widgetId },
})

export const interactionCreators = {
    changeProfileIndex,
    openDetails,
    openProfile,
    pushProfile,
    toggleProfileEdit,
    hideDetails,
    setTrendLines,
}
