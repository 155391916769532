import { Typography } from '@mui/material'

import { ChartType } from 'genesis-suite/types/visualTypes'
import { lazy } from 'react'
import { WidgetProps } from '../../types/WidgetTypes'
import DynamicTable from '../DynamicTable/DynamicTable'
import LabelWidget2 from './LabelWidget2'
import WebviewWidget from './WebviewWidget'
import FormWidget from './form/FormWidget'
import MapWidget2 from './map/MapWidget2'
import StatusTrackerWidget from './statusTracker/StatusTrackerWidget'
import TableWidget2 from './table/TableWidget2'
import TextWidget from './text/TextWidget'
import TreeWidget from './tree/TreeWidget'
import SuspenseLoader from '../SuspenseLoader'

const HighchartsComponent2 = lazy(() => import('./highcharts/HighchartsComponent2'))

export default function WidgetVisual2({ config, ...rest }: WidgetProps) {
    switch (config.type) {
        case ChartType.BOX_PLOT:
        case ChartType.BULLET:
        case ChartType.COMBO:
        case ChartType.HEATMAP:
        case ChartType.PACKED_BUBBLE:
        case ChartType.PIE:
        case ChartType.SORTED:
            return <SuspenseLoader>
                <HighchartsComponent2 config={config} {...rest} />
            </SuspenseLoader>
        case ChartType.LABEL:
            return <LabelWidget2 config={config} {...rest} />
        case ChartType.TABLE:
            return config.dynamic || config.collapsable ? (
                <DynamicTable config={config} {...rest} />
            ) : (
                <TableWidget2 config={config} {...rest} />
            )
        case ChartType.FORM:
            return <FormWidget config={config} {...rest} />
        case ChartType.TEXT:
            return <TextWidget config={config} />
        case ChartType.WEBVIEW:
            return <WebviewWidget config={config} />
        case ChartType.MAP:
            return <MapWidget2 config={config} {...rest} />
        case ChartType.TREE:
            return <TreeWidget config={config} {...rest} />
        case ChartType.STATUS_TRACKER:
            return <StatusTrackerWidget config={config} {...rest} />
        default:
            return <Typography>Widget not supported :/</Typography>
    }
}
