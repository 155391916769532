import { formsService } from '../../../lib/services'

export interface getFileViewerDocsParams {
    fileIds?: string[]
    fileBlobs?: {
        file: Blob
        name?: string
    }[]
    fileURIs?: {
        uri: string
        name?: string
    }[]
    appName?: string
}

export const getFileViewerDocs = ({
    fileIds = [],
    fileBlobs = [],
    fileURIs = [],
    appName,
}: getFileViewerDocsParams): Promise<Array<any>> => {
    const getDocAsync = async (fileId: string) => {
        const blob = await formsService.getAttachmentBlob(fileId, appName)
        const meta = await formsService.getAttachmentMeta(fileId, appName)

        if (!blob) throw new Error(`An error occurred fetching file ${meta ? meta.Name : fileId} data.`)

        return {
            uri: window.URL.createObjectURL(blob),
            fileName: meta?.Name || fileId,
        }
    }

    const promises: Array<Promise<any>> = []
    if (fileIds.length > 0)
        for (let index = 0; index < fileIds.length; index++) {
            promises.push(getDocAsync(fileIds[index]))
        }

    const docs: Array<any> = []
    if (fileBlobs.length > 0)
        for (let index = 0; index < fileBlobs.length; index++) {
            docs.push({
                uri: window.URL.createObjectURL(fileBlobs[index].file),
                fileName: fileBlobs[index]?.name || `File ${index}`,
            })
        }
    if (fileURIs.length > 0)
        for (let index = 0; index < fileURIs.length; index++) {
            docs.push({
                uri: fileURIs[index].uri,
                fileName: fileURIs[index]?.name || `File ${index}`,
            })
        }

    return Promise.all(promises).then(results => {
        return [...docs, ...results]
    })
}
