import { useEffect } from 'react'
import { v4 as uuid } from 'uuid'
import makeStyles from '@mui/styles/makeStyles'
import { Box, Button, Typography } from '@mui/material'

import { Property } from 'genesis-suite/types/networkTypes'
import { DragItem, DragList } from 'genesis-suite/components'
import { ComparisonOperator, FormConditionalFormat } from 'genesis-suite/types/visualTypes'
import { CondFormat } from './CondFormat'

const useStyles = makeStyles(({ spacing }) => ({
    dragList: { height: 'calc(100% - 28px)', overflow: 'auto' },
}))

interface CondFormatProps {
    properties: Property[]
    conditionalFormats: FormConditionalFormat[]
    onChange: (formats: FormConditionalFormat[]) => void
    onAdd: (format: any) => void
    onDelete: (key: string) => void
}

export default function CondFormatBuilder({
    onAdd,
    onDelete,
    onChange,
    properties,
    conditionalFormats,
}: CondFormatProps) {
    const classes = useStyles({})

    useEffect(() => {
        if (conditionalFormats.length && !conditionalFormats.some(cf => cf.key)) {
            const nextFormats = conditionalFormats.map(cf => (cf.key ? cf : { ...cf, key: uuid() }))
            onChange(nextFormats)
        }
    }, [conditionalFormats, onChange])

    const handleChange = (condFormat: FormConditionalFormat, index: number) => {
        const nextFormats = [...conditionalFormats]
        nextFormats.splice(index, 1, condFormat)
        onChange(nextFormats)
    }

    const defaultCF: FormConditionalFormat = {
        key: uuid(),
        format: {
            backgroundColor: '#FFFFFF',
            borderColor: '#000000',
            fontColor: '#000000',
        },
        property: null,
        rule: ComparisonOperator.EQUAL,
        target: null,
    }

    return (
        <Box height="100%">
            <Box mb={1} display="flex" justifyContent="space-between">
                <Typography display="inline">Drag to reorder. The first rule hit will apply.</Typography>
                <Button color="secondary" variant="contained" onClick={() => onAdd(defaultCF)}>
                    Add Rule
                </Button>
            </Box>
            <DragList
                droppableContainerProps={{ className: classes.dragList }}
                onDragEnd={onChange}
                items={conditionalFormats}
            >
                {conditionalFormats.map((c, i) => (
                    <DragItem key={c.key} itemId={c.key} index={i}>
                        <CondFormat
                            condFormat={c}
                            properties={properties}
                            onDelete={() => onDelete(c.key)}
                            onChange={change => handleChange(change, i)}
                        />
                    </DragItem>
                ))}
            </DragList>
        </Box>
    )
}
