import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'

import persistedReducer from '../reducers/persist.reducer'
import rootReducer from '../reducers/root.reducer'
import historyContext from './history'
import { amplitudeMiddleware } from './middleware'

const { createReduxHistory, routerMiddleware } = historyContext
let reducer
if (process.env.REACT_APP_ELECTRON) reducer = rootReducer
else reducer = persistedReducer

const initialState = {}
const enhancers = []
let middleware = [thunk, routerMiddleware]
if (amplitudeMiddleware) middleware = [...middleware, amplitudeMiddleware]

if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension())
    }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers)

const store = createStore(reducer, initialState, composedEnhancers)

export default store
export const history = createReduxHistory(store)
