import React from 'react'
import { Box, TextField } from '@mui/material'
import { useDebouncedCallback } from 'use-debounce'

import { Property } from 'genesis-suite/types/networkTypes'
import { FormColumn } from 'genesis-suite/types/visualTypes'
import FormFieldBuilder from './FormFieldBuilder'

interface ColumnBuilderProps {
    column: FormColumn
    properties: Property[]
    onChange: (column: FormColumn) => void
    className?: string
}

export default function ColumnBuilder({ className, column, onChange, properties }: ColumnBuilderProps) {
    const { title, property } = column
    const handleTextChange = useDebouncedCallback((text: string, key: string) => {
        onChange({ ...column, [key]: text })
    }, 200)

    return (
        <Box className={className}>
            <Box display="flex">
                <TextField
                    disabled
                    label="Property"
                    value={property.name}
                    style={{ marginRight: 8 }}
                    helperText="Property cannot be changed in a table form"
                />
                <TextField disabled label="Type" value={property.semanticType.baseDataType} />
            </Box>
            <TextField label="Title" defaultValue={title} onChange={e => handleTextChange(e.target.value, 'title')} />
            <FormFieldBuilder properties={properties} field={column} onChange={onChange} />
        </Box>
    )
}
