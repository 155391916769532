import { Box, Typography } from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { ChopText, DebouncedTextField } from 'genesis-suite/components'
import { DashboardNodeFilter } from 'genesis-suite/types/visualTypes'
import { useIsMobile } from '../../hooks/useIsMobile'
import { searchService } from '../../lib/services'
import { applicationSelectors } from '../../selectors'
import AsyncResourceValueSelect from '../AsyncResourceValueSelect'
import FilterOperatorButton, { isSelect } from './FilterOperatorButton2'

const labelStyle = { fontWeight: 'bold', display: 'inline', mr: 0.5 }

interface Props {
    context: any
    filter: DashboardNodeFilter
    onChange: (filter: DashboardNodeFilter) => void
}

export default function ContextFilterInput({ context, filter, onChange }: Props) {
    const {
        clickRangeName,
        displayPropertyName,
        filters,
        isMulti,
        isTemporal,
        keyPropertyName,
        nodeName,
        operator,
        range,
        title,
        values = [],
    } = filter

    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const isMobile = useIsMobile()

    const [optionCount, setOptionCount] = useState(null)
    const [totalOptionCount, setTotalOptionCount] = useState(null)

    const [clickRangeValues, setClickRangeValues] = useState(null)
    useEffect(() => {
        if (!clickRangeName) {
            if (clickRangeValues) setClickRangeValues(null)
            return
        }

        searchService.getRangeFilterBoundaries(clickRangeName, appName).then(({ Min: min, Max: max }) => {
            if (!min || !max) return
            setClickRangeValues({ min, max })
        })
    }, [appName, clickRangeName])

    let selectedLabel = ''
    let input
    if (isTemporal) {
        if (range && (range.min || range.max))
            selectedLabel = `(${moment(range.max).diff(moment(range.min), 'days') + 1} days)`
        else if (clickRangeValues)
            selectedLabel = `(${moment(clickRangeValues.max).diff(moment(clickRangeValues.min), 'days') + 1} days)`

        // TODO - support date filter using mui DataPicker
        input = <p>Dates are not supported by builder</p>
    } else if (isSelect(operator)) {
        selectedLabel =
            optionCount != null
                ? `(${values.length.toLocaleString()}/${optionCount}${totalOptionCount > optionCount ? '+' : ''})`
                : '(...)'

        input = (
            <AsyncResourceValueSelect
                resourceName={nodeName}
                keyPropertyName={keyPropertyName}
                filters={filters}
                networkFilters={context.NetworkFilters?.map(f => f.split('.')[1])}
                displayPropertyName={displayPropertyName}
                onOptions={(options, totalOptionCount) => {
                    setOptionCount(options.length)
                    setTotalOptionCount(totalOptionCount)
                }}
                value={isMulti ? values ?? [] : values[0] ?? null}
                onChange={(e, values) =>
                    onChange({ ...filter, values: (isMulti ? values : values ? [values] : []) as any })
                }
                multiple={isMulti}
            />
        )
    } else {
        input = (
            <DebouncedTextField
                fullWidth
                size="small"
                margin="none"
                variant="outlined"
                onFocus={e => e.target.select()}
                value={values[0]?.value ?? ''}
                onChange={value => onChange({ ...filter, values: value === '' ? [] : [{ label: value, value }] })}
            />
        )
    }

    return (
        <Box sx={{ flex: 1, minWidth: isMobile ? '100%' : '45%' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <ChopText showEllipsis tooltipProps={{ placement: 'top' }} sx={labelStyle}>
                    {title}
                </ChopText>
                <Typography sx={labelStyle}>{selectedLabel}</Typography>
                <Box flex={1} />
                {!isTemporal && (
                    <FilterOperatorButton value={operator} onChange={v => onChange({ ...filter, operator: v })} />
                )}
            </Box>

            {input}
        </Box>
    )
}
