import services from 'genesis-suite/services'

import userManager from './userManager'
import store from '../store/store'
import { authCreators } from '../actions/creators'
import { BaseUrls } from 'genesis-suite/services/types'

export const baseUrls: BaseUrls = {
    visor: window.TADA_APIS.VISOR,
    integration: window.TADA_APIS.INTEGRATION,
    visuals: window.TADA_APIS.VISUALS,
    forms: window.TADA_APIS.FORMS,
    collab: window.TADA_APIS.COLLAB,
    spatial: window.TADA_APIS.SPATIAL,
    tadaai: window.TADA_APIS.TADA_AI,
    message: window.TADA_APIS.MESSAGE,
    platform: window.TADA_APIS.PLATFORM,
}

const stagingBaseUrls = {
    visor: window.TADA_APIS.STAGING_VISOR,
    integration: window.TADA_APIS.STAGING_INTEGRATION,
}

const getAccessKey = () => userManager.getUser().then(user => user.profile.TADAAccessKey as string)
const getStagingAccessKey = () => store.getState().deployment.mode.accessKey
const onUnauthorized = () => store.dispatch(authCreators.expireSession())
const getAccessToken = () => userManager.getUser().then(user => user.access_token)

const {
    announcementService,
    applicationService,
    architectureService,
    authService,
    definitionService,
    formsService,
    insightService,
    logService,
    modelService,
    moduleService,
    notificationService,
    platformService,
    scenarioService,
    searchService,
    spatialService,
    userService,
    widgetService,
    visualService,
    uploadService,
    treeService,
    reportService,
    filterService,
    chatService,
    backupService,
    aiService,
    iconService,
    messageService,
} = services({ baseUrls, getAccessKey, onUnauthorized, getAccessToken })

const { commentService, taskService } = services({ baseUrls, getAccessKey, getAccessToken })

const { widgetService: stagingWidgetService, authService: stagingAuthService } = services({
    baseUrls: stagingBaseUrls,
    getAccessKey: getStagingAccessKey,
})

export {
    announcementService,
    applicationService,
    architectureService,
    authService,
    commentService,
    definitionService,
    formsService,
    insightService,
    logService,
    modelService,
    moduleService,
    notificationService,
    platformService,
    scenarioService,
    searchService,
    spatialService,
    stagingAuthService,
    stagingWidgetService,
    taskService,
    userService,
    widgetService,
    visualService,
    uploadService,
    treeService,
    reportService,
    filterService,
    chatService,
    backupService,
    aiService,
    iconService,
    messageService,
}
