import { useContext } from 'react'
import { Typography } from '@mui/material'

import { PropertyType } from 'genesis-suite/types/architectureTypes'
import { Aggregation, ChartType, SeriesConfig, TableConfig } from 'genesis-suite/types/visualTypes'
import { ConfigContext } from '../ConfigContext'
import DnDPropertySelector from './DnDPropertySelector'
import { getDataFieldProperty } from '../utils'
import { DisplayProperty, DragField, FieldPointer } from '../builderTypes'
import SortEditor from '../config_fields/SortEditor'
import useWidgetColors from '../../../hooks/useWidgetColors'
import { useSemanticTypeById } from '../../../hooks/useSemanticTypes'

interface Props {
    label?: string
}

export default function SubSeriesSelector({ label }: Props) {
    const semanticTypeById = useSemanticTypeById()
    const { dispatch, resources, selectedField, ...rest } = useContext(ConfigContext)
    const config = rest.config as SeriesConfig

    const isTable = config.type === ChartType.TABLE
    const activeSeriesIndex = selectedField?.type === 'series' ? selectedField.index : 0

    const activeSeries = config.series[activeSeriesIndex]
    const { properties } = resources.byId[activeSeries?.service.id] ?? {}
    const { subSeries } = activeSeries || {}
    const colors = useWidgetColors()

    const handleAdd = async (dragField: DragField) => {
        const { pointer, type, ...field } = dragField
        const to: FieldPointer = { type: 'subseries', index: activeSeriesIndex }
        const source = { field, aggregation: Aggregation.NONE }
        const options = dragField.pointer
            ? { operation: 'swap' as const, from: pointer }
            : { operation: 'new' as const, source }

        dispatch({ type: 'UPDATE_SELECTED_PROPERTY', payload: { to, limit: 1, ...options, semanticTypeById } })

        //if a pivot value is added in a dynamic table, the table should be collapsable
        if (config.type === ChartType.TABLE && (config as TableConfig).dynamic)
            dispatch({ type: 'UPDATE_CONFIG', payload: { collapsable: true } })
    }

    const handleRemove = () => {
        //if pivot value is removed in a collapsable table and there is one or less categories, the table is no more collapsable
        if (config.type === ChartType.TABLE && (config as TableConfig).collapsable && config.categories.length <= 1) {
            return dispatch({
                type: 'UPDATE_CONFIG',
                payload: { collapsable: false, series: [{ ...activeSeries, subSeries: undefined }] },
            })
        }

        dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: { ...activeSeries, subSeries: undefined } })
    }

    let displayField: DisplayProperty
    if (subSeries) {
        const { displayName, semanticType } = getDataFieldProperty(subSeries.field, properties)
        displayField = {
            type: semanticType?.type,
            field: subSeries.field,
            service: { ...activeSeries.service },
            title: displayName,
            pointer: { type: 'subseries', index: activeSeriesIndex },
            selected: false,
            aggregation: Aggregation.NONE,
            color: colors[0],
        }
    }

    return (
        <div>
            <DnDPropertySelector
                label={label}
                accept={!isTable || config.categories.length ? [PropertyType.DEFINING, PropertyType.QUALITATIVE] : []}
                limit={1}
                onAdd={handleAdd}
                onRemove={handleRemove}
                properties={displayField ? [displayField] : []}
            >
                {displayField && (
                    <div>
                        <Typography variant="caption">Sorting</Typography>
                        <SortEditor pointer={{ type: 'subseries', index: activeSeriesIndex }} />
                    </div>
                )}
            </DnDPropertySelector>
        </div>
    )
}
