import { Box } from '@mui/material'
import { useContext } from 'react'

import { useIsMobile } from '../../../hooks/useIsMobile'
import { TourTag } from '../../../lib/tourSteps'
import InteractionToggle from '../../InteractionToggle'
import NewWidgetButton from '../../NewWidgetButton'
import {
    ApprovalSubmitButton,
    DashboardDevicePreviewToggle,
    DashboardRowResizingAction,
    EditFiltersAction,
    EditTrigger,
    LaunchTVMode,
    LayoutToggle,
    SlideShowToggle,
} from './actions'
import DashboardFilterAction from './actions/DashboardFilterAction'
import { DashboardContext } from './DashboardContext'
import RestoreHiddenWidgets from './RestoreHiddenWidgets'
import { DashboardAction } from './types'

export default function DashboardActions() {
    const { editing } = useContext(DashboardContext)

    return (
        <Box display="flex" gap={1}>
            {editing && (
                <>
                    <RestoreHiddenWidgets />
                    <NewWidgetButton />
                </>
            )}

            <Box
                data-tour={TourTag.PerspectiveOptions}
                sx={{
                    alignSelf: 'center',
                    bgcolor: editing ? 'primary.main' : 'background.sideNav',
                    color: editing ? '#ffffff' : 'text.primary',
                    borderRadius: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    p: 0.25,
                }}
            >
                <Actions />
            </Box>
        </Box>
    )
}

function Actions() {
    const { actions, editable, editing } = useContext(DashboardContext)
    const isMobile = useIsMobile()

    if (actions)
        return (
            <>
                {actions.map(
                    a =>
                        ((
                            {
                                ['draft-status']: <ApprovalSubmitButton key="draft-status" />,
                                ['device-toggle']: <DashboardDevicePreviewToggle key="device-toggle" />,
                                ['edit-filters']: <EditFiltersAction key="edit-filters" />,
                                ['edit-trigger']: <EditTrigger key="edit-trigger" />,
                                ['filters']: <DashboardFilterAction key="filters" />,
                                ['interaction-toggle']: <InteractionToggle key="interaction-toggle" />,
                                ['layout-toggle']: <LayoutToggle key="layout-toggle" />,
                                ['row-resizing']: <DashboardRowResizingAction key="row-resizing" />,
                                ['slideshow-toggle']: <SlideShowToggle key="slideshow-toggle" />,
                                ['tv-launch']: <LaunchTVMode key="tv-launch" />,
                            } as { [key in DashboardAction]: JSX.Element }
                        )[a])
                )}
            </>
        )

    if (editing)
        return (
            <>
                <DashboardDevicePreviewToggle />
                <EditFiltersAction />
                <DashboardRowResizingAction />
                <ApprovalSubmitButton />
                <EditTrigger />
            </>
        )

    return (
        <>
            {editable && !isMobile && <EditTrigger />}
            <InteractionToggle />
            <LayoutToggle />
            <DashboardFilterAction />
            {/* <SlideShowToggle /> */}
            <LaunchTVMode />
            {/* TODO CollaborationMessengerWeb */}
        </>
    )
}
