import AddIcon from '@mui/icons-material/Add'
import { Box, Button, CircularProgress, LinearProgress, Typography, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { DataGridPro, GridColDef, GridOverlay, GridRow, GridRowProps } from '@mui/x-data-grid-pro'
import { Dispatch, memo, SetStateAction, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useDrag } from 'react-dnd'
import Measure from 'react-measure'
import { useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'

import { SortBy } from 'genesis-suite/types/utilTypes'
import { Widget, WidgetRequestFilter } from 'genesis-suite/types/visualTypes'
import { sleep } from 'genesis-suite/utils'
import { getComparator, sortData } from '../../lib/manageUtils'
import { moduleService } from '../../lib/services'
import { authSelectors } from '../../selectors'
import { ModuleViewsContext } from '../manage_module_views/ModuleViewsContext'
import { NewDragItem } from '../manage_module_views/ViewTypes'
import { authorKey, createdBySortKey, makeColumns, makeRows, RowData, useColumnWidths } from './utils'

const ROW_HEIGHT = 40

const useStyles = makeStyles(theme => ({
    tableWrapper: { flex: 1 },
    cover: { display: 'flex', flexDirection: 'column' },
    cell: { '&:focus': { outline: 'none !important' } },
    row: { cursor: 'pointer' },

    tooltip: {
        padding: '0 !important',
        borderRadius: '0 !important',
        border: '0 !important',
        backgroundColor: 'transparent !important',
    },
}))

interface Props {
    /** (default true) drag row items */
    draggable?: boolean
    filter: WidgetRequestFilter
    highlightId: string
    loading: boolean
    onNewButton?: () => void
    onOpen?: (id: string) => void
    onRefresh?: (id: string) => void
    onScrollEnd: () => void
    onSelect: (id: string) => void
    onSort: Dispatch<SetStateAction<SortBy<Widget>>>
    onUpdateFilter: (updated: Partial<WidgetRequestFilter>) => void
    sortBy: SortBy<Widget>
    views: Array<Widget>
}

export default function WidgetsTable({ draggable = true, ...rest }: Props) {
    const { onNewView, onCopyToUsersViews } = useContext(ModuleViewsContext)
    return <MemoizedTable {...rest} draggable={draggable} {...(draggable && { onNewView, onCopyToUsersViews })} />
}

interface RenderProps extends Props {
    onNewView?: (item: NewDragItem, keep: boolean, isUsers: boolean) => Promise<boolean>
    onCopyToUsersViews?: (id: string, forceCopy?: boolean) => Promise<void>
}

const MemoizedTable = memo(RenderWidgetsTable)

function RenderWidgetsTable({
    draggable,
    filter,
    highlightId,
    loading,
    onCopyToUsersViews,
    onUpdateFilter,
    onNewButton,
    onNewView,
    onOpen,
    onRefresh,
    onScrollEnd,
    onSelect,
    onSort,
    sortBy,
    views,
}: RenderProps) {
    const userId = useSelector(authSelectors.getUserId)
    const isPowerUser = useSelector(authSelectors.getIsPowerUser)

    const { columnWidths, setTableWidth } = useColumnWidths(isPowerUser)

    const columns = useMemo(
        () => makeColumns(columnWidths, draggable, filter, onUpdateFilter, isPowerUser, onOpen),
        [columnWidths, draggable, filter, onCopyToUsersViews, onUpdateFilter, isPowerUser, onOpen]
    )
    const data = useMemo(() => sortData(makeRows(views, userId), getComparator(sortBy)), [views, sortBy])

    const classes = useStyles({ draggable })

    return (
        <Box flex={1}>
            <Measure offset onResize={({ offset }) => setTableWidth(offset.width)}>
                {({ measureRef }) => <div ref={measureRef} className={classes.tableWrapper} />}
            </Measure>

            <Table
                {...{
                    classes,
                    columns,
                    data,
                    highlightId,
                    isPowerUser,
                    loading,
                    onNewButton,
                    onNewView,
                    onRefresh,
                    onScrollEnd,
                    onSelect,
                    onSort,
                }}
            />
        </Box>
    )
}

const Table = memo(RenderTable)

interface TableProps {
    classes: any
    columns: GridColDef[]
    data: any
    highlightId: string
    loading: boolean
    isPowerUser: boolean
    onNewButton?: RenderProps['onNewButton']
    onNewView?: RenderProps['onNewView']
    onRefresh?: RenderProps['onRefresh']
    onScrollEnd: RenderProps['onScrollEnd']
    onSelect?: (id: string) => void
    onSort: RenderProps['onSort']
}

function RenderTable({
    classes,
    columns,
    data,
    highlightId,
    isPowerUser,
    loading,
    onNewButton,
    onNewView,
    onRefresh,
    onScrollEnd,
    onSelect,
    onSort,
}: TableProps) {
    const [hoveringRow, setHoveringRow] = useState<RowData>(null)

    function handleSort(model) {
        if (!model.length) return onSort({})

        const { field, sort } = model[0]
        const fieldName = field === authorKey ? createdBySortKey : field
        onSort({ [fieldName]: sort })
    }

    return (
        <>
            <Box data-for={tooltipId} data-tip height="100%">
                <DataGridPro
                    classes={{ cell: classes.cell, columnHeader: classes.cell, row: classes.row }}
                    columns={columns}
                    components={{
                        LoadingOverlay,
                        NoRowsOverlay: onNewButton ? () => <EmptyRow onNew={onNewButton} /> : undefined,
                        Row: props => (
                            <Row
                                {...props}
                                isPowerUser={isPowerUser}
                                highlightId={highlightId}
                                onNewView={onNewView}
                                onRefresh={onRefresh}
                                onMouseEnter={() => setHoveringRow(props.row)}
                                onMouseLeave={() => setHoveringRow(null)}
                            />
                        ),
                    }}
                    onRowClick={p => onSelect(p.row.id)}
                    disableColumnFilter
                    disableColumnSelector
                    disableSelectionOnClick
                    hideFooter
                    loading={loading}
                    onRowsScrollEnd={onScrollEnd}
                    onSortModelChange={handleSort}
                    pinnedColumns={{ right: ['actions'] }}
                    rowHeight={ROW_HEIGHT}
                    rows={data}
                    rowThreshold={3}
                    scrollEndThreshold={300}
                    sortingMode="server"
                />
            </Box>

            <Thumbnail thumbnailId={hoveringRow?.thumbnail} />
        </>
    )
}

function LoadingOverlay() {
    return (
        <GridOverlay>
            <div style={{ position: 'absolute', top: 0, width: '100%', zIndex: 1 }}>
                <LinearProgress />
            </div>
        </GridOverlay>
    )
}

interface RowProps extends GridRowProps {
    isPowerUser: boolean
    highlightId: boolean
    onRefresh: (id: string) => void
    onNewView: (item: NewDragItem, keep: boolean, isUsers: boolean) => Promise<boolean>
}

function Row({ isPowerUser, highlightId, onNewView, onRefresh, ...gridRowProps }: RowProps) {
    const { id, title, isUsers } = gridRowProps.row
    const theme = useTheme()

    const [{ opacity }, drag] = useDrag<NewDragItem, NewDragItem, { opacity: number }>({
        type: 'widget',
        item: { type: 'widget', id, status: 'new', dragId: 'draft', title },
        collect: monitor => ({ opacity: monitor.isDragging() ? 0.4 : 1 }),
        end: async (dropResult, monitor) => {
            try {
                const keep = monitor.didDrop()
                await onNewView(dropResult, keep, isUsers)
                if (!keep) return

                await sleep(200)
                onRefresh(dropResult.id)
            } catch (err) {
                console.error(err)
            }
        },
    })

    const rowStyle = {
        ...(opacity !== 1 && { opacity }),
        ...(highlightId === id && { backgroundColor: theme.palette.grayscale.lighter }),
    }

    return (
        <div style={rowStyle} ref={node => (isPowerUser && onNewView ? drag(node) : undefined)}>
            <GridRow {...gridRowProps} />
        </div>
    )
}

function EmptyRow({ onNew }) {
    return (
        <Box flex={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={1}>
            <Typography>No collections with filter criteria</Typography>
            <Button color="secondary" startIcon={<AddIcon />} variant="contained" onClick={onNew}>
                Create
            </Button>
        </Box>
    )
}

const tooltipId = 'widget-table'

function Thumbnail({ thumbnailId }) {
    const classes = useStyles()
    const [thumbnail, setThumbnail] = useState<{ status: 'loading' | 'idle'; src?: string } | null>(null)

    const cancelImgReq = useRef(null)
    useEffect(() => {
        cancelImgReq.current?.()
        if (!thumbnailId) {
            if (thumbnail) setThumbnail(null)
            return
        }

        setThumbnail({ status: 'loading' })
        moduleService
            .getFiles(thumbnailId, cancel => (cancelImgReq.current = cancel))
            .then(blob => {
                if (!blob) throw new Error('Widget thumbnail not found')

                setThumbnail({ status: 'idle', src: URL.createObjectURL(blob) })
            })
            .catch(err => {
                if (err.__CANCEL__) return
                console.error(err)
            })
    }, [thumbnailId])

    if (!thumbnail) return null

    return (
        <ReactTooltip
            arrowColor="#ffffff00"
            className={classes.tooltip}
            id={tooltipId}
            place="right"
            getContent={() => null}
        >
            {thumbnail.status === 'loading' ? (
                <CircularProgress />
            ) : (
                <Box
                    alt="Widget thumbnail"
                    component="img"
                    sx={{
                        boxShadow: 2,
                        borderRadius: 1,
                        maxHeight: '300px',
                        maxWidth: '300px',
                    }}
                    src={thumbnail.src}
                />
            )}
        </ReactTooltip>
    )
}
