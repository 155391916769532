export const collaborationTypes = {
    COLLABORATION_CONNECTION_REQUEST: 'COLLABORATION_CONNECTION_REQUEST',
    COLLABORATION_CONNECTION_SUCCESS: 'COLLABORATION_CONNECTION_SUCCESS',
    COLLABORATION_CONNECTION_FAILURE: 'COLLABORATION_CONNECTION_FAILURE',
    COLLABORATION_CONNECTION_STOP: 'COLLABORATION_CONNECTION_STOP',
    COLLABORATION_COLLABORATORS: 'COLLABORATION_COLLABORATORS',
    COLLABORATION_REGISTERED: 'COLLABORATION_REGISTERED',
    COLLABORATION_UNREGISTERED: 'COLLABORATION_UNREGISTERED',
    COLLABORATION_MESSAGE_RECEIVED: 'COLLABORATION_MESSAGE_RECEIVED'
}
