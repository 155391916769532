import { Box } from '@mui/material'
import { useContext, useState } from 'react'
import GridLayout from 'react-grid-layout'
import 'react-grid-layout/css/styles.css'
import Measure from 'react-measure'
import { useSelector } from 'react-redux'
import 'react-resizable/css/styles.css'

import { DashboardDevice, LayoutDetails, Widget } from 'genesis-suite/types/visualTypes'
import { widgetSelectors } from '../../../selectors'
import EmptyPerspective from '../../EmptyPerspective'
import { DashboardContext } from './DashboardContext'
import { grabHandleStyles, gridGap, LayoutMode, makeGridLayout, mobileScale } from './dashboardUtils'
import DashboardWidget from './DashboardWidget'
import { appearanceConstants } from '../../../constants'

export default function DashboardMainWidgets() {
    const { devicePreview, editing, layout, positionWidgets, widgets } = useContext(DashboardContext)
    const [dimensions, setDimensions] = useState<{ height: number; width: number }>({ width: 0, height: 0 })
    const layoutMode = useSelector(widgetSelectors.getCurrentLayout)

    const mainWidgets = widgets.filter(w => !w.position?.top)
    const editable = editing && mainWidgets.length > 1
    const adjLayoutMode = editing ? 0 : layoutMode
    const { columnCount, rowCount } = layout
    const gridLayout = makeGridLayout(mainWidgets, adjLayoutMode, { row: rowCount, column: columnCount })
    const rowHeight = getRowHeight(layout, dimensions.height, adjLayoutMode)

    return (
        <Measure client onResize={({ client }) => setDimensions(client)}>
            {({ measureRef }) => (
                <Box
                    ref={measureRef}
                    sx={{
                        flex: 1,
                        marginLeft: `${appearanceConstants.ScrollBarWidth}px`,
                        paddingY: `${appearanceConstants.ScrollBarWidth}px`,
                        overflowX: 'hidden',
                        overflowY: 'scroll',
                        ...grabHandleStyles,
                    }}
                >
                    <GridLayout
                        autoSize={false}
                        cols={columnCount}
                        containerPadding={[0, 0]}
                        isDraggable={editable}
                        isResizable={editable}
                        layout={gridLayout}
                        margin={[gridGap, gridGap]}
                        onLayoutChange={positionWidgets}
                        preventCollision={false}
                        rowHeight={rowHeight}
                        transformScale={devicePreview === DashboardDevice.SMALL ? mobileScale : 1}
                        verticalCompact={true}
                        width={dimensions.width - 1}
                    >
                        {gridLayout?.map(l => {
                            const widget = widgets.find(w => (w as Widget).draft?.visualId === l.i || w.id === l.i)
                            return <div key={l.i}>{widget && <DashboardWidget config={widget} />}</div>
                        })}
                    </GridLayout>

                    {!mainWidgets?.length && <EmptyPerspective />}
                    {editable && <Box sx={{ height: '300%' }} />}
                </Box>
            )}
        </Measure>
    )
}

function getRowHeight(layout: LayoutDetails, height = 0, layoutMode: LayoutMode) {
    const { rowCount, rowHeight } = layout
    if (rowHeight) return rowHeight - gridGap

    const adjHeight = height - 2 * appearanceConstants.ScrollBarWidth
    const adjRowCount = layoutMode ? 2 : rowCount
    return adjHeight / adjRowCount - (gridGap * (adjRowCount - 1)) / adjRowCount
}
