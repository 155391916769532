import { debugTypes, moduleTypes } from '../actions/types'

const initialState = {
    isCaching: false,
    logStats: false,
    widgetDiagnostics: false,
    widgetCache: {},
}

export default (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case debugTypes.CACHE_WIDGET: {
            const { id, data } = payload
            return { ...state, widgetCache: { ...state.widgetCache, [id]: data } }
        }
        case debugTypes.CLEAR_WIDGET_CACHE:
        case moduleTypes.MODULE_SUCCESS:
            return { ...state, widgetCache: {} }
        case debugTypes.TOGGLE_CACHING:
            return { ...state, widgetCache: {}, isCaching: !state.isCaching }
        case debugTypes.TOGGLE_LOG_STATS:
            return { ...state, logStats: !state.logStats }
        case debugTypes.TOGGLE_WIDGET_DIAGNOSTICS:
            return { ...state, widgetDiagnostics: !state.widgetDiagnostics }
        default:
            return state
    }
}
