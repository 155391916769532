import { useContext } from 'react'
import { TextField, Autocomplete } from '@mui/material';

import { ChartType } from 'genesis-suite/types/visualTypes'
import { ConfigContext } from '../ConfigContext'
import { tablePageSize } from '../../widgets2/utils/configDefaults'

const MAX_SIZE = 2000

export default function PaginationEditor(props) {
    const { config, dispatch } = useContext(ConfigContext)

    if (config.type !== ChartType.TABLE) return null

    const value = (config.series[0]?.pageSize ?? tablePageSize).toString()

    // allow only numbers
    function handleKeyDown(e) {
        if (!/\d/.test(String.fromCharCode(e.charCode))) e.preventDefault()
    }

    function handleChange(v: string) {
        if (v === value) return

        const pageSize = Math.min(parseInt(v), MAX_SIZE)
        dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: { pageSize } })
    }

    return (
        <div>
            <Autocomplete
                freeSolo
                size="small"
                clearOnEscape
                disableClearable
                options={['200', '500', '1000']}
                renderInput={params => (
                    <TextField
                        {...params}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        label="Default page size"
                        onKeyPress={handleKeyDown}
                        onBlur={e => handleChange(e.target.value)}
                    />
                )}
                value={value}
                onChange={(e, val) => handleChange(val)}
            />
        </div>
    )
}
