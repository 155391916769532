import { useCallback, useMemo } from 'react'
import { EditRounded } from '@mui/icons-material'
import { Box, Button, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { GridColDef, GridColumnResizeParams, GridColumnHeaderParams } from '@mui/x-data-grid-pro'

import { FormData, TadaColDef, TableFormConfig, FormDataWithId } from 'genesis-suite/types/visualTypes'
import FormTitleBuilder from './builders/FormTitleBuilder'
import { BuilderProps } from './EditFormTypes'
import TableForm from '../widgets2/form/TableForm'

const useStyles = makeStyles(({ custom, palette, spacing }) => {
    const headerHeight = 50
    return {
        root: {
            height: '100%',
            display: 'flex',
            flexFlow: 'column',
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            height: headerHeight,
            paddingLeft: spacing(1),
            borderBottom: `1px solid rgba(224, 224, 224, 1)`,
        },
        headerBtn: {
            width: '100%',
            borderRadius: 0,
            fontWeight: 700,
            fontSize: 'inherit',
            padding: spacing(1.5, 1),
            '& span': {
                width: 'auto',
                minHeight: 16,
                display: 'block',
                overflow: 'hidden',
                marginRight: 'auto',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
            },
        },
        editIcon: {
            // Perfect styles and not magic numbers
            padding: 2,
            fontSize: 18,
            borderRadius: 12,
            color: palette.grayscale.lightest,
            background: palette.grayscale.dark,
        },
        formTableRoot: {
            '& .MuiDataGrid-columnsContainer': { flexDirection: 'initial' },
            '& .MuiDataGrid-columnHeader': { padding: 0, outline: 'none !important' },
        },
    }
})

interface TableViewerProps extends BuilderProps {
    data: FormDataWithId[]
    config: TableFormConfig
    onSave: (data: FormData[]) => Promise<any>
    onDelete: (data: FormDataWithId[]) => Promise<any>
    onExcelUpload: (rows: any[], tableName: string) => Promise<any>
}

export default function TableViewer({ config, onChange, data, onFieldSelect, editKey, ...rest }: TableViewerProps) {
    const classes = useStyles()
    const { title, columns } = config

    const handleClick = useCallback(
        (e, colDef) => {
            e.stopPropagation()
            e.preventDefault()
            onFieldSelect(colDef.id)
        },
        [onFieldSelect]
    )

    const createCustomColDef = (): Partial<GridColDef> => ({
        renderHeader: ({ colDef }: GridColumnHeaderParams) => {
            const { id, description, title, editable } = colDef as TadaColDef
            const selected = id === editKey
            const rootStyle = {
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                background: selected ? '#f2f2f2' : 'inherit',
            }
            return (
                <Box style={rootStyle}>
                    <Tooltip title={description || title}>
                        <Button className={classes.headerBtn} onClick={e => handleClick(e, colDef)}>
                            {title}
                        </Button>
                    </Tooltip>
                    {editable && (
                        <Tooltip title="Column is editable">
                            <EditRounded className={classes.editIcon} />
                        </Tooltip>
                    )}
                </Box>
            )
        },
    })

    const customColDef = useMemo(createCustomColDef, [columns, classes, handleClick, editKey])

    const handleWidthChange = (column: any, width: number) => {
        const nextCols = columns.map(c => {
            if (c.id === column.id) return { ...c, width }
            else return c
        })
        onChange({ columns: nextCols })
    }

    const handleReorder = (ids: string[]) => {
        const nextCols = ids.reduce((acc, id) => {
            const col = columns.find(c => c.id === id)
            if (col) acc.push(col)
            return acc
        }, [])
        onChange({ columns: nextCols })
    }

    return (
        <Box className={classes.root}>
            <Box className={classes.header}>
                <FormTitleBuilder title={title} onChange={title => onChange({ title })} />
            </Box>
            <TableForm
                data={data}
                defaults={{}}
                config={config}
                disableColumnMenu
                onExport={() => Promise.resolve()}
                disableSelectionOnClick
                onReorder={handleReorder}
                columnProps={customColDef}
                className={classes.formTableRoot}
                onColumnWidthChange={({ colDef, width }: GridColumnResizeParams) => handleWidthChange(colDef, width)}
                {...rest}
            />
        </Box>
    )
}
