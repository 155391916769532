import { AddRounded } from '@mui/icons-material'
import { Box, Button, List, Typography } from '@mui/material'
import { Spinner, SwalContext } from 'genesis-suite/components'
import { ResourceType, Scenario } from 'genesis-suite/types/networkTypes'
import { useSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { scenarioCreators } from '../../actions/creators'
import { scenarioService } from '../../lib/services'
import { applicationSelectors, scenarioSelectors } from '../../selectors'
import { EditScenario } from './EditScenario'
import ScenarioDisplay from './ScenarioDisplay'
import useScenarios from './useScenarios'
import { makeStyles } from '@mui/styles'

const newScenario: Scenario = {
    id: '',
    name: '',
    resourceType: ResourceType.NODE,
    resourceName: '',
    resourceId: '',
    criterias: [],
}

const useStyles = makeStyles(theme => ({
    icon: {
        color: theme.palette.text.primary,
    },
}))

export default function ScenarioController({ onRequestClose }) {
    const classes = useStyles()
    const [scenarios, refreshScenarios] = useScenarios()
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const activeScenarios = useSelector(scenarioSelectors.getActiveScenarios)
    const activeScenarioIds = activeScenarios.map(active => active.id)
    const [loading, setLoading] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [selectedIds, setSelectedIds] = useState(activeScenarioIds)
    const { confirm } = useContext(SwalContext)
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    const selectScenario = (scenarioToAdd: Scenario['id']) => setSelectedIds([...selectedIds, scenarioToAdd])

    const removeScenario = (scenarioToRemove: Scenario['id']) => {
        setSelectedIds(selectedIds.filter(selectedId => selectedId !== scenarioToRemove))
    }

    const handleClose = () => setShowEdit(false)

    const handleApply = async () => {
        const response = await confirm('Apply these changes? This will refresh the page.', {
            type: 'question',
        })
        if (response.dismiss) return
        dispatch(scenarioCreators.setActiveScenarios(scenarios.filter(scenario => selectedIds.includes(scenario.id))))
        onRequestClose()
    }

    const handleDelete = async (scenario: Scenario) => {
        const isActive = activeScenarioIds.includes(scenario.id)
        if (isActive) {
            const response = await confirm('This scenario is currently active. Delete it anyway?', {
                type: 'question',
            })
            if (response.dismiss) return
        }
        try {
            setLoading(true)
            await scenarioService.deleteScenario(appName, scenario.id)
            if (isActive) dispatch(scenarioCreators.removeScenario(scenario.id))
            refreshScenarios()
        } catch (err) {
            console.error(err)
            enqueueSnackbar('Failed to delete scenario', { variant: 'error' })
        } finally {
            setLoading(false)
        }
    }

    const handleDone = () => {
        refreshScenarios()
        handleClose()
    }

    return (
        <Box p={1} width="100%" height="100%">
            <Spinner
                show={scenarios === undefined || loading}
                coverOverride={<Typography variant="h6">Loading...</Typography>}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <Box
                        sx={theme => ({
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '90%',
                            '.MuiSvgIcon-root ': {
                                fill: theme.palette.text.primary,
                            },
                        })}
                    >
                        {!scenarios ? null : scenarios.length > 0 ? (
                            <List sx={{ height: '100%', width: '100%', overflow: 'auto' }}>
                                {scenarios.map(scenario => {
                                    const isSelected = selectedIds.includes(scenario.id)
                                    return (
                                        <ScenarioDisplay
                                            key={scenario.id}
                                            scenario={scenario}
                                            isSelected={isSelected}
                                            refreshScenarios={refreshScenarios}
                                            onDelete={() => handleDelete(scenario)}
                                            onScenarioClick={scenario =>
                                                isSelected ? removeScenario(scenario.id) : selectScenario(scenario.id)
                                            }
                                        />
                                    )
                                })}
                            </List>
                        ) : (
                            <Typography variant="h6">No Scenarios Created</Typography>
                        )}
                        {showEdit && <EditScenario scenario={newScenario} onClose={handleClose} onDone={handleDone} />}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'flex-end',
                            flex: 1,
                        }}
                    >
                        <Button
                            className={classes.icon}
                            color="secondary"
                            sx={theme => ({
                                px: '9px',
                                py: '6px',
                                mr: 1,
                                '.MuiSvgIcon-root ': {
                                    fill: theme.palette.text.primary,
                                },
                                '&.MuiButton-contained': {
                                    color: 'white',
                                },
                            })}
                            variant="contained"
                            size="small"
                            endIcon={<AddRounded fontSize="small" />}
                            onClick={() => setShowEdit(true)}
                        >
                            Create Scenario
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={handleApply}
                            sx={{
                                px: '9px',
                                py: '6px',
                                '&.MuiButton-contained.Mui-disabled': {
                                    color: 'white', // Change font color to white when button is disabled
                                },
                            }}
                            disabled={activeScenarioIds.sort().join(',') === selectedIds.sort().join(',')}
                        >
                            Apply
                        </Button>
                    </Box>
                </Box>
            </Spinner>
        </Box>
    )
}
