import { createSelector } from 'reselect'

const getData = s => s.userNav.data

const get = createSelector([getData], metaObj => Object.values(metaObj).sort((a, b) => a.title.localeCompare(b.title)))

// /** userNavs w/o focalPoint */
// const getViews = createSelector([getUserNavs], userNavs => userNavs?.filter(d => !Boolean(d.focalPoint)))

// /** userNavs w/ focalPoint */
// const getPerspectives = createSelector([getUserNavs], userNavs => userNavs?.filter(d => Boolean(d.focalPoint)))

// /** Private views owned by user selected to be in navigation menu */
// const getUsersNavs = createSelector([getViews, s => s.authentication?.user?.userId], (views, userId) =>
//     views.filter(v => v.inNavigation && v.createdBy.id === userId)
// )

export const userNavSelectors = {
    get,
    // getViews,
    // getUsersNavs,
    // getPerspectives,
}
