import { createSelector } from 'reselect'
import Store from '../store/storeTypes'

const get = (s: Store) => s.lockout

const getItems = createSelector(get, lockout => lockout.items)
const getStatus = createSelector(get, lockout => lockout.status)

export const lockoutSelectors = {
    getItems,
    getStatus,
}
