import { matchPath } from 'react-router-dom'
import { routePaths } from '../lib/routes'
import { filterSelectors, menuSelectors } from '.'
import { createSelector } from 'reselect'

function getTitle(state) {
    const elements = menuSelectors.getTopNav(state)

    const { widgets, router } = state
    const currentWidgetId = filterSelectors.getCurrentWidgetId(state)

    // have to manually check for params based on current location because props.match.params does not reflect global match
    const params = {
        ...matchPath(routePaths.PERSPECTIVE, router.location.pathname)?.params,
        ...matchPath(routePaths.COMPARE_PERSPECTIVE, router.location.pathname)?.params,
        ...matchPath(routePaths.ELEMENT, router.location.pathname)?.params,
        ...matchPath(routePaths.FULLSCREEN, router.location.pathname)?.params,
    }

    const { perspectiveID, widgetID, elementName } = params

    if (elementName) return elements.find(e => e.to === elementName)?.text ?? elementName

    const id = currentWidgetId || perspectiveID || widgetID

    const config = widgets.configs[id]
    if (!config) return ''

    return config.Title
}

const getDefaultPerspectiveByNodeName = createSelector(
    s => s.widgets.configs,
    allConfigs => {
        return Object.values(allConfigs).reduce((acc, config) => {
            if (config.ContainerConfig?.IsDefault) {
                acc[config.Element] = config
            }
            return acc
        }, {})
    }
)

const getPerspective = s => s.perspective

const getAutoRefresh = createSelector([getPerspective], perspective => perspective.autoRefresh)

const getShowPerspectiveSummary = createSelector([getPerspective], perspective => perspective.showPerspectiveSummary)

export const perspectiveSelectors = {
    getTitle,
    getDefaultPerspectiveByNodeName,
    getAutoRefresh,
    getShowPerspectiveSummary,
}
