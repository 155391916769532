import { useState } from 'react'
import { useSelector } from 'react-redux'

import { MenuIcon } from 'genesis-suite/components'
import { Present as PresentIcon } from 'genesis-suite/icons'
import { widgetSelectors } from '~/selectors'
import { logEvent } from '../lib/amplitudeClient'
import Slideshow from './Slideshow'

export default function SlideshowToggle({ disabled, iconProps, buttonStyle }) {
    const perspectiveId = useSelector(widgetSelectors.getCurrentPerspectiveId)
    const widgets = useSelector(s => widgetSelectors.getWidgetConfigsForPerspective(s, perspectiveId))

    const [open, setOpen] = useState(false)

    return (
        <>
            <MenuIcon
                disabled={disabled}
                icon={<PresentIcon {...iconProps} />}
                tooltip="Slideshow"
                onClick={() => {
                    logEvent('START_SLIDESHOW_FROM_PERSPECTIVE')
                    setOpen(true)
                }}
                buttonProps={{ sx: buttonStyle }}
            />

            <Slideshow open={open} onClose={() => setOpen(false)} configs={widgets} />
        </>
    )
}
