import { useDispatch, useSelector } from 'react-redux'

import { SelectPopover } from 'genesis-suite/components'
import { OneCol, TwoCol, ThreeCol, TileLayout } from 'genesis-suite/icons'
import { widgetSelectors } from '../../../../selectors'
import { widgetCreators } from '../../../../actions/creators'
import { useIsMobile } from '../../../../hooks/useIsMobile'

export default function LayoutToggle() {
    const isMobile = useIsMobile()
    const currentLayout = useSelector(widgetSelectors.getCurrentLayout)
    const dispatch = useDispatch()

    const options = makeOptions(isMobile)

    return (
        <SelectPopover
            buttonProps={{ sx: { color: 'inherit' } }}
            items={options}
            onChange={item => dispatch(widgetCreators.changeSelectedLayout(item.value))}
            PlaceHolderIcon={TileLayout}
            tooltip="Change layout"
            value={options[currentLayout]}
        />
    )
}

const makeOptions = (isMobile: boolean) => [
    { Icon: TileLayout, label: 'Flexible', value: 0 },
    { Icon: OneCol, label: 'Comfortable', value: 1 },
    { Icon: TwoCol, label: 'Standard', value: 2 },
    ...(!isMobile ? [{ Icon: ThreeCol, label: 'Compact', value: 3 }] : []),
]
