import { useState, useContext, useMemo } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, IconButton } from '@mui/material'
import { CloseRounded } from '@mui/icons-material'
import TabPerspectiveToolbar from './TabPerspectiveToolbar'
import TabAccordionPerspective from './TabAccordionPerspective'
import { MemoizedTabPerspective } from './TabPerspective'
import ReOrderAccordionPerspective from './ReOrderAccordionPerspective'
import { LayoutPerspectiveContext } from '~/components/contexts/LayoutPerspectiveContext'

export default function TabsView({ tabs, tabValue, isEdit, perspectives }) {
    const { tabRows, setTabRows } = useContext(LayoutPerspectiveContext)

    const [reOrder, setReOrder] = useState(false)
    const [layout, setLayout] = useState([])

    const onClose = () => {
        setReOrder(!reOrder)
    }

    const onSubmit = () => {
        let data = [...tabRows]
        let updated_data = data[tabValue].perspective.map(item => {
            layout.map(p => {
                if (p.i === item.Id) {
                    item.Order = p.y
                }
            })
            return item
        })
        data[tabValue].perspective = updated_data
        setTabRows(data)
        onClose()
    }

    const orderedPerspectives = useMemo(() => {
        return tabs[tabValue]?.perspective.sort((a, b) => a?.Order - b?.Order)
    }, [tabs, tabValue])

    return (
        <>
            {tabs.length > 0 ? (
                tabs[tabValue]?.perspective?.length > 1 ? (
                    <>
                        {isEdit && (
                            <Box sx={{ py: 1 }}>
                                <TabPerspectiveToolbar tabValue={tabValue} onClickReorder={onClose} />
                            </Box>
                        )}
                        {orderedPerspectives.map((item, key) => (
                            <TabAccordionPerspective
                                key={key}
                                id={item?.Id}
                                perspectiveLayout={item?.Position}
                                isEdit={isEdit}
                                perspectives={perspectives}
                            />
                        ))}
                    </>
                ) : (
                    <Box sx={{ height: '100%' }}>
                        {isEdit && <TabPerspectiveToolbar tabValue={tabValue} />}
                        <MemoizedTabPerspective
                            id={tabs[tabValue]?.perspective[0].Id}
                            perspectiveLayout={tabs[tabValue]?.perspective[0].Position}
                            isEdit={isEdit}
                        />
                    </Box>
                )
            ) : null}

            {isEdit && (
                <Dialog open={reOrder} fullWidth onClose={onClose}>
                    <DialogTitle>
                        Perspective Order
                        <IconButton
                            aria-label="close"
                            onClick={onClose}
                            sx={{
                                color: 'inherit',
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}
                        >
                            <CloseRounded />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        <ReOrderAccordionPerspective
                            layout={layout}
                            setLayout={setLayout}
                            perspectives={perspectives}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button variant="contained" onClick={onSubmit}>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    )
}
