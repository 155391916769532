import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Box,
    IconButton,
    Typography,
    Tooltip,
    Button,
    TextField,
    MenuItem,
    Divider,
    Checkbox,
    FormControlLabel,
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import CopyIcon from '@mui/icons-material/FileCopyOutlined'
import OpenIcon from '@mui/icons-material/Launch'

import { Dashboard, VisualType, Widget } from 'genesis-suite/types/visualTypes'
import { EditableLabel, SwalContext } from 'genesis-suite/components'
import DeleteIcon from 'genesis-suite/icons/Trash'
import HelpIcon from 'genesis-suite/icons/Help'
import { ResourceType } from 'genesis-suite/types/networkTypes'
import { sleep } from 'genesis-suite/utils'
import { moduleCreators } from '../../actions/creators'
import {
    applicationSelectors,
    authSelectors,
    deploymentSelectors,
    insightSelectors,
    moduleSelectors,
} from '../../selectors'
import { visualService } from '../../lib/services'
import { getInfoPanelDateProps } from '../../lib/manageUtils'
import { intersection, uniq } from 'lodash'
import VisualVersionWithHistoryButton from '../widgets2/VisualVersionWithHistoryButton'
import VisualReferences, { DashboardReferences, Status } from '../widgets2/VisualReferences'

interface Props {
    id: string
    onCopy: (id: string) => void
    onDelete: (id: string) => void
    onDone: () => void
    onOpen: (liveId: string) => void
    onUpdate: (id: string, body: Partial<Dashboard>) => Promise<void>
}

const noFocalPoint = '__no-focal-point'

export default function DashboardDetails({ id, onCopy, onDelete, onDone, onOpen, onUpdate }: Props) {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const userId = useSelector(authSelectors.getUserId)
    const isPowerUser = useSelector(authSelectors.getIsPowerUser)
    const viewFlag = useSelector(deploymentSelectors.getDeploymentViewFlag)
    const currentModuleId = useSelector(moduleSelectors.getModuleId)
    const insights = useSelector(insightSelectors.getList)
    const focalPoints = useSelector(insightSelectors.getFocalPoints)
    const dispatch = useDispatch()

    const { confirm } = useContext(SwalContext)

    const [data, setData] = useState<{ dashboard: Dashboard; widgets: Widget[] }>(null)
    const [status, setStatus] = useState<Status>('loading')
    const [references, setReferences] = useState<DashboardReferences>(null)

    const { created, updated } = getInfoPanelDateProps(data?.dashboard) || {}
    const { active, draft, inNavigation, title, updatedBy, focalPoint } = data?.dashboard || {}
    const liveId = draft?.visualId ?? id
    const usedWidgetInsights = uniq(
        data?.widgets?.reduce(
            (acc, cur) => [
                ...acc,
                ...(cur.meta?.resources?.filter(r => r.type === ResourceType.INSIGHT).map(r => r.name) ?? []),
            ],
            [] as string[]
        )
    )
    const availableFocalPoints = usedWidgetInsights.length
        ? intersection(...insights.filter(i => usedWidgetInsights.includes(i.name)).map(i => i.nodeNames))
        : focalPoints

    useEffect(() => {
        getDashboard(id)
    }, [id])

    function getDashboard(id?: string) {
        if (id) {
            visualService.getDashboardById(appName, id, true, viewFlag).then(setData)
        } else {
            setReferences(null)
        }
    }

    async function handleUpdate(body: Partial<Dashboard>) {
        setData(s => ({ ...s, dashboard: { ...s.dashboard, ...body } }))
        await onUpdate(id, body)
        await sleep(100)
        getDashboard(id)
    }

    async function handleActiveToggle() {
        if (!active) return handleUpdate({ active: true })

        const activeModules = references?.modules?.filter(m => m.active)
        const activeWidgets = references?.widgets?.filter(w => w.active)
        const hasActiveRefs = activeModules?.length || activeWidgets?.length

        if (hasActiveRefs) {
            const response = await confirm(
                'Deactivating dashboard will remove all references within widgets and applications throughout this cloud',
                { title: 'Remove references?', type: 'warning' }
            )
            if (response.dismiss) return
        }

        if (data.dashboard.revision) {
            const response = await confirm('Making the dashboard private will delete the change history', {
                title: 'Delete change history?',
                type: 'warning',
            })
            if (response.dismiss) return
        }

        await handleUpdate({ active: false })

        if (hasActiveRefs) setReferences(null)

        if (activeModules?.some(m => m.id === currentModuleId)) {
            const moduleName = activeModules.find(m => m.id === currentModuleId).name
            dispatch(moduleCreators.changeModule(moduleName))
        }
    }

    async function handleDelete() {
        if (references) {
            const response = await confirm(
                'Deleting dashboard will remove all references within widgets and applications throughout this cloud',
                { title: 'Remove references?', type: 'warning' }
            )
            if (response.dismiss) return
        }

        onDelete(id)
    }

    return (
        <Box height="100%" display="flex" flexDirection="column" overflow="hidden">
            <Box m={1}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="h5">Details</Typography>
                    <IconButton onClick={onDone}>
                        <ClearIcon />
                    </IconButton>
                </Box>

                <EditableLabel value={title} onChange={title => handleUpdate({ title })} />

                <Box display="flex" gap={2}>
                    <Button
                        size="small"
                        startIcon={<OpenIcon color="primary" fontSize="small" />}
                        onClick={() => onOpen(liveId)}
                    >
                        Open
                    </Button>
                    <Button
                        size="small"
                        startIcon={<CopyIcon color="primary" fontSize="small" />}
                        onClick={() => onCopy(id)}
                    >
                        Copy
                    </Button>
                    <Button
                        color="error"
                        disabled={status !== 'idle'}
                        size="small"
                        startIcon={<DeleteIcon color="error" fontSize="small" />}
                        onClick={handleDelete}
                    >
                        Delete
                    </Button>
                </Box>

                <InfoItem label="Created" value={created} />
                {updatedBy && <InfoItem label="Updated" value={updated} />}

                <Box display="flex" alignItems="center" gap={1}>
                    <Typography variant="caption">Focal point:</Typography>
                    <TextField
                        select
                        size="small"
                        variant="outlined"
                        value={focalPoint || noFocalPoint}
                        onChange={e =>
                            handleUpdate({ focalPoint: e.target.value === noFocalPoint ? undefined : e.target.value })
                        }
                    >
                        <MenuItem value={noFocalPoint}>None</MenuItem>
                        <Divider />
                        {availableFocalPoints?.map(name => (
                            <MenuItem key={name} value={name}>
                                {name}
                            </MenuItem>
                        ))}
                    </TextField>

                    <Tooltip
                        title="List of focal points available by looking at the intersection of overlapping nodes for all widgets within the collection"
                        placement="right"
                    >
                        <HelpIcon fontSize="small" />
                    </Tooltip>
                </Box>

                <Box display="flex" gap={4}>
                    {isPowerUser && (
                        <Box display="flex" alignItems="center">
                            <FormControlLabel
                                disabled={!data || status !== 'idle' || Boolean(draft)}
                                control={<Checkbox checked={active ?? false} onChange={handleActiveToggle} />}
                                label="Public"
                            />
                            <Tooltip
                                title="Allows other admins to edit the dashboard. Also, enable dashboard to be referenced by active applications and widgets."
                                placement="right"
                            >
                                <HelpIcon fontSize="small" />
                            </Tooltip>
                        </Box>
                    )}
                    {data?.dashboard?.createdBy.id === userId && (
                        <>
                            <Box display="flex" alignItems="center">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={inNavigation ?? false}
                                            onChange={() => handleUpdate({ inNavigation: !inNavigation })}
                                        />
                                    }
                                    label="Shortcut"
                                />
                                <Tooltip
                                    title="Place a shortcut to this dashboard in the application views side panel"
                                    placement="right"
                                >
                                    <HelpIcon fontSize="small" />
                                </Tooltip>
                            </Box>
                        </>
                    )}
                </Box>

                <VisualVersionWithHistoryButton type={VisualType.DASHBOARD} config={data?.dashboard} />
            </Box>

            <Typography variant="h6" sx={{ m: 1 }}>
                References
            </Typography>
            <Box overflow="auto">
                <VisualReferences
                    type={VisualType.DASHBOARD}
                    id={liveId}
                    onReferences={(status, refs) => {
                        setStatus(status)
                        setReferences(refs)
                    }}
                />
            </Box>
        </Box>
    )
}

function InfoItem({ label, value }) {
    return (
        <Box display="flex" alignItems="center" gap="8px">
            <Typography variant="caption">{label}:</Typography>
            <Typography>{value}</Typography>
        </Box>
    )
}
