import React from 'react'
import { Typography } from '@mui/material'

import WidgetInfoEmptyText from './WidgetInfoEmptyText'
import { useSelector } from 'react-redux'
import { filterSelectors } from '../../selectors'

export default function WidgetInfoInlineFilters() {
    const inlineFilters = useSelector(filterSelectors.getInlineFilters)
    const filters = inlineFilters?.['filters']

    if (!filters?.length) return <WidgetInfoEmptyText>No inline filter(s) applied</WidgetInfoEmptyText>

    return (
        <div>
            {filters.map((filter, index) => (
                <Typography display="inline" key={index}>
                    {filter['Values']}
                    {index < filters.length - 1 ? ', ' : ''}
                </Typography>
            ))}
        </div>
    )
}
