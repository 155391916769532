import { Dialog, DialogTitle, DialogContent, DialogActions, Button, DialogContentText, IconButton } from '@mui/material'
import { CloseRounded } from '@mui/icons-material'

export default function CancelPerspectiveDialog({ cancelObj }) {
    const { open, message, success, cancel } = cancelObj

    return (
        <Dialog open={open} onClose={cancel}>
            <DialogTitle>
                Confirm
                <IconButton
                    aria-label="close"
                    onClick={cancel}
                    sx={{
                        color: 'inherit',
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseRounded />
                </IconButton>
            </DialogTitle>

            <DialogContent>{message}</DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={cancel}>
                    Cancel
                </Button>
                {success && (
                    <Button variant="contained" onClick={success}>
                        Submit
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}
