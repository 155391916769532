import { ReactElement, useContext, useState } from 'react'
import { Autocomplete } from '@mui/material'
import { Box, Tooltip, TextField, Tabs, Tab, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { Trash } from 'genesis-suite/icons'
import { SwalContext } from 'genesis-suite/components'
import { Property } from 'genesis-suite/types/networkTypes'
import { FormPrompt, PromptId } from 'genesis-suite/types/visualTypes'
import FormFieldBuilder from '../builders/FormFieldBuilder'
import CustomRenderBuilder from '../builders/CustomRenderBuilder'

const useStyles = makeStyles(({ spacing, palette }) => ({
    smFlex: { flex: 1 },
    mdFlex: { flex: 2 },
    tab: { fontSize: '.75rem' },
    tabs: { borderBottom: `1px solid ${palette.grayscale.light}` },
    inputRow: { width: '100%', display: 'flex', alignItems: 'flex-end', '&>*': { marginRight: spacing(1) } },
    deleteBtn: { backgroundColor: palette.status.error, color: '#fff', alignSelf: 'flex-end' },
}))

interface PromptBuilderProps {
    prompt: FormPrompt
    properties: Property[]
    moveButton: ReactElement
    closeButton?: ReactElement
    onChange: (prompt: FormPrompt) => void
    onDelete: (promptId: PromptId) => void
}

export default function PromptBuilder({
    prompt,
    closeButton,
    onChange,
    onDelete,
    properties,
    moveButton,
}: PromptBuilderProps) {
    const classes = useStyles()
    const [tab, setTab] = useState('general')
    const [dirty, setDirty] = useState(false)

    const { confirm } = useContext(SwalContext)

    const { title, property } = prompt
    const primary = property ? property.isPrimary : false

    const handleChange = (change: Partial<FormPrompt>) => {
        if (!dirty) setDirty(true)
        onChange({ ...prompt, ...change })
    }

    const deletePrompt = async () => {
        if (dirty) {
            const response = await confirm(`Delete ${title}? It will be lost forever`, { type: 'warning' })
            if (response.dismiss) return
        }
        onDelete(prompt.id)
    }

    const propOptions = properties.filter(p => !p.isPrimary)
    const canDelete = !primary

    return (
        <Box width="100%" display="flex" flexDirection="column">
            <Box display="flex">
                {closeButton && closeButton}
                <TextField
                    fullWidth
                    value={title}
                    className={classes.mdFlex}
                    onChange={e => handleChange({ title: e.target.value })}
                />
                {moveButton}
                <Tooltip title={canDelete ? '' : 'Cannot delete primary property prompts'}>
                    <Box alignSelf="flex-end">
                        <IconButton disabled={!canDelete} onClick={deletePrompt} size="large">
                            <Trash />
                        </IconButton>
                    </Box>
                </Tooltip>
            </Box>
            <div className={classes.inputRow}>
                <Autocomplete
                    value={property}
                    disabled={primary}
                    options={propOptions}
                    className={classes.smFlex}
                    getOptionLabel={(opt: Property) => opt.displayName}
                    onChange={(e, property: Property) => handleChange({ property })}
                    isOptionEqualToValue={(opt: Property) => primary || opt.id === property.id}
                    renderInput={params => (
                        <Tooltip title={primary ? 'Cannot deselect primary properties' : ''}>
                            <TextField label="Property" {...params} />
                        </Tooltip>
                    )}
                />
                <TextField disabled label="Type" value={property?.semanticType.baseDataType ?? ''} />
            </div>

            <Tabs className={classes.tabs} value={tab} onChange={(e, v) => setTab(v)}>
                <Tab className={classes.tab} value="general" label="General" />
                <Tab className={classes.tab} value="custom" label="Custom Rendering" />
            </Tabs>

            <Box minHeight={250} display="flex" flexDirection="column" position="relative">
                {tab === 'general' ? (
                    <FormFieldBuilder {...{ field: prompt, properties, onChange: handleChange }} />
                ) : (
                    <CustomRenderBuilder
                        config={prompt.customEditor}
                        onChange={customEditor => handleChange({ customEditor })}
                        disableMessage={
                            prompt.useTadaValues ? 'Turn off "Use Tada Values" to add custom editor' : undefined
                        }
                    />
                )}
            </Box>
        </Box>
    )
}
