import MoreIcon from '@mui/icons-material/ArrowDropDownRounded'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandIcon from '@mui/icons-material/ExpandMore'
import { Skeleton } from '@mui/lab'
import { Box, Button, Divider, Popover, Typography } from '@mui/material'
import { isEqual, random } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import Measure from 'react-measure'
import { useDispatch, useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'

import { makeStyles, useTheme } from '@mui/styles'
import { ChopText, CognitiveIcon, MenuIcon } from 'genesis-suite/components'
import ActiveIcon from 'genesis-suite/icons/Donut'
import { sleep } from 'genesis-suite/utils'
import { navigationCreators } from '../actions/creators'
import GlobalFiltersController from '../containers/GlobalFiltersController'
import useIsItemAtRoute from '../hooks/useIsItemAtRoute'
import { routePaths } from '../lib/routes'
import { computeHiddenCount, openInNewTab } from '../lib/utils'
import {
    applicationSelectors,
    menuSelectors,
    moduleSelectors,
    widgetSelectors,
    appearanceSelectors,
} from '../selectors'
import { default as GlobalFiltersController2 } from './GlobalFiltersController2'
import SettingsButton from './SettingsButton'
import NetworkFiltersController from './network-filters/NetworkFiltersController'
import ScenarioButton from './scenarios/ScenarioButton'
import NeoClassicGlobalFiltersController from './NeoClassicGlobalFiltersController'
import { filterService } from '~/lib/services'

const useStyles = makeStyles(({ palette, spacing }) => ({
    expandMoreIcon: {
        position: 'absolute',
        right: '-10px',
    },
}))

export default function NeoClassicViews() {
    const modulePending = useSelector(moduleSelectors.getModulesPending)
    const views = useSelector(menuSelectors.getViews)

    const parentRef = useRef<HTMLDivElement>(null)
    const menuRef = useRef<HTMLDivElement>(null)
    const [hiddenCount, setHiddenCount] = useState(0)
    const [layingOut, setLayingOut] = useState(true)
    const [recompute, setRecompute] = useState(false)
    const [openHidden, setOpenHidden] = useState(false)

    const _360Items = views.map(({ subMenuConfig, ...rest }) => ({ items: subMenuConfig, ...rest }))
    const visibleItems = layingOut ? _360Items : _360Items.slice(0, _360Items.length - hiddenCount)
    const hiddenItems = _360Items.slice(-hiddenCount)
    const viewItemWidth = getViewItemWidth(visibleItems, 0.75)
    const theme = useTheme()
    const isV2 = useSelector(moduleSelectors.getIsV2)
    const [filterGroups, setFilterGroups] = useState([])
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const persId = useSelector(widgetSelectors.getCurrentPerspectiveId)
    const isBetaTurnedOn = useSelector(appearanceSelectors.getIsBetaTurnedOn)

    const lastViews = useRef(null)
    useEffect(() => {
        ;(async () => {
            try {
                const groups = await filterService.getFilterGroups(appName, persId)
                setFilterGroups(groups)
            } catch (err) {
                console.error(err)
            }
        })()
    }, [])
    useEffect(() => {
        if (isEqual(views, lastViews.current)) return

        lastViews.current = views
        resetLayout()
    }, [views])

    const handleResize = useDebouncedCallback(resetLayout, 500)

    async function resetLayout() {
        setLayingOut(true)
        setOpenHidden(false)
        await sleep()
        setRecompute(s => !s)
    }

    useEffect(() => {
        const menuWidth = menuRef.current?.clientWidth
        setHiddenCount(computeHiddenCount(parentRef, 2, 32 + menuWidth))
        setLayingOut(false)
    }, [recompute])

    if (modulePending)
        return (
            <Box sx={{ display: 'flex', gap: 1, ml: 1 }}>
                {new Array(4).fill(0).map((_, i) => (
                    <Skeleton key={i} width={random(75, 150)} height="100%" />
                ))}
            </Box>
        )

    if (!views.length) return <Typography sx={{ ml: 1, fontSize: '1rem', fontWeight: 700 }}>No views</Typography>

    const menuIconProps = {
        buttonStyle: { padding: '6px' },
        iconStyle: {
            fontSize: '18px',
            color: '#FFF',
        },
    }

    return (
        <Measure client innerRef={parentRef} onResize={handleResize}>
            {({ measureRef }) => (
                <Box ref={measureRef} sx={{ display: 'flex', flex: 1, overflow: 'hidden', alignItems: 'center' }}>
                    {visibleItems.map((v, i) => (
                        <ViewItem key={i} index={i} item={v} hidden={layingOut} top textWidth={viewItemWidth} />
                    ))}

                    <Box sx={{ width: '32px' }}>
                        <MenuIcon
                            buttonProps={{
                                sx: { visibility: hiddenCount ? 'visible' : 'hidden', color: 'text.primary' },
                            }}
                            icon={
                                <MoreIcon
                                    fontSize="large"
                                    sx={{
                                        cursor: 'pointer',
                                        color: theme.palette.primary.contrastText,
                                        backgroundColor: theme.palette.primary.main,
                                        borderRadius: 35 / 2,
                                    }}
                                />
                            }
                            onClick={() => setOpenHidden(true)}
                            onClose={() => setOpenHidden(false)}
                            open={openHidden}
                            tooltip="More"
                            popoverProps={{
                                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                                transformOrigin: { vertical: 'top', horizontal: 'left' },
                            }}
                        >
                            {hiddenItems.map((v, i) => (
                                <ViewItem key={i} index={i} item={v} overflow onDone={() => setOpenHidden(false)} />
                            ))}
                        </MenuIcon>
                    </Box>

                    <Box ref={menuRef} sx={{ position: 'absolute', right: 0, display: 'flex', alignItems: 'center' }}>
                        {isBetaTurnedOn ? (
                            <NeoClassicGlobalFiltersController {...menuIconProps} filterGroups={filterGroups} />
                        ) : isV2 ? (
                            <GlobalFiltersController2 {...menuIconProps} />
                        ) : (
                            <GlobalFiltersController {...menuIconProps} />
                        )}
                        <NetworkFiltersController {...menuIconProps} />
                        <ScenarioButton {...menuIconProps} />
                        <SettingsButton {...menuIconProps} />
                    </Box>
                </Box>
            )}
        </Measure>
    )
}

interface ViewItemProps {
    item: any
    index: number
    onDone?: (e?: any) => void
    hidden?: boolean
    overflow?: boolean
    top?: boolean
    textWidth?: string
}

function ViewItem({
    item,
    index,
    onDone = undefined,
    hidden = false,
    overflow = false,
    top = false,
    textWidth,
}: ViewItemProps) {
    const { type, to, isExternal, text, items } = item
    const [first, ...second] = top ? text.split(' ') : [text]
    const secondText = second ? second.join(' ') : null
    const atRoute = useIsItemAtRoute(item)
    const ref = useRef()
    const dispatch = useDispatch()
    const theme = useTheme()
    const classes = useStyles()

    const icon =
        item.icon && item.icon.file
            ? item.icon
            : top
            ? { file: 'General/sales.svg' }
            : { file: 'Manufacturing/parts.svg' }

    const [openMenu, setOpenMenu] = useState(false)
    const isParent = Boolean(items?.length)

    function handleClick(e) {
        if (isParent) return setOpenMenu(true)

        onDone?.(e)
        if (!to || atRoute) return

        if (isExternal) openInNewTab(to)
        else if (type === 'viewId') dispatch(navigationCreators.goToPerspective(to))
        else if (type === 'widgetId') dispatch(navigationCreators.goTo(routePaths.WIDGET, to))
        else if (type === 'elementName') dispatch(navigationCreators.goTo(routePaths.ELEMENT, to))
        else if (type === 'treeName') dispatch(navigationCreators.goTo(routePaths.TREE, to))
    }

    return (
        <>
            <Box
                sx={{
                    alignItems: top ? 'center' : 'stretch',
                    display: 'flex',
                    flexDirection: 'column',
                    visibility: hidden ? 'hidden' : 'visible',
                    color: '#FFF',
                    backgroundColor: '#777',
                }}
            >
                <Button
                    color="inherit"
                    onClick={handleClick}
                    ref={ref}
                    sx={{
                        paddingLeft: top ? '0px' : '8px',
                        justifyContent: 'flex-start',
                        ':hover': { borderBottom: '3px solid gold' },
                        borderRadius: 0,
                        borderBottom: `3px solid #777`,
                    }}
                >
                    {top && index > 0 && (
                        <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                            sx={{
                                borderColor: '#bfbfbf',
                                mt: 0,
                                mb: 0,
                                mr: '4px',
                                borderWidth: '1px',
                            }}
                        />
                    )}
                    {icon ? (
                        <CognitiveIcon bgColor="#24292E" fontSize={top ? 'large' : 'small'} icon={icon} />
                    ) : undefined}
                    <ChopText
                        showEllipsis
                        lineCount={1}
                        sx={{
                            maxWidth: top ? textWidth : '150px',
                            fontSize: top ? '0.75rem' : '0.7rem',
                            wordWrap: top ? 'break-word' : 'none',
                            textAlign: 'left',
                            fontFamily: 'segoe UI',
                            marginLeft: '4px',
                            width: top ? textWidth : 'auto',
                        }}
                        tooltipProps={{ placement: 'bottom' }}
                    >
                        {first}
                        {secondText && <br />}
                        {secondText}
                    </ChopText>
                    {atRoute && !top && <ActiveIcon color="primary" />}
                    <ExpandMoreIcon
                        iconProps={{
                            sx: { visibility: isParent ? 'visible' : 'hidden' },
                            className: overflow ? classes.expandMoreIcon : '',
                        }}
                        overflow={overflow}
                        isParent={isParent}
                        openMenu={openMenu}
                    />
                </Button>

                {top && (
                    <Box
                        sx={{
                            backgroundColor: atRoute ? 'primary.main' : undefined,
                            borderRadius: '2px',
                            height: '3px',
                            width: '30px',
                        }}
                    />
                )}
            </Box>

            {isParent && (
                <Popover
                    anchorEl={ref.current}
                    anchorOrigin={{ vertical: overflow ? 'center' : 'bottom', horizontal: overflow ? 'right' : 'left' }}
                    onClose={() => setOpenMenu(false)}
                    open={openMenu}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    sx={{ ml: !overflow ? 4 : 0 }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                        {items.map((v, i) => (
                            <ViewItem
                                key={i}
                                item={v}
                                index={i}
                                onDone={e => {
                                    setOpenMenu(false)
                                    onDone?.(e)
                                }}
                            />
                        ))}
                    </Box>
                </Popover>
            )}
        </>
    )
}

function ExpandMoreIcon({ overflow, openMenu, isParent, iconProps }) {
    return (
        <>
            {openMenu ? (
                overflow ? (
                    <ChevronLeftIcon fontSize="small" {...iconProps} />
                ) : (
                    <ExpandLessIcon fontSize="small" {...iconProps} />
                )
            ) : overflow ? (
                <ChevronRightIcon fontSize="small" {...iconProps} />
            ) : (
                <ExpandIcon fontSize="small" {...iconProps} />
            )}
        </>
    )
}

function getViewItemWidth(items, fontSize) {
    let maxCharLength = 0
    items.forEach(item => {
        if (item?.text?.includes(' ')) {
            const [first, ...rest] = item.text.split(' ')
            if (first?.length > maxCharLength) maxCharLength = first.length
            if (rest.join(' ')?.length > maxCharLength) maxCharLength = rest.join(' ').length
        } else {
            maxCharLength = item.text.length > maxCharLength ? item.text.length : maxCharLength
        }
    })

    maxCharLength = maxCharLength > 14 ? 14 : maxCharLength
    return fontSize * ((maxCharLength + 0.5) / 2) * 16 + 'px'
}
