import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton } from '@mui/material'
import { useDispatch } from 'react-redux'

import { dialogCreators } from '../actions/creators'
import Profile from '../components/profile/Profile'
import ProfileHistory from '../components/profile/ProfileHistory'

export default function MobileProfileContainer() {
    const dispatch = useDispatch()
    const handleClose = () => dispatch(dialogCreators.hideDialog('profiles'))

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '80vw', height: '85vh' }}>
            <Box display="flex" alignItems="center">
                <ProfileHistory />
                <IconButton onClick={handleClose} size="large">
                    <CloseIcon fontSize="small" />
                </IconButton>
            </Box>

            <Profile />
        </Box>
    )
}
