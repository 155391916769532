import React, { useContext } from 'react'
import produce from 'immer'

import { PropertyType } from 'genesis-suite/types/architectureTypes'
import { DataSource, Basket, Aggregation, BoxPlotSeries, SeriesConfig } from 'genesis-suite/types/visualTypes'
import { letMeMap } from 'genesis-suite/types/utilTypes'
import { ConfigContext } from '../ConfigContext'
import DnDPropertySelector from './DnDPropertySelector'
import { getDataFieldProperty, getDefaultAggregation, getBoxPlotAggregations, getSeriesColor } from '../utils'
import { DragField, FieldPointer, ServiceWithColor } from '../builderTypes'
import useWidgetColors from '../../../hooks/useWidgetColors'
import { useSemanticTypeById } from '../../../hooks/useSemanticTypes'

interface Props {
    basket?: Basket
    label?: string
    limit?: number
}

interface SourceWithPointer extends DataSource {
    pointer: FieldPointer
    service: ServiceWithColor
}

export default function BoxPlotSeriesSelector({ basket, label, limit }: Props) {
    const semanticTypeById = useSemanticTypeById()
    const { dataResponse, dispatch, selectedField, resources, ...rest } = useContext(ConfigContext)
    const config = rest.config as SeriesConfig
    const { series } = config
    const totalBoxPlotAggregations = getBoxPlotAggregations().length
    const lastBoxPlotValueIndex = totalBoxPlotAggregations - 1
    const seriesContainsBoxPlot = series[0]?.values.length === totalBoxPlotAggregations
    const colors = useWidgetColors()

    const seriesValues = letMeMap(series).reduce((acc, seriesObj, index) => {
        const color = getSeriesColor(series, index, colors)

        seriesObj.values?.forEach((val, valueIndex) => {
            if (!basket || (basket && val.basket === basket))
                acc.push({
                    ...val,
                    service: { ...seriesObj.service, color },
                    pointer: { type: 'series', index, valueIndex },
                })
        })
        return acc
    }, [] as SourceWithPointer[])

    const boxPlotSeries = seriesValues.reduce((seriesAccumulator, value, index) => {
        const { properties } = resources.byId[value?.service.id] ?? {}
        const { displayName, semanticType } = getDataFieldProperty(value.field, properties)
        if (index > lastBoxPlotValueIndex) return seriesAccumulator
        const selectedFieldIndex = selectedField?.index

        const seriesValue = {
            ...value,
            type: semanticType?.type,
            title: displayName,
            selected:
                selectedField?.type === 'series' &&
                series[selectedFieldIndex]?.values.length === totalBoxPlotAggregations,
            color: value.service.color,
        }

        if (index === lastBoxPlotValueIndex && seriesContainsBoxPlot) {
            seriesValue.aggregation = Aggregation.NONE
            seriesAccumulator.push(seriesValue)
        }

        return seriesAccumulator
    }, [])

    const additionalSeries = seriesValues.reduce((seriesAccumulator, value, index) => {
        const { properties } = resources.byId[value?.service.id]
        const { displayName, semanticType } = getDataFieldProperty(value.field, properties)
        if (index <= lastBoxPlotValueIndex && seriesContainsBoxPlot) return seriesAccumulator
        const selectedFieldIndex = selectedField?.index

        const selectedSeriesIndex = seriesContainsBoxPlot
            ? selectedFieldIndex + totalBoxPlotAggregations - 1
            : selectedFieldIndex

        const seriesValue = {
            ...value,
            type: semanticType?.type,
            title: displayName,
            selected: selectedField?.type === 'series' && selectedSeriesIndex === index,
            color: value.service.color,
        }

        seriesAccumulator.push(seriesValue)
        return seriesAccumulator
    }, [])

    const handleAdd = (dragField: DragField, _index?: number) => {
        const index = _index !== undefined ? _index : series.length
        const { pointer, type, ...field } = dragField
        const to: FieldPointer = { type: 'series', index }
        const { properties } = resources?.byId[resources.selectedId] ?? {}
        const { semanticType, hasAggregates } = getDataFieldProperty(field, properties)
        const aggregation = getDefaultAggregation(semanticType, hasAggregates)

        if (pointer?.type === 'category') return

        const source = { field, aggregation, basket }

        const options = pointer ? { operation: 'move' as const, from: pointer } : { operation: 'new' as const, source }

        dispatch({
            type: 'UPDATE_SELECTED_PROPERTY',
            payload: { to, limit, ...options, semanticTypeById },
        })
    }

    const handleBoxPlotAdd = (dragField: DragField, _index?: number) => {
        const index = _index !== undefined ? _index : series.length
        const { pointer, type, ...field } = dragField
        const to: FieldPointer = { type: 'series', index }
        const boxPlotAggregations = getBoxPlotAggregations()
        const aggregation = Aggregation.NONE

        if (boxPlotSeries.length === 1 || pointer?.type === 'category') return

        const source = { field, aggregation, basket }

        const options = pointer ? { operation: 'move' as const, from: pointer } : { operation: 'new' as const, source }

        dispatch({
            type: 'UPDATE_SELECTED_PROPERTY',
            payload: { to, limit, ...options, semanticTypeById, aggregations: boxPlotAggregations },
        })
    }

    const handleRemove = selectedIndex => {
        const boxPlotSeriesIndex = series.findIndex((series: BoxPlotSeries) => {
            return series.values.length === totalBoxPlotAggregations
        })

        const additionalSeriesIndex = boxPlotSeriesIndex < 0 ? selectedIndex : selectedIndex + 1

        dispatch({ type: 'SET_SELECTED_FIELD', payload: null })
        const newConfig = produce(config, draft => {
            draft.series.splice(additionalSeriesIndex, 1)
        })
        dispatch({ type: 'UPDATE_CONFIG', payload: newConfig })
    }

    const handleBoxPlotRemove = selectedIndex => {
        dispatch({ type: 'SET_SELECTED_FIELD', payload: null })
        const newConfig = produce(config, draft => {
            draft.series.splice(0, 1)
        })
        dispatch({ type: 'UPDATE_CONFIG', payload: newConfig })
    }

    const handleFieldSelect = index => {
        dispatch({ type: 'SET_SELECTED_FIELD', payload: additionalSeries[index].pointer })
    }

    const handleBoxPlotFieldSelect = index => {
        dispatch({ type: 'SET_SELECTED_FIELD', payload: boxPlotSeries[index].pointer })
    }

    const handleAggregationChange = (index: number, value: Aggregation) => {
        const newConfig = produce(config, draft => {
            const selectedIndex = config.series?.[0].values.length === totalBoxPlotAggregations ? index + 1 : index
            draft.series[selectedIndex].values[0].aggregation = value
        })
        dispatch({ type: 'UPDATE_CONFIG', payload: newConfig })
    }

    return (
        <>
            <DnDPropertySelector
                label={`Box Plot ${label}`}
                accept={[PropertyType.DEFINING, PropertyType.QUALITATIVE, PropertyType.QUANTITATIVE]}
                limit={1}
                properties={boxPlotSeries}
                onFieldSelect={handleBoxPlotFieldSelect}
                onAggregationChange={handleAggregationChange}
                onAdd={handleBoxPlotAdd}
                onRemove={handleBoxPlotRemove}
            />
            {(additionalSeries.length > 0 || boxPlotSeries.length > 0) && (
                <DnDPropertySelector
                    label={` Additional ${label}`}
                    accept={[PropertyType.DEFINING, PropertyType.QUALITATIVE, PropertyType.QUANTITATIVE]}
                    limit={limit}
                    properties={additionalSeries}
                    onFieldSelect={handleFieldSelect}
                    onAggregationChange={handleAggregationChange}
                    onAdd={handleAdd}
                    onRemove={handleRemove}
                />
            )}
        </>
    )
}
