import React, { useEffect, useState } from 'react'
import { Box, IconButton, TextField, TextFieldProps } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'
import CancelIcon from '@mui/icons-material/Clear'

interface Props {
    /** (optional). control the value */
    value?: string
    onDone: (title: string) => void
    onCancel: () => void
    textFieldProps?: TextFieldProps
}

export default function NewItemField({ value = '', onDone, onCancel, textFieldProps }: Props) {
    const [title, setTitle] = useState('')

    useEffect(() => {
        setTitle(value)
    }, [value])

    function handleSave() {
        onDone(title)
        setTitle('')
    }

    return (
        <Box display="flex" alignItems="center" mb={1}>
            <TextField
                fullWidth
                autoFocus
                value={title}
                onKeyPress={e => e.key === 'Enter' && handleSave()}
                onChange={({ target }) => setTitle(target.value)}
                {...textFieldProps}
            />
            <IconButton size="small" onClick={handleSave}>
                <DoneIcon fontSize="small" />
            </IconButton>
            <IconButton size="small" onClick={onCancel}>
                <CancelIcon fontSize="small" />
            </IconButton>
        </Box>
    )
}
