import React from 'react'
import { connect } from 'react-redux'
import { Button, Typography } from '@mui/material'
import { Help } from 'genesis-suite/icons'
import { applicationSelectors, authSelectors, moduleSelectors, widgetSelectors } from '../../selectors'
import { logService } from '../../lib/services'

class WidgetErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { error: null, info: null }
    }

    componentDidCatch(error, info) {
        // Catch errors in any components below and re-render with error message
        console.error(error)
        this.setState({
            error: error,
            info: info,
        })

        const body = {
            isFailed: true,
            isUXError: true,
            modelName: this.props.appName,
            visorName: this.props.moduleName,
            perspectiveName: this.props.perspectiveName,
            widgetId: this.props.requestConfig.Id,
            widgetName: this.props.requestConfig.Name,
            widgetType: this.props.requestConfig.Type,
            userId: this.props.userId,
            requestDef: JSON.stringify(this.props.requestConfig),
            errorMessage: error.toString(),
        }

        logService.create(body)
    }

    render() {
        const { info } = this.state
        const { children, logout } = this.props
        if (info) {
            // Error path
            return (
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        paddingLeft: '80px',
                        paddingRight: '80px',
                        flexDirection: 'column',
                        overflow: 'none',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                    }}
                >
                    <Typography margin="0 50px">
                        <b>Something went wrong.</b> <br /> <br />
                    </Typography>
                    <Typography>
                        Please refresh your browser and try again. If the error persists you can contact us by clicking
                        the {}
                        <Help viewBox="0 -7 30 30" />
                        on the top right of your screen.
                    </Typography>
                    {logout && <Button onClick={logout}>Logout</Button>}
                </div>
            )
        }
        // Normally, just render children
        return children
    }
}

const mapStateToProps = state => ({
    appName: applicationSelectors.getCurrentAppName(state),
    moduleName: moduleSelectors.getModuleName(state),
    perspectiveName: widgetSelectors.getCurrentPerspectiveConfig(state)?.Name,
    userId: authSelectors.getUserId(state),
})

export default connect(mapStateToProps)(WidgetErrorBoundary)
