import { createContext, useCallback, useEffect, useRef, useState } from 'react'
import { useMatch } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import produce from 'immer'
import { useDragLayer } from 'react-dnd'
import { useDebouncedCallback } from 'use-debounce'

import { View } from 'genesis-suite/types/visualTypes'
import { makeId } from 'genesis-suite/utils'
import { useUpdateEffect } from 'genesis-suite/hooks'
import { moduleCreators, userNavCreators } from '../../actions/creators'
import { applicationSelectors, authSelectors, deploymentSelectors, moduleSelectors } from '../../selectors'
import { DraftView, DragItem, NewDragItem } from './ViewTypes'
import { visualService } from '../../lib/services'
import { routePaths } from '../../lib/routes'
import { isEqual } from 'lodash'
import { updateDashboardOrCreateDraft } from '../../lib/manageUtils'

interface ContextProps {
    userId: string
    isPowerUser: boolean
    draftViews: DraftView[]
    draggingItem: DragItem | null
    /** Update this to indicate when a view has been added to the users views */
    newView: any
    onMoveItem: (dragItem: DragItem, toIndexes: number[]) => void
    onFindIndexes: (dragId: string) => number[]
    onNewGroup: (title: string) => void
    onDeleteGroup: (index: number) => void
    /** add view (copy if not users) to module views and return true if added (false if not) */
    onNewView: (item: NewDragItem, keep: boolean, isUsers: boolean) => Promise<boolean>
    /** Move (or copy) module view to user's */
    onCopyToUsersViews: (id: string, forceCopy?: boolean) => Promise<void>
    onRemoveView: (dragId: string) => void
    onUpdateView: (dragId: string, update: Partial<DraftView>) => void
    clearNewView: () => void
}

export const ModuleViewsContext = createContext<Partial<ContextProps>>({})

export function ModuleViewsProvider({ children }) {
    const atManageView = Boolean(useMatch(routePaths.MANAGE))

    const userId = useSelector(authSelectors.getUserId)
    const isPowerUser = useSelector(authSelectors.getIsPowerUser)
    const moduleViews = useSelector(moduleSelectors.getViews)
    const isV2 = useSelector(moduleSelectors.getIsV2)
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const dispatch = useDispatch()
    const viewFlag = useSelector(deploymentSelectors.getDeploymentViewFlag)

    const [draftViews, setDraftViews] = useState<DraftView[]>([])
    const [newView, setNewView] = useState(null)

    const { draggingItem } = useDragLayer(monitor => ({ draggingItem: monitor.getItem() }))

    // Bind draftViews getter to memo callbacks w/o watching draftViews
    const getDraftViews = useRef(null)
    getDraftViews.current = () => draftViews

    useEffect(() => {
        if (!atManageView || !isV2) return

        setDraftViews(makeDraftViews(moduleViews))
    }, [atManageView, moduleViews])

    const saveTimeout = useRef(null)
    useUpdateEffect(() => {
        if (draggingItem) return

        clearTimeout(saveTimeout.current)
        saveTimeout.current = setTimeout(handleSaveViews, 1000)
    }, [draftViews, draggingItem])

    function handleSaveViews() {
        const draftViews = getDraftViews.current()
        const views = makeViews(draftViews)
        if (isEqual(views, moduleViews)) return

        dispatch(moduleCreators.updateCurrentModule({ views }))
    }

    const onMoveItem = useDebouncedCallback(
        (dragItem: DragItem, toIndexes: number[]) => {
            if (!toIndexes) return

            const from = onFindIndexes(dragItem.dragId)
            const to = [...toIndexes]
            if (isEqual(from, to)) return

            setDraftViews(s =>
                produce(s, draft => {
                    let item: DraftView

                    switch (from?.length) {
                        case 1: {
                            item = draft.splice(from[0], 1)[0]
                            if (to.length === 2 && to[0] > from[0]) to[0]-- // move child into group from above
                            break
                        }
                        case 2: {
                            const subViews = draft[from[0]].views
                            item = subViews?.splice(from[1], 1)[0]
                            if (to.length === 1 && to[0] < from[0]) to[0]++ // move child out of group above
                            break
                        }
                        default:
                            if (dragItem.status === 'new') {
                                const { status, ...rest } = dragItem
                                item = rest
                            }
                    }

                    if (!item) return

                    switch (to.length) {
                        case 1: {
                            draft.splice(to[0], 0, item)
                            break
                        }
                        case 2: {
                            const subViews = draft[to[0]].views
                            subViews?.splice(to[1], 0, item)

                            break
                        }
                    }
                })
            )
        },
        100,
        { leading: false, maxWait: 100 }
    )

    function onFindIndexes(dragId: string) {
        const draftViews = getDraftViews.current()
        let indexes: number[]

        outer: for (let i = 0; i < draftViews.length; i++) {
            if (draftViews[i].dragId === dragId) {
                indexes = [i]
                break
            }

            const subViews = draftViews[i].views
            if (subViews) {
                for (let n = 0; n < subViews.length; n++) {
                    if (subViews[n].dragId === dragId) {
                        indexes = [i, n]
                        break outer
                    }
                }
            }
        }

        return indexes
    }

    async function onNewGroup(title: string) {
        setDraftViews(s =>
            produce(s, draft => {
                draft.splice(0, 0, { type: 'group', dragId: makeId(), title, views: [] })
            })
        )
    }

    function onDeleteGroup(groupIndex: number) {
        setDraftViews(s =>
            produce(s, draft => {
                const subViews = draft[groupIndex].views ?? []
                draft.splice(groupIndex, 1, ...subViews)
            })
        )
    }

    const onNewView = useCallback(
        async (item: NewDragItem, keep: boolean, isUsers: boolean) => {
            if (!keep) {
                onRemoveView(item.dragId)
                return false
            }

            try {
                if (item.type === 'widget') return true

                const id = isUsers
                    ? item.id
                    : await visualService
                          .cloneDashboard(appName, item.id, { title: `${item.title} (copy)` }, viewFlag)
                          .then(d => d.id)

                if (isUsers) {
                    const dashboard = await visualService.getDashboardById(appName, id, false, viewFlag)
                    if (dashboard.inNavigation || !dashboard.active) {
                        await updateDashboardOrCreateDraft(appName, id, { inNavigation: false, active: true }, viewFlag)
                        dispatch(userNavCreators.remove(id))
                    }
                }

                return true
            } catch (err) {
                console.error(err)
                return false
            }
        },
        [appName]
    )

    const onUpdateView = useCallback(async (dragId: string, update: Partial<DraftView>) => {
        const indexes = onFindIndexes(dragId)
        if (!indexes) return

        setDraftViews(s =>
            produce(s, draft => {
                switch (indexes.length) {
                    case 1:
                        draft[indexes[0]] = { ...draft[indexes[0]], ...update }
                        break
                    case 2:
                        draft[indexes[0]].views[indexes[1]] = { ...draft[indexes[0]].views[indexes[1]], ...update }
                        break
                }
            })
        )
    }, [])

    const onRemoveView = useCallback((dragId: string) => {
        const indexes = onFindIndexes(dragId)
        if (!indexes) return false

        setDraftViews(s =>
            produce(s, draft => {
                switch (indexes.length) {
                    case 1: {
                        draft.splice(indexes[0], 1)
                        break
                    }
                    case 2: {
                        const subViews = draft[indexes[0]].views
                        subViews?.splice(indexes[1], 1)
                        break
                    }
                }
            })
        )
    }, [])

    const onCopyToUsersViews = useCallback(
        async (id: string, forceCopy = false) => {
            const dashboard = await visualService.getDashboardById(appName, id, false, viewFlag)
            if (forceCopy || dashboard.createdBy.id !== userId) {
                const d = await visualService.cloneDashboard(
                    appName,
                    id,
                    { title: `${dashboard.title} (copy)` },
                    viewFlag
                )
                setNewView(d)
            }
        },
        [appName, isPowerUser]
    )

    const clearNewView = useCallback(() => setNewView(null), [])

    const values = {
        clearNewView,
        draftViews,
        draggingItem,
        isPowerUser,
        newView,
        onMoveItem,
        onFindIndexes,
        onNewGroup,
        onDeleteGroup,
        onNewView,
        onCopyToUsersViews,
        onRemoveView,
        onUpdateView,
        userId,
    }

    return <ModuleViewsContext.Provider value={values}>{children}</ModuleViewsContext.Provider>
}

function makeDraftViews(views: View[]): DraftView[] {
    const convertIt = (v: View): DraftView => ({
        ...v,
        dragId: makeId(),
        ...(v.type === 'group' && { views: v.views.map(convertIt) }),
    })

    return views.map(convertIt)
}

function makeViews(views: DraftView[]): View[] {
    const convertIt = ({ dragId, ...v }: DraftView): View => ({
        ...v,
        ...(v.type === 'group' && { type: 'group', views: v.views.map(convertIt) }),
    })

    return views.map(convertIt)
}

function changeDraftView(views: DraftView[], id: string, newId: string, newTitle: string): DraftView[] {
    const convertIt = (v: DraftView): DraftView => ({
        ...v,
        title: v.id === id ? newTitle : v.title,
        ...(v.type === 'group' ? { views: v.views.map(convertIt) } : { id: v.id === id ? newId : id }),
    })

    return views.map(convertIt)
}
