import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { modelService } from '../lib/services'
import { applicationSelectors } from '../selectors'

export default function useNodesAndLinks(): [{ nodes: any[], links: any[] }, boolean] {
    const [data, setData] = useState({ nodes: [], links: [] })
    const [loading, setLoading] = useState(true)
    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const appName = useSelector(applicationSelectors.getCurrentAppName)

    useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                const promises = [modelService.getModelNodes(appName), modelService.getModelLinks(appName)]
                const [nodes, links] = await Promise.all(promises)
                setData({ nodes, links: links.filter(l => l.Attributes) })
            } catch (error) {
                showSnackbar('An error occurred fetching data.', { variant: 'error' })
                setData({ nodes: [], links: [] })
            } finally {
                setLoading(false)
            }
        })()
    }, [appName, showSnackbar])

    return [data, loading]
}
