import React, { useState } from 'react'
import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { GridRenderEditCellParams } from '@mui/x-data-grid-pro'

import { FormColumn } from 'genesis-suite/types/visualTypes'
import FormField from '../form/FormField'
import TadaFormSelect from './TadaFormSelect'
import { FormAttachmentField } from '../../widgets/visuals/FormWidget/FormAttachmentField'

const useStyles = makeStyles(({ palette }) => ({
    tadaSelect: { display: 'flex' },
    formField: { width: '100%' },
    //@ts-ignore
    cellRoot: { border: ({ flagged }) => (flagged ? `2px solid ${palette.status.error}` : '') },
}))

interface FormEditCellProps extends GridRenderEditCellParams {
    column: FormColumn
    columns: FormColumn[]
    flagged?: boolean
}

export default function FormEditCell(props: FormEditCellProps) {
    const { id, value, api, field, row, column, columns, flagged } = props
    const classes = useStyles({ flagged })
    const [open, setOpen] = useState(false)
    const { displayProperty, useTadaValues, lastUpdateField, required, property, editable } = column
    const { semanticType } = property
    const isAttachment = semanticType.baseDataType === 'File'
    const displayValue = displayProperty ? row[displayProperty.name] : null

    const handleClose = () => {
        setTimeout(() => {
            // stupid hack because date picker doesn't return an event
            api.setCellMode(id, field, 'view')
        }, 0)
    }

    const handleChange = async value => {
        api.setEditCellValue({ id, field, value })
        const isValid = await api.commitCellChange({ id, field })
        if (isValid) handleClose()
    }

    const defaults = {
        row,
        value,
        field: column,
        onEditComplete: handleChange,
    }

    const getComponent = () => {
        if (isAttachment) {
            const attachments = value && typeof value === 'string' ? value.split(',') : []
            return (
                <FormAttachmentField
                    required={required}
                    dataType={semanticType}
                    editable={editable}
                    onChange={handleChange}
                    propertyId={property.id}
                    attachments={attachments}
                />
            )
        } else if (useTadaValues && !lastUpdateField) {
            return (
                <TadaFormSelect
                    {...{
                        ...defaults,
                        displayValue,
                        initOpen: true,
                        hideLabel: true,
                        fields: columns,
                        disableUnderline: true,
                    }}
                />
            )
        } else {
            return (
                <FormField
                    {...{
                        ...defaults,
                        open,
                        location: 'cell',
                        onOpen: () => setOpen(true),
                        onClose: () => setOpen(false),
                    }}
                />
            )
        }
    }

    return (
        <Box className={classes.cellRoot} width="100%" height="100%">
            {getComponent()}
        </Box>
    )
}
