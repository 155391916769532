import React from 'react'
import LabelIcon from '@mui/icons-material/Label'
import LabelOffIcon from '@mui/icons-material/LabelOff'
import PrimaryAxisIcon from '@mui/icons-material/LooksOne'
import SecondaryAxisIcon from '@mui/icons-material/LooksTwo'
import { DebouncedTextField } from 'genesis-suite/components'
import { TextField, IconButton, Tooltip, Box } from '@mui/material'

import { Axis } from 'genesis-suite/types/visualTypes'
import EditorWrapper from './EditorWrapper'
import PlotLineEditor from './PlotLineEditor'
import BorderedSection from '../../BorderedSection'
import DisplayFormatFields from './DisplayFormatFields'

interface Props {
    onChange: (axis: Partial<Axis>) => void
    value: Axis
    title: string
    hideTicks?: boolean
}

export default function AxisEditor({ value, title, hideTicks, onChange }: Props) {
    return (
        <EditorWrapper header={title}>
            <SingleAxisEditor
                hideTicks={hideTicks}
                axisConfig={value}
                onChange={onChange}
                onFlip={opposite => onChange({ opposite })}
            />
        </EditorWrapper>
    )
}

interface SingleAxisEditorProps {
    axisConfig: Axis
    hideTicks?: boolean
    onChange: (arg: Axis) => void
    onFlip: (opposite: boolean) => void
}

const SingleAxisEditor = ({ axisConfig, hideTicks, onChange, onFlip }: SingleAxisEditorProps) => {
    const mergeChange = (change: Partial<Axis>) => onChange({ ...axisConfig, ...change })
    const { title, tickAmount, opposite, label, plotLines } = { ...axisConfig }
    const labelEnabled = label?.enabled ?? true

    return (
        <>
            <BorderedSection header="Basic" collapsable>
                <DebouncedTextField
                    fullWidth
                    value={title?.text ?? ''}
                    label="Axis title"
                    placeholder="Enter axis title"
                    onChange={text => mergeChange({ title: { text } })}
                />

                <Box display="flex" alignItems="center">
                    <Tooltip title="Labels">
                        <IconButton
                            onClick={() => mergeChange({ label: { ...label, enabled: !labelEnabled } })}
                            size="large"
                        >
                            {labelEnabled ? <LabelIcon color="secondary" /> : <LabelOffIcon color="secondary" />}
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Secondary">
                        <IconButton onClick={() => onFlip(!opposite)} size="large">
                            {opposite ? <SecondaryAxisIcon color="secondary" /> : <PrimaryAxisIcon color="secondary" />}
                        </IconButton>
                    </Tooltip>
                    {!hideTicks && (
                        <Box flex={1}>
                            <TextField
                                fullWidth
                                type="number"
                                margin="dense"
                                variant="outlined"
                                placeholder="Ticks"
                                value={tickAmount ?? ''}
                                onChange={e =>
                                    mergeChange({
                                        tickAmount: e.target.value === '' ? undefined : parseInt(e.target.value, 10),
                                    })
                                }
                            />
                        </Box>
                    )}
                </Box>
            </BorderedSection>

            <BorderedSection header="Display format" collapsable startCollapsed>
                <DisplayFormatFields
                    format={label?.format}
                    onChange={format => mergeChange({ label: { ...label, format } })}
                />
            </BorderedSection>

            <PlotLineEditor plotLines={plotLines} onChange={v => mergeChange({ plotLines: v })} />
        </>
    )
}
