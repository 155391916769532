import { isEmpty } from 'lodash'

import {
    BaseDataRequest,
    BaseSeries,
    ChartType,
    SeriesRequest,
    TableSeries,
    DataSource,
    RawDataSource,
    DataField,
    Service,
    Widget,
    InlineWidget,
    Aggregation,
    TooltipType,
    ValueTableColumn,
} from 'genesis-suite/types/visualTypes'
import { letMeMap } from 'genesis-suite/types/utilTypes'
import { getFieldNamesFromConfig } from '../../../lib/formUtils'
import { dataSourceIsEqual } from '../../edit_widget/utils'
import { getShowCount } from './aggregateFunctions'
import validateConfig from './validateConfig'
import makeValidFilters from './makeValidFilters'
import { tablePageSize } from './configDefaults'

export default function makeBaseDataRequest(config: Widget | InlineWidget): BaseDataRequest {
    if (
        config.type === ChartType.TEXT ||
        config.type === ChartType.WEBVIEW ||
        config.type === ChartType.TREE ||
        config.type === ChartType.FLOWCHART ||
        config.type === ChartType.SEARCH ||
        config.type === ChartType.MODULE_SELECTOR ||
        config.type === ChartType.FAVORITES ||
        config.type === ChartType.SPACIAL_MAP ||
        config.type === ChartType.CALCULATOR ||
        config.type === ChartType.RECOMMENDED_WIDGET
    )
        return
    if (!validateConfig(config)) return

    let series: SeriesRequest[]
    let service: SeriesRequest['service']
    let serviceDriven = true // if set to false, do auto insight

    switch (config.type) {
        case ChartType.FORM: {
            service = makeServiceRequest(config.source)
            const fieldNames = getFieldNamesFromConfig(config)

            series = [{ service, values: fieldNames.map(name => ({ field: { name }, aggregation: Aggregation.NONE })) }]
            break
        }
        case ChartType.TABLE:
            if (!config.series.length) {
                const categoryField = config.categories?.[0].field
                const value: ValueTableColumn = { field: categoryField, aggregation: Aggregation.COUNT }
                service = { name: categoryField.resourceName, type: categoryField.resourceType }
                series = [{ service, values: [createRequestSource(value, Boolean(service))] }]
            } else {
                series = (config.series as TableSeries[]).map(s => {
                    const { subSeries, filters } = s

                    const values = [...s.values]

                    s.values.forEach(({ conditionalFormats }) => {
                        if (conditionalFormats?.length) {
                            conditionalFormats.forEach(({ format, source, targetSource: s2 }) => {
                                if (isEmpty(format)) return

                                if (source && !values.some(v => dataSourceIsEqual(source, v))) values.push(source)
                                if (s2 && !values.some(v => dataSourceIsEqual(s2, v))) values.push(s2)
                            })
                        }
                    })

                    service = makeServiceRequest(s.service)
                    if (!service) serviceDriven = false

                    return {
                        service,
                        values: values.map(d => createRequestSource(d, Boolean(service))),
                        ...(subSeries && { subSeriesField: subSeries.field }),
                        filters: makeValidFilters(filters),
                        page: { size: s.pageSize ?? tablePageSize, number: 1 },
                    }
                })
            }
            break

        default:
            series = (config.series as BaseSeries[]).map(s => {
                const { subSeries, tooltip, filters, conditionalFormats, limit } = s

                const values = [...s.values]

                if (tooltip?.type === TooltipType.CUSTOM) {
                    tooltip.configs.forEach(({ source }) => {
                        if (!values.some(v => dataSourceIsEqual(source, v))) values.push(source)
                    })
                }

                if (conditionalFormats?.length) {
                    conditionalFormats.forEach(({ format, source, targetSource: s2 }) => {
                        if (isEmpty(format)) return

                        if (source && !values.some(v => dataSourceIsEqual(source, v))) values.push(source)
                        if (s2 && !values.some(v => dataSourceIsEqual(s2, v))) values.push(s2)
                    })
                }

                return {
                    service: makeServiceRequest(s.service),
                    values: values.map(d => createRequestSource(d, serviceDriven)),
                    rollup: config.type === 'rollup',
                    showCount: getShowCount(tooltip),
                    filters: makeValidFilters(filters),
                    ...(subSeries && { subSeriesField: createRequestField(subSeries.field, serviceDriven) }),
                    ...(limit && { limit }),
                }
            })
    }

    const { appName, categories, filters } = config

    return {
        application: appName,
        series,
        ...(categories && { categories: letMeMap(categories).map(c => createRequestField(c.field, serviceDriven)) }),
        filter: makeValidFilters(filters),
    }
}

export function createRequestSource(dataSource: DataSource, serviceDriven: boolean): RawDataSource {
    const { field, aggregation } = dataSource
    return { field: createRequestField(field, serviceDriven), aggregation }
}

export const createRequestField = (field: DataField, serviceDriven: boolean) =>
    serviceDriven
        ? { name: field.name }
        : {
              resourceType: field.resourceType,
              resourceId: field.resourceId,
              name: field.name,
              id: field.id,
              displayId: field.displayId,
              displayName: field.displayName,
          }

export function makeServiceRequest(service: Service): SeriesRequest['service'] {
    if (!service) return

    const { type, name, id } = service

    return { type, ...(name ? { name } : { id }), id: id }
}
