import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, matchPath } from 'react-router-dom'
import { useTheme, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Tour from 'reactour'

import { makeSteps } from '../lib/tourSteps'
import { appearanceCreators, navigationCreators, tourCreators } from '../actions/creators'
import { appearanceSelectors, menuSelectors, tourSelectors } from '../selectors'
import { routePaths } from '../lib/routes'
import { useFeature } from '../lib/featureFlags'

/**
 * reactour component to show tour modals throughout the app using css selectors
 * @param {object} props React props
 * @param {boolean} props.open run the tour
 * @param {number} props.step current tour step
 * @param {() => null} props.closeTour callback to close tour
 * @param {step:number => null} props.updateStep callback to update step number
 */

const useStyles = makeStyles({
    container: { minWidth: (p: any) => 26 * p.stepCount },
})

export default function AppTour() {
    const { pathname } = useLocation()
    const open = useSelector(tourSelectors.getOpen)
    const step = useSelector(tourSelectors.getStep)
    const sideNavOpen = useSelector(appearanceSelectors.getSideNavState)
    const views = useSelector(menuSelectors.getViews)
    const dispatch = useDispatch()
    const [showComments] = useFeature('comments')
    const [showProfiles] = useFeature('profiles')
    const [showTasks] = useFeature('tasks')

    const theme = useTheme()

    const steps = useMemo(() => {
        return makeSteps(showComments, showProfiles, showTasks, theme.palette.text.default)
    }, [showComments, showProfiles, showTasks])

    const classes = useStyles({ stepCount: steps.length })

    function updateStep(step) {
        dispatch(tourCreators.updateTourStep(step))
    }

    useEffect(() => {
        if (!open || !views?.length) return

        const { openSideNav, requiredRoute, goForwardOnPerspective } = steps[step]
        const atPerspective = matchPath(routePaths.PERSPECTIVE, pathname)
        const atHome = matchPath(routePaths.HOME, pathname)

        if ((!sideNavOpen && openSideNav) || (sideNavOpen && openSideNav === false))
            dispatch(appearanceCreators.toggleSideNav())

        if (atPerspective && goForwardOnPerspective) {
            updateStep(step + 1)
        } else if (atPerspective && requiredRoute === 'home') {
            dispatch(navigationCreators.goTo(routePaths.HOME))
        } else if (atHome && requiredRoute === 'perspective') {
            const firstPerspective = views[0].to || views[0].subMenuConfig[0].to
            dispatch(navigationCreators.goToPerspective(firstPerspective))
        }
    }, [open, step, views, pathname])

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
    if (!isDesktop) return null

    const { hideButtons, hideNavigation, enableInteraction, disableKeyboardNavigation, prevButton } = steps[step]

    return (
        <Tour
            className={classes.container}
            steps={steps}
            startAt={step}
            goToStep={step}
            isOpen={open}
            closeWithMask={false}
            prevButton={prevButton}
            rounded={5}
            accentColor={theme.palette.primary.main}
            onRequestClose={() => dispatch(tourCreators.watchedTour())}
            showButtons={!hideButtons}
            showNavigation={!hideNavigation}
            disableKeyboardNavigation={disableKeyboardNavigation}
            disableInteraction={!enableInteraction}
            getCurrentStep={updateStep}
        />
    )
}
