import { useDispatch, useSelector } from 'react-redux'
import {
    Dialog as MuiDialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Typography,
    Button,
    IconButton,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import Close from 'genesis-suite/icons/Close'
import { dialogCreators } from '../actions/creators'

const useStyles = makeStyles(({ spacing, palette }) => ({
    titleWrapper: { display: 'flex', alignItems: 'center' },
    titleText: { margin: '0 0 0 10px' },
    titleRoot: { padding: spacing(1) },
    backdrop: { cursor: 'not-allowed' },
    container: { cursor: 'default' },
    xsContainer: { maxWidth: '260px' },
    closeIcon: { padding: spacing(1), marginLeft: 'auto', color: palette.text.primary },
    content: { padding: spacing(1), '&:first-child': { paddingTop: spacing(1) }, color: palette.text.primary },
    actions: { margin: spacing(1), justifyContent: 'flex-end' },
}))

/**
 * Dialog component that opens multiple dialogs
 * @param {Object} props React props
 * @param {Object[]} props.dialog[] array of dialogs to display
 * @param {Object} props.dialog[].key unique value assigned to dialog to know how to close it
 * @param {string=} props.dialog[].title of the dialog
 * @param {any=} props.dialog[].componentProps to supply to content if jsx component
 * @param {boolean} props.dialog[].hideBackdrop default true. Hide the opaque gray backdrop overlay
 * @param {function=} props.dialog[].TitleIcon optional. JSX icon to display in the upper left by the title
 * @param {string | function} props.dialog[].content content of dialog body as string or function that returns jsx
 * @param {boolean=} props.dialog[].hideTitle optional. Hide the dialog title, title icon and cancel button
 * @param {boolean=} props.dialog[].fullWidth optional. Show full width
 * @param {boolean=} props.dialog[].maxWidth default 'xl'. Change mui Dialog maxWidth setting
 * @param {Object[]=} props.dialog[].buttons optional. array of the button with actions
 * @param {string} props.dialog[].buttons[].text button text
 * @param {function} props.dialog[].buttons[].onClick button callback
 * @param {('primary' | 'secondary')=} props.dialog[].buttons[].color optional. mui button color theme
 */
export default function Dialog() {
    const dialog = useSelector(s => s.dialog)
    const dispatch = useDispatch()
    const hide = key => dispatch(dialogCreators.hideDialog(key))

    const classes = useStyles()

    return dialog.map(item => {
        const {
            key,
            title,
            componentProps = {},
            hideBackdrop,
            TitleIcon,
            content: Content,
            buttons,
            fullWidth,
            hideTitle,
            maxWidth = 'xl',
            dialogContentStyle = {},
        } = item

        return (
            <MuiDialog
                key={key}
                open
                hideBackdrop={hideBackdrop}
                transitionDuration={200}
                maxWidth={maxWidth}
                fullWidth={fullWidth}
                classes={{ container: classes.backdrop, paper: classes.container, paperWidthXs: classes.xsContainer }}
            >
                {hideTitle ? null : (
                    <DialogTitle classes={{ root: classes.titleRoot }}>
                        <div className={classes.titleWrapper}>
                            {TitleIcon ? <TitleIcon /> : null}
                            <Typography variant="h6" className={classes.titleText}>
                                {title || ''}
                            </Typography>
                            <IconButton classes={{ root: classes.closeIcon }} onClick={() => hide()} size="large">
                                <Close />
                            </IconButton>
                        </div>
                    </DialogTitle>
                )}
                <DialogContent
                    classes={{ root: classes.content }}
                    sx={{ ...dialogContentStyle }}
                    data-dialog-content={key}
                >
                    {typeof Content === 'object' || typeof Content === 'function' ? (
                        <Content closeDialog={() => hide()} {...componentProps} />
                    ) : (
                        <DialogContentText className={classes.content}>{Content}</DialogContentText>
                    )}
                </DialogContent>
                {buttons ? (
                    <DialogActions classes={{ root: classes.actions }}>
                        {buttons.map((button, buttonI) => {
                            const { text, color, onClick } = button
                            return (
                                <Button variant="contained" key={buttonI} color={color} onClick={onClick}>
                                    {text}
                                </Button>
                            )
                        })}
                    </DialogActions>
                ) : null}
            </MuiDialog>
        )
    })
}
