//Keep track of the changes made in a table, if it's style only we refresh the table without fetching new data
//Only for Ag Grid tables

import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react'

type TableStyleChangesProviderProps = { children: React.ReactNode }

const TableStyleChangesContext = createContext<
    { tableStyleChange: boolean; setTableStyleChange: Dispatch<SetStateAction<boolean>> } | undefined
>(undefined)

const TableStyleChangesProvider = ({ children }: TableStyleChangesProviderProps) => {
    const [tableStyleChange, setTableStyleChange] = useState(false)
    const value = { tableStyleChange, setTableStyleChange }
    return <TableStyleChangesContext.Provider value={value}>{children}</TableStyleChangesContext.Provider>
}

const useTableStyleChanges = () => {
    const context = useContext(TableStyleChangesContext)
    if (context === undefined) throw new Error('useTableStyleChanges must be used within a TableStyleChangesProvider')
    return context
}

export { TableStyleChangesProvider, useTableStyleChanges, TableStyleChangesContext }
