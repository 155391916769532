import { Box, Slider, Tooltip, Typography, type SliderValueLabelProps } from '@mui/material'
import { useDispatch } from 'react-redux'
import { appearanceCreators } from '~/actions/creators'
import { FONT_SIZE, FontSize, getFontSize } from '../../../constants'

export default function FontSizeController() {
    const sizes = Object.values(FONT_SIZE)
    const fontSize = getFontSize()
    const fontSizeIndex = sizes.indexOf(fontSize)
    const dispatch = useDispatch()

    const handleFontSizeChange = (event: Event, value: number) => {
        const fontSizeKey = Object.keys(FONT_SIZE)[value] as keyof FontSize
        dispatch(appearanceCreators.setFontSize(fontSizeKey))
    }

    return (
        <Box>
            <Typography sx={{ fontWeight: 'bold' }} variant="h6">
                Font Size
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography fontSize={FONT_SIZE['x-small']} component="span">
                    Aa
                </Typography>
                <Slider
                    sx={{ mx: 1.5 }}
                    color="primary"
                    min={0}
                    marks
                    value={fontSizeIndex}
                    max={sizes.length - 1}
                    slots={{
                        valueLabel: FontSliderLabel,
                    }}
                    valueLabelDisplay="auto"
                    onChangeCommitted={handleFontSizeChange}
                />
                <Typography fontSize={FONT_SIZE['x-large']} component="span">
                    Aa
                </Typography>
            </Box>
        </Box>
    )
}

function FontSliderLabel(props: SliderValueLabelProps) {
    const { children, value } = props
    const fontSize = Object.values(FONT_SIZE)[value]

    return (
        <Tooltip enterTouchDelay={0} placement="top" title={<Typography sx={{ fontSize }}>Aa</Typography>}>
            {children}
        </Tooltip>
    )
}
