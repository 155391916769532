import { createSelector } from 'reselect'

const getUserList = (store: any) => store.messenger.userList || []

const getMessageList = (store: any) => store.messenger.messageList || []

const getLoader = (store: any) => store.messenger.showLoader

const getAllUserChats = (store: any) => store.messenger.userChatList || []

const getChatByUserId = userId =>
    createSelector([getAllUserChats], allChat => {
        const chat = allChat.find(chat => chat?.userData?.id === userId)
        if (!chat || !chat.chatMessages) return []

        return chat.chatMessages
    })

//Todo: Add Types after finalization
export const messengerSelectors = {
    getLoader,
    getUserList,
    getMessageList,
    getAllUserChats,
    getChatByUserId,
}
