import React, { useState, useContext, useEffect, useRef } from 'react'
import useSWR from 'swr'
import { useSnackbar } from 'notistack'
import makeStyles from '@mui/styles/makeStyles'
import StopIcon from '@mui/icons-material/Stop'
import LaunchIcon from '@mui/icons-material/Launch'
import HelpIcon from '@mui/icons-material/HelpOutline'
import { useSelector, useDispatch } from 'react-redux'
import { useBeforeUnload } from 'genesis-suite/hooks'
import { SwalContext } from 'genesis-suite/components'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material'

import Migrator from './migrator'
import BooleanSelect from '../BooleanSelect'
import { moduleTypes } from '../../actions/types'
import { visualService } from '../../lib/services'
import { Status, Progress } from './migrationTypes'
import { createPreMigration } from './convertWidget'
import { appearanceCreators, moduleCreators, navigationCreators } from '../../actions/creators'
import {
    appearanceSelectors,
    applicationSelectors,
    deploymentSelectors,
    moduleSelectors,
    widgetSelectors,
} from '../../selectors'
import { useSemanticTypeById } from '../../hooks/useSemanticTypes'

const useStyles = makeStyles(({ palette, spacing, shadows }) => ({
    migratingStatusBar: {
        zIndex: 1400,
        height: '95px',
        width: '250px',
        position: 'absolute',
        right: '70px',
        bottom: '0px',
        background: '#fff',
        boxShadow: shadows['10'],
    },
    header: {
        borderRadius: '4px 4px 0 0',
        background: palette.primary.main,
        color: palette.getContrastText(palette.primary.main),
        padding: spacing(0.5),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    stopIcon: { color: 'inherit' },
    content: {
        padding: spacing(0.5),
    },
}))

export default function MigrateModule() {
    const { confirm } = useContext(SwalContext)
    const { enqueueSnackbar: showSnackbar } = useSnackbar()

    const show = useSelector(moduleSelectors.getIsMigrating)
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const moduleName = useSelector(moduleSelectors.getModuleName)
    const moduleNames = useSelector(moduleSelectors.getAllModules)?.map(m => m.name)
    const semanticTypeById = useSemanticTypeById()
    const showSettings = useSelector(appearanceSelectors.getShowSettings)
    const dispatch = useDispatch()

    useBeforeUnload(show)

    const [progress, setProgress] = useState<Progress>(null)
    const [status, setStatus] = useState<Status>('not-started')
    const [newModuleName, setNewModuleName] = useState('')

    const classes = useStyles()
    const migrator = useRef<Migrator>(null)

    useEffect(() => {
        if (!show) return
        if (status === 'dismissed') return setStatus('complete')
    }, [show])

    if (!show || status === 'dismissed') return null

    async function handleStart(existingBuilderModuleId: string, merge?: boolean) {
        try {
            migrator.current = new Migrator(
                appName,
                moduleName,
                moduleNames,
                semanticTypeById,
                setStatus,
                setProgress,
                merge
            )
            const builderModule = await migrator.current.start()
            if (!builderModule) return cleanup()

            if (!merge) {
                if (existingBuilderModuleId)
                    dispatch({ type: moduleTypes.MODULE_DELETE, payload: { id: existingBuilderModuleId } })
                dispatch({ type: moduleTypes.MODULE_CREATE, payload: builderModule })
            }
            setNewModuleName(builderModule.name)
            setStatus('complete')
        } catch (e) {
            console.error(e)
            showSnackbar('An error occurred migrating module', { variant: 'error' })
            cleanup()
        }
    }

    async function handleRequestStop() {
        const response = await confirm('Stop conversion? You will have to start over later', { type: 'warning' })
        if (response.dismiss) return

        migrator.current.stop()
    }

    function handleReportDone(open) {
        if (!open) return setStatus('complete')

        if (showSettings) dispatch(appearanceCreators.toggleSettings())
        dispatch(navigationCreators.goToModule(newModuleName))
        cleanup()
    }

    function handleDismiss() {
        dispatch(moduleCreators.toggleMigration())
        setStatus('dismissed')
    }

    function cleanup() {
        dispatch(moduleCreators.toggleMigration())
        setProgress(null)
        setStatus('not-started')
        setNewModuleName('')
    }

    const progressPercent = getProgressPercent(progress)
    const isMigrating = status === 'converting-perspectives' || status === 'converting-widgets'

    const content = isMigrating ? (
        <>
            {status === 'converting-perspectives' ? (
                <Typography gutterBottom>
                    Converting {progress?.convertedPerspectiveCount} of {progress?.totalPerspectives} perspectives
                </Typography>
            ) : (
                <Typography gutterBottom>
                    Converting {progress?.convertedWidgets.length} of {progress?.totalWidgets} widgets
                </Typography>
            )}

            <LinearProgress variant="determinate" value={progressPercent} />
        </>
    ) : status === 'complete' ? (
        <>
            <Typography>Finished conversion</Typography>

            <Box display="flex" justifyContent="space-around">
                <Button size="small" variant="contained" onClick={handleDismiss}>
                    Close
                </Button>
                <Button size="small" variant="contained" color="primary" onClick={() => setStatus('report')}>
                    Open report
                </Button>
            </Box>
        </>
    ) : null

    return status === 'not-started' ? (
        <PreReport
            appName={appName}
            onStart={handleStart}
            onCancel={() => dispatch(moduleCreators.toggleMigration())}
        />
    ) : status === 'report' ? (
        <Report progress={progress} onDone={handleReportDone} />
    ) : (
        <div className={classes.migratingStatusBar}>
            <div className={classes.header}>
                <Typography color="inherit">Converting module</Typography>
                {isMigrating && (
                    <IconButton sx={{ color: 'inherit' }} size="small" onClick={handleRequestStop}>
                        <StopIcon className={classes.stopIcon} fontSize="small" />
                    </IconButton>
                )}
            </div>

            <div className={classes.content}>{content}</div>
        </div>
    )
}

function PreReport({ appName, onStart, onCancel }) {
    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const moduleId = useSelector(moduleSelectors.getModuleId)
    const configs = Object.values(useSelector(widgetSelectors.getWidgetConfigs)) as any
    const viewFlag = useSelector(deploymentSelectors.getDeploymentViewFlag)

    const { data, error } = useSWR(`moduleByVisorId/${moduleId}`, () =>
        visualService.getModuleByVisorId(appName, moduleId, viewFlag)
    )

    const [merge, setMerge] = useState(true)

    const perspectives = configs.filter(w => w.Type === 'Container')
    const widgets = configs.filter(
        w => w.Type !== 'Container' && perspectives.some(p => p.ContainerConfig.Cells.some(c => c.WidgetId === w.Id))
    )
    const { supported, unsupported } = createPreMigration(widgets)
    const loading = data === undefined

    function handleStart() {
        if (!data) return onStart()
        onStart(data.id, merge)
    }

    if (error) {
        showSnackbar('An error occurred migrating module', { variant: 'error' })
        onCancel()
    }

    if (loading) return null

    return (
        <Dialog open onClose={onCancel}>
            <DialogTitle>Convert module</DialogTitle>
            <DialogContent>
                {data && (
                    <>
                        <Typography variant="subtitle1" color="secondary">
                            Existing builder module found
                        </Typography>

                        <Box display="flex" gap="8px" alignItems="center">
                            <FormControlLabel
                                control={
                                    <BooleanSelect
                                        color="secondary"
                                        checked={merge}
                                        onChange={() => setMerge(s => !s)}
                                    />
                                }
                                label="Merge"
                            />

                            <Tooltip
                                placement="right-start"
                                title="Merge will add any new widgets and perspectives to the existing builder module w/o overwriting previously converted widgets and perspectives. Not merging will overwrite all widgets and perspectives in the builder module"
                            >
                                <HelpIcon />
                            </Tooltip>
                        </Box>
                    </>
                )}

                <Typography gutterBottom>{perspectives.length} total perspectives</Typography>
                <CountTree label="supported widgets" items={supported} />
                <CountTree label="unsupported widgets" items={unsupported} />

                <Typography gutterBottom variant="caption">
                    Converting will create a new builder module (not touching the existing module). The unsupported
                    widgets will not be converted. Are you sure you want to continue?
                </Typography>
            </DialogContent>

            <DialogActions>
                <Button onClick={onCancel}>Cancel</Button>
                <Button variant="contained" color="primary" onClick={handleStart}>
                    Start conversion
                </Button>
            </DialogActions>
        </Dialog>
    )
}

function Report({ progress, onDone }: { progress: Progress; onDone: (open: boolean) => void }) {
    const { totalPerspectives, convertedWidgets } = progress
    const showSettings = useSelector(appearanceSelectors.getShowSettings)
    const dispatch = useDispatch()

    function goToPerspective(id) {
        onDone(false)
        dispatch(navigationCreators.goToPerspective(id))
        if (showSettings) dispatch(appearanceCreators.toggleSettings())
    }

    const successfulWidgets = convertedWidgets.filter(w => w.status === 'success')
    const failedWidgets = convertedWidgets.filter(w => w.status === 'fail')
    const unsupportedWidgets = convertedWidgets.filter(w => w.status === 'not-supported')
    const alreadyConvertedWidgets = convertedWidgets.filter(w => w.status === 'already-converted')

    return (
        <Dialog open maxWidth="lg" onClose={() => onDone(false)}>
            <DialogTitle>Conversion report</DialogTitle>

            <DialogContent>
                <Typography gutterBottom>{totalPerspectives} converted perspectives</Typography>
                <CountTree label="converted widgets" items={successfulWidgets} />
                {alreadyConvertedWidgets.length ? (
                    <CountTree label="already converted widgets" items={alreadyConvertedWidgets} />
                ) : null}
                <CountTree label="unsupported widgets" items={unsupportedWidgets} />

                {failedWidgets.length ? (
                    <>
                        <Typography gutterBottom>{failedWidgets.length} failed widgets:</Typography>

                        <Box ml={2} maxHeight="40vh" overflow="auto">
                            <Table stickyHeader size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Widget title</TableCell>
                                        <TableCell>Error</TableCell>
                                        <TableCell>Perspective</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {failedWidgets.map(c => (
                                        <TableRow key={c.visorId}>
                                            <TableCell>{c.type}</TableCell>
                                            <TableCell>
                                                <Tooltip title={c.visorId}>
                                                    <Typography>{c.title}</Typography>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell>{c.failMessage}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() => goToPerspective(c.visorPerspectiveId)}
                                                    size="large"
                                                >
                                                    <LaunchIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </>
                ) : null}
            </DialogContent>

            <DialogActions>
                <Button variant="contained" onClick={() => onDone(false)}>
                    Close
                </Button>
                <Button variant="contained" color="primary" onClick={() => onDone(true)}>
                    Go to module
                </Button>
            </DialogActions>
        </Dialog>
    )
}

function CountTree({ label, items }) {
    const totalItems = items.reduce((acc, cur) => acc + (cur.count ?? 1), 0)
    const countsByType = getCountsByType(items)

    return (
        <>
            <Typography gutterBottom>
                {totalItems} {label}:
            </Typography>

            <Box ml={2}>
                {Object.keys(countsByType).map(type => (
                    <Typography key={type}>
                        {type} ({countsByType[type]})
                    </Typography>
                ))}
            </Box>
        </>
    )
}

function getCountsByType(typeAndCounts: Array<{ type: string; count?: number }>): { [type: string]: number } {
    return typeAndCounts.reduce((acc, cur) => {
        if (acc[cur.type] === undefined) acc[cur.type] = 0
        acc[cur.type] += cur.count ?? 1
        return acc
    }, {})
}

function getProgressPercent(progress: Progress) {
    if (!progress) return 0

    const { totalPerspectives, totalWidgets, convertedPerspectiveCount, convertedWidgets } = progress
    const decimalPercent = (convertedPerspectiveCount + convertedWidgets.length) / (totalPerspectives + totalWidgets)
    return Math.floor(decimalPercent * 100)
}
