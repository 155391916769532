import { navigationTypes } from '../actions/types'

const initialState = { pendingRoute: null }

export default (state = initialState, action) => {
    const { type } = action

    switch (type) {
        case navigationTypes.SET_PENDING_ROUTE:
            const { path, context } = action.payload
            return {
                ...state,
                pendingRoute: { path, context },
            }
        case navigationTypes.PENDING_ROUTE_COMPLETE:
            return { ...state, pendingRoute: null }
        default:
            return state
    }
}
