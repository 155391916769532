import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { Dialog, Box, IconButton, DialogContent, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import CloseIcon from '@mui/icons-material/Close'

import { insightSelectors } from '../../../selectors'
import ManageWidgets from '../../ManageWidgets'
import { DashboardContext } from './DashboardContext'

export default function ExistingWidgetsDialog() {
    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const { config, updateConfig, showExistingWidgetsDialog, toggleShowExistingWidgetsDialog } =
        useContext(DashboardContext)
    const focalPoint = config?.focalPoint
    const insightsWithNodeName = useSelector(s => insightSelectors.getInsightsWithFocalPoint(s, focalPoint))

    const resourceNames = focalPoint
        ? [focalPoint, ...insightsWithNodeName.map(i => i.name), '__none'].join()
        : undefined

    async function handleSelect(id: string) {
        const currentWidgets = config.widgets || []
        if (currentWidgets.some(w => w.id === id))
            return showSnackbar('Widget already exists on collection', { variant: 'error' })

        await updateConfig({ widgets: [...currentWidgets, { id }] }, true)
        toggleShowExistingWidgetsDialog()
    }

    return (
        <Dialog open={showExistingWidgetsDialog} maxWidth={false}>
            <Box m={1} mb={0} display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6">Widgets</Typography>
                <IconButton onClick={toggleShowExistingWidgetsDialog}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <DialogContent sx={{ height: 'calc(80vh - 100px)', width: '80vw', p: 0, display: 'flex' }}>
                <ManageWidgets resourceNames={resourceNames} selectOnly onSelect={handleSelect} />
            </DialogContent>
        </Dialog>
    )
}
