import { useState } from 'react'
import { Box, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { LineStyleRounded } from '@mui/icons-material'

import { FormData, FormSection, InputFormConfig } from 'genesis-suite/types/visualTypes'
import { BuilderProps } from './EditFormTypes'
import InputForm from '../widgets2/form/InputForm'
import FormTitleBuilder from './builders/FormTitleBuilder'

const useStyles = makeStyles(({ spacing }) => ({
    inputViewerRoot: {
        flex: 1,
        padding: 8,
        height: '100%',
        display: 'flex',
        flexFlow: 'column',
        overflow: 'hidden',
        position: 'relative',
    },
    header: {
        height: 50,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: spacing(1),
        borderBottom: `1px solid rgba(224, 224, 224, 1)`,
    },
    formEditBtn: { marginLeft: 'auto' },
}))

interface InputFormViewerProps extends BuilderProps {
    data: FormData
    config: InputFormConfig
    onSave: (data: FormData[]) => Promise<any>
}

export default function InputFormViewer(props: InputFormViewerProps) {
    const classes = useStyles()
    const [editing, setEditing] = useState(false)

    const { onChange, config } = props
    const { title } = config

    const toggleEdit = () => setEditing(!editing)

    const handleLayout = async (section: FormSection) => {
        if (!editing) return
        const { sections } = config

        const newSections = sections.map(s => {
            if (s.id === section.id) return section
            else return s
        })
        onChange({ sections: newSections })
    }

    return (
        <div className={classes.inputViewerRoot}>
            <Box className={classes.header}>
                <FormTitleBuilder title={title} onChange={title => onChange({ title })} />
                <IconButton className={classes.formEditBtn} onClick={toggleEdit}>
                    <LineStyleRounded />
                </IconButton>
            </Box>
            <InputForm {...props} defaults={{}} isEditingLayout={editing} onLayoutChange={handleLayout} />
        </div>
    )
}
