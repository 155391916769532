import { Box, Button, Typography } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import SaveIcon from '@mui/icons-material/SaveOutlined'

interface Props {
    title: string
    isNew: boolean
    isDirty: boolean
    /** (default true) */
    isValid?: boolean
    onDone: () => void
    onReset: () => void
}

export default function EditWidgetHeader({ title, isNew, isDirty, isValid = true, onDone, onReset }: Props) {
    const shouldSave = isNew && isDirty

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            height="53px"
            px={1}
            borderBottom="1px solid #bdbdbd"
        >
            <Typography variant="h5">{title}</Typography>

            <Box display="flex" gap="8px">
                {isDirty && (
                    <Button startIcon={<CloseIcon />} onClick={onReset}>
                        Reset
                    </Button>
                )}

                <Button
                    variant="contained"
                    startIcon={shouldSave ? <SaveIcon /> : <DoneIcon />}
                    disabled={!isValid && isDirty}
                    onClick={onDone}
                >
                    {shouldSave ? 'Save' : 'Done'}
                </Button>
            </Box>
        </Box>
    )
}
