import { useState } from 'react'
import { Container, Box } from '@mui/material'
import { isEqual } from 'lodash'

import { DebouncedTextField } from 'genesis-suite/components'
import TextWidget from './widgets2/text/TextWidget'
import { ChartType, TextConfig } from 'genesis-suite/types/visualTypes'
import EditWidgetHeader from './EditWidgetHeader'

interface Props {
    /** (optional) provided if updating existing config */
    config?: TextConfig
    isNew: boolean
    onDone: (config: TextConfig) => Promise<void>
}

export default function EditTextWidget({ config: initialConfig = emptyConfig, isNew, onDone }: Props) {
    const [config, setConfig] = useState(initialConfig)
    const isDirty = !isEqual(config, initialConfig)

    return (
        <Box flex={1} display="flex" flexDirection="column" overflow="hidden">
            <EditWidgetHeader
                title="Edit Text Widget"
                isNew={isNew}
                isDirty={isDirty}
                isValid={Boolean(config?.text)}
                onDone={() => onDone(isDirty ? config : undefined)}
                onReset={() => setConfig(initialConfig)}
            />

            <Container maxWidth="lg" sx={{ overflow: 'hidden', display: 'flex' }}>
                <Box flex={1} overflow="hidden" py={2} display="flex" flexDirection="column" gap="8px">
                    <DebouncedTextField
                        sx={{ width: '400px' }}
                        InputProps={{ sx: { typography: 'h6' } }}
                        value={config?.title ?? ''}
                        onChange={title => setConfig(c => ({ ...c, title }))}
                        placeholder="Title"
                        autoFocus={!config?.title}
                    />

                    <Box flex={1} overflow="hidden">
                        <TextWidget config={config} onChange={updated => setConfig(c => ({ ...c, ...updated }))} />
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

const emptyConfig: TextConfig = { version: '2', title: '', type: ChartType.TEXT, text: '' }
