import { Box, Typography, Skeleton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import isEmpty from 'lodash/isEmpty'
import { useDispatch, useSelector } from 'react-redux'

import { ChopText, MenuIcon } from 'genesis-suite/components'
import { InfiniteNav, Profile as ProfileIcon } from 'genesis-suite/icons'
import { NodeResource } from 'genesis-suite/types/networkTypes'
import { dialogCreators, navigationCreators } from '../../actions/creators'
import { useIsMobile } from '../../hooks/useIsMobile'
import { logEvent } from '../../lib/amplitudeClient'
import { widgetSelectors } from '../../selectors'

const itemHeight = 52

const useStyles = makeStyles(({ spacing, palette }) => {
    const gridGap = spacing()

    return {
        profileRootNode: { margin: spacing(0.5) },
        title: { flex: 1, marginLeft: spacing() },
        grid: {
            display: 'grid',
            gridTemplateColumns: `repeat(auto-fit, minmax(120px, 1fr))`,
            gridGap,
            overflow: 'auto',
            maxHeight: (p: any) => (p.hasLinked ? itemHeight * 2 + gridGap : ''),
        },
        propertyContainer: {
            height: itemHeight,
            background: palette.background.main,
            padding: spacing(),
            borderRadius: '4px',
        },
        icon: {
            color: palette.text.primary,
        },
    }
})

interface Props {
    node: NodeResource
    data: { [propertyName: string]: number | string }
    hasLinked: boolean
    loading: boolean
}

export default function ProfileRootNode({ node, data, hasLinked, loading }: Props) {
    const isMobile = useIsMobile()
    const { name, properties, profile } = node || {}
    //@ts-ignore
    const defaultPerspective = useSelector(s => widgetSelectors.getDefaultPerspectiveForNode(s, name))
    const dispatch = useDispatch()

    const displayData = profile?.properties
        .filter(p => data && p.name in data)
        .map(({ id, name }) => ({ name: properties.find(p => p.id === id)?.displayName, value: data[name] }))
    const classes = useStyles({ hasLinked })

    function openPerspective() {
        const keyPropertyName = properties.find(p => p.isPrimary)?.name
        const keyPropertyValue = data[keyPropertyName]
        const crumb = {
            DefaultPerspective: defaultPerspective,
            FieldName: keyPropertyName,
            Name: name,
            Value: keyPropertyValue,
        }
        dispatch(navigationCreators.goToPerspective(defaultPerspective, { context: crumb }))
        if (isMobile) dispatch(dialogCreators.hideDialog('profiles'))
        logEvent('OPEN_PERSPECTIVE_FROM_PROFILE')
    }

    const content = loading ? (
        <div className={classes.grid}>
            <SkeletonLoader />
        </div>
    ) : isEmpty(data) ? (
        <Typography>No profile data available.</Typography>
    ) : (
        <div className={classes.grid}>
            {displayData?.map(({ name, value }) => (
                <div key={name} className={classes.propertyContainer}>
                    <ChopText showEllipsis variant="caption" tooltipProps={{ placement: 'top' }}>
                        {name}
                    </ChopText>
                    <ChopText showEllipsis tooltipProps={{ placement: 'bottom' }}>
                        {value}
                    </ChopText>
                </div>
            ))}
        </div>
    )

    return (
        <div className={classes.profileRootNode}>
            <Box display="flex" alignItems="center">
                <ProfileIcon />
                <ChopText variant="h6" showEllipsis className={classes.title} tooltipProps={{ placement: 'top' }}>
                    {name || ''}
                </ChopText>

                {defaultPerspective && (
                    <MenuIcon
                        icon={<InfiniteNav className={classes.icon} fontSize="small" />}
                        tooltip="Default perspective"
                        onClick={openPerspective}
                    />
                )}
            </Box>

            {content}
        </div>
    )
}

function SkeletonLoader() {
    return (
        <>
            <div>
                <Skeleton variant="text" width="60%" height={24} />
                <Skeleton variant="text" width="80%" height={28} />
            </div>
            <div>
                <Skeleton variant="text" width="30%" height={24} />
                <Skeleton variant="text" width="90%" height={28} />
            </div>
        </>
    )
}
