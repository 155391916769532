import { useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import EditIcon from '@mui/icons-material/Edit'
import DoneIcon from '@mui/icons-material/Done'

import { MenuIcon } from 'genesis-suite/components'
import { authSelectors } from '../../../../selectors'
import { DashboardContext } from '../DashboardContext'
import { navigationCreators } from '../../../../actions/creators'
import { routePaths } from '../../../../lib/routes'

export default function EditTrigger() {
    const { config, editing, onEditDone, onEditStart } = useContext(DashboardContext)

    const isPowerUser = useSelector(authSelectors.getIsPowerUser)
    const userId = useSelector(authSelectors.getUserId)

    const disabled = isDisabled(config, isPowerUser, userId)

    const dispatch = useDispatch()

    async function handleClick() {
        if (!editing) onEditStart()
        else {
            await onEditDone()
            dispatch(navigationCreators.goTo(routePaths.HOME))
        }
    }

    const Icon = editing ? DoneIcon : EditIcon

    return (
        <MenuIcon
            buttonProps={{ sx: { color: 'inherit' } }}
            icon={<Icon />}
            title="Edit perspective"
            onClick={handleClick}
            disabled={disabled}
        />
    )
}

function isDisabled(dashboard, isPowerUser, userId) {
    if (!dashboard) return true

    const { createdBy, active } = dashboard
    if (createdBy?.id === userId) return false
    if (isPowerUser && active) return false

    return true
}
