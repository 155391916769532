import React from 'react'

import { Send, Help } from 'genesis-suite/icons'
import buildRoute from 'genesis-suite/utils/buildRoute'
import CollaborationDialog from '../../components/dialog_content/CollaborationDialog'
import LoadingDialog from '../../components/dialog_content/LoadingDialog'
import { dialogTypes } from '../types'
import history from '../../store/history'
import { routePaths } from '../../lib/routes'
import { widgetSelectors, collaborationSelectors } from '../../selectors'
import { moduleCreators } from '.'
import { navigationCreators } from './navigation.creators'

/**
 * Redux action to open a dialog
 * @param {string} key of the dialog
 * @param {string | function} content text to display or a function that returns jsx
 * @param {object=} options options object
 * @param {string=} options.title of the dialog
 * @param {any=} options.componentProps props to supply to content if jsx component
 * @param {[{text:string, color: 'primary' | 'secondary', onClick: function}]=} options.buttons array of buttons to display
 * @param {Node=} options.titleIcon jsx component icon to place left of the title
 * @param {boolean=} options.hideTitle default false. Hide the dialog title, title icon and cancel button
 * @param {boolean=} options.hideBackdrop default true. Hide the black opaque backdrop
 * @param {'xs' | 'sm' | 'md' | 'lg' | 'xl' | false} maxWidth default 'xl'. see mui Dialog api
 */
const showDialog = (key, content, options = {}) => {
    const { maxWidth = 'xl' } = options
    const hideBackdrop = options.hideBackdrop === undefined ? true : options.hideBackdrop

    return {
        type: dialogTypes.SHOW_DIALOG,
        payload: { key, content, ...options, maxWidth, hideBackdrop },
    }
}

/**
 * Redux action to close a dialog
 * @param {string} key dialog key you want to close
 */
const hideDialog = key => ({
    type: dialogTypes.HIDE_DIALOG,
    payload: { key },
})

/**
 * Loader dialog
 * @param {string} key unique dialog key of your choice
 * @param {string} text text to display in loader
 * @param {object} options - showDialog() options
 */
const showLoading =
    (key, text, options = {}) =>
    dispatch => {
        const content = () => <LoadingDialog text={text} />
        dispatch(showDialog(key, content, { hideTitle: true, maxWidth: 'sm', ...options }))
    }

/**
 * Show dialog on receiving content from a user
 * @param {string} fromId user id sending perspective
 * @param {*} content contents of message sent
 */
const showCollaborationDialog = (fromId, content) => {
    return (dispatch, getState) => {
        const message = JSON.parse(decodeURIComponent(content))

        if (message.Type && message && message.Props && message.Props.Config) {
            const state = getState()
            const user = collaborationSelectors.collaborators(state).filter(user => user.id === fromId)[0]

            const { Title, Id, Context } = JSON.parse(decodeURIComponent(message.Props.Config))
            const isPerspectiveInModule = !!widgetSelectors.getWidgetConfig(state, Id)

            const key = 'perspective-share: ' + user.id

            const content = () => (
                <CollaborationDialog
                    userName={user.name}
                    perspectiveName={Title}
                    isPerspectiveInModule={isPerspectiveInModule}
                />
            )

            const parsedContext = Context ? JSON.parse(Context) : null

            const buttons = [
                {
                    text: 'Decline',
                    onClick: () => dispatch(hideDialog(key)),
                },
                {
                    text: 'Accept',
                    color: 'primary',
                    onClick: () => {
                        dispatch(hideDialog(key))

                        if (!isPerspectiveInModule) {
                            history.replace(routePaths.HOME)
                            dispatch(
                                navigationCreators.setPendingRoute(
                                    buildRoute(routePaths.PERSPECTIVE, Id),
                                    parsedContext
                                )
                            )
                            dispatch(moduleCreators.getModuleWithPerspective({ id: Id, context: parsedContext }))
                        } else {
                            dispatch(navigationCreators.goToPerspective(Id, { context: parsedContext }))
                        }
                    },
                },
            ]

            dispatch(showDialog(key, content, { title: 'Perspective Shared', buttons, titleIcon: Send }))
        }
    }
}

/**
 * Show a confirmation dialog. Returns a promise with a boolean based on user's answer
 * @param {string} message of the dialog
 * @param {object} options options object, see showDialog for more details
 */
const confirmDialog =
    (message, options = { maxWidth: 'xs' }) =>
    dispatch =>
        new Promise(resolve => {
            const key = 'confirmationDialog'

            const buttons = [
                {
                    text: 'No',
                    onClick: () => {
                        resolve(false)
                        dispatch(hideDialog(key))
                    },
                },
                {
                    text: 'Yes',
                    color: 'primary',
                    onClick: () => {
                        resolve(true)
                        dispatch(hideDialog(key))
                    },
                },
            ]

            dispatch(
                showDialog(key, message, {
                    title: 'Confirm',
                    buttons,
                    titleIcon: Help,
                    hideBackdrop: false,
                    ...options,
                })
            )
        })

export const dialogCreators = { confirmDialog, showDialog, showLoading, showCollaborationDialog, hideDialog }
