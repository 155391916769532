import { useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Close } from 'genesis-suite/icons'
import { Paper, Button, Typography, Popper, IconButton, Tooltip, Hidden } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { moduleCreators } from '../actions/creators'
import { moduleSelectors } from '../selectors'

const useStyles = makeStyles(({ spacing }) => ({
    anchor: {
        position: 'absolute',
        bottom: 0,
        right: 0,
    },
    container: {
        height: 175,
        width: 500,
        maxWidth: '95vw',
        padding: spacing(1),
        margin: spacing(1),
        position: 'relative',
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: spacing(2),
    },
    reloadButton: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        margin: spacing(1),
    },
    zIndex: {
        zIndex: 99999,
    },
}))

const ModuleUpdate = () => {
    const classes = useStyles()
    const anchorRef = useRef()
    const dispatch = useDispatch()
    const isStale = useSelector(moduleSelectors.isStale)
    const dismissed = useSelector(moduleSelectors.isUpdateDismissed)
    const [open, setOpen] = useState(false)

    useEffect(() => {
        setOpen(!!(isStale && !dismissed && anchorRef)) //!! because required prop type is bool
    }, [isStale, dismissed, anchorRef.current])

    return (
        <>
            <div className={classes.anchor} ref={anchorRef} />
            {/* Don't hide the anchor div to avoid Popper element throwing error for null ref */}
            <Hidden mdDown>
                <Popper className={classes.zIndex} open={open} anchorEl={anchorRef.current}>
                    <Paper
                        classes={{
                            root: classes.container,
                        }}
                        elevation={12}
                    >
                        <div className={classes.titleContainer}>
                            <Typography variant="h6">Updates Detected</Typography>
                            <Tooltip title="Dismiss">
                                <IconButton
                                    onClick={() => dispatch(moduleCreators.moduleUpdateDismissed())}
                                    size="large"
                                >
                                    <Close />
                                </IconButton>
                            </Tooltip>
                        </div>

                        <Typography variant="body1">
                            We've detected changes to your App. Click below to refresh the Tada App.
                        </Typography>
                        <Button
                            className={classes.reloadButton}
                            onClick={() => dispatch(moduleCreators.reloadModule())}
                            variant="contained"
                            color="primary"
                        >
                            Refresh
                        </Button>
                    </Paper>
                </Popper>
            </Hidden>
        </>
    )
}

export default ModuleUpdate
