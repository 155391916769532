import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { IconButton, Tooltip } from '@mui/material'
import CompareIcon from '@mui/icons-material/Compare'
import { filterSelectors, widgetSelectors } from '../selectors'
import { navigationCreators, filterCreators } from '../actions/creators'
import { routePaths } from '../lib/routes'
import { useHotKey } from 'genesis-suite/hooks'
import { HotKeys, createTip } from '../lib/hotkeys'

export default function PerspectiveCompareFilterTrigger({ disabled, iconProps, buttonStyle }) {
    const [filters, setFilters] = useState(null)

    const context = useSelector(filterSelectors.getCoord)
    const perspectiveId = useSelector(widgetSelectors.getCurrentPerspectiveId)
    const filtersSelected = useSelector(filterSelectors.getAppliedPerspectiveFilters)

    const dispatch = useDispatch()

    function handleClick() {
        const state = context ? { context, compareContext: context } : undefined
        dispatch(navigationCreators.goTo(routePaths.COMPARE_PERSPECTIVE, perspectiveId, state))
        if (filters) {
            dispatch(filterCreators.applyPerspectiveFilters(filters))
        }
    }

    useEffect(() => {
        if (filtersSelected) {
            setFilters(filtersSelected)
        }
    }, [filtersSelected])

    useHotKey(HotKeys.Compare, handleClick)
    const keyTip = createTip(HotKeys.Compare)

    return (
        <Tooltip title={`Compare perspective ${keyTip}`}>
            <span>
                <IconButton sx={buttonStyle} onClick={handleClick} disabled={disabled}>
                    <CompareIcon {...iconProps} />
                </IconButton>
            </span>
        </Tooltip>
    )
}
