import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { widgetSelectors } from '../selectors'

/** For any route that uses perspectiveID, get id and determine if it is builder */
export default function useDashboardId() {
    const { perspectiveID } = useParams()
    const reduxId = useSelector(widgetSelectors.getCurrentPerspectiveId)

    const id = perspectiveID || reduxId

    return { id, isV2: !id?.includes('-') }
}
