import { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import produce from 'immer'

import {
    Dashboard,
    Module,
    Widget,
    VisualType,
    VisualTypeAndConfig,
    DraftStatus,
    DraftCountByStatus,
} from 'genesis-suite/types/visualTypes'
import { visualService } from '../../lib/services'
import { applicationSelectors, deploymentSelectors } from '../../selectors'

interface ApprovalsContextProps {
    counts: DraftCountByStatus
    /** Update status of visual */
    changeStatus: (status: DraftStatus) => Promise<void>
    deleteVisual: (id: string) => void
    selectedVisual: SelectedVisual
    selectVisual: (id: string) => void
    setCounts: Dispatch<SetStateAction<DraftCountByStatus>>
    /** add or update visuals */
    updateVisuals: (updates: VisualTypeAndConfig[], selectFirst?: boolean) => void
    visual?: VisualTypeAndConfig
    visuals: VisualTypeAndConfig[]
}

interface SelectedVisual {
    type: VisualType
    status: DraftStatus
    id?: string
}

const ApprovalsContext = createContext<Partial<ApprovalsContextProps>>({})

function ApprovalsProvider({ children }) {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const viewFlag = useSelector(deploymentSelectors.getDeploymentViewFlag)

    const [visuals, setVisuals] = useState<VisualTypeAndConfig[]>([])
    const [selectedVisual, setSelectedVisual] = useState<SelectedVisual>(null)
    const [counts, setCounts] = useState<DraftCountByStatus>(null)

    useEffect(() => {
        if (visuals.length) setVisuals([])
    }, [appName, viewFlag])

    useEffect(() => {
        if (counts && !selectedVisual) autoSelect()
    }, [counts])

    const visual = selectedVisual
        ? visuals?.find(v => v.type === selectedVisual.type && v.config.id === selectedVisual.id)
        : undefined

    async function changeStatus(status: DraftStatus) {
        const { config, type } = visual
        const { draft, id } = config

        try {
            selectNextVisual()
            await updateVisual(id, type, { draft: { ...draft, status } })
        } catch (err) {
            console.error(err)
        }
    }

    function autoSelect() {
        const { Editing, Pending } = counts
        const status = Editing.all.total ? DraftStatus.EDITING : Pending.all.total ? DraftStatus.PENDING : null
        if (!status) return

        const { dashboard, widget } = counts[status]
        const type = widget.total ? VisualType.WIDGET : dashboard.total ? VisualType.DASHBOARD : VisualType.MODULE
        setSelectedVisual({ status, type })
    }

    function selectNextVisual() {
        const { config, type } = visual
        const { draft, id } = config
        const { status } = draft
        const sameVisuals = visuals.filter(v => v.config.draft.status === status && v.type === type)

        if (sameVisuals.length === 1) {
            const countsByType = counts[status]
            if (countsByType.all.total === 1) return autoSelect()

            const totalCounts = {
                dashboard: countsByType.dashboard.total - (type === VisualType.DASHBOARD ? 1 : 0),
                module: countsByType.module.total - (type === VisualType.MODULE ? 1 : 0),
                widget: countsByType.widget.total - (type === VisualType.WIDGET ? 1 : 0),
            }

            const { dashboard, widget } = totalCounts
            const nextType = widget ? VisualType.WIDGET : dashboard ? VisualType.DASHBOARD : VisualType.MODULE
            setSelectedVisual({ status, type: nextType })
        } else {
            const index = sameVisuals.findIndex(v => v.config.id === id)
            sameVisuals.splice(index, 1)
            const nextVisual = sameVisuals[Math.min(index, sameVisuals.length - 1)]
            setSelectedVisual(s => ({ ...s, id: nextVisual.config.id }))
        }
    }

    async function updateVisual<T extends keyof UpdateVisual>(id: string, type: T, body: UpdateVisual[T]) {
        let updated
        switch (type) {
            case VisualType.DASHBOARD: {
                updated = await visualService.updateDashboard(appName, id, body as Partial<Dashboard>)
                break
            }
            case VisualType.MODULE: {
                updated = await visualService.updateModule(appName, id, body as Partial<Module>)
                break
            }
            case VisualType.WIDGET: {
                updated = await visualService.updateWidget(appName, id, body as Partial<Widget>)
                break
            }
        }

        updateVisuals([{ type, config: updated }])
    }

    function updateVisuals(updatedVisuals: VisualTypeAndConfig[], selectFirst = false) {
        if (!updatedVisuals?.length) return

        setVisuals(s =>
            produce(s, draft => {
                updatedVisuals.forEach(updatedVisual => {
                    const index = draft.findIndex(v => v.config.id === updatedVisual.config.id)
                    if (index > -1) draft[index] = updatedVisual
                    else draft.push(updatedVisual)
                })
            })
        )

        if (selectFirst) {
            const { type, config } = updatedVisuals[0]
            setSelectedVisual({ type, id: config.id, status: config.draft.status })
        }
    }

    function selectVisual(id) {
        const { type, config } = visuals.find(v => v.config.id === id)
        setSelectedVisual({ type, id: config.id, status: config.draft.status })
    }

    async function deleteVisual(id) {
        const { type } = visuals.find(v => v.config.id === id)
        switch (type) {
            case VisualType.DASHBOARD: {
                await visualService.deleteDashboard(appName, id)
                break
            }
            case VisualType.MODULE: {
                await visualService.deleteModule(appName, id)
                break
            }
            case VisualType.WIDGET: {
                await visualService.deleteWidget(appName, id)
                break
            }
        }

        selectNextVisual()
        setVisuals(s => s.filter(v => v.type !== type && v.config.id !== id))
    }

    return (
        <ApprovalsContext.Provider
            value={{
                counts,
                deleteVisual,
                visuals,
                selectVisual,
                selectedVisual,
                setCounts,
                updateVisuals,
                changeStatus,
                visual,
            }}
        >
            {children}
        </ApprovalsContext.Provider>
    )
}

interface UpdateVisual {
    [VisualType.DASHBOARD]: Partial<Dashboard>
    [VisualType.MODULE]: Partial<Module>
    [VisualType.WIDGET]: Partial<Widget>
}

export { ApprovalsContext, ApprovalsProvider }
