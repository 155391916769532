import { useSelector } from 'react-redux'
import { Box, useTheme } from '@mui/material'
import { ChopText } from 'genesis-suite/components'
import makeStyles from '@mui/styles/makeStyles'
import { appearanceConstants } from '~/constants'
import { widgetSelectors } from '~/selectors'
import SlideshowToggle from '~/components/SlideshowToggle'
import PerspectiveExport from '~/components/PerspectiveExport'
import PerspectiveShare from '~/components/PerspectiveShare'
const { ScrollBarWidth } = appearanceConstants

const useStyles = makeStyles(({ spacing, breakpoints, palette, border }) => ({
    rightTools: {
        alignSelf: 'center',
        backgroundColor: palette.background.sideNav,
        borderRadius: border.radius.round,
        display: 'flex',
        justifyContent: 'flex-end',
        padding: spacing(0.25),
    },
}))

export default function IndividualPerspectiveToolbar({ Id }) {
    const classes = useStyles()
    const { palette, custom } = useTheme()
    const config = useSelector(s => widgetSelectors.getWidgetConfig(s, Id))
    const defaultIconProps = { style: { fontSize: custom.perspectiveToolbar?.fontSize, color: palette.text.primary } }
    const buttonStyle = {
        padding: custom.perspectiveToolbar?.padding,
        '&.Mui-disabled': {
            cursor: 'default',
            pointerEvents: 'none',
            opacity: 0.5,
        },
    }

    return (
        <Box
            className={`perspective-toolbar`}
            sx={{ backgroundColor: 'background.main', paddingX: `${ScrollBarWidth}px`, pt: 1, width: '100%' }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <PerspectiveTitle title={config?.Title} />
                <div className={classes.rightTools} data-html2canvas-ignore>
                    <SlideshowToggle disabled={false} iconProps={defaultIconProps} buttonStyle={buttonStyle} />
                    <PerspectiveExport disabled={false} iconProps={defaultIconProps} buttonStyle={buttonStyle} />
                </div>
            </Box>
        </Box>
    )
}

function PerspectiveTitle({ title }) {
    return (
        <Box sx={{ flex: 1, overflow: 'hidden', display: 'flex', alignItems: 'center', gap: 1 }}>
            <ChopText variant="h6" showEllipsis>
                {title}
            </ChopText>
        </Box>
    )
}
