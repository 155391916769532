import React from 'react'

export default function ModernLayoutTile({ colors }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="102" height="75" fill="none" viewBox="0 0 102 75">
            <path
                fill={colors.content}
                fillRule="evenodd"
                d="M7.653 1C3.979 1 1 3.78 1 7.208v60.918c0 3.428 2.979 6.208 6.653 6.208h86.695c3.674 0 6.652-2.78 6.652-6.208V7.208C101 3.78 98.022 1 94.348 1H7.653z"
                clipRule="evenodd"
            ></path>
            <mask id="mask0" width="101" height="74" x="1" y="1" maskUnits="userSpaceOnUse">
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M7.653 1C3.979 1 1 3.78 1 7.208v60.918c0 3.428 2.979 6.208 6.653 6.208h86.695c3.674 0 6.652-2.78 6.652-6.208V7.208C101 3.78 98.022 1 94.348 1H7.653z"
                    clipRule="evenodd"
                ></path>
            </mask>
            <g mask="url(#mask0)">
                <path fill={colors.sideNav} d="M-11 78h48V1h-48v77z"></path>
            </g>
            <mask id="mask1" width="101" height="74" x="1" y="1" maskUnits="userSpaceOnUse">
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M7.653 1C3.979 1 1 3.78 1 7.208v60.918c0 3.428 2.979 6.208 6.653 6.208h86.695c3.674 0 6.652-2.78 6.652-6.208V7.208C101 3.78 98.022 1 94.348 1H7.653z"
                    clipRule="evenodd"
                ></path>
            </mask>
            <g mask="url(#mask1)">
                <rect width="26" height="36" x="41" y="34" fill={colors.widget} rx="2"></rect>
                <rect width="56" height="15" x="41" y="15" fill={colors.widget} rx="2"></rect>
                <rect width="26" height="36" x="71" y="34" fill={colors.widget} rx="2"></rect>
                <rect width="106.498" height="12" x="-1.242" y="-1" fill={colors.topBar} rx="2"></rect>
                <rect width="14.573" height="4" x="7.726" y="4" fill="#fff" rx="2"></rect>
                <rect width="4" height="4" x="92" y="4" fill={colors.primary} rx="2"></rect>
                <rect width="4" height="4" x="85" y="4" fill="#fff" rx="2"></rect>
                <rect width="4" height="4" x="77" y="4" fill="#fff" rx="2"></rect>
            </g>
            <rect width="7" height="7" x="8" y="16" fill="#4C4C4C" rx="2"></rect>
            <rect width="7" height="7" x="8" y="47" fill="#4C4C4C" rx="2"></rect>
            <rect width="23" height="3" x="8" y="27" fill="#4C4C4C" rx="1.5"></rect>
            <rect width="23" height="3" x="8" y="58" fill="#4C4C4C" rx="1.5"></rect>
            <rect width="23" height="3" x="8" y="34" fill="#4C4C4C" rx="1.5"></rect>
            <rect width="23" height="3" x="8" y="65" fill="#4C4C4C" rx="1.5"></rect>
            <path
                fillRule="evenodd"
                stroke="#8D8F92"
                strokeWidth="0.5"
                d="M94.348 74.334H7.652C3.979 74.334 1 71.554 1 68.126V7.208C1 3.78 3.979 1 7.652 1h86.696C98.022 1 101 3.78 101 7.208v60.918c0 3.428-2.978 6.208-6.652 6.208z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}
