import { Paper, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useContext } from 'react'

import { SwalContext } from 'genesis-suite/components'
import { Property } from 'genesis-suite/types/networkTypes'
import { letMeMap } from 'genesis-suite/types/utilTypes'
import { Aggregation, BoxPlotSeries, ChartType, MapSeries, SeriesConfig } from 'genesis-suite/types/visualTypes'
import { uppercaseFirstLetter } from 'genesis-suite/utils/stringUtils'
import BorderedSection from '../../BorderedSection'
import BorderedIconButton from '../BorderedIconButton'
import { ConfigContext } from '../ConfigContext'
import {
    configHasProperties,
    getAllProperties,
    getBoxPlotAggregations,
    getDataFieldProperty,
    getDefaultAggregation,
} from '../utils'
import widgetDictionary from '../../widgets2/utils/widgetDictionary'

const useStyles = makeStyles(() => ({
    sectionHeader: { fontWeight: 'bold' },
    chartTypeHeader: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    chartTypeContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
}))

interface Props {
    omit?: ChartType[]
}

export default function ChartSelector({ omit = [] }: Props) {
    const classes = useStyles()
    const theme = useTheme()
    const { confirm } = useContext(SwalContext)
    const { config, resources, dispatch } = useContext(ConfigContext)
    const { type: currentType, categories } = config

    const properties = getAllProperties(resources.byId)
    const hasCategories = Boolean(categories.length)
    const group = getChartGroup(currentType, hasCategories)
    const convertors = chartConvertor(config, properties, group)

    async function handleChange(status: Converter['status'], type: ChartType) {
        if (status === 'block') {
            const result = await confirm('Clear config and start over?', { type: 'warning' })
            if (result.dismiss) return

            dispatch({ type: 'INIT' })
            //@ts-ignore
            dispatch({ type: 'UPDATE_CONFIG', payload: { type } })
        } else {
            const newGroup = getChartGroup(type, hasCategories)
            const convertor = convertors[newGroup]

            //@ts-ignore
            dispatch({ type: 'UPDATE_CONFIG', payload: { ...convertor.config, type } })
        }
    }

    return (
        <BorderedSection header={`Widget Type: ${uppercaseFirstLetter(currentType)}`} collapsable>
            <Paper elevation={0} className={classes.chartTypeContainer}>
                {charts
                    .filter(c => !omit.includes(c))
                    .map(type => {
                        const { status } = convertors[getChartGroup(type, hasCategories)]
                        const { title, Icon } = widgetDictionary[type]
                        return (
                            <BorderedIconButton
                                key={type}
                                tipText={title}
                                Icon={Icon}
                                status={config.type === type ? 'active' : status === 'block' ? 'disabled' : 'default'}
                                indicatorButtonColor={
                                    config.type !== type && status === 'pass-through'
                                        ? theme.palette.status.success
                                        : undefined
                                }
                                onClick={() => handleChange(status, type)}
                            />
                        )
                    })}
            </Paper>
        </BorderedSection>
    )
}

const charts = [
    ChartType.COMBO,
    ChartType.TABLE,
    ChartType.PIE,
    ChartType.SORTED,
    ChartType.HEATMAP,
    ChartType.PACKED_BUBBLE,
    ChartType.LABEL,
    ChartType.MAP,
    ChartType.BOX_PLOT,
    ChartType.BULLET,
    ChartType.STATUS_TRACKER,
]

enum ChartGroup {
    /** Single series, multiple values with no aggregations */
    RAW_TABLE = 'rawTable',
    /** Single series, multiple values with aggregations and at least one category (subSeries field optional) */
    AGG_TABLE = 'aggTable',
    /** Multiple series with aggregations and one category (subSeries field optional) - Combo */
    MULTI = 'multi',
    /** Single series with aggregations and one category (subSeries field optional) - heat-map, packed-bubble */
    SINGLE_SUBSERIES = 'singleSubseries',
    /** Single series with aggregations and one category - pie, pyramid, funnel */
    SINGLE_BASIC = 'singleBasic',
    /** Aggregation of one number (no categories and no subSeries) - label, gage */
    SINGLE_AGGREGATION = 'singleAggregation',
    BOX_PLOT = 'boxplot',
    MAP = 'map',
    BULLET = 'bullet',
    STATUS_TRACKER = 'statustracker',
}

/** Determines if a conversion is immediate (pass-through), requires a data fetch or should be prevented all together (block) */
type Converter = { status: 'pass-through' | 'fetch'; config: Omit<SeriesConfig, 'type'> } | { status: 'block' }

function chartConvertor(
    config: SeriesConfig,
    properties: Property[],
    currentGroup: ChartGroup
): { [group in ChartGroup]: Converter } {
    const emptyConfig = !configHasProperties(config)

    const defaultGroupConfig: Converter = { status: 'pass-through', config }
    let rawTable: Converter = defaultGroupConfig
    let aggTable: Converter = defaultGroupConfig
    let multi: Converter = defaultGroupConfig
    let singleAggregation: Converter = defaultGroupConfig
    let singleSubSeries: Converter = defaultGroupConfig
    let singleBasic: Converter = defaultGroupConfig
    let boxPlot: Converter = defaultGroupConfig
    let bullet: Converter = defaultGroupConfig
    let map: Converter = defaultGroupConfig
    const statusTracker: Converter = emptyConfig ? defaultGroupConfig : { status: 'block' }

    const series = letMeMap(config.series)

    if (series?.length) {
        const boxPlotAggregations = getBoxPlotAggregations()

        const boxPlotSeries = series.map((currentSeries, index) => {
            const values: BoxPlotSeries['values'] = []

            if (index === 0) {
                boxPlotAggregations.forEach(aggregation => {
                    values.push({ ...currentSeries.values[0], aggregation })
                })
            } else values.push({ ...currentSeries.values[0] })

            return { ...currentSeries, values, subSeries: undefined }
        })

        switch (currentGroup) {
            case ChartGroup.RAW_TABLE: {
                const seriesFromValues = letMeMap(series[0]?.values)?.map(v => {
                    const { semanticType } = getDataFieldProperty(v.field, properties)
                    return {
                        service: series[0].service,
                        values: [{ field: v.field, aggregation: getDefaultAggregation(semanticType) }],
                    }
                })

                map = { status: 'block' }
                multi = {
                    status: 'fetch',
                    config: { ...config, series: seriesFromValues ?? [] },
                }
                boxPlot = {
                    status: 'fetch',
                    config: { ...config, series: boxPlotSeries ?? [] },
                }
                singleAggregation = {
                    status: 'fetch',
                    config: {
                        ...config,
                        series: [
                            {
                                ...series[0],
                                values: [
                                    {
                                        ...series[0].values[0],
                                        aggregation: getDefaultAggregation(
                                            getDataFieldProperty(series[0].values[0].field, properties).semanticType
                                        ),
                                    },
                                ],
                            },
                        ],
                    },
                }
                singleSubSeries = multi
                singleBasic = multi
                bullet = {
                    status: 'fetch',
                    config: {
                        ...config,
                        series: [{ ...series[0], subSeries: undefined }],
                    },
                }
                break
            }

            case ChartGroup.AGG_TABLE: {
                const values = letMeMap(series[0].values)
                const firstCategory = [config.categories[0]]

                const seriesFromValues = values.map(v => {
                    return {
                        service: series[0].service,
                        values: [{ field: v.field, aggregation: v.aggregation }],
                        subSeries: series[0].subSeries,
                    }
                })

                map = { status: 'block' }
                boxPlot = {
                    status: 'fetch',
                    config: {
                        ...config,
                        categories: firstCategory,
                        series: boxPlotSeries,
                    },
                }
                bullet = {
                    status: 'fetch',
                    config: {
                        ...config,
                        categories: firstCategory,
                        series: [{ ...series[0], subSeries: undefined }],
                        trellis: { enabled: true },
                    },
                }
                singleAggregation = {
                    status: config.categories?.length > 1 ? 'fetch' : 'pass-through',
                    config: {
                        ...config,
                        ...(config.categories?.length && {
                            categories: [config.categories[0]],
                            trellis: { enabled: true },
                        }),
                    },
                }

                if (config.categories.length > 1 || values.length > 1) {
                    multi = {
                        status: 'fetch',
                        config: {
                            ...config,
                            categories: firstCategory,
                            series: seriesFromValues,
                        },
                    }

                    singleSubSeries = {
                        status: 'fetch',
                        config: {
                            ...config,
                            categories: firstCategory,
                            series: [seriesFromValues[0]],
                        },
                    }
                }

                const { subSeries, ...rest } = seriesFromValues[0] || { values: [] }

                if (config.categories.length > 1 || values.length > 1 || series[0].subSeries)
                    singleBasic = {
                        status: 'fetch',
                        config: { ...config, categories: firstCategory, series: [rest] },
                    }

                break
            }

            case ChartGroup.MULTI: {
                const valuesFromSeries = series.map(s => s.values?.[0] ?? { field: undefined, aggregation: undefined })
                const subSeries = series.find(s => Boolean(s.subSeries))?.subSeries

                map = { status: 'block' }
                boxPlot = {
                    status: config.categories?.length > 1 ? 'fetch' : 'pass-through',
                    config: {
                        ...config,
                        categories: [config.categories[0]],
                        series: boxPlotSeries,
                        trellis: { enabled: false },
                    },
                }

                aggTable = {
                    status: 'fetch',
                    config: {
                        ...config,
                        series: [{ service: series[0].service, values: valuesFromSeries, subSeries }],
                        trellis: { enabled: false },
                    },
                }

                bullet = {
                    status:
                        config.series.length > 1 || config.series.length > 1 || config.series[0].subSeries
                            ? 'fetch'
                            : 'pass-through',
                    config: {
                        ...config,
                        categories: [config.categories[0]],
                        series: [{ ...config.series[0], subSeries: undefined }],
                        trellis: { enabled: true },
                    },
                }

                singleAggregation = {
                    status: config.categories?.length > 1 ? 'fetch' : 'pass-through',
                    config: {
                        ...config,
                        ...(config.categories?.length && {
                            categories: [config.categories[0]],
                            trellis: { enabled: true },
                        }),
                    },
                }

                singleSubSeries = {
                    status: 'fetch',
                    config: {
                        ...config,
                        ...(config.categories?.length && {
                            categories: [config.categories[0]],
                            trellis: { enabled: false },
                            ...(config.categories?.length > 1 && {
                                series: [{ ...config.series[0], subSeries: { field: config.categories[1].field } }],
                            }),
                        }),
                    },
                }

                singleBasic = {
                    status: subSeries ? 'fetch' : 'pass-through',
                    config: {
                        ...config,
                        series: [{ ...series[0], subSeries: undefined }],
                    },
                }

                break
            }

            case ChartGroup.BOX_PLOT: {
                const totalBoxPlotAggregations = getBoxPlotAggregations().length

                const seriesWithoutBoxPlotAgg = series.map(currentSeries => {
                    const dataFieldProperty = getDataFieldProperty(currentSeries.values[0]?.field, properties)

                    return currentSeries.values.length === totalBoxPlotAggregations
                        ? {
                              ...currentSeries,
                              values: [
                                  {
                                      ...currentSeries.values[0],
                                      aggregation: getDefaultAggregation(dataFieldProperty.semanticType),
                                  },
                              ],
                          }
                        : currentSeries
                })

                multi = {
                    status: 'fetch',
                    config: { ...config, series: seriesWithoutBoxPlotAgg },
                }

                bullet = {
                    status: 'fetch',
                    config: {
                        ...config,
                        series: [seriesWithoutBoxPlotAgg[0]],
                        trellis: { enabled: config.categories.length ? true : false },
                    },
                }

                singleAggregation = {
                    status: 'fetch',
                    config: { ...config, series: [seriesWithoutBoxPlotAgg[0]] },
                }

                map = { status: 'block' }
                aggTable = multi
                singleBasic = multi
                singleSubSeries = multi
                break
            }

            case ChartGroup.BULLET: {
                const configWithoutTrellis = { ...config, trellis: undefined }

                map = { status: 'block' }
                multi = {
                    status: config.categories.length === 0 ? 'fetch' : 'pass-through',
                    config: configWithoutTrellis,
                }
                rawTable = { status: 'fetch', config: configWithoutTrellis }
                aggTable = { status: 'fetch', config: configWithoutTrellis }
                singleSubSeries = { status: 'fetch', config: configWithoutTrellis }
                singleBasic = multi
                boxPlot = {
                    status: 'fetch',
                    config: { ...configWithoutTrellis, series: boxPlotSeries },
                }
                singleAggregation = {
                    status: 'fetch',
                    config: {
                        series: [{ ...series[0] }],
                    },
                }

                break
            }

            case ChartGroup.SINGLE_AGGREGATION: {
                const configWithoutTrellis = { ...config, trellis: undefined }

                aggTable = {
                    status: 'pass-through',
                    config: configWithoutTrellis,
                }
                map = { status: 'block' }
                boxPlot = {
                    status: 'fetch',
                    config: { ...config, series: boxPlotSeries },
                }
                multi = {
                    status: config.categories.length === 0 ? 'fetch' : 'pass-through',
                    config: configWithoutTrellis,
                }
                singleBasic = multi

                break
            }

            case ChartGroup.SINGLE_SUBSERIES: {
                map = { status: 'block' }
                boxPlot = {
                    status: 'fetch',
                    config: { ...config, series: boxPlotSeries },
                }

                bullet = {
                    status: 'fetch',
                    config: {
                        ...config,
                        categories: [config.categories[0]],
                        series: [{ ...series[0], subSeries: undefined }],
                        trellis: { enabled: undefined },
                    },
                }

                singleAggregation = {
                    status: 'fetch',
                    config: { ...config, categories: [], series: [{ ...series[0], subSeries: undefined }] },
                }

                if (!config.categories.length) {
                    const { values, ...rest } = series[0]
                    const value = { field: values[0].field, aggregation: Aggregation.NONE }
                    rawTable = {
                        status: 'fetch',
                        config: { ...config, series: [{ ...rest, values: [value] }] },
                    }
                }

                const { subSeries, ...rest } = series[0]
                if (subSeries)
                    singleBasic = {
                        status: 'fetch',
                        config: { ...config, series: [rest] },
                    }

                break
            }

            case ChartGroup.SINGLE_BASIC: {
                map = { status: 'block' }
                boxPlot = {
                    status: 'fetch',
                    config: { ...config, series: boxPlotSeries },
                }

                if (!config.categories.length) {
                    const { values, ...rest } = series[0]
                    const value = { field: values[0].field, aggregation: Aggregation.NONE }
                    rawTable = {
                        status: 'fetch',
                        config: { ...config, series: [{ ...rest, values: [value] }] },
                    }
                }

                singleAggregation = {
                    status: 'pass-through',
                    config: { ...config, categories: [config.categories[0]], trellis: { enabled: true } },
                }

                bullet = {
                    status: 'pass-through',
                    config: { ...config, trellis: { enabled: true } },
                }

                break
            }

            case ChartGroup.MAP: {
                const firstSeries = series[0] as MapSeries

                boxPlot = { status: 'block' }
                singleSubSeries = { status: 'block' }
                singleAggregation = { status: 'block' }

                if (firstSeries.type === 'marker') {
                    rawTable = {
                        status: 'pass-through',
                        config: { ...config, series: [firstSeries] },
                    }
                    multi = { status: 'block' }
                    bullet = { status: 'block' }
                    singleBasic = { status: 'block' }
                } else {
                    rawTable = { status: 'block' }
                    multi = { status: 'pass-through', config: { ...config, series: [firstSeries] } }
                    bullet = { ...multi, config: { ...multi.config, trellis: { enabled: true } } }
                    singleBasic = { status: 'pass-through', config: { ...config, series: [firstSeries] } }
                }

                break
            }

            case ChartGroup.STATUS_TRACKER: {
                if (!emptyConfig) {
                    aggTable = { status: 'block' }
                    multi = { status: 'block' }
                    singleAggregation = { status: 'block' }
                    singleSubSeries = { status: 'block' }
                    singleBasic = { status: 'block' }
                    boxPlot = { status: 'block' }
                    bullet = { status: 'block' }
                    map = { status: 'block' }
                }
                break
            }
        }
    }

    return {
        [ChartGroup.RAW_TABLE]: rawTable,
        [ChartGroup.AGG_TABLE]: aggTable,
        [ChartGroup.MULTI]: multi,
        [ChartGroup.SINGLE_AGGREGATION]: singleAggregation,
        [ChartGroup.SINGLE_SUBSERIES]: singleSubSeries,
        [ChartGroup.SINGLE_BASIC]: singleBasic,
        [ChartGroup.BOX_PLOT]: boxPlot,
        [ChartGroup.MAP]: map,
        [ChartGroup.BULLET]: bullet,
        [ChartGroup.STATUS_TRACKER]: statusTracker,
    }
}

function getChartGroup(chartType: ChartType, hasCategories: boolean): ChartGroup {
    switch (chartType) {
        case ChartType.TABLE:
            return hasCategories ? ChartGroup.AGG_TABLE : ChartGroup.RAW_TABLE
        case ChartType.COMBO:
            return ChartGroup.MULTI
        case ChartType.BULLET:
            return ChartGroup.BULLET
        case ChartType.BOX_PLOT:
            return ChartGroup.BOX_PLOT
        case ChartType.LABEL:
            return ChartGroup.SINGLE_AGGREGATION
        case ChartType.HEATMAP:
        case ChartType.PACKED_BUBBLE:
            return ChartGroup.SINGLE_SUBSERIES
        case ChartType.PIE:
        case ChartType.SORTED:
            return ChartGroup.SINGLE_BASIC
        case ChartType.MAP:
            return ChartGroup.MAP
        case ChartType.STATUS_TRACKER:
            return ChartGroup.STATUS_TRACKER
        default:
            console.error('getChartGroup, chart group not found!')
            return
    }
}
