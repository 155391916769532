import { Geometry } from 'geojson'
import tinyColor from 'tinycolor2'
import { Polygon, PolygonProps } from '@react-google-maps/api'

import { MapConfig, DataIndexes, MapHeatSeries, ColorGradient } from 'genesis-suite/types/visualTypes'
import { letMeMap } from 'genesis-suite/types/utilTypes'
import { WidgetProps } from '../../../types/WidgetTypes'
import { getLatLng, HeatGeoData, Limits } from './mapUtils'

interface Props extends Omit<WidgetProps<MapConfig>, 'data'>, HeatGeoData {
    seriesIndex: number
}

export default function MapHeat({ config, color, data, selectedPoints, seriesIndex, setSelectedPoint }: Props) {
    const seriesConfig = config.series[seriesIndex] as MapHeatSeries
    const { colorGradient } = seriesConfig
    const dataPoints = letMeMap(data.data)
    const colorLimits = dataPoints.reduce((acc, cur) => {
        const val = cur.color
        if (!val) return acc
        return { min: acc.min ? Math.min(acc.min, val) : val, max: acc.max ? Math.max(acc.max, val) : val }
    }, {} as Limits)

    function handleHover(event: PointerEvent, value: string) {
        const dataIndexes: DataIndexes = { series: seriesIndex, subSeries: true, groupNames: [value] }
        setSelectedPoint(event ? { event, dataIndexes } : null)
    }

    function handleClick(event: PointerEvent, value: string) {
        const dataIndexes: DataIndexes = { series: seriesIndex, subSeries: true, groupNames: [value] }
        setSelectedPoint({ clicked: true, event, dataIndexes })
    }

    return (
        <>
            {dataPoints.map((d, i) => {
                const options = makeOptions(color, d.color, colorLimits, colorGradient)
                const selected = selectedPoints.some(
                    p => p.series === seriesIndex && p.groupNames.indexOf(d.navigationValue)
                )
                return (
                    <Region
                        key={i}
                        {...d}
                        options={options}
                        selected={selected}
                        onHover={h => handleHover(h, d.navigationValue)}
                        onClick={e => handleClick(e, d.navigationValue)}
                    />
                )
            })}
        </>
    )
}

interface RegionProps {
    geometry: Geometry
    options: PolygonProps['options']
    onClick: (e: PointerEvent) => void
    selected: boolean
    onHover: (event?: PointerEvent) => void
}

function Region({ geometry, options, selected, onClick, onHover }: RegionProps) {
    let polygonPaths: Array<PolygonProps['paths']> = []

    switch (geometry.type) {
        case 'Polygon':
            polygonPaths = [geometry.coordinates[0].map(getLatLng)]
            break
        case 'MultiPolygon':
            polygonPaths = geometry.coordinates.map(poly => poly[0].map(getLatLng))
            break
        default:
            console.error(`Geometry type ${geometry.type} not supported`)
    }

    return (
        <>
            {polygonPaths.map((paths, i) => {
                return (
                    <Polygon
                        key={i}
                        paths={paths}
                        options={options}
                        onClick={e => onClick(e.domEvent as any)}
                        onMouseOver={e => onHover(e.domEvent as any)}
                        onMouseOut={() => onHover()}
                    />
                )
            })}
        </>
    )
}

function makeOptions(
    baseColor: string,
    value: number,
    limits: Limits,
    gradient: ColorGradient
): PolygonProps['options'] {
    const color = value == null ? baseColor : makeGradientColor(gradient, limits, value)

    return {
        fillColor: color,
        strokeColor: tinyColor(color).darken().toString(),
        fillOpacity: 0.5,
        strokeOpacity: 1,
        strokeWeight: 1,
        clickable: true,
        draggable: false,
        editable: false,
        geodesic: false,
        zIndex: 1,
    }
}

function makeGradientColor(gradient: ColorGradient, limits: Limits, value: number): string {
    const totalPosition = (value - limits.min) / (limits.max - limits.min)

    let relativePosition: number
    let color1: string
    let color2: string

    for (let i = 0; i < gradient.length; i++) {
        const option = gradient[i]
        if (option.position === totalPosition) return option.color

        if (option.position > totalPosition) {
            if (i === 0) return option.color

            const previousOption = gradient[i - 1]
            relativePosition = (totalPosition - previousOption.position) / (option.position - previousOption.position)
            color1 = previousOption.color
            color2 = option.color
            break
        }
    }

    if (!color1) return gradient[gradient.length - 1].color

    const color = tinyColor.mix(color1, color2, relativePosition * 100).toString()
    return color
}
