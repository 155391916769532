import { Box } from '@mui/material'
import { Suspense } from 'react'
import Loader from './Loader'

export default function SuspenseLoader({ children }: { children: React.ReactNode }) {
    return <Suspense fallback={<CenteredLoader />}>{children}</Suspense>
}

const CenteredLoader = () => (
    <Box sx={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <Loader />
    </Box>
)
