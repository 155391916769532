import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
} from '@mui/material'
import DragIcon from '@mui/icons-material/DragIndicator'
import AddIcon from '@mui/icons-material/Add'
import EmptyIcon from '@mui/icons-material/ImageOutlined'
import useSWR, { SWRConfiguration } from 'swr'
import { isEqual } from 'lodash'

import { Profile as ProfileIcon, Trash as TrashIcon } from 'genesis-suite/icons'
import { Element } from 'genesis-suite/types/visualTypes'
import {
    CognitiveIcon,
    CognitiveIconPicker,
    DebouncedTextField,
    DragItem,
    DragList,
    MenuIcon,
} from 'genesis-suite/components'
import { applicationSelectors, moduleSelectors } from '../selectors'
import { dialogCreators, moduleCreators } from '../actions/creators'
import { architectureService } from '../lib/services'
import EditProfile from './profile/EditProfile'

type ElementWithId = Element & { id: number }

export const editManageBusinessElementsDialogKey = 'manage-business-elements'

export default function ManageBusinessElements() {
    const nodes = useArchitectureNodes()
    const businessElements: Element[] = useSelector(moduleSelectors.getElements)
    const dispatch = useDispatch()

    const hide = () => dispatch(dialogCreators.hideDialog(editManageBusinessElementsDialogKey))

    const [items, setItems] = useState<ElementWithId[]>([])
    const [madeChange, setMadeChange] = useState(false)
    const [editProfile, setEditProfile] = useState('')

    const usedNodeNames = businessElements.map(b => b.nodeName)
    const availableNodeNames = nodes?.map(n => n.ResourceName).filter(n => !usedNodeNames.includes(n)) ?? []

    useEffect(() => {
        setItems(businessElements.map((item, id) => ({ ...item, id })))
        setMadeChange(false)
    }, [businessElements])

    function handleNew() {
        const newElement: ElementWithId = { title: '', nodeName: null, id: items.length }
        updateItems([...items, newElement])
    }

    function handleItemChange(index: number, update: Partial<Element>) {
        const newItems = items.map((item, i) => (i === index ? { ...item, ...update } : item))
        updateItems(newItems)
    }

    async function handleItemDelete(index: number) {
        updateItems(items.filter(item => item.id !== index))
    }

    async function updateItems(newItems: ElementWithId[]) {
        setItems(newItems)
        if (!madeChange) setMadeChange(true)
    }

    async function handleDone() {
        const elements = items.map(({ id, ...item }) => item)
        if (isEqual(elements, businessElements)) return hide()

        try {
            await dispatch(moduleCreators.updateCurrentModule({ elements }))
            hide()
        } catch (err) {
            console.error(err)
            setItems(items)
        }
    }

    return (
        <>
            <DragList onDragEnd={updateItems} items={items}>
                {items.map((item, index) => (
                    <DragItem key={item.id} itemId={item.id} index={index}>
                        <Item
                            {...item}
                            availableNodeNames={availableNodeNames}
                            onChange={update => handleItemChange(index, update)}
                            onOpenProfile={() => setEditProfile(item.nodeName)}
                            onDelete={() => handleItemDelete(item.id)}
                        />
                    </DragItem>
                ))}
            </DragList>

            <Button onClick={handleNew} startIcon={<AddIcon />}>
                Element
            </Button>

            <DialogActions>
                {madeChange && (
                    <Button color="warning" onClick={hide}>
                        Cancel
                    </Button>
                )}
                <Button variant="contained" onClick={handleDone}>
                    Done
                </Button>
            </DialogActions>

            <Dialog open={Boolean(editProfile)} onClose={() => setEditProfile('')}>
                <DialogTitle>Edit {editProfile} profile</DialogTitle>
                <DialogContent>
                    <EditProfile initialNodeName={editProfile} preventNodeChange onDone={() => setEditProfile('')} />
                </DialogContent>
            </Dialog>
        </>
    )
}

interface ItemProps extends Element {
    availableNodeNames: string[]
    onChange: (item: Partial<Element>) => void
    onOpenProfile: () => void
    onDelete: () => void
}

function Item({ icon, title, nodeName, availableNodeNames, onChange, onOpenProfile, onDelete }: ItemProps) {
    const [openIconPicker, setOpenIconPicker] = useState(false)
    return (
        <Box display="grid" gridTemplateColumns="20px 30px 200px 200px 30px 30px" alignItems="center" gap={1}>
            <DragIcon style={{ fontSize: 20 }} />
            <MenuIcon
                open={openIconPicker}
                tooltip={title}
                buttonProps={{ size: 'small' }}
                popoverProps={{
                    anchorOrigin: { vertical: 'top', horizontal: 'left' },
                    transformOrigin: { vertical: 'bottom', horizontal: 'left' },
                }}
                icon={icon ? <CognitiveIcon fontSize="small" icon={icon} /> : <EmptyIcon fontSize="small" />}
                onClick={() => setOpenIconPicker(true)}
                onClose={() => setOpenIconPicker(false)}
            >
                <CognitiveIconPicker
                    onSelect={icon => {
                        setOpenIconPicker(false)
                        onChange({ icon })
                    }}
                />
            </MenuIcon>
            <Autocomplete
                fullWidth
                value={nodeName}
                options={availableNodeNames}
                disableClearable={true}
                onChange={(e, nodeName, reason) => {
                    if (reason !== 'blur') onChange({ nodeName, title: title || nodeName })
                }}
                renderInput={params => <TextField {...params} placeholder="Node" />}
            />
            <DebouncedTextField
                placeholder="Title"
                color="primary"
                disabled={!nodeName}
                value={title}
                onChange={title => onChange({ title })}
            />

            <IconButton size="small" onClick={onOpenProfile}>
                <ProfileIcon fontSize="small" />
            </IconButton>
            <IconButton size="small" onClick={onDelete}>
                <TrashIcon fontSize="small" />
            </IconButton>
        </Box>
    )
}

function useArchitectureNodes() {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const swrOptions: SWRConfiguration = {
        revalidateOnFocus: false,
        errorRetryCount: 2,
        dedupingInterval: 60000,
    }

    const { data, error } = useSWR(
        ['architecture nodes', appName],
        ([_, appName]) => architectureService.getNodes(appName),
        swrOptions
    )

    return data
}
