import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { Box, TextField, Tooltip, Autocomplete, MenuItem } from '@mui/material'

import { Navigation } from 'genesis-suite/types/visualTypes'
import { visualService } from '../../../lib/services'
import { applicationSelectors, deploymentSelectors } from '../../../selectors'

const useStyles = makeStyles({
    autocomplete: { flex: 1 },
    textField: { margin: 0 },
})

interface Props {
    navigation: Navigation
    focalPoint: string
    onUpdate: (updated: Navigation) => void
}

export default function NavigationEditor({ navigation, focalPoint, onUpdate }: Props) {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const viewFlag = useSelector(deploymentSelectors.getDeploymentViewFlag)

    const [perspectives, setPerspectives] = useState([])

    const { enabled = true, perspectiveId } = navigation || {}
    const optionType: OptionType = !navigation ? 'default' : enabled ? 'specify' : 'none'

    const classes = useStyles()

    useEffect(() => {
        if (!focalPoint) return setPerspectives([])

        visualService
            .getMetaDashboards(appName, { active: true, focalPoint }, viewFlag)
            .then(b => setPerspectives(b.data))
    }, [focalPoint])

    function handleChange(updated: Partial<Navigation>) {
        onUpdate({ ...navigation, ...updated })
    }

    function handleTypeChange(type: OptionType) {
        switch (type) {
            case 'none':
                onUpdate({ enabled: false })
                break
            case 'specify':
                const perspectiveId = perspectives.length ? perspectives[0].id : undefined
                onUpdate({ enabled: true, perspectiveId })
                break
            case 'default':
                onUpdate(undefined)
                break
        }
    }

    return (
        <Box>
            <TextField
                select
                size="small"
                margin="dense"
                value={optionType}
                onChange={e => handleTypeChange(e.target.value as OptionType)}
            >
                {options.map(o => (
                    <MenuItem key={o.value} value={o.value}>
                        {o.label}
                    </MenuItem>
                ))}
            </TextField>

            {optionType === 'specify' && (
                <Autocomplete
                    disableClearable
                    size="small"
                    className={classes.autocomplete}
                    disabled={!perspectives.length}
                    options={perspectives}
                    getOptionLabel={p => p.title ?? ''}
                    isOptionEqualToValue={(p, v) => p.id === v.id}
                    value={perspectives.find(p => p.id === perspectiveId) || ''}
                    onChange={(e, newValue) => handleChange({ perspectiveId: newValue?.id ?? '' })}
                    renderInput={params => {
                        //@ts-ignore
                        const value = params.inputProps.value ?? ''
                        return (
                            <Tooltip placement="top" title={value}>
                                <TextField
                                    {...params}
                                    fullWidth
                                    variant="outlined"
                                    className={classes.textField}
                                    margin="dense"
                                    placeholder="Override"
                                />
                            </Tooltip>
                        )
                    }}
                />
            )}
        </Box>
    )
}

type OptionType = 'default' | 'specify' | 'none'

const options: Array<{ value: OptionType; label: string }> = [
    { value: 'default', label: 'Default' },
    { value: 'specify', label: 'Specify' },
    { value: 'none', label: 'Disable' },
]
