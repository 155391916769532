import React, { useState, useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { visualService } from '../../lib/services'
import { useSelector } from 'react-redux'
import { applicationSelectors, deploymentSelectors } from '../../selectors'
import { ChartType } from 'genesis-suite/types/visualTypes'

import LabelIcon from '@mui/icons-material/Label'
import DeleteIcon from '@mui/icons-material/Delete'
import Add from '@mui/icons-material/Add'
import {
    Box,
    Button,
    Tooltip,
    Typography,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    IconButton,
    FilledInput,
    InputAdornment,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';

const useStyles = makeStyles(({ palette }) => ({
    Icons: { color: palette.text.primary },
    Label: { color: palette.text.primary },
}))

export default function FlowchartPopup({ handleClose, selected, setFlowCharts, flowCharts }) {
    const styles = useStyles()
    const [itemName, setItemName] = useState('')
    const [title, setTitle] = useState('')
    const [from, setFrom] = useState('')
    const [to, setTo] = useState('')
    const [initialPosition, setinitialP] = useState(0)
    const [items, setItems] = useState([])
    const [edges, setEdges] = useState([])

    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const viewFlag = useSelector(deploymentSelectors.getDeploymentViewFlag)

    useEffect(() => {
        if (selected !== null) {
            setItems(flowCharts[selected]?.flow?.nodes)
            setEdges(flowCharts[selected]?.flow?.edges)
            setTitle(flowCharts[selected]?.title)
            if (flowCharts[selected]?.flow?.nodes?.length > 0) {
                const indexP = flowCharts[selected]?.flow?.nodes?.length - 1
                setinitialP(flowCharts[selected]?.flow?.nodes[indexP].position.y + 100)
            }
        }
    }, [selected])

    function handleClickAddItem() {
        if (itemName === '') return false
        const newItem = {
            id: (items.length + 1).toString(),
            data: { label: itemName },
            position: { x: 100, y: initialPosition },
        }
        setItems(items.concat(newItem))
        setItemName('')
        setinitialP(initialPosition + 100)
    }

    function handleChange(event) {
        setItemName(event.target.value)
    }
    function handleChangeName(event) {
        setTitle(event.target.value)
    }

    function addConection() {
        const item = {
            id: `${from}-${to}`,
            source: `${from}`,
            target: `${to}`,
            type: 'step',
        }
        setEdges(edges.concat(item))
        setFrom('')
        setTo('')
    }

    function removeItem(id) {
        const newitems = items.filter(item => item.id !== id)
        setItems(newitems)

        const newEdges = edges.filter(item => item.source !== id && item.target !== id)
        setEdges(newEdges)

        setinitialP(initialPosition - 100)
    }

    function removeEdge(id) {
        const newEdges = edges.filter(edge => edge.id !== id)
        setEdges(newEdges)
    }

    async function handleSave() {
        const nFlowCharts = [...flowCharts]
        if (selected !== null) {
            const ret = await visualService.updateWidget(appName, flowCharts[selected]?.id, {
                title,
                type: ChartType.FLOWCHART,
                flow: {
                    edges,
                    nodes: items,
                },
            })
            nFlowCharts[selected] = ret
        } else {
            const ret = await visualService.createWidget(
                appName,
                {
                    title,
                    type: ChartType.FLOWCHART,
                    flow: {
                        edges,
                        nodes: items,
                    },
                    version: '2',
                },
                viewFlag
            )
            nFlowCharts.push(ret)
        }
        setFlowCharts(nFlowCharts)
        handleClose()
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                        Information
                    </Typography>
                    <FormControl fullWidth sx={{ m: 1 }}>
                        <InputLabel className={styles.Label} htmlFor="title">
                            Title
                        </InputLabel>
                        <FilledInput id="title" type="text" value={title} onChange={handleChangeName} />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                        Graph Items
                    </Typography>
                    <FormControl fullWidth sx={{ m: 1 }}>
                        <InputLabel className={styles.Label} htmlFor="item-name">
                            Add Item
                        </InputLabel>
                        <FilledInput
                            id="item-name"
                            type="text"
                            value={itemName}
                            onChange={handleChange}
                            endAdornment={
                                <InputAdornment position="end">
                                    <Tooltip title="Add item">
                                        <IconButton
                                            className={styles.Icons}
                                            aria-label="Add Item"
                                            onClick={handleClickAddItem}
                                            edge="end"
                                        >
                                            <Add />
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <List dense={true}>
                        {items?.map(item => (
                            <ListItem
                                key={item.id}
                                secondaryAction={
                                    <Tooltip title="Delete item">
                                        <IconButton
                                            className={styles.Icons}
                                            onClick={() => removeItem(item.id)}
                                            edge="end"
                                            aria-label="delete"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                            >
                                <ListItemAvatar>
                                    <Avatar>
                                        <LabelIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={item.data.label} />
                            </ListItem>
                        ))}
                    </List>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                        Connection Items
                    </Typography>
                    <Box display="flex" justifyContent="flex-end" gap={1} m={1}>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel className={styles.Label} id="from-item">
                                From
                            </InputLabel>
                            <Select
                                labelId="from-item"
                                id="demo-from-item"
                                label="From"
                                onChange={evt => setFrom(evt.target.value)}
                                value={from}
                            >
                                {items.map(item => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.data.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel className={styles.Label} id="to-item">
                                To
                            </InputLabel>
                            <Select
                                value={to}
                                onChange={evt => setTo(evt.target.value)}
                                displayEmpty
                                labelId="to-item"
                                id="demo-to-item"
                            >
                                {items
                                    .filter(item => item.id !== from)
                                    .map(item => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.data.label}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ m: 1, paddingTop: '10px' }}>
                            <Tooltip title="Add connection">
                                <IconButton className={styles.Icons} onClick={addConection}>
                                    <Add />
                                </IconButton>
                            </Tooltip>
                        </FormControl>
                    </Box>
                    <List dense={true}>
                        {edges?.map(item => (
                            <ListItem
                                key={item.id}
                                secondaryAction={
                                    <Tooltip title="Delete connection">
                                        <IconButton
                                            className={styles.Icons}
                                            onClick={() => removeEdge(item.id)}
                                            edge="end"
                                            aria-label="delete"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                            >
                                <ListItemAvatar>
                                    <Avatar>
                                        <LabelIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={`From ${items.find(x => x.id === item.source)?.data?.label} to ${
                                        items.find(x => x.id === item.target)?.data?.label
                                    }`}
                                />
                            </ListItem>
                        ))}
                    </List>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Box display="flex" justifyContent="flex-end" gap={1} m={1}>
                        <Button color="error" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleSave}>
                            save
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}
