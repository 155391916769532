import { useContext } from 'react'
import produce from 'immer'
import { makeStyles } from '@mui/styles'
import { Box, Collapse, Typography } from '@mui/material'

import { BulletSeries, SeriesConfig } from 'genesis-suite/types/visualTypes'
import EditorWrapper from '../EditorWrapper'
import { FieldPointer } from '../../builderTypes'
import { ConfigContext } from '../../ConfigContext'
import ColorGradientEditor from '../ColorGradientEditor'
import useWidgetColors from '../../../../hooks/useWidgetColors'
import FormatEditorColorSelector from './FormatEditorColorSelector'
import pickFromCarousel from '../../../widgets2/utils/pickFromCarousel'
import { ParsedResponse } from '../../../../types/WidgetTypes'

const useStyles = makeStyles(({ palette }) => ({
    select: { minWidth: '100px' },
    disabledSelect: { color: palette.text.primary, '&::before': { borderBottomStyle: 'solid !important' } },
    actualValueColorLabel: {
        fontWeight: 'bold',
    },
    bandColorsLabel: {
        fontWeight: 'bold',
    },
}))

export default function BulletFormatEditor() {
    const { dispatch, dataResponse, selectedField, ...rest } = useContext(ConfigContext)
    const config = rest.config as SeriesConfig
    const widgetColors = useWidgetColors()
    const classes = useStyles()
    const seriesFieldSelected = selectedField.type === 'series'
    const activeSeriesIndex = selectedField.index ?? 0
    const activeSeries = config.series[activeSeriesIndex] as BulletSeries
    const { values, colors } = activeSeries || {}
    const activeField = values?.[0]?.field
    const activeFieldName = activeField?.name
    const seriesDefault = !Boolean(colors?.[activeFieldName]?.series)

    const seriesColor =
        colors?.[activeFieldName]?.series || pickFromCarousel(widgetColors, getHcIndex(dataResponse, selectedField))

    function handleSeriesDefaultChange(e) {
        const seriesColor = produce(activeSeries.colors[activeFieldName], draft => {
            delete draft.series
        })
        dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: { colors: { ...colors, [activeFieldName]: seriesColor } } })
    }

    function handleSeriesChange(color) {
        const seriesColor = produce(activeSeries.colors?.[activeFieldName] ?? {}, draft => {
            draft.series = color
        })
        dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: { colors: { ...colors, [activeFieldName]: seriesColor } } })
    }

    return (
        seriesFieldSelected && (
            <EditorWrapper header="Format" startOpen>
                <Box mt={1} />

                <Collapse in={seriesFieldSelected}>
                    <>
                        <Box>
                            <Typography className={classes.actualValueColorLabel} variant="body2">
                                Actual Value Color
                            </Typography>
                            <FormatEditorColorSelector
                                value={seriesColor}
                                onChange={handleSeriesChange}
                                resetButtonProps={{ disabled: seriesDefault, onClick: handleSeriesDefaultChange }}
                            />
                        </Box>

                        <Box mt={2} />
                    </>
                    <Box>
                        <Typography className={classes.bandColorsLabel} variant="body2">
                            Band Colors
                        </Typography>
                        <ColorGradientEditor
                            colorGradient={activeSeries.bands}
                            onChange={bands => dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: { bands } })}
                        />
                    </Box>
                </Collapse>
            </EditorWrapper>
        )
    )
}

/** return the highcharts series index of the selected field */
function getHcIndex(response: ParsedResponse, selectedField: FieldPointer) {
    if (!response || selectedField.type !== 'series') return

    const { data, groupNames } = response
    const { index, subSeries } = selectedField

    let hcIndex = 0
    for (const dataIndex in data) {
        const seriesIndex = parseInt(dataIndex)
        const subSeriesNames = subSeries && groupNames.bySeries[dataIndex]

        if (subSeriesNames) {
            if (seriesIndex === index) return hcIndex + subSeriesNames.indexOf(subSeries)
            hcIndex += subSeriesNames.length
        } else {
            if (seriesIndex === index) return hcIndex
            hcIndex++
        }
    }
}
