import React, { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useSelector } from 'react-redux'
import { commentService } from '../../lib/services'
import { logEvent } from '../../lib/amplitudeClient'
import { moduleSelectors, widgetSelectors } from '../../selectors'
import { useFeature } from '../../lib/featureFlags'

const CommentContext = React.createContext({})

const CommentProvider = ({ children }) => {
    const [data, setData] = useState({})
    const [error, setError] = useState('')
    const [useComments] = useFeature('comments')
    const [loading, setLoading] = useState(false)

    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const errorSnackbar = err => showSnackbar(err, { variant: 'error' })

    const pId = useSelector(widgetSelectors.getCurrentPerspectiveId)
    const moduleId = useSelector(moduleSelectors.getCurrentModule)?.id
    const widgets = useSelector(state => widgetSelectors.getWidgetsForPerspective(state, pId))

    useEffect(() => {
        if (widgets.length < 1 || !moduleId || !useComments) return

        setLoading(true)
        setData({})
        setError('')
        commentService
            .getCommentsByContainer(widgets, moduleId)
            .then(setData)
            .catch(({ message }) => {
                console.error(message)
                setError('Unable to fetch comments.')
            })
            .finally(() => setLoading(false))
    }, [widgets])

    const createComment = (widgetId, body) => {
        if (!useComments) return

        logEvent('COMMENT_CREATED')
        setLoading(true)
        commentService
            .createComment({
                widgetId,
                moduleId,
                body,
            })
            .then(comment => {
                const arr = data[widgetId] ? data[widgetId].comments : []
                const comments = [...arr, comment]
                setData({
                    ...data,
                    [widgetId]: {
                        comments,
                        totalRecords: comments.length,
                    },
                })
            })
            .catch(({ message }) => errorSnackbar(message))
            .finally(() => setLoading(false))
    }

    const deleteComment = (widgetId, id) => {
        if (!useComments) return

        logEvent('COMMENT_DELETED')
        setLoading(true)
        commentService
            .deleteComment(id, moduleId)
            .then(() => {
                const comments = data[widgetId].comments.filter(c => c._id !== id)
                setData({
                    ...data,
                    [widgetId]: {
                        comments,
                        totalRecords: comments.length,
                    },
                })
            })
            .catch(({ message }) => errorSnackbar(message))
            .finally(() => setLoading(false))
    }

    const editComment = (widgetId, comment) => {
        if (!useComments) return

        logEvent('COMMENT_EDITED')
        setLoading(true)
        const { _id: commentId, body } = comment
        return commentService
            .updateComment(commentId, { body }, moduleId)
            .then(result => {
                const { comments } = data[widgetId]
                setData({
                    ...data,
                    [widgetId]: {
                        comments: comments.map(c => (c._id === commentId ? result : c)),
                        totalRecords: comments.length,
                    },
                })
                return Promise.resolve()
            })
            .catch(({ message }) => {
                errorSnackbar(message)
                return Promise.reject()
            })
            .finally(() => setLoading(false))
    }

    return (
        <CommentContext.Provider value={{ data, loading, createComment, deleteComment, editComment, error }}>
            {children}
        </CommentContext.Provider>
    )
}

export { CommentContext, CommentProvider }
