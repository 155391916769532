import { Box, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { forwardRef } from 'react'
import { useSelector } from 'react-redux'

import { SearchWidgetConfig } from 'genesis-suite/types/visualTypes'
import ModelLastUpdated from '../../components/ModelLastUpdated'
import SearchController from '../search/SearchController'
import useBackgroundImage from '../../hooks/useBackgroundImage'
import { getGreetingTime } from '../../lib/utils'
import { authSelectors } from '../../selectors'

const useWrapperStyles = makeStyles(({ palette, spacing }) => ({
    widget: {
        height: '100%',
        flex: 'none',
        borderRadius: '8px',
        display: 'flex',
        gridGap: spacing(),
        alignItems: 'flex-end',
    },
}))

export default forwardRef<HTMLDivElement, { config: SearchWidgetConfig }>((_, ref) => {
    const classes = useWrapperStyles()

    return (
        <div ref={ref} className={classes.widget}>
            <Box
                flex={1}
                height="100%"
                overflow="hidden"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
            >
                <SearchWidget />
            </Box>
        </div>
    )
})

function SearchWidget() {
    const firstName = useSelector(authSelectors.getUserFirstName)

    const backgroundImage = useBackgroundImage()
    const fontColor = backgroundImage ? '#FFF' : 'inherit'

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                borderRadius: '15px',
                flexDirection: 'column',
                backgroundSize: 'cover',
                justifyContent: 'center',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundImage: backgroundImage
                    ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${backgroundImage}')`
                    : '',
                border: 1,
                borderColor: 'border.main',
            }}
        >
            <Typography variant="h5" gutterBottom sx={{ color: fontColor }}>
                Good {getGreetingTime()}, {firstName}
            </Typography>
            <ModelLastUpdated sx={{ color: fontColor, mb: 1 }} />
            <SearchController
                popoverProps={{
                    transformOrigin: { vertical: 'top', horizontal: 'center' },
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                }}
            />
        </Box>
    )
}
