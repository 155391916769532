import { Filter, FilterGroup, DataField } from 'genesis-suite/types/visualTypes'
import { cleanFilters } from 'genesis-suite/utils/filterUtils'

export default function makeValidFilters(filters: FilterGroup): FilterGroup {
    if (!filters) return

    const fieldProps = (field: DataField): DataField => ({
        resourceType: field.resourceType,
        resourceName: field.resourceName,
        name: field.name,
    })
    const getContent = (filter: Filter): Filter => ({
        ...filter,
        type: 'filter',
        field: fieldProps(filter.field),
        ...(filter.targetField && { targetField: fieldProps(filter.targetField) }),
    })
    const itemsMap = (f: Filter | FilterGroup): Filter | FilterGroup =>
        f.type === 'group' ? { ...f, items: f.items.map(itemsMap) } : getContent(f)

    const cleaned = cleanFilters(filters)
    const validFilters = { ...cleaned, items: cleaned.items.map(itemsMap) }
    return validFilters.items.length ? validFilters : undefined
}
