import { useContext } from 'react'
import produce from 'immer'
import {
    Box,
    Checkbox,
    Collapse,
    FormControlLabel,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import AlignLeftIcon from '@mui/icons-material/FormatAlignLeftRounded'
import AlignCenterIcon from '@mui/icons-material/FormatAlignCenterRounded'
import AlignRightIcon from '@mui/icons-material/FormatAlignRightRounded'
import RestoreIcon from '@mui/icons-material/Restore'
import HelpIcon from '@mui/icons-material/HelpOutline'

import { DebouncedTextField } from 'genesis-suite/components'
import { ResourceType } from 'genesis-suite/types/networkTypes'
import { TableConfig, ValueTableColumn } from 'genesis-suite/types/visualTypes'
import LabeledToggleButton from '../../../LabeledToggleButton'
import { ConfigContext } from '../../ConfigContext'
import CellFormatEditor from '../FormatEditor/CellFormatEditor'
import DisplayFormatFields from '../DisplayFormatFields'
import IconFormatFields from '../IconFormatFields'
import SortEditor from '../SortEditor'
import EditorWrapper from '../EditorWrapper'
import isRawSeries from '../../../widgets2/utils/isRawSeries'
import NavigationEditor from '../NavigationEditor'
import { getAllProperties, getDataFieldProperty } from '../../utils'
import AggregationSelect from '../../AggregationSelect'
import { useTableStyleChanges } from '../../../widgets2/contexts/TableStyleChangesContext'

export default function SeriesEditorTable() {
    const { selectedField } = useContext(ConfigContext)

    return selectedField.type === 'category' ? <CategoryField /> : <SeriesField />
}

function CategoryField() {
    const { dispatch, resources, selectedField, ...rest } = useContext(ConfigContext)
    const config = rest.config as TableConfig
    const { setTableStyleChange } = useTableStyleChanges()

    const activeColumn = config.categories[selectedField.index]
    const properties = getAllProperties(resources.byId)
    const { displayName } = getDataFieldProperty(activeColumn?.field, properties) || {}

    function update(update: Partial<ValueTableColumn>, layoutChange: boolean) {
        if ((config as TableConfig).dynamic) setTableStyleChange(layoutChange)
        const categories = produce(config.categories, draft => {
            draft[selectedField.index] = { ...draft[selectedField.index], ...update }
        })
        dispatch({ type: 'UPDATE_CATEGORIES', payload: categories })
    }

    if (!activeColumn) return null

    const { title, align, navigation, field } = activeColumn

    return (
        <>
            <TitleInput value={title} defaultValue={displayName} onChange={title => update({ title }, false)} />

            <Box sx={{ mb: 1 }}>
                <Typography variant="caption">Navigation</Typography>
                <NavigationEditor
                    focalPoint={field.resourceName}
                    navigation={navigation}
                    onUpdate={navigation => update({ navigation }, false)}
                />
            </Box>

            <Box sx={{ mb: 1 }}>
                <Typography variant="caption">Sorting</Typography>
                <SortEditor pointer={selectedField} />
            </Box>

            <AlignmentToggle value={align} onChange={align => update({ align }, true)} />
        </>
    )
}

function SeriesField() {
    const { dispatch, resources, selectedField, ...rest } = useContext(ConfigContext)
    const config = rest.config as TableConfig
    const { setTableStyleChange } = useTableStyleChanges()

    const activeSeries = config.series[selectedField.index]
    const valueIndex = selectedField.type === 'series' && selectedField.valueIndex
    const activeColumn = activeSeries?.values[valueIndex]
    const properties = getAllProperties(resources.byId)
    const { displayName } = getDataFieldProperty(activeColumn?.field, properties) || {}
    const isRaw = isRawSeries(activeSeries)
    const showNavigation = isRaw && activeColumn?.field.resourceType === ResourceType.NODE

    function update(update: Partial<ValueTableColumn>, layoutChange: boolean) {
        if ((config as TableConfig).dynamic) setTableStyleChange(layoutChange)

        const values = produce(activeSeries.values, draft => {
            draft[valueIndex] = { ...draft[valueIndex], ...update }
        })
        dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: { values } })
    }

    if (!activeColumn) return null

    const {
        title,
        align,
        isHyperlink,
        hyperlinkUrl,
        numberFormat,
        navigation,
        field,
        format,
        conditionalFormats,
        aggregation,
        icon,
    } = activeColumn

    return (
        <>
            {!isRaw && <AggregationSelect />}

            <TitleInput value={title} defaultValue={displayName} onChange={title => update({ title }, false)} />

            {showNavigation && (
                <Box sx={{ mb: 1 }}>
                    <Typography variant="caption">Navigation</Typography>
                    <NavigationEditor
                        focalPoint={field.resourceName}
                        navigation={navigation}
                        onUpdate={navigation => update({ navigation }, false)}
                    />
                </Box>
            )}

            <Box sx={{ mb: 1 }}>
                <Box sx={{ mb: 0.5, display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography variant="caption">Sorting</Typography>
                    <Tooltip
                        title="In a Dynamic Table you can click on the column header to sort the results. You can also sort by multiple columns by holding down the shift key while clicking on the column
                        header."
                    >
                        <HelpIcon fontSize="small" />
                    </Tooltip>
                </Box>
                <SortEditor pointer={selectedField} />
            </Box>

            <AlignmentToggle value={align} onChange={align => update({ align }, true)} />

            <Box sx={{ mb: 1 }}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <CheckBox
                        label="Hyperlink"
                        checked={isHyperlink ?? false}
                        onChange={({ target }) => update({ isHyperlink: target.checked }, false)}
                    />
                    <HyperlinkHelp />
                </Box>

                <Collapse in={isHyperlink}>
                    <DebouncedTextField
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        label="Hyperlink URL"
                        value={hyperlinkUrl || ''}
                        onChange={hyperlinkUrl => update({ hyperlinkUrl }, false)}
                    />
                </Collapse>
            </Box>

            <EditorWrapper header="Number format">
                <DisplayFormatFields format={numberFormat} onChange={numberFormat => update({ numberFormat }, true)} />
            </EditorWrapper>

            <CellFormatEditor
                serviceId={activeSeries.service.id}
                format={format}
                conditionalFormats={conditionalFormats}
                field={field}
                aggregation={aggregation}
                onFormatChange={format => update({ format }, true)}
                onConditionalFormatsChange={conditionalFormats => update({ conditionalFormats }, true)}
            />

            <EditorWrapper header="Icon format">
                <IconFormatFields icon={icon} onChange={icon => update({ icon }, true)} />
            </EditorWrapper>
        </>
    )
}

function TitleInput({ value, defaultValue, onChange }) {
    return (
        <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
            <DebouncedTextField
                fullWidth
                variant="outlined"
                margin="dense"
                label="Title"
                value={value ?? (defaultValue || '')}
                onChange={v => {
                    if (v === defaultValue) {
                        if (value) onChange(undefined)
                    } else {
                        onChange(v)
                    }
                }}
            />
            <IconButton size="small" disabled={value === undefined} onClick={() => onChange(undefined)}>
                <Tooltip title="Reset to default">
                    <RestoreIcon fontSize="small" />
                </Tooltip>
            </IconButton>
        </Box>
    )
}

function AlignmentToggle({ value, onChange }) {
    return (
        <Box sx={{ mb: 1 }}>
            <Typography variant="caption">Alignment</Typography>
            <LabeledToggleButton
                size="small"
                value={value}
                options={alignOptions}
                onChange={(e, value) => onChange(value)}
            />
        </Box>
    )
}

const alignOptions = [
    { label: <AlignLeftIcon />, value: 'left' },
    { label: <AlignCenterIcon />, value: 'center' },
    { label: <AlignRightIcon />, value: 'right' },
]

function CheckBox({ label, ...rest }) {
    return <FormControlLabel control={<Checkbox color="primary" {...rest} />} label={label} />
}

const useHyperlinkHelpStyles = makeStyles(() => ({
    hyperlinkHelpContainer: { minWidth: '470px' },
    cell: { padding: 0, borderBottom: 'none', fontSize: '0.7rem', color: 'inherit' },
}))

function HyperlinkHelp() {
    const classes = useHyperlinkHelpStyles()

    return (
        <Tooltip
            classes={{ tooltip: classes.hyperlinkHelpContainer }}
            title={
                <>
                    <Typography variant="h6" color="inherit">
                        Property hyperlinks
                    </Typography>
                    <Typography color="inherit">Selecting will make property values hyperlinks</Typography>
                    <Typography color="inherit" gutterBottom>
                        Adding url will override navigation url or append property to end
                    </Typography>
                    <Typography color="inherit">Example url overrides:</Typography>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell className={classes.cell}>https://some-site.com</TableCell>
                                <TableCell className={classes.cell}>go to https://some-site.com</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>https://some-site.com/</TableCell>
                                <TableCell className={classes.cell}>
                                    go to https://some-site.com/[property value]
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>https://some-site.com?</TableCell>
                                <TableCell className={classes.cell}>
                                    go to https://some-site.com?[property value]
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>https://some-site.com?param=</TableCell>
                                <TableCell className={classes.cell}>
                                    go to https://some-site.com?param=[property value]
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </>
            }
        >
            <HelpIcon fontSize="small" />
        </Tooltip>
    )
}
