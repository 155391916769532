import produce from 'immer'
import { LOCATION_CHANGE } from 'redux-first-history'
import { matchPath } from 'react-router-dom'
import moment from 'moment'

import { applicationTypes, filterTypes, interactionTypes, moduleTypes } from '../actions/types'
import { routePaths } from '../lib/routes'

const initialState = {
    all: [],
    back: [],
    forward: [],
}

export default (state = initialState, action) => {
    const currentBack = state.back[state.back.length - 1]
    function updateCurrentBack(update) {
        return produce(state, draft => {
            draft.back[draft.back.length - 1] = { ...draft.back[draft.back.length - 1], ...update }
        })
    }

    switch (action.type) {
        case applicationTypes.APPLICATION_SELECT:
        case moduleTypes.MODULE_CHANGE_REQUEST: {
            const lastLocation = state.back[state.back.length - 1]
            return { all: [lastLocation], back: [lastLocation], forward: [] }
        }
        case LOCATION_CHANGE: {
            const { location } = action.payload
            const { SELECT, LOGIN, CALLBACK, TV } = routePaths
            const appMatch = matchPath(`${SELECT}|${LOGIN}|${CALLBACK}`, location.pathname)

            if (appMatch) return initialState

            const tvMatch = matchPath(TV, location.pathname)
            if (tvMatch) return state

            const { all, back, forward } = state

            const backIndex = back.findIndex(entry => entry.location.key === location.key) + 1
            const forwardIndex = forward.findIndex(entry => entry.location.key === location.key) + 1

            // entry is already in history, move to the correct stack
            if (backIndex)
                return {
                    all,
                    back: back.slice(0, backIndex),
                    forward: [...back.slice(backIndex), ...forward],
                }
            if (forwardIndex)
                return {
                    all,
                    back: [...back, ...forward.slice(0, forwardIndex)],
                    forward: forward.slice(forwardIndex),
                }

            // going back to an old route that no longer exists in router
            const oldKey = location.state?.oldRouteKey
            const oldIndex = all.findIndex(i => i.location.key === oldKey)
            if (oldKey && oldIndex !== -1) {
                all[oldIndex].location.key = location.key
                return { all, back: [...back, all[oldIndex]], forward: [] }
            }

            // we went somewhere new, add to history and clear forward stack
            let nextEntry = { widgetsInfo: {}, ...action.payload, time: moment().format('h:mm A') }

            const perspectiveId = matchPath(routePaths.PERSPECTIVE, location.pathname)?.params?.perspectiveID
            if (perspectiveId) {
                nextEntry = { ...nextEntry, perspectiveId }
                const currentEntry = back[back.length - 1]
                const previousPerspectiveId =
                    currentEntry &&
                    matchPath(routePaths.PERSPECTIVE, currentEntry.location.pathname)?.params?.perspectiveID

                // carry filters over if we're navigating to within the same perspective
                if (currentEntry && perspectiveId === previousPerspectiveId) {
                    const { filters, currentWidgetId, inlineFilters, widgetsInfo } = currentEntry
                    nextEntry = { ...nextEntry, filters, currentWidgetId, inlineFilters, widgetsInfo }
                }
                if (location.state?.carryOverFilters) {
                    if (currentEntry) {
                        const { filters, inlineFilters, searchFilters, currentWidgetId, widgetsInfo } = currentEntry
                        nextEntry = {
                            ...nextEntry,
                            filters,
                            inlineFilters: location?.state?.inlineFilters
                                ? location.state.inlineFilters
                                : inlineFilters,
                            searchFilters,
                            currentWidgetId,
                            widgetsInfo,
                        }
                    } else {
                        nextEntry = {
                            ...nextEntry,
                            inlineFilters: location?.state?.inlineFilters ? location.state.inlineFilters : null,
                        }
                    }
                }
            }

            return {
                all: [nextEntry, ...all.slice(0, 39)],
                back: [...back, nextEntry],
                forward: [],
            }
        }
        case filterTypes.PERSPECTIVE_FILTER_CHANGE: {
            let backEntries = [...state.back]
            const newState = { ...backEntries.pop(), filters: action.payload.filters }
            return { ...state, back: [...backEntries, newState] }
        }
        case filterTypes.COMPARE_PERSPECTIVE_FILTER_CHANGE: {
            let backEntries = [...state.back]
            const newState = { ...backEntries.pop(), compareFilters: action.payload.filters }
            return { ...state, back: [...backEntries, newState] }
        }
        case filterTypes.SEARCH_FILTER_CHANGE: {
            let backEntries = [...state.back]
            const newState = { ...backEntries.pop(), searchFilters: action.payload.filters }
            return { ...state, back: [...backEntries, newState] }
        }
        case filterTypes.COMPARE_SEARCH_FILTER_CHANGE: {
            let backEntries = [...state.back]
            const newState = { ...backEntries.pop(), compareSearchFilters: action.payload.filters }
            return { ...state, back: [...backEntries, newState] }
        }
        case filterTypes.SET_CURRENT_WIDGET_ID:
            return produce(state, draftState => {
                draftState.back[draftState.back.length - 1].currentWidgetId = action.payload.currentWidgetId
            })
        case filterTypes.DELETE_CONTEXT_FILTER: {
            const currentFilters = state.back[state.back.length - 1].location.state.context.Filters
            let updatedDeletedFilters = []
            return produce(state, draftState => {
                let deletedFilters = action.payload.deletedContextFilters
                let filterIndex, deletedFilterIndex
                let foundFlag
                for (filterIndex = 0; filterIndex < currentFilters.length; filterIndex++) {
                    foundFlag = false
                    for (deletedFilterIndex = 0; deletedFilterIndex < deletedFilters.length; deletedFilterIndex++) {
                        if (currentFilters[filterIndex].FilterName === deletedFilters[deletedFilterIndex].FilterName) {
                            foundFlag = true
                            break
                        }
                    }
                    if (!foundFlag) updatedDeletedFilters.push(currentFilters[filterIndex])
                }
                draftState.back[draftState.back.length - 1].location.state.context.Filters = updatedDeletedFilters
            })
        }
        case filterTypes.SET_CONTEXT:
            return produce(state, draftState => {
                draftState.back[draftState.back.length - 1].location.state = { context: action.payload }
            })
        case filterTypes.SET_COMPARE_CONTEXT:
            return produce(state, draftState => {
                draftState.back[draftState.back.length - 1].location.state.compareContext = action.payload
            })
        case filterTypes.ADD_CONTEXT_FILTER:
            return produce(state, draftState => {
                draftState.back[draftState.back.length - 1].location.state.context.Filters = [
                    ...draftState.back[draftState.back.length - 1].location.state.context.Filters,
                    ...action.payload.addedContextFilters,
                ]
            })
        case filterTypes.SET_DELETED_FILTERS:
            return produce(state, draftState => {
                let deletedFilters = action.payload.deletedFilters
                draftState.back[draftState.back.length - 1].deletedFilters = deletedFilters
            })
        case filterTypes.RESET_DELETED_PERSPECTIVE_FILTERS:
            return produce(state, draftState => {
                if (draftState.back[draftState.back.length - 1].deletedFilters)
                    draftState.back[draftState.back.length - 1].deletedFilters.perspectiveFilters = []
            })
        case filterTypes.SET_INLINE_FILTERS: {
            let { contexts, widgetId, widgetData, scrollTop, pnlCellId } = action.payload
            const filters = action.payload.filters
            const inlineFilters = {
                contexts,
                filters,
                widgetId,
                widgetData,
                scrollTop,
                pnlCellId,
            }
            return produce(state, draftState => {
                draftState.back[draftState.back.length - 1].inlineFilters = inlineFilters
            })
        }
        case filterTypes.RESET_INLINE_FILTERS:
            return produce(state, draftState => {
                if (draftState.back[draftState.back.length - 1])
                    draftState.back[draftState.back.length - 1].inlineFilters = null
            })
        case interactionTypes.SET_TRENDLINES:
            const { trendlineSeries } = action.payload
            return produce(state, draftState => {
                if (!draftState.back[draftState.back.length - 1].widgetsInfo[action.payload.widgetId]) {
                    draftState.back[draftState.back.length - 1].widgetsInfo[action.payload.widgetId] = {}
                }

                draftState.back[draftState.back.length - 1].widgetsInfo[action.payload.widgetId].trendLines =
                    trendlineSeries
            })
        case filterTypes.SET_BUILDER_FILTERS: {
            const { filters = [], source } = action.payload
            const filtersWithSource = filters.map(f => ({ ...f, source }))

            const location = produce(currentBack.location, draft => {
                if (!draft.state) draft.state = {}
                if (!draft.state.filters) draft.state.filters = []

                draft.state.filters = [...draft.state.filters.filter(f => f.source !== source), ...filtersWithSource]
            })

            return updateCurrentBack({ location })
        }
        default:
            return state
    }
}
