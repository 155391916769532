import { Dialog, DialogContent } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classnames from 'classnames'
import { useState } from 'react'

import SlideshowContainer from '../containers/SlideshowContainer'
import { logEvent } from '../lib/amplitudeClient'

const useStyles = makeStyles({
    root: {
        zIndex: '999 !important',
    },
    paper: {
        width: '80vw',
        height: '80vh',
        overflow: 'visible !important',
    },
    paperExpanded: {
        height: '100vh',
        width: '100vw',
    },
    dialogContentRoot: {
        padding: '0 !important',
        overflow: 'visible !important',
    },
})

interface Props {
    /** widget configs to show */
    configs: any[]
    open: boolean
    onClose: () => void
    /** (default 0) */
    startIndex?: number
}

export default function Slideshow({ configs, open, onClose, startIndex = 0 }: Props) {
    const [expanded, setExpanded] = useState(false)
    const classes = useStyles()

    const handleExpand = () => {
        logEvent(expanded ? 'SLIDESHOW_COLLAPSE' : 'SLIDESHOW_EXPAND')
        setExpanded(expanded => !expanded)
    }

    const handleClose = () => {
        setExpanded(false)
        onClose()
    }

    return (
        open && (
            <Dialog
                open={open}
                maxWidth={false}
                fullScreen={expanded}
                className={classes.root}
                PaperProps={{
                    className: classnames({ [classes.paper]: !expanded, [classes.paperExpanded]: expanded }),
                }}
            >
                <DialogContent classes={{ root: classes.dialogContentRoot }}>
                    <SlideshowContainer
                        expanded={expanded}
                        onRequestClose={handleClose}
                        onExpand={handleExpand}
                        startIndex={startIndex}
                        widgetConfigs={configs}
                    />
                </DialogContent>
            </Dialog>
        )
    )
}
