import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'

import { sleep } from 'genesis-suite/utils'
import { ResourceType } from 'genesis-suite/types/networkTypes'
import { FormType, TableFormConfig } from 'genesis-suite/types/visualTypes'
import TableViewer from './TableViewer'
import ConfigHeader from './ConfigHeader'
import { BuilderProps } from './EditFormTypes'
import InputFormViewer from './InputFormViewer'
import { modelService } from '../../lib/services'
import { applicationSelectors } from '../../selectors'
import { buildLinkReq, buildNodeReq, getallPrompts } from '../../lib/formUtils'
import { Typography } from '@mui/material'

const useStyles = makeStyles(({ custom }) => ({
    previewer: { ...custom.builderContainer, flex: 1, overflow: 'hidden' },
}))

export default function FormPreviewer(props: BuilderProps) {
    const classes = useStyles()
    const { config, data: initData } = props
    const [data, setData] = useState([])
    const { appName, appId } = useSelector(applicationSelectors.getAppInfo)
    const { id: formId, source, loadInitialData: showData } = config

    useEffect(() => {
        setData(initData)
    }, [initData])

    const doSave = async (data, tableName) => {
        await modelService.saveResourceValues(appName, source.name, data, tableName, formId, '', '', '', appId)
    }

    const buildRequest = (rows: any[]) => {
        const fields = config.formType === 'table' ? config.columns : getallPrompts(config)
        return source.type === ResourceType.NODE
            ? buildNodeReq(rows, data, fields, source.name)
            : buildLinkReq(rows, data, fields)
    }

    const handleSave = async (rows: any[]) => {
        const request = buildRequest(rows)
        console.info(request)
        try {
            await sleep(1000) // doSave(request, null)
            setData(s => s.map(row => rows.find(r => r.id === row.id) ?? row))
            return Promise.resolve()
        } catch (err) {
            setData([...data])
            return Promise.reject()
        }
    }

    const handleUpload = async (rows: any[], fileName: string) => {
        if (!fileName) {
            const request = buildRequest(rows)
            console.log(request)
        } else {
            console.log(fileName)
        }
        await sleep(1000)
    }

    const formatForDelete = (row: any) => {
        const { columns } = config as TableFormConfig
        if (source.type === ResourceType.NODE) {
            const {
                property: { name },
            } = columns.find(c => c.property.isPrimary)
            return row[name]
        } else {
            return columns.reduce((acc, { property }) => {
                const { name, isPrimary } = property
                if (isPrimary) acc[name] = row[name]
                return acc
            }, {})
        }
    }

    const handleDelete = async (rows: any[]) => {
        const { name: sourceName } = source
        const request = rows.map(row => formatForDelete(row))
        await modelService.deleteResourceValues(appName, sourceName, request)
    }

    const getViewer = () => {
        switch (config.formType) {
            case FormType.TABLE:
                return (
                    <TableViewer
                        {...props}
                        config={config}
                        onSave={handleSave}
                        onDelete={handleDelete}
                        data={showData ? data : []}
                        onExcelUpload={handleUpload}
                    />
                )
            case FormType.INPUT:
                return (
                    <InputFormViewer
                        {...props}
                        config={config}
                        onSave={handleSave}
                        data={showData ? data[0] || { isNew: true } : { isNew: true }}
                    />
                )
            default:
                return <Typography>No previewer found for form type {config.formType}.</Typography>
        }
    }

    return (
        <>
            <ConfigHeader title="Previewer" />
            <div className={classes.previewer}>{getViewer()}</div>
        </>
    )
}
