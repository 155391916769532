import CompareIcon from '@mui/icons-material/Compare'
import { Box, IconButton, ListItem, TextFieldProps, Tooltip } from '@mui/material'
import produce from 'immer'
import { useDispatch, useSelector } from 'react-redux'

import { ChopText } from 'genesis-suite/components'
import { Option } from 'genesis-suite/components/AsyncSelect'
import { filterCreators, navigationCreators } from '../../actions/creators'
import { useFeature } from '../../lib/featureFlags'
import { routePaths } from '../../lib/routes'
import { filterSelectors, widgetSelectors } from '../../selectors'
import AsyncResourceValueSelect from '../AsyncResourceValueSelect'

interface Props {
    /** (optional) allow user to navigate to compare view enabling compare icon buttons in options list */
    isCompare?: boolean
    /** (default normal) */
    source?: 'normal' | 'compare'
    textFieldProps?: TextFieldProps
}

export default function FocalPointSelect({ isCompare, source = 'normal', textFieldProps }: Props) {
    const baseContext = useSelector(filterSelectors.getCoord)
    const compareContext = useSelector(filterSelectors.getCompareCoord)
    const context = source === 'compare' ? compareContext : baseContext
    const perspective = useSelector(widgetSelectors.getCurrentPerspectiveConfig)
    const dispatch = useDispatch()

    function handleCompare(option: Option) {
        const { CloudName, ModelName, Type, ...currentContext } = context
        const compareContext = updateContext(currentContext, option)
        const state = { context: currentContext, compareContext }
        dispatch(navigationCreators.goTo(routePaths.COMPARE_PERSPECTIVE, perspective?.ContainerConfig?.Id, state))
    }

    function handleChange(option: Option) {
        const updated = updateContext(context, option)
        const actionCreator = source === 'compare' ? filterCreators.setCompareContext : filterCreators.setContext
        dispatch(actionCreator(updated))
    }

    function updateContext(context, option: Option) {
        const matchContextToFilter = f => f.ResourceName === context?.Name && f.PropertyName === context.FieldName
        const index = context.Filters?.findIndex(matchContextToFilter) ?? -1
        return produce(context, draft => {
            draft.Value = option.value
            draft.DisplayValue = option.label
            if (index > -1) draft.Filters[index].Values = [option.value]
        })
    }

    if (!context?.FieldName || context?.Value == null) return null

    return (
        <Box sx={{ width: '200px' }}>
            <AsyncResourceValueSelect
                componentsProps={{ popper: { style: { width: '250px' } } }}
                disableCloseOnSelect={false}
                disableClearable
                displayPropertyName={context?.DisplayFieldName}
                keyPropertyName={context.FieldName}
                multiple={false}
                onChange={(e, v: Option) => handleChange(v)}
                resourceName={context?.Name}
                renderOption={
                    isCompare
                        ? (props, option) => (
                              <ListItem
                                  {...props}
                                  key={option.value}
                                  sx={{
                                      display: 'flex',
                                      justifyContent: 'space-between !important',
                                      padding: '0 8px !important',
                                      height: '30px',
                                      '&> *:last-child': { display: 'none' },
                                      '&:hover': { '&> *:last-child': { display: 'block' } },
                                  }}
                              >
                                  <ChopText showEllipsis tooltipProps={{ placement: 'left' }}>
                                      {option.label}
                                  </ChopText>
                                  <Tooltip title="Compare">
                                      <IconButton
                                          size="small"
                                          onClick={e => {
                                              e.stopPropagation()
                                              handleCompare(option)
                                          }}
                                      >
                                          <CompareIcon
                                              fontSize="small"
                                              sx={theme => ({ color: theme.palette.text.primary })}
                                          />
                                      </IconButton>
                                  </Tooltip>
                              </ListItem>
                          )
                        : undefined
                }
                textFieldProps={textFieldProps}
                value={{ value: context.Value, label: context?.DisplayValue }}
            />
        </Box>
    )
}
