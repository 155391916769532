import ExpandMore from '@mui/icons-material/ExpandMore'
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Popover,
    Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import produce from 'immer'
import { uniq } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'

import { ChopText } from 'genesis-suite/components'
import AggregateMenu from 'genesis-suite/components/aggregation_selector/AggregateMenu'
import { aggregationDetailDictionary } from 'genesis-suite/components/aggregation_selector/aggregationOptions'
import ExpressionEditor, { Suggestion, SuggestMethod } from 'genesis-suite/components/ExpressionEditor'
import { Property, ResourceType } from 'genesis-suite/types/networkTypes'
import { letMeMap } from 'genesis-suite/types/utilTypes'
import {
    Aggregation,
    BaseSeries,
    ChartType,
    CustomTooltip,
    DataField,
    DataGroup,
    OneSeries,
    SeriesConfig,
    TooltipType,
    Widget,
} from 'genesis-suite/types/visualTypes'
import { replaceMustacheValues } from '../../../../lib/utils'
import Markdown from '../../../Markdown'
import { aggregateFunction, arithmeticFunction } from '../../../widgets2/utils/aggregateFunctions'
import { makeDefaultTooltip } from '../../../widgets2/utils/configDefaults'
import isRawSeries from '../../../widgets2/utils/isRawSeries'
import makeTooltipMarkdown from '../../../widgets2/utils/makeTooltipMarkdown'
import { ConfigContext } from '../../ConfigContext'
import { getDataFieldProperty } from '../../utils'
import DisplayFormatFields from '../DisplayFormatFields'
import { ParsedResponse } from '../../../../types/WidgetTypes'

const valueSeparator = '~'

type NewValueType = 'text' | 'property' | 'values'

const useStyles = makeStyles(({ spacing, palette, border }) => ({
    dialog: { width: '800px', maxWidth: '90vw' },
    content: { display: 'grid', gridColumnGap: spacing(), gridTemplateColumns: 'auto 200px' },
    expressionWrapper: { marginTop: 0 },
    displayFormatWrapper: {
        border: `1px solid rgba(0, 0, 0, 0.23)`,
        borderRadius: border.radius.round,
        padding: spacing(),
    },
    tooltipPreviewBox: {
        border: border.default,
        padding: spacing(),
        margin: `auto ${spacing()}`,
        borderRadius: border.radius.round,
        cursor: 'default',
    },
    tooltipWrapper: { '& p': { margin: 0 } },
}))

export default function SeriesTooltipEditor() {
    const { resources, dispatch, dataResponse, selectedField, ...rest } = useContext(ConfigContext)
    const config = rest.config as SeriesConfig

    const [open, setOpen] = useState(false)
    const [draft, setDraft] = useState<CustomTooltip>(emptyTooltip)
    const [isDefault, setIsDefault] = useState(true)
    const [selectedConfigIndex, setSelectedConfigIndex] = useState<number>(null)
    const [changesMade, setChangesMade] = useState(false)

    const activeSeriesIndex = selectedField.index
    const activeSeries = config.series[activeSeriesIndex] as BaseSeries
    const { properties } = resources.byId[activeSeries?.service.id] ?? {}
    const isRaw = isRawSeries(activeSeries)
    const classes = useStyles()

    useEffect(() => {
        init()
    }, [activeSeriesIndex])

    function init() {
        setChangesMade(false)
        setDraft(
            activeSeries?.tooltip?.type === TooltipType.CUSTOM
                ? activeSeries.tooltip
                : makeDefaultTooltip(config.type, activeSeries)
        )
        setIsDefault(activeSeries?.tooltip?.type !== TooltipType.CUSTOM)
    }

    function handleDone() {
        if (changesMade)
            dispatch({
                type: 'UPDATE_ACTIVE_SERIES',
                payload: { tooltip: isDefault ? { type: TooltipType.DEFAULT } : draft },
            })
        setSelectedConfigIndex(null)
        setOpen(false)
    }

    function handleClose() {
        init()
        setOpen(false)
    }

    function handleEnableTooltip(e) {
        const disabled = !e.target.checked
        dispatch({
            type: 'UPDATE_ACTIVE_SERIES',
            payload: { ...activeSeries, tooltip: { ...activeSeries.tooltip, disabled } },
        })
    }

    function handleDefaultCheckbox(e) {
        const { checked } = e.target
        if (!checked) return

        setIsDefault(checked)
        setDraft(makeDefaultTooltip(config.type, activeSeries))
        setChangesMade(true)
    }

    const suggestions = isRaw
        ? makeRawSuggestions(properties)
        : makeAggregateSuggestions(properties, config.categories, activeSeries)
    const displayMarkdown = replaceMustacheValues(
        draft.markdown,
        draft.configs.map(c => makeDisplayField(c, properties))
    )
    const selectedConfig = draft.configs?.[selectedConfigIndex]
    const selectedConfigProperty = getDataFieldProperty(selectedConfig?.source.field, properties)

    function handleChange(e, display: string, method: SuggestMethod) {
        if (method === 'up' || method === 'down' || method === 'escape') return

        let markdown = displayToMarkdown(draft.configs, display, properties)

        const configs = [...draft.configs]
        const newValue = display.match(/\{\{!(.*)!\}\}/)

        if (newValue) {
            e.preventDefault()

            const { 1: input, index: newIndex } = newValue
            const [valueType, value] = input.split(valueSeparator) as [NewValueType, string]
            let displayValue: string

            /** add new property to configs keeping in order */
            const updateConfigsAndMarkdown = (field: DataField, aggregation: Aggregation) => {
                const configsBefore = configs.reduce((acc, _, i) => {
                    return (acc += markdown.indexOf(`{{${i.toString()}}}`) < newIndex ? 1 : 0)
                }, 0)

                displayValue = `{{${configsBefore}}}`
                configs.splice(configsBefore, 0, { source: { field, aggregation } })

                // shift markdown indexes after newIndex
                new Array(configs.length - configsBefore - 1)
                    .fill(null)
                    .map((_, i) => [configsBefore + i, `{{${configsBefore + i + 1}}}`])
                    .reverse() // start at end to prevent overwriting
                    .forEach(([key, value]) => {
                        markdown = replaceMustacheValues(markdown, { [key]: value })
                    })
            }

            switch (valueType) {
                case 'values': {
                    const { field, aggregation } = activeSeries.values[parseInt(value)]
                    updateConfigsAndMarkdown(field, aggregation)
                    break
                }
                case 'property': {
                    const { container, id, name, semanticType } = properties[parseInt(value)]
                    const field = {
                        resourceType: container.type,
                        resourceId: container.id,
                        resourceName: container.name,
                        id,
                        name,
                        dataTypeId: semanticType.id,
                    }
                    const aggregation = container.type === ResourceType.NODE ? Aggregation.NONE : Aggregation.SUM
                    updateConfigsAndMarkdown(field, aggregation)
                    break
                }
                case 'text': {
                    displayValue = value
                    break
                }
                default:
                    console.log('Something is wrong')
            }

            markdown = markdown.replace(new RegExp(escapeRegExp(makeValue(valueType, value)), 'g'), displayValue)
        }

        setDraft(s => ({ ...s, markdown, configs }))
        handleChangesMade()
    }

    function handleKeyDown(e) {
        // prettier-ignore
        const ignoreKeys = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Meta', 'Alt', 'Shift', 'Control', 'End', 'Home']
        if (ignoreKeys.includes(e.key)) return

        const { selectionStart, selectionEnd } = convertSelectionAndValue(e, draft, properties)

        const newMarkdown = draft.markdown.slice(0, selectionStart) + draft.markdown.slice(selectionEnd)
        const oldMarkdownIndexes = getMarkdownIndexes(draft.markdown)
        const newMarkdownIndexes = getMarkdownIndexes(newMarkdown)

        // eject if markdown indexes didn't change
        if (oldMarkdownIndexes.length === newMarkdownIndexes.length) return

        // prevent change handle
        e.preventDefault()

        // filter configs and update markdown to reflect
        const configs = draft.configs.filter((c, i) => newMarkdownIndexes.includes(i))
        const replacements = uniq(newMarkdownIndexes)
            .sort()
            .reduce((acc, cur, newIndex) => ({ ...acc, [cur]: `{{${newIndex}}}` }), {})
        const markdown = replaceMustacheValues(newMarkdown, replacements)

        setDraft(s => ({ ...s, configs, markdown }))
        handleChangesMade()
    }

    function handleKeyUp(e) {
        const { focusedConfigIndex } = convertSelectionAndValue(e, draft, properties)
        if (focusedConfigIndex !== selectedConfigIndex) setSelectedConfigIndex(focusedConfigIndex)
    }

    function handleClick(e) {
        // bail if click is outside text area
        if (!e.target.selectionStart) return

        const { focusedConfigIndex } = convertSelectionAndValue(e, draft, properties)
        if (focusedConfigIndex !== selectedConfigIndex) setSelectedConfigIndex(focusedConfigIndex)
    }

    function handleFormatChange(format) {
        setDraft(s =>
            produce(s, draft => {
                draft.configs[selectedConfigIndex].format = format
            })
        )
        handleChangesMade()
    }

    function handleAggregationChange(aggregation) {
        setDraft(s =>
            produce(s, draft => {
                draft.configs[selectedConfigIndex].source.aggregation = aggregation
            })
        )
        handleChangesMade()
    }

    function handleChangesMade() {
        setChangesMade(true)
        setIsDefault(false)
    }

    return (
        <>
            <TheCheckbox label="Enabled" checked={!activeSeries?.tooltip?.disabled} onChange={handleEnableTooltip} />
            <Button variant="outlined" onClick={() => setOpen(true)}>
                Edit
            </Button>

            <Dialog open={open} onClose={handleClose} classes={{ paper: classes.dialog }}>
                <DialogTitle>Tooltip Editor</DialogTitle>
                <DialogContent className={classes.content}>
                    <div>
                        <Typography variant="subtitle2">
                            <strong>Expression</strong>
                        </Typography>
                        <ExpressionEditor
                            expression={displayMarkdown || ''}
                            suggestions={suggestions}
                            onChange={handleChange}
                            textFieldProps={{
                                rows: 10,
                                className: classes.expressionWrapper,
                                label: '',
                                spellCheck: 'false',
                                onKeyDown: handleKeyDown,
                                onKeyUp: handleKeyUp,
                                onClick: handleClick,
                            }}
                        />
                        <Box display="flex">
                            <TheCheckbox label="default" checked={isDefault} onChange={handleDefaultCheckbox} />
                            <>
                                <div data-for="editorTooltip" data-tip className={classes.tooltipPreviewBox}>
                                    Preview sample
                                </div>

                                <ReactTooltip id="editorTooltip" place="bottom">
                                    <div className={classes.tooltipWrapper}>
                                        <Markdown>
                                            {makeTooltipDisplay(config, draft, activeSeriesIndex, dataResponse)}
                                        </Markdown>
                                    </div>
                                </ReactTooltip>
                            </>
                        </Box>
                    </div>
                    <div>
                        <ChopText variant="subtitle2" showEllipsis tooltipProps={{ placement: 'top' }}>
                            <strong>
                                Property
                                {selectedConfigProperty
                                    ? ` (${selectedConfigProperty.displayName || selectedConfigProperty.name})`
                                    : ''}
                            </strong>
                        </ChopText>
                        <div className={classes.displayFormatWrapper}>
                            {!isRaw && (
                                <AggregationPicker
                                    aggregation={selectedConfig?.source.aggregation}
                                    onChange={handleAggregationChange}
                                />
                            )}
                            <DisplayFormatFields
                                disabled={!selectedConfig}
                                format={selectedConfig?.format}
                                onChange={handleFormatChange}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    {changesMade && <Button onClick={init}>Reset</Button>}
                    <Button variant="contained" color="primary" onClick={handleDone}>
                        {changesMade ? 'Save' : 'Done'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

interface AggregationPickerProps {
    aggregation: Aggregation
    onChange: (aggregation: Aggregation) => void
}

function AggregationPicker({ aggregation, onChange }: AggregationPickerProps) {
    const [aggregationMenuAnchor, setAggregationMenuAnchor] = useState(null)

    function handleAggregationChange(e, aggregation) {
        setAggregationMenuAnchor(null)
        onChange(aggregation)
    }

    const { label, Icon } = aggregationDetailDictionary[aggregation] ?? {}

    return (
        <>
            <Button
                variant="outlined"
                size="small"
                disabled={!aggregation}
                endIcon={<ExpandMore fontSize="small" />}
                startIcon={Icon ? <Icon fontSize="small" /> : undefined}
                onClick={e => setAggregationMenuAnchor(e.currentTarget)}
            >
                {label ?? 'Aggregation'}
            </Button>
            <Popover
                anchorEl={aggregationMenuAnchor}
                keepMounted
                open={Boolean(aggregationMenuAnchor)}
                onClose={() => setAggregationMenuAnchor(null)}
            >
                <AggregateMenu value={aggregation} onChange={handleAggregationChange} />
            </Popover>
        </>
    )
}

const emptyTooltip: CustomTooltip = { type: TooltipType.CUSTOM, markdown: '', configs: [] }

function displayToMarkdown(configs: CustomTooltip['configs'], display: string, properties: Property[]) {
    return configs
        .map((cur, ind) => [escapeRegExp(makeDisplayField(cur, properties)), `{{${ind}}}`])
        .reduce((acc, [key, value]) => acc.replace(new RegExp(key), value), display)
}

/** convert text area selection props from display to markdown format */
function convertSelectionAndValue(e, tooltipConfig: CustomTooltip, properties: Property[]) {
    let selectionStart = e.target.selectionStart as number
    let selectionEnd = e.target.selectionEnd as number
    let markdownBuilder = e.target.value as string
    let focusedConfigIndex: number = null

    const { configs, markdown } = tooltipConfig

    // correct for delete and backspace at beginning and end of
    if (selectionStart === selectionEnd) {
        if (e.key === 'Delete') selectionEnd++
        else if (e.key === 'Backspace') selectionStart--
    }

    // convert display text to mustache and correct selections to account for property replacements
    configs.forEach((c, i) => {
        const markupLength = getMarkupPropertyLength(i)
        const displayLength = makeDisplayField(c, properties).length

        const matchStart = markdownBuilder.indexOf(makeDisplayField(c, properties))
        if (matchStart === -1) return

        markdownBuilder =
            markdownBuilder.slice(0, matchStart) + `{{${i}}}` + markdownBuilder.slice(matchStart + displayLength)

        if (selectionStart >= matchStart + displayLength) {
            if (selectionStart === matchStart + displayLength) focusedConfigIndex = i
            selectionStart -= displayLength - markupLength
        } else if (selectionStart > matchStart) {
            focusedConfigIndex = i
            selectionStart = matchStart
        }

        if (selectionEnd >= matchStart + displayLength) selectionEnd -= displayLength - markupLength
        else if (selectionEnd > matchStart) selectionEnd = matchStart + markupLength
    })

    if (markdownBuilder !== markdown) {
        console.log('Something wrong here..')
    }

    return { selectionStart, selectionEnd, focusedConfigIndex }
}

function makeDisplayField(tooltipConfig: CustomTooltip['configs'][0], properties: Property[]) {
    const { aggregation, field } = { ...tooltipConfig.source }
    const { displayName } = { ...getDataFieldProperty(field, properties) }
    return aggregation === Aggregation.NONE ? `[${displayName}]` : `[${aggregation} | ${displayName}]`
}

/** return the length of a markup property ex. {{11}} */
const getMarkupPropertyLength = (i: number) => i.toString().length + 4

function getMarkdownIndexes(markdown: string) {
    const myRe = new RegExp(`{{\\s*(.*?)\\s*}}`, 'g')
    let myArray
    const indexes: number[] = []
    while ((myArray = myRe.exec(markdown)) !== null) {
        indexes.push(parseInt(myArray[1]))
    }

    return indexes
}

/** escape all special characters from string for regex match */
const escapeRegExp = string => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

function makeRawSuggestions(properties: Property[]): Suggestion[] {
    if (!properties?.length) return []

    const fields = properties.map((p, i) => ({
        label: p.displayName,
        value: makeValue('property', `${i}`),
        description: `Value for ${p.displayName}`,
    }))

    return [
        {
            type: 'section',
            label: 'Properties',
            suggestions: fields,
        },
        {
            ...aggregateFunction.totalRecords,
            value: makeValue('text', aggregateFunction.totalRecords.valueString),
        },
    ]
}

function makeAggregateSuggestions(
    properties: Property[],
    categories: Widget['categories'],
    activeSeries: OneSeries
): Suggestion[] {
    if (!activeSeries) return []
    if (!properties?.length) return []

    const { values, subSeries } = activeSeries
    const primaryPropertyId = values[0]?.field.id
    const primaryProperty = properties.find(p => p.id === primaryPropertyId)
    const primaryTitle = primaryProperty?.displayName ?? primaryProperty?.name
    const categoryTitles = letMeMap(categories).map(c => {
        const { name, displayName } = properties.find(p => p.id === c.field.id) ?? {}
        return displayName || name
    })
    const subSeriesProperty = subSeries ? properties.find(p => p.id === subSeries.field.id) : null
    const subSeriesTitle = subSeriesProperty?.displayName || subSeriesProperty?.name || ''

    const otherFields = properties.map((p, i) => ({
        label: p.displayName,
        value: makeValue('property', `${i}`),
        description: `Value for ${p.displayName}`,
        hide: p.name === primaryPropertyId || p.semanticType?.type !== 'Quantitative',
    }))

    const functions = [
        {
            ...aggregateFunction.valueTotal,
            value: makeValue('text', aggregateFunction.valueTotal.valueString),
            hide: !categoryTitles.length,
        },
        {
            ...aggregateFunction.categoryValueTotal,
            value: makeValue('text', aggregateFunction.categoryValueTotal.valueString),
            hide: !subSeries,
        },
        {
            ...aggregateFunction.percentOfTotal,
            value: makeValue('text', aggregateFunction.percentOfTotal.valueString),
            hide: !categoryTitles.length,
        },
        {
            ...aggregateFunction.percentOfCategory,
            value: makeValue('text', aggregateFunction.percentOfCategory.valueString),
            hide: !subSeries,
        },
        {
            ...aggregateFunction.totalRecords,
            value: makeValue('text', aggregateFunction.totalRecords.valueString),
        },
        {
            ...aggregateFunction.totalCategoryRecords,
            value: makeValue('text', aggregateFunction.totalCategoryRecords.valueString),
            hide: !categoryTitles.length,
        },
        {
            ...aggregateFunction.totalSubSeriesRecords,
            value: makeValue('text', aggregateFunction.totalSubSeriesRecords.valueString),
            hide: !subSeries,
        },
    ]

    const arithmeticFunctions = [
        {
            ...arithmeticFunction.addition,
            value: makeValue('text', arithmeticFunction.addition.valueString),
        },
        {
            ...arithmeticFunction.subtraction,
            value: makeValue('text', arithmeticFunction.subtraction.valueString),
        },
        {
            ...arithmeticFunction.multiplication,
            value: makeValue('text', arithmeticFunction.multiplication.valueString),
        },
        {
            ...arithmeticFunction.division,
            value: makeValue('text', arithmeticFunction.division.valueString),
        },
    ]

    return [
        {
            label: primaryTitle,
            value: makeValue('values', '0'),
            description: 'Primary value field and aggregation',
        },
        {
            label: 'Category',
            value: makeValue('text', `${categoryTitles[categoryTitles.length - 1]}: [category]`),
            description: 'Active category name',
            hide: !categoryTitles.length,
        },
        {
            label: 'Sub-series',
            value: makeValue('text', `${subSeriesTitle}: [sub-series]`),
            description: 'Active category name',
            hide: !subSeries,
        },
        {
            type: 'section',
            label: 'Other properties',
            suggestions: otherFields,
        },
        {
            type: 'section',
            label: 'Arithmetic operations',
            suggestions: arithmeticFunctions,
        },
        {
            type: 'section',
            label: 'Functions',
            suggestions: functions,
        },
    ]
}

const makeValue = (type: NewValueType, string: string) => `{{!${type}${valueSeparator}${string}!}}`

function TheCheckbox({ label, ...rest }) {
    return <FormControlLabel control={<Checkbox {...rest} />} label={label} />
}

function makeTooltipDisplay(
    config: SeriesConfig,
    draft: CustomTooltip,
    activeSeriesIndex: number,
    res: ParsedResponse
) {
    if (!res) return 'Data required..'
    if (config.type === ChartType.TABLE) return 'table tooltip..'

    const allSeriesWithDraft = produce(config.series, seriesDraft => {
        if (seriesDraft[activeSeriesIndex]) seriesDraft[activeSeriesIndex].tooltip = draft
    })

    const updatedConfig = { ...config, series: allSeriesWithDraft }
    const dataIndexes = {
        groupNames: getGroupNamesOfFirstPoint(res.data[activeSeriesIndex]?.data),
        series: activeSeriesIndex,
    }

    //@ts-ignore
    return makeTooltipMarkdown(updatedConfig, res.data, dataIndexes) || 'Data required..'
}

function getGroupNamesOfFirstPoint(dataGroups: DataGroup[]): string[] {
    if (!dataGroups?.length) return []
    const { group, children } = dataGroups[0]
    return [group, ...getGroupNamesOfFirstPoint(children)]
}

function propertyInsideFunction(markdown: string, configIndex: number) {
    return (
        markdown.indexOf(`${aggregateFunction.percentOfTotal.value}({{${configIndex}}})`) > -1 ||
        markdown.indexOf(`${aggregateFunction.percentOfCategory.value}({{${configIndex}}})`) > -1
    )
}
