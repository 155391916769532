import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'

import PerspectiveWidget from '../components/widgets/PerspectiveWidget'
import DashboardWidget from '../components/widgets2/dashboard/DashboardWidget'
import useDashboard from '../components/widgets2/dashboard/useDashboard'
import useDashboardId from '../hooks/useDashboardId'
import { filterSelectors, widgetSelectors } from '../selectors'

export default function TVView() {
    const { id, isV2 } = useDashboardId()
    return isV2 ? <BuilderTVView id={id} /> : <VisorTVView id={id} />
}

const useStyles = makeStyles({
    container: {
        padding: '15px',
        overflow: 'auto',
        height: '100%',
    },
    gridContainer: {
        height: '100%',
        width: '100%',
    },
    gridItem: {
        height: itemCount => (itemCount === 1 ? '100%' : '50%'),
    },
})

function VisorTVView({ id }) {
    const widgetConfigs = useSelector(s => widgetSelectors.getWidgetConfigsForPerspective(s, id))
    const filters = useSelector(filterSelectors.currentFiltersConfiguration)
    const networkContext = useSelector(filterSelectors.getCoord)
    const inlineFilters = useSelector(filterSelectors.getInlineFilters)

    const classes = useStyles(widgetConfigs.length)
    const breakpoint = Math.floor(12 / Math.ceil(widgetConfigs.length / 2))

    return (
        <div className={`${classes.container} widget-parent-wrapper`}>
            <Grid container className={classes.gridContainer} spacing={2}>
                {widgetConfigs.map(widgetConfig => {
                    return (
                        <Grid className={classes.gridItem} item xs={breakpoint} key={widgetConfig.Id}>
                            <PerspectiveWidget config={widgetConfig} {...{ filters, networkContext, inlineFilters }} />
                        </Grid>
                    )
                })}
            </Grid>
        </div>
    )
}

function BuilderTVView({ id }) {
    const { data } = useDashboard(id)
    const { widgets = [] } = data || {}

    const classes = useStyles(widgets.length)
    const breakpoint = Math.floor(12 / Math.ceil(widgets.length / 2))

    return (
        <div className={`${classes.container} widget-parent-wrapper`}>
            <Grid container className={classes.gridContainer} spacing={2}>
                {widgets.map(config => {
                    return (
                        <Grid className={classes.gridItem} item xs={breakpoint} key={config.id}>
                            <DashboardWidget config={config} />
                        </Grid>
                    )
                })}
            </Grid>
        </div>
    )
}
