import { Autocomplete, AutocompleteChangeReason, CircularProgress, TextField } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { FormColumn, FormPrompt } from 'genesis-suite/types/visualTypes'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'
import { ContextFilter } from '../../../lib/formUtils'
import { modelService } from '../../../lib/services'
import { applicationSelectors, filterSelectors } from '../../../selectors'
import { useLocation, matchPath } from 'react-router-dom'
import { routePaths } from '~/lib/routes'
import { combineFilters } from '~/lib/utils'

const useStyles = makeStyles(theme => ({
    indicator: { marginRight: 0 },
    noOptionText: { color: theme.palette.text.primary },
}))

type Field = FormColumn | FormPrompt
interface FormSelectProps {
    value: any
    field: Field
    onEditComplete: (value: any) => void
    multiEdit?: boolean
    disabled?: boolean
    displayValue?: any
    initOpen?: boolean
    multiple?: boolean
    className?: string
    hideLabel?: boolean
    disableUnderline?: boolean
    contextFilters?: ContextFilter[]
    inputStyle?: React.CSSProperties
}

/**
 * Select dropdown that gets own values from Tada network
 * Also consumes contextFilters and needs to retrieve new data based on them
 */
export default function TadaFormSelect({
    value,
    field,
    onEditComplete,
    multiEdit,
    disabled: isDisabled,
    displayValue,
    initOpen,
    multiple,
    className,
    hideLabel,
    disableUnderline,
    contextFilters,
    inputStyle,
}: FormSelectProps) {
    const classes = useStyles()
    const cancelCall = useRef(null)
    const [loading, setLoading] = useState(false)
    const [blurred, setBlurred] = useState(false)
    const [fetchError, setFetchError] = useState(false)
    const [open, setOpen] = useState(Boolean(initOpen))
    const [options, setOptions] = useState<string[]>([])
    const [searchText, setSearchText] = useState<string>('')
    const [displayObj, setDisplayObj] = useState(displayValue ? { [value]: displayValue } : {})
    const location = useLocation()
    const isCompare = matchPath(routePaths.COMPARE_PERSPECTIVE, location.pathname) ? true : false
    const normalContext = useSelector(filterSelectors.getCoord)
    const compareContext = useSelector(filterSelectors.getCompareCoord)
    const inlineFilters = useSelector(filterSelectors.getInlineFilters)
    const normalFilters = useSelector(filterSelectors.currentFiltersConfiguration)
    const compareFilters = useSelector(filterSelectors.currentCompareFiltersConfiguration)

    const filters = isCompare ? compareFilters : normalFilters
    const context = isCompare ? compareContext : normalContext
    /**
     * Combine context filters with inline filters and field filters
     * Ideally we need to send this combined filters in payload to get resource values but
     * It has other side effects such as not showing any options in few cases where applied filters
     * are not matching with any resource values.
     * Hence keeping this as unused for now.
     */
    const combinedFilters = [...(combineFilters(context.Filters, filters, inlineFilters) || []), ...contextFilters]

    const {
        title,
        editable,
        property,
        required,
        autogenerate,
        lastUpdateField,
        displayProperty,
        autogeneratePrefix,
        visorMeta,
    } = field
    const isAutoGenerated = autogenerate || autogeneratePrefix
    const disabled =
        isDisabled || Boolean(!editable || isAutoGenerated || lastUpdateField) || (property?.isPrimary && multiEdit)
    const emptyError = required && blurred && (value == null || !String(value))
    const errorText = fetchError ? 'Failed to load data' : emptyError ? 'Field is required' : undefined
    const searchName = displayProperty?.name ?? property.name
    const { name: propertyName, container } = property

    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const getOptions = async (): Promise<string[]> => {
        setLoading(true)
        setFetchError(false)
        const data = {
            id: visorMeta?.visorFieldId,
            pageNumber: 1,
            pageSize: 100,
            contextFilters,
            properties: [`${propertyName}`, displayProperty?.name],
            filterExpression: searchText ? `${searchName}<c>${searchText}` : '',
            sortOrders: { [propertyName]: 'Ascending' },
        }
        try {
            const { Data: values } = await modelService.filterResourceValues(
                appName,
                container.name,
                data,
                visorMeta?.visorFormId,
                cancel => (cancelCall.current = cancel)
            )
            const displayDict = displayProperty
                ? values.reduce((acc, row) => {
                      const key = row[propertyName]
                      const value = row[displayProperty.name]
                      acc[key] = value
                      return acc
                  }, displayObj)
                : displayObj
            setDisplayObj(displayDict)
            const opts = values.map(v => v[propertyName])
            return opts
        } catch (err) {
            if (err.__CANCEL__) return
            console.error(err)
            setFetchError(true)
        } finally {
            setLoading(false)
        }
    }

    const getWithFilters = async (opts: string[]) => {
        if (!opts) return
        setOptions(opts)
        if (opts.length === 1)
            onEditComplete({
                value: opts[0],
                label: opts[0],
            })
        else if (value && !opts.find(opt => opt === value)) onEditComplete(null)
    }

    /** Update existing options by merging with new options and value if applicable.
     * Remove dupes.
     * return new options
     */
    const updateOptions = newOptions => {
        let mergedOptions = options.concat(newOptions)
        if (value) mergedOptions = mergedOptions.concat(value)
        return Array.from(new Set(mergedOptions))
    }

    const handleFetchOptions = async () => {
        if (disabled) return
        const opts = await getOptions()
        if (contextFilters?.length) getWithFilters(opts)
        else {
            const newOptions = updateOptions(opts)
            setOptions(newOptions)
        }
    }

    /** Fetch options on mount, searchText, and contextFilter updates */
    useEffect(() => {
        handleFetchOptions()
        // Cleanup pending requests on unmount
        return () => cancelCall?.current?.()
    }, [searchText, contextFilters])

    /** Retry fetch if fetch failed */
    useEffect(() => {
        if (open && fetchError) {
            handleFetchOptions()
        }
    }, [open, fetchError])

    const handleChange = (e: React.ChangeEvent, option: any, reason: AutocompleteChangeReason) => {
        // TODO: handle create-option
        if (reason === 'selectOption' || reason === 'removeOption') {
            if (e.nativeEvent.type === 'keydown') e.stopPropagation()
            let selectedOption = null
            if (option) {
                selectedOption = {
                    value: option,
                    label: displayObj[option] || option,
                }
            }
            onEditComplete(selectedOption)
        } else if (reason === 'clear') onEditComplete(null)
    }
    const handleSearch = useDebouncedCallback(text => {
        setSearchText(text)
    }, 250)
    const handleInputChange = (e, val, reason) => {
        if (reason === 'input') {
            cancelCall.current?.()
            handleSearch(val)
        }
    }
    const toggleOpen = () => setOpen(!open)
    const getOptionLabel = opt => {
        if (opt == null) return ''
        return displayObj[opt] || opt.toString()
    }

    return (
        <Autocomplete
            fullWidth
            open={open}
            value={value}
            limitTags={2}
            freeSolo={false}
            options={options}
            loading={loading}
            disabled={disabled}
            onOpen={toggleOpen}
            multiple={multiple}
            onClose={toggleOpen}
            className={className}
            onChange={handleChange}
            getOptionLabel={getOptionLabel}
            disableCloseOnSelect={multiple}
            filterSelectedOptions={multiple}
            onInputChange={handleInputChange}
            classes={{ popupIndicator: classes.indicator }}
            noOptionsText={
                fetchError ? (
                    'An error occurred fetching options'
                ) : (
                    <span className={classes.noOptionText}>No options</span>
                )
            }
            renderInput={params => (
                <TextField
                    {...params}
                    error={Boolean(errorText)}
                    helperText={errorText}
                    required={required}
                    label={!hideLabel && title}
                    InputLabelProps={{ shrink: true, required: required && !hideLabel }}
                    InputProps={{
                        ...params.InputProps,
                        disableUnderline,
                        onBlur: () => setBlurred(true),
                        style: inputStyle,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    )
}
