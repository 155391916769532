import LaunchRounded from '@mui/icons-material/LaunchRounded'
import { Box, Button } from '@mui/material'
import { TGPTRequest, TGPTResponse, TGPTResponseType } from 'genesis-suite/types/visualTypes'
import { buildRoute } from 'genesis-suite/utils'
import { isEmpty } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { navigationCreators } from '../../actions/creators'
import { ColOptionsProvider } from '../../components/contexts/ColumnOptionsContext'
import Widget from '../../components/widgets/Widget'
import { routePaths } from '../../lib/routes'
import { chatService } from '../../lib/services'
import { applicationSelectors, authSelectors, moduleSelectors } from '../../selectors'
import ChatComponent from './ChatComponent'
import DataResponseTable from './DataResponseTable'
import SpeechMimic from './SpeechMimic'
import { stripHtmlDOM } from '~/lib/utils'

type Message = {
    text: string | JSX.Element
    type: 'user' | 'bot'
}

// Temp stuff for gartner conference
export default function Chatbox({ Header, onLoad }) {
    const [loading, setLoading] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [messages, setMessages] = useState<Message[]>([
        {
            text: 'Hello, how can I help you?',
            type: 'bot',
        },
    ])

    const d3WidgetBlowsUpOtherwiseRef = useRef(null)

    const dispatch = useDispatch()
    const userId = useSelector(authSelectors.getUserId)
    const tModule = useSelector(moduleSelectors.getCurrentModule)
    const appInfo = useSelector(applicationSelectors.getAppInfo)

    const chatboxRef = useRef<HTMLDivElement>(null)
    const scrollIntoView = () => {
        chatboxRef.current?.scrollTo(0, chatboxRef.current.scrollHeight)
    }
    const [responseType, setResponseType] = useState<TGPTResponseType>(TGPTResponseType.Auto)

    useEffect(() => {
        onLoad?.({ sendQuestion: sendQuestionExternal })
    }, [])

    useEffect(() => {
        scrollIntoView()
    }, [messages])

    const sendQuestionExternal = async (question: string) => {
        if (!question.trim()) return

        try {
            setMessages([...messages, { text: question, type: 'user' }])
            setInputValue('')
            setLoading(true)
            const request: TGPTRequest = {
                Question: question,
                PortalId: tModule.id,
                PortalName: tModule.name,
                CloudId: appInfo.cloudId,
                CloudName: appInfo.cloudName,
                AppId: appInfo.appId,
                AppName: appInfo.appName,
                UserId: userId,
                ResponseType: responseType,
            }
            const response: TGPTResponse = await chatService.getResponse(request)
            if (!response) {
                addBotMessage('Sorry, I did not understand that. Please try again.')
            } else {
                switch (response.ResponseType) {
                    case TGPTResponseType.Text:
                        addBotMessage(response.Answer)
                        break
                    case TGPTResponseType.Widget:
                        processWidgetResponse(response, request)
                        break
                    case TGPTResponseType.Data:
                        processDataResponse(response)
                        break
                    default:
                        addBotMessage('Sorry, I did not understand that. Please try again.')
                        break
                }
            }
        } catch (err) {
            console.log(err)
            addBotMessage('An error occurred. Please try again.')
        } finally {
            setLoading(false)
        }
    }

    const sendQuestion = async (value = inputValue) => {
        const stripedVal = stripHtmlDOM(value)
        if (!stripedVal.trim()) return

        // to get the response Array
        // const arr = parseHTMLToArrayTGPT(inputValue)

        sendQuestionExternal(stripedVal)
    }

    const addBotMessage = async (text: string | JSX.Element) => {
        if (typeof text === 'string')
            setMessages(prev => [...prev, { text: <SpeechMimic text={text} onChange={scrollIntoView} />, type: 'bot' }])
        else setMessages(prev => [...prev, { text, type: 'bot' }])
    }

    function processWidgetResponse(response, request) {
        const widget = response.Item
        const title = response.Answer
        if (widget.ContainerConfig) {
            addBotMessage(`Navigating to **${title}**`)
            const path = buildRoute(routePaths.PERSPECTIVE, request.AppName, request.PortalName, widget.Id)
            const context = widget.Filters ? buildContext(widget.Filters, widget.Id) : null
            setTimeout(() => {
                // dispatch(navigationCreators.goTo(routePaths.PERSPECTIVE, widget.Id))
                dispatch(navigationCreators.goToPath(path, { context }))
            }, 1500)
        } else {
            widget.Context = widget.Filters ? buildContext(widget.Filters, widget.Id) : null
            addBotMessage(`Here is what I found: **${title}**`)
            addBotMessage(
                <ColOptionsProvider id={widget.Id}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexFlow: 'column',
                            bgcolor: '#fff',
                            color: '#000',
                            height: 450,
                        }}
                    >
                        {/* @ts-ignore */}
                        <Widget config={widget} networkContext={widget.Context} ref={d3WidgetBlowsUpOtherwiseRef} />

                        <Box sx={{ p: 1 }}>
                            <Button
                                variant="contained"
                                startIcon={<LaunchRounded />}
                                onClick={function () {
                                    const path = buildRoute(
                                        routePaths.PERSPECTIVE,
                                        request.AppName,
                                        request.PortalName,
                                        widget.Id
                                    )
                                    const context = widget.Filters ? buildContext(widget.Filters, widget.Id) : null
                                    dispatch(navigationCreators.goToPath(path, { context }))
                                }}
                            >
                                Open Widget
                            </Button>
                        </Box>
                    </Box>
                </ColOptionsProvider>
            )
        }
    }

    function buildContext(filters, perspectiveId) {
        const [first, ...remaining] = filters
        const crumb = buildCrumb(first, perspectiveId)
        return { ...crumb, Filters: filters }
    }

    function buildCrumb(filter, perspectiveId) {
        return {
            Name: filter.ResourceName,
            FieldName: filter.PropertyName,
            Value: filter.Values.length === 1 ? filter.Values[0] : null,
            DefaultPerspective: perspectiveId,
        }
    }

    function processDataResponse(response) {
        const { Meta, SemanticData } = response
        if (!isEmpty(SemanticData) && !isEmpty(Meta)) {
            Object.keys(SemanticData).forEach(key => {
                const insightMeta = Meta.find(m => m.Name === key)
                if (!isEmpty(insightMeta)) {
                    addBotMessage(<DataResponseTable {...insightMeta} />)
                }
            })
        }
    }

    return (
        <ChatComponent
            ref={chatboxRef}
            Header={Header}
            messages={messages}
            loading={loading}
            inputValue={inputValue}
            setInputValue={setInputValue}
            sendQuestion={sendQuestion}
            responseType={responseType}
            setResponseType={setResponseType}
        />
    )
}
