import { notificationTypes, applicationTypes } from '../actions/types'

const initialState = {
    isPending: false,
    all: [],
}

export default (state = initialState, action) => {
    const { payload } = action
    switch (action.type) {
        case applicationTypes.APPLICATION_SELECT:
            return initialState
        case notificationTypes.NOTIFICATION_REQUEST:
            return {
                ...state,
                isPending: true,
            }
        case notificationTypes.NOTIFICATION_FAILURE:
            return {
                ...state,
                isPending: false,
                error: payload.error,
            }
        case notificationTypes.NOTIFICATION_SUCCESS:
            return {
                ...state,
                isPending: false,
                all: payload.notifications,
            }
        case notificationTypes.NOTIFICATION_ARCHIVED:
            const { id } = payload
            const newState = state.all.filter(n => n.Id !== id)
            return { ...state, all: newState }
        case notificationTypes.NOTIFICATION_RECEIVED:
            const { notification } = payload
            let inserted = false

            const all = state.all.map(n => {
                if (n.Id === notification.Id) {
                    inserted = true
                    return notification
                }
                return n
            })

            if (!inserted) all.push(notification)

            return {
                ...state,
                all,
            }
        case notificationTypes.NOTIFICATION_RULE_DELETED:
            const { ruleId } = payload

            return {
                ...state,
                all: state.all.filter(notification => {
                    return notification.BusinessRuleId !== ruleId
                }),
            }
        default:
            return state
    }
}
