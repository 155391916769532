import useResourceMeta from '~/hooks/useResourceMeta'
import { startCase } from 'lodash'

export function ResourceData(config) {
    if (config?.GanttConfig) {
        const { ElementType, ElementName } = config?.GanttConfig?.Parents[0]?.Source || {}
        const categoryPropertyName = config?.GanttConfig?.Parents[0]?.StartDateProperty

        const options = useOptions(ElementType, ElementName, categoryPropertyName)
        return options
    }
    return []
}

function useOptions(resourceType, resourceName, categoryPropertyName) {
    const [metaDataSource] = useResourceMeta(resourceType, resourceName)

    if (!metaDataSource?.properties) return []

    let categoryInsightProperty = metaDataSource.properties.find(n => n.name == categoryPropertyName)

    if (categoryInsightProperty?.referenceAttributeId) {
        categoryInsightProperty = metaDataSource?.properties?.find(
            n => n.id == categoryInsightProperty.referenceAttributeId
        )
    }

    return metaDataSource.properties
        ?.filter(
            p =>
                (p != null &&
                    p.computed &&
                    categoryInsightProperty?.id &&
                    p.referenceAttributeId == categoryInsightProperty?.id &&
                    !p.name.toLowerCase().endsWith('halfyearname')) ||
                p.id === categoryInsightProperty?.id
        )
        ?.map(p => ({
            ...p,
            displaySortOrder: getTemporalPropertyOrder(p.name, categoryInsightProperty?.name),
            displayName: startCase(getTemporalPropertyName(p.name, categoryInsightProperty?.name)),
        }))
        ?.sort((a, b) => {
            if (a.displaySortOrder > b.displaySortOrder) return 1
            if (a.displaySortOrder < b.displaySortOrder) return -1
            return 0
        })
}

function getTemporalPropertyName(temporalPropertyName, referencePropertyName) {
    if (!temporalPropertyName || temporalPropertyName.length < 1) return 'Default'
    if (!referencePropertyName || referencePropertyName.length < 1) return temporalPropertyName
    if (temporalPropertyName === referencePropertyName) return 'Default'
    return temporalPropertyName.substring(referencePropertyName.length)
}
function getTemporalPropertyOrder(temporalPropertyName, referencePropertyName) {
    if (!temporalPropertyName || temporalPropertyName.length < 1) return 0
    if (!referencePropertyName || referencePropertyName.length < 1) return 0
    temporalPropertyName = temporalPropertyName.substring(referencePropertyName.length)

    switch (temporalPropertyName.toLowerCase()) {
        case 'year':
            return 1
        case 'halfyear':
            return 2
        case 'quarter':
            return 3
        case 'quartername':
            return 4
        case 'monthyear':
            return 5
        case 'month':
            return 6
        case 'monthname':
            return 7
        case 'fullmonthname':
            return 8
        case 'week':
            return 9
        case 'isoweek':
            return 10
        case 'day':
            return 11
        case 'hour':
            return 12
        case 'minute':
            return 13
        case 'second':
            return 14
        default:
            return 0
    }
}

export function getStartEndDateOfWeek(date) {
    const inputDate = new Date(date)

    const dayOfWeek = inputDate.getDay()

    const firstDay = new Date(inputDate)
    firstDay.setDate(inputDate.getDate() - dayOfWeek)

    const lastDay = new Date(inputDate)
    lastDay.setDate(inputDate.getDate() + (6 - dayOfWeek))

    return { firstDay, lastDay }
}

export const getDateData = date => {
    const month = (1 + date.getMonth()).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    const year = date.getFullYear()
    const hour = date.getHours().toString().padStart(2, '0')
    const min = date.getMinutes().toString().padStart(2, '0')
    return { year, month, day, hour, min }
}

export const WEEK = { name: 'Week', value: 'W' }
export const DAY = { name: 'Day', value: 'D' }
export const HOUR = { name: 'Hour', value: 'H' }
