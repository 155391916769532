import { ChartType, Dashboard } from 'genesis-suite/types/visualTypes'

const defaultDashboard: Omit<Dashboard, 'id'> = {
    homePage: true,
    title: 'homepage',
    widgets: [
        {
            inline: { type: ChartType.SEARCH },
            positions: {
                large: { shrink: true, x: 0, y: 0, w: 18, h: 9 },
                small: { shrink: true, x: 0, y: 0, w: 4, h: 5 },
            },
        },
        {
            inline: { type: ChartType.MODULE_SELECTOR },
            positions: {
                large: { shrink: true, x: 0, y: 9, w: 18, h: 5 },
                small: { shrink: true, hide: true },
            },
        },
        {
            inline: { type: ChartType.RECOMMENDED_WIDGET },
            positions: {
                large: { shrink: true, x: 0, y: 14, w: 18, h: 12 },
                small: { shrink: true, x: 0, y: 5, w: 4, h: 8 },
            },
        },
        {
            inline: { type: ChartType.SPACIAL_MAP },
            positions: {
                large: { shrink: true, x: 0, y: 23, w: 18, h: 8 },
                small: { shrink: true, x: 0, y: 9, w: 4, h: 8 },
            },
        },
    ],
}

export default defaultDashboard
