import { ChartType, InlineWidgetType } from 'genesis-suite/types/visualTypes'

export default function isInlineWidget(type: ChartType): type is InlineWidgetType {
    switch (type) {
        case ChartType.RECOMMENDED_WIDGET:
        case ChartType.SPACIAL_MAP:
        case ChartType.MODULE_SELECTOR:
        case ChartType.SEARCH:
        case ChartType.FAVORITES:
        case ChartType.CALCULATOR:
            return true
        default:
            return false
    }
}
