import makeStyles from '@mui/styles/makeStyles'
import { CSSProperties } from 'react'
import { useSelector } from 'react-redux'
import StatusIcon from 'status-icon'
import tinycolor from 'tinycolor2'

import {
    CategoryTableColumn,
    ColorFormat,
    ConditionalFormat,
    DataIndexes,
    Rule,
    TableConfig,
    TableSeries,
} from 'genesis-suite/types/visualTypes'
import { themeSelectors } from '../../../selectors'
import { ThemeMode } from '../../../types/ThemeTypes'
import { SetSelectedPoint } from '../../../types/WidgetTypes'
import formatNumber from '../utils/formatNumber'
import {
    conditionalFormatsDynamicTable,
    getConditionalFormat,
    getConditionalFormatDynamicTable,
} from '../utils/getConditionalFormats'
import isRawSeries from '../utils/isRawSeries'
import ProgressBar, { calculatePercentage } from './ProgressBar'
import { Column } from './renderTableTypes'

const useStyles = makeStyles({
    cell: {
        boxSizing: 'border-box',
        padding: '5px',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        position: 'relative',
    },
    cellDataWrapper: {
        zIndex: 2,
        position: 'relative',
    },
    statusIconWrapper: { position: 'absolute', zIndex: 3 },
})

interface Props {
    config: TableConfig
    cellData: string | number
    rowData: Array<string | number>
    columns: Column[]
    rowIndex: number
    columnIndex: number
    canNavigate: boolean
    column: any
    setSelectedPoint: SetSelectedPoint
}

export default function TableWidgetCell2(props: Props) {
    const { config, cellData, rowData, columns, column, rowIndex, columnIndex, canNavigate, setSelectedPoint } = props
    const col = columns[columnIndex]
    const { isHyperlink, hyperlinkUrl, format, rules } = col

    const themeMode = useSelector(themeSelectors.getThemeMode)
    const classes = useStyles()

    const hyperlink = isHyperlink && createHyperlink(cellData, hyperlinkUrl)
    const canClick = Boolean(canNavigate && cellData)
    const style = createStyle(canClick, format, rules, rowData, themeMode)
    const configSeries = config.series[0]
    const iconType = column?.icon?.iconType || null
    const minValue = column?.icon?.minValue || 0
    const maxValue = column?.icon?.maxValue || 100
    const showBar = column?.icon?.showBar || null
    const hideValue = column?.icon?.hideValue || null
    const warnLevel = column?.icon?.warnLevel || null
    const lowLevel = column?.icon?.lowLevel || null

    return (
        <div
            className={classes.cell}
            style={style}
            onClick={e =>
                canClick
                    ? handleClickColumn(
                          e,
                          configSeries,
                          config.categories,
                          setSelectedPoint,
                          columnIndex,
                          rowIndex,
                          isRawSeries(configSeries),
                          rowData,
                          false
                      )
                    : undefined
            }
        >
            {typeof cellData === 'number' && (
                <>
                    {showBar && <ProgressBar minValue={minValue} maxValue={maxValue} value={cellData} />}
                    {iconType && (
                        <div className={classes.statusIconWrapper}>
                            <StatusIcon
                                variant={iconType}
                                value={calculatePercentage(minValue, maxValue, cellData)}
                                low={
                                    lowLevel ? { value: calculatePercentage(minValue, maxValue, lowLevel) } : undefined
                                }
                                warn={
                                    warnLevel
                                        ? { value: calculatePercentage(minValue, maxValue, warnLevel) }
                                        : undefined
                                }
                            />
                        </div>
                    )}
                </>
            )}
            {!hideValue && (
                <div className={classes.cellDataWrapper} style={{ paddingLeft: iconType ? '30px' : '0' }}>
                    {hyperlink ? (
                        <a href={hyperlink} rel="noopener noreferrer" target="_blank">
                            {cellData + ' '}
                            <i className="fa fa-external-link"></i>
                        </a>
                    ) : typeof cellData === 'number' && 'numberFormat' in col ? (
                        formatNumber(cellData, col.numberFormat)
                    ) : (
                        cellData
                    )}
                </div>
            )}
        </div>
    )
}

export function createHyperlink(cellData, hyperlinkUrl) {
    let hyperlink = ''

    if (hyperlinkUrl) {
        const c = hyperlinkUrl.slice(-1)
        if (c === '/' || c === '?' || c === '=') hyperlink = `${hyperlinkUrl}${cellData}`
        else hyperlink = hyperlinkUrl
    } else {
        hyperlink = cellData.toString()
    }

    if (!hyperlink.includes('://')) hyperlink = `//${hyperlink}`

    return hyperlink
}

export function createStyle(
    canNavigate: boolean,
    format: ColorFormat,
    rules: Rule[] | ConditionalFormat[],
    data: conditionalFormatsDynamicTable | Array<number | string>,
    themeMode: ThemeMode,
    isDynamicTable?: boolean,
    cellValue?: any
) {
    const style: CSSProperties = {}
    const navigateColor = themeMode === ThemeMode.DARK ? tinycolor('blue').lighten(25).toString() : 'blue'

    if (canNavigate) {
        style.color = navigateColor
        style.textDecoration = 'underline'
        style.cursor = 'pointer'
    }

    const c = isDynamicTable
        ? getConditionalFormatDynamicTable(
              data as conditionalFormatsDynamicTable,
              cellValue,
              rules as ConditionalFormat[]
          )
        : getConditionalFormat(data as Array<number | string>, rules as Rule[])
    if (c) {
        if (c.backgroundColor) style.backgroundColor = c.backgroundColor
        if (c.fontColor) style.color = c.fontColor
        if (c.borderColor) style.border = `1px solid ${c.borderColor}`
    }

    if (format) {
        if (!c?.backgroundColor && format.backgroundColor) style.backgroundColor = format.backgroundColor
        if (!c?.fontColor && format.fontColor) style.color = format.fontColor
        if (!c?.borderColor && format.borderColor) style.border = format.borderColor
    }

    return style
}

export const handleClickColumn = (
    event,
    configSeries: TableSeries,
    categories: CategoryTableColumn[],
    setSelectedPoint: SetSelectedPoint,
    columnIndex: number,
    rowIndex: number,
    isRawSeries: boolean,
    rowData: any,
    dynamicTable: boolean
) => {
    let dataIndexes: DataIndexes

    if (isRawSeries) {
        dataIndexes = { raw: { row: rowIndex, column: columnIndex } }
    } else {
        const lastCategoryIndex = categories.length - 1
        const groupNames = categories
            .slice(0, Math.min(columnIndex, lastCategoryIndex) + 1)
            .map((c, i) => rowData[dynamicTable ? c.title : i].toString())

        const isPivot = Boolean(configSeries.subSeries) && columnIndex > lastCategoryIndex
        if (isPivot) groupNames.push(configSeries[columnIndex].title)

        dataIndexes = { groupNames, subSeries: isPivot }
    }

    setSelectedPoint({ clicked: true, dataIndexes, event })
}
