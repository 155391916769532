import { Box, Dialog, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from '@mui/material'
import Widget from './widgets/Widget'
import PerspectiveSummary from './widgets/PerspectiveSummary'
import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { filterSelectors, widgetSelectors } from '~/selectors'
import { Close } from 'genesis-suite/icons'
import { filterCreators } from '~/actions/creators'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(({ spacing, palette }) => ({
    dialog: {
        '& .MuiDialog-container': {
            '& .MuiPaper-root': {
                background: `${palette.background?.main}`,
                width: '1200px',
            },
        },
    },
    summary: {
        maxWidth: '50%',
        border: `1px solid ${palette.border.main}`,
        borderRadius: '15px',
        padding: spacing(1),
        overflow: 'hidden',
        minWidth: '50%',
    },
}))

export const WidgetSummaryDialog = ({ open, onClose, config }) => {
    const classes = useStyles()
    const widgetRef = useRef()
    const dispatch = useDispatch()
    const filters = useSelector(filterSelectors.currentFiltersConfiguration)
    const inlineFilters = useSelector(filterSelectors.getInlineFilters)
    const setInlineFilters = inlineFilters => dispatch(filterCreators.setInlineFilters(inlineFilters))
    const networkContext = useSelector(filterSelectors.getCoord)
    const interactionType = useSelector(widgetSelectors.getInteractionType)

    return (
        <Dialog className={classes.dialog} open={open} onClose={onClose} maxWidth="lg">
            <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                <Typography variant="h6">{config?.Title}</Typography>
                <Tooltip key="close" title="Close Summary Dialog">
                    <IconButton sx={{ color: 'text.primary', padding: '10px' }} onClick={onClose}>
                        <Close />
                    </IconButton>
                </Tooltip>
            </Box>
            <DialogContent sx={{ display: 'flex', flexDirection: 'row' }}>
                <Box sx={{ width: '50%', marginRight: '15px' }}>
                    <Widget
                        ref={widgetRef}
                        config={config}
                        filters={filters}
                        networkContext={networkContext}
                        inlineFilters={inlineFilters}
                        setInlineFilters={setInlineFilters}
                        interactionType={interactionType}
                    />
                </Box>
                <Box className={classes.summary}>
                    <PerspectiveSummary open={open} />
                </Box>
            </DialogContent>
        </Dialog>
    )
}
