import { initialize, pageview as GApageview } from 'react-ga'

const { GOOGLE_ANALYTICS } = window.TADA_APIS
const useGA = Boolean(GOOGLE_ANALYTICS)

if (useGA) initialize(GOOGLE_ANALYTICS)

export function pageview(path) {
    if (!useGA) return

    GApageview(path)
}
