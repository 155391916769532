import { BareResource } from 'genesis-suite/types/networkTypes'

export enum FilterSourceType {
    SESSION = 'SESSION',
    USER_DEFAULTS = 'USER_DEFAULTS',
    WIDGET_DEFAULTS = 'WIDGET_DEFAULTS',
}

export type FilterApplyMethod = 'apply-only' | 'save' | 'save-for-all'

export interface WidgetFilter {
    search: any[]
    dynamic: FilterValueByPropertyName
    temporal: any[]
}

export interface FilterValueByPropertyName {
    [propertyName: string]: WidgetFilterValue
}
export interface DatePickerFilter {
    values: []
    range: object
    rangeOffset: object
    useLastRefreshDate: false
    clickRangeName: null
    operator: 'EqualTo'
    FilterName: string
}
export interface WidgetFilterValue {
    /** dynamic filter id */
    id: string
    resource: BareResource
    operator: FilterOperator
    values: Array<{ label?: string; value: string | number }>
    datePickerFilter?: DatePickerFilter
}

export type FilterOperator =
    | 'EqualTo'
    | 'NotEqualTo'
    | 'Contains'
    | 'NotContains'
    | 'StartsWith'
    | 'EndsWith'
    | 'GreaterThan'
    | 'LessThan'
    | 'LessThanorEqualTo'
    | 'GreaterThanorEqualTo'
