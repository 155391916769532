import React from 'react'
import { ListItemText } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

import WidgetInfoEmptyText from './WidgetInfoEmptyText'
import { useSelector } from 'react-redux'
import { filterSelectors } from '../../selectors'

const useStyles = makeStyles(({ palette }) => ({
    filterContent: { fontSize: 12 },
    filterHeading: { fontSize: 13, fontWeight: 'bold' },
}))

export default function WidgetInfoGlobalFilters() {
    const classes = useStyles()

    const globalFiltersApplied = useSelector(filterSelectors.getAppliedGlobalFilters) || {}
    const globalFiltersConfig: any = useSelector(filterSelectors.getGlobalFiltersConfig)

    const hasGlobalFilters = Object.keys(globalFiltersApplied).some((distinctValues, index) => {
        return Object.values(globalFiltersApplied[distinctValues]?.values ?? {}).length !== 0
    })

    if (!hasGlobalFilters) return <WidgetInfoEmptyText>No global filter(s) applied</WidgetInfoEmptyText>

    return (
        <div>
            {globalFiltersConfig.map((filterKeys, index) => {
                const filterValues = Object.values(globalFiltersApplied[filterKeys.Name]?.values ?? {})

                return filterValues.length > 0 ? (
                    <ListItemText key={index}>
                        <span className={classes.filterHeading}>{filterKeys.Title + ':'}</span>

                        <ListItemText key={index}>
                            {filterValues.map((global: any, index) => (
                                <span key={index} className={classes.filterContent}>
                                    {global.label}
                                    {index < filterValues.length - 1 ? ', ' : ''}
                                </span>
                            ))}
                        </ListItemText>
                    </ListItemText>
                ) : null
            })}
        </div>
    )
}
