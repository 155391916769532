export interface TrellisPointer {
    index: number
    name: string
}

export enum TrellisConstants {
    CATEGORY_INDEX = 0,
    GRID_MIN = 1,
    GRID_MAX = 10,
}
