import isEmpty from 'lodash/isEmpty'

import { filterTypes } from '../types/filter.types'
import { widgetSelectors, filterSelectors, moduleSelectors, applicationSelectors, authSelectors } from '../../selectors'
import { authCreators, widgetCreators } from './'
import { networkFilterActions } from '../../redux/networkFilterSlice'

const applyPerspectiveFilterUserPreferences = perspectiveId => {
    return (dispatch, getState) => {
        const state = getState()
        const filters = filterSelectors.getFilterPreference(state, 'PerspectiveFilters-' + perspectiveId)
        let networkFilters = authSelectors.getPreference(state, 'NetworkFilters-' + perspectiveId)
        if (isEmpty(networkFilters)) networkFilters = filterSelectors.getPerspectiveWidgetNetworkDefaults(state)
        if (isEmpty(networkFilters)) {
            const securityNetworkFilter = authSelectors.getAppDefaultNetworkFilters(state)
            if (securityNetworkFilter) networkFilters = [securityNetworkFilter]
        }
        dispatch(networkFilterActions.apply(networkFilters || []))

        const currentPerspectiveDefaults = filterSelectors.getUserPerspectiveDefaults(state)

        if (isEmpty(filters) && isEmpty(currentPerspectiveDefaults)) return
        dispatch(changePerspectiveDefaults(filters))
    }
}

const saveDefaultGlobalFilters = filters => {
    return (dispatch, getState) => {
        const state = getState()
        const moduleId = moduleSelectors.getModuleId(state)
        const isV2 = moduleSelectors.getIsV2(state)
        const _filters = isV2 ? filters : filterSelectors.removeEmptyFilters(filters)
        const newPreference = createFilterPreference(state, _filters, 'GlobalFilters', moduleId)

        dispatch(applyGlobalFilters(_filters))

        dispatch(authCreators.saveUserPreferencesForCurrentApplication([newPreference]))
    }
}

const saveDefaultPerspectiveFilters = (filters, saveForAll) => {
    return (dispatch, getState) => {
        //first thing we do is apply the filters they are trying to save so that the ui reflects the new filter selection
        dispatch(applyPerspectiveFilters(filters))

        const state = getState()
        const currentPerspective = widgetSelectors.getCurrentPerspectiveId(state)
        const nonDefaultFirstValueFilters = filterSelectors.removerDefaultFirstValueFilters(state, filters)
        const nonEmptyFilters = filterSelectors.removeEmptyFilters(nonDefaultFirstValueFilters)

        if (!saveForAll) return dispatch(saveDefaultPerspectiveFiltersForUser(nonEmptyFilters, currentPerspective))
        else return dispatch(saveDefaultFiltersForWidget(nonEmptyFilters, currentPerspective))
    }
}

/**
 * Saves default filters for a perspective to the user preferences for a single user
 * @param {*} filters - the filters selection to be saved
 * @param {*} perspectiveId - id of the perspective to save defaults for
 */
const saveDefaultPerspectiveFiltersForUser = (filters, perspectiveId) => (dispatch, getState) => {
    const newPreference = createFilterPreference(getState(), filters, 'PerspectiveFilters-' + perspectiveId)
    dispatch(changePerspectiveDefaults(filters))
    dispatch(authCreators.saveUserPreferencesForCurrentApplication([newPreference]))
}

/**
 * Saves default filters for a perspective to the user preferences for a single user
 * @param {*} filters - the filters selection to be saved
 * @param {*} perspectiveId - id of the perspective to save defaults for
 * @param {*} saveForAll - flag to determine if to be saved for all or user
 */
const saveDefaultNetworkFilters = (filters, perspectiveId, saveForAll) => (dispatch, getState) => {
    dispatch(networkFilterActions.apply(filters))
    if (!saveForAll) {
        const newPreference = createFilterPreference(getState(), filters, 'NetworkFilters-' + perspectiveId)
        return dispatch(authCreators.saveUserPreferencesForCurrentApplication([newPreference]))
    }

    const state = getState()
    const currentPerspective = widgetSelectors.getCurrentPerspectiveId(state)
    return dispatch(saveDefaultFiltersForWidget(filters, currentPerspective, 'DefaultNetworkFilters'))
}
/**
 * Saves default filters for a perspective to the widget config object to be applied for all users
 * @param {*} filters - the filters selection to be saved
 * @param {*} perspectiveId - id of the perspective to save defaults for
 * @param {*} filterKey - the key name in config to save filters
 */
const saveDefaultFiltersForWidget =
    (filters, perspectiveId, filterKey = 'DefaultFilters') =>
    (dispatch, getState) => {
        const state = getState()
        const widgetConfig = widgetSelectors.getWidgetConfig(state, perspectiveId)
        const defaultFilters =
            filterKey === 'DefaultNetworkFilters' ? filters : createFilterPreferenceValue(state, filters)

        widgetConfig[filterKey] = defaultFilters
        return dispatch(widgetCreators.saveWidget(widgetConfig))
    }

const changeGlobalFilters = filters => ({
    type: filterTypes.GLOBAL_FILTER_CHANGE,
    payload: { filters },
})

const changePerspectiveDefaults = filters => ({
    type: filterTypes.PERSPECTIVE_DEFAULT_FILTER_CHANGE,
    payload: { filters },
})

const changePerspectiveFilters = filters => ({
    type: filterTypes.PERSPECTIVE_FILTER_CHANGE,
    payload: { filters },
})

const changeComparePerspectiveFilters = filters => ({
    type: filterTypes.COMPARE_PERSPECTIVE_FILTER_CHANGE,
    payload: { filters },
})

const setInlineFilters = inlineFilters => ({
    type: filterTypes.SET_INLINE_FILTERS,
    payload: inlineFilters,
})

const resetInlineFilters = () => ({
    type: filterTypes.RESET_INLINE_FILTERS,
})

const resetInlineCompareFilters = () => ({
    type: filterTypes.RESET_INLINE_COMPARE_FILTERS,
})

const applyGlobalFilters = filters => {
    return (dispatch, getState) => {
        const state = getState()

        // Reset inline filters
        dispatch(resetInlineFilters(widgetSelectors.getCurrentPerspectiveId(state)))
        dispatch(changeGlobalFilters(filters))
    }
}

const applyPerspectiveFilters = (nextFilters, shouldResetDeletedPerspectiveFilters = true) => {
    return (dispatch, getState) => {
        const state = getState()
        const perspectiveId = widgetSelectors.getCurrentPerspectiveId(state)
        const deletedFilters = filterSelectors.getDeletedFilters(state)
        const inlineFilters = filterSelectors.getInlineFilters(state)

        if (shouldResetDeletedPerspectiveFilters && !isEmpty(deletedFilters)) dispatch(resetDeletedPerspectiveFilters())
        if (!isEmpty(inlineFilters)) dispatch(resetInlineFilters(perspectiveId))
        dispatch(changePerspectiveFilters(nextFilters))
    }
}

const applyComparePerspectiveFilters = (nextFilters, shouldResetDeletedPerspectiveFilters = true) => {
    return (dispatch, getState) => {
        const state = getState()
        const perspectiveId = widgetSelectors.getCurrentPerspectiveId(state)
        const deletedFilters = filterSelectors.getDeletedFilters(state)
        const inlineFilters = filterSelectors.getInlineFilters(state)

        if (shouldResetDeletedPerspectiveFilters && !isEmpty(deletedFilters))
            dispatch(resetDeletedComparePerspectiveFilters())
        if (!isEmpty(inlineFilters)) dispatch(resetInlineCompareFilters(perspectiveId))
        dispatch(changeComparePerspectiveFilters(nextFilters))
    }
}

const applySearchFilters = filters => ({
    type: filterTypes.SEARCH_FILTER_CHANGE,
    payload: { filters },
})

const applyCompareSearchFilters = filters => ({
    type: filterTypes.COMPARE_SEARCH_FILTER_CHANGE,
    payload: { filters },
})

/** Set the filters based on the source of filters passed */
const setBuilderFilters = (source, filters) => ({
    type: filterTypes.SET_BUILDER_FILTERS,
    payload: { filters, source },
})

const deleteContextFilter = deletedContextFilters => ({
    type: filterTypes.DELETE_CONTEXT_FILTER,
    payload: { deletedContextFilters },
})

const addContextFilter = addedContextFilters => ({
    type: filterTypes.ADD_CONTEXT_FILTER,
    payload: { addedContextFilters },
})

const setDeletedFilters = deletedFilters => ({
    type: filterTypes.SET_DELETED_FILTERS,
    payload: { deletedFilters },
})

const resetDeletedPerspectiveFilters = () => ({
    type: filterTypes.RESET_DELETED_PERSPECTIVE_FILTERS,
})

const resetDeletedComparePerspectiveFilters = () => ({
    type: filterTypes.RESET_DELETED_COMPARE_PERSPECTIVE_FILTERS,
})

const setCurrentWidgetId = currentWidgetId => ({
    type: filterTypes.SET_CURRENT_WIDGET_ID,
    payload: { currentWidgetId },
})

const setContext = context => ({
    type: filterTypes.SET_CONTEXT,
    payload: context,
})

const setCompareContext = context => ({
    type: filterTypes.SET_COMPARE_CONTEXT,
    payload: context,
})

const clearAllPerspectiveFilters = () => (dispatch, getState) => {
    const filterConfigs = filterSelectors.getPerspectiveFiltersConfig(getState())
    const filters = filterConfigs.reduce((acc, filterConfig) => {
        acc[filterConfig.Name] = emptyFilter
        return acc
    }, {})
    dispatch(applyPerspectiveFilters(filters))
}

export const filterCreators = {
    saveDefaultGlobalFilters,
    saveDefaultPerspectiveFilters,
    applyPerspectiveFilterUserPreferences,
    applyPerspectiveFilters,
    applyComparePerspectiveFilters,
    applySearchFilters,
    applyCompareSearchFilters,
    applyGlobalFilters,
    setInlineFilters,
    addContextFilter,
    deleteContextFilter,
    setDeletedFilters,
    resetInlineFilters,
    setCurrentWidgetId,
    saveDefaultPerspectiveFiltersForUser,
    changePerspectiveFilters,
    setContext,
    setCompareContext,
    setBuilderFilters,
    saveDefaultNetworkFilters,
    clearAllPerspectiveFilters,
}

const createFilterPreference = (state, filters, preferenceKey, VisorId) => {
    const currentApplication = applicationSelectors.getCurrentAppName(state)
    const isV2 = moduleSelectors.getIsV2(state)
    if (!currentApplication) return

    const preferenceValue =
        isV2 || preferenceKey.includes('NetworkFilters-') ? filters : createFilterPreferenceValue(state, filters)
    const filterPreference = filterSelectors.getFilterPreference(state, preferenceKey)

    return {
        Id: filterPreference ? filterPreference.Id : null,
        Key: preferenceKey,
        Value: preferenceValue.length ? JSON.stringify(preferenceValue) : null,
        VisorId,
    }
}

const createFilterPreferenceValue = (state, filterById) =>
    Object.keys(filterById).map(filterId => {
        const filterConfig = filterSelectors.getFilterConfig(state, filterId)
        return filterSelectors.getConfigurationFromSelection(filterById[filterId], filterConfig)
    })

const emptyFilter = {
    values: [],
    range: {},
    rangeOffset: {},
    useLastRefreshDate: false,
    clickRangeName: null,
    operator: 'EqualTo',
}
