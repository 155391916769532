import { collaborationCreators, dialogCreators } from '../actions/creators'

export const registerCollaborationClientFunctions = dispatch => {
    const { $ } = window
    const collaborationHub = $.connection.collaborationHub
    const notificationHub = $.connection.TADANotificationsHub

    if (collaborationHub) {
        collaborationHub.client.showContent = (fromId, content) => {
            dispatch(dialogCreators.showCollaborationDialog(fromId, content))
        }

        collaborationHub.client.registered = sessionName => {
            dispatch(collaborationCreators.collaborationRegistered(sessionName))
        }

        collaborationHub.client.unregistered = () => {
            dispatch(collaborationCreators.collaborationUnregistered())
        }

        collaborationHub.client.collaborators = clients => {
            dispatch(collaborationCreators.collaborationCollaborators(clients))
        }
    }

    if (notificationHub) {
        notificationHub.client.notifyTADANotification = message => {
            console.info('Received Notification: ', message)
        }
    }
}
