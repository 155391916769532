import React from 'react'
import { useSelector } from 'react-redux'
import { Box, Tooltip, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { TreeItem, TreeView } from '@mui/x-tree-view'
import CollapseIcon from '@mui/icons-material/ExpandMore'
import ExpandIcon from '@mui/icons-material/ChevronRight'

import { widgetSelectors } from '../../selectors'

const useStyles = makeStyles({
    titleText: { fontSize: 15, fontWeight: 'bold' },
})

export default function WidgetInfoDiagnostics({ config }) {
    const diagnostics = useSelector(widgetSelectors.getDiagnostics)
    const { time, trace } = diagnostics[config.Id] || {}

    const classes = useStyles()

    return (
        <div>
            {time !== undefined && (
                <>
                    <Typography className={classes.titleText} display="inline">
                        Total user data request time:
                    </Typography>
                    <Typography display="inline">
                        {` ${time === 0 ? '< ' : ''}${time || '1'} second${time < 2 ? '' : 's'}`}
                    </Typography>

                    <Box mt={1} />
                </>
            )}

            {trace && (
                <div>
                    <Typography className={classes.titleText} display="inline">
                        Widget data processing times:
                    </Typography>

                    <TreeView multiSelect defaultCollapseIcon={<CollapseIcon />} defaultExpandIcon={<ExpandIcon />}>
                        <TreeItem nodeId="root" label={<TreeLabel {...trace} />}>
                            {trace.traces?.map((t, i) => (
                                <TraceItem key={i} depth={1} nodeId={i.toString()} {...t} />
                            ))}
                        </TreeItem>
                    </TreeView>
                </div>
            )}
        </div>
    )
}

function TraceItem({ nodeId, depth, traces, name, time, description }) {
    return (
        <TreeItem nodeId={nodeId} label={<TreeLabel name={name} time={time} description={description} />}>
            {traces?.map((t, i) => (
                <TraceItem key={i} depth={depth + 1} nodeId={`${nodeId}-${i.toString()}`} {...t} />
            ))}
        </TreeItem>
    )
}

const useTreeLabelStyles = makeStyles({
    labelContainer: { display: 'grid', gridTemplateColumns: '250px auto' },
    labelText: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
})

function TreeLabel({ name, time, description }) {
    const classes = useTreeLabelStyles()
    return (
        <Tooltip title={description ?? ''} placement="right">
            <div className={classes.labelContainer}>
                <Typography className={classes.labelText}>{name}</Typography>
                <Typography>{time.toFixed(2)} ms</Typography>
            </div>
        </Tooltip>
    )
}
