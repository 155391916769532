import { makeStyles } from '@mui/styles'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

interface MobileWidgetRecommendationsProps {
    items: any[]
    handleOpenWidget: (widget: any) => void
    ThumbnailCard: React.FC<any> // Receive ThumbnailCard as a prop
}

const useStyles = makeStyles(theme => ({
    container: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '0 10px',

        '& .slick-prev:before, .slick-next:before': {
            color: theme.palette.text.primary,
        },
        '& .slick-next': {
            right: '-20px !important',
            zIndex: 1,
        },
        '& .slick-prev': {
            left: '-20px !important',
            zIndex: 1,
        },
    },
    sliderItem: {
        padding: '8px',
    },
}))

const MobileWidgetRecommendations: React.FC<MobileWidgetRecommendationsProps> = ({
    items,
    handleOpenWidget,
    ThumbnailCard,
}) => {
    const classes = useStyles()

    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    }

    return (
        <div className={classes.container}>
            <Slider {...sliderSettings}>
                {items.map((config, index) => (
                    <div key={index} className={classes.sliderItem}>
                        <ThumbnailCard config={config} handleOpenWidget={handleOpenWidget} />
                    </div>
                ))}
            </Slider>
        </div>
    )
}

export default MobileWidgetRecommendations
