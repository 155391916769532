import { useState } from 'react'
import { Button, Menu, MenuItem } from '@mui/material'
import { FilterOperator } from '~/types/FilterTypes'

interface Props {
    /** (default false) determines the list of filter options */
    numeric?: boolean
    /** (default EqualTo) */
    value?: FilterOperator
    onChange: (operator: FilterOperator) => void
}

export default function FilterOperatorButton({ numeric, value = 'EqualTo', onChange }: Props) {
    const [buttonRef, setButtonRef] = useState(null)

    const operators = makeOperators(numeric)

    function handleSelect(operator) {
        onChange(operator)
        setButtonRef(null)
    }

    return (
        <>
            <Button
                onClick={e => setButtonRef(e.currentTarget)}
                sx={{ fontSize: '0.7rem', fontWeight: 'normal', flex: 'none', color: 'tada.teal' }}
            >
                {operators.find(o => o.value === value).label}
            </Button>

            <Menu
                anchorEl={buttonRef}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(buttonRef)}
                onClose={() => setButtonRef(null)}
            >
                {operators.map(o => (
                    <MenuItem key={o.value} selected={o.value === value} onClick={() => handleSelect(o.value)}>
                        {o.label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}

export const isSelect = (operator: FilterOperator) => operator === 'EqualTo' || operator === 'NotEqualTo'

interface Option {
    value: FilterOperator
    label: string
}

function makeOperators(numeric: boolean): Array<Option> {
    const baseOptions: Option[] = [
        { value: 'EqualTo', label: 'Equals' },
        { value: 'NotEqualTo', label: 'Not equal' },
    ]

    return numeric
        ? [
              ...baseOptions,
              { value: 'GreaterThan', label: '>' },
              { value: 'GreaterThanorEqualTo', label: '>=' },
              { value: 'LessThan', label: '<' },
              { value: 'LessThanorEqualTo', label: '<=' },
          ]
        : [
              ...baseOptions,
              { value: 'Contains', label: 'Contains' },
              { value: 'NotContains', label: 'Not contains' },
              { value: 'StartsWith', label: 'Starts with' },
              { value: 'EndsWith', label: 'Ends with' },
          ]
}
