"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Csv", {
  enumerable: true,
  get: function () {
    return _Csv.default;
  }
});
Object.defineProperty(exports, "Doc", {
  enumerable: true,
  get: function () {
    return _Doc.default;
  }
});
Object.defineProperty(exports, "Docx", {
  enumerable: true,
  get: function () {
    return _Docx.default;
  }
});
Object.defineProperty(exports, "Generic", {
  enumerable: true,
  get: function () {
    return _Generic.default;
  }
});
Object.defineProperty(exports, "Gif", {
  enumerable: true,
  get: function () {
    return _Gif.default;
  }
});
Object.defineProperty(exports, "Jpg", {
  enumerable: true,
  get: function () {
    return _Jpg.default;
  }
});
Object.defineProperty(exports, "Pdf", {
  enumerable: true,
  get: function () {
    return _Pdf.default;
  }
});
Object.defineProperty(exports, "Png", {
  enumerable: true,
  get: function () {
    return _Png.default;
  }
});
Object.defineProperty(exports, "Ppt", {
  enumerable: true,
  get: function () {
    return _Ppt.default;
  }
});
Object.defineProperty(exports, "Pptx", {
  enumerable: true,
  get: function () {
    return _Pptx.default;
  }
});
Object.defineProperty(exports, "Svg", {
  enumerable: true,
  get: function () {
    return _Svg.default;
  }
});
Object.defineProperty(exports, "Txt", {
  enumerable: true,
  get: function () {
    return _Txt.default;
  }
});
Object.defineProperty(exports, "XlsFile", {
  enumerable: true,
  get: function () {
    return _Xls.default;
  }
});
Object.defineProperty(exports, "Xlsx", {
  enumerable: true,
  get: function () {
    return _Xlsx.default;
  }
});
var _Csv = _interopRequireDefault(require("./Csv"));
var _Doc = _interopRequireDefault(require("./Doc"));
var _Docx = _interopRequireDefault(require("./Docx"));
var _Generic = _interopRequireDefault(require("./Generic"));
var _Gif = _interopRequireDefault(require("./Gif"));
var _Jpg = _interopRequireDefault(require("./Jpg"));
var _Pdf = _interopRequireDefault(require("./Pdf"));
var _Png = _interopRequireDefault(require("./Png"));
var _Ppt = _interopRequireDefault(require("./Ppt"));
var _Pptx = _interopRequireDefault(require("./Pptx"));
var _Svg = _interopRequireDefault(require("./Svg"));
var _Txt = _interopRequireDefault(require("./Txt"));
var _Xls = _interopRequireDefault(require("./Xls"));
var _Xlsx = _interopRequireDefault(require("./Xlsx"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }