import produce from 'immer'
import { useContext } from 'react'

import { ArrowDownwardRounded, ArrowUpwardRounded } from '@mui/icons-material'
import { Box, ListItem, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { DragItem, DragList } from 'genesis-suite/components'
import { Menu } from 'genesis-suite/icons'
import { Property } from 'genesis-suite/types/networkTypes'
import { letMeMap } from 'genesis-suite/types/utilTypes'
import {
    CategoryTableColumn, SeriesConfig,
    SortProps, TableConfig, ValueTableColumn
} from 'genesis-suite/types/visualTypes'
import BorderedSection from '../../BorderedSection'
import LabeledToggleButton from '../../LabeledToggleButton'
import { DisplayProperty, FieldPointer } from '../builderTypes'
import { ConfigContext } from '../ConfigContext'
import { getDataFieldProperty } from '../utils'

const useStyles = makeStyles(({ palette, spacing }) => ({
    listItem: {
        padding: spacing(0.5, 1),
        gridGap: spacing(),
        backgroundColor: '#fff',
        flex: 1,
        alignItems: 'center',
    },
    ruleButtonSelected: {
        backgroundColor: `${palette.secondary.light} !important`,
        color: palette.primary.contrastText,
    },
    ruleDragIcon: { fontSize: '18px', color: 'inherit', cursor: 'grab' },
    ruleDeleteIcon: { fontSize: '18px', color: 'inherit' },
}))

export default function SortOrderEditor() {
    const classes = useStyles()
    const { dispatch, resources, ...rest } = useContext(ConfigContext)
    const config = rest.config as SeriesConfig

    //don't show this configuration if it's not a dynamic table
    if (!(config as TableConfig).dynamic) return null

    const { series } = config

    const seriesValues =
        series?.[0]?.values.map(value => {
            return {
                ...value,
                service: { ...series[0].service },
            }
        }) ?? []

    let displayProperties: Partial<DisplayProperty>[] = []

    displayProperties = displayProperties
        .concat(
            letMeMap(config.categories).map((value, index) => {
                if (!value.sort) return null
                const properties = Object.values(resources.byId).reduce<Array<Property>>((acc, currData) => {
                    return [...acc, ...currData.properties]
                }, [])

                const { displayName } = getDataFieldProperty(value.field, properties)

                return {
                    title: displayName,
                    pointer: { type: 'category', index: index },
                    sortIndex: value.sortIndex,
                    sort: value.sort,
                }
            })
        )
        .filter(c => c)

    displayProperties = displayProperties.concat(
        seriesValues
            .map((value, i) => {
                if (!value.sort) return null
                const { properties } = resources.byId[value.service.id] || {}
                const { displayName } = getDataFieldProperty(value.field, properties)

                return {
                    ...value,
                    title: displayName,
                    pointer: { type: 'series', index: 0, valueIndex: i },
                    sortIndex: value.sortIndex,
                }
            })
            .filter(s => s)
    )

    displayProperties = displayProperties.sort((a, b) => a.sortIndex - b.sortIndex)

    const handleRemove = (index: number) => {
        const element = displayProperties[index]

        const categoriesToUpdate: Array<CategoryTableColumn> = config.categories
            .map(c => {
                if (c.sortIndex === element.sortIndex) return { ...c, sort: null, sortIndex: null }
                if (c.sortIndex > element.sortIndex) return { ...c, sortIndex: c.sortIndex - 1 }
                return null
            })
            .filter(c => c)

        const seriesToUpdate: Array<ValueTableColumn> = config.series[0].values
            .map(s => {
                if (s.sortIndex === element.sortIndex) return { ...s, sort: null, sortIndex: null }
                if (s.sortIndex > element.sortIndex) return { ...s, sortIndex: s.sortIndex - 1 }
                return null
            })
            .filter(s => s)

        const newConfig: SeriesConfig = produce(config, draft => {
            categoriesToUpdate.forEach((c, index) => {
                draft.categories[index] = { ...c }
            })
            seriesToUpdate.forEach((s, index) => {
                draft.series[0].values[index] = { ...s }
            })
        })

        dispatch({ type: 'UPDATE_CONFIG', payload: newConfig })
    }

    const handleDragEnd = items => {
        const categoriesToUpdate: Array<CategoryTableColumn & { position: number }> = []
        const seriesToUpdate: Array<ValueTableColumn & { position: number }> = []

        items.forEach((item, index) => {
            if (item.pointer.type === 'category') {
                if (index === config.categories[item.pointer.index].sortIndex) return
                categoriesToUpdate[item.pointer.index] = {
                    ...config.categories[item.pointer.index],
                    sortIndex: index,
                    position: item.pointer.index,
                }
            }

            if (item.pointer.type === 'series') {
                if (index === config.series[0].values[item.pointer.valueIndex].sortIndex) return

                seriesToUpdate[item.pointer.valueIndex] = {
                    ...config.series[0].values[item.pointer.valueIndex],
                    sortIndex: index,
                    position: item.pointer.valueIndex,
                }
            }
        })

        const newConfig: SeriesConfig = produce(config, draft => {
            categoriesToUpdate.forEach(c => {
                const position = c.position
                delete c.position
                draft.categories[position] = { ...c }
            })
            seriesToUpdate.forEach(s => {
                const position = s.position
                delete s.position
                draft.series[0].values[position] = { ...s }
            })
        })

        dispatch({ type: 'UPDATE_CONFIG', payload: newConfig })
    }

    function handleUpdate(pointer: FieldPointer, sortProps: Partial<SortProps>, index: number) {
        if (!sortProps.sort) return handleRemove(index)
        dispatch({ type: 'UPDATE_SORT', payload: { pointer, ...sortProps } })
    }

    return (
        <BorderedSection header="Sort Order">
            {displayProperties.length > 0 ? (
                <DragList onDragEnd={handleDragEnd} items={displayProperties}>
                    {displayProperties.map((property, index) => (
                        <DragItem key={index} itemId={index} index={index}>
                            <ListItem classes={{ root: classes.listItem, selected: classes.ruleButtonSelected }}>
                                <Menu className={classes.ruleDragIcon} />
                                <Box flex="1" display="flex" flexDirection="column" alignItems="stretch" gap="4px">
                                    <Typography marginX="2px" color="inherit">
                                        {property.title}
                                    </Typography>
                                    <LabeledToggleButton
                                        size="small"
                                        value={property.sort}
                                        options={directionOptions}
                                        onChange={(e, sort) =>
                                            handleUpdate(property.pointer, { sort: sort || undefined }, index)
                                        }
                                    />
                                </Box>
                            </ListItem>
                        </DragItem>
                    ))}
                </DragList>
            ) : (
                <Typography fontSize="0.75rem" fontStyle="italic" textAlign="center">
                    Select a sort direction for one or more Columns or Category Columns and you can order them here.
                </Typography>
            )}
        </BorderedSection>
    )
}

const directionOptions = [
    { value: 'ascending', label: <ArrowUpwardRounded fontSize="small" />, tip: 'Ascending' },
    { value: 'descending', label: <ArrowDownwardRounded fontSize="small" />, tip: 'Descending' },
]
