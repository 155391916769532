import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { ArrowForwardRounded as ArrowRight, ArrowBackRounded as ArrowLeft } from '@mui/icons-material'

import { MenuIcon } from 'genesis-suite/components'
import { interactionCreators } from '../../actions/creators'
import { interactionSelectors } from '../../selectors'

const useStyles = makeStyles({ profileHistory: { flex: 1 } })

export default function ProfileHistory() {
    const count = useSelector(interactionSelectors.getProfiles).length
    const index = useSelector(interactionSelectors.getProfileIndex)
    const dispatch = useDispatch()

    const classes = useStyles()

    const disableBack = count === 0 || index === 0
    const disableForward = count === 0 || index === count - 1

    return (
        <div className={classes.profileHistory}>
            <MenuIcon
                icon={<ArrowLeft />}
                tooltip="Go back"
                buttonProps={{ sx: { padding: '1px' } }}
                onClick={() => dispatch(interactionCreators.changeProfileIndex(index - 1))}
                disabled={disableBack}
            />
            <MenuIcon
                icon={<ArrowRight />}
                tooltip="Go forward"
                buttonProps={{ sx: { padding: '1px' } }}
                onClick={() => dispatch(interactionCreators.changeProfileIndex(index + 1))}
                disabled={disableForward}
            />
        </div>
    )
}
