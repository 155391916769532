export const widgetConstants = {
    Interactions: {
        DRILLDOWN: 'drilldown',
        INFINITE: 'infinite',
        INLINE: 'inline',
    },
    Edit: {
        NEW_ROUTE: '__new',
        BUSINESS_EXPLORER_ROUTE: '__business-explorer',
    },
    DataStatus: {
        /** Waiting to start fetching data (initial state). If perspective contains a control widget, all remaining widgets stay in waiting until the control widget context is in place */
        WAITING: 'waiting',
        FETCHING: 'fetching',
        DATA: 'data',
        EMPTY: 'empty',
        ERROR: 'error',
        UNAUTHORIZED: 'unauthorized',
    },
    Type: {
        BUSINESS_ELEMENT: 'BUSINESS-ELEMENT',
    },
}
