import { Box, MenuItem, Paper, TextField, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { MenuIcon } from 'genesis-suite/components'
import { Filter as FilterIcon } from 'genesis-suite/icons'
import { TreeConfig } from 'genesis-suite/types/visualTypes'
import { useLocation } from 'react-router-dom'
import { architectureService, treeService } from '../../lib/services'
import { applicationSelectors } from '../../selectors'
import NavigationEditor from '../edit_widget/config_fields/NavigationEditor'

const useStyles = makeStyles(({ palette, spacing }) => ({
    sectionTitle: { fontWeight: 'bold', margin: spacing(), fontSize: '20px' },
    titleWrapper: { display: 'flex', alignItems: 'center' },
    filterWrapper: { width: '100%' },
    fieldWrapper: {
        display: 'flex',
        flexDirection: 'column',
        padding: spacing(0.8, 0.8, 0, 0.8),
        marginBottom: spacing(1.5),
        backgroundColor: palette.background.sideNav,
    },
    field: {
        ['& .MuiInputBase-input:focus']: { backgroundColor: 'white' },
        marginBottom: spacing(0.8),
        width: '100%',
        padding: spacing(0.8, 0.8, 0.8, 0.8),
    },
}))

interface Props {
    className: string
    config: TreeConfig
    onChange?: (config: TreeConfig) => void
}

export default function SourceList({ className, config, onChange }: Props) {
    const classes = useStyles()
    const [trees, setTrees] = useState([])
    const [nodes, setNodes] = useState([])
    const [selectedNode, setSelectedNode] = useState('')
    const [selectedTree, setSelectedTree] = useState(config.treeName)
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const [open, setOpen] = useState(false)
    const location = useLocation()
    const state = location.state as any
    const focalPoint = state.focalPoint

    const cancelCall = useRef(null)

    useEffect(() => {
        treeService
            .getTrees(appName, cancel => {
                cancelCall.current = cancel
            })
            .then(res => {
                setTrees(res)
            })
            .catch(error => {
                if (error.__CANCEL__) return
                setTrees([])
            })

        architectureService
            .getNodes(appName, '', cancel => {
                cancelCall.current = cancel
            })
            .then(res => {
                setNodes(res)
            })
            .catch(error => {
                if (error.__CANCEL__) return
                setNodes([])
            })
        return () => cancelCall.current()
    }, [])

    async function updateServices(id) {
        if (id === selectedNode) return

        setSelectedNode(id)
        setOpen(false)
    }

    const handleChange = async treeName => {
        if (treeName === selectedTree) return

        setSelectedTree(treeName)
        onChange({ ...config, treeName })
    }

    function update(navigation) {
        onChange({ ...config, navigation })
    }

    return (
        <div className={className}>
            <Box overflow="auto" flex={1}>
                <div>
                    <div className={classes.titleWrapper}>
                        <Typography className={classes.sectionTitle} gutterBottom variant="subtitle1">
                            Trees
                        </Typography>
                        <MenuIcon
                            title="Nodes"
                            buttonProps={{ size: 'small' }}
                            icon={<FilterIcon fontSize="small" />}
                            open={open}
                            onClick={() => setOpen(true)}
                            onClose={() => setOpen(false)}
                        >
                            <Box width="200px">
                                <Paper elevation={0}>
                                    <TextField
                                        select
                                        className={classes.field}
                                        value={selectedNode}
                                        onChange={e => updateServices(e.target.value)}
                                        placeholder="Node"
                                    >
                                        <MenuItem key="AllNodes" value="AllNodes">
                                            All Nodes
                                        </MenuItem>
                                        {nodes &&
                                            nodes.map(node => (
                                                <MenuItem key={node.ResourceId} value={node.ResourceName}>
                                                    {node.ResourceName}
                                                </MenuItem>
                                            ))}
                                    </TextField>
                                </Paper>
                            </Box>
                        </MenuIcon>
                    </div>
                    <Paper className={classes.fieldWrapper} elevation={0}>
                        <TextField
                            select
                            className={classes.field}
                            onChange={e => handleChange(e.target.value)}
                            value={selectedTree}
                            placeholder="Tree"
                        >
                            {trees &&
                                (selectedNode !== '' && selectedNode !== 'AllNodes'
                                    ? trees
                                          .filter(t => t.ServiceName.split('.')[0] === selectedNode)
                                          .map(tree => (
                                              <MenuItem key={tree.ResourceId} value={tree.ServiceName}>
                                                  {tree.ServiceName.split('.')[1]}
                                              </MenuItem>
                                          ))
                                    : trees.map(tree => (
                                          <MenuItem key={tree.ResourceId} value={tree.ServiceName}>
                                              {tree.ServiceName.split('.')[1]}
                                          </MenuItem>
                                      )))}
                        </TextField>
                    </Paper>
                    <div className={classes.fieldWrapper}>
                        <Typography className={classes.sectionTitle} variant="subtitle1">
                            Navigation
                        </Typography>
                        <NavigationEditor
                            focalPoint={selectedNode === '' ? focalPoint : selectedNode}
                            navigation={config.navigation}
                            onUpdate={navigation => update(navigation)}
                        />
                    </div>
                </div>
            </Box>
        </div>
    )
}
