import React, { useState } from 'react'
import { ChromePicker } from 'react-color'
import makeStyles from '@mui/styles/makeStyles'
import { Box, Radio, Popover, FormLabel, TextField, RadioGroup, FormControl, FormControlLabel } from '@mui/material'

import { FormConfig, FormPalette, Gradient } from 'genesis-suite/types/visualTypes'
import { getFormBackground } from '../../../lib/formUtils'

const useStyles = makeStyles(({ spacing }) => ({
    group: { margin: spacing(0, 2) },
    previewer: {
        width: 75,
        height: 60,
        display: 'flex',
        borderRadius: 4,
        alignItems: 'center',
        justifyContent: 'center',
        border: `1px solid black`,
    },
    colorPickerWrapper: {
        width: 60,
        height: 30,
        borderRadius: 4,
        padding: spacing(0.5),
        marginTop: spacing(2),
        border: '1px solid black',
    },
    colorPicker: {
        width: '100%',
        height: '100%',
        borderRadius: 4,
        backgroundColor: ({ color }: any) => (color ? color : '#FFFFFF'),
    },
}))

interface PaletteBuilderProps {
    palette: FormPalette
    onChange: (change: Partial<FormConfig>) => void
}

export default function FormPaletteBuilder(props: PaletteBuilderProps) {
    const classes = useStyles({})
    const [temp, setTemp] = useState<Gradient>({ angle: 45, color1: 'red', color2: 'blue' })

    const { palette, onChange } = props
    const { background, fontColor } = palette

    const handleChange = (change: Partial<FormPalette>) => onChange({ palette: { ...palette, ...change } })

    const handleType = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === 'gradient') {
            const bgString = background as string
            handleChange({ background: { ...temp, color1: bgString } })
        } else {
            const bg = background as Gradient
            setTemp({ ...bg })
            handleChange({ background: bg.color1 })
        }
    }

    return (
        <Box width="100%" display="flex" alignItems="center">
            <FormControl className={classes.group}>
                <FormLabel>Background color type</FormLabel>
                <RadioGroup row value={typeof background === 'string' ? 'solid' : 'gradient'} onChange={handleType}>
                    <FormControlLabel value="solid" control={<Radio />} label="Solid" />
                    <FormControlLabel value="gradient" control={<Radio />} label="Gradient" />
                </RadioGroup>
            </FormControl>
            {typeof background === 'string' ? (
                <PopPicker label="Bg Color" color={background} onChange={background => handleChange({ background })} />
            ) : (
                <GradientBuilder gradient={background} onChange={gradient => handleChange({ background: gradient })} />
            )}
            <PopPicker label="Font color" color={fontColor} onChange={fontColor => handleChange({ fontColor })} />
            <Box className={classes.group}>
                <div
                    className={classes.previewer}
                    style={{ background: getFormBackground(background), color: fontColor }}
                >
                    Preview
                </div>
            </Box>
        </Box>
    )
}

interface GradientProps {
    gradient: Gradient
    onChange: (gradient: Gradient) => void
}

function GradientBuilder({ gradient, onChange }: GradientProps) {
    const { angle, color1, color2 } = gradient
    const handleChange = (change: Partial<Gradient>) => onChange({ ...gradient, ...change })

    return (
        <Box display="flex" justifyContent="space-evenly">
            <TextField
                label="Angle"
                type="number"
                defaultValue={angle}
                inputProps={{ min: 0, max: 360 }}
                onChange={e => handleChange({ angle: Number(e.target.value) })}
            />
            <PopPicker label="Bg Color 1" color={color1} onChange={color1 => handleChange({ color1 })} />
            <PopPicker label="Bg Color 2" color={color2} onChange={color2 => handleChange({ color2 })} />
        </Box>
    )
}

function PopPicker({ label = null, color: initColor, onChange }) {
    const [color, setColor] = useState(initColor)
    const [anchorEl, setAnchorEl] = useState(null)
    const classes = useStyles({ color })

    const toggleAnchor = (e: React.MouseEvent<HTMLElement>) => {
        if (anchorEl) {
            setAnchorEl(null)
            onChange(color)
        } else setAnchorEl(e.currentTarget)
    }

    return (
        <FormControl className={classes.group}>
            {label && <FormLabel>{label}</FormLabel>}
            <div className={classes.colorPickerWrapper} onClick={toggleAnchor}>
                <div className={classes.colorPicker} />
            </div>
            <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={toggleAnchor}>
                <ChromePicker disableAlpha color={color} onChange={({ hex }) => setColor(hex)} />
            </Popover>
        </FormControl>
    )
}
