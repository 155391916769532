import DownIcon from '@mui/icons-material/ArrowDropDown'
import { Box, Button, Popover, Popper, Tooltip, useTheme, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useEffect, useRef, useState } from 'react'
import Measure from 'react-measure'
import { useDispatch, useSelector } from 'react-redux'
import tinycolor from 'tinycolor2'

import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandIcon from '@mui/icons-material/ExpandMore'
import { ChopText, CognitiveIcon, MenuIcon } from 'genesis-suite/components'
import { useHover } from 'genesis-suite/hooks'
import { filterCreators, navigationCreators } from '../actions/creators'
import { appearanceConstants } from '../constants'
import useIsItemAtRoute from '../hooks/useIsItemAtRoute'
import { routePaths } from '../lib/routes'
import { appearanceSelectors, filterSelectors, menuSelectors } from '../selectors'
import BusinessElementDropDown from './BusinessElementDropDown'
import { ServiceTypes } from 'genesis-suite/types/visualTypes'

const buttonHeight = 34
const buttonWidth = 130
const margin = 8
const moreButtonWidth = 40

const useStyles = makeStyles(({ palette, spacing }) => ({
    root: { flex: 1, display: 'flex', alignItems: 'center' },
    moreIcon: {
        fontSize: buttonHeight,
        cursor: 'pointer',
        color: palette.primary.contrastText,
        backgroundColor: palette.primary.main,
        borderRadius: buttonHeight / 2,
    },
    hiddenContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: spacing(),
        '& > *': { marginBottom: margin },
        zIndex: 3,
    },
    button: { width: buttonWidth, marginRight: margin, height: buttonHeight, padding: '5px 7px' },
    neoClassicButton: {
        maxWidth: 130,
        height: 35,
        padding: '5px 7px',
        boxShadow: `1px -1px ${palette.getContrastText(palette.background.topBar)}`,
        textWrap: 'wrap',
        backgroundColor: '#FDBD01',
        color: '#24292E',
        '&:hover': {
            backgroundColor: '#24292E',
            color: '#FDBD01 !important',
        },
    },
    downIcon: {
        backgroundColor: '#FDBD01',
        color: '#24292E',
        '&:hover': {
            backgroundColor: '#24292E',
            color: '#FDBD01 !important',
        },
    },
    neoClassicChopText: {
        fontSize: '0.75rem',
        fontFamily: 'segoe UI',
        textAlign: 'left',
        wordWrap: 'break-word',
        textShadow: '0.5px -0.5px 0px rgba(0, 0, 0, 0.5)',
    },
    buttonIcon: {
        marginRight: spacing(0.5),
    },
}))

export default function BusinessElementButtons() {
    const all = useSelector(menuSelectors.getTopNav)
    const dispatch = useDispatch()

    const [width, setWidth] = useState(0)
    const theme = useTheme()
    const whiteText = tinycolor(theme.palette.primary.main).isDark()
    const classes = useStyles()
    const showNeoClassic = useSelector(appearanceSelectors.getShowNeoClassicLayOut)
    const [openHidden, setOpenHidden] = useState(false)

    function handleClick(type, to, treeId) {
        if (type === 'viewId') dispatch(navigationCreators.goToPerspective(to))
        if (type === 'widgetId') dispatch(navigationCreators.goTo(routePaths.WIDGET, to))
        else if (type === 'elementName') {
            const query = treeId ? { treeId } : null
            dispatch(navigationCreators.goTo(routePaths.ELEMENT, to, null, query))
        }
    }

    const visibleAmount =
        width > moreButtonWidth
            ? Math.min(Math.floor((width - moreButtonWidth) / (buttonWidth + margin)), all.length)
            : 0
    const visible = all.slice(0, visibleAmount)
    const hidden = all.slice(visibleAmount, all.length)
    const neoClassicBizButtonWidth = getViewItemWidth(visible, 0.75)

    return (
        <Measure offset onResize={contentRect => setWidth(contentRect.offset?.width)}>
            {({ measureRef }) => (
                <div className={classes.root} ref={measureRef}>
                    {visible.map(b =>
                        showNeoClassic ? (
                            <NeoClassicBizButton
                                classes={classes}
                                key={b.text}
                                whiteText={whiteText}
                                {...b}
                                onClick={handleClick}
                                buttonWidth={neoClassicBizButtonWidth}
                            />
                        ) : (
                            <BizButton
                                classes={classes}
                                key={b.text}
                                whiteText={whiteText}
                                {...b}
                                onClick={handleClick}
                            />
                        )
                    )}

                    {hidden.length ? (
                        showNeoClassic ? (
                            <Box sx={{ width: '32px' }}>
                                <MenuIcon
                                    buttonProps={{
                                        sx: {
                                            visibility: hidden.length > 0 ? 'visible' : 'hidden',
                                            color: 'text.primary',
                                        },
                                    }}
                                    icon={
                                        <DownIcon
                                            fontSize="large"
                                            sx={{
                                                cursor: 'pointer',
                                                borderRadius: 35 / 2,
                                            }}
                                            className={classes.downIcon}
                                        />
                                    }
                                    onClick={() => setOpenHidden(true)}
                                    onClose={() => setOpenHidden(false)}
                                    open={openHidden}
                                    tooltip="More"
                                    popoverProps={{
                                        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                                        transformOrigin: { vertical: 'top', horizontal: 'left' },
                                    }}
                                >
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                                        {hidden.map(b => (
                                            <NeoClassicBizButton
                                                classes={classes}
                                                key={b.text}
                                                isHidden
                                                whiteText={whiteText}
                                                {...b}
                                                onClick={handleClick}
                                                onDone={() => setOpenHidden(false)}
                                            />
                                        ))}
                                    </Box>
                                </MenuIcon>
                            </Box>
                        ) : (
                            <HoverList
                                Anchor={DownIcon}
                                anchorProps={{ className: classes.moreIcon }}
                                popperProps={{ placement: 'bottom', className: classes.hiddenContent }}
                                closeOnClick
                            >
                                {hidden.map(b => (
                                    <BizButton
                                        classes={classes}
                                        key={b.text}
                                        isHidden
                                        whiteText={whiteText}
                                        {...b}
                                        onClick={handleClick}
                                    />
                                ))}
                            </HoverList>
                        )
                    ) : null}
                </div>
            )}
        </Measure>
    )
}

/**
 * Render an anchor (button, icon, text) with a list that opens and persists on hover
 * @param {Node} props.Anchor React node specifying the anchor to hover over
 * @param {*} props.anchorProps props spread to the anchor
 * @param {*} props.popperProps props spread to the popper
 * @param {boolean=} props.closeOnClick optional. close list on clicking
 * @param {number=} props.hoverDelay default 500, ms time to delay keeping the list open after hover-away
 */
function HoverList({ Anchor, anchorProps, popperProps, closeOnClick, hoverDelay = 500, children }) {
    const [open1, setOpen1] = useState(false)
    const [open2, setOpen2] = useState(false)
    const ref1 = useRef()
    const hover1 = useHover(ref1)
    const timeout1 = useRef()
    const timeout2 = useRef()

    useEffect(() => {
        clearTimeout(timeout1.current)
        if (hover1) setOpen1(true)
        else timeout1.current = setTimeout(() => setOpen1(false), hoverDelay)
    }, [hover1])

    function handleEnterList() {
        setOpen2(true)
        clearTimeout(timeout2.current)
    }

    function handleLeaveList() {
        timeout2.current = setTimeout(() => setOpen2(false), hoverDelay)
    }

    function handleClick() {
        if (!closeOnClick) return

        setOpen1(false)
        setOpen2(false)
    }

    return (
        <>
            <Anchor ref={ref1} {...anchorProps} />

            <Popper
                open={open1 || open2}
                anchorEl={ref1.current}
                onMouseEnter={handleEnterList}
                onMouseLeave={handleLeaveList}
                onClick={handleClick}
                {...popperProps}
            >
                {children}
            </Popper>
        </>
    )
}

function BizButton({ classes, onClick, isHidden, whiteText, ...item }) {
    const ref = useRef()
    const isHovering = useHover(ref)
    const atRoute = useIsItemAtRoute(item)
    const topNav = useSelector(appearanceSelectors.getTopNav)

    const { text, type, to, icon, treeId } = item

    return (
        <Tooltip title={text}>
            <Button
                ref={ref}
                size="small"
                classes={{ root: classes.button, startIcon: classes.buttonIcon }}
                variant="contained"
                color="primary"
                onClick={() => onClick(type, to, treeId)}
                startIcon={icon ? <CognitiveIcon icon={icon} white={whiteText} /> : undefined}
            >
                {topNav !== appearanceConstants.TopNav.ICONS_ONLY || icon.file === null ? (
                    <ChopText
                        color="inherit"
                        variant="caption"
                        lineCount={2}
                        showEllipsis
                        isHovering={isHovering}
                        tooltipProps={{ placement: isHidden ? 'right' : 'bottom' }}
                    >
                        {text}
                        {atRoute && <Dot />}
                    </ChopText>
                ) : (
                    ''
                )}
            </Button>
        </Tooltip>
    )
}

function NeoClassicBizButton({ classes, onClick, isHidden, whiteText, buttonWidth, onDone, ...item }) {
    const ref = useRef()
    const isHovering = useHover(ref)
    const dispatch = useDispatch()
    const atRoute = useIsItemAtRoute(item)
    const topNav = useSelector(appearanceSelectors.getTopNav)
    const [openMenu, setOpenMenu] = useState(false)
    const appliedGlobalFilters = useSelector(filterSelectors.getAppliedGlobalFilters) || []

    const { text, type, to, serviceType, serviceName, treeId } = item

    const existingFilterConfig = useSelector(state => filterSelectors.getFilterConfigByName(state, serviceName))

    const [first, ...second] = !isHidden ? text.split(' ') : [text]
    const secondText = second ? second.join(' ') : null

    const icon = item.icon && item.icon.file ? item.icon : { file: 'General/sales.svg' }

    const selected =
        appliedGlobalFilters[
            existingFilterConfig && existingFilterConfig.Name ? existingFilterConfig.Name : serviceName
        ]

    const getLineCount = () => {
        return text.includes(' ') ? 2 : 1
    }

    const handleClick = () => {
        onClick(type, to, treeId)
    }

    const handleArrowClick = e => {
        e.stopPropagation()
        if (serviceType === ServiceTypes.Concept) {
            setOpenMenu(true)
            return
        }

        onClick(type, to)
    }

    const onApply = (filterName, values, filterConfig) => {
        const filterId = existingFilterConfig && existingFilterConfig.Name ? existingFilterConfig.Name : filterName
        const newFilters = {
            ...appliedGlobalFilters,
            [filterId]: { ...emptyFilter, values: values ?? [], filterConfig: filterConfig },
        }
        dispatch(filterCreators.applyGlobalFilters(newFilters))
        setOpenMenu(false)
        if (onDone) onDone()
    }

    const onCancel = () => {
        setOpenMenu(false)
        if (onDone) onDone()
    }

    return (
        <>
            <Tooltip title={text}>
                <Button
                    ref={ref}
                    size="small"
                    classes={{
                        root: classes.neoClassicButton,
                        startIcon: classes.buttonIcon,
                    }}
                    variant="contained"
                    color="primary"
                    onClick={handleClick}
                    sx={{ my: isHidden ? 0.5 : 0, mx: isHidden ? 0 : 0.5 }}
                    startIcon={
                        icon ? <CognitiveIcon icon={icon} bgColor={isHovering ? '#24292E' : '#FDBD01'} /> : undefined
                    }
                >
                    {topNav !== appearanceConstants.TopNav.ICONS_ONLY || icon.file === null ? (
                        <ChopText
                            color="inherit"
                            variant="caption"
                            lineCount={getLineCount()}
                            showEllipsis
                            isHovering={isHovering}
                            tooltipProps={{ placement: isHidden ? 'right' : 'bottom' }}
                            className={classes.neoClassicChopText}
                            sx={{ width: !isHidden ? buttonWidth : 'auto' }}
                        >
                            {first}
                            {secondText && <br />}
                            {secondText}
                            {atRoute && <Dot />}
                        </ChopText>
                    ) : (
                        ''
                    )}
                    <Typography sx={{ fontSize: '0.75rem', fontWeight: 600, margin: 0.25 }}>
                        {selected?.values?.length > 0 && ` (${selected.values.length})`}
                    </Typography>
                    {serviceType === ServiceTypes.Concept &&
                        (isHidden ? (
                            <ChevronRightIcon fontSize="small" onClick={e => handleArrowClick(e)} />
                        ) : (
                            <ExpandIcon fontSize="small" onClick={e => handleArrowClick(e)} />
                        ))}
                </Button>
            </Tooltip>
            <Popover
                anchorEl={ref.current}
                anchorOrigin={{ vertical: isHidden ? 'top' : 'bottom', horizontal: isHidden ? 'right' : 'left' }}
                onClose={() => setOpenMenu(false)}
                open={openMenu}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                <BusinessElementDropDown
                    elementName={serviceName}
                    onApply={onApply}
                    onCancel={onCancel}
                    selected={selected || emptyFilter}
                />
            </Popover>
        </>
    )
}

const Dot = () => <>&nbsp;&#9679;</>

function getViewItemWidth(items, fontSize) {
    let maxCharLength = 0
    items.forEach(item => {
        if (item?.text?.includes(' ')) {
            const [first, ...rest] = item.text.split(' ')
            if (first?.length > maxCharLength) maxCharLength = first.length
            if (rest.join(' ')?.length > maxCharLength) maxCharLength = rest.join(' ').length
        } else {
            maxCharLength = item.text.length
        }
    })

    maxCharLength = maxCharLength > 14 ? 14 : maxCharLength
    return fontSize * (maxCharLength + 0.5) * 16 + 'px'
}

const emptyFilter = {
    values: [],
    range: {},
    rangeOffset: {},
    useLastRefreshDate: false,
    clickRangeName: null,
    operator: 'EqualTo',
    filterConfig: {},
}
