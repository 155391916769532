import { Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PropertyType } from 'genesis-suite/types/architectureTypes'
import { Property } from 'genesis-suite/types/networkTypes'
import { ComparisonOperator, FilterGroup } from 'genesis-suite/types/visualTypes'
import { getFilterFields } from 'genesis-suite/utils'
import produce from 'immer'
import { isEmpty, isEqual } from 'lodash'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { ConfigContext } from '../ConfigContext'
import FilterEditor from '../FilterEditor'
import { DragField } from '../builderTypes'
import EditorWrapper from '../config_fields/EditorWrapper'
import { getDataFieldProperty } from '../utils'
import PropertyController from './PropertyController'

const useStyles = makeStyles(({ border, palette, spacing }) => ({
    borderedSection: {
        maxHeight: 200,
        display: 'flex',
        padding: spacing(1),
        marginBottom: spacing(1),
        flexFlow: 'column nowrap',
        border: `2px solid ${palette.grayscale.light}`,
    },
    filterWrapper: {
        padding: spacing(0.5),
        marginBottom: spacing(1),
        border: `1px solid ${palette.grayscale.light}`,
        borderRadius: border.radius.round,
    },
}))

interface Props {
    /** allow all properties that match across all series */
    root?: boolean
}

export default function FilterSelector({ root = false }: Props) {
    const { resources, config, dispatch, selectedField } = useContext(ConfigContext)
    const [draft, setDraft] = useState<FilterGroup>()

    const activeSeriesIndex = selectedField?.index ?? 0
    const activeSeries = config.series[activeSeriesIndex]
    const activeFilters = root ? config.filters : activeSeries?.filters

    useEffect(() => {
        if (!isEqual(draft, activeFilters)) setDraft(activeFilters)
    }, [activeFilters])

    const timeout = useRef(null)
    useEffect(() => {
        clearTimeout(timeout.current)
        timeout.current = setTimeout(() => {
            if (isEqual(draft, activeFilters)) return

            if (root) {
                dispatch({ type: 'SET_FILTERS', payload: draft })
            } else {
                dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: { filters: draft } })
            }
        }, 500)
    }, [draft])

    const filterFields = getFilterFields(draft)
    const classes = useStyles()
    const properties = useMemo(() => {
        if (isEmpty(resources?.byId)) return []
        if (!root) return resources.byId[resources.selectedId]?.properties

        const [firstResource, ...remainingResources] = Object.values(resources.byId)
        return firstResource.properties.reduce((acc, cur) => {
            if (!remainingResources.every(r => r.properties.some(p => p.name === cur.name))) return acc
            return [...acc, cur]
        }, [] as Property[])
    }, [resources])

    function onNew({ type, pointer, ...field }: DragField) {
        const { semanticType } = getDataFieldProperty(field, properties)

        const current = activeFilters ?? { type: 'group', group: 'AND', items: [] }
        const filters = produce(current, draft => {
            draft.items.push({
                comparison: semanticType.type === 'Defining' ? ComparisonOperator.IN : ComparisonOperator.EQUAL,
                field,
                type: 'filter',
            })
        })
        setDraft(filters)
    }

    return (
        <EditorWrapper header={'Filters' + (filterFields?.length ? ` (${filterFields?.length})` : '')}>
            {properties?.length && isBasic(draft) && (
                <>
                    <FilterEditor filter={draft} properties={properties} onChange={setDraft} />
                    <PropertyController
                        label="New filter"
                        accept={[PropertyType.DEFINING, PropertyType.QUALITATIVE, PropertyType.QUANTITATIVE]}
                        onDrop={onNew}
                        className={classes.borderedSection}
                    />
                </>
            )}

            <Button onClick={() => dispatch({ type: 'UPDATE_ADVANCED_FILTER_EDITOR', payload: { open: true } })}>
                Advanced
            </Button>
        </EditorWrapper>
    )
}

function isBasic(filters: FilterGroup) {
    if (!filters) return true
    if (filters.group !== 'AND') return false
    for (const filter of filters.items) {
        if (filter.type === 'group') return false
    }

    return true
}
