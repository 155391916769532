import { Box } from '@mui/material'

import { appearanceConstants } from '../../../constants'
import DashboardActions from './DashboardActions'
import DashboardTitle from './DashboardTitle'

export default function DashboardHeader() {
    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                gap: 1,
                justifyContent: 'space-between',
                paddingX: `${appearanceConstants.ScrollBarWidth}px`,
                py: 1,
            }}
        >
            <DashboardTitle />
            <DashboardActions />
        </Box>
    )
}
