import { useState } from 'react'
import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { isEqual } from 'lodash'

import { TreeType, ChartType, TreeConfig } from 'genesis-suite/types/visualTypes'
import EditWidgetHeader from './EditWidgetHeader'
import SourceList from './edit_tree/SourceList'
import EditTree from './edit_tree/EditTree'

const columnWidth = 236
const useStyles = makeStyles(({ palette, spacing }) => {
    const baseColumn = {
        overflow: 'hidden',
        display: 'flex',
        flexFlow: 'column nowrap',
        paddingTop: spacing(),
    }

    return {
        controller: {
            position: 'relative',
            display: 'grid',
            gridTemplateColumns: `${columnWidth}px `,
        },
        dataColumn: { ...baseColumn, backgroundColor: palette.background.sideNav, zIndex: 2 },
        column: { ...baseColumn, paddingLeft: spacing(2) },
        widget: {
            width: '100%',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '8px',
            padding: spacing(),
        },
    }
})

const defaultConfig: TreeConfig = {
    version: '2',
    title: '',
    type: ChartType.TREE,
    treeName: '',
    treeType: TreeType.TABLE,
}

interface Props {
    /** (optional) provided if updating existing config */
    config?: TreeConfig
    isNew: boolean
    onDone: (config?: TreeConfig) => Promise<void>
}

export default function EditTreeWidget({ config: initialConfig = defaultConfig, isNew, onDone }: Props) {
    const classes = useStyles()
    const [config, setConfig] = useState(initialConfig)
    const isDirty = !isEqual(config, initialConfig)

    return (
        <Box flex={1} height="100%" display="flex" flexDirection="column">
            <EditWidgetHeader
                title="Edit Tree Widget"
                isNew={isNew}
                isDirty={isDirty}
                onDone={() => onDone(isDirty ? config : undefined)}
                onReset={() => setConfig(initialConfig)}
            />

            <Box flex={1} display="flex" overflow="hidden">
                <div className={classes.controller}>
                    <SourceList className={classes.dataColumn} config={config} onChange={setConfig} />
                </div>

                <EditTree className={classes.widget} config={config} onChange={setConfig} />
            </Box>
        </Box>
    )
}
