import { ResourceType } from 'genesis-suite/types/networkTypes'
import { Category, ChartType, DataSource, PieConfig, PieSeries } from 'genesis-suite/types/visualTypes'
import { makeField, stringToFilters } from 'genesis-suite/utils'
import { WidgetConverterProps } from '../migrationTypes'
import {
    convertBaseFormat,
    convertLegend,
    convertTooltip,
    getPropertyFromResource,
    makeAggregation,
    makeNavigation,
} from '../widgetConverterUtils'

export default async function pieConverter({
    config,
    dashboardIdByOldId,
    resourceManager,
}: WidgetConverterProps): Promise<PieConfig> {
    const { Title, ChartConfig, NoDataMessage } = config
    const {
        Source,
        XAxisField,
        ValueField,
        CrumbMetaName,
        DefaultPerspective,
        SortAxis,
        ValueName,
        AggregationType,
        ShowLabels,
        ShowPercentage,
        ShowCategoryLabels,
        BaseFormat,
        PieHemisphere,
    } = ChartConfig

    const insight = await resourceManager.getInsight(Source.ElementName)

    const category: Category = {
        field: makeField(insight.properties, XAxisField),
        navigation: makeNavigation(CrumbMetaName, DefaultPerspective, dashboardIdByOldId),
        ...(SortAxis && { sort: 'descending' }),
    }

    const value: DataSource = {
        field: makeField(insight.properties, ValueField),
        aggregation: makeAggregation(getPropertyFromResource(insight, ValueField), AggregationType),
    }

    const singleSeries: PieSeries = {
        doughnut: ChartConfig.ChartType === 'Doughnut',
        hemisphere: PieHemisphere.toLowerCase(),
        title: ValueName,
        service: { type: ResourceType.INSIGHT, name: insight.name, id: insight.id },
        values: [value],
        tooltip: convertTooltip(insight, ChartConfig),
        ...(ShowLabels && {
            dataLabels: {
                value: true,
                format: { base: convertBaseFormat(BaseFormat) },
                percent: ShowPercentage,
                categories: ShowCategoryLabels,
            },
        }),
    }

    return {
        version: '2',
        type: ChartType.PIE,
        title: Title,
        noDataMessage: NoDataMessage,
        categories: [category],
        series: [singleSeries],
        filters: stringToFilters(insight.properties, Source.Filters),
        legend: convertLegend(ChartConfig),
    }
}
