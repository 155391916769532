import { createSelector } from 'reselect'
import { moduleSelectors } from './module.selectors'
import { navigationSelectors } from './navigation.selectors'
import { widgetSelectors } from './widget.selectors'

const getPowerNav = state => state.menu._360 || []

const getV1TopNav = state => state.menu.topNav

const getMenuConfig = createSelector([getPowerNav, getV1TopNav], (powerNav, topNav) =>
    powerNav.length > 0 ? powerNav : topNav
)

const getMobileMenuConfig = createSelector([getPowerNav, getV1TopNav], (powerNav, topNav) => {
    let _360config = []
    if (powerNav.length > 0) {
        _360config = [{ text: 'Views', subMenuConfig: powerNav }]
    }
    return [..._360config, ...topNav]
})

const getTopNav = createSelector(
    [moduleSelectors.getIsV2, getV1TopNav, moduleSelectors.getBusinessElements],
    (isV2, v1Nav, v2Nav) => (isV2 ? v2Nav : v1Nav)
)

const getViews = createSelector(
    [moduleSelectors.getIsV2, getPowerNav, moduleSelectors.getViewMenu],
    (isV2, v1Nav, v2Nav) => [...(!isV2 ? v1Nav : []), ...v2Nav]
)

const getAllNavItemIcons = createSelector([getPowerNav, getTopNav], (views, elements) => {
    function flattenView(acc, cur) {
        const { to, type, icon, subMenuConfig } = cur
        if (to && icon) acc.push({ to, type, icon })
        if (subMenuConfig) acc = [...acc, ...subMenuConfig.reduce(flattenView, [])]
        return acc
    }

    const flatViews = views.reduce(flattenView, [])
    return [...flatViews, ...elements]
})

const getIcon = createSelector(
    [
        widgetSelectors.getCurrentPerspectiveId,
        navigationSelectors.getCurrentBusinessElement,
        navigationSelectors.getCurrentWidgetId,
        getAllNavItemIcons,
    ],
    (viewId, elementName, widgetId, navItems) =>
        navItems.find(({ type, to }) => {
            if (viewId && type === 'viewId' && viewId === to) return true
            if (widgetId && type === 'widgetId' && widgetId === to) return true
            if (elementName && type === 'elementName' && elementName === to) return true
            return false
        })?.icon
)

export const menuSelectors = {
    getViews,
    getTopNav,
    getMenuConfig,
    getMobileMenuConfig,
    getIcon,
}
