import { authTypes } from '../actions/types'

const initialState = {
    expired: false,
}

export default (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case authTypes.SESSION_EXPIRED:
            return { ...state, expired: true }
        case authTypes.SESSION_EXTEND:
            return state
        default:
            return state
    }
}
