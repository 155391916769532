import { useSelector } from 'react-redux'
import { appearanceSelectors } from '~/selectors'
import AppMenu from './AppMenu'
import { useBrowserPreferences } from '~/lib/browserPreferences'
import { logEvent } from '~/lib/amplitudeClient'
import { Box } from '@mui/material'
import { useIsMobile } from '~/hooks/useIsMobile'
import SideNavCollapseToggle from './SideNavCollapseToggle'

export default function LeftNav() {
    const isMobile = useIsMobile()
    const showNeoClassic = useSelector(appearanceSelectors.getShowNeoClassicLayOut)
    const [leftNavSettings, setLeftNavSettings] = useBrowserPreferences('leftNav')
    const collapsed = leftNavSettings?.collapse

    const togglePanel = () => {
        logEvent('RIGHT_NAV_COLLAPSE')
        setLeftNavSettings(s => ({ ...s, collapse: !s?.collapse }))
    }

    if (!showNeoClassic || isMobile) return null

    return (
        <>
            <Box
                sx={{
                    display: collapsed ? 'none' : 'block',
                    width: 60,
                    bgcolor: 'background.sideNav',
                    boxShadow: '6px 0 5px -4px #888',
                    flex: 0,
                    px: 1,
                    zIndex: 1,
                }}
            >
                <AppMenu orientation="vertical" />
            </Box>
            <SideNavCollapseToggle open={!collapsed} onClick={togglePanel} invert position="left" />
        </>
    )
}
