import PsychologyAltRoundedIcon from '@mui/icons-material/PsychologyAltRounded'
import { MenuIcon } from 'genesis-suite/components'
import { useDispatch, useSelector } from 'react-redux'
import { perspectiveCreators } from '~/actions/creators/perspective.creators'
import { authSelectors, moduleSelectors } from '~/selectors'

export default function PerspectiveSummaryController({ disabled, iconProps, buttonStyle }) {
    const dispatch = useDispatch()
    const isAdmin = useSelector(authSelectors.getIsAdmin)
    const enableAIChat = useSelector(moduleSelectors.getEnableAIChat)

    const onExplainClick = () => {
        dispatch(perspectiveCreators.togglePerspectiveSummary(true))
    }

    if (!isAdmin || !enableAIChat) return null

    return (
        <MenuIcon
            buttonProps={{ sx: buttonStyle }}
            closeButton
            icon={<PsychologyAltRoundedIcon {...iconProps} />}
            disabled={disabled}
            onClick={() => onExplainClick()}
            title="Explains Perspective"
            tooltip="What am I looking at?"
        ></MenuIcon>
    )
}
