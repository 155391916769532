import { useSelector } from 'react-redux'
import useSWR from 'swr'

import { visualService } from '../../lib/services'
import { applicationSelectors, deploymentSelectors } from '../../selectors'

export function useCounts() {
    const viewFlag = useSelector(deploymentSelectors.getDeploymentViewFlag)
    const appName = useSelector(applicationSelectors.getCurrentAppName)

    return useSWR(['visualService.getDraftCounts', appName, viewFlag], ([_, appName, viewFlag]) =>
        visualService.getDraftCounts(appName, viewFlag)
    )
}
