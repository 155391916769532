import { combineReducers } from 'redux'

import historyContext from '../store/history'

import appearance from './appearance.reducer'
import applications from './applications.reducer'
import authentication from './auth.reducer'
import businessExplorer from './businessExplorer.reducer'
import collaboration from './collaboration.reducer'
import debug from './debug.reducer'
import deployment from './deployment.reducer'
import dialog from './dialog.reducer'
import filters from './filters.reducer'
import history from './history.reducer'
import interaction from './interaction.reducer'
import insight from './insight.reducer'
import links from './links.reducer'
import lockout from './lockout.reducer'
import logo from './logo.reducer'
import menu from './menu.reducer'
import modules from './modules.reducer'
import navigation from './navigation.reducer'
import notifications from './notification.reducer'
import scenarios from './scenario.reducer'
import session from './session.reducer'
import snackbar from './snackbar.reducer'
import tour from './tour.reducer'
import theme from './theme.reducer'
import userNav from './userNav.reducer'
import widgets from './widgets.reducer'
import calculator from './calculator.reducer'
import networkFilters from '../redux/networkFilterSlice'
import perspective from './perspective.reducer'
import backup from './backup.reducer'
import chat from './chat.reducer'
import messenger from './messenger.reducer'

const appReducer = combineReducers({
    appearance,
    applications,
    authentication,
    businessExplorer,
    collaboration,
    debug,
    deployment,
    dialog,
    filters,
    history,
    insight,
    interaction,
    links,
    lockout,
    logo,
    menu,
    modules,
    navigation,
    networkFilters,
    notifications,
    router: historyContext.routerReducer,
    scenarios,
    session,
    snackbar,
    tour,
    theme,
    userNav,
    widgets,
    calculator,
    perspective,
    backup,
    chat,
    messenger,
})

export default appReducer
