import {
    Button,
    Checkbox,
    DialogContent,
    FormControlLabel,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Switch,
    Typography,
} from '@mui/material'
import { NetworkFilter } from 'genesis-suite/services/services/filter'
import { makeFilterReadable } from 'genesis-suite/utils'
import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { networkFilterActions, networkFilterSelectors } from '../../redux/networkFilterSlice'
import useNetworkFilters from './useNetworkFilters'
import { DebouncedTextField, Spinner } from 'genesis-suite/components'
import { useBrowserPreferences } from '~/lib/browserPreferences'
import { authSelectors } from '~/selectors'

interface NetFiltersContextProps {
    networkFilters: NetworkFilter[]
    selected: string[]
    isLoading: boolean
    toggleFilter: (filter: NetworkFilter) => () => void
    clearAll: () => void
    dirty: boolean
    makeDirty: any
    setShowNetworkFilterRules: Dispatch<SetStateAction<boolean>>
    showNetworkFilterRules: boolean
}

const NetFiltersContext = createContext<NetFiltersContextProps>(null)

const NetworkFiltersProvider = ({ children, perspectiveId = null, isCompare = false }) => {
    const [networkFilters, isLoading] = useNetworkFilters(perspectiveId)
    const appliedIds = useSelector(networkFilterSelectors.getAppliedIds)
    const appliedCompareIds = useSelector(networkFilterSelectors.getAppliedCompareIds)
    const [dirty, setDirty] = useState(false)
    const [showNetworkFilterRules, setShowNetworkFilterRules] = useBrowserPreferences('showNetworkFilterRules')
    const [selected, setSelected] = useState(isCompare ? appliedCompareIds : appliedIds)
    const defaultNetworkFilter = useSelector(authSelectors.getAppDefaultNetworkFilters)
    const makeDirty =
        cb =>
        (...args) => {
            if (!dirty) setDirty(true)
            cb(...args)
        }

    const toggleFilter = filter => () => {
        if (selected.includes(filter.id)) {
            defaultNetworkFilter ? setSelected([defaultNetworkFilter]) : setSelected([])
        } else {
            setSelected([filter.id])
        }
    }

    const clearAll = () => {
        setSelected([])
    }

    return (
        <NetFiltersContext.Provider
            value={{
                networkFilters,
                selected,
                toggleFilter,
                clearAll,
                dirty,
                makeDirty,
                isLoading,
                setShowNetworkFilterRules,
                showNetworkFilterRules,
            }}
        >
            {children}
        </NetFiltersContext.Provider>
    )
}

const NetworkFiltersList = () => {
    const {
        networkFilters,
        selected,
        toggleFilter,
        makeDirty,
        isLoading,
        setShowNetworkFilterRules,
        showNetworkFilterRules,
    } = useContext(NetFiltersContext)

    const [search, setSearch] = useState('')
    const [sortedNetworkFilters, setSortedNetworkFilters] = useState(networkFilters)
    const defaultNetworkFilter = useSelector(authSelectors.getAppDefaultNetworkFilters)

    useEffect(() => {
        setSortedNetworkFilters(networkFilters)
    }, [networkFilters])

    const handleSearch = val => {
        setSearch(val)

        if (val === '') {
            setSortedNetworkFilters(networkFilters)
        } else if (val) {
            const tFilters = networkFilters.filter(nFilter => nFilter.name.toLowerCase().includes(val.toLowerCase()))
            setSortedNetworkFilters(tFilters)
        }
    }

    const isSelected = filterId => {
        if (selected.length) return selected.includes(filterId)
        if (defaultNetworkFilter && filterId === defaultNetworkFilter) return true
    }

    return (
        <>
            {(isLoading || networkFilters?.length) && (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={7}>
                            <DebouncedTextField
                                debounceTime={300}
                                disabled={isLoading}
                                onChange={handleSearch}
                                placeholder="Search"
                                sx={theme => ({
                                    marginLeft: '15px',
                                    width: '300px',
                                    '& .MuiInputBase-root:before': {
                                        borderBottomColor: theme.palette.text.primary,
                                    },
                                })}
                                value={search}
                            />
                        </Grid>
                        <Grid item xs={5} display={'block'} textAlign={'end'}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={showNetworkFilterRules}
                                        color="primary"
                                        onChange={e => {
                                            setShowNetworkFilterRules(!showNetworkFilterRules)
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label={`${showNetworkFilterRules ? 'Show' : 'Hide'} Filter Rules`}
                                labelPlacement="start"
                                sx={{ marginRight: '5px' }}
                            />
                        </Grid>
                    </Grid>
                </>
            )}
            <DialogContent sx={{ p: 1 }}>
                <List dense>
                    {isLoading ? (
                        <Spinner />
                    ) : !sortedNetworkFilters?.length ? (
                        <Typography>No network filters</Typography>
                    ) : (
                        <>
                            {sortedNetworkFilters?.map(nFilter => (
                                <ListItem key={nFilter.id} disablePadding dense>
                                    <ListItemButton dense onClick={makeDirty(toggleFilter(nFilter))}>
                                        <ListItemIcon
                                            sx={theme => ({
                                                '.MuiCheckbox-root': {
                                                    color: theme.palette.text.primary,
                                                },
                                            })}
                                        >
                                            <Checkbox
                                                edge="start"
                                                checked={isSelected(nFilter.id)}
                                                tabIndex={-1}
                                                disableRipple
                                                disabled={
                                                    selected.includes(nFilter.id) && nFilter.id === defaultNetworkFilter
                                                }
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            sx={() => ({
                                                '.MuiListItemText-secondary': {
                                                    color: 'inherit',
                                                },
                                            })}
                                            primary={nFilter.name}
                                            {...(showNetworkFilterRules
                                                ? { secondary: makeFilterReadable(nFilter.filter) }
                                                : {})}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </>
                    )}
                </List>
            </DialogContent>
        </>
    )
}

const NetworkFiltersApplyButton = ({
    onApply,
    isCompare = false,
    disabled = false,
}: {
    onApply?: () => void
    isCompare?: boolean
    disabled?: boolean
}) => {
    const { selected } = useContext(NetFiltersContext)
    const dispatch = useDispatch()
    const applyFilters = ids => dispatch(networkFilterActions.apply(ids))
    const applyCompareFilters = ids => dispatch(networkFilterActions.applyCompare(ids))
    const handleApply = () => {
        if (isCompare) {
            applyCompareFilters(selected)
        } else {
            applyFilters(selected)
        }
        onApply && onApply()
    }

    return (
        <Button variant="contained" onClick={handleApply} disabled={disabled}>
            Apply
        </Button>
    )
}

export { NetworkFiltersApplyButton, NetworkFiltersList, NetworkFiltersProvider, NetFiltersContext }
