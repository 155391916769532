import { InfoOutlined as Info } from '@mui/icons-material'
import { Box, Button, Collapse, Typography } from '@mui/material'
import { isEqual } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { SplitButton } from 'genesis-suite/components'
import { useHover } from 'genesis-suite/hooks'
import { ComparisonOperator, DashboardNodeFilter } from 'genesis-suite/types/visualTypes'
import { useIsMobile } from '../../hooks/useIsMobile'
import { authSelectors, filterSelectors } from '../../selectors'
import { FilterApplyMethod, FilterSourceType } from '../../types/FilterTypes'
import ContextFilterInput from './ContextFilterInput'
import { isSelect } from './FilterOperatorButton2'
import { emptyDashboardFilterValues } from './utils/contextFilterUtils'

export interface Props {
    /** (optional) Collapse actions when not needed */
    collapseActions?: boolean
    filters: DashboardNodeFilter[]
    /** (optional) Turn off specified methods */
    omitMethods?: FilterApplyMethod[]
    onApply: (filters: DashboardNodeFilter[], method: FilterApplyMethod) => void
    onDone: () => void
    source: FilterSourceType
}

export default function ContextFilterSelector({
    collapseActions,
    filters,
    omitMethods,
    onApply,
    onDone,
    source,
}: Props) {
    const isMobile = useIsMobile()
    const isPowerUser = useSelector(authSelectors.getIsPowerUser)
    const context = useSelector(filterSelectors.getCoord)

    const [draft, setDraft] = useState<DashboardNodeFilter[]>(null)

    const [delayedHovering, setDelayedHovering] = useState(false)
    const containerRef = useRef()
    const isHovering = useHover(containerRef)
    const hoverTimeout = useRef(null)
    useEffect(() => {
        clearTimeout(hoverTimeout.current)
        if (!isHovering) setDelayedHovering(false)
        else hoverTimeout.current = setTimeout(() => setDelayedHovering(true), 400)
    }, [isHovering])

    useEffect(() => {
        setDraft(filters)
    }, [filters])

    if (!filters?.length) return <Typography>No filters to display</Typography>

    const sourceInfo = getSourceInfo(source)
    const dirty = !isEqual(draft, filters)
    const showControls = !collapseActions || delayedHovering || dirty

    const saveOptions = [{ onClick: () => handleApply('save'), label: 'Save as Default' }]
    if (!omitMethods?.includes('save-for-all') && isPowerUser && !isMobile)
        saveOptions.push({ onClick: () => handleApply('save-for-all'), label: 'Save for All' })

    function handleChange(index: number, filter: DashboardNodeFilter) {
        const wasSelect = isSelect(filters[index].operator)
        const operator = filter.operator ?? ComparisonOperator.EQUAL
        const toBeSelect = isSelect(operator)
        let values = filter.values
        if (wasSelect && !toBeSelect) values = [draft[index].values[0]?.label ?? '']
        else if (!wasSelect && toBeSelect) values = []

        setDraft(s => s.map((d, i) => (i === index ? { ...filter, operator, values } : d)))
    }

    function handleApply(method: FilterApplyMethod) {
        onApply(draft, method)
        onDone()
    }

    function handleCancel() {
        onDone()
    }

    function handleClear() {
        onApply(
            draft.map(f => emptyDashboardFilterValues(f)),
            'apply-only'
        )
        onDone()
    }

    return (
        <Box
            ref={containerRef}
            sx={{
                width: '100%',
                display: 'flex',
                flexFlow: 'column wrap',
                justifyContent: 'space-around',
            }}
        >
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 1 }}>
                {draft?.map((filter, index) => {
                    return (
                        <ContextFilterInput
                            key={`${filter.nodeName}-${filter.keyPropertyName}`}
                            context={context}
                            filter={filter}
                            onChange={value => handleChange(index, value)}
                        />
                    )
                })}
            </Box>

            <Collapse in={showControls}>
                {sourceInfo && (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <Info sx={{ m: 1 }} />
                        <Typography variant="body1" display="inline">
                            {sourceInfo}
                        </Typography>
                    </Box>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between',
                        gap: 1,
                        ...(isMobile && { flex: 1 }),
                    }}
                >
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 1,
                            justifyContent: 'center',
                            ...(isMobile && { flex: 1 }),
                        }}
                    >
                        <Button
                            color="warning"
                            onClick={handleClear}
                            size="small"
                            sx={{ ...(isMobile && { flex: 1 }) }}
                            variant="contained"
                        >
                            Clear All
                        </Button>
                        <SplitButton
                            buttonGroupProps={{ size: 'small', sx: { ...(isMobile && { flex: 1 }) } }}
                            options={saveOptions}
                        />
                    </Box>

                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 1,
                            justifyContent: 'center',
                            ...(isMobile && { flex: 1 }),
                        }}
                    >
                        <Button
                            disabled={!dirty}
                            onClick={handleCancel}
                            size="small"
                            sx={{ ...(isMobile && { flex: 1 }) }}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="secondary"
                            disabled={!dirty}
                            onClick={() => handleApply('apply-only')}
                            size="small"
                            sx={{ ...(isMobile && { flex: 1 }) }}
                            variant="contained"
                        >
                            Apply
                        </Button>
                    </Box>
                </Box>
            </Collapse>
        </Box>
    )
}

function getSourceInfo(source) {
    switch (source) {
        case FilterSourceType.USER_DEFAULTS:
            return 'You are seeing these filters because you have saved them to your user preferences.'
        case FilterSourceType.WIDGET_DEFAULTS:
            return 'You are seeing these filters because they were set by your system administrator.'
        default:
            return ''
    }
}
