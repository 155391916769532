import { CloseRounded } from '@mui/icons-material'
import Add from '@mui/icons-material/Add'
import ForwardIcon from '@mui/icons-material/ArrowForward'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import LabelIcon from '@mui/icons-material/Label'
import VisibilityIcon from '@mui/icons-material/Visibility'
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip,
    Typography,
    Avatar,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles'
import { ChartType } from 'genesis-suite/types/visualTypes'
import React, { Suspense, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { visualService } from '../../lib/services'
import { applicationSelectors, deploymentSelectors } from '../../selectors'
import FlowchartPopup from './FlowchartPopup'

const Flowchart = React.lazy(() => import('./Flowchart'))

const useStyles = makeStyles(({ palette, spacing }) => ({
    Container: {
        width: '100%',
        height: 'calc(100% - 40px)',
        display: 'flex',
        alignItems: 'end',
    },
    actionDialog: { '& .MuiDialog-paper': { width: '100%', maxWidth: 400, paddingBottom: spacing(1.8) } },
    actionHeader: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: spacing(0.5, 1),
        justifyContent: 'space-between',
    },
    Icons: { color: palette.text.primary },
    WrapHeaderButton: { width: '100%', display: 'flex', justifyContent: 'right' },
    Header: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '20px',
    },
}))

export default function FlowchartController({ Header }) {
    const styles = useStyles()

    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const viewFlag = useSelector(deploymentSelectors.getDeploymentViewFlag)

    const [open, setOpen] = useState(false)
    const [flowCharts, setFlowCharts] = useState([])
    const [selected, setSelected] = useState(null)
    const [preDelete, setPreDelete] = useState(null)
    const [loading, setLoading] = useState(false)


    function handleClose() {
        setOpen(false)
    }

    useEffect(() => {
        async function getWidgets() {
            setLoading(true)
            const result = await visualService.getWidgets(appName, { type: ChartType.FLOWCHART }, viewFlag)
            setFlowCharts(result.data)
            setLoading(false)
        }
        getWidgets()
    }, [appName, viewFlag])

    async function deleteItem() {
        await visualService.deleteWidget(appName, preDelete.id)

        const nFlowCharts = [...flowCharts]
        const newList = nFlowCharts.filter(x => x.id !== preDelete.id)
        setFlowCharts(newList)
        setPreDelete(null)
    }

    function FlowchartList() {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Typography sx={{ marginLeft: '10px', textAlign: 'center' }} variant="h6" component="div">
                        Flowcharts
                    </Typography>
                    {loading && (
                        <Box display="flex" justifyContent="center" gap={1} m={1}>
                            <CircularProgress color="primary" />
                        </Box>
                    )}
                    {!loading && (
                        <>
                            <List dense={true}>
                                {flowCharts?.map((item, index) => (
                                    <ListItem
                                        key={item.id}
                                        secondaryAction={
                                            <>
                                                <Tooltip title="Go to flowchart">
                                                    <IconButton
                                                        edge="end"
                                                        onClick={() => setSelected(index)}
                                                        aria-label="delete"
                                                        className={styles.Icons}
                                                    >
                                                        <VisibilityIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Delete flowchart">
                                                    <IconButton
                                                        sx={{ marginLeft: '10px' }}
                                                        edge="end"
                                                        onClick={() => setPreDelete(item)}
                                                        aria-label="delete"
                                                        className={styles.Icons}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                        }
                                    >
                                        <ListItemAvatar>
                                            <Avatar>
                                                <LabelIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={item.title} />
                                    </ListItem>
                                ))}
                            </List>
                            {flowCharts.length === 0 && (
                                <Typography sx={{ textAlign: 'center' }} variant="h6">
                                    The list is empty
                                </Typography>
                            )}
                        </>
                    )}
                </Grid>
            </Grid>
        )
    }

    return (
        <>
            <Header>
                <div className={styles.WrapHeaderButton}>
                    {selected !== null && (
                        <>
                            <Tooltip title="Edit flowchart">
                                <IconButton className={styles.Icons} onClick={() => setOpen(true)} size="large">
                                    <EditIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Back">
                                <IconButton className={styles.Icons} onClick={() => setSelected(null)} size="large">
                                    <ForwardIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </>
                    )}
                    {selected === null && (
                        <Tooltip title="Create new flowchart">
                            <IconButton className={styles.Icons} onClick={() => setOpen(true)} size="large">
                                <Add fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
            </Header>
            {selected === null && <FlowchartList />}
            {selected !== null && (
                <div className={styles.Container}>
                    <Suspense fallback={<div>Loading</div>}>
                        <Flowchart setFlowCharts={setFlowCharts} flowCharts={flowCharts} selected={selected} />
                    </Suspense>
                </div>
            )}
            <Dialog className={styles.actionDialog} open={open}>
                <div className={styles.actionHeader}>
                    <Typography variant="h6">Configure Flowchart</Typography>
                    <IconButton onClick={handleClose} className={styles.Icons}>
                        <CloseRounded />
                    </IconButton>
                </div>
                <DialogContent>
                    <FlowchartPopup
                        handleClose={handleClose}
                        setFlowCharts={setFlowCharts}
                        flowCharts={flowCharts}
                        selected={selected}
                    />
                </DialogContent>
            </Dialog>
            <Dialog open={preDelete !== null} onClose={() => setPreDelete(null)}>
                <DialogTitle>Are you sure you want to delete the flowchart?</DialogTitle>
                <DialogContent>
                    <Typography>The flowchart will be permanently deleted</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setPreDelete(null)} color="error">
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" onClick={deleteItem} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
