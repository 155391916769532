import { Box, Typography } from '@mui/material'
import { DataGridPro, DataGridProProps } from '@mui/x-data-grid-pro'
import { ErrorBoundary } from 'react-error-boundary'

import { MapConfig } from 'genesis-suite/types/visualTypes'
import { makeColumns, makeGroupingColumn, RowData } from './utils'

interface Props {
    config: MapConfig
    rows: RowData[]
    isCollapsed: boolean
}

export default function NodeLocationsTable({ config, rows, isCollapsed }: Props) {
    const { series } = config
    const groupingColumn = makeGroupingColumn()
    const columns = makeColumns()

    const getTreeDataPath: DataGridProProps['getTreeDataPath'] = row => row.path

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <DataGridPro
                sx={{
                    '&.MuiDataGrid-root': {
                        borderColor: 'border.main',
                        overflow: isCollapsed ? 'hidden' : 'visible',
                    },
                    '& .MuiDataGrid-columnHeaders, & .MuiDataGrid-row .MuiDataGrid-cell': {
                        borderColor: 'border.main',
                        '& .MuiDataGrid-columnSeparator': { color: 'border.main' },
                    },
                    '& .MuiDataGrid-footerContainer': {
                        pr: 3,
                    },
                }}
                treeData
                getTreeDataPath={getTreeDataPath}
                groupingColDef={groupingColumn}
                rows={rows}
                columns={columns}
                defaultGroupingExpansionDepth={series?.length <= 1 ? -1 : 0}
                disableSelectionOnClick
            />
        </ErrorBoundary>
    )
}

function ErrorFallback() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
            }}
        >
            <Typography variant="h6">Something went wrong.</Typography>
            <Typography variant="subtitle1">Cannot render table.</Typography>
        </Box>
    )
}
