import { Theme } from '@mui/material'
import { v4 as uuid } from 'uuid'
import store from '../../../store/store'

import { Property, ResourceType } from 'genesis-suite/types/networkTypes'
import {
    BaseFormConfig,
    ChartType,
    CustomFormField,
    FieldDisplayType,
    FieldVisibility,
    FormConfig,
    FormField,
    FormPrompt,
    FormSection,
    FormType,
    InputFormConfig,
    Justification,
    KanbanFormConfig,
    NumberFormat,
    TableFormConfig,
} from 'genesis-suite/types/visualTypes'
import { ResourceManager } from '../widgetConverterUtils'

export default async function visorFormConverter(
    widgetConfig,
    resourceManager: ResourceManager,
    formConfigs: any
): Promise<FormConfig> {
    const formId = widgetConfig.FormConfig?.FormId
    const config = formConfigs.find(f => f.id === formId)
    if (!config) throw new Error(`Form id ${formId} doesn't exist`)

    const resourceType = config.source.elementType === 'Link' ? ResourceType.LINK : ResourceType.NODE
    const resource = await resourceManager.get(resourceType, config.source.element)
    const insight = config.insightName ? await resourceManager.getInsight(config.insightName) : undefined
    const makeProp = (name: string) => resource.properties.find(p => p.name === name || p.id === name)
    const theme: Theme = store.getState().theme

    const base: BaseFormConfig = {
        version: '2',
        formType: FormType.TABLE,
        type: ChartType.FORM,
        title: widgetConfig.Title,
        description: config.description,
        isBlockchain: config.blockChainVerify,
        migratedVisorId: widgetConfig.Id,
        palette: {
            background: config.visualSettings?.formBackground || theme.palette.background.main,
            fontColor: config.visualSettings?.inputColor || theme.palette.text.primary,
        },
        loadInitialData: config.loadInitialData,
        source: {
            type: resourceType,
            id: resource.id,
            name: resource.name,
            filters: config.source.filters,
        },
        ...(insight && { insight: { type: ResourceType.INSIGHT, id: insight.id, name: insight.name } }),
    }

    if (config.allowFormOnly) return inputConverter(base, config, makeProp)
    else if (config.defaultMode === 6) return kanbanConverter(base, config, makeProp)
    else return tableConverter(base, config, makeProp)
}

function inputConverter(base: BaseFormConfig, config, makeProp: (name: string) => Property): InputFormConfig {
    return {
        ...base,
        formType: FormType.INPUT,
        sectionOrientation: 'vertical',
        sections: makeSections(config, makeProp),
    }
}

function kanbanConverter(base: BaseFormConfig, config, makeProp: (name: string) => Property): KanbanFormConfig {
    const kanbanCol = config.fields.find(f => f.advanceSettings.useAsKanbanColumns)
    const columnProperty = makeProp(kanbanCol.fieldName)
    const cardFields = config.fields.filter(f => !f.isHidden)
    const cardContent: Property[] = cardFields
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .map(f => makeProp(f.fieldName))
    return {
        ...base,
        formType: FormType.KANBAN,
        canAddNew: config.canAddRecords,
        canDelete: config.canDeleteRecords,
        cardContent,
        columnProperty,
        sections: makeSections(config, makeProp),
    }
}

function tableConverter(base: BaseFormConfig, config, makeProp: (name: string) => Property): TableFormConfig {
    return {
        ...base,
        formType: FormType.TABLE,
        canUploadExcel: config.allowExcel,
        canInputEdit: config.allowForm,
        canCellEdit: config.allowInline,
        canAddNew: config.canAddRecords,
        canDelete: config.canDeleteRecords,
        exportFileName: config.tableName,
        customInputForm: config.sections ? inputConverter(base, config, makeProp) : null,
        ...(config.sortOrders && {
            sortOrders: config.sortOrders.map(s => ({
                name: s.name,
                sort: s.sortOrder === 'DESC' ? 'descending' : 'ascending',
            })),
        }),
        columns: makeColumns(config, makeProp),
    }
}

function makeColumns(config, makeProp) {
    return config.fields
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .map((f: any) => ({
            id: uuid(),
            ...makeField(config, makeProp, f),
            ...(f.advanceSettings?.width && { width: Number(f.advanceSettings.width) }),
        }))
        .filter(p => p)
}

function makeSections(config, makeProp: (name: string) => Property): FormSection[] {
    const { sections, fields } = config
    if (!sections)
        return [
            {
                id: uuid(),
                required: true,
                prompts: fields
                    .sort((a, b) => a.displayOrder - b.displayOrder)
                    .map(
                        (f: any): FormPrompt => ({
                            id: uuid(),
                            ...makeField(config, makeProp, f),
                        })
                    ),
            },
        ]
    return sections.map(({ id, title, description, propertyIds }, index) => ({
        id,
        title,
        description,
        required: true,
        goTo: index + 1 < sections.length ? sections[index + 1].id : null,
        prompts: propertyIds.map(id => ({
            id: uuid(),
            ...makeField(
                config,
                makeProp,
                fields.find(f => f.propertyId === id)
            ),
        })),
    }))
}

function makeField(config, makeProp: (name: string) => Property, f): FormField {
    const property = makeProp(f.fieldName)
    const displayProperty = makeProp(f.fieldDisplayName)
    if (!property) return null

    const advSettings = f.advanceSettings || {}
    const { isMandatory, description, currencyDefinition, denomination: base, displayFormat: dispForm } = advSettings
    const decimalPlaces = dispForm?.replace(/[^0-9]/g, '') || null
    const keys = ['denomination', 'displayFormat', 'currencyDefinition']
    const hasFormat = keys.some(k => advSettings[k])
    const numberFormat: NumberFormat = hasFormat
        ? {
              base,
              decimalPlaces,
              removeCommas: dispForm === '.0f',
              prefix: currencyDefinition?.currencySymbol,
          }
        : null

    return {
        visorMeta: { visorFormId: config.id, visorFieldId: f.id },
        property,
        description,
        numberFormat,
        title: f.shortName,
        editable: f.isEditable,
        multiAdd: f.isMultiSelect,
        visibility: getVisibility(f),
        autogenerate: f.autogenerate,
        dependencies: f.dynamicFilters?.map((d: string) => makeProp(d)),
        useTadaValues: f.validateWithTada,
        autogeneratePrefix: f.autogeneratePrefix,
        headerJustification: getJustification(advSettings.headerJustification),
        rowJustification: getJustification(advSettings.rowJustification),
        ...(f.fieldDisplayName && { displayProperty }),
        required: (isMandatory || property?.isPrimary) ?? false,
        lastUpdateField: f.fieldName === config.lastUpdateProperty,
        dateFormat: f.dateFormat === 'datetime' ? 'MM/DD/YYYY h:mm a' : 'MM/DD/YYYY',
        ...(getCustomEditor(advSettings) && { customEditor: getCustomEditor(advSettings) }),
        ...(f.conditionalFormatting && {
            conditionalFormatting: f.conditionalFormatting.map(r => ({
                property: makeProp(r.propertyName),
                format: { backgroundColor: r.backgroundColor, fontColor: r.textColor },
                rule: r.operator,
                target: !r.targetProperty || r.targetProperty === '--' ? r.targetValue : makeProp(r.targetProperty),
            })),
        }),
    }
}

export const getJustification = value => {
    if (!value) return null
    switch (value) {
        case 0:
            return null
        case 1:
            return Justification.LEFT
        case 2:
            return Justification.CENTER
        case 3:
            return Justification.RIGHT
    }
}

function getVisibility(field): FieldVisibility {
    const { isHidden, isFormHidden } = field
    if (isHidden && isFormHidden) return FieldVisibility.HIDE
    if (isHidden) return FieldVisibility.FORM
    if (isFormHidden) return FieldVisibility.TABLE

    return FieldVisibility.SHOW
}

function getCustomEditor(advancedFieldSettings): CustomFormField {
    if (!advancedFieldSettings) return

    const { validationList, validationDisplayType } = advancedFieldSettings
    if (!validationDisplayType || !validationList?.length) return

    const display =
        validationDisplayType === 1
            ? FieldDisplayType.SELECT
            : validationDisplayType === 2
            ? FieldDisplayType.RADIO
            : FieldDisplayType.CHECKBOX

    return { display, values: validationList }
}
