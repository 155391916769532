import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import { Autocomplete, Box, Button, Chip, Grid, Popover, Stack, TextField, Typography } from '@mui/material'
import { CognitiveIcon, DropZone, FILE_TYPES, Spinner } from 'genesis-suite/components'
import { isEmpty } from 'lodash'
import { useSnackbar } from 'notistack'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { authSelectors } from '~/selectors'
import { iconService } from '../lib/services'

export default function ManageIcons() {
    const [icons, setIcons] = useState<any>({})
    const [loading, setLoading] = useState<boolean>(true)
    const [showUpload, setShowUpload] = useState<boolean>(false)
    const [categoryName, setCategoryName] = useState<string>('General')
    const [file, setFile] = useState<File | null>(null)
    const uploadButtonRef = useRef()
    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const [errorText, setErrorText] = useState<string>('')
    const isCoreSuperUser = useSelector(authSelectors.getIsCoreSuperUser)
    const [categoryNameOptions, setCategoryNameOptions] = useState([])

    const fetchIcons = async () => {
        try {
            setLoading(true)
            const response = await iconService.getIcons()
            setIcons(response)
            if (!isEmpty(response)) {
                setCategoryNameOptions(Object.keys(response))
            }
        } catch (error) {
            console.error('Error fetching icons:', error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchIcons()
    }, [])

    const onDrop = async fileArr => {
        const file = fileArr[0]
        if (isDuplicateFileName(file.name)) {
            setErrorText(`An icon with the name ${file.name} already exists`)
            return
        }

        setFile(file)
        setErrorText('')
    }

    const isDuplicateFileName = (fileName: string) => {
        if (isEmpty(icons)) return false

        for (const iconGroup in icons) {
            if (icons[iconGroup].some(name => name.toLowerCase() === fileName.toLowerCase())) return true
        }

        return false
    }

    const handleUploadClick = () => {
        setShowUpload(!showUpload)
    }

    const handleUpload = async () => {
        if (categoryName && file) {
            const fileData = new FormData()
            fileData.append('file', file)
            try {
                await iconService.uploadIcon(fileData, categoryName)
                showSnackbar('Icon uploaded successfully', { variant: 'success' })
                fetchIcons()
            } catch (err) {
                console.error(err)
                showSnackbar('An error occurred uploading your file.', { variant: 'error' })
            } finally {
                setFile(null)
                setShowUpload(false)
            }
        }
    }

    if (loading) {
        return (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Spinner />
            </Box>
        )
    }
    const handleRemoveFile = () => {
        setFile(null)
        setErrorText('')
    }

    const RenderUploadedFile = () => {
        if (file) {
            return (
                <Stack direction="row" gap={1} padding={1}>
                    <Chip label={file.name} size="small" onDelete={() => handleRemoveFile()} />
                </Stack>
            )
        }
        return null
    }
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            {isCoreSuperUser && (
                <Box sx={{ display: 'flex', justifyContent: 'end', padding: 2 }}>
                    <Button
                        ref={uploadButtonRef}
                        variant="contained"
                        color="secondary"
                        endIcon={<FileUploadOutlinedIcon fontSize="small" />}
                        onClick={handleUploadClick}
                    >
                        Upload
                    </Button>
                </Box>
            )}
            <Popover
                anchorEl={uploadButtonRef.current}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                onClose={() => setShowUpload(false)}
                open={showUpload}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', padding: 2 }}>
                    <Autocomplete
                        freeSolo
                        value={categoryName}
                        sx={{
                            paddingBottom: 1,
                        }}
                        options={categoryNameOptions}
                        onChange={(e, value) => setCategoryName(value)}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label="Category Name"
                                variant="outlined"
                                required
                                error={!Boolean(categoryName)}
                                helperText={!Boolean(categoryName) ? 'Enter the category name for the icon' : null}
                                onChange={e => setCategoryName(e.target.value)}
                            />
                        )}
                    />
                    <RenderUploadedFile />
                    <DropZone onDrop={onDrop} accept={FILE_TYPES.SVG} message="Drop a SVG file or click to choose" />
                    <Typography variant="caption" sx={{ paddingTop: 1, color: 'red' }}>
                        {errorText}
                    </Typography>
                    <Button
                        variant="outlined"
                        onClick={handleUpload}
                        style={{ marginTop: '10px' }}
                        disabled={!Boolean(categoryName) || !file}
                        sx={{ color: 'text.primary', borderColor: 'text.primary' }}
                    >
                        Upload
                    </Button>
                </Box>
            </Popover>

            {isEmpty(icons) ? (
                <Typography>No icons found</Typography>
            ) : (
                Object.keys(icons).map(iconGroup => (
                    <React.Fragment key={iconGroup}>
                        <Typography variant="h6" paddingLeft={1}>
                            {iconGroup}
                        </Typography>
                        <Grid container spacing={2} sx={{ padding: 2 }}>
                            {icons[iconGroup].map((iconName: string) => (
                                <Grid item xs={3} key={iconName}>
                                    <div style={{ textAlign: 'center' }}>
                                        <CognitiveIcon icon={{ file: `${iconGroup}/${iconName}` }} />
                                        <Typography>{iconName}</Typography>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </React.Fragment>
                ))
            )}
        </Box>
    )
}
