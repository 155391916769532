import { Box, List, ListItem, ListItemButton, ListItemText, Skeleton, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Collapsable } from 'genesis-suite/components'
import { VisualChange, VisualType } from 'genesis-suite/types/visualTypes'
import { visualService } from '../../lib/services'
import { applicationSelectors } from '../../selectors'
import { PublishContext } from '../contexts/PublishContext'

export default function PublishDetails() {
    const { publish } = useContext(PublishContext)
    if (!publish) return null

    return (
        <Box py={1}>
            {Boolean(publish.counts.widget.total) && <AsyncChangesByType type={VisualType.WIDGET} />}
            {Boolean(publish.counts.dashboard.total) && <AsyncChangesByType type={VisualType.DASHBOARD} />}
            {Boolean(publish.counts.module.total) && <AsyncChangesByType type={VisualType.MODULE} />}
        </Box>
    )
}

function AsyncChangesByType({ type }: { type: VisualType }) {
    const { publish, selectedChange, setSelectedChange } = useContext(PublishContext)
    const appName = useSelector(applicationSelectors.getCurrentAppName)

    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [changes, setChanges] = useState<Omit<VisualChange, 'log'>[]>(null)

    const title = type === VisualType.DASHBOARD ? 'Collections' : type === VisualType.MODULE ? 'Apps' : 'Widgets'
    const publishId = publish?.id
    const totalCount = publish.counts[type].total

    useEffect(() => {
        if (!open && selectedChange?.type === type) setOpen(true)
    }, [selectedChange])

    useEffect(() => {
        if (!open) return

        setLoading(true)
        visualService
            .getMetaChanges(appName, { visualType: type, publishId })
            .then(res => {
                const changes = res.data
                if (!changes?.length) return

                setChanges(changes)
                if (!selectedChange?.id) setSelectedChange({ type, id: changes[0].id })
            })
            .finally(() => setLoading(false))
    }, [open, appName, type, publishId])

    return (
        <Collapsable
            open={open}
            onToggle={() => setOpen(s => !s)}
            header={
                <Typography sx={{ fontWeight: 'bold', pl: 2 }}>
                    {title} ({totalCount})
                </Typography>
            }
        >
            <List component="div" disablePadding dense>
                {loading
                    ? Array(totalCount).fill(0).map((_, index) => (
                          <ListItem key={index} sx={{ pl: 4 }}>
                              <Skeleton height="22px" width="100%" />
                          </ListItem>
                      ))
                    : changes?.map(change => (
                          <ListItemButton
                              key={change.id}
                              selected={change.id === selectedChange.id}
                              sx={{ pl: 4 }}
                              onClick={() => setSelectedChange({ type, id: change.id })}
                          >
                              <ListItemText primary={change.visual.title || 'Untitled'} />
                          </ListItemButton>
                      ))}
            </List>
        </Collapsable>
    )
}
