import { CloseRounded } from '@mui/icons-material'
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classnames from 'classnames'
import { ExpandWidget } from 'genesis-suite/icons'
import { useState } from 'react'
import AIChatBox from './AIChatBox'

const useStyles = makeStyles({
    root: {
        zIndex: '999 !important',
    },
    paper: {
        width: '80vw',
        height: '80vh',
        overflow: 'scroll',
    },
    paperExpanded: {
        height: '100vh',
        width: '100vw',
    },
    dialogContentRoot: {
        padding: '0 !important',
        overflow: 'scroll',
    },
})

export default function AIChatDialog({ openChatDialog, closeDialog, inputQuestion = null }) {
    const classes = useStyles()
    const [expanded, setExpanded] = useState(false)
    return (
        <Dialog
            open={openChatDialog}
            maxWidth={false}
            className={classes.root}
            PaperProps={{
                className: classnames({ [classes.paper]: !expanded, [classes.paperExpanded]: expanded }),
            }}
        >
            <DialogTitle>
                Explainable AI
                <Box sx={{ display: 'flex', position: 'absolute', right: 8, top: 8 }}>
                    <IconButton
                        aria-label="close"
                        onClick={() => setExpanded(!expanded)}
                        sx={{
                            color: 'inherit',
                        }}
                    >
                        <ExpandWidget sx={{ fontSize: '18px' }} />
                    </IconButton>
                    <IconButton
                        aria-label="close"
                        onClick={() => {
                            setExpanded(false)
                            closeDialog()
                        }}
                        sx={{
                            color: 'inherit',
                        }}
                    >
                        <CloseRounded />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent classes={{ root: classes.dialogContentRoot }}>
                <AIChatBox Header={({ children }) => <>{children}</>} inputQuestion={inputQuestion}></AIChatBox>
            </DialogContent>
        </Dialog>
    )
}
