import { Box, Hidden } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import { MenuIcon } from 'genesis-suite/components'
import { Globe } from 'genesis-suite/icons'
import { appearanceCreators } from '../actions/creators'
import { appearanceSelectors } from '../selectors'
import GlobalFilters2 from './GlobalFilters2'
import { filterHasValue, useGlobalFilters } from './widgets2/utils/contextFilterUtils'

export default function GlobalFiltersController({ iconStyle, buttonStyle }) {
    const open = useSelector(appearanceSelectors.getShowGlobalFilters)
    const dispatch = useDispatch()

    const toggleOpen = () => dispatch(appearanceCreators.toggleGlobalFilters())

    const { filters } = useGlobalFilters() || {}
    const filterCount = filters?.filter(filterHasValue).length

    return (
        <Hidden mdDown>
            <MenuIcon
                open={open}
                onClick={toggleOpen}
                onClose={toggleOpen}
                icon={<Globe sx={iconStyle} />}
                buttonProps={{ sx: buttonStyle, 'data-cy': 'global-filters-btn' }}
                title="Global Filters"
                tooltip="Global filters"
                badge={filterCount}
            >
                <Box width={filters?.length > 1 ? '450px' : undefined}>
                    <GlobalFilters2 onDone={toggleOpen} />
                </Box>
            </MenuIcon>
        </Hidden>
    )
}
