import React from 'react'

export default function NeoClassicLayoutTile({ colors }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="110" height="75" fill="none" viewBox="0 0 110 75">
            <path
                fillRule="evenodd"
                stroke="#8D8F92"
                fill={colors.content}
                strokeWidth="0.5"
                d="M94.348 74.334H7.652C3.979 74.334 1 71.554 1 68.126V7.208C1 3.78 3.979 1 7.652 1h86.696C98.022 1 101 3.78 101 7.208v60.918c0 3.428-2.978 6.208-6.652 6.208z"
                clipRule="evenodd"
            ></path>
            <mask id="mask0" width="100" height="74" x="1" y="1" maskUnits="userSpaceOnUse">
                <path
                    fill={colors.content}
                    fillRule="evenodd"
                    d="M7.653 1C3.979 1 1 3.78 1 7.208v60.918c0 3.428 2.979 6.208 6.653 6.208h86.695c3.674 0 6.652-2.78 6.652-6.208V7.208C101 3.78 98.022 1 94.348 1H7.653z"
                    clipRule="evenodd"
                ></path>
            </mask>
            <g mask="url(#mask0)">
                <path fill={colors.sideNav} d="M2 78h6.726V-9H-.12v87z"></path>
                <rect width="4" height="4" x="3" y="20" fill="#fff" rx="2"></rect>
                <rect width="4" height="4" x="3" y="30" fill="#fff" rx="2"></rect>
                <rect width="4" height="4" x="3" y="40" fill="#fff" rx="2"></rect>
                <rect width="4" height="4" x="3" y="50" fill="#fff" rx="2"></rect>
                <rect width="4" height="4" x="3" y="60" fill="#fff" rx="2"></rect>
            </g>
            <mask id="mask1" width="100" height="74" x="1" y="1" maskUnits="userSpaceOnUse">
                <path
                    fill={colors.content}
                    fillRule="evenodd"
                    d="M7.653 1C3.979 1 1 3.78 1 7.208v60.918c0 3.428 2.979 6.208 6.653 6.208h86.695c3.674 0 6.652-2.78 6.652-6.208V7.208C101 3.78 98.022 1 94.348 1H7.653z"
                    clipRule="evenodd"
                ></path>
            </mask>
            <g mask="url(#mask1)">
                <path fill={colors.topBar} d="M-1.242 18h105.377V0H-1.242v18z"></path>
                <rect width="18" height="6" x="69" y="7" fill="#FDBD01" rx="3"></rect>
                <rect width="18" height="6" x="92" y="7" fill="#FDBD01" rx="3"></rect>
                <rect width="18" height="6" x="46" y="7" fill="#FDBD01" rx="3"></rect>
                <rect width="19" height="6" x="21.663" y="7" fill="#FDBD01" rx="3"></rect>
            </g>
            <rect width="6" height="6" x="7.847" y="7" fill="#fff" rx="3"></rect>
            <rect width="41.478" height="18" x="54.81" y="51" fill={colors.widget} rx="2"></rect>
            <rect width="84.077" height="24" x="12.21" y="23" fill={colors.widget} rx="2"></rect>
            <rect width="38.115" height="18" x="12.21" y="51" fill={colors.widget} rx="2"></rect>
        </svg>
    )
}
