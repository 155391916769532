import { KeyboardRounded } from '@mui/icons-material'
import { IconButton, Popover, Tooltip } from '@mui/material'
import HotKeyDisplay from './HotKeyDisplay'
import { useRef, useState } from 'react'
import { useHotKey } from 'genesis-suite/hooks'
import { HotKeys, createTip } from '../lib/hotkeys'

export default function HotKeysButton({ buttonStyle, iconStyle }) {
    const [anchorEl, setAnchorEl] = useState(null)
    const btnRef = useRef(null)

    const handleClick = () => {
        if (!anchorEl) setAnchorEl(btnRef.current)
        else setAnchorEl(null)
    }
    useHotKey(HotKeys.ShowHotKeys, handleClick)
    const keyTip = createTip(HotKeys.ShowHotKeys)

    return (
        <>
            <Tooltip title={`Show hotkeys ${keyTip}`}>
                <IconButton ref={btnRef} sx={buttonStyle} onClick={handleClick}>
                    <KeyboardRounded sx={iconStyle} />
                </IconButton>
            </Tooltip>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                PaperProps={{ sx: { p: 1 } }}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <HotKeyDisplay />
            </Popover>
        </>
    )
}
