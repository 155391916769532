import { combineReducers } from 'redux'

import { tourTypes } from '../actions/types'

const open = (state = false, action) => {
    switch (action.type) {
        case tourTypes.TOUR_OPEN:
            return true

        case tourTypes.TOUR_CLOSED:
            return false

        default:
            return state
    }
}

const step = (state = 0, action) => {
    const { type, payload } = action

    switch (type) {
        case tourTypes.UPDATE_TOUR_STEP:
            return payload.step

        default:
            return state
    }
}

export default combineReducers({ open, step })
