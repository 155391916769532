import { useSelector } from 'react-redux'
import useSWR, { type SWRConfiguration } from 'swr'
import { filterService } from '../../lib/services'
import { applicationSelectors } from '../../selectors'

const swrOptions: SWRConfiguration = {
    revalidateOnFocus: false,
    errorRetryCount: 2,
    dedupingInterval: 60000,
}

export default function useNetworkFilters(perspectiveId?: string) {
    const modelName = useSelector(applicationSelectors.getCurrentAppName)
    const args = perspectiveId ? [modelName, perspectiveId] : [modelName]
    const service = perspectiveId ? filterService.getPerspectiveNetworkFilters : filterService.getNetworkFilters
    //@ts-ignore
    const { data: networkFilters, mutate, error, isLoading } = useSWR(args, args => service(...args), swrOptions)

    return [networkFilters, isLoading, mutate, error]
}
