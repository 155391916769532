import { isEmpty } from 'lodash'
import useSWR, { SWRConfiguration } from 'swr'

import { SemanticType } from 'genesis-suite/types/architectureTypes'
import { definitionService } from '../lib/services'
import { useMemo } from 'react'

const swrOptions: SWRConfiguration = {
    revalidateOnFocus: false,
    errorRetryCount: 2,
    dedupingInterval: 3600 * 1000,
}

export default function useSemanticTypes() {
    const { data } = useSWR<SemanticType[]>(
        'definitionService.getSemDataTypes',
        () => definitionService.getSemDataTypes(),
        swrOptions
    )
    return data
}

export function useHasSemanticTypes() {
    const types = useSemanticTypes()
    return !isEmpty(types)
}

export function useSemanticTypeById() {
    const types = useSemanticTypes()
    const byId = useMemo(
        () => types?.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {} as { [id: string]: SemanticType }),
        [types]
    )
    return byId
}
