import { useContext } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Box, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import FolderIcon from '@mui/icons-material/FolderOutlined'

import { EditableLabel } from 'genesis-suite/components'
import ViewItem from './ViewItem'
import { DragItem, MoveDragItem } from './ViewTypes'
import { ModuleViewsContext } from './ModuleViewsContext'

const useStyles = makeStyles(({ spacing, palette }) => ({
    viewGroup: {
        cursor: (p: any) => (p.isPowerUser ? 'grab' : ''),
        background: palette.background.sideNav,
        borderWidth: '2px',
        borderStyle: 'solid',
        borderRadius: '4px',
        opacity: (p: any) => (p.isDraggingGroup ? 0 : 1),
        borderColor: (p: any) => (p.isDragging ? palette.text.primary : 'transparent'),
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        height: '28px', // match ViewItem height
    },
    footer: { height: '10px' },
}))

export interface Props {
    index: number
}

export default function ViewGroup({ index }: Props) {
    const { draftViews, draggingItem, onMoveItem, onFindIndexes, onDeleteGroup, onUpdateView, isPowerUser } =
        useContext(ModuleViewsContext)
    const { id, dragId, title, views } = draftViews[index]
    const originalIndexes = onFindIndexes(dragId)

    const [{ isDraggingGroup }, drag] = useDrag<MoveDragItem, MoveDragItem, { isDraggingGroup: boolean }>({
        type: 'group',
        item: { type: 'group', id, status: 'existing', dragId, originalIndexes },
        isDragging: monitor => dragId === monitor.getItem().dragId,
        collect: monitor => ({ isDraggingGroup: monitor.isDragging() }),
        end: (dropResult, monitor) => {
            if (!dropResult) return
            const { originalIndexes } = dropResult
            const didDrop = monitor.didDrop()
            if (!didDrop) onMoveItem(dropResult, originalIndexes)
        },
    })

    const [, dropHeader] = useDrop({
        accept: ['dashboard', 'group', 'widget'],
        hover(item: DragItem) {
            if (item.dragId === dragId) return

            const groupIndex = onFindIndexes(dragId)[0]
            const itemIndexes = onFindIndexes(item.dragId)
            const toIndexes =
                item.type === 'group'
                    ? [groupIndex]
                    : groupIndex === itemIndexes?.[0]
                    ? [groupIndex - 1]
                    : [groupIndex, 0]

            onMoveItem(item, toIndexes)
        },
    })

    const [, dropFooter] = useDrop({
        accept: ['dashboard', 'group', 'widget'],
        hover(item: DragItem) {
            if (item.type === 'group') return
            if (item.dragId === dragId) return

            const groupIndex = onFindIndexes(dragId)[0]
            const itemIndexes = onFindIndexes(item.dragId)
            const toIndexes = groupIndex === itemIndexes?.[0] ? [groupIndex + 1] : [groupIndex, views?.length ?? 0]

            onMoveItem(item, toIndexes)
        },
    })

    const classes = useStyles({ isPowerUser, isDraggingGroup, isDragging: Boolean(draggingItem) })
    let groupDownSteps: number
    if (draggingItem?.type === 'group' && draftViews[index + 1])
        groupDownSteps = (draftViews[index + 1]?.views?.length ?? 0) + 1

    return (
        <div>
            <div className={classes.viewGroup} ref={node => (isPowerUser ? drag(node) : undefined)}>
                <div className={classes.header} ref={dropHeader}>
                    <FolderIcon sx={{ mr: 0.5 }} />
                    <EditableLabel
                        editMethod="click-text"
                        hideFinishButtons
                        value={title}
                        onChange={title => onUpdateView(dragId, { title })}
                    />
                    <Box flex={1} />

                    {isPowerUser && (
                        <IconButton size="small" onClick={() => onDeleteGroup(index)}>
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    )}
                </div>

                <Box pl={1}>
                    {views?.map(item => (
                        <ViewItem key={item.dragId} view={item} isInGroup groupDownSteps={groupDownSteps} />
                    ))}
                </Box>
            </div>
            <div ref={dropFooter} className={classes.footer} />
        </div>
    )
}
