import { connect } from 'react-redux'
import { filterSelectors } from '../selectors'
import { filterCreators, appearanceCreators } from '../actions/creators'
import NeoClassicFiltersSelector from './NeoClassicFiltersSelector'
import { logEvent } from '../lib/amplitudeClient'

const mapStateToProps = state => {
    const globalFilters = filterSelectors.getGlobalFiltersConfig(state)
    const selectedValues = filterSelectors.getAppliedGlobalFilters(state) || {}
    const source = filterSelectors.getGlobalFiltersSource(state)

    return { filters: globalFilters, selectedValues, source }
}

const mapDispatchToProps = dispatch => ({
    applyFilters: filters => {
        dispatch(filterCreators.applyGlobalFilters(filters))
        dispatch(appearanceCreators.toggleNeoClassicGlobalFilters())
    },
    saveFilters: filters => {
        logEvent('GLOBAL_FILTERS_SAVE_AS_DEFAULT')
        dispatch(filterCreators.saveDefaultGlobalFilters(filters))
    },
    onCancel: () => {
        dispatch(appearanceCreators.toggleNeoClassicGlobalFilters())
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(NeoClassicFiltersSelector)
