import produce from 'immer'
import tinyColor from 'tinycolor2'

import { appearanceTypes, themeTypes } from '../actions/types'
import initialTheme, { getThemePalette } from '../lib/theme'

export default function themeReducer(state = initialTheme, action) {
    const { type, payload } = action

    return produce(state, draft => {
        switch (type) {
            case themeTypes.UPDATE_BASE: {
                const baseTheme = getThemePalette(state.mode)
                const { id, name, base } = payload

                draft.custom.themeMeta = { id, name }
                draft.custom.stroke = base.fonts.stroke
                updateBaseTheme(baseTheme, draft)
                return
            }

            case themeTypes.UPDATE_CUSTOM: {
                const { custom } = payload

                draft.palette.primary = updatePaletteColor(custom.accent1)
                draft.palette.secondary = updatePaletteColor(custom.accent2)
                draft.palette.background.topBar = custom.topBar.main
                draft.custom.widgetColors = custom.widget
                return
            }

            case themeTypes.SET_THEME_MODE: {
                const baseTheme = getThemePalette(payload)

                draft.mode = payload
                draft.palette.mode = payload
                updateBaseTheme(baseTheme, draft)
                return
            }

            case appearanceTypes.SET_FONT_SIZE: {
                draft.typography.fontSize = payload.fontSize
                return
            }

            default:
                return
        }
    })
}

/**
 * For mui's palette system, generate custom palette colors for primary or secondary
 * @param {{main: string, light?: string, dark?: string}} value color object input
 */
const updatePaletteColor = ({ main, light, dark }) => ({
    main,
    light: light || tinyColor(main).lighten(20).toString(),
    dark,
})

const updateBaseTheme = (baseTheme, draft) => {
    draft.palette.action = {
        ...draft.palette.action,
        disabled: baseTheme.actions.disabled,
        hover: baseTheme.actions.hover,
    }

    draft.palette.highcharts = baseTheme.highcharts

    draft.palette.text = {
        ...draft.palette.text,
        primary: baseTheme.fonts.primary,
        disabled: baseTheme.fonts.disabled,
    }

    draft.palette.border = {
        ...draft.palette.border,
        main: baseTheme.borders.main,
    }

    draft.palette.background = {
        ...draft.palette.background,
        default: baseTheme.backgrounds.default,
        main: baseTheme.backgrounds.main,
        secondary: baseTheme.backgrounds.secondary,
        paper: baseTheme.backgrounds.main,
        sideNav: baseTheme.backgrounds.sideNav,
        widget: baseTheme.backgrounds.widget,
    }
}
