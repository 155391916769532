import { useContext } from 'react'

import { ChartType } from 'genesis-suite/types/visualTypes'
import { ConfigContext } from '../../ConfigContext'
import SeriesEditorCombo from './SeriesEditorCombo'
import SeriesEditorHeatmap from './SeriesEditorHeatmap'
import SeriesEditorFunnel from './SeriesEditorFunnel'
import SeriesEditorPie from './SeriesEditorPie'
import SeriesEditorPackedBubble from './SeriesEditorPackedBubble'
import SeriesEditorTable from './SeriesEditorTable'
import SeriesEditorMap from './SeriesEditorMap'
import SeriesEditorBoxPlot from './SeriesEditorBoxPlot'
import SeriesEditorBullet from './SeriesEditorBullet'
import SeriesEditorLabel from './SeriesEditorLabel'
import StatusTrackerStageEditor from '../StatusTrackerStageEditor'

export default () => {
    const { config } = useContext(ConfigContext)

    switch (config.type) {
        case ChartType.BOX_PLOT:
            return <SeriesEditorBoxPlot />
        case ChartType.BULLET:
            return <SeriesEditorBullet />
        case ChartType.COMBO:
            return <SeriesEditorCombo />
        case ChartType.HEATMAP:
            return <SeriesEditorHeatmap />
        case ChartType.LABEL:
            return <SeriesEditorLabel />
        case ChartType.PACKED_BUBBLE:
            return <SeriesEditorPackedBubble />
        case ChartType.PIE:
            return <SeriesEditorPie />
        case ChartType.MAP:
            return <SeriesEditorMap />
        case ChartType.SORTED:
            return <SeriesEditorFunnel />
        case ChartType.STATUS_TRACKER:
            return <StatusTrackerStageEditor />
        case ChartType.TABLE:
            return <SeriesEditorTable />
        default:
            return null
    }
}
