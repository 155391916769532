import LogRocket from 'logrocket'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { applicationSelectors, authSelectors } from '../selectors'
import { useFeature } from './featureFlags'

const environment = window.TADA_APIS.ENVIRONMENT || ''
const { LOG_ROCKET_KEY } = window.TADA_APIS
let hasInit = false

export default function useLogRocket() {
    const [enabled] = useFeature('log_rocket')

    const user = useSelector(authSelectors.getUser)
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const appPermission = useSelector(authSelectors.getAppPermission)

    useEffect(() => {
        if (!enabled || hasInit) return

        hasInit = true
        LogRocket.init(LOG_ROCKET_KEY, {})
    }, [enabled])

    useEffect(() => {
        if (!enabled || !user) return

        const { alias, email, userId } = user

        LogRocket.identify(userId, {
            name: alias,
            email,

            // optional
            ...(appName && { appName }),
            ...(appPermission && { appPermission }),
            ...(environment && { environment }),
        })
    }, [enabled, user, appName, appPermission])
}
