import { notificationService } from '../../lib/services'
import { notificationTypes } from '../types'
import { applicationSelectors, authSelectors } from '../../selectors'

const notificationRequest = () => {
    return {
        type: notificationTypes.NOTIFICATION_REQUEST,
    }
}

const notificationSuccess = notifications => {
    return {
        type: notificationTypes.NOTIFICATION_SUCCESS,
        payload: { notifications },
    }
}

const processNotification = notification => {
    return {
        type: notificationTypes.NOTIFICATION_RECEIVED,
        payload: { notification },
    }
}

const notificationRuleDeleted = ruleId => {
    return {
        type: notificationTypes.NOTIFICATION_RULE_DELETED,
        payload: { ruleId },
    }
}

const notificationFailure = error => {
    return {
        type: notificationTypes.NOTIFICATION_FAILURE,
        payload: { error },
        error: true,
    }
}

const notificationArchived = id => ({
    type: notificationTypes.NOTIFICATION_ARCHIVED,
    payload: { id },
})

const getNotifications = () => {
    return (dispatch, getState) => {
        const state = getState()
        const user = authSelectors.getUser(state)
        const appName = applicationSelectors.getCurrentAppName(state)
        if (!(user && appName)) return

        dispatch(notificationRequest())
        return notificationService
            .get(appName)
            .then(notifications => dispatch(notificationSuccess(notifications)))
            .catch(error => dispatch(notificationFailure(error)))
    }
}

const archive = id => (dispatch, getState) => {
    const state = getState()
    const appName = applicationSelectors.getCurrentAppName(state)

    return notificationService
        .archiveNotification(id, appName)
        .then(() => {
            dispatch(notificationArchived(id))
            return Promise.resolve()
        })
        .catch(error => Promise.reject(error))
}

export const notificationCreators = {
    getNotifications,
    processNotification,
    notificationRuleDeleted,
    notificationSuccess,
    archive,
}
