import { ChartType } from 'genesis-suite/types/visualTypes'

export default function isHighChartsType(type: ChartType) {
    switch (type) {
        case ChartType.FORM:
        case ChartType.LABEL:
        case ChartType.TABLE:
        case ChartType.TEXT:
        case ChartType.WEBVIEW:
            return false
        default:
            return true
    }
}
