import { widgetSelectors } from '../../selectors'
import { filterCreators } from './'

const perspectiveChange = id => {
    return (dispatch, getState) => {
        const state = getState()
        const config = widgetSelectors.getWidgetConfig(state, id)

        if (!config) return
        dispatch(filterCreators.applyPerspectiveFilterUserPreferences(id))
    }
}

export const networkCreators = {
    perspectiveChange,
}