import { ChopText, CognitiveIcon } from 'genesis-suite/components'
import makeStyles from '@mui/styles/makeStyles'
import { useDispatch, useSelector } from 'react-redux'
import { Edit } from '@mui/icons-material'
import { useIsMobile } from '~/hooks/useIsMobile'
import { Box, Tooltip, IconButton, useTheme } from '@mui/material'
import { menuSelectors } from '~/selectors'
import { appearanceConstants } from '~/constants'
import { widgetCreators } from '~/actions/creators'
import { widgetSelectors, authSelectors } from '~/selectors'
import PerspectiveSearchFilter from '~/components/widgets/PerspectiveSearchFilter'
import ContextPanel from '~/components/ContextPanel'
import InteractionToggle from '~/components/InteractionToggle'
import PerspectiveFilterController from '~/containers/PerspectiveFilterController'
import PerspectiveCompareFilterTrigger from '~/containers/PerspectiveCompareFilterTrigger'
import PerspectiveShare from '~/components/PerspectiveShare'
import RootPerspectiveController from '~/components/RootPerspectiveController'
import PerspectiveToolbarMenuItems from '~/components/PerspectiveToolbarMenuItems'
import FilterString from '~/components/FilterString'
import FocalPointSelect from '~/components/widgets/FocalPointSelect'
import MobileFiltersController from '~/components/MobileFiltersController'
import { NetworkFiltersProvider } from '~/components/network-filters/NetworkFiltersContext'

const { ScrollBarWidth } = appearanceConstants

const useStyles = makeStyles(({ spacing, breakpoints, palette, border }) => ({
    rightTools: {
        alignSelf: 'center',
        backgroundColor: palette.background.sideNav,
        borderRadius: border.radius.round,
        display: 'flex',
        justifyContent: 'flex-end',
        padding: spacing(0.25),
    },
    tools: {
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        [breakpoints.down('sm')]: {
            padding: spacing(1),
            justifyContent: 'space-between',
        },
    },
    leftTools: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
    },
}))

export default function PerspectiveViewLayoutToolbar({ config }) {
    const icon = useSelector(menuSelectors.getIcon)
    const disabled = !Boolean(useSelector(widgetSelectors.getCurrentPerspectiveId))
    const isMobile = useIsMobile()
    const isPublic = useSelector(authSelectors.getIsPublicLogin)

    const classes = useStyles()
    const dispatch = useDispatch()
    const { palette, custom } = useTheme()
    const defaultIconProps = { style: { fontSize: custom.perspectiveToolbar?.fontSize, color: palette.text.primary } }
    const buttonStyle = {
        padding: custom.perspectiveToolbar?.padding,
        '&.Mui-disabled': {
            cursor: 'default',
            pointerEvents: 'none',
            opacity: 0.5,
        },
    }

    const handleResetWidgets = () => {}

    return isMobile ? (
        <div className={classes.tools}>
            <PerspectiveTitle lpName={config.perspectiveLayoutName} icon={icon} isPublic={isPublic} />
            <div>
                <MobileFiltersController />
                <PerspectiveShare disabled={disabled} iconProps={defaultIconProps} />
            </div>
        </div>
    ) : (
        <Box
            className={`perspective-layout-toolbar`}
            sx={{ backgroundColor: 'background.main', paddingX: `${ScrollBarWidth}px`, pt: 1, width: '100%' }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <PerspectiveTitle lpName={config.perspectiveLayoutName} icon={icon} />
                <PerspectiveSearchFilter />
                <div className={classes.rightTools} data-html2canvas-ignore>
                    <Tooltip title="Edit Perspective Layout">
                        <span>
                            <IconButton
                                sx={buttonStyle}
                                onClick={() => {
                                    dispatch(widgetCreators.startChangingToPerspectiveLayout(config))
                                }}
                            >
                                <Edit {...defaultIconProps} />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <ContextPanel disabled={disabled} iconProps={defaultIconProps} buttonStyle={buttonStyle} />
                    <InteractionToggle disabled={disabled} iconProps={defaultIconProps} buttonStyle={buttonStyle} />
                    <PerspectiveFilterController
                        disabled={disabled}
                        iconProps={defaultIconProps}
                        buttonStyle={buttonStyle}
                        handleResetWidgets={handleResetWidgets}
                    />
                    <PerspectiveCompareFilterTrigger
                        disabled={disabled}
                        iconProps={defaultIconProps}
                        buttonStyle={buttonStyle}
                    />
                    <PerspectiveShare disabled={disabled} iconProps={defaultIconProps} buttonStyle={buttonStyle} />
                    <PerspectiveToolbarMenuItems
                        disabled={disabled}
                        iconProps={defaultIconProps}
                        buttonStyle={buttonStyle}
                        perspectiveLayout={true}
                    />
                </div>
            </Box>
            <div className={classes.tools}>
                <div className={classes.leftTools}>
                    <RootPerspectiveController />
                </div>
            </div>
            <NetworkFiltersProvider perspectiveId={config.perspectiveId}>
                <FilterString />
            </NetworkFiltersProvider>
        </Box>
    )
}

function PerspectiveTitle({ lpName, icon, isPublic }) {
    return (
        <Box sx={{ flex: 1, overflow: 'hidden', display: 'flex', alignItems: 'center', gap: 1, fontWeight: '600' }}>
            <CognitiveIcon icon={icon} />
            <ChopText variant="h5" showEllipsis>
                {lpName}&nbsp;
            </ChopText>
            {!isPublic && <FocalPointSelect isCompare />}
        </Box>
    )
}
