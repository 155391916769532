import { useState, useRef, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { appearanceConstants } from '~/constants'
import { styled } from '@mui/material/styles'
import { Box, Tabs, Tab, Typography, AccordionDetails } from '@mui/material'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordion from '@mui/material/Accordion'
import PerspectiveWidget from '~/components/widgets/PerspectiveWidget'
import { filterSelectors, widgetSelectors, scenarioSelectors } from '~/selectors'
import { PerspectiveProvider } from '~/components/contexts/PerspectiveContext'
import { parsePerspectiveConfig } from './utils'
import PerspectiveViewLayoutToolbar from './PerspectiveViewLayoutToolbar'
import TabsView from '../tabPerspective/TabsView'

const { ScrollBarWidth } = appearanceConstants
const useStyles = makeStyles(({ palette, spacing, border }) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    layout: {
        flex: 1,
        backgroundColor: palette.background.main,
        paddingX: `${ScrollBarWidth}px`,
        pt: 1,
        width: '100%',
        height: '100%',
        overflowY: 'scroll',
    },
}))

export default function PerspectiveLayoutView({ config, id }) {
    const classes = useStyles()
    const widgetRef = useRef()
    const dispatch = useDispatch()

    const perspectiveConfig = parsePerspectiveConfig(config, id)
    const { perspectiveLayoutName, layoutWidget, tabs } = perspectiveConfig

    const widgetConfigs = useSelector(s => widgetSelectors.getWidgetConfig(s, layoutWidget))
    const filters = useSelector(filterSelectors.currentFiltersConfiguration)
    const networkContext = useSelector(filterSelectors.getCoord)
    const inlineFilters = useSelector(filterSelectors.getInlineFilters)
    const perspectives = useSelector(widgetSelectors.getAllPerspectives)
    const activeScenarios = useSelector(scenarioSelectors.getActiveScenarioIds)
    const hasActiveScenarios = activeScenarios.length > 0

    const [tabValue, setTabValue] = useState(0)
    const [headerExpand, setHeaderExpand] = useState(false)

    const handleChange = (event, newValue) => {
        setTabValue(newValue)
    }

    useEffect(() => {
        setTabValue(0)
    }, [id])

    const orderedTabs = useMemo(() => {
        return tabs.sort((a, b) => a?.Order - b?.Order)
    }, [tabs])

    const baseGrid = {
        display: 'grid',
        gridTemplateColumns: 'repeat(2,1fr)',
        gap: 1,
        overflowX: 'hidden',
        overflowY: 'scroll',
    }

    const Accordion = styled(props => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        marginTop: '10px',
    }))

    const AccordionSummary = styled(props => (
        <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
    ))(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1),
        },
    }))

    return (
        <>
            <Box id="perspective-layout-container" className={classes.root}>
                <PerspectiveViewLayoutToolbar config={perspectiveConfig} />
                {layoutWidget && widgetConfigs && (
                    <Box
                        sx={{
                            backgroundColor: 'background.main',
                            paddingX: `${ScrollBarWidth}px`,
                            pt: 1,
                            width: '100%',
                        }}
                    >
                        <PerspectiveProvider id={layoutWidget} configs={[widgetConfigs]}>
                            <Accordion expanded={headerExpand} onChange={() => setHeaderExpand(!headerExpand)}>
                                <AccordionSummary>
                                    <Typography sx={{ fontWeight: '600' }}>{widgetConfigs?.Title}</Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                    sx={{
                                        height: '60dvh',
                                        overflowY: 'scroll',
                                    }}
                                >
                                    {hasActiveScenarios ? (
                                        <>
                                            <Box sx={{ ...baseGrid, flex: 1, py: 1 }}>
                                                <Typography sx={{ mb: 1 }} variant="h5">
                                                    Original
                                                </Typography>
                                                <Typography sx={{ mb: 1 }} variant="h5">
                                                    Scenario
                                                </Typography>
                                            </Box>
                                            <Box sx={{ ...baseGrid, flex: 1, height: '100%' }}>
                                                <PerspectiveWidget
                                                    config={widgetConfigs}
                                                    perspectiveId={widgetConfigs.Id}
                                                    {...{ filters, networkContext, inlineFilters }}
                                                />

                                                <PerspectiveWidget
                                                    config={widgetConfigs}
                                                    perspectiveId={widgetConfigs.Id}
                                                    {...{ filters, networkContext, inlineFilters }}
                                                    activeScenarios={activeScenarios}
                                                />
                                            </Box>
                                        </>
                                    ) : (
                                        <Box sx={{ flex: 1, height: '100%' }}>
                                            <PerspectiveWidget
                                                config={widgetConfigs}
                                                perspectiveId={widgetConfigs.Id}
                                                {...{ filters, networkContext, inlineFilters }}
                                            />
                                        </Box>
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        </PerspectiveProvider>
                    </Box>
                )}
                <Box sx={{ backgroundColor: 'background.main', paddingX: `${ScrollBarWidth}px`, pt: 1, width: '100%' }}>
                    <Tabs
                        value={tabValue}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        {orderedTabs.map((tab, key) => (
                            <Tab label={tab.name} key={key} sx={{ color: 'inherit', fontWeight: 600 }} />
                        ))}
                    </Tabs>
                </Box>
                <Box
                    sx={{
                        backgroundColor: 'background.main',
                        paddingX: `${ScrollBarWidth}px`,
                        pt: 1,
                        width: '100%',
                        height: '100%',
                        overflowY: 'scroll',
                    }}
                >
                    <TabsView tabs={tabs} tabValue={tabValue} isEdit={false} perspectives={perspectives} />
                </Box>
            </Box>
        </>
    )
}
