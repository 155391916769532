import React from 'react'
import { Typography, LinearProgress, Box } from '@mui/material'

export default function WidgetWaiting() {
    return (
        <Box height="100%" p={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Box width="100px" m={2}>
                <LinearProgress />
            </Box>

            <Typography align="center">Widget will display shortly.</Typography>
        </Box>
    )
}
