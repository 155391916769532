"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generateUniqueId = generateUniqueId;
exports.getColor = getColor;
function getColor({
  value,
  low,
  warn
}) {
  const lowValue = low?.value;
  const warnValue = warn?.value;
  if (value < lowValue) return low?.color ?? 'red';
  if (value < warnValue) return warn?.color ?? 'yellow';
  return 'green';
}
function generateUniqueId() {
  const randomId = Math.random().toString(36).substring(2, 10); // Generate a random string
  const timestamp = Date.now().toString(36).substring(4, 10); // Get the current timestamp and convert it to a string
  const uniqueId = `${randomId}-${timestamp}`; // Combine the random string and the timestamp
  return uniqueId;
}