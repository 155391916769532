import { Dialog, DialogContent } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import SyncChartContainer from '../containers/SyncChartContainer'

const useStyles = makeStyles({
    root: {
        zIndex: '999 !important',
    },
    paper: {
        width: '80vw',
        height: '80vh',
        overflow: 'visible !important',
    },
    dialogContentRoot: {
        padding: '0 !important',
        overflow: 'visible !important',
    },
})

interface Props {
    config: any
    open: boolean
    onClose: () => void
}

export default function SyncChartModel({ config, open, onClose }: Props) {
    const classes = useStyles()

    const handleClose = () => {
        onClose()
    }

    return (
        open && (
            <Dialog open={open} maxWidth={false} className={classes.root} PaperProps={{ className: classes.paper }}>
                <DialogContent classes={{ root: classes.dialogContentRoot }}>
                    <SyncChartContainer onRequestClose={handleClose} widgetConfig={config} />
                </DialogContent>
            </Dialog>
        )
    )
}
