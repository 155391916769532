import React from 'react'
import { Tooltip, ListItemIcon, ListItemText } from '@mui/material';
import withStyles from '@mui/styles/withStyles'

const styles = theme => ({
    wrapper: {
        display: 'inline-flex',
        alignItems: 'center',
        width: '100%',
    },
    letterBox: ({ background, text }) => ({
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 30,
        height: 30,
        borderRadius: 5,
        backgroundColor: background ?? theme.palette.tada.purple,
        color: text ?? 'white',
    }),
})

const LetterOption = ({ classes, label, textClass, overriddenPrimaryColor, background, text, ...rest }) => {
    if (!label) return null
    return (
        <span className={classes.wrapper} {...rest}>
            <ListItemIcon>
                <span className={`${classes.letterBox} ${overriddenPrimaryColor ? overriddenPrimaryColor : null}`}>
                    {label[0]}
                </span>
            </ListItemIcon>
            <Tooltip title={label}>
                <ListItemText classes={{ primary: textClass }}>{label}</ListItemText>
            </Tooltip>
        </span>
    )
}

export default withStyles(styles)(LetterOption)
