const initializeHub = (model, user) => {
    const { connection } = window.$

    connection.hub.url = `${window.TADA_APIS.COLLABORATION}/signalr`
    connection.hub.qs = { an: model, user }

    return new Promise((resolve, reject) => {
        connection.hub
            .start()
            .done(() => {
                connection.hub.disconnected(function () {
                    setTimeout(function () {
                        connection.hub.start()
                    }, 5000)
                })
                resolve(connection.hub.id)
            })
            .fail(error => reject(error))
    })
}

const stopHub = () => {
    return new Promise((resolve, reject) => {
        if (window.$.connection && window.$.connection.collaborationHub) {
            const hub = window.$.connection.collaborationHub
            hub.connection.stop()
            resolve()
        }
    })
}

export const hubService = {
    initializeHub,
    stopHub,
}
