import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { Close } from 'genesis-suite/icons'
import { TGPTRequest } from 'genesis-suite/types/visualTypes'
import { useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { perspectiveCreators } from '~/actions/creators/perspective.creators'
import {
    applicationSelectors,
    authSelectors,
    filterSelectors,
    getSelectedBackup,
    moduleSelectors,
    perspectiveSelectors,
    scenarioSelectors,
} from '~/selectors'
import LoadingView from '~/views/LoadingView'
import SpeechMimic from '~/views/rightnav/SpeechMimic'
import { aiService } from '../../lib/services'
import { PerspectiveContext } from '../contexts/PerspectiveContext'
import { PerspectiveWidgetContext } from '../contexts/PerspectiveWidgetContext'

export default function PerspectiveSummary(props) {
    const { perspectiveDataById } = useContext(PerspectiveContext)
    const { widgetDataById } = useContext(PerspectiveWidgetContext)
    const dispatch = useDispatch()
    const userId = useSelector(authSelectors.getUserId)
    const tModule = useSelector(moduleSelectors.getCurrentModule)
    const appInfo = useSelector(applicationSelectors.getAppInfo)
    const accessKey = useSelector(authSelectors.getAccessKey)
    const contextFilters = useSelector(filterSelectors.currentGlobalFiltersConfiguration)
    const selectedBackup = useSelector(getSelectedBackup)
    const backupVersionId = selectedBackup?.[0]?.Id ?? null
    const scenarios = useSelector(scenarioSelectors.getActiveScenarioIds)
    const [summary, setSummary] = useState('')
    const summaryBoxRef = useRef(null)
    const [loading, setLoading] = useState(false)
    const show = useSelector(perspectiveSelectors.getShowPerspectiveSummary)

    const fetchSummary = async () => {
        setLoading(true)
        const request: TGPTRequest = {
            PortalId: tModule.id,
            PortalName: tModule.name,
            CloudId: appInfo.cloudId,
            CloudName: appInfo.cloudName,
            AppId: appInfo.appId,
            AppName: appInfo.appName,
            UserId: userId,
            accessKey,
            contextFilters,
            backupVersionId,
            scenarios,
            data: widgetDataById !== undefined ? JSON.stringify(widgetDataById) : JSON.stringify(perspectiveDataById),
        }

        try {
            const response = await aiService.getSummary(request)

            setSummary(response)
        } catch {
            console.error('Failed to get explanation')
            setSummary('Failed to get summary. Try again later.')
        } finally {
            setLoading(false)
        }
    }

    const handleClose = () => {
        dispatch(perspectiveCreators.togglePerspectiveSummary(false))
    }

    useEffect(() => {
        return () => {
            handleClose()
        }
    }, [])

    useEffect(() => {
        if (show || props?.open) fetchSummary()
    }, [show])

    const scrollIntoView = () => {
        summaryBoxRef.current?.scrollTo(0, summaryBoxRef.current.scrollHeight)
    }

    const handleRegenerate = () => {
        fetchSummary()
    }

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                    Enterprise AI:
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip key="refresh" title="Regenerate Summary">
                        <IconButton sx={{ color: 'text.primary' }} onClick={handleRegenerate}>
                            <AutorenewRoundedIcon />
                        </IconButton>
                    </Tooltip>
                    {!widgetDataById && (
                        <Tooltip key="close" title="Close Summary">
                            <IconButton sx={{ color: 'text.primary' }} onClick={handleClose}>
                                <Close />
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>
            </Box>
            <Box
                sx={{
                    ml: '25px',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '72lvh',
                }}
            >
                <Box ref={summaryBoxRef} sx={{ height: '100%', overflow: 'scroll' }}>
                    {loading ? (
                        <LoadingView
                            message={`Generating ${widgetDataById ? 'Widget Summary' : 'Perspective Summary'} ...`}
                            hideTopBar={true}
                        />
                    ) : (
                        <SpeechMimic text={summary} onChange={scrollIntoView} />
                    )}
                </Box>
                {!widgetDataById && (
                    <Typography sx={{ lineHeight: 1.2 }} variant="caption">
                        Summary generated by TADA Enterprise AI may contain errors or inaccurate information and should
                        not be relied upon as a substitute for professional advice. Validate your response accordingly.
                    </Typography>
                )}
            </Box>
        </>
    )
}
