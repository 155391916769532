export const businessExplorerTypes = {
    CREATE_CONFIG: 'CREATE_CONFIG',
    DELETE_CONFIG: 'DELETE_CONFIG',
    DELETE_CONFIGS: 'DELETE_CONFIGS',
    RESET: 'RESET',
    SET_ANCHOR_ID: 'SET_ANCHOR_ID',
    SET_SELECTED_CONFIG: 'SET_SELECTED_CONFIG',
    SET_SERVICE: 'SET_SERVICE',
    UPDATE_CONFIG: 'UPDATE_CONFIG',
}
