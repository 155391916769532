import { messengerType } from '../actions/types'

//Todo: Add Types after finalization
const initialState: any = {
    messageList: [],
    userList: [],
    userChatList: [
        // {
        //     userData: {},
        //     chatMessages: [],
        //     isLoading: false,
        //     pagination: {
        //         pageNo: 1,
        //         pageSize: 10,
        //         hasMore: true,
        //     },
        // },
    ],

    showLoader: false,
}

export default (state = initialState, action): any => {
    const { payload } = action
    switch (action.type) {
        case messengerType.TOGGLE_LOADER:
            return { ...state, showLoader: !state.showLoader }
        case messengerType.UPDATE_USER_LIST:
            return { ...state, userList: payload }
        case messengerType.UPDATE_MESSAGES_LIST:
            return { ...state, messageList: payload }
        case messengerType.UPDATE_USER_CHAT_LIST:
            return { ...state, userChatList: payload }
        case messengerType.UPDATE_USER_MESSAGES:
            return {
                ...state,
                messageList: payload.messageList,
                userList: payload.userList,
            }
        default:
            return state
    }
}
