import { Box, Typography } from '@mui/material'
import { forwardRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { TreeConfig } from 'genesis-suite/types/visualTypes'
import { navigationCreators } from '../../../actions/creators'
import { treeService } from '../../../lib/services'
import { applicationSelectors } from '../../../selectors'
import { WidgetProps } from '../../../types/WidgetTypes'
import TreeTable from './TreeTable'

const TreeWidget = forwardRef<HTMLDivElement, WidgetProps<TreeConfig>>(({ config }, ref) => {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const dispatch = useDispatch()
    const [response, setResponse] = useState(null)
    const [error, setError] = useState('')

    useEffect(() => {
        getTreeData(config)
    }, [config])

    async function getTreeData(config) {
        try {
            const [meta, data] = await Promise.all([
                treeService.getMeta(appName, config.treeName),
                treeService.getData(appName, config.treeName),
            ])

            setResponse({ data, meta })
        } catch (err) {
            console.error(err)
            setError('Could not get tree data')
        }
    }

    const content = (() => {
        if (error) return <Typography>{error}</Typography>
        if (!response) return <Typography>No data</Typography>

        const { data, meta } = response
        function openPerspective(this, value) {
            if (value.field !== meta.PrimaryPropertyName) return

            const crumb = {
                DefaultPerspective: config.navigation?.perspectiveId,
                FieldName: meta.PrimaryPropertyName,
                Name: 'employee',
                Value: value.value,
            }
            dispatch(navigationCreators.goToPerspective(config.navigation?.perspectiveId, { context: crumb }))
        }
        return <TreeTable meta={meta} data={data} onNav={openPerspective} />
    })()

    return (
        <Box ref={ref} sx={{ height: '100%' }}>
            {content}
        </Box>
    )
})

export default TreeWidget
