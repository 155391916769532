import { Dispatch, memo, SetStateAction, useMemo } from 'react'
import { LinearProgress } from '@mui/material'
import { DataGridPro, GridColDef, GridOverlay } from '@mui/x-data-grid-pro'
import makeStyles from '@mui/styles/makeStyles'
import tinyColor from 'tinycolor2'

import { Module } from 'genesis-suite/types/visualTypes'
import { SortBy } from 'genesis-suite/types/utilTypes'
import { useSelector } from 'react-redux'
import { authSelectors, moduleSelectors } from '../../selectors'
import { makeColumns } from './utils'
import { makeRows, authorKey, createdBySortKey } from '../ManageModules/utils'
import { sortData, getComparator } from '../../lib/manageUtils'
import { ModuleMetaFilter } from '../../types/ManageAppTypes'

const ROW_HEIGHT = 40

const useStyles = makeStyles(({ palette }) => ({
    tableWrapper: { flex: 1 },
    root: {
        borderColor: palette.border?.main,
    },
    cell: { '&:focus': { outline: 'none !important' }, borderColor: `${palette.border?.main} !important` },
    columnHeaders: { '& .MuiButtonBase-root': { color: palette.text.primary }, borderColor: palette.border?.main },
    columnSeparator: { color: `${palette.border?.main} !important` },
    pinnedColumn: {
        '& .MuiButtonBase-root': { color: palette.text.primary },
        backgroundColor: palette.background.main,
        boxShadow: 'none',
    },
    cover: { display: 'flex', flexDirection: 'column' },
    currentModuleRow: {
        backgroundColor: (p: any) => {
            const highlightColor = p.tinyColor(palette.primary.main)
            highlightColor.setAlpha(0.3)
            return highlightColor
        },
    },
}))

function LoadingOverlay() {
    return (
        <GridOverlay>
            <div style={{ position: 'absolute', top: 0, width: '100%', zIndex: 1 }}>
                <LinearProgress />
            </div>
        </GridOverlay>
    )
}

interface TableProps {
    classes: any
    columns: GridColDef[]
    data: any
    loading: boolean
    onSort: Props['onSort']
}

function RenderTable({ classes, columns, data, loading, onSort }: TableProps) {
    const currentModuleId = useSelector(moduleSelectors.getModuleId)

    function handleSort(model) {
        if (!model.length) return onSort({})

        const { field, sort } = model[0]
        const fieldName = field === authorKey ? createdBySortKey : field
        onSort({ [fieldName]: sort })
    }

    return (
        <DataGridPro
            classes={{
                root: classes.root,
                cell: classes.cell,
                columnHeaders: classes.columnHeaders,
                columnSeparator: classes.columnSeparator,
                pinnedColumnHeaders: classes.pinnedColumn,
                pinnedColumns: classes.pinnedColumn,
            }}
            columns={columns}
            components={{ LoadingOverlay }}
            disableColumnFilter
            disableColumnSelector
            disableSelectionOnClick
            getRowClassName={({ row }) => {
                return currentModuleId === row?.id ? classes.currentModuleRow : ''
            }}
            hideFooter
            loading={loading}
            onSortModelChange={handleSort}
            pinnedColumns={{ right: ['actions'] }}
            rows={data}
            rowHeight={ROW_HEIGHT}
            rowThreshold={3}
        />
    )
}

const Table = memo(RenderTable)

interface Props {
    filter: ModuleMetaFilter['filter']
    loading: boolean
    modules: Array<Module>
    onCopy: (module: Module) => void
    onDelete: (moduleId: string) => void
    onOpen: (viewId: string) => void
    onSort: Dispatch<SetStateAction<SortBy<Module>>>
    onSettings: () => void
    onUpdateFilter: (updated: Partial<ModuleMetaFilter['filter']>) => void
    sortBy: SortBy<Module>
}

export default function ModulesTable({
    filter,
    loading,
    modules,
    onCopy,
    onDelete,
    onOpen,
    onSettings,
    onSort,
    sortBy,
}: Props) {
    const isPowerUser = useSelector(authSelectors.getIsPowerUser)
    const currentModuleId = useSelector(moduleSelectors.getModuleId)

    const columns = useMemo(
        () => makeColumns(onCopy, onDelete, onOpen, onSettings),
        [onCopy, onDelete, onOpen, onSettings]
    )

    const sortCurrentModule = (data: Module[]) => {
        const modules = [...data]
        const currentModuleIndex = data.findIndex(module => module.id === currentModuleId)

        if (currentModuleIndex === -1) return data

        const currentModule = modules.splice(currentModuleIndex, 1)[0]

        modules.unshift(currentModule)
        return modules
    }

    const data = useMemo(() => {
        let data = sortData(makeRows(modules, filter), getComparator(sortBy))

        if (Object.keys(sortBy).length === 0 && data.length > 1) {
            data = sortCurrentModule(data)
        }

        return data
    }, [modules, sortBy, filter])

    const classes = useStyles({ tinyColor })

    return (
        <Table
            {...{
                classes,
                columns,
                data,
                isPowerUser,
                loading,
                onSort,
                onSettings,
            }}
        />
    )
}
