import { forwardRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { InlineNodeFilter, NavigationNodeFilter, SeriesConfig } from 'genesis-suite/types/visualTypes'
import { navigationCreators } from '../../actions/creators'
import { useSemanticTypeById } from '../../hooks/useSemanticTypes'
import { applicationSelectors, filterSelectors, widgetSelectors } from '../../selectors'
import { comboConverter } from '../MigrateModule/widgetConverters'
import { ResourceManager } from '../MigrateModule/widgetConverterUtils'
import Widget2 from '../widgets2/Widget2'
import Widget from './Widget'

/**To render a Visor widget or convert to a Builder*/
const WidgetConverter = forwardRef(({ config: visorConfig, ...rest }: any, ref) => {
    const { config, source } = useConfig(visorConfig)

    if (!config) return null

    const Component = source === 'builder' ? BuilderWidget : Widget

    return <Component config={config} {...rest} ref={ref} />
})

const BuilderWidget = forwardRef<HTMLDivElement, { config: SeriesConfig }>(({ config }, ref) => {
    const visorInlineFilters = useSelector(filterSelectors.getInlineFilters)
    const interactionType = useSelector(widgetSelectors.getInteractionType)
    const dispatch = useDispatch()

    const nodeFilters = visorInlineFilters?.filters.map(
        (f: any): InlineNodeFilter => ({
            dataIndexes: [],
            keyPropertyName: f.PropertyName,
            nodeName: f.ResourceName,
            source: 'inline',
            values: f.Values.map(value => ({ value })),
            widgetId: config.id,
        })
    )

    function handleNavigation(perspectiveId, filters: NavigationNodeFilter[], interactionTypeOverride) {
        const Filters = filters.map(f => convertFilter(config.id, f))
        const first = filters[0]
        const context = {
            Name: first.nodeName,
            FieldName: first.keyPropertyName,
            Value: first.values[0]?.value,
            DisplayFieldName: first.displayPropertyName,
            DisplayValue: first.values[0]?.label,
            DefaultPerspective: perspectiveId,
            Filters,
        }

        // TODO - look at interactionType and combine context
        dispatch(navigationCreators.goToPerspective(perspectiveId, { context }))
    }

    return (
        <Widget2 ref={ref} config={config} nodeFilters={nodeFilters} interactions={{ onNavigate: handleNavigation }} />
    )
})

let resourceManager: ResourceManager

function useConfig(visorConfig) {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const semanticTypeById = useSemanticTypeById()

    const converter = getConverter(visorConfig)
    const [config, setConfig] = useState<SeriesConfig>(converter ? null : visorConfig)

    useEffect(() => {
        if (!converter) {
            setConfig({ ...visorConfig })
        } else {
            if (!resourceManager) resourceManager = new ResourceManager(appName, semanticTypeById)

            converter({ config: visorConfig, resourceManager }).then(builderConfig => {
                setConfig({ ...builderConfig, appName, id: visorConfig.Id })
            })
        }
    }, [visorConfig])

    return { config, source: converter ? 'builder' : 'visor' }
}

export function getConverter(visorConfig) {
    const { Type, ChartConfig } = visorConfig

    switch (Type) {
        case 'Chart': {
            switch (ChartConfig?.ChartType) {
                case 'Bar':
                case 'Column':
                    if (ChartConfig?.IsTrellis) return comboConverter
            }
            break
        }
    }
}

function convertFilter(WidgetId, filter: NavigationNodeFilter) {
    return {
        FilterName: `${filter.nodeName}.${filter.keyPropertyName}`,
        PropertyName: filter.keyPropertyName,
        ResourceName: filter.nodeName,
        ResourceType: 'Concept',
        Values: filter.values.map(v => v.value),
        WidgetId,
    }
}

export default WidgetConverter
