import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { FormData, FormPalette, FormPrompt } from 'genesis-suite/types/visualTypes'
import { useSnackbar } from 'notistack'
import { FC, useState } from 'react'
import { ContextFilter, makeContextFilters } from '../../../lib/formUtils'
import { FormAttachmentField } from '../../widgets/visuals/FormWidget/FormAttachmentField'
import FormField from './FormField'
import TadaFormSelect from './TadaFormSelect'

interface PromptProps {
    values: FormData
    multiEdit: boolean
    flexLayout: boolean
    prompt: FormPrompt
    palette: FormPalette
    isEditingLayout: boolean
    onError: (reason: string, value: any) => void
    onChange: (prompt: FormPrompt, value: any) => void
    readOnly?: boolean
    textAreaHeight?: number
}

const useStyles = makeStyles(({ spacing }) => ({
    prompt: {
        marginBottom: spacing(1),
        '& label': { fontSize: 16 },
        //@ts-ignore
        '& *': { color: ({ fontColor }) => fontColor },
        //@ts-ignore
        '& *:before': { borderColor: ({ fontColor }) => fontColor },
    },
}))

const InputFormPrompt: FC<PromptProps> = ({
    prompt,
    values,
    multiEdit,
    flexLayout,
    palette,
    onChange,
    onError,
    isEditingLayout,
    readOnly = false,
    textAreaHeight,
}) => {
    const { enqueueSnackbar } = useSnackbar()
    const [contextFilters, setContextFilters] = useState<ContextFilter[]>(
        makeContextFilters(prompt.dependencies, values)
    )
    const { background, fontColor } = palette
    const classes = useStyles({ background, fontColor })
    const { id, property, displayProperty, useTadaValues, multiAdd } = prompt
    const key = property?.name || id
    const isAttachment = property?.semanticType.baseDataType === 'File'
    const { [key]: v, ...rest } = values
    const hasMultiValue = Object.values(rest).some(val => Array.isArray(val))
    const multiple = multiAdd && !hasMultiValue && Boolean(values.isNew)
    const val = values[key] ?? null
    const value = hasMultiValue ? val : formatValue(val, multiple)
    const displayValue = values[displayProperty?.name] ?? null

    const [prevValues, setPrevValues] = useState(values)
    const dependencyNames = prompt.dependencies?.map(dep => dep.name)
    const hasDependencyChanged = dependencyNames?.some(name => prevValues[name] !== values[name])
    if (hasDependencyChanged) {
        setPrevValues(values)
        setContextFilters(makeContextFilters(prompt.dependencies, values))
    }

    const handleValueChange = (val: any) => {
        onChange(prompt, val)
    }

    const handleSelectValueChange = (val: any) => {
        handleValueChange(val?.value ?? null)
    }

    const handleChange = (val: any) => {
        handleValueChange(val)
        enqueueSnackbar('Done. Submit form to complete', { variant: 'warning' })
    }

    const getComponent = () => {
        if (isAttachment) {
            const attachments = value && typeof value === 'string' ? value.split(',') : []
            const { semanticType } = property

            return (
                <FormAttachmentField
                    title={prompt.title}
                    dataType={semanticType}
                    propertyId={property.id}
                    attachments={attachments}
                    required={prompt.required}
                    editable={prompt.editable && !readOnly}
                    onChange={handleChange}
                />
            )
        } else if (useTadaValues) {
            return (
                <TadaFormSelect
                    key={prompt.id}
                    value={value}
                    field={prompt}
                    multiEdit={multiEdit}
                    multiple={multiple}
                    className={classes.prompt}
                    disabled={isEditingLayout || readOnly}
                    displayValue={displayValue}
                    contextFilters={contextFilters}
                    onEditComplete={handleSelectValueChange}
                />
            )
        } else {
            const { semanticType } = property
            return (
                <FormField
                    key={prompt.id}
                    value={value}
                    field={prompt}
                    onError={onError}
                    multiEdit={multiEdit}
                    multiple={multiple}
                    flexLayout={flexLayout}
                    disabled={isEditingLayout || readOnly}
                    className={classes.prompt}
                    onEditComplete={handleValueChange}
                    dataType={semanticType}
                    textAreaHeight={textAreaHeight}
                />
            )
        }
    }

    return (
        <Box display="flex" flexDirection="column">
            {getComponent()}
        </Box>
    )
}

export default InputFormPrompt

const formatValue = (value: any, multiAdd: boolean) => {
    if (multiAdd) {
        if (Array.isArray(value)) return value
        else return value || value === 0 ? [value] : []
    } else {
        return value
    }
}
