import React, { useContext, useEffect, useRef, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { useSnackbar } from 'notistack'
import { makeStyles } from '@mui/styles'
import { Trash } from 'genesis-suite/icons'
import { SwalContext } from 'genesis-suite/components'
import { Property } from 'genesis-suite/types/networkTypes'
import { AddRounded, ReplyRounded, RemoveRounded } from '@mui/icons-material'
import { Box, Menu, Button, Tooltip, MenuItem, Typography, IconButton } from '@mui/material'
import { FormSection, FormPrompt, PromptId, FieldVisibility } from 'genesis-suite/types/visualTypes'

import PromptBuilder from './PromptBuilder'
import SectionBuilder from './SectionBuilder'
import ExpansionPanel from '../../ExpansionPanel'
import { createSection } from '../../../lib/formUtils'

const useStyles = makeStyles(({ custom, palette, spacing }) => ({
    promptConfigRoot: {
        ...custom.builderContainer,
        flex: 1,
        display: 'flex',
        overflow: 'auto',
        flexFlow: 'column nowrap',
    },
    listRoot: { width: '100%', padding: spacing(1), display: 'flex', flexFlow: 'column', overflow: 'auto' },
    addBtn: { margin: spacing(1), marginLeft: 'auto' },
    closeBtn: {
        width: 12,
        height: 12,
        borderRadius: 4,
        margin: spacing(0.5, 1, 0, -1),
        border: `1px solid ${palette.primary.main}`,
    },
    sectionRoot: {
        display: 'flex',
        flexFlow: 'column',
        marginTop: spacing(1),
    },
    sectionCard: {
        marginBottom: spacing(1),
    },
    sectionHeader: {
        height: 16,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        backgroundColor: palette.primary.dark,
    },
    promptList: { overflow: 'auto' },
    goToText: { alignSelf: 'center', marginLeft: 'auto' },
}))

const getTitleDisplay = (section: FormSection) => {
    return section.title || 'Untitled Section'
}

interface InputFormBuilderProps {
    properties: Property[]
    sections: FormSection[]
    onChange: (sections: FormSection[]) => void
}

export default function InputFormBuilder({ sections, onChange, properties }: InputFormBuilderProps) {
    const classes = useStyles({ multiSections: sections.length > 1 })
    const { confirm } = useContext(SwalContext)
    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const [expandedId, setExpandedId] = useState(sections[0].prompts[0]?.id)

    const ref = useRef(null)
    useEffect(() => {
        ref.current.scrollTop = ref.current.offsetHeight
    }, [sections.length])

    const replaceSections = (replacements: FormSection[]): FormSection[] => {
        return replacements.reduce((acc, replacement) => {
            const index = sections.findIndex(s => s.id === replacement.id)
            acc[index] = replacement
            return acc
        }, sections)
    }

    const addSection = (prompt?: FormPrompt) => {
        const lastIndex = sections.length - 1
        const newSection = createSection([])
        const nextSections = sections
            .map((section, index) => {
                if (index === lastIndex) return { ...section, goTo: newSection.id }
                else return section
            })
            .concat(newSection)
        if (prompt) {
            const fromSection = sections.find(s => s.prompts.some(p => p.id === prompt.id))
            if (fromSection) movePromptToSection(newSection, fromSection, prompt)
            else onChange(nextSections)
        } else {
            onChange(nextSections)
        }
        setExpandedId(newSection.id)
    }

    const movePromptToSection = (to: FormSection, from: FormSection, prompt: FormPrompt) => {
        const toSection = { ...to, prompts: [...to.prompts, prompt] }
        const fromSection = { ...from, prompts: from.prompts.filter(p => p.id !== prompt.id) }
        const nextSections = replaceSections([toSection, fromSection])
        onChange(nextSections)
    }

    const handleSectionChange = section => {
        const index = sections.findIndex(s => s.id === section.id)
        const nextSections = [...sections]
        nextSections.splice(index, 1, section)
        onChange(nextSections)
    }

    const deleteSection = async (section: FormSection, event?: React.MouseEvent<HTMLButtonElement>) => {
        event?.stopPropagation()
        const { prompts } = section
        if (prompts.length > 0) {
            const response = await confirm(
                'Are you sure you want to delete this section? Any non-primary prompts remaining will be deleted!',
                { type: 'warning' }
            )
            if (response.dismiss) return
            const primaryPrompts = prompts.filter(p => p.property && p.property.isPrimary)
            const nextSections = sections.filter(s => s.id !== section.id)
            nextSections[0].prompts = nextSections[0].prompts.concat(primaryPrompts)
            onChange(nextSections)
            if (primaryPrompts) showSnackbar('Primary prompts moved to first section', { variant: 'info' })
        } else {
            onChange(sections.filter(s => s.id !== section.id))
        }
    }

    return (
        <div className={classes.promptConfigRoot}>
            <div ref={ref} className={classes.listRoot}>
                {sections.map((section, i) => {
                    const { id, prompts, goTo } = section
                    const goToSection = sections.find(s => s.id === goTo)
                    const handlePrompts = (prompts: FormPrompt[]) => {
                        const newSection = { ...section, prompts }
                        handleSectionChange(newSection)
                    }

                    const addPrompt = (e: React.MouseEvent<HTMLButtonElement>) => {
                        e.stopPropagation()
                        const id = uuid()
                        handlePrompts([
                            ...prompts,
                            {
                                id,
                                editable: true,
                                required: false,
                                multiAdd: false,
                                useTadaValues: false,
                                dateFormat: 'MM/DD/YYYY',
                                visibility: FieldVisibility.SHOW,
                                title: `Untitled Prompt ${prompts.length}`,
                            },
                        ])
                        setExpandedId(id)
                    }

                    return (
                        <Box key={id} className={classes.sectionRoot}>
                            <div className={classes.sectionHeader} />
                            <ExpansionPanel
                                expanded={id === expandedId}
                                onChange={() => setExpandedId(id)}
                                className={classes.sectionCard}
                                title={
                                    <Box display="flex" flex="1" alignItems="center">
                                        <Typography variant="h6">{getTitleDisplay(section)}</Typography>
                                        <Typography className={classes.goToText} variant="caption">
                                            {sections.length > 1
                                                ? goToSection
                                                    ? `will go to ${getTitleDisplay(goToSection)}`
                                                    : 'will submit the form'
                                                : ''}
                                        </Typography>
                                        <IconButton onClick={addPrompt}>
                                            <Tooltip title="Add Prompt">
                                                <AddRounded />
                                            </Tooltip>
                                        </IconButton>
                                        <IconButton
                                            disabled={sections.length === 1}
                                            onClick={e => deleteSection(section, e)}
                                        >
                                            <Trash />
                                        </IconButton>
                                    </Box>
                                }
                            >
                                <SectionBuilder
                                    section={section}
                                    sections={sections}
                                    onChange={handleSectionChange}
                                    deleteButton={
                                        <IconButton
                                            disabled={sections.length === 1}
                                            onClick={() => deleteSection(section)}
                                        >
                                            <Trash />
                                        </IconButton>
                                    }
                                    closeButton={<CloseButton onClose={() => setExpandedId(null)} />}
                                />
                            </ExpansionPanel>
                            <Box pl={2} pr={2}>
                                {prompts.map(p => {
                                    const otherSections = sections.filter(s => s.id !== id)
                                    const canMove = otherSections.length > 0

                                    const handlePromptChange = (prompt: FormPrompt) => {
                                        const nextPrompts = prompts.reduce((acc, p, i) => {
                                            if (p.id === prompt.id) acc[i] = prompt
                                            return acc
                                        }, prompts)
                                        handlePrompts(nextPrompts)
                                    }

                                    const handlePromptDelete = (id: PromptId) => {
                                        const nextPrompts = prompts.filter(p => p.id !== id)
                                        handlePrompts(nextPrompts)
                                    }

                                    return (
                                        <ExpansionPanel
                                            key={p.id}
                                            title={p.title}
                                            expanded={p.id === expandedId}
                                            onChange={() => setExpandedId(p.id)}
                                        >
                                            <PromptBuilder
                                                prompt={p}
                                                properties={properties}
                                                onChange={handlePromptChange}
                                                onDelete={handlePromptDelete}
                                                closeButton={<CloseButton onClose={() => setExpandedId(null)} />}
                                                moveButton={
                                                    <MovePromptBtn
                                                        canMove={canMove}
                                                        sections={otherSections}
                                                        onMove={toSection => movePromptToSection(toSection, section, p)}
                                                    />
                                                }
                                            />
                                        </ExpansionPanel>
                                    )
                                })}
                            </Box>
                        </Box>
                    )
                })}
            </div>
            <Button color="primary" variant="contained" className={classes.addBtn} onClick={() => addSection()}>
                New Section
            </Button>
        </div>
    )
}

function CloseButton({ onClose }) {
    const classes = useStyles()
    return (
        <IconButton className={classes.closeBtn} onClick={onClose}>
            <RemoveRounded fontSize="small" />
        </IconButton>
    )
}

function MovePromptBtn({ sections, canMove, onMove }) {
    const [anchorEl, setAnchorEl] = useState(null)

    const toggleMenu = (e?: React.MouseEvent<HTMLButtonElement>) => {
        if (anchorEl) setAnchorEl(null)
        else setAnchorEl(e.currentTarget)
    }
    const handleMove = section => {
        onMove(section)
        toggleMenu()
    }

    return (
        <Tooltip title={canMove ? 'Move prompt...' : 'Add section to move prompt'}>
            <Box alignSelf="flex-end">
                <IconButton disabled={!canMove} onClick={toggleMenu}>
                    <ReplyRounded style={{ transform: 'scaleX(-1)' }} />
                </IconButton>
                <Menu keepMounted anchorEl={anchorEl} onClose={toggleMenu} open={Boolean(anchorEl)}>
                    {sections.map((s, i) => {
                        return <MenuItem onClick={() => handleMove(s)}>to {getTitleDisplay(s)}</MenuItem>
                    })}
                </Menu>
            </Box>
        </Tooltip>
    )
}
