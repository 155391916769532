"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const options = [{
  value: 'battery',
  label: 'Battery'
}, {
  value: 'circle',
  label: 'Circle'
}];
var _default = exports.default = options;