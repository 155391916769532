import React from 'react'
import { Box, Container, Link, Typography } from '@mui/material'
import { useSelector } from 'react-redux'

import { buildRoute } from 'genesis-suite/utils'
import BusinessExplorer from '../components/BusinessExplorer/BusinessExplorer'
import { useFeature } from '../lib/featureFlags'
import { routePaths } from '../lib/routes'
import { applicationSelectors, moduleSelectors } from '../selectors'
import { TableStyleChangesProvider } from '../components/widgets2/contexts/TableStyleChangesContext'

export default function BusinessExplorerView() {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const moduleName = useSelector(moduleSelectors.getModuleName)

    const [enable] = useFeature('business_explorer')

    if (!enable)
        return (
            <TableStyleChangesProvider>
                <Container maxWidth="sm">
                    <Box mt={3} display="flex" flexDirection="column" alignItems="center">
                        <Typography variant="h5">Shucks</Typography>
                        <Typography>You do not have access to use this :/</Typography>
                        <Link href={buildRoute(routePaths.HOME, appName, moduleName)}>Home</Link>
                    </Box>
                </Container>
            </TableStyleChangesProvider>
        )

    return (
        <TableStyleChangesProvider>
            <BusinessExplorer />
        </TableStyleChangesProvider>
    )
}
