import { Button, DialogActions, IconButton, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { SwalContext, TextEditor } from 'genesis-suite/components'
import Close from 'genesis-suite/icons/Close'
import { useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import { dialogCreators } from '../../actions/creators'

export const printNotesDialogKey = 'PRINT_NOTES'

const useStyles = makeStyles({
    dialog: {
        width: '800px',
        height: '460px',
        cursor: 'default',
    },
    topBar: {
        height: '10%',
    },
    title: {
        paddingTop: '5px',
        fontSize: '1.25rem',
        fontFamily: 'Nunito, Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 'bold',
        lineHeight: '1.6',
        letterSpacing: '0.3px',
    },
    editorSave: {
        height: '90%',
    },
    preview: {
        position: 'relative',
        width: '100%',
        height: '87%',
    },
    cross: {
        position: 'absolute',
        right: '0',
        top: '0',
    },
})

export default function PrintNotes({ saveNotes, printNote }) {
    const classes = useStyles()
    const { confirm } = useContext(SwalContext)
    const dispatch = useDispatch()
    const [notesText, setNotesText] = useState(printNote)
    const handleChange = value => {
        setNotesText(value)
    }
    const handleCross = async () => {
        if (notesText.localeCompare(printNote) === 0) dispatch(dialogCreators.hideDialog(printNotesDialogKey))
        else {
            const response = await confirm(`Your unsaved changes will be lost, press Ok to confirm`, {
                type: 'warning',
                confirmButtonProps: { text: 'Ok' },
            })
            if (response.dismiss) return
            dispatch(dialogCreators.hideDialog(printNotesDialogKey))
        }
    }

    return (
        <div className={classes.dialog}>
            <div className={classes.topBar}>
                <Typography variant="h6">Print Notes</Typography>
                <span className={classes.cross}>
                    <IconButton onClick={handleCross} size="large">
                        <Close />
                    </IconButton>
                </span>
            </div>
            <div className={classes.editorSave}>
                <div className={classes.preview}>
                    <TextEditor value={notesText} onChange={handleChange} />
                </div>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            saveNotes(notesText)
                        }}
                    >
                        Save
                    </Button>
                </DialogActions>
            </div>
        </div>
    )
}
