import { useState, useEffect, useRef } from 'react'
import classnames from 'classnames'
import {
    useTheme,
    Typography,
    IconButton,
    TextField,
    Button,
    Box,
    MenuItem,
    FormControlLabel,
    Checkbox,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import AddIcon from '@mui/icons-material/Add'

import { NodeFilter } from 'genesis-suite/types/visualTypes'
import { DragList, DragItem } from 'genesis-suite/components/DragList'
import { Menu, Trash } from 'genesis-suite/icons'
import useNodesAndLinks from '../hooks/useNodesAndLinks'

const useStyles = makeStyles(({ palette, spacing, border }) => ({
    closeButton: { padding: '4px' },
    items: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        borderRight: `1px solid ${palette.border?.main}`,
        overflow: 'hidden',
    },
    item: {
        display: 'grid',
        gridTemplateColumns: '18px auto 20px',
        gridColumnGap: '10px',
        alignItems: 'center',
        cursor: 'pointer',
        padding: spacing(),
        background: palette.background.main,
        border: `2px solid ${palette.border?.main}`,
        borderRadius: border.radius.round,
        marginBottom: spacing(1),
    },
    selectedItem: { borderColor: palette.primary.main },
    grabIcon: { cursor: 'grab' },
    inputContainer: {
        '& .MuiInput-root svg': { color: palette.text.primary, '&.Mui-disabled': { color: palette.text.disabled } },
        '& .MuiInput-root::before': { borderColor: palette.text.primary },
        '& .MuiCheckbox-root': { color: palette.text.primary },
        '& .MuiInputLabel-root': { color: palette.text.primary, '&.Mui-disabled': { color: palette.text.disabled } },
    },
}))

interface Props {
    filters: NodeFilter[]
    onChange: (filters: NodeFilter[]) => void
}

/** temporary id for dragging */
type FilterWithId = NodeFilter & { id: number }

export default function EditFilters({ filters, onChange }: Props) {
    const [{ nodes }] = useNodesAndLinks() || [{}]

    const [items, setItems] = useState<FilterWithId[]>([])
    const [selectedItemId, setSelectedItemId] = useState(null)
    const [startedEmpty, setStartedEmpty] = useState(false)
    const [initFilters, setInitFilters] = useState(false)

    const classes = useStyles()
    const theme = useTheme()
    const selectedItem = items.find(i => i.id === selectedItemId)
    const selectedItemNodeProperties = nodes?.find(n => n.Name === selectedItem?.nodeName)?.Attributes

    useEffect(() => {
        setItems(filters?.length ? filters.map((f, id) => ({ ...f, id })) : [{ id: 0, ...emptyFilter }])
        setStartedEmpty(!filters?.length)
        if (selectedItemId == null || selectedItemId > filters?.length - 1) setSelectedItemId(0)
        setInitFilters(true)
    }, [filters])

    const timeout = useRef(null)
    useEffect(() => {
        clearTimeout(timeout.current)
        if (initFilters) return setInitFilters(false)

        const notValid = items.some(f => !f.title || !f.nodeName || !f.keyPropertyName || !f.displayPropertyName)
        if (notValid || (!items.length && startedEmpty)) return

        timeout.current = setTimeout(() => onChange(items.map(({ id, ...f }) => f)), 500)
    }, [items])

    const handleSelectItem = (e, id) => {
        e.stopPropagation()
        setSelectedItemId(id)
    }

    const handleChange = e => {
        const { name, value } = e.target
        if (value === ' ') return

        // auto fill title with property name
        let title = selectedItem.title
        let displayPropertyName = selectedItem.displayPropertyName
        if (name === 'keyPropertyName') {
            if (!title) title = selectedItemNodeProperties.find(p => p.Name === value)?.DisplayName
            if (!displayPropertyName) displayPropertyName = value
        }
        const newItems = items.map(item =>
            item.id === selectedItemId ? { ...item, title, displayPropertyName, [name]: value } : item
        )
        setItems(newItems)
    }

    const handleCheck = e => {
        const { name, checked } = e.target

        const newItems = items.map(item => (item.id === selectedItemId ? { ...item, [name]: checked } : item))
        setItems(newItems)
    }

    const handleAdd = () => {
        const id = items.reduce((acc, { id }) => Math.max(acc, id), 0) + 1
        setItems([...items, { id, ...emptyFilter }])
        setSelectedItemId(id)
    }

    const handleDelete = (e, itemId) => {
        e.preventDefault()
        e.stopPropagation()
        const newItems = items.filter(({ id }) => id !== itemId)
        setItems(newItems)
        setSelectedItemId(newItems?.[0]?.id)
    }

    const handleDragEnd = newItems => {
        setItems(newItems)
    }

    return (
        <Box flex="1" height="100%" display="grid" gridTemplateColumns="250px auto" columnGap="8px" overflow="hidden">
            <div className={classes.items}>
                <Box overflow="scroll" width="100%">
                    <DragList onDragEnd={handleDragEnd} items={items}>
                        {items.map((item, index) => (
                            <DragItem key={item.id} itemId={item.id} index={index}>
                                <div
                                    className={classnames(classes.item, {
                                        [classes.selectedItem]: item.id === selectedItemId,
                                    })}
                                    onClick={e => handleSelectItem(e, item.id)}
                                >
                                    <Menu className={classes.grabIcon} style={{ fontSize: 20 }} />
                                    <Typography>{item.title || 'Filter'}</Typography>
                                    <IconButton
                                        sx={{ color: 'text.primary' }}
                                        size="small"
                                        onClick={e => handleDelete(e, item.id)}
                                    >
                                        <Trash style={{ fontSize: 17 }} />
                                    </IconButton>
                                </div>
                            </DragItem>
                        ))}
                    </DragList>
                </Box>
                <Button onClick={handleAdd}>
                    <AddIcon sx={{ mr: 1 }} htmlColor={theme.palette.text.primary} />
                    Add
                </Button>
            </div>
            <Box className={classes.inputContainer}>
                <TextField
                    fullWidth
                    select
                    margin="dense"
                    label="Node"
                    name="nodeName"
                    value={selectedItem?.nodeName || ''}
                    onChange={handleChange}
                >
                    {nodes?.map(n => (
                        <MenuItem key={n.Name} value={n.Name}>
                            {n.DisplayName}
                        </MenuItem>
                    )) ?? <p>no props</p>}
                </TextField>
                <TextField
                    fullWidth
                    select
                    margin="dense"
                    label="Key property"
                    name="keyPropertyName"
                    disabled={!selectedItemNodeProperties}
                    value={selectedItem?.keyPropertyName || ''}
                    onChange={handleChange}
                >
                    {selectedItemNodeProperties?.map(n => (
                        <MenuItem key={n.Name} value={n.Name}>
                            {n.DisplayName}
                        </MenuItem>
                    )) ?? <p>no props</p>}
                </TextField>
                <TextField
                    fullWidth
                    select
                    margin="dense"
                    label="Display property"
                    name="displayPropertyName"
                    disabled={!selectedItemNodeProperties}
                    value={selectedItem?.displayPropertyName || ''}
                    onChange={handleChange}
                >
                    {selectedItemNodeProperties?.map(n => (
                        <MenuItem key={n.Name} value={n.Name}>
                            {n.DisplayName}
                        </MenuItem>
                    )) ?? <p>no props</p>}
                </TextField>
                <TextField
                    fullWidth
                    margin="dense"
                    label="Title"
                    name="title"
                    value={selectedItem?.title || ''}
                    onChange={handleChange}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={selectedItem?.isMulti ?? false}
                            onChange={handleCheck}
                            name="isMulti"
                            color="primary"
                        />
                    }
                    label="Multi select"
                />
            </Box>
        </Box>
    )
}

const emptyFilter: NodeFilter = {
    title: '',
    nodeName: '',
    keyPropertyName: '',
    displayPropertyName: '',
    isMulti: false,
    type: 'values',
}
