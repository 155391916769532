import { CloseRounded } from '@mui/icons-material'
import { Skeleton } from '@mui/lab'
import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Spinner } from 'genesis-suite/components'
import { Criteria, ResourceType, Scenario } from 'genesis-suite/types/networkTypes'
import { useSnackbar } from 'notistack'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { scenarioService } from '../../lib/services'
import { applicationSelectors, linksSelectors } from '../../selectors'
import CriteriaEditor from './CriteriaEditor'

const useStyles = makeStyles(theme => ({
    overflowParent: { display: 'flex', flexDirection: 'column', overflow: 'hidden' },
    icon: { color: theme.palette.text.primary },
    // label: { color: 'red' },
}))

interface Props {
    scenario: Scenario
    onClose: () => void
    onDone: (updated: Scenario) => void
}

export function EditScenario({ scenario: initScenario, onClose, onDone }: Props) {
    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const [scenario, setScenario] = useState(initScenario)
    const { enqueueSnackbar: showSnackbar } = useSnackbar()

    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const { nodes, links, isPending } = useSelector(linksSelectors.getLinksAndNodes)
    const options = useMemo(
        () => nodes.map(n => ({ ...n, type: 'Nodes' })).concat(links.map(l => ({ ...l, type: 'Links' }))),
        [nodes, links]
    )

    const handleDone = async () => {
        setLoading(true)
        try {
            await scenarioService.saveScenario(appName, scenario)
            onDone(scenario)
        } catch (err) {
            setLoading(false)
            console.error(err)
            showSnackbar('Failed to save', { variant: 'error', preventDuplicate: true })
        }
    }
    const handleScenarioChange = (change: Partial<Scenario>) => {
        setScenario({ ...scenario, ...change })
    }
    const handleAddCriteria = (criteria: Criteria) => {
        const criterias = scenario.criterias ? [...scenario.criterias, criteria] : [criteria]
        handleScenarioChange({ criterias })
    }
    const handleRemoveCriteria = (criteria: Criteria) => {
        const criterias = scenario.criterias.filter(c => c.id !== criteria.id)
        handleScenarioChange({ criterias })
    }

    if (!scenario) return null
    const { criterias, name, resourceId } = scenario
    return (
        <Spinner hideCover className={classes.overflowParent} show={loading}>
            <Dialog PaperProps={{ sx: { width: 600, height: 600, overflow: 'hidden' } }} open={true} onClose={onClose}>
                <DialogTitle>
                    {scenario.id ? 'Edit Scenario' : 'Create Scenario'}
                    <IconButton
                        className={classes.icon}
                        aria-label="close"
                        sx={{ position: 'absolute', right: 8, top: 8 }}
                        onClick={onClose}
                    >
                        <CloseRounded />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        <TextField
                            sx={{
                                width: '50%',
                                '.MuiInputBase-root:before': {
                                    borderBottomColor: 'text.primary',
                                },
                                '& .MuiFormLabel-root': {
                                    color: 'text.primary',
                                },
                            }}
                            name="name"
                            value={name}
                            label="Scenario Name"
                            onChange={e => handleScenarioChange({ name: e.target.value })}
                        />
                        {isPending ? (
                            <Skeleton height={43} width={275} />
                        ) : (
                            <Autocomplete
                                sx={{
                                    width: '50%',
                                }}
                                disableClearable
                                options={options}
                                groupBy={option => option.type}
                                getOptionLabel={option => option.name}
                                value={options.find(o => o.id === resourceId)}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderInput={params => (
                                    <TextField
                                        label="Resource"
                                        sx={theme => ({
                                            '.MuiInputBase-root:before': {
                                                borderBottomColor: theme.palette.text.primary,
                                            },
                                            '& .MuiFormLabel-root': {
                                                color: theme.palette.text.primary,
                                            },

                                            '.MuiSvgIcon-root ': {
                                                fill: theme.palette.text.primary,
                                            },
                                        })}
                                        {...params}
                                    />
                                )}
                                renderGroup={params => (
                                    <Box key={params.key}>
                                        <Typography>{params.group}</Typography>
                                        {params.children}
                                    </Box>
                                )}
                                onChange={(_, value) => {
                                    handleScenarioChange({
                                        resourceId: value.id,
                                        resourceName: value.name,
                                        resourceType: value.type === 'Links' ? ResourceType.LINK : ResourceType.NODE,
                                    })
                                }}
                            />
                        )}
                    </Box>
                    <Box>
                        <CriteriaEditor
                            scenario={scenario}
                            onAdd={handleAddCriteria}
                            onEdit={criterias => handleScenarioChange({ criterias })}
                            onDelete={handleRemoveCriteria}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" variant="contained" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        sx={{
                            ml: 1,
                            '&.MuiButton-contained.Mui-disabled': {
                                color: 'white', // Change font color to white when button is disabled
                            },
                        }}
                        onClick={handleDone}
                        disabled={!criterias || criterias?.length === 0}
                    >
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
        </Spinner>
    )
}
