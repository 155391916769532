import React, { useContext } from 'react'
import produce from 'immer'

import { PropertyType } from 'genesis-suite/types/architectureTypes'
import { DataSource, Basket, Aggregation } from 'genesis-suite/types/visualTypes'
import { letMeMap } from 'genesis-suite/types/utilTypes'
import { ConfigContext } from '../ConfigContext'
import DnDPropertySelector from './DnDPropertySelector'
import { getDataFieldProperty, getDefaultAggregation, getSeriesColor } from '../utils'
import { DragField, FieldPointer, ServiceWithColor } from '../builderTypes'
import useWidgetColors from '../../../hooks/useWidgetColors'
import { useSemanticTypeById } from '../../../hooks/useSemanticTypes'

interface Props {
    basket?: Basket
    label?: string
    limit?: number
}

interface SourceWithPointer extends DataSource {
    pointer: FieldPointer
    service: ServiceWithColor
}

/** for charts w/ multiple series (combo) */
export default function SeriesSelector({ basket, label, limit }: Props) {
    const semanticTypeById = useSemanticTypeById()
    const { dataResponse, dispatch, selectedField, resources, config } = useContext(ConfigContext)
    const { series } = config
    const colors = useWidgetColors()

    const seriesValues = letMeMap(series).reduce((acc, seriesObj, index) => {
        const subSeries = dataResponse?.data?.[index]?.data[0]?.children?.[0].group // first subseries by default
        acc.push({
            ...seriesObj.values[0],
            service: { ...seriesObj.service, color: getSeriesColor(series, index, colors) },
            pointer: { type: 'series', index, valueIndex: 0, subSeries },
        })

        return acc
    }, [] as SourceWithPointer[])

    const flatSeries = seriesValues.map((v, i) => {
        const { properties } = resources.byId[v.service.id] ?? {}
        const { displayName, semanticType } = getDataFieldProperty(v.field, properties)

        return {
            ...v,
            type: semanticType?.type,
            title: displayName,
            selected: selectedField?.type === 'series' && selectedField?.index === i,
            color: v.service.color,
        }
    })

    const handleAdd = (dragField: DragField, _index?: number) => {
        const index = _index !== undefined ? _index : series.length
        const { pointer, type, ...field } = dragField
        const to: FieldPointer = { type: 'series', index }
        const { properties } = resources?.byId[resources.selectedId] ?? {}
        const { semanticType, hasAggregates } = getDataFieldProperty(field, properties)
        const aggregation = getDefaultAggregation(semanticType, hasAggregates)
        const source = { field, aggregation, basket }

        const options = pointer ? { operation: 'move' as const, from: pointer } : { operation: 'new' as const, source }

        dispatch({ type: 'UPDATE_SELECTED_PROPERTY', payload: { to, limit, ...options, semanticTypeById } })
    }

    const handleRemove = index => {
        dispatch({ type: 'SET_SELECTED_FIELD', payload: null })

        const newConfig = produce(config, draft => {
            draft.series.splice(index, 1)
        })
        dispatch({ type: 'UPDATE_CONFIG', payload: newConfig })
    }

    function handleFieldSelect(i) {
        dispatch({ type: 'SET_SELECTED_FIELD', payload: flatSeries[i].pointer })
    }

    const handleAggregationChange = (index: number, value: Aggregation) => {
        const payload = produce(config.series[index], draft => {
            draft.values[0].aggregation = value
        })
        dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload })
    }

    return (
        <DnDPropertySelector
            label={label}
            accept={[PropertyType.DEFINING, PropertyType.QUALITATIVE, PropertyType.QUANTITATIVE]}
            limit={limit}
            properties={flatSeries}
            onFieldSelect={handleFieldSelect}
            onAggregationChange={handleAggregationChange}
            onAdd={handleAdd}
            onRemove={handleRemove}
        />
    )
}
