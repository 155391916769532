import { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@mui/material'
import { CloseRounded } from '@mui/icons-material'
import ReorderTabsDialogContent from './ReorderTabsDialogContent'

export default function ReorderTabsDialog({ tabRows, setTabRows, reOrder, onClose }) {
    const [layout, setLayout] = useState([])

    const onSubmit = () => {
        const data = [...tabRows]
        data.map(item => {
            layout.map(p => {
                if (p.i === item.name.replace(/\s/g, '')) {
                    item.Order = p.y
                }
            })
            return item
        })
        setTabRows(data)
        onClose()
    }
    return (
        <Dialog open={reOrder} fullWidth onClose={onClose}>
            <DialogTitle>
                Order Tabs
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        color: 'inherit',
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseRounded />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <ReorderTabsDialogContent layout={layout} setLayout={setLayout} tabRows={tabRows} />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={onSubmit}>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}
