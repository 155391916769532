import { ChartType, TableConfig, Widget } from 'genesis-suite/types/visualTypes'
import isSeriesWidget from './isSeriesWidget'

export default function skipData(config: Widget) {
    if (config.type === ChartType.TABLE) {
        const tableConfig = config as TableConfig
        return tableConfig.dynamic || tableConfig.collapsable
    }
    if (config.type === ChartType.FORM && config.loadInitialData) return false
    if (isSeriesWidget(config.type)) return false

    return true
}
