import Cancel from '@mui/icons-material/Cancel'
import Done from '@mui/icons-material/Done'
import StarIcon from '@mui/icons-material/StarBorderRounded'
import FilledStarIcon from '@mui/icons-material/StarRounded'
import {
    Box,
    IconButton,
    ListItem,
    MenuItem,
    MenuList,
    Popover,
    Skeleton,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classnames from 'classnames'
import { uniq } from 'lodash'
import { useSnackbar } from 'notistack'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import tinyColor from 'tinycolor2'

import { ChopText } from 'genesis-suite/components'
import { PlusCircle } from 'genesis-suite/icons'
import { applicationCreators, authCreators, navigationCreators } from '../actions/creators'
import useModules from '../hooks/useModules'
import { useFeature } from '../lib/featureFlags'
import { userService, visualService } from '../lib/services'
import { snackbarConfigs } from '../lib/snackbarConfigs'
import { createSlug } from '../lib/utils'
import { applicationSelectors, authSelectors, deploymentSelectors, moduleSelectors } from '../selectors'
import LetterOption from './LetterOption'

const useStyles = makeStyles(({ palette, spacing }) => ({
    moduleList: {
        minWidth: 200,
        paddingTop: 0,
    },
    option: {
        alignSelf: 'flex-start',
    },
    icon: {
        marginLeft: 'auto',
        cursor: 'pointer',
    },
    active: {
        backgroundColor: `${tinyColor(palette.background.topBar).setAlpha(0.1)} !important`,
        cursor: 'default',
    },
    moduleErrorPopper: {
        margin: spacing(),
        padding: spacing(2),
        background: palette.primary.main,
        color: palette.primary.contrastText,
    },
    letterWrapper: {
        width: '90%',
        display: 'flex',
        marginRight: '15px',
        minWidth: '90%',
        alignItems: 'center',
    },
    createWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: spacing(2),
    },
    createAppList: {
        border: `1px dashed ${palette.border?.main}`,
        marginTop: spacing(1),
        height: '45px',
        borderRadius: '5px',
        justifyContent: 'center',
        marginLeft: spacing(2),
        maxWidth: '180px',
        '&:hover': { backgroundColor: palette.background.secondary },
    },
    createText: {
        color: palette.text.primary,
        margin: spacing(1),
    },
    MuiIconButton: {
        color: palette.text.primary,
        '&:hover': {
            backgroundColor: 'inherit',
        },
    },
    groupTitle: {
        color: 'grey',
        fontWeight: 'bold',
        fontSize: 'smaller',
    },
}))

export default function ModuleController({ appName, onClose, currentApplication, onExpand, onModules }) {
    const [builderEnabled] = useFeature('builder')
    const user = useSelector(authSelectors.getUser)
    const isAdmin = useSelector(authSelectors.getIsAdmin)

    const [defaultModule, setDefaultModule] = useState('')
    const [modules, updateModules] = useModules(appName)

    const classes = useStyles()
    const showNew = builderEnabled && isAdmin

    const groupTitles = useMemo(() => {
        if (!modules) return []

        const titles = modules.reduce(
            (acc, cur) => (cur.modules?.length ? [...acc, ...cur.modules.map(n => n.Name)] : acc),
            []
        )
        return uniq(titles).sort()
    }, [modules])
    const ungroupedModules = modules?.filter(m => !m.modules?.length || (m.version === '2' && m.active === true)).sort()

    useEffect(() => {
        async function updateDefaultModule(appName) {
            const appPrefs = await userService.getUserPreferences(appName)
            if (appPrefs?.length) setDefaultModule(appPrefs.find(pref => pref.Key === 'defaultModule')?.Value)
        }
        onModules(modules)
        if (appName !== currentApplication) updateDefaultModule(appName)
    }, [modules, appName])

    if (!modules)
        return (
            <div>
                <Skeleton variant="text" count={2} />
            </div>
        )

    return (
        <div>
            <MenuList className={classes.moduleList}>
                {ungroupedModules?.map(appModule => (
                    <App
                        key={appModule.name}
                        appModule={appModule}
                        onClose={onClose}
                        appName={appName}
                        isAdmin={isAdmin}
                        expandedDefaultModule={defaultModule}
                        onExpand={onExpand}
                    ></App>
                ))}
                {groupTitles &&
                    groupTitles.map(title => (
                        <div key={title}>
                            <ChopText showEllipsis className={classes.groupTitle}>
                                {title?.toUpperCase()}
                            </ChopText>
                            <Modules
                                title={title}
                                onClose={onClose}
                                appName={appName}
                                isAdmin={isAdmin}
                                modules={modules}
                                expandedDefaultModule={defaultModule}
                                onExpand={onExpand}
                            ></Modules>
                        </div>
                    ))}
                {showNew && (
                    <ListItem className={classes.createAppList}>
                        <NewModuleButton
                            classes={classes}
                            moduleNames={modules?.map(m => m.name)}
                            onNew={onClose}
                            updateModules={updateModules}
                            appName={appName}
                            currentApplication={currentApplication}
                        />
                    </ListItem>
                )}
            </MenuList>
        </div>
    )
}

function Modules({ title, onClose, moduleTitle, appName, modules, expandedDefaultModule, onExpand }) {
    const groups = modules.filter(m => m.modules?.find(n => n.Name === title))
    return groups?.map(appModule => (
        <App
            key={appModule.name}
            appModule={appModule}
            onClose={onClose}
            moduleTitle={moduleTitle}
            appName={appName}
            expandedDefaultModule={expandedDefaultModule}
            onExpand={onExpand}
        />
    ))
}

function App({ appModule, onClose, appName, expandedDefaultModule, onExpand }) {
    const classes = useStyles()
    const theme = useTheme()
    const dispatch = useDispatch()
    const currAppName = useSelector(applicationSelectors.getCurrentAppName)
    const moduleTitle = useSelector(moduleSelectors.getModuleTitle)
    const defaultModule = useSelector(authSelectors.getDefaultModule)
    const { enqueueSnackbar: showSnackbar } = useSnackbar()

    const isSelected = appModule.title === moduleTitle && currAppName === appName
    return (
        <>
            <MenuItem
                className={classnames(classes.option, {
                    [classes.active]: isSelected,
                })}
            >
                <LetterOption
                    label={appModule.title}
                    className={classes.letterWrapper}
                    background={appModule.theme?.custom?.accent1.main ?? theme.palette.tada.purple}
                    text={appModule.theme?.custom?.accent1.contrastText ?? 'white'}
                    onClick={
                        isSelected
                            ? null
                            : () => {
                                  dispatch(applicationCreators.selectApplication(appName))
                                      .then(() => {
                                          dispatch(navigationCreators.goToModule(appModule.name))
                                          onClose()
                                          onExpand()
                                      })
                                      .catch(error => {
                                          // Handle the error
                                      })
                              }
                    }
                />
                {defaultModule === appModule.name || expandedDefaultModule === appModule.name ? (
                    <FilledStarIcon
                        htmlColor="#FFD700"
                        className={classes.icon}
                        onClick={() => {
                            dispatch(authCreators.updateDefaultModule()).catch(() => {
                                showSnackbar(
                                    'An error occurred removing default. Please try again.',
                                    snackbarConfigs.error
                                )
                            })
                            onClose()
                        }}
                    />
                ) : (
                    <Tooltip title="Set Default">
                        <StarIcon
                            className={classes.icon}
                            htmlColor={theme.palette.grayscale.light}
                            onClick={() => {
                                dispatch(authCreators.updateDefaultModule(appModule.name)).catch(() => {
                                    showSnackbar(
                                        'An error occurred saving your Default Module. Please try again.',
                                        snackbarConfigs.error
                                    )
                                })
                                onClose()
                            }}
                        />
                    </Tooltip>
                )}
            </MenuItem>
        </>
    )
}

function NewModuleButton({ classes, moduleNames, onNew, appName, updateModules }) {
    const dispatch = useDispatch()
    const viewFlag = useSelector(deploymentSelectors.getDeploymentViewFlag)

    const [open, setOpen] = useState(false)
    const [title, setTitle] = useState('')
    const buttonRef = useRef(null)

    async function handleSave() {
        if (!title) return

        const name = createSlug(title, moduleNames)
        const appModule = await visualService.createModule(appName, { active: true, name, title }, viewFlag)
        updateModules()
        dispatch(applicationCreators.selectApplication(appName))
            .then(() => {
                dispatch(navigationCreators.goToModule(appModule.name))
                handleDone()
                onNew()
            })
            .catch(error => {
                // Handle the error
            })
    }

    function handleDone() {
        setTitle('')
        setOpen(false)
    }

    return (
        <>
            <Tooltip title="New app">
                <div className={classes.createWrapper}>
                    <IconButton
                        className={classes.MuiIconButton}
                        size="small"
                        ref={buttonRef}
                        onClick={() => setOpen(true)}
                    >
                        <PlusCircle /> <Typography className={classes.createText}>Create App</Typography>
                    </IconButton>
                </div>
            </Tooltip>
            <Popover
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={open}
                onClose={handleDone}
                anchorEl={buttonRef.current}
            >
                <Box display="flex" alignItems="center" p={2}>
                    <TextField
                        placeholder="Application Title"
                        className={classes.input}
                        autoFocus
                        fullWidth
                        value={title}
                        onFocus={e => e.target.select()}
                        onChange={e => setTitle(e.target.value)}
                        onKeyDown={e => {
                            e.stopPropagation()
                            e.key === 'Enter' && handleSave()
                        }}
                    />
                    <IconButton size="small" className={classes.doneButton} onClick={handleSave}>
                        <Done fontSize="small" />
                    </IconButton>
                    <IconButton size="small" onClick={handleDone}>
                        <Cancel fontSize="small" />
                    </IconButton>
                </Box>
            </Popover>
        </>
    )
}
