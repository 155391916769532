import { useContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Button, Popover, Typography } from '@mui/material'
import DropDownIcon from '@mui/icons-material/ArrowDropDownRounded'

import { DateOrTime } from 'genesis-suite/components'
import { userDisplayName } from '../../lib/manageUtils'
import { visualService } from '../../lib/services'
import { applicationSelectors, deploymentSelectors } from '../../selectors'
import { PublishContext } from '../contexts/PublishContext'
import PublishDetails from './PublishDetails'
import PublishTable from './PublishTable'

export default function PublishSideNav() {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const viewFlag = useSelector(deploymentSelectors.getDeploymentViewFlag)
    const { publish, setPublish } = useContext(PublishContext)
    const [openPublishes, setOpenPublishes] = useState(false)
    const publishButtonRef = useRef()

    useEffect(() => {
        if (publish) return

        visualService
            .getPublished(appName, { sortBy: { createdAt: 'desc' }, pageSize: 1 }, viewFlag)
            .then(res => setPublish(res.data?.[0] ?? null))
    }, [publish, viewFlag])

    if (publish === undefined) return null

    if (publish === null)
        return (
            <Typography textAlign="center" sx={{ mt: 2 }}>
                No publishes
            </Typography>
        )

    return (
        <Box display="flex" flexDirection="column" overflow="hidden">
            <Typography variant="caption" gutterBottom sx={{ pl: 2, pt: 2 }}>
                Publish:
            </Typography>

            <Button ref={publishButtonRef} variant="outlined" onClick={() => setOpenPublishes(true)} sx={{ mx: 2 }}>
                <Box flex={1} display="flex" alignItems="center" justifyContent="space-between">
                    <Box>
                        <Box display="flex" gap={1}>
                            <DateOrTime>{publish.createdAt}</DateOrTime>
                            <Typography>by {userDisplayName(publish.createdBy)}</Typography>
                        </Box>
                        <Typography textAlign="start">{`${publish.counts.all.total} total changes`}</Typography>
                    </Box>

                    <DropDownIcon />
                </Box>
            </Button>
            <Popover
                anchorEl={publishButtonRef.current}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                onClose={() => setOpenPublishes(false)}
                open={openPublishes}
                PaperProps={{ sx: { height: '300px', width: '450px' } }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                <PublishTable onDone={() => setOpenPublishes(false)} />
            </Popover>

            <Typography variant="caption" gutterBottom sx={{ pl: 2, pt: 2 }}>
                Published changes:
            </Typography>
            <Box overflow="auto">
                <PublishDetails key={publish.id} />
            </Box>
        </Box>
    )
}
