import produce from 'immer'
import { useContext } from 'react'

import { PropertyType } from 'genesis-suite/types/architectureTypes'
import { letMeMap } from 'genesis-suite/types/utilTypes'
import { Aggregation, ChartType, TableConfig } from 'genesis-suite/types/visualTypes'
import { useSemanticTypeById } from '../../../hooks/useSemanticTypes'
import useWidgetColors from '../../../hooks/useWidgetColors'
import { DisplayProperty, DragField, FieldPointer } from '../builderTypes'
import { ConfigContext } from '../ConfigContext'
import { getAllProperties, getDataFieldProperty } from '../utils'
import DnDPropertySelector from './DnDPropertySelector'

interface Props {
    label?: string
    numericOnly?: boolean
    limit?: number
}

const CategorySelector = ({ label, numericOnly, limit }: Props) => {
    const semanticTypeById = useSemanticTypeById()
    const { config, dispatch, selectedField, resources } = useContext(ConfigContext)
    const { categories } = config
    const colors = useWidgetColors()

    const { trellis } = config
    const correctedCategories = trellis?.enabled ? categories.slice(1) : categories

    const formattedCategories: DisplayProperty[] = letMeMap(correctedCategories).map((value, index) => {
        const correctedIndex = trellis?.enabled ? index + 1 : index

        const properties = getAllProperties(resources.byId)
        const { displayName, semanticType } = getDataFieldProperty(value.field, properties)

        return {
            type: semanticType?.type,
            title: displayName,
            field: value.field,
            aggregation: Aggregation.NONE,
            selected: selectedField?.type === 'category' && selectedField?.index === correctedIndex,
            pointer: { type: 'category', index: correctedIndex },
            color: colors[0],
        }
    })

    const handleAdd = (dragField: DragField, _index?: number) => {
        let index
        if (trellis?.enabled) index = 1
        else index = _index ?? (limit === undefined ? categories.length : Math.min(categories.length, limit - 1))

        const { pointer, type, ...field } = dragField
        const to: FieldPointer = { type: 'category', index }
        const source = { field, aggregation: Aggregation.NONE }
        const options = pointer
            ? { operation: limit === 1 && categories.length ? ('swap' as const) : ('move' as const), from: pointer }
            : { operation: 'new' as const, source }

        dispatch({ type: 'UPDATE_SELECTED_PROPERTY', payload: { to, limit, ...options, semanticTypeById } })
    }

    const handleRemove = index => {
        const updatedCategories = produce(categories, draft => {
            draft.splice(index, trellis?.enabled ? categories.length : 1)
        })

        if (updatedCategories.length <= 1 && config.type === ChartType.TABLE) {
            let payload: Partial<TableConfig> = { categories: updatedCategories }

            //if there's no categories, remove the pivot
            if (updatedCategories.length === 0 && config.series[0].subSeries)
                payload = { ...payload, series: [{ ...config.series[0], subSeries: undefined }] }

            //if there's one or less categories, disable collapsable
            if ((config as TableConfig).collapsable) payload = { ...payload, collapsable: false }

            return dispatch({ type: 'UPDATE_CONFIG', payload })
        }

        dispatch({
            type: 'UPDATE_CATEGORIES',
            payload: updatedCategories,
        })

        dispatch({ type: 'SET_SELECTED_FIELD', payload: null })
        if (trellis?.enabled) dispatch({ type: 'UPDATE_TRELLIS', payload: { enabled: false } })
    }

    return (
        <DnDPropertySelector
            label={label}
            accept={[PropertyType.DEFINING, PropertyType.QUALITATIVE]}
            limit={limit}
            onAdd={handleAdd}
            onRemove={handleRemove}
            properties={formattedCategories}
            onFieldSelect={index =>
                dispatch({
                    type: 'SET_SELECTED_FIELD',
                    payload: { type: 'category', index: trellis?.enabled ? index + 1 : index },
                })
            }
        />
    )
}

export default CategorySelector
