import React, { useState, useEffect, useRef } from 'react'
import produce from 'immer'
import makeStyles from '@mui/styles/makeStyles'
import AddIcon from '@mui/icons-material/AddRounded'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import { Box, Button, IconButton, Slider, TextField } from '@mui/material'

import { ColorGradient } from 'genesis-suite/types/visualTypes'
import WidgetColorPicker from './WidgetColorPicker'
import { getDefaultColorGradient } from '../../widgets2/utils/configDefaults'

const maxColors = 5

const useStyles = makeStyles(({ palette }) => {
    const getBorder = i => p => p.selectedIndex === i ? `2px solid ${palette.primary.main}` : ''
    return {
        slider: {
            color: palette.grayscale.dark,
            ['& .MuiSlider-thumb[data-index="0"]']: { color: (p: any) => p.colors[0], border: getBorder(0) },
            ['& .MuiSlider-thumb[data-index="1"]']: { color: (p: any) => p.colors[1], border: getBorder(1) },
            ['& .MuiSlider-thumb[data-index="2"]']: { color: (p: any) => p.colors[2], border: getBorder(2) },
            ['& .MuiSlider-thumb[data-index="3"]']: { color: (p: any) => p.colors[3], border: getBorder(3) },
            ['& .MuiSlider-thumb[data-index="4"]']: { color: (p: any) => p.colors[4], border: getBorder(4) },
        },
        activeThumb: { zIndex: 1 },
    }
})

interface Props {
    colorGradient: ColorGradient
    onChange: (colorGradient: ColorGradient) => void
}

export default function ColorGradientEditor({ colorGradient, onChange }: Props) {
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [gradient, setGradient] = useState(() => init(colorGradient))
    const [dragging, setDragging] = useState(false)

    const positions = gradient.map(g => g.position)
    const colors = gradient.map(g => g.color)
    const classes = useStyles({ colors, selectedIndex })

    useEffect(() => {
        setGradient(init(colorGradient))
    }, [colorGradient])

    // logic to prevent changing position when just clicking slider
    const draggingDebounce = useRef(false)
    const draggingTimeout = useRef(null)
    useEffect(() => {
        clearTimeout(draggingTimeout.current)
        draggingDebounce.current = false
        if (dragging) draggingTimeout.current = setTimeout(() => (draggingDebounce.current = true), 150)
    }, [dragging])

    async function handleSliderChange(event, positions, activeThumb) {
        const index = activeThumb
        if (!dragging) setDragging(true)

        if (index !== selectedIndex) {
            setSelectedIndex(index)
            if (!dragging) return

            // swap thumb positions
            setGradient(
                produce(gradient, draft => {
                    draft[selectedIndex] = gradient[index]
                    draft[index] = gradient[selectedIndex]
                })
            )
        } else {
            if (!draggingDebounce.current) return

            setGradient(
                produce(gradient, draft => {
                    draft[index].position = positions[index]
                })
            )
        }
    }

    function handleSliderChangeCommitted(e, positions) {
        setDragging(false)
        if (!draggingDebounce.current) return
        const indexString = e.target.getAttribute('data-index')
        if (!indexString) return

        const index = parseInt(indexString)
        onChange(
            produce(gradient, draft => {
                draft[index].position = positions[index]
            }).map(toPercent)
        )
    }

    function handleColorChange({ hex }) {
        onChange(
            produce(gradient, draft => {
                draft[selectedIndex].color = hex
            }).map(toPercent)
        )
    }

    function handleChangePosition(e) {
        const position = parseInt(e.target.value)
        onChange(
            produce(gradient, draft => {
                draft[selectedIndex].position = position
            }).map(toPercent)
        )
    }

    function handleAdd() {
        const [first, second] = gradient
        const position = (first.position + second.position) / 2
        const color = getMiddleColor(first.color, second.color)
        setSelectedIndex(1)
        onChange(
            produce(gradient, draft => {
                draft.splice(1, 0, { position, color })
            }).map(toPercent)
        )
    }

    function handleDelete() {
        if (selectedIndex > 0) setSelectedIndex(s => s - 1)
        onChange(
            produce(gradient, draft => {
                draft.splice(selectedIndex, 1)
            }).map(toPercent)
        )
    }

    return (
        <div>
            <Box pl={1} pr={2}>
                <Slider
                    track={false}
                    marks={marks}
                    classes={{ root: classes.slider, active: classes.activeThumb }}
                    value={positions}
                    onChange={handleSliderChange}
                    onChangeCommitted={handleSliderChangeCommitted}
                />
            </Box>

            <Box display="flex" alignItems="center" justifyContent="space-between" columnGap="8px" my={1}>
                <TextField
                    label="Position"
                    size="small"
                    variant="outlined"
                    type="number"
                    inputProps={{ min: '0', max: '100' }}
                    value={gradient[selectedIndex].position}
                    onChange={handleChangePosition}
                />
                <Box height="37px" width="86px">
                    <WidgetColorPicker value={gradient[selectedIndex].color} onChange={handleColorChange} />
                </Box>
                <IconButton size="small" disabled={gradient.length < 3} onClick={handleDelete}>
                    <DeleteIcon />
                </IconButton>
            </Box>

            <Button
                size="small"
                variant="outlined"
                startIcon={<AddIcon />}
                disabled={gradient.length >= maxColors}
                onClick={handleAdd}
            >
                Add point
            </Button>
        </div>
    )
}

const marks = [
    { value: 0, label: '0%' },
    { value: 100, label: '100%' },
]

const init = (gradient: ColorGradient) =>
    (gradient || getDefaultColorGradient()).map(g => ({ ...g, position: Math.floor(g.position * 100) }))

const toPercent = (g: ColorGradient[0]) => ({ position: parseFloat((g.position / 100).toFixed(2)), color: g.color })

function getMiddleColor(color1: string, color2: string): string {
    const ratio = 0.5

    function hex(x) {
        x = x.toString(16)
        return x.length === 1 ? '0' + x : x
    }

    const getBinaryChunk = (color, start, end) => parseInt(color.substring(start, end), 16)

    const r = Math.ceil(getBinaryChunk(color1, 1, 3) * ratio + getBinaryChunk(color2, 1, 3) * (1 - ratio))
    const g = Math.ceil(getBinaryChunk(color1, 3, 5) * ratio + getBinaryChunk(color2, 3, 5) * (1 - ratio))
    const b = Math.ceil(getBinaryChunk(color1, 5, 7) * ratio + getBinaryChunk(color2, 5, 7) * (1 - ratio))

    return '#' + hex(r) + hex(g) + hex(b)
}
