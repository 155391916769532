import { ResourceType } from 'genesis-suite/types/networkTypes'
import { Category, ChartType, DataSource, SortedConfig, SortedSeries } from 'genesis-suite/types/visualTypes'
import { makeField, stringToFilters } from 'genesis-suite/utils'
import { WidgetConverterProps } from '../migrationTypes'
import {
    convertBaseFormat,
    convertTooltip,
    getPropertyFromResource,
    makeAggregation,
    makeNavigation,
} from '../widgetConverterUtils'

export default async function sortedConverter({
    config,
    dashboardIdByOldId,
    resourceManager,
}: WidgetConverterProps): Promise<SortedConfig> {
    const { Title, ChartConfig, NoDataMessage } = config
    const {
        Source,
        XAxisField,
        ValueField,
        CrumbMetaName,
        DefaultPerspective,
        SortAxis,
        ValueName,
        DisplayCount,
        AggregationType,
        ShowLabels,
        ShowPercentage,
        ShowCategoryLabels,
        BaseFormat,
    } = ChartConfig

    const insight = await resourceManager.getInsight(Source.ElementName)

    const category: Category = {
        field: makeField(insight.properties, XAxisField),
        navigation: makeNavigation(CrumbMetaName, DefaultPerspective, dashboardIdByOldId),
        ...(SortAxis && { sort: 'descending' }),
    }

    const value: DataSource = {
        field: makeField(insight.properties, ValueField),
        aggregation: makeAggregation(getPropertyFromResource(insight, ValueField), AggregationType),
    }

    const singleSeries: SortedSeries = {
        type: ChartConfig.ChartType === 'Funnel' ? 'funnel' : 'pyramid',
        title: ValueName,
        service: { type: ResourceType.INSIGHT, name: insight.name, id: insight.id },
        values: [value],
        tooltip: convertTooltip(insight, ChartConfig),
        ...(DisplayCount && { limit: DisplayCount }),
        ...(ShowLabels && {
            dataLabels: {
                value: true,
                format: { base: convertBaseFormat(BaseFormat) },
                percent: ShowPercentage,
                categories: ShowCategoryLabels,
            },
        }),
    }

    return {
        version: '2',
        type: ChartType.SORTED,
        title: Title,
        noDataMessage: NoDataMessage,
        categories: [category],
        series: [singleSeries],
        filters: stringToFilters(insight.properties, Source.Filters),
    }
}
