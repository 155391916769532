import { Box, useTheme } from '@mui/material'
import { useSelector } from 'react-redux'
import { appearanceSelectors, filterSelectors } from '~/selectors'
import NeoClassicGlobalFilters from './NeoClassicGlobalFilters'

export default function NeoClassicGlobalFiltersWrapper() {
    const theme = useTheme()
    const showNeoClassic = useSelector(appearanceSelectors.getShowNeoClassicLayOut)
    const globalFilters = useSelector(state => filterSelectors.getGlobalFiltersConfig(state))
    const isGlobalFiltersOpen = useSelector(appearanceSelectors.isNeoClassicGlobalFiltersOpen)
    const open = globalFilters?.length > 0 && isGlobalFiltersOpen
    const isBetaTurnedOn = useSelector(appearanceSelectors.getIsBetaTurnedOn)

    if (!showNeoClassic || !isBetaTurnedOn) return null

    return (
        <Box
            sx={{
                display: open ? 'block' : 'none',
                borderBottom: 1,
                borderColor: 'border.main',
                transition: 'display 0.5s ease-out',
                boxShadow: `2px 2px 10px 0px ${theme.palette.border.shadow}`,
                zIndex: 2,
            }}
        >
            <NeoClassicGlobalFilters />
        </Box>
    )
}
