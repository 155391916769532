import { uniqBy } from 'lodash'
import { createSelector } from 'reselect'
import Store from '../store/storeTypes'

const get = (s: Store) => s.links
const getIsPending = (s: Store) => s.links.status === 'pending'

const getLinks = createSelector(get, links => links.list)

const getLinkedNodes = createSelector(getLinks, links => {
    const nodes = links.reduce((acc, cur) => [...acc, ...cur.nodes], [] as Array<{ id: string; name: string }>)
    return uniqBy(nodes, 'id').sort((a, b) => a.name.localeCompare(b.name))
})

const getLinksAndNodes = createSelector(getLinks, getLinkedNodes, getIsPending, (links, nodes, isPending) => ({ links, nodes, isPending }))

export const linksSelectors = {
    getLinks,
    getLinkedNodes,
    getLinksAndNodes,
}
