import { linksTypes } from '../actions/types'
import { LinksStore } from '../store/storeTypes'

const initialState: LinksStore = {
    status: 'idle',
    list: [],
}

export default function Links(state: LinksStore = initialState, action): LinksStore {
    const { type, payload } = action
    switch (type) {
        case linksTypes.LINKS_REQUEST:
            return { status: 'pending', list: [] }
        case linksTypes.LINKS_SUCCESS:
            return { status: 'idle', list: payload.links.sort((a, b) => a.name.localeCompare(b.name)) }
        case linksTypes.LINKS_FAILURE:
            return { status: 'failed', list: [] }
        default:
            return state
    }
}
