import makeStyles from '@mui/styles/makeStyles';

const offset = 0
const offsetMax = 40
const width = offsetMax / 2
const duration = 4
const stagger = duration / 2


const useStyles = makeStyles(theme => ({
    '@keyframes loading-ani1': {
        '25%': { transform: 'rotate(135deg)' },
        '50%': { transform: 'rotate(225deg)' },
        '75%': { transform: 'rotate(315deg)' },
        '100%': { transform: 'rotate(405deg)' },
    },
    '@keyframes loading-ani2': {
        '17.5%,25%,42.5%,50%,67.5%,75%,92.5%,100%': {
            transform: `translate(0, ${offset}px)`
        },
        '12.5%, 37.5%, 62.5%, 87.5%': {
            transform: `translate(0, ${offsetMax}px)`
        }
    },
    '@keyframes loading-ani3': {
        '17.5%,25%,42.5%,50%,67.5%,75%,92.5%,100%': {
            transform: `rotate(90deg) translate(0, ${offset}px)`
        },
        '12.5%, 37.5%, 62.5%, 87.5%': {
            transform: `rotate(90deg) translate(0, ${offsetMax}px)`
        }
    },
    '@keyframes loading-ani4': {
        '17.5%,25%,42.5%,50%,67.5%,75%,92.5%,100%': {
            transform: `rotate(180deg) translate(0, ${offset}px)`
        },
        '12.5%, 37.5%, 62.5%, 87.5%': {
            transform: `rotate(180deg) translate(0, ${offsetMax}px)`
        },
    },
    '@keyframes loading-ani5': {
        '17.5%,25%,42.5%,50%,67.5%,75%,92.5%,100%': {
            transform: `rotate(270deg) translate(0, ${offset}px)`
        },
        '12.5%, 37.5%, 62.5%, 87.5%': {
            transform: `rotate(270deg) translate(0, ${offsetMax}px)`
        }
    },
    loading: {
        width: width,
        height: width,
        transform: 'rotate(45deg)',
        animation: `$loading-ani1 ${duration}s cubic-bezier(0.165, 0.840, 0.440, 1.000) infinite`,
        '& i': {
            width: width,
            height: width,
            display: 'inline-block',
            borderRadius: '.3rem',
            position: 'absolute',
        },
        '& i:nth-child(1)': {
            background: theme.palette.tada.purple,
            transform: `translate(0, ${offset})`,
            animation: `$loading-ani2 ${duration}s ease-in-out infinite`,
        },
        '& i:nth-child(2)': {
            background: theme.palette.tada.blue,
            transform: `rotate(90deg) translate(0, ${offset})`,
            animation: `$loading-ani3 ${duration + stagger}s ease-in-out infinite`,
        },
        '& i:nth-child(3)': {
            background: theme.palette.tada.red,
            transform: `rotate(180deg) translate(0, ${offset})`,
            animation: `$loading-ani4 ${duration + stagger + stagger}s ease-in-out infinite`,
        },
        '& i:nth-child(4)': {
            background: theme.palette.tada.beige,
            transform: `rotate(270deg) translate(0, ${offset})`,
            animation: `$loading-ani5 ${duration + stagger + stagger + stagger}s ease-in-out infinite`,
        },
    },
}))

const Loader = () => {
    const classes = useStyles()
    return <div className={classes.loading}><i></i><i></i><i></i><i></i></div>
}

export default Loader