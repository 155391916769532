import { Typography } from '@mui/material'
import { _360 } from 'genesis-suite/icons'
import { forwardRef } from 'react'
import { useSelector } from 'react-redux'

import CollapsableList from '../../components/CollapsableList'
import DockingMenuItem from '../../components/DockingMenuItem'
import ParagraphSkeleton from '../../components/loaders/ParagraphSkeleton'
import { TourTag } from '../../lib/tourSteps'
import { menuSelectors, moduleSelectors } from '../../selectors'

export default function Views({ open, modulePending, classes }) {
    const views = useSelector(menuSelectors.getViews)
    const enable = useSelector(moduleSelectors.isPowerNavEnabled)
    const _360Items = views.map(({ subMenuConfig, ...rest }) => ({ items: subMenuConfig, ...rest }))

    return (
        <DockingMenuItem
            dataTour={TourTag._360Views}
            title="Views"
            Icon={forwardRef((props, ref) => (
                <_360 {...props} ref={ref} />
            ))}
            collapsed={!open}
            noUnderline
            collapsable
        >
            {(!enable || views.length === 0) && !modulePending ? (
                !open ? (
                    <Typography classes={{ root: classes.emptyText }}>N/A</Typography>
                ) : (
                    <Typography classes={{ root: classes.emptyText }}>No views to display</Typography>
                )
            ) : (
                <CollapsableList
                    collapsed={!open}
                    items={_360Items}
                    loading={modulePending}
                    loader={<ParagraphSkeleton lineCount={2} />}
                />
            )}
        </DockingMenuItem>
    )
}
