import { Box, Chip, ClickAwayListener, Paper, Popper, Typography } from '@mui/material'
import { isEqual } from 'lodash'
import { useMemo, useRef, useState } from 'react'

import { DebouncedTextField } from 'genesis-suite/components'
import useSearch from '~/hooks/useSearch'
import { updateOrableContextFilters } from '~/lib/utils'
import GroupedList from '../GroupedList'

interface Props {
    /** widget id */
    id: string
    filters: any[]
    onUpdate: (body: any[]) => void
}

export default function WidgetSearchFilterMenu({ id, filters, onUpdate }: Props) {
    const [text, setText] = useState('')
    const inputRef = useRef()

    const [searchResults] = useSearch(text, id)
    const pending = text && !searchResults
    const noResults = searchResults?.length === 0

    const listItems = useMemo(() => {
        if (!searchResults) return null

        return searchResults.reduce((acc, cur) => {
            const { nodeName, displayProperty, context } = cur
            if (filters.some(f => isEqual(f, context))) return acc

            let nodeListIndex = acc.findIndex(l => l.primary === nodeName)
            if (nodeListIndex === -1) {
                acc.push({ primary: nodeName, list: [] })
                nodeListIndex = acc.length - 1
            }

            acc[nodeListIndex].list.push({
                primary: displayProperty.value,
                onClick: () => handleSelect(context),
            })
            return acc
        }, [])
    }, [filters, searchResults])

    function removeFilter(index) {
        const updated = filters.filter((_, i) => i !== index)
        onUpdate(updated)
    }

    function handleSelect(context) {
        const filterName = `search|${context.Name}|${context.FieldName}`
        onUpdate(updateOrableContextFilters(filters, filterName, 'EqualTo', context))
        setText('')
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <DebouncedTextField
                debounceTime={500}
                inputRef={inputRef}
                onChange={setText}
                placeholder="Find"
                sx={{
                    mx: 1,
                    '& .MuiInputBase-root': {
                        '&.MuiInput-root': {
                            '&.MuiInput-underline': {
                                '&:before, &:after': {
                                    borderBottomColor: 'text.primary',
                                },
                            },
                        },
                    },
                }}
                value={text}
            />
            <Popper anchorEl={inputRef.current} open={Boolean(text)} placement="bottom-start" sx={{ zIndex: 1301 }}>
                <ClickAwayListener onClickAway={() => setText('')}>
                    <Paper
                        style={
                            pending || noResults ? { display: 'flex', minWidth: '150px', minHeight: '60px' } : undefined
                        }
                        sx={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            maxHeight: '400px',
                            maxWidth: '600px',
                            overflow: 'auto',
                        }}
                    >
                        <GroupedList data={listItems} pending={pending} />
                        {noResults && <Typography>No results</Typography>}
                    </Paper>
                </ClickAwayListener>
            </Popper>

            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {filters.map((f, i) => (
                    <Chip
                        size="small"
                        key={`${f.FieldName}${f.Values[0]}`}
                        label={f.DisplayValues[0] || f.Values[0]}
                        onDelete={() => removeFilter(i)}
                    />
                ))}
            </Box>
        </Box>
    )
}
