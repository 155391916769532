import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'
import { Tooltip } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

import { SelectPopover } from 'genesis-suite/components'

import Comfy from '@mui/icons-material/FormatAlignRightRounded'
import Compact from '@mui/icons-material/FormatAlignJustifyRounded'
import { chatSelectors } from '~/selectors'
import { chatCreators } from '~/actions/creators'

const options = [
    { value: 'comfy', Icon: Comfy, tooltip: 'Comfy' },
    { value: 'compact', Icon: Compact, tooltip: 'Compact' },
]

const useStyles = makeStyles(({ palette }) => ({
    chatDensityToggle: { position: 'relative', alignSelf: 'center' },
    tooltip: { background: palette.primary.main, color: palette.primary.contrastText },
    tooltipArrow: { color: palette.primary.main },
    tooltipRef: { position: 'absolute', right: '50%' },
}))

/**
 * Toggle interaction mode
 * @param {*=} props
 * @returns
 */
export default function ChatDensityToggle({ disabled, iconProps }) {
    const value = useSelector(chatSelectors.getChatDensity) ?? 'comfy'

    const dispatch = useDispatch()

    const [showTooltip, setShowTooltip] = useState(false)

    const classes = useStyles()

    const handleChange = next => {
        if (next.value === value) return

        dispatch(chatCreators.toggleChatDensity(next.value))
    }

    return (
        <div className={classes.chatDensityToggle}>
            <SelectPopover
                PlaceHolderIcon={Comfy}
                defaultValue={options.find(o => o.value === value)}
                tooltip="Change Chat Density"
                items={options}
                iconProps={iconProps}
                disabled={disabled}
                onChange={handleChange}
            />

            <Tooltip
                placement="top"
                arrow
                classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
                open={showTooltip}
                title={`Using ${options.find(o => o.value === value).tooltip}`}
            >
                <span className={classes.tooltipRef} />
            </Tooltip>
        </div>
    )
}
