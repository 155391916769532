import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { applicationCreators } from '~/actions/creators'
import { applicationSelectors } from '~/selectors'

export default function useApplications() {
    const applications = useSelector(applicationSelectors.getAllAuthorizedApps)
    const currAppName = useSelector(applicationSelectors.getCurrentAppName)
    const [applicationsFetched, setApplicationsFetched] = useState(false)
    const dispatch = useDispatch()

    const sortedApplications = useMemo(
        () =>
            applications.sort((a, b) => {
                if (a.AppName.toLowerCase() === currAppName.toLowerCase()) return -1
                if (b.AppName.toLowerCase() === currAppName.toLowerCase()) return 1
                return a.AppName.localeCompare(b.AppName)
            }),
        [applications, currAppName]
    )

    useEffect(() => {
        if ((!applications || applications.length === 0) && !applicationsFetched) {
            dispatch(applicationCreators.getAllApplications())
            setApplicationsFetched(true)
        }
    }, [applications])

    return [sortedApplications, currAppName]
}
