import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { authSelectors, applicationSelectors, moduleSelectors, lockoutSelectors } from '../selectors'
import { lockoutCreators } from '../actions/creators'
import { useFeature } from '../lib/featureFlags'

export default function useLockout() {
    const status = useSelector(lockoutSelectors.getStatus)
    const isPowerUser = useSelector(authSelectors.getIsPowerUser)
    const userId = useSelector(authSelectors.getUserId)
    const isV2 = useSelector(moduleSelectors.getIsV2)
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const moduleId = useSelector(moduleSelectors.getModuleId)
    const [enable] = useFeature('builder')
    const dispatch = useDispatch()

    useEffect(() => {
        if (!isPowerUser || !enable) {
            if (status === 'connected') lockoutCreators.disconnect()
            return
        }

        if (!moduleId) return

        if (status === 'idle') {
            dispatch(lockoutCreators.connect())
        } else {
            dispatch(lockoutCreators.setOptions({ userId, appName, moduleId: isV2 ? moduleId : undefined }))
        }
    }, [enable, isPowerUser, userId, isV2, appName, moduleId, status])
}
