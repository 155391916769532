import { logoTypes, moduleTypes } from '../actions/types'

const initState = { src: '', isPending: false }

export default (state = initState, action) => {
    const { payload } = action

    switch (action.type) {
        case moduleTypes.MODULE_CHANGE_REQUEST:
            return { ...state, isPending: true }

        case logoTypes.MODULE_LOGO_SUCCESS:
            return { src: payload.src, isPending: false }

        case logoTypes.MODULE_LOGO_FAILURE:
        case moduleTypes.MODULE_FAILURE:
            return { src: '', isPending: false }

        default:
            return state
    }
}
