import React, { useState, useEffect, memo, useRef } from 'react'
import { GroundOverlay } from '@react-google-maps/api'
import { isEqual } from 'lodash'

import { MapImageOverlay } from 'genesis-suite/types/visualTypes'
import { moduleService } from '../../../lib/services'

export default memo(MapOverlays)

interface Props {
    overlays: MapImageOverlay[]
}

function MapOverlays({ overlays }: Props) {
    const [overlaysWithImages, setOverlaysWithImages] = useState<MapImageOverlay[]>(null)

    const lastOverlays = useRef<MapImageOverlay[]>()
    useEffect(() => {
        if (isEqual(overlays, lastOverlays.current)) return

        lastOverlays.current = overlays
        if (overlaysWithImages) setOverlaysWithImages(null) // unmount Overlay components between changes to overlays
        if (!overlays) return

        getImagesForOverlays(overlays).then(setOverlaysWithImages)
    }, [overlays])

    if (!overlaysWithImages) return null

    return (
        <>
            {overlaysWithImages.map((o, i) => (
                <Overlay key={i} overlay={o} />
            ))}
        </>
    )
}

function Overlay({ overlay }: { overlay: MapImageOverlay }) {
    const { urlString, placement } = overlay || {}

    return (
        <GroundOverlay
            bounds={{
                //@ts-ignore
                north: placement.top,
                east: placement.right,
                south: placement.bottom,
                west: placement.left,
            }}
            url={urlString}
        />
    )
}

async function getImagesForOverlays(overlays: MapImageOverlay[]) {
    const images = await Promise.allSettled(overlays.map(o => moduleService.getFiles(o.urlString)))
    const overlaysWithImages = overlays.map((o, i) => {
        const image = images[i]
        if (image.status === 'rejected') return null
        return { ...o, urlString: URL.createObjectURL(image.value) }
    })
    return overlaysWithImages.filter(o => Boolean(o))
}
