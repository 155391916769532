import React, { useEffect } from 'react'
import userManager from '../lib/userManager'
import Loader from '../components/Loader'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
    },
})

const LoginView = () => {
    const classes = useStyles()

    useEffect(() => {
        userManager.clearStaleState()
        userManager.signinRedirect()
    }, [])

    return (
        <div className={classes.container}>
            <Loader />
        </div>
    )
}

export default LoginView
