import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { HistoryRouter as ConnectedRouter } from 'redux-first-history/rr6'
import { PersistGate } from 'redux-persist/integration/react'

import App from './App'
import { FeatureFlagProvider } from './lib/featureFlags'
import './lib/globalTypes'
import { unregister } from './registerServiceWorker'
import persistor from './store/persistor'
import store, { history } from './store/store'

if (process.env.NODE_ENV !== 'production') {
    const whyDidYouRender = require('@welldone-software/why-did-you-render')
    whyDidYouRender(React)
}

const PersistorWrapper = ({ children }) => {
    if (process.env.REACT_APP_ELECTRON) return children

    return (
        <PersistGate loading={null} persistor={persistor}>
            {children}
        </PersistGate>
    )
}

createRoot(document.getElementById('root')).render(
    <Provider store={store}>
        <PersistorWrapper>
            <ConnectedRouter history={history}>
                <FeatureFlagProvider>
                    <App />
                </FeatureFlagProvider>
            </ConnectedRouter>
        </PersistorWrapper>
    </Provider>
)

unregister()
