import { useState } from 'react'
import { IconButton, Tooltip } from '@mui/material'

import { getExportType } from './lib/widgetExporting'

import ShareIcon from '@mui/icons-material/Share'
import WidgetShareDialog from './WidgetShareDialog'

export default function WidgetShareButton({ config, visualRef, iconStyle, buttonStyle }) {
    const [showWidgetShare, setShowWidgetShare] = useState(false)
    const exportType = getExportType(config)
    const widgetTitle = config?.version === '2' ? config?.title : config?.Title
    const exportOptions = { includeWatermark: true, title: widgetTitle }

    const imgParams = {
        exportType,
        visualRef,
        exportOptions,
    }

    return (
        <>
            <Tooltip title="Share">
                <IconButton sx={buttonStyle} onClick={() => setShowWidgetShare(true)}>
                    <ShareIcon sx={iconStyle} />
                </IconButton>
            </Tooltip>
            <WidgetShareDialog
                open={showWidgetShare}
                widgetConfig={config}
                imgParams={imgParams}
                onClose={() => setShowWidgetShare(false)}
            />
        </>
    )
}
