import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTheme, Typography, IconButton, TextField, Button, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import AddIcon from '@mui/icons-material/Add'
import DragIcon from '@mui/icons-material/DragIndicator'

import { DragList, DragItem } from 'genesis-suite/components/DragList'
import { Help, Close, Trash, Link } from 'genesis-suite/icons'
import { authSelectors } from '../selectors'
import { dialogCreators, authCreators } from '../actions/creators'
import { openInNewTab } from '../lib/utils'

export const editShortcutsDialogKey = 'edit-shortcuts'

const useStyles = makeStyles(({ spacing, border, palette }) => ({
    container: { width: '600px' },
    header: { display: 'flex', alignItems: 'center', marginBottom: spacing() },
    helpIcon: { marginLeft: spacing() },
    headerSpacer: { flex: 1 },
    item: {
        display: 'grid',
        gridTemplateColumns: '18px 1fr 2fr 20px 20px',
        gridColumnGap: '10px',
        alignItems: 'center',
        padding: spacing(),
        background: palette.background.default,
        borderRadius: border.radius.round,
    },
    httpsText: { padding: '0 3px' },
    linkWrapper: { display: 'flex', alignItems: 'center' },
    footer: { display: 'flex', alignItems: 'center', justifyContent: 'flex-end' },
    saveButton: { marginLeft: spacing() },
}))

export default function EditShortcuts() {
    const [changesMade, setChangesMade] = useState(false)
    const [notValid, setNotValid] = useState(false)
    const [items, setItems] = useState([])

    const shortcuts = useSelector(authSelectors.getShortcuts)

    const dispatch = useDispatch()
    const showConfirmation = message => dispatch(dialogCreators.confirmDialog(message))
    const hide = () => dispatch(dialogCreators.hideDialog(editShortcutsDialogKey))

    useEffect(() => {
        setItems(shortcuts)
    }, [shortcuts])

    const handleSave = () => {
        const isValid = items.every(({ text, to }) => text && to)
        if (!isValid) {
            setNotValid(true)
            return
        }

        dispatch(authCreators.saveShortcuts(items))
        hide()
    }

    const handleCancel = async () => {
        if (changesMade) {
            const response = await showConfirmation('Changes will be lost. Are you sure you want to exit?')
            if (!response) return
        }
        hide()
    }

    const handleChange = ({ target: { name, value } }, itemId) => {
        if (value === ' ') return

        const newItems = items.map(item => {
            if (item.id === itemId) return { ...item, [name]: value }
            return item
        })
        setItems(newItems)
        setChangesMade(true)
        setNotValid(false)
    }

    const handleAdd = () => {
        const id = items.reduce((acc, { id }) => Math.max(acc, id), 0) + 1
        setItems([...items, makeNewShortcut(id)])
        setChangesMade(true)
    }

    const handleDelete = itemId => {
        const newItems = items.filter(({ id }) => id !== itemId)
        setItems(newItems)
        setChangesMade(true)
    }

    const handleDragEnd = newItems => {
        setItems(newItems)
        setChangesMade(true)
    }

    const classes = useStyles()
    const theme = useTheme()

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <Typography variant="h6">Shortcuts</Typography>
                <Tooltip
                    title="Add links to quickly access external applications from Tada. These links will only be visible to you."
                    placement="right-start"
                >
                    <Help className={classes.helpIcon} fontSize="small" />
                </Tooltip>
                <div className={classes.headerSpacer} />

                <IconButton onClick={handleCancel} size="large" sx={{ p: 0.5, color: 'text.primary' }}>
                    <Close />
                </IconButton>
            </div>

            <DragList onDragEnd={handleDragEnd} items={items}>
                {items.map((item, index) => (
                    <DragItem key={item.id} itemId={item.id} index={index}>
                        <div className={classes.item}>
                            <DragIcon style={{ fontSize: 20 }} />
                            <TextField
                                value={item.text}
                                name="text"
                                placeholder="Text"
                                onChange={e => handleChange(e, item.id)}
                            />
                            <div className={classes.linkWrapper}>
                                <Typography className={classes.httpsText} display="inline">
                                    https://
                                </Typography>
                                <TextField
                                    fullWidth
                                    value={item.to}
                                    name="to"
                                    placeholder="URL"
                                    onChange={e => handleChange(e, item.id)}
                                />
                            </div>
                            <IconButton
                                size="small"
                                onClick={() => openInNewTab(item.to)}
                                sx={{ color: 'text.primary' }}
                            >
                                <Link style={{ fontSize: 17 }} />
                            </IconButton>
                            <IconButton
                                size="small"
                                onClick={() => handleDelete(item.id)}
                                sx={{ color: 'text.primary' }}
                            >
                                <Trash style={{ fontSize: 17 }} />
                            </IconButton>
                        </div>
                    </DragItem>
                ))}
            </DragList>

            {notValid && <Typography color="error">Labels and URLs are both required</Typography>}

            <Button onClick={handleAdd} sx={{ color: 'text.primary' }}>
                <AddIcon />
                Add
            </Button>

            <div className={classes.footer}>
                <div className={classes.addItem}>
                    <Button variant="contained" onClick={handleCancel} disabled={!changesMade}>
                        Cancel
                    </Button>
                </div>
                <Button
                    className={classes.saveButton}
                    variant="contained"
                    color="primary"
                    disabled={!changesMade}
                    onClick={handleSave}
                >
                    Save
                </Button>
            </div>
        </div>
    )
}

/** make a new shortcut item. isExternal is used by SideNav to open link in new tab */
const makeNewShortcut = id => ({
    id,
    text: '',
    to: '',
    isExternal: true,
})
