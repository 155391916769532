import HelpIcon from '@mui/icons-material/HelpOutline'
import { Box, Button, Tooltip } from '@mui/material'
import { useState } from 'react'

import { ChopText, MenuIcon } from 'genesis-suite/components'
import FilterIcon from 'genesis-suite/icons/Filter'
import { makeStyles } from '@mui/styles'

interface Props {
    title: string | JSX.Element
    /** (optional) show help icon with text in tooltip */
    helpText?: string
    /** (optional) show filter icon with filters inside */
    filter?: JSX.Element
    /** (if filter) show badge that filters are applied */
    filterApplied?: boolean
    /** (optional if filter) request to clear filters if any are applied */
    onClearFilter?: () => void
}

const useStyles = makeStyles(theme => ({
    icon: {
        color: theme.palette.text.primary,
    },
}))

export default function TableHeaderCell(props: Props) {
    const { title, helpText = undefined, filter = null, filterApplied = false, onClearFilter = undefined } = props
    const [openFilter, setOpenFilter] = useState(false)
    const classes = useStyles()

    const header =
        typeof title === 'string' ? (
            <ChopText
                showEllipsis
                tooltipProps={{ placement: 'top' }}
                sx={{ fontWeight: 600, textOverflow: 'ellipsis' }}
            >
                {title}
            </ChopText>
        ) : (
            title
        )

    function handleClearFilter(e) {
        setOpenFilter(false)
        onClearFilter()
    }

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
            {helpText ? (
                <Tooltip title={helpText}>
                    <Box sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
                        {header}
                        <HelpIcon sx={{ ml: 0.5, fontSize: '1rem' }} />
                    </Box>
                </Tooltip>
            ) : (
                header
            )}

            {filter && (
                <MenuIcon
                    open={openFilter}
                    onClick={e => {
                        e.stopPropagation()
                        setOpenFilter(true)
                    }}
                    onClose={() => setOpenFilter(false)}
                    icon={<FilterIcon className={classes.icon} sx={{ fontSize: '14px' }} />}
                    badgeProps={{ invisible: !filterApplied, color: 'primary', variant: 'dot' }}
                    buttonProps={{ size: 'small' }}
                    popoverProps={{
                        PaperProps: { sx: { maxHeight: '400px', overflow: 'hidden', padding: 0, display: 'flex' } },
                    }}
                >
                    <Box display="flex" flexDirection="column" overflow="hidden">
                        <Box display="flex" overflow="auto">
                            {filter}
                        </Box>

                        {filterApplied && onClearFilter && (
                            <Button size="small" variant="outlined" color="error" onClick={handleClearFilter}>
                                Clear
                            </Button>
                        )}
                    </Box>
                </MenuIcon>
            )}
        </Box>
    )
}
