const sendMessage = (toID, payload) => {
    const { $ } = window
    const { connection } = $
    const hub = $.connection.collaborationHub
    if (!hub) return

    hub.server.pass(connection.hub.id, toID, payload)
}

const disconnect = () => {
    const { $ } = window
    const { connection } = $
    const { hub } = connection

    if (!hub || !hub.stop) return

    hub.stop()
}

export const collaborationService = {
    sendMessage,
    disconnect
}
