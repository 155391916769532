import { chatTypes } from '../actions/types'

const initialState = {
    chatMessages: [
        {
            text: 'Hello, how can I help you?',
            type: 'bot',
        },
    ],
    messages: [
        {
            text: 'Hello, how can I help you?',
            type: 'bot',
        },
    ],
    splitScreenOn: true,
    aiChatSessionId: null,
    chatDensity: 'comfy',
    settings: {
        format: false,
        genIndex: 1,
        responseIndex: 1,
    },
    version: 'V2',
}

export default (state = initialState, action) => {
    const { payload } = action
    switch (action.type) {
        case chatTypes.UPDATE_CHAT_MESSAGES:
            return { ...state, chatMessages: payload.messages }
        case chatTypes.UPDATE_AI_CHAT_MESSAGES:
            return { ...state, messages: payload.messages }
        case chatTypes.TOGGLE_SPLIT_SCREEN:
            return { ...state, splitScreenOn: !state.splitScreenOn }
        case chatTypes.CLEAR_ALL_MESSAGES:
            return { ...state, chatMessages: initialState.chatMessages, messages: initialState.messages }
        case chatTypes.INITIATE_AI_CHAT_SESSION:
            return { ...state, aiChatSessionId: payload.sessionId }
        case chatTypes.CLEAR_AI_CHAT_SESSION:
            return { ...state, aiChatSessionId: null }
        case chatTypes.TOGGLE_CHAT_DENSITY:
            return { ...state, chatDensity: payload.value }
        case chatTypes.UPDATE_CHAT_SETTINGS:
            return { ...state, settings: payload.settings }
        case chatTypes.UPDATE_CHAT_VERSION:
            return { ...state, version: payload.version }
        default:
            return state
    }
}
