import { TextFilterModel } from '@ag-grid-community/core'
import { NodeFilterWithValue } from 'genesis-suite/types/visualTypes/commonVisualTypes'
import { Sort } from 'genesis-suite/types/visualTypes/widgetTypes/DataSourceTypes'
import { cloneDeep } from 'lodash'
import { useRef } from 'react'
import { useSelector } from 'react-redux'
import { reportService, widgetService } from '../../../lib/services'
import {
    applicationSelectors,
    authSelectors,
    debugSelectors,
    moduleSelectors,
    scenarioSelectors,
    widgetSelectors,
    getSelectedBackup,
} from '../../../selectors'
import { makeOperator } from '../../widgets2/agGridTable/util'
import { networkFilterSelectors } from '../../../redux/networkFilterSlice'
import isEmpty from 'lodash/isEmpty'

export type SortItem = {
    [key: string]: Sort
}

const useFetchWidgetData = (config: any) => {
    const cancelCall = useRef(null)
    const Cloud = useSelector(moduleSelectors.getModuleCloud)
    const accessKey = useSelector(authSelectors.getAccessKey)
    const VisorName = useSelector(moduleSelectors.getModuleName)
    const moduleName = useSelector(moduleSelectors.getModuleName)
    const Model = useSelector(applicationSelectors.getCurrentAppName)
    const showDiagnostics = useSelector(debugSelectors.getWidgetDiagnostics)
    const activeScenarios = useSelector(scenarioSelectors.getActiveScenarios)
    const perspective = useSelector(widgetSelectors.getCurrentPerspectiveConfig)
    const NetworkFilters = useSelector(
        config?.isCompare ? networkFilterSelectors.getAppliedCompareIds : networkFilterSelectors.getAppliedIds
    )
    const selectedBackup = useSelector(getSelectedBackup)

    const fetchData = (pageNumber = 1, pageSize, sortOrders?: SortItem, filters?: any, aggregates?: any) => {
        const requestConfig = cloneDeep(config)
        const configs = ['FormConfig', 'TableConfig']

        if (!pageSize) {
            if (requestConfig['FormConfig']) pageSize = 1000
            else pageSize = 200
        }

        configs.forEach(key => {
            if (requestConfig[key]) {
                requestConfig[key].PageSize = pageSize
                requestConfig[key].PageNumber = pageNumber
                if (!isEmpty(sortOrders)) requestConfig[key].SortOrders = sortOrders
            }
        })

        if (filters) {
            const parsedContext = JSON.parse(requestConfig.Context)
            parsedContext.Filters = addFilters(requestConfig.TableConfig, filters)
            requestConfig.Context = JSON.stringify(parsedContext)
        }

        requestConfig.Config = JSON.stringify(requestConfig.Config)

        if (!requestConfig.Source) {
            requestConfig.Source = {}
            requestConfig.Source.BaseUrl = window.TADA_APIS.INTEGRATION
            requestConfig.Source.AccessKey = accessKey
            requestConfig.Source.Model = Model
        }

        switch (requestConfig.Type) {
            case 'ReportWidget':
                return reportService.getReport(
                    requestConfig.Model,
                    requestConfig.ReportWidgetConfig.ReportId,
                    false,
                    selectedBackup?.[0]?.Id
                )
            case 'Form':
                const { Context, FormConfig, DynamicFilters: dynaFilters } = requestConfig
                const DynamicFilters = JSON.stringify(dynaFilters)
                const { PageSize, PageNumber, SortOrders } = FormConfig
                return widgetService.getFormData(
                    {
                        Cloud,
                        Model,
                        WidgetId: requestConfig.Id,
                        Context,
                        PageSize,
                        PageNumber,
                        SortOrders,
                        VisorName,
                        NetworkFilters,
                        DynamicFilters,
                        backupVersionId: selectedBackup?.[0]?.Id ?? null,
                    },
                    cancel => (cancelCall.current = cancel)
                )
            default:
                return widgetService.getData(
                    {
                        Config: JSON.stringify(requestConfig),
                        Body: false,
                        Model,
                        Cloud,
                        VisorName: moduleName,
                        PerspectiveName: perspective?.Name,
                        Reload: false,
                        ReturnData: true,
                        scenarios: requestConfig?.Scenario === false ? [] : activeScenarios.map(s => s.id),
                        NetworkFilters,
                        backupVersionId: selectedBackup?.[0]?.Id ?? null,
                        Aggregates: aggregates,
                    },
                    showDiagnostics,
                    cancel => (cancelCall.current = cancel)
                )
        }
    }

    return { fetchData, cancelCall }
}

const addFilters = (config: any, filters: TextFilterModel): Array<NodeFilterWithValue> => {
    if (!filters) return null

    return Object.keys(filters).reduce((acc, key, index) => {
        const { filter, filterType, type, dateFrom, dateTo } = filters[key]
        const field = config.Fields.find((f: any) => f.HeaderName === key)

        acc[index] = {
            Values: filterType !== 'date' ? [filter] : [],
            Operator: makeOperator(type),
            ResourceType: 'Concept',
            ResourceName: field?.CrumbMetaName,
            PropertyName: field?.CrumbFieldName,
        }

        if (filterType === 'date') {
            acc[index].IsTemporal = true
            acc[index].Range = {
                MinValue: dateFrom,
                MaxValue: dateTo,
            }
        }

        return acc
    }, [])
}

export default useFetchWidgetData
