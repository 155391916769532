import { IconButton, Tooltip } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import { useContext, useMemo, useState } from 'react'
import { PerspectiveWidgetContext } from '../contexts/PerspectiveWidgetContext'
import EditTextWidgetModal from './visuals/TextWidget/EditTextWidgetModal'

export interface buttonStyleProps {
    padding: string
}

export interface iconStyleProps {
    fontSize: string
    color: string
}

interface Props {
    config: any
    buttonStyle: buttonStyleProps
    iconStyle: iconStyleProps
    onClick: (mode) => void
}
export default function EditWidget({ config, buttonStyle, iconStyle, onClick }: Props) {
    const [open, setOpen] = useState(false)
    const configId = config?.Id
    const { isEdit } = useContext(PerspectiveWidgetContext)
    const getMode = useMemo(() => {
        let mode = 'Edit'
        if (isEdit?.[configId] == 'Edit') {
            mode = 'Save'
        }
        if (isEdit?.[configId] == 'Hide') {
            mode = 'Hide'
        }
        return mode
    }, [isEdit])

    return (
        <>
            {config.Type.toUpperCase() == 'LABEL' ? (
                getMode == 'Hide' ? null : (
                    <Tooltip title={getMode}>
                        <IconButton sx={buttonStyle} onClick={() => onClick(getMode)}>
                            {getMode == 'Save' ? <SaveIcon sx={iconStyle} /> : <EditIcon sx={iconStyle} />}
                        </IconButton>
                    </Tooltip>
                )
            ) : config.Type.toUpperCase() === 'RICHTEXT' && !config.RichTextConfig?.PropertyName ? (
                <>
                    <Tooltip title={'Edit Text Widget'}>
                        <IconButton sx={buttonStyle} onClick={() => setOpen(true)}>
                            <EditIcon sx={iconStyle} />
                        </IconButton>
                    </Tooltip>
                    {open && <EditTextWidgetModal config={config} open={open} setOpen={setOpen} />}
                </>
            ) : null}
        </>
    )
}
