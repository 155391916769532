import { deploymentTypes } from '../actions/types'
import { DeploymentStore } from '../store/storeTypes'
import { DeploymentView } from '../types/DeploymentTypes'

const initState: DeploymentStore = {
    mode: { view: DeploymentView.LIVE },
    appName: null,
}

export default (state = initState, action): DeploymentStore => {
    const { type, payload } = action
    switch (type) {
        case deploymentTypes.SET_DEPLOYMENT_MODE: {
            return { ...state, mode: { ...state.mode, ...payload } }
        }
        case deploymentTypes.SET_DEPLOYMENT_APP_NAME: {
            return { ...state, appName: payload }
        }
        default:
            return state
    }
}
