import Icon from '@mui/icons-material/PivotTableChart'
import { IconButton, Tooltip } from '@mui/material'
import { useHotKey } from 'genesis-suite/hooks'
import { useDispatch, useSelector } from 'react-redux'
import { appearanceCreators } from '../../../actions/creators'
import { appearanceConstants } from '../../../constants'
import { logEvent } from '../../../lib/amplitudeClient'
import { appearanceSelectors } from '../../../selectors'
import { HotKeys, createTip } from '../../../lib/hotkeys'

export default function AppearanceButton({ sx }) {
    const { CLASSIC, MODERN } = appearanceConstants.Layout

    const layout = useSelector(appearanceSelectors.getApplicationLayout)
    const dispatch = useDispatch()

    function toggleLayout() {
        const newLayout = layout === CLASSIC ? MODERN : CLASSIC
        dispatch(appearanceCreators.setLayout(newLayout))
        logEvent('SET_LAYOUT', { layout: newLayout })
    }
    useHotKey(HotKeys.Appearance, toggleLayout)
    const keyTip = createTip(HotKeys.Appearance)

    if (layout === MODERN) {
        return (
            <Tooltip title={`Classic layout ${keyTip}`}>
                <IconButton color="inherit" onClick={toggleLayout}>
                    <Icon sx={sx} />
                </IconButton>
            </Tooltip>
        )
    }

    return (
        <Tooltip title={`Modern layout ${keyTip}`}>
            <IconButton color="inherit" onClick={toggleLayout}>
                <Icon sx={sx} />
            </IconButton>
        </Tooltip>
    )
}
