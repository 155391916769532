import { useState } from 'react'
import { Trash } from 'genesis-suite/icons'
import { useDispatch, useSelector } from 'react-redux'

import StarIcon from '@mui/icons-material/StarBorderRounded'

import { widgetSelectors } from '../selectors'
import useFavorites from '../hooks/useFavorites'
import { logEvent } from '../lib/amplitudeClient'
import howToFavorite from '../assets/img/how-to-fav.png'
import { navigationCreators } from '../actions/creators'
import { RightNavComponentProps } from './rightnav/RightNav'

import {
    Box,
    List,
    Link,
    Button,
    Divider,
    Tooltip,
    Typography,
    IconButton,
    ListItemButton,
    ListItemSecondaryAction,
} from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(({ palette }) => ({
    icon: { color: palette.text.primary },
}))

const FavoritesList = ({ Header }: RightNavComponentProps) => {
    const { favorites: ids, removeFavorite } = useFavorites()
    const configs = useSelector(widgetSelectors.getWidgetConfigs)

    const favorites = ids?.map(id => configs[id]).filter(Boolean) ?? []

    return (
        <Box sx={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
            {Header && (
                <Header>
                    <Typography variant="h6">Favorites</Typography>
                </Header>
            )}
            {favorites.length ? (
                <List>
                    <Divider />
                    {favorites.map(config => (
                        <>
                            <FavoriteItem key={config.Id} config={config} onRemove={removeFavorite} />
                            <Divider />
                        </>
                    ))}
                </List>
            ) : (
                <Box sx={{ p: 1 }}>
                    <Typography>
                        <b>No Favorites.</b>
                    </Typography>
                    <Typography>
                        Click the <StarIcon sx={{ verticalAlign: 'text-bottom' }} fontSize="small" /> Favorite option on
                        a Widget to add a favorite!
                    </Typography>
                    <Box sx={{ width: '50%', ml: 3 }}>
                        <img width="100%" src={howToFavorite} alt="how to favorite" />
                    </Box>
                </Box>
            )}
        </Box>
    )
}

export default FavoritesList

const _delay = 5
const _delayMs = _delay * 1000

const FavoriteItem = ({ config, onRemove }) => {
    const classes = useStyles()
    const [timeoutID, setTimeoutID] = useState(null)
    const dispatch = useDispatch()
    const goTo = (id: string) => dispatch(navigationCreators.goToPerspective(id))

    const goToWidget = (id: string) => {
        goTo(id)
    }

    const doRemove = () => {
        onRemove(config.Id)
        setTimeoutID(null)
    }
    const handleUnfavorite = e => {
        e.stopPropagation()
        e.preventDefault()
        setTimeoutID(setTimeout(doRemove, _delayMs))
    }

    const handleUndo = e => {
        e.stopPropagation()
        e.preventDefault()
        logEvent('UNDO_UNFAVORITE_WIDGET')
        clearTimeout(timeoutID)
        setTimeoutID(null)
    }

    return (
        <ListItemButton
            key={config.Id}
            className={timeoutID ? 'undo' : ''}
            sx={{ opacity: 1, '&.undo': { transition: `opacity ${_delay}s linear`, opacity: 0 } }}
            onClick={timeoutID ? undefined : () => goToWidget(config.Id)}
        >
            {timeoutID ? (
                <Typography display="inline">Favorite removed.</Typography>
            ) : (
                <Link sx={{ cursor: 'pointer' }}>{config.Title}</Link>
            )}
            <ListItemSecondaryAction>
                {!timeoutID ? (
                    <Tooltip title="Remove Favorite">
                        <IconButton className={classes.icon} size="small" onClick={handleUnfavorite}>
                            <Trash fontSize="small" />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Button sx={{ ml: 'auto' }} size="small" variant="outlined" onClick={handleUndo}>
                        Undo?
                    </Button>
                )}
            </ListItemSecondaryAction>
        </ListItemButton>
    )
}
