import { getPreference } from '~/lib/browserPreferences'

export const appearanceConstants = {
    Layout: {
        CLASSIC: 'classic',
        MODERN: 'modern',
        NEO: 'neo',
    },
    RightNav: {
        WIDTH: 330,
        PANEL_WIDTH: 60,
    },
    ScrollBarWidth: 16,
    SideNav: {
        HEADER_HEIGHT: 40,
        FOOTER_HEIGHT: 33,
    },
    TopNav: {
        ICONS_ONLY: 'iconsOnly',
        ICONS_AND_TEXT: 'iconsAndText',
    },
}

export const FONT_SIZE = {
    'x-small': 10,
    small: 12,
    medium: 14,
    large: 16,
    'x-large': 18,
} as const

export type FontSize = typeof FONT_SIZE

export function getFontSize(): FontSize[keyof FontSize] {
    const fontSizeKey = getPreference('fontSize')
    return FONT_SIZE[fontSizeKey]
}
