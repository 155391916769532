import { useEffect, useState, useRef } from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

import makeStyles from '@mui/styles/makeStyles'
import { Search, ExpandMoreRounded, ExpandLessRounded } from '@mui/icons-material'
import {
    Avatar,
    Box,
    IconButton,
    TextField,
    Typography,
    InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Divider,
    Badge,
    styled,
    BadgeProps,
} from '@mui/material'

import { EditBox } from 'genesis-suite/icons'
import { Spinner } from 'genesis-suite/components'

import { stripHtmlDOM } from '~/lib/utils'
import { authSelectors, collaborationSelectors } from '~/selectors'
import { messengerSelectors } from '~/selectors/messenger.selectors'

const useStyles = makeStyles(({ palette }) => ({
    messageList: {
        overflow: 'scroll',
        maxHeight: '360px',
    },
    listContent: {
        color: palette.text.primary,
        alignItems: 'center',
        padding: '4px 12px',
        '& .MuiListItemText-secondary': {
            color: palette.text.secondary,
            overflow: 'hidden',
            display: '-webkit-box',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
        },
    },
    chatUnread: {
        '& .MuiTypography-root': {
            fontWeight: 700,
        },
    },
    '@keyframes jump': {
        '0%': { transform: 'translateY(0)' },
        '30%': { transform: 'translateY(-10px)' },
        '60%': { transform: 'translateY(0)' },
        '100%': { transform: 'translateY(0)' },
    },
    jump: {
        animation: `$jump 0.5s ease-in-out 3`,
    },
}))

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
    '& .MuiBadge-badge': {
        right: '-15px',
        top: '50%',
    },
}))

const UnreadBadge = styled(Badge)<BadgeProps>(() => ({
    '& .MuiBadge-badge': {
        top: '50%',
    },
}))

const MessagesBox = ({ classes, onNewChat, onChatOpen }) => {
    const chatClasses = useStyles()
    const [isOpen, setIsOpen] = useState(false)
    const [unReadCount, setUnReadCount] = useState(0)
    const [search, setSearch] = useState('')
    const [animateKey, setAnimateKey] = useState(0)

    const userAlias = useSelector(authSelectors.getUserAlias)
    const userId = useSelector(authSelectors.getUserId)
    const chatList = useSelector(messengerSelectors.getMessageList)
    const userList = useSelector(messengerSelectors.getUserList)
    const loader = useSelector(messengerSelectors.getLoader)
    const users = useSelector(collaborationSelectors.collaborators)

    const chats = chatList
        .sort(
            (a, b) =>
                new Date(b.lastUnreadMessage?.createdAt).getTime() - new Date(a.lastUnreadMessage?.createdAt).getTime()
        )
        .reduce((acc, chat) => {
            const keyToFilterBy = chat.receiverId === userId ? 'senderId' : 'receiverId'
            const chatKey = `${userId}-${chat[keyToFilterBy]}`
            const existingChat = acc.find(c => c.key === chatKey)
            if (!existingChat) {
                const user = userList.find(user => user.UserId === chat[keyToFilterBy])
                if (!user) return acc

                const lastMessage = chat.lastUnreadMessage?.content
                    ? `${chat.senderId === userId ? 'You' : user.FirstName}: ${chat.lastUnreadMessage.content}`
                    : ''
                const cleanString = stripHtmlDOM(lastMessage)

                acc.push({
                    ...chat,
                    key: chatKey,
                    user: { id: user.UserId, ScreenAlias: user.ScreenAlias },
                    lastMessage: cleanString,
                    isUnread: chat.lastUnreadMessage.isUnread,
                    unreadCount: chat.unreadCount,
                })
            }
            return acc
        }, [])

    useEffect(() => {
        if (chats?.length > 0) {
            const unreadCount = chats.reduce((acc, chat) => acc + (chat.unreadCount || 0), 0)
            setUnReadCount(unreadCount)
        }
    }, [chats])

    useEffect(() => {
        // Update the key whenever unReadCount changes
        if (unReadCount > 0) {
            setAnimateKey(prevKey => prevKey + 1)
        }
    }, [unReadCount])

    const name = userAlias || userId || 'Empty'

    const toggleChatBox = () => {
        setIsOpen(!isOpen)
    }

    return (
        <Box className={`${classes.chatBoxContainer} ${isOpen ? classes.chatBoxOpen : ''}`}>
            <Box className={classes.chatBoxToggle} onClick={toggleChatBox}>
                <Box className={classes.chatHeader} sx={{ display: 'flex', gap: 0.5, alignItems: 'center', ml: 0.5 }}>
                    <Avatar className={classes.avatar}>{name[0].toUpperCase()}</Avatar>
                    <Typography variant="body1" sx={{ fontSize: '1.2rem', fontWeight: 500, marginRight: '10px' }}>
                        Messages
                    </Typography>
                    <Badge
                        key={animateKey}
                        badgeContent={unReadCount}
                        color="secondary"
                        className={chatClasses.jump}
                    ></Badge>
                </Box>
                <Box sx={{ display: 'flex', gap: '10px' }}>
                    <IconButton
                        className={classes.buttonStyle}
                        onClick={e => {
                            e.stopPropagation()
                            onNewChat()
                        }}
                        size="large"
                        data-cy="edit-row"
                    >
                        <EditBox />
                    </IconButton>
                    <IconButton onClick={toggleChatBox} className={classes.buttonStyle} size="large" data-cy="edit-row">
                        {isOpen ? <ExpandMoreRounded /> : <ExpandLessRounded />}
                    </IconButton>
                </Box>
            </Box>
            <Box className={classes.chatBox}>
                <TextField
                    id="search-box"
                    placeholder="Search Messages"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    className={classes.searchBox}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                        style: { borderRadius: 10, height: 30 },
                    }}
                />
                {loader ? (
                    <Box my={1}>
                        <Spinner />
                    </Box>
                ) : (
                    <List className={chatClasses?.messageList}>
                        {chats
                            .filter(chat => chat.user?.ScreenAlias.toLowerCase().includes(search.toLowerCase()))
                            .map(chat => {
                                const isOnline = users.some(user => user.name === chat.user?.ScreenAlias)
                                return (
                                    <>
                                        <ListItem
                                            key={chat.user?.UserId}
                                            onClick={() => onChatOpen(chat.user)}
                                            className={classNames(chatClasses.listContent, {
                                                [chatClasses.chatUnread]: chat?.isUnread,
                                            })}
                                            sx={{ cursor: 'pointer', alignItems: 'flex-start' }}
                                        >
                                            <ListItemAvatar sx={{ minWidth: '40px' }}>
                                                <Badge
                                                    overlap="circular"
                                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                    variant="dot"
                                                    invisible={!isOnline}
                                                    color={'success'}
                                                >
                                                    <Avatar className={classes.avatar}>
                                                        {chat.user?.ScreenAlias[0].toUpperCase()}
                                                    </Avatar>
                                                </Badge>
                                            </ListItemAvatar>
                                            <ListItemText
                                                sx={{
                                                    marginBottom: 0,
                                                    paddingBottom: 1,
                                                    '& .MuiListItemText-secondary': {
                                                        fontWeight: chat.isUnread === true ? 'bold' : 'normal',
                                                    },
                                                }}
                                                primary={
                                                    <StyledBadge badgeContent={chat?.unReadCount} color="secondary">
                                                        {chat.user?.ScreenAlias}
                                                    </StyledBadge>
                                                }
                                                secondary={chat.lastMessage}
                                            />
                                            {chat?.unreadCount > 0 && (
                                                <UnreadBadge
                                                    badgeContent={chat?.unreadCount}
                                                    color="secondary"
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        right: 16,
                                                        transform: 'translateY(-50%)',
                                                    }}
                                                />
                                            )}
                                        </ListItem>
                                        <Divider sx={{ borderColor: 'border.main' }} />
                                    </>
                                )
                            })}
                    </List>
                )}
            </Box>
        </Box>
    )
}

export default MessagesBox
