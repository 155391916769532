import { useDispatch } from 'react-redux'
import { Typography, IconButton, Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import isEmpty from 'lodash/isEmpty'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { Globe, Filter } from 'genesis-suite/icons'
import { useIsMobile } from '../hooks/useIsMobile'
import { appearanceCreators } from '../actions/creators'
import MobileFiltersController from '../components/MobileFiltersController'
import WidgetInfoSources from '../components/widget_info/WidgetInfoSources'
import WidgetInfoDescription from '../components/widget_info/WidgetInfoDescription'
import WidgetInfoInlineFilters from '../components/widget_info/WidgetInfoInlineFilters'
import WidgetInfoGlobalFilters from '../components/widget_info/WidgetInfoGlobalFilters'
import WidgetInfoPerspectiveFilters from '../components/widget_info/WidgetInfoPerspectiveFilters'
import WidgetInfoWidgetFilters from '../components/widget_info/WidgetInfoWidgetFilters'
import WidgetInfoDiagnostics from '../components/widget_info/WidgetInfoDiagnostics'
import WidgetInfoName from '~/components/widget_info/WidgetInfoName'

const useStyles = makeStyles(({ spacing, palette }) => ({
    infoContainer: { minWidth: '300px', maxHeight: '560px', maxWidth: '560px', padding: spacing(0, 1) },
    sectionTitle: { display: 'flex', alignItems: 'center' },
    text: { marginRight: 'auto', fontWeight: 'bold', fontSize: '1rem', lineHeight: 1.5 },
    filtersText: { fontSize: 15, fontWeight: 'bold' },
    separation: { paddingBottom: spacing() },
    icon: { height: 20, width: 20, color: palette.text.primary },
    filterContent: { fontSize: 12 },
    divider: { borderBottom: `1px solid ${palette.grayscale.lighter}`, marginBottom: spacing() },
    accordion: {
        boxShadow: 'none',
        '&:before': {
            opacity: 0,
        },
        '& .Mui-expanded': {
            minHeight: 0,
        },
    },
    accordionSummary: {
        padding: 0,
        minHeight: 0,
        '& .MuiAccordionSummary-content': {
            marginRight: 'auto',
            fontWeight: 'bold',
            fontSize: '1rem',
            lineHeight: 1.5,
            margin: 0,
        },
        '& .MuiAccordionSummary-expandIconWrapper': {
            color: palette.text.primary,
        },
    },
    accordionDetails: {
        padding: 0,
    },
}))

export default function WidgetInfoContainer({ config }) {
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const classes = useStyles()

    return (
        <div className={classes.infoContainer}>
            {!isEmpty(config.FormConfig) ? (
                <>
                    <div className={classes.separation}>
                        <Typography className={classes.text}>Form Name</Typography>
                        <WidgetInfoName config={config} />
                    </div>
                    <div className={classes.divider} />
                </>
            ) : (
                ''
            )}
            <div className={classes.separation}>
                <Typography className={classes.text}>Widget Description</Typography>
                <WidgetInfoDescription config={config} />
            </div>
            <div className={classes.divider} />
            <div className={classes.separation}>
                <Accordion classes={{ root: classes.accordion }}>
                    <AccordionSummary classes={{ root: classes.accordionSummary }} expandIcon={<ExpandMoreIcon />}>
                        Source Insights
                    </AccordionSummary>
                    <AccordionDetails classes={{ root: classes.accordionDetails }}>
                        <WidgetInfoSources config={config} />
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className={classes.divider} />
            <Accordion classes={{ root: classes.accordion }}>
                <AccordionSummary classes={{ root: classes.accordionSummary }} expandIcon={<ExpandMoreIcon />}>
                    Filters
                </AccordionSummary>
                <AccordionDetails classes={{ root: classes.accordionDetails }}>
                    <div className={classes.separation}>
                        <div className={classes.sectionTitle}>
                            <Typography className={classes.filtersText}> Global Filters:</Typography>
                            {isMobile ? (
                                <MobileFiltersController filter="global" />
                            ) : (
                                <IconButton
                                    size="small"
                                    onClick={() => dispatch(appearanceCreators.toggleGlobalFilters())}
                                >
                                    <Globe className={classes.icon} />
                                </IconButton>
                            )}
                        </div>
                        <div className={classes.filterContent}>
                            <WidgetInfoGlobalFilters />
                        </div>
                    </div>
                    <div className={classes.separation}>
                        <Typography className={classes.filtersText}> Inline Filters:</Typography>
                        <div className={classes.filterContent}>
                            <WidgetInfoInlineFilters />
                        </div>
                    </div>
                    <div className={classes.separation}>
                        <div className={classes.sectionTitle}>
                            <Typography className={classes.filtersText}> Perspective Filters:</Typography>
                            {isMobile ? (
                                <MobileFiltersController filter="perspective" />
                            ) : (
                                <IconButton
                                    size="small"
                                    onClick={() => dispatch(appearanceCreators.togglePerspectiveFilterPopover())}
                                >
                                    <Filter className={classes.icon} />
                                </IconButton>
                            )}
                        </div>
                        <div className={classes.filterContent}>
                            <WidgetInfoPerspectiveFilters />
                        </div>
                    </div>
                    <div className={classes.separation}>
                        <Typography className={classes.filtersText}> Widget Filters:</Typography>
                        <WidgetInfoWidgetFilters config={config} />
                    </div>
                </AccordionDetails>
            </Accordion>
            <div className={classes.divider} />
            <WidgetInfoDiagnostics config={config} />
        </div>
    )
}
