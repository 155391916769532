import EditIcon from '@mui/icons-material/Edit'
import { SvgIconProps, Tooltip, useTheme } from '@mui/material'
import tinycolor from 'tinycolor2'
import { getAppLogo } from '../../lib/appLogoUtils'

interface Props extends SvgIconProps {
    title: string
}

export function EditButton({ title, sx, ...rest }: Props) {
    return (
        <Tooltip title={title} enterDelay={500}>
            <EditIcon sx={{ ...sx }} {...rest} />
        </Tooltip>
    )
}

export const AppLogo = ({ classes, collapse, bgColor }) => {
    const appLogo = getAppLogo()
    const theme = useTheme()
    const background = bgColor ? bgColor : theme.palette.background.main
    const hasDarkBackground = tinycolor(background).isDark()

    let logoSrc
    let className

    if (collapse) {
        className = classes.logoSmall
        logoSrc = hasDarkBackground ? appLogo.smallLight : appLogo.smallDark
    } else {
        className = classes.logo
        logoSrc = hasDarkBackground ? appLogo.light : appLogo.dark
    }

    return <img src={logoSrc} alt="App Logo" className={className} draggable="false" />
}

export const EditShortcutsButton = ({ title: _t, sx, ...rest }: Props) => (
    <Tooltip title="Edit shortcuts" enterDelay={500}>
        <EditIcon sx={{ ...sx }} {...rest} />
    </Tooltip>
)
