import { CloseRounded } from '@mui/icons-material'
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classnames from 'classnames'
import { VirtualTable } from 'genesis-suite/components'
import { VirtualTableColumn } from 'genesis-suite/components/tables/VirtualTable/types'
import { ExpandWidget } from 'genesis-suite/icons'
import { isEmpty } from 'lodash'
import { useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import chartUtils from 'tadacharts/es/utils/chart-utils'
import { insightService } from '~/lib/services'
import { applicationSelectors } from '~/selectors'

const useStyles = makeStyles({
    root: {
        zIndex: '999 !important',
    },
    paper: {
        width: '80vw',
        height: '80vh',
        overflow: 'scroll',
    },
    paperExpanded: {
        height: '100vh',
        width: '100vw',
    },
    dialogContentRoot: {
        padding: '0 !important',
        overflow: 'scroll',
    },
})

export default function DataResponseTable({ Insight, Properties, Name, FilterExpression }) {
    const [openTableDialog, setOpenTableDialog] = useState(false)
    const classes = useStyles()

    return (
        <>
            <DataTable
                Insight={Insight}
                Properties={Properties}
                onExpand={() => setOpenTableDialog(true)}
                showExpand={true}
                Name={Name}
                filterExpression={FilterExpression}
            />
            <Dialog
                open={openTableDialog}
                maxWidth="lg"
                className={classes.root}
                PaperProps={{
                    className: classnames({ [classes.paper]: true }),
                }}
            >
                <DialogTitle>
                    <Box sx={{ display: 'flex', position: 'absolute', right: 8, top: 8 }}>
                        <IconButton
                            aria-label="close"
                            onClick={() => {
                                setOpenTableDialog(false)
                            }}
                            sx={{
                                color: 'inherit',
                            }}
                        >
                            <CloseRounded />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent classes={{ root: classes.dialogContentRoot }}>
                    <Box sx={{ p: 2 }}>
                        <DataTable
                            Insight={Insight}
                            Properties={Properties}
                            showExpand={false}
                            pageSize={200}
                            Name={Name}
                            filterExpression={FilterExpression}
                        />
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

const DataTable = ({ Insight, Properties, showExpand, onExpand = null, pageSize = 20, Name, filterExpression }) => {
    const appInfo = useSelector(applicationSelectors.getAppInfo)
    const { cloudId, partitionId } = appInfo
    const [tableStatus, setTableStatus] = useState('')
    const ref = useRef(null)
    const tableColumns: VirtualTableColumn[] = useMemo(() => {
        if (!isEmpty(Properties)) {
            return Properties?.map(p => ({
                width: 200,
                field: p.Name,
                fieldName: p.Name,
                headerName: p.DisplayName,
                HeaderName: p.DisplayName,
                resizable: true,
                sortable: false,
                filterable: false,
                cellRenderer: ({ row, column }) => {
                    let value = row[column.fieldName]

                    if (!isNaN(value) && p.DataTypeClass === 'Number' && p.SemanticDataType?.Format) {
                        value = chartUtils.format(
                            value,
                            p.SemanticDataType?.Format === '$ #,##0.00' ? '$,.2f' : p.SemanticDataType?.Format
                        )
                    }

                    return <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{value}</Box>
                },
            }))
        }
    }, [Properties])

    const fetchData = async pageNumber => {
        const config = {
            cloudId: cloudId,
            ModelPartId: partitionId,
            pageIndex: pageNumber,
            pageSize: pageSize,
        }

        if (filterExpression) {
            config['cf'] = filterExpression
        }

        const response = await insightService.preview(Insight, config)
        return response
    }

    const onRequestRows = async (page = 1) => {
        const { values = [], totalRecords } = await fetchData(page)

        setTableStatus(
            totalRecords > 0
                ? `Showing 1 - ${(page - 1) * pageSize + values?.length} rows of total ${totalRecords} rows `
                : ''
        )

        return {
            rows: values.map((d, id) => ({ id, ...d })),
            rowCount: totalRecords,
        }
    }

    // function getPinnedRows(tableColumns) {
    //     return getAggregatedRows(tableColumns, { 'Load Sec_Sum': 14678 }, 'fieldName')
    // }

    return (
        <Box
            flex={1}
            mx={1}
            display="flex"
            flexDirection="column"
            borderRadius={1}
            overflow="hidden"
            sx={{ height: showExpand ? 350 : '70vh' }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                <Typography variant="h5">{Name}</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {showExpand && (
                        <Tooltip title="Expand">
                            <IconButton
                                sx={{ padding: 0, pr: 0.5, cursor: 'pointer' }}
                                onClick={() => onExpand(Insight, Properties)}
                            >
                                <ExpandWidget sx={{ fontSize: '18px', color: '#fff' }} />
                            </IconButton>
                        </Tooltip>
                    )}
                    <Typography>{tableStatus}</Typography>
                </Box>
            </Box>
            <VirtualTable
                ref={ref}
                columns={tableColumns}
                statusBar={null}
                //pinnedBottomRowData={getPinnedRows(tableColumns)}
                rowsPerPage={pageSize}
                onRequestRows={onRequestRows}
                paginationMode="infiniteScroll"
            ></VirtualTable>
        </Box>
    )
}
