import { Backdrop, Box, CircularProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { SwalContext } from 'genesis-suite/components'
import { Property, ResourceType } from 'genesis-suite/types/networkTypes'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuid } from 'uuid'

import { ColumnId, FormConfig, InputFormConfig, PromptId, TableFormConfig } from 'genesis-suite/types/visualTypes'
import { authCreators } from '../../actions/creators'
import { useSemanticTypeById } from '../../hooks/useSemanticTypes'
import { createSection, propertyToColumn } from '../../lib/formUtils'
import { modelService, visualService } from '../../lib/services'
import { applicationSelectors } from '../../selectors'
import EditWidgetHeader from '../EditWidgetHeader'
import { ColOptionsProvider } from '../contexts/ColumnOptionsContext'
import { parseProperty } from '../edit_widget/utils'
import { defaultConfigs } from '../widgets2/form/defaultConfigs'
import { toInput, toTable } from '../widgets2/form/formConverter'
import { BuilderProps, FormProp, SourceOption } from './EditFormTypes'
import FormConfigDrawer from './FormConfigDrawer'
import FormFields from './FormFields'
import FormOptions from './FormOptions'
import FormPreviewer from './FormPreviewer'

const useStyles = makeStyles(() => ({
    backdrop: { zIndex: 1, width: '100%' },
}))

interface Props {
    /** (optional) provided if updating existing config */
    config?: FormConfig
    isNew: boolean
    onDone: (config: FormConfig) => Promise<void>
}

export default function EditForm({ config: initialConfig, isNew, onDone }: Props) {
    const classes = useStyles({})
    const [data, setData] = useState([])
    const [dirty, setDirty] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [network, setNetwork] = useState<SourceOption[]>([])
    const [properties, setProperties] = useState<Property[]>([])
    const [editKey, setEditKey] = useState<ColumnId | PromptId>(null)
    const [config, setConfig] = useState(initialConfig || defaultConfigs.table)

    const dispatch = useDispatch()
    const { confirm } = useContext(SwalContext)
    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const semanticTypes = useSemanticTypeById()
    const appName = useSelector(applicationSelectors.getCurrentAppName)

    const handleProperties = (properties, nodes, container) => {
        let result = []
        if (properties) {
            result = result.concat(properties.map(p => parseProperty(p.Property, semanticTypes, container)))
        }
        if (nodes) {
            result = result
                .concat(
                    nodes.map(({ Properties, ResourceId: id, ResourceName: name }) =>
                        Properties.map(p =>
                            parseProperty(p.Property, semanticTypes, { id, name, type: ResourceType.NODE })
                        )
                    )
                )
                .flat()
        }
        return result.filter(p => !p.computed)
    }

    const handleNetwork = (arr: any[], type: ResourceType): SourceOption[] => {
        return arr.map(
            ({ ServiceName, ResourceId: id, ResourceName: name, Properties, ChildServices }): SourceOption => ({
                id,
                type,
                name: ServiceName,
                groupBy: type === ResourceType.NODE ? 'Nodes' : 'Links',
                properties: handleProperties(Properties, ChildServices, { id, name, type }),
            })
        )
    }

    useEffect(() => {
        const init = async () => {
            setLoading(true)
            const promises = []
            promises.push(
                modelService.getModelNodes(appName).then(arr =>
                    handleNetwork(
                        arr.filter(o => o.Properties && o.Properties.length),
                        ResourceType.NODE
                    )
                )
            )
            promises.push(modelService.getModelLinks(appName).then(arr => handleNetwork(arr, ResourceType.LINK)))
            try {
                const network: SourceOption[] = await (await Promise.all(promises)).flat()
                if (!config.source) {
                    await handleSourceChange(network[0])
                }
                if (!isNew) {
                    const config = (await visualService.getWidgetById(appName, initialConfig.id)) as FormConfig
                    const networkSource = network.find(o => o.id === config.source?.id)
                    if (networkSource) {
                        const result = await modelService.getModelData(appName, networkSource.name)
                        setData(result.Data.map(d => ({ ...d, id: uuid() })) || [])
                    }
                    setProperties(properties)
                    setConfig(config)
                    setEditKey(
                        config.formType === 'table' ? config.columns?.[0].id : config.sections?.[0].prompts?.[0].id
                    )
                }
                setNetwork(network)
            } catch (error) {
                console.error(error)
                showSnackbar('An error occurred with setup. Please try again', { variant: 'error' })
            } finally {
                setLoading(false)
            }
        }
        init()
    }, [])

    const toggleFormType = () => {
        setDirty(false)
        if (config.formType === 'table') {
            const inputConfig = toInput(config)
            setEditKey(inputConfig.sections[0].id)
            setConfig(inputConfig)
        } else {
            const tableConfig = toTable(config)
            setEditKey(tableConfig.columns.find(p => p.required).id)
            setConfig(tableConfig)
        }
    }

    const handleTypeChange = async () => {
        if (!dirty) {
            toggleFormType()
            return
        }
        const response = await confirm(
            'Are you sure you want to change form types? You may lose some of your configuration.',
            { type: 'warning' }
        )
        if (response.dismiss) return
        toggleFormType()
    }

    const handleSourceChange = async (source: SourceOption) => {
        setEditKey(null)
        setLoading(true)
        const { properties, groupBy, ...rest } = source
        const primaryProps = properties.filter(p => p.isPrimary)
        try {
            const result = await modelService.getModelData(appName, source.name)
            setProperties(properties)
            if (config.formType === 'table') {
                const columns = primaryProps
                    .map(p => propertyToColumn(p))
                    .sort((a, b) => (a.property.isPrimary && b.property.isPrimary ? 0 : a.property.isPrimary ? -1 : 0))
                handleChange({ source: rest, columns })
                setEditKey(columns.find(p => p.required).id)
            } else {
                const section = createSection(primaryProps)
                handleChange({ source: rest, sections: [section] })
                setEditKey(section.id)
            }
            setData(result.Data.map(d => ({ ...d, id: uuid() })) || [])
            setDirty(false)
        } catch (error) {
            console.error(error)
            showSnackbar('An error occurred switching Sources. Please try again.', { variant: 'error' })
            handleChange({ source: rest })
        } finally {
            setLoading(false)
        }
        return Promise.resolve()
    }

    const handleChange = (change: FormProp) => {
        setDirty(true)
        dispatch(authCreators.pingRedux())
        if (config.formType === 'table') {
            setConfig((prevConfig: TableFormConfig) => ({ ...prevConfig, ...(change as Partial<TableFormConfig>) }))
        } else {
            setConfig((prevConfig: InputFormConfig) => ({ ...prevConfig, ...(change as Partial<InputFormConfig>) }))
        }
    }

    return (
        <ColOptionsProvider id={config.id}>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="secondary" />
            </Backdrop>
            <Box width="100%" display="flex" flexDirection="column">
                <EditWidgetHeader
                    title="Edit Form Widget"
                    isNew={isNew}
                    isDirty={dirty}
                    onDone={() => onDone(config)}
                    onReset={() => setConfig(config)}
                />
                <FormConfigDrawer
                    isNew={isNew}
                    config={config}
                    network={network}
                    loading={loading}
                    handleTypeChange={handleTypeChange}
                    onSourceChange={handleSourceChange}
                />
                <FormBuilder
                    data={data}
                    config={config}
                    editKey={editKey}
                    onChange={handleChange}
                    properties={properties}
                    onFieldSelect={setEditKey}
                />
            </Box>
        </ColOptionsProvider>
    )
}

function FormBuilder(props: BuilderProps) {
    return (
        <Box display="flex" height="100%" overflow="hidden">
            <FormFields {...props} />
            <Box m={1} flex={1} display="flex" flexDirection="column">
                <FormOptions {...props} />
                <FormPreviewer {...props} />
            </Box>
        </Box>
    )
}
