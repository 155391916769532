import React from 'react'
import withStyles from '@mui/styles/withStyles';
import { Typography, Link } from '@mui/material'
import { CloseCircle } from 'genesis-suite/icons'

const styles = theme => ({
    wrapper: {
        marginLeft: 'auto',
    },
    removedText: {
        marginRight: theme.spacing(1),
    },
    undoBtn: {
        verticalAlign: 'baseline',
    },
    closeBtn: {
        cursor: 'pointer',
        marginLeft: 'auto',
    },
})

/**
 * Button that toggles between a circle X and 'UNDO'
 * @param {boolean} removed - Boolean representing if this item was removed, toggles display
 * @param {function} onClick - Callback function when RemoveButton is clicked
 */
const RemoveButton = ({ classes, removed, onClick }) =>
    removed ? (
        <div className={classes.wrapper}>
            <Typography display="inline" color="textSecondary" className={classes.removedText}>
                Removed
            </Typography>
            <Link component="button" onClick={onClick} className={classes.undoBtn}>
                UNDO
            </Link>
        </div>
    ) : (
        <CloseCircle className={classes.closeBtn} fontSize="small" onClick={onClick} />
    )

export default withStyles(styles)(RemoveButton)
