import React from 'react'
import { Popover, Box, ListItemButton, Typography, Tooltip } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { FixedSizeList as Lists } from 'react-window'
import { ChopText } from 'genesis-suite/components'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

const GridLabelCategory = ({
    labelConfig,
    content,
    displayValue,
    displayValueStyle,
    onLabelClick,
    anchorEl,
    commonProps,
    valueProps,
    summarisedValueDivProps,
    summarisedValueProps,
    data,
    labelStyle,
}) => {
    const { SeriesName, FormatOptions } = labelConfig
    const open = Boolean(anchorEl)
    const id = open ? SeriesName : undefined

    const { ShowVartiation, summarisedValue, VariationViewSettings, summarisedValueStyle, itemWidth } =
        summarisedValueProps

    const commonTextProps = {
        showEllipsis: true,
        lineCount: 2,
        color: 'inherit',
    }
    const itemSize = 30
    const listHeight = data?.length <= 4 ? itemSize * data?.length : 120

    function items({ index, style }) {
        const item = data[index]
        return (
            <ListItemButton onClick={onLabelClick} style={style}>
                <ChopText {...commonTextProps} variant="body1">
                    {item[labelConfig.LabelField]}
                </ChopText>
                <ChopText {...commonTextProps} variant="h5" style={{ marginLeft: '20px' }}>
                    {item[labelConfig.ValueField]}
                </ChopText>
            </ListItemButton>
        )
    }

    const SeriesDisplay = () => (
        <>
            <Box display="flex" justifyContent="space-between" width="100%">
                <ChopText
                    {...commonProps}
                    sx={{ fontSize: '0.8rem', lineHeight: '1.3 !important', p: 1 }}
                    variant="body1"
                >
                    {SeriesName}
                </ChopText>

                <Box sx={{ cursor: 'pointer', p: 1 }}>
                    <ArrowForwardIosIcon
                        sx={{
                            transform: Boolean(anchorEl) ? 'rotate(90deg)' : 'rotate(0deg)',
                            transition: 'transform 0.3s ease-in-out',
                        }}
                    />
                </Box>
            </Box>
            <Box display="flex" justifyContent="space-between">
                {ShowVartiation && !isNaN(displayValue) && (
                    <Box
                        sx={theme => ({
                            color:
                                labelStyle?.backgroundColor && labelStyle?.backgroundColor !== '#a6a6a6'
                                    ? theme.palette.getContrastText(labelStyle?.backgroundColor)
                                    : displayValue > parseInt(VariationViewSettings?.TargetValue, 10)
                                    ? '#008000'
                                    : '#ff0000',
                            top: itemWidth <= 120 ? 0 : null,
                        })}
                        {...summarisedValueDivProps}
                    >
                        <Tooltip title={VariationViewSettings?.Text || summarisedValue}>
                            <>
                                <Typography
                                    sx={{
                                        fontSize: '10px',
                                        fontWeight:
                                            VariationViewSettings?.FontWeight === 'lighter'
                                                ? 200
                                                : VariationViewSettings?.FontWeight,
                                        paddingLeft: '8px',
                                    }}
                                    style={summarisedValueStyle}
                                >
                                    {summarisedValue}
                                </Typography>
                                <span>
                                    {displayValue > parseInt(VariationViewSettings?.TargetValue, 10) ? (
                                        <ArrowUpwardIcon fontSize="small" />
                                    ) : (
                                        <ArrowDownwardIcon fontSize="small" />
                                    )}
                                </span>
                            </>
                        </Tooltip>
                    </Box>
                )}

                <ChopText
                    variant="h5"
                    sx={{
                        textAlign: 'right',
                        fontWeight:
                            FormatOptions &&
                            (FormatOptions[0]?.FontWeight !== 'Unknown' || FormatOptions[0]?.FontWeight !== null)
                                ? FormatOptions && FormatOptions[0]?.FontWeight
                                : 600,
                    }}
                    style={displayValueStyle}
                    {...valueProps}
                >
                    {displayValue}
                </ChopText>
            </Box>
        </>
    )

    return (
        <>
            <Box>{SeriesDisplay()}</Box>
            <Popover
                id={id}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                sx={{
                    '& .MuiPopover-paper': {
                        width: `${anchorEl?.clientWidth}px !important`,
                        minWidth: '0 !important',
                    },
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Lists
                    height={open ? listHeight : 0}
                    itemCount={data?.length}
                    itemSize={30}
                    width="100%"
                    style={{ overflow: 'auto' }}
                >
                    {items}
                </Lists>
            </Popover>
        </>
    )
}

export default GridLabelCategory
