import { useState } from 'react'
import { FormControlLabel, Switch, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import BugIcon from '@mui/icons-material/BugReportOutlined'

import { MenuIcon } from 'genesis-suite/components'
import { useSelector, useDispatch } from 'react-redux'
import { authSelectors, debugSelectors } from '../selectors'
import { debugCreators } from '../actions/creators'

const isDev = process.env.NODE_ENV === 'development'

const useStyles = makeStyles(({ spacing }) => ({
    title: { padding: spacing(1) },
    content: { display: 'flex', flexDirection: 'column' },
}))

export default function DebugPanel({ iconStyle, buttonStyle }) {
    const [open, setOpen] = useState(false)
    const isCaching = useSelector(debugSelectors.getIsCaching)
    const isAdmin = useSelector(authSelectors.getIsAdmin)
    const logStats = useSelector(debugSelectors.getLogStats)
    const widgetDiagnostics = useSelector(debugSelectors.getWidgetDiagnostics)
    const dispatch = useDispatch()
    const classes = useStyles()

    return (
        <MenuIcon
            open={open}
            onClick={() => setOpen(true)}
            onClose={() => setOpen(false)}
            icon={<BugIcon sx={iconStyle} />}
            buttonProps={{ sx: buttonStyle }}
            tooltip="Debug"
            noPadding
            popoverProps={{ PaperProps: { sx: { py: 1, px: 2, overflow: 'scroll' } } }}
        >
            <Typography variant="h6">Developer Options</Typography>

            <div className={classes.content}>
                {isDev && (
                    <Toggle
                        label="Cache widget data"
                        checked={isCaching}
                        onChange={() => dispatch(debugCreators.toggleCaching())}
                    />
                )}
                {isDev && (
                    <Toggle
                        label="Log widget stats"
                        checked={logStats}
                        onChange={() => dispatch(debugCreators.toggleLogStats())}
                    />
                )}
                {(isDev || isAdmin) && (
                    <Toggle
                        label="Show widget diagnostics"
                        checked={widgetDiagnostics}
                        onChange={() => dispatch(debugCreators.toggleWidgetDiagnostics())}
                    />
                )}
            </div>
        </MenuIcon>
    )
}

function Toggle({ label, ...rest }) {
    return <FormControlLabel label={label} control={<Switch color="primary" {...rest} />} />
}
