import { useDispatch } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'
import { makeStyles } from '@mui/styles'

import Widget from '../components/widgets/Widget'
import { navigationCreators } from '../actions/creators'
import NavTitle from '../containers/NavTitle'
import { widgetConstants } from '../constants'

const useStyles = makeStyles({
    container: {
        height: '100%',
        overflow: 'auto',
    },
    tools: {
        display: 'flex',
        flexFlow: 'row nowrap',
        margin: '10px 15px',
    },
})

export default function BusinessElementView() {
    const { elementName } = useParams()
    const [searchParams] = useSearchParams()
    const treeId = searchParams.get('treeId')

    const dispatch = useDispatch()
    const goToPerspective = (perspectiveId, context) =>
        dispatch(navigationCreators.goToPerspective(perspectiveId, { context }))

    const classes = useStyles()

    if (!elementName) return null

    const config = { Type: widgetConstants.Type.BUSINESS_ELEMENT, elementName, treeId }

    return (
        <>
            <div className={classes.tools}>
                <NavTitle />
            </div>
            <span className={classes.container}>
                <Widget key={elementName} config={config} goToPerspective={goToPerspective} />
            </span>
        </>
    )
}
