export const widgetTypes = {
    WIDGET_SAVE_SUCCESS: 'WIDGET_SAVE_SUCCESS',
    WIDGET_SAVE_ERROR: 'WIDGET_SAVE_ERROR',
    CHANGE_SELECTED_LAYOUT: 'CHANGE_SELECTED_LAYOUT',
    CHANGE_CURRENT_LAYOUT: 'CHANGE_CURRENT_LAYOUT',
    START_PERSPECTIVE_EDITING: 'START_PERSPECTIVE_EDITING',
    STOP_PERSPECTIVE_EDITING: 'STOP_PERSPECTIVE_EDITING',
    START_CHANGING_PERSPECTIVE_LAYOUT: 'START_CHANGING_PERSPECTIVE_LAYOUT',
    STOP_CHANGING_PERSPECTIVE_LAYOUT: 'STOP_CHANGING_PERSPECTIVE_LAYOUT',
    START_SAVING_WIDGETS: 'START_SAVING_WIDGETS',
    STOP_SAVING_WIDGETS: 'STOP_SAVING_WIDGETS',
    SET_PERSPECTIVE_WIDTH: 'SET_PERSPECTIVE_WIDTH',
    SET_DRAFT_PERSPECTIVE_LAYOUTS: 'SET_DRAFT_PERSPECTIVE_LAYOUTS',
    SET_DRAFT_TITLE: 'SET_DRAFT_TITLE',
    SET_PERSPECTIVE: 'SET_PERSPECTIVE',
    INTERACTION_CHANGE: 'INTERACTION_CHANGE',
    SET_COMMENTING_WIDGET: 'SET_COMMENTING',
    CLEAR_WIDGET_COMMENT: 'CLEAR_COMMENTING',
    SET_SHOULD_REFRESH: 'SET_SHOULD_REFRESH',
    CLEAR_SHOULD_REFRESH: 'CLEAR_SHOULD_REFRESH',
    SET_DIAGNOSTICS: 'SET_DIAGNOSTICS',
    ADD_THUMBNAIL_REQUEST: 'WIDGET_ADD_THUMBNAIL_REQUEST',
    REMOVE_THUMBNAIL_REQUEST: 'WIDGET_REMOVE_THUMBNAIL_REQUEST',
    CHANGE_PREVIOUS_LAYOUT: 'CHANGE_PREVIOUS_LAYOUT',
    SET_LABEL_WIDGET_LAYOUTS: 'SET_LABEL_WIDGET_LAYOUTS',
    SET_LABEL_WIDGET_LAYOUTS_TO_NULL: 'SET_LABEL_WIDGET_LAYOUTS_TO_NULL',
    SET_TOP_LABEL_WIDGET_LAYOUTS: 'SET_TOP_LABEL_WIDGET_LAYOUTS',
    SET_TOP_LABEL_WIDGET_LAYOUTS_TO_NULL: 'SET_TOP_LABEL_WIDGET_LAYOUTS_TO_NULL',
}
