export const parsePerspectiveConfig = (obj, Id) => {
    const Tabs = obj.Tabs.map(tab => {
        const TabPerspectives = tab?.Perspectives?.map(p => {
            let TabPerspectivePosition = {}
            if (p?.Position?.Small?.length > 0) {
                const sm = layoutMapping(p?.Position?.Small)
                TabPerspectivePosition['sm'] = sm
            }
            if (p?.Position?.Large?.length > 0) {
                const lg = layoutMapping(p?.Position?.Large)
                TabPerspectivePosition['lg'] = lg
            }
            return { Id: p.Id, Position: TabPerspectivePosition, Order: p.Order }
        })
        return { name: tab.Name, perspective: TabPerspectives, Order: tab.Order }
    })
    const perspectiveLayout = {
        perspectiveLayoutName: obj.Name,
        perspectiveId: Id,
        layoutWidget: obj.WidgetId,
        tabs: Tabs,
    }
    return perspectiveLayout
}

const layoutMapping = obj => {
    return obj.map(ele => {
        return {
            i: ele.WidgetId,
            x: ele.Xpos,
            y: ele.Ypos,
            w: ele.Width,
            h: ele.Height,
        }
    })
}
