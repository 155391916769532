import classnames from 'classnames'
import withStyles from '@mui/styles/withStyles'
import ArrowRight from '@mui/icons-material/ArrowRight'

const styles = {
    arrow: {
        width: '12px',
        transformOrigin: '25% 50%',
        marginLeft: '2px',
        transition: 'all 300ms',
        '&.open': {
            transform: 'rotate(90deg)',
        },
    },
}

const CollapsableTwist = ({ open, classes, theme }) => {
    return (
        <ArrowRight
            htmlColor={theme.typography.body.color}
            className={classnames(classes.arrow, { open })}
            viewBox="10 0 12 24"
        />
    )
}

export default withStyles(styles, { withTheme: true })(CollapsableTwist)
