import FontDownloadOutlinedIcon from '@mui/icons-material/FontDownloadOutlined'

import { MenuIcon } from 'genesis-suite/components'
import { logEvent } from '../../../lib/amplitudeClient'
import FontSizeController from './FontSizeController'

export default function FontSizeButton({ iconStyle, buttonStyle }) {
    return (
        <MenuIcon
            tooltip="Font size"
            icon={<FontDownloadOutlinedIcon sx={iconStyle} />}
            buttonProps={{ sx: buttonStyle }}
            popoverProps={{ PaperProps: { sx: { width: '25%', maxWidth: '300px' } } }}
            onClick={() => logEvent('OPEN_FONT_SETTINGS')}
        >
            <FontSizeController />
        </MenuIcon>
    )
}
