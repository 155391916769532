import React, { useState, useEffect } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'

export default function NewListDialog({ open, onClose, onSubmit }) {
    const [listName, setListName] = useState('')

    const handleKeyPress = key => {
        if (key === 'Enter' && listName) onSubmit(listName)
    }

    useEffect(() => {
        setListName('')
    }, [open])

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle id="form-dialog-title">Create New List</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    label="Name"
                    margin="dense"
                    value={listName}
                    placeholder="Enter list name"
                    onChange={e => setListName(e.target.value)}
                    onKeyPress={({ key }) => handleKeyPress(key)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    onClick={() => onSubmit(listName)}
                    color="primary"
                    variant="contained"
                    disabled={!listName}
                >
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    )
}
