import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles({
    header: { fontWeight: 'bold' },
    keyPropertyCell: { color: 'blue', textDecoration: 'underline', cursor: 'pointer' },
})

export default function MakeTreeTableRowsAndColumns(meta, data) {
    const classes = useStyles()
    const rows = []
    let columns = []

    columns.push(
        {
            field: meta.PrimaryPropertyName,
            headerName: meta.PrimaryPropertyName,
            headerClassName: classes.header,
            cellClassName: classes.keyPropertyCell,
        },
        {
            field: meta.DisplayPropertyName,
            headerName: meta.DisplayPropertyName,
            headerClassName: classes.header,
        }
    )

    const metaProperties = meta.Properties?.map(({ Name, DisplayName }) => ({
        field: Name,
        headerName: DisplayName,
        headerClassName: classes.header,
    }))

    if (metaProperties) columns = [...columns, ...metaProperties]

    const constructRootRow = async () => {
        const root = data.filter(d => d.Level === 1)
        root.forEach(value => {
            const row = {}
            row['Path'] = [value[meta.PrimaryPropertyName]]
            row['id'] = [value[meta.PrimaryPropertyName]]
            columns.forEach(element => (row[element.field] = value[element.field] === null ? '' : value[element.field]))

            rows.push(row)

            if (value.ChildCount > 0) constructChildRow(row['Path'], value[meta.PrimaryPropertyName])
        })
    }

    const constructChildRow = async (path, parentValue) => {
        const childData = data.filter(d => d[meta.LevelName + '.' + meta.PrimaryPropertyName] === parentValue)

        childData.forEach(value => {
            const row = {}
            row['Path'] = [...path, value[meta.PrimaryPropertyName]]
            row['id'] = [value[meta.PrimaryPropertyName]]
            columns.forEach(element => (row[element.field] = value[element.field]))

            rows.push(row)

            if (value.ChildCount > 0) constructChildRow(row['Path'], value[meta.PrimaryPropertyName])
        })
    }

    constructRootRow()
    return { rows, columns }
}
