import React from 'react'
import { connect } from 'react-redux'
import { ListItemText, MenuItem, MenuList, ListItemIcon } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { interactionCreators } from './../actions/creators'
import { filterSelectors } from './../selectors'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import classnames from 'classnames'

const styles = ({ palette, border }) => {
    const topBarColor = palette.background.topBar
    const topBarContrast = palette.getContrastText(topBarColor)

    return {
        menu: {
            backgroundColor: topBarColor,
            borderRadius: border.radius.round,
            minWidth: 150,
            marginLeft: 2,
            marginRight: 2,
            boxShadow: '0 6px 20px 0 rgba(0, 0, 0, 0.3)',
        },
        itemIcon: {
            width: 24,
            justifyContent: 'center',
            '& svg': { color: topBarContrast },
        },
        optionText: {
            color: topBarContrast,
        },
        trendlineEnabledIcon: {
            paddingLeft: 10,
        },
        trendlineDisabled: {
            opacity: 0,
        },
    }
}

const TrendLineMenu = ({ trendLines, storedTrendLines, setTrendLines, classes }) => {
    let renderTrendLines = storedTrendLines || trendLines

    const clickHandler = seriesName => {
        let clonedTrendLine = JSON.parse(JSON.stringify(renderTrendLines))
        let trendLineIndex
        for (trendLineIndex = 0; trendLineIndex < clonedTrendLine.length; trendLineIndex++) {
            if (seriesName === clonedTrendLine[trendLineIndex].seriesName) {
                clonedTrendLine[trendLineIndex].showTrendLine = !clonedTrendLine[trendLineIndex].showTrendLine
                break
            }
        }
        setTrendLines(clonedTrendLine)
    }

    return (
        <MenuList className={classes.menu}>
            {renderTrendLines.map((trendLine, index) => {
                return (
                    <MenuItem
                        onClick={clickHandler.bind(this, trendLine.seriesName)}
                        key={index}
                    >
                        <ListItemText classes={{ primary: classes.optionText }}>
                            {trendLine.seriesName.replace(' - Trend', '')}
                        </ListItemText>
                        <ListItemIcon
                            className={classnames(classes.itemIcon, classes.trendlineEnabledIcon, {
                                [classes.trendlineDisabled]: !trendLine.showTrendLine,
                            })}
                        >
                            <CheckCircleOutlineRoundedIcon />
                        </ListItemIcon>
                    </MenuItem>
                )
            })}
        </MenuList>
    )
}

const mapStateToProps = (state, ownProps) => ({
    storedTrendLines: filterSelectors.getTrendLines(state, ownProps.widgetId),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    setTrendLines: trendLineSeries => {
        dispatch(interactionCreators.setTrendLines(trendLineSeries, ownProps.widgetId))
    },
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(TrendLineMenu))
