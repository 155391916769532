import { hubService } from '../../api'
import { collaborationTypes } from '../types'
import { registerCollaborationClientFunctions } from '../../lib/collaborationClient'
import { applicationSelectors, authSelectors, moduleSelectors } from '../../selectors'
import { subscribeToNotifications, registerNotificationClientFunctions } from '../../lib/notificationClient'
import { subscribeToMessageNotifications, registerMessagesClientFunctions } from '../../lib/messagesClient'

const connectionRequest = () => {
    return {
        type: collaborationTypes.COLLABORATION_CONNECTION_REQUEST,
    }
}

const connectionSuccess = hubID => {
    return {
        type: collaborationTypes.COLLABORATION_CONNECTION_SUCCESS,
        payload: {
            hubID,
        },
    }
}

const connectionFailure = error => {
    return {
        type: collaborationTypes.COLLABORATION_CONNECTION_FAILURE,
        payload: {
            error,
        },
        error: true,
    }
}

const connect = () => {
    return (dispatch, getState) => {
        registerCollaborationClientFunctions(dispatch)
        registerNotificationClientFunctions(dispatch)
        registerMessagesClientFunctions(dispatch)
        dispatch(connectionRequest())

        const state = getState()
        const user = authSelectors.getUser(state)
        const appName = applicationSelectors.getCurrentAppName(state)
        const moduleId = moduleSelectors.getModuleId(state)
        const moduleName = moduleSelectors.getModuleName(state)
        const appDomainId = applicationSelectors.getAppInfo(state)?.domainId

        if (!(user && appName)) return
        const { alias, userId, domainId } = user

        hubService.initializeHub(appName, alias).then(
            hubID => {
                const moduleCloud = moduleSelectors.getModuleCloud(state)
                subscribeToNotifications(appName, moduleCloud, moduleId, moduleName)
                subscribeToMessageNotifications(userId, appDomainId)
                dispatch(connectionSuccess(hubID))
            },
            error => {
                console.error('error: ', error)
                dispatch(connectionFailure(error))
            }
        )
    }
}

const connectionStop = () => {
    return {
        type: collaborationTypes.COLLABORATION_CONNECTION_STOP,
    }
}

const stop = () => {
    return dispatch => {
        hubService.stopHub().then(
            success => {
                dispatch(connectionStop())
            },
            error => {
                console.error(error)
            }
        )
    }
}

export const hubCreators = {
    connect,
    stop,
}
