import { Box, Typography, useTheme } from '@mui/material'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
    applicationCreators,
    filterCreators,
    logoCreators,
    moduleCreators,
    navigationCreators,
} from '../actions/creators'

import ApplicationLogo from '../components/ApplicationLogo'
import Loader from '../components/Loader'
import Perspective from '../components/widgets/Perspective'
import useSessionStatus from '../hooks/useSessionStatus'
import { useIsMobile } from '../hooks/useIsMobile'
import { moduleService } from '../lib/services'
import { login } from '../lib/userManager'
import { applicationSelectors, filterSelectors, moduleSelectors, widgetSelectors } from '../selectors'

export default function PublicView() {
    const { publicId, resource, resourceId } = usePublicParams()
    const isMobile = useIsMobile()

    const appPending = useSelector(applicationSelectors.getIsPending)
    const module = useSelector(moduleSelectors.getCurrentModule)
    const modulePending = isEmpty(module)
    const dispatch = useDispatch()

    const [error, setError] = useState('')
    const [sessionStatus] = useSessionStatus(Boolean(publicId), false)
    const initializing = appPending || modulePending

    useEffect(() => {
        if (!publicId) return setError('Missing code')
    }, [])

    useEffect(() => {
        if (sessionStatus === 'authenticating' || !publicId) return

        if (sessionStatus === 'failed') return login({ _public: publicId })

        moduleService.getPerspectiveURLById(publicId).then(async res => {
            const {
                ModelName: appName,
                SourceParentId: moduleId,
                SourceId: perspectiveId,
                ContextFilters: contextFilters,
            } = res

            try {
                dispatch(applicationCreators.selectApplication(appName))
                    //@ts-ignore
                    .then(async () => {
                        const module = await moduleService.getByName(appName, moduleId)
                        dispatch(logoCreators.getModuleLogo(appName, module.Name))
                        await dispatch(moduleCreators.processModule(appName, module))

                        if (resource !== 'perspective' || !resourceId) {
                            dispatch(navigationCreators.goToPerspective(perspectiveId))
                        }

                        updateFilters(contextFilters)
                    })
                    .catch(e => {
                        //handle error
                    })
            } catch (err) {
                console.error(err)
                setError('Could not load application details')
            }
        })
    }, [sessionStatus, publicId])

    function updateFilters(filters) {
        if (!filters) return

        const _filters = [...filters]
        if (_filters[0] && !_filters[0].FilterName) {
            const contextFilter = _filters.shift()
            dispatch(filterCreators.setContext(filterToContext(contextFilter)))
        }
        dispatch(filterCreators.changePerspectiveFilters(_filters))
    }

    return (
        <Box
            sx={{
                height: '100vh',
                width: '100vw',
                display: 'flex',
            }}
        >
            {error ? (
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'center',
                    }}
                >
                    <Typography variant="h4">{error}</Typography>
                </Box>
            ) : initializing ? (
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                        gap: 4,
                        justifyContent: 'center',
                    }}
                >
                    <Loader />
                    <Typography variant="h4">Getting information</Typography>
                </Box>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                        overflow: 'hidden',
                    }}
                >
                    <PublicTopBar isMobile={isMobile} />
                    <PublicPerspective id={resourceId} />
                </Box>
            )}
        </Box>
    )
}

function PublicTopBar({ isMobile }) {
    const theme = useTheme()
    const height = theme.custom.topNav.height

    return (
        <Box
            sx={{
                alignItems: 'center',
                backgroundColor: 'background.topBar',
                display: 'flex',
                justifyContent: isMobile ? 'center' : 'flex-start',
                maxHeight: `${height}px`,
                minHeight: `${height}px`,
                padding: '0 8px',
            }}
        >
            <ApplicationLogo />
        </Box>
    )
}

function PublicPerspective({ id }) {
    //@ts-ignore
    const configs = useSelector(s => widgetSelectors.getWidgetConfigsForPerspective(s, id))
    const layouts = useSelector(widgetSelectors.getCurrentPerspectiveLayouts)

    const props = {
        id,
        configs,
        networkContext: useSelector(filterSelectors.getCoord),
    }

    //@ts-ignore
    return <Perspective key={id} layouts={layouts} {...props} />
}

function usePublicParams() {
    const splat = useParams()['*']
    const [publicId, resource, resourceId] = splat.split('/')

    return { publicId, resource, resourceId }
}

function filterToContext(filter) {
    return {
        FieldName: filter.PropertyName,
        Name: filter.ResourceName,
        Value: filter.Values[0],
    }
}
