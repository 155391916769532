import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import StarIcon from '@mui/icons-material/StarBorderRounded'
import FilledStarIcon from '@mui/icons-material/StarRounded'
import { useTheme, IconButton, Tooltip } from '@mui/material'

import { authSelectors } from '../selectors'
import { authCreators } from '../actions/creators'
import { logEvent } from '../lib/amplitudeClient'

export default function FavoriteController({ prefKey, value, onClick }) {
    const theme = useTheme()
    const { enqueueSnackbar: showSnackbar } = useSnackbar()

    const isFavorite = useSelector(state => authSelectors.getIsFavorite(state, prefKey, value))
    const dispatch = useDispatch()
    const saveFavorite = e => {
        e.preventDefault()
        e.stopPropagation()
        logEvent('FAVORITE_WIDGET')
        dispatch(authCreators.saveFavorite(prefKey, value)).catch(() =>
            showSnackbar('An error occurred saving your favorites.', { variant: 'error' })
        )
    }

    return (
        <IconButton sx={{ padding: '6px' }} onClick={onClick || saveFavorite} size="large">
            {isFavorite ? (
                <Tooltip title="Unfavorite">
                    <FilledStarIcon style={{ fontSize: 20 }} htmlColor={theme.palette.primary.main} />
                </Tooltip>
            ) : (
                <Tooltip title="Favorite">
                    <StarIcon style={{ fontSize: 20 }} htmlColor={theme.palette.text.primary} />
                </Tooltip>
            )}
        </IconButton>
    )
}
