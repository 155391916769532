import { useSelector } from 'react-redux'
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { TreeType, ChartType, TreeConfig } from 'genesis-suite/types/visualTypes'
import { applicationSelectors } from '../selectors'
import { useParams } from 'react-router-dom'
import Widget2 from '../components/widgets2/Widget2'

const useStyles = makeStyles({
    container: {
        height: '100%',
        overflow: 'auto',
        backgroundColor: 'white',
        borderRadius: 5,
    },
    tools: {
        display: 'flex',
        flexFlow: 'row nowrap',
        margin: '10px 0 0 15px',
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
})

export default function TreeView() {
    const { treeName } = useParams()
    const appName = useSelector(applicationSelectors.getCurrentAppName)

    const classes = useStyles()

    if (!treeName) return null

    const config: TreeConfig = {
        version: '2',
        appName: appName,
        title: treeName.split('.')[1],
        type: ChartType.TREE,
        treeName: treeName,
        treeType: TreeType.TABLE,
    }
    return (
        <>
            <div className={classes.tools}>
                <Typography variant="h5" className={classes.title}>
                    {config.title}&nbsp;
                </Typography>
            </div>
            <Box flex={1} m={2} className={classes.container}>
                <Widget2 editing={false} config={config} />
            </Box>
        </>
    )
}
