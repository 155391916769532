import React, { forwardRef } from 'react'
import makeStyles from '@mui/styles/makeStyles'

import { WebviewConfig } from 'genesis-suite/types/visualTypes'

interface Props {
    config: WebviewConfig
}

const useStyles = makeStyles({
    iFrameWrap: { height: '100%' },
    iFrame: { height: '100%', width: '100%', border: 0 },
})

export default forwardRef<HTMLDivElement, Props>(({ config }, ref) => {
    const url = cleanUrl(config?.url)
    const classes = useStyles({})

    return (
        <div ref={ref} className={classes.iFrameWrap}>
            {url && <iframe key={url} className={classes.iFrame} src={url} title={config.title} />}
        </div>
    )
})

function cleanUrl(url) {
    if (!url) return
    return url.match(/^https?:\/\//i) ? url : 'http://' + url
}
