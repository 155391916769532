import { useContext, useState } from 'react'
import {
    Badge,
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Fab,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material'
import { VisibilityOffRounded, VisibilityRounded } from '@mui/icons-material'
import makeStyles from '@mui/styles/makeStyles'
import { Search, Refresh, Close } from 'genesis-suite/icons'
import { ChopText } from 'genesis-suite/components'

import { useIsMobile } from '~/hooks/useIsMobile'
import { widgetConstants } from '~/constants'
import { PerspectiveContext } from '../contexts/PerspectiveContext'
import { useSelector } from 'react-redux'
import { widgetSelectors } from '~/selectors'

const useStyles = makeStyles(({ spacing, palette }) => ({
    infoContainer: { minWidth: '300px', maxHeight: '560px', maxWidth: '560px', padding: spacing(0, 1) },
    toolbar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    refresh: {
        fontSize: '14px',
    },
    refreshButton: {
        backgroundColor: palette.grayscale.lighter,
        height: '25px',
        width: '25px',
        borderRadius: '15px',
        minWidth: 0,
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    titleWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'space-between' },
    titleText: { margin: '0 0 0 10px' },
    titleRoot: { padding: spacing(1) },
    backdrop: { cursor: 'not-allowed' },
    container: { cursor: 'default' },
    closeIcon: { padding: spacing(1), marginLeft: 'auto', color: palette.text.primary },
}))

const HiddenWidgets = ({ onDone }) => {
    const isMobile = useIsMobile()
    const { palette } = useTheme()
    const { hiddenWidgets, onUnHideWidget, showAllHiddenWidgets } = useContext(PerspectiveContext)

    const [isModelOpen, setIsModelOpen] = useState(false)

    if (isMobile || hiddenWidgets?.length === 0) return null

    const handleModelClose = () => {
        setIsModelOpen(false)
    }

    const handleOpenModel = () => {
        if (hiddenWidgets.length > 0) {
            setIsModelOpen(true)
        }
    }

    const onUnHidingWidget = async widgetId => {
        await onUnHideWidget(widgetId)
        handleModelClose()
        onDone?.()
    }

    const onShowAllHiddenWidgets = async () => {
        await showAllHiddenWidgets()
        handleModelClose()
        onDone?.()
    }

    return (
        <>
            <div style={{ height: 0 }}>
                <Tooltip title="Hidden Widgets" placement="top">
                    <Fab
                        color="primary"
                        size="medium"
                        aria-label="Hidden Widgets"
                        sx={{
                            position: 'relative',
                            zIndex: 50,
                            bottom: isMobile ? 110 : 60,
                            left: 'calc(97%)',
                            color: palette.text.primary,
                            backgroundColor: palette.background.sideNav,
                            '&:hover': {
                                backgroundColor: palette.background.sideNav,
                            },
                        }}
                        onClick={handleOpenModel}
                    >
                        <Badge badgeContent={hiddenWidgets.length} color="primary">
                            <VisibilityOffRounded />
                        </Badge>
                    </Fab>
                </Tooltip>
            </div>
            <MinimizedContainer
                open={isModelOpen}
                onClose={handleModelClose}
                hiddenWidgets={hiddenWidgets}
                onUnHideWidget={onUnHidingWidget}
                onShowAllHiddenWidgets={onShowAllHiddenWidgets}
            />
        </>
    )
}

const MinimizedContainer = ({ hiddenWidgets, onUnHideWidget, onShowAllHiddenWidgets, open, onClose }) => {
    const classes = useStyles()
    const { ERROR, EMPTY } = widgetConstants.DataStatus

    return (
        <Dialog
            open={open}
            onClose={onClose}
            hideBackdrop={true}
            classes={{ container: classes.backdrop, paper: classes.container }}
        >
            <DialogTitle classes={{ root: classes.titleRoot }}>
                <div className={classes.titleWrapper}>
                    <Typography variant="h6" className={classes.titleText}>
                        Hidden Widgets
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Tooltip title="Show all hidden widgets" placement="top">
                            <IconButton
                                classes={{ root: classes.closeIcon }}
                                onClick={onShowAllHiddenWidgets}
                                size="large"
                            >
                                <VisibilityRounded />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Close" placement="top">
                            <IconButton classes={{ root: classes.closeIcon }} onClick={onClose} size="large">
                                <Close />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className={classes.infoContainer}>
                    <TableContainer component={Paper}>
                        <Table size="medium">
                            <TableBody>
                                {hiddenWidgets.map(widget => (
                                    <TableRow key={widget}>
                                        <TableCell sx={{ width: '350px' }}>
                                            <WidgetTitle widgetId={widget} />
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                className={classes.refreshButton}
                                                onClick={() => onUnHideWidget(widget)}
                                            >
                                                <VisibilityRounded classes={{ root: classes.refresh }} />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </DialogContent>
        </Dialog>
    )
}

const WidgetTitle = ({ widgetId }) => {
    const title = useSelector(state => widgetSelectors.getWidgetTitle(state, widgetId))
    return (
        <ChopText variant="body1" showEllipsis tooltipProps={{ placement: 'top' }}>
            {title}
        </ChopText>
    )
}

export default HiddenWidgets
