import { RowModelType } from '@ag-grid-community/core'

export const agGridConstants = {
    PAGE_SIZE: 200,
    CACHE_BLOCK_SIZE: 100,
    MAX_BLOCKS_IN_CACHE: 10,
    ROW_BUFFER: 0,
    ROW_MODEL_TYPE: 'serverSide' as RowModelType,
    LIGHT_THEME: 'ag-theme-alpine',
    DARK_THEME: 'ag-theme-alpine-dark',
}