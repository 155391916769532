import { snackbarTypes } from '../types'

const enqueueSnackbar = notification => {
    const key = notification.options && notification.options.key

    return {
        type: snackbarTypes.ENQUEUE_SNACKBAR,
        notification: {
            ...notification,
            key: key || new Date().getTime() + Math.random(),
        },
    }
}

const closeSnackbar = key => ({
    type: snackbarTypes.CLOSE_SNACKBAR,
    dismissAll: !key, // dismiss all if no key has been defined
    key,
})

const removeSnackbar = key => ({
    type: snackbarTypes.REMOVE_SNACKBAR,
    key,
})

const error = message => dispatch => dispatch(enqueueSnackbar({ message, options: { variant: 'error' } }))

const info =
    (message, options = {}) =>
    dispatch =>
        dispatch(enqueueSnackbar({ message, options: { ...options, variant: 'info' } }))

export const snackbarCreators = {
    enqueueSnackbar,
    closeSnackbar,
    removeSnackbar,
    error,
    info,
}
