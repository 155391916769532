import moment from 'moment'
import React, { useState, useRef, useImperativeHandle } from 'react'

const UniversalCellEditor = React.forwardRef((props: any, ref) => {
    const { type, value: colValue } = props
    const [value, setValue] = useState(colValue)
    const inputRef = useRef<HTMLInputElement>()

    useImperativeHandle(ref, () => ({
        getValue: () => value,
        isCancelBeforeStart: () => false,
        isCancelAfterEnd: () => false,
        focusIn: () => {
            inputRef.current.focus()
            return true
        },
        focusOut: () => {
            return true
        },
    }))
    const handleChange = event => {
        setValue(event.target.value)
    }
    switch (type) {
        case 'number':
            return (
                <input
                    type="number"
                    className="ag-cell-editing"
                    style={{ width: 'inherit' }}
                    ref={inputRef}
                    value={value}
                    onChange={handleChange}
                    autoFocus
                />
            )
        case 'date':
            return (
                <input
                    className="ag-cell-editing"
                    type="date"
                    style={{ width: 'inherit' }}
                    ref={inputRef}
                    onChange={handleChange}
                    value={moment(value).format('YYYY-MM-DD')}
                />
            )
        case 'dateTime':
            return (
                <input
                    className="ag-cell-editing"
                    type="datetime-local"
                    ref={inputRef}
                    value={moment(value).format('YYYY-MM-DDTHH:mm')}
                    style={{ width: 'inherit' }}
                    onChange={handleChange}
                />
            )
        case 'string':
            return (
                <input
                    className="ag-cell-editing"
                    type="text"
                    style={{ width: 'inherit' }}
                    ref={inputRef}
                    onChange={handleChange}
                    value={value}
                    autoFocus
                />
            )
        default:
            return <span>Unsupported field type</span>
    }
})
export default UniversalCellEditor
