import { useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ChopText } from 'genesis-suite/components'
import { memo, useContext, useRef, useState, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { isEmpty } from 'lodash'
import { filterCreators, interactionCreators, navigationCreators } from '../../actions/creators'
import { useIsMobile } from '../../hooks/useIsMobile'
import { TourTag } from '../../lib/tourSteps'
import { authSelectors, filterSelectors, widgetSelectors } from '../../selectors'
import WidgetFooterController from '../WidgetActions/WidgetActionController'
import { ColOptionsProvider } from '../contexts/ColumnOptionsContext'
import { PerspectiveContext } from '../contexts/PerspectiveContext'
import { PerspectiveWidgetProvider } from '../contexts/PerspectiveWidgetContext'
import WidgetConverter from './WidgetConverter'
import WidgetLoadingIcon from './WidgetLoadingIcon'
import WidgetMenu from './WidgetMenu'
import WidgetToolbar from './WidgetToolbar'
import useIsMobileControlSelect from './visuals/TableWidget/helpers/useIsMobileControlSelect'
import GanttInterval from './visuals/HighChartGanttWidget/GanttInterval'
import { ResourceData } from './visuals/HighChartGanttWidget/ResourceData'

const useStyles = makeStyles(({ palette }) => ({
    container: {
        height: '100%',
        position: 'relative',
        border: p => `1px solid ${p.isControlWidget ? palette.primary.main : palette.border?.main}`,
        backgroundColor: palette.background.widget,
        borderRadius: 15,
        display: 'flex',
        flexDirection: 'column',
        padding: '10px',
        overflow: 'hidden',
        '& .ui.dimmer': {
            borderRadius: '15px',
            boxSizing: 'border-box',
        },
    },
    topButtons: { display: 'flex', alignItems: 'center', flexShrink: 0 },
    emptyContainer: { height: '100%', position: 'relative' },
    toolbar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}))

/**
 * Renders a widget plus a title bar and menu of additional options
 * @param {Object} props.config - the widget config to be rended
 * @param {string} props.perspectiveId - id of the perspective the widget is being rended by
 * @param {function} props.fullscreenWidget - callback to nav to fullscreen mode (mobile only)
 * @param {function} props.showDialog - callback to open a dialog box for widget info
 */
function PerspectiveWidget({
    config: originalConfig,
    perspectiveId,
    isCompare = false,
    onPerspectiveDataLoaded,
    ...rest
}) {
    const isMobile = useIsMobile()
    const { controlWidget } = useContext(PerspectiveContext)
    const classes = useStyles({ isControlWidget: originalConfig.Id === controlWidget?.id })
    const { palette, custom } = useTheme()
    const iconStyle = { fontSize: custom.widgetToolbar?.fontSize, color: palette.text.primary }
    const buttonStyle = { padding: custom.widgetToolbar?.padding }
    const { hideFooter, hideHeader } = useHideOptions(originalConfig)
    const options = ResourceData(originalConfig)
    const [showTemporalSelector, setShowTemporalSelector] = useState(originalConfig.Type === 'Chart')
    const [data, setData] = useState({})
    const [config, setConfig] = useState(originalConfig)
    const [trendLines, setTrendLines] = useState([])

    const updateConfigAndRefresh = newConfig => {
        setConfig(newConfig)
    }

    /** That is use for Allow Multiple Edits in Forms */
    const globalMultiEditEnabled = useSelector(authSelectors.getIsFormMultiEditEnabled)
    const [canMultipleEdit, setCanMultipleEdit] = useState(globalMultiEditEnabled)

    const interactionType = useSelector(widgetSelectors.getInteractionType)
    const draftTitle = useSelector(widgetSelectors.getDraftTitles)[originalConfig.Id]
    const normalContext = useSelector(filterSelectors.getCoord)
    const compareContext = useSelector(filterSelectors.getCompareCoord)
    const inlineFilters = useSelector(state => filterSelectors.getInlineFilters(state))
    const normalFilters = useSelector(state => filterSelectors.currentFiltersConfiguration(state))
    const compareFilters = useSelector(state => filterSelectors.currentCompareFiltersConfiguration(state))

    const dispatch = useDispatch()
    const goToPerspective = (perspectiveId, context) =>
        dispatch(navigationCreators.goToPerspective(perspectiveId, { context }))
    const openDetails = (config, context) => dispatch(interactionCreators.openDetails(config, context))
    const openProfile = (...args) => dispatch(interactionCreators.openProfile(isMobile, ...args))
    const setInlineFilters = inlineFilters => dispatch(filterCreators.setInlineFilters(inlineFilters))

    const visualRef = useRef()

    const filters = isCompare ? compareFilters : normalFilters
    const context = isCompare ? compareContext : normalContext
    const showWidgetHeader = config.HeaderVisibility !== 1 && config.HeaderVisibility !== 2
    const showWidgetToolbar = config.HeaderVisibility !== 1 && config.HeaderVisibility !== 3
    const configWithCompare = useMemo(() => ({ ...config, isCompare }), [config, isCompare])
    const { CategoryDateFormat, LabelField } = showTemporalSelector ? originalConfig.ChartConfig : {}

    const onDataLoaded = data => {
        if (originalConfig.Type === 'Chart') {
            setShowTemporalSelector(!isEmpty(data))
        }
        setData(data)
    }

    useEffect(() => {
        onPerspectiveDataLoaded?.(data)
    }, [data])

    return (
        <PerspectiveWidgetProvider config={config} updateConfigAndRefresh={updateConfigAndRefresh}>
            <ColOptionsProvider id={config.Id}>
                <div className={classes.container} data-cy="widget-view-container">
                    {!hideHeader && (
                        <div className={classes.toolbar}>
                            <ChopText
                                variant="h6"
                                showEllipsis
                                tooltipProps={{ placement: 'top' }}
                                data-cy="widget-title"
                            >
                                {showWidgetHeader && (draftTitle || config.Title)}
                            </ChopText>
                            <div data-tour={TourTag.WidgetMenuButton} className={classes.topButtons}>
                                <WidgetLoadingIcon />
                                {showWidgetToolbar && (
                                    <>
                                        <WidgetToolbar
                                            config={config}
                                            data={data}
                                            visualRef={visualRef}
                                            perspectiveId={perspectiveId}
                                            iconStyle={iconStyle}
                                            buttonStyle={buttonStyle}
                                            canMultipleEdit={canMultipleEdit}
                                            setCanMultipleEdit={setCanMultipleEdit}
                                            isPopup={rest?.isPopup}
                                        />
                                        <WidgetMenu
                                            visualRef={visualRef}
                                            config={config}
                                            trendLines={trendLines}
                                            data={data}
                                            iconStyle={iconStyle}
                                            buttonStyle={buttonStyle}
                                            {...rest}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                    {originalConfig.Type === 'Gantt' && options.length > 0 && (
                        <GanttInterval config={configWithCompare} options={options} />
                    )}
                    <WidgetConverter
                        config={configWithCompare}
                        appMode="perspective"
                        setTrendLines={setTrendLines}
                        inlineFilters={inlineFilters}
                        interactionType={interactionType}
                        filters={filters}
                        goToPerspective={goToPerspective}
                        openDetails={openDetails}
                        openProfile={openProfile}
                        setInlineFilters={setInlineFilters}
                        ref={visualRef}
                        onLoad={onDataLoaded}
                        networkContext={context}
                        canMultipleEdit={canMultipleEdit}
                        setCanMultipleEdit={setCanMultipleEdit}
                        isCompare={isCompare}
                        {...rest}
                    />
                    {!hideFooter && (
                        <WidgetFooterController
                            config={originalConfig}
                            isEditing={rest.isEditing}
                            visualRef={visualRef}
                            filters={filters}
                            inlineFilters={inlineFilters}
                            networkContext={context}
                            temporalConfig={configWithCompare}
                            showTemporalSelector={showTemporalSelector}
                            onUpdate={setConfig}
                            categoryDateFormat={CategoryDateFormat}
                            originalCategoryPropertyName={LabelField}
                        />
                    )}
                </div>
            </ColOptionsProvider>
        </PerspectiveWidgetProvider>
    )
}

function useHideOptions(config) {
    let hideFooter = false,
        hideHeader = false

    const isMobileControlSelect = useIsMobileControlSelect(config)
    if (isMobileControlSelect) {
        hideFooter = true
        hideHeader = true
    }

    if (config.Type.toUpperCase() === 'RICHTEXT' && config.Title === '#blank') {
        hideHeader = true
    }

    if (config.HeaderVisibility === 1) {
        hideHeader = true
    }

    return { hideFooter, hideHeader }
}

export default memo(PerspectiveWidget)
