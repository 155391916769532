import { Scenario, ScenarioRequestType } from 'genesis-suite/types/networkTypes'
import { scenarioTypes } from '../types'
import { applicationSelectors, authSelectors } from '~/selectors'
import { scenarioService } from '~/lib/services'

const setActiveScenarios = (scenarios: Array<Scenario>) => {
    return { type: scenarioTypes.SET_ACTIVE_SCENARIOS, payload: scenarios }
}

const setUserScenarios = (createdScenarios: Array<Scenario>, assignedScenarios: Array<Scenario>) => {
    return {
        type: scenarioTypes.SET_USER_SCENARIOS,
        payload: {
            createdScenarios: createdScenarios,
            assignedScenarios: assignedScenarios,
        },
    }
}

const removeScenario = (scenarioId: string) => {
    return { type: scenarioTypes.REMOVE_SCENARIO, payload: scenarioId }
}

const getUserScenario = () => {
    return (dispatch, getState) => {
        const state = getState()
        const isPublish = authSelectors.getCanPublishScenario(state)
        const isApprove = authSelectors.getCanApproveScenario(state)
        const appName = applicationSelectors.getCurrentAppName(state)

        if (!((isPublish || isApprove) && appName)) return

        const publish = new Promise<Array<Scenario>>(resolve => {
            if (isPublish) {
                scenarioService
                    .getUserScenarios(appName, ScenarioRequestType.REQUESTS.toString())
                    .then(res => resolve(res))
                    .catch(() => resolve([]))
            } else {
                resolve([])
            }
        })

        const approvals = new Promise<Array<Scenario>>(resolve => {
            if (isApprove) {
                scenarioService
                    .getUserScenarios(appName, ScenarioRequestType.APPROVALS.toString())
                    .then(res => resolve(res))
                    .catch(() => resolve([]))
            } else {
                resolve([])
            }
        })

        return Promise.all([publish, approvals])
            .then(([tPublish, tApproval]) => dispatch(setUserScenarios(tPublish, tApproval)))
            .catch(error => dispatch(setUserScenarios([], [])))
    }
}

export const scenarioCreators = {
    setActiveScenarios,
    removeScenario,
    getUserScenario,
}
