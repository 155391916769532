import { Dialog, DialogTitle, DialogContent, Box, Typography, IconButton } from '@mui/material'
import WidgetShare from './WidgetShare'
import { Close } from 'genesis-suite/icons'

const WidgetShareDialog = ({ open, widgetConfig, onClose, imgParams }) => {
    return (
        <Dialog open={open} maxWidth="xs" fullWidth onClose={onClose}>
            <DialogTitle>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h6">Share Widget</Typography>
                    <IconButton sx={{ color: 'text.primary' }} onClick={onClose} size="large">
                        <Close />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <WidgetShare widgetConfig={widgetConfig} imgParams={imgParams} />
            </DialogContent>
        </Dialog>
    )
}

export default WidgetShareDialog
