import React, { useContext } from 'react'
import { Checkbox, Collapse, FormControlLabel, MenuItem, TextField } from '@mui/material'

import { Legend } from 'genesis-suite/types/visualTypes'
import { ConfigContext } from '../ConfigContext'
import EditorWrapper from './EditorWrapper'

export default function LegendEditor() {
    const { config, dispatch } = useContext(ConfigContext)
    const { legend } = config
    const { enabled = true, placement = 'top', maxRows = 2, reversed = false } = legend || {}

    function handleUpdate(payload: Partial<Legend>) {
        dispatch({ type: 'UPDATE_LEGEND', payload })
    }

    return (
        <EditorWrapper header="Legend">
            <FormControlLabel
                control={<Checkbox checked={enabled} onChange={e => handleUpdate({ enabled: e.target.checked })} />}
                label="Enabled"
            />

            <Collapse in={enabled}>
                <TextField
                    select
                    fullWidth
                    margin="dense"
                    label="Placement"
                    value={placement}
                    onChange={e => handleUpdate({ placement: e.target.value as Legend['placement'] })}
                >
                    {placementOptions.map(({ value, label }) => (
                        <MenuItem key={value} value={value}>
                            {label}
                        </MenuItem>
                    ))}
                </TextField>

                <Collapse in={placement === 'top' || placement === 'bottom'}>
                    <TextField
                        fullWidth
                        type="number"
                        margin="dense"
                        inputProps={{ min: '1' }}
                        label="Max rows"
                        value={maxRows}
                        onChange={e => handleUpdate({ maxRows: parseInt(e.target.value) })}
                    >
                        {placementOptions.map(({ value, label }) => (
                            <MenuItem key={value} value={value}>
                                {label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Collapse>

                <FormControlLabel
                    control={
                        <Checkbox checked={reversed} onChange={e => handleUpdate({ reversed: e.target.checked })} />
                    }
                    label="Reversed"
                />
            </Collapse>
        </EditorWrapper>
    )
}

const placementOptions: { value: Legend['placement']; label: string }[] = [
    { value: 'top', label: 'Top' },
    { value: 'left', label: 'Left' },
    { value: 'right', label: 'Right' },
    { value: 'bottom', label: 'Bottom' },
]
