import { Box } from '@mui/material'
import { SyncFusionRTE } from 'genesis-suite/components'
import { TextConfig } from 'genesis-suite/types/visualTypes'
import { forwardRef, useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'

interface TextWidgetProps {
    config: TextConfig
    /** (optional) if provided, allow editing of the config */
    onChange?: (config: TextConfig) => void
}

export default forwardRef<HTMLDivElement, TextWidgetProps>(({ config, onChange }, ref) => {
    const [text, setText] = useState(config?.text ?? '')

    const [debouncedText] = useDebounce(text, 500)
    useEffect(() => {
        onChange && onChange({ ...config, text: debouncedText ?? '' })
    }, [debouncedText])

    return (
        <Box ref={ref} sx={{ height: '100%', overflow: 'auto' }}>
            <SyncFusionRTE editable={Boolean(onChange)} value={config.text} onChange={value => setText(value)} />
        </Box>
    )
})
