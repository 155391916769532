import { InfoOutlined as Info } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import classNames from 'classnames'
import { SplitButton } from 'genesis-suite/components'
import { useHover } from 'genesis-suite/hooks'
import { isEmpty } from 'lodash'
import { useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import trashIcon from '../assets/svg/trash.svg'
import { useIsMobile } from '../hooks/useIsMobile'
import { authSelectors } from '../selectors'
import { FilterSourceType } from '../types/FilterTypes'
import FilterGroupController from './FilterGroupController'
import FilterInput from './FilterInput'
import { isSelect } from './FilterOperatorButton'

const filterWidth = 300
const styles = ({ palette, spacing, breakpoints }) => ({
    wrapper: {
        flex: 1,
        display: 'flex',
        flexFlow: 'column wrap',
        justifyContent: 'space-around',
    },
    sourceInfo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    infoIcon: {
        margin: spacing(1),
    },
    contentContainer: {
        overflow: 'auto',
        display: 'flex',
        flexFlow: 'column wrap',
        alignContent: 'center',
        maxWidth: '900px',
        margin: '25px 0 5px',
        [breakpoints.up('sm')]: {
            maxHeight: '50vh',
        },
    },
    noFiltersText: { padding: spacing(1), margin: 0 },
    controlsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        [breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            alignItems: 'center',
        },
    },
    buttons: {
        [breakpoints.down('sm')]: {
            width: '200px',
        },
    },
    splitButtons: {
        margin: spacing(1),
    },
    primarySplitButton: {
        [breakpoints.down('sm')]: {
            width: '200px',
        },
    },
    clearAllButton: {
        backgroundColor: '#f13f6b',
        color: '#000000',
    },
    applyButton: { backgroundColor: palette.secondary.main, color: palette.getContrastText(palette.secondary.main) },
    cancelButton: {
        backgroundColor: palette.grayscale.lighter,
        color: palette.getContrastText(palette.grayscale.lighter),
    },
    defaultButton: {
        backgroundColor: palette.grayscale.lighter,
        color: palette.getContrastText(palette.grayscale.lighter),
    },
    listContainer: { display: 'flex', flexFlow: 'row wrap', marginBottom: '15px' },
    filterLabel: { fontWeight: 'bold' },
})

const CHANGE_VALUE = 'CHANGE_VALUE'
const CHANGE_RANGE = 'CHANGE_RANGE'
const CHANGE_RANGE_OFFSET = 'CHANGE_RANGE_OFFSET'
const CHANGE_CLICK_RANGE = 'CHANGE_CLICK_RANGE'
const CHANGE_OPERATOR = 'CHANGE_OPERATOR'
const CLEAR_ALL = 'CLEAR_ALL'
const CANCEL = 'CANCEL'
const INIT = 'INIT'

const FiltersSelector = ({
    filters: filterConfigs,
    selectedValues,
    saveFilters,
    saveFiltersForAll,
    applyFilters,
    onChange,
    onClear,
    onCancel,
    onSave,
    onSaveForAll,
    onApply,
    source,
    type,
    classes,
    isCompare = false,
}) => {
    const reducer = (state, action) => {
        const { type, payload } = action

        switch (type) {
            case CHANGE_VALUE: {
                const { id, values } = payload
                return {
                    ...state,
                    [id]: { ...state[id], values: values.filter(Boolean) },
                }
            }
            case CHANGE_RANGE: {
                const { id, range } = payload
                return {
                    ...state,
                    [id]: { ...state[id], range },
                }
            }
            case CHANGE_RANGE_OFFSET: {
                const { id, ...rest } = payload
                return {
                    ...state,
                    [id]: { ...state[id], ...rest },
                }
            }
            case CHANGE_CLICK_RANGE: {
                const { id, clickRangeName } = payload
                return {
                    ...state,
                    [id]: { ...state[id], clickRangeName },
                }
            }
            case CHANGE_OPERATOR: {
                const { id, operator } = payload
                const wasSelect = isSelect(state[id].operator)
                const toBeSelect = isSelect(operator)
                const values =
                    wasSelect && !toBeSelect
                        ? [state[id].values[0]?.label ?? '']
                        : !wasSelect && toBeSelect
                        ? []
                        : state[id].values

                return {
                    ...state,
                    [id]: { ...state[id], operator, values },
                }
            }
            case CANCEL:
                return filterConfigs.reduce((acc, filterConfig) => {
                    const { Name: id } = filterConfig
                    acc[id] = {
                        values: [],
                        range: {},
                        ...selectedValues[id],
                    }
                    return acc
                }, {})
            case CLEAR_ALL:
                return filterConfigs.reduce((acc, filterConfig) => {
                    acc[filterConfig.Name] = emptyFilter
                    return acc
                }, {})
            case INIT:
                return filterConfigs.reduce((acc, filterConfig) => {
                    const { Name: id } = filterConfig
                    acc[id] = { ...emptyFilter, ...selectedValues[id] }
                    return acc
                }, {})
            default:
                return
        }
    }

    const [filters, dispatch] = useReducer(reducer, {})

    useEffect(() => {
        dispatch({ type: INIT })
    }, [filterConfigs, selectedValues])

    const sourceInfo = useMemo(() => {
        switch (source) {
            case FilterSourceType.USER_DEFAULTS:
                return 'You are seeing these filters because you have saved them to your user preferences.'
            case FilterSourceType.WIDGET_DEFAULTS:
                return 'You are seeing these filters because they were set by your system administrator.'
            default:
                return
        }
    }, [source])

    const handleValueChange = (filter, values, action) => {
        const { Name: id } = filter
        if (!Array.isArray(values)) values = [values]
        dispatch({
            type: CHANGE_VALUE,
            payload: { id, values },
        })
        onChange(filter, values, action)
    }

    const handleRangeChange = (filter, range) => {
        const { Name: id } = filter
        dispatch({
            type: CHANGE_RANGE,
            payload: { id, range },
        })
    }

    const handleDateOffsetChange = (filter, rangeOffset, useLastRefreshDate, startDate, endDate) => {
        const { Name: id } = filter
        dispatch({
            type: CHANGE_RANGE_OFFSET,
            payload: { id, rangeOffset, useLastRefreshDate, startDate, endDate },
        })
    }

    const handleClickRangeChange = (filter, clickRangeName) => {
        const { Name: id } = filter
        dispatch({
            type: CHANGE_CLICK_RANGE,
            payload: { id, clickRangeName },
        })
    }

    const handleOperatorChange = (filter, operator) => {
        const { Name: id } = filter
        dispatch({
            type: CHANGE_OPERATOR,
            payload: { id, operator },
        })
    }

    const handleClear = () => {
        dispatch({
            type: CLEAR_ALL,
        })
        onClear()
    }

    const [dirty, setDirty] = useState(false)
    const makeDirty =
        cb =>
        (...args) => {
            if (!dirty) setDirty(true)
            cb(...args)
        }

    const handleCancel = () => {
        dispatch({
            type: CANCEL,
        })
        onCancel()
        setDirty(false)
    }

    const handleSave = (saveFunc, saveCallback) => {
        saveFunc(filters)
        saveCallback()
    }

    const handleApply = () => {
        const updatedFilters = Object.keys(filters).reduce((acc, key) => {
            const config = filterConfigs.find(f => f.Name === key)
            if (!isEmpty(config)) {
                acc[key] = { ...filters[key], filterConfig: config }
            }

            return acc
        }, {})
        applyFilters(updatedFilters)
        onApply()
        setDirty(false)
    }

    const handleFGApply = filtersToApply => {
        handleClear()
        filtersToApply?.map(({ filterVal, filterConfig }) => {
            const { values, range, dateRangeOffset, useLastRefreshDate, operator } = filterVal
            if (operator) handleOperatorChange(filterConfig, operator)
            if (values.length) handleValueChange(filterConfig, values)
            if (!isEmpty(range)) handleRangeChange(filterConfig, range)
            if (!isEmpty(dateRangeOffset) || useLastRefreshDate)
                handleDateOffsetChange(filterConfig, dateRangeOffset, useLastRefreshDate)
        })
        setDirty(true)
    }

    const isMobile = useIsMobile()
    const isPowerUser = useSelector(authSelectors.getIsPowerUser)

    const containerRef = useRef()
    const isHovering = useHover(containerRef)
    const [delayedHovering, setDelayedHovering] = useState(false)
    const hoverTimeout = useRef()
    useEffect(() => {
        clearTimeout(hoverTimeout.current)
        if (!isHovering) setDelayedHovering(false)
        else hoverTimeout.current = setTimeout(() => setDelayedHovering(true), 400)
    }, [isHovering])

    const saveOptions = [{ onClick: () => handleSave(saveFilters, onSave), label: 'Save as Default' }]

    if (saveFiltersForAll && isPowerUser && !isMobile)
        saveOptions.push({ onClick: () => handleSave(saveFiltersForAll, onSaveForAll), label: 'Save for All' })

    if (!filterConfigs || filterConfigs.length < 1) {
        return <h2 className={classes.noFiltersText}>No filters to display</h2>
    }

    // 45vh is the maxHeight of the contentContainer
    const maxFilterHeight = 50 / filterConfigs.length
    const compoundFilterConfigs = filterConfigs?.filter(conf => conf.IsCompound)

    return (
        <div ref={containerRef} className={classes.wrapper}>
            <FilterGroupController
                filterConfigs={filterConfigs}
                perspectiveFilters={filters}
                onChange={handleFGApply}
            />
            <div className={type === 'flex' ? undefined : classes.contentContainer}>
                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                    {filterConfigs.map(filter => {
                        const { Name: id } = filter
                        const { [id]: selected } = filters
                        const compoundFilters = []

                        if (filter.IsCompound && !isEmpty(compoundFilterConfigs)) {
                            compoundFilterConfigs.forEach(comFilConf => {
                                if (comFilConf.Name !== filter.Name && !isEmpty(filters[comFilConf.Name])) {
                                    const { Name, Source, KeyPropertyName, IsTemporal, PostCalculation } = comFilConf

                                    const { ElementName, ElementType } = Source

                                    const { range, values, rangeOffset, clickRangeName, useLastRefreshDate, operator } =
                                        filters[Name]

                                    if (
                                        !isEmpty(range) ||
                                        rangeOffset?.min != null ||
                                        rangeOffset?.max != null ||
                                        !isEmpty(values)
                                    ) {
                                        const filterObj = {
                                            FilterName: Name,
                                            ResourceName: ElementName,
                                            ResourceType: ElementType,
                                            PropertyName: KeyPropertyName,
                                            ClickRangeName: clickRangeName,
                                            IsTemporal: IsTemporal,
                                            UseLastRefreshDate: useLastRefreshDate,
                                            PostCalculation: PostCalculation,
                                            Operator: operator,
                                        }
                                        /**
                                         * At a time either values or range or rangeoffset can be present in filter.
                                         * All 3 together in single filter not likely as these come from different filter inputs.
                                         * Hence including only one of these based on the availability
                                         */
                                        if (!isEmpty(values)) filterObj['Values'] = values.map(val => val.value)
                                        else if (rangeOffset?.min != null || rangeOffset?.max != null)
                                            filterObj['DateRangeOffset'] = rangeOffset
                                        else if (!isEmpty(range)) filterObj['Range'] = range

                                        compoundFilters.push(filterObj)
                                    }
                                }
                            })
                        }

                        return (
                            <FilterInput
                                key={id}
                                filter={filter}
                                borderRadius={type === 'flex' ? '4px' : ''}
                                selectedValues={selected || emptyFilter}
                                contextFilters={compoundFilters}
                                onValueChange={makeDirty(handleValueChange)}
                                onRangeChange={makeDirty(handleRangeChange)}
                                onDateOffsetChange={makeDirty(handleDateOffsetChange)}
                                onClickRangeChange={makeDirty(handleClickRangeChange)}
                                onOperatorChange={makeDirty(handleOperatorChange)}
                                maxFilterHeight={maxFilterHeight}
                                sx={{ width: filterWidth, flex: '1 1 auto' }}
                                data-cy="autocomplete-input"
                            />
                        )
                    })}
                </Box>
            </div>
            {sourceInfo && !isCompare && (
                <div className={classes.sourceInfo}>
                    <Info className={classes.infoIcon} />
                    <Typography variant="body1" display="inline">
                        {sourceInfo}
                    </Typography>
                </div>
            )}
            <div className={classes.controlsContainer}>
                <div className={classes.buttonGroup}>
                    <Button
                        img={trashIcon}
                        className={classNames(classes.buttons, classes.clearAllButton)}
                        onClick={makeDirty(handleClear)}
                        variant="contained"
                    >
                        Clear All
                    </Button>
                    {!isCompare && (
                        <SplitButton
                            buttonGroupProps={{ className: classes.splitButtons }}
                            buttonProps={{ className: classes.primarySplitButton }}
                            options={saveOptions}
                        />
                    )}
                </div>
                <div>
                    <Button className={classNames(classes.buttons)} onClick={handleCancel} disabled={!dirty}>
                        Cancel
                    </Button>
                    <Button
                        className={classes.buttons}
                        onClick={handleApply}
                        variant="contained"
                        disabled={!dirty}
                        data-cy="filter-apply-btn"
                    >
                        Apply
                    </Button>
                </div>
            </div>
        </div>
    )
}

const emptyFilter = {
    values: [],
    range: {},
    rangeOffset: {},
    useLastRefreshDate: false,
    clickRangeName: null,
    operator: 'EqualTo',
}

FiltersSelector.defaultProps = {
    onChange: () => {},
    onClear: () => {},
    onCancel: () => {},
    onSave: () => {},
    onSaveForAll: () => {},
    onApply: () => {},
}

export default withStyles(styles)(FiltersSelector)
