import buildRoute from 'genesis-suite/utils/buildRoute'
import { lazy } from 'react'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import { routePaths, subPaths } from '../../lib/routes'
import SuspenseLoader from '../../components/SuspenseLoader'

const BusinessElementView = lazy(() => import('../BusinessElementView'))
const BusinessExplorerView = lazy(() => import('../BusinessExplorerView'))
const ChangeReviewView = lazy(() => import('../ChangeReviewView'))
const ComparePerspectiveView = lazy(() => import('../ComparePerspectiveView'))
const ForecastView = lazy(() => import('../../components/analytics/ForecastView'))
const FullscreenWidgetView = lazy(() => import('../FullscreenWidgetView'))
const HomePageManageView = lazy(() => import('../HomePageManageView'))
const HomeView = lazy(() => import('../HomeView'))
const ManageView = lazy(() => import('../ManageView'))
const MenuView = lazy(() => import('../MenuView'))
const NetworkDiagram = lazy(() => import('../../components/network_architecture/NetworkDiagram'))
const PerspectiveView = lazy(() => import('../PerspectiveView'))
const TreeView = lazy(() => import('../TreeView'))
const WidgetView = lazy(() => import('../WidgetView'))
const GenerateSimulatorView = lazy(() => import('../bcsForm/GenerateSimulatorView'))

const AppViewRouter = () => {
    const { appName, moduleName } = useParams()
    const { PERSPECTIVE, COMPARE_PERSPECTIVE, WIDGET } = subPaths
    const routes = [
        {
            path: 'analytics',
            element: <ForecastView />,
        },
        {
            path: 'business-explorer',
            element: <BusinessExplorerView />,
        },
        {
            path: 'change-review',
            component: <ChangeReviewView />,
        },
        {
            path: 'element/:elementName',
            element: <BusinessElementView />,
        },
        {
            path: `${PERSPECTIVE}/fullscreen`,
            element: <FullscreenWidgetView />,
        },
        {
            path: 'home',
            element: <HomeView />,
        },
        {
            path: 'home-dashboard',
            element: <HomePageManageView />,
        },
        {
            path: 'manage',
            element: <ManageView />,
        },
        {
            path: 'menu',
            element: <MenuView />,
        },
        {
            path: 'network',
            element: <NetworkDiagram />,
        },
        {
            path: PERSPECTIVE,
            element: <PerspectiveView />,
        },
        {
            path: COMPARE_PERSPECTIVE,
            element: <ComparePerspectiveView />,
        },
        {
            path: WIDGET,
            element: <WidgetView />,
        },
        {
            path: 'tree/:treeName',
            element: <TreeView />,
        },
        {
            path: 'ai-analysis',
            element: <GenerateSimulatorView />,
        },
    ]
    return (
        <Routes>
            {routes?.map(({ path, element }) => (
                <Route key={path} path={path} element={<SuspenseLoader>{element}</SuspenseLoader>} />
            ))}
            <Route path="*" element={<Navigate to={buildRoute(routePaths.HOME, appName, moduleName)} />} />
        </Routes>
    )
}

export default AppViewRouter
