import { useState, useContext } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Collapse, Typography } from '@mui/material'

import {
    ConditionalFormat,
    ColorFormat,
    DataField,
    Aggregation,
    ComparisonOperator,
} from 'genesis-suite/types/visualTypes'
import EditorWrapper from '../EditorWrapper'
import { ConfigContext } from '../../ConfigContext'
import FormatEditorColorSelector from './FormatEditorColorSelector'
import { getDataFieldProperty, getDefaultAggregation } from '../../utils'
import { ConditionalFormatListEditor } from './ConditionalFormatListEditor'
import ConditionalFormatSelectedEditor from './ConditionalFormatSelectedEditor'

const useStyles = makeStyles({
    colorSelectorWrapper: { display: 'grid', gridTemplateColumns: '60px auto', alignItems: 'center' },
})

interface Props {
    serviceId: string
    conditionalFormats: ConditionalFormat[]
    field: DataField
    aggregation: Aggregation
    format: ColorFormat
    onFormatChange: (value: ColorFormat) => void
    onConditionalFormatsChange: (value: ConditionalFormat[]) => void
}

export default function CellFormatEditor({
    serviceId,
    conditionalFormats,
    field,
    aggregation,
    format,
    onFormatChange,
    onConditionalFormatsChange,
}: Props) {
    const { resources } = useContext(ConfigContext)
    const [selectedRuleIndex, setSelectedRuleIndex] = useState<number>(null)

    const { properties } = resources.byId[serviceId] ?? {}

    const selectedRule = selectedRuleIndex != null && conditionalFormats?.[selectedRuleIndex]
    const currentFormat = selectedRule ? selectedRule.format : format
    const { semanticType } = getDataFieldProperty(field, properties)
    const defaultAggregation = aggregation === Aggregation.NONE ? Aggregation.NONE : getDefaultAggregation(semanticType)

    const classes = useStyles()

    function handleAddRule() {
        const newRule: ConditionalFormat = {
            source: { field, aggregation: defaultAggregation },
            rule: ComparisonOperator.EQUAL,
            format: {},
        }

        onConditionalFormatsChange([...(conditionalFormats ?? []), newRule])
        setSelectedRuleIndex(conditionalFormats?.length ?? 0)
    }

    function handleDeleteRule(index) {
        onConditionalFormatsChange(conditionalFormats.filter((c, i) => i !== index))
        setSelectedRuleIndex(null)
    }

    function handleUpdateSelectedRuleSource(field: DataField) {
        updateSelectedRule({
            source: { field, aggregation: defaultAggregation },
            rule: ComparisonOperator.EQUAL,
            target: null,
        })
    }

    function handleSelectedRuleTargetSource(field: DataField) {
        updateSelectedRule({
            target: null,
            targetSource: { field, aggregation: defaultAggregation },
        })
    }

    function updateSelectedRule(update: Partial<ConditionalFormat>) {
        onConditionalFormatsChange(
            conditionalFormats.map((c, i) => (i === selectedRuleIndex ? { ...c, ...update } : c))
        )
    }

    const updateColor = (colorProp: keyof ColorFormat) => value =>
        selectedRule
            ? updateSelectedRule({ format: { ...selectedRule.format, [colorProp]: value } })
            : onFormatChange({ ...format, [colorProp]: value })

    const removeColor = (colorProp: keyof ColorFormat) => () =>
        selectedRule
            ? updateSelectedRule({ format: { ...selectedRule.format, [colorProp]: undefined } })
            : onFormatChange({ ...format, [colorProp]: undefined })

    return (
        <EditorWrapper header="Color format">
            <ConditionalFormatListEditor
                conditionalFormats={conditionalFormats}
                selectedIndex={selectedRuleIndex}
                onIndexSelect={setSelectedRuleIndex}
                onDragEnd={v => onConditionalFormatsChange(v)}
                onAdd={handleAddRule}
                onDelete={handleDeleteRule}
            />

            <Collapse in={Boolean(selectedRule)}>
                <ConditionalFormatSelectedEditor
                    selectedRule={selectedRule}
                    onUpdate={updateSelectedRule}
                    onUpdateSource={handleUpdateSelectedRuleSource}
                    onUpdateTargetSource={handleSelectedRuleTargetSource}
                    properties={properties}
                />
            </Collapse>

            <div className={classes.colorSelectorWrapper}>
                <Typography variant="subtitle2">Fill</Typography>
                <FormatEditorColorSelector
                    value={currentFormat?.backgroundColor}
                    onChange={updateColor('backgroundColor')}
                    resetButtonProps={{
                        disabled: !currentFormat?.backgroundColor,
                        onClick: removeColor('backgroundColor'),
                    }}
                />
            </div>

            <div className={classes.colorSelectorWrapper}>
                <Typography variant="subtitle2">Font</Typography>
                <FormatEditorColorSelector
                    value={currentFormat?.fontColor}
                    onChange={updateColor('fontColor')}
                    resetButtonProps={{
                        disabled: !currentFormat?.fontColor,
                        onClick: removeColor('fontColor'),
                    }}
                />
            </div>

            <div className={classes.colorSelectorWrapper}>
                <Typography variant="subtitle2">Border</Typography>
                <FormatEditorColorSelector
                    value={currentFormat?.borderColor}
                    onChange={updateColor('borderColor')}
                    resetButtonProps={{
                        disabled: !currentFormat?.borderColor,
                        onClick: removeColor('borderColor'),
                    }}
                />
            </div>
        </EditorWrapper>
    )
}
