import { dataSourceIsEqual } from '../../edit_widget/utils'
import { ConditionalFormat, SeriesRequest, Rule } from 'genesis-suite/types/visualTypes'

export default function makeConditionalRules(
    conditionalFormats: ConditionalFormat[],
    request: SeriesRequest,
    offset = 0
): Rule[] {
    if (!conditionalFormats) return

    return conditionalFormats.map(cf => ({
        ...cf,
        sourceDataIndex: cf.source ? request.values.findIndex(r => dataSourceIsEqual(r, cf.source)) + offset : offset,
        ...(cf.targetSource && {
            targetDataIndex: request.values.findIndex(r => dataSourceIsEqual(r, cf.targetSource)) + offset,
        }),
    }))
}
