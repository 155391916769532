import { useContext } from 'react'

import ContextFilterSelector from '../ContextFilterSelector'
import { DashboardContext } from './DashboardContext'

export default function DashboardFilters({ onDone }) {
    const { filters, filterSource, updateFilters } = useContext(DashboardContext)

    return <ContextFilterSelector filters={filters} onApply={updateFilters} onDone={onDone} source={filterSource} />
}
