import { Skeleton } from '@mui/material'
import { useEffect, useState } from 'react'

import useFetchWidgetData from '../widgets/hooks/useFetchWidgetData'
import WidgetInfoEmptyText from './WidgetInfoEmptyText'

export default function WidgetInfoName({ config }) {
    const { fetchData } = useFetchWidgetData({ ...config, Context: undefined })
    const [name, setName] = useState<string>()

    useEffect(() => {
        fetchData(1, 1)
            .then(res => {
                const parsedConfig = JSON.parse(res.config)
                const dataForm = JSON.parse(parsedConfig?.FormConfig?.DataForm)
                setName(dataForm?.Name ?? null)
            })
            .catch(err => {
                console.error(err)
                setName(null)
            })
    }, [config])

    if (name) return <div dangerouslySetInnerHTML={{ __html: name }} />
    if (name === undefined) return <Skeleton height={20} width={150} />
    return <WidgetInfoEmptyText>No form name available</WidgetInfoEmptyText>
}
