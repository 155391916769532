import { default as DeleteIcon } from '@mui/icons-material/DeleteOutline'
import { ChopText, ComparisonSelector } from 'genesis-suite/components'
import { Property } from 'genesis-suite/types/networkTypes'
import { Aggregation, Filter, FilterGroup } from 'genesis-suite/types/visualTypes'
import PropertyChip from './PropertyChip'
import { getDataFieldProperty, getDisplayProperty } from './utils'
import ValueField from './ValueField'

import { Box, IconButton } from '@mui/material';

interface FilterEditorProps {
    filter?: FilterGroup
    properties: Property[]
    onChange: (filter: FilterGroup) => void
}

const FilterEditor = ({ filter, properties, onChange }: FilterEditorProps) => {
    const { items } = filter ?? { items: [] }

    function updateFilter(index: number, update: Partial<Filter>) {
        onChange({ ...filter, items: items.map((f, i) => (i === index ? { ...f, ...update } : f)) })
    }

    function handleDelete(index) {
        onChange({ ...filter, items: items.filter((_, i) => i !== index) })
    }

    return (
        <>
            {items.map((f, i) => {
                const { displayName, semanticType } = getDataFieldProperty(f.field, properties)
                return (
                    <Box
                        key={i}
                        sx={{ padding: 0.5, mb: 1, border: 1, borderColor: 'grayscale.light', borderRadius: 1 }}
                    >
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <ChopText showEllipsis>{displayName}</ChopText>
                            <IconButton size="small" onClick={() => handleDelete(i)}>
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </Box>

                        <ComparisonSelector
                            value={f.comparison}
                            onChange={comparison => updateFilter(i, { comparison })}
                            semanticType={semanticType}
                        />

                        {f.targetField ? (
                            <PropertyChip
                                property={getDisplayProperty(
                                    { aggregation: Aggregation.NONE, field: f.targetField },
                                    getDataFieldProperty(f.targetField, properties)
                                )}
                                onRemove={() => updateFilter(i, { targetField: undefined })}
                            />
                        ) : (
                            <ValueField
                                {...f}
                                onAdd={targetField => updateFilter(i, { targetField })}
                                onChange={v => {
                                    let values = v
                                    if (v != null && !Array.isArray(v)) values = [v]
                                    updateFilter(i, { values })
                                }}
                                properties={properties}
                            />
                        )}
                    </Box>
                )
            })}
        </>
    )
}

export default FilterEditor
