import { IRowNode } from '@ag-grid-community/core'
import { ResourceType } from 'genesis-suite/types/networkTypes'
import { DataResponse, Filter, FilterGroup, LayoutGroup, ReportValueField } from 'genesis-suite/types/visualTypes'
import tadacharts from 'tadacharts/es/widgetFunctions'

import { isNil } from 'lodash'
import { IReportTableRowData } from './ReportTable'

export const getGroupFilters = (group: LayoutGroup, filters: Filter[] = null, source: any): FilterGroup => {
    if (!filters) return group?.rules
    const groupFilters: FilterGroup = {
        type: 'group',
        group: 'AND',
        items: [],
    }
    groupFilters.items = filters.map(f => {
        return {
            type: 'filter',
            comparison: f.comparison,
            field: {
                name: f.field.name,
                resourceName: source.ElementName,
                resourceType: ResourceType.INSIGHT,
            },
            values: f.values,
        }
    })

    groupFilters.items.push(group.rules)
    return groupFilters
}

export const convertDataToGroupData = (
    groups: LayoutGroup[],
    response: DataResponse,
    valueFields: ReportValueField[],
    parentNode: IRowNode<any>,
    firstGroupData: IReportTableRowData = null
): IReportTableRowData[] => {
    const layoutData: IReportTableRowData[] = []
    const propertyFields = valueFields
        .filter(v => v.propertyType.toLocaleLowerCase() == 'property')
        .map(v => {
            return { id: v.id, name: v.field.name }
        })

    const addLayoutData = (group: LayoutGroup, groupData: IReportTableRowData) => {
        const seriesData = response[group.title]
        if (!seriesData || seriesData.length === 0) return

        seriesData.forEach(item => {
            //aggregated row name on the left
            const groupRow: IReportTableRowData = {
                [group.levelName]: group.title,
                key: '',
                computeColumnDefs: group.computeColumnDefs,
            }
            let hasValue = false
            let key = `${group.id}-${group.title}`
            const widgetFunctionFields = []
            for (const valueField of valueFields) {
                if (valueField.propertyType.toLocaleLowerCase() !== 'property') {
                    widgetFunctionFields.push(valueField)
                    continue
                }
                let fieldValue: string | number = item[valueField.title]
                groupRow[`${valueField.field?.name || valueField.title}_raw`] = fieldValue
                if (isNil(fieldValue)) {
                    fieldValue = '--'
                } else hasValue = true
                //actual value of the row
                groupRow[valueField.field?.name || valueField.title] = fieldValue
                key += fieldValue

                if (!valueField.percentageFieldTitle) continue
                groupRow[valueField.percentageFieldTitle] = getPercentageFieldValue(
                    valueField,
                    parentNode,
                    fieldValue,
                    groupData
                )
            }
            if (widgetFunctionFields.length > 0) {
                widgetFunctionFields.forEach(widgetFunctionField => {
                    let functionValue = tadacharts.calculateWidgetFunction(
                        groupRow,
                        widgetFunctionField.widgetFunction,
                        propertyFields
                    )
                    if (!isFinite(functionValue)) functionValue = 0
                    groupRow[widgetFunctionField.title] = functionValue ?? '--'
                    groupRow[`${widgetFunctionField.title}_raw`] = functionValue
                    if (widgetFunctionField.percentageFieldTitle) {
                        if (isNaN(functionValue)) groupRow[widgetFunctionField.percentageFieldTitle] = '--'
                        else
                            groupRow[widgetFunctionField.percentageFieldTitle] = getPercentageFieldValue(
                                widgetFunctionField,
                                parentNode,
                                functionValue,
                                firstGroupData
                            )
                    }
                })
            }

            groupRow.show = hasValue
            groupRow.key = key
            groupRow.nodePosition = group.id

            layoutData.push(groupRow)
        })
    }

    addLayoutData(groups[0], firstGroupData)

    const _firstGroupData = firstGroupData ? firstGroupData : layoutData[0]

    groups.slice(1).forEach(group => {
        addLayoutData(group, _firstGroupData)
    })

    return layoutData
}

export const getPercentageParentGroupData = (
    parentNode: IRowNode<any>,
    level: number,
    firstGroupData: IReportTableRowData,
    parentLevel = 1
) => {
    if (level === -1) return firstGroupData

    if (!parentNode.key) return parentNode.data

    if (parentLevel < level) return getPercentageParentGroupData(parentNode.parent, level, null, parentLevel + 1)

    return parentNode.data
}

export const getPercentageFieldValue = (
    value: ReportValueField,
    parentNode: IRowNode<IReportTableRowData>,
    fieldValue: string | number,
    firstGroupData: IReportTableRowData = null
): string => {
    const parentLevel = value.percentageParentLevel === 0 ? 1 : value.percentageParentLevel
    let percentageValue = 100
    const percentageParentGroupData = getPercentageParentGroupData(parentNode, parentLevel, firstGroupData)
    const denominatorValue =
        (parentNode.key || parentLevel === -1) && percentageParentGroupData
            ? percentageParentGroupData[value.field?.name || value.title]
            : fieldValue

    percentageValue =
        denominatorValue &&
        !isNaN(Number(fieldValue)) &&
        !isNaN(denominatorValue) &&
        isFinite(Number(fieldValue)) &&
        isFinite(denominatorValue)
            ? (Number(fieldValue) / denominatorValue) * 100
            : 0

    return percentageValue.toFixed(2) + '%'
}
