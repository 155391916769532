import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import LockRoundedIcon from '@mui/icons-material/LockRounded'
import { Box, IconButton, Tooltip } from '@mui/material'
import { Filter } from 'genesis-suite/icons'
import { isEmpty } from 'lodash'
import { networkFilterActions } from '~/redux/networkFilterSlice'
import { authSelectors, filterSelectors } from '~/selectors'
import { filterCreators, navigationCreators } from '../../actions/creators'
import useSearch from '../../hooks/useSearch'
import { logEvent } from '../../lib/amplitudeClient'
import GroupedList from '../GroupedList'
import useNetworkFilters from '../network-filters/useNetworkFilters'
import ProfileButton from '../profile/ProfileButton'
interface Props {
    text: string
    onNodeSelect?: () => void
}

/** Search for text in network and display a list of the results grouped by node with navigation actions */
export default function SearchListByNode({ text, onNodeSelect }: Props) {
    const dispatch = useDispatch()
    const goToPerspective = (id, state) => {
        onNodeSelect?.()
        dispatch(navigationCreators.goToPerspective(id, state))
    }

    const filterConfigs = useSelector(filterSelectors.getV1Configs)
    const appliedGlobalFilters = useSelector(filterSelectors.getAppliedGlobalFilters) || []
    const savedFilters = useSelector(state => authSelectors.getPreference(state, 'savedFilters'))
    const [networkFilters, isNetworkFiltersLoading] = useNetworkFilters()

    const applyGlobalFilter = context => {
        const { Name, FieldName, DisplayFieldName, Value, DisplayValue } = context
        const filterKey = Object.keys(filterConfigs)?.find(key => filterConfigs[key]?.Source?.ElementName === Name)
        const existingConfig = filterKey ? filterConfigs[filterKey] : null
        const filterId = existingConfig && existingConfig.Name ? existingConfig.Name : Name

        const filterConfig = {
            Name: context.Name,
            Source: { ElementName: context.Name },
            Title: context.Name,
            KeyPropertyName: DisplayFieldName ?? FieldName,
            IsTemporal: false,
            PostCalculation: false,
        }

        const newFilters = {
            ...appliedGlobalFilters,
            [filterId]: {
                ...emptyFilter,
                values: [{ label: DisplayValue ?? Value, value: Value }],
                filterConfig: filterConfig,
            },
        }
        dispatch(filterCreators.applyGlobalFilters(newFilters))
        onNodeSelect?.()
    }

    const applyFilters = filters => {
        const { global, perspective, search, network } = filters
        dispatch(filterCreators.applyGlobalFilters(global))
        dispatch(filterCreators.applyPerspectiveFilters(perspective))
        dispatch(filterCreators.applySearchFilters(search))
        dispatch(networkFilterActions.apply(network))
        onNodeSelect?.()
    }

    const applyNetworkFilters = id => {
        dispatch(networkFilterActions.apply([id]))
        onNodeSelect?.()
    }

    const [searchResults, fetching] = useSearch(text)
    const pending = fetching || isNetworkFiltersLoading

    const updateSavedFilterResults = items => {
        if (!isEmpty(savedFilters)) {
            const savedFilterNames = Object.keys(savedFilters)
            const matchingFilterNames = savedFilterNames.filter(item => item.toLowerCase().includes(text.toLowerCase()))
            if (!isEmpty(matchingFilterNames)) {
                const item = { primary: 'Saved Filters', list: [] }
                matchingFilterNames.forEach(name => {
                    item.list.push({
                        primary: name,
                        onClick: () => {
                            applyFilters(savedFilters[name])
                        },
                        action: () => (
                            <Tooltip title="Apply Filters">
                                <IconButton
                                    onClick={() => {
                                        applyFilters(savedFilters[name])
                                    }}
                                    sx={{ color: 'text.primary', p: 0.25 }}
                                >
                                    <Filter fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        ),
                    })
                })

                items.push(item)
            }
        }
    }

    const updateNetworkFilterResults = items => {
        if (!isEmpty(networkFilters)) {
            const matchingNetworkFilters = networkFilters.filter(nFilter =>
                nFilter.name.toLowerCase().includes(text.toLowerCase())
            )
            if (!isEmpty(matchingNetworkFilters)) {
                const item = { primary: 'Network Filters', list: [] }
                matchingNetworkFilters.forEach(filter => {
                    item.list.push({
                        primary: filter.name,
                        onClick: () => {
                            applyNetworkFilters(filter.id)
                        },
                        action: () => (
                            <Tooltip title="Apply Filters">
                                <IconButton
                                    onClick={() => {
                                        applyNetworkFilters(filter.id)
                                    }}
                                    sx={{ color: 'text.primary', p: 0.25 }}
                                >
                                    <Filter fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        ),
                    })
                })

                items.push(item)
            }
        }
    }

    const listItems = useMemo(() => {
        const items = []
        updateSavedFilterResults(items)
        updateNetworkFilterResults(items)

        if (searchResults) {
            searchResults.reduce((acc, cur) => {
                const { nodeName, displayProperty, perspectiveId, context } = cur
                if (displayProperty) {
                    let nodeListIndex = acc.findIndex(l => l.primary === nodeName)
                    if (nodeListIndex === -1) {
                        acc.push({ primary: nodeName, list: [] })
                        nodeListIndex = acc.length - 1
                    }

                    acc[nodeListIndex].list.push({
                        primary: displayProperty.value,
                        disabled: !perspectiveId,
                        onClick: () => {
                            if (!perspectiveId) return
                            goToPerspective(perspectiveId, { context })
                        },
                        action: () => (
                            <Box sx={{ display: 'flex' }}>
                                <Tooltip title="Lock Filters">
                                    <IconButton
                                        onClick={() => applyGlobalFilter(context)}
                                        sx={{ color: 'text.primary', p: 0.25 }}
                                    >
                                        <LockRoundedIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                                <ProfileButton
                                    nodeName={nodeName}
                                    fieldName={context.FieldName}
                                    value={context.Value}
                                    onClick={() => {
                                        logEvent('OPEN_PROFILE', { method: 'search' })
                                        onNodeSelect?.()
                                    }}
                                />
                            </Box>
                        ),
                    })
                }
                return acc
            }, items)
        }

        return items
    }, [searchResults])

    return <GroupedList data={listItems} pending={pending} />
}

const emptyFilter = {
    values: [],
    range: {},
    rangeOffset: {},
    useLastRefreshDate: false,
    clickRangeName: null,
    operator: 'EqualTo',
    filterConfig: {},
}
