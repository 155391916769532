import produce from 'immer'
import { ChartType } from 'genesis-suite/types/visualTypes'

/** remove any of the config that is not being used */
export default function cleanConfig(config) {
    return produce(config, draft => {
        const { type, xAxis, yAxis, series, categories } = draft
        if (type === ChartType.COMBO) {
            const hasPrimaryAxis = series.some(s => !s.yAxis)
            const hasSecondaryAxis = series.some(s => !!s.yAxis)
            if (!hasPrimaryAxis && yAxis.length === 2) {
                draft.yAxis.splice(0, 1)
                draft.series = draft.series.map(s => ({ ...s, yAxis: 0 }))
            } else if (!hasSecondaryAxis && yAxis.length === 2) draft.yAxis.splice(1, 1)
        }

        if (axisIsEmpty(xAxis)) draft.xAxis = {}
        if (!yAxis?.length || yAxis.every(axisIsEmpty)) draft.yAxis = [{}]

        categories.forEach(category => {
            if (category.dateFormat === undefined) delete category.dateFormat
        })
    })
}

function axisIsEmpty(axis) {
    if (!axis) return true
    const { title, ...otherAxisProps } = axis
    const { text, ...otherTitleProps } = title || {}
    const noOtherAxisProps = !Object.keys(otherAxisProps).length
    const noOtherTitleProps = !Object.keys(otherTitleProps).length
    if (noOtherAxisProps && noOtherTitleProps && !text) return true

    return false
}
