import { combineReducers } from 'redux'
import { LOCATION_CHANGE } from 'redux-first-history'
import { applicationTypes, moduleTypes, widgetTypes } from '../actions/types'
import { getPreference } from '../lib/browserPreferences'
import { widgetConstants } from '../constants'

const configs = (state = {}, action) => {
    const { payload } = action
    switch (action.type) {
        case moduleTypes.MODULE_SUCCESS:
            const {
                appModule: { Widgets: widgets },
            } = payload
            if (!widgets) return {}
            return formatWidgets(widgets)
        case applicationTypes.APPLICATION_SELECT:
        case moduleTypes.MODULE_CHANGE_REQUEST:
            return {}
        case widgetTypes.WIDGET_SAVE_SUCCESS:
            const { id, config } = payload
            return {
                ...state,
                [id]: config,
            }
        default:
            return state
    }
}

const formatWidgets = widgets => {
    const obj = {}
    widgets.forEach(widget => {
        const { Type, ContainerConfig } = widget || {}
        if (Type !== 'Container') return (obj[widget.Id] = widget)

        // for perspectives, filter out any widgets that don't exist (have configs)
        obj[widget.Id] = {
            ...widget,
            ContainerConfig: {
                ...widget.ContainerConfig,
                Cells: ContainerConfig.Cells?.filter(c => {
                    if (!c.WidgetId || widgets.some(w => w.Id === c.WidgetId)) return true

                    console.error(`Widget ${c.WidgetId} doesn't exist in perspective ${widget.Title}`)
                    return false
                }),
            },
        }
    })
    return obj
}

const currentLayout = (state = 0, action) => {
    const { payload } = action
    switch (action.type) {
        case widgetTypes.CHANGE_CURRENT_LAYOUT:
        case widgetTypes.CHANGE_SELECTED_LAYOUT:
            return payload.colNum
        case widgetTypes.START_PERSPECTIVE_EDITING:
            return 0
        default:
            return state
    }
}

const selectedLayout = (state = 0, action) => {
    const { payload } = action
    switch (action.type) {
        case widgetTypes.CHANGE_SELECTED_LAYOUT:
            return payload.colNum
        default:
            return state
    }
}

const previousLayout = (state = 0, action) => {
    const { payload } = action
    switch (action.type) {
        case widgetTypes.CHANGE_PREVIOUS_LAYOUT:
            return payload.colNum
        default:
            return state
    }
}

const initialInteractionType = getPreference('interactionMode') || widgetConstants.Interactions.INFINITE

const interactionType = (state = initialInteractionType, action) => {
    const { payload } = action
    switch (action.type) {
        case widgetTypes.INTERACTION_CHANGE:
            return payload.interactionType
        default:
            return state
    }
}

const editingPerspective = (state = null, action) => {
    switch (action.type) {
        case widgetTypes.START_PERSPECTIVE_EDITING:
            return action.payload
        case LOCATION_CHANGE:
        case widgetTypes.STOP_PERSPECTIVE_EDITING:
            return null
        default:
            return state
    }
}

const changeToPerspectiveLayout = (state = null, action) => {
    switch (action.type) {
        case widgetTypes.START_CHANGING_PERSPECTIVE_LAYOUT:
            return action.payload
        case LOCATION_CHANGE:
        case widgetTypes.STOP_CHANGING_PERSPECTIVE_LAYOUT:
            return null
        default:
            return state
    }
}

const isSavingWidgets = (state = false, action) => {
    switch (action.type) {
        case widgetTypes.START_SAVING_WIDGETS:
            return true
        case LOCATION_CHANGE:
        case widgetTypes.STOP_SAVING_WIDGETS:
            return false
        default:
            return state
    }
}

const perspectiveWidth = (state = '100%', action) => {
    switch (action.type) {
        case widgetTypes.SET_PERSPECTIVE_WIDTH:
            return action.payload.value
        case widgetTypes.STOP_PERSPECTIVE_EDITING:
            return '100%'
        default:
            return state
    }
}

const draftPerspectiveLayouts = (state = null, action) => {
    switch (action.type) {
        case widgetTypes.SET_DRAFT_PERSPECTIVE_LAYOUTS:
            return action.payload.value
        case widgetTypes.STOP_PERSPECTIVE_EDITING:
            return null
        default:
            return state
    }
}

const draftTitles = (state = {}, action) => {
    const { type, payload } = action
    switch (type) {
        case widgetTypes.SET_DRAFT_TITLE:
            return { ...state, [payload.id]: payload.value }
        case LOCATION_CHANGE:
        case widgetTypes.STOP_PERSPECTIVE_EDITING:
            return {}
        default:
            return state
    }
}

const commentingWidget = (state = null, action) => {
    const { type, payload } = action
    switch (type) {
        case widgetTypes.SET_COMMENTING_WIDGET:
            return payload.widgetId
        case widgetTypes.CLEAR_WIDGET_COMMENT:
            return null
        default:
            return state
    }
}

const shouldRefresh = (state = [], action) => {
    const { type, payload } = action
    switch (type) {
        case widgetTypes.SET_SHOULD_REFRESH:
            return payload.widgetIds
        case LOCATION_CHANGE:
        case widgetTypes.CLEAR_SHOULD_REFRESH:
            return []
        default:
            return state
    }
}

const diagnostics = (state = {}, action) => {
    const { type, payload } = action
    switch (type) {
        case widgetTypes.SET_DIAGNOSTICS: {
            const { id, time, trace } = payload
            return { ...state, [id]: { time, trace: cleanTrace(trace) } }
        }
        case LOCATION_CHANGE:
            return {}
        default:
            return state
    }
}

function cleanTrace(trace) {
    if (!trace) return

    // add additional time step for any unaccounted times
    function makeOtherTrace(traces, parentTime) {
        if (!traces) return

        const totalChildTime = traces.reduce((acc, cur) => acc + cur.time, 0)
        const otherTime = parentTime - totalChildTime
        if (otherTime > 0.1) traces.push({ time: otherTime, name: 'Other', description: 'Additional processing time' })
    }

    function createTraces(rawTraces, parentTime) {
        if (!rawTraces) return

        let traces = rawTraces.map(s => {
            const time = s.ElapsedMilliseconds
            let traces = s.InnerRequestContexts?.map(({ ElapsedMilliseconds, Traces }, i) => {
                let traces = {
                    time: ElapsedMilliseconds,
                    name: `Series ${i + 1}`,
                    traces: createTraces(Traces, ElapsedMilliseconds),
                }

                return traces
            })

            const otherTrace = makeOtherTrace(traces, time)
            if (otherTrace) traces.push(otherTrace)

            return { time, name: s.EventName, description: s.Message, traces }
        })

        const otherTrace = makeOtherTrace(traces, parentTime)
        if (otherTrace) traces.push(otherTrace)

        return traces
    }

    const time = trace.ElapsedMilliseconds
    const traces = createTraces(trace.Traces, time)
    return { time, name: 'Total', description: 'Total server trace', traces }
}

const thumbnailRequests = (state = [], action) => {
    const { type, payload } = action
    switch (type) {
        case widgetTypes.ADD_THUMBNAIL_REQUEST:
            if (state.includes(payload.id)) return state
            return [...state, payload.id]
        case widgetTypes.REMOVE_THUMBNAIL_REQUEST:
            return state.filter(id => id !== payload.id)
        case applicationTypes.APPLICATION_SELECT:
            return []
        default:
            return state
    }
}

const labelWidgetLayouts = (state = {}, action) => {
    const { type, payload } = action
    switch (type) {
        case widgetTypes.SET_LABEL_WIDGET_LAYOUTS:
            return { ...state, payload }
        case widgetTypes.SET_LABEL_WIDGET_LAYOUTS_TO_NULL:
            return {}
        default:
            return state
    }
}

const topLabelWidgetLayouts = (state = {}, action) => {
    const { type, payload } = action
    switch (type) {
        case widgetTypes.SET_TOP_LABEL_WIDGET_LAYOUTS:
            return { ...state, payload }
        case widgetTypes.SET_TOP_LABEL_WIDGET_LAYOUTS_TO_NULL:
            return {}
        default:
            return state
    }
}

export default combineReducers({
    configs,
    currentLayout,
    selectedLayout,
    interactionType,
    editingPerspective,
    changeToPerspectiveLayout,
    isSavingWidgets,
    perspectiveWidth,
    draftPerspectiveLayouts,
    draftTitles,
    commentingWidget,
    shouldRefresh,
    diagnostics,
    thumbnailRequests,
    previousLayout,
    labelWidgetLayouts,
    topLabelWidgetLayouts,
})
