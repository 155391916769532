import TreeIcon from '@mui/icons-material/AccountTree'
import BubbleIcon from '@mui/icons-material/BubbleChartRounded'
import FormIcon from '@mui/icons-material/DescriptionRounded'
import SortedIcon from '@mui/icons-material/FilterListRounded'
import LabelIcon from '@mui/icons-material/LabelRounded'
import ComboIcon from '@mui/icons-material/MultilineChartRounded'
import PieIcon from '@mui/icons-material/PieChartRounded'
import MapIcon from '@mui/icons-material/RoomRounded'
import TableIcon from '@mui/icons-material/TableChartRounded'
import TextIcon from '@mui/icons-material/TextFieldsRounded'
import HeatmapIcon from '@mui/icons-material/ViewModuleRounded'
import CalculateIcon from '@mui/icons-material/Calculate'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import RecommendIcon from '@mui/icons-material/Recommend';
import { ChartType } from 'genesis-suite/types/visualTypes'
import { BoxPlot, Bullet, Link, StatusTracker, Settings, Search } from 'genesis-suite/icons'

import { SvgIconProps } from '@mui/material';

export type WidgetDictionary = {
    [key in ChartType]?: WidgetDetail
}

interface WidgetDetail {
    title: string
    Icon: (props: SvgIconProps) => JSX.Element
}

const dictionary: WidgetDictionary = {
    [ChartType.BOX_PLOT]: { title: 'Box plot', Icon: BoxPlot },
    [ChartType.BUBBLE]: { title: 'Bubble', Icon: BubbleIcon },
    [ChartType.BULLET]: { title: 'Bullet', Icon: Bullet },
    [ChartType.COMBO]: { title: 'Combo', Icon: ComboIcon },
    [ChartType.FORM]: { title: 'Form', Icon: FormIcon },
    [ChartType.HEATMAP]: { title: 'Heat map', Icon: HeatmapIcon },
    [ChartType.LABEL]: { title: 'Label', Icon: LabelIcon },
    [ChartType.MAP]: { title: 'Map', Icon: MapIcon },
    [ChartType.PACKED_BUBBLE]: { title: 'Packed bubble', Icon: BubbleIcon },
    [ChartType.PIE]: { title: 'Pie', Icon: PieIcon },
    [ChartType.SORTED]: { title: 'Sorted', Icon: SortedIcon },
    [ChartType.STATUS_TRACKER]: { title: 'Status tracker', Icon: StatusTracker },
    [ChartType.TABLE]: { title: 'Table', Icon: TableIcon },
    [ChartType.TEXT]: { title: 'Text', Icon: TextIcon },
    [ChartType.TREE]: { title: 'Tree', Icon: TreeIcon },
    [ChartType.WEBVIEW]: { title: 'Web view', Icon: Link },
    [ChartType.SPACIAL_MAP]: { title: 'Spacial Map', Icon: MapIcon },
    [ChartType.CALCULATOR]: { title: 'Calculator', Icon: CalculateIcon },
    [ChartType.FAVORITES]: { title: 'Favorites', Icon: StarBorderIcon },
    [ChartType.MODULE_SELECTOR]: { title: 'Module Selector', Icon: Settings },
    [ChartType.SEARCH]: { title: 'Search', Icon: Search },
    [ChartType.RECOMMENDED_WIDGET]: { title: 'Recommended Widget', Icon: RecommendIcon },
}

export default dictionary
