import { useTheme } from '@mui/material'
import { shouldApplyFormat } from '../../lib/conditionalFormatter'

export const LabelStyle = (labelConfig, content) => {
    const { SeriesName } = labelConfig
    const data = makeData(labelConfig, content)
    const value = makeValue(labelConfig, data)

    const { style, leftStripStyle } = makeDynamicStyle(labelConfig, value, data?.[0], SeriesName)

    function makeData(labelConfig, content) {
        if (!content) return

        const dataString = content.seriesData[labelConfig.SeriesName]
        if (!dataString) return

        return JSON.parse(dataString)
    }

    function makeValue(labelConfig, data) {
        if (!data) return

        const { SeriesName, ValueField, LabelField } = labelConfig
        return LabelField
            ? data.find(d => Boolean(d[`Total-${SeriesName}`]))?.[`Total-${SeriesName}`]
            : data[0][ValueField]
    }

    function makeDynamicStyle({ FormatOptions, FormatConfig, DefaultPerspective }, value, data, seriesName) {
        let style = makeStyle(FormatOptions)
        if (FormatConfig) {
            style = { ...style, ...makeConditionalStyle(FormatConfig, value, data, seriesName) }
        }
        if (DefaultPerspective) {
            style.style = { ...style.style, cursor: 'pointer' }
        }
        return style
    }

    function makeConditionalStyle(formatConfigs, value, data, seriesName) {
        return formatConfigs
            .filter(formatConfig => {
                const { FormatValue, FormatRule, FormatOption, TargetProperty, TableField } = formatConfig
                const sourceValue =
                    TableField && data
                        ? data[TableField]
                            ? data[TableField]
                            : data[`${TableField}-${seriesName}`]
                            ? data[`${TableField}-${seriesName}`]
                            : value
                        : value
                const target =
                    TargetProperty && data
                        ? data[TargetProperty]
                            ? data[TargetProperty]
                            : data[`${TargetProperty}-${seriesName}`]
                            ? data[`${TargetProperty}-${seriesName}`]
                            : FormatValue
                        : FormatValue
                return FormatOption && shouldApplyFormat(sourceValue, target, FormatRule)
            })
            .reduceRight((acc, { FormatOption }) => ({ ...acc, ...makeStyle(FormatOption) }), {})
    }

    function makeStyle(formats) {
        if (!formats?.length) return {}

        return formats.reduce(
            (acc, format) => {
                if (format.Fill) acc = { ...acc, style: { ...acc.style, backgroundColor: format.Fill } }
                if (format.BorderColor)
                    acc = {
                        ...acc,
                        style: {
                            ...acc.style,
                            border: `${format.BorderWidth}px ${format.BorderStyle} ${format.BorderColor}`,
                            borderRadius: '2px 8px 2px 8px',
                        },
                    }

                if (format.ShowLeftStrip)
                    acc = {
                        ...acc,
                        leftStripStyle: {
                            ...acc.leftStripStyle,
                            display: 'block',
                            width: '14px',
                            backgroundColor: format.BorderColor,
                            borderRadius: '0px 0px 0px 6.5px',
                        },
                    }
                if (format.ForegroundColor)
                    acc = {
                        ...acc,
                        style: { ...acc.style, color: format.ForegroundColor },
                    }
                return acc
            },
            { style: {}, leftStripStyle: { display: 'none' } }
        )
    }

    const labelStyle =
        style != undefined
            ? style
            : { border: '2px solid #4E4E4E', borderRadius: '2px 8px 2px 8px', backgroundColor: '#a6a6a6' }

    return { labelStyle, leftStripStyle }
}
