import React, { useState, useContext } from 'react'
import { useSnackbar } from 'notistack'
import { Trash, AddTask } from 'genesis-suite/icons'
import { SwalContext } from 'genesis-suite/components'
import { CheckBoxOutlineBlankRounded, CheckBoxRounded, ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material'
import {
    TextField,
    Box,
    ListItem,
    ListItemIcon,
    ListItemText,
    IconButton,
    List,
    Collapse,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { taskService } from '../../lib/services'
import { useSelector } from 'react-redux'
import { moduleSelectors } from '../../selectors'
import { logEvent } from '../../lib/amplitudeClient'

const useStyles = makeStyles(({ spacing, palette }) => ({
    addTask: { 
        color: palette.text.primary,
        '&::placeholder': {
            color: palette.text.primary,
        }
    },
    btn: {
        color: palette.text.primary, 
    },
    listItem: { borderBottom: `1px solid ${palette.grayscale.dark}` },
    focus: { backgroundColor: 'transparent !important' },
    completed: { borderTop: `1px solid ${palette.grayscale.dark}`, marginTop: 'auto' },
    taskList: { height: '100%', overflow: 'scroll' },
}))

export default function TasksController({ tasks, listId, onChange }) {
    const moduleId = useSelector(moduleSelectors.getCurrentModule).id

    const classes = useStyles({})
    const [text, setText] = useState('')
    const [open, setOpen] = useState(false)

    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const errorSnackbar = err => showSnackbar(err, { variant: 'error' })

    const updateTasks = async task => {
        const oldTask = tasks.find(t => t._id === task._id)
        onChange(tasks.map(t => (t._id === task._id ? task : t)))
        try {
            const { body, complete } = task
            logEvent('TASK_UPDATED', { body, complete })
            await taskService.updateTask(moduleId, listId, task._id, { body, complete })
        } catch ({ message }) {
            onChange(tasks.map(t => (t._id === oldTask._id ? oldTask : t)))
            errorSnackbar(message)
        }
    }

    const handleAdd = async () => {
        if (text) {
            try {
                logEvent('TASK_ADDED')
                const task = await taskService.createTask(moduleId, listId, text)
                onChange([...tasks, task])
                setText('')
            } catch ({ message }) {
                errorSnackbar(message)
            }
        }
    }

    const handleEnter = ({ key }) => key === 'Enter' && handleAdd()

    const handleDelete = async task => {
        try {
            logEvent('TASK_DELETED')
            await taskService.deleteTask(moduleId, listId, task._id)
            onChange(tasks.filter(t => t._id !== task._id))
        } catch ({ message }) {
            errorSnackbar(message)
        }
    }

    const toggleCompleted = () => setOpen(!open)
    const openTasks = tasks.filter(t => !t.complete)
    const completeTasks = tasks.filter(t => t.complete)

    return (
        <Box height="calc(100% - 36px)" display="flex" flexDirection="column">
            <Box display="flex" justifyContent="center">
                <TextField
                    placeholder="Add new task"
                    fullWidth
                    value={text}
                    disabled={!listId}
                    onKeyPress={handleEnter}
                    className={classes.addTask}
                    onChange={e => setText(e.target.value)}
                />
                <IconButton className={classes.btn} disabled={!text} onClick={handleAdd} size="large">
                    <AddTask />
                </IconButton>
            </Box>
            <List className={classes.taskList}>
                {openTasks?.map(task => (
                    <TaskItem
                        key={task._id}
                        task={task}
                        onToggle={t => updateTasks(t)}
                        onDelete={() => handleDelete(task)}
                        onEdit={text => updateTasks({ ...task, body: text })}
                    />
                ))}
            </List>
            <ListItem button onClick={toggleCompleted} className={classes.completed}>
                <ListItemText primary={`Completed (${completeTasks?.length || 0})`} />
                {open ? <ExpandMoreRounded /> : <ExpandLessRounded />}
            </ListItem>
            <Box height={open ? "80%" : "auto"} overflow="scroll">
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List disablePadding>
                        {completeTasks?.map(task => (
                            <TaskItem
                                key={task._id}
                                task={task}
                                onToggle={t => updateTasks(t)}
                                onDelete={() => handleDelete(task)}
                                onEdit={text => updateTasks({ ...task, body: text })}
                            />
                        ))}
                    </List>
                </Collapse>
            </Box>
        </Box>
    );
}

const TaskItem = ({ task, onToggle, onEdit, onDelete }) => {
    const classes = useStyles()
    const [text, setText] = useState('')
    const [editing, setEditing] = useState(false)
    const [hovering, setHovering] = useState(false)

    const { confirm } = useContext(SwalContext)

    const { body, complete } = task

    const toggleEditing = () => {
        setText(body)
        setEditing(true)
    }

    const handleEdit = () => {
        setEditing(false)
        if (body !== text) onEdit(text)
    }

    const handleEnter = ({ key }) => key === 'Enter' && handleEdit()

    const handleDelete = async e => {
        e.stopPropagation()
        e.preventDefault()
        const response = await confirm('Delete this task?', { type: 'question' })
        if (response.dismiss) return
        else onDelete()
    }

    return (
        <ListItem
            button
            className={classes.listItem}
            classes={{ focusVisible: classes.focus }}
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
        >
            <ListItemIcon className={classes.btn} onClick={() => onToggle({ ...task, complete: !complete })}>
                {complete ? <CheckBoxRounded /> : <CheckBoxOutlineBlankRounded />}
            </ListItemIcon>
            {editing ? (
                <TextField
                    fullWidth
                    autoFocus
                    value={text}
                    onBlur={handleEdit}
                    onKeyPress={handleEnter}
                    onChange={e => setText(e.target.value)}
                />
            ) : (
                <ListItemText onClick={toggleEditing}>{body}</ListItemText>
            )}
            {hovering && !editing && (
                <IconButton className={classes.btn} size="small" onClick={handleDelete}>
                    <Trash fontSize="small" />
                </IconButton>
            )}
        </ListItem>
    )
}
