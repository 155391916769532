import BackArrowIcon from '@mui/icons-material/ArrowBack'
import { Box, Button, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ChopText } from 'genesis-suite/components'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import tinycolor from 'tinycolor2'
import { applicationCreators, moduleCreators, navigationCreators } from '../actions/creators'
import { moduleTypes } from '../actions/types'
import ModuleSelector from '../components/widgets2/module_selector/ModuleSelector'
import { useIsMobile } from '../hooks/useIsMobile'
import { logEvent } from '../lib/amplitudeClient'
import { getAppLogo } from '../lib/appLogoUtils'
import { routePaths } from '../lib/routes'
import { applicationSelectors, authSelectors, moduleSelectors } from '../selectors'

const useStyles = makeStyles(({ custom, palette, spacing }) => ({
    moduleSelectView: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        background: palette.background.main,
    },
    topBar: {
        width: '100%',
        height: custom.topNav.height,
        background: palette.tada.purple,
        padding: spacing(0, 1),
        display: 'flex',
        alignItems: 'center',
        flexShrink: 0,
        justifyContent: 'space-between',
    },
    moduleSelectWrapper: {
        flex: 1,
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    logo: { height: custom.topNav.height - 30, maxWidth: 230 },
    loading: { margin: 'auto', maxWidth: '400px' },
    selectApplicationsButton: { display: 'block', margin: spacing(2), textAlign: 'center' },
    goBackToAppButton: { color: '#fff', fontWeight: 'normal' },
    backIcon: { color: '#fff' },
    selectedItem: {
        backgroundColor: `${tinycolor(palette.primary.main).setAlpha(0.4)} !important`,
    },
    moduleGroupName: {
        color: palette.text.primary,
    },
}))

export default function ModuleSelectView() {
    const { appName } = useParams()
    const isMobile = useIsMobile()

    const currentAppName = useSelector(applicationSelectors.getCurrentAppName)
    const applications = useSelector(applicationSelectors.getAllApplications)
    const modules = useSelector(moduleSelectors.getActiveModules)
    const currentModuleName = useSelector(moduleSelectors.getModuleName)
    const defaultModuleName = useSelector(authSelectors.getDefaultModule)
    const moduleGroups = modules?.find(m => m.modules?.length > 0)
    const appDisplayName = useSelector(applicationSelectors.getAppInfo)?.appDisplayName
    const dispatch = useDispatch()

    const [noModules, setNoModules] = useState(false)
    const [module, setModule] = useState('AllModules')

    const applicationError = !applications.includes(appName)
    const appLogo = getAppLogo()

    const classes = useStyles()

    useEffect(() => {
        if (applicationError) return
        else if (appName !== currentAppName) dispatch(applicationCreators.selectApplication(appName))
        else if (!modules) dispatch(moduleCreators.getAppModules(appName, true))
        else if (!currentModuleName && defaultModuleName) openModule(defaultModuleName)
        else if (!modules.length) return setNoModules(true)
        else if (modules.length < 3 || isMobile) openModule(modules[0].name)
    }, [applicationError, appName, currentAppName, modules, currentModuleName, defaultModuleName, isMobile])

    function openModule(name) {
        dispatch(navigationCreators.goToModule(name))
    }

    const content = applicationError ? (
        <div className={classes.loading}>
            <Typography variant="h5" align="center">
                Application Error
            </Typography>
            <Typography align="center">
                There was an issue loading application <strong>{appName}</strong> from the url. If you're confident of
                the spelling, reach out to your account admin for access.
            </Typography>
            <Link to={routePaths.SELECT} className={classes.selectApplicationsButton}>
                <Button variant="outlined">Go to your applications</Button>
            </Link>
        </div>
    ) : noModules ? (
        <div className={classes.loading}>
            <Typography variant="h5" align="center">
                No modules
            </Typography>
            <Typography align="center">
                No modules exist for application <strong>{appName}</strong>.
            </Typography>
            <Link to={routePaths.SELECT} className={classes.selectApplicationsButton}>
                <Button variant="outlined">Go to your applications</Button>
            </Link>
        </div>
    ) : !modules ? (
        <Typography variant="h5" align="center" className={classes.loading}>
            Loading modules...
        </Typography>
    ) : !modules.length ? (
        <Typography variant="h5" align="center" className={classes.loading}>
            No modules exist for this application
        </Typography>
    ) : defaultModuleName || modules.length < 3 || isMobile ? null : (
        <div className={classes.moduleSelectWrapper}>
            {moduleGroups && (
                <Box pt="6%" width="20%" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Button
                        style={{ minWidth: 0, margin: 2 }}
                        onClick={() => {
                            setModule('AllModules')
                            logEvent('MODULE_SELECTED', { origin: 'Login View' })
                        }}
                        className={module === 'AllModules' ? classes.selectedItem : ''}
                    >
                        <ChopText className={classes.moduleGroupName}>All Modules</ChopText>
                    </Button>
                    {Object.entries(
                        Object.fromEntries(
                            modules
                                .filter(m => m.version === '1')
                                .map(module => module.modules)
                                .flat()
                                .map(n => [n.Id, n.Name])
                        )
                    )
                        .map(([Id, Name]) => ({ Id, Name }))
                        .sort((a, b) => a.Name.localeCompare(b.Name))
                        .map(m => (
                            <Button
                                key={m.Id}
                                style={{ minWidth: 0, margin: 2 }}
                                onClick={() => setModule(m.Id)}
                                className={module === m.Id ? classes.selectedItem : ''}
                            >
                                <ChopText className={classes.moduleGroupName} showEllipsis>
                                    {m.Name}
                                </ChopText>
                            </Button>
                        ))}
                </Box>
            )}
            <Box>
                <Box mt={4} mb={2}>
                    <Typography variant="h4" gutterBottom display="block" align="center">
                        {appDisplayName || currentAppName}
                    </Typography>
                    <Typography variant="h5" display="block" align="center">
                        Select Application
                    </Typography>
                </Box>
                <Box sx={{ width: 'min(900px, 90vw)', margin: 'auto' }}>
                    <ModuleSelector onSelect={openModule} module={module} />
                </Box>
            </Box>
        </div>
    )

    return (
        <div className={classes.moduleSelectView}>
            <div className={classes.topBar}>
                <img className={classes.logo} alt="App Logo" src={appLogo.light} draggable="false" />
                <Link onClick={() => dispatch({ type: moduleTypes.CLEAR_MODULES })} to={routePaths.SELECT}>
                    <Button
                        className={classes.goBackToAppButton}
                        startIcon={<BackArrowIcon fontSize="small" className={classes.backIcon} />}
                    >
                        Applications
                    </Button>
                </Link>
            </div>
            {content}
        </div>
    )
}
