import { Service, Widget } from 'genesis-suite/types/visualTypes'
import { businessExplorerTypes } from '../types'

const createConfig = (config: Widget) => ({ type: businessExplorerTypes.CREATE_CONFIG, payload: config })

const deleteConfig = (id: string) => ({ type: businessExplorerTypes.DELETE_CONFIG, payload: id })

const deleteConfigs = (ids: string[]) => ({ type: businessExplorerTypes.DELETE_CONFIGS, payload: ids })

const reset = () => ({ type: businessExplorerTypes.RESET })

const setAnchorId = (id: string) => ({ type: businessExplorerTypes.SET_ANCHOR_ID, payload: id })

const setService = (service: Service) => ({ type: businessExplorerTypes.SET_SERVICE, payload: service })

const setSelectedConfig = (id: string) => ({ type: businessExplorerTypes.SET_SELECTED_CONFIG, payload: id })

const updateSelectedConfig = (updated: Partial<Widget>) => ({
    type: businessExplorerTypes.UPDATE_CONFIG,
    payload: updated,
})

export const businessExplorerCreators = {
    createConfig,
    deleteConfig,
    deleteConfigs,
    reset,
    setAnchorId,
    setService,
    setSelectedConfig,
    updateSelectedConfig,
}
