import React, { useContext, useEffect, useState } from 'react'
import produce from 'immer'
import { makeStyles } from '@mui/styles'
import { Box, Collapse, MenuItem, Slider, TextField, Typography } from '@mui/material'

import { Property } from 'genesis-suite/types/networkTypes'
import { PropertyType } from 'genesis-suite/types/architectureTypes'
import {
    Aggregation,
    Basket,
    DataField,
    DataSource,
    LocationType,
    MapConfig,
    MapConnectionSeries,
    MapHeatSeries,
    MapMarkerSeries,
    MapSeries,
    Navigation,
} from 'genesis-suite/types/visualTypes'
import EditorWrapper from '../EditorWrapper'
import { FieldPointer } from '../../builderTypes'
import NavigationEditor from '../NavigationEditor'
import { ConfigContext } from '../../ConfigContext'
import SeriesTooltipEditor from './SeriesTooltipEditor'
import ColorGradientEditor from '../ColorGradientEditor'
import useWidgetColors from '../../../../hooks/useWidgetColors'
import DnDPropertySelector from '../../selectors/DnDPropertySelector'
import pickFromCarousel from '../../../widgets2/utils/pickFromCarousel'
import { getDataFieldProperty, getDefaultAggregation } from '../../utils'
import FormatEditorColorSelector from '../FormatEditor/FormatEditorColorSelector'
import { OptionsObject, useSnackbar } from 'notistack'
import { useSemanticTypeById } from '../../../../hooks/useSemanticTypes'
import AggregationSelect from '../../AggregationSelect'

const useStyles = makeStyles(({ spacing }) => ({
    group: { marginBottom: spacing() },
}))

export default function SeriesEditorMap() {
    const classes = useStyles()
    const semanticTypeById = useSemanticTypeById()
    const { dispatch, selectedField, resources, ...rest } = useContext(ConfigContext)
    const { enqueueSnackbar: showSnackbar, closeSnackbar } = useSnackbar()
    const config = rest.config as MapConfig

    const activeSeries = config.series[selectedField.index]
    if (!activeSeries) return null
    const { properties } = resources.byId[activeSeries?.service.id]

    const { values } = activeSeries

    const snackbarOptions: OptionsObject = { variant: 'error', autoHideDuration: 8000 }

    function updateSeries(payload: Partial<MapSeries>) {
        dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload })
    }

    function changeLocationType(locationType: LocationType) {
        const newValues = values.filter(s => s.basket === Basket.ID || s.basket === Basket.SIZE)
        updateSeries({ locationType, values: newValues })
    }

    function handleAdd(basket: Basket, field: DataField) {
        const to: FieldPointer = { type: 'series', index: selectedField.index, valueIndex: values.length }
        const aggregation = Aggregation.NONE
        const source: DataSource = { field, aggregation, basket }
        const activeSeriesInsightName = activeSeries.service.name
        if (!properties.find(property => property.id === source?.field.id)) {
            closeSnackbar()
            showSnackbar(
                `Property "${field.name}" is not found in "${activeSeriesInsightName}" Insight. Please select another property.`,
                snackbarOptions
            )
            return
        }
        dispatch({ type: 'UPDATE_SELECTED_PROPERTY', payload: { to, operation: 'new', source, semanticTypeById } })
    }

    function handleRemove(basket: Basket) {
        const index = values.findIndex(v => v.basket === basket)
        const newConfig = produce(config, draft => {
            draft.series[selectedField.index].values.splice(index, 1)
        })
        dispatch({ type: 'UPDATE_CONFIG', payload: newConfig })
    }

    return (
        <>
            {activeSeries.type === 'marker' ? (
                <MarkerSeriesEditor
                    {...activeSeries}
                    classes={classes}
                    properties={properties}
                    onAddField={handleAdd}
                    onRemoveField={handleRemove}
                    onChangeLocationType={changeLocationType}
                />
            ) : activeSeries.type === 'heat' ? (
                <HeatSeriesEditor
                    {...activeSeries}
                    classes={classes}
                    properties={properties}
                    onAddField={handleAdd}
                    onRemoveField={handleRemove}
                    onChangeLocationType={changeLocationType}
                />
            ) : (
                <ConnectionSeriesEditor
                    {...activeSeries}
                    classes={classes}
                    properties={properties}
                    onAddField={handleAdd}
                    onRemoveField={handleRemove}
                />
            )}
        </>
    )
}

interface BaseSeriesProps {
    classes: any
    properties: Property[]
    onAddField: (basket: Basket, field: DataField) => void
    onRemoveField: (basket: Basket) => void
}

interface MarkerSeriesProps extends MapMarkerSeries, BaseSeriesProps {
    onChangeLocationType: (locationType: LocationType) => void
}

function MarkerSeriesEditor({
    classes,
    properties,
    values,
    colors,
    locationType,
    size,
    onAddField,
    onRemoveField,
    onChangeLocationType,
}: MarkerSeriesProps) {
    const widgetColors = useWidgetColors()
    const { selectedField, dispatch } = useContext(ConfigContext)
    const [draftSize, setDraftSize] = useState(15)

    useEffect(() => {
        if (size && size !== draftSize) setDraftSize(size)
    }, [size])

    const colorFieldName = values?.[0]?.field.name
    const seriesDefault = !Boolean(colors?.[colorFieldName]?.series)
    const color = colors?.[colorFieldName]?.series || pickFromCarousel(widgetColors, selectedField.index)
    const markerSource = getSourceByBasket(values, Basket.ID)

    function handleUpdateColor(color?: string) {
        const colors = color ? { [colorFieldName]: { series: color } } : undefined
        dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: { colors } })
    }
    function handleChangeNavigation(navigation: Navigation) {
        const newValues = values.map(v => {
            if (v.basket !== Basket.ID) return v
            return { ...v, navigation }
        })
        dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: { values: newValues } })
    }

    return (
        <>
            <AggregationSelect />

            <TextField
                select
                margin="dense"
                label="Type"
                value={locationType ?? LocationType.GEO}
                onChange={e => onChangeLocationType(e.target.value as LocationType)}
            >
                {makeLocationTypeOptions(true).map(o => (
                    <MenuItem key={o.value} value={o.value}>
                        {o.label}
                    </MenuItem>
                ))}
            </TextField>

            {!locationType || locationType === LocationType.GEO ? (
                <>
                    <BasketSelector
                        label="Latitude"
                        source={getSourceByBasket(values, Basket.LATITUDE)}
                        properties={properties}
                        onAdd={f => onAddField(Basket.LATITUDE, f)}
                        onRemove={() => onRemoveField(Basket.LATITUDE)}
                        color={color}
                    />
                    <BasketSelector
                        label="Longitude"
                        source={getSourceByBasket(values, Basket.LONGITUDE)}
                        properties={properties}
                        onAdd={f => onAddField(Basket.LONGITUDE, f)}
                        onRemove={() => onRemoveField(Basket.LONGITUDE)}
                        color={color}
                    />
                </>
            ) : (
                <BasketSelector
                    source={getSourceByBasket(values, Basket.LOCATION)}
                    properties={properties}
                    onAdd={f => onAddField(Basket.LOCATION, f)}
                    onRemove={() => onRemoveField(Basket.LOCATION)}
                    color={color}
                />
            )}

            <BasketSelector
                label="Marker"
                source={markerSource}
                properties={properties}
                onAdd={f => onAddField(Basket.ID, f)}
                onRemove={() => onRemoveField(Basket.ID)}
                color={color}
            />

            <Collapse in={Boolean(markerSource)}>
                <div className={classes.group}>
                    <Typography variant="caption">Navigation</Typography>
                    <NavigationEditor
                        focalPoint={markerSource?.field.resourceName}
                        navigation={markerSource?.navigation}
                        onUpdate={handleChangeNavigation}
                    />
                </div>
            </Collapse>

            <BasketSelector
                label="Size"
                source={getSourceByBasket(values, Basket.SIZE)}
                properties={properties}
                onAdd={f => onAddField(Basket.SIZE, f)}
                onRemove={() => onRemoveField(Basket.SIZE)}
                color={color}
            />

            <Box mx={1}>
                <Slider
                    value={draftSize}
                    onChange={(e, val) => setDraftSize(val as number)}
                    onChangeCommitted={(e, val) =>
                        dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: { size: val as number } })
                    }
                />
            </Box>

            <div className={classes.group}>
                <Typography variant="caption">Color</Typography>
                <FormatEditorColorSelector
                    value={color}
                    onChange={handleUpdateColor}
                    resetButtonProps={{
                        disabled: seriesDefault,
                        onClick: () => handleUpdateColor(),
                    }}
                />
            </div>

            <EditorWrapper header="Tooltip">
                <SeriesTooltipEditor />
            </EditorWrapper>
        </>
    )
}

interface HeatSeriesProps extends MapHeatSeries, BaseSeriesProps {
    onChangeLocationType: (locationType: LocationType) => void
}

function HeatSeriesEditor({
    classes,
    properties,
    values,
    colorGradient,
    locationType,
    subSeries,
    onChangeLocationType,
    colors,
}: HeatSeriesProps) {
    const widgetColors = useWidgetColors()
    const { selectedField, dispatch, ...rest } = useContext(ConfigContext)
    const config = rest.config as MapConfig

    function updateActiveSeries(payload: Partial<MapHeatSeries>) {
        dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload })
    }
    function handleAddLocation(field: DataField) {
        updateActiveSeries({ subSeries: { field } })
    }
    function handleRemoveLocation() {
        updateActiveSeries({ subSeries: undefined })
    }
    function handleAddValue(basket: Basket, field: DataField) {
        const { semanticType, hasAggregates } = getDataFieldProperty(field, properties)
        const aggregation = getDefaultAggregation(semanticType, hasAggregates)
        const source = { field, aggregation, basket }
        updateActiveSeries({ values: [source] })
    }
    function handleRemoveValue(basket: Basket) {
        const index = values.findIndex(v => v.basket === basket)
        const newValues = produce(config.series[selectedField.index].values, draft => {
            draft.splice(index, 1)
        })
        updateActiveSeries({ values: newValues })
    }
    function handleUpdateGradient(colorGradient) {
        updateActiveSeries({ colorGradient })
    }
    function handleUpdateColor(color?: string) {
        const colors = color ? { [subSeries?.field.name]: { series: color } } : undefined
        updateActiveSeries({ colors })
    }
    function handleChangeNavigation(navigation: Navigation) {
        updateActiveSeries({ subSeries: { ...subSeries, navigation } })
    }

    const colorSource = getSourceByBasket(values, Basket.COLOR)
    const seriesDefault = !Boolean(colors?.[subSeries?.field.name]?.series)
    const color = colors?.[subSeries?.field.name]?.series || pickFromCarousel(widgetColors, selectedField.index)

    return (
        <>
            <AggregationSelect />

            <TextField
                select
                margin="dense"
                label="Type"
                value={locationType ?? LocationType.GEO}
                onChange={e => onChangeLocationType(e.target.value as LocationType)}
            >
                {makeLocationTypeOptions(false).map(o => (
                    <MenuItem key={o.value} value={o.value}>
                        {o.label}
                    </MenuItem>
                ))}
            </TextField>

            <Collapse in={Boolean(locationType)}>
                <BasketSelector
                    source={{ field: subSeries?.field, aggregation: Aggregation.NONE }}
                    properties={properties}
                    onAdd={handleAddLocation}
                    onRemove={handleRemoveLocation}
                    color={color}
                />
            </Collapse>

            <Collapse in={Boolean(subSeries)}>
                <div className={classes.group}>
                    <Typography variant="caption">Navigation</Typography>
                    <NavigationEditor
                        focalPoint={subSeries?.field.resourceName}
                        navigation={subSeries?.navigation}
                        onUpdate={handleChangeNavigation}
                    />
                </div>

                <BasketSelector
                    label="Color"
                    source={colorSource}
                    properties={properties}
                    onAdd={f => handleAddValue(Basket.COLOR, f)}
                    onRemove={() => handleRemoveValue(Basket.COLOR)}
                    color={color}
                />

                {colorSource ? (
                    <ColorGradientEditor colorGradient={colorGradient} onChange={handleUpdateGradient} />
                ) : (
                    <FormatEditorColorSelector
                        value={color}
                        onChange={handleUpdateColor}
                        resetButtonProps={{
                            disabled: seriesDefault,
                            onClick: () => handleUpdateColor(),
                        }}
                    />
                )}
            </Collapse>
        </>
    )
}

interface ConnectionSeriesProps extends MapConnectionSeries, BaseSeriesProps {}

function ConnectionSeriesEditor({
    classes,
    colors,
    properties,
    values,
    onAddField,
    onRemoveField,
}: ConnectionSeriesProps) {
    const widgetColors = useWidgetColors()
    const { selectedField, dispatch } = useContext(ConfigContext)

    const colorFieldName = values?.[0]?.field.name
    const seriesDefault = !Boolean(colors?.[colorFieldName]?.series)
    const color = colors?.[colorFieldName]?.series || pickFromCarousel(widgetColors, selectedField.index)

    function handleUpdateColor(color?: string) {
        const colors = color ? { [colorFieldName]: { series: color } } : undefined
        dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: { colors } })
    }

    return (
        <>
            <BasketSelector
                label="From marker"
                source={getSourceByBasket(values, Basket.ID)}
                properties={properties}
                onAdd={f => onAddField(Basket.ID, f)}
                onRemove={() => onRemoveField(Basket.ID)}
                color={color}
            />

            <BasketSelector
                label="To marker"
                source={getSourceByBasket(values, Basket.ID2)}
                properties={properties}
                onAdd={f => onAddField(Basket.ID2, f)}
                onRemove={() => onRemoveField(Basket.ID2)}
                color={color}
            />

            <div className={classes.group}>
                <Typography variant="caption">Color</Typography>
                <FormatEditorColorSelector
                    value={color}
                    onChange={handleUpdateColor}
                    resetButtonProps={{
                        disabled: seriesDefault,
                        onClick: () => handleUpdateColor(),
                    }}
                />
            </div>
        </>
    )
}

interface BasketSelectorProps {
    label?: string
    source: DataSource
    properties: Property[]
    color: string
    onAdd: (field: DataField) => void
    onRemove: () => void
}

function BasketSelector({ source, properties, onAdd, color, ...rest }: BasketSelectorProps) {
    const { selectedField, config } = useContext(ConfigContext)
    const activeSeries = config.series[selectedField.index]
    const p = getDataFieldProperty(source?.field, properties)
    const property = p && {
        ...source,
        service: { ...activeSeries.service },
        type: p.semanticType?.type,
        title: p.displayName,
        selected: false,
        color,
    }

    return (
        <DnDPropertySelector
            accept={[PropertyType.DEFINING, PropertyType.QUALITATIVE, PropertyType.QUANTITATIVE]}
            limit={1}
            properties={property ? [property] : []}
            onAdd={({ type, pointer, ...field }) => onAdd(field)}
            {...rest}
        />
    )
}

const makeLocationTypeOptions = (withGeo: boolean): Array<{ value: LocationType; label: string }> => [
    ...(withGeo ? [{ value: LocationType.GEO, label: 'Geo' }] : []),
    { value: LocationType.COUNTRY, label: 'Country' },
    { value: LocationType.STATE, label: 'State' },
    { value: LocationType.COUNTY, label: 'County' },
]

const getSourceByBasket = (values: DataSource[], basket: Basket) => values.find(v => v.basket === basket)
