import { Box } from '@mui/material'
import { useSelector } from 'react-redux'

import Spinner from 'genesis-suite/components/Spinner'
import RefreshError from '../components/RefreshError'
import DashboardDisplay from '~/components/widgets2/dashboard/DashboardDisplay'
import defaultHomePage from '~/lib/defaultHomePage'
import { moduleSelectors } from '../selectors'
import { HomeDashboardProvider, useHomeDashboardContext } from '~/components/widgets2/dashboard/HomeDashboardContext'

function HomeViewContent() {
    const error = useSelector(moduleSelectors.getModuleError)
    const modulesPending = useSelector(moduleSelectors.getModulesPending)
    const { dashboard, appOptionsDashboard } = useHomeDashboardContext()

    if (error) {
        return (
            <Box sx={{ position: 'flex', display: 'flex', flex: 1, overflow: 'hidden' }}>
                <RefreshError />
            </Box>
        )
    }

    return (
        <Box sx={{ position: 'relative', display: 'flex', flex: 1 }}>
            {modulesPending ? (
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Spinner />
                </Box>
            ) : (
                <DashboardDisplay
                    hideHeader
                    config={dashboard || appOptionsDashboard || defaultHomePage}
                    widgets={[]}
                />
            )}
        </Box>
    )
}

const HomeView = () => {
    return (
        <HomeDashboardProvider>
            <HomeViewContent />
        </HomeDashboardProvider>
    )
}

export default HomeView
