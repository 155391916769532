import { Box, Button, IconButton, ListItem, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import NewIcon from '@mui/icons-material/AddRounded'

import { DragList, DragItem } from 'genesis-suite/components'
import { Trash, Menu } from 'genesis-suite/icons'
import { ConditionalFormat } from 'genesis-suite/types/visualTypes'
import BorderedSection from '../../../BorderedSection'

const useStyles = makeStyles(({ border, palette, spacing }) => ({
    rules: { border: border.default, borderRadius: border.radius.round, marginBottom: spacing() },
    listItem: { padding: spacing(0.5, 1), gridGap: spacing(), backgroundColor: '#fff' },
    ruleButtonSelected: {
        backgroundColor: `${palette.secondary.light} !important`,
        color: palette.primary.contrastText,
    },
    ruleDragIcon: { fontSize: '18px', color: 'inherit', cursor: 'grab' },
    ruleDeleteIcon: { fontSize: '18px', color: 'inherit' },
}))

interface Props {
    conditionalFormats: ConditionalFormat[]
    selectedIndex?: number
    onIndexSelect: (index: number | null) => void
    onDragEnd: (rules: ConditionalFormat[]) => void
    onAdd: (e: any) => void
    onDelete: (index: number) => void
}

export function ConditionalFormatListEditor({
    conditionalFormats,
    selectedIndex,
    onIndexSelect,
    onDragEnd,
    onAdd,
    onDelete,
}: Props) {
    const classes = useStyles()

    const handleDragEnd = v => onDragEnd(v.map(({ id, ...c }) => c))

    if (!conditionalFormats?.length) return <NewButton onClick={onAdd} />

    return (
        <BorderedSection header="Rules">
            <ListItem
                button
                classes={{ root: classes.listItem, selected: classes.ruleButtonSelected }}
                selected={selectedIndex == null}
                onClick={() => onIndexSelect(null)}
            >
                Base
            </ListItem>

            <DragList onDragEnd={handleDragEnd} items={conditionalFormats.map((c, i) => ({ ...c, id: i }))}>
                {conditionalFormats.map((rule, index) => (
                    <DragItem key={index} itemId={index} index={index}>
                        <ListItem
                            button
                            classes={{ root: classes.listItem, selected: classes.ruleButtonSelected }}
                            selected={selectedIndex === index}
                            onClick={() => onIndexSelect(index)}
                        >
                            <Box flex="1" display="flex" alignItems="center" gap="8px">
                                <Menu className={classes.ruleDragIcon} />
                                <Typography color="inherit">Rule {index}</Typography>
                            </Box>
                            <IconButton color="inherit" size="small" onClick={() => onDelete(index)}>
                                <Trash className={classes.ruleDeleteIcon} />
                            </IconButton>
                        </ListItem>
                    </DragItem>
                ))}
            </DragList>

            <Box mt={1} />

            <NewButton onClick={onAdd} />
        </BorderedSection>
    )
}

function NewButton(props) {
    return (
        <Button
            size="small"
            variant="outlined"
            startIcon={<NewIcon fontSize="small" style={{ color: 'inherit' }} />}
            {...props}
        >
            Rule
        </Button>
    )
}
