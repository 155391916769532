import { useContext, useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Box, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { isEmpty } from 'lodash'

import { AdvancedFilterEditor } from 'genesis-suite/components'
import { FilterGroup } from 'genesis-suite/types/visualTypes'
import { Property } from 'genesis-suite/types/networkTypes'
import { sleep } from 'genesis-suite/utils'
import { applicationSelectors } from '../../selectors'
import { modelService } from '../../lib/services'
import { ConfigContext } from './ConfigContext'

const columnWidth = 236
const panelWidth = columnWidth * 3

export default function AdvancedFilterEditorPanel() {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const { advancedFilterEditor, resources, config, dispatch, selectedField } = useContext(ConfigContext)
    const { open, series } = advancedFilterEditor
    const [renderContent, setRenderContent] = useState(false)
    const activeSeriesIndex = selectedField?.index ?? 0
    const activeSeries = config.series[activeSeriesIndex]
    const activeFilters = series ? activeSeries?.filters : config.filters

    useEffect(() => {
        if (open) setRenderContent(true)
        else sleep(500).then(() => setRenderContent(false))
    }, [open])

    const properties = useMemo(() => {
        if (isEmpty(resources?.byId)) return []
        if (series) return resources.byId[resources.selectedId]?.properties

        const [firstResource, ...remainingResources] = Object.values(resources.byId)
        return firstResource.properties.reduce((acc, cur) => {
            if (!remainingResources.every(r => r.properties.some(p => p.name === cur.name))) return acc
            return [...acc, cur]
        }, [] as Property[])
    }, [resources])

    function handleChange(filters: FilterGroup) {
        if (series) {
            dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: { filters } })
        } else {
            dispatch({ type: 'SET_FILTERS', payload: filters })
        }
    }

    function handleClose() {
        dispatch({ type: 'UPDATE_ADVANCED_FILTER_EDITOR', payload: { open: false, series: undefined } })
    }

    return (
        <Box
            sx={{
                backgroundColor: 'grayscale.lightest',
                position: 'absolute',
                transition: 'all 200ms',
                width: panelWidth,
                height: '100%',
                zIndex: 2,
            }}
            style={{ transform: `translateX(-${open ? 0 : panelWidth}px)` }}
        >
            <Box height="100%" display="flex" flexDirection="column" overflow="hidden">
                <Box pl={1} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>
                        Advanced Filter Editor{series ? ` (${activeSeries?.values[0].field.name} series)` : ''}
                    </Typography>
                    <IconButton onClick={handleClose} size="large">
                        <CloseIcon />
                    </IconButton>
                </Box>

                {renderContent && (
                    <AdvancedFilterEditor
                        appName={appName}
                        getResourceValues={modelService.getResourceValues}
                        value={activeFilters}
                        onCancel={handleClose}
                        onChange={handleChange}
                        properties={properties}
                        resourceName={resources?.byId[resources?.selectedId]?.name}
                    />
                )}
            </Box>
        </Box>
    )
}
