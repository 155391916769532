import makeStyles from '@mui/styles/makeStyles'

import { PropertyType } from 'genesis-suite/types/architectureTypes'
import { Property } from 'genesis-suite/types/networkTypes'
import { ConditionalFormat, DataField } from 'genesis-suite/types/visualTypes'
import { ComparisonSelector } from 'genesis-suite/components'
import PropertyChip from '../../PropertyChip'
import { getDataFieldProperty, getDisplayProperty } from '../../utils'
import ValueField from '../../ValueField'
import DnDPropertySelector from '../../selectors/DnDPropertySelector'
import useWidgetColors from '../../../../hooks/useWidgetColors'

interface Props {
    selectedRule: ConditionalFormat
    onUpdate: (update: Partial<ConditionalFormat>) => void
    onUpdateSource: (field: DataField) => void
    onUpdateTargetSource: (field: DataField) => void
    properties: Property[]
}

const useStyles = makeStyles(({ palette, spacing }) => ({
    borderedSection: {
        maxHeight: 200,
        display: 'flex',
        padding: spacing(0.5),
        flexFlow: 'column nowrap',
        border: `2px solid ${palette.grayscale.light}`,
    },
    ruleContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: spacing(0.5),
    },
}))

export default function ConditionalFormatSelectedEditor({
    selectedRule,
    onUpdateSource,
    onUpdate,
    onUpdateTargetSource,
    properties,
}: Props) {
    const selectedRuleProperty = selectedRule && getDataFieldProperty(selectedRule.source?.field, properties)
    const selectedRuleTargetProperty =
        selectedRule?.targetSource && getDataFieldProperty(selectedRule?.targetSource.field, properties)
    const selectedRuleDisplayProperty = getDisplayProperty(selectedRule?.source, selectedRuleProperty)
    const selectedRuleTargetDisplayProperty = getDisplayProperty(selectedRule?.targetSource, selectedRuleTargetProperty)
    const colors = useWidgetColors()
    const classes = useStyles()

    return (
        <DnDPropertySelector
            label="Rule definition"
            accept={propertyTypes}
            limit={1}
            onAdd={({ type, pointer, ...field }) => onUpdateSource(field)}
            onRemove={() => onUpdate({ source: undefined })}
            properties={selectedRuleDisplayProperty ? [{ ...selectedRuleDisplayProperty, color: colors[0] }] : []}
        >
            {selectedRuleDisplayProperty && (
                <div className={classes.ruleContainer}>
                    <ComparisonSelector
                        isAggregate
                        value={selectedRule?.rule ?? ''}
                        onChange={rule => onUpdate({ rule })}
                        semanticType={selectedRuleProperty?.semanticType}
                        disabled={!selectedRule?.source}
                    />

                    {selectedRule?.targetSource ? (
                        <PropertyChip
                            property={{ ...selectedRuleTargetDisplayProperty, color: colors[0] }}
                            accept={propertyTypes}
                            onAdd={({ type, pointer, ...field }) => onUpdateTargetSource(field)}
                            onAggregationChange={aggregation =>
                                onUpdate({
                                    targetSource: { field: selectedRule?.targetSource?.field, aggregation },
                                })
                            }
                            onRemove={() => onUpdate({ targetSource: undefined })}
                        />
                    ) : (
                        <ValueField
                            comparison={selectedRule?.rule}
                            field={selectedRule?.source?.field}
                            values={selectedRule?.target}
                            onChange={target => onUpdate({ target })}
                            disabled={!selectedRule?.source}
                            onAdd={onUpdateTargetSource}
                            properties={properties}
                        />
                    )}
                </div>
            )}
        </DnDPropertySelector>
    )
}

const propertyTypes = [PropertyType.DEFINING, PropertyType.QUALITATIVE, PropertyType.QUANTITATIVE]
