import { useMemo, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { useSnackbar } from 'notistack'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { GridRowId } from '@mui/x-data-grid-pro'
import { Box, IconButton, Popover, Tooltip, Typography } from '@mui/material'
import { AddRounded, CloudUploadRounded } from '@mui/icons-material'

import { EditBox, Excel, Trash } from 'genesis-suite/icons'
import { DropZone, FILE_TYPES } from 'genesis-suite/components'
import { TableFormConfig } from 'genesis-suite/types/visualTypes'
import { uploadService } from '../../../lib/services'
import { applicationSelectors, getSelectedBackup } from '../../../selectors'
import UploadFormExcelDialog from '../../widgets/visuals/FormWidget/UploadFormExcelDialog'

const useStyles = makeStyles(({ spacing }) => ({
    DZPaper: {
        padding: spacing(1),
        '& > div': { padding: spacing(1) },
    },
}))

interface TableFormHeaderProps {
    config: TableFormConfig
    selectedIds: GridRowId[] | string[]
    rows: number
    totalRows: number
    onAdd: () => void
    onDelete: () => void
    onInputEdit: () => void
    onExcelExport: () => void
    onUpload: (rows: any[], tableName: string, mappedFields: string[]) => Promise<any>
}

export default function TableFormHeader({
    config,
    onAdd,
    onDelete,
    onInputEdit,
    onUpload,
    onExcelExport,
    rows,
    totalRows,
    selectedIds,
}: TableFormHeaderProps) {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState(null)
    const [fileConfig, setFileConfig] = useState(null)
    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const selectedBackup = useSelector(getSelectedBackup)

    const { canAddNew, canDelete, canInputEdit, canUploadExcel } = config

    const buttonStyle = { padding: '10px', color: 'text.primary' }

    const selectedBackupBool = useMemo(() => {
        return selectedBackup.length > 0 ? true : false
    }, [selectedBackup])

    const onDrop = async fileArr => {
        if (!fileArr) return setFileConfig(null)

        const file = fileArr[0]
        const fileData = new FormData()
        fileData.append('file', file)
        const fileName = file.name.substring(0, file.name.lastIndexOf('.'))
        try {
            const fileToken = await uploadService.uploadFile(fileData, fileName, appName, true)
            setFileConfig({ fileName, fileToken })
            setAnchorEl(null)
        } catch (err) {
            console.error(err)
            showSnackbar('An error occurred uploading your file.', { variant: 'error' })
        }
    }

    const handleExcelSubmit = async (excelData, tableName, mappedFields) => {
        let formatted = null
        if (!tableName) {
            formatted = excelData.reduce((acc, row) => {
                const keysIWant = Object.keys(row)
                const obj = keysIWant.reduce((acc, key) => {
                    if (key !== 'TemplateRowId' && key !== 'RecordStatus') acc[key] = row[key]
                    return acc
                }, {})
                acc.push({ key: uuid(), ...obj })
                return acc
            }, [])
        }

        return onUpload(formatted, tableName, mappedFields)
            .then(() => {
                showSnackbar('Uploaded successfully', { variant: 'success' })
                setFileConfig(null)
            })
            .catch(() => Promise.reject('An error occurred saving Excel data. Please try again.'))
    }

    const oldFormFields = useMemo(
        () =>
            config.columns
                .filter(c => c.editable || c.required)
                .map(c => ({
                    shortName: c.title,
                    isKeyProperty: c.required,
                    fieldName: c.property.name,
                })),
        [config.columns]
    )

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box
                sx={{
                    display: 'flex',
                }}
            >
                {canAddNew && (
                    <Tooltip title="Add new">
                        <IconButton
                            sx={buttonStyle}
                            onClick={onAdd}
                            size="large"
                            data-cy="add-row"
                            disabled={selectedBackupBool}
                        >
                            <AddRounded />
                        </IconButton>
                    </Tooltip>
                )}
                {canInputEdit && (
                    <Tooltip title={selectedIds.length ? 'Edit selected in Form' : ''}>
                        <div>
                            <IconButton
                                sx={buttonStyle}
                                disabled={selectedIds.length < 1 || selectedBackupBool}
                                onClick={onInputEdit}
                                size="large"
                                data-cy="edit-row"
                            >
                                <EditBox />
                            </IconButton>
                        </div>
                    </Tooltip>
                )}
                {canDelete && (
                    <Tooltip title="Delete Selected">
                        <div>
                            <IconButton
                                sx={buttonStyle}
                                disabled={!selectedIds.length || selectedBackupBool}
                                onClick={onDelete}
                                size="large"
                                data-cy="delete-row"
                            >
                                <Trash />
                            </IconButton>
                        </div>
                    </Tooltip>
                )}
                {canUploadExcel && (
                    <Tooltip title="Upload Excel">
                        <IconButton
                            sx={buttonStyle}
                            onClick={e => setAnchorEl(e.currentTarget)}
                            size="large"
                            disabled={selectedBackupBool}
                        >
                            <CloudUploadRounded />
                        </IconButton>
                    </Tooltip>
                )}
                <Popover
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    classes={{ paper: classes.DZPaper }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <DropZone
                        onDrop={onDrop}
                        accept={FILE_TYPES.EXCEL}
                        message="Drop an Excel file or click to choose"
                    />
                </Popover>
                <Tooltip title="Export to Excel">
                    <IconButton sx={buttonStyle} onClick={onExcelExport} size="large">
                        <Excel />
                    </IconButton>
                </Tooltip>
                <UploadFormExcelDialog
                    file={fileConfig}
                    form={config}
                    onSubmit={handleExcelSubmit}
                    updateFile={onDrop}
                />
            </Box>
            <Typography variant="subtitle2" component="span">
                {rows === totalRows ? `Total Rows: ${totalRows}` : `Total Rows: ${rows} of ${totalRows}`}
            </Typography>
        </Box>
    )
}
