import SearchIcon from '@mui/icons-material/Search'
import { IconButton, Popover, Typography } from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { SearchBar } from 'genesis-suite/components'
import { logEvent } from '../../lib/amplitudeClient'
import { moduleSelectors } from '../../selectors'
import SearchListByNode from './SearchListByNode'

const useStyles = makeStyles(theme => ({
    paper: {
        width: '90vw',
    },
    icon: {
        color: theme.palette.getContrastText(theme.palette.background.topBar),
        fontSize: '20px',
    },
    loaderContainer: {
        position: 'relative',
        flexGrow: 1,
    },
    resultsContainer: {
        height: '45vh',
        display: 'flex',
        flexDirection: 'column',
    },
    resultsLabel: {
        padding: theme.spacing(1),
    },
    searchButton: {
        minWidth: 'unset',
    },
}))

export default function MobileSearchController() {
    const modulesPending = useSelector(moduleSelectors.getModulesPending)
    const enabled = useSelector(moduleSelectors.getEnableSearch)
    const theme = useTheme()

    const iconRef = useRef()
    const [openInput, setOpenInput] = useState(false)
    const [openSearch, setOpenSearch] = useState(false)
    const [text, setText] = useState('')
    const classes = useStyles()

    if (!enabled) return

    function handleText(val) {
        setOpenSearch(false)
        setText(val)
    }

    function handlePerformSearch(query, usedVoice) {
        if (!query) return
        if (usedVoice) logEvent('VOICE_EVENT', { result: query })
        setOpenSearch(true)
    }

    function handleDone() {
        setOpenInput(false)
        setOpenSearch(false)
        setText('')
    }

    const searchColor = theme.palette.getContrastText(theme.palette.background.topBar)

    return (
        <>
            <IconButton onClick={() => setOpenInput(true)} className={classes.searchButton} size="large">
                <SearchIcon className={classes.icon} ref={iconRef} />
            </IconButton>
            <Popover
                open={openInput}
                onClose={handleDone}
                anchorEl={iconRef.current}
                classes={{ paper: classes.paper }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <SearchBar
                    InputProps={{ autoFocus: true, disableUnderline: true }}
                    placeholder="Search Tada"
                    height="small"
                    onEnter={handlePerformSearch}
                    disabled={modulesPending}
                    value={text}
                    onChange={handleText}
                    collapsable={!openInput}
                    radius={false}
                    inputColor={searchColor}
                    iconColor={searchColor}
                />
                {openSearch && (
                    <div className={classes.resultsContainer}>
                        <Typography className={classes.resultsLabel} variant="h6">
                            Search Results
                        </Typography>
                        <SearchListByNode text={text} />
                    </div>
                )}
            </Popover>
        </>
    )
}
