import { CSSProperties } from 'react'

import { Axis, ChartType, Widget } from 'genesis-suite/types/visualTypes'
import { WidgetLayout } from '../../../types/WidgetTypes'

const baseGrid: CSSProperties = { display: 'grid', height: '100%' }

/** Create a trellis grid for widgets based on available container size */
export default function makeWidgetLayout(containerWidth: number, widgetCount: number, config: Widget): WidgetLayout {
    if (!config?.trellis?.enabled)
        return { columnCount: 1, rowCount: 1, containerStyle: { height: '100%', display: 'flex' } }

    switch (config.type) {
        case ChartType.BULLET:
            return {
                columnCount: 1,
                rowCount: widgetCount,
                containerStyle: {
                    ...baseGrid,
                    gridTemplateColumns: 'minmax(0, 1fr)',
                    gridAutoRows: 180,
                },
            }
    }

    const { gridColumns, gridRows } = config.trellis.layout ?? {}

    let minWidth = 250
    let minHeight = 250
    if (config.type === ChartType.LABEL) {
        minWidth = 180
        minHeight = 70
    }

    const colWidth = containerWidth < minWidth ? containerWidth : minWidth
    const colCount = Math.floor(containerWidth / colWidth)
    const correctedColCount = widgetCount < colCount ? widgetCount : colCount

    const rowHeight = minHeight
    const correctedRowCount = Math.ceil(widgetCount / correctedColCount)

    let columns: number
    let rows: number

    if (gridColumns == undefined && gridRows == undefined) {
        columns = correctedColCount
        rows = correctedRowCount
    } else if (gridColumns == undefined) {
        columns = Math.ceil(widgetCount / gridRows)
        rows = gridRows
    } else if (gridRows == undefined) {
        columns = gridColumns
        rows = Math.ceil(widgetCount / gridColumns)
    } else {
        columns = gridColumns
        rows = gridRows
    }

    return {
        columnCount: columns,
        rowCount: rows,
        containerStyle: {
            ...baseGrid,
            gap: 1,
            gridTemplateColumns: `repeat(${columns}, minmax(0px,1fr))`,
            gridTemplateRows: `repeat(${rows}, minmax(${rowHeight}px,1fr))`,
        },
    }
}

export function createTrellisAxes(
    config: Widget,
    layout: WidgetLayout,
    trellisMetaData: { index: number; widgetCount: number; trellisOverflow: boolean }
): { xAxis: Axis; yAxis: Axis[] } {
    let xAxisVisibility
    let yAxisVisibility
    const invertAxis = 'invertAxis' in config ? config.invertAxis : false
    const { rowCount, columnCount } = layout
    const { index, widgetCount, trellisOverflow } = trellisMetaData

    if (showDefaultAxes(config, trellisOverflow)) {
        return { xAxis: config?.xAxis, yAxis: config?.yAxis }
    }

    const lastRowStart = (Math.ceil(widgetCount / rowCount) - 1) * columnCount
    const nextToLastRowStart = lastRowStart - columnCount
    const lastRowEnd = lastRowStart + columnCount
    const emptyCellCount = lastRowEnd - widgetCount
    const xAxisStart = emptyCellCount === 0 ? lastRowStart : nextToLastRowStart + columnCount - emptyCellCount
    const isFirstColumn = index % columnCount === 0

    if (invertAxis) {
        xAxisVisibility = isFirstColumn ? 'visible' : 'hidden'
        yAxisVisibility = index >= xAxisStart ? 'visible' : 'hidden'
    } else {
        xAxisVisibility = index >= xAxisStart ? 'visible' : 'hidden'
        yAxisVisibility = isFirstColumn ? 'visible' : 'hidden'
    }

    return {
        xAxis: {
            ...config?.xAxis,
            label: { ...config?.xAxis?.label, style: { ...config?.xAxis?.label?.style, visibility: xAxisVisibility } },
        },
        yAxis: config?.yAxis?.map(y => ({
            ...y,
            label: { ...y?.label, style: { ...y?.label?.style, visibility: yAxisVisibility } },
        })),
    }
}

function showDefaultAxes(config: Widget, trellisOverflow: boolean) {
    switch (true) {
        case !config?.trellis?.enabled:
        case config?.type !== 'combo':
        case trellisOverflow:
            return true
        default:
            return false
    }
}
