import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
    Box,
    Button,
    Checkbox,
    Collapse,
    FormControlLabel,
    IconButton,
    List,
    ListItem,
    Switch,
    Tab,
    Tabs,
    TextField,
    Tooltip,
    Typography,
    Autocomplete,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles'
import CloseIcon from '@mui/icons-material/Close'
import HelpIcon from '@mui/icons-material/HelpOutline'

import { Dashboard, Widget } from 'genesis-suite/types/visualTypes'
import { applicationSelectors, deploymentSelectors, moduleSelectors } from '../../selectors'
import { visualService } from '../../lib/services'
import { businessExplorerCreators, dialogCreators, navigationCreators, userNavCreators } from '../../actions/creators'
import { updateDashboardOrCreateDraft } from '../../lib/manageUtils'

const useStyles = makeStyles(({ spacing, palette }) => ({
    publisherContainer: {
        padding: spacing(),
        borderLeft: `1px solid ${palette.grayscale.light}`,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    tabs: { borderBottom: `1px solid ${palette.grayscale.light}` },
    tab: { minWidth: '50%' },
    switchGroup: { display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' },
    switch: { color: palette.secondary.main },
    switchTrack: { backgroundColor: palette.secondary.main },
    dashboardsList: { overflow: 'auto', background: '#fff' },
    selectedDashboard: { backgroundColor: `${palette.primary.main} !important`, color: palette.primary.contrastText },
}))

interface Props {
    open: boolean
    focalPoints: string[]
    configDictionary: { [id: string]: Widget }
    selectedConfigIds: string[]
    onDone: () => void
}

export default function BusinessExplorerPublisher({
    open,
    focalPoints,
    configDictionary,
    selectedConfigIds,
    onDone,
}: Props) {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const moduleId = useSelector(moduleSelectors.getModuleId)
    const isV2 = useSelector(moduleSelectors.getIsV2)
    const viewFlag = useSelector(deploymentSelectors.getDeploymentViewFlag)
    const dispatch = useDispatch()

    const [tab, setTab] = useState<'new' | 'existing'>('new')
    const [dashboards, setDashboards] = useState([])
    const [isPerspective, setIsPerspective] = useState(false)
    const [focalPoint, setFocalPoint] = useState('')
    const [selectedId, setSelectedId] = useState('')
    const [title, setTitle] = useState('')

    const usedFocalPoints = focalPoints.filter(f => dashboards.some(d => d.focalPoint === f))
    const filteredDashboards = dashboards
        .filter(d =>
            isPerspective
                ? focalPoint
                    ? d.focalPoint === focalPoint
                    : usedFocalPoints.includes(d.focalPoint)
                : !d.focalPoint
        )
        .sort((a, b) => a.title.localeCompare(b.title))
    const isValid = validate(selectedConfigIds.length, tab, isPerspective, focalPoint, title, selectedId)
    const classes = useStyles()

    useEffect(() => {
        if (!open) {
            setTab('new')
            setIsPerspective(false)
            setFocalPoint('')
            setSelectedId('')
            return
        }

        if (tab === 'new') return

        const options: any = {
            author: 'user',
            global: true,
            ...(isV2 && { moduleId }),
            ...(focalPoint && { focalPoint }),
        }

        visualService.getMetaDashboards(appName, options, viewFlag).then(d => {
            setDashboards(d.data)
        })
    }, [open, tab, focalPoint])

    async function handlePublish() {
        const dialogKey = 'publishing-widgets'
        dispatch(dialogCreators.showLoading(dialogKey, 'Publishing your widgets...', { hideBackdrop: false }))

        try {
            let widgets = []
            let perspectiveId

            if (tab === 'existing') {
                const dashboard = await visualService.getDashboardById(appName, selectedId, false, viewFlag)
                widgets = dashboard.widgets ?? []
            }

            for (const configId of selectedConfigIds) {
                const { id } = await visualService.createWidget(
                    appName,
                    { ...configDictionary[configId], ...(isV2 && { moduleId }) },
                    viewFlag
                )

                widgets.push({ id })
            }

            if (tab === 'existing') {
                await updateDashboardOrCreateDraft(appName, selectedId, { widgets }, viewFlag)
                perspectiveId = selectedId
            } else {
                const dashboardBody: Dashboard = {
                    title,
                    ...(focalPoint && { focalPoint }),
                    ...(isV2 && { moduleId }),
                    widgets,
                    inNavigation: true,
                }
                const perspective = await visualService.createDashboard(appName, dashboardBody, viewFlag)
                perspectiveId = perspective.id
                dispatch(userNavCreators.add(perspective))
            }

            dispatch(businessExplorerCreators.deleteConfigs(selectedConfigIds))
            dispatch(navigationCreators.goToPerspective(perspectiveId))
        } catch (err) {
            console.error(err)
        }

        dispatch(dialogCreators.hideDialog(dialogKey))
    }

    function handleTab(e, v) {
        setFocalPoint('')
        setTab(v)
    }

    function handleSwitch() {
        setIsPerspective(s => !s)
        setSelectedId('')
    }

    if (!open) return null

    return (
        <div className={classes.publisherContainer}>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                <Typography variant="h6">Save</Typography>
                <IconButton onClick={onDone} size="small">
                    <CloseIcon fontSize="small" />
                </IconButton>
            </Box>

            <Typography variant="subtitle2" gutterBottom>
                Create a new view with selected widgets or add to an existing view.
            </Typography>

            <Tabs className={classes.tabs} value={tab} onChange={handleTab}>
                <Tab className={classes.tab} value="new" label="New" />
                <Tab className={classes.tab} value="existing" label="Existing" />
            </Tabs>

            <Box flex={1} display="flex" flexDirection="column" overflow="hidden" py={2}>
                {tab === 'new' ? (
                    <>
                        <TextField
                            fullWidth
                            required
                            margin="dense"
                            variant="outlined"
                            autoFocus
                            label="Title"
                            value={title}
                            onChange={({ target }) => setTitle(target.value)}
                        />

                        <Box display="flex" alignItems="center">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isPerspective}
                                        onChange={({ target }) => setIsPerspective(target.checked)}
                                    />
                                }
                                label="Perspective"
                            />
                            <Tooltip
                                title={
                                    <div>
                                        <p>
                                            Saving by default will create a collection. Collections contain widgets that
                                            include any business element and metric from the entire network
                                        </p>
                                        <p>
                                            Perspectives are used for navigating from a widget and requires a focal
                                            point that includes relevant insights
                                        </p>
                                    </div>
                                }
                            >
                                <HelpIcon />
                            </Tooltip>
                        </Box>

                        <Collapse in={isPerspective}>
                            <Autocomplete
                                disableClearable
                                options={focalPoints}
                                value={focalPoint}
                                onChange={(e, newValue) => setFocalPoint(newValue)}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        margin="dense"
                                        variant="outlined"
                                        label="Focal point"
                                    />
                                )}
                            />
                        </Collapse>
                    </>
                ) : (
                    <>
                        <div className={classes.switchGroup} onClick={handleSwitch}>
                            <Typography>Collections</Typography>
                            <Switch
                                classes={{ colorSecondary: classes.switch, track: classes.switchTrack }}
                                checked={isPerspective}
                            />
                            <Typography>Perspectives</Typography>
                            <Tooltip
                                title={
                                    <div>
                                        <p>
                                            Collections contain widgets that include any business element and metric
                                            from the entire network
                                        </p>
                                        <p>
                                            Perspectives are used for navigating from a widget and requires a focal
                                            point that includes relevant insights
                                        </p>
                                    </div>
                                }
                            >
                                <HelpIcon style={{ marginLeft: '3px', fontSize: '12px' }} />
                            </Tooltip>
                        </div>

                        <Collapse in={isPerspective}>
                            <Autocomplete
                                disableClearable
                                options={usedFocalPoints}
                                value={focalPoint}
                                onChange={(e, newValue) => setFocalPoint(newValue)}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        margin="dense"
                                        variant="outlined"
                                        label="Focal point"
                                    />
                                )}
                            />
                        </Collapse>

                        <List className={classes.dashboardsList} dense>
                            {filteredDashboards.length ? (
                                filteredDashboards.map(({ id, title }) => (
                                    <ListItem
                                        key={id}
                                        button
                                        classes={{ selected: classes.selectedDashboard }}
                                        selected={id === selectedId}
                                        onClick={() => setSelectedId(id)}
                                    >
                                        {title}
                                    </ListItem>
                                ))
                            ) : (
                                <Box mx={1} my={2}>
                                    {isPerspective ? (
                                        <Typography>
                                            You do not have any perspectives
                                            {focalPoint ? ' with this focal point' : ''}
                                        </Typography>
                                    ) : (
                                        <Typography>You do not have any collections</Typography>
                                    )}
                                </Box>
                            )}
                        </List>
                    </>
                )}
            </Box>

            <Box display="flex" justifyContent="flex-end">
                <Button color="primary" variant="contained" disabled={!isValid} onClick={handlePublish}>
                    Done
                </Button>
            </Box>
        </div>
    )
}

function validate(configLength, tab, isPerspective, focalPoint, title, selectedId) {
    if (!configLength) return false

    if (tab === 'new') {
        if (!title) return false
        if (isPerspective && !focalPoint) return false
    } else {
        if (!selectedId) return false
    }

    return true
}
