import { Theme } from '@mui/material'
import domtoimage from 'dom-to-image'
import mergeImages, { Options } from 'merge-images'
import moment from 'moment'

import isHighChartsType from '../../widgets2/utils/isHighChartsType'
import tadaWatermark from '../../../assets/img/tada.png'
import store from '../../../store/store'
import { getConverter } from '../WidgetConverter'

export type ExportWidgetType = 'chart' | 'table' | 'generic'

export enum ContentType {
    PNG = 'image/png',
    JPEG = 'image/jpeg',
}

type ExportConfig = {
    images: Array<{
        src: string
        x?: number
        y?: number
        opacity?: number
    }>
    options: Options
    widgetWidth: number
    widgetHeight: number
}

export type ExportOptions = {
    includeWatermark?: boolean
    title?: string
}

const WATERMARK_OPACITY = 0.2

/** Get the type of export widget or undefined if not supported */
export function getExportType(config): ExportWidgetType {
    const { type, Type } = config
    const isV2HighChart = type && isHighChartsType(type)
    if (isV2HighChart) return 'generic'
    if (isVisorHighchart(Type)) return getConverter(config) ? 'generic' : 'chart'
    if (type === 'table' || Type === 'Table') return 'table'
    if (type === 'form' || Type === 'Form' || type === 'label' || Type === 'Label') return 'generic'
}

function isVisorHighchart(type) {
    switch (type) {
        case 'BubbleChart':
        case 'Bullet':
        case 'Chart':
        case 'Flow':
        case 'Heatmap':
        case 'Histogram':
        case 'Treemap':
            return true
        default:
            return false
    }
}

export async function copyImage(exportType: ExportWidgetType, visualRef, exportOptions?: ExportOptions) {
    const contentType = ContentType.PNG
    const exportConfig = await getExportConfig(exportType, visualRef)
    const imageBlob = await makeImageBlob(exportConfig, contentType, exportOptions)

    await copyToClipboard(imageBlob, contentType)
}

export async function exportImage(
    exportType: ExportWidgetType,
    visualRef,
    type: ContentType,
    filename: string,
    exportOptions?: ExportOptions
) {
    const exportConfig = await getExportConfig(exportType, visualRef)
    const imageBlob = await makeImageBlob(exportConfig, type, exportOptions)

    downloadFile(imageBlob, filename, type)
}

export async function getImageBlob(exportType: ExportWidgetType, visualRef, exportOptions?: ExportOptions) {
    const contentType = ContentType.PNG
    const exportConfig = await getExportConfig(exportType, visualRef)
    const imageBlob = await makeImageBlob(exportConfig, contentType, exportOptions)

    return imageBlob
}

async function getExportConfig(exportType: ExportWidgetType, visualRef) {
    let exportConfig: ExportConfig

    switch (exportType) {
        case 'chart': {
            exportConfig = await createWidgetExport(visualRef.current.chart.container)
            break
        }
        case 'table': {
            exportConfig = await createTableExport(visualRef.current)
            break
        }
        case 'generic': {
            exportConfig = await createWidgetExport(visualRef.current)
        }
    }
    return exportConfig
}

async function makeImageBlob(exportConfig: ExportConfig, contentType: ContentType, exportOptions?: ExportOptions) {
    const { widgetWidth, widgetHeight, images } = exportConfig
    const { includeWatermark, title } = exportOptions ?? {}
    const appLogoWatermark = showAppLogoWatermark()

    let titleHeight: number
    let titleImage: string
    let options = exportConfig.options
    let updatedImages = [...exportConfig.images]

    if (title) {
        let titleElement = createTitleElement(widgetWidth, title)

        try {
            titleElement = document.body.appendChild(titleElement)
            titleHeight = titleElement.offsetHeight
            titleImage = await domtoimage.toPng(titleElement)
        } catch (error) {
            console.error(error)
        }

        document.body.removeChild(titleElement)

        options = { ...options, height: widgetHeight + titleHeight }

        updatedImages = images.map(widgetImage => ({ ...widgetImage, y: widgetImage.y + titleHeight }))

        updatedImages.push({ src: titleImage, x: 0, y: 0 })
    }

    if (appLogoWatermark && includeWatermark) {
        const correctedWidgetHeight = title ? widgetHeight + titleHeight : widgetHeight
        const watermark = await createWatermark(tadaWatermark, widgetWidth, correctedWidgetHeight)

        updatedImages.push({ ...watermark })
    }

    const base64 = await mergeImages(updatedImages, options)

    const [_, data] = base64.split(',')
    return b64toBlob(data, contentType)
}

async function createTableExport(element: HTMLDivElement): Promise<ExportConfig> {
    const scrollBarWidth = 8
    const headerElement = element.querySelector('.ag-header-container') as HTMLDivElement
    const headerImage: string = await domtoimage.toPng(headerElement)

    const bodyElement = element.querySelector('.ag-center-cols-container') as HTMLDivElement
    const bodyImage: string = await domtoimage.toPng(bodyElement)

    const headerHeight = headerElement.offsetHeight
    const bodyChildren = Array.from(bodyElement.children) as HTMLDivElement[]
    const bodyHeight = bodyChildren.reduce((acc, cur) => (acc += cur.offsetHeight), 0)
    const height = headerHeight + bodyHeight
    const width = headerElement.offsetWidth - scrollBarWidth
    const options: Options = { format: ContentType.PNG, width, height }

    const images = [
        { src: headerImage, x: 0, y: 0 },
        { src: bodyImage, x: 0, y: headerHeight },
    ]

    return { images, options, widgetWidth: width, widgetHeight: height }
}

async function createWidgetExport(element: HTMLDivElement): Promise<ExportConfig> {
    const domtoimageOptions = { height: element.offsetHeight }
    const widgetImage = await createImageElement(await domtoimage.toPng(element, domtoimageOptions))

    const images = [{ src: widgetImage.src, x: 0, y: 0 }]

    const options: Options = { format: ContentType.PNG, width: widgetImage.width, height: widgetImage.height }

    return { images, options, widgetWidth: widgetImage.width, widgetHeight: widgetImage.height }
}

function createImageElement(imageSrc: string): Promise<HTMLImageElement> {
    return new Promise(res => {
        const image = new Image()
        image.onload = function () {
            res(image)
        }
        image.src = imageSrc
    })
}

async function createWatermark(src: string, widgetWidth: number, widgetHeight: number) {
    let watermark = await createImageElement(src)
    watermark = await resizeWatermark(watermark, widgetWidth, widgetHeight)
    const watermarkPosition = centerWatermark(watermark, widgetWidth, widgetHeight)

    return { src: watermark.src, opacity: WATERMARK_OPACITY, ...watermarkPosition }
}

function createTitleElement(width: number, title: string) {
    const titleElement = document.createElement('h6')
    const titleStyles = createTitleStyles(String(width))

    titleElement.textContent = title

    for (const prop in titleStyles) {
        titleElement.style[prop] = titleStyles[prop]
    }
    return titleElement
}

function centerWatermark(watermark: HTMLImageElement, widgetWidth: number, widgetHeight: number) {
    const x = widgetWidth - watermark.width - 5
    const y = widgetHeight - watermark.height - 5

    return { x, y }
}

async function resizeWatermark(watermark: HTMLImageElement, widgetWidth: number, widgetHeight: number) {
    const canvas = document.createElement('canvas')
    const canvasContext = canvas.getContext('2d')

    const watermarkRatio = watermark.width / watermark.height
    const MAX_HEIGHT = widgetHeight * 0.35

    let newWidth = 80
    let newHeight = newWidth / watermarkRatio

    if (newHeight > MAX_HEIGHT) {
        newWidth = MAX_HEIGHT * watermarkRatio
        newHeight = newWidth / watermarkRatio
    }

    canvas.width = newWidth
    canvas.height = newHeight

    canvasContext.drawImage(watermark, 0, 0, newWidth, newHeight)

    return await createImageElement(canvas.toDataURL())
}

export function downloadFile(blob, filename = 'unnamed', contentType: ContentType) {
    const correctedFilename = filename.replace(/[/\\?%*:|"<>]/g, '-')
    const timestamp = moment().format('_YYYY-MM-DD-HHmmss')
    const filenameWithTimestamp = `${correctedFilename}${timestamp}${
        contentType === ContentType.PNG ? '.png' : '.jpeg'
    }`

    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = filenameWithTimestamp
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

export async function copyToClipboard(blob, contentType: ContentType) {
    //@ts-ignore
    await navigator.clipboard.write([new window.ClipboardItem({ [contentType]: blob })])
}

function b64toBlob(b64Data, contentType, sliceSize = 512) {
    const byteCharacters = atob(b64Data)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)

        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
    }

    const blob = new Blob(byteArrays, { type: contentType })
    return blob
}

function createTitleStyles(width: string): Partial<CSSStyleDeclaration> {
    const theme: Theme = store.getState().theme

    return {
        fontSize: '1.16rem',
        fontWeight: '500',
        display: 'inline-block',
        margin: '0px',
        backgroundColor: theme.palette.background.main,
        paddingTop: '5px',
        paddingBottom: '5px',
        width: `${width}px`,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    }
}

export function showAppLogoWatermark() {
    const environment = window.TADA_APIS.ENVIRONMENT

    switch (environment) {
        case 'copperwire': {
            return false
        }
        default: {
            return true
        }
    }
}
