import {
    createInstance,
    OptimizelyProvider,
    OptimizelyFeature,
    useFeature as useOptimizelyFeature,
} from '@optimizely/react-sdk'
import { useSelector } from 'react-redux'

import { applicationSelectors, authSelectors } from '../selectors'
import { FeatureProps } from '@optimizely/react-sdk/dist/Feature'

type Feature =
    | 'builder'
    | 'builder_drafts'
    | 'business_explorer'
    | 'comments'
    | 'compare_filtering'
    | 'demo'
    | 'log_rocket'
    | 'profiles'
    | 'show_details'
    | 'tasks'

const environment = window.TADA_APIS.ENVIRONMENT || ''
const sdkKey = process.env.NODE_ENV === 'production' ? 'E2ZkTWuG3DoH2PrKZqDSX' : 'L5XLbyVTL3giqETqFKTXd'
const optimizely = createInstance({ sdkKey, logLevel: 4 })

export function FeatureFlagProvider({ children }) {
    const { alias, email, firstName, lastName, userId } = useSelector(authSelectors.getUser) || {}
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const appPermission = useSelector(authSelectors.getAppPermission)
    const userObj = {
        id: userId,
        attributes: {
            alias,
            email,
            firstName,
            lastName,
            ...(appName && { appName }),
            ...(appPermission && { appPermission }),
            ...(environment && { environment }),
        },
    }

    return (
        <OptimizelyProvider optimizely={optimizely} user={userObj}>
            {children}
        </OptimizelyProvider>
    )
}

interface FeatureFlagProps extends FeatureProps {
    feature: Feature
}

export const FeatureFlag = (props: FeatureFlagProps) => <OptimizelyFeature {...props} />

type VariableValuesObject = {
    [key: string]: boolean | number | string | null
}

export function useFeature(featureName: Feature): [boolean, VariableValuesObject, boolean, boolean] {
    const [enabled, ...rest] = useOptimizelyFeature(featureName, { autoUpdate: true })

    let isValid = true
    switch (featureName) {
        case 'builder':
        case 'builder_drafts':
            if (!window.TADA_APIS.VISUALS) isValid = false
            break
        case 'tasks':
        case 'comments':
            if (!window.TADA_APIS.COLLAB) isValid = false
            break
        case 'log_rocket':
            if (!window.TADA_APIS.LOG_ROCKET_KEY) isValid = false
            break
    }

    return [enabled && isValid, ...rest]
}
