import { ExpandMore } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import {
    DataGridProProps,
    GridColumns,
    gridFilteredDescendantCountLookupSelector,
    GridRenderCellParams,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid-pro'
import { isEmpty } from 'lodash'

import { ChopText } from 'genesis-suite/components'
import { Basket, DataResponse, MapSeries } from 'genesis-suite/types/visualTypes'
import TableHeaderCell from '../../TableHeaderCell'

const tadaLocationIdKey = 'tadaLocId'

export interface RowData {
    id: string
    tadaLocId: string
    path: string[]
}

interface SeriesPath {
    seriesTitle: string
    paths: { [key: string]: [{ id: string; tadaLocId: string; label: string }] }
}

export function makeRows(mapSeries: MapSeries[], mapData: DataResponse) {
    const seriesPaths: SeriesPath[] = []

    mapSeries?.forEach((series, seriesIndex) => {
        const paths = createPaths(series, mapData, seriesIndex)
        if (paths) seriesPaths.push(paths)
    })
    return convertPathToRows(seriesPaths)
}

export function makeColumns(): GridColumns {
    return [
        {
            renderHeader: () => <TableHeaderCell title="Tada Location ID" />,
            renderCell: ({ value }) => <ChopText showEllipsis>{value}</ChopText>,
            field: tadaLocationIdKey,
            disableColumnMenu: true,
            sortable: false,
            resizable: false,
            width: 150,
        },
    ]
}

export function makeGroupingColumn(): DataGridProProps['groupingColDef'] {
    return {
        renderHeader: () => <TableHeaderCell title="Group" />,
        renderCell: params => <GroupingCell {...params} />,
        resizable: false,
        sortable: true,
    }
}

function createPaths(series: MapSeries, mapData: DataResponse, seriesIndex: number): SeriesPath {
    const seriesData = mapData?.[seriesIndex]?.data[0]?.rawData
    const idIndex = series.values.findIndex(value => value.basket === Basket.ID)
    const tadaLocIndex = series.values.findIndex(value => value.basket === Basket.ID2)
    const labelIndex = series.values.findIndex(value => value.basket === Basket.LABEL)

    if (seriesData) {
        const paths: SeriesPath['paths'] = {}
        const seriesTitle = series.title

        const ids = new Set()
        for (const data of seriesData) {
            const id = data?.[idIndex] ? String(data[idIndex]) : ''
            const tadaLocId = data?.[tadaLocIndex] ? String(data[tadaLocIndex]) : ''
            const pathLabel = data?.[labelIndex] ? String(data[labelIndex]) : ''

            if (!id || (!pathLabel && !tadaLocId) || ids.has(id)) continue
            ids.add(id)

            if (pathLabel) {
                if (paths[pathLabel]?.length > 0) paths[pathLabel].push({ id, label: pathLabel, tadaLocId })
                else paths[pathLabel] = [{ id, label: pathLabel, tadaLocId }]
            } else if (tadaLocId) {
                if (paths[tadaLocId]?.length > 0) paths[tadaLocId].push({ id, label: pathLabel, tadaLocId })
                else paths[tadaLocId] = [{ id, label: pathLabel, tadaLocId }]
            }
        }
        return !isEmpty(paths) ? { seriesTitle, paths } : undefined
    }
}

function convertPathToRows(seriesPaths: SeriesPath[]) {
    return seriesPaths.reduce((rows, { seriesTitle, paths }) => {
        for (const [parentLabel, children] of Object.entries(paths)) {
            if (children?.length > 1) {
                children.forEach(child => {
                    if (child.label) {
                        rows.push({
                            id: `${seriesTitle}${child.id}`,
                            tadaLocId: child.tadaLocId,
                            path: [seriesTitle, parentLabel, child.id],
                        })
                    } else if (child.tadaLocId) {
                        rows.push({
                            id: `${seriesTitle}${child.id}`,
                            tadaLocId: child.tadaLocId,
                            path: [seriesTitle, child.id],
                        })
                    }
                })
            } else {
                const [{ id, tadaLocId, label }] = children

                if (!label) rows.push({ id: `${seriesTitle}${id}`, tadaLocId, path: [seriesTitle, id] })
                else rows.push({ id: `${seriesTitle}${id}`, tadaLocId, path: [seriesTitle, label] })
            }
        }
        return rows
    }, [] as RowData[])
}

function GroupingCell(props: GridRenderCellParams) {
    const { id, field, rowNode, value } = props

    const apiRef = useGridApiContext()
    const descendantCountLookup = useGridSelector(apiRef, gridFilteredDescendantCountLookupSelector)

    const descendantCount = descendantCountLookup[rowNode.id] ?? 0
    const descendantCountText = descendantCount > 0 ? `(${Intl.NumberFormat('en-US').format(descendantCount)})` : ''
    const valueText = `${value} ${descendantCountText}`

    const handleClick = event => {
        apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)
        apiRef.current.setCellFocus(id, field)
        event.stopPropagation()
    }

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                overflow: 'hidden',
                ml: rowNode.depth * 2,
            }}
        >
            <IconButton
                onClick={handleClick}
                sx={{
                    mr: 2,
                    visibility: descendantCount > 0 ? 'visible' : 'hidden',
                    transition: 'transform 0.3s',
                    transform: rowNode.childrenExpanded ? 'rotate(0deg)' : 'rotate(-90deg)',
                    color: 'text.primary',
                }}
                size="small"
            >
                <ExpandMore />
            </IconButton>
            <ChopText showEllipsis>{valueText}</ChopText>
        </Box>
    )
}
