import makeStyles from '@mui/styles/makeStyles'
import ModuleColors from './ModuleColors'

const useStyles = makeStyles(({ spacing }) => ({
    root: { width: '100%', padding: spacing(), height: '100%' },
}))

export default function ManageTheme({ updateSaveHandle, updateCancelHandle, module, setModule, ...rest }) {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <ModuleColors
                updateSaveHandle={updateSaveHandle}
                updateCancelHandle={updateCancelHandle}
                module={module}
                setModule={setModule}
                {...rest}
            />
        </div>
    )
}
