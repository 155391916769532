import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box, Typography } from '@mui/material'
import { isEqual } from 'lodash'

import EditFilters from '../EditFilters'
import { moduleCreators } from '../../actions/creators'
import { applicationSelectors, deploymentSelectors } from '../../selectors'
import { visualService } from '../../lib/services'

export default function ManageGlobalFilters({ module, updateSaveHandle, updateCancelHandle }) {
    const { id, version } = module
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const viewFlag = useSelector(deploymentSelectors.getDeploymentViewFlag)
    const dispatch = useDispatch()

    const [draft, setDraft] = useState()
    const [filters, setFilters] = useState()

    useEffect(() => {
        const getModuleData = async () => {
            try {
                const moduleData = await visualService.getModuleById(appName, id, viewFlag)
                setDraft(moduleData.filters)
                setFilters(moduleData.filters)
            } catch (error) {
                console.error(error)
            }
        }
        getModuleData()
    }, [])

    useEffect(() => {
        const isSame = isEqual(draft, filters)
        updateSaveHandle(
            isSame
                ? undefined
                : {
                      label: 'Save',
                      onClick: async () => {
                          try {
                              await dispatch(moduleCreators.updateModule(id, { filters: draft }, version === '2'))
                              setFilters(draft)
                          } catch (error) {
                              console.error(error)
                          }
                      },
                  }
        )
        updateCancelHandle(isSame ? undefined : () => setDraft(filters))
    }, [filters, draft])

    return (
        <Box p={1} height="100%" display="flex" flexDirection="column">
            <Typography variant="h6" gutterBottom>
                Global filters
            </Typography>
            <Box flex="1" overflow="hidden">
                <EditFilters filters={draft} onChange={setDraft} />
            </Box>
        </Box>
    )
}
