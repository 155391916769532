import { FormControlLabel, Switch, Typography } from '@mui/material'

const MultiEditToggle = ({ className, value, onChange }) => {
    return (
        <div className={className}>
            <div>
                <FormControlLabel
                    control={
                        <Switch
                            color="primary"
                            checked={value}
                            onChange={() => {
                                onChange(!value)
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    sx={{
                        margin: 0,
                    }}
                    label={
                        <Typography display="inline" variant="subtitle1" fontWeight={'bold'}>
                            Allow Multiple Edits in Forms
                        </Typography>
                    }
                    labelPlacement="start"
                />
            </div>
        </div>
    )
}
export default MultiEditToggle
