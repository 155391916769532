import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { isEqual } from 'lodash'

import { ChartType, Dashboard, DraftKey, TextConfig, Widget } from 'genesis-suite/types/visualTypes'
import { visualService } from '../../../lib/services'
import { applicationSelectors, deploymentSelectors } from '../../../selectors'
import DashboardDisplay from './DashboardDisplay'
import { DashboardAction } from './types'

interface Props {
    /** (optional) overwrite default actions */
    actions?: DashboardAction[]
    config: Dashboard
    /** (default true) */
    editable?: boolean
    /** (optional) Override current view */
    view?: DraftKey
}

export default function CustomDashboard({ config, view, ...rest }: Props) {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const currentView = useSelector(deploymentSelectors.getDeploymentViewFlag)
    const [widgets, setWidgets] = useState<Widget[]>([])
    const _view = view || currentView

    const previousConfig = useRef<Dashboard>()
    useEffect(() => {
        if (isEqual(config, previousConfig.current)) return

        previousConfig.current = config

        Promise.allSettled(config.widgets?.map(w => visualService.getWidgetById(appName, w.id, _view))).then(data => {
            const widgets = data.map((res, index) => {
                return res.status === 'fulfilled' ? res.value : { ...missingWidgetConfig, id: config.widgets[index].id }
            })
            setWidgets(widgets)
        })
    }, [config, _view])

    if (!widgets) return null

    return <DashboardDisplay config={config} widgets={widgets} {...rest} />
}

const missingWidgetConfig: TextConfig = {
    type: ChartType.TEXT,
    text: 'This widget no longer exists',
    title: 'Missing Widget',
}
