import { Box, Button, useTheme } from '@mui/material'
import { DimLoader } from 'genesis-suite/components'
import { useUpdateEffect } from 'genesis-suite/hooks'
import { FormData, FormSection, InputFormConfig } from 'genesis-suite/types/visualTypes'
import { useSnackbar } from 'notistack'
import { ComponentType, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getFormBackground, getallPrompts, isDefaultFormPalette } from '../../../lib/formUtils'
import { themeSelectors } from '../../../selectors'
import InputFormHeader from './InputFormHeader'
import InputFormSection from './InputFormSection'
import WizardForm from './WizardForm'

export interface InputFormProps {
    data: FormData
    defaults: FormData
    config: InputFormConfig
    onSave: (data: FormData[]) => Promise<any>
    title?: string
    multiEdit?: boolean
    onClose?: () => void
    isEditingLayout?: boolean
    FormFooter?: ComponentType<{ row: any }>
    onLayoutChange?: (section: FormSection) => void
    readOnly?: boolean
}

const defaultData = {}

export default function InputForm({
    config,
    onSave,
    onClose,
    title,
    data = defaultData,
    defaults,
    readOnly,
    ...rest
}: InputFormProps) {
    const { sections, palette, loadInitialData } = config
    const prompts = getallPrompts(config)
    const defaultVals = useMemo(
        () =>
            Object.entries(defaults).reduce((acc, [key, val]) => {
                const prompt = prompts.find(p => p.property?.name === key)
                if (Array.isArray(val)) {
                    if (prompt?.multiAdd) acc[key] = val
                    else if (val.length === 1) acc[key] = val[0]
                } else if (typeof val === 'string' || typeof val === 'number') acc[key] = val
                return acc
            }, {}),
        [defaults]
    )
    const initializeData = () => ({ ...data, ...defaultVals, isNew: data.isNew || !loadInitialData })
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState<FormData>(initializeData())

    useUpdateEffect(() => {
        setValues(initializeData())
    }, [data, defaultVals])

    const theme = useTheme()
    const themeMode = useSelector(themeSelectors.getThemeMode)

    const { background, formPalette } = useMemo(() => {
        const formPalette = isDefaultFormPalette(palette)
            ? { background: theme.palette.background.main, fontColor: theme.palette.text.primary }
            : palette
        return { background: getFormBackground(formPalette.background), formPalette: formPalette }
    }, [palette, themeMode])

    const { enqueueSnackbar } = useSnackbar()
    const errSnack = (message: string) => enqueueSnackbar(message, { variant: 'error' })

    const formatData = (row: FormData): FormData[] => {
        const arrayKey = Object.keys(row).find(key => Array.isArray(row[key]))
        if (arrayKey) {
            const array = row[arrayKey] as any[]
            return array.reduce((acc, val) => {
                acc.push({ ...row, [arrayKey]: val })
                return acc
            }, [])
        } else return [row]
    }

    const handleSave = async (row: FormData) => {
        setLoading(true)
        try {
            const rows = formatData(row)
            await onSave(rows)
            onClose && onClose()
            setLoading(false)
        } catch (err) {
            console.error(err)
            setLoading(false)
            errSnack('An error occurred saving the form')
        }
    }

    const handleChange = (change: FormData) => setValues(prevVals => ({ ...prevVals, ...change }))

    const params = {
        values,
        palette: formPalette,
        onClose,
        section: sections[0],
        onSubmit: handleSave,
        onChange: handleChange,
        readOnly: readOnly,
        FormActions: ({ submitDisabled, onSubmit }) => (
            <Button
                color="primary"
                variant="contained"
                onClick={onSubmit}
                disabled={submitDisabled}
                sx={{ ml: 1, alignSelf: 'flex-end' }}
                data-cy="submit-form-btn"
            >
                Submit
            </Button>
        ),
        ...rest,
    }

    return (
        <Box
            sx={{
                p: 1,
                flex: 1,
                width: '100%',
                height: '100%',
                display: 'flex',
                flexFlow: 'column',
                overflow: 'hidden',
                background: background,
            }}
        >
            {loading && <DimLoader />}
            <InputFormHeader {...{ title, palette, multiEdit: rest.multiEdit, onClose }} />
            {sections.length > 1 ? <WizardForm sections={sections} {...params} /> : <InputFormSection {...params} />}
        </Box>
    )
}
