import { createSelector } from 'reselect'

const getTheme = s => s.theme

const getThemeMode = s => s.theme.mode

/** convert mui theme to object w/ same interface as server's module theme */
const getCurrentThemeObject = createSelector([getTheme], theme => {
    const { custom, palette } = theme

    if (!custom.themeMeta || !custom.stroke || !palette.background) return {}

    return {
        id: custom.themeMeta.id,
        name: custom.themeMeta.name,
        base: {
            backgrounds: {
                main: palette.background.main,
                sideNav: palette.background.sideNav,
                widget: palette.background.widget,
            },
            fonts: {
                primary: palette.text.primary,
                secondary: '#FFFFFF', // palette.text.secondary, secondary color from server doesn't make sense
                stroke: custom.stroke,
            },
        },
        custom: {
            accent1: { main: palette.primary.main },
            accent2: { main: palette.secondary.main },
            topBar: { main: palette.background.topBar },
            widget: custom.widgetColors,
        },
    }
})

export const themeSelectors = {
    getThemeMode,
    getCurrentThemeObject,
}
