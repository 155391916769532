import { Tooltip } from '@mui/material'
import { useState } from 'react'

import makeStyles from '@mui/styles/makeStyles'

import { SelectPopover } from 'genesis-suite/components'
import { useDispatch, useSelector } from 'react-redux'
import { chatSelectors } from '~/selectors'
import { chatCreators } from '~/actions/creators'

const options = [
    { value: 'V1', label: 'V1', tooltip: 'Version 1' },
    { value: 'V2', label: 'V2', tooltip: 'Version 2' },
    { value: 'V3.1', label: 'V3.1', tooltip: 'Version 3.1' },
]

const useStyles = makeStyles(({ palette }) => ({
    aiVersionToggle: { position: 'relative', alignSelf: 'center' },
    tooltip: { background: palette.primary.main, color: palette.primary.contrastText },
    tooltipArrow: { color: palette.primary.main },
    tooltipRef: { position: 'absolute', right: '50%' },
}))

/**
 * Toggle AI Version
 * @param {*=} props
 * @returns
 */
export default function AIVersionToggle({ disabled, iconProps }) {
    const version = useSelector(chatSelectors.getAIChatVersion) || 'V2'

    const [showTooltip, setShowTooltip] = useState(false)

    const dispatch = useDispatch()

    const classes = useStyles()

    const handleChange = next => {
        if (next.value === version) return

        dispatch(chatCreators.setAIChatVersion(next.value))
    }

    return (
        <div className={classes.aiVersionToggle}>
            <SelectPopover
                PlaceHolderIcon={null}
                defaultValue={options.find(o => o.value === version)}
                tooltip="Change AI Version"
                items={options}
                iconProps={iconProps}
                disabled={disabled}
                onChange={handleChange}
            />

            <Tooltip
                placement="top"
                arrow
                classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
                open={showTooltip}
                title={`Using ${options.find(o => o.value === version)?.tooltip}`}
            >
                <span className={classes.tooltipRef} />
            </Tooltip>
        </div>
    )
}
