export enum DeploymentView {
    DRAFT = 'draft',
    LIVE = 'live',
    STAGING = 'staging',
}

export enum DeploymentViewTooltip {
    DRAFT = 'Editable visuals including drafts by other users.',
    LIVE = 'Production visuals viewable by all users.',
    STAGING = 'Visuals paired with the staging model.',
}

export enum DeploymentViewColor {
    DRAFT = '#2F80ED',
    LIVE = '#2CA02C',
    STAGING = '#F2C80F',
}

export interface DeploymentMode {
    view: DeploymentView.DRAFT | DeploymentView.LIVE
    accessKey?: string
}

export interface DeploymentModeSecure {
    view: DeploymentView.STAGING
    accessKey: string
}
