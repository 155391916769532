import { EmailRounded, ExpandLessRounded, ExpandMoreRounded, SmsRounded } from '@mui/icons-material'
import { CircularProgress, Collapse, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import makeStyles from '@mui/styles/makeStyles'

import { notificationService } from '../../lib/services'
import { applicationSelectors } from '../../selectors'

const useStyles = makeStyles(({ palette, spacing }) => ({
    root: {
        height: '40vh',
        overflow: 'scroll',
        paddingRight: spacing(1),
    },
    boldText: { fontWeight: 600 },
    rule: { borderBottom: `1px solid ${palette.grayscale.light}` },
    ruleSubs: { padding: spacing(1) },
    formLabel: {
        display: 'flex',
        alignItems: 'center',
        '&>svg': { marginRight: spacing(0.5) },
    },
    loading: { marginLeft: spacing(1) },
    icon: { color: palette.text.primary },
}))

export default function Rule({ rule, onChange }) {
    const classes = useStyles({})
    const [toggled, setToggled] = useState(false)
    const [loading, setLoading] = useState(false)
    const appName = useSelector(applicationSelectors.getCurrentAppName)

    useEffect(() => {
        if (toggled && !rule.subs) getSubs()
    }, [toggled])

    const { ruleId, ruleName, description, subs = {} } = rule
    const { email, sms } = subs

    const handleChange = (key, value) => {
        onChange({ ...rule, subs: { ...rule.subs, [key]: value } })
    }

    const getSubs = async () => {
        setLoading(true)
        const subs = await notificationService.getRuleSubscriptions(appName, ruleId)
        if (subs && subs.length > 0) {
            const { Email: email, SMS: sms } = subs[0]
            onChange({ ...rule, subs: { email, sms } })
        } else onChange({ ...rule, subs: {} })
        setLoading(false)
    }

    const EmailLabel = () => (
        <div className={classes.formLabel}>
            <EmailRounded fontSize="small" />
            Email
        </div>
    )

    const SMSLabel = () => (
        <div className={classes.formLabel}>
            <SmsRounded fontSize="small" />
            SMS
        </div>
    )

    return (
        <div key={ruleId} className={classes.rule}>
            <ListItem dense button onClick={() => setToggled(!toggled)}>
                <ListItemText
                    primary={ruleName}
                    primaryTypographyProps={{ style: { fontWeight: 600 }, variant: 'subtitle2' }}
                />
                <ListItemIcon className={classes.icon}>
                    {toggled ? <ExpandLessRounded /> : <ExpandMoreRounded />}
                </ListItemIcon>
            </ListItem>
            <Collapse in={toggled}>
                {loading ? (
                    <CircularProgress className={classes.loading} color="primary" />
                ) : (
                    <div className={classes.ruleSubs}>
                        <Typography>Description: {description || 'N/A'}</Typography>
                        {/* <Typography display="inline">Receive notifications via:</Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={Boolean(email)}
                                    onChange={() => handleChange('email', !Boolean(email))}
                                />
                            }
                            label={<EmailLabel />}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={Boolean(sms)}
                                    onChange={() => handleChange('sms', !Boolean(sms))}
                                />
                            }
                            label={<SMSLabel />}
                            labelPlacement="start"
                        /> */}
                    </div>
                )}
            </Collapse>
        </div>
    )
}
