import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { authService } from '../lib/services'
import userManager from '../lib/userManager'
import { authSelectors } from '../selectors'

const pingPeriod = 5 * 60 * 1000 // ms time period to call ping to keep accessKey alive
const inactiveTime = 2 * 3600 * 1000 // ms after last click action to timeout session
const lockedTime = 2 * 60 * 1000 // ms after timeout to lock out user

export type SessionStatus = 'authenticating' | 'active' | 'timed-out' | 'expired' | 'failed' | 'locked'

export function showContent(status: SessionStatus) {
    switch (status) {
        case 'active':
        case 'timed-out':
        case 'expired':
            return true
        default:
            return false
    }
}

/** Authenticate user, ping accessKey, and (if timeOut) maintain active session by watching for click/touch events */
export default function useSessionStatus(authenticate: boolean, timeOut: boolean): [SessionStatus, () => void] {
    const sessionExpired = useSelector(authSelectors.getSessionExpired)
    const accessKey = useSelector(authSelectors.getAccessKey)

    const [status, setStatus] = useState<SessionStatus>('authenticating')
    const inactiveTimeout = useRef(null)
    const lockedTimeout = useRef(null)

    useEffect(() => {
        if (!authenticate) return setStatus('authenticating')

        Promise.all([userManager.getUser(), authService.ping()])
            .then(([user]) => {
                if (!user) throw new Error('User token failed')

                setStatus('active')
            })
            .catch(err => {
                console.error('err: ', err)
                setStatus('failed')
            })
    }, [authenticate])

    useEffect(() => {
        if (!accessKey) return

        const pingInterval = setInterval(authService.ping, pingPeriod)
        return () => clearInterval(pingInterval)
    }, [accessKey])

    useEffect(() => {
        if (!authenticate || !timeOut) return

        resetInterval()
        document.body.addEventListener('click', resetInterval, true)
        return () => document.body.removeEventListener('click', resetInterval, true)
    }, [authenticate, timeOut])

    function resetInterval() {
        clearTimeout(inactiveTimeout.current)
        inactiveTimeout.current = setTimeout(() => {
            setStatus('timed-out')
            lockedTimeout.current = setTimeout(() => setStatus('locked'), lockedTime)
        }, inactiveTime)
    }

    function extendSession() {
        clearTimeout(lockedTimeout.current)
        setStatus('active')
    }

    const correctedStatus = sessionExpired ? 'expired' : !authenticate ? 'active' : status

    return [correctedStatus, extendSession]
}
