import { Box, Divider, IconButton } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import { makeStyles, useTheme } from '@mui/styles'
import HomeIcon from 'genesis-suite/icons/House'
import { navigationCreators } from '../actions/creators'
import { useIsMobile } from '../hooks/useIsMobile'
import { routePaths } from '../lib/routes'
import { appearanceSelectors, moduleSelectors } from '../selectors'
import ClassicViews from './ClassicViews'
import HistoryController from './HistoryController'
import NeoClassicViews from './NeoClassicViews'
import { AppearanceButton } from './settings/ManageAppearance'
import NeoApplicationController from './NeoApplicationController'

const useStyles = makeStyles(({ palette, spacing }) => ({
    expandMoreIcon: {
        position: 'absolute',
        right: 0,
    },
}))

export default function SecondTopBar() {
    const layout = useSelector(appearanceSelectors.getApplicationLayout)
    const showNeoClassic = useSelector(appearanceSelectors.getShowNeoClassicLayOut)
    const isMobile = useIsMobile()
    const modulePending = useSelector(moduleSelectors.getModulesPending)
    const dispatch = useDispatch()
    const goHome = () => dispatch(navigationCreators.goTo(routePaths.HOME))
    const theme = useTheme()
    const showApplicationController = useSelector(appearanceSelectors.getShowApplicationController)

    if (layout === 'modern' || isMobile) return null

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                borderBottom: 1,
                borderColor: 'border.main',
                backgroundColor: '#777',
            }}
        >
            {!showApplicationController && (
                <>
                    <IconButton onClick={!modulePending ? goHome : undefined} sx={{ color: '#FFF' }}>
                        <HomeIcon />
                    </IconButton>
                    {showNeoClassic && <AppearanceButton sx={{ color: '#FFF' }} />}
                    <HistoryController />
                    <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{ borderColor: '#bfbfbf', mr: '4px', borderWidth: '1px' }}
                    />
                </>
            )}
            {showNeoClassic ? (
                showApplicationController ? (
                    <NeoApplicationController />
                ) : (
                    <NeoClassicViews />
                )
            ) : (
                <ClassicViews />
            )}
        </Box>
    )
}
