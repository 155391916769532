import { useDispatch, useSelector } from 'react-redux'
import { moduleCreators } from '../actions/creators'
import { applicationSelectors, moduleSelectors } from '../selectors'
import { moduleService } from './services'
import { useMemo, useCallback } from 'react'

export default function useAppOptions() {
    const rawAppOptions = useSelector(moduleSelectors.getAppOptions)
    const appOptions = useMemo(() => JSON.parse(rawAppOptions || '{}'), [rawAppOptions])
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const visorId = useSelector(moduleSelectors.getModuleId)
    const dispatch = useDispatch()

    const getOptions = useCallback(key => appOptions?.[key] ?? null, [appOptions])

    const updateOptions = useCallback(
        async (key, options) => {
            const newAppOptions = {
                visorId,
                appName,
                appOptions: JSON.stringify({ ...appOptions, [key]: options }),
            }

            try {
                await moduleService.updateAppOptions(newAppOptions)
            } catch (ex) {
                return ex?.Message || 'failed'
            }

            dispatch(moduleCreators.updateModuleOptions(newAppOptions))
            return 'success'
        },
        [appOptions, appName, visorId, dispatch]
    )

    return {
        getOptions,
        updateOptions,
    }
}
