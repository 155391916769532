import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useContext } from 'react'
import { PerspectiveWidgetContext } from '~/components/contexts/PerspectiveWidgetContext'

export default function KanbanColumnsSelector() {
    const { kanbanColumns, setKanbanColumns } = useContext(PerspectiveWidgetContext)
    const handleColChange = (e: SelectChangeEvent) => {
        setKanbanColumns({ ...kanbanColumns, selectedColumn: e?.target.value })
    }

    if (!kanbanColumns || !kanbanColumns.cols || kanbanColumns.cols.length < 2) return null

    return (
        <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label=""
            value={kanbanColumns.selectedColumn}
            onChange={handleColChange}
            sx={theme => ({
                '& .MuiSelect-select': { pl: 1, py: 0.2 },
                '& .MuiSelect-icon': { fill: theme.palette.text.primary },
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.text.primary,
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.text.primary,
                },
            })}
            variant="outlined"
        >
            {kanbanColumns?.cols?.map(k => (
                <MenuItem value={k}>{k}</MenuItem>
            ))}
        </Select>
    )
}
