import { EditRounded } from '@mui/icons-material'
import { Box, Tooltip } from '@mui/material'
import { GridCellParams, GridColDef, GridRenderEditCellParams } from '@mui/x-data-grid-pro'
import clsx from 'classnames'
import { Property, ResourceType } from 'genesis-suite/types/networkTypes'
import {
    DateFormat,
    FieldVisibility,
    FormColumn,
    FormConfig,
    FormData,
    FormField,
    FormPrompt,
    FormSection,
    Gradient,
    InputFormConfig,
    KanbanFormConfig,
} from 'genesis-suite/types/visualTypes'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import { ColumnOptions } from '../components/contexts/ColumnOptionsContext'
import FormEditCell from '../components/widgets2/form/FormEditCell'
import TableFormCell from '../components/widgets2/form/TableFormCell'
import formatNumber from '../components/widgets2/utils/formatNumber'
import { modelService } from './services'
import { darkTheme, lightTheme } from './tadaThemes'

export type LoadingCell = { rowId: string; fieldName: string; originalFieldName: string }

type DataType = 'number' | 'date' | 'dateTime' | 'string' | 'attachment'
export const getType = (property: Property, dateFormat: DateFormat): DataType => {
    if (!property) return 'string'
    const { dataTypeClass, semanticType } = property
    if (semanticType?.baseDataType === 'File') return 'attachment'
    switch (dataTypeClass) {
        case 'Number':
            return 'number'
        case 'DateTime':
            if (!dateFormat || !dateFormat.includes('h')) return 'date'
            else return 'dateTime'
        default:
            return 'string'
    }
}

const muiEditors = ['string', 'number', 'date', 'dateTime']

export const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss'

export function buildXGridColumns(
    columns: FormColumn[],
    flaggedColumns: any = {},
    customColDef: Partial<GridColDef> = {},
    columnOptions: ColumnOptions[],
    loadingCells: LoadingCell[]
): GridColDef[] {
    const colDefs = columns.map(column => {
        const {
            title,
            width,
            editable,
            property,
            visibility,
            dateFormat,
            customEditor,
            autogenerate,
            useTadaValues,
            numberFormat,
            displayProperty,
            rowJustification,
            autogeneratePrefix,
            headerJustification,
            conditionalFormatting,
        } = column
        const { width: colOptWidth } = columnOptions.find(opt => opt.colId === property.name) || {}
        const type = getType(displayProperty || property, dateFormat)
        const canEdit = editable && !autogenerate && !autogeneratePrefix && !property?.computed
        const colWidth = type === 'attachment' ? colOptWidth || 160 : colOptWidth || width || 125

        return {
            ...column,
            hideable: false,
            editable: canEdit,
            description: title,
            field: property.name,
            align: rowJustification,
            headerAlign: headerJustification,
            type: type === 'attachment' ? 'string' : type,
            width: colWidth,
            hide: visibility === FieldVisibility.FORM || visibility === FieldVisibility.HIDE,
            renderHeader: () => (
                <>
                    <Tooltip title={title}>
                        <Box
                            sx={{
                                fontWeight: 600,
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {title}
                        </Box>
                    </Tooltip>
                    {canEdit && (
                        <Tooltip title="Column is editable">
                            <EditRounded sx={{ ml: 0.5, fontSize: 10 }} />
                        </Tooltip>
                    )}
                </>
            ),
            cellClassName: (params: GridCellParams) => {
                const {
                    row: { id },
                } = params
                const flagged = flaggedColumns[id] ? flaggedColumns[id].includes(title) : false
                return clsx('tadaCell', {
                    flaggedCell: flagged,
                })
            },
            renderCell: (params: GridCellParams) => {
                const isLoading = Boolean(
                    loadingCells.find(c => c.rowId === params.row.id && c.fieldName === property.name)
                )
                return (
                    <TableFormCell
                        {...params}
                        isLoading={isLoading}
                        numberFormat={numberFormat}
                        conditionalFormatting={conditionalFormatting}
                    />
                )
            },
            ...((useTadaValues || customEditor || !muiEditors.includes(type)) && {
                renderEditCell: (params: GridRenderEditCellParams) => (
                    <FormEditCell {...params} column={column} columns={columns} />
                ),
            }),
            preProcessEditCellProps: params => {
                let error = null
                const {
                    props: { value },
                } = params
                const minDate = new Date('1/1/1900')
                if (type === 'date' && value && moment(value as string).isBefore(moment(minDate)))
                    error = 'Minimum date is 01/01/1900'
                else if (property.isPrimary && !value && value !== 0) error = true
                return { ...params.props, error }
            },
            ...customColDef,
        }
    })
    if (columnOptions.length === colDefs.length) {
        const ordered = columnOptions.map(opt => colDefs.find(col => col.field === opt.colId))
        return ordered
    } else return colDefs
}

export function hasProperties(config: FormConfig): boolean {
    if (!config) return false
    if (config.formType === 'table') {
        return Boolean(config.columns?.length) ?? false
    } else {
        return Boolean(config?.sections[0]?.prompts?.length) ?? false
    }
}

export const propertyToColumn = (property: Property): FormColumn => ({
    property,
    id: uuid(),
    width: 150,
    editable: true,
    multiAdd: false,
    dependencies: [],
    useTadaValues: false,
    dateFormat: 'MM/DD/YYYY',
    conditionalFormatting: [],
    title: property.displayName,
    required: property.isPrimary,
    visibility: FieldVisibility.SHOW,
})

export const createSection = (properties: Property[]): FormSection => ({
    id: uuid(),
    required: true,
    prompts: properties
        .filter(p => p.isPrimary)
        .map(property => ({
            property,
            id: uuid(),
            editable: true,
            required: true,
            multiAdd: false,
            useTadaValues: false,
            dateFormat: 'MM/DD/YYYY',
            title: property.displayName,
            visibility: FieldVisibility.SHOW,
        })),
})

export interface ContextFilter {
    ResourceName: string
    ResourceType: 'Concept' | 'Link'
    PropertyName: string
    Values: any[]
}
export const makeContextFilters = (dependencies: Property[], data: FormData): ContextFilter[] => {
    if (!dependencies) return []
    return dependencies.reduce((acc, { container, name }) => {
        const value = data[name]
        if (value == null) return acc
        else {
            acc.push({
                ResourceName: container.name,
                ResourceType: container.type,
                PropertyName: name,
                Values: Array.isArray(value) ? value : [value],
            })
            return acc
        }
    }, [])
}

/** Check for any column dependencies on updatedFieldName and clear them if no longer valid */
export async function validateUpdatedRow(
    appName: string,
    columns: FormColumn[],
    updatedRow: FormData,
    fieldName: string
) {
    const dependentCols = getDependentColumns(columns, fieldName)
    if (!dependentCols.length) return

    const flaggedCells = await Promise.all(
        dependentCols.map(async ({ title, property, dependencies }) => {
            const { name: filterName, container } = property
            const filterVal = updatedRow[filterName]
            const contextFilters = makeContextFilters(dependencies, updatedRow)
            const request = {
                contextFilters,
                filterExpression: `${filterName}=${filterVal}`,
            }
            const { Data } = await modelService.filterResourceValues(appName, container.name, request)
            if (!Data?.length) return Promise.resolve(title)
            else return Promise.resolve()
        })
    )
    return flaggedCells.filter(v => !!v)
}

function getDependentColumns(columns: FormColumn[], fieldName?: string) {
    return columns.filter(
        c => c.dependencies?.length && (c.title === fieldName || c.dependencies.find(d => d.name === fieldName))
    )
}

export function getFieldNamesFromConfig(config: FormConfig) {
    if (config.formType === 'table') return config.columns.map(c => c.property?.name).filter(n => Boolean(n))
    else return config.sections?.[0].prompts.map(p => p.property?.name).filter(n => Boolean(n))
}

export function getFormBackground(background: string | Gradient): string {
    if (typeof background === 'string') return background
    else {
        const { angle, color1, color2 } = background
        const string = `linear-gradient(${angle}deg, ${color1}, ${color2})`
        return string
    }
}

export function isDefaultFormPalette(formConfig) {
    if (!formConfig) return false
    if (!formConfig?.palette) return true

    const formFontColor = formConfig?.palette.fontColor
    const formBgColor = formConfig?.palette.background

    return (
        (formFontColor === lightTheme.fonts.primary && formBgColor === lightTheme.backgrounds.main) ||
        (formFontColor === darkTheme.fonts.primary && formBgColor === darkTheme.backgrounds.main)
    )
}

export function getFormFields(config: FormConfig): FormColumn[] | FormPrompt[] {
    if (config.formType === 'table') return config.columns
    else return getallPrompts(config)
}

export function getallPrompts(config: InputFormConfig | KanbanFormConfig): FormPrompt[] {
    const { sections } = config
    return sections.reduce((acc, sec) => {
        sec.prompts.forEach(p => {
            acc.push(p)
        })
        return acc
    }, [])
}

type Fields = FormColumn[] | FormPrompt[]
export const buildNodeReq = (rows, oldRows, fields: Fields, nodeName) => {
    const fieldNames = fields.map(f => f.property.name)
    const primaryPropName = fields.find(f => f.property.isPrimary)?.property.name
    const lastUpdateProperty = fields.find(f => f.lastUpdateField)?.property
    return rows.reduce((acc, row) => {
        const date = new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()
        const oldRow = oldRows.find(r => r.id === row.id) || {}
        const properties = Object.entries(row)
            .filter(([k, v]) => fieldNames.includes(k) && lastUpdateProperty?.name !== k)
            .map(([key, value]) => ({
                name: key,
                value: lastUpdateProperty?.name === key ? date : value,
                oldValue: oldRow[key] ?? null,
            }))
        acc.push({
            name: nodeName,
            nodeIndex: primaryPropName ? oldRow[primaryPropName] : undefined,
            properties,
        })
        return acc
    }, [])
}

export const buildLinkReq = (rows, oldRows, fields: Fields) => {
    const nodeProps = fields.filter(f => f.property.container.type === ResourceType.NODE).map(f => f.property)
    const linkProps = fields.filter(f => f.property.container.type === ResourceType.LINK).map(f => f.property)
    const lastUpdateProperty = fields.find(f => f.lastUpdateField)?.property
    return rows.reduce((acc, row) => {
        const date = new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()
        acc.push({
            nodes: nodeProps.reduce((acc, prop) => {
                if (!acc.find(obj => obj.name === prop.container.name)) {
                    acc.push(
                        buildNodeReq(
                            [row],
                            oldRows,
                            fields.filter(f => f.property.container.name === prop.container.name),
                            prop.container.name
                        )
                    )
                    acc = acc.flat()
                }
                return acc
            }, []),
            properties: linkProps.reduce((acc, prop) => {
                const oldRow = oldRows.find(r => r.id === row.id) || {}
                acc.push({
                    name: prop.name,
                    value: lastUpdateProperty?.name === prop.name ? date : row[prop.name],
                    oldValue: oldRow[prop.name] ?? null,
                })
                return acc
            }, []),
        })
        return acc
    }, [])
}

export function getFormProperties(config: FormConfig) {
    switch (config.formType) {
        case 'input':
        case 'kanban':
            return config.sections[0].prompts.map(p => p.property).filter(p => Boolean(p))
        case 'table':
            return config.columns.map(c => c.property)
    }
}

export function hasComputedProperty(config: FormConfig) {
    const properties = getFormProperties(config)
    return properties.some(p => p.computed)
}

export function getIndexProperties(config: FormConfig) {
    const properties = getFormProperties(config)
    return properties.filter(p => p.isPrimary)
}

export const formatFormValue = (field: FormField, value: any) => {
    if (!field) {
        console.error('No field provided!')
        return value
    }
    const { dateFormat, numberFormat, property } = field
    if (!property) {
        console.error('No property found!')
        return value
    } else {
        const { dataTypeClass } = property
        switch (dataTypeClass) {
            case 'Text':
                return value
            case 'DateTime':
                return moment(value).format(dateFormat)
            case 'Number':
                return formatNumber(value, numberFormat)
            default:
                return value
        }
    }
}
